import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class TakeoffCompareService {
    public readonly displayCompare$ = new Subject<number>();
    public readonly takeoffSaved$ = new Subject<boolean>();
}
