<div class="flex-row flex">
    <mat-icon class="cb-help-cursor"
              [ngClass]="actvityStatus.value.class"
              [svgIcon]="actvityStatus.value.icon"
              [matTooltip]="actvityStatus.value.text"></mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="poDownloadStatus.value.visible"
              [ngClass]="poDownloadStatus.value.class"
              [svgIcon]="poDownloadStatus.value.icon"
              [matTooltip]="poDownloadStatus.value.text"></mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="complianceStatus.value.visible"
              [ngClass]="complianceStatus.value.class"
              [svgIcon]="complianceStatus.value.icon"
              [matTooltip]="complianceStatus.value.text"></mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="buildProgrammeActivity?.hasUnallocatedQuantity"
              svgIcon="gauge-full"
              class="cb-grey"
              [matTooltip]="'Has Unallocated Quantity'"></mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="buildProgrammeActivity?.hasHorizontalPredecessor"
              svgIcon="ray-start-arrow"
              class="cb-primary"
              [matTooltip]="'Activity has Horizontal Predecessor'"></mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="pausedStatus.value.visible"
              [svgIcon]="pausedStatus.value.icon"
              [ngClass]="pausedStatus.value.class"
              [matTooltip]="pausedStatus.value.text"></mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="buildProgrammeActivity?.isTransformedToManualOrder"
              svgIcon="transfer"
              class="cb-grey"
              matTooltip="Transformed to Manual Order"></mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="buildProgrammeActivity?.splitFromBuildActivityId > 0"
              svgIcon="call-split"
              class="cb-grey"
              matTooltip="Split Activity"></mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="blockLevelActivityStatus.value.visible"
              [svgIcon]="blockLevelActivityStatus.value.icon"
              [ngClass]="blockLevelActivityStatus.value.class"
              [matTooltip]="blockLevelActivityStatus.value.text"></mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="needsVariationAssociatedStatus.value.visible"
              [svgIcon]="needsVariationAssociatedStatus.value.icon"
              [ngClass]="needsVariationAssociatedStatus.value.class"
              [matTooltip]="needsVariationAssociatedStatus.value.text">
    </mat-icon>
    <mat-icon class="cb-help-cursor"
              *ngIf="hasUnknownRateStatus.value.visible"
              [svgIcon]="hasUnknownRateStatus.value.icon"
              [ngClass]="hasUnknownRateStatus.value.class"
              [matTooltip]="hasUnknownRateStatus.value.text"></mat-icon>
</div>
