import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ILotSpecPinned } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ILotSpecAllItem } from '@app/logic/lot-spec/interfaces/i.lot-spec-all-item.dto';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { ILotSpecColourItemDto, ILotSpecItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { remove } from 'lodash';
import { ColWidthSyncService } from '../services/col-width-sync.service';

export interface ILotSpecItemExpandedPanels {
    [specGroupId: number]: boolean | null;
}
export const HOUSE_AREA_PIN_PREFIX = 'houseArea';


@Component({
    selector: 'cb-lot-spec-items-list',
    templateUrl: './lot-spec-items-list.component.html',
    styleUrls: ['./lot-spec-items-list.component.scss'],
    providers: [
        ColWidthSyncService
    ]
})
export class LotSpecItemsListComponent implements AfterViewInit {

    public readonly HOUSE_AREA_PIN_PREFIX = HOUSE_AREA_PIN_PREFIX;
    public readonly ITEM_CARD_HEIGHT_PX = 425;
    public displayViewport = false;

    @Input() public fullscreen: boolean;
    @Input() public readonly lotSpec: ILotSpecMappedItem;
    @Input() public readonly houseAreaItems: { specGroupId: number; sortOrder: number; label: string; items: ILotSpecAllItem[] }[];
    @Input() public readonly listViewEnabled = false;

    public readonly readyPanels = {} as ILotSpecItemExpandedPanels;
    @Input() public readonly expandedPanels: ILotSpecItemExpandedPanels;
    @Output() public readonly expandedPanelsChange = new EventEmitter<ILotSpecItemExpandedPanels>();

    public get userCacheItem(): UserCacheItem<ILotSpecPinned> {
        return this.userCacheService.lotSpecPinned;
    }

    constructor(
        private readonly userCacheService: UserCacheService,
        /** provided by LotSpecItemsListComponent */
        public readonly colWidthSync: ColWidthSyncService,
        public readonly cdref: ChangeDetectorRef
    ) { }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.displayViewport = true;
            this.cdref.detectChanges();
        });
    }

    public trackBySpecGroup(_index: number, houseArea: { specGroupId: number }): number | null {
        return houseArea.specGroupId;
    }

    public trackByItem(_index: number, item: ILotSpecAllItem): number | null {
        return item.item.id;
    }

    public expandedChange(): void {
        this.expandedPanelsChange.emit(this.expandedPanels);
    }

    public togglePanelPinned = (event: MouseEvent, key: string): void => {
        event.stopPropagation();
        this.userCacheItem.data[key] = !this.userCacheItem.silentData[key];
    };

    public specItemDeleted(item: ILotSpecItemDto): void {
        remove(this.lotSpec.items, { id: item.id });
        this.lotSpec.$recomputeProps();
    }

    public specItemEdited(item: ILotSpecItemDto): void {
        remove(this.lotSpec.items, { id: item.id });
        this.lotSpec.items.push(item);
        this.lotSpec.$recomputeProps();
    }


    public colourItemDeleted(item: ILotSpecColourItemDto): void {
        remove(this.lotSpec.colourItems, { id: item.id });
        this.lotSpec.$recomputeProps();
    }

    public colourItemEdited(item: ILotSpecColourItemDto): void {
        remove(this.lotSpec.colourItems, { id: item.id });
        this.lotSpec.colourItems.push(item);
        this.lotSpec.$recomputeProps();
    }

    public initSpecGroup(specGroupId: number): void {
        // set timeout to display house area panels one after another - better for performance in this case
        setTimeout(() => {
            this.readyPanels[specGroupId] = true;
        });
    }

    public exitSpecGroup(specGroupId: number): void {
        this.readyPanels[specGroupId] = false;
    }

    // eslint-disable-next-line max-len
    private readonly visibleFromBottom = (rect: DOMRect, viewport: HTMLElement) => rect.top > 0 && (rect.top - this.ITEM_CARD_HEIGHT_PX - viewport.clientHeight) < viewport.clientHeight;
    private readonly visibleFromTop = (rect: DOMRect, viewport: HTMLElement) => rect.top < 0 && rect.top > (viewport.clientHeight * -1);

    /** is the spec item already in or approaching the viewport */
    public isSpecItemInViewport(viewport: HTMLElement, ele: HTMLElement): boolean {
        const rect = ele.getBoundingClientRect();
        return this.visibleFromTop(rect, viewport) || this.visibleFromBottom(rect, viewport);
    }
}
