import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ITaskOutcomeDto } from './interfaces/i.task-outcome.dto';
import { ITaskOutcomeMappedItem } from './interfaces/i.task-outcome.mapped';
import { ITaskOutcomesLogicService } from './interfaces/i.task-outcomes.logic.service';
import { TaskOutcomeMappedItem } from './task-outcome.mapped';


@Injectable()
export class TaskOutcomesLogicService
    extends BaseLogicService<ITaskOutcomeDto, ITaskOutcomeMappedItem>
    implements ITaskOutcomesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'taskoutcomes',
            TaskOutcomeMappedItem,
        );
    }
}
