import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserSettingsService {

    constructor(
        private readonly httpWrapperService: HttpWrapperService
    ) {}

    public deleteAllSettingsForCurrentUser(): Observable<number[]> {
        return this.httpWrapperService.delete<number[]>('/usersetting/deleteallforcurrentuser');
    }

    public getClientIpAddress(): Observable<string> {
        return this.httpWrapperService.get<string>('/usersetting/clientip');
    }
}
