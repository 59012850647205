<div class="flex"
     *ngIf="mappedItem">
    <mat-tab-group class="flex"
                   [cbTabRoute]="PRODUCT_TAB_FULL_ROUTE"
                   cbDefaultTabId="item-usage-summary"
                   cbTabUrlParam="cbTabRouteId2">
        <mat-tab label="Summary"
                 cbTabRouteId="item-usage-summary">
            <cb-item-usage-summary-tab [productId]="mappedItem.id"></cb-item-usage-summary-tab>
        </mat-tab>
        <mat-tab label="Spec Templates"
                 cbTabRouteId="item-usage-spec-templates">
            <ng-template matTabContent>
                <cb-item-usage-spec-template-tab [productId]="mappedItem.id"></cb-item-usage-spec-template-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Lot Specification"
                 cbTabRouteId="item-usage-lot-specifications">
            <ng-template matTabContent>
                <cb-item-usage-lot-specification-tab [productId]="mappedItem.id"></cb-item-usage-lot-specification-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Confirmed Quantities"
                 cbTabRouteId="item-usage-confirmed-quantities">
            <ng-template matTabContent>
                <cb-item-usage-confirmed-quantity-tab [productId]="mappedItem.id">
                </cb-item-usage-confirmed-quantity-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Build Programmes"
                 cbTabRouteId="item-usage-build-programmes">
            <ng-template matTabContent>
                <cb-item-usage-build-programme-tab [productId]="mappedItem.id"></cb-item-usage-build-programme-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Purchase Orders"
                 cbTabRouteId="item-usage-purchse-orders">
            <ng-template matTabContent>
                <cb-item-usage-purchase-order-tab [productId]="mappedItem.id"></cb-item-usage-purchase-order-tab>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
