import {Component, Input} from '@angular/core';
import {IDesignConceptMappedItem} from '@app/logic/design-concepts';
import {ILotMappedItem, LotsLogicService} from '@app/logic/lots';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'cb-design-concept-view',
    templateUrl: './design-concept-view.component.html',
    styleUrls: ['./design-concept-view.component.scss']
})
export class DesignConceptViewComponent {

    public readonly mappedItem$$: BehaviorSubject<IDesignConceptMappedItem> = new BehaviorSubject(null);
    @Input() public set mappedItem(_designConcept: IDesignConceptMappedItem) {
        this.mappedItem$$.next(_designConcept);
        this.lotsLogicService.$getMappedItem(_designConcept.lotId).subOnce((_lotMappedItem) => {
            this.lotMappedItem = _lotMappedItem;
        });
    }

    public get mappedItem(): IDesignConceptMappedItem {
        return this.mappedItem$$?.value;
    }

    public lotMappedItem: ILotMappedItem;

    constructor(public readonly lotsLogicService: LotsLogicService) { }
}
