import {BasePermissions, IBaseSystemAreaPermissions,} from './base.permissions';
import {ProductPermissionEnum} from '../../shared/enums/permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';


export interface IProductPermissions extends IBaseSystemAreaPermissions {

    canSearch(): boolean;

    canSeeComponent(): boolean;

    canManageProductAvailability(): boolean;

    canManageImages(): boolean;

    canViewRates(): boolean;

    canManageRates(): boolean;

    canManageCategories(): boolean;

    canExportSearchResults(): boolean;

    canExportCatalogue(): boolean;

    canPerformImport(): boolean;

    canViewDocuments(): boolean;

    canUploadFiles(): boolean;

    canViewHistory(): boolean;

    canViewProductUsage(): boolean;

    canManageProductCatalogueExports(): boolean;
}

@Injectable()
export class ProductPermissions
    extends BasePermissions<ProductPermissionEnum>
    implements IProductPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('product', ProductPermissionEnum, currentUser, http, router);
    }

    public canSearch(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanSearch);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanSearch);
    }

    public canView(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanCreate);
    }

    public canEdit(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanEdit);
    }

    public canManageProductAvailability(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanManageAvailability);
    }

    public canManageImages(): boolean {
        return this.permissionObject(ProductPermissionEnum.ImagesCanManage);
    }

    public canViewRates(): boolean {
        return this.permissionObject(ProductPermissionEnum.RatesCanView);
    }

    public canManageRates(): boolean {
        return this.permissionObject(ProductPermissionEnum.RatesCanManage);
    }

    public canManageCategories(): boolean {
        return this.permissionObject(ProductPermissionEnum.CategoriesCanManage);
    }

    public canExportSearchResults(): boolean {
        return this.permissionObject(ProductPermissionEnum.SearchResultsCanExport);
    }

    public canExportCatalogue(): boolean {
        return this.permissionObject(ProductPermissionEnum.CatalogueCanExport);
    }

    public canPerformImport(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanImport);
    }

    public canViewDocuments(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanViewDocuments);
    }

    public canUploadFiles(): boolean {
        return this.permissionObject(ProductPermissionEnum.FilesCanUpload);
    }

    public canViewHistory(): boolean {
        return this.permissionObject(ProductPermissionEnum.HistoryCanView);
    }

    public canViewProductUsage(): boolean {
        return this.permissionObject(ProductPermissionEnum.ProductUsageCanView);
    }

    public canManageProductCatalogueExports(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanManageProductCatalogueExports);
    }

    public canManageBuildOverheadCosts(): boolean {
        return this.permissionObject(ProductPermissionEnum.CanManageBuildOverheadCosts);
    }
}
