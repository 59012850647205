export enum USER_CACHE_AREA {
    DesignTeamSchemesSearch = 1,
    DesignTeamPreconsentPlansSearch = 2,
    DesignTeamWorkingDrawingsSearch = 3,
    DesignTeamCouncilRfiSearch = 4,
    DesignTeamImageRequestSearch = 5,
    DesignTeamQuestionSearch = 6,
    StandardPlansSearch = 7,
    QsTeamQuestionSearch = 8,
    DesignTeamChangeRecordSearch = 9,
    QsTeamChangeRecordSearch = 10,
    DesignTeamTaskSearch = 11,
    QsTeamTaskSearch = 12,
    QsTeamPricingRevisionSearch = 13,
    QsTeamTakeoffSearch = 14,
    ManualPurchaseOrdersForApprovalSearch = 15,
    BusinessAccountSearch = 16,
    ClientSaleSearch = 17,
    SpecTemplateView = 18,
    BusinessAccountQuoteSearch = 19,
    BuildProgrammeTemplateEdit = 20,
    HelpSearch = 21,
    LotBuildProgrammeView = 22,
    BuildProgrammePurchaseOrderSearch = 23,
    LotPriceRevisionSearch = 24,
    LotConfirmedQuanitities = 25,
    LotDesignSchemeSearch = 26,
    LotDesignQuestionsSearch = 27,
    LotWorkingDrawingSearch = 28,
    LotSummary = 29,
    LotSpecItemManageAttributes = 30,
    LotSpecPinned = 31,
    LotCouncilRfiSearch = 32,
    LotQuotesSearch = 33,
    LotSpecSearch = 34,
    ManualPurchaseOrderSearch = 35,
    ProductCatalogSearch = 36,
    OldBuildActivitySearch = 37,
    DashboardWidgetConfig = 38,
    ProductSearch = 39,
    ProductItemUsageLotSpecSearch = 40,
    TeamBuildProgrammeSearch = 41,
    LeadSearch = 42,
    PurchaseOrderSearch = 43,
    SidebarClientAccountView = 44,
    SidebarBusinessAccountView = 45,
    SidebarBuildTemplateView = 46,
    SidebarContactView = 47,
    SidebarLeadView = 48,
    SidebarProductView = 49,
    SidebarProjectView = 50,
    SidebarLotView = 51,
    SidebarProjectReleaseView = 52,
    SidebarUserView = 53,
    SidebarSpecTemplateView = 54,
    SidebarBuildProgrammeDetailsView = 55,
    SidebarProductItemUsageView = 56,
    SidebarBuildActivityView = 57,
    SidebarBusinessEntityView = 58,
    BuildActivitySearch = 59,
    LotPropertySearch = 60,
    HouseLandPropertySearch = 61,
    AccountSearch = 62,
    ContactSearch = 63,
    ProjectSearch = 64,
    AddAccountSearch = 65,
    AddBizAccountSearch = 66,
    AddContactSearch = 67,
    AddLeadSearch = 68,
    AddVendorSearch = 69,
    LotChangesListSearch = 70,
    LotBuildOrdersSearch = 71,
    AllManualOrderSearch = 72,
    MyLeadsSearch = 73,
    MyClientSalesSearch = 74,
    IncompleteBuildActivities = 75,
    SlotSearch = 76,
    UserSearch = 77,
    ScheduleItemExternalReferences = 78,
    SupplierPortalDashboardSearch = 79,
    ProductRateExceptionSearch = 80
}
