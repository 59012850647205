import {BasePermissions, IBaseSystemAreaPermissions, IDocumentPermissions, IhaveDocumentPermissions, IhaveNotePermissions, INotePermissions} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {BusinessAccountPermissionEnum} from '../../shared/enums/permissions';

export interface IBusinessAccountPermissions
    extends IBaseSystemAreaPermissions, IhaveDocumentPermissions, IhaveNotePermissions {
    canSearch(): boolean;
    canView(): boolean;
    canEdit(): boolean;
    canCreate(): boolean;
    canEditFinanceInformation(): boolean;
    canExport(): boolean;
    canViewHs(): boolean;
    canApproveHs(): boolean;
    canViewDocuments(): boolean;
    canUploadDocuments(): boolean;
    canEditDocuments(): boolean;
    canReviewDocuments(): boolean;
    canDeleteDocuments(): boolean;
    canApproveContracts(): boolean;
    canChangePaymentFrequency(): boolean;
    canViewContacts(): boolean;
    canManageContacts(): boolean;
    canManageRegions(): boolean;
    canViewQuotes(): boolean;
    canApplyHold(): boolean;
    canReleaseHold(): boolean;
    canViewHistory(): boolean;
    canViewCatalogueItems(): boolean;
    canCloseAccount(): boolean;
    canGenerateContracts(): boolean;
    canViewOrders(): boolean;
    getDocumentPermissions(): IDocumentPermissions;
    getNotePermissions(): INotePermissions;
}

@Injectable()
export class BusinessAccountPermissions
    extends BasePermissions<BusinessAccountPermissionEnum>
    implements IBusinessAccountPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('businessaccount', BusinessAccountPermissionEnum, currentUser, http, router);
    }

    public canSearch(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanSearch);
    }
    public canView(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanView);
    }
    public canEdit(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanEdit);
    }
    public canCreate(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanCreate);
    }
    public canEditFinanceInformation(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanEditFinanceInformation);
    }
    public canViewHs(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanViewHistory);
    }
    public canApproveHs(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanApproveHs);
    }
    public canViewDocuments(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanViewDocuments);
    }
    public canUploadDocuments(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanUploadDocuments);
    }
    public canEditDocuments(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanEditDocuments);
    }
    public canReviewDocuments(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanReviewDocuments);
    }
    public canDeleteDocuments(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanDeleteDocuments);
    }
    public canApproveContracts(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanApproveContracts);
    }
    public canChangePaymentFrequency(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanChangePaymentFrequency);
    }
    public canViewContacts(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanViewContacts);
    }
    public canManageContacts(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanManageContacts);
    }
    public canManageRegions(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanManageRegions);
    }
    public canViewQuotes(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanViewQuotes);
    }
    public canApplyHold(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanApplyHold);
    }
    public canReleaseHold(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanReleaseHold);
    }
    public canViewHistory(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanViewHistory);
    }
    public canViewCatalogueItems(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanViewCatalogueItems);
    }
    public canCloseAccount(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanCloseAccount);
    }
    public canGenerateContracts(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanGenerateContracts);
    }
    public canViewOrders(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanViewOrders);
    }

    public canExport(): boolean {
        return this.permissionObject(BusinessAccountPermissionEnum.CanExportBusinessAccounts);
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => this.permissionObject(BusinessAccountPermissionEnum.CanDeleteDocuments),
            canEditDocuments: () => this.permissionObject(BusinessAccountPermissionEnum.CanEditDocuments),
            canReviewDocuments: () => this.permissionObject(BusinessAccountPermissionEnum.CanReviewDocuments),
            canUploadDocument: () => this.permissionObject(BusinessAccountPermissionEnum.CanUploadDocuments),
            canViewDocuments: () => this.permissionObject(BusinessAccountPermissionEnum.CanViewDocuments),
        };
    }

    public getNotePermissions(): INotePermissions {
        return {
            canCreateNote: () => this.permissionObject(BusinessAccountPermissionEnum.CanAddNote),
            canDeleteNote: () => this.permissionObject(BusinessAccountPermissionEnum.CanDeleteNote),
            canEditNote: () => this.permissionObject(BusinessAccountPermissionEnum.CanEditNote),
            canViewNotes: () => this.permissionObject(BusinessAccountPermissionEnum.CanViewNote)
        };
    }
}
