<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-col flex">
    <mat-card class="flex-col flex-100">
        <form class="search-filter-row flex-display flex xs-md-column">
            <div class="cb-margin-top cb-margin-bottom cb-margin-right flex-row flex phone-filter-search">
                <cb-input class="flex"
                          type="text"
                          label="Search"
                          (changeEvent)="changeQuery($event)"
                          (keyup.enter)="doSearch(true)"
                          [disabled]="searchIsLoading"
                          name="search"></cb-input>
                <button mat-icon-button
                        class="search-button"
                        (click)="doSearch(true)"
                        [disabled]="searchIsLoading">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <ng-container *ngIf="userCacheItem.resolved" class="xs-md-column">
                <cb-select-list class="cb-margin flex"
                                label="Status"
                                [allOptions]="statusList"
                                [disabled]="searchIsLoading"
                                [multiple]="true"
                                [value]="userCacheItem.silentData.status"
                                (selectChanged)="selectedStatusesChanged($event)">
                </cb-select-list>
                <cb-select-list class="cb-margin flex"
                                label="Invoice Status"
                                [allOptions]="invoiceStatusList"
                                [disabled]="searchIsLoading"
                                [value]="userCacheItem.silentData.invoiceReceived"
                                (selectChanged)="selectedInvoiceStatusChanged($event)">
                </cb-select-list>
                <cb-select-list class="cb-margin flex"
                                label="Cost Nature"
                                [allOptions]="costNatureList"
                                [disabled]="searchIsLoading"
                                [multiple]="true"
                                [value]="userCacheItem.silentData.costNature"
                                (selectChanged)="selectedCostNatureChanged($event)">
                </cb-select-list>
                <cb-select-list class="cb-margin flex"
                                label="Construction Manager"
                                [allOptions]="constructionManagersList"
                                [disabled]="searchIsLoading"
                                [value]="userCacheItem.silentData.constructionManagerId"
                                (selectChanged)="selectedConstructionManagerChanged($event)">
                </cb-select-list>
                <div class="center-center flex-col">
                    <button mat-raised-button
                            [disabled]="searchIsLoading"
                            color="primary"
                            (click)="exportToCSV()">
                        Export to CSV
                    </button>
                </div>
            </ng-container>
        </form>
        <div class="cb-table cb-margin-top">
            <mat-table infiniteScroll
                       [dataSource]="searchResults"
                       [immediateCheck]="false"
                       [fromRoot]="true"
                       [infiniteScrollContainer]="'div#mainContainer'"
                       (scrolled)="doSearch()">
                <ng-container matColumnDef="jobNumber">
                    <mat-header-cell *matHeaderCellDef> Job # </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.jobNumber}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="orderNumber">
                    <mat-header-cell *matHeaderCellDef> PO # </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.orderNumber || '-'}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="statusLabel">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{PURCHASE_ORDER_STATUS_ENUM[element.statusId]}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="costNature">
                    <mat-header-cell *matHeaderCellDef> Cost Nature </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.costNatureLabel || '-'}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="memoStatus">
                    <mat-header-cell *matHeaderCellDef> Memo Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.complianceDocumentStatusLabel}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="invoiceStatus">
                    <mat-header-cell *matHeaderCellDef> Invoice Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.hasMatchedInvoices ? 'Received' : 'Not Received'}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="supplierName">
                    <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.supplierName}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="costCode">
                    <mat-header-cell *matHeaderCellDef> Cost Code </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.activityCode}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="activityName">
                    <mat-header-cell *matHeaderCellDef> Activity Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.activityName}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="activityStartDate">
                    <mat-header-cell *matHeaderCellDef> Activity Start Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.activityStartDate | date:'d MMM y'}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="activityEndDate">
                    <mat-header-cell *matHeaderCellDef> Activity End Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.activityEndDate | date:'d MMM y'}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef
                                     class="table-currency"> Amount (Ex GST) </mat-header-cell>
                    <mat-cell *matCellDef="let element"
                              class="table-currency"> {{element.orderSubtotal | currency}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="constructionManagerName">
                    <mat-header-cell *matHeaderCellDef> Construction Manager </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.constructionManagerName}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-icon-button
                                (click)="downloadPO(element)"
                                [disabled]="downloadDisabled(element)">
                            <mat-icon>file_download</mat-icon>
                        </button>
                        <button mat-icon-button
                                (click)="goToBuildProgramme(element.lotId, element.buildProgrammeActivityId, element, $event);">
                            <mat-icon>pageview</mat-icon>
                        </button>
                        <button mat-icon-button
                                *ngIf="this.canSeeAbmReconfirmButton"
                                [matTooltip]="'Resend confirmation message to ABM'"
                                (click)="resendAbmConfirmMessage(element)"
                                [disabled]="!canResendAbmConfirm(element)">
                            <mat-icon>compare_arrows</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
        </div>
    </mat-card>
</div>
