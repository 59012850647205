import { Component, Input } from '@angular/core';
import { AuthService } from '../../../../core/authentication/auth.service';
import { CurrentUserService } from '../../../../core/authentication/current.user';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'cb-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {

    @Input() public sidenav: MatSidenav;

    constructor(
        public readonly authService: AuthService,
        public readonly currentUser: CurrentUserService,
        private readonly navigationService: NavigationService
    ) { }

    public navigateToUserSettings(event: MouseEvent): void {
        this.navigationService.navigate(['user-settings'], event)
            .then((result) => {
                if (result.success && !result.newTab) {
                    this.sidenav.close();
                }
            });
    }

}
