import { Component, Input } from '@angular/core';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { LeadChooseContractTypeDialogComponent } from './lead-choose-contract-type-dialog';

@Component({
    selector: 'cb-lead-contract-type-tbc-card',
    templateUrl: './lead-contract-type-tbc-card.component.html',
    styleUrls: ['./lead-contract-type-tbc-card.component.scss']
})
export class LeadContractTypeTbcCardComponent {
    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;
    @Input() public mappedItem: ILeadMappedItem;
    @Input() public canEdit: boolean;

    constructor(public readonly cbDialog: CbDialogService) { }

    public chooseContractType(): void {
        if (!this.canEdit) {
            return;
        }

        this.cbDialog.open(LeadChooseContractTypeDialogComponent, {
            data: {
                mappedItem: this.mappedItem.$clone()
            },
            minWidth: 600
        })
            .afterClosed()
            .subOnce();
    }

}
