<mat-form-field class="flex"
                *ngIf="!readonly"
                [ngClass]="{'cb-soft-required': softRequired && !required }">
    <input #trigger="matAutocompleteTrigger"
           matInput
           [name]="internalName"
           type="text"
           [(ngModel)]="value"
           [ignoreDirty]="ignoreDirty"
           [placeholder]="label"
           [maxlength]="maxlength"
           [required]="required"
           (keyup)="onKeyUp()"
           [matAutocomplete]="auto"
           [requireMatch]="requireMatch"
           [isInvalid]="isInvalid"
           [disabled]="disabled"
           #searchInput/>
    <mat-autocomplete class="flex"
                      #auto="matAutocomplete"
                      [displayWith]="displayWith"
                      (optionSelected)="handleOptionSelected($event)"
                      showPanel="true"
                      (optionsScroll)="onScroll()">
        <mat-option *ngFor="let option of filteredLookups$ | async; let i = index"
                    [value]="option">
            <span [innerHTML]="displayWith(option) || 'Unknown'"></span>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
<div class="flex"
     *ngIf="readonly">
    <cb-display-value-new class="flex"
                          [label]="label">
        {{displayWith(value)}}
    </cb-display-value-new>
</div>
