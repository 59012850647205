import {Component, Input} from '@angular/core';
import {CTSEnum} from '@classictechsolutions/typescriptenums';

@Component({
    selector: 'cb-td-enum[value][enum]',
    templateUrl: './enum.component.html',
    styleUrls: ['./enum.component.scss', './../table-cell.scss']
})
export class TdEnumComponent {
    @Input() public readonly value: string;
    @Input() public readonly enum: CTSEnum<any>;
}
