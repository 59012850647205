import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ILeadMappedItem} from '@app/logic/leads/interfaces/i.lead.mapped';
import {LOT_CONTRACT_TYPE_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lead-view',
    templateUrl: './lead-view.component.html',
    styleUrls: ['./lead-view.component.scss']
})
export class LeadViewComponent {
    @Input() public mappedItem: ILeadMappedItem;
    @Input() public canEdit: boolean;
    @Output() public leadChanged: EventEmitter<any> = new EventEmitter<any>();

    public get isContractTypeTbc(): boolean {
        return this.mappedItem?.contractType === LOT_CONTRACT_TYPE_ENUM.None;
    }

    public get shouldShowHouseLandInterest(): boolean {
        return !this.isDesignAndBuild
            && !this.isContractTypeTbc;
    }

    public get isDesignAndBuild(): boolean {
        return this.mappedItem?.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild;
    }

    constructor() { }


    public qualifyStepNumber(): string {
        // Remove SiteEvaluation and SitePlan steps for Tasks 8969 & 8970
        // return this.isDesignAndBuild() ? "7" : "5";
        return '5';
    }

    public updateLead(mappedItem: ILeadMappedItem): void {
        this.mappedItem = mappedItem;
        this.leadChanged.emit(this.mappedItem);
    }

}
