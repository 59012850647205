<form class="flex-col">
    <div class="flex-row">
        <cb-select class="flex"
                   label="Region"
                   name="region"
                   [required]="isForm"
                   [options]="regions"
                   [disabled]="disabled"
                   [(ngModel)]="region"
                   (change)="onRegionChanged()"></cb-select>
        <button *ngIf="!isForm"
                mat-icon-button
                class="cb-margin-left-3x"
                [disabled]="!region && !district && !area"
                (click)="clearLocation()">
            <mat-icon>remove_circle</mat-icon>
        </button>
    </div>
    <cb-select label="District"
               name="district"
               class="cb-margin-top-2x"
               [required]="isForm"
               [options]="districts"
               [(ngModel)]="district"
               [disabled]="disabled"
               (change)="onDistrictChanged()"></cb-select>
    <cb-select label="Area"
               name="area"
               class="cb-margin-top-2x"
               [required]="isForm"
               [options]="areas"
               [(ngModel)]="area"
               [disabled]="disabled"
               (change)="onLocationChanged()"></cb-select>
</form>
