import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { Observable } from 'rxjs';
import { RESOURCE_CONSENT_STATUS_ENUM, BUILDING_CONSENT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BuildingConsentMappedItem, IBuildingConsentMappedItem, IResourceConsentMappedItem } from '@app/logic/lot-consents';
import moment from 'moment';


interface IData {
    mappedItem: any;
}

@Component({
    selector: 'cb-grant-consent-dialog',
    templateUrl: './grant-consent-dialog.component.html',
    styleUrls: ['./grant-consent-dialog.component.scss']
})
export class GrantConsentDialogComponent extends BaseDialogFormViewDirective<any, any, any> {
    public static readonly MIN_WIDTH = '33%';
    public consentReceivedDateChanged: boolean;
    public get mappedItem(): IBuildingConsentMappedItem | IResourceConsentMappedItem {
        return this.data.mappedItem;
    }
    public get resourceConsent(): IResourceConsentMappedItem {
        return this.data.mappedItem as IResourceConsentMappedItem;
    }
    public get buildingConsent(): IBuildingConsentMappedItem {
        return this.data.mappedItem as IBuildingConsentMappedItem;
    }

    public readonly isBuildingConsent = this.mappedItem instanceof BuildingConsentMappedItem;

    constructor(
        public readonly dialogRef: MatDialogRef<GrantConsentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
    }

    public saveMethod(): Observable<any> {
        if (this.isBuildingConsent) {
            this.mappedItem.consentStatus = BUILDING_CONSENT_STATUS_ENUM.Approved;
        } else {
            this.mappedItem.consentStatus = RESOURCE_CONSENT_STATUS_ENUM.Approved as any;
        }
        return this.mappedItem.$save();
    }

    public onConsentReceivedDateChanged(): void {
        if (this.isBuildingConsent) {
            this.consentReceivedDateChanged = true;
            const numberOfMonthsDueAfterRecieved = 12;
            this.buildingConsent.consentExpiryDate = moment(this.buildingConsent.consentGrantedDate)
                .add(numberOfMonthsDueAfterRecieved, 'months')
                .toJSON();

            const numberOfMonthsDueAfterRecievedCodeOfCompliance = 24;
            this.buildingConsent.codeOfComplianceExpiryDate
                = moment(this.buildingConsent.consentGrantedDate)
                    .add(numberOfMonthsDueAfterRecievedCodeOfCompliance, 'months')
                    .toJSON();
        } else {
            const numberOfYearsDueAfterRecieved = 5;
            this.resourceConsent.consentExpiryDate = moment(this.resourceConsent.consentGrantedDate)
                .add(numberOfYearsDueAfterRecieved, 'years')
                .toJSON();
        }
    }

}
