import {BasePermissions, IBaseSystemAreaPermissions, IDocumentPermissions, IhaveDocumentPermissions, IhaveNotePermissions, INotePermissions,} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ProjectPermissionEnum} from '../../shared/enums/permissions';

export interface IProjectPermissions extends IBaseSystemAreaPermissions, IhaveDocumentPermissions, IhaveNotePermissions {
    canSearch(): boolean;
    canSeeComponent(): boolean;
    canView(): boolean;
    canCreate(): boolean;
    canEdit(): boolean;
    canCancel(): boolean;
    canComplete(): boolean;
    canViewHistory(): boolean;
    canViewUsers(): boolean;
    canViewAccountContacts(): boolean;
    getDocumentPermissions(): IDocumentPermissions;
    getNotePermissions(): INotePermissions;
}

@Injectable()
export class ProjectPermissions extends BasePermissions<ProjectPermissionEnum> implements IProjectPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('project', ProjectPermissionEnum, currentUser, http, router);
    }


    public canSearch(): boolean {
        return this.permissionObject(ProjectPermissionEnum.CanSearch);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(ProjectPermissionEnum.CanSearch);
    }

    public canView(): boolean {
        return this.permissionObject(ProjectPermissionEnum.CanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(ProjectPermissionEnum.CanCreate);
    }

    public canEdit(): boolean {
        return this.permissionObject(ProjectPermissionEnum.CanEdit);
    }

    public canCancel(): boolean {
        return this.permissionObject(ProjectPermissionEnum.ProjectCanCancel);
    }

    public canComplete(): boolean {
        return this.permissionObject(ProjectPermissionEnum.ProjectCanComplete);
    }

    public canViewHistory(): boolean {
        return this.permissionObject(ProjectPermissionEnum.ProjectHistoryCanView);
    }

    public canViewUsers(): boolean {
        return this.permissionObject(ProjectPermissionEnum.ProjectUsersCanView);
    }

    public canViewAccountContacts(): boolean {
        return this.permissionObject(ProjectPermissionEnum.AccountContactsCanView);
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => this.permissionObject(ProjectPermissionEnum.ProjectDocumentCanDelete),
            canEditDocuments: () => this.permissionObject(ProjectPermissionEnum.ProjectDocumentCanEdit),
            canReviewDocuments: () => this.permissionObject(ProjectPermissionEnum.ProjectDocumentCanReview),
            canUploadDocument: () => this.permissionObject(ProjectPermissionEnum.ProjectDocumentCanUpload),
            canViewDocuments: () => this.permissionObject(ProjectPermissionEnum.ProjectDocumentCanView),
        };
    }


    public getNotePermissions(): INotePermissions {
        return {
            canCreateNote: () => this.permissionObject(ProjectPermissionEnum.ProjectNoteCanAdd),
            canDeleteNote: () => this.permissionObject(ProjectPermissionEnum.ProjectNoteCanDelete),
            canEditNote: () => this.permissionObject(ProjectPermissionEnum.ProjectNoteCanEdit),
            canViewNotes: () => this.permissionObject(ProjectPermissionEnum.ProjectNoteCanView)
        };
    }
}
