import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { BuildProgrammeTemplateMappedItem } from './build-programme-template.mapped';
import { IBuildProgrammeTemplateDto, IBuildTemplateActivityDto, IBuildTemplateStageDto } from './interfaces/i.build-programme-template.dto';
import { IBuildProgrammeTemplateLogicService } from './interfaces/i.build-programme-template.logic.service';
import { IBuildProgrammeTemplateMappedItem } from './interfaces/i.build-programme-template.mapped';

@Injectable()
export class BuildProgrammeTemplateLogicService
    extends BaseLogicService<IBuildProgrammeTemplateDto, IBuildProgrammeTemplateMappedItem>
    implements IBuildProgrammeTemplateLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildprogrammetemplates',
            BuildProgrammeTemplateMappedItem,
        );
    }

    public getLookup(): Observable<IBuildProgrammeTemplateDto[]> {
        return this.$http.get(`${this.$baseUri}/lookups`);
    }

    public getBuildProgrammeTemplatesByAddressRegionLookup(addressRegionId: number, existingTemplateId: number, lotId: number): Observable<IBuildProgrammeTemplateDto[]> {
        return this.$http.get(`${this.$baseUri}/lookups/getbyaddressregion/${addressRegionId}`, { existingBPTemplateId: existingTemplateId, lotId });
    }

    public saveActivities(templateId: number, activity: IBuildTemplateActivityDto): Observable<Object> {
        return this.$http.post(`${this.$baseUri}/${templateId}/activities/${activity.id}`, activity);
    }

    public saveStages(templateId: number, stages: IBuildTemplateStageDto[]): Observable<Object> {
        return this.$http.post(`${this.$baseUri}/${templateId}/stages`, { stages });
    }
}
