import { Injectable, Injector } from '@angular/core';
import { FeatureToggleEnumId, IFeatureToggleStateDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IFeatureToggleLogicService } from './interfaces/i.feature-toggle-logic.service';
import { IFeatureToggleStateDictionary } from './interfaces/i.feature-toggle-state-dictionary';

@Injectable()
export class FeatureToggleLogicService
    extends BaseLogicService<IFeatureToggleStateDto, Object>
    implements IFeatureToggleLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'featuretoggle',
            Object
        );
    }

    public seedStates(): Observable<IFeatureToggleStateDto[]> {
        return this.$http
            .post<IFeatureToggleStateDto[]>(`${this.$baseUri}/seed`);
    }

    public updateFeatureToggleState(enumId: FeatureToggleEnumId): Observable<IFeatureToggleStateDto> {
        return this.$http
            .post<IFeatureToggleStateDto>(`${this.$baseUri}/${enumId}`);
    }

    public getDtoList(): Observable<IFeatureToggleStateDto[]> {
        return this.$http
            .get<IFeatureToggleStateDto[]>(`${this.$baseUri}/dtolist`);
    }

    /** Observable that will emit the Feature Toggle State Dictionary. \
     * \
     * ***IMPORTANT:***
     * - **DONT** store the state globally (e.g. in a singleton service) because that would require a browser window refresh in order to get the latest states.
     * - **DO** store the state on the component (where the state is checked), this means the state will be loaded when used and is less likely to be out of date.
     * *Usage Exmaple:*
     * ```typescript
     * class ExampleComponent {
     *     public isScheduleItemSpecTemplatesEnabled = false;
     *
     *     constructor(
     *          private readonly featureToggleLogic: FeatureToggleLogicService,
     *     ) {
     *         this.featureToggleLogic.getStatesDictionary().subOnce((result) => {
     *             this.isScheduleItemSpecTemplatesEnabled = result[FEATURE_TOGGLE_ENUM.ScheduleItemSpecTemplates];
     *         });
     *     }
     * }
     * ```
     */
    public getStatesDictionary(): Observable<IFeatureToggleStateDictionary> {
        return this.$http
            .get<IFeatureToggleStateDictionary>(`${this.$baseUri}/states`);
    }
}
