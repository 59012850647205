import { Component } from '@angular/core';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { BuildTypesDialogComponent } from './build-types-dialog/build-types-dialog.component';
import { BuildTypeLogicService, IBuildTypeDto, BuildTypeMappedItem, IBuildTypeMappedItem } from '@app/logic/build-type';
import { ILocationDto, LOT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { filter, find, some, map } from 'lodash';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LocationLogicService } from '@app/logic/location';
import { BuildProgrammeTemplateLogicService, IBuildProgrammeTemplateDto } from '@app/logic/build-programme-template';

@Component({
    selector: 'cb-build-types',
    templateUrl: './build-types.component.html',
    styleUrls: ['./build-types.component.scss'],
})
export class BuildTypesComponent extends BaseSimpleListViewDirective<IBuildTypeDto, BuildTypeLogicService> {
    public readonly displayedColumns: string[] = [
        'code',
        'label',
        'paymentTemplates',
        'lotTypes',
        'isActive',
        'actions',
    ];
    public lotTypes = LOT_TYPE_ENUM.toSelectList().slice().sort((a, b) => a.label.localeCompare(b.label));
    public filterLotTypes = map(this.lotTypes, lotType => lotType.id);
    public cachedSearchResults: IBuildTypeDto[];
    public locationRegions: ILocationDto[];
    public buildProgrammeTemplates: IBuildProgrammeTemplateDto[];

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly buildTypeLogicService: BuildTypeLogicService,
        public readonly locationLogicService: LocationLogicService,
        public readonly buildProgrammeTemplateLogicService: BuildProgrammeTemplateLogicService,
    ) {
        super(
            cbDialog,
            buildTypeLogicService,
            BuildTypesDialogComponent,
            'Build Type',
            BuildTypeMappedItem,
            permissionsPermissions
        );
        this.locationLogicService.$getList().subOnce((result: ILocationDto[]) => this.locationRegions = result);
        this.buildProgrammeTemplateLogicService.$getList().subOnce((result: IBuildProgrammeTemplateDto[]) => {
            result.forEach((template: any) => {
                if (!template.isActive) {
                    template.name += ' (Inactive)';
                }
            });
            result.unshift({ id: 0, name: 'None' } as any);
            this.buildProgrammeTemplates = result;
        });
    }

    public openDialog(mappedItem: IBuildTypeMappedItem, dialogHeading: string): MatDialogRef<any> {
        return this.cbDialog.open(BuildTypesDialogComponent, {
            minWidth: '50%',
            data: {
                dialogHeading,
                mappedItem,
                buildTypes: this.cachedSearchResults,
                locationRegions: this.locationRegions,
                buildTemplates: this.buildProgrammeTemplates,
            } as any,
        });
    }

    protected handleSearchResults(searchResults: IBuildTypeDto[]): void {
        this.searchResults = this.sortSearchResults(searchResults);
        this.cachedSearchResults = this.searchResults.slice();
        this.searchResultsLoaded = true;
    }

    public sortSearchResults(searchResults: IBuildTypeDto[]): IBuildTypeDto[] {
        return searchResults.sort((a, b) => a.code.localeCompare(b.code));
    }

    public getDefaultPaymentTemplate(templates: any[]): string {
        const defaultTemplate: any = find(templates, { isDefault: true });
        return defaultTemplate ? defaultTemplate.label : '-';
    }

    public getLotTypeLabelList(lotTypes: any[]): string {
        const lotTypeLabels: any[] = [];
        if (lotTypes && lotTypes.length > 0) {
            for (const key of lotTypes) {
                if (key > 0) {
                    lotTypeLabels.push(find(this.lotTypes, { id: key }).label);
                }
            }
        }
        return lotTypeLabels.length === 0 ? '-' : lotTypeLabels.join(', ');
    }

    public onLotTypesChanged(): void {
        this.searchResults = this.filterLotTypes.length === this.lotTypes.length
            ? this.cachedSearchResults.slice()
            : filter(this.cachedSearchResults, (buildType: IBuildTypeDto) => {
                return some(buildType.lotType, (lotType: number) => this.filterLotTypes.indexOf(lotType) > -1);
            });
    }
}
