import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ClientSaleLogicService} from './../../../logic/client-sale/client-sale-logic.service';
import {ClientSalePermissions} from '@app/core/permissions';
import {DocumentService} from '@app/shared/components/documents/services/document.service';
import {DOCUMENT_TYPE_CODES_CONST, SYSTEM_AREA_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {Subscription} from 'rxjs';
import {ClientSaleDocumentsService} from '../documents-service/client-sale-documents.service';
import {MYCLIENTSALES_TAB_FULL_ROUTE} from '../client-sales-route.const';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {IClientSaleMappedItem} from '@app/logic/client-sale/interfaces/i.client-sale.mapped';
import {IClientSaleDto} from '@app/logic/client-sale';

@Component({
    selector: 'cb-client-sale-view',
    templateUrl: './client-sale-view.component.html',
    styleUrls: ['./client-sale-view.component.scss'],
    providers: [
        ClientSaleDocumentsService
    ]
})
export class ClientSaleViewComponent implements OnInit, OnDestroy {

    public clientSale: IClientSaleMappedItem;
    public systemArea = SYSTEM_AREA_ENUM.ClientSale;
    public readonly TABS_ROUTE = MYCLIENTSALES_TAB_FULL_ROUTE;

    @Input() public readonly showTitle = false;

    private _clientSaleId: number;
    @Input() public set clientSaleId(v: number) {
        this._clientSaleId = Number(v);
        if (this._clientSaleId > 0) {
            this.clientSaleLogic.$getMappedItem(this._clientSaleId)
                .subOnce((x: IClientSaleMappedItem) => {
                    this.clientSale = x;
                    this.clientSaleDocumentsService.init(this.clientSale);

                    this.clientSaleSub$.unsubscribe();
                    this.clientSaleSub$ = this.clientSale.$updated.subscribe(() => this.clientSaleUpdated.emit(this.clientSale.$getMappedDtoItem()));
                });
        }
    }
    public get clientSaleId(): number {
        return this._clientSaleId;
    }

    @Output() public readonly clientSaleUpdated = new EventEmitter<IClientSaleDto>();

    private documentSub$: Subscription;
    private clientSaleSub$ = new Subscription();

    constructor(
        public readonly clientSaleLogic: ClientSaleLogicService,
        public readonly permissions: ClientSalePermissions,
        public readonly route: ActivatedRoute,
        private readonly documentService: DocumentService,
        public readonly clientSaleDocumentsService: ClientSaleDocumentsService,
        private readonly navigationService: NavigationService
    ) {
    }

    public ngOnInit(): void {
        this.documentSub$ = this.documentService.any$
            .subscribe((entity) => {
                const codeName = entity?.documentType?.codeName;
                if (codeName === DOCUMENT_TYPE_CODES_CONST.SIGNED_CREATE_SCHEDULE
                    || codeName === DOCUMENT_TYPE_CODES_CONST.SIGNED_SALE_AND_PURCHASE
                    || codeName === DOCUMENT_TYPE_CODES_CONST.SIGNED_SALE_AND_PURCHASE_INFO
                    || codeName === DOCUMENT_TYPE_CODES_CONST.SIGNED_BUILD_CONTRACT_DOCUMENT
                ) {
                    this.reloadClientSale();
                }
            });
    }

    private reloadClientSale(): void {
        this.clientSale.$reload()
            .subOnce();
    }

    public ngOnDestroy(): void {
        this.documentSub$.unsubscribe();
        this.clientSaleSub$.unsubscribe();
    }

    public viewLot(): void {
        this.navigationService.navigate([`lots/${this.clientSale.lot.id}/summary`]);
    }
}
