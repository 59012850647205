export enum LotBuildPermissionEnum {
    None = 0x00,
    CanGenerateBuildProgramme = 0x01 << 0,
    CanSetQSApproval = 0x01 << 1,
    CanSetConstructionManager = 0x01 << 2,
    CanGenerateBuildProgrammeDocument = 0x01 << 3,
    CanGenerateSSR = 0x01 << 4,
    CanEditSSR = 0x01 << 5,
    CanCancelSSR = 0x01 << 6,
    CanSplitSSR = 0x01 << 7,
    CanAbandonSSR = 0x01 << 8,
    CanConfirmSSR = 0x01 << 9,
    CanCompleteSSR = 0x01 << 10,
    CanEditActivity = 0x01 << 11,
    CanSetSupplier = 0x01 << 12,
    CanViewGantt = 0x01 << 13,
    CanManageGantt = 0x01 << 14,
    CanViewOrdersTab = 0x01 << 15,
    CanManageManualOrder = 0x01 << 17,
    CanSubmitManualOrder = 0x01 << 18,
    CanApproveManualOrder = 0x01 << 19,
    CanDownloadSSR = 0x01 << 20,
    CanDownloadPO = 0x01 << 21,
    DocumentsCanView = 0x01 << 22,
    DocumentsCanUpload = 0x01 << 23,
    DocumentsCanReview = 0x01 << 24,
    DocumentsCanDelete = 0x01 << 25,
    DocumentsCanEdit = 0x01 << 26,
}
