<div class="flex-row"
     [hidden]="loading">
    <div class="flex-col"
         *ngIf="documentGroups.length > 1">
        <mat-nav-list style="width: 250px; margin-right: 25px;">
            <mat-list-item (click)="this.applyFilter(undefined)"
                           [disableRipple]="true"
                           [ngClass]="!this.selectedDocumentGroup ? 'isActive' : 'isNotActive'">
                {{ "All Documents" | uppercase }} ({{ this.getAllDocumentsLength() }})
            </mat-list-item>
            <mat-list-item [disableRipple]="true"
                           *ngFor="let documentgroup of documentGroups | cbSortByPipe: 'name': 'asc'"
                           (click)="this.applyFilter(documentgroup)"
                           [ngClass]="this.selectedDocumentGroup && documentgroup.name === this.selectedDocumentGroup.name ? 'isActive' : 'isNotActive'">
                {{ documentgroup.name | uppercase }} ({{ this.getGroupLength(documentgroup.id) }})
            </mat-list-item>
        </mat-nav-list>
    </div>
    <div class="flex-col flex">
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="dataSource"
                       matSort
                       [matSortActive]="COLUMN_NAMES.DateUploaded"
                       matSortDirection="desc">

                <!-- Checkbox Column -->
                <ng-container [matColumnDef]="COLUMN_NAMES.Checkbox"
                              sticky>
                    <mat-header-cell *matHeaderCellDef
                                     class="select-actions-col">
                        <button mat-icon-button
                                [matMenuTriggerFor]="checkedMenu"
                                [matTooltip]="documentActionsTooltip()"
                                class="checkbox-menu"
                                (click)="$event.stopPropagation()"
                                [disabled]="selectedDocuments?.length < 1">
                            <mat-icon>playlist_add_check</mat-icon>
                        </button>
                        <mat-menu #checkedMenu="matMenu">
                            <button mat-menu-item
                                    (click)="toggleAllChecked($event)">
                                <mat-icon>done_all</mat-icon>
                                <span>&nbsp;{{toggleAllLabel()}}</span>
                            </button>
                            <button mat-menu-item
                                    (click)="downloadAllChecked()">
                                <mat-icon>file_download</mat-icon>
                                <span>&nbsp;{{downloadAllLabel()}}</span>
                            </button>
                        </mat-menu>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"
                              class="select-actions-col">
                        <div class="center-center flex-fill">
                            <mat-checkbox [disabled]="!element?.document?.id || element?.document?.isDeleted"
                                          [matTooltip]="(!element?.document?.id || element?.document?.isDeleted) ? 'Document Upload Required' : ''"
                                          [(ngModel)]="checkedDocumentIds[element.id]"
                                          [ngModelOptions]="{ standalone: true}">
                            </mat-checkbox>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Prefix Column -->
                <ng-container *ngFor="let extraColumn of prefixColumns"
                              [cdkColumnDef]="extraColumn.col">
                    <mat-header-cell *cdkHeaderCellDef>{{ extraColumn.label }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let element">
                        <dl>
                            <dd class="mat-body-1"
                                *ngFor="let prop of extraColumn.props">{{element[prop]}}</dd>
                        </dl>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container [matColumnDef]="COLUMN_NAMES.Name">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>
                        Name
                        <cb-sortable-header-cell [sort]="this.sort"
                                                 [cell]="COLUMN_NAMES.Name"></cb-sortable-header-cell>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <cb-info-popup *ngIf="isLinkedDocument(element)"
                                       [icon]="'linked'"
                                       [tooltip]="'This is a Linked Document'">
                        </cb-info-popup>
                        <cb-info-popup *ngIf="element.document"
                                       [label]="element.document.name"
                                       [tooltip]="element.document.description">
                        </cb-info-popup>
                    </mat-cell>
                </ng-container>

                <!-- Type Column -->
                <ng-container [matColumnDef]="COLUMN_NAMES.FileType">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>
                        Type
                        <cb-sortable-header-cell [sort]="this.sort"
                                                 [cell]="COLUMN_NAMES.FileType"></cb-sortable-header-cell>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <cb-info-popup [label]="element.documentType.label"
                                       [tooltip]="element.documentType.description">
                        </cb-info-popup>
                    </mat-cell>
                </ng-container>

                <!-- Renewal Column -->
                <ng-container *ngIf="!hideRenewalColumn"
                              [matColumnDef]="COLUMN_NAMES.Renewal">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>
                        Renewal
                        <cb-sortable-header-cell [sort]="this.sort"
                                                 [cell]="COLUMN_NAMES.Renewal"></cb-sortable-header-cell>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span>
                            {{ element.document && element.document.renewalDate
                            ? (element.document.renewalDate | date: "dd MMM yyyy")
                            : "N/A" }}
                        </span>
                    </mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container [matColumnDef]="COLUMN_NAMES.Status">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>
                        Status
                        <cb-sortable-header-cell [sort]="this.sort"
                                                 [cell]="COLUMN_NAMES.Status"></cb-sortable-header-cell>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.documentStatus | cbEnumToLabel: DOCUMENT_STATUS_ENUM }}
                    </mat-cell>
                </ng-container>

                <!-- Date Uploaded -->
                <ng-container [matColumnDef]="COLUMN_NAMES.DateUploaded">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>
                        Date Uploaded
                        <cb-sortable-header-cell [sort]="this.sort"
                                                 [cell]="COLUMN_NAMES.DateUploaded"></cb-sortable-header-cell>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element?.document?.updatedDate | date:'dd MMM yyyy hh:mm:ss a' | cbDasher }}
                    </mat-cell>
                </ng-container>

                <!-- Reviews Column -->
                <ng-container [matColumnDef]="COLUMN_NAMES.Reviews">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>
                        Reviews
                        <cb-sortable-header-cell [sort]="this.sort"
                                                 [cell]="COLUMN_NAMES.Reviews"></cb-sortable-header-cell>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span *ngIf="element.document">
                            <cb-info-popup *ngFor="let approvalStatus of element.document.approvalStatuses"
                                           [label]="approvalStatus.needsApprovalByTag"
                                           [tooltip]="this.getReviewComment(approvalStatus)"
                                           [icon]="this.getReviewIcon(approvalStatus)">
                            </cb-info-popup>
                        </span>
                    </mat-cell>
                </ng-container>

                <!-- Actions Column -->
                <ng-container [matColumnDef]="COLUMN_NAMES.Actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf="!this.isRowDisabled(element)">
                            <button [matMenuTriggerFor]="menu"
                                    mat-icon-button
                                    matTooltip="Edit"
                                    matTooltipPosition="right"
                                    [disabled]="false"
                                    *ngIf="!this.isActionMenuHidden(element)">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item
                                        (click)="this.viewDocumentDetails(element)">
                                    <mat-icon>help_outline</mat-icon>
                                    <span> View Details</span>
                                </button>
                                <button mat-menu-item
                                        *ngIf="canViewHistory(element)"
                                        (click)="this.viewDocumentHistory(element)">
                                    <mat-icon>history</mat-icon>
                                    <span> View History</span>
                                </button>
                                <button mat-menu-item
                                        (click)="this.downloadDocument(element)">
                                    <mat-icon>file_download</mat-icon>
                                    <span>Download</span>
                                </button>
                                <button mat-menu-item
                                        *ngIf="this.canEdit(element)"
                                        (click)="this.editDocumentDetails(element)">
                                    <mat-icon>mode_edit</mat-icon>
                                    <span>Edit Details</span>
                                </button>
                                <button mat-menu-item
                                        *ngIf="this.canReview(element)"
                                        (click)="this.reviewDocument(element)">
                                    <mat-icon>rate_review</mat-icon>
                                    <span>Review Document</span>
                                </button>
                                <button mat-menu-item
                                        *ngIf="this.canUpload(element)"
                                        (click)="this.uploadDocument(element)">
                                    <mat-icon>file_upload</mat-icon>
                                    <span>Upload</span>
                                </button>
                                <button mat-menu-item
                                        *ngIf="this.canDelete$(element) | async"
                                        (click)="this.deleteDocument(element)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete File</span>
                                </button>
                            </mat-menu>
                        </div>
                        <button *ngIf="this.isUploadOnly(element)"
                                mat-icon-button
                                matTooltip="Upload"
                                matTooltipPosition="right"
                                (click)="this.uploadDocument(element)">
                            <mat-icon>file_upload</mat-icon>
                        </button>
                        <button *ngIf="this.canDeleteStub$(element) | async"
                                mat-icon-button
                                matTooltip="Delete stub"
                                matTooltipPosition="right"
                                (click)="this.deleteStub(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button *ngIf="this.isLinkedDocument(element)"
                                mat-icon-button
                                matTooltip="Upload"
                                matTooltipPosition="right"
                                (click)="this.downloadDocument(element)">
                            <mat-icon>file_download</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row [class.table-disabled]="this.isRowDisabled(row)"
                         [class.highlight]="row.id == this.documentId"
                         *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
        <cb-info-message message="There are no Documents found"
                         *ngIf="!this.loading && selectedDocuments.length < 1">
        </cb-info-message>
    </div>
</div>
<cb-loading-spinner *ngIf="this.loading"></cb-loading-spinner>
