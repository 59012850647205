import { Component} from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { PaymentFrequenciesLogicService } from '@app/logic/payment-frequencies/payment-frequencies.logic.service';
import { IPaymentFrequencyMappedItem } from '@app/logic/payment-frequencies/interfaces/i.payment-frequency.mapped';
import { PaymentFrequencyDialogComponent } from './payment-frequency-dialog/payment-frequency-dialog.component';
import { PaymentFrequencyMappedItem } from '@app/logic/payment-frequencies/payment-frequency.mapped';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { IPaymentFrequencyDto } from '@app/logic/payment-frequencies/interfaces/i.payment-frequency.dto';
import { PAYMENT_FREQUENCY_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-payment-frequencies',
    templateUrl: './payment-frequencies.component.html',
    styleUrls: ['./payment-frequencies.component.scss'],
    providers: [PaymentFrequenciesLogicService]
})
export class PaymentFrequenciesComponent extends BaseSimpleListViewDirective<IPaymentFrequencyDto, PaymentFrequenciesLogicService> {

    public searchResults: IPaymentFrequencyMappedItem[] = [];
    public searchResultsLoaded = false;

    public readonly displayedColumns: string[] = ['name', 'actions'];

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        private readonly payemntFrequenciesLogicService: PaymentFrequenciesLogicService,
    ) {
        super(
            dialog,
            payemntFrequenciesLogicService,
            PaymentFrequencyDialogComponent,
            'Payment Frequency',
            PaymentFrequencyMappedItem,
            permissionsPermissions
        );
    }

    public getLabelForEnum(id: number): string {
        return PAYMENT_FREQUENCY_ENUM[id];
    }
}
