import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LotPermissions } from '@app/core/permissions';
import { LotSpecScheduleItemLogicService } from '@app/logic/lot-spec-schedule-item';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { ILotSpecColourItemDto, ILotSpecItemDto, ILotSpecScheduleItemDto, ISpecGroupDto } from '@classictechsolutions/hubapi-transpiled-enums';
import * as _ from 'lodash';
import { remove } from 'lodash';
import { of, Subscription } from 'rxjs';
import { EditLotSpecColourItemDialogComponent } from '../edit-lot-spec-colour-item-dialog/edit-lot-spec-colour-item-dialog.component';
import { EditLotSpecItemDialogComponent } from '../edit-lot-spec-item-dialog/edit-lot-spec-item-dialog.component';
import { LotSpecScheduleItemDialogComponent } from '../lot-spec-schedule-item-dialog/lot-spec-schedule-item-dialog.component';
import { ManageLotSpecColourItemDialogComponent } from '../manage-lot-spec-colour-item/manage-lot-spec-colour-item.component';
import { ManageLotSpecItemAttributesDialogComponent } from '../manage-lot-spec-item-attributes-dialog/manage-lot-spec-item-attributes-dialog.component';
import { ManageLotSpecScheduleItemAttributesDialogComponent } from '../manage-lot-spec-schedule-item-attributes-dialog/manage-lot-spec-schedule-item-attributes-dialog.component';
import { LotSpecificationService } from '../services/lot-specification.service';
import {
    ManageLotSpecCreateScheduleItemDialogComponent
} from "@app/views/lot/specification/manage-lot-spec-create-schedule-item/manage-lot-spec-create-schedule-item-dialog.component";

@Component({
    selector: 'cb-lot-spec-actions-menu',
    templateUrl: './lot-spec-actions-menu.component.html',
    styleUrls: ['./lot-spec-actions-menu.component.scss']
})
export class LotSpecActionsMenuComponent {

    @Input() public readonly lotMappedItem: ILotMappedItem;

    @Input() public readonly lotSpec: ILotSpecMappedItem;
    @Output() public readonly lotSpecChange = new EventEmitter<ILotSpecMappedItem>();

    @Input() public readonly houseAreas: ISpecGroupDto[];
    @Output() public readonly lotScheduleItemAdded = new EventEmitter<ILotSpecScheduleItemDto>();

    private subscriptions$ = new Subscription();

    constructor(
        private readonly lotPermissions: LotPermissions,
        private readonly cbDialog: CbDialogService,
        private readonly lotSpecScheduleItemLogicService: LotSpecScheduleItemLogicService,
        private readonly lotSpecificationService: LotSpecificationService
    ) { }

    public ngOnInit(): void {
        this.subscriptions$.add(

            this.lotSpecificationService.lotScheduleParentItemRemoved.subscribe((itemId) => {

                const itemIndex = this.lotSpec?.lotSpecScheduleItems?.findIndex(item => item.id === itemId);

                if (itemIndex > -1) {
                    remove(this.lotSpec?.lotSpecScheduleItems, { id: itemId });
                }

            })
        );

        this.subscriptions$.add(

            this.lotSpecificationService.lotScheduleChildItemRemoved.subscribe(({ parentId, childId }) => {

                const parentIndex = this.lotSpec?.lotSpecScheduleItems?.findIndex(item => item.id === parentId);

                if (parentIndex > -1) {

                    const childIndex = this.lotSpec?.lotSpecScheduleItems[parentIndex].childItems.findIndex(item => item.id === childId);

                    if (childIndex > -1) {
                        remove(this.lotSpec?.lotSpecScheduleItems[parentIndex].childItems, { id: childId });
                    }
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    public addLotSpecItem(): void {

        // New Lot Specification Item Dialog
        if (this.lotSpec && this.lotSpec?.lotSpecScheduleItems?.length > 0) {

            const listOfItems = this.lotSpec?.lotSpecScheduleItems
                .map(item => ({
                    slotId: item.slotId, specGroupId: item.specGroupId, childItems: item.childItems
                        .map(c => ({ slotId: c.slotId, specGroupId: c.specGroupId }))
                }));

            // Get flat list of all parent items
            const parentItems = listOfItems.flatMap(x => ({ slotId: x.slotId, specGroupId: x.specGroupId }));

            // Get flat list of all child items
            const childItems = listOfItems.flatMap(x => x.childItems);

            const parentsAndChildsList = parentItems.concat(childItems);

            const slotIdListByGroupId = _.mapValues(_.groupBy(parentsAndChildsList, 'specGroupId'),
                specGroupList => specGroupList.map(item => item.slotId));

            this.cbDialog.open(
                LotSpecScheduleItemDialogComponent,
                {
                    data:
                    {
                        mappedItem: this.lotSpecScheduleItemLogicService.$createMappedItem({ quantity: 1 }),
                        houseAreas: this.houseAreas,
                        lotId: this.lotSpec?.lotId,
                        formMode: FormMode.Add,
                        slotIdListByGroupId,
                        isLotSpecLocked$: of(this.lotSpec.isLocked)
                    },
                }
            )
                .afterClosed()
                .subOnce((result: ILotSpecScheduleItemDto | false) => {
                    if (result) {
                        this.lotSpecificationService.lotScheduleItemAdded.next(result);
                        this.lotSpec.lotSpecScheduleItems.push(result);
                    }
                });
        }

        // Old Lot Spec Item Dialog
        else {
            this.cbDialog
                .open(
                    EditLotSpecItemDialogComponent,
                    {
                        minWidth: '40%',
                        data: {
                            lotSpec: this.lotSpec,
                            removeOwnersCareCostType: !this.lotMappedItem.hasClientSale,
                        }
                    }
                )
                .afterClosed()
                .subOnce((result: ILotSpecItemDto | false) => {
                    if (result) {
                        this.lotSpec.items.push(result);
                        this.lotSpec.$recomputeProps();
                    }
                });
        }
    }

    public addLotSpecColourItem(): void {
        this.cbDialog
            .open(
                EditLotSpecColourItemDialogComponent,
                {
                    minWidth: '40%',
                    data: {
                        lotSpec: this.lotSpec,
                    }
                }
            )
            .afterClosed()
            .subOnce((result: ILotSpecColourItemDto | false) => {
                if (result) {
                    this.lotSpec.colourItems.push(result);
                    this.lotSpec.$recomputeProps();
                }
            });
    }

    public canClearLotSpec = (): boolean => {
        return this.lotSpec?.appliedTemplateId != null
            && this.lotSpec?.isLocked === false;
    };

    public clearLotSpec(): void {
        if (this.canClearLotSpec()) {
            this.cbDialog.confirm({
                dialogHeading: 'Confirm Clear Lot Specification',
                message: 'Are you sure you want to clear ALL Items from this Lot Specification?',
                confirmed: () => {
                    this.lotSpec
                        .clear(this.lotSpec.lotSpecScheduleItems?.length > 0)
                        .subOnce((result) => {
                            result.appliedTemplateId = null;
                            this.lotSpec.$updateThisData(result);
                        });
                }
            });
        }
    }

    public manageLotSpecScheduleItemsWithAttributes(): void {
        this.cbDialog
            .open(
                ManageLotSpecScheduleItemAttributesDialogComponent,
                {
                    minWidth: '70%',
                    data: {
                        lotId: this.lotSpec.lotId,
                        specVersion: this.lotSpec.specVersion,
                        specId: this.lotSpec.id,
                    }
                }
            )
            .afterClosed()
            .subOnce((result: boolean) => {
                if (result) {
                    // this.lotSpecScheduleLogicService.$getItem().subOnce(result => this.lotSpec = result);
                }
            });
    }

    public manageLotSpecItemsWithAttributes(): void {
        this.cbDialog
            .open(
                ManageLotSpecItemAttributesDialogComponent,
                {
                    minWidth: '70%',
                    data: {
                        lotId: this.lotSpec.lotId,
                        specVersion: this.lotSpec.specVersion,
                        specId: this.lotSpec.id,
                    }
                }
            )
            .afterClosed()
            .subOnce((result: boolean) => {
                if (result) {
                    this.lotSpec.$reload().subOnce();
                }
            });
    }

    public openManageAttributesDialog(): void {
        if (this.lotSpec?.items?.length > 0) {
            this.manageLotSpecItemsWithAttributes();
        }

        if (this.lotSpec?.lotSpecScheduleItems?.length > 0) {
            this.manageLotSpecScheduleItemsWithAttributes();
        }
    }

    public manageLotSpecHouseItems(): void {
        this.cbDialog
            .open(
                ManageLotSpecColourItemDialogComponent,
                {
                    data: {
                        lotId: this.lotSpec.lotId,
                        specVersion: this.lotSpec.specVersion,
                        isLotSpecScheduleItem: this.lotSpec.lotSpecScheduleItems?.length > 0
                    },
                    fullWidth: true,
                    minWidth: '70%',
                }
            )
            .afterClosed()
            .subOnce((result) => {
                if (result?.sortOrderChanged) {
                    this.lotSpec.$reload().subOnce();
                }
            });
    }

    public manageCreateScheduleLotSpecHouseItems(): void {
        this.cbDialog
            .open(
                ManageLotSpecCreateScheduleItemDialogComponent,
                {
                    data: {
                        lotId: this.lotSpec.lotId,
                        specVersion: this.lotSpec.specVersion,
                        isLotSpecScheduleItem: this.lotSpec.lotSpecScheduleItems?.length > 0
                    },
                    fullWidth: true,
                    minWidth: '70%',
                }
            )
            .afterClosed()
            .subOnce((result) => {
                if (result) {
                    this.lotSpecChange.emit();
                }
            });
    }

    public canChangeSpecification(): boolean {
        return this.lotPermissions.canChangeSpecification();
    }

    public canSetAttributeValue(): boolean {
        return this.lotPermissions.canSetSpecificationAttributeValue();
    }

    public isSIMSchedule(): boolean {
        return this.lotPermissions.canSetSpecificationAttributeValue() && this.lotSpec?.lotSpecScheduleItems?.length > 0;
    }

    public disableExpandAllAndFilterAndManageColourItems(): boolean {
        return this.lotSpec?.items?.length < 1
            && this.lotSpec?.lotSpecScheduleItems?.length < 1;
    }
}
