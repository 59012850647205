import { Component, Input } from '@angular/core';

@Component({
    selector: 'cb-item-usage-summary-card',
    templateUrl: './item-usage-summary-card.component.html',
    styleUrls: ['./item-usage-summary-card.component.scss']
})
export class ItemUsageSummaryCardComponent {
    @Input() public label: string;
    @Input() public value: string;
    constructor() {}
}
