import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { of, Subscription } from 'rxjs';

@Component({
    selector: 'cb-all-teams-area',
    templateUrl: './all-teams-area.component.html',
    styleUrls: ['./all-teams-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllTeamsAreaComponent implements OnDestroy {

    public readonly ALL_TEAMS_ROUTE = 'teams/all/:cbTabRouteId';
    public selectedTabIndex: number;
    private _subscriptions = new Subscription();
    public TEAM_CODES_CONST = TEAM_CODES_CONST;

    constructor(
        private readonly navigationService: NavigationService,
        private route: ActivatedRoute,
    ) {
    }

    public resultDirections$ = of([
        { id: 'asc', label: 'Ascending' },
        { id: 'desc', label: 'Descending' },
    ]);

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public onTabChanged(selectedTabIndex: number): void {
        this.selectedTabIndex = selectedTabIndex;
    }
}
