import { Injectable, Injector } from '@angular/core';
import { IPermissionGroupAreaDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IPermissionGroupAreaMappedItem } from './interfaces/i.permission-group-area.mapped';
import { IPermissionGroupAreaLogicService } from './interfaces/i.permission-group-area.logic.service';
import { PermissionGroupAreaMappedItem } from './permission-group-area.mapped';

@Injectable()
export class PermissionGroupAreaLogicService
    extends BaseLogicService<IPermissionGroupAreaDto, IPermissionGroupAreaMappedItem>
    implements IPermissionGroupAreaLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('auth/roles', PermissionGroupAreaMappedItem);
    }
}
