import { USER_TAG_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';

export const approverTags = [
    USER_TAG_CONSTANTS_CONST.BRANCH_MANAGER,
    USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT,
    USER_TAG_CONSTANTS_CONST.BUILDING_COORDINATOR,
    USER_TAG_CONSTANTS_CONST.CONTRACT_MANAGER,
    USER_TAG_CONSTANTS_CONST.HOUSE_AND_LAND_COORDINATOR,
    USER_TAG_CONSTANTS_CONST.REGIONAL_BUILDINGCOORDONATOR,
    USER_TAG_CONSTANTS_CONST.REGIONAL_MANAGER
];
