<cb-dialog dialogHeading="Edit Consent Number">
    <form class="flex-col flex"
          #editConsentForm="ngForm">
        <cb-input type="text"
                  label="Consent Number"
                  name="consentNumber"
                  [(ngModel)]="mappedItem.consentNumber"
                  [softRequired]="true"></cb-input>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="editConsentForm.invalid || editConsentForm.pristine"
                (cbClick)="save()"
                [clickLimit]="1">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
