<div class="flex-row flex-fill"
     *cbWaitFor="mappedItem">
    <mat-tab-group class="flex"
                   [(selectedIndex)]="selectedTabIndex"
                   [cbTabRoute]="RELEASE_ROUTE">
        <mat-tab label="Details"
                 cbTabRouteId="details">
            <form #releaseEditForm="ngForm">
                <cb-manage-project-release-stage-details *cbWaitFor="mappedItem"
                                                         [mappedItem]="mappedItem">
                </cb-manage-project-release-stage-details>
            </form>
        </mat-tab>
        <mat-tab label="Lots"
                 cbTabRouteId="lots">
            <ng-template matTabContent>
                <cb-manage-project-release-stage-lots *cbWaitFor="mappedItem"
                                                      [mappedItem]="mappedItem"
                                                      [selectedTabIndex]="lotsSelectedTabIndex">
                </cb-manage-project-release-stage-lots>
            </ng-template>
        </mat-tab>
        <mat-tab label="Default Installers"
                 cbTabRouteId="default-installers"
                 *ngIf="canViewReleaseDefaultInstallers">
            <ng-template matTabContent>
                <cb-release-default-installer *cbWaitFor="mappedItem"
                                              [mappedItem]="mappedItem">
                </cb-release-default-installer>
            </ng-template>
        </mat-tab>
        <mat-tab label="Default Suppliers"
                 cbTabRouteId="default-suppliers"
                 *ngIf="isSupplierTypesEnabled && canViewReleaseDefaultSuppliers">
            <ng-template matTabContent>
                <cb-release-default-supplier *cbWaitFor="mappedItem"
                                              [mappedItem]="mappedItem">
                </cb-release-default-supplier>
            </ng-template>
        </mat-tab>
        <mat-tab label="Tasks"
                 cbTabRouteId="tasks"
                 *ngIf="canViewReleaseTasks">
            <ng-template matTabContent>
                <cb-tasks class="flex-col"
                          *cbWaitFor="mappedItem"
                          [entity]="mappedItem"
                          [edit]="true"
                          [permissions]="taskPermissions">
                </cb-tasks>
            </ng-template>
        </mat-tab>
        <mat-tab label="Notes"
                 cbTabRouteId="notes"
                 *ngIf="canViewReleaseNotes">
            <ng-template matTabContent>
                <cb-notes-new *cbWaitFor="mappedItem"
                              [entity]="mappedItem"
                              [edit]="true"
                              [permissions]="notePermissions">
                </cb-notes-new>
            </ng-template>
        </mat-tab>
        <mat-tab label="Documents"
                 cbTabRouteId="documents"
                 *ngIf="canViewReleaseDocuments">
            <ng-template matTabContent>
                <cb-document *cbWaitFor="mappedItem"
                             [entity]="mappedItem"
                             [permissions]="documentPermissions"
                             [includeLinked]="true"
                             [systemArea]="systemArea">
                </cb-document>
            </ng-template>
        </mat-tab>
        <mat-tab label="History"
                 cbTabRouteId="history"
                 *ngIf="canViewReleaseHistory">
            <ng-template matTabContent>
                <cb-history-list *cbWaitFor="mappedItem"
                                 [entityId]="mappedItem?.id"
                                 entity="projectreleasestage">
                </cb-history-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <cb-manage-project-release-stage-side-bar-card *ngIf="mappedItem"
                                                   [mappedItem]="mappedItem"
                                                   class="cb-margin">
    </cb-manage-project-release-stage-side-bar-card>
</div>
