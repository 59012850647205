import { Prop } from '@app/shared/decorators/prop.decorator';
import { LotContractTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { exportDto } from '@app/shared/logic-utils/export-dto.util';
import { ILeadSearchDto } from '../interfaces/i.lead-search.dto';

class LeadSearchDto implements ILeadSearchDto {
    @Prop public id: number;
    @Prop public name: string;
    @Prop public status: string;
    @Prop public buildingConsultant: string;
    @Prop public buildingConsultantId: string;
    @Prop public callbackDate: DateString;
    @Prop public rawCallbackDate: string;
    @Prop public qualifiedDate: DateString;
    @Prop public businessEntitities: number[];
    @Prop public contractType: LotContractTypeEnumId;
    @Prop public updatedDate: DateString;
    @Prop public createdDate: DateString;
}

export const leadSearchDto = exportDto<ILeadSearchDto>(LeadSearchDto);
