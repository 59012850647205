
import { Component, OnInit, Inject, ViewChildren } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IPurchaseOrderDto } from '@app/logic/purchase-orders/interfaces/i.purchase-order.dto';
import { IPurchaseOrderMappedItem } from '@app/logic/purchase-orders';
import { IPurchaseOrdersLogicService } from '@app/logic/purchase-orders/interfaces/i.purchase-orders.logic.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { NgForm } from '@angular/forms';

interface IData {
    variations: any;
    mappedPurchaseOrder: IPurchaseOrderMappedItem;
}


@Component({
    selector: 'cb-select-variation',
    templateUrl: './select-variation.component.html',
    styleUrls: ['./select-variation.component.scss']
})
export class SelectVariationDialogComponent
    extends BaseDialogFormViewDirective<IPurchaseOrderDto, IPurchaseOrderMappedItem, IPurchaseOrdersLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '33%';

    public get selectVariationForm(): NgForm {
        return this.selectVariationFormParent.first;
    }
    @ViewChildren('selectVariation') public selectVariationFormParent;

    public get hasVariations(): boolean {
        return this.data?.variations?.length > 0;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<SelectVariationDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) {
        super(dialogRef, toastService);
    }

    public removeVariation(): void {
        if (this.data.mappedPurchaseOrder.variationId) {
            this.data.mappedPurchaseOrder.variationId = undefined;

            this.selectVariationForm.form.markAsDirty();
        }
    }

    public save(): void {
        if (this.data.mappedPurchaseOrder.variationId) {
            this.data.mappedPurchaseOrder.assignVariation().subOnce((response) => {
                this.dialogRef.close(response);
            });
        } else {
            this.data.mappedPurchaseOrder.unassignVariation().subOnce((response) => {
                this.dialogRef.close(response);
            });
        }
    }

}
