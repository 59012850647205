import { Injectable, Injector } from '@angular/core';
import { IDesignTeamImageRequestSearch } from '@app/core/services/user-cache/user-cache-areas';
import { IDesignConceptDocumentDto, IDesignConceptDto, IRequestImagesByLotIdsDto, LotImageTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { DesignConceptMappedItem } from './design-concept.mapped';
import { IDesignConceptMappedItem } from './interfaces/i.design-concept.mapped';
import { IDesignConceptsLogicService } from './interfaces/i.design-concepts-logic.service';

@Injectable()
export class DesignConceptsLogicService
    extends BaseLogicServiceWithSearch<
    IDesignConceptDto,
    IDesignConceptMappedItem,
    IDesignTeamImageRequestSearch,
    IDesignConceptDocumentDto>
    implements IDesignConceptsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('designconcepts', DesignConceptMappedItem);
    }

    public getByLot(lotId: number): Observable<IDesignConceptMappedItem[]> {
        return this.$http.get<IDesignConceptDto[]>(`${this.$baseUri}/lot/${lotId}`)
            .pipe(map(dtos => dtos.map(dto => this.$createMappedItem(dto))));
    }

    public getApplicableByLot(lotId: number): Observable<IDesignConceptMappedItem[]> {
        return this.$http.get<IDesignConceptDto[]>(`${this.$baseUri}/lot/${lotId}/applicable`)
            .pipe(map(dtos => dtos.map(dto => this.$createMappedItem(dto))));
    }

    public applyToLot(conceptId: number): Observable<IDesignConceptDto> {
        return this.$http.post<IDesignConceptDto>(`${this.$baseUri}/${conceptId}/apply`, null);
    }

    public requestImagesByLotIds(payload: IRequestImagesByLotIdsDto): Observable<IDesignConceptDto[]> {
        return this.$http.post<IDesignConceptDto[]>(`${this.$baseUri}/requestimagesbylotids`, payload);
    }

    public requestExteriorImage(conceptId: number): Observable<IDesignConceptDto> {
        return this.$http.post<IDesignConceptDto>(`${this.$baseUri}/${conceptId}/requestexteriorimage`);
    }

    public requestFloorPlanImage(conceptId: number): Observable<IDesignConceptDto> {
        return this.$http.post<IDesignConceptDto>(`${this.$baseUri}/${conceptId}/requestfloorplanimage`);
    }

    public abandonConcept(conceptId: number): Observable<IDesignConceptDto> {
        return this.$http.post<IDesignConceptDto>(`${this.$baseUri}/${conceptId}/abandon`);
    }

    public updateImage(conceptId: number, imageType: LotImageTypeEnumId, file: File,): Observable<IDesignConceptDto> {
        const fd = new FormData();
        fd.append('file', file);
        return this.$http.post(`${this.$baseUri}/${conceptId}/image/${imageType}`, fd);
    }

    public deleteImage(conceptId: number, imageId: string): Observable<IDesignConceptDto> {
        return this.$http.delete<IDesignConceptDto>(`${this.$baseUri}/${conceptId}/images/${imageId}`);
    }
}
