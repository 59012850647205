import { Component } from '@angular/core';

@Component({
    selector: 'cb-minor-components',
    templateUrl: './minor.components.html',
    styleUrls: ['./minor.components.scss']
})
export class MinorComponents {
    isLocked: boolean = false;
}
