import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { QSTeamPermissions } from '@app/core/permissions';
import { TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { of } from 'rxjs';

@Component({
    selector: 'cb-qs-team-area',
    templateUrl: './qs-team-area.component.html',
    styleUrls: ['./qs-team-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QsTeamAreaComponent {

    public readonly QS_TEAM_ROUTE = 'teams/qs/:cbTabRouteId';
    public selectedTabIndex: number;
    public TEAM_CODES_CONST = TEAM_CODES_CONST;

    constructor(
        public readonly qsTeamPermissions: QSTeamPermissions,
        private readonly cdRef: ChangeDetectorRef
    ) {
    }

    public resultDirections$ = of([
        { id: 'asc', label: 'Ascending' },
        { id: 'desc', label: 'Descending' },
    ]);

    public onTabChanged(selectedTabIndex: number): void {
        this.selectedTabIndex = selectedTabIndex;
        this.cdRef.detectChanges();
    }
}
