import {BasePermissions, IBaseSystemAreaPermissions} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HouseLandPermissionEnum} from '../../shared/enums/permissions';

export interface IHouseLandPermissions extends IBaseSystemAreaPermissions {
    canView(): boolean;
    canCreateSaleFromHouseAndLandSearch(): boolean;
}

@Injectable()
export class HouseLandPermissions extends BasePermissions<HouseLandPermissionEnum>
    implements IHouseLandPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('houseland', HouseLandPermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(HouseLandPermissionEnum.CanView);
    }
    public canEdit(): boolean {
        throw new Error('Method not implemented.');
    }
    public canCreate(): boolean {
        throw new Error('Method not implemented.');
    }
    public canCreateSaleFromHouseAndLandSearch(): boolean {
        return this.permissionObject(HouseLandPermissionEnum.CanCreateSaleFromHouseAndLandSearch);
    }
}
