import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast/toast.component';
import { ToastsComponent } from './toasts.component';
import { MatButtonModule } from '@angular/material/button';

const COMPONENTS = [ToastComponent, ToastsComponent];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatButtonModule
    ],
    exports: COMPONENTS,
})
export class ToastsModule { }
