import { ACCOUNT_TYPE_ENUM, CLIENT_ACCOUNT_STATUS_ENUM, IContactDto, SALUTATION_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, Input, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { IAddressDto } from '@app/shared/interfaces/i.address.dto';
import { IClientAccountMappedItem } from '@app/logic/client-account/interfaces/i.client-account.mapped';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { PermissionsPermissions } from '@app/core/permissions';

@Component({
    selector: 'cb-client-account-details',
    templateUrl: './client-account-details.component.html',
    styleUrls: ['./client-account-details.component.scss']
})
export class ClientAccountDetailsComponent implements OnInit {

    @Input() public account: IClientAccountMappedItem;
    @Input() public contacts: IContactDto[];

    public CLIENT_ACCOUNT_STATUS_ENUM = CLIENT_ACCOUNT_STATUS_ENUM;
    public ACCOUNT_TYPE_ENUM = ACCOUNT_TYPE_ENUM;
    public SALUTATION_ENUM = SALUTATION_ENUM;

    public postalAddress: IAddressDto;

    public readonly displayedColumns: string[] = [
        'isMainContact',
        'title',
        'name',
        'email',
        'phoneHome',
        'phoneWork',
        'phoneMobile',
        'address',
        'isStaffMember',
        'status',
        'actions'
    ];

    constructor(
        public readonly dialogRef: MatDialog,
        protected readonly clientAccountLogicService: ClientAccountLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly route: ActivatedRoute,
        public readonly navigation: NavigationService,) { }


    public ngOnInit(): void {
        if (this.account.postalAddress?.id) {
            this.postalAddress = this.account.postalAddress;
        } else {
            this.postalAddress = this.account.physicalAddress;
        }
    }

    public viewContact(id: string, event: MouseEvent): void {
        this.navigation.navigate([`contacts/${id}/summary`], event);
    }
}
