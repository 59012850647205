import {Component} from '@angular/core';

@Component({
    selector: 'cb-product-history-tab',
    templateUrl: './product-history-tab.component.html',
    styleUrls: ['./product-history-tab.component.scss']
})
export class ProductHistoryTabComponent {

    constructor() { }
}
