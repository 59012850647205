<cb-dialog dialogHeading="Lead Enquiry Origin">
    <form class="flex-col flex"
          #enquiryOriginForm="ngForm">
        <cb-select label="Enquiry Origin"
                   name="enquiryOrigin"
                   [(ngModel)]="mappedItem.enquiryOrigin"
                   [options]="enquiryOrigins"
                   [required]="true">
        </cb-select>
        <cb-checkbox label="Visited a Showhome"
                     name="visitedAShowHome"
                     [(ngModel)]="mappedItem.visitedAShowHome">
        </cb-checkbox>
        <cb-select label="Showhome"
                   *ngIf="mappedItem.visitedAShowHome"
                   name="showHomeId"
                   [(ngModel)]="mappedItem.showHomeId"
                   [options]="showHomes"
                   [required]="!mappedItem.otherShowHome"
                   (change)="showHomeChanged()">
        </cb-select>
        <cb-input type="text"
                  label="Other Showhome"
                  name="otherShowHome"
                  [(ngModel)]="mappedItem.otherShowHome"
                  *ngIf="mappedItem.visitedAShowHome && mappedItem.showHomeId == undefined">
        </cb-input>
        <cb-datepicker label="Enquiry Date"
                       name="enquiryDate"
                       [(ngModel)]="mappedItem.enquiryOriginOn"
                       pickerType="calendar"
                       [required]="true">
        </cb-datepicker>
        <span class="mat-body-2">Referrer Details</span>
        <div position="relative">
            <mat-divider></mat-divider>
        </div>
        <cb-contacts-input-find-cards label="Select Referrer"
                                      [(ngModel)]="referredByContact"
                                      name="selectedReferrer"
                                      [text]="getReferredByName()"
                                      [removeable]="true"
                                      #selectedReferrer="ngModel"
                                      (change)="referrerChanged()">
        </cb-contacts-input-find-cards>
        <ng-container *ngIf="!mappedItem.referredById">
            <cb-input type="text"
                      label="Referrer Name"
                      name="referredBy"
                      [(ngModel)]="mappedItem.referredBy"
                      [required]="mappedItem.referredByHomePhone || mappedItem.referredByMobilePhone">
            </cb-input>
            <cb-input type="text"
                      label="Referrer Mobile Phone"
                      name="referredByMobilePhone"
                      [(ngModel)]="mappedItem.referredByMobilePhone"
                      [required]="requirePhoneNumber(mappedItem.referredByHomePhone)"
                      maskType="MOBILE">
            </cb-input>
            <cb-input type="text"
                      label="Referrer Home Phone"
                      name="referredByHomePhone"
                      [(ngModel)]="mappedItem.referredByHomePhone"
                      [required]="requirePhoneNumber(mappedItem.referredByMobilePhone)"
                      maskType="LANDLINE">
            </cb-input>
        </ng-container>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="enquiryOriginForm.pristine || enquiryOriginForm.invalid"
                    (cbClick)="save()"
                    [clickLimit]="1">Save</button>
            <button mat-raised-button
                    (cbClick)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
