import { Injectable } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ICouncilRfiMappedItem, CouncilRfiLogicService } from '@app/logic/council-rfis';
import { Subject } from 'rxjs';

@Injectable()
export class CouncilRfiViewService {

    public councilRfi: ICouncilRfiMappedItem | null;

    public readonly councilRfiDeleted$ = new Subject<number>();
    public readonly reloadCounfilRfiList$ = new Subject<boolean>();
    public readonly councilRfiChanged$ = new Subject<boolean>();

    private _questionNumber: number | null;
    public get questionNumber(): number | null {
        return this._questionNumber;
    }
    public set questionNumber(v: number | null) {
        const qNumber = Number(v);
        if (qNumber) {
            this._questionNumber = qNumber;
        }
    }

    constructor(
        public readonly councilRfiLogic: CouncilRfiLogicService,
        private readonly navigationService: NavigationService
    ) { }

    public loadCouncilRfi(id: number, questionNumber: number = null): void {
        this.councilRfiLogic
            .$getMappedItem(id)
            .subOnce((x) => {
                this.questionNumber = questionNumber;
                this.councilRfi = x;
                this.councilRfiChanged$.next(true);
                this.navigationService.clearQueryParams();
            });
    }

    public backToList(): void {
        this.councilRfi = null;
        this.questionNumber = null;
        this.reloadCounfilRfiList$.next(true);
    }

    public councilRfiDeleted(id: number): void {
        this.councilRfiDeleted$.next(id);
        this.backToList();
    }
}
