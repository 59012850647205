<div class="flex-col">
    <mat-tab-group (selectedIndexChange)="onTabChanged($event);"
                   [selectedIndex]="selectedTabIndex"
                   cbTabUrlParam="cbTabRouteId2"
                   [cbTabRoute]="LOTS_ROUTE">
        <mat-tab label="Lot Specification"
                 cbTabRouteId="lotspec"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-spec-view *cbWaitFor="lotMappedItem$$ | async"
                                  [lotMappedItem]="lotMappedItem"
                                  [lotSpecVersion]="lotMappedItem.lotSpecVersion"
                                  [isSkinnyView]="false">
                </cb-lot-spec-view>
            </ng-template>
        </mat-tab>
        <mat-tab label="Quotes"
                 cbTabRouteId="quotes"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-quotes-list *cbWaitFor="lotMappedItem$$ | async"
                                    [lotMappedItem]="lotMappedItem">
                </cb-lot-quotes-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
