import { BaseMappedItem } from '../base/base-mapped-item';
import { IBundleTemplatesLogicService } from './interfaces/i.bundle-templates.logic.service';
import { IBundleTemplateMappedItem, IBundleTemplateItemMappedItem } from './interfaces/i.bundle-template.mapped';
import { IBaseLogicService } from '../base/interfaces/i.base-logic.service';
import { Observable } from 'rxjs';
import { DtoProp } from '../base/dto-prop.decorator';
import { IBundleTemplateDto, IBundleTemplateItemDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class BundleTemplateMappedItem
    extends BaseMappedItem<IBundleTemplateDto, IBundleTemplateMappedItem, IBundleTemplatesLogicService>
    implements IBundleTemplateMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public isActive: boolean;
    @DtoProp public bundleItems: any[];
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;

    constructor(
        sourceData: IBundleTemplateDto,
        logicService: IBundleTemplatesLogicService
    ) {
        super(sourceData, logicService, BundleTemplateMappedItem);
    }

    protected $preSave(toSave: IBundleTemplateDto): void {
        toSave.bundleItems = toSave.bundleItems.filter(x => x.name);
    }
}

export class BundleTemplateItemMappedItem
    extends BaseMappedItem<IBundleTemplateItemDto, IBundleTemplateItemMappedItem, IBaseLogicService<IBundleTemplateItemDto, IBundleTemplateItemMappedItem>>
    implements IBundleTemplateItemMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public isRequired: boolean;
    @DtoProp public isLocked: boolean;
    @DtoProp public categoryId: number;
    @DtoProp public categoryPath: string;
    @DtoProp public bundleTemplateId: number;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;

    constructor(
        sourceData: IBundleTemplateItemDto,
        logicService: IBaseLogicService<IBundleTemplateItemDto, IBundleTemplateItemMappedItem>
    ) {
        super(sourceData, logicService, BundleTemplateItemMappedItem);
    }

    public $save(): Observable<IBundleTemplateItemDto> {
        throw new Error('Bundle Template Item is not saved directly');
    }
}
