import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IBankAccountMappedItem } from '@app/logic/bank-accounts/interfaces/i.bank-account.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IBankAccountDto } from '@app/logic/bank-accounts';
import { IBankAccountsLogicService } from '@app/logic/bank-accounts/interfaces/i.bank-accounts.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BankAccountMappedItem } from '@app/logic/bank-accounts/bank-account.mapped';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';

interface IData {
    mappedItem: BankAccountMappedItem;
}

@Component({
    selector: 'cb-bank-account-dialog',
    templateUrl: './bank-account-dialog.component.html',
    styleUrls: ['./bank-account-dialog.component.scss']
})
export class BankAccountDialogComponent extends BaseDialogFormViewDirective<IBankAccountDto, IBankAccountMappedItem, IBankAccountsLogicService> {
    public get mappedItem(): BankAccountMappedItem {
        return this.data.mappedItem;
    }
    public businessEntities: IBusinessEntityDto[];

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<BankAccountDialogComponent>,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);

        this.businessEntitiesLogicService.$getList().subOnce(x => {
            this.businessEntities = x.filter(y => y.isActive);
        });
    }

    public optionLabel = (option: IBusinessEntityDto): string => {
        return option ? option.name : '';
    };
}
