import { Component, Inject, Input } from '@angular/core';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ViewImageComponent } from '@app/shared/components/image-handlers/view-image/view-image.component';

@Component({
    selector: 'cb-lot-summary-images',
    templateUrl: './lot-summary-images.component.html',
    styleUrls: ['./lot-summary-images.component.scss']
})
export class LotSummaryImagesComponent {

    @Input() public lotMappedItem: ILotMappedItem;
    public isValidExteriorImage = true;
    public isValidFloorPlanImage = true;

    constructor(
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService
    ) { }

    public handleInvalidExteriorImage(): void {
        this.isValidExteriorImage = false;
    }

    public handleInvalidFloorPlanImage(): void {
        this.isValidFloorPlanImage = false;
    }

    public viewImage(validImage: boolean, imageUrl: string, imageName: string): void {
        if(!validImage) {
            return;
        }
        this.cbDialog.open(ViewImageComponent,
            {
                data: {
                    name: imageName,
                    url: imageUrl
                },
                panelClass: 'cb-full-width-dialog'
            }
        );
    }
}
