import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DocumentsLogicService } from '@app/logic/documents';
import { PurchaseOrdersLogicService } from '@app/logic/purchase-orders';
import { IPurchaseOrderHistoryDto, PurchaseOrderStatusEnumId, PURCHASE_ORDER_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-po-document-history',
    templateUrl: './po-document-history.component.html',
    styleUrls: ['./po-document-history.component.scss']
})
export class PoDocumentHistoryComponent {

    public static readonly MIN_WIDTH = '75%';
    public PURCHASE_ORDER_STATUS_ENUM = PURCHASE_ORDER_STATUS_ENUM;

    public readonly displayedColumns = [
        'PO #',
        'Supplier',
        'Status',
        'Hub Version',
        'Supplier Version',
        'Order Total',
        'Activity Start Date',
        'Activity End Date',
        'UpdatedBy',
        'Date Updated',
        '',
        ''
    ];

    constructor(
        public readonly dialogRef: MatDialogRef<PoDocumentHistoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { purchaseOrderHistories: IPurchaseOrderHistoryDto[]; title: string },
        public readonly documentsLogicService: DocumentsLogicService,
        public readonly purchaseOrdersLogicService: PurchaseOrdersLogicService) { }

    public downloadDocument(purchaseOrder: IPurchaseOrderHistoryDto): void {
        this.purchaseOrdersLogicService.viewPO(purchaseOrder.id);
    }

    public readonly manualOrderReasonText = (purchaseOrder: IPurchaseOrderHistoryDto): string => {
        return `Reason: ${purchaseOrder.reasonLabel}${purchaseOrder.reasonDescription ? ` - ${purchaseOrder.reasonDescription}` : ''}`;
    };

    public readonly activityRowClasses = (purchaseOrder: IPurchaseOrderHistoryDto): { [key: string]: boolean } => (
        {
            'table-pink': purchaseOrder.isManualOrder && purchaseOrder.statusId !== PurchaseOrderStatusEnumId.Cancelled,
            'table-abandoned dragula-ignore': purchaseOrder.statusId === PurchaseOrderStatusEnumId.Cancelled,
        }
    );

    public isCancelled(purchaseOrder: IPurchaseOrderHistoryDto): boolean {
        return purchaseOrder.statusId === PurchaseOrderStatusEnumId.Cancelled;
    }
}
