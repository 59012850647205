import { ChangeDetectorRef, Component, Input } from '@angular/core';
import moment, { Duration, Moment } from 'moment';

@Component({
    selector: 'cb-elapsed-time',
    templateUrl: './elapsed-time.component.html',
    styleUrls: ['./elapsed-time.component.scss']
})
export class ElapsedTimeComponent {

    private _elapsedTime: string;
    @Input() public set elapsedTime(v: string) {
        if (!v) {
            return;
        }
        this._elapsedTime = v;
        this.dateTimeLastPolled = moment();
        const durationText = v.toString().replace(/\"/g, '');
        this.elapsedTimeDuration = moment.duration(durationText);
        setTimeout(this.updateElapsedTimeClientside, 1000);
    }
    public get elapsedTime(): string {
        return this._elapsedTime;
    }

    private _counting = false;
    @Input() public set counting(v: boolean) {
        this._counting = v;
        this.elapsedTime = this._elapsedTime;
    }
    public get counting(): boolean {
        return this._counting;
    }

    @Input() public label: string | null;

    public clientSideElapsedTimeDuration = {} as Duration;
    public displayText = '';
    private elapsedTimeDuration = moment.duration();
    private dateTimeLastPolled: Moment = moment();

    constructor(public cdRef: ChangeDetectorRef) { }

    /* Calulate the elapsed time on clientside to avoid constant polling serverside */
    private readonly updateElapsedTimeClientside = () => {
        if (this.counting) {
            const dateNow = moment();
            // Calculates how long since the initial elapsed time was updated form the server-side
            // and add duration to now.
            const duration = moment.duration(dateNow.diff(this.dateTimeLastPolled));

            // Now add that duration to initial duration to get real-time duration.
            this.clientSideElapsedTimeDuration = moment.duration(this.elapsedTimeDuration);
            this.clientSideElapsedTimeDuration.add(duration.asSeconds(), 's');

            // set model to allow duration input control to format duration as required
            setTimeout(this.updateElapsedTimeClientside, 1000);
        } else {
            // since not in progress no further time is elapsing.
            this.clientSideElapsedTimeDuration = moment.duration(this.elapsedTimeDuration);
        }
        this.setDisplayText();
    };

    private setDisplayText(): void {
        if (this.clientSideElapsedTimeDuration !== undefined) {
            try {
                this.displayText = `${(Math.floor(this.clientSideElapsedTimeDuration.asHours())).toString()
                }:${moment(this.clientSideElapsedTimeDuration.asMilliseconds()).format('mm:ss').toString()}`;
                this.cdRef.detectChanges();
            } catch (error) {
                this.displayText = '';
                this.cdRef.detectChanges();
            }
        } else {
            this.displayText = '';
            this.cdRef.detectChanges();
        }
    }
}
