import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject, Subscriber, Subscription, map, mergeMap } from 'rxjs';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { orderBy } from 'lodash';
import { DesignTeamPermissions } from '@app/core/permissions';
import { FormMode } from '@app/shared/enums/form';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { DesignSchemesLogicService, IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { IDesignTeamSchemesSearch } from '@app/core/services/user-cache/user-cache-areas';
import { DesignSchemeStatusEnumId, DESIGN_SCHEME_STATUS_ENUM, IDesignSchemeDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { DesignSchemeDialogComponent } from '@app/views/lot/design/schemes/manage/design-scheme-dialog/design-scheme-dialog.component';
import { DesignTeamWorkLoadDialogComponent } from '../work-load-dialog/design-team-work-load-dialog';

@Component({
    selector: 'cb-design-team-schemes',
    templateUrl: './design-team-schemes.component.html',
    styleUrls: ['./design-team-schemes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignTeamSchemesComponent implements OnDestroy, OnInit {

    public resultOrders$ = of([
        { id: 'jobNumber', label: 'Job Number' },
        { id: 'assignedTo', label: 'Assigned To' },
        { id: 'createdDate', label: 'Created Date' },
        { id: 'dueDate', label: 'Due Date' },
        { id: 'status', label: 'Status' }
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    private _subscriptions = new Subscription();
    public searchEnabled = false;
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public teamCode: string;
    public loaded = false;
    public cacheLoaded$ = new Subject();
    public results: IDesignSchemeDocumentDto[] = [];
    public readonly allUsers = '999';
    public readonly allStatuses = 999;
    public readonly unassigned = undefined;
    public fetchInProgress = false;

    public schemeStatuses$ = of(DESIGN_SCHEME_STATUS_ENUM.toLookup())
        .pipe(
            map(schemeStatuses => {
                const orderedSchemeStatuses = orderBy(schemeStatuses, tag => tag.label.toLowerCase());
                orderedSchemeStatuses.unshift({
                    id: this.allStatuses,
                    label: 'All',
                    isActive: true,
                    code: ''
                } as unknown as IEnumLookup<DesignSchemeStatusEnumId>);
                return orderedSchemeStatuses;
            })
        );

    public hasPermissionsToEdit$ = of(this.designTeamPermissions.canEditScheme());

    public canEdit$(scheme: IDesignSchemeDocumentDto): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            subscriber.next(scheme.statusId !== DESIGN_SCHEME_STATUS_ENUM.Accepted);
        });
    }

    public teamUsersOptions$ = this.teamsLogicService
        .loadAllMembersByKey('DESIGNTEAM').pipe(
            map(users => {
                users = orderBy(users, 'firstName');
                users.unshift({ id: this.allUsers, label: 'All' } as any);
                return users;
            })
        );

    public get searchFiltersCache(): UserCacheItem<IDesignTeamSchemesSearch> {
        return this.userCacheService.designTeamSchemesSearch;
    }

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: DesignSchemesLogicService,
        public cdRef: ChangeDetectorRef,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
        private readonly dialogService: CbDialogService,
        public readonly designTeamPermissions: DesignTeamPermissions,
        private readonly navigationService: NavigationService
    ) {

    }

    public ngOnInit(): void {
        this._initSearchFiltersCache();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public queryChanged($event): void {
        this.searchFiltersCache.data.query = $event;
        this.searchFiltersChanged$.next($event);
    }

    public fetchResults(): Observable<IDesignSchemeDocumentDto[]> {
        if (!this.searchEnabled) {
            return;
        }

        return this.logicService.$getSearchList(this._queryParams)
            .pipe(mergeMap(results => {
                this.loaded = true;
                return of(results);
            }));

    }

    public hasPermissionsToEditScheme = (): boolean => {
        if (this.designTeamPermissions) {
            return this.designTeamPermissions.canEditScheme();
        }

        return false;
    };

    public getStreetAddress(scheme: IDesignSchemeDocumentDto): string {
        return `${scheme.addressStreet}, ${scheme.addressCity}`;
    }

    public openDesignTeamWorkLoadDialog(): void {
        this.dialogService
            .open(DesignTeamWorkLoadDialogComponent)
            .afterClosed()
            .subOnce();
    }

    public readonly editScheme = async (
        scheme: IDesignSchemeDocumentDto,
        mode = FormMode.Edit
    ): Promise<Subscription> => {
        const mappedItem = await this.logicService.$getMappedItem(scheme.id).toPromise();
        return this.dialogService
            .open(
                DesignSchemeDialogComponent,
                {
                    data: {
                        mappedItem,
                        mode,
                        lotTypeEnumId: scheme.lotType,
                    },
                }
            ).afterClosed()
            .subOnce({
                next: (result: IDesignSchemeMappedItem) => {
                    if (result && result.id) {
                        const foundIndex = this.results.findIndex(x => x.id === result.id);
                        Object.assign(this.results[foundIndex], result);
                        this.results[foundIndex].complexityCode = result.designComplexity.complexity;
                        this.cdRef.detectChanges();
                    }
                }
            });
    };

    public viewLot(lotId: number): void {
        if (lotId) {
            this.navigationService.navigate([`/lots/${lotId}/summary`]);
        }
    }

    private get _queryParams(): IDesignTeamSchemesSearch {
        return this.searchEnabled ?
            {
                ...this.searchFiltersCache.copyData(),
                userId: this.searchFiltersCache.data.userId === this.allUsers ?
                    undefined :
                    this.searchFiltersCache.data.userId ?
                        this.searchFiltersCache.data.userId :
                        undefined,
                statusId: (this
                    .searchFiltersCache.data.statusId as any) === this.allStatuses ?
                    undefined :
                    this.searchFiltersCache.data.statusId,
                currentPage: this.currentPage
            } :
            undefined;
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    public _initSearchFiltersCache(): void {
        this.searchFiltersCache.init().then(() => {
            this.cacheLoaded$.next(true);
            this.searchEnabled = true;
            this._onSearchFiltersChanged();
            this._subscriptions.add(
                this.searchFiltersCache.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );

            this.searchFiltersCache.silentData.canSeeAll = this.designTeamPermissions.canSeeAll();
            if (!this.searchFiltersCache.silentData.canSeeAll) {
                this.searchFiltersCache
                    .silentData
                    .userId = this.searchFiltersCache.currentUserId;
            }
        });
    }
}
