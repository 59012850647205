import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LotPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { CLIENT_SALE_STATUS_ENUM, IAmenitiesDto, LOT_CONTRACT_TYPE_ENUM, LOT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { LotClassicContactsDialogComponent } from '../lot-classic-contacts-dialog/lot-classic-contacts-dialog.component';
import { LotDetailsDialogComponent } from '../lot-details-dialog/lot-details-dialog.component';

@Component({
    selector: 'cb-lot-sidebar',
    templateUrl: './lot-sidebar.component.html',
    styleUrls: ['./lot-sidebar.component.scss']
})
export class LotSidebarComponent {

    public LOT_TYPE_ENUM = LOT_TYPE_ENUM;
    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    public amenities: IAmenitiesDto;

    public _lotMappedItem: ILotMappedItem;

    public clientRoute: string;
    public parentRoute: string;
    public projectRoute: string;
    public releaseStageRoute: string;

    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this._lotMappedItem = mappedItem;

            this.amenities = {
                bedrooms: this._lotMappedItem.amenities.noBedrooms,
                bathrooms: this._lotMappedItem.amenities.noBathrooms,
                living: this._lotMappedItem.amenities.noLivingRooms,
                garages: this._lotMappedItem.amenities.noGarages,
            };

            this.clientRoute = `/accounts/${this.lotMappedItem?.clientAccountId}/summary`;
            this.parentRoute = `/lots/${this.lotMappedItem?.parentId}/summary`;
            this.projectRoute = `/projects/${this.lotMappedItem?.project?.id}/details`;
            this.releaseStageRoute = `/projects/${this.lotMappedItem?.project?.id}/releases/${this.lotMappedItem?.projectReleaseStage?.id}/details`;
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this._lotMappedItem;
    }

    @Output() public lotMappedItemChange = new EventEmitter<ILotMappedItem>();

    constructor(
        public readonly lotPermissions: LotPermissions,
        public readonly cbDialog: CbDialogService,
        public readonly navigationService: NavigationService,
    ) { }

    public editLot(): void {
        this.cbDialog
            .open(LotDetailsDialogComponent, {
                data: this.lotMappedItem.$clone()
            }).afterClosed().subOnce(result => {
                if (result) {
                    this.lotMappedItemChange.emit(this.lotMappedItem);
                }
            });
    }

    public editClassicContacts(): void {
        this.cbDialog
            .open(LotClassicContactsDialogComponent, {
                data: this.lotMappedItem.$clone()
            }).afterClosed().subOnce(result => {
                if (result) {
                    this.lotMappedItemChange.emit(this.lotMappedItem);
                }
            });
    }

    public viewClientSale(): void {
        this.navigationService.navigate([`clientsales/view/${this.lotMappedItem.clientSaleId}/details`]);
    }


    public isJobFinalised(): boolean {
        return this.lotMappedItem.clientSale &&
            (this.lotMappedItem.clientSale.status === CLIENT_SALE_STATUS_ENUM.SaleLost
                || this.lotMappedItem.clientSale.status === CLIENT_SALE_STATUS_ENUM.Complete);
    }

    public canEditLotDetails(): boolean {
        return this.isJobFinalised() !== true
            && (this.lotMappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild ||
                this.lotMappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand ||
                this.lotMappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand ||
                this.lotMappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand);
    }

}
