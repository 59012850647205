import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { DesignSchemesLogicService, IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { ILotMappedItem } from '@app/logic/lots';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-design-schemes-view',
    templateUrl: './design-schemes-view.component.html',
    styleUrls: ['./design-schemes-view.component.scss'],
})
export class DesignSchemesViewComponent implements OnInit {

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    @Input() public set lotMappedItem(_lotMappedItem: ILotMappedItem) {
        if (_lotMappedItem) {
            this.lotMappedItem$$.next(_lotMappedItem);
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public selectedDesignScheme$$: BehaviorSubject<IDesignSchemeMappedItem> = new BehaviorSubject(null);
    public get selectedDesignScheme(): IDesignSchemeMappedItem {
        return this.selectedDesignScheme$$?.value;
    }

    constructor(
        private readonly navigationService: NavigationService,
        private readonly designSchemeLogic: DesignSchemesLogicService,
    ) { }

    public ngOnInit(): void {
        this.loadQueryParams();
    }

    private loadQueryParams(): void {
        const queryParams = this.navigationService.getQueryParams<{ paramEntityId?: string }>();
        if (queryParams?.paramEntityId) {
            this.loadDesignScheme(Number(queryParams.paramEntityId));
        }
    }

    private loadDesignScheme(designSchemeId: number): void {
        this.designSchemeLogic
            .$getMappedItem(designSchemeId)
            .subOnce(this.designSchemeSelected);
    }

    public readonly designSchemeSelected = (event: IDesignSchemeMappedItem): void => {
        this.selectedDesignScheme$$.next(event);
    };

    public handleGoBack(): void {
        this.lotMappedItem.$reload().subOnce();
        this.selectedDesignScheme$$.next(null);
    }
}
