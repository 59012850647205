import {IBaseMappedItem} from './interfaces/i.base-mapped-item';
import {cloneDeepSafe} from '@app/shared/utils/clone-object.util';
import {BaseMappedItem} from './base-mapped-item';
import {PrivateProp} from '@app/shared/decorators/private-prop.decorator';
import {IBaseMappedItemPrivate} from './interfaces/i.base-mapped-item-private';

export class SwapMapping<TSwapDto, TMainDto, TMappedItem extends IBaseMappedItem<TMainDto, any, any> = IBaseMappedItem<TMainDto, any, any>> {

    public constructor(
        @PrivateProp private readonly $dtoDefinition: TSwapDto,
        @PrivateProp private readonly $mapDownDef: (target: TSwapDto, src: TMainDto) => void,
        @PrivateProp private readonly $mapUpDef: (target: TMainDto, src: TSwapDto) => void,
        @PrivateProp private readonly $propertyKey: string,
        @PrivateProp private readonly $mappedItem: () => TMappedItem,
    ) {
        const mappedItem = $mappedItem();
        if (!mappedItem || mappedItem[this.$propertyKey] == null) {
            mappedItem[this.$propertyKey] = this;
        }
        this.$mapDown();
    }

    /** @returns an immutable copy of this SwapMapping instance's data */
    public $getImmutableData(): TSwapDto {
        const data = {} as TSwapDto;
        Object.keys(this.$dtoDefinition).forEach((key) => {
            data[key] = cloneDeepSafe(this[key]);
        });
        return data;
    }

    /** assign the @param data onto this SwapMapping instance's data without any side-effects on TMappedItem */
    public $assign(data: Partial<TSwapDto>): void {
        const clone = cloneDeepSafe(data);
        Object.keys(this.$dtoDefinition).forEach((key) => {
            this[key] = clone[key];
        });
    }

    /** maps this SwapMapping's instance data onto TMappedItem using $updateThisData
     *
     * @param data - optional data to be mapped, calls $assign
     */
    public $mapUpThisData(data?: Partial<TSwapDto>): void {
        if (data) {
            this.$assign(data);
        }
        const mappedItemDto = this.$mappedItem().$getMappedDtoItem();
        this.$mapUpDef(mappedItemDto, this.$getImmutableData());
        this.$mappedItem().$updateThisData(mappedItemDto);
    }

    /** maps this SwapMapping's instance data onto TMappedItem using $updateThisAndOriginal
     *
     * @param data - optional data to be mapped, calls $assign
     */
    public $mapUpThisAndOriginal(data?: Partial<TSwapDto>): void {
        if (data) {
            this.$assign(data);
        }
        const mappedItemDto = this.$mappedItem().$getMappedDtoItem();
        this.$mapUpDef(mappedItemDto, this.$getImmutableData());
        this.$mappedItem().$updateThisAndOriginal(mappedItemDto);
    }

    /** pulls data down from TMappedItem onto this SwapMapping instance */
    public $mapDown(): void {
        const swapDto = this.$getImmutableData();
        this.$mapDownDef(swapDto, this.$mappedItem().$getMappedDtoItem());
        this.$assign(swapDto);
    }
}

/** ***For use with mapped items only***
 */
export function SwapMap<
    TSwapDto,
    TMainDto,
>(
    swapMapConfig: {
        dto: TSwapDto;
        up: (target: TMainDto, src: TSwapDto) => void;
        down: (target: TSwapDto, src: TMainDto) => void;
    }
): PropertyDecorator {
    return <Target extends BaseMappedItem<any, any, any>>(target: Target, propertyKey: string) => {
        const exposedTarget = target as unknown as IBaseMappedItemPrivate;
        if (exposedTarget.$swapMapCtors == null) {
            Object.defineProperty(target, '$swapMapCtors', {
                enumerable: false,
                configurable: false,
                writable: true,
                value: {},
            });
        }

        exposedTarget.$swapMapCtors[propertyKey] = SwapMapping.bind(undefined, swapMapConfig.dto, swapMapConfig.down, swapMapConfig.up, propertyKey);
    };
}
