import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import {
    IDesignConceptDto,
    IDesignSchemeDto,
    IPricingRevisionDto,
    IWorkingDrawingDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, tap } from 'rxjs';
import { DtoProp } from '../base/dto-prop.decorator';
import { IDesignConceptMappedItem } from './interfaces/i.design-concept.mapped';
import { IDesignConceptsLogicService } from './interfaces/i.design-concepts-logic.service';

export class DesignConceptMappedItem
    extends BaseMappedItem<IDesignConceptDto, IDesignConceptMappedItem, IDesignConceptsLogicService>
    implements IDesignConceptMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public statusId: number;
    @DtoProp public statusLabel: string;
    @DtoProp public lotSpecVersion: number;
    @DtoProp public isEstimatedPrice: boolean;
    @DtoProp public sellingPrice: number;
    @DtoProp public pricingExpiryDate: string;
    @DtoProp public exteriorImageUrl: string;
    @DtoProp public exteriorImageId: string;
    @DtoProp public floorPlanImageUrl: string;
    @DtoProp public floorPlanImageId: string;
    @DtoProp public lotId: number;
    @DtoProp public isActiveOnLot: boolean;
    @DtoProp public designSchemeId: number;
    @DtoProp public designScheme: IDesignSchemeDto;
    @DtoProp public pricingRevisionId: number;
    @DtoProp public pricingRevision: IPricingRevisionDto;
    @DtoProp public workingDrawingId: number;
    @DtoProp public workingDrawing: IWorkingDrawingDto;
    @DtoProp public clientSaleId: number;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public exteriorImageRequesterName: string;
    @DtoProp public exteriorImageRequestedDate: string;
    @DtoProp public floorPlanImageRequesterName: string;
    @DtoProp public floorPlanImageRequestedDate: string;
    @DtoProp public exteriorImageUploaderName: string;
    @DtoProp public exteriorImageUploadedDate: string;
    @DtoProp public floorPlanImageUploaderName: string;
    @DtoProp public floorPlanImageUploadedDate: string;

    constructor(
        sourceData: IDesignConceptDto,
        logicService: IDesignConceptsLogicService
    ) {
        super(sourceData, logicService, DesignConceptMappedItem);
    }



    public applyToLot(): Observable<IDesignConceptDto> {
        return this.$logicService.applyToLot(this.id)
            .pipe(tap(this.$updateThisAndOriginal.bind(this)));
    }

    public requestExteriorImage(): void {
        this.$logicService.requestExteriorImage(this.id).subOnce(result => {
            this.$updateThisData(result);
        });
    }

    public requestFloorPlanImage(): void {
        this.$logicService.requestFloorPlanImage(this.id).subOnce(result => {
            this.$updateThisData(result);
        });
    }

    public abandonConcept(): void {
        this.$logicService.abandonConcept(this.id).subOnce(result => {
            this.$updateThisData(result);
        });
    }
}
