export class DateUtil {
    public static formatDateWithoutTimeZone(date: Date | string): string {
        const positionOfGmt = date.toString().indexOf('GMT');
        if (positionOfGmt > -1) {
            return date.toString().substring(0, positionOfGmt);
        }
        return date.toString();
    }
    public static formatUtcDateToDateWithLocalTimeZone(dateString: DateString): DateString {
        const utcStartDate = new Date(dateString);

        const localOffset = new Date().getTimezoneOffset();

        const localStartDate = new Date(utcStartDate.getTime() - (localOffset * 60 * 1000));

        return localStartDate.toISOString();
    }
}
