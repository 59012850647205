import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule, CbSelectListModule } from 'cb-hub-lib';
import { MatSortModule } from '@angular/material/sort';
import { CbSortableHeaderCellModule } from '@app/shared/components/sortable-header-cell/sortable-header-cell.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { MatRadioModule } from '@angular/material/radio';
import { SpecTemplateViewComponent } from './spec-template-view/spec-template-view.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbHistoryModule } from '@app/shared/components/history/history.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { SpecTemplatesComponent } from './spec-templates.component';
import { SpecTemplateFiltersComponent } from './spec-template-filters/spec-template-filters.component';
import { SpecTemplateTableComponent } from './spec-template-table/spec-template-table.component';
import { SpecTemplateDialogComponent } from './spec-template-dialog/spec-template-dialog.component';
import { SpecTemplateSidePanelComponent } from './spec-template-side-panel/spec-template-side-panel.component';
import { SpecTemplateItemDialogComponent } from './spec-template-item-dialog/spec-template-item-dialog.component';
import { SpecTemplateColourItemDialogComponent } from './spec-template-colour-item-dialog/spec-template-colour-item-dialog.component';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbImageButtonModule } from '@app/shared/components/image-handlers/image-button/image-button.module';

@NgModule({
    declarations: [
        SpecTemplatesComponent,
        SpecTemplateFiltersComponent,
        SpecTemplateTableComponent,
        SpecTemplateDialogComponent,
        SpecTemplateViewComponent,
        SpecTemplateSidePanelComponent,
        SpecTemplateItemDialogComponent,
        SpecTemplateColourItemDialogComponent,
    ],
    imports: [
        CommonModule,
        CbHubTableModule,
        CbTableModule,
        MatSortModule,
        CbSortableHeaderCellModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        CbFormsModule,
        CbSelectListModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        MatTableModule,
        CbDialogModule,
        MatCardModule,
        CbDatePipeModule,
        MatRadioModule,
        MatTabsModule,
        MatTooltipModule,
        CbUserDateTimeModule,
        CbHistoryModule,
        MatMenuModule,
        MatExpansionModule,
        CbDisplayValueModule,
        CbImageButtonModule,
        RouterModule.forRoot([
            {
                component: SpecTemplatesComponent,
                path: 'spec-templates',
                data: { breadcrumb: 'Specification Templates' },
            },
            {
                component: SpecTemplateViewComponent,
                path: 'spec-templates/view/:id/:tabName',
                data: {
                    breadcrumb: 'View Specification Template',
                    breadcrumbPredecessors: [
                        { label: 'Specification Templates', url: '/spec-templates' }
                    ]
                },
            }
        ]),
    ]
})

export class SpecTemplatesModule { }
