import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FieldValidationItem } from '@app/core/services/field-validation/field.validation.item';
import { LOT_COMPONENT_TYPE } from '@app/core/services/field-validation/lot-field-validator/interfaces/i.lot.field.rules.service';
import { LotFieldValidatorService } from '@app/core/services/field-validation/lot-field-validator/lot-field-validator-service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem } from '@app/logic/lots';
import { ILotsLogicService } from '@app/logic/lots/interfaces/i.lots.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ILotContactsDto, ILotDto, IStringIdAndLabelDto, USER_TAG_CONSTANTS_CONST, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { LotDetailsDialogComponent } from '../lot-details-dialog/lot-details-dialog.component';

interface ITagSearchParams {
    active: boolean;
    tag: string;
}

interface ITeamSearchParams {
    active: boolean;
    team: string;
}

@Component({
    selector: 'cb-lot-classic-contacts-dialog',
    templateUrl: './lot-classic-contacts-dialog.component.html',
    styleUrls: ['./lot-classic-contacts-dialog.component.scss']
})
export class LotClassicContactsDialogComponent extends BaseDialogFormViewDirective<ILotDto, ILotMappedItem, ILotsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '600px';

    public tags = USER_TAG_CONSTANTS_CONST;
    public teams = TEAM_CODES_CONST;
    public contacts: ILotContactsDto;

    // Field Validation Items
    public buildingConsultantField: FieldValidationItem;
    public buildingCoordinatorField: FieldValidationItem;
    public designerField: FieldValidationItem;
    public quantitySurveyorField: FieldValidationItem;
    public foundationManagerField: FieldValidationItem;
    public framingManagerField: FieldValidationItem;
    public hlCoordinatorField: FieldValidationItem;
    public qualityAssuranceManagerField: FieldValidationItem;
    public businessEntityField: FieldValidationItem;

    constructor(
        public readonly dialogRef: MatDialogRef<LotDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: ILotMappedItem,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LotFieldValidatorService) public readonly lotFieldValidatorService: LotFieldValidatorService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data;
        this.setDefaults();
    }

    public ngOnInit(): void {
        this.populateFieldValidationItems();
    }

    private populateFieldValidationItems(): void {
        this.lotFieldValidatorService.dto = this.mappedItem.$getMappedDtoItem();

        this.buildingConsultantField = this.lotFieldValidatorService.contactsBuildingConsultant(LOT_COMPONENT_TYPE.ClassicContacts);
        this.buildingCoordinatorField = this.lotFieldValidatorService.contactsBuildingCoordinator(LOT_COMPONENT_TYPE.ClassicContacts);
        this.designerField = this.lotFieldValidatorService.contactsDesigner(LOT_COMPONENT_TYPE.ClassicContacts);
        this.quantitySurveyorField = this.lotFieldValidatorService.contactsQuantitySurveyor(LOT_COMPONENT_TYPE.ClassicContacts);
        this.foundationManagerField = this.lotFieldValidatorService.contactsFoundationManager(LOT_COMPONENT_TYPE.ClassicContacts);
        this.framingManagerField = this.lotFieldValidatorService.contactsFramingManager(LOT_COMPONENT_TYPE.ClassicContacts);
        this.hlCoordinatorField = this.lotFieldValidatorService.contactsHouseAndLandCoordinator(LOT_COMPONENT_TYPE.ClassicContacts);
        this.qualityAssuranceManagerField
            = this.lotFieldValidatorService.contactsQualityAssuranceManager(LOT_COMPONENT_TYPE.ClassicContacts);
        this.businessEntityField = this.lotFieldValidatorService.businessEntity(LOT_COMPONENT_TYPE.ClassicContacts);
    }

    private setDefaults(): void {
        this.contacts = {
            houseAndLandCoordinator: this.mappedItem?.contacts.houseAndLandCoordinator || {} as IStringIdAndLabelDto,
            buildingConsultant: this.mappedItem?.contacts.buildingConsultant || {} as IStringIdAndLabelDto,
            buildingCoordinator: this.mappedItem?.contacts.buildingCoordinator || {} as IStringIdAndLabelDto,
            designer: this.mappedItem?.contacts.designer || {} as IStringIdAndLabelDto,
            quantitySurveyor: this.mappedItem?.contacts.quantitySurveyor || {} as IStringIdAndLabelDto,
            foundationManager: this.mappedItem?.contacts.foundationManager || {} as IStringIdAndLabelDto,
            framingManager: this.mappedItem?.contacts.framingManager || {} as IStringIdAndLabelDto,
            qualityAssuranceManager: this.mappedItem?.contacts.qualityAssuranceManager || {} as IStringIdAndLabelDto,
            constructionManager: this.mappedItem?.contacts.constructionManager || {} as IStringIdAndLabelDto,
        };
    }

    public saveMethod(): Observable<ILotDto> {
        this.mappedItem.contacts = this.contacts;
        return this.mappedItem.$save();
    }

    public getTagSearchParams(tagCode: string): ITagSearchParams {
        return {
            active: true,
            tag: tagCode
        };
    }

    public getTeamSearchParams(teamCode: string): ITeamSearchParams {
        return {
            active: true,
            team: teamCode
        };
    }

}
