import { Component, OnInit } from '@angular/core';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ManageCostxExportDialogComponent } from '../manage-costx-export-dialog/manage-costx-export-dialog.component';
import { CostxRateLibraryLogicService } from '@app/logic/costx-rate-library/costx-rate-library-logic.service';
import { ICostxRateLibraryMappedItem } from '@app/logic/costx-rate-library/interfaces/i.costx-rate-library.mapped';
import { Observable } from 'rxjs';
import { ProductPermissions } from '@app/core/permissions';
import { orderBy } from 'lodash';
import { CostxExportDateDialogComponent } from '../costx-export-date-dialog/costx-export-date-dialog.component';

const ROW_ORDER = 'name';

@Component({
    selector: 'cb-costx-export',
    templateUrl: './costx-export.component.html',
    styleUrls: ['./costx-export.component.scss']
})
export class CostxExportComponent implements OnInit {

    public costxRateLibraries: ICostxRateLibraryMappedItem[] = [];
    public readonly displayedColumns = [
        'CostX Rate Library Name',
        'Hub Rate Location',
        'Last Exported',
        ''
    ];
    public loaded = false;
    public canManage = false;

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly costxRateLibraryLogic: CostxRateLibraryLogicService,
        private readonly productPermissions: ProductPermissions,
    ) {
        this.productPermissions.observable.subOnce(() => {
            this.canManage = this.productPermissions.canManageProductCatalogueExports();
        });
    }

    public ngOnInit(): void {
        this.loadRateLibraries();
    }

    private readonly loadRateLibraries = (): void => {
        this.costxRateLibraryLogic.$getMappedList().subOnce((x) => {
            this.costxRateLibraries = orderBy(x, ROW_ORDER);
            this.loaded = true;
        });
    };

    public editRateLibrary(mappedItem: ICostxRateLibraryMappedItem): void {
        if (!mappedItem.canManage) {
            return;
        }
        this.openDialog(mappedItem)
            .subOnce();
    }

    public addRateLibrary(): void {
        if (!this.productPermissions?.canManageProductCatalogueExports()) {
            return;
        }
        const mappedItem = this.costxRateLibraryLogic.$createMappedItem();
        this.openDialog(mappedItem)
            .subOnce((result) => {
                if (result) {
                    this.costxRateLibraries.push(mappedItem);
                    this.costxRateLibraries = orderBy(this.costxRateLibraries, ROW_ORDER);
                }
            });
    }

    private openDialog(mappedItem: ICostxRateLibraryMappedItem): Observable<ICostxRateLibraryMappedItem> {
        return this.cbDialog.open(ManageCostxExportDialogComponent, {
            data: {
                mappedItem: mappedItem.$clone(),
            },
            minWidth: '40%',
        })
            .afterClosed();
    }

    public downloadRateLibraryExport(mappedItem: ICostxRateLibraryMappedItem): void {
        this.cbDialog.open(CostxExportDateDialogComponent, {
            data: {
                rateLibraryId: mappedItem.id,
            }
        });
    }

    public downloadRateLibrariesExport(): void {
        this.cbDialog
            .open(CostxExportDateDialogComponent)
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.loadRateLibraries();
                }
            });
    }

    public deleteRateLibrary(mappedItem: ICostxRateLibraryMappedItem): void {
        if (!mappedItem.canManage) {
            return;
        }
        this.cbDialog.confirm({
            dialogHeading: ' ',
            message: 'Are you sure you want to delete this CostX Rate Library Configuration?',
            confirmed: (): void => {
                mappedItem
                    .delete()
                    .subOnce((result) => {
                        if (result) {
                            this.costxRateLibraries.splice(this.costxRateLibraries.indexOf(mappedItem), 1);
                        }
                    });
            }
        });
    }
}
