import {Component, Input} from '@angular/core';
import {IStandardPlanMappedItem} from '@app/logic/standard-plans/interfaces/i.standard-plan.mapped';

@Component({
    selector: 'cb-standard-plan-details-view',
    templateUrl: './standard-plan-details.view.component.html',
    styleUrls: ['./standard-plan-details.view.component.scss']
})
export class StandardPlanDetailsViewComponent {

    @Input() public plan: IStandardPlanMappedItem;

    constructor() { }

}
