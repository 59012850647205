import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LotChangePermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ChangeOptionLogicService } from '@app/logic/change-option/change-option.logic-service';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import {
    CHANGE_SOURCE_ENUM,
    CHANGE_STATUS_ENUM,
    COST_NATURE_ENUM,
    ITeamDto,
    TEAM_CODES_CONST,
    VARIATION_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { LotChangeOptionDialogNewComponent } from '../lot-change-option-dialog/lot-change-option-dialog.component';
import { LotChangeReassignApprovalDialogComponent } from '../lot-change-reassign-approval-dialog/lot-change-reassign-approval-dialog.component';
import { LotChangeReassignDialogComponent } from '../lot-change-reassign-dialog/lot-change-reassign-dialog.component';
import { LotChangesVariationsDataService } from '../lot-changes-variations.data.service';
import { LotCreateChangeDialogComponent } from '../lot-create-change-dialog/lot-create-change-dialog.component';

@Component({
    selector: 'cb-lot-change-details',
    templateUrl: './lot-change-details.component.html',
    styleUrls: ['./lot-change-details.component.scss']
})
export class LotChangeDetailsComponent {

    @Input() public changeRecordMappedItem: IChangeRecordMappedItem;
    @Input() public lotMappedItem: ILotMappedItem;
    @Input() public teams: ITeamDto[];

    public CHANGE_SOURCE_ENUM = CHANGE_SOURCE_ENUM;
    public COST_NATURE_ENUM = COST_NATURE_ENUM;
    public CHANGE_STATUS_ENUM = CHANGE_STATUS_ENUM;

    @Output() public readonly changesUpdated = new EventEmitter<number[]>();

    constructor(
        private readonly changeOptionLogicService: ChangeOptionLogicService,
        private readonly changePermissions: LotChangePermissions,
        private readonly currentUser: CurrentUserService,
        private readonly cbDialog: CbDialogService,
        private readonly toast: ToastService,
        private readonly navigationService: NavigationService,
        private readonly lotChangesVariationsDataService: LotChangesVariationsDataService
    ) { }


    public viewQuote(): void {
        this.navigationService.navigate([`/lots/${this.changeRecordMappedItem.lotId}/spec/quotes`], undefined, {
            queryParams: {
                quoteId: this.changeRecordMappedItem.quoteId
            }
        });
    }

    public viewVariation(): void {
        this.navigationService.navigate([`/lots/${this.lotMappedItem.id}/changes/variations`], undefined, {
            queryParams: {
                variationNumber: this.changeRecordMappedItem.variationNumber
            }
        });
    }

    public revertToInProgress(): void {
        if (!this.canRevertToInProgress()) {
            return;
        }

        this.cbDialog.confirm({
            message: 'Are you sure you want to revert this Client Sale to its previous status?',
            confirmed: (): void => {
                this.changeRecordMappedItem.revertToInProgress().subOnce(() => {
                    this.lotChangesVariationsDataService.loadUnassignedChangeRecordsCount(this.lotMappedItem.id);
                });
            }
        });

    }

    public approveAndAcceptOption(): void {
        if (!this.canApproveAndAccept()) {
            return;
        }

        this.cbDialog.confirm({
            message: 'Are you sure you want to Approve & Accept this change?',
            confirmed: (): void => {
                const blockedDialog = this.cbDialog.block('Accepting change, please wait...');
                this.changeRecordMappedItem.approveAndAcceptOption().subOnce({
                    next: () => {
                        this.lotChangesVariationsDataService.loadUnassignedChangeRecordsCount(this.lotMappedItem.id);
                        blockedDialog.close();
                    },
                    error: () => {
                        blockedDialog.close();
                    }
                });
            }
        });
    }

    public editChangeDetails(): void {

        const dialogRef = this.cbDialog.open(LotCreateChangeDialogComponent,
            {
                data: {
                    mappedItem: this.changeRecordMappedItem.$clone(),
                    lotMappedItem: this.lotMappedItem,
                    mode: FormMode.Edit,
                    teams: this.teams,
                }
            });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {
                this.changesUpdated.emit(result);
            }
        });

    }

    public addOption(): void {
        const dialogRef = this.cbDialog.open(LotChangeOptionDialogNewComponent,
            {
                data: {
                    changeRecordMappedItem: this.changeRecordMappedItem,
                    changeOptionMappedItem: this.changeOptionLogicService.$createMappedItem(
                        {
                            changeRecordId: this.changeRecordMappedItem.id,
                            price: 0,
                            otherCosts: 0,
                            marginPercentage: 0,
                        }),
                    lotMappedItem: this.lotMappedItem,
                    mode: FormMode.Add,
                },
                width: '60%'
            });

        dialogRef.afterClosed().subOnce(_changeRecordDto => {
            if (_changeRecordDto && _changeRecordDto.id > 0) {
                this.changeRecordMappedItem.$updateThisAndOriginal(_changeRecordDto);
            }
        });
    }

    public submittedForPricing(): boolean {
        return this.changeRecordMappedItem.changeStatus !== CHANGE_STATUS_ENUM.Pending;
    }

    public declineOptionsConfirmDialog(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Decline All Options',
            message: 'Are you sure you want to decline all options for the below change record?',
            confirmed: (): void => {
                this.changeRecordMappedItem.changeOptions.forEach(option => {
                    option.isSelectedChangeOption = false;
                });

                this.changeRecordMappedItem.changeStatus = CHANGE_STATUS_ENUM.Declined;

                this.changeRecordMappedItem.$save().subOnce();
            }
        });
    }


    public cancelChangeRecordDialog(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Cancel Change Record',
            message: 'Are you sure you want to Cancel this Change Record?',
            confirmed: (): void => {
                this.changeRecordMappedItem.changeStatus = CHANGE_STATUS_ENUM.Cancelled;
                this.changeRecordMappedItem.$save().subOnce();
            }
        });
    }

    public generateChangeSummary(): void {
        this.changeRecordMappedItem.generateChangeSummary().subOnce({
            next: () => {
                this.toast.showToast('Dowload Successful');
            }
        });
    }


    public setStatusInQueue(): void {
        this.setStatus(CHANGE_STATUS_ENUM.InQueue);
    }

    public setStatusInProgress(): void {
        this.setStatus(CHANGE_STATUS_ENUM.InProgress);
    }

    public setStatusWaitingReview(): void {
        this.setStatus(CHANGE_STATUS_ENUM.WaitingReview);
    }

    public setStatusOnHold(): void {
        this.setStatus(CHANGE_STATUS_ENUM.OnHold);
    }

    private setStatus(status): void {
        this.changeRecordMappedItem.changeStatus = status;
        if (this.changeRecordMappedItem.changeStatus !== CHANGE_STATUS_ENUM.Accepted &&
            this.changeRecordMappedItem.changeStatus !== CHANGE_STATUS_ENUM.Completed) {
            this.falsifyOptions();
        }
        this.changeRecordMappedItem.$save().subOnce();
    }

    public falsifyOptions(): void {
        this.changeRecordMappedItem.changeOptions.forEach(option => {
            option.isSelectedChangeOption = false;
        });
    }


    public canMangeOwnChanged(): boolean {
        return this.changePermissions.canManageOwnChange() && this.changeRecordMappedItem.assignedToId === this.currentUser.guid;
    }

    public canManageOption(): boolean {
        return this.changePermissions.canManageOption();
    }

    public canEditChange(): boolean {
        return this.changePermissions.canEdit();
    }

    public canCancelChange(): boolean {
        return this.changePermissions.canCancel();
    }

    public canChangeStatus(): boolean {
        return this.canMangeOwnChanged() || this.isQsTeam() || !this.isMenuItemsDisabled();
    }

    public canReassignChange(): boolean {
        return this.changePermissions.canReassign();
    }

    public isCouncilRequest(): boolean {
        return this.changeRecordMappedItem.changeSource === CHANGE_SOURCE_ENUM.CouncilRequest;
    }

    public isAccepted(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Accepted;
    }

    public isCompleted(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Completed;
    }

    public isQsTeam(): boolean {
        return this.changeRecordMappedItem.teamCode === TEAM_CODES_CONST.QsTeamKey;
    }

    public canRevertToInProgress(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Accepted && !this.changeRecordMappedItem.variationNumber;
    }

    public canApproveAndAccept(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.InProgress
            && this.canMangeOwnChanged()
            && this.changeRecordMappedItem.changeOptions.length === 1;
    }


    public declineAllOptionsDisabled(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.InQueue
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.InProgress
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.WaitingReview
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.OnHold
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Pending
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Accepted
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.InDesign;
    }


    public isMenuDisabled(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Completed
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Cancelled
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Declined
            || (
                this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Accepted
                && this.changeRecordMappedItem.variationNumber
                && this.changeRecordMappedItem.variationStatus !== VARIATION_STATUS_ENUM.Accepted
            );
    }

    public isMenuItemsDisabled(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Accepted
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Pending
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.InDesign;
    }


    public isEditChangeDetailsDisabled(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Accepted ||
            this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.InDesign;
    }

    public isAddAnotherOptionDisabled(): boolean {
        return this.changeRecordMappedItem.changeStatus !== CHANGE_STATUS_ENUM.Pending && this.changeRecordMappedItem.changeStatus !== CHANGE_STATUS_ENUM.InProgress;
    }


    public reassign(): void {
        this.cbDialog.open(
            LotChangeReassignDialogComponent,
            {
                data: this.changeRecordMappedItem.$clone(),
                minWidth: '40%',
            }
        );
    }

    public reassignReview(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Cancel Change Record',
            message: `Are you sure you want to reassign this change to ${this.changeRecordMappedItem.reviewer} for review?`,
            confirmed: (): void => {
                this.changeRecordMappedItem.changeStatus = CHANGE_STATUS_ENUM.WaitingReview;
                this.changeRecordMappedItem.$save().subOnce();
            }
        });
    }


    public reassignApproval(): void {
        this.cbDialog.open(
            LotChangeReassignApprovalDialogComponent,
            {
                data: this.changeRecordMappedItem.$clone(),
            }
        );
    }

}
