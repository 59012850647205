<mat-card class="cb-margin flex-col">
    <div class="start-center flex-row">
        <mat-icon class="cb-margin-right-2x">search</mat-icon>
        <mat-form-field class="flex">
            <input type="text"
                   placeholder="Search History"
                   aria-label="Search History"
                   [formControl]="searchText"
                   name="{{entity}}-history-search"
                   matInput/>
        </mat-form-field>
    </div>
    <cb-table class="cb-margin-top flex"
              maxHeightOffset="310"
              [columns]="['Date', 'Action', 'User', '']"
              [loaded]="true"
              [fetch]="search()"
              [queryUpdated]="queryUpdated"
              [infiniteScrollEnabled]="searchEnabled"
              [(results)]="historyItems"
              [(currentPage)]="currentPage">
        <tbody cbTableBody>
            <tr *ngFor="let element of historyItems">
                <td>{{element.date | date}}</td>
                <td>{{element.eventTypeDisplay}}</td>
                <td>{{element.contactName}}</td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            *ngIf="element.details"
                            (click)="viewHistoryInfo(element);">
                        <mat-icon>help_outline</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</mat-card>
