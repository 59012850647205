import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {DatePipe} from '@angular/common';
import {ContactPermissions} from '@app/core/permissions';
import {ContactsLogicService} from '@app/logic/contacts';
import {IContactMappedItem} from '@app/logic/contacts/interfaces/i.contact.mapped';
import {NZ_LOCALE} from '@app/shared/declarations/app.constants';
import {CONTACT_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {toPromise} from 'cb-hub-lib';
import {Subscription, switchMap} from 'rxjs';
import {CONTACT_FULL_ROUTE} from '../contact-route.const';

@Component({
    selector: 'cb-contact-view',
    templateUrl: './contact-view.component.html',
    styleUrls: ['./contact-view.component.scss']
})
export class ContactViewComponent implements OnInit {

    public contact: IContactMappedItem;
    public editContact: IContactMappedItem;
    public subscription: Subscription;
    public CONTACT_STATUS_ENUM = CONTACT_STATUS_ENUM;
    public readonly CONTACT_FULL_ROUTE = CONTACT_FULL_ROUTE;

    public isEdit = false;

    public isEmailDuplicate = false;

    constructor(
        public readonly contactsLogic: ContactsLogicService,
        public readonly permissions: ContactPermissions,
        public readonly route: ActivatedRoute,
    ) {
    }

    public ngOnInit(): void {
        toPromise(this.route.params.pipe(
            switchMap((params: { id: string }) => {
                return this.contactsLogic.$getMappedItem(params.id);
            })
        )).then((mappedItem: IContactMappedItem) => {
            this.contact = mappedItem;
        });
    }

    public getCreatedDisplayValue(): string {
        return `${this.contact.createdByName} on ${new DatePipe(NZ_LOCALE).transform(this.contact.createdDate)}`;
    }


    public getUpdatedDisplayValue(): string {
        return `${this.contact.updatedByName} on ${new DatePipe(NZ_LOCALE).transform(this.contact.updatedDate)}`;
    }

    public OpenEditForm(): void {
        this.isEdit = true;
        this.editContact = this.contact.$clone();
    }

    public cancelEdit(): void {
        this.isEdit = false;
    }

    public saveContact(): void {
        this.editContact.$save()
            .subOnce(contact => {
                this.isEdit = false;
            });
    }
}
