import {map, Observable, Subject} from 'rxjs';

import {ClassCtor} from '@app/shared/types/classctor.type';
import {IBaseLogicService} from './interfaces/i.base-logic.service';
import {IBaseMappedItem} from './interfaces/i.base-mapped-item';
import {IClonedMappedItemSetup} from './interfaces/i.cloned-mapped-item';
import {IComputedPropDictionary} from './interfaces/i.computed-prop';
import {cleanObjectDeep} from 'cb-hub-lib';
import {ISwapMapCtorDictionary} from './interfaces/i.swap-map';

export const CLONE_ERROR = 'You cannot a clone a cloned *MappedItem - cloning can only be performed on the original version';

interface IOptionalId {
    readonly id?: string | number | undefined;
}

/** IMPORTANT: Use `$` prefix for ALL properties and functions on the BaseMappedItem. \
 * This avoids any naming overlaps from the sub class / sub class dto properties. \
 * It also makes it clear that the property/function that is being accessed is on the BaseMappedItem.
 */
export abstract class BaseMappedItem<DtoType extends Object, ClassType, LogicServiceType extends IBaseLogicService<DtoType, ClassType>>
implements IBaseMappedItem<DtoType, ClassType, LogicServiceType> {

    /** @returns 'this.id' by default \
     * Override 'get $id()' when 'this.id' is not the valid id property e.g. if an enity uses 'this.guid' instead
     */
    public get $id(): string | number | undefined {
        return (this as IOptionalId).id;
    }

    public readonly $isCloned: boolean = false;
    public readonly $original?: ClassType & IBaseMappedItem<DtoType, ClassType, LogicServiceType>;
    /** emits next after
     * - $save()
     * - $reload()
     * - $updateThisData()
     * - $updateThisAndOriginal()
     * - $updateOriginal()
     * - $mapDefinition()
     * - $recomputeProps()*/
    public readonly $updated = new Subject<void>();

    /** compute function definitions for computed properties (Computed decorator) */
    private readonly $computedProps: IComputedPropDictionary;
    /** computed values/results for computed properties (Computed decorator) */
    private readonly $computedPropsValues: { [prop: string]: any } = {};
    private readonly $dtoPropKeys: string[];
    /** definitions for swap map properties (SwapMap decorator) */
    private readonly $swapMapCtors: ISwapMapCtorDictionary;

    constructor(
        private $sourceData: DtoType,
        public readonly $logicService: LogicServiceType,
        private readonly $mappedItemClass: ClassCtor<ClassType>,
        private $defaultSourceData: Partial<DtoType> = {},
        /** If this is set, it will be used for mapping this MappedItem, instead of the properties decorated with ```@DtoProps``` on the MappedItem */
        public readonly $dtoDefinitionOverride: DtoType = null,
    ) {
        const data = {
            ...$defaultSourceData,
            ...cleanObjectDeep(this.$logicService.$cloneDeepSafe($sourceData)),
        };
        this.$mapDefinition(this, data);
        delete this.$defaultSourceData;
        delete this.$sourceData;
    }

    /** Override this if you need to pass extra args into the clone constructor for this mapped item */
    protected $cloneConstructor(): IClonedMappedItemSetup<DtoType, ClassType, LogicServiceType> {
        return new this.$mappedItemClass(this.$getMappedDtoItem(), this.$logicService) as unknown as IClonedMappedItemSetup<DtoType, ClassType, LogicServiceType>;
    }

    public $clone(): ClassType {
        if (this.$isCloned) {
            throw new Error(CLONE_ERROR);
        }
        const cloned: IClonedMappedItemSetup<DtoType, ClassType, LogicServiceType>
            = this.$cloneConstructor();
        cloned.$isCloned = true;
        cloned.$original = this;
        return cloned as any;
    }

    /**
     *
     * @returns The Dto version of a populated mappedItem
     * (usually to send to the back end)
     */
    public $getMappedDtoItem(): DtoType {
        const dtoItem = {} as DtoType;
        this.$mapDefinition(dtoItem, this, null, true, true, true, true);
        return dtoItem;
    }

    /** Maps the passed in dto to a new object with only the properties of the passed in dto, which has the values of the mappedItem
     *
     * @param dto A dto that will be used to set the property keys of the returned object
     */
    public $mapToDtoItem<DtoInterface extends Object>(dto: DtoInterface): DtoInterface {
        const dtoItem = {} as DtoInterface;
        this.$mapDefinition(dtoItem, this, dto, true, true, true, true);
        return dtoItem;
    }

    public $updateThisData<DataType>(newData: DataType): void {
        this.$mapDefinition(this, newData);
    }

    public $updateOriginal<DataType>(newData: DataType): void {
        if (!this.$isCloned) {
            return;
        }
        this.$original.$updateThisData(newData);
    }

    public $updateThisAndOriginal<DataType>(newData: DataType): void {
        this.$updateThisData(newData);
        this.$updateOriginal(newData);
    }

    private $mapDefinition<DestType, SrcType extends Object>(
        destination: DestType,
        source: SrcType,
        dtoDefinition: Object = null,
        skipPostLoad = false,
        skipRecompute = false,
        skipSwapMap = false,
        skipEmit = false,
    ): void {
        let keys = this.$dtoPropKeys;
        if (dtoDefinition != null) {
            keys = Object.keys(dtoDefinition);
        } else if (this.$dtoDefinitionOverride != null) {
            keys = Object.keys(this.$dtoDefinitionOverride);
        }
        // decrementing while loop - for performance
        let keyIdx = keys.length;
        while (keyIdx) {
            keyIdx--;
            const key = keys[keyIdx];
            if (!source?.hasOwnProperty(key)) {
                // skip mapping if property does not exist - we only want to update properties that have been defined in the source data
                continue;
            }
            // using cloneDeepSafe here to break/remove child object/array refrences, preventing mutation of original objects/arrays
            destination[key] = this.$logicService.$cloneDeepSafe(source[key]);
        }
        if (!skipPostLoad) {
            this.$postLoad();
        }
        if (!skipRecompute) {
            this.$recomputeProps(true);
        }
        if (!skipSwapMap && this.$swapMapCtors != null) {
            Object.keys(this.$swapMapCtors).forEach(swapMapKey => this[swapMapKey] ? this[swapMapKey].$mapDown() : new this.$swapMapCtors[swapMapKey](() => this));
        }
        if (!skipEmit) {
            this.$updated.next();
        }
    }

    /** Recomputes *ALL* @Computed() decorated properties
     *
     * @param skipEmit - if true, $updated next will not be emitted/triggerd
     */
    public $recomputeProps(skipEmit = false): void {
        if (this.$computedProps == null) {
            return;
        }
        const computedProps = Object.keys(this.$computedProps);
        if (computedProps.length < 1) {
            return;
        }
        let propIdx = computedProps.length;
        while (propIdx) {
            propIdx--;
            const key = computedProps[propIdx];
            if (!this.$computedProps.hasOwnProperty(key)) {
                continue;
            }
            this.$computedPropsValues[key] = this.$computedProps[key].compute.apply(this);
        }
        if (!skipEmit) {
            this.$updated.next();
        }
    }

    public $save(): Observable<DtoType> {
        const toSave = this.$getMappedDtoItem();
        this.$preSave(toSave);
        return this.$logicService
            .$saveItem(toSave)
            .pipe(
                map((response) => {
                    this.$updateThisAndOriginal(response);
                    return response;
                })
            );
    }

    /** Reloads this item from the DB via the backend API */
    public $reload(): Observable<DtoType> {
        return this.$logicService
            .$getItem(this.$id)
            .pipe(
                map((response) => {
                    // not using $updateThisAndOriginal here because
                    // - the intention may be to reload only one item
                    // - in general, reloading the clone should not affect the original
                    this.$updateThisData(response);
                    return response;
                })
            );
    }

    /** Called when a MappedItem is loaded/reloaded/mapped. This is called before $recomputeProps() */
    protected $postLoad(): void {

    }

    protected $preSave(toSave: DtoType): void {

    }
}
