<div class="cb-margin-left-2x cb-margin-right-2x cb-margin-top flex-row layoutGap-20">
    <h2>{{pdfFileName}}</h2>
    <span class="flex"> </span>
    <div>
        <button mat-icon-button
                (click)="zoomOut()">
            <mat-icon>zoom_out</mat-icon>
        </button>
        <b>{{pdfZoom * 100}}%</b>
        <button mat-icon-button
                (click)="zoomIn()">
            <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button
                matTooltip="Fit to page"
                (click)="resetZoom()">
            <mat-icon>fit_screen</mat-icon>
        </button>
    </div>
    <span class="flex"> </span>
    <div class="layoutGap-10">
        <button mat-mini-fab
                matTooltip="Download"
                (click)="download()">
            <mat-icon>file_download</mat-icon>
        </button>
    </div>
</div>
<pdf-viewer *ngIf="isLoaded"
            [src]="pdfBase64URL"
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="false"
            [zoom]="pdfZoom"
            [zoom-scale]="'page-width'"
            [stick-to-page]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="false"></pdf-viewer>
