import { BUILDING_CONSENT_STATUS_ENUM, BUILDING_CONSENT_TYPE_ENUM, ILotDto, RESOURCE_CONSENT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BuildingConsentLogicService, IBuildingConsentDto, IResourceConsentDto, ResourceConsentLogicService } from '@app/logic/lot-consents';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CreateConsentDialogComponent, ICreateConsentDialogData } from '../create-consent-dialog/create-consent-dialog.component';

import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { LotConsentViewService } from './../service/lot-consent-view.service';
import { LotDesignPermissions } from '@app/core/permissions';
import { RequestConsentDialogComponent } from '../request-consent-dialog/request-consent-dialog.component';

@Component({
    selector: 'cb-lot-consents-action-bar',
    templateUrl: './lot-consents-action-bar.component.html',
    styleUrls: ['./lot-consents-action-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotConsentsActionBarComponent {

    @Input() public lotDto: ILotDto;
    @Input() public readonly hasConsents: boolean;
    @Output() public readonly itemAdded = new EventEmitter<IBuildingConsentDto | IResourceConsentDto>();

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly buildingConsentLogic: BuildingConsentLogicService,
        public readonly resourceConsetLogic: ResourceConsentLogicService,
        public readonly lotConsentViewService: LotConsentViewService,
    ) { }

    public canCreateConsent(): boolean {
        return this.lotDesignPermissions.canCreateConsent();
    }

    public createBuildingConsent(): void {
        if (!this.canCreateConsent()) {
            return;
        }
        const buildingConsent = this.buildingConsentLogic.mapBuildingConsent({
            lotId: this.lotDto.id,
            councilId: this.lotDto.councilId,
            consentFor: 'Building Consent',
            consentType: BUILDING_CONSENT_TYPE_ENUM.BuildingConsentAndPIM,
            consentStatus: BUILDING_CONSENT_STATUS_ENUM.Pending,
        });
        this.openCreateDialog({
            mappedItem: buildingConsent,
            canAddPrimaryBuildingConsent: !this.lotConsentViewService.hasPrimaryBuildingConsent,
        });
    }

    public createResourceConsent(): void {
        if (!this.canCreateConsent()) {
            return;
        }
        const resourceConsent = this.resourceConsetLogic.mapResourceConsent({
            lotId: this.lotDto.id,
            councilId: this.lotDto.councilId,
            consentFor: 'Resource Consent',
            consentStatus: RESOURCE_CONSENT_STATUS_ENUM.Pending,
        });
        this.openCreateDialog({
            mappedItem: resourceConsent,
            canAddPrimaryBuildingConsent: false,
        });
    }

    public requestBuildingConsent(): void {
        if (!this.lotDto.hasCompletedWorkingDrawing || this.lotConsentViewService.hasPrimaryBuildingConsent) {
            return;
        }
        this.cbDialog
            .open(RequestConsentDialogComponent, {
                data: {
                    lotId: this.lotDto.id,
                },
                minWidth: '33%',
            })
            .afterClosed()
            .subOnce(this.handlePostDialogRefresh);
    }

    private openCreateDialog(data: ICreateConsentDialogData): void {
        this.cbDialog
            .open(CreateConsentDialogComponent, { data, minWidth: '33%' })
            .afterClosed()
            .subOnce(this.handlePostDialogRefresh);
    }

    private readonly handlePostDialogRefresh = (result: boolean | IBuildingConsentDto | IResourceConsentDto): void => {
        if (result) {
            this.itemAdded.emit(result as IBuildingConsentDto | IResourceConsentDto);
        }
    };

}
