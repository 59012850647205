import {LeadLogicService} from '@app/logic/leads';
import {AfterViewInit, ChangeDetectorRef, Directive, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {LeadSearchService} from '@app/core/services/search/lead-search.service';
import {ILeadMappedItem} from '@app/logic/leads/interfaces/i.lead.mapped';
import {BaseInputFindDialogDirective} from '../../base-input-find-dialog.component';
import {InputFindType} from '../../../input-find-type.enum';

interface IData {
    mappedItem: ILeadMappedItem;
    searchService: LeadSearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

@Directive()
export class LeadsInputFindDialogDirective extends BaseInputFindDialogDirective<LeadLogicService> implements AfterViewInit {
    constructor(
        public readonly dialogRef: MatDialogRef<LeadsInputFindDialogDirective>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public logicService: LeadLogicService,
        public cdRef: ChangeDetectorRef
    ) {
        super(
            dialogRef,
            data,
            logicService,
            cdRef,
            InputFindType.Lead,
        );
    }

    public ngAfterViewInit(): void {
        this.data.searchService.infiniteScrollContainer = this.scrollContainer;
        this.data.searchService.extraSearchParams = this.data.extraSearchParams;
        this.data.searchService.logicService = this.logicService;
        if (this.data.searchService.isPagination) {
            this.data.searchService.nextPage$.next({ addPage: 1 });
        } else {
            this.data.searchService.doSearchIfNoScrollBar();
        }
        this.cdRef.detectChanges();
    }
}

