import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { BaseFormViewDirective } from './base-form-view.directive';
import { OnDestroy, ElementRef, Directive } from '@angular/core';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { TableComponent, USER_CACHE_AREA } from 'cb-hub-lib';
import * as $ from 'jquery';

export interface ISearchFilters {
    currentPage: number;
    query: string;
    [x: string]: any;
}

export class SearchFilters implements ISearchFilters {
    public currentPage: number;
    public query: string;
    [x: string]: any;
}

/**
 * This directive can be used as a base directive for views that perform searches
 */
@Directive()
export abstract class BaseSearchViewDirective<TUserCacheItem> extends BaseFormViewDirective<any, any, any> implements OnDestroy {
    public dialogResult: (param: any) => void;
    public onSearch: () => void;
    public isVisible: boolean;
    public isDialog: boolean;
    public cardType: string;
    public noMoreResults = false;
    public currentPage = 0;
    public searchResults: any[];
    public searchIsLoading = false;
    public permissions: any;
    public expanded = true;
    private hasReceivedResults: boolean;
    public ignoreEmptyQueries = true;
    public pageSize = 10;
    protected doSearchInterval = 100;
    public isElasticSearch = true;
    public queryUpdate = new Subject<string>();
    public infiniteScrollContainer: ElementRef;
    public infiniteScrollTable: TableComponent;
    public onReceivedSearchResults: (results: any) => any;

    public searchResultsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public searchResultsLoaded = false;
    public readonly subscriptions$ = new Subscription();
    public get userCacheItem(): UserCacheItem<TUserCacheItem> {
        return this.userCacheService && this.userCacheService.all[this.userCacheArea] as any;
    }

    public get scrollElement(): HTMLElement {
        if (this?.infiniteScrollTable?.infiniteScrollContainer?.nativeElement) {
            // Angular DOM elements are too buggy, clientHeight and scrollHeight becomes 0 eventually, breaking infinite scroll, just get the plain HTML element without angular
            return $('#infiniteScrollContainer')[0];
        }
        return this?.infiniteScrollContainer?.nativeElement as HTMLElement ?? document.getElementById('mainContainer');
    }

    private doSearchIntervalTimeout: NodeJS.Timeout;
    private destroyed = false;

    constructor(
        protected entityTypelogicService: any,
        public readonly toastService: ToastService,
        public readonly userCacheService?: UserCacheService,
        public readonly userCacheArea?: USER_CACHE_AREA
    ) {
        super(toastService);
        this.entityTypelogicService = entityTypelogicService;
    }

    public sortSearchResults(searchResults: Array<any>): Array<any> { return searchResults; }

    public ngOnDestroy(): void {
        this.destroyed = true;
        this.subscriptions$.unsubscribe();
        clearInterval(this.doSearchIntervalTimeout);
    }

    protected getSearchParams(): TUserCacheItem {
        throw new Error('Method not implemented');
    }

    public filtersUpdated = (): void => {
        if (this.searchIsLoading) {
            return;
        }
        this.currentPage = 0;
        this.searchResults = undefined;
        this.noMoreResults = false;
        this.doSearch();
    };

    public doSearchIfNoScrollBar(): void {
        clearInterval(this.doSearchIntervalTimeout);
        this.doSearchIntervalTimeout = setInterval(() => {
            if (this.destroyed) {
                clearInterval(this.doSearchIntervalTimeout);
                return;
            }

            let isVerticalScrollbar: boolean;
            if (this.infiniteScrollTable) {
                isVerticalScrollbar = this.scrollElement.scrollHeight > this.scrollElement.clientHeight;
            } else {
                const root = document.compatMode === 'BackCompat' ? document.body : document.documentElement;
                isVerticalScrollbar = this.scrollElement.scrollHeight > root.clientHeight;
            }

            if (!isVerticalScrollbar && !this.noMoreResults) {
                this.doSearch();
            } else {
                clearInterval(this.doSearchIntervalTimeout);
            }
        }, this.doSearchInterval);
    }

    public doSearch = (doSearch = false): void => {
        if (doSearch === true) {
            this.resetSearch();
        }
        if (doSearch || this.shouldDoSearch()) {
            this.doSearchIfNoScrollBar();
            this.currentPage++;
            this.searchIsLoading = true;
            this.entityTypelogicService
                .$getSearchList({
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    ...this.getSearchParams()
                }).subOnce(this.handleSearchResult, this.handleSearchError);
        }
    };

    private resetSearch() {
        this.currentPage = 0;
        this.searchResults = [];
        this.searchIsLoading = false;
    }

    protected shouldDoSearch(): boolean {
        if (this.isDialog !== true) {
            return !this.searchIsLoading && !this.noMoreResults;
        }

        return this.isVisible;
    }

    protected handleSearchResult = (results: any): void => {
        this.searchResultsLoaded = true;
        this.searchResultsLoaded$.next(true);
        this.searchIsLoading = false;
        if (!this.searchResults) {
            this.searchResults = [];
        }
        const resultsConst = results.items || results;
        if ((!this.isElasticSearch && this.hasReceivedResults) || !resultsConst.length) {
            this.noMoreResults = true;
        } else {
            this.searchResults = this.sortSearchResults(this.searchResults.concat(resultsConst));
        }
        this.hasReceivedResults = true;
        if (this.onReceivedSearchResults) {
            this.onReceivedSearchResults(this.searchResults);
        }
    };

    protected handleSearchError = (): void => {
        this.searchResultsLoaded = true;
        this.searchResultsLoaded$.next(true);

        this.searchIsLoading = false;
        this.noMoreResults = true;
    };

    protected loadSearchParams(): void {
        if (this.userCacheItem) {
            this.userCacheItem.init().then(() => {
                this.filtersUpdated();
                this.subscriptions$.add(
                    this.userCacheItem.updated$.subscribe({
                        next: () => {
                            this.filtersUpdated();
                        }
                    })
                );
            });
        }
    }
}
