import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem } from '@app/logic/lots';
import { IWorkingDrawingMappedItem } from '@app/logic/working-drawings';
import { IWorkingDrawingsLogicService } from '@app/logic/working-drawings/interfaces/i.working-drawings-logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IWorkingDrawingDto, LotTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-working-drawing-dialog',
    templateUrl: './working-drawing-dialog.component.html',
    styleUrls: ['./working-drawing-dialog.component.scss']
})
export class WorkingDrawingDialogComponent extends BaseDialogFormViewDirective<IWorkingDrawingDto, IWorkingDrawingMappedItem, IWorkingDrawingsLogicService> {

    public static readonly MIN_WIDTH = '66%';
    public mode: 'Create' | 'Manage' = 'Create';

    public get mappedItem(): IWorkingDrawingMappedItem {
        return this.data.mappedItem;
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.data.lotMappedItem;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<WorkingDrawingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IWorkingDrawingMappedItem;
            lotTypeEnumId: LotTypeEnumId;
            lotMappedItem: ILotMappedItem;
        },
        public toastService: ToastService,
        public readonly cbDialog: CbDialogService
    ) {
        super(dialogRef, toastService, cbDialog);
        if (this.mappedItem?.id > 0) {
            this.mode = 'Manage';
        }
    }

    public saveAndSetPristine(form: NgForm): void {
        super.save();
        form.form.markAsPristine();
    }

}
