import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IQuoteMappedItem, IQuoteLogicService, QuoteLogicService } from '@app/logic/quote';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { COST_NATURE_ENUM, IQuoteDto, IQuoteLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import _, { Dictionary, sortBy } from 'lodash';
import { LotQuoteVariationOrdersDialogComponent } from '../lot-quote-variation-orders-dialog/lot-quote-variation-orders-dialog.component';

@Component({
    selector: 'cb-lot-quote-submit-dialog',
    templateUrl: './lot-quote-submit-dialog.component.html',
    styleUrls: ['./lot-quote-submit-dialog.component.scss']
})
export class LotQuoteSubmitDialogComponent extends BaseDialogFormViewDirective<IQuoteDto, IQuoteMappedItem, IQuoteLogicService> {

    public static readonly MIN_WIDTH = '600px';
    public COST_NATURE_ENUM = COST_NATURE_ENUM;

    public variationsToGenerate: IQuoteLineDto[][] | Dictionary<IQuoteLineDto[]>;
    public keys: string[];

    constructor(
        public readonly dialogRef: MatDialogRef<LotQuoteVariationOrdersDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IQuoteMappedItem,
        @Inject(QuoteLogicService) public readonly logicService: QuoteLogicService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = data;
        this.groupVariationsAsObject();
    }

    public canGenerateChangesForQuoteLines(): boolean {
        return this.mappedItem.changeRecordRequired;
    }

    public getNoChangesMessage(): string {
        let message = 'No Changes being generated';
        if (!this.canGenerateChangesForQuoteLines()) {
            message += ' because this Lot has not been locked';
        }
        return message;
    }

    public isVariationsEmpty(): boolean {
        return Object.keys(this.variationsToGenerate).length <= 0;
    }

    public groupVariationsAsObject(): void {
        const sortedQuoteLines = sortBy(this.mappedItem.quoteLines, 'costNatureId');

        this.variationsToGenerate = this.mappedItem.createMultipleChangeRecords
            ? sortedQuoteLines.map(x => [x])
            : _.groupBy(this.mappedItem.quoteLines, 'costNatureId');

        this.keys = Object.keys(this.variationsToGenerate);
    }


    public calcVariationTotal(quoteLines: IQuoteLineDto[]): number {
        return quoteLines.map(q => Number(q.allocatedAmount) - Number(q.assignedAmount)).reduce((a, b) => a + b);
    }

    public submitQuote(): void {
        this.dialogRef.close(true);
    }

}
