import { IDocumentTypeDto } from '@app/logic/document-types';
import { ILocationDto } from '@app/logic/location';
import { ITradeTypeDto } from '@app/logic/trade-types/interfaces/i.trade-type.dto';
import { ISupplyTypeDto } from '@app/logic/supply-types/interfaces/i.supply-type.dto';
import { IAddressDto } from '@app/shared/interfaces/i.address.dto';
import { BusinessAccountStatusEnumId, BusinessAccountTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

export interface IBusinessAccountDto {
    id: number;
    name: string;
    isActive: boolean;
    bundleItems: any[];
    createdDate: string;
    createdByName: string;
    updatedDate: string;
    updatedByName: string;
    tradingName: string;
    isSupplier: boolean;
    isInstaller: boolean;
    email: string;
    legalName: string;
    phoneNumber: string;
    status: number;
    regions: Array<number>;
    accountCode: string;
    locations: number[] | any[];
    businessAccountTradeTypes: any[];
    businessAccountSupplyTypes: any[];
    reason: string;
    sevenDayPaymentRequest: any;
    canRequest7Days: boolean;
    availableHolds: any[];
    holds: any[];
    canCloseAccount: boolean;
    allRequiredDocumentsUploaded: boolean;
    anyDocumentRequiresReview: boolean;
    approvalOverrideRequest: any;
    approvedByHealthAndSafetyDate: string;
    approvedByHealthAndSafetyUser: string;
    approvedByProcurementDate: string;
    approvedByProcurementUser: string;
    businessName: string;
    canHealthSafetyApprove: boolean;
    canProcurementApprove: boolean;
    canRequestApprovalOverride: boolean;
    canSubmitForApproval: boolean;
    contractsStatus: number;
    creditorsContactExistsOnAccount: boolean;
    declinedByTag: string;
    declinedByUser: string;
    declinedDate: string;
    declinedReason: string;
    defaultLocationId: number;
    gstNumber: string;
    healthAndSafetyStatus: number;
    healthandSafetyRequestDeclinedByUser: string;
    healthandSafetyRequestDeclinedDate: string;
    healthandSafetyRequestDeclinedReason: string;
    isParentAccount: boolean;
    isRatesOnly: boolean;
    ordersContactExistsOnAccount: boolean;
    parentAccount: any;
    parentAccountId: number;
    paymentFrequency: number;
    physicalAddress: any;
    postalAddress: any;
    requestApprovedByUser: string;
    requestApprovedDate: string;
    requestCancelledByUser: string;
    requestCancelledDate: string;
    requestDeclinedByUser: string;
    requestDeclinedDate: string;
    requestDeclinedReason: string;
    requestedBy: string;
    requestedById: string;
    requestedDate: string;
    selectedRegions: any;
    shouldMaintainRequiredDocumentsOnChildAccounts: boolean;
    shouldMaintainRequiredDocumentsOnParentAccount: boolean;
    tradeTypes: number[];
    supplyTypes: number[];
    bankName: string;
    bankAccountNumber: string;
    abmCode: string;
    parentDocsMaintainedOn: number;
}

export interface IDocTypeTradeTypeDto {
    id: number;
    tradeType: ITradeTypeDto;
    documentTypeIds: Array<number>;
    documentTypes: Array<IDocumentTypeDto>;
}

export interface IDocTypeSupplyTypeDto {
    id: number;
    supplyType: ISupplyTypeDto;
    documentTypeIds: Array<number>;
    documentTypes: Array<IDocumentTypeDto>;
}

export interface IDocTypeBusinessAccountTypeDto {
    id: BusinessAccountTypeEnumId;
    documentTypeIds: Array<number>;
    documentTypes: Array<IDocumentTypeDto>;
}

export interface IBusinessAccountContactDto {
    id: number;
    receivesDailyEmails: boolean;
    regions: number[];
    districts: number[];
    suburbs: number[];
    areas: any[];
    tradeTypes: number[];
    contactId: string;
    position: string;
    contactTypes: number[];
    name: string;
    email: string;
    phoneMobile: string;
    contactComplianceRegistrations: string;
    isPortalAccessEnabled: boolean;
}

export interface IBusinessAccountLocationDto {
    id?: number;
    name: string;
    contactType: string;
    contactTypeId: number;
    contactTypeIcon: string;
    email: string;
    position: string;
    phoneMobile: string;
    region: number;
    regionLabel: string;
    district: number;
    districtLabel: string;
    area: number;
    areaLabel: string;
    locationLabel?: string;
    tradeTypes: number[];
    location?: ILocationDto;
    emailAddress?: string;
    tradeTypeId?: number;
}

export interface IBusinessAccountHoldDto {
    comments: string;
    holdAction: number;
    holdDate: string;
    holdPlaceBy: string;
    holdType: number;
    id: number;
}

export interface IBusinessAccountBaseDto {
    businessName: string;
    tradingName: string;
    isSupplier: boolean;
    isInstaller: boolean;
    gstNumber: string;
    physicalAddress: IAddressDto;
    postalAddress: IAddressDto;
    status: BusinessAccountStatusEnumId;
    email: string;
    phoneNumber: string;
}

export interface IBusinessAccountEditBaseDto extends IBusinessAccountBaseDto {
    isRatesOnly: boolean;
    tradeTypes: number[];
    supplyTypes: number[];
    requestedById: string;
    locations: number[];
    reason: string;
    abmCode: string;
    id?: number;
}

export interface IBusinessAccountTradeTypeDto {
    id?: number;
    parentId?: number;
    parentLabel?: string;
    children?: ITradeTypeDto[];
}

export interface IBusinessAccountSupplyTypeDto {
    id?: number;
    parentId?: number;
    parentLabel?: string;
    children?: ISupplyTypeDto[];
}

export enum ParentDocsMaintainance {
    BothParentAndChildAccounts = 1,
    ParentAccountOnly = 2,
    ChildAccountsOnly = 3
}
