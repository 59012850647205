<mat-tab-group (selectedIndexChange)="onTabChanged($event);"
               [selectedIndex]="selectedTabIndex"
               cbTabUrlParam="cbTabRouteId"
               [cbTabRoute]="DESIGN_TEAM_ROUTE">
    <mat-tab label="Schemes"
             cbTabRouteId="schemes"
             cbNestedTabLevels="0">
        <ng-template matTabContent>
            <cb-design-team-schemes *cbWaitFor="isViewInit"
                                    [resultDirections$]="resultDirections$">
            </cb-design-team-schemes>
        </ng-template>
    </mat-tab>
    <mat-tab label="Pre-consent Plans"
             cbTabRouteId="preconsent-plans"
             cbNestedTabLevels="0">
        <ng-template matTabContent>
            <cb-design-team-preconsent-plans *cbWaitFor="isViewInit"
                                             [resultDirections$]="resultDirections$">
            </cb-design-team-preconsent-plans>
        </ng-template>
    </mat-tab>
    <mat-tab label="Working Drawings"
             cbTabRouteId="working-drawings"
             cbNestedTabLevels="0">
        <ng-template matTabContent>
            <cb-design-team-working-drawings *cbWaitFor="isViewInit"
                                             [resultDirections$]="resultDirections$">
            </cb-design-team-working-drawings>
        </ng-template>
    </mat-tab>
    <mat-tab label="Council Rfis"
             cbTabRouteId="council-rfis"
             cbNestedTabLevels="0">
        <ng-template matTabContent>
            <cb-design-team-council-rfis *cbWaitFor="isViewInit"
                                         [resultDirections$]="resultDirections$">
            </cb-design-team-council-rfis>
        </ng-template>
    </mat-tab>
    <mat-tab label="Questions"
             cbTabRouteId="questions"
             cbNestedTabLevels="0">
        <ng-template matTabContent>
            <cb-teams-questions *cbWaitFor="isViewInit"
                                [resultDirections$]="resultDirections$"
                                [teamCode]="TEAM_CODES_CONST.DesignTeamKey">
            </cb-teams-questions>
        </ng-template>
    </mat-tab>
    <mat-tab label="Changes"
             cbTabRouteId="changes"
             cbNestedTabLevels="0">
        <ng-template matTabContent>
            <cb-teams-changes *cbWaitFor="isViewInit"
                              [resultDirections$]="resultDirections$"
                              [teamCode]="TEAM_CODES_CONST.DesignTeamKey">
            </cb-teams-changes>
        </ng-template>
    </mat-tab>
    <mat-tab label="Image Requests"
             cbTabRouteId="image-requests"
             cbNestedTabLevels="0">
        <ng-template matTabContent>
            <cb-design-team-image-requests *cbWaitFor="isViewInit"
                                           [resultDirections$]="resultDirections$">
            </cb-design-team-image-requests>
        </ng-template>
    </mat-tab>
    <mat-tab label="Tasks"
             cbTabRouteId="tasks"
             cbNestedTabLevels="0">
        <ng-template matTabContent>
            <cb-teams-tasks *cbWaitFor="isViewInit"
                            [resultDirections$]="resultDirections$"
                            [teamCode]="TEAM_CODES_CONST.DesignTeamKey">
            </cb-teams-tasks>
        </ng-template>
    </mat-tab>
    <mat-tab label="Reports"
             cbTabRouteId="reports"
             cbNestedTabLevels="0"
             *ngIf="designTeamPermissions.canViewDesignTeamReports()">
        <ng-template matTabContent>
            <cb-revision-report *cbWaitFor="isViewInit">
            </cb-revision-report>
        </ng-template>
    </mat-tab>
</mat-tab-group>
