import { Component, OnInit } from '@angular/core';
import { PaymentTemplatesLogicService } from '@app/logic/payment-templates/payment-templates.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IPaymentTemplateMappedItem } from '@app/logic/payment-templates/interface/i.payment-template.mapped';
import { PermissionsPermissions } from '@app/core/permissions';
import { PaymentTemplateDialogComponent } from '../payment-template-dialog/payment-template-dialog.component';
import { IPromised, toPromisedArray } from 'cb-hub-lib';
import { orderBy } from 'lodash';

@Component({
    selector: 'cb-payment-templates',
    templateUrl: './payment-templates.component.html',
    styleUrls: ['./payment-templates.component.scss']
})
export class PaymentTemplatesComponent implements OnInit {

    public paymentTemplates: IPromised<IPaymentTemplateMappedItem[]>;
    public columns = [
        'Template Name',
        'Active',
        ''
    ];

    constructor(
        private readonly paymentTemplatesLogic: PaymentTemplatesLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly permissions: PermissionsPermissions,
    ) { }

    public ngOnInit(): void {
        this.loadPaymentTemplates();
    }

    public canEdit(): boolean {
        return this.permissions.canEditSettings();
    }

    public addPaymentTemplate(): void {
        this.editPaymentTemplate(this.paymentTemplatesLogic.$createMappedItem());
    }

    public editPaymentTemplate(paymentTemplate: IPaymentTemplateMappedItem): void {
        const isNew = !paymentTemplate.id;
        if (paymentTemplate?.stages) {
            paymentTemplate.stages = orderBy(paymentTemplate.stages, ['sortOrder'], ['asc']);
        }
        this.cbDialog
            .open(
                PaymentTemplateDialogComponent,
                {
                    data: {
                        mappedItem: paymentTemplate.$clone(),
                    },
                    minWidth: '40%',
                }
            )
            .afterClosed()
            .subOnce((result) => {
                if (result && isNew) {
                    this.loadPaymentTemplates();
                }
            });
    }


    private loadPaymentTemplates(): void {
        this.paymentTemplates = toPromisedArray(this.paymentTemplatesLogic.$getMappedList());
    }
}
