import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IPreConsentPlanMappedItem } from '@app/logic/pre-consent-plans';
import { PRECONSENT_PLAN_STATUS_ENUM, SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import {LotDesignPermissions} from '@app/core/permissions';
import {IDocumentEntityMappedItem} from '@app/logic/documents';

@Component({
    selector: 'cb-manage-pre-consent-tabs',
    templateUrl: './manage-pre-consent-tabs.component.html',
    styleUrls: ['./manage-pre-consent-tabs.component.scss']
})
export class ManagePreConsentTabsComponent implements OnInit {
    @Output() public selectedTabChanged = new EventEmitter<number>();
    @Output() public documentsChanged = new EventEmitter<IDocumentEntityMappedItem[]>();
    @Input() public selectedIndex: number;

    private _mappedItem: IPreConsentPlanMappedItem;
    @Input() public set mappedItem(item: IPreConsentPlanMappedItem) {
        this._mappedItem = item;
    }
    public get mappedItem(): IPreConsentPlanMappedItem {
        return this._mappedItem;
    }

    public documentCount = 0;
    public questionsCount = 0;
    private readonly questionsTabIndex = 3;
    public readonly SYSTEM_AREA = SYSTEM_AREA_ENUM.PreConsentPlan;
    constructor(
        private readonly navigationService: NavigationService,
        public readonly lotDesignPermissions: LotDesignPermissions
    ) {
    }

    public ngOnInit(): void {
        const params = this.navigationService.getQueryParams();
        if (params?.qnsId) {
            this.selectedIndex = this.questionsTabIndex;
            this.navigationService.clearQueryParams();
        }
    }

    public onTabChanged(idx): void {
        this.selectedTabChanged.emit(idx);
    }

    public get canAddQuestions(): boolean {
        return this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Rejected
            || this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Pending
            || this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.InQueueForRework
            || this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.InProgress
            || this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.InQueue
            || this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.OnHold;
    }
}
