import { Injectable, Injector } from '@angular/core';
import {
    ILotSpecCreateItemUpdateItemDto,
    ILotSpecDto,
    ILotSpecItemDto,
    ILotSpecItemManageAttributeDto,
    ISaveableLotSpecItemAttributeManageDto,
    ISkinnySpecTemplateDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, map } from 'rxjs';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ILotSpecItemUpdateItemDto} from './interfaces/i.lot-spec-order.dto';
import { ILotSpecItemColourYourDreamsDto } from './interfaces/i.lot-spec.dto';
import { ILotSpecItemColourYourDreamsLogicService, ILotSpecLogicService } from './interfaces/i.lot-spec.logic.service';
import { ILotSpecItemColourYourDreamsMappedItem, ILotSpecMappedItem } from './interfaces/i.lot-spec.mapped';
import { LotSpecItemColourYourDreamsMappedItem, LotSpecMappedItem } from './lot-spec.mapped';

@Injectable()
export class LotSpecItemColourYourDreamsLogicService
    extends BaseLogicService<ILotSpecItemColourYourDreamsDto, ILotSpecItemColourYourDreamsMappedItem>
    implements ILotSpecItemColourYourDreamsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'lotspecs',
            LotSpecItemColourYourDreamsMappedItem
        );
    }

    public createMappedItem(entityId: number, item: ILotSpecItemColourYourDreamsDto): ILotSpecItemColourYourDreamsMappedItem {
        const result = this.$createMappedItem(item);
        result.lotId = entityId;
        return result;
    }


    public getMappedLotSpecItemColourYourDreams(entityId: number): Observable<ILotSpecItemColourYourDreamsMappedItem[]> {
        return this.$http.get<ILotSpecItemColourYourDreamsDto[]>(`${this.$baseUri}/${entityId}/colouryourdreams`)
            .pipe(
                map(results => results.map(item => this.createMappedItem(entityId, item)))
            );
    }

    public getMappedLotSpecSheduleItemColorSchedule(entityId: number): Observable<ILotSpecItemColourYourDreamsMappedItem[]> {
        return this.$http.get<ILotSpecItemColourYourDreamsDto[]>(`/${entityId}/lotspecschedule/colourschedule`)
            .pipe(
                map(results => results.map(item => this.createMappedItem(entityId, item)))
            );
    }

    public updateColourItems(entityId: number, updateDto: ILotSpecItemUpdateItemDto): Observable<ILotSpecItemColourYourDreamsMappedItem[]> {
        return this.$http.post<Array<ILotSpecItemColourYourDreamsMappedItem>>(`${this.$baseUri}/${entityId}/colouryourdreams/update`, updateDto)
            .pipe(
                map(results => results.map(item => this.createMappedItem(entityId, item)))
            );

    }

    public updateColourSchedule(entityId: number, updateDto: ILotSpecItemUpdateItemDto): Observable<ILotSpecItemColourYourDreamsMappedItem[]> {
        return this.$http.post<Array<ILotSpecItemColourYourDreamsMappedItem>>(`/${entityId}/lotspecschedule/colourschedule`, updateDto)
            .pipe(
                map(results => results.map(item => this.createMappedItem(entityId, item)))
            );
    }

    public updateCreateSchedule(entityId: number, updateDto: ILotSpecCreateItemUpdateItemDto): Observable<ILotSpecItemColourYourDreamsMappedItem[]> {
        return this.$http.post<Array<ILotSpecItemColourYourDreamsMappedItem>>(`/${entityId}/lotspecschedule/createschedule`, updateDto)
            .pipe(
                map(results => results.map(item => this.createMappedItem(entityId, item)))
            );
    }

}

@Injectable()
export class LotSpecLogicService
    extends BaseLogicService<ILotSpecDto, ILotSpecMappedItem>
    implements ILotSpecLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'lotspecs',
            LotSpecMappedItem
        );
    }

    public $getItem(lotOrLotSpecId: number): Observable<ILotSpecDto> {
        return super.$getItem(lotOrLotSpecId);
    }

    public $getMappedItem(lotOrLotSpecId: number): Observable<ILotSpecMappedItem> {
        return super.$getMappedItem(lotOrLotSpecId);
    }

    public getLotSpecItemsWithColourYourDreams(lotOrLotSpecId: number): Observable<ILotSpecItemColourYourDreamsDto[]> {
        return this.$http.get<ILotSpecItemColourYourDreamsDto[]>(`${this.$baseUri}/${lotOrLotSpecId}/colouryourdreams`);
    }

    public updateLotSpecItemsWithColourYourDreams(lotOrLotSpecId: number, dtos: ILotSpecItemColourYourDreamsDto[]): Observable<ILotSpecItemColourYourDreamsDto[]> {
        return this.$http.post<ILotSpecItemColourYourDreamsDto[]>(`${this.$baseUri}/${lotOrLotSpecId}/colouryourdreams`, dtos);
    }

    public getLotSpecAndColourItemsWithAttributes(lotOrLotSpecId: number): Observable<ILotSpecItemManageAttributeDto[]> {
        return this.$http.get<ILotSpecItemManageAttributeDto[]>(`${this.$baseUri}/${lotOrLotSpecId}/attributeitems`);
    }

    public updateLotSpecAndColourItemsWithAttributes(lotOrLotSpecId: number, dtos: ISaveableLotSpecItemAttributeManageDto[]): Observable<null> {
        return this.$http.post<null>(`${this.$baseUri}/${lotOrLotSpecId}/attributeitems`, dtos);
    }

    public getAvailableSpecTemplates(lotOrLotSpecId: number): Observable<ISkinnySpecTemplateDto[]> {
        return this.$http.get<ISkinnySpecTemplateDto[]>(`${this.$baseUri}/${lotOrLotSpecId}/spectemplates`);
    }

    public getLotSpecByVersion(lotOrLotSpecId: number, versionNumber: number): Observable<LotSpecMappedItem> {
        return this.$http.get<ILotSpecDto>(`${this.$baseUri}/${lotOrLotSpecId}/version/${versionNumber}`)
            .pipe(
                map(result => this.$createMappedItem(result, LotSpecMappedItem))
            );
    }

    public getLotSpecTemplatesByLotId(lotId: number): Observable<LotSpecMappedItem> {
        return this.$http.get<ILotSpecDto>(`${this.$baseUri}/${lotId}/spectemplates`)
            .pipe(
                map(result => this.$createMappedItem(result, LotSpecMappedItem))
            );
    }

    public applySpecTemplate(lotOrLotSpecId: number, templateId: number): Observable<ILotSpecDto> {
        return this.$http.post<ILotSpecDto>(`${this.$baseUri}/${lotOrLotSpecId}/applyspectemplate/${templateId}`);
    }

    public clearLotSpec(lotOrLotSpecId: number, isLotScheduleTemplate: boolean): Observable<ILotSpecDto> {
        if (isLotScheduleTemplate) {
            return this.$http.delete<ILotSpecDto>(`/lotschedule/${lotOrLotSpecId}/clear`);
        } else {
            return this.$http.delete<ILotSpecDto>(`${this.$baseUri}/${lotOrLotSpecId}/clear`);
        }
    }

    public getLotSpecItems(lotId: number): Observable<ILotSpecItemDto[]> {
        return this.$http.get<ILotSpecItemDto[]>(`lots/${lotId}/lotSpecItems`);
    }
}
