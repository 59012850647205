import {Observable, PartialObserver, Subscription} from 'rxjs';
import {takeOne} from '../lib/utils/observable.util';
import {extendPrototype} from './extension.util';

// Extension name definitions
export const SUB_ONCE = 'subOnce';

/** rxjs with ClassicHub Module Augmentations */
declare module 'rxjs' {
    export interface Observable<T> {
        /** Shorthand for `observable.pipe(take(1)).subscribe(observer)` */
        subOnce(observer?: PartialObserver<T>): Subscription;

        /** Shorthand for `observable.pipe(take(1)).subscribe(observer)` */
        // tslint:disable-next-line: unified-signatures
        subOnce(next?: (value: T) => void): Subscription;
    }
}

/** extension method for rxjs Observable */
export function subOnce<T>(observerOrNext?: PartialObserver<T> | ((value: T) => void)): Subscription {
    if (observerOrNext instanceof Function) {
        observerOrNext = {
            next: observerOrNext
        };
    }
    return this.pipe(takeOne()).subscribe(observerOrNext);
}

extendPrototype(Observable.prototype, SUB_ONCE, subOnce);
