<cb-dialog class="flex-col"
           dialogHeading="Request Lot/Concept Images">
    <div class="dialog-container">
        <form #form="ngForm"
              class="form-container flex-col flex">
            <span class="mat-body-1">Images to Request</span>
            <div class="checkbox-list flex-row">
                <cb-checkbox name="enableExteriorImages"
                             label="Exterior"
                             [(ngModel)]="selectedLotImageTypes[LOT_IMAGE_TYPE_ENUM.Exterior]"
                             [disabled]="selectedLotImageTypes[LOT_IMAGE_TYPE_ENUM.FloorPlan] === false"
                             class="cb-margin-top-2x cb-margin-right-2x">
                </cb-checkbox>
                <cb-checkbox name="enableFloorPlanImages"
                             label="Floor Plan"
                             [(ngModel)]="selectedLotImageTypes[LOT_IMAGE_TYPE_ENUM.FloorPlan]"
                             [disabled]="selectedLotImageTypes[LOT_IMAGE_TYPE_ENUM.Exterior] === false"
                             class="cb-margin-top-2x cb-margin-right-2x">
                </cb-checkbox>
            </div>
            <span class="mat-body-1">Lots to Request Images for</span>
            <cb-table class="cb-margin-top cb-margin-bottom-3x"
                      [loaded]="loaded">
                <thead cbTableHead>
                    <tr>
                        <td>Select</td>
                        <td>LOT</td>
                        <td>DP No.</td>
                        <td>Address</td>
                        <td>B Type</td>
                        <td>Lot Market Status</td>
                        <td>Job Status</td>
                    </tr>
                </thead>
                <tbody cbTableBody>
                    <tr cbTableRow
                        *ngFor="let lot of lots | cbSortByPipe: 'lotNumber': 'asc'; let $index = index">
                        <td>
                            <mat-checkbox [checked]="selectedLots[lot.id]"
                                          (change)="clicked(lot.id, form.form)"
                                          *ngIf="canCheck(lot)">
                            </mat-checkbox>
                            <mat-icon *ngIf="!canCheck(lot)"
                                      style="display: table-header-group;"
                                      title="This Lot does not have a concept to Request Images for">block</mat-icon>
                        </td>
                        <cb-td-text [value]="lot.lotNumber"></cb-td-text>
                        <cb-td-text [value]="lot.dpNumber"></cb-td-text>
                        <td>
                            {{lot.address.street | cbDasher}}
                            <br/>
                            {{lot.address.suburb | cbDasher}}
                        </td>
                        <td>
                            {{lot.buildType.label | cbDasher}}
                        </td>
                        <td style="white-space: nowrap;">
                            <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.Available">
                                <mat-icon class="cb-grey">thumb_up</mat-icon>
                                {{MARKET_STATUS_ENUM.Available}}
                            </ng-container>
                            <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.NotAvailable">
                                <mat-icon class="cb-grey">lock_outline</mat-icon>
                                {{MARKET_STATUS_ENUM.Available}}
                            </ng-container>
                            <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.PreRelease">
                                <mat-icon class="cb-grey">flag</mat-icon>
                                {{MARKET_STATUS_ENUM.Available}}
                            </ng-container>
                            <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.Sold">
                                <mat-icon class="cb-grey">monetization_on</mat-icon>
                                {{MARKET_STATUS_ENUM.Available}}
                            </ng-container>
                            <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.SaleLost">
                                <mat-icon class="cb-grey">thumbs_down</mat-icon>
                                {{MARKET_STATUS_ENUM.Available}}
                            </ng-container>
                        </td>
                        <cb-td-enum [value]="lot.jobStatus"
                                    [enum]="LOT_JOB_STATUS_ENUM">
                        </cb-td-enum>
                    </tr>
                    <tr *ngIf="lots?.length < 1 && loaded">
                        <td colspan="17">
                            <mat-icon class="cb-grey">info</mat-icon>
                            &nbsp;No Lots have been added to this Release
                            Stage
                        </td>
                    </tr>
                </tbody>
            </cb-table>
        </form>
    </div>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="form.invalid || form.pristine"
                (cbClick)="save()"
                [clickLimit]="1">upload</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
