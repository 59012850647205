import { BaseMappedItem } from '../base/base-mapped-item';
import { DesignImageType } from '../standard-plans/enums/design.image.type.enums';
import { IStandardPlanStyleDto } from './interfaces/i.style.dto';
import { IStyleMappedItem } from './interfaces/i.style.mapped';
import { IStylesLogicService } from './interfaces/i.style.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';

export class StyleMappedItem
    extends BaseMappedItem<IStandardPlanStyleDto, IStyleMappedItem, IStylesLogicService>
    implements IStyleMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public name: string;
    @DtoProp public url: string;
    @DtoProp public imageType: DesignImageType;
    @DtoProp public isPlaceHolderImage: boolean;

    constructor(
        sourceData: IStandardPlanStyleDto,
        logicService: IStylesLogicService
    ) {
        super(sourceData, logicService, StyleMappedItem);
    }
}
