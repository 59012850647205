import { Injectable, Injector } from '@angular/core';
import { ExternalSystemEnumId, IExternalSystemFieldTemplateItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';

import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ExternalSystemFieldMappedItem } from './external-system-fields.mapped';
import { IExternalSystemFieldsLogicService } from './interfaces/i.external-system-fields.logic-service';
import { IExternalSystemFieldMappedItem } from './interfaces/i.external-system-fields.mapped';

@Injectable()
export class ExternalSystemFieldsLogicService
    extends BaseLogicService<IExternalSystemFieldTemplateItemDto, IExternalSystemFieldMappedItem>
    implements IExternalSystemFieldsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'externalsystemfieldtemplateitems',
            ExternalSystemFieldMappedItem
        );
    }

    public $getList(): Observable<Array<IExternalSystemFieldTemplateItemDto>> {
        return this.$http
            .get<Array<IExternalSystemFieldTemplateItemDto>>(`${this.$baseUri}`);
    }

    public getListBySystemId(externalSystemId: ExternalSystemEnumId): Observable<Array<IExternalSystemFieldTemplateItemDto>> {
        return this.$http
            .get<Array<IExternalSystemFieldTemplateItemDto>>(`${this.$baseUri}/byexternalsystem/${externalSystemId}`);
    }

    public getActiveListBySystemId(externalSystemId: ExternalSystemEnumId): Observable<Array<IExternalSystemFieldTemplateItemDto>> {
        const params = { activeOnly: true };
        return this.$http
            .get<Array<IExternalSystemFieldTemplateItemDto>>(`${this.$baseUri}/byexternalsystem/${externalSystemId}`, params);
    }

    public saveOrder(ids: number[], externalSystemId: ExternalSystemEnumId): Observable<IExternalSystemFieldMappedItem[]> {
        const idsAndSortOrders: { [id: number]: number } = {};
        ids.forEach((id, i) => {
            idsAndSortOrders[id] = i + 1;
        });

        return this.$http
            .post<Array<IExternalSystemFieldTemplateItemDto>>(`${this.$baseUri}/sortforsystem/${externalSystemId}`, idsAndSortOrders)
            .pipe(
                map(items => items.map(x => this.$createMappedItem(x)))
            );
    }
}
