import { Component, Input } from '@angular/core';
import { ProductLogicService, IProductMappedItem } from '@app/logic/products';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { PRODUCT_TAB_FULL_ROUTE } from '@app/views/products/products-route.const';

@Component({
    selector: 'cb-item-usage-tab',
    templateUrl: './item-usage-tab.component.html',
    styleUrls: ['./item-usage-tab.component.scss'],
    providers: [NavigationService, UserCacheService, CbDialogService]
})
export class ItemUsageTabComponent {
    @Input() public mappedItem: IProductMappedItem;
    public readonly PRODUCT_TAB_FULL_ROUTE = PRODUCT_TAB_FULL_ROUTE;
    constructor(
        public readonly productLogicService: ProductLogicService
    ) {
    }
}
