import {Component, Input} from '@angular/core';

@Component({
    selector: 'cb-td-date[value]',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss', './../table-cell.scss']
})
export class TdDateComponent {
    @Input() public readonly value: string;
}
