import {Component} from '@angular/core';
import {ICategoryAttributeDto, IProductCategoryDto} from '@app/logic/product-categories/interfaces/i.product-category.dto';

import {CategoryLogicService} from '@app/logic/product-categories';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {IProductCategoryMappedItem} from '@app/logic/product-categories/interfaces/i.product-category.mapped';
import {ManageCategoryAttributeDialogComponent} from './manage-category-attribute-dialog/manage-category-attribute-dialog.component';
import {ManageProductCategoryDialogComponent} from './manage-product-category-dialog/manage-product-category-dialog.component';
import {Subject} from 'rxjs';
import {UserCacheService} from '@app/core/services/user-cache/user-cache.service';

export interface CategoryAddedSubject {
    category: IProductCategoryMappedItem;
    parentCategory: IProductCategoryMappedItem;
}

@Component({
    selector: 'cb-product-categories-tab',
    templateUrl: './product-categories-tab.component.html',
    styleUrls: ['./product-categories-tab.component.scss']
})
export class ProductCategoriesTabComponent {
    public selectedCategories;
    public currentlySelectedCategory: IProductCategoryMappedItem;
    public readonly displayedColumns: string[] = ['Option Name', 'Option Values', 'Is Active', ''];
    public readonly categoryEdited$: Subject<IProductCategoryDto> = new Subject<IProductCategoryDto>();
    public readonly categoryAdded$: Subject<CategoryAddedSubject> = new Subject<CategoryAddedSubject>();

    constructor(
        private readonly cbDialogService: CbDialogService,
        public readonly productCategoryLogicService: CategoryLogicService,
        public readonly userCacheService: UserCacheService) { }

    public addCategory(parentCategory?: IProductCategoryMappedItem): void {
        this.cbDialogService.open(
            ManageProductCategoryDialogComponent,
            {
                data: { parentCategory },
                fullWidth: true,
            }
        )
            .afterClosed()
            .subOnce({
                next: (x) => {
                    const catMappedItem = this.productCategoryLogicService.$createMappedItem(x);
                    this.handleCategoryAdded(undefined, catMappedItem);
                }
            });
    }

    public editCategory(category: IProductCategoryMappedItem): void {
        if (category) {
            category = category.$clone();
        }
        this.cbDialogService.open(
            ManageProductCategoryDialogComponent,
            {
                data: { mappedItem: category },
                fullWidth: true,
            }
        )
            .afterClosed()
            .subOnce({
                next: x => {
                    if (x) {
                        this.handleCategoryEdited(category);
                    }
                }
            });
    }

    public editCategoryAttribute(attribute: ICategoryAttributeDto): void {
        if (attribute) {
            attribute = JSON.parse(JSON.stringify(attribute));
        }
        this.cbDialogService.open(
            ManageCategoryAttributeDialogComponent,
            {
                data: {
                    mappedItem: this.currentlySelectedCategory,
                    attribute
                },
                fullWidth: true
            }
        )
            .afterClosed()
            .subOnce({
                next: result => {
                    if (result) {
                        if (this.currentlySelectedCategory.attributes.some(x => x.id === result.id)) {
                            this.currentlySelectedCategory.attributes.forEach(y => {
                                if (y.id === result.id) {
                                    y.isActive = result.isActive;
                                    y.isLocked = result.isLocked;
                                    y.options = result.options;
                                    y.name = result.name;
                                }
                            });
                        } else {
                            this.currentlySelectedCategory.attributes = [...this.currentlySelectedCategory.attributes, result];
                        }
                    }
                }
            });
    }

    private readonly handleCategoryAdded = (parentCategory: IProductCategoryMappedItem, category: IProductCategoryMappedItem): void => {
        this.categoryAdded$.next({ category, parentCategory });
    };

    private readonly handleCategoryEdited = (category: IProductCategoryMappedItem): IProductCategoryMappedItem => {
        category.label = category.name;
        this.categoryEdited$.next(category);
        return category;
    };

    public setSelectedCategory = (categoryParam: { selectedCategories: any[]; category: any }): void => {
        const { selectedCategories, category } = categoryParam;
        this.currentlySelectedCategory = this.productCategoryLogicService.$createMappedItem(category);
    };
}
