import {Injectable} from '@angular/core';
import {SlotsLogicService} from '@app/logic/slots/slots.logic-service';
import {takeOne} from 'cb-hub-lib';
import {ISlotDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {Observable} from 'rxjs';
import {ISlotSearchFilters} from '../user-cache/user-cache-areas';
import {SearchService} from './base.search.service';

@Injectable()
export class SlotSearchService extends SearchService {

    constructor(
        public readonly logicService: SlotsLogicService,
    ) {
        super();
        this.pageSize = null;
    }

    public getResults(query: string, currentPage: number): Observable<any[]> {
        return new Observable<any[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;
                const resultsObservable = this.logicService.$getSearchList({
                    query,
                    currentPage,
                    ...this.extraSearchParams
                } as ISlotSearchFilters);
                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults(x);
                        subscriber.next(this.searchResults$.value);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value);
                subscriber.complete();
            }
        });
    }

    public doSearch = (query: string, page: number): Observable<any[]> => {
        this.searchResultsLoaded = false;
        return this.getResults(query, page).pipe(takeOne());
    };

    public setSearchResults(searchResults: any): void {
        this.hasSearched = true;
        this.numPages = Math.ceil(searchResults.total / 10);
        this.totalItemCount = searchResults.total;
        if (this.isPagination) {
            this.searchResults$.next(searchResults.items);
        } else {
            const currentResults = this.searchResults$.getValue();
            this.hasDuplicates = false;
            if (currentResults &&
                currentResults.length > 0 &&
                searchResults.items?.some((e: ISlotDto) => e.id === currentResults[0].id)
            ) {
                this.hasDuplicates = true;
            }

            if (searchResults?.items?.length === 0) {
                this.hasDuplicates = true;
            }

            if (this.totalItemCount < this.searchResults$?.value?.length) {
                this.hasDuplicates = true;
            }

            if (currentResults && this.currentPage > 1 && !this.hasDuplicates) {
                this.searchResults$.next(this.sortSearchResults(searchResults.items as any[]));
            } else if (currentResults && this.currentPage > 1 && this.hasDuplicates) {
                // It has searched one too many times, do nothing
            } else if ((searchResults.total > this.pageSize * this.currentPage) && (!currentResults || this.currentPage === 0)) {
                // they have typed, and it is the first search since they typed
                this.searchResults$.next(this.sortSearchResults(searchResults.items));
            } else if (searchResults.total > this.pageSize * this.currentPage && currentResults) {
                // they have typed, and scrolled, so it needs to concat results (have scrolled)
                this.searchResults$.next(this.sortSearchResults(searchResults.items as any[]));
            } else {
                this.searchResults$.next(this.sortSearchResults((searchResults.items)));
            }
        }
        if (!searchResults.items || searchResults.items.length < 1) {
            this.noMoreResults = true;
        }
        this.searchResultsLoaded = true;
    }
}
