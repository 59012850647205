import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { LotBuildPermissions } from '@app/core/permissions';
import { CurrentHttpRequestsService } from '@app/core/services/current-http-requests/current-http-requests.service';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { LotsLogicService } from '@app/logic/lots';
import { PurchaseOrdersLogicService } from '@app/logic/purchase-orders';
import { IPurchaseOrderDto } from '@app/logic/purchase-orders/interfaces/i.purchase-order.dto';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ViewManualOrderDialogComponent } from '@app/views/lot/build/orders/view-manual-order-dialog/view-manual-order-dialog.component';
import { ILotDto, LotTypeEnumId, LOT_JOB_STATUS_ENUM, MANUAL_PURCHASE_ORDER_ORIGIN_ENUM, SSR_STATE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { toPromise } from 'cb-hub-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { EditManualOrderDialogComponent } from '../../../orders/edit-manual-order-dialog/edit-manual-order-dialog.component';
import { LotBuildProgrammeActionBarService } from '../../services/lot-build-programme-action-bar.service';
import { CompleteBuildProgrammeActivitiesDialogComponent } from '../complete-build-programme-activities-dialog/complete-build-programme-activities-dialog.component';
import { FullscreenBuildProgrammeComponent } from '../fullscreen-build-programme/fullscreen-build-programme.component';
import { LotBuildProgrammeEventService } from './../../../services/lot-build-programme-event.service';
import { GenerateBuildProgrammeDialogComponent } from './../generate-build-programme-dialog/generate-build-programme-dialog.component';


@Component({
    selector: 'cb-lot-build-programme-action-bar',
    templateUrl: './lot-build-programme-action-bar.component.html',
    styleUrls: ['./lot-build-programme-action-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotBuildProgrammeActionBarComponent implements AfterViewInit {

    @Input() public lotDto: ILotDto;
    @Output() public readonly lotDtoChange = new EventEmitter<ILotDto>();

    @Input() public readonly fullscreen: boolean = false;
    @Input() public readonly hasAlerts: boolean = false;

    private _generateBuildProgrammeContainerPromise = new Promise((resolver) => {
        this._generateBuildProgrammeContainerResolver = resolver;
    });
    private _generateBuildProgrammeContainerResolver: (value?: unknown) => void;
    private _generateBuildProgrammeContainer: HTMLElement;
    @ViewChild('generateBuildProgrammeContainer', {}) private set generateBuildProgrammeContainer(x: ElementRef<HTMLElement>) {
        if (x?.nativeElement != null) {
            this._generateBuildProgrammeContainer = x.nativeElement;
            this._generateBuildProgrammeContainerResolver();
        }
    }

    public allItemsValidExceptBuildProgrammeTemplate = false;
    /** used to hide generate build programme button until view has intialised */
    public viewInitComplete = false;

    private _canGenerateBuildProgrammeButtonEnabled$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public get canGenerateBuildProgrammeButtonEnabled$(): Observable<boolean> {
        return this._canGenerateBuildProgrammeButtonEnabled$$;
    }



    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly purchaseOrderLogic: PurchaseOrdersLogicService,
        public readonly lotBuildProgrammeActionBar: LotBuildProgrammeActionBarService,
        public readonly userCache: UserCacheService,
        public readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
        public readonly buildProgrammeLogic: BuildProgrammeLogicService,
        private readonly lotsLogicService: LotsLogicService,
        private readonly lotBuildPermissions: LotBuildPermissions,
        private readonly ngZone: NgZone,
        private readonly currentRequests: CurrentHttpRequestsService,
        public readonly featureToggleStateService: FeatureToggleStatesService
    ) { }


    public ngAfterViewInit(): void {
        this.ngZone.run(() => {
            setTimeout(() => {
                Promise.all([
                    this.currentRequests.currentRequestsComplete(),
                    this.lotDto,
                    toPromise(this.lotBuildPermissions.observable),
                    this._generateBuildProgrammeContainerPromise,
                    this.checkAllItemsValidExceptBuildProgrammeTemplate()
                ]).then(() => {
                    this.viewInitComplete = true;
                    this._generateBuildProgrammeContainer.classList.remove('preload-hide');
                    this._evaluateGenerateBuildProgrammeButtonEnabled();

                });
            });
        });
    }

    public viewFullscreen(): void {
        const ref = this.cbDialog
            .open(FullscreenBuildProgrammeComponent, {
                data: {
                    lotDto: this.lotDto,
                },
                minWidth: '100%',
                minHeight: '100%',
                fullWidth: true,
            });

        ref.afterOpened()
            .subOnce(() => {
                this.lotBuildProgrammeActionBar.FULLSCREEN_OPEN.next(true);
            });
        ref.afterClosed()
            .subOnce(() => {
                this.lotBuildProgrammeActionBar.FULLSCREEN_OPEN.next(false);
            });
    }

    public viewAllStages(): void {
        this.lotBuildProgrammeActionBar.VIEW_ALL_STAGES_CHANGED.next(null);
    }

    public expandCollapseAll(): void {
        this.lotBuildProgrammeActionBar.EXPAND_COLLAPSE_ALL.next(null);
    }

    public generateMultiple(): void {
        this.cbDialog
            .open(CompleteBuildProgrammeActivitiesDialogComponent, {
                data: {
                    targetSsrState: SSR_STATE_ENUM.Draft,
                    buildStageId: null,
                }
            });
    }

    public setMultipleSsrCompleted(): void {
        this.cbDialog
            .open(CompleteBuildProgrammeActivitiesDialogComponent, {
                data: {
                    targetSsrState: SSR_STATE_ENUM.Completed,
                    buildStageId: null,
                }
            });
    }

    public setMultipleSsrConfirmed(): void {
        this.cbDialog
            .open(CompleteBuildProgrammeActivitiesDialogComponent, {
                data: {
                    targetSsrState: SSR_STATE_ENUM.Confirmed,
                    buildStageId: null,
                }
            });
    }

    public createManualOrder(): void {
        const mappedItem = this.purchaseOrderLogic.$createMappedItem({
            activityDuration: 1,
            lotId: this.lotDto.id,
            orderDate: new Date().toJSON(),
            originId: MANUAL_PURCHASE_ORDER_ORIGIN_ENUM.LotBuild,
        } as IPurchaseOrderDto);

        this.cbDialog
            .open(
                EditManualOrderDialogComponent,
                {
                    data: {
                        lotDto: this.lotDto,
                        mappedItem,
                        regionId: this.lotDto.location.region.id,
                    },
                    minWidth: '33%',
                }
            )
            .afterClosed()
            .subOnce((purchaseOrder: IPurchaseOrderDto) => {
                const buildProgrammeId = this.lotBuildProgrammeEvents?.buildProgramme?.id;
                if (!purchaseOrder || !buildProgrammeId || !purchaseOrder.buildProgrammeActivityId) {
                    return;
                }

                // refresh entire build programme because the sort order of all activties in this stage have changed
                this.lotBuildProgrammeEvents.reload();
                this.viewManualOrder(purchaseOrder);
            });
    }

    private viewManualOrder(purchaseOrder: IPurchaseOrderDto): void {
        const mappedItem = this.purchaseOrderLogic.$createMappedItem(purchaseOrder);
        this.cbDialog
            .open(
                ViewManualOrderDialogComponent,
                {
                    data: {
                        mappedItem,
                        origin: MANUAL_PURCHASE_ORDER_ORIGIN_ENUM.LotBuild,
                        lotDto: this.lotDto,
                        permissions: this.lotBuildPermissions,
                    },
                    minWidth: '66%',
                }
            )
            .afterClosed()
            .subOnce(result => {
                this.lotBuildProgrammeEvents
                    ?.activityIndex[result.buildProgrammeActivityId]
                    ?.$reload()
                    ?.subOnce();
            });
    }

    public generateBuildProgramme(): void {
        if (!this._canGenerateBuildProgrammeButtonEnabled$$.value) {
            return;
        }
        this.cbDialog
            .open(GenerateBuildProgrammeDialogComponent, {
                data: {
                    lotDto: this.lotDto,
                },
                width: '33%',
            })
            .afterClosed()
            .subOnce(this.handleGenerateBuildProgramme);
    }

    private readonly handleGenerateBuildProgramme = (result: boolean): void => {
        if (!result) {
            return;
        }
        const blockRef = this.cbDialog.block('Generating Build Programme...');
        this.buildProgrammeLogic
            .generateBuildProgramme(this.lotDto.id)
            .subOnce({
                next: () => {
                    this.lotBuildProgrammeEvents.BP_RECEIVED.subOnce(() => blockRef.close());
                    this.lotBuildProgrammeEvents.initLoad(this.lotDto.id);
                    this.lotsLogicService.$getItem(this.lotDto.id).subOnce((_lotDto) => {
                        this.lotDto = _lotDto;
                        this.lotDtoChange.emit(this.lotDto);
                    });
                },
                error: () => blockRef.close(),
            });
    };

    /** this is a function instead of computed property because it depends on the lot - unsure when to update it */
    private _evaluateGenerateBuildProgrammeButtonEnabled(): void {

        const value = this.viewInitComplete
            && this.lotDto?.id > 0
            && this.lotDto.hasCurrentTakeOff
            && !this.lotBuildProgrammeEvents?.buildProgramme?.hasBuildProgrammeTemplateApplied
            && this.lotBuildPermissions.canGenerateBuildProgramme()
            && this.allItemsValidExceptBuildProgrammeTemplate;
        if (value !== this._canGenerateBuildProgrammeButtonEnabled$$.value) {
            this._canGenerateBuildProgrammeButtonEnabled$$.next(value);
        }
    }

    public isJobBuildReportEnabled(): boolean {
        // Is the feature toggle on
        return this.featureToggleStateService.isJobBuildReportGenerationEnabled &&
            // Do we have a build programme?
            this.lotDto.hasBuildProgrammeGenerated &&
            this.lotDto.hasBuildProgrammeTemplateApplied &&
            // Do we have a takeoff?
            this.lotDto.hasCurrentTakeOff &&
            // Is this a Non-block unit?
            (this.lotDto.lotType === LotTypeEnumId.Standalone || this.lotDto.lotType === LotTypeEnumId.Land) &&
            // Is this a SIM lot?
            this.lotDto.hasScheduleSpecTemplate;
    }

    /* this is required to check Generate Build Programme Button since a Build Programme Template is generated once a Build Programme is generated */
    private checkAllItemsValidExceptBuildProgrammeTemplate(): Promise<void> {

        return new Promise((resolve) => {
            this.buildProgrammeLogic.getValidationItems(this.lotDto.id).subOnce(x => {
                const invalidItems = x.filter(i =>
                    i.fieldName !== 'Build Programme Template' &&
                    i.fieldName !== 'Block Level Activities' &&
                    i.inValid === true);
                const hasInvalidItems = invalidItems !== undefined && invalidItems?.length > 0;

                this.allItemsValidExceptBuildProgrammeTemplate = !hasInvalidItems;
                resolve();
            });
        });

    }

    public canCreateManualOrder(): boolean {
        return this.lotDto.hasBuildProgrammeGenerated ||
            this.lotDto.jobStatus === LOT_JOB_STATUS_ENUM.Consent ||
            this.lotDto.jobStatus === LOT_JOB_STATUS_ENUM.PreConsent ||
            this.lotDto.jobStatus === LOT_JOB_STATUS_ENUM.WorkingDrawings;
    }
}
