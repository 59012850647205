import {Component, Input} from '@angular/core';
import {FieldValidationItem} from '@app/core/services/field-validation/field.validation.item';
import {LOT_COMPONENT_TYPE} from '@app/core/services/field-validation/lot-field-validator/interfaces/i.lot.field.rules.service';
import {LotFieldValidatorService} from '@app/core/services/field-validation/lot-field-validator/lot-field-validator-service';
import {ILotMappedItem, LotsLogicService} from '@app/logic/lots';
import {ILotDto, LOT_TYPE_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {LotSummaryPinnablePanelDirective} from '../../shared/lot-summary-pinnable-panel';

@Component({
    selector: 'cb-lot-summary-build-details',
    templateUrl: './lot-summary-build-details.component.html',
    styleUrls: ['./lot-summary-build-details.component.scss']
})
export class LotSummaryBuildDetailsComponent extends LotSummaryPinnablePanelDirective {

    @Input() public lotMappedItem: ILotMappedItem;

    public parentLotDto: ILotDto;

    public LOT_TYPE_ENUM = LOT_TYPE_ENUM;

    // Field Validation Items
    public buildTypeField = new FieldValidationItem();
    public appliedDesignConceptField = new FieldValidationItem();
    public floorAreaField = new FieldValidationItem();
    public bedroomsField = new FieldValidationItem();
    public bathroomsField = new FieldValidationItem();
    public livingroomsField = new FieldValidationItem();
    public garagesField = new FieldValidationItem();
    public buildProgrammeTemplateField = new FieldValidationItem();
    public parentLotField = new FieldValidationItem();
    public estimatedStartDateField = new FieldValidationItem();
    public estimatedCompletionDateField = new FieldValidationItem();
    public actualStartDateField = new FieldValidationItem();
    public actualCompletionDateField = new FieldValidationItem();
    public landAreaField = new FieldValidationItem();

    constructor(
        private readonly lotFieldValidatorService: LotFieldValidatorService,
        private readonly lotsLogicService: LotsLogicService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.lotFieldValidatorService.dto = this.lotMappedItem.$getMappedDtoItem();
        this.populateFieldValidationItems();
        this.loadParentLot();
    }

    private populateFieldValidationItems(): void {
        this.buildTypeField = this.lotFieldValidatorService.buildType(LOT_COMPONENT_TYPE.BuildDetails);
        this.appliedDesignConceptField = this.lotFieldValidatorService.appliedDesignConcept(LOT_COMPONENT_TYPE.BuildDetails);
        this.floorAreaField = this.lotFieldValidatorService.amenitiesFloorArea(LOT_COMPONENT_TYPE.BuildDetails);
        this.bedroomsField = this.lotFieldValidatorService.amenitiesNoBedrooms(LOT_COMPONENT_TYPE.BuildDetails);
        this.bathroomsField = this.lotFieldValidatorService.amenitiesNoBathrooms(LOT_COMPONENT_TYPE.BuildDetails);
        this.livingroomsField = this.lotFieldValidatorService.amenitiesNoLivingRooms(LOT_COMPONENT_TYPE.BuildDetails);
        this.garagesField = this.lotFieldValidatorService.amenitiesNoGarages(LOT_COMPONENT_TYPE.BuildDetails);
        this.buildProgrammeTemplateField = this.lotFieldValidatorService.buildProgrammeTemplate(LOT_COMPONENT_TYPE.BuildDetails);
        this.landAreaField = this.lotFieldValidatorService.amenitiesLandArea(LOT_COMPONENT_TYPE.BuildDetails);
        this.estimatedStartDateField = this.lotFieldValidatorService.estimatedStartDate(LOT_COMPONENT_TYPE.BuildDetails);
        this.estimatedCompletionDateField = this.lotFieldValidatorService.estimatedCompletionDate(LOT_COMPONENT_TYPE.BuildDetails);
        this.actualStartDateField = this.lotFieldValidatorService.actualStartDate(LOT_COMPONENT_TYPE.BuildDetails);
        this.actualCompletionDateField = this.lotFieldValidatorService.actualCompletionDate(LOT_COMPONENT_TYPE.BuildDetails);
    }

    private loadParentLot(): void {
        if (this.lotMappedItem.parentId) {
            this.lotsLogicService.$getItem(this.lotMappedItem.parentId).subOnce((_lotParent) => {
                this.parentLotDto = _lotParent;
                this.parentLotField = this.lotFieldValidatorService.parentLot(LOT_COMPONENT_TYPE.BuildDetails);
            });
        }
    }
}
