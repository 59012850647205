<mat-card class="cb-margin flex-row flex">
    <mat-card-content class="flex-col flex flex-fill"
                      *cbWaitFor="mappedItem">
        <div class="end-end flex-row flex"
             *ngIf="!mappedItem.isCancelled()">
            <button mat-raised-button
                    color="primary"
                    class="cb-margin-right-2x"
                    (cbClick)="dialog(undefined)"
                    [disabled]="!projectReleaseStagePermissions.canEdit()">Add</button>
            <button mat-raised-button
                    color="primary"
                    (cbClick)="addAll()"
                    [disabled]="!projectReleaseStagePermissions.canEdit()">Reset</button>
        </div>
        <cb-table *cbWaitFor="mappedItem"
                  class="cb-margin-top-3x cb-margin-bottom-3x flex"
                  [loaded]="loaded">
            <thead cbTableHead>
                <tr>
                    <td>Supply Type</td>
                    <td>Business Account</td>
                    <td></td>
                </tr>
            </thead>
            <tbody cbTableBody>
                <tr *ngFor="let defaultSupplier of mappedItem.defaultProductSuppliers; let $index = index">
                    <td>{{defaultSupplier.supplyType.path}}</td>
                    <td>{{defaultSupplier.supplier.label}}</td>
                    <td class="actions">
                        <button mat-icon-button
                                (cbClick)="dialog(defaultSupplier, $index)"
                                *ngIf="!mappedItem.isCancelled()">
                            <mat-icon class="material-icons">edit_mode</mat-icon>
                        </button>
                        <button mat-icon-button
                                (cbClick)="deleteClick(defaultSupplier, $index)"
                                *ngIf="!mappedItem.isCancelled()">
                            <mat-icon class="material-icons">delete</mat-icon>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="!mappedItem.defaultProductSuppliers.length || mappedItem.defaultProductSuppliers.length < 1">
                    <td colspan="3">
                        <cb-info-message [message]="'There are no Default Suppliers to display'"
                                         [icon]="'info_circle'"
                                         iconClass="cb-accent"></cb-info-message>
                    </td>
                </tr>
            </tbody>
        </cb-table>
    </mat-card-content>
</mat-card>
