<div class="start-center flex-row">
    <button mat-icon-button
            (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <h1 class="app-name"
        (click)="goToHome()">ClassicHub</h1>
    <cb-env-display></cb-env-display>
    <span class="flex"></span>
    <ng-container *ngIf="button">
        <button class="button-place-holder" color="white" (click)="button.onClick()">{{ button.element.innerText }}</button>
    </ng-container>
    <button mat-icon-button
            (click)="openNotifications($event)"
            id="notification-button"
            class="cb-notification-icon"
            aria-label="Open Notifications">
        <div *ngIf="unreadNotificationsCount > 0"
             class="count-wrapper-outer">
            <div class="count-wrapper-inner">
                <div class="count-text">{{unreadNotificationsCount}}</div>
            </div>
        </div>
        <mat-icon *ngIf="!notificationsOpen">chat</mat-icon>
        <mat-icon *ngIf="notificationsOpen">close</mat-icon>
    </button>
    <div id="logo-container">
        <div class="cbhub-logo-curve"></div>
        <div class="logo"></div>
    </div>
</div>
