import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentTemplatesComponent} from './payment-templates/payment-templates.component';
import {PaymentTemplateDialogComponent} from './payment-template-dialog/payment-template-dialog.component';
import {PaymentTemplateBuildTypeDialogComponent} from './payment-template-build-type-dialog/payment-template-build-type-dialog.component';
import {PaymentTemplateStageDialogComponent} from './payment-template-stage-dialog/payment-template-stage-dialog.component';
import {FormsModule} from '@angular/forms';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {MatButtonModule} from '@angular/material/button';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbInfoMessageModule, CbTableModule} from 'cb-hub-lib';
import {MatIconModule} from '@angular/material/icon';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {CbClickModule} from '@app/shared/directives/click/click.module';
import {CbWaitForModule} from '@app/shared/directives/wait-for/wait-for.module';
import {MatRadioModule} from '@angular/material/radio';
import {DragulaModule} from 'ng2-dragula';
import {CbSortByModule} from '@app/shared/pipe/sort-by/sort-by.module';


@NgModule({
    declarations: [PaymentTemplatesComponent, PaymentTemplateDialogComponent, PaymentTemplateBuildTypeDialogComponent, PaymentTemplateStageDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        CbFormsModule,
        MatButtonModule,
        CbHubTableModule,
        CbTableModule,
        MatIconModule,
        CbDialogModule,
        CbSortByModule,
        CbClickModule,
        CbWaitForModule,
        MatRadioModule,
        DragulaModule,
        CbInfoMessageModule
    ],
    exports: [
        PaymentTemplatesComponent
    ],
})
export class PaymentTemplatesModule { }
