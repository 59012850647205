import {Component, Input} from '@angular/core';
import {FieldValidationItem} from '@app/core/services/field-validation/field.validation.item';
import {LOT_COMPONENT_TYPE} from '@app/core/services/field-validation/lot-field-validator/interfaces/i.lot.field.rules.service';
import {LotFieldValidatorService} from '@app/core/services/field-validation/lot-field-validator/lot-field-validator-service';
import {ILotMappedItem} from '@app/logic/lots';
import {MARKET_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {LotSummaryPinnablePanelDirective} from '../../shared/lot-summary-pinnable-panel';

@Component({
    selector: 'cb-lot-summary-details',
    templateUrl: './lot-summary-details.component.html',
    styleUrls: ['./lot-summary-details.component.scss']
})
export class LotSummaryDetailsComponent extends LotSummaryPinnablePanelDirective {

    @Input() public lotMappedItem: ILotMappedItem;

    public MARKET_STATUS_ENUM = MARKET_STATUS_ENUM;

    // Field Validation Items
    public jobNumberField = new FieldValidationItem();
    public jobStatusField = new FieldValidationItem();
    public marketStatusField = new FieldValidationItem();
    public contractGenerationFeeField = new FieldValidationItem();
    public landPriceField = new FieldValidationItem();
    public buildPriceField = new FieldValidationItem();
    public sellPriceField = new FieldValidationItem();
    public consentNumberField = new FieldValidationItem();

    public buildingConsentDate: string;
    public buildingConsentDateLabel: string;

    constructor(private lotFieldValidatorService: LotFieldValidatorService) {
        super();
    }

    public ngOnInit(): void {
        this.lotFieldValidatorService.dto = this.lotMappedItem.$getMappedDtoItem();
        this.populateFieldValidationItems();
        this.loadBuildingConsentDate();
    }

    private populateFieldValidationItems(): void {
        this.jobNumberField = this.lotFieldValidatorService.jobNumber(LOT_COMPONENT_TYPE.LotDetailsSummary);
        this.jobStatusField = this.lotFieldValidatorService.jobStatus(LOT_COMPONENT_TYPE.LotDetailsSummary);
        this.marketStatusField = this.lotFieldValidatorService.marketStatus(LOT_COMPONENT_TYPE.LotDetailsSummary);
        this.contractGenerationFeeField = this.lotFieldValidatorService.contractGenerationFee(LOT_COMPONENT_TYPE.LotDetailsSummary);
        this.landPriceField = this.lotFieldValidatorService.financialsLandPrice(LOT_COMPONENT_TYPE.LotDetailsSummary);
        this.buildPriceField = this.lotFieldValidatorService.financialsBuildPrice(LOT_COMPONENT_TYPE.LotDetailsSummary);
        this.sellPriceField = this.lotFieldValidatorService.financialsSellPrice(LOT_COMPONENT_TYPE.LotDetailsSummary);
        this.consentNumberField = this.lotFieldValidatorService.consentNumber(LOT_COMPONENT_TYPE.LotDetailsSummary);
    }

    private loadBuildingConsentDate(): void {
        if (this.lotMappedItem.buildingConsent) {
            if (this.lotMappedItem.buildingConsent.consentGranted === true) {
                this.buildingConsentDate = this.lotMappedItem.buildingConsent.consentReceivedDate !== null
                    ? this.lotMappedItem.buildingConsent.consentReceivedDate : null;
            } else {
                this.buildingConsentDate = this.lotMappedItem.buildingConsent.consentDueDate !== null
                    ? this.lotMappedItem.buildingConsent.consentDueDate : null;
            }

            if (this.lotMappedItem.buildingConsent.consentGranted === true) {
                this.buildingConsentDateLabel = 'Consent Received Date';
            } else {
                this.buildingConsentDateLabel = 'Consent Due Date';
            }
        } else {
            this.buildingConsentDateLabel = 'Consent Due Date';
        }
    }

}
