<div class="cb-margin start-center flex-row flex">
    <div class="flex-col">
        <div class="row">
            #{{itemId}}
        </div>
    </div>
    <div class="flex-col flex">
        <div>
            <div class="cb-margin flex-col flex"
                 style="margin-top:0;">
                <div class="flex-col flex"
                     style="padding-top: 0; margin-top: 0;margin-bottom: 0;margin-left: 0;">
                    <span>
                        {{description}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
