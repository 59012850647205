import { BaseMappedItem } from '../base/base-mapped-item';
import { IGlobalSettingsLogicService } from './interfaces/i.global-settings.logic.service';
import { IGlobalSettingsMappedItem } from './interfaces/i.global-settings.mapped';
import { IGlobalSettingsDto } from './interfaces/i.global-settings.dto';
import { DtoProp } from '../base/dto-prop.decorator';

export class GlobalSettingsMappedItem
    extends BaseMappedItem<IGlobalSettingsDto, IGlobalSettingsMappedItem, IGlobalSettingsLogicService>
    implements IGlobalSettingsMappedItem {
    @DtoProp public description: string;
    @DtoProp public id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public value: string;
    @DtoProp public readOnly: boolean;

    constructor(
        sourceData: IGlobalSettingsDto,
        logicService: IGlobalSettingsLogicService
    ) {
        super(
            sourceData,
            logicService,
            GlobalSettingsMappedItem
        );
    }
}
