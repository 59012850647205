import { Component, Input, OnInit } from '@angular/core';
import { LotPermissions } from '@app/core/permissions';
import { ProjectReleaseStagePermissions } from '@app/core/permissions/project-release-stage.permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { LotSwitchReleaseStageDialogComponent } from '@app/views/lot/shared/lot-switch-release-stage-dialog/lot-switch-release-stage-dialog.component';
import {
    IProjectReleaseLotDto,
    IProjectReleaseStageDto,
    LotTypeEnumId,
    LOT_CONTRACT_TYPE_ENUM,
    LOT_JOB_STATUS_ENUM,
    LOT_TYPE_ENUM,
    MARKET_STATUS_ENUM,
    PROJECT_RELEASE_STAGE_TYPE_ENUM,
    PROJECT_RELEASE_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { remove } from 'lodash';
import { ProjectReleaseStageEditLotDialogComponent } from './edit/edit-lot-dialog.component';
import { ImportLotsDialogComponent } from './import/import-lots-dialog.component';
import { ProjectReleaseStageReleaseLotsToSalesDialogComponent } from './sales/release-lots-to-sales-dialog.component';

@Component({
    selector: 'cb-manage-project-release-stage-lots-summary-tab',
    templateUrl: './release-lots-summary.component.html',
    styleUrls: ['./release-lots-summary.component.scss']
})
export class ManageProjectReleaseStageLotsSummaryTabComponent implements OnInit {
    @Input() public mappedItem: IReleaseStageMappedItem;
    @Input() public projectReleases: IProjectReleaseStageDto[];
    public selectedTabIndex = 0;
    public lots: IProjectReleaseLotDto[];
    public loaded = false;
    public MARKET_STATUS_ENUM = MARKET_STATUS_ENUM;
    public LOT_JOB_STATUS_ENUM = LOT_JOB_STATUS_ENUM;
    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;
    public LOT_TYPE_ENUM = LOT_TYPE_ENUM;
    constructor(
        private readonly releaseStagesLogicService: ReleaseStagesLogicService,
        public readonly lotPermissions: LotPermissions,
        public readonly projectReleaseStagePermissions: ProjectReleaseStagePermissions,
        public readonly cbDialog: CbDialogService,
        private readonly navigationService: NavigationService,
        private readonly toastService: ToastService,
        private readonly lotsLogicService: LotsLogicService
    ) { }

    public ngOnInit(
    ): void {
        this.loadLots();
    }

    public getMarketStatusIcon(lot: IProjectReleaseLotDto): string | null {
        switch (lot.marketStatus) {
            case MARKET_STATUS_ENUM.Sold:
                return 'monetization_on';
            case MARKET_STATUS_ENUM.NotAvailable:
                return 'block_black';
            case MARKET_STATUS_ENUM.Available:
                return 'attach_money';
            case MARKET_STATUS_ENUM.PreRelease:
                return 'info_black';
            default:
                return;
        }
    }

    public editLot(lot: IProjectReleaseLotDto) {
        this.lotsLogicService.$getMappedItem(lot.id).subOnce(LotMappedItem => {
            this.cbDialog
                .open(ProjectReleaseStageEditLotDialogComponent, {
                    data: { mappedItem: LotMappedItem, isReadonly: false },
                    minWidth: '90%',
                })
                .afterClosed()
                .subOnce((result: ILotMappedItem) => {
                    if (result) {
                        const index = result?.id != null && this.lots.findIndex(x => x.id === result.id);
                        if (index >= 0) {
                            Object.assign(this.lots[index], result);
                        }
                    }
                });
        });
    }

    public viewLotDetails(lot: IProjectReleaseLotDto) {
        this.lotsLogicService.$getMappedItem(lot.id).subOnce(LotMappedItem => {
            this.cbDialog
                .open(ProjectReleaseStageEditLotDialogComponent, {
                    data: { mappedItem: LotMappedItem, isReadonly: true },
                    minWidth: '90%',
                })
                .afterClosed()
                .subOnce();
        });
    }

    public viewLot(lot: IProjectReleaseLotDto): void {
        this.navigationService.navigate([`lots/${lot.id}/summary`]);
    }

    public getAmenitiesDisplay(noAmenities: number, lotType: LotTypeEnumId) {
        return this.isBlockLotType(lotType) ? '-' : noAmenities;
    }

    public isBlockLotType(value: LotTypeEnumId) {
        return value === LOT_TYPE_ENUM.ApartmentBlock || value === LOT_TYPE_ENUM.TerracedBlock;
    }

    public canReleaseLot(): boolean {
        return this.mappedItem.type === PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand
            && this.lots.length > 0
            && this.mappedItem.releaseStatus !== PROJECT_RELEASE_STATUS_ENUM.Cancelled;
    }

    public canEditLot(lot: IProjectReleaseLotDto) {
        return !lot.hasPriceRevisions &&
            !lot.hasSchemesInProgress &&
            !lot.activeConceptId;
    }

    public canImportLots() {
        return this.mappedItem.releaseStatus !== PROJECT_RELEASE_STATUS_ENUM.Cancelled;
    }

    public canMakeAvailableForSale(lot: IProjectReleaseLotDto): boolean {
        return !lot.hasClientSale
            && lot.marketStatus !== MARKET_STATUS_ENUM.Available
            && (lot.activeConceptId || lot.lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand)
            && !lot.hasEstimatedPricingRevisionOnAppliedConcept;
    }

    public makeAvailableForSale(lot: IProjectReleaseLotDto) {
        const ids: string[] = [lot.id.toString()];

        this.lotsLogicService.updateMarketStatus(ids, MARKET_STATUS_ENUM.Available).subOnce(
            (results) => {
                if (results) {
                    this.toastService.saveSuccess();
                    this.loadLots();
                }
            });
    }

    public canMakeUnavailableForSale(lot: IProjectReleaseLotDto): boolean {
        return lot.marketStatus !== MARKET_STATUS_ENUM.NotAvailable && !lot.hasClientSale;
    }

    public setAsExternalSale(lot: IProjectReleaseLotDto) {
        this.cbDialog.simpleMessageDialog('Set as External Sale is Not yet Implemented');
    }

    public canSwitchReleaseStage(lot: IProjectReleaseLotDto): boolean {
        return !lot.hasClientSale && this.projectReleases?.length > 1;
    }

    public removeLotFromRelease(lot) {
        this.cbDialog.confirm({
            dialogHeading: 'Remove Lot From Release',
            message: 'Are you sure you want to remove this Lot from the Release?',
            confirmed: () => {
                this.lotsLogicService.deleteLot(lot.id).subOnce(() => {
                    this.toastService.saveSuccess();
                    this.lots = remove(this.lots, (someLot) => {
                        return someLot.id !== lot.id;
                    });
                });
            }
        });
    }

    public canRemoveLotFromRelease(lot: IProjectReleaseLotDto): boolean {
        if (lot.lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand || lot.lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return !lot.hasClientSale;
        }
        return false;
    }

    public switchReleaseStage(lot: IProjectReleaseLotDto) {
        this.lotsLogicService.$getMappedItem(lot.id).subOnce(lotMappedItem => {
            this.cbDialog
                .open(LotSwitchReleaseStageDialogComponent, {
                    data: { mappedItem: lotMappedItem }
                })
                .afterClosed()
                .subOnce((result) => {
                    if (result) {
                        this.loadLots();
                    }
                });
        });
    }

    private loadLots = () => {
        this.loaded = false;
        this.releaseStagesLogicService.getProjectReleaseStageLots(this.mappedItem.id).subOnce((result) => {
            this.lots = result;
            this.loaded = true;
        });
    };

    public makeUnavailableForSale(lot: IProjectReleaseLotDto) {
        const ids: string[] = [lot.id.toString()];

        this.lotsLogicService.updateMarketStatus(ids, MARKET_STATUS_ENUM.NotAvailable).subOnce(
            (results) => {
                if (results) {
                    this.toastService.saveSuccess();
                    this.loadLots();
                }
            });
    }

    public releaseToSalesDialog() {
        if (this.canReleaseLot()) {
            this.cbDialog
                .open(ProjectReleaseStageReleaseLotsToSalesDialogComponent, {
                    data: { projectReleaseStageId: this.mappedItem.id, projectId: this.mappedItem.projectId },
                    minWidth: '1000px',
                })
                .afterClosed()
                .subOnce(result => {
                    if (result) {
                        this.mappedItem.$reload();
                    }
                });
        }
    }

    public importLotsDialog() {
        this.cbDialog
            .open(ImportLotsDialogComponent, {
                data: { projectReleaseStageMappedItem: this.mappedItem },
                minWidth: '1000px',
            })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.loadLots();
                }
            });
    }

}
