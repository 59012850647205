import { Injectable, Injector } from '@angular/core';
import { ISkinnyScheduleTemplateDto, IUpdateSkinnyScheduleTemplateDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';

import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IScheduleTemplatesLogicService } from './interfaces/i.schedule-templates.logic-service';
import { IScheduleTemplateMappedItem } from './interfaces/i.schedule-template.mapped';
import { ScheduleTemplateMappedItem } from './schedule-template.mapped';
import { environment } from '@src/environments/environment';
import { prefixStr } from 'cb-hub-lib';


@Injectable()
export class ScheduleTemplatesLogicService
    extends BaseLogicService<ISkinnyScheduleTemplateDto, IScheduleTemplateMappedItem>
    implements IScheduleTemplatesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'scheduletemplates',
            ScheduleTemplateMappedItem
        );
    }

    public $saveItem(entity: IUpdateSkinnyScheduleTemplateDto): Observable<any> {
        const uri = this.$baseUri;
        return this.$http.post<ISkinnyScheduleTemplateDto[]>(uri, entity);
    }

    public generateReport(id: number): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/report/${id}`, 'Generating Schedule Template Report...');
    }

    public generatePreviewTemplate(id: number): void {
        const path = ['open-pdf'];
        const documentFetchUrl = `/${this.$baseUri}/generateHlFlyerByScheduleTemplate/${id}`;
        const documentName = 'Flyer Preview';
        window.open(environment.uri + prefixStr(path.join(''), '/') + `?documentFetchUrl=${documentFetchUrl}&documentName=${documentName}`);
    }
}
