import { PermissionsPermissions } from '@app/core/permissions';
import { Component, OnInit } from '@angular/core';
import { TradeTypesLogicService } from '@app/logic/trade-types/trade-types.logic.service';
import { ITradeTypeTreeMappedItem } from '@app/logic/trade-types/interfaces/i.trade-type-tree.mapped';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { TradeTypeDialogComponent } from '../trade-type-dialog/trade-type-dialog.component';
import { TradeTypesTreeLogicService } from '@app/logic/trade-types/trade-types-tree.logic.service';
import { isNullOrWhiteSpace } from 'cb-hub-lib';

@Component({
    selector: 'cb-trade-types-setings',
    templateUrl: './trade-types.component.html',
    styleUrls: ['./trade-types.component.scss']
})
export class TradeTypesComponent implements OnInit {

    public filterText = '';
    public tradeTypesTree: ITradeTypeTreeMappedItem[];
    public loaded = false;
    public expanded: { [tradeTypeId: number]: boolean } = {};

    public readonly filteredResults = new ComputedProperty(() => {
        this.expanded = {};
        if (!this.tradeTypesTree) {
            return;
        }
        return this.tradeTypesTree?.filter(this.filterTradeTypes) ?? [];
    });

    constructor(
        public readonly permissions: PermissionsPermissions,
        public readonly tradeTypesLogic: TradeTypesLogicService,
        public readonly tradeTypesTreeLogic: TradeTypesTreeLogicService,
        private readonly cbDialog: CbDialogService,
    ) { }

    public ngOnInit(): void {
        this.loadTradeTypes();
    }

    public loadTradeTypes(): void {
        this.tradeTypesTreeLogic
            .getMappedTree()
            .subOnce((tree) => {
                this.tradeTypesTree = tree;
                this.loaded = true;
                this.filteredResults.recompute();
            });
    }

    public generateReport(): void {
        this.tradeTypesLogic.downloadTradeTypesReport().subOnce();
    }

    public addTradeType(): void {
        this.cbDialog.open(TradeTypeDialogComponent, {
            data: {
                mappedItem: this.tradeTypesLogic.$createMappedItem(),
            },
            minWidth: '50%',
        })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.loadTradeTypes();
                }
            });
    }

    public canEdit(): boolean {
        return this.permissions.canEditSettings();
    }

    @Debounce(300)
    public filterChanged(): void {
        this.filteredResults.recompute();
    }

    public readonly filterTradeTypes = (item: ITradeTypeTreeMappedItem): boolean => {
        if (item?.filterChildren) {
            item?.filterChildren(this.filterText);
        }
        if (isNullOrWhiteSpace(this.filterText)) {
            return true;
        }
        const childFound = item?.filteredChildren?.length > 0;
        if (childFound && !this.expanded[item?.id]) {
            this.expanded[item?.id] = true;
        }
        return this.matchesSearch(item?.label) || childFound;
    };

    public matchesSearch(text: string): boolean {
        return text.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1;
    }
}
