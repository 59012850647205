import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbCurrencyPipe } from './currency.pipe';



@NgModule({
    declarations: [CbCurrencyPipe],
    imports: [
        CommonModule
    ],
    exports: [CbCurrencyPipe]
})
export class CbCurrencyModule { }
