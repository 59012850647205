import { Component, Input, OnInit } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { ContactUtilities } from '@app/logic/contacts/contact.utilities';
import { LocationLogicService } from '@app/logic/location';
import { ProjectsLogicService } from '@app/logic/projects';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';
import { ICouncilDto, IProjectDetailsDto, ISkinnyContactDto, IUserDto, PROJECT_STATUS_ENUM, SALUTATION_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';
import { IIdAndLabelDto } from '@app/shared/interfaces/i.idandlabel.dto';
import { CouncilLogicService } from '@app/logic/councils';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { UserDetailsDialogComponent } from '@app/views/users/user-details-dialog/user-details-dialog.component';

@Component({
    selector: 'cb-manage-project-details',
    templateUrl: './manage-project-details-view.component.html',
    styleUrls: ['./manage-project-details-view.component.scss']
})
export class ManageProjectDetailsComponent implements OnInit {
    public SALUTATION_ENUM = SALUTATION_ENUM;
    public ContactUtilities = ContactUtilities;
    public loaded: boolean;
    @Input() public mappedItem: IProjectMappedItem;
    public selectedTabIndex = 0;
    public projectStatuses = PROJECT_STATUS_ENUM.toLookup();
    public PROJECT_STATUS_ENUM = PROJECT_STATUS_ENUM;
    public areas: IIdAndLabelDto<number>[];
    public councils: ICouncilDto[] | any = [];
    public locationLabel: string;
    public councilLabel: string;

    constructor(
        private readonly toastService: ToastService,
        private readonly logicService: ProjectsLogicService,
        private readonly locationLogicService: LocationLogicService,
        private readonly councilLogicService: CouncilLogicService,
        public securityPermissions: PermissionsPermissions,
        private readonly accountLogicService: ClientAccountLogicService,
        private readonly navigationService: NavigationService,
        public readonly cbDialog: CbDialogService
    ) {
    }

    public ngOnInit(): void {
        this.loaded = true;
        this.loadLocation(this.mappedItem);
        this.loadCouncils(this.mappedItem);
    }

    public onAccountChanged = (): void => {
        this.accountLogicService.getContacts(this.mappedItem.accountOwner.id).subOnce((contacts) => this.mappedItem.contacts = (contacts as unknown as ISkinnyContactDto[]));
    };

    public viewContact(contactId: number): void {
        this.navigationService.navigate([`contacts/${contactId}/summary`]);
    }

    public orderOptionsByLabel(options): IIdAndLabelDto<number>[] {
        options = orderBy(options, ['label'], ['asc']);
        return options;
    }

    public loadLocation(project: IProjectDetailsDto): void {
        if (project?.address?.region) {
            this.locationLogicService.getAreasByRegionId({ addressRegionId: project.address.region }).subOnce(result => {
                this.areas = this.orderOptionsByLabel(result);
                this.locationLabel = this.areas.find(area => area.id === this.mappedItem.locationId).label;
            });
        }
    }

    public loadCouncils(project: IProjectDetailsDto): void {
        if (project?.address?.region) {
            this.councilLogicService.getByLocation(this.mappedItem.locationId).subOnce(result => {
                this.councils = result;
                this.councilLabel = this.councils.find(council => council.id === this.mappedItem.buildingConsentCouncilId).name;
            });
        }
    }

    public viewPrincipal = (accountId: number): void => {
        this.navigationService.navigate([`/accounts/${accountId}/summary`]);
    };

    public viewUser(user: IUserDto): void {
        this.cbDialog
            .open(UserDetailsDialogComponent, {
                data: { dto: user },
                minWidth: '500px',
            })
            .afterClosed()
            .subOnce();
    }
}
