<form class="flex-row" #editAccountForm="ngForm">
    <mat-tab-group class="flex"
                   #tabGroup
                   [cbTabRoute]="CLIENT_ACCOUNT_FULL_ROUTE"
                   defaultTabId="summary">
        <mat-tab label="Account Details"
                 cbTabRouteId="summary">
            <ng-template matTabContent>
                <div *ngIf="!isEdit">
                    <cb-client-account-details *cbWaitFor="account && contacts"
                                               [account]="account"
                                               [contacts]="contacts">
                    </cb-client-account-details>
                </div>
                <div *ngIf="isEdit">
                    <mat-card class="cb-margin flex">
                        <cb-client-account-edit class="flex"
                                                *cbWaitFor="account && contacts"
                                                [(account)]="editAccount"
                                                [contacts]="contacts">
                        </cb-client-account-edit>
                    </mat-card>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Client Sales"
                 cbTabRouteId="clientsales">
            <ng-template matTabContent>
                <cb-client-account-sales *cbWaitFor="account"
                                         [account]="account"></cb-client-account-sales>
            </ng-template>
        </mat-tab>
        <mat-tab label="Leads"
                 cbTabRouteId="leads">
            <ng-template matTabContent>
                <cb-client-account-leads *cbWaitFor="account"
                                         [account]="account">
                </cb-client-account-leads>
            </ng-template>
        </mat-tab>
        <mat-tab label="Projects"
                 cbTabRouteId="projects">
            <ng-template matTabContent>
                <cb-client-account-projects *cbWaitFor="account"
                                            [account]="account">
                </cb-client-account-projects>
            </ng-template>
        </mat-tab>
        <mat-tab label="Notes"
                 cbTabRouteId="notes">
            <ng-template matTabContent>
                <cb-notes-new *cbWaitFor="account"
                              [entity]="account"
                              [edit]="true"
                              [permissions]="permissions.getNotePermissions()">
                </cb-notes-new>
            </ng-template>
        </mat-tab>
        <mat-tab label="History"
                 cbTabRouteId="history">
            <ng-template matTabContent>
                <cb-history-list *cbWaitFor="account"
                                 [entityId]="account?.id"
                                 entity="account"></cb-history-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <cb-side-bar *cbWaitFor="account"
                 class="cb-margin flex-col flex-20">
        <div class="flex-col flex">
            <div class="start-center flex-row">
                <h1 class="mat-title cb-margin-bottom-nil"> {{account.name}} </h1>
            </div>
            <mat-divider [inset]="true"> </mat-divider>
            <cb-display-value-new class="cb-padding-top-2x"
                                  label="Account Status"
                                  [value]="CLIENT_ACCOUNT_STATUS_ENUM[account.status]"></cb-display-value-new>
            <cb-display-value-new label="Account Type"
                                  [value]="ACCOUNT_TYPE_ENUM[account.accountType]"></cb-display-value-new>
            <div class="start-center flex-row">
                <h1 class="mat-title cb-margin-bottom-nil"> Main Contact </h1>
            </div>
            <mat-divider [inset]="true"> </mat-divider>
            <p class="cb-padding-top-2x cb-padding-bottom">
                <strong>{{account.mainContact?.firstName }} {{account.mainContact?.lastName }}</strong>
            </p>
            <p>
                {{account.physicalAddress?.street}}
            </p>
            <p>
                {{account.physicalAddress?.suburb}}
            </p>
            <p>
                {{account.physicalAddress?.city}}
            </p>
            <p>
                {{account.physicalAddress?.regionName}}
            </p>
            <cb-display-value-new class="cb-padding-top-2x"
                                  label="Email"
                                  [value]="account.mainContact?.email"></cb-display-value-new>
            <cb-display-value-new label="Created"
                                  [value]="getCreatedDisplayValue()"></cb-display-value-new>
            <cb-display-value-new label="Updated"
                                  [value]="getUpdatedDisplayValue()"></cb-display-value-new>
            <div *ngIf="tabGroup.selectedIndex === 0"
                 class="cb-margin-top-3x">
                <button *ngIf="!isEdit"
                        mat-raised-button
                        color="primary"
                        class="cb-margin-bottom-2x cb-margin-right-2x"
                        (click)="OpenEditForm()">
                    Edit Account
                </button>
                <button *ngIf="isEdit"
                        mat-raised-button
                        color="primary"
                        class="cb-margin-bottom-2x cb-margin-right-2x"
                        [disabled]="!editAccountForm.valid"
                        (click)="saveAccount()">
                    Save Account
                </button>
                <button *ngIf="isEdit"
                        mat-raised-button
                        class="cb-margin-bottom-2x"
                        (click)="cancelEdit()">
                    Cancel
                </button>
            </div>
        </div>
    </cb-side-bar>
</form>
