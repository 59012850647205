import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input
} from '@angular/core';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem } from '@app/logic/lots';
import { TakeoffsLogicService } from '@app/logic/takeoffs/takeoffs.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import {
    COST_TYPE_ENUM,
    ICostXImportResultDto,
    ITakeOffCompareBuildStageDto,
    ITakeOffCompareDto,
    ITakeOffCompareItemDto,
    ITakeOffDtoSummary,
    TAKE_OFF_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';

import { filter, find, flattenDeep, forEach, some } from 'lodash';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, Subscription, take } from 'rxjs';
import { TakeoffCompareService } from './takeoff-compare.service';
import { TakeoffRateVariationsDialogComponent } from './takeoff-rate-variations-dialog/takeoff-rate-variations-dialog.component';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

@Component({
    selector: 'cb-takeoff-compare-new',
    templateUrl: './takeoff-compare.component.html',
    styleUrls: ['./takeoff-compare.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeoffCompareComponent {
    private _id: number;
    public get id(): number {
        return this._id;
    }
    @Input() public set id(value: number) {
        this._id = value;
        this.comparison$ = this.logicService.getChanges(this.id).pipe(
            shareReplay(1),
            take(1)
        );
        this._subscriptions.add(this.comparison$.subscribe(this.comparison$$));

        this.allVariations$ = this.comparison$.pipe(
            map(comparison => {
                const allVariations = flattenDeep(comparison.stages.map((stage) => {
                    return stage.activities.map(activity => {
                        this.setActivityItemsWarning(activity.items);
                        return filter(activity.items, (a: ITakeOffCompareItemDto) => {
                            return !comparison.isRevision ||
                                a.takeOffRate !== a.catalogueRate ||
                                (comparison.isRevision && a.originalQuantity !== a.quantity) ||
                                (comparison.isRevision && a.originalQuantity === undefined && a.quantity !== undefined) ||
                                (a.originalNotes !== a.notes) ||
                                (this.isTakeOffBudgetsEnabled() && comparison.isRevision && a.hasBudget && a.originalBudget !== a.budget);
                        });
                    });
                }));

                return flattenDeep(allVariations);
            })
        );

        const isTakeOffBudgetEnabled = this.featureToggleStateService.isTakeOffBudgetImportEnabled;
        this.rateVariations$ = this.allVariations$.pipe(
            map(allVariations =>
                filter(allVariations, (item: ITakeOffCompareItemDto) =>
                    (!isTakeOffBudgetEnabled && item.takeOffRate !== item.catalogueRate) || item.priceOption === 0))
        );
        this._setUpProperties();
    }

    public hasRateVariations$: Observable<boolean>;
    public hasRateVariations$$ = new BehaviorSubject<boolean>(null);

    public noAlerts$: Observable<boolean>;
    public noAlerts$$ = new BehaviorSubject<boolean>(null);

    @Input() public takeOffs: ITakeOffDtoSummary[];
    @Input() public lotId: number;
    @Input() public lot: ILotMappedItem;
    @Input() public displayCompare$: BehaviorSubject<boolean>;
    @Input() public takeOffResult: ICostXImportResultDto;
    @Input() public document: any = {};

    @Input() public comparison$: Observable<ITakeOffCompareDto>;
    public comparison$$ = new BehaviorSubject<ITakeOffCompareDto>(null);


    public allVariations$: Observable<ITakeOffCompareItemDto[]>;
    public allVariations$$ = new BehaviorSubject<ITakeOffCompareItemDto[]>(null);

    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public rateVariations$: Observable<ITakeOffCompareItemDto[]>;
    public rateVariations$$: BehaviorSubject<ITakeOffCompareItemDto[]>;
    public variationsWithoutCatalogueRates$: Observable<ITakeOffCompareItemDto[]>;
    public selectedTakeoffId: number;
    private _subscriptions = new Subscription();

    constructor(
        public readonly logicService: TakeoffsLogicService,
        private readonly toastService: ToastService,
        public cdRef: ChangeDetectorRef,
        private readonly dialogService: CbDialogService,
        private readonly takeoffCompareService: TakeoffCompareService,
        private readonly featureToggleStateService: FeatureToggleStatesService
    ) {
        this._subscriptions.add(this.takeoffCompareService.displayCompare$.subscribe());
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public isTakeOffBudgetsEnabled(): boolean {
        return this.featureToggleStateService.isTakeOffBudgetImportEnabled;
    }

    public setActivityItemsWarning = (items: ITakeOffCompareItemDto[]): void => {
        items.forEach(item => {
            if (item.takeOffRate !== item.catalogueRate) {
                (item as any).$warning = true;
            }
            if (item.quantity !== item.originalQuantity && this.comparison$$.value?.isRevision) {
                (item as any).$quantitywarning = true;
            }
            if (this.isTakeOffBudgetsEnabled() &&
                this.comparison$$.value?.isRevision && item.hasBudget && item.budget !== item.originalBudget) {
                (item as any).$budgetWarning = true;
            }
        });
    };

    public quotedVariationFilter = (item: ITakeOffCompareItemDto): boolean => {
        // eslint-disable-next-line eqeqeq
        return item.quoteLineId == undefined;
    };

    public itemCount(stage: ITakeOffCompareBuildStageDto): number {
        let count = 0;
        for (const activity of stage.activities) {
            count += activity.items.length;
        }
        return count;
    }

    public resolveAll(): void {
        forEach(this.rateVariations$$.value, function(variation) {
            variation.priceOption = 1;
        });
    }

    public save(): void {
        if (this.hasRateVariations$$.value === true) {
            const numResolved = filter(this.rateVariations$$.value, function(item) { return item.priceOption > 0; }).length;
            const message = 'Please Resolve all Rate Variations First. ' + numResolved + '/' + this.rateVariations$$.value?.length + ' resolved';
            this.toastService.showToast(message, 'OK', Infinity);
        } else if (this.allVariations$$.value?.length < 1) {
            const message = 'There are no variations in this Takeoff';
            this.toastService.showToast(message, 'OK', Infinity);
        } else {
            this.dialogService.confirm({
                message: 'Are you sure you want to save this Takeoff data?',
                dialogHeading: 'Save Takeoff',
                confirmed: () => {
                    const blockRef = this.dialogService.block('Please wait');
                    this.logicService.confirm(this.comparison$$.value).subOnce((_) => {
                        blockRef.close();
                        this.takeoffCompareService.takeoffSaved$.next(true);
                        this.toastService.showToast('Saved Successfully', 'OK');
                    });
                }
            });
        }
        this.cdRef.detectChanges();
    }

    public abandon(): void {
        this.logicService.abandon(this.comparison$$.value?.id).subOnce(() => {
            const takeoff: any = find(this.takeOffs, (obj: any) => { return Number(obj.id) === this.id; });
            takeoff.status = TAKE_OFF_STATUS_ENUM.Abandoned;
            this.displayCompare$.next(false);
        });
    }

    public goBackToList(): void {
        this.displayCompare$.next(false);
    }

    public showRateVariationDialog($event): void {
        this.dialogService
            .open(
                TakeoffRateVariationsDialogComponent,
                {
                    data: {
                        rateVariations$: this.rateVariations$
                    },
                }
            ).afterClosed().subOnce(rateVariations$ => {
                if (rateVariations$) {
                    this.rateVariations$ = rateVariations$.pipe(
                        map(rateVariations => filter((rateVariations), (item: any) => item.priceOption === 0))
                    );

                    this._setUpProperties();
                    this.cdRef.detectChanges();
                }
            });
    }

    private _setUpProperties(): void {

        this.variationsWithoutCatalogueRates$ = this.allVariations$.pipe(
            map(allVariations => filter(allVariations, (item) => item.catalogueRate === undefined ||
                item.catalogueRate === null))
        );

        this.hasRateVariations$ = this.rateVariations$.pipe(
            map(rateVariations$ => some(rateVariations$, (item) => item.priceOption === 0))
        );

        this.noAlerts$ = combineLatest([
            this.rateVariations$,
            this.comparison$,
            this.variationsWithoutCatalogueRates$
        ]).pipe(map(([rateVariations, comparison, variationsWithoutCatalogueRates]) => {
            return rateVariations?.length < 1 &&
                comparison?.lotSpecItemsNotInTakeoff?.length < 1 &&
                variationsWithoutCatalogueRates?.length < 1;
        }));

        this._subscriptions.add(this.allVariations$.subscribe(this.allVariations$$));
        this._subscriptions.add(this.hasRateVariations$.subscribe(this.hasRateVariations$$));
        this._subscriptions.add(this.noAlerts$.subscribe(this.noAlerts$$));
    }
}
