import {Component} from '@angular/core';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {NonWorkingDayLogicService} from '@app/logic/non-working-day';
import {BaseFormComponentDirective, getBaseFormComponentDirectiveProvider} from '../base-form-component';

@Component({
    selector: 'cb-design-scheme-line-status-checkbox',
    templateUrl: './design-scheme-line-status-checkbox.component.html',
    styleUrls: ['./design-scheme-line-status-checkbox.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(DesignSchemeLineStatusCheckboxComponent),
        NonWorkingDayLogicService,
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class DesignSchemeLineStatusCheckboxComponent extends BaseFormComponentDirective {

    public isCompletedChange(): boolean {
        return this.getStatus() === 2;

    }

    public isNotStartedChange(): boolean {
        return this.getStatus() === 1;
    }

    public isNotDoingChange(): boolean {
        return this.getStatus() === 3;
    }

    public completeChangeClicked(): void {
        this.setStatus(this.getStatus() + 1);
    }

    public notStartedChangeClicked(): void {
        this.setStatus(this.getStatus() + 1);
    }

    public notDoingChangeClicked(): void {
        this.setStatus(this.getStatus() + 1);
    }

    private getStatus(): number {
        if (this.value !== undefined) {
            return this.value ? this.value : 1;
        }
    }

    private setStatus(status: number): void {
        if (this.value !== undefined) {

            if (status > 3) {
                status = 1;
            }
            this.value = status;
        }
    }
}
