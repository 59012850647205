<cb-dialog class="flex-col"
           dialogHeading="{{getDialogHeading()}}">
    <div class="dialog-container">
        <form #form="ngForm"
              class="form-container flex-col flex">
            <div class="flex-row flex-fill"
                 *cbWaitFor="mappedItem">
                <div class="cb-margin flex-col flex-50">
                    <h3 class="mat-title">
                        Build Details
                    </h3>
                    <cb-select label="Build Type"
                               name="buildType"
                               [options]="buildTypes"
                               [(ngModel)]="mappedItem.buildTypeId"
                               [required]="buildTypeField.isRequired"
                               [disabled]="!buildTypeField.isEditable"
                               (change)="buildTypeChanged()"
                               *ngIf="buildTypeField.isVisible"
                               [readonly]="isReadonly">
                    </cb-select>
                    <cb-input name="jobNumber"
                              label="Job Number"
                              [(ngModel)]="mappedItem.jobNumber"
                              [required]="jobNumberField.isRequired"
                              [disabled]="!jobNumberField.isEditable"
                              *ngIf="jobNumberField.isVisible"
                              [readonly]="isReadonly">
                    </cb-input>
                    <h3 class="mat-title">
                        Lot Details
                    </h3>
                    <cb-input name="lotNumber"
                              label="Lot Number"
                              type="number"
                              [(ngModel)]="mappedItem.lotNumber"
                              [required]="lotNumberField.isRequired"
                              [disabled]="!lotNumberField.isEditable"
                              *ngIf="lotNumberField.isVisible"
                              [readonly]="isReadonly">
                    </cb-input>
                    <cb-select label="Road Direction"
                               name="roadDirection"
                               [options]="COMPASS_DIRECTION_TYPE_ENUM.toLookup()"
                               [(ngModel)]="mappedItem.roadDirection"
                               [required]="roadDirectionField.isRequired"
                               [disabled]="!roadDirectionField.isEditable"
                               *ngIf="roadDirectionField.isVisible"
                               [readonly]="isReadonly">
                    </cb-select>
                    <cb-checkbox name="hasExternalLandVendor"
                                 label="External Land Vendor"
                                 [(ngModel)]="mappedItem.hasExternalLandVendor"
                                 [required]="hasExternalLandVendorField.isRequired"
                                 [disabled]="!hasExternalLandVendorField.isEditable"
                                 *ngIf="hasExternalLandVendorField.isVisible"
                                 [readonly]="isReadonly">
                    </cb-checkbox>
                    <cb-currency label="Lot Purchase Price"
                                 name="purchasePrice"
                                 [(ngModel)]="mappedItem.financials.purchasePrice"
                                 [inclGst]="true"
                                 [required]="lotPurchasePriceField.isRequired"
                                 [disabled]="!lotPurchasePriceField.isEditable"
                                 *ngIf="lotPurchasePriceField.isVisible"
                                 [readonly]="isReadonly">
                    </cb-currency>
                    <cb-currency label="Lot Deposit"
                                 name="deposit"
                                 [(ngModel)]="mappedItem.financials.deposit"
                                 [inclGst]="false"
                                 [required]="lotDepositField.isRequired"
                                 [disabled]="!lotDepositField.isEditable"
                                 *ngIf="lotDepositField.isVisible"
                                 [readonly]="isReadonly">
                    </cb-currency>
                    <h3 class="mat-title">
                        Sale Details
                    </h3>
                    <cb-currency label="Land Price"
                                 name="landPrice"
                                 [(ngModel)]="mappedItem.financials.landPrice"
                                 [inclGst]="true"
                                 [required]="landPriceField.isRequired"
                                 [disabled]="!landPriceField.isEditable"
                                 *ngIf="landPriceField.isVisible"
                                 [readonly]="isReadonly">
                    </cb-currency>
                    <cb-currency label="Selling Price"
                                 name="sellPrice"
                                 [(ngModel)]="mappedItem.financials.sellPrice"
                                 [inclGst]="true"
                                 [required]="sellPriceField.isRequired"
                                 [disabled]="!sellPriceField.isEditable"
                                 *ngIf="sellPriceField.isVisible"
                                 [readonly]="isReadonly">
                    </cb-currency>
                    <cb-currency label="Lot Holding Deposit"
                                 name="lotHoldingDeposit"
                                 [(ngModel)]="mappedItem.lotHoldingDeposit"
                                 [inclGst]="true"
                                 [required]="lotHoldingDepositField.isRequired"
                                 [disabled]="!lotHoldingDepositField.isEditable"
                                 *ngIf="lotHoldingDepositField.isVisible"
                                 [readonly]="isReadonly">
                    </cb-currency>
                    <h3 class="mat-title">
                        Lot Description
                    </h3>
                    <cb-text-area name="lotHeading"
                                  label="Header"
                                  [(ngModel)]="mappedItem.lotHeading"
                                  [maxlength]="1000"
                                  [readonly]="isReadonly"></cb-text-area>
                    <cb-text-area label="Description"
                                  name="lotDescription"
                                  [(ngModel)]="mappedItem.lotDescription"
                                  [maxlength]="2000"
                                  [readonly]="isReadonly"></cb-text-area>
                    <h3 class="mat-title">
                        Lot Amenities
                    </h3>
                    <cb-input name="floorArea"
                              label="Floor Area"
                              type="number"
                              [(ngModel)]="mappedItem.amenities.floorArea"
                              [required]="floorAreaField.isRequired"
                              [disabled]="!floorAreaField.isEditable"
                              *ngIf="floorAreaField.isVisible"
                              svgIcon="floor-plan"
                              suffix="m&#178;"
                              [readonly]="isReadonly">
                    </cb-input>
                    <cb-input name="landArea"
                              label="Land Area"
                              type="number"
                              [(ngModel)]="mappedItem.amenities.landArea"
                              [required]="landAreaField.isRequired"
                              [disabled]="!landAreaField.isEditable"
                              *ngIf="landAreaField.isVisible"
                              svgIcon="floor-plan"
                              suffix="m&#178;"
                              [readonly]="isReadonly">
                    </cb-input>
                    <cb-input name="noBedrooms"
                              label="Bedroom"
                              type="number"
                              [(ngModel)]="mappedItem.amenities.noBedrooms"
                              [required]="bedroomsField.isRequired"
                              [disabled]="!bedroomsField.isEditable"
                              *ngIf="bedroomsField.isVisible"
                              svgIcon="bed"
                              [readonly]="isReadonly">
                    </cb-input>
                    <cb-input name="noBathrooms"
                              label="Bathroom"
                              type="number"
                              [(ngModel)]="mappedItem.amenities.noBathrooms"
                              [required]="bathroomsField.isRequired"
                              [disabled]="!bathroomsField.isEditable"
                              *ngIf="bathroomsField.isVisible"
                              icon="wc"
                              [readonly]="isReadonly">
                    </cb-input>
                    <cb-input name="noLivingRooms"
                              label="Living"
                              type="number"
                              [(ngModel)]="mappedItem.amenities.noLivingRooms"
                              [required]="livingRoomsField.isRequired"
                              [disabled]="!livingRoomsField.isEditable"
                              *ngIf="livingRoomsField.isVisible"
                              icon="weekend"
                              [readonly]="isReadonly">
                    </cb-input>
                    <cb-input name="noGarages"
                              label="Garage"
                              type="number"
                              [(ngModel)]="mappedItem.amenities.noGarages"
                              [required]="garagesField.isRequired"
                              [disabled]="!garagesField.isEditable"
                              *ngIf="garagesField.isVisible"
                              icon="home"
                              [readonly]="isReadonly">
                    </cb-input>
                    <cb-input name="noCarparks"
                              label="Carpark"
                              type="number"
                              [(ngModel)]="mappedItem.amenities.noCarparks"
                              icon="directions_car"
                              [readonly]="isReadonly">
                    </cb-input>
                    <cb-input name="noStudy"
                              label="Study"
                              type="number"
                              [(ngModel)]="mappedItem.amenities.noStudy"
                              icon="import_contacts"
                              [readonly]="isReadonly">
                    </cb-input>
                </div>
                <div class="cb-margin flex-col flex-50">
                    <cb-address-new [(ngModel)]="mappedItem.lotAddress"
                                    addressTitle="Lot Address"
                                    name="lotAddress"
                                    [lockRegion]="true"
                                    [required]="lotAddressField.isRequired"
                                    *ngIf="lotAddressField.isVisible"
                                    [disabled]="!lotAddressField.isEditable"
                                    [readonly]="isReadonly"
                                    class="cb-margin-bottom">
                    </cb-address-new>
                    <div class="flex-col"
                         *ngIf="!mappedItem.hasUnits && (mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand || mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand)">
                        <h3 class="mat-title">
                            Website Publishing
                        </h3>
                        <cb-checkbox name="isKiwibuild"
                                     label="Kiwibuild"
                                     [(ngModel)]="mappedItem.isKiwibuild"
                                     [readonly]="isReadonly">
                        </cb-checkbox>
                        <cb-checkbox name="isGrowhome"
                                     label="Grow home"
                                     [(ngModel)]="mappedItem.isGrowhome"
                                     [readonly]="isReadonly">
                        </cb-checkbox>
                        <cb-checkbox name="isKitset"
                                     label="Kitset"
                                     [(ngModel)]="mappedItem.isKitset"
                                     [readonly]="isReadonly">
                        </cb-checkbox>
                        <cb-checkbox name="isTransportable"
                                     label="Transportable"
                                     [(ngModel)]="mappedItem.isTransportable"
                                     [readonly]="isReadonly">
                        </cb-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="form.invalid || form.pristine"
                (cbClick)="save()"
                [clickLimit]="1"
                *ngIf="!isReadonly">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
