import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { IGenericQuestionMappedItem } from '@app/logic/generic-questions';
import { SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { CbDialogService } from '../../dialog/cb-dialog.service';
import { GenericQuestionEditDialogComponent } from '../generic-question-edit-dialog/generic-question-edit-dialog.component';
import { GenericQuestionResponseDialogComponent } from '../generic-question-response-dialog/generic-question-response-dialog.component';

@Component({
    selector: 'cb-generic-question-item',
    templateUrl: './generic-question-item.component.html',
    styleUrls: ['./generic-question-item.component.scss']
})
export class GenericQuestionItemComponent {

    @Input() public genericQuestion: IGenericQuestionMappedItem;
    @Input() public childItems: any[];
    @Input() public childItemLabel: string;
    @Input() public childSystemArea: SystemAreaEnumId;
    @Input() public entityId: number;
    @Input() public edit: boolean; // was isReadonly
    @Input() public lotId: number; // why is this here, should be generic
    @Input() public systemArea: SystemAreaEnumId;

    private _questionId: number;
    @Input() public set questionNumber(v: number) {
        this._questionId = v;
        if (this.questionNumber === this.genericQuestion.questionNumber) {
            (this.questionSelector.nativeElement as Element).scrollIntoView({
                behavior: 'smooth'
            });
        }
    }
    public get questionNumber(): number {
        return this._questionId;
    }

    @ViewChild('questionSelector') public questionSelector: ElementRef;

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly cbDialog: CbDialogService,
        private readonly cdRef: ChangeDetectorRef
    ) { }



    public editQuestion(): void {
        const dialogRef = this.cbDialog.open(GenericQuestionEditDialogComponent, {
            minWidth: '40%',
            data: {
                genericQuestion: this.genericQuestion.$clone(),
                entityId: this.entityId,
                lotId: this.lotId,
                systemArea: this.systemArea,
                childItemLabel: this.childItemLabel,
                childItems: this.childItems,
                childSystemArea: this.childSystemArea,
            }
        });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {
                this.cdRef.detectChanges();
            }
        });
    }

    public respondQuestion(): void {
        const dialogRef = this.cbDialog.open(GenericQuestionResponseDialogComponent, {
            minWidth: '40%',
            data: {
                childItemLabel: this.childItemLabel,
                childItems: this.childItems,
                childSystemArea: this.childSystemArea,
                entityId: this.genericQuestion.entityId,
                readonly: false,
                genericQuestion: this.genericQuestion.$clone(),
                lotId: this.genericQuestion.lotId,
            }
        });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {
                this.cdRef.detectChanges();
            }
        });
    }

    public viewQuestion(): void {
        this.cbDialog.open(GenericQuestionResponseDialogComponent, {
            minWidth: '40%',
            data: {
                childItemLabel: this.childItemLabel,
                childItems: this.childItems,
                childSystemArea: this.childSystemArea,
                entityId: this.genericQuestion.entityId,
                readonly: true,
                genericQuestion: this.genericQuestion.$clone(),
                lotId: this.genericQuestion.lotId,
            }
        });
    }

    public cancelQuestion(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Cancel Question',
            message: `Are you sure you want to cancel Question #${this.genericQuestion.questionNumber}?`,
            confirmed: () => {
                this.genericQuestion.cancel().subOnce();
                this.cdRef.detectChanges();
            }
        });
    }
}
