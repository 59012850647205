<cb-dialog dialogHeading="Rate Composition">
    <div class="flex-row flex">
        <cb-standard-table class="flex"
                           [value]="rate.locations[0].rates"
                           [columns]="columns"
                           [footerColumns]="footerColumns"
                           valueLabel="Rate Composition Items"
                           label="Rate Composition for {{rate.locations[0].locationLabel}}">
        </cb-standard-table>
    </div>
    <footer class="end-center flex-row">
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </footer>
</cb-dialog>
