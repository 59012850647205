<cb-dialog>
    <form class="flex-col flex"
          #pricingComplexityForm="ngForm">
        <cb-input type="text"
                  name="complexity"
                  label="Complexity"
                  [required]="true"
                  [(ngModel)]="mappedItem.complexity">
        </cb-input>
        <cb-input type="number"
                  name="targetWorkHours"
                  label="Target Work Hours"
                  [required]="true"
                  [(ngModel)]="mappedItem.targetWorkHours">
        </cb-input>
        <cb-input type="number"
                  name="targetCompletionHours"
                  label="Target Completion Hours"
                  [required]="true"
                  [(ngModel)]="mappedItem.targetCompletionHours">
        </cb-input>
        <cb-checkbox name="isActive"
                     label="Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!pricingComplexityForm.dirty || !pricingComplexityForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
