<ng-container>
    <cb-table class="cb-margin-top"
              label="Schedule Items"
              [loaded]="true"
              [minWidth]="750"
              [hideDefaultTable]="true">
        <table cbTable>
            <thead>
                <tr>
                    <td [width]="48"
                        *ngIf="isEdit"></td>
                    <td [width]="48"></td>
                    <td>Name</td>
                    <td>Tag(s)</td>
                    <td [width]="150">Cost Type</td>
                    <td>Product Name /Description</td>
                    <td>Quantity</td>
                    <td class="center-center">
                        Include in Flyer / Create Schedule
                    </td>
                    <td class="costCol">Amount</td>
                    <td></td>
                </tr>
            </thead>
            <tbody class="parent-schedule-template-item"
                   *ngFor="let parentScheduleTemplateItem of houseAreaIdWithItems.scheduleTemplateItems">
                <tr>
                    <td *ngIf="isEdit">
                        <button mat-icon-button
                                [disabled]="!featureToggle.isScheduleItemSpecTemplatesEnabled"
                                (click)="removeItemFromSpecificationTemplate(this.houseAreaIdWithItems.houseAreaId, parentScheduleTemplateItem, true)">
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                    </td>

                    <td>
                        <button mat-icon-button
                                [disabled]="!parentScheduleTemplateItem?.childItems?.length"
                                (click)="expandedParents[parentScheduleTemplateItem.slotId] = !expandedParents[parentScheduleTemplateItem.slotId]">
                            <mat-icon>{{ expandedParents[parentScheduleTemplateItem.slotId] &&
                                parentScheduleTemplateItem?.childItems?.length ?
                                'arrow_drop_up' : 'arrow_drop_down'}}
                            </mat-icon>
                        </button>
                    </td>

                    <td>
                        <span>
                            <cb-td-text [value]="parentScheduleTemplateItem.slotName"></cb-td-text>
                            <cb-info-popup [tooltip]="parentScheduleTemplateItem.slotDescription"></cb-info-popup>
                        </span>
                    </td>
                    <cb-td-list [list]="parentScheduleTemplateItem.tags"></cb-td-list>
                    <cb-td-enum [value]="parentScheduleTemplateItem.costType"
                                [enum]="COST_TYPE_ENUM">
                    </cb-td-enum>
                    <td>
                        <cb-display-value-new *ngIf="parentScheduleTemplateItem.productLabel">
                            {{parentScheduleTemplateItem.productLabel}}
                        </cb-display-value-new>
                    </td>
                    <td>
                        <cb-display-value-new>
                            {{parentScheduleTemplateItem.quantity}}
                        </cb-display-value-new>
                    </td>
                    <td>
                        <div class="flex center-center">
                            <mat-checkbox [(ngModel)]="parentScheduleTemplateItem.showInClientSpecification"
                                          [disabled]="isEdit"
                                          (change)="onFlyerCheckboxChange(parentScheduleTemplateItem)">
                            </mat-checkbox>
                        </div>
                    </td>
                    <cb-td-currency class="costCol"
                                    [value]="parentScheduleTemplateItem.rateSnapshot"
                                    [gstMode]="parentScheduleTemplateItem.costType === COST_TYPE_ENUM.Provisional && 'addGst'">
                    </cb-td-currency>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-icon-button
                                [disabled]="!featureToggle.isScheduleItemSpecTemplatesEnabled || !parentScheduleTemplateItem.id"
                                (cbClick)="editItemClicked(parentScheduleTemplateItem)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>

                </tr>
                <ng-container *ngIf="expandedParents[parentScheduleTemplateItem.slotId]">
                    <tr *ngFor="let childScheduleTemplateItem of parentScheduleTemplateItem.childItems">
                        <td [width]="48"></td>
                        <td *ngIf="isEdit">
                            <button *ngIf="!childScheduleTemplateItem.isRequired"
                                    mat-icon-button
                                    [disabled]="!featureToggle.isScheduleItemSpecTemplatesEnabled"
                                    (click)="removeItemFromSpecificationTemplate(this.houseAreaIdWithItems.houseAreaId,childScheduleTemplateItem, false)">
                                <mat-icon>remove_circle</mat-icon>
                            </button>
                        </td>
                        <td class="childLabel">
                            <span>
                                <cb-td-text [value]="childScheduleTemplateItem.slotName"></cb-td-text>
                                <cb-info-popup [tooltip]="childScheduleTemplateItem.slotDescription"></cb-info-popup>
                            </span>
                        </td>
                        <cb-td-list [list]="childScheduleTemplateItem.tags"></cb-td-list>
                        <cb-td-enum [value]="childScheduleTemplateItem.costType"
                                    [enum]="COST_TYPE_ENUM">
                        </cb-td-enum>
                        <cb-td-text [value]="childScheduleTemplateItem.productLabel"></cb-td-text>
                        <td>
                            <cb-display-value-new>
                                {{childScheduleTemplateItem.quantity}}
                            </cb-display-value-new>
                        </td>
                        <td>
                            <div class="flex center-center">
                                <mat-checkbox [(ngModel)]="childScheduleTemplateItem.showInClientSpecification"
                                              [disabled]="isEdit"
                                              (change)="onFlyerCheckboxChange(childScheduleTemplateItem)">
                                </mat-checkbox>
                            </div>
                        </td>
                        <cb-td-currency class="costCol"
                                        [value]="childScheduleTemplateItem.rateSnapshot"
                                        [gstMode]="childScheduleTemplateItem.costType === COST_TYPE_ENUM.Provisional && 'addGst'">
                        </cb-td-currency>
                        <td cbTableShrinkCol
                            cbTableStickyCol="end">
                            <button mat-icon-button
                                    [disabled]="!featureToggle?.isScheduleItemSpecTemplatesEnabled || !childScheduleTemplateItem?.id"
                                    (cbClick)="editItemClicked(childScheduleTemplateItem)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tbody *ngIf="houseAreaIdWithItems.scheduleTemplateItems?.length < 1">
                <tr class="cb-table-footer-row">
                    <td colspan="100%">
                        <cb-info-message message="There are no Specification Items to display for this House Area"
                                         icon="info_circle"></cb-info-message>
                    </td>
                </tr>
            </tbody>
        </table>
    </cb-table>
</ng-container>
