<cb-dialog dialogHeading="Qualify Lead">
    <form class="flex-row flex"
          #leadQualifyHouseLandForm="ngForm">
        <div class="cb-margin-right-3x flex-col flex">
            <h1 class="cb-margin-bottom-2x mat-title">Qualify Lead - House &amp; Land / Design &amp; Land</h1>
            <div class="cb-margin-bottom-2x start-center flex-row">
                <mat-icon class="mat-accent">
                    info
                </mat-icon>
                <span class="mat-body-1 cb-margin-left">
                    Search for an existing Client Account to qualify this Lead against. Create a new Client Account if
                    there
                    is no existing Account
                </span>
            </div>
            <span class="mat-body-2">Search or Create a new Client Account</span>
            <cb-client-accounts-input-find-cards *ngIf="!data.createNewClientAccount"
                                                 label="Select Existing Client Account"
                                                 [(ngModel)]="selectedAccount"
                                                 name="selectedAccount"
                                                 [text]="selectedAccount?.name"
                                                 [removeable]="true"
                                                 [required]="!data.createNewClientAccount"
                                                 #selectedAccountControl="ngModel"
                                                 (change)="selectedAccountChanged()">
            </cb-client-accounts-input-find-cards>
            <cb-checkbox label="Create New Client Account"
                         name="createNewClientAccount"
                         (change)="createClientAccountChange()"
                         [(ngModel)]="data.createNewClientAccount">
            </cb-checkbox>
        </div>
        <div position="relative">
            <mat-divider></mat-divider>
        </div>
        <div class="flex-col flex">
            <span class="mat-body-2 cb-margin-bottom-2x">Choose the Lots to Convert to a Client Sale</span>
            <ng-container *ngFor="let interest of mappedItem.interests | cbCallback: filterInterests; let last = last; let index = index;">
                <div position="relative">
                    <mat-divider></mat-divider>
                </div>
                <div class="start-center flex-row"
                     style="padding: 10px 16px">
                    <cb-checkbox style="height: 20px !important"
                                 class="cb-margin-right"
                                 [name]="'selectedLotInterests' + index"
                                 (change)="selectedLotsChanged($event, interest)"
                                 *ngIf="canSelectLot(interest)">
                    </cb-checkbox>
                    <mat-icon *ngIf="interest.isPrimary"
                              title="Primary Lot Interest"
                              class="mat-accent">stars</mat-icon>
                    <mat-icon *ngIf="!interest.isPrimary">radio_button_unchecked</mat-icon>
                    <div class="flex-col">
                        <span class="mat-body-2">
                            Lot {{interest.lot.lotNumber}}
                        </span>
                        <span class="mat-body-1">
                            <span class="mat-body-2">
                                {{interest.lot.projectName}}
                            </span>
                            ,&nbsp;{{interest.lot.address.city}}
                        </span>
                    </div>
                </div>
                <div position="relative">
                    <mat-divider></mat-divider>
                </div>
            </ng-container>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="leadQualifyHouseLandForm.pristine || leadQualifyHouseLandForm.invalid || saveDisabled()"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
