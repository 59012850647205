import { Component } from '@angular/core';
import { CouncilLogicService } from '@app/logic/councils/council.logic.service';
import { CouncilMappedItem } from '@app/logic/councils/council.mapped';
import { CouncilDialogComponent } from './council-dialog/council-dialog.component';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ICouncilDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-councils',
    templateUrl: './councils.component.html',
    styleUrls: ['./councils.component.scss'],
    providers: [CouncilLogicService]
})
export class CouncilsComponent extends BaseSimpleListViewDirective<ICouncilDto, CouncilLogicService> {
    public readonly displayedColumns: string[] = [
        'name',
        'isActive',
        'actions'
    ];

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly councilLogicService: CouncilLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
    ) {
        super(
            cbDialog,
            councilLogicService,
            CouncilDialogComponent,
            'Councils',
            CouncilMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<ICouncilDto>): Array<ICouncilDto> {
        return searchResults.sort((a, b) => a.name.localeCompare(b.name));
    }
}



