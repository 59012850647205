import { Pipe, PipeTransform } from '@angular/core';

/** Do not need to use this for enums from @classictechsolutions/hubapi-transpiled-enums - do AN_ENUM[enumId] instead */
@Pipe({
    name: 'cbEnumToLabel'
})
export class CbEnumToLabelPipe implements PipeTransform {
    public transform(value: any, enumeration: any, args?: any): string {
        return this.toHumanizeWords(enumeration[value]);
    }

    public toHumanizeWords(label: string): any {
        const words = (label.charAt(0).toUpperCase() + label.substring(1)).match(/[A-Za-z][a-z]*/g) || [];
        return words.join(' ');
    }
}
