<div class="sliderImgGallery flex-col"
     #imageThumbs>
    <div class="mainImg center-center flex-row flex-fill">
        <div *ngIf="images.length > 1">
            <button mat-icon-button
                    (click)="previousImage()">
                <mat-icon>chevron_left</mat-icon>
            </button>
        </div>
        <div class="center-center flex">
            <cb-image-button [baseUrl]="selectedImage"
                             [images]="images">
            </cb-image-button>
        </div>
        <div class="center-center"
             *ngIf="images.length > 1">
            <button mat-icon-button
                    (click)="nextImage()">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
    </div>
    <div class="scroller"
         *ngIf="images.length > 1"
         style="max-width: 450px; height: 120px;">
        <ul>
            <li *ngFor="let image of images; let i = index"
                class="image-thumb-{{i}} flex"
                [ngClass]="{'selectedImage': i === selectedIndex}">
                <img class="flex"
                     [src]="image"
                     alt="img"
                     (click)="selectImage(i)"/>
            </li>
        </ul>
    </div>
</div>
