import { Component, Input } from '@angular/core';
import { FieldValidationItem } from '@app/core/services/field-validation/field.validation.item';
import { LOT_COMPONENT_TYPE } from '@app/core/services/field-validation/lot-field-validator/interfaces/i.lot.field.rules.service';
import { LotFieldValidatorService } from '@app/core/services/field-validation/lot-field-validator/lot-field-validator-service';
import { ILotMappedItem } from '@app/logic/lots';
import { CONTACT_METHOD_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { LotSummaryPinnablePanelDirective } from '../../shared/lot-summary-pinnable-panel';

@Component({
    selector: 'cb-lot-summary-classic-care',
    templateUrl: './lot-summary-classic-care.component.html',
    styleUrls: ['./lot-summary-classic-care.component.scss']
})
export class LotSummaryClassicCareComponent extends LotSummaryPinnablePanelDirective {

    @Input() public lotMappedItem: ILotMappedItem;

    public CONTACT_METHOD_ENUM = CONTACT_METHOD_ENUM;

    // Field Validation Items
    public contactField = new FieldValidationItem();
    public contactMethodField = new FieldValidationItem();
    public contactDetailField = new FieldValidationItem();

    constructor(private readonly lotFieldValidatorService: LotFieldValidatorService,) {
        super();
    }

    public ngOnInit(): void {
        this.lotFieldValidatorService.dto = this.lotMappedItem.$getMappedDtoItem();
        this.populateFieldValidationItems();
    }

    private populateFieldValidationItems(): void {
        this.contactField = this.lotFieldValidatorService.classicCareContact(LOT_COMPONENT_TYPE.ClassicCare);
        this.contactMethodField = this.lotFieldValidatorService.classicCareContactMethod(LOT_COMPONENT_TYPE.ClassicCare);
        this.contactDetailField = this.lotFieldValidatorService.classicCareContactDetail(LOT_COMPONENT_TYPE.ClassicCare);
    }

}
