import { Component, Input, OnDestroy } from '@angular/core';
import { LotBuildProgrammeEventService } from '../../../services/lot-build-programme-event.service';
import { BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM, IBuildProgrammeStageDto, ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { LotBuildProgrammeActionBarService } from '../../services/lot-build-programme-action-bar.service';
import { IBuildProgrammeActivityMappedItem } from '@app/logic/build-programme-activity';
import { IBuildProgrammeActivitySummaryDto } from '@app/logic/build-programme/interfaces/i.build-programme-indexed.dto';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cb-lot-build-programme-activity-table',
    templateUrl: './lot-build-programme-activity-table.component.html',
    styleUrls: ['./lot-build-programme-activity-table.component.scss']
})
export class LotBuildProgrammeActivityTableComponent implements OnDestroy {
    public static readonly RENDER_DELAY = 1000;
    @Input() public readonly lotDto: ILotDto;
    @Input() public readonly dragulaGroup: string;
    @Input() public readonly highlightedActivityId: number | null;

    public summaryExpanded = {} as { [buildActivityId: number]: boolean };
    public readonly BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM = BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM;

    public readonly displayedColumns: string[] = [
        'status',
        'code',
        'name',
        'supplier',
        'startDate',
        'endDate',
        'duration',
        'actions',
    ];
    public loaded = false;

    private _buildProgrammeStage: IBuildProgrammeStageDto;
    @Input() public set buildProgrammeStage(buildProgrammeStage: IBuildProgrammeStageDto) {
        this._buildProgrammeStage = buildProgrammeStage;
        if (buildProgrammeStage != null) {
            this.loaded = true;
        }
    }
    public get buildProgrammeStage(): IBuildProgrammeStageDto {
        return this._buildProgrammeStage;
    }

    private _renderTimeout: NodeJS.Timeout;
    private _render = false;
    /** for performance - render uses *ngIf to render the table when it should be visible */
    @Input() public set render(v: boolean) {
        clearTimeout(this._renderTimeout);
        if (!v) {
            this._renderTimeout = setTimeout(() => {
                this._render = v;
            }, LotBuildProgrammeActivityTableComponent.RENDER_DELAY);
        } else {
            this._render = v;
        }
    }
    public get render(): boolean {
        return this._render;
    }

    public get activities(): IBuildProgrammeActivityMappedItem[] {
        return this.filterManualOrders(this.lotBuildProgrammeEvents?.activities[this.buildProgrammeStage?.buildStageId]);
    }

    public get summaries(): IBuildProgrammeActivitySummaryDto[] {
        return (this.lotBuildProgrammeEvents?.summaries[this.buildProgrammeStage?.buildStageId] ?? []);
    }

    public get summaryActivties(): { [buildActivityId: number]: IBuildProgrammeActivityMappedItem[] } {
        return this.lotBuildProgrammeEvents?.summaryActivties[this.buildProgrammeStage?.buildStageId];
    }

    private sub$ = new Subscription();

    constructor(
        public readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
        public readonly lotBuildProgrammeActionBar: LotBuildProgrammeActionBarService,
    ) {
        this.sub$.add(
            this.lotBuildProgrammeActionBar.POST_EXPAND_COLLAPSE_ALL.subscribe(this.clearExpandedSummaryCache)
        );
    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    public getBlockActivities(): IBuildProgrammeActivityMappedItem[] {
        return this.filterManualOrders(this.activities)
            .filter(x => x.lotId === this.lotDto?.id);
    }

    public getSummaryActivties(buildActivityId): IBuildProgrammeActivityMappedItem[] {
        return this.filterManualOrders(this.summaryActivties[buildActivityId])
            .filter(x => x.lotId !== this.lotDto?.id);
    }

    private filterManualOrders(activities: IBuildProgrammeActivityMappedItem[]): IBuildProgrammeActivityMappedItem[] {
        return this.lotBuildProgrammeActionBar.viewManualOrders ? activities : activities.filter(x => !x.isFromManualPurchaseOrder && !x.manualOrderId);
    }

    public readonly activityRowClasses = (activity: IBuildProgrammeActivityMappedItem, highlightedActivityId: number): { [key: string]: boolean } => (
        {
            'table-pink': activity.isFromManualPurchaseOrder && activity.statusId !== BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned,
            'table-olive': activity.isOwnersCare && activity.statusId !== BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned,
            'table-grey dragula-ignore': activity.isControlledByParentLot && activity.statusId !== BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned,
            'table-abandoned dragula-ignore': activity.statusId === BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned,
            // eslint-disable-next-line quote-props
            'highlight': activity.id === highlightedActivityId
        }
    );
    private readonly clearExpandedSummaryCache = (allExpanded: boolean): void => {
        if (!allExpanded) {
            this.summaryExpanded = {};
        }
    };
}
