import { Component, Input } from '@angular/core';
import { DesignConceptsLogicService, IDesignConceptMappedItem } from '@app/logic/design-concepts';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ImageUploadDialogComponent } from '@app/shared/components/image-handlers/image-upload-dialog/image-upload-dialog.component';
import { LotImageTypeEnumId, LOT_IMAGE_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
@Component({
    selector: 'cb-design-concept-info',
    templateUrl: './design-concept-info.component.html',
    styleUrls: ['./design-concept-info.component.scss'],
})
export class DesignConceptInfoComponent {

    @Input() public designConcept: IDesignConceptMappedItem;

    public selectedIndex = 0;
    public isCollapsed = false;

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly designConceptsLogicService: DesignConceptsLogicService
    ) { }

    public get isExteriorSelected(): boolean {
        return this.selectedIndex === 0;
    }

    public get isFloorPlanSelected(): boolean {
        return this.selectedIndex === 1;
    }

    public get imageType(): string {
        if (this.isExteriorSelected) {
            return LOT_IMAGE_TYPE_ENUM[LOT_IMAGE_TYPE_ENUM.Exterior];
        } else if (this.isFloorPlanSelected) {
            return LOT_IMAGE_TYPE_ENUM[LOT_IMAGE_TYPE_ENUM.FloorPlan];
        }
    }

    public get imageTypeEnum(): LotImageTypeEnumId {
        if (this.isExteriorSelected) {
            return LOT_IMAGE_TYPE_ENUM.Exterior;
        } else if (this.isFloorPlanSelected) {
            return LOT_IMAGE_TYPE_ENUM.FloorPlan;
        }
    }

    public getUploadButtonText(): string {
        let imageUploaded;
        if (this.isExteriorSelected) {
            imageUploaded = this.designConcept.exteriorImageUrl !== undefined;
        } else if (this.isFloorPlanSelected) {
            imageUploaded = this.designConcept.floorPlanImageUrl !== undefined;
        }
        return imageUploaded ? `Change ${this.imageType} Image` : `Upload ${this.imageType} Image`;
    }

    public getDeleteButtonText(): string {
        return `Delete ${this.imageType} Image`;
    }

    public showDeleteButton(): boolean {
        let imageUploaded;
        if (this.isExteriorSelected) {
            imageUploaded = this.designConcept.exteriorImageUrl !== undefined;
        } else if (this.isFloorPlanSelected) {
            imageUploaded = this.designConcept.floorPlanImageUrl !== undefined;
        }
        return imageUploaded ? true : false;
    }


    public uploadImage(): void {
        const dialogRef = this.cbDialog.open(ImageUploadDialogComponent, {
            data: {
                dialogHeading: 'Upload Image',
                documentName: `${this.imageType}`
            }
        });

        dialogRef.componentInstance.submitClicked.subscribe(file => {
            this.designConceptsLogicService.updateImage(this.designConcept.id, this.imageTypeEnum, file)
                .subOnce((designConceptDto) => {
                    this.designConcept.$updateThisAndOriginal(designConceptDto);
                    dialogRef.close();
                });
        });
    }

    public deleteImage(): void {
        let imageId;
        if (this.isExteriorSelected) {
            imageId = this.designConcept.exteriorImageId;
            this.designConcept.exteriorImageUrl = null;
        } else if (this.isFloorPlanSelected) {
            imageId = this.designConcept.floorPlanImageId;
            this.designConcept.floorPlanImageUrl = null;
        }

        this.designConceptsLogicService.deleteImage(this.designConcept.id, imageId).subOnce((designConceptDto) => {
            this.designConcept.$updateThisAndOriginal(designConceptDto);
        });
    }


}
