import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import {
    NotificationTemplateMappedItem, NotificationTemplateLogicService,
    INotificationTemplateLogicService, INotificationTemplateMappedItem,
    INotificationTemplateDto, INotificationTemplateTreeDto, INotificationTemplateMediumDto
} from '@app/logic/notification-template';
import { IIdAndLabelDto, NOTIFICATION_MEDIUM_ENUM, NOTIFICATION_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { find, map } from 'lodash';
import { insertTextAtCursor, sanitizeString } from '@app/shared/utils/text.util';

interface IData {
    mappedItem: NotificationTemplateMappedItem;
    notificationTemplateTree: INotificationTemplateTreeDto[];
}

@Component({
    selector: 'cb-notification-template-dialog',
    templateUrl: './notification-template-dialog.component.html',
    styleUrls: ['./notification-template-dialog.component.scss']
})
export class NotificationTemplateDialogComponent extends BaseDialogFormViewDirective<INotificationTemplateDto, INotificationTemplateMappedItem, INotificationTemplateLogicService> {
    @ViewChild('dialogElment') public dialogElment: HTMLElement;

    public static readonly MIN_WIDTH = '75%';
    public mappedItem: NotificationTemplateMappedItem;
    public categories: IIdAndLabelDto[];
    public types: IIdAndLabelDto[];
    public mediumTabs: IIdAndLabelDto[];
    public indexedTemplateMediums: any = {};
    public templateVariableFields: any[];
    public seletedVariableField: any;

    private selectedTemplateField: TemplateField;
    private selectedTemplateFieldElement: HTMLInputElement | HTMLTextAreaElement;

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<NotificationTemplateDialogComponent>,
        protected readonly notificationTemplateLogicService: NotificationTemplateLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        if (data.mappedItem.id) {
            this.notificationTemplateLogicService.$getItem(data.mappedItem.id).subOnce((result: INotificationTemplateDto) => {
                Object.assign(this.mappedItem, result);
                this.indexedTemplateMediums = {};
                this.mappedItem.templateMediums.forEach((item: INotificationTemplateMediumDto) => {
                    this.indexedTemplateMediums[item.mediumId] = item;
                });
                this.templateVariableFields = map(this.mappedItem.templateFields, (field: string, index: number) => {
                    return { id: index, label: field, value: `{{${field.toLowerCase()}}}` };
                });
            });
        }
        this.categories = map(data.notificationTemplateTree, (node: INotificationTemplateTreeDto) => {
            return { id: node.id, label: node.label };
        });
        this.types = NOTIFICATION_TYPE_ENUM.toSelectList();
        this.mediumTabs = NOTIFICATION_MEDIUM_ENUM.toSelectList().reverse();
    }

    public get insertVariableLabel(): string {
        return this.selectedTemplateField ? `Inserting ${TemplateField[this.selectedTemplateField]} Template Variables` : undefined;
    }

    public onTabClicked(): void {
        this.selectedTemplateFieldElement = undefined;
        this.selectedTemplateField = undefined;
        this.seletedVariableField = undefined;
    }

    public selectSubjectField(event: MouseEvent): void {
        this.selectFieldEventElement(event, TemplateField.Subject, FieldSelector.InputSelector);
    }

    public selectBodyField(event: MouseEvent): void {
        this.selectFieldEventElement(event, TemplateField.Body, FieldSelector.TextareaSelector);
    }

    public selectFieldEventElement(event: MouseEvent, templateField: TemplateField, fieldSelector: FieldSelector): void {
        const ele = event.currentTarget as HTMLElement;
        const self = this;
        setTimeout(
            () => {
                self.selectedTemplateField = templateField;
                self.selectedTemplateFieldElement = ele.querySelector(fieldSelector)
                    || (self.dialogElment && self.dialogElment.querySelector(`#${FieldSelector.EditableAreaId}`));
            }
        );
    }

    public onVariableSelected(variableId: number): void {
        const element = this.selectedTemplateFieldElement;
        if (element) {
            const variableValue = find(this.templateVariableFields, { id: variableId });
            if (variableValue) {
                setTimeout(() => {
                    element.focus();
                    insertTextAtCursor(element, variableValue.value);
                });
            }
        }
    }

    public onGenerateTemplateMediumClicked(mediumId: number): void {
        this.mappedItem.templateMediums.push({
            templateId: this.mappedItem.id,
            mediumId,
            subject: 'Template Subject Required',
            template: 'Template Body Required',
            isActive: false,
        });
        this.mappedItem.templateMediums.forEach((item: INotificationTemplateMediumDto) => {
            this.indexedTemplateMediums[item.mediumId] = item;
        });
    }

    public onSaveClicked(): void {
        this.mappedItem.templateMediums.forEach((templateMedium: INotificationTemplateMediumDto) => {
            templateMedium.subject = sanitizeString(templateMedium.subject);
            templateMedium.template = sanitizeString(templateMedium.template);
        });
        super.save();
    }
}

enum TemplateField {
    Subject = 1,
    Body = 2
}

enum FieldSelector {
    InputSelector = 'input',
    TextareaSelector = 'textarea',
    EditableAreaId = 'editableArea'
}
