<cb-dialog dialogHeading="Add {{data.stubTypeForTitle}} Document Stubs">
    <form class="flex-col flex"
          #documentStubsForm="ngForm">
        <div class="cb-margin-top flex-row layoutGap-16 flex">
            <cb-select class="flex"
                       label="Available Document Types"
                       name="unusedDocumentType"
                       [options]="unusedDocumentTypes"
                       [valueProp]="WHOLE_OPTION_VALUE_PROP"
                       [(ngModel)]="_selectedDocumentType">
            </cb-select>
            <button mat-icon-button
                    [disabled]="!_selectedDocumentType"
                    (click)="addSelectedDocumentType()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <cb-table class="flex"
                  label="Document Types"
                  [columns]="['Document Type', '']"
                  [loaded]="documentTypesToEnsure">
            <tr cbTableRow
                *ngFor="let document of documentTypesToEnsure">
                <td>
                    {{document.label}}
                </td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="removeSelectedDocumentType(document)">
                        <mat-icon>remove</mat-icon>
                    </button>
                </td>
            </tr>
        </cb-table>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="documentTypesToEnsure.length < 1"
                    (cbClick)="saveEnsureDocumentTypes()"
                    [clickLimit]="1">Add
            </button>
            <button mat-raised-button
                    (cbClick)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
