import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import FileSaver from 'file-saver';

@Component({
    selector: 'cb-view-pdf-dialog',
    templateUrl: './view-pdf-dialog.component.html',
    styleUrls: ['./view-pdf-dialog.component.scss']
})
export class CbViewPdfDialogComponent {


    constructor(public dialogRef: MatDialogRef<CbViewPdfDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IViewPDFData) {
    }

    public ZOOM_STEP = 0.25;
    public DEFAULT_ZOOM = 1;
    public MAX_ZOOM = 2;
    public MIN_ZOOM = 0.25;

    public pdfZoom = this.DEFAULT_ZOOM;

    public close(): void {
        this.dialogRef.close();
    }

    public download(): void {
        FileSaver.saveAs(this.data.blob, this.data.fileName);
    }

    public downloadAndClose(): void {
        FileSaver.saveAs(this.data.blob, this.data.fileName);
        setTimeout(
            () => {
                this.close();
            },
            500
        );
    }


    public zoomIn(): void {
        if (this.pdfZoom < this.MAX_ZOOM) {
            this.pdfZoom += this.ZOOM_STEP;
        }
    }

    public zoomOut(): void {
        if (this.pdfZoom > this.MIN_ZOOM) {
            this.pdfZoom -= this.ZOOM_STEP;
        }
    }

    public resetZoom(): void {
        this.pdfZoom = this.DEFAULT_ZOOM;
    }
}

interface IViewPDFData {
    fileName: string;
    src: string;
    blob: Blob;

}
