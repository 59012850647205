import { cbHandleInvalidImageDirective } from './handle-invalid-image.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [cbHandleInvalidImageDirective],
    exports: [cbHandleInvalidImageDirective],
    imports: [
        CommonModule
    ]
})
export class CbHandleInvalidImageModule { }
