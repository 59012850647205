import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IPermissionDto, IPermissionGroupAreaDto, PermissionAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPermissionGroupAreaMappedItem } from './interfaces/i.permission-group-area.mapped';
import { IPermissionGroupAreaLogicService } from './interfaces/i.permission-group-area.logic.service';

export class PermissionGroupAreaMappedItem
    extends BaseMappedItem<IPermissionGroupAreaDto, IPermissionGroupAreaMappedItem, IPermissionGroupAreaLogicService>
    implements IPermissionGroupAreaMappedItem {

    public label: string;
    @DtoProp public area: PermissionAreaEnumId;
    @DtoProp public permissions: IPermissionDto[];

    constructor(
        sourceData: IPermissionGroupAreaDto,
        logicService: IPermissionGroupAreaLogicService
    ) {
        super(sourceData, logicService, PermissionGroupAreaMappedItem);
    }
}
