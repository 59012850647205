import { Injectable } from '@angular/core';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LotBuildProgrammeActionBarService {

    public reorderingEnabled = false;

    public set viewManualOrders(x: boolean) {
        this.userCache.lotBuildProgrammeView.data.viewManualOrders = x;
    }
    public get viewManualOrders(): boolean {
        return this.userCache?.lotBuildProgrammeView?.silentData?.viewManualOrders;
    }

    public get showAll(): boolean {
        return this.userCache?.lotBuildProgrammeView?.silentData?.viewAllStages;
    }
    public VIEW_ALL_STAGES_CHANGED = new Subject();

    public allExpanded = false;
    public EXPAND_COLLAPSE_ALL = new Subject();
    public POST_EXPAND_COLLAPSE_ALL = new Subject<boolean>();

    public FULLSCREEN_OPEN = new BehaviorSubject(false);

    constructor(
        private readonly userCache: UserCacheService,
    ) { }
}
