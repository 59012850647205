<label class="cb-field-label"
       [ngClass]="{'cb-required': required, 'cb-error': required && value && value.length < 1 && touched}">{{label}}</label>
<ng-container *ngIf="!readonly">
    <mat-selection-list *cbWaitFor="options"
                        [name]="name"
                        [(ngModel)]="value"
                        [required]="required"
                        [disabled]="disabled"
                        (selectionChange)="onSelection()"
                        [cbRequireSelectionListCheckedValidator]="required"
                        [compareWith]="selectComparator.compareSelectOptions"
                        [ngStyle]="{'max-height': maxHeight}">
        <mat-list-option *ngFor="let option of options$ | async"
                         [value]="valueProp ? valueProp == WHOLE_OPTION_VALUE_PROP ? option : option[valueProp] : option.id || 0"
                         [checkboxPosition]="checkboxPosition">
            <ng-container *ngIf="option.isActive === false && strikeThroughInactive; else noStrike">
                <s class="cb-disabled">
                    {{optionLabel(option)}}
                </s>
            </ng-container>
            <ng-template #noStrike>
                <span class="option-label">
                    {{optionLabel(option)}}
                </span>
            </ng-template>
        </mat-list-option>
    </mat-selection-list>
</ng-container>
<div class="flex-col"
     *ngIf="readonly">
    <mat-checkbox *ngFor="let option of options$ | async"
                  [ngClass]="{'read-only': readonly, 'mat-form-field': !slim}"
                  [checked]="isChecked(option['id'])"
                  [disableRipple]="true"
                  [disabled]="true">
        {{optionLabel(option)}}
    </mat-checkbox>
</div>
