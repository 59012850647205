<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="pinnedPanels[silentDataKey]"
                     #buildDetailsExpansionPanel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">home</mat-icon>
            <b>Build Details</b>
        </mat-panel-title>
        <mat-panel-description class="end-center flex-row">
            <button *ngIf="buildDetailsExpansionPanel.expanded || silentData[silentDataKey]"
                    mat-icon-button
                    (click)="togglePanelPinned($event, silentDataKey)">
                <mat-icon svgIcon="pin"
                          [ngClass]="{'cb-accent' : silentData[silentDataKey]}">
                </mat-icon>
            </button>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex-col">
        <div class="flex-wrap">
            <cb-display-value-new class="flex-50"
                                  label="Build Type">
                {{lotMappedItem?.buildType.label | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="appliedDesignConceptField.isVisible"
                                  label="Applied Design Concept">
                {{lotMappedItem?.appliedConceptName | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="landAreaField.isVisible"
                                  label="Land Area">
                {{lotMappedItem?.amenities?.landArea | cbDasher}}m²
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="floorAreaField.isVisible"
                                  label="Floor Area">
                {{lotMappedItem?.amenities?.floorArea | cbDasher}}m²
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="bedroomsField.isVisible"
                                  label="Bedroom">
                {{lotMappedItem?.amenities?.noBedrooms | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="bathroomsField.isVisible"
                                  label="Bathroom">
                {{lotMappedItem?.amenities?.noBathrooms | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="livingroomsField.isVisible"
                                  label="Living">
                {{lotMappedItem?.amenities?.noLivingRooms | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="garagesField.isVisible"
                                  label="Garage">
                {{lotMappedItem?.amenities?.noGarages | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  label="Carpark">
                {{lotMappedItem?.amenities?.noCarparks | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  label="Study">
                {{lotMappedItem?.amenities?.noStudy | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="buildProgrammeTemplateField.isVisible"
                                  label="Build Programme Template">
                {{lotMappedItem?.buildProgrammeTemplateId | cbDasher}}
            </cb-display-value-new>
            <ng-container *ngIf="parentLotField.isVisible">
                <cb-display-value-new class="flex-50"
                                      label="Apartment/Terrace">
                    {{parentLotDto?.id| cbDasher}}
                </cb-display-value-new>
                <cb-display-value-new class="flex-50"
                                      label="Job Number">
                    {{parentLotDto?.jobNumber| cbDasher}}
                </cb-display-value-new>
                <cb-display-value-new class="flex-50"
                                      label="Lot Type">
                    {{parentLotDto?.lotType | cbEnumToLabel: LOT_TYPE_ENUM | cbDasher}}
                </cb-display-value-new>
            </ng-container>
            <cb-display-value-new class="flex-50"
                                  *ngIf="estimatedStartDateField.isVisible"
                                  label="Estimated Start Date">
                {{lotMappedItem?.jobStart?.estimate | date: 'dd MMM yyyy' | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="estimatedCompletionDateField.isVisible"
                                  label="Estimated Completion Date">
                {{lotMappedItem?.jobComplete?.estimate | date: 'dd MMM yyyy' | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="actualStartDateField.isVisible"
                                  label="Actual Start Date">
                {{lotMappedItem?.jobStart?.actual | date: 'dd MMM yyyy' | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="actualCompletionDateField.isVisible"
                                  label="Actual Completion Date">
                {{lotMappedItem?.jobComplete?.actual | date: 'dd MMM yyyy' | cbDasher}}
            </cb-display-value-new>
        </div>
    </div>
</mat-expansion-panel>
