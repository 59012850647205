import {Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';

@Component({
    selector: 'cb-popup-tip',
    templateUrl: './popup-tip.component.html',
    styleUrls: ['./popup-tip.component.scss']
})
export class PopupTipComponent {

    @Input() public readonly horizontalPosition: 'left' | 'right' = 'right';
    @Input() public readonly verticalPosition: 'top' | 'bottom' = 'bottom';
    /**
     * An escape hatch for the old angular js code to display properly. Not used except in extreme circumstances.
     * Keep usage as low as possible then delete field once app is upgraded to new Angular
     */
    @Input() public readonly undefinedBottom = false;
    @Input() public readonly icon = 'info';
    @Input() public readonly iconColour: string | null;
    /** Should always be absolute unless element will never appear on top of the stack with absolute.
     *  This is an escape hatch for extremely hard to fix z-index issues.
     */
    @Input() public readonly cssPosition: 'absolute' | 'fixed' = 'absolute';

    public contentCardHeight: number;
    @ViewChild('contentCardRef', { static: false }) public readonly contentCardRef: ElementRef<HTMLDivElement>;
    public get contentCard(): HTMLDivElement {
        return this.contentCardRef.nativeElement;
    }

    public showContent = false;
    private sub$ = new Subscription();

    @HostListener('click', ['$event'])
    public interceptClick(event: MouseEvent): void {
        // prevent clicks on this popup/toggle from bubbling past the root of this component
        event.stopPropagation();
    }

    public toggle(event: MouseEvent): void {
        event.stopPropagation();
        this.toggleAllowPropagate();
    }

    private toggleAllowPropagate(): void {
        if (!this.showContent) {
            this.contentCard.classList.remove('hide');
        }
        this.showContent = !this.showContent;
        const style = !this.showContent ? 'hide-add' : 'hide-remove';
        this.contentCard.classList.add(style);
        this.contentCardHeight = this.contentCard.offsetHeight;
        setTimeout(() => {
            this.contentCard.classList.remove(style);
            if (!this.showContent) {
                this.contentCard.classList.add('hide');
            }
        }, 300);
        // detect outside click
        this.detectBackdropClick();
    }

    private detectBackdropClick(): void {
        this.sub$.unsubscribe();
        this.sub$ = fromEvent(document, 'click')
            .subscribe(() => {
                if (this.showContent) {
                    this.toggleAllowPropagate();
                }
                this.sub$.unsubscribe();
            });
    }
}
