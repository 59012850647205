export enum ContactPermissionEnum {
    None = 0x00,
    CanSearch = 0x01 << 0,
    CanView = 0x01 << 1,
    CanEdit = 0x01 << 2,
    NoteCanAdd = 0x01 << 3,
    NoteCanEdit = 0x01 << 4,
    NoteCanDelete = 0x01 << 5,
    NoteCanView = 0x01 << 6,
    HistoryCanView = 0x01 << 7,
    AccountsCanView = 0x01 << 8,
    LeadsCanView = 0x01 << 9,
    MyClassicCanView = 0x01 << 10,
    MyClassicCanCreateAccount = 0x01 << 11,
    MyClassicCanChangeUsername = 0x01 << 12,
    MyClassicCanResetPassword = 0x01 << 13,
    MyClassicCanChangeAccess = 0x01 << 14
}
