import {Component, Input} from '@angular/core';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {IStandardPlanMappedItem} from '@app/logic/standard-plans/interfaces/i.standard-plan.mapped';
import {garageTypesSelect} from '../const/garage.constants';
import {StandardPlanLogicService} from '@app/logic/standard-plans';
import {IStandardPlanCategoryDto} from '@classictechsolutions/hubapi-transpiled-enums';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';

@Component({
    selector: 'cb-standard-plan-details-edit',
    templateUrl: './standard-plan-details-edit.component.html',
    styleUrls: ['./standard-plan-details-edit.component.scss'],
    viewProviders: [
        provideParentForm()
    ]
})
export class StandardPlanDetailsEditComponent {

    @Input() public plan: IStandardPlanMappedItem;
    public categories: IStandardPlanCategoryDto[];
    public readonly WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;

    public garageTypes = garageTypesSelect;

    constructor(private readonly standardPlanLogic: StandardPlanLogicService) {
        this.standardPlanLogic.getStandardPlanActiveCategories().subOnce(categories => {
            this.categories = categories;
        });
    }
}
