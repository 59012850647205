import {Injectable, Injector} from '@angular/core';
import {HttpWrapperService} from '../../core/services/http-wrapper/http-wrapper.service';
import {IPricingComplexityLogicService} from './interfaces/i.pricing-complexity.logic.service';
import {BaseLogicService} from '../base/base-logic.service';
import {IPricingComplexityDto} from './interfaces/i.pricing-complexity.dto';
import {IPricingComplexityMappedItem} from './interfaces/i.pricing-complexity.mapped';
import {PricingComplexityMappedItem} from './pricing-complexity.mapped';

@Injectable()
export class PricingComplexityLogicService extends BaseLogicService<IPricingComplexityDto, IPricingComplexityMappedItem> implements IPricingComplexityLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('pricingcomplexity', PricingComplexityMappedItem);
    }
}
