import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import { DtoProp } from '@app/logic/base/dto-prop.decorator';
import { ChangeSourceEnumId, IQuoteDto, IQuoteLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { IQuoteMappedItem } from './interfaces/i.quote.mapped';
import { IQuoteLogicService } from './interfaces/i.quote.logic.service';


export class QuoteMappedItem
    extends BaseMappedItem<IQuoteDto, IQuoteMappedItem, IQuoteLogicService>
    implements IQuoteMappedItem {

    constructor(
        sourceData: IQuoteDto,
        logicService: IQuoteLogicService) {
        super(sourceData, logicService, QuoteMappedItem);
    }

    @DtoProp public costTypeId: number;
    @DtoProp public quoteStatus: number;
    @DtoProp public description: string;
    @DtoProp public quoteNumber: string;
    @DtoProp public quoteTotal: number;
    @DtoProp public allocatedTotal: number;
    @DtoProp public lotId: number;
    @DtoProp public lotHasClientSale: boolean;
    @DtoProp public lotHasBuildProgramme: boolean;
    @DtoProp public lotHasADesignConcept: boolean;
    @DtoProp public supplierId: number;
    @DtoProp public supplierTradingName: string;
    @DtoProp public freeTextSupplierName: string;
    @DtoProp public quoteLines: IQuoteLineDto[];
    @DtoProp public quoteDate: string;
    @DtoProp public expiryDate: string;
    @DtoProp public requiredDate: string;
    @DtoProp public requiredOverdue: boolean;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public changeSource: ChangeSourceEnumId;
    @DtoProp public createMultipleChangeRecords: boolean;
    @DtoProp public changeRecordIds: number[];
    @DtoProp public isScheduleSpecTemplate: boolean;
    @DtoProp public id: number;
    @DtoProp public changeRecordRequired: boolean;

    public getExcludedSlots(quoteId: number): Observable<number[]> {
        return this.$logicService.getExcludedSlots(quoteId);
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }
}
