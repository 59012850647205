import {Injectable, Injector} from '@angular/core';
import {BaseLogicService} from '@app/logic/base/base-logic.service';
import {IDesignRequirementsLogicService} from '@app/logic/design-requirements/interfaces/i.design-requirements-logic.service';
import {IDesignRequirementsMappedItem} from '@app/logic/design-requirements/interfaces/i.design-requirements.mapped';
import {HttpWrapperService} from '@app/core/services/http-wrapper/http-wrapper.service';
import {DesignRequirementsMappedItem} from '@app/logic/design-requirements/design-requirements.mapped';
import {IDesignRequirementsDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class DesignRequirementsLogicService
    extends BaseLogicService<IDesignRequirementsDto, IDesignRequirementsMappedItem>
    implements IDesignRequirementsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('designrequirements', DesignRequirementsMappedItem);
    }
}
