import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { environment } from '@src/environments/environment';
import { filter, Subject } from 'rxjs';

interface IBreadcrumb {
    label: string;
    params: Params;
    url: string;
}

@Component({
    selector: 'cb-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

    public breadcrumbs: Array<IBreadcrumb>;
    private readonly onDestroy = new Subject();
    public readonly environment = environment;

    constructor(
        public readonly navigationService: NavigationService,
        public readonly router: Router,
        private readonly activatedRoute: ActivatedRoute) {
    }

    public ngOnInit(): void {
        this.initialiseBreadCrumbs();
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
            this.initialiseBreadCrumbs();
        });

    }

    public initialiseBreadCrumbs(): void {
        const root: ActivatedRoute = this.activatedRoute.root;
        this.setBreadCrumbs(root.snapshot.firstChild.data);
    }

    private setBreadCrumbs(childRoute): void {
        this.breadcrumbs = null;

        // when using a resolver to specify the route data it comes nested 1 layer too deep
        if (childRoute?.breadcrumb?.breadcrumb) {
            childRoute = childRoute.breadcrumb;
        }
        if (childRoute?.breadcrumbPredecessors) {
            this.breadcrumbs = childRoute?.breadcrumbPredecessors?.map(breadcrumb => ({
                label: breadcrumb.label,
                url: breadcrumb.url,
            }));
        }
        this.breadcrumbs = [
            { label: 'Home', url: '', params: null },
            ...(this.breadcrumbs ?? []),
            { label: childRoute?.breadcrumb, url: '', params: null }
        ];
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(null);
        this.onDestroy.complete();
    }

    public navigate(event: MouseEvent, breadcrumb: IBreadcrumb): void {
        this.navigationService.navigate([breadcrumb.url], event);
    }
}
