import { TaskOutcomesLogicService } from './../../../../../logic/task-outcomes/task-outcomes.logic.service';
import { Component } from '@angular/core';
import { ITaskOutcomeMappedItem } from '@app/logic/task-outcomes/interfaces/i.task-outcome.mapped';
import { PermissionsPermissions } from '@app/core/permissions';
import { TaskOutcomeDialogComponent } from './task-outcome-dialog/task-outcome-dialog.component';
import { CbSortByPipe } from '@app/shared/pipe/sort-by/sort-by.pipe';
import { TaskOutcomeMappedItem } from '@app/logic/task-outcomes/task-outcome.mapped';
import { ITaskOutcomeDto } from '@app/logic/task-outcomes/interfaces/i.task-outcome.dto';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { taskTypeLabels } from '@app/logic/task-outcomes/task.constants';

@Component({
    selector: 'cb-task-outcomes',
    templateUrl: './task-outcomes.component.html',
    styleUrls: ['./task-outcomes.component.scss'],
    providers: [TaskOutcomesLogicService]
})
export class TaskOutcomesComponent extends BaseSimpleListViewDirective<ITaskOutcomeDto, TaskOutcomesLogicService> {

    public taskOutcomes: ITaskOutcomeMappedItem[] = [];
    public taskOutcomesLoaded = false;

    public readonly displayedColumns: string[] = ['id', 'name', 'code', 'taskType', 'isActive', 'actions'];

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        private readonly taskOutcomesLogicService: TaskOutcomesLogicService,
    ) {
        super(
            dialog,
            taskOutcomesLogicService,
            TaskOutcomeDialogComponent,
            'Task Outcome',
            TaskOutcomeMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<ITaskOutcomeDto>): Array<ITaskOutcomeDto> {
        return new CbSortByPipe().transform(searchResults, 'name', 'asc');
    }

    public getLabelForTask(code: string): string {
        return taskTypeLabels[code];
    }
}


