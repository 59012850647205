<cb-dialog [dialogHeading]="getTitle()">
    <form class="flex-col"
          #form="ngForm">
        <cb-select label="House Area"
                   name="houseArea"
                   [(ngModel)]="mappedItem.specGroupId"
                   [options]="houseAreas"
                   [disabled]="data.lockedSpecGroupId || data.locked || !isEditOrAdd()"
                   [required]="true"></cb-select>
        <cb-select label="Colour Item"
                   name="colourItem"
                   [(ngModel)]="mappedItem.colourItemId"
                   [options]="colourItems"
                   [disabled]="data.locked || !isAdd()"
                   [required]="true"></cb-select>
        <cb-input label="Colour"
                  name="colourName"
                  [(ngModel)]="mappedItem.name"
                  [readonly]="!isEditOrAdd()"
                  [required]="false"></cb-input>
        <cb-text-area label="Notes"
                      name="notes"
                      [(ngModel)]="mappedItem.notes"
                      [readonly]="!isEditOrAdd()"
                      [required]="false"></cb-text-area>
        <div class="flex-row">
            <div class="flex-col"
                 id="imageUploadDropzone"
                 style="max-width: 180px;">
                <div class="start-center flex-row flex">
                    <cb-image-button class="flex"
                                     *ngIf="imageUrl"
                                     [baseUrl]="imageUrl"></cb-image-button>
                </div>
                <input type="file"
                       hidden
                       #fileInput
                       (click)="$event.target.value = null"
                       accept=".jpg,.jpeg,.png,.gif,image/jpeg,image/gif,image/png"
                       (change)="uploadFile(form, $event.target.files)"/>
                <button class="cb-margin-top"
                        mat-raised-button
                        (click)="fileInput.click()">
                    <mat-icon>file_upload</mat-icon>
                    <span *ngIf="!imageUrl && !mappedItem.specColourItemImageFile">&nbsp;Upload
                        Image</span>
                    <span *ngIf="imageUrl|| mappedItem.specColourItemImageFile">&nbsp;Replace
                        Image</span>
                </button>
                <button class="cb-margin-top"
                        mat-raised-button
                        *ngIf="mappedItem.canRemoveImage && (imageUrl || mappedItem.specColourItemImageFile)"
                        (click)="removeImage(form)">
                    <mat-icon>delete</mat-icon>
                    &nbsp;Remove Image
                </button>
            </div>
        </div>
        <div class="cb-margin-top flex-col flex">
            <cb-nullable-boolean *ngIf="isAdd()"
                                 label="Include in Flyer / Create Schedule"
                                 name="createScheduleNull"
                                 [(ngModel)]="mappedItem.showInClientSpecification"
                                 nullLabel="Not Set (Inherit from Colour Item)"></cb-nullable-boolean>
            <cb-checkbox label="Include in Flyer / Create Schedule"
                         name="createSchedule"
                         [(ngModel)]="mappedItem.showInClientSpecification"
                         *ngIf="isEdit()"></cb-checkbox>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="form.invalid || form.pristine"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel(form.dirty)">Cancel</button>
    </footer>
</cb-dialog>
