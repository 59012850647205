import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { ProjectsLogicService } from '@app/logic/projects';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';

@Directive({
    selector: '[cbUniqueProjectName]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: UniqueProjectNameValidatorDirective,
        multi: true
    }, ProjectsLogicService]
})
export class UniqueProjectNameValidatorDirective implements Validator {
    @Input() public cbProjectId: number;
    @Input() public cbUniqueProjectName: IProjectMappedItem;

    constructor(private logicService: ProjectsLogicService) { }

    public validate(control: AbstractControl): ValidationErrors {
        if (!control.value || control.value.length <= 0) {
            return new Promise(resolve => resolve(null));
        }
        else if (this.cbUniqueProjectName.$isCloned && this.cbUniqueProjectName.projectName === this.cbUniqueProjectName.$original.projectName) {
            return new Promise(resolve => resolve(null));
        }
        return new Promise(resolve =>
            this.logicService.isProjectNameUnique(this.cbProjectId, control.value).subscribe(result => {
                if (result) {
                    resolve(null);
                }
                else {
                    resolve({ projectName: `Project name ${control.value} is already in use` });
                }
            }));
    }
}
