import { Component, Inject, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IBuildTypeDto } from '@app/logic/build-type';
import { CouncilLogicService } from '@app/logic/councils';
import { ICouncilDto } from '@app/logic/councils/interfaces/i.council.dto';
import { ILeadDto } from '@app/logic/leads';
import { ILeadLotInterestDto } from '@app/logic/leads/interfaces/i.lead.lot.interest.dto';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ILocationDto, LOT_CONTRACT_TYPE_ENUM, LOT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

interface IData {
    mappedItem: ILeadMappedItem;
}

@Component({
    selector: 'cb-lead-choose-contract-type-dialog',
    templateUrl: './lead-choose-contract-type-dialog.component.html',
    styleUrls: ['./lead-choose-contract-type-dialog.component.scss']
})
export class LeadChooseContractTypeDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnDestroy {
    public static readonly MIN_WIDTH = '600px';

    public mappedItem: ILeadMappedItem;
    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;
    public councils: ICouncilDto[];
    public location = {} as ILocationDto;
    public regionId$ = new BehaviorSubject<number>(undefined);
    public regionId: number;
    public buildTypes: IBuildTypeDto[];
    private readonly onDestroy = new Subject();

    public get interest(): ILeadLotInterestDto {
        this.handleInterestSetup();
        return this.mappedItem.interests[0];
    }

    public get dbClientHasLand(): boolean {
        return this._dbClientHasLand;
    }

    public set dbClientHasLand(value: boolean) {
        if (value === false) {
            this.cbDialog.confirm({
                dialogHeading: 'Client Has Land',
                message: 'Are you sure you want to change the client has land field? Doing so will result in the loss of the lot data for this lead interest.',
                confirmed: () => {
                    this.interest.lot = undefined;
                    this.interest.locationId = undefined;
                    this._dbClientHasLand = value;
                    if (this._dbClientHasLand) {
                        this.setupInterestLot();
                    }
                }
            });
        } else {
            this.interest.lot = undefined;
            this.interest.locationId = undefined;
            this._dbClientHasLand = value;
            if (this._dbClientHasLand) {
                this.setupInterestLot();
            }
        }
    }

    private _dbClientHasLand = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        public readonly cbDialog: CbDialogService,
        public readonly dialogRef: MatDialogRef<LeadChooseContractTypeDialogComponent>,
        public readonly councilsLogicService: CouncilLogicService,
        @Inject(LookupService) public readonly lookupService: LookupService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.handleInterestSetup();
        this.regionId$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: regionId => {
                this.location = undefined;
                this.regionId = regionId;
            }
        });
        this.lookupService.BUILD_TYPES.toLookup().$promise.then((result: any) => {
            this.buildTypes = (result as IBuildTypeDto[]).filter(x => x.lotType.indexOf(LOT_TYPE_ENUM.Standalone) > -1);
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(null);
        this.onDestroy.complete();
    }

    public isDesignAndBuild(): boolean {
        return this.mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild;
    }

    private setupInterestLot(): void {
        if (this.isDesignAndBuild() && this.dbClientHasLand) {
            this.interest.lot = {
                id: 0,
                lotAddress: {} as any
            } as any;
            this.interest.lotId = 0;
        }
    }

    private handleInterestSetup(): void {
        if (!this.mappedItem.interests) {
            this.mappedItem.interests = [];
        }
        if (!this.mappedItem.interests[0]) {
            this.mappedItem.interests[0] = {} as any;
        } else if (this.mappedItem.interests[0].contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            this.mappedItem.contractType = LOT_CONTRACT_TYPE_ENUM.HouseAndLand;
        }
    }

    public onAreaChanged(): void {
        this.councilsLogicService.getByLocation(this.location?.id).subOnce(c => this.councils = c.filter(council => council.isActive));
    }

    public performPreSaveMapping(): void {
        this.mappedItem.interests[0].isPrimary = true;
        this.mappedItem.interests[0].locationId = this.location?.id;
        this.mappedItem.interests[0].newLot = this.mappedItem?.interests[0]?.lot?.lotAddress;
        this.mappedItem.interests[0].lotNumber = this.mappedItem?.interests[0]?.lot?.lotNumber;
        this.mappedItem.interests[0].lotId = this.mappedItem?.interests[0]?.lot?.id;
        this.mappedItem.interests[0].newLotOwned = this.mappedItem?.interests[0]?.lot?.isOwned;

        if (this.mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            this.mappedItem.interests[0].lot = null;
        }
    }

    public saveMethod(): Observable<ILeadDto> {
        this.performPreSaveMapping();
        return this.mappedItem.$save();
    }
}
