import {BasePermissions, IBaseSystemAreaPermissions, IDocumentPermissions, IhaveDocumentPermissions} from './base.permissions';
import {PermissionsPermissionEnum} from '../../shared/enums/permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

export interface IPermissionsPermissions extends IBaseSystemAreaPermissions, IhaveDocumentPermissions {
    getDocumentPermissions(): IDocumentPermissions;
    canViewSettings(): boolean;
    canEditSettings(): boolean;
    canViewUsers(): boolean;
    canEditUsers(): boolean;
    canViewRoles(): boolean;
    canEditRoles(): boolean;
    canSeeComponent(): boolean;
    canView(): boolean;
    canEdit(): boolean;
    canViewConnections(): boolean;
}

@Injectable()
export class PermissionsPermissions
    extends BasePermissions<PermissionsPermissionEnum>
    implements IPermissionsPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('permissions', PermissionsPermissionEnum, currentUser, http, router);
    }

    public canSeeComponent(): boolean {
        throw new Error('Method not implemented.');
    }

    public canView(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.UsersCanView);
    }

    public canEdit(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.UsersCanEdit);
    }

    public canViewSettings(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.SettingsCanView);
    }

    public canEditSettings(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.SettingsCanEdit);
    }

    public canViewUsers(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.UsersCanView);
    }

    public canEditUsers(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.UsersCanEdit);
    }

    public canViewRoles(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.RolesCanView);
    }

    public canEditRoles(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.RolesCanEdit);
    }

    public canCreate(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.UsersCanEdit);
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => true,
            canEditDocuments: () => true,
            canReviewDocuments: () => true,
            canUploadDocument: () => true,
            canViewDocuments: () => true
        };
    }

    public canViewConnections(): boolean {
        return this.permissionObject(PermissionsPermissionEnum.CanViewConnection);
    }
}
