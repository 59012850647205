import { CbEnumService, SelectListItem } from '@app/core/services/enum/enum.service';

import { Component } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { SearchIndexesLogicService } from '@app/logic/search-indexes/search-indexes.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { find, indexOf } from 'lodash';
import { SEARCH_INDEX_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { ArrayUtils } from '@app/shared/utils/array.util';

@Component({
    selector: 'cb-search-indexes',
    templateUrl: './search-indexes.component.html',
    styleUrls: ['./search-indexes.component.scss'],
    providers: [SearchIndexesLogicService]
})
export class SearchIndexesComponent {
    public running: boolean[] = [];
    public indexes: Array<SelectListItem>;

    public columns = ['Index', ''];

    constructor(
        private readonly toastService: ToastService,
        protected readonly searchIndexesLogicService: SearchIndexesLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        private readonly enumService: CbEnumService
    ) {
        const indexes = SEARCH_INDEX_ENUM.toSelectList();
        const indexOfAllEnum = indexOf(indexes, indexes.find(x => x.id === SEARCH_INDEX_ENUM.All));

        // Move SEARCH_INDEX_ENUM.All  Enum on the top
        this.indexes = ArrayUtils.moveItemInArrayFromIndexToIndex<SelectListItem>(indexes, indexOfAllEnum, 0);
    }

    public resetIndex(x: SelectListItem): void {
        this.running[x.id] = true;
        const idx = find(this.indexes, (index) => index.id === x.id);
        this.searchIndexesLogicService.reindex(x.id).subOnce({
            next: (response): any => { this.handleResponse(x, idx, response); },
            error: (): any => { this.toastService.showToast(`An error occurred re-indexing ${idx.label}`); },
            complete: (): any => { this.running[x.id] = false; }
        });
    }

    private handleResponse(idx, x: SelectListItem, response: Response): void {
        if (response.resultObject === false) {
            const errorMessages = Object.values(response.errors).join(', ');
            this.toastService.showToast(`Errors occurred in the ${idx.label} index${x.id === 0 ? 'es' : ''}: ${errorMessages}`);
        } else {
            this.toastService.showToast(`${idx.label} index${x.id === 0 ? 'es' : ''} successfully re-indexed`);
        }

    }

    public getDisabled(x: SelectListItem): boolean {
        return this.running[x.id];
    }
}

interface Response {
    errors: {
        [key: string]: string;
    };
    resultObject: boolean;
    avoid404ForNullResponse: boolean;
}
