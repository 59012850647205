<div class="flex-row lg-60 xl-60 flex"
     layout-margin
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">
                Non-Working Days
            </h3>
            <button mat-raised-button
                    (click)="newItemClicked();"
                    color="primary">
                Add Non-Working Day
            </button>
        </div>
        <form class="flex-row flex">
            <cb-select label="Year"
                       name="year"
                       [options]="years"
                       [ngModel]="currentYear"
                       (change)="onCurrentYearChanged($event)"></cb-select>
        </form>
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="searchResults">
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>
                        Dates for {{currentYear}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.date | cbDate}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="cb-margin-right-2x"
                                mat-icon-button
                                (click)="editItemClicked(element)">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                        <button class="cb-margin-right-2x"
                                mat-icon-button
                                (click)="onDeleteClicked(element)">
                            <mat-icon>
                                delete
                            </mat-icon>
                        </button>
                        <button mat-raised-button class="cb-margin-bottom"
                                color="primary"
                                (click)="onManageReionsClicked(element)">
                            Manage Regions
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-info-message *ngIf="searchResultsLoaded && searchResults.length === 0"
                             [message]="'There are no Non-Working Days to display'"
                             [icon]="'info_circle'"></cb-info-message>
            <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
        </div>
    </div>
</div>
