import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {QSTeamPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {UserCacheService} from '@app/core/services/user-cache/user-cache.service';
import {BuildProgrammeLogicService} from '@app/logic/build-programme';
import {DesignConceptsLogicService, IDesignConceptMappedItem} from '@app/logic/design-concepts';
import {ILotMappedItem} from '@app/logic/lots';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {ITakeOffDtoSummary} from '@classictechsolutions/hubapi-transpiled-enums';
import {orderBy} from 'lodash';
import {BehaviorSubject, combineLatest, first, map, shareReplay, skipWhile, Subscription, switchMap, take} from 'rxjs';

@Component({
    selector: 'cb-lot-costs-summary',
    templateUrl: './lot-costs-summary.component.html',
    styleUrls: ['./lot-costs-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotCostsSummarysComponent implements OnDestroy {
    private _subscriptions = new Subscription();

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);

    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public selectedTakeOffIndex$$ = new BehaviorSubject(0);

    public takeOffs$ = this.lotMappedItem$$.asObservable().pipe(
        skipWhile(lot => !lot),
        first(),
        switchMap(lot => lot.getTakeOffs()),
        map(takeOffs => orderBy(
            takeOffs,
            'Id'
        ))
    );
    private _takeOffs$$ = new BehaviorSubject<ITakeOffDtoSummary[]>(null);

    public designConcepts$ = this.lotMappedItem$$.asObservable().pipe(
        skipWhile(lot => !lot),
        switchMap(lot => this.designConceptsLogicService.getByLot(lot.id)),
        map(designConcepts => orderBy(
            designConcepts,
            'createdDate'
        )),
        shareReplay(1),
        take(1)
    );
    private _designConcepts$$ = new BehaviorSubject<IDesignConceptMappedItem[]>(null);

    public selectedIndex$ = combineLatest([
        this.designConcepts$,
        this.lotMappedItem$$,
    ]).pipe(
        map(([designConcepts, lot]) => {
            return lot.activeDesignConceptId ?
                designConcepts.map(concept => concept.id)
                    .indexOf(lot.activeDesignConceptId) :
                designConcepts.length - 1;
        }));
    private selectedIndex$$ = new BehaviorSubject<number>(null);

    public selectedConcept$ = combineLatest([
        this.designConcepts$,
        this.selectedIndex$$,
    ]).pipe(
        map(([
            designConcepts,
            selectedIndex]) => designConcepts[selectedIndex] ?? {}
        ));

    public selectedTakeOff$ = combineLatest([
        this.takeOffs$,
        this.selectedTakeOffIndex$$,
    ]).pipe(
        map(([
            takeOffs,
            selectedTakeOffIndex]) => takeOffs[selectedTakeOffIndex]
        ));

    public nextConceptButtonDisabled$ = combineLatest([
        this.selectedConcept$,
        this.selectedIndex$$,
        this.designConcepts$
    ]).pipe(
        map(([
            selectedConcept,
            selectedIndex,
            designConcepts
        ]) => !selectedConcept ||
            selectedIndex === (designConcepts.length - 1)
        ));

    public previousConceptButtonDisabled$ = combineLatest([
        this.selectedConcept$,
        this.selectedIndex$$
    ]).pipe(
        map(([
            selectedConcept,
            selectedIndex
        ]) => !selectedConcept ||
            selectedIndex <= 0
        ));

    public nextTakeoffButtonDisabled$ = combineLatest([
        this.selectedTakeOff$,
        this.selectedTakeOffIndex$$
    ]).pipe(
        map(([
            selectedTakeOff,
            selectedTakeOffIndex
        ]) => !selectedTakeOff ||
            selectedTakeOffIndex === 0
        )
    );

    public previousTakeoffButtonDisabled$ = combineLatest([
        this.selectedTakeOff$,
        this.selectedTakeOffIndex$$,
        this.takeOffs$
    ]).pipe(
        map(([
            selectedTakeOff,
            selectedTakeOffIndex,
            takeOffs
        ]) => !selectedTakeOff ||
            (selectedTakeOffIndex === (takeOffs.length - 1))
        )
    );

    public actualBuildCost$ = this.lotMappedItem$$.asObservable().pipe(
        skipWhile(lot => !lot),
        first(),
        switchMap(lot => lot.getLotActualBuildCost())
    );

    constructor(
        private readonly designConceptsLogicService: DesignConceptsLogicService,
        private readonly logicService: BuildProgrammeLogicService,
        private readonly dialogService: CbDialogService,
        public cdRef: ChangeDetectorRef,
        private readonly navigationService: NavigationService,
        public readonly qsTeamPermissions: QSTeamPermissions,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
    ) {
        this._subscriptions.add(
            this.selectedIndex$.subscribe(
                this.selectedIndex$$)
        );
        this._subscriptions.add(
            this.designConcepts$.subscribe(
                this._designConcepts$$)
        );
        this._subscriptions.add(
            this.takeOffs$.subscribe(
                this._takeOffs$$)
        );
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public nextConcept(): void {
        if (this.selectedIndex$$.value <
            (this._designConcepts$$
                .value.length - 1)) {
            this.selectedIndex$$.next(
                this.selectedIndex$$.value + 1
            );
        }
    }

    public previousConcept(): void {
        if (this.selectedIndex$$.value > 0) {
            this.selectedIndex$$.next(
                this.selectedIndex$$.value - 1
            );
        }
    }

    public nextTakeoff(): void {
        if (this.selectedTakeOffIndex$$.value > 0) {
            this.selectedTakeOffIndex$$.next(
                this.selectedTakeOffIndex$$.value - 1
            );
        }
    }

    public previousTakeoff(): void {
        if (this.selectedTakeOffIndex$$.value < (this._takeOffs$$.value.length - 1)) {
            this.selectedTakeOffIndex$$.next(
                this.selectedTakeOffIndex$$.value + 1
            );
        }
    }
}
