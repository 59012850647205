import {IBuildOverheadCostDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {IBuildOverheadCostMappedItem} from '@app/logic/build-overhead-costs/interfaces/i.build-overhead-cost-mapped';
import {IBuildOverheadCostLogicService} from '@app/logic/build-overhead-costs/interfaces/i.build-overhead-cost-logic.service';
import {BuildOverheadCostMappedItem} from '@app/logic/build-overhead-costs/build-overhead-cost.mapped';
import {Injectable, Injector} from '@angular/core';
import {HttpWrapperService} from '@app/core/services/http-wrapper/http-wrapper.service';
import {BaseLogicService} from '@app/logic/base/base-logic.service';
import {Observable} from 'rxjs';

@Injectable()
export class BuildOverheadCostLogicService extends BaseLogicService<IBuildOverheadCostDto, IBuildOverheadCostMappedItem> implements IBuildOverheadCostLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildoverheadcosts',
            BuildOverheadCostMappedItem,
        );
    }

    public getActive(): Observable<IBuildOverheadCostDto[]> {
        return this.$http
            .get<IBuildOverheadCostDto[]>(`${this.$baseUri}/active`);
    }
}
