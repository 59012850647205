import {
    ILotSpecScheduleDto,
    ILotSpecScheduleListItemDto} from '@classictechsolutions/hubapi-transpiled-enums';
import { ILotSpecScheduleLogicService } from '.';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ILotSpecScheduleMappedItem } from './interfaces/i.lot-spec-schedule.mapped';

export class LotSpecScheduleMappedItem
    extends BaseMappedItem<ILotSpecScheduleDto, ILotSpecScheduleMappedItem, ILotSpecScheduleLogicService>
    implements ILotSpecScheduleMappedItem {

    @DtoProp public id: number;
    @DtoProp public lotId: number;
    @DtoProp public version: number;
    @DtoProp public lotSpecScheduleItems: ILotSpecScheduleListItemDto[];
    @DtoProp public specTemplateId: number;
    @DtoProp public isScheduleType: boolean;
    @DtoProp public isLotSpecLocked: boolean;

    constructor(
        sourceData: ILotSpecScheduleDto,
        logicService: ILotSpecScheduleLogicService
    ) {
        super(sourceData, logicService, LotSpecScheduleMappedItem);
    }


}
