import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';

import {IBuildOverheadCostDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {ILotBuildOverheadCostLogicService} from '@app/logic/lot-build-overhead-costs/interfaces/i.lot-build-overhead-cost-logic.service';
import {ILotBuildOverheadCostsDto} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/LotBuildOverheadCostsDto';
import {ILotBuildOverheadCostMappedItem} from '@app/logic/lot-build-overhead-costs/interfaces/i.lot-build-overhead-cost-mapped';

export class LotBuildOverheadCostMappedItem
    extends BaseMappedItem<ILotBuildOverheadCostsDto, ILotBuildOverheadCostMappedItem, ILotBuildOverheadCostLogicService>
    implements ILotBuildOverheadCostMappedItem {

    @DtoProp public readonly buildOverheadCost: IBuildOverheadCostDto;
    @DtoProp public readonly id: number;
    @DtoProp public readonly notes: string;
    @DtoProp public readonly value: number;
    @DtoProp public readonly valueGstIncl: number;
    @DtoProp public readonly lotId: number;
    @DtoProp public readonly updatedByName: string;
    @DtoProp public readonly updatedDate: string;
    @DtoProp public readonly createdByName: string;
    @DtoProp public readonly createdDate: string;

    constructor(
        sourceData: ILotBuildOverheadCostsDto,
        logicService: ILotBuildOverheadCostLogicService
    ) {
        super(sourceData, logicService, LotBuildOverheadCostMappedItem);
    }


}
