import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbSortByPipe } from './sort-by.pipe';



@NgModule({
    declarations: [
        CbSortByPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CbSortByPipe
    ]
})
export class CbSortByModule { }
