import { DatePipe } from '@angular/common';
import { ProductPermissions } from '@app/core/permissions';
import { NZ_LOCALE } from '@app/shared/declarations/app.constants';
import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DepInject } from '../base/dep-inject.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { ICostxRateLibraryLogicService } from './interfaces/i.costx-rate-library-logic.service';
import { ICostxRateLibraryDto } from './interfaces/i.costx-rate-library.dto';
import { ICostxRateLibraryMappedItem } from './interfaces/i.costx-rate-library.mapped';

export class CostxRateLibraryMappedItem
    extends BaseMappedItem<ICostxRateLibraryDto, ICostxRateLibraryMappedItem, ICostxRateLibraryLogicService>
    implements ICostxRateLibraryMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public locationId: number;
    @DtoProp public readonly lastExported: DateString;
    @DtoProp public readonly locationName: string;
    @DtoProp public readonly locationPath: string;
    @DtoProp public readonly lastExportedByUserId: string;
    @DtoProp public readonly lastExportedByUserName: string;
    @DtoProp public readonly createdDate: string;
    @DtoProp public readonly createdByName: string;
    @DtoProp public readonly updatedDate: string;
    @DtoProp public readonly updatedByName: string;

    @DepInject(ProductPermissions) public readonly productPermission: ProductPermissions;

    @Computed() public get canManage(): boolean {
        return this.productPermission?.canManageProductCatalogueExports();
    }

    @Computed() public get lastExportedUserDate(): string {
        return this.lastExportedByUserName && this.lastExported ? `${this.lastExportedByUserName} on ${new DatePipe(NZ_LOCALE).transform(this.lastExported)}` : '-';
    }

    constructor(
        sourceData: ICostxRateLibraryDto,
        logicService: ICostxRateLibraryLogicService
    ) {
        super(sourceData, logicService, CostxRateLibraryMappedItem,
            // default data
            {}
        );
    }

    public delete(): Observable<boolean> {
        return this.$logicService.$deleteItem(this.id);
    }

    public download(effectiveDate: DateString = null, includeCompositeItems = false): Observable<void> {
        return this.$logicService.downloadCostXRateLibraryExport(this.id, effectiveDate, includeCompositeItems)
            .pipe(
                tap(() => this.$reload().subOnce())
            );
    }
}
