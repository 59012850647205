import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { ICategoryDto, IParentSlotSaveableDto, ISlotDocumentDto, ISlotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { ISlotMappedItem } from './interfaces/i.slot.mapped';
import { ISlotsLogicService } from './interfaces/i.slots.logic-service';
import { SlotMappedItem } from './slot.mapped';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { ISlotSearchFilters } from '@app/core/services/user-cache/user-cache-areas';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { cleanObjectDeep } from 'cb-hub-lib';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';

/** Note - this is also known as a ***schedule item***
 */
@Injectable()
export class SlotsLogicService
    extends BaseLogicServiceWithSearch<
    ISlotDto,
    ISlotMappedItem,
    ISlotSearchFilters,
    ISearchResult<ISlotDocumentDto>>
    implements ISlotsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('slots', SlotMappedItem);
    }

    public $getSearchList(params: ISlotSearchFilters): Observable<ISearchResult<ISlotDocumentDto>> {
        return this.$http.get<ISearchResult<ISlotDocumentDto>>(`${this.$baseUri}/search`, cleanObjectDeep(cloneDeepSafe(params)));
    }

    public $saveItem(entity: any): Observable<any & ISlotDto[]> {
        const uri = this.$baseUri;
        return this.$http.post<ISlotDto[]>(uri, entity);
    }

    /** @param reportOrders key = Slot.ID, value = Slot.ReportOrder */
    public saveOrder(reportOrders: { [slotId: number]: number }): Observable<ISlotDto[]> {
        return this.$http
            .post<Array<ISlotDto>>(`${this.$baseUri}/reorder`, reportOrders);
    }

    public saveParentSlot(slot: IParentSlotSaveableDto): Observable<ISlotDto[]> {
        return this.$http
            .post<Array<ISlotDto>>(`${this.$baseUri}/parent`, slot);
    }

    public applyTag(slotIds: string[], tagId: number): Observable<ISlotDto[]> {
        const payload = {
            slotIds,
            tagId
        };
        return this.$http.post<ISlotDto[]>(`${this.$baseUri}/tags/addslots`, payload);
    }

    public getChildSlots(parentSlotId: number): Observable<Array<ISlotDto>> {
        return this.$http.get(`${this.$baseUri}/${parentSlotId}/children`);
    }

    public getScheduleItemCategoryPath(slotId: number): Observable<ICategoryDto> {
        return this.$http.get(`${this.$baseUri}/getscheduleitemcategorypath/${slotId}`);
    }

    public generateSimReport(): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/simreport`, 'Generating Schedule Items Manager Report...');
    }
}
