import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ILotMappedItem } from '@app/logic/lots';
import { IPreConsentPlanMappedItem } from '@app/logic/pre-consent-plans';
import { BehaviorSubject } from 'rxjs';
import {IDocumentEntityMappedItem} from '@app/logic/documents';

@Component({
    selector: 'cb-manage-pre-consent-view',
    templateUrl: './manage-pre-consent-view.component.html',
    styleUrls: ['./manage-pre-consent-view.component.scss']
})
export class ManagePreConsentViewComponent {
    @Output() public readonly goBack = new EventEmitter();
    @Output() public documents$$ = new BehaviorSubject([] as IDocumentEntityMappedItem[]);
    @Input() public readonly lotMappedItem: ILotMappedItem;
    public mappedItem$$: BehaviorSubject<IPreConsentPlanMappedItem> = new BehaviorSubject(null);

    @Input() public set mappedItem(_preConsentPlanMappedItem: IPreConsentPlanMappedItem) {
        if (_preConsentPlanMappedItem) {
            _preConsentPlanMappedItem.$reload().subOnce();
            this.mappedItem$$.next(_preConsentPlanMappedItem);
            this.cdRef.detectChanges();
        }
    }
    public get mappedItem(): IPreConsentPlanMappedItem {
        return this.mappedItem$$.value;
    }

    public mappedItemChanged(changedMappedItem: IPreConsentPlanMappedItem): void {
        this.mappedItem = changedMappedItem;
    }

    constructor(public readonly cdRef: ChangeDetectorRef) { }
}
