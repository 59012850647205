<div class="cb-margin flex-col flex">
    <mat-tab-group class="flex"
                   (selectedIndexChange)="onTabChanged($event);"
                   [selectedIndex]="selectedTabIndex"
                   cbTabUrlParam="cbTabRouteId"
                   [cbTabRoute]="QS_TEAM_ROUTE">
        <mat-tab label="Pricing Revisions"
                 cbTabRouteId="pricing-revisions"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-pricing-revisions [resultDirections$]="resultDirections$">
                </cb-pricing-revisions>
            </ng-template>
        </mat-tab>
        <mat-tab label="Takeoffs"
                 cbTabRouteId="takeoffs"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-takeoffs [resultDirections$]="resultDirections$">
                </cb-takeoffs>
            </ng-template>
        </mat-tab>
        <mat-tab label="Changes"
                 cbTabRouteId="changes"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-teams-changes [resultDirections$]="resultDirections$"
                                  [teamCode]="TEAM_CODES_CONST.QsTeamKey">
                </cb-teams-changes>
            </ng-template>
        </mat-tab>
        <mat-tab label="Questions"
                 cbTabRouteId="questions"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-teams-questions [resultDirections$]="resultDirections$"
                                    [teamCode]="TEAM_CODES_CONST.QsTeamKey">
                </cb-teams-questions>
            </ng-template>
        </mat-tab>
        <mat-tab label="Tasks"
                 cbTabRouteId="tasks"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-teams-tasks [resultDirections$]="resultDirections$"
                                [teamCode]="TEAM_CODES_CONST.QsTeamKey">
                </cb-teams-tasks>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
