import { Component, Input } from '@angular/core';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { DesignSchemesLogicService, IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { IPricingRevisionMappedItem } from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import { DateFormats } from '@app/shared/declarations/date-formats.constants';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { DESIGN_SCHEME_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';

@Component({
    selector: 'cb-pricing-revisions-design-scheme-tab',
    templateUrl: './pricing-revisions-design-scheme-tab.component.html',
    styleUrls: ['./pricing-revisions-design-scheme-tab.component.scss']
})
export class PricingRevisionsDesignSchemeTabComponent {
    private _designScheme: IDesignSchemeMappedItem;

    private _mappedItem: IPricingRevisionMappedItem;
    @Input() public set mappedItem(pricingRevision: IPricingRevisionMappedItem) {
        this._mappedItem = pricingRevision;
        if (this.mappedItem) {
            this.logicService.$getMappedItem(this.mappedItem.designSchemeId).subOnce(result => {
                this._designScheme = result;
            });
        }
    }
    public get mappedItem(): IPricingRevisionMappedItem {
        return this._mappedItem;
    }

    public get designScheme(): IDesignSchemeMappedItem {
        return this._designScheme;
    }

    public readonly rejectionHistories = new ComputedProperty(() => {
        const rejectionHistory = this.designScheme
            ?.designSchemeHistories
            ?.filter((x) => x.toStatus === DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution);

        return orderBy(rejectionHistory ?? [], (x) => x.createdDate);
    });

    @Input() public readonly displayName: boolean;
    public buildTypes: { [buildTypeId: number]: string } = {};

    public readonly REJECTION_DATE_FORMAT = DateFormats.TIMEDATE_STANDARD;

    constructor(
        private readonly lookups: LookupService,
        private readonly logicService: DesignSchemesLogicService,
    ) {
        this.lookups.BUILD_TYPES.toLookup().$promise.then((result) => {
            this.buildTypes = result.reduce((prev, curr) => {
                prev[curr.id] = curr.label;
                return prev;
            }, {} as { [buildTypeId: number]: string });
        });
    }

    public displayRejectionNotes(): boolean {
        return this.mappedItem?.statusId === DESIGN_SCHEME_STATUS_ENUM.Declined;
    }
}
