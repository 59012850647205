import { Component } from '@angular/core';
import { ProductPermissions } from '@app/core/permissions';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

@Component({
    selector: 'cb-product-tabs',
    templateUrl: './product-tabs.component.html',
    styleUrls: ['./product-tabs.component.scss']
})
export class ProductTabsComponent {

    constructor(
        private readonly productPermissions: ProductPermissions,
        public readonly featureToggle: FeatureToggleStatesService,
    ) { }

    public canPerformImport = (): boolean => this.productPermissions && this.productPermissions.canPerformImport();
    public canExportCatalogue = (): boolean => this.productPermissions && this.productPermissions.canExportCatalogue();
    public canManageBuildOverheadCosts = (): boolean => this.productPermissions && this.productPermissions.canManageBuildOverheadCosts();
}
