<div class="uploadfilecontainer start-center flex-row flex"
     cbDragDrop
     (onFileDropped)="uploadFile($event)">
    <input hidden
           type="file"
           #fileInput
           [required]="true"
           (click)="$event.target.value = null"
           (change)="uploadFile($event?.target?.files)"/>
    <div layout-padding
         class="cb-margin-3x flex-col flex"
         *ngIf="!(isFileValidAndUploaded$ | async)">
        <div>
            <h4 class="cb-padding-bottom">{{(file$ | async)?.name}}</h4>
            <div class="cb-padding-bottom">{{description}}</div>
            <div class="cb-padding-bottom"
                 *ngIf="maximumFileSizeInMegaBytes"><strong>Max Size</strong>:
                {{maximumFileSizeInMegaBytes}}MB
            </div>
            <h4 *ngIf="allowedFileTypes">Allowed File Types</h4>
            <li *ngFor="let type of allowedFileTypes">
                {{ FILE_TYPE_ENUM[type] }}
            </li>
        </div>
    </div>
    <div layout-padding
         class="cb-margin-3x flex-row flex"
         *ngIf="(isFileValidAndUploaded$ | async)">
        <h4> {{(file$ | async)?.name}}</h4>
    </div>
    <div class="cb-margin-right"
         (click)="fileInput?.click()">
        <button style="width: 60px;height: 60px;"
                mat-icon-button
                color="accent">
            <mat-icon>attach_file_outline</mat-icon>
        </button>
    </div>
</div>
<div class="flex-col">
    <div *ngIf="!isFileExtensionValid"
         class="icon-text">
        <mat-icon color="warn"
                  class="cb-margin-right-2x">report_problem</mat-icon>
        <span>
            The file you have selected has the wrong extension .{{fileExtension}}. Please select a file with one
            of the extensions:
            <span *ngFor="let type of allowedFileTypes; let last = last;">{{ last ?
                FILE_TYPE_ENUM[type] + '. ' : FILE_TYPE_ENUM[type] + ', ' }}</span>
        </span>
    </div>
    <div *ngIf="!isFilesizeValid"
         class="icon-text">
        <mat-icon color="warn"
                  class="cb-margin-right-2x">report_problem</mat-icon>
        <span> The file you have selected is {{fileSizeFormatted}}. This is too large. Please select a file under
            {{maximumFileSizeInMegaBytes}}MB. </span>
    </div>
</div>
