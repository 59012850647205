import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ColourItemsLogicService } from '@app/logic/colour-items';
import { ILotSpecColourItemLogicService, ILotSpecColourItemMappedItem, LotSpecColourItemLogicService } from '@app/logic/lot-spec-colour-item';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { toPromisedArray } from 'cb-hub-lib';
import { ILotSpecColourItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';
import { map } from 'rxjs';

@Component({
    selector: 'cb-edit-lot-spec-colour-item-dialog',
    templateUrl: './edit-lot-spec-colour-item-dialog.component.html',
    styleUrls: ['./edit-lot-spec-colour-item-dialog.component.scss']
})
export class EditLotSpecColourItemDialogComponent
    extends BaseDialogFormViewDirective<ILotSpecColourItemDto, ILotSpecColourItemMappedItem, ILotSpecColourItemLogicService> {
    public static readonly MIN_WIDTH = '45%';

    public houseAreas = toPromisedArray(this.specGroupLogic.$getList().pipe(map(results => orderBy(results, 'sortOrder', 'asc'))));
    public colourItems = toPromisedArray(this.colourItemLogic.$getList());

    private tempImage: string;
    public get imageUrl(): string {
        return !this.mappedItem?.removeImageOnSave && (this.tempImage ?? this.mappedItem.specColourItemImage);
    }

    constructor(
        public readonly dialogRef: MatDialogRef<EditLotSpecColourItemDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: ILotSpecColourItemMappedItem;
            lotSpec: ILotSpecMappedItem;
            locked: boolean;
            lockedSpecGroupId: number;
        },
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(SpecGroupsLogicService) public readonly specGroupLogic: SpecGroupsLogicService,
        @Inject(LotSpecColourItemLogicService) public readonly lotSpecColourItemLogic: LotSpecColourItemLogicService,
        @Inject(ColourItemsLogicService) public readonly colourItemLogic: ColourItemsLogicService,
    ) {
        super(dialogRef, toastService, cbDialog);

        this.formMode = FormMode.Edit;
        this.mappedItem = data.mappedItem;
        if (!this.mappedItem) {
            this.formMode = FormMode.Add;
            this.mappedItem = this.lotSpecColourItemLogic.$createMappedItem({
                lotId: data.lotSpec.lotId,
                versionNumber: data.lotSpec.specVersion,
                specGroupId: data.lockedSpecGroupId,
            });
        } else {
            this.mappedItem.$reload().subOnce();
        }
    }

    public save(): void {
        const block = this.cbDialog.block('Saving Item...');
        this.saveInProgress = true;
        this.saveMethod().subOnce({
            next: (result) => {
                this.handleNext(this.handleSaveSuccess(result));
                this.saveInProgress = false;
                block.close();
            },
            error: (error) => {
                this.handleError(this.handleSaveError(error));
                this.saveInProgress = false;
                block.close();
            }
        });
    }


    public getTitle(): string {
        return `${this.isAdd() ? 'Add' : 'Edit'} Colour Item Details`;
    }

    public clickUpload(e: MouseEvent): void {
        e.stopPropagation();
        e.preventDefault();
    }

    public uploadFile(form: NgForm, uploadedFile: File): void {
        this.mappedItem.specColourItemImageFile = uploadedFile[0];

        const reader = new FileReader();
        reader.addEventListener(
            'load', () => {
                this.mappedItem.specColourItemImage = reader.result as string;
                this.mappedItem.removeImageOnSave = false;
                form.form.markAsDirty();
            },
            false
        );

        if (this.mappedItem.specColourItemImageFile) {
            reader.readAsDataURL(this.mappedItem.specColourItemImageFile);
        }
    }

    public removeImage(form: NgForm): void {
        this.mappedItem.removeImageOnSave = true;
        this.mappedItem.specColourItemImage = null;
        form.form.markAsDirty();
    }
}
