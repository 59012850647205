import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DesignConceptsLogicService, IDesignConceptMappedItem } from '@app/logic/design-concepts';
import { find } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-design-concept-search',
    templateUrl: './design-concept-search.component.html',
    styleUrls: ['./design-concept-search.component.scss']
})
export class DesignConceptSearchComponent {

    public designConcepts$$: BehaviorSubject<IDesignConceptMappedItem[]> = new BehaviorSubject(null);

    @Input() public lotId: number;

    public get designConcepts(): IDesignConceptMappedItem[] {
        return this.designConcepts$$.value;
    }

    @Output() public readonly designConceptSelected = new EventEmitter<IDesignConceptMappedItem>();

    constructor(
        private readonly designConceptsLogicService: DesignConceptsLogicService,
        private readonly route: ActivatedRoute
    ) { }

    public ngAfterViewInit(): void {
        this.designConceptsLogicService.getByLot(this.lotId).subOnce((results) => {
            this.designConcepts$$.next(results);
            this.route.params.subOnce((params: { [key: string]: number }) => {
                const found = find(results, { id: params.id2 * 1 });
                if (found) {
                    this.conceptSelected(found);
                }
            });
        });
    }

    public conceptSelected(designConcept: IDesignConceptMappedItem): void {
        this.designConceptSelected.emit(designConcept);
    }
}
