import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ISlotTagMappedItem } from './interfaces/i.slot-tag.mapped';
import { ISlotTagsLogicService } from './interfaces/i.slot-tags.logic-service';


/** Note - this is also known as a ***schedule item tag***
 */
export class SlotTagMappedItem
    extends BaseMappedItem<ITagDto, ISlotTagMappedItem, ISlotTagsLogicService>
    implements ISlotTagMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public key: string;
    @DtoProp public members: number;
    @DtoProp public isActive: boolean;
    @DtoProp public isSystemTag: boolean;

    constructor(
        sourceData: ITagDto,
        logicService: ISlotTagsLogicService
    ) {
        super(sourceData, logicService, SlotTagMappedItem);
    }
}
