import { BaseMappedItem } from '../base/base-mapped-item';
import { ISpecTemplateLogicService } from './interfaces/i.spec-template.logic.service';
import { ISpecTemplateMappedItem } from './interfaces/i.spec-template.mapped';
import { ISpecHouseAreaDto, ISpecTemplateDto } from './interfaces/i.spec-template.dto';
import { DtoProp } from '../base/dto-prop.decorator';
import { IProjectDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class SpecTemplateMappedItem
    extends BaseMappedItem<ISpecTemplateDto, ISpecTemplateMappedItem, ISpecTemplateLogicService>
    implements ISpecTemplateMappedItem {
    @DtoProp public id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public isRegional: boolean;
    @DtoProp public isScheduleSpecTemplate: boolean;
    @DtoProp public name: string;
    @DtoProp public project: IProjectDetailsDto;
    @DtoProp public projectId: number;
    @DtoProp public regionIds: number[];
    @DtoProp public createdByName: string;
    @DtoProp public createdDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public specTemplateGroups: ISpecHouseAreaDto[];
    @DtoProp public baseTemplateId?: number;

    constructor(
        sourceData: ISpecTemplateDto,
        logicService: ISpecTemplateLogicService
    ) {
        super(
            sourceData,
            logicService,
            SpecTemplateMappedItem
        );
    }
}
