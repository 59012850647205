import { Injectable, Injector } from '@angular/core';
import { ITakeOffTaskDetailsDto, TakeOffTaskStatusEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';

import { map, Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { ITakeoffTaskMappedItem } from './interfaces/i.takeoff-task-mapped';
import { ITakeoffTasksLogicService } from './interfaces/i.takeoff-tasks.logic.service';
import { TakeoffTaskMappedItem } from './takeoff-task-mapped';

@Injectable()
export class TakeoffTasksLogicService
    extends BaseLogicService<
    ITakeOffTaskDetailsDto,
    ITakeoffTaskMappedItem>
    implements ITakeoffTasksLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector
    ) {
        super('takeoffs/tasks', TakeoffTaskMappedItem);
    }

    public getTakeOffTasksForLot(lotId: number): Observable<ITakeOffTaskDetailsDto[]> {
        return this.$http.get<ITakeOffTaskDetailsDto[]>(`${this.$baseUri}/lot/${lotId}`);
    }

    public updateStatus(takeOffTaskId: number, status: TakeOffTaskStatusEnumId): Observable<ITakeOffTaskDetailsDto> {
        return this.$http.post<ITakeOffTaskDetailsDto>(`${this.$baseUri}/${takeOffTaskId}/status/${status}`);
    }

    public getMappedTakeOffTasksForLot(lotId: number): Observable<ITakeoffTaskMappedItem[]> {
        return this.$http.get<ITakeOffTaskDetailsDto[]>(`${this.$baseUri}/lot/${lotId}`).pipe(
            map(dtos => dtos.map(dto => this.$createMappedItem(dto)))
        );
    }
}
