import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IStandardPlanStyleDto } from './interfaces/i.style.dto';
import { IStylesLogicService } from './interfaces/i.style.logic.service';
import { IStyleMappedItem } from './interfaces/i.style.mapped';
import { StyleMappedItem } from './style.mapped';


@Injectable()
export class StylesLogicService
    extends BaseLogicService<IStandardPlanStyleDto, IStyleMappedItem>
    implements IStylesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'standardplans/styles',
            StyleMappedItem,
        );
    }

}
