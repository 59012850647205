<div class="uploadfilecontainer start-center flex-row"
     cbDragDrop
     (onFileDropped)="uploadFile($event)">
    <input hidden
           type="file"
           #fileInput
           (change)="uploadFile($event.target.files)"
           (click)="$event.target.value = null"/>
    <div layout-padding
         class="cb-margin-3x flex-col flex"
         *ngIf="!isFileValidAndUploaded">
        <div>
            <h4 class="cb-padding-bottom">{{this.documentEntityMappedItem?.documentGroup?.name}} -
                {{this.documentEntityMappedItem?.documentType?.label}}</h4>
            <div class="cb-padding-bottom"> {{ this.documentEntityMappedItem?.documentType?.description }}</div>
            <div class="cb-padding-bottom"
                 *ngIf="this.documentEntityMappedItem?.documentType?.maximumFileSizeInMegaBytes">
                <strong>Max
                    Size</strong>
                :
                {{this.documentEntityMappedItem?.documentType?.maximumFileSizeInMegaBytes}}MB
            </div>
            <h4 *ngIf="this.documentEntityMappedItem?.documentType?.fileTypes">Allowed File Types</h4>
            <ul>
                <li *ngFor="let type of this.documentEntityMappedItem?.documentType?.fileTypes">
                    {{ type }}
                </li>
            </ul>
        </div>
    </div>
    <div layout-padding
         class="cb-margin-3x flex-row flex"
         *ngIf="isFileValidAndUploaded">
        <h4> {{this.documentEntityMappedItem?.document?.name}}</h4>
    </div>
    <div class="cb-margin-right"
         (click)="fileInput.click()">
        <button style="width: 60px;height: 60px;"
                mat-icon-button
                color="accent">
            <mat-icon>attach_file_outline</mat-icon>
        </button>
    </div>
</div>
<div class="flex-col">
    <div *ngIf="!this.isFileExtensionValid"
         class="icon-text">
        <mat-icon color="warn"
                  class="cb-margin-right-2x">report_problem</mat-icon>
        <span> The file you have selected has the wrong extension .{{this.fileExtension}}. Please select a file with one
            of the extensions: {{ this.documentEntityMappedItem?.documentType?.fileTypes }}</span>
    </div>
    <div *ngIf="!this.isFilesizeValid"
         class="icon-text">
        <mat-icon color="warn"
                  class="cb-margin-right-2x">report_problem</mat-icon>
        <span> The file you have selected is {{this.fileSizeFormatted}}. This is too large. Please select a file under
            {{this.documentEntityMappedItem?.documentType?.maximumFileSizeInMegaBytes}}MB. </span>
    </div>
</div>
