import { Inject, Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { IPermissionsMappedItem } from './interfaces/i.permissions.mapped';
import { BaseLogicService } from '../base/base-logic.service';
import { IPermissionsLogicService } from './interfaces/i.permissions.logic-service';
import { PermissionsMappedItem } from './permissions.mapped';
import { IPermissionDto, IUserPermissionsDto, PermissionAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { PermissionLogicService } from './permission.logic.service';
import { IPermissionMappedItem, IPermissionOption } from './interfaces/i.permission.mapped';
import { IEnumNestedLookup } from '@app/shared/enums/IEnumNestedLookup';
import { find, forEach, startCase } from 'lodash';

export interface IExplainRequestDto {
    id: string;
    area: PermissionAreaEnumId;
    permissionName: string;
}

export interface IExplainResponseDto {
    source: string;
    value: string;
}

@Injectable()
export class PermissionsLogicService
    extends BaseLogicService<IUserPermissionsDto, IPermissionsMappedItem>
    implements IPermissionsLogicService {

    private _permissionOptionsAllow: IPermissionOption[] = [
        { id: 1, label: 'Inherited - Allow', value: null },
        { id: 2, label: 'Explicit Allow', value: true },
        { id: 3, label: 'Explicit Deny', value: false }
    ];
    private _permissionOptionsDeny: IPermissionOption[] = [
        { id: 1, label: 'Inherited - Deny', value: null },
        { id: 2, label: 'Explicit Allow', value: true },
        { id: 3, label: 'Explicit Deny', value: false }
    ];
    private _permissionOptionsNone: IPermissionOption[] = [
        { id: 1, label: 'Inherited - Not Set', value: null },
        { id: 2, label: 'Explicit Allow', value: true },
        { id: 3, label: 'Explicit Deny', value: false }
    ];

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        @Inject(LookupService) public readonly lookupService: LookupService,
        @Inject(PermissionLogicService) public readonly permissionLogicService: PermissionLogicService,
    ) {
        super('auth/users/permissions', PermissionsMappedItem);
    }

    public $saveItem(entity: IUserPermissionsDto): Observable<IUserPermissionsDto> {
        const uri = this.$baseUri;
        let params: {id: string};
        if (entity.id) {
            params = {id: entity.id};
        }
        return this.$http.put<IUserPermissionsDto>(uri, entity, undefined, undefined, params);
    }

    public $getItem(userId: string): Observable<IUserPermissionsDto> {
        return this.$http.get<IUserPermissionsDto>(`/auth/users/${userId}/permissions`);
    }

    public explain(dto: IExplainRequestDto): Observable<IExplainResponseDto> {
        return this.$http
            .post<IExplainResponseDto>('auth/users/trace', dto, undefined, undefined, {id: dto.id});
    }

    public async hydrate$Permissions(mappedItem: IPermissionsMappedItem): Promise<void> {
        await this.lookupService.SYSTEM_AREAS.toNestedLookup().$promise.then((systemAreas: IEnumNestedLookup<number>[]) => {
            mappedItem.$permissions = this._getMappedPermissions(mappedItem.permissions);
            forEach(mappedItem.$permissions, (permission) => {
                permission.$areaLabel = find(
                    systemAreas,
                    { id: permission.area }).label;
                permission.$label = startCase(permission.name);
                this._hydratePermissionMappedItem(permission);
            });
        });
    };

    private _getMappedPermissions(permissions: IPermissionDto[]): IPermissionMappedItem[] {
        return permissions?.map(dto => {
            if (dto) {
                return this.permissionLogicService.$createMappedItem(dto);
            }
        });
    }

    private _hydratePermissionMappedItem(permission: IPermissionMappedItem): IPermissionMappedItem {

        if (permission.inheritedAllow === true) {
            permission.$statusOptions = this._permissionOptionsAllow;
        } else if (permission.inheritedAllow === false) {
            permission.$statusOptions = this._permissionOptionsDeny;
        } else {
            permission.$statusOptions = this._permissionOptionsNone;
        }

        // set value
        if (permission.explicitAllow === true) {
            permission.$value = permission.$statusOptions[1];
        } else if (permission.explicitAllow === false) {
            permission.$value = permission.$statusOptions[2];
        } else {
            permission.$value = permission.$statusOptions[0];
        }
        return permission;
    }
}
