<div class="cb-margin">
    <mat-card class="cb-margin-bottom flex-col">
        <div class="flex-row"
             style="overflow-x: auto"
             *cbWaitFor="selectedConcept$ | async">
            <div class="flex-col"
                 style="min-width: 140px; padding: 69px 8px 12px 8px;">
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-2">Land Price</span>
                </div>
                <mat-divider></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-2"
                          class="no-wrap">Build Cost</span>
                </div>
                <mat-divider></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-2"
                          class="no-wrap">Margin</span>
                </div>
                <mat-divider></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-2"
                          class="no-wrap">Build Price</span>
                </div>
                <mat-divider></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-2"
                          class="no-wrap">Sell Price</span>
                </div>
                <mat-divider></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-2"
                          class="no-wrap">Contract Price</span>
                </div>
                <mat-divider></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-2"
                          class="no-wrap">Actual Margin</span>
                </div>
                <mat-divider></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-2"
                          class="no-wrap">Floor Area</span>
                </div>
                <mat-divider></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-2"
                          class="no-wrap">
                        Cost m
                        <sup>2</sup>
                        Rate
                    </span>
                </div>
            </div>
            <div class="flex-col"
                 style="min-width: 205px; margin-top:8px;">
                <div class="center-center flex-row"
                     style="margin: 8px; height: 44px">
                    <cb-button [disabled]="previousConceptButtonDisabled$ | async"
                               (clicked)="previousConcept()"
                               color="white"
                               icon="keyboard_arrow_left">
                    </cb-button>
                    <div class="no-wrap center-center flex-col flex"
                         style="margin: 0 8px">
                        <span class="mat-body-2"> Initial Pricing </span>
                        <span class="mat-body-1">
                            {{(selectedConcept$ | async)?.name || '-'}}
                        </span>
                    </div>
                    <cb-button [disabled]="nextConceptButtonDisabled$ | async"
                               (clicked)="nextConcept()"
                               color="white"
                               icon="keyboard_arrow_right">
                    </cb-button>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">{{(selectedConcept$ | async)?.pricingRevision?.landPrice |
                        cbCurrency}}</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">{{(selectedConcept$ | async)?.pricingRevision?.buildCost |
                        cbCurrency}}</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">{{(selectedConcept$ | async)?.pricingRevision?.margin != undefined ?
                        (selectedConcept$ | async)?.pricingRevision.margin.toString()
                        + '%' : '-'}}</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">{{(selectedConcept$ | async)?.pricingRevision?.buildPrice |
                        cbCurrency}}</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">{{(selectedConcept$ | async)?.sellingPrice | cbCurrency}}</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">{{(lotMappedItem$$ | async)?.financials?.contractPrice |
                        cbCurrency}}</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">{{(lotMappedItem$$ | async)?.financials?.contractPrice > 0 ?
                        (((lotMappedItem$$ | async)?.financials?.contractPrice-(selectedConcept$ |
                        async)?.pricingRevision?.buildPrice)
                        | cbCurrency) : '-'}}</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <cb-display-value-new *ngIf="(selectedConcept$ | async)?.pricingRevision != undefined"
                                          value="{{(selectedConcept$ | async)?.pricingRevision?.amenities.floorArea}}m²">
                    </cb-display-value-new>
                    <span class="mat-body-1"
                          *ngIf="(selectedConcept$ | async)?.pricingRevision == undefined">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">{{((selectedConcept$ | async)?.pricingRevision?.buildCost /
                        (selectedConcept$ | async)?.pricingRevision?.amenities.floorArea)
                        | cbCurrency}}</span>
                </div>
            </div>
            <div class="flex-col"
                 style="min-width: 170px; margin-top:8px;">
                <div class="center-center flex-row"
                     style="margin: 8px; height: 44px">
                    <cb-button [disabled]="previousTakeoffButtonDisabled$ | async"
                               (clicked)="previousTakeoff()"
                               color="white"
                               icon="keyboard_arrow_left">
                    </cb-button>
                    <div class="no-wrap center-center flex-col flex"
                         style="margin: 0 8px">
                        <span class="mat-body-2">Takeoff</span>
                        <span class="mat-body-1">{{(selectedTakeOff$ | async)?.takeoffTaskNumber | cbDasher}}</span>
                    </div>
                    <cb-button [disabled]="nextTakeoffButtonDisabled$ | async"
                               (clicked)="nextTakeoff()"
                               color="white"
                               icon="keyboard_arrow_right">
                    </cb-button>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <cb-display-value-new [value]="(selectedTakeOff$ | async)?.cost | cbCurrency">
                    </cb-display-value-new>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
            </div>
            <div class="flex-col"
                 style="min-width: 120px; margin-top:8px;">
                <div class="center-center flex-row"
                     style="margin: 8px; height: 44px">
                    <div class="center-center flex-col">
                        <span class="mat-body-2">Actual</span>
                    </div>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <cb-display-value-new [value]="(actualBuildCost$ | async) | cbCurrency: undefined: undefined: true">
                    </cb-display-value-new>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
                <mat-divider style="margin: 0 8px"></mat-divider>
                <div class="center-center flex-row"
                     style="height: 45px">
                    <span class="mat-body-1">-</span>
                </div>
            </div>
        </div>
    </mat-card>
</div>
