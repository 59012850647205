export enum USER_CACHE_CATEGORY {
    DesignTeam = 1,
    QsTeam = 2,
    StandardPlans = 3,
    ClientSale = 4,
    SpecTemplates = 5,
    BusinessAccounts = 6,
    BuildProgramme = 7,
    HelpArea = 8,
    Lot = 9,
    LotSpecification = 10,
    ManualOrders = 11,
    Products = 12,
    Settings = 13,
    Dashboard = 14,
    Sidebars = 15,
    Leads = 16,
    PurchaseOrders = 17,
    HouseAndLand = 18,
    Account = 19,
    Contact = 20,
    Project = 21,
    Vendor = 22,
    LotChange = 23,
    AllTeams = 24,
    Slots = 25,
    Users = 26,
    SupplierPortal = 27,
}
