import { ILotConsentDto } from './interfaces/i.lot-consent.dto';
import { BaseLogicService } from '../base/base-logic.service';
import { ILotConsentLogicService } from './interfaces/i.lot-consent-logic.service';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LotConsentLogicService
    extends BaseLogicService<ILotConsentDto, Object>
    implements ILotConsentLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'lotconsents',
            Object
        );
    }

    public getByLot(lotId: number): Observable<ILotConsentDto[]> {
        return this.$http.get(`${this.$baseUri}/getbylot/${lotId}`);
    }

}
