import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { IDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { takeOne } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider } from '../../autocomplete-scroller.component';

@Component({
    selector: 'cb-document-type-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(DocumentTypeAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(DocumentTypeAutocompleteScrollerComponent),
        SearchService,
    ]
})
export class DocumentTypeAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent {
    public results: IDocumentTypeDto[] = [];
    private resultsLoaded = false;

    constructor(
        public readonly buildProgrammeLogicService: BuildProgrammeLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: SearchService,
        protected readonly documentTypeLogicService: DocumentTypesLogicService
    ) {
        super(dialog, searchService);
        this.searchService.doSearch = this.doSearch;
    }

    public doSearch = (query: string): Observable<any[]> => {
        return this.getResults()
            .pipe(takeOne(), map((results) => {
                const filtered = results.filter(x =>
                    (this.displayWith(x) || '').toLowerCase().indexOf(query.toLowerCase()) > -1
                );
                return filtered;
            }));
    };

    public displayWith(item: IDocumentTypeDto): string {
        return item ? item.label : null;
    }

    private getResults(): Observable<IDocumentTypeDto[]> {
        return new Observable<IDocumentTypeDto[]>((subscriber) => {
            if (!this.resultsLoaded) {
                this.resultsLoaded = true;
                const resultsObservable = this.documentTypeLogicService.$getList();
                resultsObservable
                    .subOnce(x => {
                        this.results = x;
                        subscriber.next(this.results);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.results);
                subscriber.complete();
            }
        });
    }
}
