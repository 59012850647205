<cdk-virtual-scroll-viewport class="flex-fill"
                             *cbWaitFor="loaded$$ | async"
                             itemSize="50">
    <mat-accordion [multi]="true"
                   class="accordion">
        <mat-expansion-panel class="cb-margin-left cb-margin-right"
                             *cdkVirtualFor="let buildProgrammeStage of visibleStages"
                             [(expanded)]="expandedPanels[buildProgrammeStage.buildStageId]"
                             (expandedChange)="resetAllExpanded()">
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title>
                    <span class="mat-body-2 cb-margin-top">{{buildProgrammeStage?.buildStageLabel}}
                        ({{lotBuildProgrammeEvents?.activities[buildProgrammeStage.buildStageId]?.length || 0}})</span>
                </mat-panel-title>
                <mat-panel-description class="end-center flex-row">
                    <button mat-stroked-button
                            color="accent"
                            class="cb-margin-right"
                            *ngIf="lotBuildProgrammePermissions.canGenerateSSR() && stageFlags[buildProgrammeStage.buildStageId]?.canGenerateSsrs"
                            (click)="generateSsrs($event, buildProgrammeStage.buildStageId)">
                        Generate PO
                        <small>s</small>
                        &amp; SSR
                        <small>s</small>
                    </button>
                    <button mat-stroked-button
                            color="accent"
                            class="cb-margin-right"
                            *ngIf="lotBuildProgrammePermissions.canConfirmSSR() && stageFlags[buildProgrammeStage.buildStageId]?.canConfirmSsrs"
                            (click)="confirmSsrs($event, buildProgrammeStage.buildStageId)">
                        Confirm SSR
                        <small>s</small>
                    </button>
                    <button mat-stroked-button
                            color="accent"
                            class="cb-margin-right"
                            *ngIf="lotBuildProgrammePermissions.canCompleteSSR() && stageFlags[buildProgrammeStage.buildStageId]?.canCompleteSsrs"
                            (click)="completeActivities($event, buildProgrammeStage.buildStageId)">
                        Complete Activities
                    </button>
                    <button mat-icon-button
                            (click)="togglePinnedPanel($event, buildProgrammeStage.buildStageId)">
                        <mat-icon svgIcon="pin"
                                  [color]="cache.data[buildProgrammeStage.buildStageId] ? 'accent' : ''"></mat-icon>
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <cb-lot-build-programme-activity-table [lotDto]="lotDto"
                                                   [buildProgrammeStage]="buildProgrammeStage"
                                                   [render]="expandedPanels[buildProgrammeStage.buildStageId]"
                                                   [dragulaGroup]="dragulaGroup"
                                                   [highlightedActivityId]="highlightedActivityId">
            </cb-lot-build-programme-activity-table>
        </mat-expansion-panel>
        <cb-info-message *ngIf="!anyActivitiesAvailable()"
                         message="There are no Activities"
                         [icon]="'info_circle'">
        </cb-info-message>
    </mat-accordion>
</cdk-virtual-scroll-viewport>
