<cb-dialog dialogHeading="Standard Plan Cost">
    <form class="flex-col"
          #standardPlanCostForm="ngForm">
        <mat-dialog-content>
            <cb-select [required]="true"
                       name="region"
                       label="Region"
                       [options]="this.data.addressRegions"
                       [(ngModel)]="this.data.cost.regionId">
            </cb-select>
            <mat-form-field>
                <input matInput
                       type="text"
                       name="floorArea"
                       placeholder="Floor Area"
                       disabled
                       [value]="this.formattedFloorArea"/>
            </mat-form-field>
            <cb-select [required]="true"
                       name="style"
                       label="Style"
                       [options]="this.exteriorStyles"
                       [(ngModel)]="this.data.cost.styleId">
            </cb-select>
            <cb-currency name="totalCost"
                         label="Total Cost"
                         [(ngModel)]="this.data.cost.totalCost"
                         (change)="calcCost()"></cb-currency>
            <cb-display-value-new label="M² Cost">
                {{this.data.cost.m2Cost | cbCurrency}}
            </cb-display-value-new>
            <cb-checkbox name="isActive"
                         label="Active"
                         [(ngModel)]="this.data.cost.isActive"></cb-checkbox>
            <cb-display-value-new label="Created by"
                                  *ngIf="this.data.cost.createdByName">
                {{this.data.cost.createdByName}} on {{this.data.cost.createdDate | date}}
            </cb-display-value-new>
            <cb-display-value-new label="Updated by"
                                  *ngIf="this.data.cost.updatedByName">
                {{this.data.cost.updatedByName}} on {{this.data.cost.updatedDate | date}}
            </cb-display-value-new>
        </mat-dialog-content>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                [disabled]=" !standardPlanCostForm.dirty || !standardPlanCostForm.valid"
                (click)="save()">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
