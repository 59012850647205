import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { environment } from '@src/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { Observable, Subject, take, tap } from 'rxjs';
import { CurrentUserService } from './current.user';

const TOKEN_EXPIRATION_BUFFER_SECONDS = 60;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private aadToken: string;
    private apiToken: string;
    private apiTimeout = 0;
    private aadTimeout = 0;
    /** when set to true, an api token fetch is in progress */
    private fetchAPIRequestInProgress = false;
    private fetchAADRequestInProgress = false;

    private fetchAPIRequest: Promise<string>;
    private fetchAADRequest: Promise<string>;

    public errorResponse: HttpErrorResponse;

    public isApiAuthed = new Subject();

    public get isAuthenticated(): boolean {
        return (this.isAADAuth && this.isAPIAuth);
    }

    private get isAADAuth(): boolean {
        return !!this.aadToken && this.aadToken.length > 1;
    }

    private get isAPIAuth(): boolean {
        return !!this.apiToken && this.apiToken.length > 1;
    }

    private get tokenAPIHasTimedOut(): boolean {
        // Refresh Hub API token within the last X seconds before it expires
        return ((this.apiTimeout - TOKEN_EXPIRATION_BUFFER_SECONDS) <= this.getTimeInSeconds());
    }

    private get tokenAADHasTimedOut(): boolean {
        // Refresh the AAD token within the last X seconds before it expires
        return ((this.aadTimeout - TOKEN_EXPIRATION_BUFFER_SECONDS) <= this.getTimeInSeconds());
    }

    public get authFailed(): boolean {
        return !!this.errorResponse;
    }

    public get errorResponseMessage(): string {
        return this.errorResponse.error && typeof (this.errorResponse.error.message) === 'string'
            ? this.errorResponse.error.message
            : this.errorResponse.error || 'Authentication Failed';
    }

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private readonly currentUser: CurrentUserService,
        private readonly http: HttpClient,
        private readonly msalService: MsalService,
    ) {
        this.msalService.instance.enableAccountStorageEvents();
    }

    private getTimeInSeconds(): number {
        // convert from milliseconds to seconds
        const toSeconds = 1000;
        return Date.now() / toSeconds;
    }

    private setAADToken(idToken: string): void {
        this.aadToken = idToken;
        const decodedToken = jwtDecode(idToken) as any; // decode token
        this.aadTimeout = decodedToken.exp;
        this.checkAndSetActiveAccount();
    }

    private setApiToken(token: string): void {
        this.apiToken = token;
        const decodedToken = jwtDecode(token) as any; // decode token
        this.currentUser.setAPIInfo(decodedToken);
        this.isApiAuthed.next(true);
        this.apiTimeout = decodedToken.exp;
    }

    private checkAndSetActiveAccount(): void {
        if (this.msalService.instance.getAllAccounts().length > 0) {
            const accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
    }

    private fillAuthData(): void {
        const authData = this.msalService.instance.getActiveAccount();
        if (authData) {
            this.currentUser.setAADInfo({ userName: authData.username, profile: authData.idTokenClaims });
        }
    }

    private clearApiToken(): void {
        this.apiToken = undefined;
        this.apiTimeout = 0;
    }

    private clearAADToken(): void {
        this.aadToken = undefined;
        this.aadTimeout = 0;
        // There is a bug in the MSAL redirection library where logging out and redirecting back to the app
        // does not always clear the MSAL state cookies. When we redirect to hub using the 'isLoggedOut' url
        // everything works fine as we manually clean up the session, but sometimes AD does not redirect
        // us back to hub so the msal library thinks "there is an interaction in progress" and subsequent
        // login operations fail.
        // The solution below (clearing the session) is a work around BUT its the only recommended workaround.
        // I'm leaving these comments here as they might fix this bug in a future version, after which this
        // might become a problem and should then be removed.
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5807
        const itemKey = 'msal.interaction.status';
        if (sessionStorage.getItem(itemKey))
        {
            sessionStorage.removeItem(itemKey);
        }
    }

    public clearTokens(): void {
        this.clearAADToken();
        this.clearApiToken();
    }

    public login(): Observable<AuthenticationResult> {
        if (this.msalGuardConfig.authRequest) {
            return this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                .pipe(tap((response: AuthenticationResult) => {
                    this.msalService.instance.setActiveAccount(response.account);
                }));
        } else {
            return this.msalService.loginPopup()
                .pipe(tap((response: AuthenticationResult) => {
                    this.msalService.instance.setActiveAccount(response.account);
                }));
        }
    }

    public readonly logout = (): void => {
        this.msalService.logoutRedirect();
    };

    public getAADToken(callback: (errorDesc?: string, token?: string, error?: string) => void): void {
        // If we have a token that's not expired, simply return it
        if (this.aadToken && !this.tokenAADHasTimedOut) {
            callback(null, this.aadToken);
            return;
        }
        this.authenticate().then((token) => {
            /** Don't use the token returned, it's from Hub, return the AAD token
             * which would have been set by the msalBroadcastMessage
             */
            callback(null, this.aadToken);
        }).catch(reason => {
            callback('AAD Token Refresh Failed', null, reason);
        });
    }

    public getAPIToken(callback: (errorDesc?: string, token?: string, error?: string) => void): void {
        // If we have a token that's not expired, simply return it
        if (this.apiToken && !this.tokenAPIHasTimedOut) {
            callback(null, this.apiToken);
            return;
        }
        this.authenticate().then((token) => {
            callback(null, token);
        }).catch(reason => {
            callback('API Token Refresh Failed', null, reason);
        });
    }


    /** Authenticates the user using ADAL and then handles fetching our api token after the fact
     *
     * @param force - if true tokens will be cleared and re-fetched (AAD Token may be fetched from cached which is intended by ADAL)
     * @returns Promise with API Token as value
     */
    public authenticate(force = false): Promise<string> {
        if (force) {
            this.clearTokens();
        }
        return new Promise(this.authenticatePromiseHandler);
    }

    private readonly authenticatePromiseHandler = (resolvePromise, rejectPromise): void => {
        if (this.isAuthenticated &&
            !this.tokenAPIHasTimedOut &&
            !this.tokenAADHasTimedOut) {
            resolvePromise(this.apiToken);
            return;
        }

        this.fetchAADToken().then(token => {
            this.fetchAPIToken(token).then(apiToken => {
                resolvePromise(apiToken);
            }).catch(reason => {
                // Failed trying to get an API token
                rejectPromise(reason);
            });

        }).catch(reason => {
            // Failed trying to get an AAD token
            rejectPromise(reason);
        });
    };

    /** fetches the api token from /api/auth/fetchclaimtoken or cached token */
    private fetchAADToken(): Promise<string> {
        if (this.fetchAADRequestInProgress) {
            return this.fetchAADRequest;
        }
        // create and return new fetch request Promise
        this.fetchAADRequestInProgress = true;
        this.fetchAADRequest = new Promise(this.fetchAADTokenPromiseHandler.bind(this));
        return this.fetchAADRequest;
    }


    /** fetches the api token from /api/auth/fetchclaimtoken or cached token */
    private fetchAPIToken(aadToken: string): Promise<string> {
        if (this.fetchAPIRequestInProgress) {
            return this.fetchAPIRequest;
        }
        // create and return new fetch request Promise
        this.fetchAPIRequestInProgress = true;
        this.fetchAPIRequest = new Promise(this.fetchAPITokenPromiseHandler.bind(this, aadToken));
        return this.fetchAPIRequest;
    }

    private readonly fetchAADTokenPromiseHandler = (resolvePromise, rejectPromise): void => {
        // If we have a valid AAD Token, and it's not expired, simply return it
        if (this.isAADAuth && !this.tokenAADHasTimedOut) {
            resolvePromise(this.aadToken);
            this.fetchAADRequestInProgress = false;
            return;
        }

        // clear aad token to ensure new AAD token will be used in subsequent requests
        this.clearAADToken();

        this.msalService.initialize().pipe().subscribe((x=> {
            this.HandleAADToken(resolvePromise, rejectPromise);
        }));
    };

    private HandleAADToken(resolvePromise, rejectPromise): void {
        this.msalService.instance.acquireTokenSilent({ scopes: ['user.read'], forceRefresh: true }).then((authResult) => {
            this.setAADToken(authResult.idToken);
            resolvePromise(authResult.idToken);
        }).catch(() => {
            // Failing a silent refresh, perform a log in popup
            this.msalService.instance.loginPopup({ scopes: ['user.read'] }).then((authResult) => {
                this.setAADToken(authResult.idToken);
                resolvePromise(authResult.idToken);
            }).catch((reason) => {
                rejectPromise(reason);
            });
        }).finally(() => {
            this.fetchAADRequestInProgress = false;
        });
    }

    private readonly fetchAPITokenPromiseHandler = (aadToken: string, resolvePromise, rejectPromise): void => {

        // if already authenticated and token not timed out, just return the apiToken
        if (!this.tokenAPIHasTimedOut && this.isAPIAuth) {
            resolvePromise(this.apiToken);
            this.fetchAPIRequestInProgress = false;
            return;
        }
        this.fillAuthData();

        // clear api token to ensure new token will be used in subsequent requests
        this.clearApiToken();

        // get our Hub API token
        this.http
            .get(
                `${environment.api}/api/auth/fetchclaimtoken`,
                {
                    headers: {
                        Authorization: `Bearer ${aadToken}`
                    }
                }
            )
            .pipe(take(1))
            .subscribe({
                next: (token: string) => {
                    if (token) {
                        this.errorResponse = undefined;
                        this.setApiToken(token);
                        resolvePromise(token);
                    } else {
                        this.errorResponse = new HttpErrorResponse({
                            status: 200,
                            statusText: 'Empty Response',
                            error: {
                                message: 'Fetchclaimtoken returned empty response.'
                            }
                        });
                        console.error(this.errorResponse);
                        rejectPromise(this.errorResponse);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.errorResponse = error;
                    console.error(error);
                    rejectPromise(error);
                },
                complete: () => {
                    this.fetchAPIRequestInProgress = false;
                }
            });

    };

}
