import {Component, Input} from '@angular/core';
import {IWorkingDrawingMappedItem} from '@app/logic/working-drawings';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
// eslint-disable-next-line max-len
import {
    RequestWorkingDrawingEditItemDialogComponentComponent
} from '../../request-working-drawing-edit-item-dialog-component/request-working-drawing-edit-item-dialog-component.component';

@Component({
    selector: 'cb-request-working-drawing-step-one',
    templateUrl: './request-working-drawing-step-one.component.html',
    styleUrls: ['./request-working-drawing-step-one.component.scss']
})
export class RequestWorkingDrawingStepOneComponent {

    @Input() public mappedItem: IWorkingDrawingMappedItem;
    constructor(
        public readonly cbDialog: CbDialogService
    ) { }
    public addItems(): void {

        let nextItemNumber = 1;
        if (this.mappedItem.items) {
            const itemNumbers = this.mappedItem.items.filter(o => o.isDeleted === false).map(o => o.itemNumber);
            nextItemNumber = Math.max(...itemNumbers, 0) + 1;
        }
        this.cbDialog
            .open(RequestWorkingDrawingEditItemDialogComponentComponent, {
                data: { mappedItem: this.mappedItem, item: { itemNumber: nextItemNumber, isDeleted: false }, mode: FormMode.Add },
            });
    }

    public hasItems(): boolean {
        return this.mappedItem?.items?.length > 0;
    }

}
