import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[cbStepWrapperMaxWidth]'
})
export class StepWrapperMaxWidthDirective implements AfterViewChecked {
    private prevWidth = 0;
    private _cbStepper: HTMLElement | null;
    private get cbStepper(): HTMLElement {
        return this._cbStepper ?? this.ref.nativeElement.parentElement?.parentElement?.parentElement;
    }

    constructor(private readonly ref: ElementRef<HTMLElement>) {
        this._cbStepper = this.ref.nativeElement.parentElement?.parentElement?.parentElement;
    }

    public ngAfterViewChecked(): void {
        this.setMaxWidth();
    }

    @HostListener('window:resize')
    public onResize(): void {
        this.setMaxWidth();
    }

    private setMaxWidth(): void {
        const cbStepRectLeft = this.ref.nativeElement?.parentElement?.parentElement?.getBoundingClientRect()?.left;
        const width = this.cbStepper?.clientWidth - (cbStepRectLeft ?? 0);
        if (width === this.prevWidth) {
            return;
        }
        this.prevWidth = width;
        this.ref.nativeElement.style.maxWidth = width < 1 ? 'none' : `${Math.floor(width ?? 0)}px`;
        this.ref.nativeElement.style.width = this.ref.nativeElement.style.maxWidth;
    }
}
