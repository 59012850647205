import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ILotMappedItem } from '@app/logic/lots';
import { IWorkingDrawingMappedItem, WorkingDrawingsLogicService } from '@app/logic/working-drawings';

@Component({
    selector: 'cb-working-drawing-view',
    templateUrl: './working-drawing-view.component.html',
    styleUrls: ['./working-drawing-view.component.scss']
})
export class WorkingDrawingViewComponent {

    @Output() public readonly goBack = new EventEmitter();

    @Input() public mappedItem: IWorkingDrawingMappedItem;
    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public lotMappedItemChange = new EventEmitter();

    constructor(
        public readonly cdRef: ChangeDetectorRef,
        private readonly logicService: WorkingDrawingsLogicService
    ) { }

    public workingDrawingMappedItemChanged(): void {
        this.mappedItem.$reload().subOnce(workingDrawingDto => {
            this.mappedItem = this.logicService.$createMappedItem(workingDrawingDto);
            this.cdRef.detectChanges();
        });
    }

    public lotMappedItemChanged(): void {
        this.lotMappedItemChange.emit();
    }
}
