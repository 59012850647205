<mat-card class="start-center flex-row layoutGap-10">
    <button class="cb-margin-right"
            mat-button
            (click)="backToPreConsent()">
        <mat-icon>arrow_back</mat-icon>
        &nbsp;Back to Pre-Consent
    </button>
    <button class="cb-margin-right"
            color="primary"
            mat-raised-button
            [disabled]="isReviewDisabled"
            (click)="review()">
        <mat-icon>person</mat-icon>
        &nbsp;Review
    </button>
    <button mat-raised-button
            color="primary"
            (click)="managePreConsentPlan()"
            [disabled]="isManageDisabled()">
        Manage
    </button>
    <button mat-raised-button
            color="primary"
            [matMenuTriggerFor]="statusMenu"
            [disabled]="isNoLongerActive()"
            *ngIf="mappedItem?.preConsentPlanStatus?.id != PRECONSENT_PLAN_STATUS_ENUM.Rejected">
        <mat-icon>arrow_drop_down</mat-icon>
        Pre Consent Status
    </button>
    <button mat-raised-button
            color="primary"
            (click)="resubmit()"
            [disabled]="isResubmitDisabled"
            *ngIf="mappedItem?.preConsentPlanStatus?.id == PRECONSENT_PLAN_STATUS_ENUM.Rejected">
        <mat-icon>keyboard_return</mat-icon>
        Resubmit
    </button>
    <mat-menu #statusMenu="matMenu">
        <button mat-menu-item
                (click)="setStatus(PRECONSENT_PLAN_STATUS_ENUM.InQueue)"
                [disabled]="isInQueueDisabled">
            <mat-icon color="accent">timelapse</mat-icon>
            In Queue
        </button>
        <button mat-menu-item
                (click)="setStatus(PRECONSENT_PLAN_STATUS_ENUM.InProgress)"
                [disabled]="isInProgressDisabled">
            <mat-icon color="warn">check</mat-icon>
            In Progress
        </button>
        <button mat-menu-item
                (click)="setStatus(PRECONSENT_PLAN_STATUS_ENUM.OnHold)"
                [disabled]="isOnHoldDisabled">
            <mat-icon color="primary">pause</mat-icon>
            On Hold
        </button>
        <button mat-menu-item
                (click)="finalisePreConsentPlan()"
                [disabled]="isFinaliseDisabled">
            <mat-icon color="primary">send</mat-icon>
            Finalise
        </button>
        <button mat-menu-item
                (click)="rejectPreConsentPlan()"
                [disabled]="isRejectDisabled"
                [hidden]="!canRejectPreConsentPlans">
            <mat-icon color="accent">keyboard_return</mat-icon>
            Reject
        </button>
        <button mat-menu-item
                (click)="cancelPreConsentPlan()"
                [disabled]="isCancelDisabled">
            <mat-icon color="primary">cancel</mat-icon>
            Cancel
        </button>
    </mat-menu>
</mat-card>
