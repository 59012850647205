import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {IWorkingDrawingMappedItem, WorkingDrawingsLogicService} from '@app/logic/working-drawings';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {WORKING_DRAWING_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {CompleteWorkingDrawingDialogComponent} from '../complete-working-drawing-dialog/complete-working-drawing-dialog.component';

@Component({
    selector: 'cb-working-drawing-card',
    templateUrl: './working-drawing-card.component.html',
    styleUrls: ['./working-drawing-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkingDrawingCardComponent {

    public WORKING_DRAWING_STATUS_ENUM = WORKING_DRAWING_STATUS_ENUM;

    @Input() public mappedItem: IWorkingDrawingMappedItem;
    @Output() public readonly viewClick = new EventEmitter<IWorkingDrawingMappedItem>();

    constructor(
        public readonly workingDrawingsLogicService: WorkingDrawingsLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly cdref: ChangeDetectorRef
    ) { }

    public getStatusIconIcon(): string {
        switch (this.mappedItem.statusId) {
            case WORKING_DRAWING_STATUS_ENUM.Accepted:
            case WORKING_DRAWING_STATUS_ENUM.Completed:
                return 'check_circle';
            case WORKING_DRAWING_STATUS_ENUM.Pending:
                return 'timelapse';
            case WORKING_DRAWING_STATUS_ENUM.InQueue:
            case WORKING_DRAWING_STATUS_ENUM.InQueueForRework:
            case WORKING_DRAWING_STATUS_ENUM.InProgress:
            case WORKING_DRAWING_STATUS_ENUM.WaitingForReview:
                return 'assignment';
            case WORKING_DRAWING_STATUS_ENUM.OnHold:
                return 'pause_circle_filled';
            case WORKING_DRAWING_STATUS_ENUM.Declined:
                return 'indeterminate_check_box';
            case WORKING_DRAWING_STATUS_ENUM.Cancelled:
                return 'indeterminate_check_box';
            default:
                return 'assignment';
        }
    }

    public getStatusIconClass(): string {
        switch (this.mappedItem.statusId) {
            case WORKING_DRAWING_STATUS_ENUM.Accepted:
            case WORKING_DRAWING_STATUS_ENUM.Completed:
                return 'mat-primary';
            case WORKING_DRAWING_STATUS_ENUM.Pending:
                return 'mat-accent';
            case WORKING_DRAWING_STATUS_ENUM.InQueue:
            case WORKING_DRAWING_STATUS_ENUM.InQueueForRework:
            case WORKING_DRAWING_STATUS_ENUM.InProgress:
            case WORKING_DRAWING_STATUS_ENUM.WaitingForReview:
                return 'mat-accent';
            case WORKING_DRAWING_STATUS_ENUM.OnHold:
                return 'mat-primary';
            case WORKING_DRAWING_STATUS_ENUM.Declined:
                return 'mat-warn';
            case WORKING_DRAWING_STATUS_ENUM.Cancelled:
                return 'mat-warn';
            default:
                return 'assignment';
        }
    }

    public getStatusIconTitle(): string {
        switch (this.mappedItem.statusId) {
            case WORKING_DRAWING_STATUS_ENUM.Accepted:
                return 'Accepted';
            case WORKING_DRAWING_STATUS_ENUM.Completed:
                return 'Completed';
            case WORKING_DRAWING_STATUS_ENUM.Pending:
                return 'Pending';
            case WORKING_DRAWING_STATUS_ENUM.InQueue:
                return 'In Queue';
            case WORKING_DRAWING_STATUS_ENUM.InQueueForRework:
                return 'In Queue - Failed Review';
            case WORKING_DRAWING_STATUS_ENUM.InProgress:
                return 'In Progress';
            case WORKING_DRAWING_STATUS_ENUM.WaitingForReview:
                return 'Waiting for Review';
            case WORKING_DRAWING_STATUS_ENUM.OnHold:
                return 'On Hold';
            case WORKING_DRAWING_STATUS_ENUM.Declined:
                return 'Declined';
            case WORKING_DRAWING_STATUS_ENUM.Cancelled:
                return 'Cancelled';
            case WORKING_DRAWING_STATUS_ENUM.Rejected:
                return 'Branch - To Resolve';
            default:
                return 'assignment';
        }
    }

    public get canCompleteWorkingDrawing(): boolean {
        return this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.WaitingForReview;
    }

    public completeWorkingDrawing(): void {
        this.cbDialog.open(CompleteWorkingDrawingDialogComponent, {
            data: {
                mappedItem: this.mappedItem.$clone(),
            }
        }).afterClosed()
            .subOnce(() => {
                this.mappedItem.$reload().subOnce(() => {
                    this.cdref.detectChanges();
                });
            });
    }

    public viewWorkingDrawing(): void {
        this.mappedItem.$reload().subOnce((result) => {
            const mappedItem = this.workingDrawingsLogicService.$createMappedItem(result);
            this.viewClick.emit(mappedItem);
        });
    }

}
