import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import { IProductCategoryDto, ICategoryAttributeDto, IAttributeOptionDto } from './interfaces/i.product-category.dto';
import { IProductCategoriesLogicService } from './interfaces/i.product-categories.logic.service';
import { IProductCategoryMappedItem } from './interfaces/i.product-category.mapped';
import { Observable } from 'rxjs';
import { DtoProp } from '../base/dto-prop.decorator';


export class ProductCategoryMappedItem
    extends BaseMappedItem<IProductCategoryDto, IProductCategoryMappedItem, IProductCategoriesLogicService>
    implements IProductCategoryMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public label: string;
    @DtoProp public parentId: number;
    @DtoProp public attributes: ICategoryAttributeDto[];
    @DtoProp public isDeleted: boolean;
    @DtoProp public isActive: boolean;

    constructor(
        sourceData: IProductCategoryDto,
        logicService: IProductCategoriesLogicService
    ) {
        super(sourceData, logicService, ProductCategoryMappedItem);
    }

    public newAttribute(): ICategoryAttributeDto {
        return {
            id: 0,
            isLocked: false,
            name: '',
            offeringCategoryId: this.id,
            options: []
        };
    }

    public newAttributeOption(): IAttributeOptionDto {
        return {
            id: 0,
            name: ''
        };
    }

    public saveAttribute(attribute: ICategoryAttributeDto): Observable<IProductCategoryDto> {
        return this.$logicService.addAttribute(this.id, attribute);
    }

    public updateAttribute(attribute: ICategoryAttributeDto): Observable<IProductCategoryDto> {
        return this.$logicService.updateAttribute(this.id, attribute.id, attribute);
    }

    protected $preSave(toSave: IProductCategoryDto): void {
        toSave.isDeleted = !toSave.isActive;
    }

    protected $postLoad(): void {
        this.isActive = !this.isDeleted;
    }
}
