import * as _ from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cbSortByPipe'
})
export class CbSortByPipe implements PipeTransform {

    public transform(value: any[], column: string, order = ''): any[] {
        if (!value || !column || column === '' || order === '') {
            return value; // no array
        }
        if (value.length <= 1) {
            return value; // array with only one item
        }
        return _.orderBy(value, [column], [order]);
    }

}
