<cb-document *ngIf="mappedItem && lotBuildPermissions?.$resolved"
             [edit]="true"
             [entity]="mappedItem"
             [permissions]="lotBuildPermissions?.getDocumentPermissions()"
             [prefixColumns]="extraCol"
             [systemArea]="systemArea"
             [hideDeletedToggle]="false"
             [includeDeleted]="true"
             [dtoDefinition]="dtoDefinition"
             [enableCheckbox]="true"
             [allowReviewAtUpload]="true">
</cb-document>
