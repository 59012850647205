<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">language</mat-icon>
            <b>Website Publishing</b>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="cb-checkbox-list flex-col">
        <cb-checkbox label="Withhold from website"
                     name="isWithheldFromWebsite"
                     [(ngModel)]="lotMappedItem.isWithheldFromWebsite"></cb-checkbox>
        <cb-checkbox label="Kiwibuild"
                     name="isKiwibuild"
                     [(ngModel)]="lotMappedItem.isKiwibuild"></cb-checkbox>
        <cb-checkbox label="Grow home"
                     name="isGrowhome"
                     [(ngModel)]="lotMappedItem.isGrowhome"></cb-checkbox>
        <cb-checkbox label="Kitset"
                     name="isKitset"
                     [(ngModel)]="lotMappedItem.isKitset"></cb-checkbox>
        <cb-checkbox label="Transportable"
                     name="isTransportable"
                     [(ngModel)]="lotMappedItem.isTransportable"></cb-checkbox>
    </div>
</mat-expansion-panel>
