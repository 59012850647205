import {Inject, Injectable} from '@angular/core';
import {SearchService} from './base.search.service';
import {Observable} from 'rxjs';
import {takeOne} from 'cb-hub-lib';
import {ISearchResult} from '@app/shared/components/search/i.search';
import {LotSearchParams, LotsLogicService} from '@app/logic/lots/lots.logic.service';
import {ILotDocumentDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class LotSearchService extends SearchService {

    public availableOnly = false;

    constructor(
        @Inject(LotsLogicService) public readonly lotLogic: LotsLogicService,
    ) {
        super();
    }

    public getResults(query: string, currentPage: number): Observable<ILotDocumentDto[]> {
        return new Observable<ILotDocumentDto[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;

                const resultsObservable = this.search({
                    query,
                    currentPage,
                    ...this.extraSearchParams
                }, false);

                if (!resultsObservable) {
                    return;
                }

                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<ILotDocumentDto>(x);
                        subscriber.next(this.searchResults$.value as ILotDocumentDto[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as ILotDocumentDto[]);
                subscriber.complete();
            }
        });
    }

    public readonly doSearch = (query: string, page: number): Observable<ILotDocumentDto[]> => {
        this.searchResultsLoaded = false;
        return this.getResults(query, page).pipe(takeOne());
    };

    private readonly search = (params: Partial<LotSearchParams>, ignoreEmptyQueries?: boolean): Observable<ISearchResult<ILotDocumentDto>> => {
        return this.availableOnly ? this.lotLogic.searchAvailable(params, ignoreEmptyQueries) : this.lotLogic.search(params, ignoreEmptyQueries);
    };
}
