import { Component } from '@angular/core';
import { IIdAndLabelDto } from '@app/shared/interfaces/i.idandlabel.dto';
import { SystemAreaEnumId, SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { DashboardBaseWidgetDirective, IUrlAndParam } from '../dashboard-base-widget/dashboard-base-widget.directive';

interface IDocumentRequiringAction {
    document: IIdAndLabelDto<number>;
    documentType: IIdAndLabelDto<number>;
    systemArea: SystemAreaEnumId;
    systemAreaEntityId: number;
    viewingAreaEntityId: number;
    info: string;
    statusId: number;
    statusLabel: string;
}

@Component({
    selector: 'cb-dashboard-widget-document-list',
    templateUrl: './dashboard-widget-document-list.component.html',
    styleUrls: ['./dashboard-widget-document-list.component.scss']
})
export class DashboardWidgetDocumentListComponent extends DashboardBaseWidgetDirective<IDocumentRequiringAction[]> {

    public documentEntityViewUrl(item: IDocumentRequiringAction): IUrlAndParam {
        switch (item.systemArea) {
            case SYSTEM_AREA_ENUM.ClientSale:
                return { url: `/clientsales/view/${item.viewingAreaEntityId}/documents` };
            case SYSTEM_AREA_ENUM.Lot:
                return { url: `/lots/${item.viewingAreaEntityId}/documents` };
            case SYSTEM_AREA_ENUM.Lead:
                return { url: `/leads/${item.viewingAreaEntityId}/documents` };
            case SYSTEM_AREA_ENUM.Project:
                return { url: `/projects/${item.viewingAreaEntityId}/documents` };
            case SYSTEM_AREA_ENUM.ProjectReleaseStage:
                return { url: `/projects/${item.viewingAreaEntityId}/releases/${item.systemAreaEntityId}/documents` };
            case SYSTEM_AREA_ENUM.BusinessAccount:
                return { url: `/business-accounts/edit/${item.viewingAreaEntityId}/documents` };
            case SYSTEM_AREA_ENUM.HelpPage:
                return { url: '/help/search' };
            case SYSTEM_AREA_ENUM.Quote:
                return { url: `/lots/${item.viewingAreaEntityId}/spec/quotes` };
            case SYSTEM_AREA_ENUM.ChangeRecord:
                return { url: `/lots/${item.viewingAreaEntityId}/changes/changes` };
            case SYSTEM_AREA_ENUM.Variation:
                return { url: `/lots/${item.viewingAreaEntityId}/changes/variations` };
            case SYSTEM_AREA_ENUM.DesignScheme:
                return { url: `/lots/${item.viewingAreaEntityId}/design/schemes` };
            case SYSTEM_AREA_ENUM.PricingRevision:
                return { url: `/lots/${item.viewingAreaEntityId}/costs/price-revisions` };
            case SYSTEM_AREA_ENUM.BuildingConsent:
            case SYSTEM_AREA_ENUM.ResourceConsent:
                return { url: `/lots/${item.viewingAreaEntityId}/design/consent` };
            case SYSTEM_AREA_ENUM.CouncilRfi:
                return { url: `/lots/${item.viewingAreaEntityId}/design/council-rfis` };
            case SYSTEM_AREA_ENUM.WorkingDrawing:
                return { url: `/lots/${item.viewingAreaEntityId}/design/working-drawings` };
            case SYSTEM_AREA_ENUM.PreConsent:
                return { url: `/lots/${item.viewingAreaEntityId}/design/pre-consent` };
            case SYSTEM_AREA_ENUM.SSR:
                return { url: `/lots/${item.viewingAreaEntityId}/build/programme` };
            case SYSTEM_AREA_ENUM.BuildProgramme:
            case SYSTEM_AREA_ENUM.BuildProgrammeActivity:
                return { url: `/lots/${item.viewingAreaEntityId}/build/documents` };
            default:
                return { url: '#' };
        }
    }
}
