import { Observable } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DesignConceptsLogicService, IDesignConceptMappedItem } from '@app/logic/design-concepts';
import { takeOne } from 'cb-hub-lib';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IDesignConceptsLogicService } from '@app/logic/design-concepts/interfaces/i.design-concepts-logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IDesignConceptDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

interface IData {
    clientSale: IClientSaleMappedItem;
}

@Component({
    selector: 'cb-change-design-concept-dialog',
    templateUrl: './change-design-concept-dialog.component.html',
    styleUrls: ['./change-design-concept-dialog.component.scss'],
    providers: [DesignConceptsLogicService]
})
export class ChangeDesignConceptDialogComponent extends BaseDialogFormViewDirective<IDesignConceptDto, IDesignConceptMappedItem, IDesignConceptsLogicService> implements OnInit {

    public designConcepts: Observable<IDesignConceptMappedItem[]>;

    public selectedConcept: IDesignConceptMappedItem;

    constructor(
        public readonly dialogRef: MatDialogRef<ChangeDesignConceptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        private readonly designConceptsLogic: DesignConceptsLogicService,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.designConcepts = this.designConceptsLogic.getApplicableByLot(this.data.clientSale.lot.id)
            .pipe(takeOne());
    }

    public save(): void {
        this.data.clientSale
            .changeDesignConcept(this.selectedConcept.id)
            .subOnce({
                next: this.handleNext,
                error: this.handleError
            });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
