<div *ngIf="render"
     class="standardTable">
    <table>
        <thead>
            <tr>
                <td [style.width.px]="300">Status</td>
                <td [style.width.px]="100">Code</td>
                <td [style.width.px]="300">Name</td>
                <td *ngIf="lotBuildProgrammeEvents.hasSummaries"
                    [style.width.px]="50">Lot Number</td>
                <td [style.width.px]="300">Supplier</td>
                <td [style.width.px]="150">Start Date</td>
                <td [style.width.px]="150">End Date</td>
                <td [style.width.px]="50">Duration</td>
                <td [style.width.px]="50">PO Total</td>
                <td></td>
            </tr>
        </thead>
        <tbody [dragula]="dragulaGroup"
               [id]="'buildStageId_' + buildProgrammeStage.buildStageId">
            <ng-container *ngIf="this.buildProgrammeStage?.buildStageId > 0 && lotBuildProgrammeEvents.hasSummaries; else stageActivities">
                <cb-lot-build-programme-activity-row class="table-row"
                                                     [id]="'buildProgrammeActivity_' + activity.id"
                                                     [ngClass]="activityRowClasses(activity, highlightedActivityId)"
                                                     *ngFor="let activity of getBlockActivities()"
                                                     [activity]="activity"
                                                     [lotDto]="lotDto"
                                                     [highlightedActivityId]="lotDto"
                                                     [displayUnitNumber]="true"
                                                     [downloadOnly]="activity.isActivityFromUnit">
                </cb-lot-build-programme-activity-row>
                <ng-container *ngFor="let summary of summaries">
                    <ng-container *ngIf="getSummaryActivties(summary.buildActivityId).length > 0">
                        <tr [id]="'buildActivitySummary_{{this.buildProgrammeStage?.buildStageId}}_{{summary.buildActivityId}}'">
                            <td class="actions">
                            </td>
                            <td>
                                <strong>{{summary?.code | cbDasher}}</strong>
                            </td>
                            <td>
                                <strong>{{summary?.name | cbDasher}}</strong>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <strong>{{summary?.startDate | date}}</strong>
                            </td>
                            <td>
                                <strong>{{summary?.endDate | date}}</strong>
                            </td>
                            <td></td>
                            <td class="actions">
                                <button mat-icon-button
                                        (click)="summaryExpanded[summary.buildActivityId] = !summaryExpanded[summary.buildActivityId]">
                                    <mat-icon>{{ summaryExpanded[summary.buildActivityId] ? 'keyboard_arrow_up' :
                                        'keyboard_arrow_down'}}</mat-icon>
                                </button>
                            </td>
                        </tr>
                        <ng-container *ngIf="summaryExpanded[summary.buildActivityId]">
                            <cb-lot-build-programme-activity-row class="table-row"
                                                                 [id]="'buildProgrammeActivity_' + activity.id"
                                                                 [ngClass]="activityRowClasses(activity, highlightedActivityId)"
                                                                 *ngFor="let activity of getSummaryActivties(summary.buildActivityId)"
                                                                 [activity]="activity"
                                                                 [lotDto]="lotDto"
                                                                 [highlightedActivityId]="lotDto"
                                                                 [displayUnitNumber]="true"
                                                                 [downloadOnly]="activity.isActivityFromUnit">
                            </cb-lot-build-programme-activity-row>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #stageActivities>
                <cb-lot-build-programme-activity-row class="table-row"
                                                     [id]="'buildProgrammeActivity_' + activity.id"
                                                     [ngClass]="activityRowClasses(activity, highlightedActivityId)"
                                                     *ngFor="let activity of activities"
                                                     [activity]="activity"
                                                     [lotDto]="lotDto"
                                                     [highlightedActivityId]="lotDto">
                </cb-lot-build-programme-activity-row>
            </ng-template>
            <tr *ngIf="!loaded"
                class="dragula-ignore">
                <td [attr.colspan]="lotBuildProgrammeEvents.hasSummaries ? '9' : '8'">
                    <cb-loading-spinner></cb-loading-spinner>
                </td>
            </tr>
            <tr *ngIf="loaded && (!activities || activities?.length === 0)"
                class="dragula-ignore">
                <td [attr.colspan]="lotBuildProgrammeEvents.hasSummaries ? '9' : '8'">
                    <cb-info-message [message]="'There are no Activities to display'"
                                     [icon]="'info_circle'"></cb-info-message>
                </td>
            </tr>
        </tbody>
    </table>
</div>
