<mat-card class="flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon">
            <mat-icon class="cb-accent"
                      title="Complete">
                info_circle
            </mat-icon>
        </div>
        <mat-card-title>
            Follow Up
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-col flex">
        <cb-display-value-new label="Building Consultant">
            {{mappedItem?.buildingConsultantName}}
        </cb-display-value-new>
        <cb-display-value-new label="Lead Status">
            {{mappedItem?.statusLabel}}
        </cb-display-value-new>
        <cb-display-value-new label="Next Follow Up"
                              *ngIf="canViewFollowUp()">
            {{mappedItem?.callbackDate | date}} by {{preferredContactMethod}}
            <a [href]="preferredContactMethodHref">
                {{mappedItem?.mainContact?.preferredContactDetails | cbDasher}}
            </a>
        </cb-display-value-new>
    </mat-card-content>
    <div position="relative"
         *ngIf="canEdit && !this.mappedItem.isQualified()">
        <mat-divider></mat-divider>
    </div>
    <mat-card-actions class="end-center flex-row"
                      *ngIf="canEdit && !this.mappedItem.isQualified()">
        <button mat-stroked-button
                color="accent"
                *ngIf="canEdit"
                (cbClick)="editLeadDetails($event)">
            <mat-icon>edit</mat-icon>
            &nbsp;Edit
        </button>
    </mat-card-actions>
</mat-card>
