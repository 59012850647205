<div class="cb-margin cb-padding flex-col">
    <form class="flex-col flex"
          #productUsageForm="ngForm">
        <h4 class="mat-title">Product Usage</h4>
        <p class="mat-body-1">Generates an Excel workbook that shows the usage of the product.</p>
        <div class="flex-col flex">
            <cb-product-autocomplete-scroller class="flex"
                                              #autocompleteControl
                                              label="Product"
                                              name="altProduct"
                                              [displayCategories]="true"
                                              [requireMatch]="false"
                                              [(ngModel)]="product"
                                              (optionSelected)="productSelected($event)"
                                              [hideCompositeItems]="true">
            </cb-product-autocomplete-scroller>
            <cb-business-accounts-input-find-cards label="Business Account"
                                                   [(ngModel)]="businessAccount"
                                                   name="businessAccount"
                                                   [text]="businessAccount?.tradingName"
                                                   [removeable]="true"
                                                   [searchParams]="productUsageBusinessAccountSearchParams">
            </cb-business-accounts-input-find-cards>
            <cb-datepicker name="createdDateFrom"
                           [(ngModel)]="createdDateFrom"
                           label="Created Date From"
                           pickerType="calendar">
            </cb-datepicker>
            <cb-datepicker name="createdDateTo"
                           [(ngModel)]="createdDateTo"
                           label="Created Date To"
                           pickerType="calendar">
            </cb-datepicker>
            <cb-datepicker name="activityStartDateFrom"
                           [(ngModel)]="activityStartDateFrom"
                           label="Activity Start Date From"
                           pickerType="calendar">
            </cb-datepicker>
            <cb-datepicker name="activityEndDateTo"
                           [(ngModel)]="activityEndDateTo"
                           label="Activity End Date To"
                           pickerType="calendar">
            </cb-datepicker>
            <cb-input type="text"
                      label="Job #"
                      name="jobNo"
                      [(ngModel)]="jobNo"></cb-input>
            <cb-trade-type-autocomplete-scroller class="cb-margin-top flex"
                                                 label="Select Trade Type"
                                                 name="tradeType"
                                                 [ignoreDirty]="false"
                                                 [(ngModel)]="selectedTradeTypeDto"
                                                 [isParentOnly]="true">
            </cb-trade-type-autocomplete-scroller>
            <button mat-raised-button
                    color="primary"
                    (cbClick)="exportProductUsage()">
                Export Product Usage
            </button>
        </div>
    </form>
</div>
