import { BaseMappedItem } from '../base/base-mapped-item';
import { IAddressDto } from './../../shared/interfaces/i.address.dto';
import { IClientAccountDto } from './interfaces/i.client-account.dto';
import { IClientAccountLogicService } from './interfaces/i.client-account.logic.service';
import { IClientAccountMappedItem } from './interfaces/i.client-account.mapped';
import { cleanObjectDeep } from 'cb-hub-lib';
import { isEmpty } from 'lodash';
import { DtoProp } from '../base/dto-prop.decorator';
import { ISkinnyUserDto } from '../users/interfaces/i.skinny-user.dto';

export class ClientAccountMappedItem
    extends BaseMappedItem<IClientAccountDto, IClientAccountMappedItem, IClientAccountLogicService>
    implements IClientAccountMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public abmCode: string;
    @DtoProp public accountType: number;
    @DtoProp public city: string;
    @DtoProp public contactIds: string[];
    @DtoProp public irdNumber: string;
    @DtoProp public isGstRegistered: boolean;
    @DtoProp public legalEntityName: string;
    @DtoProp public legalName: string;
    @DtoProp public mainContact: ISkinnyUserDto;
    @DtoProp public mainContactId: string;
    @DtoProp public name: string;
    @DtoProp public physicalAddress: IAddressDto;
    @DtoProp public postalAddress: IAddressDto;
    @DtoProp public solicito: string;
    @DtoProp public solicitor: string;
    @DtoProp public solicitorEmail: string;
    @DtoProp public status: number;
    @DtoProp public statusDisplay: string;
    @DtoProp public tradingName: string;
    @DtoProp public createdDate: string | Date;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string | Date;
    @DtoProp public updatedByName: string;

    public get noteEntityId(): number {
        return this.id;
    }

    public get noteEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    constructor(
        sourceData: IClientAccountDto,
        logicService: IClientAccountLogicService
    ) {
        super(sourceData, logicService, ClientAccountMappedItem,
            // default data
            {
                physicalAddress: {} as IAddressDto,
                postalAddress: {} as IAddressDto,
            }
        );
    }

    protected $preSave(toSave: IClientAccountDto): void {
        cleanObjectDeep(toSave.postalAddress);
        cleanObjectDeep(toSave.physicalAddress);

        if (isEmpty(toSave.postalAddress)) {
            delete toSave.postalAddress;
        }

        if (isEmpty(toSave.physicalAddress)) {
            delete toSave.physicalAddress;
        }
    }

}
