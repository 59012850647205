import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
    templateUrl: './blocking-dialog.component.html',
    styleUrls: ['./blocking-dialog.component.scss']
})
export class BlockingDialogComponent {

    constructor(
        public readonly dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { message: string },
    ) { }

}
