import {BasePermissions, IBaseSystemAreaPermissions, IDocumentPermissions, IhaveNotePermissions, INotePermissions} from './base.permissions';
import {CurrentUserService} from '../authentication/current.user';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LotCostPermissionEnum} from '@app/shared/enums/permissions';


export interface ILotCostPermissions extends IBaseSystemAreaPermissions, IhaveNotePermissions {
    canViewCostSummary(): boolean;

    canViewPriceRevisions(): boolean;

    canViewTakeoffs(): boolean;

    canViewConfirmedQuantities(): boolean;

    canViewActualCosts(): boolean;

    canViewPricingRevision(): boolean;

    canEditPricingRevision(): boolean;
    canChangePricingRevisionStatus(): boolean;
    canCreateTakeoff(): boolean;
    canManageTakeoff(): boolean;
}


@Injectable()
export class LotCostPermissions extends BasePermissions<LotCostPermissionEnum> implements ILotCostPermissions {
    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lotcost', LotCostPermissionEnum, currentUser, http, router);
    }

    public canViewCostSummary(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanViewCostSummary);
    }

    public canViewPriceRevisions(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanViewPriceRevisions);
    }

    public canViewTakeoffs(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanViewTakeoffs);
    }

    public canViewConfirmedQuantities(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanViewConfirmedQuantities);
    }

    public canViewActualCosts(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanViewActualCosts);
    }

    public canViewPricingRevision(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanViewPricingRevision);
    }

    public canEditPricingRevision(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanEditPricingRevision);
    }

    public canChangePricingRevisionStatus(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanChangePricingRevisionStatus);
    }

    public canCreateTakeoff(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanCreateTakeoff);
    }

    public canManageTakeoff(): boolean {
        return this.permissionObject(LotCostPermissionEnum.LotCanManageTakoff);
    }

    public getNotePermissions(): INotePermissions {
        throw new Error('Method not implemented');
    }

    public canView(): boolean {
        throw new Error('Method not implemented');
    }

    public canEdit(): boolean {
        return this.canEditPricingRevision();
    }

    public canCreate(): boolean {
        return this.canEditPricingRevision();
    }


    public canViewEditPricingRevisionMargins(): boolean {
        return this.permissionObject(LotCostPermissionEnum.CanViewEditPricingRevisionMargins);
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => {
                return true;
            },
            canEditDocuments: () => {
                return true;
            },
            canReviewDocuments: () => {
                return true;
            },
            canUploadDocument: () => {
                return true;
            },
            canViewDocuments: () => {
                return true;
            }
        };
    }
}
