<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <mat-card-title>{{dto.name}}</mat-card-title>
        <span class="flex"></span>
        <div class="help-cursor flex-col center-right">
            <mat-icon *ngIf="dto.status === CONTACT_STATUS_ENUM.Active && !dto.incomplete"
                      class="mat-primary"
                      title="Active">check_circle</mat-icon>
            <mat-icon *ngIf="dto.status !== CONTACT_STATUS_ENUM.Active"
                      title="Inactive">radio_button_unchecked
            </mat-icon>
            <mat-icon *ngIf="dto.incomplete && dto.status === CONTACT_STATUS_ENUM.Active"
                      class="mat-warn"
                      title="Incomplete">warning</mat-icon>
        </div>
    </mat-card-header>
    <mat-card-content class="flex">
        <ng-container>
            <div class="flex-col">
                <cb-display-value-new label="Address"
                                      [value]="dto.address"></cb-display-value-new>
                <cb-display-value-new label="Email"
                                      [value]="dto.email"></cb-display-value-new>
                <cb-display-value-new *ngIf="dto.preferred === 'Mobile Phone'"
                                      label="Mobile Phone"
                                      [value]="dto.preferredContactDetails">
                </cb-display-value-new>
            </div>
        </ng-container>
    </mat-card-content>
    <mat-card-actions class="with-divider end-center flex-row">
        <span class="flex"></span>
        <button mat-stroked-button
                color="accent"
                (click)="selectionChosen(dto)">
            Add Contact
        </button>
    </mat-card-actions>
</mat-card>
