<cb-dialog>
    <form class="flex-col flex"
          #teamForm="ngForm">
        <h1 class="mat-title">
            {{mappedItem.id > 0 ? 'Edit' : 'New'}} Team
        </h1>
        <cb-input type="text"
                  name="name"
                  label="Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name"></cb-input>
        <cb-select label="Key"
                   name="key"
                   [required]="true"
                   [(ngModel)]="mappedItem.key"
                   [options]="teamKeys"
                   [disabled]="isSystemTag"></cb-select>
        <cb-checkbox name="isSystemTag"
                     [disabled]="isSystemTag"
                     label="Is System Tag"
                     [(ngModel)]="mappedItem.isSystemTag"></cb-checkbox>
        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
        <footer class="cb-margin-top-2x end-center flex-row">
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (click)="save()"
                    [disabled]="!teamForm.dirty || !teamForm.valid">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel()">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
