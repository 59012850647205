import { Component, Input } from '@angular/core';
import { DesignConceptsLogicService } from '@app/logic/design-concepts';
import { LotConsentLogicService } from '@app/logic/lot-consents/lot-consent-logic.service';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { ILotProgressMilestoneDto } from '@app/logic/lots/interfaces/i.lot-progress-milestones.dto';
import {
    DESIGN_CONCEPT_STATUS_ENUM,
    LOT_MILESTONE_PROGRESS_ENUM,
    MARKET_STATUS_ENUM,
    RESOURCE_CONSENT_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-summary-alerts',
    templateUrl: './lot-summary-alerts.component.html',
    styleUrls: ['./lot-summary-alerts.component.scss']
})
export class LotSummaryAlertsComponent {

    public MARKET_STATUS_ENUM = MARKET_STATUS_ENUM;

    private _lotMappedItem: ILotMappedItem;
    @Input() public set lotMappedItem(lotMappedItem: ILotMappedItem) {
        this._lotMappedItem = lotMappedItem;
        this.initialiseData();
    }

    public get lotMappedItem(): ILotMappedItem {
        return this._lotMappedItem;
    }

    @Input() public set blockLevelProgress(blockLevelProgress: ILotProgressMilestoneDto) {
        this._blockLevelProgress = blockLevelProgress;
    }

    public get blockLevelProgress(): ILotProgressMilestoneDto
    {
        return this._blockLevelProgress;
    }

    public _blockLevelProgress: ILotProgressMilestoneDto;

    public hasPendingResourceConsent: boolean;
    public hasIncompleteLotSpecItemAttributes: boolean;
    public hasIncompleteLotSpecItems: boolean;
    public hasPendingConcept: boolean;

    constructor(
        private readonly lotsLogicService: LotsLogicService,
        private readonly lotConsentLogicService: LotConsentLogicService,
        private readonly designConceptsLogicService: DesignConceptsLogicService,

    ) { }


    public initialiseData(): void {
        this.lotConsentLogicService.getByLot(this.lotMappedItem.id).subOnce((consents) => {
            consents.forEach((item) => {
                if (item.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.Pending &&
                    item.consentTypeLabel === 'Resource Consent') {
                    this.hasPendingResourceConsent = true;
                }
            });
        });

        this.lotsLogicService.getHasincompletelotspecitemattributes(this.lotMappedItem.id).subOnce((result) => {
            this.hasIncompleteLotSpecItemAttributes = result;
        });

        this.lotsLogicService.getHasIncompleteLotSpecItems(this.lotMappedItem.id)
            .subOnce(hasIncomplete => {
                this.hasIncompleteLotSpecItems = hasIncomplete;
            });

        this.designConceptsLogicService.getByLot(this.lotMappedItem.id).subOnce((results) => {
            results.forEach((concept) => {
                if (concept.statusId === DESIGN_CONCEPT_STATUS_ENUM.Pending) {
                    this.hasPendingConcept = true;
                }
            });
        });
    }

    public hasPrimaryBuildingConsent(): boolean {
        return this.lotMappedItem?.buildingConsent?.id > 0
            || this.blockLevelProgress?.consent === LOT_MILESTONE_PROGRESS_ENUM.Started;
    }

    public showPrimaryLotInBlockAlert(): boolean {
        return this.lotMappedItem.isPrimaryUnit;
    }

    public showSaleLostAlert(): boolean {
        return this.lotMappedItem.marketStatus === MARKET_STATUS_ENUM.SaleLost;
    }

    public showIsShowHomeAlert(): boolean {
        return this.lotMappedItem.isShowHome;
    }

    public showNoConceptAlert(): boolean {
        return !(this.lotMappedItem.activeDesignConceptId > 0) && !this.lotMappedItem.hasUnits;
    }

    public showSpecTemplateAlert(): boolean {
        return !this.lotMappedItem.specTemplateId && !this.lotMappedItem.hasUnits;
    }

    public showIncompleteSpecItemAlert(): boolean {
        return this.hasIncompleteLotSpecItemAttributes && this.hasPrimaryBuildingConsent();
    }

    public showEstimatePricingAlert(): boolean {
        return this.lotMappedItem.hasEstimatedPriceRevisionOnAppliedConcept;
    }

    public showHasOwnersCareAlert(): boolean {
        return this.lotMappedItem.hasOwnersCareItem;
    }

    public showWithheldFromWebsiteAlert(): boolean {
        return this.lotMappedItem.isWithheldFromWebsite;
    }

    public displayNoAlertsMsg(): boolean {
        return !(
            this.showPrimaryLotInBlockAlert() ||
            this.showSaleLostAlert() ||
            this.showIsShowHomeAlert() ||
            this.showNoConceptAlert() ||
            this.hasIncompleteLotSpecItems ||
            this.showSpecTemplateAlert() ||
            this.hasPendingResourceConsent ||
            this.showIncompleteSpecItemAlert() ||
            this.showEstimatePricingAlert() ||
            this.showHasOwnersCareAlert() ||
            this.hasPendingConcept ||
            this.showWithheldFromWebsiteAlert()
        );
    }
}
