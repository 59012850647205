import {IBaseMappedItem} from './interfaces/i.base-mapped-item';
import {IBaseMappedItemPrivate} from './interfaces/i.base-mapped-item-private';
import {IComputedProp, IComputedPropDictionary} from './interfaces/i.computed-prop';

/** ***For use with mapped items only*** \
 * Properties get recomputed when the mapped item data is updated e.g. via $save(), $reload(), $updateThisData(), $updateThisAndOriginal(), $updateOriginal(), $mapDefinition(),
 * or when called manually via $recomputeProps()
 */
export function Computed(): MethodDecorator {
    return <Target extends IBaseMappedItem<any, any, any>>(target: Target, propertyKey: string, descriptor: PropertyDescriptor) => {
        const exposedTarget = target as unknown as IBaseMappedItemPrivate;
        if (exposedTarget.$computedProps == null) {
            (exposedTarget.$computedProps as IComputedPropDictionary) = {};
        }

        exposedTarget.$computedProps[propertyKey] = {
            compute: descriptor.get
        } as IComputedProp;

        descriptor.get = function(): void {
            // get computed prop values from the $computedPropsValues object that is populated
            // when the mappedItem class instance fires $recomputeProps
            return this.$computedPropsValues[propertyKey];
        };

        return descriptor;
    };
}
