import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LotBuildProgrammeEventService } from '../../../services/lot-build-programme-event.service';
import { LotBuildProgrammeActionBarService } from '../../services/lot-build-programme-action-bar.service';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-build-programme-view',
    templateUrl: './lot-build-programme-view.component.html',
    styleUrls: ['./lot-build-programme-view.component.scss'],
})
export class LotBuildProgrammeViewComponent implements OnInit, OnDestroy {

    public lotDto$$: BehaviorSubject<ILotDto> = new BehaviorSubject(null);

    @Input() public set lotDto(lotDto: ILotDto) {
        if (lotDto) {
            this.lotDto$$.next(lotDto);
            this.lotBuildProgrammeEvents.initLoad(lotDto.id);
        }
    }

    public get lotDto(): ILotDto {
        return this.lotDto$$?.value;
    }

    /** is this instance fullscreen/dialog */
    @Input() public readonly fullscreen: boolean = false;
    @Input() public readonly hasAlerts: boolean = false;

    public get buildProgramme(): any {
        return this.lotBuildProgrammeEvents?.buildProgramme;
    }

    /** is this instance in background behind a fullscreen/dialog instance */
    public readonly backgrounded = new ComputedProperty(() => {
        return !this.fullscreen && this.lotBuildProgrammeActionBar?.FULLSCREEN_OPEN?.value;
    }, false);

    private readonly subs$ = new Subscription();

    constructor(
        public readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
        public readonly lotBuildProgrammeActionBar: LotBuildProgrammeActionBarService,
        private readonly cdRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        this.subs$.add(
            this.lotBuildProgrammeActionBar.FULLSCREEN_OPEN.subscribe(() => {
                if (this.fullscreen) {
                    this.subs$.unsubscribe();
                    return;
                }
                this.backgrounded.recompute();
            })
        );
    }

    public ngOnDestroy(): void {
        this.subs$.unsubscribe();
        if (!this.fullscreen) {
            this.lotBuildProgrammeEvents.manualDestroy();
        }
    }

    public handleLotDtoChange(_lotDto: ILotDto): void {
        this.lotDto$$.next(_lotDto);
        this.cdRef.detectChanges();
    }

}
