import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ITeamsLogicService } from './interfaces/i.teams.logic.service';
import { ITeamDto } from './interfaces/i.team.dto';
import { ITeamsMappedItem } from './interfaces/i.teams.mapped';
import { TeamsMappedItem } from './teams.mapped';
import { IDesignTeamReportItemDto, ISkinnyUserDto, IUserDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class TeamsLogicService
    extends BaseLogicService<ITeamDto, ITeamsMappedItem>
    implements ITeamsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('auth/teams', TeamsMappedItem);
    }

    public getByKey(key: string): Observable<ITeamDto> {
        throw new Error('Method not implemented.');
    }

    public getFramingManagers(): Observable<ISkinnyUserDto[]> {
        return this.$http.get('/users/framingManagers');
    }

    public getFoundationManagers(): Observable<ISkinnyUserDto[]> {
        return this.$http.get('/users/foundationManagers');
    }

    public getConstructionManagersAllowedByUser(userId: string): Observable<IUserDetailsDto[]> {
        return this.$http
            .get<Array<IUserDetailsDto>>(`/users/allowedconstructionmanagers/${userId}`);
    }

    public $saveItem(team: ITeamDto): Observable<ITeamDto> {
        return this.$http
            .post<ITeamDto>(`${this.$baseUri}`, team);
    }

    /** Load All (Active & Inactive) Team Members by TeamId */
    public loadAllMembers(id: number): Observable<ISkinnyUserDto[]> {
        return this.$http.get(`${this.$baseUri}/${id}/all`);
    }

    /** Load Active Team Members by TeamId */
    public loadActiveTeamMembers(id: number): Observable<ISkinnyUserDto[]> {
        return this.$http.get(`${this.$baseUri}/${id}/active`);
    }

    /** Load All (Active & Inactive) Team Members by Key */
    public loadAllMembersByKey(key: string): Observable<ISkinnyUserDto[]> {
        return this.$http.get(`${this.$baseUri}/membersbykey/${key}/all`);
    }

    /** Load Active Team Members by Key */
    public loadActiveMembersByKey(key: string): Observable<ISkinnyUserDto[]> {
        return this.$http.get(`${this.$baseUri}/membersbykey/${key}/active`);
    }

    public getDesignTeamWorkLoad(): Observable<IDesignTeamReportItemDto[]> {
        return this.$http.get('teams/designteam/workload');
    }

}
