import { Component } from '@angular/core';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { filter, findIndex, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DashboardAddWidgetDialogComponent } from './dashboard-add-widget-dialog/dashboard-add-widget-dialog.component';
import { DASHBOARD_WIDGET, IDashboardWidget } from './dashboard-base-widget/dashboard-base-widget.directive';

@Component({
    selector: 'cb-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
    public readonly DASHBOARD_WIDGET = DASHBOARD_WIDGET;
    public widgets: IDashboardWidget[] = [];
    public widgetsLoaded = false;
    public hasNoWidget = true;
    public columns: number[] = [];
    public isEditing$ = new BehaviorSubject(false);

    constructor(
        protected readonly userCacheService: UserCacheService,
        protected readonly cbDialogService: CbDialogService,
    ) {
    }

    public ngOnInit(): void {
        this.userCacheService.dashboardWidgetConfig.init().then(() => {
            this.userCacheService.dashboardWidgetConfig.loadCacheItem().subOnce(dashboardWidgetConfig => {
                if (dashboardWidgetConfig?.id) {
                    this.userCacheService.dashboardWidgetConfig.id = dashboardWidgetConfig.id;
                    this.widgets = orderBy(JSON.parse(dashboardWidgetConfig.data).widgets, 'order');
                }
                this.widgetsLoaded = true;
                this.hasNoWidget = !this.widgets.length;
            });
        });
        window.addEventListener('resize', () => this.calculateColumns());
        this.calculateColumns();
    }

    public getWidgetsInColumn(column: number): IDashboardWidget[] {
        return filter(this.widgets, (widget, index) => index > 0 ? index % this.columns.length === column : column === 0);
    }

    public onAddWidgetClicked(): void {
        const dialog = this.cbDialogService.open(DashboardAddWidgetDialogComponent, {
            data: {
                dialogHeading: 'Add Widget',
                selectedWidgets: this.widgets,
            },
        });
        dialog.afterClosed().subOnce((selectedWidgetIds: DASHBOARD_WIDGET[]) => {
            if (selectedWidgetIds?.length) {
                this.widgets = [];
                selectedWidgetIds.forEach((selectedWidgetId: number, index: number) => {
                    const newWidget = {
                        type: selectedWidgetId,
                        order: index + 1,
                        configuration: {}
                    } as IDashboardWidget;
                    this.widgets.push(newWidget);
                });
                this.userCacheService.dashboardWidgetConfig.data = { widgets: this.widgets };
                this.userCacheService.dashboardWidgetConfig.saveCacheItem().subOnce();
            }
        });
    }

    public onRemoveWidgetClicked(widget: IDashboardWidget): void {
        this.cbDialogService.confirm({
            dialogHeading: 'Remove Widget',
            message: 'Are you sure you want to remove this Widget?',
            confirmed: () => {
                const index = findIndex(this.widgets, { ...widget });
                if (index > -1) {
                    this.widgets.splice(index, 1);
                    this.userCacheService.dashboardWidgetConfig.data = { widgets: this.widgets };
                    this.userCacheService.dashboardWidgetConfig.saveCacheItem().subOnce();
                }
            }
        });
    }

    private calculateColumns(): void {
        const width = window.innerWidth;
        const smBreakpoint = 600;
        const mdBreakpoint = 960;
        const lgBreakpoint = 1280;
        const xlBreakpoint = 1920;
        if (width < smBreakpoint) {
            // layout-xs
            this.columns = [ColumnIndex.One];
        } else if (width >= smBreakpoint && width < mdBreakpoint) {
            // layout-sm
            this.columns = [ColumnIndex.One, ColumnIndex.Two];
        } else if (width >= mdBreakpoint && width < lgBreakpoint) {
            // layout-md
            this.columns = [ColumnIndex.One, ColumnIndex.Two, ColumnIndex.Three];
        } else if (width >= lgBreakpoint && width < xlBreakpoint) {
            // layout-lg
            this.columns = [ColumnIndex.One, ColumnIndex.Two, ColumnIndex.Three, ColumnIndex.Four];
        } else {
            // layout-xl
            // >= 1920
            this.columns = [ColumnIndex.One, ColumnIndex.Two, ColumnIndex.Three, ColumnIndex.Four, ColumnIndex.Five];
        }
    }
}

enum ColumnIndex {
    One,
    Two,
    Three,
    Four,
    Five
}
