import {SpecificationTemplatePermissionEnum} from '../../shared/enums/permissions';

import {BasePermissions, IBaseSystemAreaPermissions} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';


export interface ISpecificationTemplatePermissions extends IBaseSystemAreaPermissions {
    canView(): boolean;
    canViewHistory(): boolean;
    canEdit(): boolean;
    canDuplicate(): boolean;
    canViewAll(): boolean;
    canEditAll(): boolean;
    canCreate(): boolean;
}

@Injectable()
export class SpecificationTemplatePermissions extends BasePermissions<SpecificationTemplatePermissionEnum>
    implements ISpecificationTemplatePermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('specificationtemplate', SpecificationTemplatePermissionEnum, currentUser, http, router);
    }

    public canView = () => this.permissionObject(SpecificationTemplatePermissionEnum.CanView);

    public canViewHistory = () => this.permissionObject(SpecificationTemplatePermissionEnum.CanViewHistory);

    public canEdit = () => this.permissionObject(SpecificationTemplatePermissionEnum.CanEdit);

    public canDuplicate = () => this.permissionObject(SpecificationTemplatePermissionEnum.CanDuplicate);

    public canViewAll = () => this.permissionObject(SpecificationTemplatePermissionEnum.CanViewAll);

    public canEditAll = () => this.permissionObject(SpecificationTemplatePermissionEnum.CanEditAll);

    public canCreate = () => this.permissionObject(SpecificationTemplatePermissionEnum.CanCreate);
}
