import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import {
    CostNatureEnumId,
    IVariationDto,
    VariationStatusEnumId,
    IAddressDto,
    IVariationLineDto,
    COST_NATURE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IVariationLogicService } from './interfaces/i.variation.logic-service';
import { IVariationMappedItem } from './interfaces/i.variation.mapped';
import { Observable } from 'rxjs';
import { Computed } from '../base/computed-prop.decorator';


export class VariationMappedItem
    extends BaseMappedItem<IVariationDto, IVariationMappedItem, IVariationLogicService>
    implements IVariationMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public variationNumber: number;
    @DtoProp public lotId: number;
    @DtoProp public supplierId: number;
    @DtoProp public clientAccountId: number;
    @DtoProp public address: IAddressDto;
    @DtoProp public variationStatus: VariationStatusEnumId;
    @DtoProp public costNature: CostNatureEnumId;
    @DtoProp public variationDate: string;
    @DtoProp public variationTotal: number;
    @DtoProp public variationTotalWithGst: number;
    @DtoProp public showGstExclusive: boolean;
    @DtoProp public lines: IVariationLineDto[];
    @DtoProp public approvedByUserId: string;
    @DtoProp public variationRaisedByUserId: string;
    @DtoProp public clientSaleId: number;
    @DtoProp public clientSaleNumber: string;
    @DtoProp public approvedDate: string;
    @DtoProp public approvedByUser: string;
    @DtoProp public variationRaisedByUser: string;
    @DtoProp public costNatureName: string;
    @DtoProp public variationStatusName: string;
    @DtoProp public variationToName: string;
    @DtoProp public isDeleted: boolean;
    @DtoProp public approvalDocumentGeneratedByUserId: string;
    @DtoProp public approvalDocumentGeneratedByName: string;
    @DtoProp public approvalDocumentGeneratedDate: string;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public extraToSaleAndPurchaseAgreement: boolean;
    @DtoProp public approvalDocumentId: number;

    @Computed() public get isExtraToClient(): boolean {
        return this.costNature === COST_NATURE_ENUM.ExtraToClient;
    }

    public get documentEntityId(): number {
        return this.id;
    }
    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    constructor(
        sourceData: IVariationDto,
        logicService: IVariationLogicService
    ) {
        super(sourceData, logicService, VariationMappedItem);
    }

    public delete(): Observable<boolean> {
        return this.$logicService.$deleteItem(this.id);
    }

}
