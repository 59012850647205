import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { IBuildingConsentMappedItem } from '@app/logic/lot-consents';
import { BUILDING_CONSENT_STATUS_ENUM, ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Subscription } from 'rxjs';
import { LotConsentViewService } from '../../service/lot-consent-view.service';

@Component({
    selector: 'cb-manage-building-consent',
    templateUrl: './manage-building-consent.component.html',
    styleUrls: ['./manage-building-consent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageBuildingConsentComponent implements OnInit, OnDestroy {
    @Input() public lotDto: ILotDto;
    @Output() public reloadLotMappedItem = new EventEmitter();

    public elapsedTime: string;
    public hasBuildingConsentDocument = false;
    public selectedTabIndex = 0;
    public councilRfiCount = 0;
    public documentCount = 0;

    private _subscription: Subscription;

    public get buildingConsent(): IBuildingConsentMappedItem {
        return this.lotConsentViewService?.buildingConsent ?? {} as IBuildingConsentMappedItem;
    }

    public readonly BUILDING_CONSENT_STATUS_ENUM = BUILDING_CONSENT_STATUS_ENUM;

    constructor(
        public readonly lotConsentViewService: LotConsentViewService,
        private readonly cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this._subscription = this.lotConsentViewService?.buildingConsent?.$updated.subscribe(() => {
            this.cdRef.detectChanges();
        });
        this.getElapsedTime();
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }


    public getElapsedTime(): void {
        this.buildingConsent.getElapsedTime().subOnce(x => this.elapsedTime = x);
    }

}
