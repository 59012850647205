import { Directive, ElementRef, HostListener } from '@angular/core';
import { removeCurrencyFormatting } from './currency.util';

@Directive({
    selector: '[cbTwoDp]'
})
export class TwoDpDirective {
    // Allow decimal numbers and negative values
    private readonly regex: RegExp = new RegExp(/^-?\d*\.?\d{0,2}$/g);
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private readonly specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    constructor(private readonly el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
        // Allow Backspace, tab, end, and home keys
        if (event.ctrlKey || event.shiftKey || this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        let next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
        next = removeCurrencyFormatting(next);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
