import { Input, Directive, HostListener } from '@angular/core';
import { Subject, Observable } from 'rxjs';

/** Use with [buttonControl] directive e.g.
 *  <button [buttonControl]="varThatIsInstanceOfButtonControl">Test</button>
 */
export class ButtonControl {
    private readonly _clickedSubject$ = new Subject<MouseEvent>();
    public readonly clicked = this._clickedSubject$.pipe();
}

/** for use in CbButtonControlDirective only */
interface IExposedButtonControl {
    readonly _clickedSubject$: Subject<MouseEvent>;
    readonly clicked: Observable<MouseEvent>;
}

@Directive({
    selector: '[buttonControl]'
})
export class CbButtonControlDirective {

    @Input() public buttonControl: IExposedButtonControl;

    @HostListener('click', ['$event'])
    public handleClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.buttonControl._clickedSubject$.next(event);
    }
}
