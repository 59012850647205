<h3 class="mat-title"
    style="margin-bottom: 0">
    Project Team
</h3>
<cb-table *cbWaitFor="mappedItem"
          class="cb-margin-top cb-margin-bottom-3x"
          [loaded]="loaded">
    <thead cbTableHead>
        <tr>
            <td>Name</td>
            <td>Role</td>
            <td>Company</td>
            <td>Mobile Phone</td>
            <td>Email</td>
            <td></td>
        </tr>
    </thead>
    <tr cbTableRow
        *ngFor="let user of mappedItem.teamMembers | cbSortByPipe: 'firstName': 'asc'">
        <cb-td-text [value]="user.firstName + ' ' + user.lastName">
        </cb-td-text>
        <cb-td-text [value]="user.jobTitle"></cb-td-text>
        <cb-td-text [value]="user.company"></cb-td-text>
        <cb-td-text [value]="user.phoneMobile"></cb-td-text>
        <cb-td-text [value]="user.email"></cb-td-text>
        <td cbTableStickyCol="end"
            cbTableShrinkCol>
            <button mat-icon-button
                    *ngIf="securityPermissions.canView()"
                    (cbClick)="viewUser(user)">
                <mat-icon class="cb-grey">info</mat-icon>
            </button>
        </td>
    </tr>
</cb-table>
