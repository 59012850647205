import { AfterViewInit, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DefaultSystemAreaDocumentTypesLogicService } from '@app/logic/default-system-area-document-types';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { DocumentGroupLogicService, DocumentsLogicService } from '@app/logic/documents';
import { DocumentService } from '@app/shared/components/documents/services/document.service';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { FormMode } from '@app/shared/enums/form';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import {
    DOCUMENT_GROUP_CODES_CONST, DOCUMENT_REQUIRED_TYPE_ENUM,
    IDefaultSystemAreaDocumentTypeDto,
    IDocumentUploadDto,
    SYSTEM_AREA_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IRequestDesignSchemeDocumentsToUpload } from '../i.request-design-scheme-documents-to-upload';
interface IFileTypesDto {
    fileTypesLabel: string;
    accepts: string;
    dropZoneId: string;
    fileInputId: string;
    /** documentTypeId_documentEntityId e.g. 1_0 (0 represents no document entity) */
    indexId: string;
}

type DefaultDocumentType = IDefaultSystemAreaDocumentTypeDto & IFileTypesDto;

type DefaultDocumentTypeDto
    = IDefaultSystemAreaDocumentTypeDto & IFileTypesDto;

@Component({
    selector: 'cb-request-design-scheme-step-five',
    templateUrl: './request-schemes-step-five.component.html',
    styleUrls: ['./request-schemes-step-five.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class RequestDesignSchemesStepFiveComponent implements AfterViewInit {

    @Input() public mappedItem: IDesignSchemeMappedItem;
    public POST_RENDER = 'design-scheme-request::default-design-info-document-types::post-render';
    public files: { [documentTypeId_documentEntityId: string]: File } = {};
    public defaultDocumentTypes: DefaultDocumentTypeDto[] = [];
    public document: IDocumentUploadDto;
    public readonly documentTypeFileErrors: { [documentTypeId_documentEntityId: string]: string[] } = {};
    public readonly defaultDocumentTypesIndex: { [documentTypeId_documentEntityId: string]: DefaultDocumentTypeDto } = {};
    private readonly existingDocumentTypes = new Set<number>();
    public readonly documentTypeRequired: { [documentTypeId: number]: boolean } = {};
    public documentType: DefaultDocumentTypeDto;
    @Input() public childForm: UntypedFormGroup;
    @Input() public mode: FormMode;
    @Input() public documentsToUpload: IRequestDesignSchemeDocumentsToUpload;
    @Input() public form: UntypedFormGroup;
    @Input() public lotId: number;

    constructor(
        public readonly documentsLogicService: DocumentsLogicService,
        public readonly documentGroupsLogicService: DocumentGroupLogicService,
        public readonly documentTypesLogicService: DocumentTypesLogicService,
        private readonly documentService: DocumentService,
        private readonly defaultSystemAreaDocumentTypesLogic: DefaultSystemAreaDocumentTypesLogicService,

    ) {
    }

    public ngAfterViewInit(): void {
        this.loadDefaultDocumentTypes();
    }

    public getFileName(documentType: DefaultDocumentTypeDto
    ): string | undefined {
        return this.files[documentType.indexId]?.name ?? documentType?.existingDocument?.name;
    }

    public disableUpload(documentType: DefaultDocumentTypeDto
    ): boolean {
        return !!this.files[documentType.indexId]
            || documentType.existingDocument?.allowMultiples;
    }

    public removeFile(documentType: DefaultDocumentTypeDto
    ): void {
        this.clearInputFile(documentType.fileInputId);
        delete this.files[documentType.indexId];
        this.setDocumentTypeRequired(documentType, true);
        this.mapUploadFilesTomappedItem();
    }

    triggerFileInput(fileInput: HTMLInputElement): void {
        if (fileInput) {
            fileInput.click();
        }
    }

    @Debounce()
    public fileUploaded(indexId: string,  event: Event): void {
        const input = event.target as HTMLInputElement;

        // Check if any file is selected
        if (!input.files || input.files.length === 0) {
            return;
        }

        const file = input.files[0];
        if (!this.validateFile(indexId, file)) {
            this.clearInputFile(this.defaultDocumentTypesIndex[indexId]?.fileInputId);
            return;
        }
        this.files[indexId] = file;
        this.setDocumentTypeRequired(this.defaultDocumentTypesIndex[indexId], false);
        this.mapUploadFilesTomappedItem();
    }


    /** @returns true for valid */
    private validateFile(indexId: string, file: File): boolean {
        this.documentTypeFileErrors[indexId] = [];
        let result = true;
        const documentType = this.defaultDocumentTypesIndex[indexId];

        const maxMB = documentType.maximumFileSizeInMegaBytes;
        const actualFileSizeMB = (file.size / 1024 / 1024); // Bytes to MB
        if (actualFileSizeMB > maxMB) {
            const fileSizeError = `Invalid File Size ${actualFileSizeMB.toFixed(2)}MB is greater than the maximum ${maxMB}MB`;
            this.documentTypeFileErrors[indexId].push(fileSizeError);
        }

        const fileExt = file.name.split('.').pop().toLowerCase();
        if (!documentType.fileTypes.some(fileType => fileType.toString().toLowerCase() === fileExt)) {
            const extError = `Invalid File type (${fileExt}) must be one of the following types; ${documentType.fileTypesLabel}`;
            this.documentTypeFileErrors[indexId].push(extError);
            result = false;
        }

        return result;
    }

    private mapUploadFilesTomappedItem(): void {
        this.documentsToUpload.designSchemeDocuments = [];
        for (const indexId in this.files) {
            if (this.files.hasOwnProperty(indexId)) {
                const file = this.files[indexId];
                const documentType: DefaultDocumentTypeDto
                    = this.defaultDocumentTypesIndex[indexId];
                this.documentsToUpload.designSchemeDocuments.push({
                    documentEntityId: documentType.existingDocument?.documentEntityId,
                    documentGroupId: documentType.documentGroupId,
                    documentTypeId: documentType.documentTypeId,
                    file,
                    name: file.name,
                } as IDocumentUploadDto);
            }
        }
    }

    private loadDefaultDocumentTypes(): void {
        this.defaultSystemAreaDocumentTypesLogic
            .getListByLot(SYSTEM_AREA_ENUM.DesignScheme, this.lotId, DOCUMENT_GROUP_CODES_CONST.DESIGN_SCHEME_DOC_GROUP)
            .subOnce((results) => {
                this.defaultDocumentTypes = results.map((docType: DefaultDocumentTypeDto) => ({ ...docType, ...this.mapFileTypes(docType) }));
                this.defaultDocumentTypes.forEach(docType => {
                    this.defaultDocumentTypesIndex[docType.indexId] = docType;
                    if (docType.existingDocument != undefined) {
                        this.existingDocumentTypes.add(docType.documentTypeId);
                    }
                    this.setDocumentTypeRequired(docType);
                });
            });
    }

    private setDocumentTypeRequired(documentType: DefaultDocumentTypeDto, required = true): void {
        if (documentType.requiredType === DOCUMENT_REQUIRED_TYPE_ENUM.Required) {
            this.documentTypeRequired[documentType.documentTypeId]
                = this.existingDocumentTypes.has(documentType.documentTypeId) ? false : required;
        }
        const isValid = !Object.values(this.documentTypeRequired).some(x => x);
        if (isValid) {
            this.childForm.setErrors(null);
        } else {
            this.childForm.setErrors({ invalid: true });
        }
    }

    private clearInputFile(fileInputId: string): void {
        const input = document.getElementById(fileInputId) as HTMLInputElement;
        if (input) {
            input.value = '';
        }
    }

    private mapFileTypes(docType: IDefaultSystemAreaDocumentTypeDto): IFileTypesDto {
        const indexId = `${docType.documentTypeId}_${docType?.existingDocument?.documentEntityId ?? 0}`;

        return {
            accepts: docType.fileTypes.map(fileType => `.${fileType}`).join(', '),
            dropZoneId: `file-dropzone-${indexId}`,
            fileInputId: `file-upload-${indexId}`,
            fileTypesLabel: docType.fileTypes.join(', '),
            indexId,
        };
    }
}
