import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IClientSaleDto } from '@app/logic/client-sale';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { map, Subject } from 'rxjs';

@Component({
    selector: 'cb-my-client-sales-view',
    templateUrl: './my-client-sales-view.component.html',
    styleUrls: ['./my-client-sales-view.component.scss']
})
export class MyClientSalesViewComponent implements OnInit {

    public currentClientSaleId: number;
    public readonly isBuildingConsultant = new ComputedProperty(() => this.currentUser?.hasBuildingConsultant() ?? false);
    public loading = false;
    public readonly clientSaleUpdated = new Subject<IClientSaleDto>();

    constructor(
        private readonly currentUser: CurrentUserService,
        private readonly route: ActivatedRoute,
        private readonly navigation: NavigationService,
    ) {
        this.currentUser.$promise.then(() => {
            this.isBuildingConsultant.recompute();
        });
    }

    public ngOnInit(): void {
        this.route.params
            .pipe(
                map((params: { id: string }) => {
                    this.loading = true;
                    return Number(params.id);
                })
            )
            .subOnce((x: number) => {
                if (x > 0) {
                    this.currentClientSaleId = x;
                }
                this.loading = false;
            });
    }

    public currentClientSaleChange(clientSaleId: number): void {
        this.loading = true;
        this.currentClientSaleId = null;
        setTimeout(() => {
            this.navigation.redirectTo(`/clientsales/view/${clientSaleId}/details`).then(() => {
                this.loading = false;
                this.currentClientSaleId = clientSaleId;
            });
        }, 100);
    }
}
