<mat-card class="start-center flex-row">
    <button *ngIf="canCreateVariation()"
            [disabled]="!canGenerateVariations()"
            class="cb-margin-left"
            mat-raised-button
            color="primary"
            (click)="openGenerateVariationDialog()">
        Generate Variation ({{$numberofUnassignedChangeRecords}})
    </button>
</mat-card>
