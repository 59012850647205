<cb-dialog>
    <form class="flex-col flex"
          #manageLocations="ngForm">
        <div class="flex-col flex">
            <cb-checkbox *ngFor="let region of regions"
                         [label]="region.name"
                         [name]="'region'+region.id"
                         [ngModel]="isRegionChecked(region.id)"
                         (change)="onRegionChanged(region.id, $event)"></cb-checkbox>
        </div>
    </form>
    <footer class="center-center flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                [disabled]="!manageLocations.valid || !manageLocations.dirty"
                (click)="saveClicked()">Save</button>
        <button mat-raised-button
                (click)="dialogRef.close()">Cancel</button>
    </footer>
</cb-dialog>
