<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="pinnedPanels[silentDataKey]"
                     #lotDetailsExpansionPanel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">info</mat-icon>
            <b> Lot Details</b>
        </mat-panel-title>
        <mat-panel-description class="end-center flex-row">
            <button *ngIf="lotDetailsExpansionPanel.expanded || silentData[silentDataKey]"
                    mat-icon-button
                    (click)="togglePanelPinned($event, silentDataKey)">
                <mat-icon svgIcon="pin"
                          [ngClass]="{'cb-accent' : silentData[silentDataKey]}">
                </mat-icon>
            </button>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex-wrap flex">
        <cb-display-value-new class="flex-50"
                              *ngIf="jobNumberField.isVisible"
                              label="Job No.">
            {{lotMappedItem?.jobNumber | cbDasher}}
        </cb-display-value-new>
        <cb-display-value-new class="flex-50"
                              *ngIf="jobStatusField.isVisible"
                              label="Job Status">
            {{lotMappedItem?.jobStatus | cbDasher}}
        </cb-display-value-new>
        <cb-display-value-new class="flex-50"
                              *ngIf="jobStatusField.isVisible"
                              label="Lot Contract Type">
            {{lotMappedItem?.contractType | cbDasher}}
        </cb-display-value-new>
        <cb-display-value-new class="flex-50"
                              *ngIf="marketStatusField.isVisible"
                              label="Market Status">
            {{lotMappedItem?.marketStatus | cbEnumToLabel: MARKET_STATUS_ENUM | cbDasher}}
        </cb-display-value-new>
        <cb-currency class="flex-50"
                     *ngIf="contractGenerationFeeField.isVisible"
                     label="Contract Generation Fee"
                     [inclGst]="true"
                     [readonly]="true"
                     name="contractGenerationFee"
                     [(ngModel)]="lotMappedItem.contractGenerationFee">
        </cb-currency>
        <cb-currency class="flex-50"
                     *ngIf="landPriceField.isVisible"
                     label="Land Price"
                     [inclGst]="true"
                     [readonly]="true"
                     name="landPrice"
                     [(ngModel)]="lotMappedItem.financials.landPrice">
        </cb-currency>
        <cb-currency class="flex-50"
                     *ngIf="buildPriceField.isVisible"
                     label="Build Price"
                     [inclGst]="true"
                     [readonly]="true"
                     name="buildPrice"
                     [(ngModel)]="lotMappedItem.financials.buildPrice">
        </cb-currency>
        <cb-currency class="flex-50"
                     *ngIf="sellPriceField.isVisible"
                     label="Selling Price"
                     [inclGst]="true"
                     [readonly]="true"
                     name="sellPrice"
                     [(ngModel)]="lotMappedItem.financials.sellPrice">
        </cb-currency>
        <cb-currency class="flex-50"
                     label="Contract Price"
                     [inclGst]="true"
                     [readonly]="true"
                     name="contractPrice"
                     [(ngModel)]="lotMappedItem.financials.contractPrice">
        </cb-currency>
        <cb-display-value-new class="flex-50"
                              *ngIf="consentNumberField.isVisible"
                              label="Consent Number">
            {{lotMappedItem?.buildingConsent?.consentNumber | cbDasher}}
        </cb-display-value-new>
        <cb-display-value-new class="flex-50"
                              [label]="buildingConsentDateLabel">
            {{ (buildingConsentDate !== null) ? (buildingConsentDate | cbDate ) : '-' }}
        </cb-display-value-new>
        <cb-display-value-new class="flex-50"
                              label="Expected Title Date">
            {{lotMappedItem?.expectedTitleDate | date: 'dd MMM yyyy' | cbDasher}}
        </cb-display-value-new>
    </div>
</mat-expansion-panel>
