import { Component, OnInit, Inject } from '@angular/core';
import {
    BUSINESS_ACCOUNT_STATUS_ENUM,
    UNIT_OF_MEASURE_ENUM,
    BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM,
    COST_NATURE_ENUM,
    PRODUCT_SEARCH_TYPE_ENUM,
    ILotDto
} from '@classictechsolutions/hubapi-transpiled-enums';

import { IPurchaseOrderLineMappedItem } from '@app/logic/purchase-orders/interfaces/i.purchase-order-line.mapped';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseFormViewDirective } from '@app/shared/base-views/base-form-view.directive';
import { IPurchaseOrderLineDto } from '@app/logic/purchase-orders/interfaces/i.purchase-order-line.dto';
import { IPurchaseOrdersLogicService } from '@app/logic/purchase-orders/interfaces/i.purchase-orders.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { FormMode } from '@app/shared/enums/form';
import { toPromise } from 'cb-hub-lib';
import { ProductLogicService } from '@app/logic/products';
import { IProductDto } from '@app/logic/products/interfaces/i.product.dto';
import { IBusinessAccountSearchParams } from '@app/logic/business-accounts/interfaces/i.business-account-search-params';
import { isNullOrWhiteSpace } from 'cb-hub-lib';

interface IData {
    lineItem: IPurchaseOrderLineMappedItem;
    lot: ILotDto;
}

@Component({
    selector: 'cb-edit-manual-order-line-dialog',
    templateUrl: './edit-manual-order-line-dialog.component.html',
    styleUrls: ['./edit-manual-order-line-dialog.component.scss'],
    providers: [
        ToastService,
        ProductLogicService,
    ],
})
export class EditManualOrderLineDialogComponent extends BaseFormViewDirective<IPurchaseOrderLineDto, IPurchaseOrderLineMappedItem, IPurchaseOrdersLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '66%';

    public get mappedItem(): IPurchaseOrderLineMappedItem {
        return this.data.lineItem;
    }
    public get lot(): ILotDto {
        return this.data.lot;
    }

    public PRODUCT_SEARCH_TYPE_ENUM = PRODUCT_SEARCH_TYPE_ENUM;

    public selectedProduct: IProductDto;
    public productSearchText: string;
    public uomList = UNIT_OF_MEASURE_ENUM.toSelectList();
    public costNatureList = COST_NATURE_ENUM.toSelectList();
    public productLoaded = false;
    public businessAccountFindParams = {} as Partial<IBusinessAccountSearchParams>;

    public mode: FormMode;

    constructor(
        public readonly dialogRef: MatDialogRef<EditManualOrderLineDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly toastService: ToastService,
        public readonly productLogic: ProductLogicService,
    ) {
        super(toastService);
    }

    public ngOnInit(): void {
        this.setupDialog();
        this.businessAccountFindParams = {
            reg: [this.lot.location.region.id],
            s: [
                BUSINESS_ACCOUNT_STATUS_ENUM.Active,
                BUSINESS_ACCOUNT_STATUS_ENUM.Overridden
            ],
            t: BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM.All
        };
    }

    public productUpdate(item: IProductDto): void {
        // skip first product update call because it is triggered when loading the dialog
        if (!this.productLoaded && this.mappedItem.id > 0) {
            this.productLoaded = true;
            return;
        }
        this.productLogic
            .getRateForManualPurchaseOrder(item.id, this.mappedItem.parent.id)
            .subOnce({
                next: (rate) => {
                    this.mappedItem.setProductDetails(item, this.productSearchText, rate);
                }
            });
    }

    public updateLineItemDescription(value: string): void {
        this.productSearchText = value;
        this.mappedItem.description = this.productSearchText;
        this.mappedItem.offeringId = null;
    }

    public saveDialog(): void {
        toPromise(this.mappedItem.$save()).then(() => {
            this.dialogRef.close(this.mappedItem);
        });
    }

    public uomChanged(): void {
        this.mappedItem.uom = UNIT_OF_MEASURE_ENUM[this.mappedItem.uomId];
    }

    public cancelDialog(): void {
        this.dialogRef.close(false);
    }

    public unknownRateChanged(): void {
        if (this.mappedItem.costToBeDetermined) {
            this.mappedItem.rate = null;
        }
    }

    private readonly setupDialog = (): void => {
        this.mode = this.mappedItem.id > 0 ? FormMode.Edit : FormMode.Add;


        if (this.mappedItem.offeringId) {
            toPromise(this.productLogic.$getItem(this.mappedItem.offeringId)).then((offering: IProductDto) => {
                this.selectedProduct = offering;
            });
        } else if (!isNullOrWhiteSpace(this.mappedItem.description)) {
            // this must be set to true if product is not pre-existing
            // otherwise selecting a product will not work the first time (until productLoaded is set to true)
            this.productLoaded = true;
            this.selectedProduct = {
                id: null,
                name: this.mappedItem.description,
            } as IProductDto;
        }

        if (!this.mappedItem.isNoteLine) {
            this.productSearchText = this.mappedItem.description;
        }
    };
}
