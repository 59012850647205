import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import {IBuildOverheadCostMappedItem} from '@app/logic/build-overhead-costs/interfaces/i.build-overhead-cost-mapped';
import {IBuildOverheadCostLogicService} from '@app/logic/build-overhead-costs/interfaces/i.build-overhead-cost-logic.service';
import {IBuildOverheadCostDto} from '@classictechsolutions/hubapi-transpiled-enums';

export class BuildOverheadCostMappedItem
    extends BaseMappedItem<IBuildOverheadCostDto, IBuildOverheadCostMappedItem, IBuildOverheadCostLogicService>
    implements IBuildOverheadCostMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public readonly description: string;
    @DtoProp public readonly financeCode: string;
    @DtoProp public readonly isActive: boolean;
    @DtoProp public readonly name: string;

    constructor(
        sourceData: IBuildOverheadCostDto,
        logicService: IBuildOverheadCostLogicService
    ) {
        super(sourceData, logicService, BuildOverheadCostMappedItem);
    }
}
