<ng-container [ngSwitch]="authService.isAuthenticated">
    <ng-container *ngSwitchCase="true">
        <mat-progress-bar class="cb-http-request-progress-bar"
                          mode="indeterminate"
                          *ngIf="currentHttpRequestsService.anyCurrentRequests"></mat-progress-bar>
        <mat-toolbar class="flex-col"
                     color="primary"
                     id="topnav-container">
            <cb-topnav-title [sidenav]="sidenav"></cb-topnav-title>
            <cb-breadcrumbs></cb-breadcrumbs>
        </mat-toolbar>
    </ng-container>
    <cb-auth-loading *ngSwitchCase="false"></cb-auth-loading>
</ng-container>
<mat-sidenav-container class="cb-sidenav-container"
                       id="parentContainer">
    <mat-sidenav #sidenav
                 fixedTopGap="56">
        <cb-sidenav-menu [sidenav]="sidenav"></cb-sidenav-menu>
    </mat-sidenav>
    <mat-sidenav-content>
        <div id="mainContainer">
            <div ng-view
                 id="ngView"></div>

            <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
            <router-outlet *ngIf="!isIframe"></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<cb-toasts style="z-index: 10000;"></cb-toasts>
