import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ILeadMappedItem} from '@app/logic/leads/interfaces/i.lead.mapped';
import {LeadLogicService} from '@app/logic/leads';
import {CONTACT_METHOD_ENUM, LEAD_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {LeadDetailsEditDialogComponent} from '../../dialogs/lead-details-edit-dialog.component';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-lead-followup-card',
    templateUrl: './lead-followup-card.component.html',
    styleUrls: ['./lead-followup-card.component.scss']
})
export class LeadFollowupCardComponent {
    @Input() public mappedItem: ILeadMappedItem;
    @Input() public canEdit: boolean;
    @Output() public leadChanged: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private leadLogicService: LeadLogicService,
        public readonly cbDialog: CbDialogService) { }

    public get preferredContactMethod(): string {
        if (!this.mappedItem || !this.mappedItem.mainContact) {
            return 'using';
        }
        switch (this.mappedItem.mainContact.preferredContact) {
            case CONTACT_METHOD_ENUM.Email:
                return 'emailing';
            case CONTACT_METHOD_ENUM.HomePhone:
            case CONTACT_METHOD_ENUM.WorkPhone:
            case CONTACT_METHOD_ENUM.MobilePhone:
                return 'calling';
            default:
                return 'using';
        }
    }

    public get preferredContactMethodHref(): string {
        if (!this.mappedItem || !this.mappedItem.mainContact) {
            return '';
        }
        switch (this.mappedItem.mainContact.preferredContact) {
            case CONTACT_METHOD_ENUM.Email:
                return `mailto:${this.mappedItem.mainContact.preferredContactDetails}`;
            case CONTACT_METHOD_ENUM.HomePhone:
            case CONTACT_METHOD_ENUM.WorkPhone:
            case CONTACT_METHOD_ENUM.MobilePhone:
                return `tel:${this.mappedItem.mainContact.preferredContactDetails}`;
            default:
                return '';
        }
    }

    public canViewFollowUp(): boolean {
        return this.mappedItem?.status !== LEAD_STATUS_ENUM.Qualified
            && this.mappedItem?.status !== LEAD_STATUS_ENUM.Lost;
    }

    public editLeadDetails(event: Event): void {
        if (!this.canEdit) { return; }
        this.cbDialog
            .open(
                LeadDetailsEditDialogComponent,
                {
                    data: {
                        mappedItem: this.mappedItem.$clone()
                    },
                    disableClose: true,
                    minWidth: '33%',
                }
            ).afterClosed()
            .subOnce((result) => {
                if (result) {
                    this.leadChanged.emit(this.leadLogicService.$createMappedItem(result));
                }
            });
    }

}
