import { AfterViewInit, Directive, ElementRef, HostListener, OnDestroy, Renderer2 } from '@angular/core';
import { forEach, maxBy, minBy } from 'lodash';

@Directive({
    selector: 'textarea[cbTextAreaAutoUpdateHeight]'
})
export class CbTextAreaAutoUpdateHeightDirective implements AfterViewInit, OnDestroy {
    private clickListener: () => void;
    constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) { }

    public ngOnDestroy(): void {
        if (typeof this.clickListener === 'function') {
            this.clickListener();
        }
    }

    @HostListener('blur')
    public onBlur(): void {
        this.delayedResize();
    }

    @HostListener('cut')
    public onCut(): void {
        this.delayedResize();
    }

    @HostListener('paste')
    public onPaste(): void {
        this.delayedResize();
    }

    @HostListener('drop')
    public onDrop(): void {
        this.delayedResize();
    }

    @HostListener('keydown', ['$event'])
    public onKeydown(e: KeyboardEvent): void {
        if (e.key === 'Backspace') {
            this.resizeFromBackspace();
        }
        this.delayedResize();
    }

    @HostListener('click', ['$event'])
    public blockClick(e: KeyboardEvent): void {
        e.stopPropagation();
    }

    public ngAfterViewInit(): void {
        setTimeout(this.run, 100);
    }

    private readonly matchHeightLarge = (): void => {
        const pair = $(`textArea[data-line-item="${this.el.nativeElement.dataset.lineItem}"]`);
        const maxEle = maxBy(pair, (area: any) => {
            return area.scrollHeight;
        });

        forEach(pair, (area: any) => {
            area.style.height = `${parseInt(maxEle.style.height, 10)}px`;
        });
    };

    private readonly matchHeightSmall = (): void => {
        const pair = $(`textArea[data-line-item="${this.el.nativeElement.dataset.lineItem}"]`);
        const minEle = minBy(pair, (area: any) => {
            return area.scrollHeight;
        });

        forEach(pair, (area: any) => {
            area.style.height = `${minEle.scrollHeight}px`;
        });
    };

    private readonly resize = (): void => {
        this.el.nativeElement.style.height = this.el.nativeElement.scrollHeight ?
            `${this.el.nativeElement.scrollHeight}px` : '21px'; // 21px is the height of textArea with 1 line - prevent 0px <textarea>
        this.matchHeightLarge();
    };

    private readonly resizeFromBackspace = (): void => {
        this.el.nativeElement.style.height = 'auto';
        this.matchHeightSmall();
    };

    public delayedResize = (): void => {
        setTimeout(() => this.resize(), 10);
    };

    private readonly run = () => {
        const textareaParent = this.el.nativeElement.parentElement;

        if (!textareaParent) {
            setTimeout(this.run, 100);
            return;
        }

        this.clickListener = this.renderer.listen(textareaParent, 'click', () => {
            this.el.nativeElement.focus();
        });

        this.delayedResize();
    };
}
