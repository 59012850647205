import {BasePermissions, IBaseSystemAreaPermissions} from '@app/core/permissions/base.permissions';
import {Injectable} from '@angular/core';
import {CurrentUserService} from '@app/core/authentication/current.user';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {LotBuildOverheadCostsPermissionEnum} from '@app/shared/enums/permissions/lot.build-overhead-costs.permission';

export interface ILotBuildOverheadCostsPermissions extends IBaseSystemAreaPermissions {
    canView(): boolean;
    canCreate(): boolean;
    canEdit(): boolean;
}

@Injectable()
export class LotBuildOverheadCostsPermissions
    extends BasePermissions<LotBuildOverheadCostsPermissionEnum>
    implements ILotBuildOverheadCostsPermissions {
    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lotbuildoverheadcosts', LotBuildOverheadCostsPermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(LotBuildOverheadCostsPermissionEnum.CanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(LotBuildOverheadCostsPermissionEnum.CanCreate);
    }

    public canEdit(): boolean {
        return this.permissionObject(LotBuildOverheadCostsPermissionEnum.CanEdit);
    }
}
