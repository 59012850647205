import {Pipe, PipeTransform} from '@angular/core';
import {CbSortByPipe} from '../sort-by/sort-by.pipe';

@Pipe({
    name: 'cbSortByImpurePipe',
    pure: false
})
export class CbSortByImpurePipe implements PipeTransform {

    constructor(private sortByPipe: CbSortByPipe) { }

    public transform(value: any[], column: string, order = ''): any {
        return this.sortByPipe.transform(value, column, order);
    }

}
