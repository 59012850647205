import {CbEnumService, SelectListItem} from '@app/core/services/enum/enum.service';
import {Injectable, Injector} from '@angular/core';

import {BaseLogicService} from '../base/base-logic.service';
import {GlobalSettingsType} from '@app/shared/enums/global-settings-type/global-setitngs-type';
import {HttpWrapperService} from '../../core/services/http-wrapper/http-wrapper.service';
import {IGlobalSettingsDto} from './interfaces/i.global-settings.dto';
import {IGlobalSettingsLogicService} from './interfaces/i.global-settings.logic.service';
import {find} from 'lodash';
import {IGlobalSettingsMappedItem} from './interfaces/i.global-settings.mapped';
import {GlobalSettingsMappedItem} from './global-settings.mapped';

@Injectable()
export class GlobalSettingsLogicService extends BaseLogicService<IGlobalSettingsDto, IGlobalSettingsMappedItem> implements IGlobalSettingsLogicService {
    public globalSettings: Array<SelectListItem>;
    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        private readonly enumService: CbEnumService
    ) {
        super('globalsettings', GlobalSettingsMappedItem);
        this.globalSettings = this.enumService.getSelectList(GlobalSettingsType);
    }

    public getLabelFromEnum(setting: IGlobalSettingsDto): string {
        const item = find(this.globalSettings, { id: setting.id });
        return item.label;
    }
}
