import {
    IColourItemDto,
    ILotSpecColourItemDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { concatMap, map, Observable, of, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ILotSpecColourItemLogicService } from './interfaces/i.lot-spec-colour-item-logic.service';
import { ILotSpecColourItemMappedItem } from './interfaces/i.lot-spec-colour-item.mapped';

export class LotSpecColourItemMappedItem
    extends BaseMappedItem<ILotSpecColourItemDto, ILotSpecColourItemMappedItem, ILotSpecColourItemLogicService>
    implements ILotSpecColourItemMappedItem {

    @DtoProp public lotId: number;
    @DtoProp public colourItem: IColourItemDto;
    @DtoProp public name: string;
    @DtoProp public specGroupId: number;
    @DtoProp public specGroupName: string;
    @DtoProp public notes: string;
    @DtoProp public lotSpecGroupId: number;
    @DtoProp public colourItemId: number;
    @DtoProp public specColourItemImage: string;
    @DtoProp public sortOrder: number;
    @DtoProp public canRemoveImage: boolean;
    @DtoProp public textForSpecification: string;
    @DtoProp public isDeleted: boolean;
    @DtoProp public versionNumber: number;
    @DtoProp public colourId: number;
    @DtoProp public showInClientSpecification: boolean;
    @DtoProp public id: number;

    public removeImageOnSave = false;
    public specColourItemImageFile: File;

    constructor(
        sourceData: ILotSpecColourItemDto,
        logicService: ILotSpecColourItemLogicService
    ) {
        super(sourceData, logicService, LotSpecColourItemMappedItem);
    }

    public $reload(): Observable<ILotSpecColourItemDto> {
        return this.$logicService
            .getItemAtVersion(this.lotId, this.id, this.versionNumber)
            .pipe(
                tap((response) => {
                    this.$updateThisData(response);
                })
            );
    }

    public $save(): Observable<ILotSpecColourItemDto> {
        return super.$save()
            .pipe(
                concatMap((result: ILotSpecColourItemDto) => {
                    this.$updateThisAndOriginal(result);
                    if (this.specColourItemImageFile) {
                        return this.uploadImage();
                    } else if (this.removeImageOnSave) {
                        return this.deleteImage();
                    }
                    return of(result);
                })
            );
    }

    public delete(specVersion?: number): Observable<boolean> {
        return this.$logicService
            .$deleteItem<boolean>(this.lotId, this.id, specVersion)
            .pipe(
                tap(result => {
                    if (result) {
                        const data = this.$getMappedDtoItem();
                        data.isDeleted = true;
                        this.$updateThisAndOriginal(data);
                    }
                })
            );
    }

    public uploadImage(): Observable<ILotSpecColourItemDto> {
        if (this.specColourItemImageFile == null) {
            throw new Error('Cannot upload image - specColourItemImageFile must be set');
        }

        return this.$logicService
            .uploadColourItemImageAtVersion(this.lotId, this.id, this.versionNumber, this.specColourItemImageFile)
            .pipe(
                tap(result => {
                    this.specColourItemImageFile = null;
                    this.$updateThisAndOriginal(result);
                })
            );
    }


    public deleteImage(): Observable<ILotSpecColourItemDto> {
        return this.$logicService.removeColourItemImage(this.lotId, this.id)
            .pipe(
                map(result => {
                    const data = this.$getMappedDtoItem();
                    this.removeImageOnSave = false;
                    if (result) {
                        data.specColourItemImage = null;
                        this.$updateThisAndOriginal(data);
                    }
                    return data;
                })
            );
    }
}
