<mat-card class="flex-col flex">
    <mat-card-header *ngIf="isBuildingConsent.value">
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="buildingConsentIcon.value.title">
            <mat-icon [ngClass]="buildingConsentIcon.value.class"
                      class="help-cursor">{{buildingConsentIcon.value.icon}}</mat-icon>
        </div>
        <mat-card-title>Building Consent - {{consent.consentNumber}}</mat-card-title>
    </mat-card-header>
    <mat-card-header *ngIf="!isBuildingConsent.value">
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="resourceConsentIcon.value.title">
            <mat-icon [ngClass]="resourceConsentIcon.value.class"
                      class="help-cursor">{{resourceConsentIcon.value.icon}}</mat-icon>
        </div>
        <mat-card-title>Resource Consent - {{consent.consentNumber}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-row flex">
        <div class="flex-col flex">
            <div class="flex-col">
                <cb-display-value-new label="Council Name"
                                      [value]="consent.councilName"></cb-display-value-new>
                <div class="flex-row">
                    <cb-user-date-time-new class="flex"
                                           label="Council Received"
                                           [floatingLabel]="true"
                                           [date]="consent.receivedByCouncilDate">
                    </cb-user-date-time-new>
                    <cb-user-date-time-new class="flex"
                                           label="Due Date"
                                           [floatingLabel]="true"
                                           [date]="consent.consentDueDate">
                    </cb-user-date-time-new>
                </div>
                <div class="flex-row">
                    <cb-user-date-time-new class="flex"
                                           label="Issued Date"
                                           [floatingLabel]="true"
                                           [date]="consent.consentReceivedDate">
                    </cb-user-date-time-new>
                    <cb-user-date-time-new class="flex"
                                           label="Expiry Date"
                                           [floatingLabel]="true"
                                           [date]="consent.consentExpiryDate">
                    </cb-user-date-time-new>
                </div>
                <div class="flex-row">
                    <mat-checkbox *ngIf="consent.isPrimary"
                                  [ngModel]="consent.isPrimary"
                                  [ngModelOptions]="{ standalone: true }"
                                  [disabled]="true">Primary Building Consent</mat-checkbox>
                </div>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions class="flex-col">
        <mat-divider></mat-divider>
        <div class="cb-margin-top flex-row">
            <span class="capitalize flex">{{consent.consentTypeLabel}}</span>
            <button mat-stroked-button
                    color="accent"
                    (click)="manageLotConsent()">View</button>
        </div>
    </mat-card-actions>
</mat-card>
