import { Directive, Input, ViewContainerRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { AutocompleteScrollerComponent } from '.';

@Directive({
    selector: `
                input[matAutocomplete][requireMatch][ngModel],
                cb-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-user-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-product-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-contact-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-build-programme-activity-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-product-category-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-slot-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-build-activity-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-business-account-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-council-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-document-type-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-location-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-trade-type-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-supply-type-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-client-account-autocomplete-scroller[requireMatch][ngModel][matchProps],
                cb-lot-autocomplete-scroller[requireMatch][ngModel][matchProps],
            `,
    providers: [
        { provide: NG_VALIDATORS, useExisting: RequireMatchValidatorDirective, multi: true }
    ]
})
export class RequireMatchValidatorDirective {
    private _cbRequireMatchValidator: boolean;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('requireMatch') public set cbRequireMatchValidator(x: boolean) {
        this._cbRequireMatchValidator = x;
        if (this.gControl) {
            this.gControl.updateValueAndValidity();
        }
    }
    public get cbRequireMatchValidator(): boolean {
        return this._cbRequireMatchValidator;
    }

    private _matchProps: string[];
    public get matchProps(): string[] {
        return this._matchProps || (this.host && this.host.matchProps);
    }
    @Input() public set matchProps(val: string[]) {
        this._matchProps = val;
        if (this.gControl) {
            this.gControl.updateValueAndValidity();
        }
    }

    public gControl: AbstractControl;

    constructor(public readonly viewContainerRef: ViewContainerRef, private readonly host: AutocompleteScrollerComponent) { }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (!this.cbRequireMatchValidator) {
            return null;
        }

        this.gControl = control;

        const val = control.value || this.host.value;
        const required = this.host && this.host.required != null && this.host.required !== false;

        const isValid = val != null && this.matchProps && val && this.matchProps.every(x => val[x] != null);

        const notRequiredButValid = !required && (isValid || isNullOrWhiteSpace(val));
        const requiredAndValid = required && isValid;

        return notRequiredButValid || requiredAndValid ? null : { matchRequired: 'cb-autocomplete-scroller requires an option to be selected' };
    }
}
