<form #searchForm="ngForm">
    <div infiniteScroll
         [infiniteScrollDistance]="1"
         [fromRoot]="true"
         [infiniteScrollContainer]="'div#mainContainer'"
         (scrolled)="doSearch()"
         class="cb-margin start flex-col">
        <mat-card class="start flex-col">
            <div class="cb-margin flex-row flex">
                <cb-input class="input-contact flex"
                          type="text"
                          label="Type in First Name, Last Name or Phone Number to Search"
                          [(ngModel)]="userCacheItem.data.query"
                          name="search"></cb-input>
                <div class="cb-margin-left-2x flex-row center-end">
                    <mat-checkbox name="StrictSearch"
                                  [(ngModel)]="userCacheItem.data.strictSearch">
                        Strict Search
                    </mat-checkbox>
                </div>
            </div>
        </mat-card>
        <div class="flex-parent flex-wrap xs-column">
            <div class="contact-card md-33 lg-33 sm-50 xl-20 xs-100 flex-33"
                 *ngFor="let contact of this.searchResults">
                <mat-card>
                    <div class="flex-row">
                        <mat-card-title>
                            {{contact.name}}
                        </mat-card-title>
                        <span class="flex"></span>
                        <div class="help-cursor flex-col start-start">
                            <mat-icon *ngIf="contact.status === CONTACT_STATUS_ENUM.Active && !contact.incomplete"
                                      class="mat-primary"
                                      title="Active">
                                check_circle
                            </mat-icon>
                            <mat-icon *ngIf="contact.status !== CONTACT_STATUS_ENUM.Active"
                                      title="Inactive">
                                radio_button_unchecked
                            </mat-icon>
                            <mat-icon *ngIf="contact.incomplete && contact.status === CONTACT_STATUS_ENUM.Active"
                                      class="mat-warn"
                                      title="Incomplete">
                                warning
                            </mat-icon>
                        </div>
                    </div>
                    <mat-card-content>
                        <div class="flex-row">
                            <div [ngClass]="{'flex-col': true, 'flex-70': contact.numLeads > 0 || contact.numAccounts, 'flex-100': !(contact.numLeads > 0 || contact.numAccounts)}">
                                <cb-display-value-new label="Address"
                                                      [value]="contact.address"></cb-display-value-new>
                                <cb-display-value-new label="Email"
                                                      [value]="contact.email"
                                                      applyEllipsis="true"></cb-display-value-new>
                                <cb-display-value-new *ngIf="contact.preferred === 'Mobile Phone'"
                                                      label="Mobile Phone"
                                                      [value]="contact.preferredContactDetails"></cb-display-value-new>
                            </div>
                            <span class="flex"></span>
                            <div class="flex-col">
                                <button *ngIf="contact.numLeads > 0"
                                        mat-button
                                        style="text-align:left"
                                        class="cb-margin-bottom"
                                        (click)="viewLeads(contact, $event)">
                                    Leads: {{contact.numLeads}}
                                </button>
                                <button *ngIf="contact.numAccounts > 0"
                                        mat-button
                                        style="text-align:left"
                                        (click)="viewAccounts(contact, $event)">
                                    Accounts: {{contact.numAccounts}}
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-divider [inset]="true">
                    </mat-divider>
                    <mat-card-actions class="center-end layoutGap-16">
                        <span class="flex"></span>
                        <button mat-stroked-button
                                [disabled]="contact.status !== CONTACT_STATUS_ENUM.Active"
                                (click)="createLead(contact)">
                            Create Lead
                        </button>
                        <button mat-stroked-button
                                color="accent"
                                (click)="viewContact(contact, $event)">
                            <mat-icon class="material-icons">
                                pageview
                            </mat-icon>
                            &nbsp;View
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</form>
