import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

export class ExportCsvUtil<ValueType>{

    private readonly _defaultExportFileName = 'export.csv';
    private readonly _waitForDownloadMs = 100;

    constructor() {
    }

    /*
    This method will allow you to transform a MATTable into a CSV file.
    You're able to specify which columns to be displayed as well as provide custom formatters that allows you to
    control how the value in each column is displayed (if you need to perform operations etc.)
     */
    public downloadFromMatTable(
        matTable: MatTable<ValueType>,
        displayedColumns: { id: string; displayValue: string }[],
        customColumnFormatters: { [columnName: string]: (row: ValueType) => string },
        fileName = undefined
    ): boolean {

        if (!fileName){
            fileName = this._defaultExportFileName;
        }

        const data = this.getMatTableDataSource(matTable);
        if (!data)
        {
            return false;
        }
        let csvContent = this.addHeaderColumns(displayedColumns);

        csvContent += this.addDataContent(data, displayedColumns, customColumnFormatters);
        this.saveCsvFile(csvContent, fileName);

        return true;
    }

    private saveCsvFile(csvContent: string, fileName: string): void {
        const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'});
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, this._waitForDownloadMs);
    }

    private addDataContent(data: ValueType[],
                           exportedColumns: { id: string; displayValue: string }[],
                           customColumns: { [columnName: string]: (row: any) => string }): string
    {
        let csvContent = '';

        // Add data rows
        data.forEach((row, rowIndex) => {
            exportedColumns.forEach((column, columnIndex) => {
                let cellText: string;

                if (customColumns[column.id]) {
                    // If a custom function is provided for the column, use it
                    cellText = customColumns[column.id](row);
                }else{
                    cellText = row[column.id] ? String(row[column.id]).replace(/"/g, '""') : '';
                }

                csvContent += `"${cellText}"`;
                csvContent += columnIndex < exportedColumns.length - 1 ? ',' : '';
            });
            csvContent += rowIndex < data.length - 1 ? '\r\n' : '';
        });
        return csvContent;
    }

    private addHeaderColumns(exportedColumns: { id: string; displayValue: string }[]): string {
        let csvContent = '';

        // Add header row
        exportedColumns.forEach((column, columnIndex) => {
            csvContent += `"${column.displayValue}"`;
            csvContent += columnIndex < exportedColumns.length - 1 ? ',' : '';
        });
        return csvContent += '\r\n';
    }

    private getMatTableDataSource(matTable: MatTable<ValueType>): ValueType[] {
        const dataSource = matTable.dataSource;

        if (dataSource instanceof MatTableDataSource) {
            return dataSource.data;
        } else if (Array.isArray(dataSource)) {
            return dataSource;
        } else {
            console.error('Unsupported data source type');
            return null;
        }
    }

}
