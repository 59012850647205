import { Component, Input } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { INotificationDto, NotificationLogicService } from '@app/logic/notification';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { NOTIFICATION_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { forEach, some } from 'lodash';
import { map, Observable, Subject } from 'rxjs';
import { getPrimaryEntityType, simplifySecondaryEntityType } from '../app-root/components/notifications-dialog/notification-routes';

@Component({
    selector: 'cb-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
    @Input() public isPopUp: boolean;
    @Input() public showUnreadOnly: boolean;

    public hasUnread = false;
    public searchText = '';
    public currentPage: number;
    public searchEnabled = false;
    public results: (ISearchResult<INotificationDto>)[] = [];

    public readonly queryUpdate = new Subject();

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly navigationService: NavigationService,
        private readonly notificationLogicService: NotificationLogicService,
    ) {
    }

    public ngOnInit(): void {
        this.searchEnabled = true;
        this.queryUpdated();
    }

    public readonly queryUpdated = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.queryUpdate.next(null);
    };

    public fetchResults(): Observable<ISearchResult<INotificationDto>> {
        if (!this.searchEnabled) {
            return;
        }
        return this.notificationLogicService.getNotifications(this.currentPage, this.searchText).pipe(map(data => {
            this.hasUnread = some(data as unknown as INotificationDto[], (item: INotificationDto) => !item.isRead);
            return data;
        }));
    }

    public goToItem(notification: INotificationDto): void {
        let url = '/';
        if (!notification.primaryEntityType) {
            return;
        }
        url = getPrimaryEntityType(notification as any);

        if (notification.secondaryEntityType) {
            url += simplifySecondaryEntityType(notification as any);
        }

        this.notificationLogicService.markAsRead(notification.id).subOnce(() => {
            this.navigationService.navigate([url], undefined, {
                queryParams: { paramEntityId: notification.secondaryEntityId, qnsId: notification.tertiaryEntityId }
            });
        });
    }

    public markAsRead(notification: INotificationDto): void {
        this.notificationLogicService.markAsRead(notification.id).subOnce(savedNotification => {
            forEach(this.results, (item: INotificationDto) => {
                if (item.id === savedNotification.id) {
                    item.isRead = true;
                }
            });
        });
    }

    public markAllAsRead(): void {
        this.cbDialog.confirm({
            dialogHeading: '',
            message: 'Are you sure you want to Mark All as Read?',
            confirmed: () => {
                this.notificationLogicService.markAllAsRead().subOnce(savedNotifications => {
                    this.hasUnread = false;
                    forEach(this.results, (item: INotificationDto) => {
                        savedNotifications.forEach(savedNotification => {
                            if (item.id === savedNotification.id) {
                                item.isRead = true;
                            }
                        });
                    });
                });
            }
        });
    }

    public getNotifcationIcon(notification: INotificationDto): string {
        switch (notification.notificationType) {
            default:
            case NOTIFICATION_TYPE_ENUM.Alert:
                return 'alarm';
            case NOTIFICATION_TYPE_ENUM.Info:
                return 'info_outline';
            case NOTIFICATION_TYPE_ENUM.Action:
                return 'update';
        }
    }
}
