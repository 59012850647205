<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-col flex">
    <mat-card class="flex-col flex-100">
        <div class="flex-col flex-100">
            <div class="flex-col">
                <div class="flex-row">
                    <h3 class="mat-title flex"
                        style="margin: 0;">Qualified Leads
                    </h3>
                </div>
                <mat-divider [inset]="true"
                             class="cb-margin-bottom"> </mat-divider>
            </div>
            <div class="cb-table cb-margin-top">
                <mat-table [dataSource]="accountLeads">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{LEAD_STATUS_ENUM[element.status]}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="createdDate">
                        <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.createdDate  | date }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="noContacts">
                        <mat-header-cell *matHeaderCellDef> No. Contacts </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.noContacts }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="mainContact">
                        <mat-header-cell *matHeaderCellDef> Main Contact </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.mainContact}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lotInterests">
                        <mat-header-cell *matHeaderCellDef> Interests </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.lotInterests}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="buildingConsultant">
                        <mat-header-cell *matHeaderCellDef> Building Consultant </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.buildingConsultant}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lastCallBackDate">
                        <mat-header-cell *matHeaderCellDef> Last Callback </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.lastCallBackDate}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lostDate">
                        <mat-header-cell *matHeaderCellDef> Date Lost </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.lostDate  | date}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions"
                                  stickyEnd>
                        <mat-header-cell *matHeaderCellDef>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <button mat-icon-button
                                    (click)="viewLead(element.id, $event)">
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="cb-margin"
                     *ngIf="accountLeadsLoaded && accountLeads.length === 0">
                    <cb-info-message [message]="'This Client Account has no Qualified Leads'"
                                     [icon]="'info_circle'"></cb-info-message>
                </div>
                <cb-loading-spinner *ngIf="!accountLeadsLoaded"></cb-loading-spinner>
            </div>
        </div>
    </mat-card>
</div>
