export enum ClientAccountPermissionEnum {
    None = 0x00,

    CanSearch = 0x01 << 0,
    CanView = 0x01 << 1,
    CanCreate = 0x01 << 2,
    CanEdit = 0x01 << 3,

    NoteCanView = 0x01 << 4,
    NoteCanAdd = 0x01 << 5,
    NoteCanEdit = 0x01 << 6,
    NoteCanDelete = 0x01 << 7,

    HistoryCanView = 0x01 << 8,

    ContactCanAdd = 0x01 << 9,
    ContactCanRemove = 0x01 << 10,
    MainContactCanChange = 0x01 << 11,

    ClientSalesCanView = 0x01 << 12,
    LeadsCanView = 0x01 << 13,
    ProjectsCanView = 0x01 << 14,
}
