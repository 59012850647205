import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PermissionsPermissions } from '@app/core/permissions';
import { IDesignSchemeNotificationStageMappedItem } from '@app/logic/design-scheme-notification-stages';
import { DesignSchemeNotificationStageLogicService } from '@app/logic/design-scheme-notification-stages/design-scheme-notification-stage.logic.service';
import { DesignSchemeNotificationStageMappedItem } from '@app/logic/design-scheme-notification-stages/design-scheme-notification-stage.mapped';
import { IDesignSchemeNotificationStageDto } from '@app/logic/design-scheme-notification-stages/interfaces/i.design-scheme-notification-stage.dto';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { DesignSchemeNotificationsDialogComponent } from '../design-scheme-notifications-dialog/design-scheme-notifications-dialog.component';

@Component({
    selector: 'cb-design-scheme-notifications',
    templateUrl: './design-scheme-notifications.component.html',
    styleUrls: ['./design-scheme-notifications.component.scss']
})
export class DesignSchemeNotificationsComponent extends BaseSimpleListViewDirective<IDesignSchemeNotificationStageDto, DesignSchemeNotificationStageLogicService> {
    public readonly columns = [
        'Revision Number',
        'Is Active',
        ''
    ];

    constructor(
        protected readonly designSchemeNotificationStageLogicService: DesignSchemeNotificationStageLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly cbDialog: CbDialogService,
    ) {
        super(
            cbDialog,
            designSchemeNotificationStageLogicService,
            DesignSchemeNotificationsDialogComponent,
            'Design Scheme Notification Stage',
            DesignSchemeNotificationStageMappedItem,
            permissionsPermissions
        );
    }

    // overriding default dialog due to minWidth
    public openDialog(mappedItem: IDesignSchemeNotificationStageMappedItem, dialogHeading: string): MatDialogRef<DesignSchemeNotificationsDialogComponent> {
        return this.cbDialog.open(DesignSchemeNotificationsDialogComponent, {
            minWidth: '25%',
            data: {
                dialogHeading,
                mappedItem,
                ...(this.otherData && { otherData: this.otherData }),
            },
        });
    }
}
