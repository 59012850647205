<cb-dialog dialogHeading="Reject Design Scheme">
    <form class="flex-col"
          #rejectDesignSchemeForm="ngForm">
        <cb-text-area label="Reason for Rejection"
                      name="reasonRejection"
                      [(ngModel)]="mappedItem.rejectionNotes"
                      [maxlength]="1024"
                      [required]="false"></cb-text-area>
        <cb-display-value-new class="flex-col"
                              *ngIf="!data.hideRejectionOptions"
                              label="Status">
            <mat-radio-group class="flex-col"
                             [(ngModel)]="reasonType"
                             [required]="true"
                             name="reasonType">
                <mat-radio-button [value]="DESIGN_SCHEME_STATUS_ENUM.InQueueForRework">Scheme doesn't meet requirements
                    specified in Changes and needs reworking</mat-radio-button>
                <mat-radio-button [value]="DESIGN_SCHEME_STATUS_ENUM.Declined">Scheme is not going to proceed any
                    further
                </mat-radio-button>
            </mat-radio-group>
        </cb-display-value-new>
    </form>
    <footer class="end-center">
        <button color="primary"
                mat-raised-button
                (cbClick)="rejectDesignScheme()"
                [disabled]="isSaveDisabled(rejectDesignSchemeForm)">Confirm</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
