import { Injectable } from '@angular/core';
import { LotsLogicService } from '@app/logic/lots';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LotChangesVariationsDataService {

    constructor(private readonly lotsLogicService: LotsLogicService,) { }

    private readonly _count: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public setnumberofUnassignedChangeRecordsCount(value: number): void {
        this._count.next(value);
    }

    public getnumberofUnassignedChangeRecordsCount(): Observable<number> {
        return this._count;
    }

    public loadUnassignedChangeRecordsCount = (lotId: number): void => {
        if (lotId > 0) {
            this.lotsLogicService.getUnassignedChangeRecordsForVariationCount(lotId).subOnce((count) => {
                this.setnumberofUnassignedChangeRecordsCount(count);
            });
        } else {
            this.setnumberofUnassignedChangeRecordsCount(0);

        }
    };
}
