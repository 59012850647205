import { ClassCtor } from '@app/shared/types/classctor.type';
import { APP_INITIALIZER, Provider } from '@angular/core';

/** factory for initOnStartupProvider  */
export function _initOnStartupFactory(...args: any[]): () => Promise<boolean> {
    return () => Promise.resolve(true);
}

/** Initialises a service on app statup \
 *  Services provided using this must have @Injectable({ provided: 'root' }) configuration
 */
export function initOnStartupProvider(service: ClassCtor): Provider {
    return { provide: APP_INITIALIZER, multi: true, deps: [service], useFactory: _initOnStartupFactory };
}
