import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IBuildProgrammeTemplateDto, IBuildTemplateStageDto } from './interfaces/i.build-programme-template.dto';
import { IBuildProgrammeTemplateLogicService } from './interfaces/i.build-programme-template.logic.service';
import { IBuildProgrammeTemplateMappedItem } from './interfaces/i.build-programme-template.mapped';

export class BuildProgrammeTemplateMappedItem
    extends BaseMappedItem<IBuildProgrammeTemplateDto, IBuildProgrammeTemplateMappedItem, IBuildProgrammeTemplateLogicService>
    implements IBuildProgrammeTemplateMappedItem {
    @DtoProp public id: number;
    @DtoProp public name: string;
    @DtoProp public isActive: boolean;
    @DtoProp public createdByName: string;
    @DtoProp public createdDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public regions: number[];
    @DtoProp public stages: IBuildTemplateStageDto[];
    @DtoProp public buildProgrammeTemplateId?: number;

    constructor(
        sourceData: IBuildProgrammeTemplateDto,
        logicService: IBuildProgrammeTemplateLogicService
    ) {
        super(
            sourceData,
            logicService,
            BuildProgrammeTemplateMappedItem
        );
    }
}
