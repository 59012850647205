<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">info</mat-icon>
            <b>Lot Info</b>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex-col">
        <cb-display-value-new label="Address">
            <div class="flex-col">
                <span>
                    {{lotMappedItem?.lotAddress?.street | cbDasher}}</span>
                <span>
                    {{lotMappedItem?.lotAddress?.suburb | cbDasher}}</span>
                <span>
                    {{lotMappedItem?.lotAddress?.postCode | cbDasher}}</span>
                <span>
                    {{lotMappedItem?.lotAddress?.city | cbDasher}}</span>
                <span>
                    {{lotMappedItem?.lotAddress?.regionName | cbDasher}}</span>
            </div>
        </cb-display-value-new>
        <cb-display-value-new label="Location">
            {{lotMappedItem?.location.path.join(' > ') | cbDasher}}
        </cb-display-value-new>
        <div class="flex-wrap flex">
            <cb-display-value-new class="flex-50"
                                  label="Business Entity">
                {{lotMappedItem?.businessEntity.label | cbDasher}}
            </cb-display-value-new>
            <ng-container *ngIf="shouldShowComponent(LOT_COMPONENT_TYPE.InformationDetails)">
                <cb-display-value-new class="flex-50"
                                      *ngIf="lotTypeField.isVisible"
                                      label="Lot Type">
                    {{lotMappedItem?.lotType | cbEnumToLabel: LOT_TYPE_ENUM | cbDasher}}
                </cb-display-value-new>
                <cb-display-value-new class="flex-50"
                                      *ngIf="lotNumberField.isVisible"
                                      label="Lot Number">
                    {{lotMappedItem?.lotNumber | cbDasher}}
                </cb-display-value-new>
                <cb-display-value-new class="flex-50"
                                      *ngIf="dpNumberField.isVisible"
                                      label="Dp Number">
                    {{lotMappedItem?.dpNumber | cbDasher}}
                </cb-display-value-new>
                <cb-display-value-new class="flex-50"
                                      *ngIf="landTitleField.isVisible && lotMappedItem?.landTitleStatus"
                                      label="Land Title Status">
                    {{lotMappedItem?.landTitleStatus | cbEnumToLabel: LAND_TITLE_STATUS_ENUM | cbDasher}}
                </cb-display-value-new>
                <cb-display-value-new class="flex-50"
                                      *ngIf="expectedTitleDateField.isVisible"
                                      label="Expected Title Date">
                    {{lotMappedItem?.expectedTitleDate | date: 'dd MMM yyyy' | cbDasher}}
                </cb-display-value-new>
                <cb-display-value-new class="flex-50"
                                      *ngIf="developerProjectNameField.isVisible"
                                      label="Developer Project">
                    {{lotMappedItem?.project?.label | cbDasher}}
                </cb-display-value-new>
                <cb-display-value-new class="flex-50"
                                      *ngIf="roadDirectionField.isVisible"
                                      label="Road Direction">
                    {{lotMappedItem?.roadDirection | cbEnumToLabel: COMPASS_DIRECTION_TYPE_ENUM | cbDasher}}
                </cb-display-value-new>
                <div class="cb-checkbox-list flex-col">
                    <cb-checkbox *ngIf="clientOwnsLandField.isVisible"
                                 label="Client Owns Land"
                                 name="isOwned"
                                 [(ngModel)]="lotMappedItem.isOwned"></cb-checkbox>
                    <cb-checkbox *ngIf="isShowHomeField.isVisible"
                                 label="Is Showhome"
                                 name="isShowHome"
                                 [(ngModel)]="lotMappedItem.isShowHome"></cb-checkbox>
                </div>
            </ng-container>
        </div>
    </div>
</mat-expansion-panel>
