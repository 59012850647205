<cb-dialog>
    <form class="flex-col flex"
          #tagForm="ngForm">
        <cb-input type="text"
                  name="name"
                  label="Tag Name"
                  maxlength="100"
                  [required]="true"
                  [(ngModel)]="mappedItem.name">
        </cb-input>
        <cb-display-value-new label="Key"
                              style="color: rgba(0, 0, 0, 0.54)"
                              *ngIf="isEdit()"
                              [value]="mappedItem.key">
        </cb-display-value-new>
        <cb-checkbox name="isSystemTag"
                     label="Is System Tag"
                     [disabled]="isSystemTag"
                     [(ngModel)]="mappedItem.isSystemTag"></cb-checkbox>
        <cb-checkbox label="Is Active"
                     name="isActive"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
        <footer class="cb-margin-top-2x end-center flex-row">
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (click)="save()"
                    [disabled]="!tagForm.dirty || !tagForm.valid">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel()">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
