import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ITaskOutcomeDto } from '../task-outcomes/interfaces/i.task-outcome.dto';
import { ICloseTaskDto } from './interfaces/i.close-task.dto';
import { ITaskDto } from './interfaces/i.Task.dto';
import { ITaskMappedItem } from './interfaces/i.task.mapped';
import { ITasksLogicService } from './interfaces/i.tasks.logic.service';
import { TaskMappedItem } from './task.mapped';

@Injectable()
export class TasksLogicService
    extends BaseLogicService<ITaskDto, ITaskMappedItem>
    implements ITasksLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'tasks',
            TaskMappedItem
        );
    }

    public createMappedTaskItem(entityUri: string, entityId: number | string, task?: ITaskDto): ITaskMappedItem {
        const result = this.$createMappedItem(task);
        result.entityUri = entityUri;
        result.entityId = entityId;
        return result;
    }

    public getMappedTasks(entityUri: string, entityId: number | string): Observable<ITaskMappedItem[]> {
        return this.$http.get<ITaskDto[]>(`${entityUri}/${entityId}/${this.$baseUri}`)
            .pipe(
                map(results => results.map(value => this.createMappedTaskItem(entityUri, entityId, value)))
            );
    }

    public getTasksForTeam(team: 'qsteam' | 'designteam'): Observable<ITaskDto[]> {
        return this.$http.get<ITaskDto[]>(`${this.$baseUri}/${team}`);
    }

    public saveTask(entityUri: string, entityId: number | string, task: ITaskDto): Observable<ITaskDto> {
        if (task.id > 0) {
            return this.$http.post(`${entityUri}/${entityId}/${this.$baseUri}/${task.id}`, task);
        }
        return this.$http.post(`${entityUri}/${entityId}/${this.$baseUri}`, task);
    }

    public closeTask(entityUri: string, entityId: number | string, closeTaskDto: ICloseTaskDto): Observable<ICloseTaskDto> {
        return this.$http.post(`${entityUri}/${entityId}/${this.$baseUri}/${closeTaskDto.closed.id}/close`, closeTaskDto);
    }

    public getPossibleTaskOutcomes(taskId: number): Observable<ITaskOutcomeDto[]> {
        return this.$http
            .get<ITaskOutcomeDto[]>(`${this.$baseUri}/${taskId}/possibleoutcomes`);
    }
}
