import { Injectable, Injector } from '@angular/core';

import { IARVariationDto, IDocumentEntityDto, IDocumentUploadDto, IVariationDto, IVariationLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IVariationLogicService } from './interfaces/i.variation.logic-service';
import { IVariationMappedItem } from './interfaces/i.variation.mapped';
import { VariationMappedItem } from './variation.mapped';

@Injectable()
export class VariationLogicService
    extends BaseLogicService<IVariationDto, IVariationMappedItem>
    implements IVariationLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'variations',
            VariationMappedItem
        );
    }

    public getByLot(lotId: number): Observable<IVariationMappedItem[]> {
        return this.$http.get<IVariationDto[]>(`lots/${lotId}/${this.$baseUri}`)
            .pipe(
                map(items => items.map(x => this.$createMappedItem(x)))
            );
    }

    public getAcceptedByLot(lotId: number): Observable<IARVariationDto[]> {
        return this.$http.get<IARVariationDto[]>(`lots/${lotId}/${this.$baseUri}/accepted`);
    }

    // To Do change API endpoint to take id from the object itself the we can get rid of this and use $save();
    public $saveCreate(payload: IVariationDto): Observable<IVariationDto> {
        return this.$http.post<IVariationDto>(`${this.$baseUri}/0`, payload);
    }


    public addVariationlines(variationId: number, changeRecordsIds: number[]): Observable<IVariationDto> {
        return this.$http.post<IVariationDto>(`${this.$baseUri}/${variationId}/addvariationlines`, changeRecordsIds);
    }

    public removeVariationlines(variationId: number, variationLineIds: number[]): Observable<IVariationDto> {
        return this.$http.post<IVariationDto>(`${this.$baseUri}/${variationId}/removevariationlines`, variationLineIds);
    }

    public updateVariationLineSortOrder(variationId: number, variationLines: IVariationLineDto[]): Observable<boolean> {
        return this.$http.post<boolean>(`${this.$baseUri}/${variationId}/sortvariationlines`, variationLines);
    }

    public generateApprovalDocument(variationId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/GetVariationAcceptanceTemplate/${variationId}`, 'Generating Approval Document...');
    }

    public generateSignedApprovalDocument(variationId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/getassignedapprovaldocument/${variationId}`, 'Generating Signed Approval Document...');
    }

    public reuploadApprovedApprovalDocumenty(entityId: number, uploadedDocument: IDocumentUploadDto): Observable<IDocumentEntityDto> {

        const formData = new FormData();
        formData.append('file', uploadedDocument.file);
        formData.append('documentGroupId', uploadedDocument.documentGroupId as any);
        formData.append('documentTypeId', uploadedDocument.documentTypeId as any);
        formData.append('documentEntityId', uploadedDocument.documentEntityId as any);
        formData.append('name', uploadedDocument.name);

        if (!isNullOrWhiteSpace(uploadedDocument.description)) {
            formData.append('description', uploadedDocument.description);
        }
        if (uploadedDocument.renewalDate) {
            formData.append('renewalDate', uploadedDocument.renewalDate);
        }
        if (uploadedDocument?.reviewStatus !== null
            && uploadedDocument?.reviewStatus !== undefined) {
            formData.append('reviewStatus', uploadedDocument?.reviewStatus as any);
        }
        if (uploadedDocument?.reviewComments !== null
            && uploadedDocument?.reviewComments !== undefined) {
            formData.append('reviewComments', uploadedDocument?.reviewComments as any);
        }
        return this.$http
            .post(`variation/${entityId}/reuploadapproved`, formData);

    }
}
