import { IComplianceRegistrationDto } from './../compliance-registration/interfaces/i.compliance-registration.dto';
import { ITradeTypeMappedItem } from './interfaces/i.trade-type.mapped';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ITradeTypeDto } from './interfaces/i.trade-type.dto';
import { ITradeTypesLogicService } from './interfaces/i.trade-types.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';

export class TradeTypeMappedItem
    extends BaseMappedItem<ITradeTypeDto, ITradeTypeMappedItem, ITradeTypesLogicService>
    implements ITradeTypeMappedItem {
    @DtoProp public canRequestSevenDayPayments: boolean;
    @DtoProp public includeInLiveSchedule: boolean;
    @DtoProp public id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public label: string;
    @DtoProp public parentId: number;
    @DtoProp public parentLabel: string;
    @DtoProp public requiredComplianceRegistrations: IComplianceRegistrationDto[];

    constructor(
        sourceData: ITradeTypeDto,
        logicService: ITradeTypesLogicService
    ) {
        super(sourceData, logicService, TradeTypeMappedItem, {
            requiredComplianceRegistrations: [],
            isActive: true,
            canRequestSevenDayPayments: false,
            includeInLiveSchedule: false,
        });
    }
}
