export enum LotCostPermissionEnum {
    None = 0x00,
    LotCanViewCostSummary = 0x01 << 0,
    LotCanViewPriceRevisions = 0x01 << 1,
    LotCanViewTakeoffs = 0x01 << 2,
    LotCanViewConfirmedQuantities = 0x01 << 3,
    LotCanViewActualCosts = 0x01 << 4,
    LotCanViewPricingRevision = 0x01 << 5,
    LotCanEditPricingRevision = 0x01 << 6,
    LotCanChangePricingRevisionStatus = 0x01 << 7,
    LotCanCreateTakeoff = 0x01 << 8,
    LotCanManageTakoff = 0x01 << 9,
    CanViewEditPricingRevisionMargins = 0x01 << 10
}
