<button mat-mini-fab
        color="accent"
        class="collapse-button"
        (click)="collapsed = !collapsed"
        [matTooltip]="collapsed ? 'Expand' : 'Collapse'">
    <mat-icon>{{ collapsed ? 'chevron_right' : 'chevron_left '}}</mat-icon>
</button>
<mat-card *ngIf="collapsed"
          class="collapsed-card">
    <span class="mat-title collapsed-title">My Client Sales</span>
</mat-card>
<ng-container *ngIf="!collapsed">
    <mat-card class="filter-card flex-col">
        <div class="flex-row">
            <span class="mat-title flex">My Client Sales</span>
            <button mat-icon-button
                    *ngIf="permissions.canCreate()"
                    (click)="createClientSale()"
                    matTooltip="Create Client Sale">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <form class="start-center flex-row"
              #searchForm="ngForm">
            <cb-input class="cb-margin-right flex"
                      label="Search"
                      name="search"
                      [(ngModel)]="userCacheItem.data.query"></cb-input>
            <button mat-raised-button
                    [matMenuTriggerFor]="actionsMenu">
                <mat-icon svgIcon="dots-horizontal"></mat-icon>
                Filter
            </button>
            <mat-menu #actionsMenu>
                <div class="cb-margin-2x flex-col">
                    <cb-selection-list label="Show my client sales that are"
                                       name="status"
                                       [(ngModel)]="userCacheItem.data.jobStatus"
                                       [options]="clientSaleStatuses"
                                       (click)="$event.stopPropagation()"></cb-selection-list>
                    <cb-select label="Order Client Sales by"
                               name="order"
                               [(ngModel)]="userCacheItem.data.order"
                               [options]="orders"
                               (click)="$event.stopPropagation()"></cb-select>
                </div>
            </mat-menu>
        </form>
    </mat-card>
    <mat-card class="list-card scroll-container flex-col"
              [cbInfiniteScroll]="listContainer"
              [fetch]="fetchResults()"
              [queryUpdated]="queryUpdate"
              [enabled]="searchEnabled"
              [(results)]="results"
              [(currentPage)]="currentPage">
        <cb-info-message *ngIf="results.length < 1"
                         message="There are no Client Sales available to view"
                         iconClass="cb-accent"></cb-info-message>
        <div class="list-container flex"
             #listContainer>
            <mat-list class="flex flex-col">
                <mat-list-item *ngFor="let clientSale of results"
                               [ngClass]="{'selected-client-sale': currentClientSaleId === clientSale.id, 'client-sale-list-item': true}">
                    <div class="flex-row flex">
                        <div class="flex-col flex">
                            <h3 matLine
                                class="mat-body-2 item-line"> {{clientSale.clientAccountName}} </h3>
                            <p matLine
                               class="item-line"> {{LOT_CONTRACT_TYPE_ENUM[clientSale.contractType]}} </p>
                            <p matLine
                               class="item-line">
                                {{clientSale.lotJobNumber}} - {{clientSale.statusDisplay}}
                            </p>
                            <p matLine
                               class="item-line"> Last activity {{clientSale.updatedDate | date}}</p>
                        </div>
                        <div class="center-center flex-col">
                            <button mat-icon-button
                                    (click)="loadClientSale(clientSale.id)"
                                    [disabled]="currentClientSaleId === clientSale.id">
                                <mat-icon>exit_to_app</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </mat-card>
</ng-container>
