import { Component } from '@angular/core';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { ICategoryMappedItem } from '@app/logic/categories/interfaces/i.category.mapped';
import { IStandardPlanCategoryDto} from '@classictechsolutions/hubapi-transpiled-enums';
import { PermissionsPermissions } from '@app/core/permissions';
import { CategoriesLogicService } from '@app/logic/categories/category.logic.service';
import { CategoryMappedItem } from '@app/logic/categories/category.mapped';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
    providers: [CategoriesLogicService]
})
export class CategoriesComponent extends BaseSimpleListViewDirective<IStandardPlanCategoryDto, CategoriesLogicService> {

    public categories: ICategoryMappedItem[] = [];
    public categoriesLoaded = false;

    public readonly displayedColumns: string[] = ['id', 'name', 'isActive', 'actions'];

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        private readonly categoriesLogicService: CategoriesLogicService,
    ) {
        super(
            dialog,
            categoriesLogicService,
            CategoryDialogComponent,
            'Categories',
            CategoryMappedItem,
            permissionsPermissions
        );
    }
}
