import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ChangeOptionLineLogicService } from '@app/logic/change-option-line/change-option-line.logic.service';
import { IQuoteLineMappedItem, IQuoteLineLogicService, IQuoteMappedItem } from '@app/logic/quote';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import {
    CostNatureEnumId,
    COST_NATURE_ENUM,
    COST_TYPE_ENUM,
    IIdAndNameDto,
    IQuoteLineDto,
    ISkinnyOfferingDto,
    ISlotDto,
    ISpecGroupDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { filter } from 'lodash';

interface IData {
    quoteLineDto: IQuoteLineDto;
    houseAreas: ISpecGroupDto[];
    quoteMappedItem: IQuoteMappedItem;
    excludedSlotIds: number[];
    formMode: FormMode;
}

@Component({
    selector: 'app-lot-quote-line-dialog',
    templateUrl: './lot-quote-line-dialog.component.html',
    styleUrls: ['./lot-quote-line-dialog.component.scss']
})
export class LotQuoteLineDialogComponent extends BaseDialogFormViewDirective<IQuoteLineDto, IQuoteLineMappedItem, IQuoteLineLogicService>  {
    public static readonly MIN_WIDTH = '750px';

    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public costNatures: IEnumLookup<CostNatureEnumId>[] = [];

    public readonly houseAreas: ISpecGroupDto[];
    public quoteLineDto: IQuoteLineDto;
    public quoteMappedItem: IQuoteMappedItem;
    public selectedSlot: ISlotDto;
    public excludedSlotIds: number[] = [];
    public currentLotSpecItem: string;

    constructor(
        public readonly dialogRef: MatDialogRef<LotQuoteLineDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ChangeOptionLineLogicService) public readonly changeOptionLineLogicService: ChangeOptionLineLogicService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.quoteLineDto = data.quoteLineDto;
        this.quoteMappedItem = data.quoteMappedItem;
        this.houseAreas = data.houseAreas;
        this.excludedSlotIds = this.data.excludedSlotIds;
        this.formMode$.next(this.data.formMode);
    }

    public ngOnInit(): void {
        this.costNatures = COST_NATURE_ENUM.toLookup({ transform: this.handleCostNatures });

        if (this.quoteMappedItem.isScheduleSpecTemplate) {
            this.getProductForSelectedSlot(this.quoteLineDto?.slot?.id);
            this.selectedSlot = this.quoteLineDto?.slot as ISlotDto;

            if (this.isAdd$$) {
                this.quoteLineDto.includeInColourSchedule = true;
                this.quoteLineDto.includeInCreateSchedule = true;
            }
        }
    }

    public slotSelected(slot: ISlotDto): void {
        if (slot) {
            this.selectedSlot = slot;
            this.quoteLineDto.slot = { id: slot.id, name: slot.name } as IIdAndNameDto;
            this.getProductForSelectedSlot(slot.id);
        }
    }

    public specGroupSelected(specGroupId: number): void {
        if (specGroupId > 0) {
            this.quoteLineDto.specGroupLabel = this.houseAreas.find(x => x.id === specGroupId)?.label;
        }
    }


    public getProductForSelectedSlot(slotId: number): void {
        if (this.quoteMappedItem.lotId && slotId) {
            this.changeOptionLineLogicService.getExistingScheduleItem(this.quoteMappedItem.lotId, slotId).subOnce(changeOptionLine => {
                if (changeOptionLine != null) {
                    this.currentLotSpecItem = changeOptionLine?.itemDetails;
                }
            });
        }
    }

    public unallocatedAmount(): number {
        const sum = this.quoteMappedItem.quoteLines.reduce(
            (a, b) => {
                return b.isRemoved ? Number(a) : Number(a) - (Number(b.allocatedAmount) || 0);
            },
            this.quoteMappedItem.quoteTotal);

        return Number(sum.toFixed(2));
    }

    private readonly handleCostNatures = (costNatures: IEnumLookup<CostNatureEnumId>[]): IEnumLookup<CostNatureEnumId>[] => {
        if (this.quoteMappedItem.lotHasClientSale && !this.quoteMappedItem.lotHasBuildProgramme) {
            costNatures = filter(costNatures, cn => cn.id !== COST_NATURE_ENUM.ExtraToSupplier);
        } else if (!this.quoteMappedItem.lotHasClientSale && !this.quoteMappedItem.lotHasBuildProgramme) {
            costNatures = filter(costNatures, cn =>
                cn.id !== COST_NATURE_ENUM.ExtraToSupplier
                && cn.id !== COST_NATURE_ENUM.ExtraToClient);
        }
        if (this.quoteLineDto?.costTypeId === COST_TYPE_ENUM.Estimate || !this.quoteMappedItem.lotHasClientSale) {
            costNatures = filter(costNatures, cn => cn.id !== COST_NATURE_ENUM.ExtraToClient);
        }
        return costNatures;
    };

    public saveItem(): void {
        // This will be saved via parent Dialog

        if (this.quoteLineDto.offering) {
            this.quoteLineDto.offeringId = this.quoteLineDto.offering.id;
        }

        this.dialogRef.close(this.quoteLineDto);
    }

    public canEditCostNature(): boolean {
        return this.quoteLineDto.costTypeId !== COST_TYPE_ENUM.Provisional
            && this.quoteLineDto.costTypeId !== COST_TYPE_ENUM.Estimate;
    }

    public getAssignedAmountTypeLabel(): string {
        if (this.quoteLineDto.costTypeId === COST_TYPE_ENUM.Provisional) {
            return 'Assigned Amount From Provisional';
        }

        if (this.quoteLineDto.costTypeId === COST_TYPE_ENUM.Estimate) {
            return 'Assigned Amount From Estimate';
        }

        return '';
    }

    public getDescriptionLabel(): string {
        if (this.quoteMappedItem.isScheduleSpecTemplate) {
            return 'Description - Appears on Specification';
        } else {
            return 'Description - Overrides the Product Catalogue Item description and appears on Purchase Order and SSR lines';

        }
    }

    public productSelected = (product): void => {
        this.quoteLineDto.offering = product as ISkinnyOfferingDto;
    };

}
