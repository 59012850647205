import * as _moment from 'moment';

import { Component } from '@angular/core';
import { RevisionReportLogicService } from '@app/logic/revision-report/revision-report.logic.service';

@Component({
    selector: 'cb-revision-report',
    templateUrl: './revision-report.component.html',
    styleUrls: ['./revision-report.component.scss'],
    providers: [RevisionReportLogicService]
})
export class RevisionReportComponent {

    public date;
    constructor(public readonly revisionReportLogicService: RevisionReportLogicService) { }

    public exportToCsv(): void {
        const dateMoment = _moment(this.date);
        this.revisionReportLogicService.exportToCsv(dateMoment.year(), dateMoment.month()).subOnce();
    }
}

