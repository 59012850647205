<div class="flex-row">
    <form class="flex flex-display"
          #lotSearchForm="ngForm">
        <mat-card class="flex-row centered-content layoutGap-20 flex xs-md-column flex">
            <div class="flex-row start-center flex search-filter">
                <mat-form-field class="flex flex-display mg-20">
                    <input #searchQuery
                           matInput
                           type="text"
                           name="search"
                           [ngModel]="searchData.query"
                           (keydown.enter)="onEnter($event, searchQuery.value)"
                           placeholder="Type in Lot or Project Details and Press Enter to Search"
                           [disabled]="fetchInProgress"/>
                </mat-form-field>
                <button mat-icon-button
                        [disabled]="fetchInProgress"
                        (click)="onEnter($event, searchQuery.value)">
                    <mat-icon>
                        search
                    </mat-icon>
                </button>
            </div>
            <cb-select label="Filter By"
                       name="lotFilterType"
                       class="flex"
                       [(ngModel)]="searchData.lotFieldToSearch"
                       (ngModelChange)="searchQueryUpdate$.next($event)"
                       [options]="lotSearchFields"
                       [disabled]="fetchInProgress">
            </cb-select>
            <cb-select label="Lot Type"
                       name="lotTypes"
                       class="flex"
                       [(ngModel)]="searchData.lotType"
                       (ngModelChange)="searchQueryUpdate$.next($event)"
                       [options]="lotTypes"
                       [disabled]="fetchInProgress">
            </cb-select>
            <cb-select-list class="flex"
                            label="Lot Status"
                            [allOptions]="jobStatuses"
                            [multiple]="true"
                            [value]="searchData.lotStatuses"
                            (selectChanged)="selectedJobStatusesChanged($event)"
                            [disabled]="fetchInProgress">
            </cb-select-list>
            <cb-select label="Region"
                       name="regions"
                       class="flex"
                       [(ngModel)]="searchData.locationId"
                       (ngModelChange)="searchQueryUpdate$.next($event)"
                       [options]="regions"
                       [disabled]="fetchInProgress">
            </cb-select>
            <div class="flex-row flex end-center">
                <div class="flex-row mg-20">
                    <mat-checkbox name="myLotsOnlyCheckbox"
                                  [(ngModel)]="searchData.myLotsOnly"
                                  (change)="searchQueryUpdate$.next($event);"
                                  [checked]="searchData.myLotsOnly === true"
                                  [disabled]="fetchInProgress">
                        Only My Lots
                    </mat-checkbox>
                </div>
                <div class="flex-row mg-0">
                    <button mat-icon-button
                            [matMenuTriggerFor]="popUpSearchMenu"
                            [disabled]="fetchInProgress">
                        <mat-icon>
                            filter_list
                        </mat-icon>
                    </button>
                </div>
                <mat-menu #popUpSearchMenu="matMenu">
                    <cb-lot-search-popup-filter *cbWaitFor="searchData && floorAreas"
                                                (click)="$event.stopPropagation()"
                                                [(searchData)]="searchData"
                                                [floorAreas]="floorAreas"
                                                (emitPopUpSearchEvent)="searchQueryUpdate$.next($event)">
                    </cb-lot-search-popup-filter>
                </mat-menu>
            </div>
        </mat-card>
    </form>
</div>
<div class="flex-row"
     *ngIf="filterChips && filterChips.length > 0">
    <mat-card class="flex-100">
        <mat-chip-list>
            <mat-chip *ngFor="let chip of filterChips"
                      (removed)="removeFilterChip(chip.id)"
                      [disabled]="fetchInProgress">
                {{chip.text}}
                <mat-icon matChipRemove>
                    cancel
                </mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-card>
</div>
