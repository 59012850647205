
import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DesignConceptsLogicService } from '@app/logic/design-concepts';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IDesignConceptDto, LOT_IMAGE_TYPE_ENUM, LOT_JOB_STATUS_ENUM, MARKET_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Dictionary, find, keys, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { ICustomProjectReleaseLotDesignSummaryDto } from '../../release-lots-design.component';
import { RequestSchemesDialogComponent } from '../request-schemes-dialog/request-schemes-dialog.component';

export interface IRequestConceptImagesDialogData {
    lots: ICustomProjectReleaseLotDesignSummaryDto[];
}

@Component({
    selector: 'cb-request-concept-images',
    templateUrl: './request-concept-images.component.html',
    styleUrls: ['./request-concept-images.component.scss']
})
export class RequestConceptImagesDialogComponent extends BaseDialogFormViewDirective<any, any, any> {
    public static readonly MIN_WIDTH = '1000px';

    public LOT_IMAGE_TYPE_ENUM = LOT_IMAGE_TYPE_ENUM;
    public MARKET_STATUS_ENUM = MARKET_STATUS_ENUM;
    public LOT_JOB_STATUS_ENUM = LOT_JOB_STATUS_ENUM;
    public loaded = false;
    public lots: ICustomProjectReleaseLotDesignSummaryDto[];
    public selectedLots: Dictionary<Boolean> = {};
    public selectedLotImageTypes: Dictionary<Boolean> = {
        [LOT_IMAGE_TYPE_ENUM.Exterior]: true,
        [LOT_IMAGE_TYPE_ENUM.FloorPlan]: true
    };

    constructor(
        public readonly dialogRef: MatDialogRef<RequestSchemesDialogComponent>,
        public readonly designConceptsLogicService: DesignConceptsLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IRequestConceptImagesDialogData,
        private readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
        this.lots = data.lots;
        this.loaded = true;
    }

    public canCheck(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return !!lot.activeConceptId;
    }

    public clicked = (lotId: number, form: UntypedFormGroup): void => {
        this.selectedLots[lotId] = !this.selectedLots[lotId];
        form.markAsDirty();
    };

    public saveMethod(): Observable<IDesignConceptDto[]> {
        const lotIds = this.getKeysAsNumber(this.selectedLots);
        const lotImageTypes = this.getKeysAsNumber(this.selectedLotImageTypes);
        if (lotIds.length < 1 || lotImageTypes.length < 1) {
            return;
        }
        return this.designConceptsLogicService.requestImagesByLotIds({ lotIds, lotImageTypes });
    }

    public isSaveDisabled(): boolean {
        return !find(this.selectedLots, a => a === true);
    }

    private getKeysAsNumber(dictionary: Dictionary<Boolean>): number[] {
        return keys(pickBy(dictionary, (value, key) => value)).map(a => Number(a));
    }

}
