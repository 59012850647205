<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="tooltipMessage.value">
            <mat-icon [ngClass]="cardIconClass.value">{{cardIcon.value}}</mat-icon>
        </div>
        <mat-card-title [ngClass]="cardIconClass.value">{{stepNumber}}. Contract Pricing Details</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex flex-col">
        <div class="flex-col"
             *ngIf="clientSale?.isThirdPartyHouseAndLand">
            <cb-display-value-new label="Lot Deposit"
                                  [value]="clientSale?.thirdPartyLotDeposit | cbCurrency:'addGst'">
            </cb-display-value-new>
            <cb-display-value-new label="Lot Purchase Price"
                                  [value]="clientSale?.thirdPartyLotPurchasePrice | cbCurrency:'addGst'">
            </cb-display-value-new>
            <cb-display-value-new label="Lot Vendor Name"
                                  [value]="clientSale?.thirdPartyClientAccountName"></cb-display-value-new>
        </div>
        <cb-display-value-new label="Sell Price on Applied Concept"
                              [value]="clientSale?.lot?.sellingPrice | cbCurrency:'addGst'"></cb-display-value-new>
        <cb-display-value-new label="Contract Price"
                              [value]="clientSale?.lot?.contractPrice | cbCurrency:'addGst'"></cb-display-value-new>
        <cb-display-value-new label="Payment Template"
                              [value]="clientSale?.lot?.paymentTemplateLabel"></cb-display-value-new>
        <cb-display-value-new label="Payments Include GST "
                              [value]="clientSale?.paymentsIncludeGst | cbPrettyBool"></cb-display-value-new>
        <div class="flex-col"
             *ngIf="clientSale?.isHouseAndLand">
            <cb-display-value-new label="Contract Generation Fee"
                                  [value]="clientSale?.lot?.contractGenerationFee | cbCurrency:'addGst'">
            </cb-display-value-new>
            <cb-display-value-new label="Lot Holding Deposit"
                                  [value]="clientSale?.lot?.lotHoldingDeposit | cbCurrency:'addGst'">
            </cb-display-value-new>
        </div>
        <cb-display-value-new label="Contract Manager"
                              [value]="clientSale?.contractMangerName"></cb-display-value-new>
        <div *ngIf="clientSale?.lot?.appliedConceptId == null && clientSale?.lot?.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild"
             class="cb-error">
            <mat-icon class="cb-error"
                      style="vertical-align: middle;">error</mat-icon>
            Concept needs to be applied prior to being able to set Contract Pricing Details
        </div>
    </mat-card-content>
    <mat-card-actions class="end-center flex-col flex">
        <button mat-stroked-button
                class="cb-margin"
                style="width: 100%"
                *ngIf="(!clientSale?.isThirdPartyHouseAndLand && !clientSale?.isDesignAndLand) && permissions?.canChangeContractType()"
                [disabled]="!clientSale?.canChangeContractType"
                (click)="openChangeContractTypeDialog()">Change Contract Type</button>
        <button mat-stroked-button
                style="width: 100%"
                *ngIf="permissions?.canEdit()"
                (click)="openEditDialog()"
                [disabled]="!clientSale?.canEditContractDetails"
                color="accent">
            <mat-icon>edit</mat-icon>
            &nbsp;Edit
        </button>
    </mat-card-actions>
</mat-card>
