import { LeadLogicService } from '@app/logic/leads';
import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LeadSearchService } from '@app/core/services/search/lead-search.service';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { LeadsInputFindDialogDirective } from './leads-input-find-dialog.directive';

interface IData {
    mappedItem: ILeadMappedItem;
    searchService: LeadSearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

@Component({
    selector: 'cb-leads-input-find-dialog-cards',
    templateUrl: '../../input-find-dialog-cards/input-find-dialog-cards.component.html',
    styleUrls: ['../../input-find-dialog-cards/input-find-dialog-cards.component.scss'],
    providers: [LeadLogicService]
})
export class LeadsInputFindDialogCardsComponent extends LeadsInputFindDialogDirective {
    constructor(
        public readonly dialogRef: MatDialogRef<LeadsInputFindDialogDirective>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public logicService: LeadLogicService,
        public cdRef: ChangeDetectorRef
    ) {
        super(
            dialogRef,
            data,
            logicService,
            cdRef,
        );
    }
}

