import { Component } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ClientSaleLogicService } from '@app/logic/client-sale';
import { ClientSaleReportLineExtendedDtoVm } from '@app/logic/client-sale/dtos/client-sale-report-line-extended-dto-vm';
import { ConsentType } from '@app/logic/lot-consents';
import { DashboardBaseWidgetDirective, IUrlAndParam } from '@app/views/dashboard/dashboard-base-widget/dashboard-base-widget.directive';
import { IClientSaleReportLineDto, IClientSaleReportTaskItemExtendedDto } from '@classictechsolutions/hubapi-transpiled-enums';


@Component({
    selector: 'cb-client-sales-summary-report',
    templateUrl: './client-sales-summary-report.component.html',
    styleUrls: ['./client-sales-summary-report.component.scss']
})
export class ClientSalesSummaryReportComponent {
    public displayedColumns = [
        'Client Account Name',
        'Job Number',
        'Lot Number',
        'Lot Type',
        'Project',
        'Sale Status',
        'Lot Status',
        'Lot Last Activity',
        'Assigned To',
        'Activity Due Date',
        '',
        ''
    ];

    public tableRows: IClientSaleReportLineDto[];
    public extendedtableRows: ClientSaleReportLineExtendedDtoVm[] = [];
    public isLoaded = false;
    public isExtendedSummaryEnabled = false;


    constructor(
        private readonly clientSaleLogicService: ClientSaleLogicService,
        private readonly currentUserService: CurrentUserService,
        private readonly navigationService: NavigationService,
        public readonly featureToggleStateService: FeatureToggleStatesService,
    ) {

        this.featureToggleStateService.init().then(() => {
            this.isExtendedSummaryEnabled = this.featureToggleStateService.isClientSaleSummarExtendedReportEnabled;
            const userId = this.currentUserService.guid;

            if (this.isExtendedSummaryEnabled) {
                this.clientSaleLogicService.getClientSaleSummaryExtendedReport(userId).subscribe(x => {
                    for (let i = 0; i <= x.length - 1; i++) {
                        const d = new ClientSaleReportLineExtendedDtoVm();
                        d.isExpanded = false;
                        d.dto = x[i];
                        this.extendedtableRows.push(d);
                    }
                    this.isLoaded = true;
                });
            }
            else {
                this.clientSaleLogicService.getClientSaleSummaryReport(userId).subscribe(x => {
                    this.tableRows = x;
                    this.isLoaded = true;
                });
            }

        });
    }

    public isDateLate(date: Date): boolean {
        const dateToCompare = new Date(date);
        return dateToCompare < new Date();
    }

    public viewLot(lotId: number): void {
        this.navigationService.navigate([`lots/${lotId}/summary`]);
    }

    public goToItem(params: IClientSaleReportTaskItemExtendedDto, event: MouseEvent): void {
        const p = this.viewItemUrl(params);
        this.navigationService.navigate([p.url], null, { queryParams: p.queryParams });
    }

    public viewItemUrl(item: IClientSaleReportTaskItemExtendedDto): IUrlAndParam {

        let url = item.url;
        const matchedStrs = url.match(DashboardBaseWidgetDirective.RETURN_MATCHED_CURLYS_AND_CONTENT_REGEX);
        matchedStrs?.forEach(match => {
            const prop = match?.replace(DashboardBaseWidgetDirective.CURLY_BRACKETS_REGEX, '');
            if (item[prop]?.toString()) {
                url = url.replace(match, item[prop].toString());
            }
        });

        let queryParams: { [key: string]: string | number } = {};
        if (item.secondaryEntityType !== undefined) {
            switch (item.secondaryEntityType) {
                case 'GenericQuestions':
                    queryParams = { paramEntityId: item.secondaryEntityId, qnsId: item.entityId };
                    break;
                case 'DesignConcept':
                    queryParams = { conceptId: item.secondaryEntityId, paramEntityId: item.secondaryEntityId };
                    url = this.removeParamsFromUrlString(url);
                    break;
                case 'Task':
                    queryParams = {};
                    url = this.removeParamsFromUrlString(url);
                    break;
                case ConsentType.BuildingConsent:
                    queryParams = { lotConsentId: item.secondaryEntityId, consentType: ConsentType.BuildingConsent };
                    break;
                case ConsentType.ResourceConsent:
                    queryParams = { lotConsentId: item.secondaryEntityId, consentType: ConsentType.ResourceConsent };
                    break;
                default:
                    queryParams = { paramEntityId: item.entityId };
            }
        }

        return { url, queryParams };

    }

    private removeParamsFromUrlString(url: string): string {
        return url.includes('?') ? url.slice(0, url.indexOf('?')) : url;
    }

    public toggleExpanded(row: ClientSaleReportLineExtendedDtoVm): void {

        if (row.isExpanded) {
            row.isExpanded = false;
        } else {
            for (let i = 0; i <= this.extendedtableRows.length - 1; i++) {
                this.extendedtableRows[i].isExpanded = false;
            }
            this.clientSaleLogicService.getClientSaleNextSteps(row.dto.clientSaleId).subscribe(x => {
                row.nextSteps = [];
                row.nextSteps = x;
            });
            row.isExpanded = true;

        }
    }

}
