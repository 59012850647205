<cb-dialog dialogHeading="Product Catalogue">
    <cb-product-catalogue-tab [activeOnly]="data.activeOnly"
                              [hideCompositeItems]="data.hideCompositeItems"
                              [inputFindDialogMode]="true"
                              [restrictedCategories]="data.restrictedCategories"
                              [hasRestrictedCategory]="data.hasRestrictedCategory"
                              (productSelected)="productSelected($event)">
    </cb-product-catalogue-tab>
    <footer class="end-center flex-row">
        <button mat-raised-button
                (click)="close()">Close</button>
    </footer>
</cb-dialog>
