import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ToastService } from '@app/core/services/toast/toast.service';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { LotCostPermissions, QSTeamPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ITakeoffTaskMappedItem } from '@app/logic/takeoff-tasks/interfaces/i.takeoff-task-mapped';
import { TakeoffTasksLogicService } from '@app/logic/takeoff-tasks/takeoff-tasks-logic-service';
import { TakeoffTaskDialogComponent } from '../takeoff-task-dialog/takeoff-task-dialog.component';
import { FormMode } from '@app/shared/enums/form';
import {
    DOCUMENT_TYPE_CODES_CONST,
    ICostXImportResultDto,
    TakeOffTaskStatusEnumId,
    TAKE_OFF_TASK_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { ILotMappedItem } from '@app/logic/lots';
import { UploadTakeoffDialogComponent } from '../../upload-takeoff-dialog/upload-takeoff-dialog.component';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { DocumentsLogicService, IDocumentEntityMappedItem } from '@app/logic/documents';
import { TakeoffCompareService } from '../../takeoff-compare/takeoff-compare.service';
import { TakeoffErrorsDialogComponent } from '../../taskoff-errors-dialog/takeoff-errors-dialog.component';
import moment from 'moment';

@Component({
    selector: 'cb-takeoff-task',
    templateUrl: './takeoff-task.component.html',
    styleUrls: ['./takeoff-task.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeoffTaskComponent implements OnDestroy, OnInit {

    public mappedItem$$: BehaviorSubject<ITakeoffTaskMappedItem> = new BehaviorSubject(null);
    @Input() public set mappedItem(item: ITakeoffTaskMappedItem) {

        if (item) {
            item.$reload().subOnce((_) => {
                this.mappedItem$$.next(item);
            });
        }
    }
    public get mappedItem(): ITakeoffTaskMappedItem {
        return this.mappedItem$$.value;
    }

    @Output() public readonly goBack = new EventEmitter();
    @Output() public readonly reloadLotMappedItem = new EventEmitter();

    @Input() public lot: ILotMappedItem;

    public takeOffResult: ICostXImportResultDto;
    public document: IDocumentEntityMappedItem;
    public selectedTakeOffId: number;
    public displayCompare$ = new BehaviorSubject(false);
    public clientSideElapsedTime$ = new ReplaySubject(1);
    public TAKE_OFF_TASK_STATUS_ENUM = TAKE_OFF_TASK_STATUS_ENUM;
    private _subscriptions = new Subscription();


    constructor(
        private readonly documentTypesLogicService: DocumentTypesLogicService,
        @Inject(DocumentsLogicService) private readonly documentsLogic: DocumentsLogicService,
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: TakeoffTasksLogicService,
        private readonly toastService: ToastService,
        private readonly lotCostPermissions: LotCostPermissions,
        private readonly takeoffCompareService: TakeoffCompareService,
        public cdRef: ChangeDetectorRef,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
        private readonly dialogService: CbDialogService,
        private readonly qsTeamPermissions: QSTeamPermissions,
        private readonly navigationService: NavigationService
    ) {
        this.clientSideElapsedTime$.subscribe();
    }

    public ngOnInit(): void {
        this._loadDocumentEntity();
        this._updateClientSideElapsedTime();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public backToSearch(): void {
        this.goBack.emit();
    }

    private _loadDocumentEntity(): void {
        this.documentTypesLogicService
            .getByCode(DOCUMENT_TYPE_CODES_CONST.COSTX_WORKSHEET)
            .subOnce((documentType) => {
                this.document = this.documentsLogic.$createMappedItem(
                    {
                        documentType,
                        document: {}
                    } as IDocumentEntityMappedItem);
            });
    }

    public goToTakeOff(takeOffId: number): void {
        this.selectedTakeOffId = takeOffId;
        this.displayCompare$.next(true);
        this.takeoffCompareService.displayCompare$.next(takeOffId);
    }

    public openUploadDialogue(): void {
        this.dialogService
            .open(
                UploadTakeoffDialogComponent,
                {
                    data: {
                        document: this.document,
                        lot: this.lot,
                        takeOffResult: this.takeOffResult,
                        takeOffTaskId: this.mappedItem.id,
                        takeOffs: this.mappedItem.takeOffs,
                    },
                }
            ).afterClosed().subOnce(result => {

                if (result) {
                    this.takeOffResult = result;
                    if (this.takeOffResult.hasErrors) {
                        this.showErrors(result);
                    } else {
                        this.mappedItem.$reload().subOnce((_) => {
                            this.mappedItem$$.next(this.mappedItem);
                            this.reloadLotMappedItem.emit();
                        });
                    }
                    this.cdRef.detectChanges();
                }
            });
    }

    public showErrors($event: any): void {
        this.dialogService.open(TakeoffErrorsDialogComponent,
            {
                data: {
                    takeOffResult: this.takeOffResult
                }
            });
    }

    public canCreateTakeoff(): boolean {
        return this.lotCostPermissions.canCreateTakeoff();
    }

    public editTakeOffTask(): void {
        this.dialogService
            .open(
                TakeoffTaskDialogComponent,
                {
                    data: {
                        mappedItem: this.mappedItem,
                        mode: FormMode.Edit,
                        lotId: this.lot.id
                    },
                }
            ).afterClosed().subOnce(result => {
                this.mappedItem$$.next(this.mappedItem);
            });
    }

    public canSetStatus(status: TakeOffTaskStatusEnumId): boolean {
        return this.mappedItem?.$canEditTakeOffTask
            && this.mappedItem?.statusId !== status;
    }

    public setStatus(status: TakeOffTaskStatusEnumId): void {
        this.dialogService.confirm({
            dialogHeading: 'Update Takeoff Status',
            message: 'Are you sure you want to update this Takeoffs status?',
            confirmed: () => {
                this.mappedItem.updateStatus(status).subOnce(this._updateClientSideElapsedTime);
            }
        });
    }

    private readonly _updateClientSideElapsedTime = (): void => {
        if (!this.mappedItem) {
            return;
        }

        if (this.mappedItem.statusId === TAKE_OFF_TASK_STATUS_ENUM.InProgress) {
            const dateNow = moment();
            // Calculates how long since the initial elapsed time was updated form the server-side
            // and add duration to now.
            const duration = moment.duration(dateNow.diff(this.mappedItem.elapsedTimeAt));

            // Now add that duration to initial duration to get real-time duration.
            this.clientSideElapsedTime$.next(moment.duration(this.mappedItem.elapsedTime).add(duration.asSeconds(), 's'));

        } else {
            // since not in progress no further time is elapsing.
            this.clientSideElapsedTime$.next(moment.duration(this.mappedItem.elapsedTime));
        }
    };
}
