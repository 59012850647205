<cb-dialog dialogHeading="Client Account">
    <form class="flex-col flex"
          #clientAccountForm="ngForm">
        <div class="flex-col flex"
             *ngIf="!data.editAddressOnly">
            <cb-input type="text"
                      [required]="true"
                      label="Client Account Name"
                      name="clientAccountName"
                      [(ngModel)]="clientAccountDetails.clientAccountName"></cb-input>
            <cb-input type="text"
                      [required]="true"
                      label="Client Account Legal Name"
                      name="clientAccountLegalName"
                      [(ngModel)]="clientAccountDetails.clientAccountLegalName"></cb-input>
            <cb-select [required]="true"
                       label="Client Account Type"
                       name="clientAccountType"
                       [(ngModel)]="clientAccountDetails.clientAccountType"
                       [options]="accountTypeLookup"></cb-select>
            <cb-contact-autocomplete-scroller class="cb-margin-top flex"
                                              label="Main Contact"
                                              name="mainContact"
                                              [(ngModel)]="selectedContact"
                                              [requireMatch]="true"
                                              [matchProps]="['id']"
                                              [active]="true"
                                              [required]="true"></cb-contact-autocomplete-scroller>
            <cb-input type="text"
                      softRequired="true"
                      label="Solicitor Name"
                      name="solicitorName"
                      [(ngModel)]="clientAccountDetails.solicitorName"></cb-input>
            <cb-input type="email"
                      softRequired="true"
                      label="Solicitor Email"
                      name="solicitorEmail"
                      [(ngModel)]="clientAccountDetails.solicitorEmail"></cb-input>
        </div>
        <cb-address-new label="Physical Address"
                        name="address"
                        [hideHeading]="true"
                        [required]="true"
                        [(ngModel)]="clientAccountDetails.physicalAddress"></cb-address-new>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="!clientAccountForm.dirty || !clientAccountForm.valid"
                    (click)="save()">Save</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
