import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotSpecItemManageAttributes } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ILotSpecLogicService } from '@app/logic/lot-spec/interfaces/i.lot-spec.logic.service';
import { LotSpecLogicService } from '@app/logic/lot-spec/lot-spec.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ISaveableLotSpecItemAttributeManageDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { flattenDeep, values } from 'lodash';
import { LotSpecItemAttributeManage } from '../models/lot.spec.item.attribute.manage';

interface IGroupedItems {
    id: number;
    label: string;
    items: Array<LotSpecItemAttributeManage>;
    expanded: boolean;
    anyEmptyItems: () => boolean;
}

@Component({
    templateUrl: './manage-lot-spec-item-attributes-dialog.component.html',
    styleUrls: ['./manage-lot-spec-item-attributes-dialog.component.scss']
})
export class ManageLotSpecItemAttributesDialogComponent extends BaseDialogFormViewDirective<any, any, ILotSpecLogicService> {
    public static readonly MIN_WIDTH = '70%';

    public finishedLoading = false;
    public groupedItems: IGroupedItems[] = [];
    public changedItems: { [key: number]: boolean } = {};
    public itemsHaveChanged = false;

    public get userCacheItem(): UserCacheItem<ILotSpecItemManageAttributes> {
        return this.userCacheService.lotSpecItemManageAttributes;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<ManageLotSpecItemAttributesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            lotId: number;
            specVersion: number;
        },
        @Inject(LotSpecLogicService) private readonly lotSpecLogic: LotSpecLogicService,
        @Inject(UserCacheService) private readonly userCacheService: UserCacheService,
        @Inject(ToastService) toastService: ToastService,
    ) {
        super(dialogRef, toastService);
        this.lotSpecLogic.getLotSpecAndColourItemsWithAttributes(this.data.lotId)
            .subOnce(this.handleLoadItems);
    }

    public attributeChanged(item: LotSpecItemAttributeManage): void {
        this.itemsHaveChanged = true;
        item.hasChanged = true;
    }

    public saveAll(): void {
        const toSave = flattenDeep(
            this.groupedItems.map(group => group.items.map(item => item.getSaveableItem()))
        ).filter(a => a != null) as ISaveableLotSpecItemAttributeManageDto[];

        this.lotSpecLogic
            .updateLotSpecAndColourItemsWithAttributes(this.data.lotId, toSave)
            .subOnce(() => this.dialogRef.close(true));
    }

    public togglePanelPinned(groupId: number): void {
        event.stopPropagation();
        this.userCacheItem.data[groupId]
            = !this.userCacheItem.silentData[groupId];
    }

    public expandCollapseAll(): void {
        const shouldExpand = !this.allPanelsExpanded();
        this.groupedItems.forEach(item => {
            item.expanded = shouldExpand;
        });
    }

    public allPanelsExpanded(): boolean {
        return this.groupedItems != null && this.groupedItems.every(a => a.expanded);
    }

    private handleLoadItems = (items: any[]): void => {
        const grouped: { [key: number]: IGroupedItems } = {};

        items.forEach(item => {
            if (!grouped[item.specGroupId]) {
                grouped[item.specGroupId] = {
                    anyEmptyItems() { return this.items.some(a => a.anyEmptyAttributes()); },
                    expanded: false,
                    id: item.specGroupId,
                    items: [],
                    label: item.specGroupLabel
                };
            }
            grouped[item.specGroupId].items.push(new LotSpecItemAttributeManage(item));
        });

        this.groupedItems = values(grouped);
        this.loadPinnedPanels();
        this.finishedLoading = true;
    };

    private loadPinnedPanels(): void {
        this.userCacheItem.init().then(() => {
            this.groupedItems.forEach((value) => {
                value.expanded = this.userCacheItem.silentData[value.id];
            });
        });
    }
}
