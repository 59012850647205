<cb-dialog dialogHeading="Review Manual Purchase Order">
    <form #reviewPurchaseOrderForm="ngForm">
        <div class="cb-margin flex-row">
            <div class="flex-col flex">
                <span class="cb-field-label cb-margin-bottom">Decision</span>
                <mat-radio-group aria-label="Select an option"
                                 [(ngModel)]="approvalStatus"
                                 name="decision"
                                 (change)="commentsRequired.recompute()">
                    <mat-radio-button class="cb-margin-right"
                                      [value]="PURCHASE_ORDER_APPROVAL_STATUS_ENUM.Approved">Approve</mat-radio-button>
                    <mat-radio-button [value]="PURCHASE_ORDER_APPROVAL_STATUS_ENUM.Rejected">Reject</mat-radio-button>
                </mat-radio-group>
                <cb-text-area label="Comments"
                              name="comments"
                              [(ngModel)]="comments"
                              maxlength="1024"
                              [required]="commentsRequired.value">
                </cb-text-area>
            </div>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                (click)="submit()"
                [disabled]="reviewPurchaseOrderForm.invalid || reviewPurchaseOrderForm.pristine">Submit
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
