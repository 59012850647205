import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IVendorDto} from '@app/logic/vendors/interfaces/i.vendor.dto';

@Component({
    selector: 'cb-vendor-input-find-card',
    templateUrl: './vendor-input-find-card.component.html',
    styleUrls: ['./vendor-input-find-card.component.scss']
})
export class VendorInputFindCardComponent {
    @Input() public dto: IVendorDto;
    @Output() public readonly selectionChosenEmitter = new EventEmitter();

    constructor() { }

    public selectionChosen(val: IVendorDto): void {
        this.selectionChosenEmitter.emit(val);
    }

    public getDisplayName(): string {
        if (this.dto.legalName) {
            return this.dto.legalName;
        } else {
            return this.dto.legalEntityName;
        }
    }

}
