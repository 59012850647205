<mat-drawer-container class="parent-container">
    <mat-drawer mode="side"
                opened="true"
                position="start"
                [ngStyle]="rightDrawer.opened && featureToggle.isScheduleItemSpecTemplatesEnabled ?{'width': '50%'} : {'width': '100%'}">

        <!-- Specification Template Panel -->
        <div class="cb-margin-right cb-margin-top flex-col flex">
            <div class="end flex-row">
                <h3 class="flex">{{this.scheduleTemplateName}}</h3>
                <span class="flex"></span>
                <button class="cb-margin-right"
                        mat-raised-button
                        color="primary"
                        (click)="getPreview()">
                    Preview Template
                </button>
                <button *ngIf="this.canEdit"
                        class="cb-margin-right"
                        mat-raised-button
                        color="primary"
                        [disabled]="!featureToggle.isScheduleItemSpecTemplatesEnabled || isSaveDisabled "
                        (click)="saveAll()">
                    Save All
                </button>
                <button *ngIf="this.canEdit"
                        class="cb-margin-right"
                        mat-raised-button
                        [disabled]="!featureToggle.isScheduleItemSpecTemplatesEnabled || isCancelDisabled"
                        (click)="cancelChanges() ; rightDrawer.close()">
                    Cancel
                </button>
                <button *ngIf="this.canEdit"
                        class="cb-margin-right"
                        mat-raised-button
                        [disabled]="!featureToggle.isScheduleItemSpecTemplatesEnabled || rightDrawer.opened"
                        (click)="rightDrawer.toggle(); isCancelDisabled = false;">
                    Manage
                </button>
                <button class="cb-margin-right"
                        mat-raised-button
                        (click)="generateReport();">
                    Generate Report
                </button>
                <button mat-raised-button
                        (click)="expandCollapseTemplatesAll()">
                    <span class="start-center flex-row"
                          *ngIf="!allTemplatesExpanded">
                        <span>Expand All&nbsp;</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </span>
                    <span class="start-center flex-row"
                          *ngIf="allTemplatesExpanded">
                        <span>Collapse All&nbsp;</span>
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </span>
                </button>
            </div>
            <div class="start flex-row">
                <small class="cb-margin-bottom-2x">
                    <i>Remove items to the specification template using the
                        '-'</i>
                </small>
            </div>
            <mat-accordion [multi]="true"
                           class="accordion cb-padding-bottom">
                <mat-expansion-panel class="panel"
                                     *ngFor="let houseArea of houseAreas"
                                     [(expanded)]="expandedTemplateAreas[houseArea.id]"
                                     (expandedChange)="setAllItemsExpanded()">
                    <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                        <div id="{{'scrollTo' + houseArea.id}}"></div>
                        <mat-panel-title>
                            <span class="mat-body-2 cb-margin-top">{{houseArea.label}} </span>
                        </mat-panel-title>
                        <mat-panel-description class="end-center flex-row">
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <cb-schedule-template-items-table *ngIf="expandedTemplateAreas[houseArea.id]"
                                                      #scheduleTemplateItemsTable
                                                      [houseAreaIdWithItems]="{ houseAreaId: houseArea.id, scheduleTemplateItems: listOfGroupedSpecTemplateItems.get(houseArea.id) }"
                                                      (scheduleItemRemoved)="handleItemRemoved($event)"
                                                      [isEdit]="rightDrawer.opened">
                    </cb-schedule-template-items-table>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-drawer>
    <mat-drawer #rightDrawer
                mode="side"
                opened="false"
                position="end"
                style="width: 50%;"
                [hidden]="!featureToggle.isScheduleItemSpecTemplatesEnabled">
        <div *ngIf="featureToggle.isScheduleItemSpecTemplatesEnabled"
             class="cb-margin flex-col flex">
            <div class="end flex-row">
                <h3 class="cb-margin-bottom-2x">Schedule Item Manager</h3>
                <span class="flex"></span>
                <button mat-raised-button
                        (click)="expandCollapseItemAll()">
                    <span class="start-center flex-row"
                          *ngIf="!allItemsExpanded">
                        <span>Expand All&nbsp;</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </span>
                    <span class="start-center flex-row"
                          *ngIf="allItemsExpanded">
                        <span>Collapse All&nbsp;</span>
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </span>
                </button>
            </div>
            <small class="cb-margin-bottom-2x">
                <i>Add items to the specification template using the '+'</i>
            </small>
            <mat-accordion [multi]="true"
                           class="accordion cb-padding-bottom">
                <mat-expansion-panel class="panel"
                                     *ngFor="let houseArea of houseAreas"
                                     [(expanded)]="expandedItemAreas[houseArea.id]"
                                     (expandedChange)="setAllItemsExpanded()">
                    <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom test">
                        <mat-panel-title>
                            <span class="mat-body-2 cb-margin-top">{{houseArea.label}}</span>
                        </mat-panel-title>
                        <mat-panel-description class="end-center flex-row">
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <cb-schedule-items-table *ngIf="expandedItemAreas[houseArea.id] && listOfGroupedSpecTemplateItems"
                                             (scheduleItemAdded)="handleScheduleItemAdded($event)"
                                             [houseAreaIdWithSkinnyItems]="{ houseAreaId: houseArea.id, skinnyTemplateItems: this.skinnyScheduleTemplateItems }">
                    </cb-schedule-items-table>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-drawer>
</mat-drawer-container>
