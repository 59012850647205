import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IBundleTemplateMappedItem } from '@app/logic/bundle-templates/interfaces/i.bundle-template.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { BundleTemplatesLogicService } from '@app/logic/bundle-templates';
import { IBundleTemplatesLogicService } from '@app/logic/bundle-templates/interfaces/i.bundle-templates.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BundleTemplateMappedItem } from '@app/logic/bundle-templates/bundle-template.mapped';
import { SelectItemsDialogComponent } from '@app/shared/components/forms/select-items-dialog/select-items-dialog.component';
import { BundleTemplateItemDialogComponent } from '../bundle-template-item-dialog/bundle-template-item-dialog.component';
import { SelectItemsService } from '@app/shared/components/forms/select-items-dialog/select-items-service';
import { TableColumn } from '@app/shared/components/table/base-table.component';
import { IBundleTemplateDto, IBundleTemplateItemDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: BundleTemplateMappedItem;
}

@Component({
    selector: 'cb-bundle-template-dialog',
    templateUrl: './bundle-template-dialog.component.html',
    styleUrls: ['./bundle-template-dialog.component.scss'],
    providers: [BundleTemplatesLogicService, SelectItemsService]
})
export class BundleTemplateDialogComponent extends BaseDialogFormViewDirective<IBundleTemplateDto, IBundleTemplateMappedItem, IBundleTemplatesLogicService> {
    @ViewChild(SelectItemsDialogComponent, {}) public selectItemsDialog: SelectItemsDialogComponent<IBundleTemplateItemDto>;

    public mappedItem: BundleTemplateMappedItem;
    public columns: Array<TableColumn>;
    public newEmptyItem = {
        isLocked: false,
        isRequired: false
    };

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly selectItemsService: SelectItemsService,
        public readonly dialogRef: MatDialogRef<BundleTemplateDialogComponent>,
        public readonly dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.selectItemsService.dialogComponent = BundleTemplateItemDialogComponent;
        this.selectItemsService.dialogHeading = 'Bundle Template Item';
        if (!this.mappedItem.bundleItems) {
            this.mappedItem.bundleItems = [];
        }
        this.mappedItem.bundleItems.push({});
        this.columns = [
            { name: '', valueProp: 'isLocked', type: 'icon', icons: { true: 'lock', false: 'lock_open' } },
            { name: 'Name', valueProp: 'name', type: 'text' },
            { name: 'Category', valueProp: 'categoryPath', type: 'text' },
            { name: 'Required', valueProp: 'isRequired', type: 'checkbox' }
        ];
    }
}
