import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'cb-env-display',
    templateUrl: './env-display.component.html',
    styleUrls: ['./env-display.component.scss']
})
export class EnvDisplayComponent {

    public environment = environment;

}
