<mat-card class="start-center flex-row">
    <button class="cb-margin-right"
            mat-button
            (click)="goBackToPriceRevisionSearch()">
        <mat-icon>arrow_back</mat-icon>
        &nbsp;Back to Price Revisions
    </button>
    <button *ngIf="canEditPricingRevision()"
            [disabled]="!mappedItem.canEdit"
            (click)="editRevision()"
            class="cb-margin-right"
            color="primary"
            mat-raised-button>
        Edit Revision
    </button>
    <button *ngIf="canChangePricingRevisionStatus()"
            color="primary"
            class="cb-margin-right"
            mat-raised-button
            [matMenuTriggerFor]="statusMenu"
            (click)="$event.stopPropagation()"
            [disabled]="!mappedItem.canEdit">
        <mat-icon>arrow_drop_down</mat-icon>
        &nbsp;Revision Status
    </button>
    <mat-menu #statusMenu="matMenu">
        <button mat-menu-item
                (click)="setInQueue()"
                [disabled]="!mappedItem.canChangeStatus">
            <mat-icon color="accent">timelapse</mat-icon>
            <span>&nbsp;In Queue</span>
        </button>
        <button mat-menu-item
                (click)="setInProgress()"
                [disabled]="inProgressDisabled()">
            <mat-icon color="warn">check</mat-icon>
            <span>&nbsp;In Progress</span>
        </button>
        <button mat-menu-item
                (click)="setOnHold()"
                [disabled]="!mappedItem.canChangeStatus">
            <mat-icon color="primary">pause</mat-icon>
            <span>&nbsp;On Hold</span>
        </button>
        <button mat-menu-item
                (click)="finalise()"
                [disabled]="!mappedItem.canChangeStatus">
            <mat-icon color="primary">send</mat-icon>
            <span>&nbsp;Finalise</span>
        </button>
    </mat-menu>
    <span *cbWaitFor="designSchemeLotSpecVersion">
        <button *ngIf="lotMappedItem.hasScheduleSpecTemplate"
                [disabled]="!currentUser.isQSTeam()"
                (click)="exportItemsForCostX()"
                class="cb-margin-right"
                color="primary"
                mat-raised-button>
            Export Items For CostX
        </button>
    </span>
</mat-card>
