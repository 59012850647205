import { Component, OnInit, Inject } from '@angular/core';
import { LotTypeEnumId, LOT_TYPE_ENUM, UNIT_OF_MEASURE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotBuildPermissions } from '@app/core/permissions';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { ISsrDto } from '@app/logic/ssrs/interfaces/i.ssr.dto';
import { ISsrsLogicService } from '@app/logic/ssrs/interfaces/i.ssrs.logic.service';
import { ISsrMappedItem } from '@app/logic/ssrs';
import { ISsrLineItemDto, IPossibleLineItemDto } from '@app/logic/ssrs/interfaces/i.ssr-line-item.dto';
import { isNullOrWhiteSpace } from 'cb-hub-lib';

interface IData {
    mappedItem: ISsrMappedItem;
    lotType: LotTypeEnumId;
}

@Component({
    templateUrl: './add-ssr-lines-dialog.component.html',
    styleUrls: ['./add-ssr-lines-dialog.component.scss']
})
export class AddSsrLinesDialogComponent
    extends BaseDialogFormViewDirective<ISsrDto, ISsrMappedItem, ISsrsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '640px';

    public readonly UNIT_OF_MEASURE_ENUM = UNIT_OF_MEASURE_ENUM;
    public readonly LOT_TYPE_ENUM = LOT_TYPE_ENUM;

    public get mappedItem(): ISsrMappedItem {
        return this.data.mappedItem;
    }
    public readonly filteredLineItems = new ComputedProperty(() => {
        return isNullOrWhiteSpace(this.searchFilter)
            ? this.possibleLineItems
            : this.possibleLineItems?.filter(x => Object.values(x).join(' ').toLowerCase().includes(this.searchFilter.toLowerCase()));
    });


    public searchFilter = '';
    public possibleLineItems: IPossibleLineItemDto[] = [];
    public linesToAdd: { [key: number]: boolean } = {};
    public selectedLines: Array<IPossibleLineItemDto> = [];

    constructor(
        public readonly dialogRef: MatDialogRef<AddSsrLinesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LotBuildPermissions) public readonly lotBuildPermissions: LotBuildPermissions,
    ) {
        super(dialogRef, toastService);
    }


    public ngOnInit(): void {
        this.mappedItem
            .getPossibleLineItems()
            .subOnce((result) => {
                this.possibleLineItems = result;
                this.filteredLineItems.recompute();
            });
    }

    public updateLinesToAdd(quantityAccountItemId: number): void {
        const toAdd = this.linesToAdd[quantityAccountItemId];
        const existingIndex = this.selectedLines?.findIndex(x => x.quantityAccountItemId === quantityAccountItemId);
        const exists = existingIndex > -1;

        if (toAdd && !exists) {
            const line = this.possibleLineItems.find(x => x.quantityAccountItemId === quantityAccountItemId);
            if (!line) {
                return;
            }
            this.selectedLines.push(line);
        } else if (!toAdd && exists) {
            this.selectedLines.splice(existingIndex, 1);
        }
    }

    public removeChip(chip: ISsrLineItemDto): void {
        this.linesToAdd[chip.quantityAccountItemId] = false;
        this.updateLinesToAdd(chip.quantityAccountItemId);
    }

    public addSelectedLines(): void {
        this.saveInProgress = true;
        const selectedLinesToAdd = [...this.selectedLines];

        this.mappedItem
            .addLineItems(selectedLinesToAdd)
            .subOnce(this.handleLineItemsAdded);
    }

    private readonly handleLineItemsAdded = (addedItems: Array<ISsrLineItemDto>): void => {
        this.dialogRef.close(addedItems);
    };

}
