<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="pinnedPanels[silentDataKey]"
                     #classicContactsExpansionPanel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">contacts</mat-icon>
            <b>Classic Contacts</b>
        </mat-panel-title>
        <mat-panel-description class="end-center flex-row">
            <button *ngIf="classicContactsExpansionPanel.expanded || silentData[silentDataKey]"
                    mat-icon-button
                    (click)="togglePanelPinned($event, silentDataKey)">
                <mat-icon svgIcon="pin"
                          [ngClass]="{'cb-accent' : silentData[silentDataKey]}">
                </mat-icon>
            </button>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex-col">
        <div class="flex-wrap flex">
            <cb-display-value-new class="flex-50"
                                  *ngIf="businessEntityField.isVisible"
                                  label="Business Entity">
                {{lotMappedItem?.businessEntity.label | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="hlCoordinatorField.isVisible"
                                  label="HL Coordinator">
                {{lotMappedItem?.contacts.houseAndLandCoordinator?.label | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="buildingConsultantField.isVisible"
                                  label="Building Consultant">
                {{lotMappedItem?.contacts.buildingConsultant?.label | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="buildingCoordinatorField.isVisible"
                                  label="Building Coordinator">
                {{lotMappedItem?.contacts.buildingCoordinator?.label | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="designerField.isVisible"
                                  label="Designer">
                {{lotMappedItem?.contacts.designer?.label | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="quantitySurveyorField.isVisible"
                                  label="QS">
                {{lotMappedItem?.contacts.quantitySurveyor?.label | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="foundationManagerField.isVisible"
                                  label="Foundation Manager">
                {{lotMappedItem?.contacts.foundationManager?.label | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="framingManagerField.isVisible"
                                  label="Framing Manager">
                {{lotMappedItem?.contacts.framingManager?.label | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="qualityAssuranceManagerField.isVisible"
                                  label="QA Manager">
                {{lotMappedItem?.contacts.qualityAssuranceManager?.label | cbDasher}}
            </cb-display-value-new>
        </div>
    </div>
</mat-expansion-panel>
