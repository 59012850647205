import { AddressRegionMappedItem, AddressRegionsLogicService } from '@app/logic/address-regions';
import { Component, OnDestroy } from '@angular/core';
import { AddressRegionDialogComponent } from './components/address-region-dialog/address-region-dialog.component';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { DragulaService } from 'ng2-dragula';
import { PermissionsPermissions } from '../../../../core/permissions';
import { Subscription } from 'rxjs';
import { ToastService } from '@app/core/services/toast/toast.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';

const DRAGULA_IGNORE = 'cb-table-footer-row';

@Component({
    selector: 'cb-address-regions',
    templateUrl: './address-regions.component.html',
    styleUrls: ['./address-regions.component.scss'],
    providers: [AddressRegionsLogicService]
})
export class AddressRegionsComponent extends BaseSimpleListViewDirective<IAddressRegionDto, AddressRegionsLogicService> implements OnDestroy {
    public subscriptions = new Subscription();
    public readonly ADDRESS_REGIONS = 'ADDRESS_REGIONS';
    public dragulaModel: number[] = [];
    public sortOrderChanged = false;

    public readonly displayedColumns: string[] = ['Name', 'Active', ''];

    public savingOrder = false;

    constructor(
        public readonly toastService: ToastService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        private readonly dragulaService: DragulaService,
        private readonly addressRegionsLogicService: AddressRegionsLogicService
    ) {
        super(
            dialog,
            addressRegionsLogicService,
            AddressRegionDialogComponent,
            'Address Region',
            AddressRegionMappedItem,
            permissionsPermissions
        );
    }

    public ngOnInit(): void {
        this.setupDragula();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.dragulaService.destroy(this.ADDRESS_REGIONS);
    }

    protected handleNewItem = (item: any) => {
        super.handleNewItem(item);
        this.setDragulaModel();
    };

    protected handleSearchResults = (searchResults: Array<IAddressRegionDto>) => {
        super.handleSearchResults(searchResults);
        this.setDragulaModel();
    };

    public saveOrder(): void {
        this.dialog
            .confirm({
                message: 'Are you sure you want to save the order of the Address Regions?',
                declined: () => {
                    this.savingOrder = false;
                },
                confirmed: this.handleSaveOrder,
            });
    }

    private readonly handleSaveOrder = (): void => {
        this.addressRegionsLogicService
            .saveOrder(this.searchResults)
            .subOnce((result) => {
                this.searchResults = result;
                this.setDragulaModel();
                this.sortOrderChanged = false;
                this.savingOrder = false;
            });
    };

    /** Creates dragula group for address regions table and subscribes to dragula observables */
    private setupDragula(): void {
        this.dragulaService.createGroup(
            this.ADDRESS_REGIONS,
            {
                removeOnSpill: false,
                accepts: (el: Element) => !el.classList.contains(DRAGULA_IGNORE), // do not allow move of mat-header-row
                moves: (el: Element) => !el.classList.contains(DRAGULA_IGNORE), // do not allow move of mat-header-row
                revertOnSpill: true
            }
        );

        this.subscriptions.add(
            this.dragulaService.dropModel(this.ADDRESS_REGIONS)
                .subscribe(
                    ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                        if (sourceIndex === targetIndex) {
                            return;
                        }
                        moveItemInArray(this.searchResults, sourceIndex, targetIndex);
                        this.setDragulaModel();
                        this.sortOrderChanged = true;
                    }
                )
        );
    }

    /** Sets the dragula model for drag and drop - separate to the actual array of data because
     * the dragula model needs an item for the header row as well...
     */
    private setDragulaModel(): void {
        this.dragulaModel = this.searchResults.map(x => x.id);
    }
}
