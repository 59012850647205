import { Component, Input } from '@angular/core';
import { FieldValidationItem } from '@app/core/services/field-validation/field.validation.item';
import { LOT_COMPONENT_TYPE } from '@app/core/services/field-validation/lot-field-validator/interfaces/i.lot.field.rules.service';
import { LotFieldValidatorService } from '@app/core/services/field-validation/lot-field-validator/lot-field-validator-service';
import { ILotMappedItem } from '@app/logic/lots';
import { LotSummaryPinnablePanelDirective } from '../../shared/lot-summary-pinnable-panel';

@Component({
    selector: 'cb-lot-summary-classic-contacts',
    templateUrl: './lot-summary-classic-contacts.component.html',
    styleUrls: ['./lot-summary-classic-contacts.component.scss']
})
export class LotSummaryClassicContactsComponent extends LotSummaryPinnablePanelDirective {

    @Input() public lotMappedItem: ILotMappedItem;

    // Field Validation Items
    public buildingConsultantField: FieldValidationItem;
    public buildingCoordinatorField: FieldValidationItem;
    public designerField: FieldValidationItem;
    public quantitySurveyorField: FieldValidationItem;
    public foundationManagerField: FieldValidationItem;
    public framingManagerField: FieldValidationItem;
    public hlCoordinatorField: FieldValidationItem;
    public qualityAssuranceManagerField: FieldValidationItem;
    public businessEntityField: FieldValidationItem;

    constructor(private readonly lotFieldValidatorService: LotFieldValidatorService,) {
        super();
    }

    public ngOnInit(): void {
        this.lotFieldValidatorService.dto = this.lotMappedItem.$getMappedDtoItem();
        this.populateFieldValidationItems();
    }

    private populateFieldValidationItems(): void {
        this.buildingConsultantField = this.lotFieldValidatorService.contactsBuildingConsultant(LOT_COMPONENT_TYPE.ClassicContacts);
        this.buildingCoordinatorField = this.lotFieldValidatorService.contactsBuildingCoordinator(LOT_COMPONENT_TYPE.ClassicContacts);
        this.designerField = this.lotFieldValidatorService.contactsDesigner(LOT_COMPONENT_TYPE.ClassicContacts);
        this.quantitySurveyorField = this.lotFieldValidatorService.contactsQuantitySurveyor(LOT_COMPONENT_TYPE.ClassicContacts);
        this.foundationManagerField = this.lotFieldValidatorService.contactsFoundationManager(LOT_COMPONENT_TYPE.ClassicContacts);
        this.framingManagerField = this.lotFieldValidatorService.contactsFramingManager(LOT_COMPONENT_TYPE.ClassicContacts);
        this.hlCoordinatorField = this.lotFieldValidatorService.contactsHouseAndLandCoordinator(LOT_COMPONENT_TYPE.ClassicContacts);
        this.qualityAssuranceManagerField
            = this.lotFieldValidatorService.contactsQualityAssuranceManager(LOT_COMPONENT_TYPE.ClassicContacts);
        this.businessEntityField = this.lotFieldValidatorService.businessEntity(LOT_COMPONENT_TYPE.ClassicContacts);
    }

}
