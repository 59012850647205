import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { map, Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { DocumentGroupMappedItem } from './document-group.mapped';
import { IDocumentGroupDto } from './interfaces/i.document-group.dto';
import { IDocumentGroupLogicService } from './interfaces/i.document-group.logic.service';
import { IDocumentGroupMappedItem } from './interfaces/i.document-group.mapped';

@Injectable()
export class DocumentGroupLogicService
    extends BaseLogicService<IDocumentGroupDto, IDocumentGroupMappedItem>
    implements IDocumentGroupLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'documentgroups',
            DocumentGroupMappedItem,
        );
    }

    public createMappedDocumentGroupItem(entityUri: string, entityId: number | string, document?: IDocumentGroupDto): IDocumentGroupMappedItem {
        const result = this.$createMappedItem(document);
        result.entityUri = entityUri;
        result.entityId = entityId;
        return result;
    }

    public getMappedDocumentGroups(entityUri: string, entityId: number | string, systemArea: number): Observable<IDocumentGroupMappedItem[]> {
        entityUri = 'documentsgroups';
        const systemAreaUri = this.$baseUri.replace(/documentgroups/g, 'systemareas');
        return this.$http.get<IDocumentGroupDto[]>(`${systemAreaUri}/${systemArea}/${entityUri}`)
            .pipe(
                map(results => results.map(value => this.createMappedDocumentGroupItem(entityUri, entityId, value)))
            );
    }

    public getByCode(code: string): Observable<IDocumentGroupDto> {
        return this.$http.get('documentgroups/bycode', { code });
    }

    public checkCodeIsUnique(documentGroupId: number, codeName: string): Observable<boolean> {
        return this.$http.get(`${this.$baseUri}/${documentGroupId}/checkcodename`, { codeName });
    }

    public saveDocumentGroup(entityUri: string, entityId: number | string, document: IDocumentGroupDto): Observable<IDocumentGroupDto> {
        if (document.id > 0) {
            return this.$http.post(`${entityUri}/${entityId}/systemareas/${document.id}`, document);
        }
        return this.$http.post(`${entityUri}/${entityId}/systemareas`, document);
    }
}
