<cb-dialog>
    <form class="flex-col flex"
          #form="ngForm">
        <span class="mat-subheading-2">Select widgets to add to your dashboard</span>
        <cb-checkbox *ngFor="let item of availableWidgets"
                     [label]="item.label"
                     [name]="'widget'+item.id"
                     [ngModel]="isWidgetSelected(item.id)"
                     (change)="onWidgetChanged(item.id, $event)"></cb-checkbox>
        <div *ngIf="!availableWidgets.length"
             class="cb-margin no-widgets-message start-center flex-row">
            <mat-icon class="mat-accent">info_circle</mat-icon>
            <span class="cb-margin-left">You do not have any available widgets</span>
        </div>
    </form>
    <footer class="center-center flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                [disabled]="!canSave()"
                (click)="onSaveClicked()">Add Widget</button>
        <button mat-raised-button
                (click)="dialogRef.close()">Close</button>
    </footer>
</cb-dialog>
