import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPropertySearch } from '@app/core/services/user-cache/user-cache-areas';
import { IOption } from '@app/shared/components/forms/select/select.component';
import { garageTypesSelect } from '@app/views/standard-plans/const/garage.constants';

@Component({
    selector: 'cb-lot-search-popup-filter',
    templateUrl: './lot-search-popup-filter.component.html',
    styleUrls: ['./lot-search-popup-filter.component.scss']
})
export class LotSearchPopupFilterComponent {

    @Input() public searchData: IPropertySearch;

    private _floorAreas: number[];
    @Input() public set floorAreas(floorAreas: number[]) {
        this._floorAreas = floorAreas;
        this.setoptionsFloorArea();
    }

    public get floorAreas(): number[] {
        return this._floorAreas || [];
    }

    @Output() public emitPopUpSearchEvent = new EventEmitter();

    public minValue: number;
    public maxValue: number;
    public options: Options;
    public floorAreasLoaded = false;

    constructor() { }

    public optionsBedRoom: Options = {
        floor: 0,
        ceil: 10,
        step: 1,
        showTicks: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 0) {
                        return 'Bedroom: Any';
                    }
                    return `Bedroom: ${value}`;
                default:
                    return value.toString();
            }
        }
    };

    public optionsBathRoom: Options = {
        floor: 0,
        ceil: 10,
        step: 1,
        showTicks: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 0) {
                        return 'Bathroom: Any';
                    }
                    return `Bathroom:  ${value}`;
                default:
                    return value.toString();
            }
        }
    };

    public optionsLiving: Options = {
        floor: 0,
        ceil: 10,
        step: 1,
        showTicks: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 0) {
                        return 'Living: Any';
                    }
                    return `Living: ${value}`;
                default:
                    return value.toString();
            }
        }
    };

    public garageTypes = garageTypesSelect;

    public sortingOptions: IOption[] = [
        { id: undefined, label: 'None' },
        { id: 'projectLots', label: 'Project Lots' }
    ];

    public setoptionsFloorArea(): void {
        // Min Max Floor Area
        this.minValue = this.floorAreas[0];
        this.maxValue = this.floorAreas[1];
        this.options = {
            floor: this.minValue,
            ceil: this.maxValue,
            translate: (value: number, label: LabelType): string => {
                if (value > 0 && Number.isFinite(value) && this.floorAreas[0] !== this.floorAreas[1]) {
                    switch (label) {
                        case LabelType.Low:
                            return `Min : ${value}m²`;
                        case LabelType.High:
                            return `Max : ${value}m²`;
                        default:
                            return `${value}m²`;
                    }
                } else {
                    return '';
                }
            }
        };

        this.floorAreasLoaded = true;
    }

    public filterUpdated(): void {
        this.emitPopUpSearchEvent.emit(true);
    }

    public floorAreaChange(): void {
        this.searchData.floorAreaMin = this.minValue;
        this.searchData.floorAreaMax = this.maxValue;
        this.emitPopUpSearchEvent.emit(true);
    }

    public clearAll(): void {
        this.searchData.query = null;
        this.searchData.lotType = null;
        this.searchData.lotStatuses = null;
        this.searchData.locationId = null;
        this.searchData.myLotsOnly = null;

        this.searchData.floorAreaMin = null;
        this.searchData.floorAreaMax = null;
        this.searchData.numberOfBathrooms = null;
        this.searchData.numberOfBedrooms = null;
        this.searchData.numberOfGarages = null;
        this.searchData.numberOfLivingRooms = null;

        this.searchData.order = this.sortingOptions[0];
        this.emitPopUpSearchEvent.emit(true);
    }

}
