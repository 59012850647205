import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LotDesignPermissions } from '@app/core/permissions';
import { ILotWorkingDrawingSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { IWorkingDrawingMappedItem, WorkingDrawingsLogicService } from '@app/logic/working-drawings';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { ILotAmenitiesDto, WORKING_DRAWING_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, Subject, Subscription } from 'rxjs';
import { RequestWorkingDrawingDialogComponentComponent } from '../request-working-drawing-dialog-component/request-working-drawing-dialog-component.component';
import { WorkingDrawingDialogComponent } from '../working-drawing-dialog/working-drawing-dialog.component';

@Component({
    selector: 'cb-working-drawing-search',
    templateUrl: './working-drawing-search.component.html',
    styleUrls: ['./working-drawing-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkingDrawingSearchComponent implements OnInit, OnDestroy {

    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public readonly workingDrawingSelected = new EventEmitter<IWorkingDrawingMappedItem>();

    public readonly queryUpdate = new Subject();
    private readonly subscription$ = new Subscription();
    private _workingDrawingSubscription: Subscription[] = [];


    public get userCacheItem(): UserCacheItem<ILotWorkingDrawingSearch> {
        return this.userCacheService.lotWorkingDrawingSearch;
    }

    public currentPage: number;
    public searchEnabled = false;
    public results: IWorkingDrawingMappedItem[] = [];

    constructor(
        private readonly workingDrawingsLogicService: WorkingDrawingsLogicService,
        private readonly userCacheService: UserCacheService,
        private readonly lotLogicService: LotsLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly lotDesignPermissions: LotDesignPermissions

    ) { }

    public ngOnDestroy(): void {
        this._workingDrawingSubscription.forEach((item) => item.unsubscribe());
        this.subscription$.unsubscribe();
    }

    public ngOnInit(): void {
        this.initSearch();
    }

    public readonly resultsChange = (event: IWorkingDrawingMappedItem[]): void => {

        this._workingDrawingSubscription.forEach((item) => item.unsubscribe());
        this._workingDrawingSubscription = [];
        this.results.forEach((workingDrawing) => {
            this._workingDrawingSubscription.push(workingDrawing.$updated.subscribe(() => {
                this.cdRef.detectChanges();
            }));
        });
    };

    public initSearch(): void {
        this.userCacheItem.init().then(() => {
            this.searchEnabled = true;
            this.queryUpdated();
            this.subscription$.add(
                this.userCacheItem.updated$.subscribe({
                    next: this.queryUpdated
                })
            );
        });
    }

    public readonly queryUpdated = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.queryUpdate.next(null);
    };

    public fetchResults(): Observable<IWorkingDrawingMappedItem[]> {
        if (!this.searchEnabled) {
            return;
        }
        return this.workingDrawingsLogicService.$getMappedSearchList(this.getQueryParams());
    }

    protected getQueryParams(): any {
        return {
            pageSize: 10,
            currentpage: this.currentPage,
            query: this.userCacheItem.data.query,
            lotId: this.lotMappedItem.id
        };
    }

    public get isRequestWorkingDrawingDisabled(): boolean {
        return this.results.some((workingDrawing: IWorkingDrawingMappedItem) => {
            return workingDrawing.statusId !== WORKING_DRAWING_STATUS_ENUM.Accepted
                && workingDrawing.statusId !== WORKING_DRAWING_STATUS_ENUM.Cancelled
                && workingDrawing.statusId !== WORKING_DRAWING_STATUS_ENUM.Completed;
        });
    }

    public get canRequestWorkingDrawing(): boolean {
        return this.lotDesignPermissions.canRequestWorkingDrawing();
    }

    public get canCreateWorkingDrawing(): boolean {
        return this.lotDesignPermissions.canCreateWorkingDrawing();
    }

    public requestWorkingDrawing(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Request Working Drawings',
            message: 'Are you sure you want to Request Working Drawings?',
            confirmed: () => {
                this.workingDrawingsLogicService.requestWorkingDrawing(this.lotMappedItem.id).subOnce((_workingDrawing) => {
                    if (_workingDrawing) {
                        this.results = [this.workingDrawingsLogicService.$createMappedItem(_workingDrawing), ...this.results];
                        this.resultsChange(this.results);
                        this.cdRef.detectChanges();
                    }
                });
            }
        });
    }

    public requestWorkingDrawingSteps(): void {
        const mappedItem = this.workingDrawingsLogicService.$createMappedItem();
        this.openRequestWorkingDrawingStepsDialog(mappedItem, this.lotMappedItem, FormMode.Add);
    }


    public createWorkingDrawing(): void {
        const mappedItem = this.workingDrawingsLogicService.$createMappedItem(
            {
                lotId: this.lotMappedItem.id,
                amenities: {} as ILotAmenitiesDto
            }
        );

        this.cbDialog
            .open(WorkingDrawingDialogComponent, {
                data: {
                    mappedItem,
                    lotTypeEnumId: this.lotMappedItem.lotType,
                    lotMappedItem: this.lotMappedItem
                }
            })
            .afterClosed()
            .subOnce((_workingDrawing) => {
                if (_workingDrawing) {
                    this.results = [this.workingDrawingsLogicService.$createMappedItem(_workingDrawing), ...this.results];
                    this.resultsChange(this.results);
                    this.cdRef.detectChanges();
                }
            });
    }

    public viewWorkingDrawing(event: IWorkingDrawingMappedItem): void {
        this.workingDrawingSelected.emit(event);
    }

    private openRequestWorkingDrawingStepsDialog(mappedItem: IWorkingDrawingMappedItem, lotMappedItem: ILotMappedItem, mode: FormMode): void {
        this.cbDialog
            .open(RequestWorkingDrawingDialogComponentComponent, {
                data: { mappedItem, lotMappedItem, mode },
            })
            .afterClosed()
            .subOnce((result) => {
                if (result) {
                    this.lotMappedItem.$reload().subOnce();
                    this.queryUpdated();
                }
            });
    }

}
