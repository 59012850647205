import { IPermissionsPermissions } from '@app/core/permissions';

export class SettingsPermissionsController {
    protected permissionsPermissions: IPermissionsPermissions;
    // store can edit value in instance member for performance reasons
    // rather than checking with permission enum every time.
    protected _canEdit = false;
    constructor(permissionsPermissions: IPermissionsPermissions) {
        this.permissionsPermissions = permissionsPermissions;
    }

    public canView(): boolean {
        return this.permissionsPermissions.canViewSettings();
    }

    public canEdit(): boolean {
        return this.permissionsPermissions.canEditSettings();
    }
}
