import {environment} from '@src/environments/environment';
import * as _ from 'lodash';
import {Observable, tap} from 'rxjs';
import {BaseMappedItem} from '../base/base-mapped-item';
import {Computed} from '../base/computed-prop.decorator';
import {DtoProp} from '../base/dto-prop.decorator';
import {IStandardPlanStyleDto} from '../styles/interfaces/i.style.dto';
import {DesignImageType} from './enums/design.image.type.enums';
import {IStandardPlanCostDto} from './interfaces/i.standard-plan-cost.dto';
import {IAmenities, IStandardPlanDto, IStandardPlanImageDto} from './interfaces/i.standard-plan.dto';
import {IStandardPlanLogicService} from './interfaces/i.standard-plan.logic.service';
import {IStandardPlanMappedItem} from './interfaces/i.standard-plan.mapped';
import { IStandardPlanCategoryDto} from '@classictechsolutions/hubapi-transpiled-enums';

export class StandardPlanMappedItem
    extends BaseMappedItem<IStandardPlanDto, IStandardPlanMappedItem, IStandardPlanLogicService>
    implements IStandardPlanMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public description: string;
    @DtoProp public seriesId: number;
    @DtoProp public themeId: number;
    @DtoProp public amenities: IAmenities;
    @DtoProp public floorArea: number;
    @DtoProp public isActive: boolean;
    @DtoProp public isPublished: boolean;
    @DtoProp public qsComplexity: string;
    @DtoProp public designComplexity: string;
    @DtoProp public m2CostToBuild: number;
    @DtoProp public planCosts: IStandardPlanCostDto[];
    @DtoProp public images: IStandardPlanImageDto[];
    @DtoProp public category: IStandardPlanCategoryDto;
    @DtoProp public createdDate: DateString;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: DateString;
    @DtoProp public updatedByName: string;

    public styles: any[];
    public categories: any[];
    public uploadedImage?: File;

    public get planEntityId(): number {
        return this.id;
    }

    public get planEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    @Computed() public get stylesWithUrl(): IStandardPlanStyleDto[] {

        const stylesWithUri: IStandardPlanStyleDto[] = [];

        const _floorPlanImage = _.filter(this.images, { imageType: DesignImageType.FloorPlan, isDeleted: false, standardPlanId: this.id });

        this.$logicService
            .standardPlanStyles
            .then(_styles => {
                this.styles = _styles;

                _styles.forEach(style => {

                    const _filterredImages = _.filter(this.images, { imageType: DesignImageType.Exterior, isDeleted: false, styleId: style.id, standardPlanId: this.id });

                    const newStyle: IStandardPlanStyleDto = {
                        id: style.id,
                        isActive: style.isActive,
                        name: style.name,
                        imageType: DesignImageType.Exterior,
                        url: _filterredImages.length > 0
                            ? (`${environment.api}/${_filterredImages[0].path}/images/${_filterredImages[0].id}.${_filterredImages[0].extension}`).toLocaleLowerCase()
                            : this.PLACEHOLDER_URL,
                        isPlaceHolderImage: _filterredImages.length > 0 ? false : true
                    };

                    stylesWithUri.push(newStyle);
                });

                // Add Floor Plan to the list // DO NOT DELETE // THIS WILL GENERATE THE FLOOR PLAN TAB AT THE END
                const newFloorPlanStyle: IStandardPlanStyleDto = {
                    id: 0,
                    isActive: true,
                    name: 'Floor Plan',
                    imageType: DesignImageType.FloorPlan,
                    url: _floorPlanImage.length > 0
                        ? (`${environment.api}/${_floorPlanImage[0].path}/images/${_floorPlanImage[0].id}.${_floorPlanImage[0].extension}`).toLocaleLowerCase()
                        : this.PLACEHOLDER_URL,
                    isPlaceHolderImage: _floorPlanImage.length > 0 ? false : true
                };

                stylesWithUri.push(newFloorPlanStyle);
            });



        return stylesWithUri;

    }

    constructor(
        sourceData: IStandardPlanDto,
        logicService: IStandardPlanLogicService
    ) {
        super(sourceData, logicService, StandardPlanMappedItem, { planCosts: [] });
    }

    public readonly PLACEHOLDER_URL = 'assets/img/no-image-placeholder.png';

    public postImage(file: File, style: IStandardPlanStyleDto): Observable<IStandardPlanDto> {
        return this.$logicService
            .uploadPlanImage(file, this, style)
            .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
    }

    public deleteImage(imageId: string, planId: number): Observable<IStandardPlanDto> {
        return this.$logicService
            .deletePlanImage(imageId, planId)
            .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
    }


}
