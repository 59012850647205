<mat-icon *ngIf="value"
          [class]="class"
          [matTooltip]="toolTip">
    {{ value }}
</mat-icon>
<mat-icon *ngIf="svgValue"
          [class]="class"
          [matTooltip]="toolTip"
          [svgIcon]="svgValue">
</mat-icon>
<span *ngIf="!value && !svgValue">-</span>