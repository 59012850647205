import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LotCostPermissions } from '@app/core/permissions';
import { DesignSchemesLogicService } from '@app/logic/design-schemes';
import { ILotMappedItem } from '@app/logic/lots';
import { IPricingRevisionMappedItem } from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { IPricingRevisionDto, PricingRevisionStatusEnumId, PRICING_REVISION_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BlockingDialogComponent } from '@app/shared/components/dialog/blocking-dialog/blocking-dialog.component';
import { FinalisePricingRevisionDialogComponent } from '../finalise-pricing-revision-dialog/finalise-pricing-revision-dialog.component';
import { ManagePricingRevisionDialogComponent } from '../manage-pricing-revision-dialog/manage-pricing-revision-dialog.component';

@Component({
    selector: 'cb-pricing-revision-action-bar',
    templateUrl: './pricing-revision-action-bar.component.html',
    styleUrls: ['./pricing-revision-action-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingRevisionActionBarComponent implements OnInit {
    @Input() public readonly mappedItem: IPricingRevisionMappedItem;
    @Input() public readonly previousMappedItem: IPricingRevisionMappedItem;
    @Output() public mappedItemChange = new EventEmitter<IPricingRevisionMappedItem>();

    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public readonly goBack = new EventEmitter<void>();

    public designSchemeLotSpecVersion: number;
    public blockingRef: MatDialogRef<BlockingDialogComponent>;

    constructor(
        public readonly lotCostPermissions: LotCostPermissions,
        public readonly cbDialog: CbDialogService,
        public readonly designSchemeLogicService: DesignSchemesLogicService,
        public readonly currentUser: CurrentUserService,
        public readonly cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.previousMappedItem?.$reload().subOnce();
        this.designSchemeLogicService.$getMappedItem(this.mappedItem.designSchemeId).subOnce(result => {
            this.designSchemeLotSpecVersion = result.lotSpecVersion;
            this.cdRef.detectChanges();
        });
    }

    public goBackToPriceRevisionSearch(): void {
        this.goBack.emit();
    }

    public canEditPricingRevision(): boolean {
        return this.lotCostPermissions.canEditPricingRevision();
    }

    public canChangePricingRevisionStatus(): boolean {
        return this.lotCostPermissions.canChangePricingRevisionStatus();
    }

    public editRevision(): void {
        this.cbDialog
            .open(ManagePricingRevisionDialogComponent, {
                data: {
                    mappedItem: this.mappedItem.$clone(),
                    lotTypeEnumId: this.lotMappedItem.lotType,
                    mode: FormMode.Edit
                }
            })
            .afterClosed()
            .subOnce((result: IPricingRevisionDto) => {
                if (result) {
                    this.mappedItemChange.emit(this.mappedItem);
                }
            });
    }

    public setInQueue(): void {
        this.saveStatus(PRICING_REVISION_STATUS_ENUM.InQueue);
    }

    public setOnHold(): void {
        this.saveStatus(PRICING_REVISION_STATUS_ENUM.OnHold);
    }

    public setInProgress(): void {
        this.saveStatus(PRICING_REVISION_STATUS_ENUM.InProgress);
    }

    public inProgressDisabled(): boolean {
        return !this.mappedItem.canEdit || !this.mappedItem.assignedToUserId;
    }

    public exportItemsForCostX(): void {
        this.blockingRef = this.cbDialog.block('Generating CostX export...');
        this.lotMappedItem.generateCostXItemsReport(this.designSchemeLotSpecVersion).subOnce(() => {
            this.blockingRef.close();
        });
    }

    public finalise(): void {
        this.cbDialog
            .open(FinalisePricingRevisionDialogComponent, {
                data: {
                    currentMappedItem: this.mappedItem.$clone(),
                    previousMappedItem: this.previousMappedItem?.$clone() ?? undefined
                }
            })
            .afterClosed()
            .subOnce((result: IPricingRevisionDto) => {
                if (result) {
                    this.mappedItemChange.emit(this.mappedItem);
                    this.cdRef.detectChanges();
                }
            });
    }

    private saveStatus(status: PricingRevisionStatusEnumId): void {
        const dialog = this.cbDialog.block('Saving status...');
        this.mappedItem.statusId = status;
        this.mappedItem
            .$save()
            .subOnce(() => {
                this.mappedItemChange.emit(this.mappedItem);
                this.cdRef.detectChanges();
                dialog.close();
            });
    }

}
