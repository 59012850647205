import { Component, Input } from '@angular/core';
import { ILeadSearchDto } from '@app/logic/leads';
import { LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { IUserDto } from '@app/logic/users';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { LeadPermissions } from '@app/core/permissions';
import { viewLeadUrl } from '@app/views/leads/lead-routes';
import moment from 'moment';

@Component({
    selector: 'cb-lead-search-card',
    templateUrl: './lead-search-card.component.html',
    styleUrls: ['./lead-search-card.component.scss']
})
export class LeadSearchCardComponent {
    @Input() public readonly lead: ILeadSearchDto;
    @Input() public buildingConsultants: IUserDto[];

    constructor(
        public readonly navigationService: NavigationService,
        public readonly cbDialog: CbDialogService,
        public readonly leadPermissions: LeadPermissions
    ) { }

    public get contractType(): string {
        return LOT_CONTRACT_TYPE_ENUM[this.lead.contractType];
    }


    public getCallbackDate(): string {
        if (!this.lead.callbackDate || (this.lead.status as any === 'Lost') || (this.lead.status as any === 'Qualified')) {
            return undefined;
        }
        return moment(this.lead.callbackDate).format('YYYY-MM-DD');
    }

    public canView(): boolean | void {
        return this.leadPermissions.canView();
    }

    public viewLead(event): void {
        this.navigationService.navigate([viewLeadUrl(this.lead.id)], event);
    }

}
