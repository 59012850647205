import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '@app/logic/base/base-logic.service';
import { IQuoteLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { IQuoteLineLogicService } from '..';
import { QuoteLineMappedItem } from './quote-line.mapped';

@Injectable()
export class QuoteLineLogicService
    extends BaseLogicService<IQuoteLineDto, QuoteLineMappedItem>
    implements IQuoteLineLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'quotes',
            QuoteLineMappedItem
        );
    }

    public abandonQuoteLine(quoteLineId: number): Observable<boolean> {
        return this.$http.post(`${this.$baseUri}/quotelines/${quoteLineId}/abandon`);
    }

    public finaliseQuoteLine(quoteLineId: number): Observable<IQuoteLineDto> {
        return this.$http.post(`${this.$baseUri}/quotelines/${quoteLineId}/finalise`);
    }

    public download(quoteLineId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/${quoteLineId}/download`);
    }
}
