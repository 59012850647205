import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IPreConsentPlanMappedItem, PreConsentPlansLogicService } from '@app/logic/pre-consent-plans';
import { IPreConsentPlansLogicService } from '@app/logic/pre-consent-plans/interfaces/i.pre-consent-plans-logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IPreConsentPlanDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    preConsentPlanMappedItem: IPreConsentPlanMappedItem;
}

@Component({
    selector: 'cb-reject-preconsent-dialog',
    templateUrl: './reject-preconsent-dialog.component.html',
    styleUrls: ['./reject-preconsent-dialog.component.scss']
})
export class RejectPreconsentDialogComponent extends
    BaseDialogFormViewDirective<IPreConsentPlanDto, IPreConsentPlanMappedItem, IPreConsentPlansLogicService> {

    public static readonly MIN_WIDTH = '650px';

    constructor(
        public readonly dialogRef: MatDialogRef<RejectPreconsentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(PreConsentPlansLogicService) private readonly preConsentPlansLogicService: PreConsentPlansLogicService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.preConsentPlanMappedItem;
    }

    public rejectPreconsent(): void {
        const preconsentPlanDto = this.mappedItem.$getMappedDtoItem();
        this.mappedItem.reject(this.mappedItem.id, preconsentPlanDto).subOnce((result) => {
            this.dialogRef.close(result);
        });
    }
}
