import { BuildStageMappedItem, BuildStagesLogicService, IBuildStageDto, IBuildStageMappedItem } from '@app/logic/build-stages';
import { Component, OnDestroy } from '@angular/core';

import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { BuildStageDialogComponent } from './components/build-stage-dialog/build-stage-dialog.component';
import { CONSTRUCTION_MANAGER_ROLE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { CbEnumService } from '@app/core/services/enum/enum.service';
import { DragulaService } from 'ng2-dragula';
import { PermissionsPermissions } from '@app/core/permissions';
import { Subscription } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';

const MAT_HEADER_ROW_CLASSNAME = 'mat-header-row';

@Component({
    selector: 'cb-build-stages',
    templateUrl: './build-stages.component.html',
    styleUrls: ['./build-stages.component.scss'],
    providers: [BuildStagesLogicService]
})
export class BuildStagesComponent extends BaseSimpleListViewDirective<IBuildStageDto, BuildStagesLogicService> implements OnDestroy {

    public searchResults: IBuildStageMappedItem[] = [];
    public constructionManagerRoles = CONSTRUCTION_MANAGER_ROLE_ENUM.toSelectList();
    public buildStagesLoaded = false;

    public subscriptions = new Subscription();

    public readonly BUILD_STAGES = 'BUILD_STAGES';
    public dragulaModel: number[] = [];
    public sortOrderChanged = false;
    /** Number of header rows on the BUILD_STAGES table */
    private readonly numHeaderRows = 1;

    public readonly displayedColumns: string[] = ['code', 'label', 'manager', 'active', 'isPreconsentStage','actions'];

    constructor(
        public readonly enumService: CbEnumService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        private readonly dragulaService: DragulaService,
        private readonly buildStagesLogicService: BuildStagesLogicService
    ) {
        super(
            dialog,
            buildStagesLogicService,
            BuildStageDialogComponent,
            'Build Stage',
            BuildStageMappedItem,
            permissionsPermissions
        );
        this.setupDragula();
    }

    protected handleSearchResults = (searchResults: Array<IBuildStageDto>) => {
        super.handleSearchResults(searchResults);
        this.setDragulaModel();
    };

    public ngOnDestroy(): void {
        this.dragulaService.destroy(this.BUILD_STAGES);
    }

    protected handleNewItem = (item: any) => {
        super.handleNewItem(item);
        this.setDragulaModel();
    };

    public getConstructionManagerRole(constructionManagerRole: number): string {
        let returnValue = '';
        if (constructionManagerRole) {
            returnValue = this.constructionManagerRoles.find(role => role.id === constructionManagerRole).label;
        }

        return returnValue;
    }

    public saveOrder(): void {
        this.dialog
            .confirm({
                message: 'Are you sure you want to save the order of the Build Stages?',
                confirmed: this.handleSaveOrder,
            });
    }

    private readonly handleSaveOrder = (): void => {
        this.buildStagesLogicService
            .saveOrder(this.searchResults)
            .subOnce((result) => {
                this.searchResults = result;
                this.setDragulaModel();
                this.sortOrderChanged = false;
            });
    };

    /** Creates dragula group for build stages table and suscribes to dragula observables */
    private setupDragula(): void {
        this.dragulaService.createGroup(
            this.BUILD_STAGES,
            {
                removeOnSpill: false,
                accepts: (el: Element) => !el.classList.contains(MAT_HEADER_ROW_CLASSNAME), // do not allow move of mat-header-row
                moves: (el: Element) => !el.classList.contains(MAT_HEADER_ROW_CLASSNAME), // do not allow move of mat-header-row
                revertOnSpill: true
            }
        );

        this.dragulaService.dropModel(this.BUILD_STAGES)
            .subscribe(
                ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                    if (sourceIndex === targetIndex) {
                        return;
                    }
                    moveItemInArray(this.searchResults, sourceIndex - this.numHeaderRows, targetIndex - this.numHeaderRows);
                    this.setDragulaModel();
                    this.sortOrderChanged = true;
                }
            );
    }
    /** Sets the dragula model for drag and drop - seperate to the actual array of data because
     *  the dragula model needs an item for the header row aswell...
     */
    private setDragulaModel(): void {
        this.dragulaModel = this.searchResults.map(x => x.id);
        // add a placeholder row for each header row delcared
        for (let headerRow = 0; headerRow < this.numHeaderRows; headerRow++) {
            this.dragulaModel.unshift(0);
        }
    }
}
