import { Component, OnInit, Input } from '@angular/core';
import { ILotMappedItem } from '@app/logic/lots';
import { toPromisedArray } from 'cb-hub-lib';
import { PaymentTemplatesLogicService } from '@app/logic/payment-templates/payment-templates.logic.service';
import { IPaymentTemplateDto } from '@app/logic/payment-templates/interface/i.payment-template.dto';
import { startCase } from 'lodash';
import { LOT_JOB_STATUS_ENUM, MARKET_STATUS_ENUM, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import moment from 'moment';

@Component({
    selector: 'cb-view-house-land-details',
    templateUrl: './view-house-land-details.component.html',
    styleUrls: ['./view-house-land-details.component.scss']
})
export class ViewHouseLandDetailsComponent implements OnInit {
    public canCreateLead: boolean;
    @Input() public mappedItem: ILotMappedItem;
    public close: (param: any) => void;
    public salePrice: number;
    public paymentTemplates: IPaymentTemplateDto[];
    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;
    constructor(private readonly paymentTemplateLogic: PaymentTemplatesLogicService) { }

    public ngOnInit(): void {
        this.paymentTemplates = toPromisedArray(this.paymentTemplateLogic.getListByContractTypeAndBuildType(this.mappedItem?.contractType, this.mappedItem?.buildType.id));
        if (this.canCreateLead == null) {
            this.canCreateLead = true;
        }
        this.salePrice = this.mappedItem?.financials?.sellPrice;
    }

    public jobStatusDisplay(): string {
        const value: number = this.mappedItem?.jobStatus;
        return value > 0 ? startCase(LOT_JOB_STATUS_ENUM[value]) : 'Not Started';
    }

    public landTitleDisplay(): string {
        if (this.mappedItem?.hasTitle) {
            return 'Has Title';
        }
        if (this.mappedItem?.expectedTitleDate) {
            const titleDate = moment(this.mappedItem?.expectedTitleDate).format('Do MMMM YYYY');
            return `Expected by ${titleDate}`;
        }
        return 'Unknown at this time';
    }

    public isPreRelease(): boolean {
        let isPreRelease = false;
        if (MARKET_STATUS_ENUM.PreRelease === this.mappedItem?.marketStatus) {
            isPreRelease = true;
        }
        return isPreRelease;
    }

}
