import { Component } from '@angular/core';
import { SpecGroupsLogicService } from '@app/logic/spec-groups/spec-groups.logic.service';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { ISpecGroupDto } from '@app/logic/spec-groups';
import { SpecGroupMappedItem } from '@app/logic/spec-groups/spec-group.mapped';
import { SpecGroupDialogComponent } from './spec-group-dialog/spec-group-dialog.component';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-spec-groups',
    templateUrl: './spec-groups.component.html',
    styleUrls: ['./spec-groups.component.scss'],
    providers: [SpecGroupsLogicService, UsersLogicService]
})
export class SpecGroupsComponent extends BaseSimpleListViewDirective<ISpecGroupDto, SpecGroupsLogicService> {
    public readonly displayedColumns: string[] = [
        'specGroup',
        'sortOrder',
        'isQsOnly',
        'actions'
    ];

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly specGroupsLogicService: SpecGroupsLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
    ) {
        super(
            cbDialog,
            specGroupsLogicService,
            SpecGroupDialogComponent,
            'House Area',
            SpecGroupMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<ISpecGroupDto>): Array<ISpecGroupDto> {
        return searchResults.sort((a, b) => a.sortOrder - b.sortOrder);
    }
}



