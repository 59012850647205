

import { Component, OnInit } from '@angular/core';

import { AddressRegionsLogicService } from '@app/logic/address-regions';
import { IStandardPlanMappedItem } from '@app/logic/standard-plans/interfaces/i.standard-plan.mapped';
import { IStandardPlanStyleDto } from '@app/logic/styles/interfaces/i.style.dto';
import { StandardPlanLogicService } from '@app/logic/standard-plans/standard-plan.logic.service';
import { StylesLogicService } from '@app/logic/styles/style.logic.service';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-standard-plans',
    templateUrl: './standard-plans.component.html',
    styleUrls: ['./standard-plans.component.scss'],
    providers: [
        StandardPlanLogicService,
    ]
})
export class StandardPlansComponent implements OnInit {

    public standardPlans: IStandardPlanMappedItem[] = [];
    public standardPlanssLoaded = false;

    public addressRegions: IAddressRegionDto[] = [];
    public addressRegionsLoaded = false;

    public styles: IStandardPlanStyleDto[] = [];
    public stylesLoaded = false;


    constructor(
        private readonly standardPlanLogicService: StandardPlanLogicService,
        private readonly addressRegionsLogicService: AddressRegionsLogicService,
        private readonly stylesLogicService: StylesLogicService
    ) {
        this.standardPlanLogicService.loadStandardPlanStyles();
    }

    public ngOnInit(): void {
        this.standardPlanLogicService
            .getMappedStandardPlans()
            .subOnce((result) => {
                this.standardPlans = result;
                this.getStyles();
                this.standardPlanssLoaded = true;
            });

        this.addressRegionsLogicService
            .$getList()
            .subOnce((result) => {
                this.addressRegions = result;
                this.addressRegionsLoaded = true;
            });

    }

    public getStyles(): void {
        this.stylesLogicService
            .$getList()
            .subOnce((result) => {
                this.stylesLoaded = true;
            });
    }

    public getFilterredPlans(standardPlans: IStandardPlanMappedItem[]): void {
        this.standardPlans = standardPlans;
    }

}
