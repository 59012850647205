import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LotDesignPermissions } from '@app/core/permissions';
import { ILotMappedItem } from '@app/logic/lots';
import { IWorkingDrawingMappedItem } from '@app/logic/working-drawings';
import { SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-working-drawing-view-tabs',
    templateUrl: './working-drawing-view-tabs.component.html',
    styleUrls: ['./working-drawing-view-tabs.component.scss']
})
export class WorkingDrawingViewTabsComponent {

    @Output() public selectedTabChanged = new EventEmitter<number>();
    @Input() public selectedIndex: number;
    @Input() public mappedItem: IWorkingDrawingMappedItem;
    @Input() public lotMappedItem: ILotMappedItem;

    public documentCount = 0;
    public questionsCount = 0;

    public readonly SYSTEM_AREA = SYSTEM_AREA_ENUM.WorkingDrawing;

    constructor(public readonly lotDesignPermissions: LotDesignPermissions,) { }

    public onTabChanged(index): void {
        this.selectedTabChanged.emit(index);
    }
}
