import { Component } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { IBusinessAccountTradeTypeDto } from '@app/logic/business-accounts/interfaces/i.business-account.dto';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { ContractGenerationLogicService } from '@app/logic/contract-generation/contract-generation.logic-service';
import { TradeTypesLogicService } from '@app/logic/trade-types';
import { UsersLogicService } from '@app/logic/users';
import {
    CONTRACT_GENERATION_DOC_FORMAT_ENUM,
    CONTRACT_GENERATION_DOC_TYPE_ENUM,
    IContractGenerationDto,
    PAYMENT_FREQUENCY_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import _, { find, orderBy } from 'lodash';

@Component({
    selector: 'cb-contract-generation',
    templateUrl: './contract-generation.component.html',
    styleUrls: ['./contract-generation.component.scss']
})
export class ContractGenerationComponent {

    public contractGenerationDto: IContractGenerationDto = { tradeTypes: [] } as IContractGenerationDto;

    public contractGenerationDocFormats = CONTRACT_GENERATION_DOC_FORMAT_ENUM.toLookup().filter(x => x.id !== CONTRACT_GENERATION_DOC_FORMAT_ENUM.None);
    public contractGenerationDocTypes = CONTRACT_GENERATION_DOC_TYPE_ENUM.toLookup();
    public paymentFrequencies = PAYMENT_FREQUENCY_ENUM.toLookup();

    public availableBusinessEntities: IBusinessEntityDto[];
    public availableTradeTypes: IBusinessAccountTradeTypeDto[] = [];

    constructor(
        private readonly usersLogicService: UsersLogicService,
        public readonly currentUserService: CurrentUserService,
        public readonly businessEntityLogicService: BusinessEntitiesLogicService,
        public readonly tradeTypesLogicService: TradeTypesLogicService,
        public readonly contractGenerationLogicService: ContractGenerationLogicService,
    ) {
        this.initialiseAvailableBusinessEntities();
        this.initialiseAvailableTradeTypes();
    }


    public initialiseAvailableBusinessEntities(): void {
        this.businessEntityLogicService.$getList().subOnce((regions) => {

            this.usersLogicService.$getItem(this.currentUserService.guid).subOnce(user => {

                this.availableBusinessEntities = _.filter(regions, (region) => {
                    if (!region.isActive) {
                        return false;
                    }
                    return _.indexOf(user.selectedBusinessEntities, region.id) > -1;
                });

            });

        });

    }

    public initialiseAvailableTradeTypes(): void {
        this.tradeTypesLogicService.$getList().subOnce(result => {
            result.forEach(item => {
                if (item.parentId) {
                    let parent = find(this.availableTradeTypes, { id: item.parentId });
                    if (parent) {
                        parent.children.push(item);
                    } else {
                        parent = { id: item.parentId, label: item.parentLabel } as IBusinessAccountTradeTypeDto;
                        parent.children = [item];
                        this.availableTradeTypes.push(parent);
                    }
                }
            });
            this.availableTradeTypes = orderBy(this.availableTradeTypes, 'label');
            this.availableTradeTypes.forEach(item => item.children = orderBy(item.children, 'label'));
        });
    }

    public onTradeTypeSelectionChanged(tradeType: IBusinessAccountTradeTypeDto, checked: boolean): void {

        if (checked) {
            this.contractGenerationDto.tradeTypes.push(tradeType.id);
        } else {
            const removeIndex = this.contractGenerationDto.tradeTypes.findIndex(itm => itm === tradeType.id);

            if (removeIndex > -1) {
                this.contractGenerationDto.tradeTypes.splice(removeIndex, 1);
            }
        }

    }

    public generateContract(): void {
        this.contractGenerationLogicService.generateContract(this.contractGenerationDto).subOnce();
    }
}
