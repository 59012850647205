<div class="flex-row flex">
    <cb-my-leads-side-panel class="cb-margin flex-row"
                            *ngIf="isBuildingConsultant.value"
                            [currentLeadId]="currentLeadId"
                            (leadSelected)="leadSelected($event)"
                            [leadUpdated]="leadUpdated">
    </cb-my-leads-side-panel>
    <cb-lead-edit class="cb-margin flex"
                  style="overflow: hidden;"
                  *ngIf="currentLeadId"
                  [id]="currentLeadId"
                  (leadChanged)="updateLead($event)"
                  [isBuildingConsultant]="isBuildingConsultant.value">
    </cb-lead-edit>
    <div class="cb-margin-2x flex-col flex"
         *ngIf="!currentLeadId">
        <cb-info-message message="No Lead has been selected"
                         iconClass="cb-grey"></cb-info-message>
    </div>

    <!-- <mat-card *ngIf="collapsed"
              class="collapsed-card">
        <span class="mat-title collapsed-title">My Leads</span>
    </mat-card> -->
    <cb-lead-side-bar-card-new *ngIf="lead"
                               [lead]="lead"
                               class="cb-margin">
    </cb-lead-side-bar-card-new>
</div>
