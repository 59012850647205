import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IStandardPlanStyleDto } from '../styles/interfaces/i.style.dto';
import { IStandardPlanCostDto } from './interfaces/i.standard-plan-cost.dto';
import { IStandardPlanCostLogicService } from './interfaces/i.standard-plan-cost.logic.service';
import { IStandardPlanCostMappedItem } from './interfaces/i.standard-plan-cost.mapped';

export class StandardPlanCostMappedItem
    extends BaseMappedItem<IStandardPlanCostDto, IStandardPlanCostMappedItem, IStandardPlanCostLogicService>
    implements IStandardPlanCostMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public standardPlanId: number;
    @DtoProp public regionId: number;
    @DtoProp public addressRegion: IAddressRegionDto;
    @DtoProp public styleId: number;
    @DtoProp public style: IStandardPlanStyleDto;
    @DtoProp public totalCost: number;
    @DtoProp public m2Cost: number;
    @DtoProp public isActive: boolean;
    @DtoProp public createdDate: DateString;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: DateString;
    @DtoProp public updatedByName: string;

    public entityUri: string;
    public entityId: number | string;

    constructor(
        sourceData: IStandardPlanCostDto,
        logicService: IStandardPlanCostLogicService
    ) {
        super(
            sourceData,
            logicService,
            StandardPlanCostMappedItem,
            { isActive: true } // default data
        );
    }

    public saveStandardPlanCost(): Observable<IStandardPlanCostDto> {
        return this.$logicService.saveStandardPlanCost('standardplans', this.$getMappedDtoItem())
            .pipe(
                tap((item) => this.$updateThisAndOriginal(item))
            );
    }

}
