import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TableColumn} from '@app/shared/components/table/base-table.component';
import {NavigationService} from '@app/core/services/navigation/navigation.service';

// tslint:disable: member-ordering

interface IData {
    rate: any;
    productId: number;
}

@Component({
    templateUrl: './rate-composition-dialog.component.html',
    styleUrls: ['./rate-composition-dialog.component.scss'],
    providers: [NavigationService]
})
export class RateCompositionDialogComponent {
    public static readonly MIN_WIDTH = '70%';

    public rate: any;
    public rateTotal = 0;
    public percentTotal = 0;

    public viewProduct = (product): void => {
        this.navigationService.redirectTo(`view-product/${product.productId}/details`);
        this.cancel();
    };

    public readonly columns: Array<TableColumn> = [
        { name: 'Item', valueProp: 'label', type: 'text' },
        { name: 'Rate', valueProp: 'rate', type: 'currency' },
        { name: '%', valueProp: 'percentage', type: 'percent' },
        {
            name: '', valueProp: 'actions', type: 'actions', actions: [
                {
                    function: this.viewProduct,
                    icon: 'pageview'
                }
            ]
        }
    ];

    public readonly footerColumns: Array<TableColumn> = [
        { name: 'Item', value: 'Total' },
        { name: 'Rate', value: this.rateTotal.toString() },
        { name: '%', value: `${this.percentTotal}%` }
    ];

    constructor(
        public readonly dialogRef: MatDialogRef<RateCompositionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly navigationService: NavigationService,
    ) {
        this.rate = data.rate;
        this.setTotalRate();
        this.setTotalPercent();
    }

    private setTotalPercent(): void {
        if (this.rate.locations[0].rates.length > 1) {
            this.percentTotal = this.rate.locations[0].rates.reduce((accumulator, currentValue) => accumulator.percentage + currentValue.percentage);
        } else {
            this.percentTotal = this.rate.locations[0].rates[0].percentage;
        }
        if (Number.isNaN(this.percentTotal)) {
            this.percentTotal = 100;
        }
    }

    private setTotalRate(): void {
        this.rateTotal = this.rate.locations[0].aggregateValue;
        if (Number.isNaN(this.rateTotal)) {
            this.rateTotal = 0;
        }
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
