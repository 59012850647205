<div class="flex-col flex">
    <div class="end-center flex-row">
        <button mat-raised-button
                (click)="onAddClicked()"
                color="primary">
            Add Contact to Account
        </button>
    </div>
    <div class="contacts-container flex-wrap flex">
        <div class="cb-padding flex-33 flex-col md-50 lt-md-100"
             *ngFor="let contact of contacts">
            <mat-card>
                <mat-card-header>
                    <mat-icon mat-card-avatar
                              class="contact-icon">person</mat-icon>
                    <mat-card-title>
                        <small>{{contact.name}}</small>
                    </mat-card-title>
                    <mat-card-subtitle class="cb-no-margin">{{contact.position || '-'}}</mat-card-subtitle>
                    <div class="end-center flex-row flex">
                        <mat-icon *ngIf="contact.isDefaultSupplier"
                                  class="help-cursor cb-grey"
                                  title="Set as Default">
                            settings
                        </mat-icon>
                        <mat-icon *ngIf="contactIsSales(contact)"
                                  class="help-cursor cb-grey"
                                  title="Sales Role">
                            info
                        </mat-icon>
                        <mat-icon *ngIf="contactIsOrders(contact)"
                                  class="help-cursor cb-grey"
                                  title="Orders Role">
                            shopping_cart
                        </mat-icon>
                        <mat-icon *ngIf="contactIsAccounts(contact)"
                                  class="help-cursor cb-grey"
                                  title="Accounts Role">
                            monetization_on
                        </mat-icon>
                        <mat-icon *ngIf="contactIsInstaller(contact)"
                                  class="help-cursor cb-grey"
                                  title="Installer Role">
                            build
                        </mat-icon>
                        <mat-icon *ngIf="contactIsHealthAndSafety(contact)"
                                  class="help-cursor cb-grey"
                                  title="Health & Safety Role">
                            enhanced_encryption
                        </mat-icon>
                    </div>
                </mat-card-header>
                <mat-card-content class="flex-row flex">
                    <form class="flex-col flex"
                          #contactForm="ngForm">
                        <div style="min-height: 60px;">
                            <cb-input *ngIf="isEditing[contact.id]"
                                      label="Position"
                                      name="position"
                                      class="cb-no-margin"
                                      [(ngModel)]="contact.position"></cb-input>
                        </div>
                        <cb-input label="Email"
                                  name="email"
                                  class="cb-no-margin"
                                  [ngModel]="contact.email"
                                  [readonly]="true"></cb-input>
                        <cb-info-message class="cb-margin-bottom"
                                         *ngIf="!contact.isPortalAccessEnabled && contact.portalUsername && hasDuplicateEmail[contact.id]"
                                         [message]="'The email address is taken. Try another'"
                                         [icon]="'info_circle'"></cb-info-message>
                        <cb-input label="Mobile"
                                  name="mobile"
                                  class="cb-no-margin"
                                  [ngModel]="contact.phoneMobile"
                                  [readonly]="true"></cb-input>
                        <div style="min-height: 40px;">
                            <cb-checkbox *ngIf="contact.portalUsername"
                                         label="Portal Access Activated"
                                         name="isPortalAccessEnabled"
                                         class="cb-no-margin"
                                         [(ngModel)]="contact.isPortalAccessEnabled"
                                         [readonly]="!isEditing[contact.id] || (hasDuplicateEmail[contact.id] && !contact.isPortalAccessEnabled)">
                            </cb-checkbox>
                        </div>
                        <div style="min-height: 40px;">
                            <cb-checkbox *ngIf="contact.portalUsername"
                                         label="Receive Daily Emails"
                                         name="receivesDailyEmails"
                                         class="cb-no-margin"
                                         [(ngModel)]="contact.receivesDailyEmails"
                                         [readonly]="!isEditing[contact.id] || !contact.isPortalAccessEnabled">
                            </cb-checkbox>
                        </div>
                    </form>
                </mat-card-content>
                <mat-card-actions class="flex-row"
                                  [ngSwitch]="isEditing[contact.id]">
                    <div class="flex-row flex layoutGap-16">
                        <button *ngSwitchDefault
                                mat-button
                                class="cb-grey"
                                (click)="onManageRolesClicked(contact)">
                            <mat-icon>assignment</mat-icon>
                        </button>
                        <button *ngSwitchDefault
                                mat-button
                                class="cb-grey"
                                (click)="editContact(contact)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngSwitchCase="true"
                                mat-button
                                class="cb-grey"
                                (click)="onDeleteClicked(contact)">
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                        <button *ngSwitchCase="true"
                                mat-button
                                class="cb-grey"
                                (click)="onSwapClicked(contact)">
                            <mat-icon>swap_calls</mat-icon>
                        </button>
                    </div>
                    <div class="end-center flex-row flex">
                        <button *ngSwitchDefault
                                mat-raised-button
                                (click)="onViewClicked(contact.contactId)">View</button>
                        <button *ngSwitchCase="true"
                                mat-raised-button
                                color="primary"
                                [disabled]="!contactForm.dirty || !contactForm.valid"
                                (click)="onSaveClicked(contact)">Save</button>
                        <button *ngSwitchCase="true"
                                mat-raised-button
                                (click)="onCancelClicked(contact)">Cancel</button>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
