
    <!-- <cb-dialog dialogHeading="Request Scheme - Step {{selectedTabStep + 1}}"
           fxLayout="column"
           (cancelDialog)="cancel(true)"> -->
<cb-dialog class="flex-col"
           dialogHeading="Request Working Drawing"
           (cancelDialog)="cancel(true)">
    <form class="flex-col flex"
          #requestSchemeForm="ngForm"
          *cbWaitFor="mappedItem">
        <mat-tab-group class="cb-wizard"
                       [dynamicHeight]="true"
                       [(selectedIndex)]="selectedTabStep">
            <mat-tab>
                <form class="flex-col flex"
                      #step1Form="ngForm">
                    <cb-request-working-drawing-step-one [mappedItem]="mappedItem">
                    </cb-request-working-drawing-step-one>
                </form>
            </mat-tab>

            <!-- <mat-tab>
                <form #step2Form="ngForm"
                      class="flex-col flex">
                    <cb-request-working-drawing-step-two [mappedItem]="mappedItem">
                    </cb-request-working-drawing-step-two>
                </form>
            </mat-tab> -->
        </mat-tab-group>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="back()"
                    *ngIf="canGoBack()">
                Back
            </button>

            <!-- <button mat-raised-button
                    color="primary"
                    *ngIf="canGoNext()"
                    [disabled]="!isStepComplete(step1Form.form)"
                    (cbClick)="next()">
                Next
            </button> -->
            <button mat-raised-button
                    color="primary"
                    [disabled]="!isStepComplete(step1Form.form)"
                    (cbClick)="requestWorkingDrawing()"
                    *ngIf="canSeeSave()"
                    [clickLimit]="1">
                Save
            </button>

            <!-- <button mat-raised-button
                    color="primary"
                    [disabled]="isSaveDisabled(step1Form.form)"
                    (cbClick)="requestWorkingDrawing()"
                    *ngIf="canSeeSave()"
                    [clickLimit]="1">
                Save
            </button> -->
            <button mat-raised-button
                    (click)="cancel(true)">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
