import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ConnectionsComponent } from './connections.component';

@NgModule({
    declarations: [ConnectionsComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        CbFormsModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        RouterModule.forRoot([
            {
                component: ConnectionsComponent,
                path: 'connections',
                data: { breadcrumb: 'Connections' },
            }
        ]),
    ]
})
export class ConnectionsModule { }
