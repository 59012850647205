import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IDocumentUploadDto } from '@app/logic/documents/interfaces/i.document.dto';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../../base/base-logic.service';
import { BuildingConsentMappedItem } from './building-consent.mapped';
import { IBuildingConsentLogicService } from './interfaces/i.building-consent-logic.service';
import { IBuildingConsentDto } from './interfaces/i.building-consent.dto';
import { IBuildingConsentMappedItem } from './interfaces/i.building-consent.mapped';

@Injectable()
export class BuildingConsentLogicService
    extends BaseLogicService<IBuildingConsentDto, IBuildingConsentMappedItem>
    implements IBuildingConsentLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildingconsents',
            BuildingConsentMappedItem
        );
    }

    public mapBuildingConsent(data = {} as Partial<IBuildingConsentDto>): IBuildingConsentMappedItem {
        return this.$createMappedItem(data);
    }

    public getElapsedTime(id: number): Observable<string> {
        return this.$http.get(`${this.$baseUri}/elapsedtime/${id}`);
    }

    public requestPrimaryBuildingConsent(lotId: number, documentUploadDto?: IDocumentUploadDto): Observable<IBuildingConsentDto> {
        let data: FormData | null;
        if (documentUploadDto) {
            data = new FormData();
            data.set('file', documentUploadDto.file);
            data.set('name', documentUploadDto.name);
            data.set('documentGroupId', documentUploadDto.documentGroupId.toString());
            data.set('documentTypeId', documentUploadDto.documentTypeId.toString());
        }
        return this.$http.post(`${this.$baseUri}/requestprimarybuildingconsentforlot/${lotId}`, data ?? {});
    }
}
