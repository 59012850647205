import { Injectable, Injector } from '@angular/core';
import { UtilFiles } from '@app/shared/utils/file.util';
import { ICustomProjectReleaseLotDesignSummaryDto } from '@app/views/projects/release/lots/design/release-lots-design.component';
import {
    IProjectReleaseLotDesignSummaryDto,
    IProjectReleaseLotDto,
    IProjectReleaseStageDefaultSupplierDto,
    IProjectReleaseStageDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable, of } from 'rxjs';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IReleaseStageMappedItem } from './interfaces/i.release-stage.mapped';
import { IReleaseStagesLogicService } from './interfaces/i.release-stages.logic.service';
import { ReleaseStageMappedItem } from './release-stage.mapped';
import { IProjectReleaseStageDefaultProductSupplierDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/ProjectReleaseStageDefaultProductSupplierDto';

@Injectable()
export class ReleaseStagesLogicService
    extends BaseLogicService<IProjectReleaseStageDto, IReleaseStageMappedItem>
    implements IReleaseStagesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('releasestages', ReleaseStageMappedItem);
    }

    public getProjectReleaseStageLots(projectReleaseStageId: number): Observable<IProjectReleaseLotDto[]> {
        return this.$http.get<Array<IProjectReleaseLotDto>>(`${this.$baseUri}/${projectReleaseStageId}/lots`);
    }

    public getProjectReleaseStageDesignSummaryLots(projectReleaseStageId: number): Observable<ICustomProjectReleaseLotDesignSummaryDto[]> {
        return this.$http.get<Array<IProjectReleaseLotDesignSummaryDto>>(`${this.$baseUri}/${projectReleaseStageId}/lots/design`).pipe(
            map((array: IProjectReleaseLotDesignSummaryDto[]) => {
                return array.map(lot => ({
                    ...lot,
                    $shouldRequestDesignScheme: false,
                    $shouldApplyConcept: false,
                }));
            }));
    }

    public getProjectReleaseStagesByProjectId(projectId: number): Observable<IProjectReleaseStageDto[]> {
        return this.$http.get<Array<IProjectReleaseStageDto>>(`${this.$baseUri}/?projectid=${projectId}`);
    }

    public importLots(releaseId: number, file: File, importCustomJobNumbers: boolean): Observable<any> {

        if (!file) {
            return of('file-none');
        } else if (!file.name.match(UtilFiles.isExcelFile)) {
            return of('file-type');
        }

        const fd = new FormData();
        fd.append('file', file);
        fd.append('importCustomJobNumbers', importCustomJobNumbers.toString());

        return this.$http.post(`${this.$baseUri}/${releaseId}/lots/upload`, fd);
    }

    public deleteDefaultInstaller(releaseId: number, tradeTypeId: number): Observable<IProjectReleaseStageDefaultSupplierDto> {
        return this.$http.delete(`/${this.$baseUri}/${releaseId}/defaultinstallers/${tradeTypeId}`);
    }

    public deleteDefaultSupplier(releaseId: number, supplyTypeId: number): Observable<IProjectReleaseStageDefaultProductSupplierDto> {
        return this.$http.delete(`/${this.$baseUri}/${releaseId}/defaultsuppliers/${supplyTypeId}`);
    }

    public updateDefaultInstaller(releaseId: number, payload: IProjectReleaseStageDefaultSupplierDto): Observable<any> {
        return this.$http.post(`${this.$baseUri}/${releaseId}/defaultinstallers`, payload);
    }

    public updateDefaultSupplier(releaseId: number, payload: IProjectReleaseStageDefaultProductSupplierDto): Observable<any> {
        return this.$http.post(`${this.$baseUri}/${releaseId}/defaultsuppliers`, payload);
    }

    public addAllDefaultInstallers(releaseId: number): Observable<any> {
        return this.$http.post(`${this.$baseUri}/${releaseId}/defaultinstallers/add`);
    }

    public addAllDefaultSuppliers(releaseId: number): Observable<any> {
        return this.$http.post(`${this.$baseUri}/${releaseId}/defaultsuppliers/add`);
    }

    public getAvailableTrades(releaseId: number): Observable<any> {
        return this.$http.get(`${this.$baseUri}/trades?id=${releaseId}`);
    }

    public getAvailableSupplyTypes(releaseId: number): Observable<any> {
        return this.$http.get(`${this.$baseUri}/supplytypes?id=${releaseId}`);
    }

    public costSummaryLots(releaseId: number): Observable<any> {
        return this.$http.get(`${this.$baseUri}/${releaseId}/lots/finances`);
    }
}
