import {COST_TYPE_ENUM, CostTypeEnumId} from '@classictechsolutions/hubapi-transpiled-enums';
import {IEnumLookup} from '@classictechsolutions/typescriptenums';
import {IItemsCount} from './i.items-count';

export const costTypeOrder = {
    [COST_TYPE_ENUM.Actual]: 1,
    [COST_TYPE_ENUM.Provisional]: 2,
    [COST_TYPE_ENUM.Estimate]: 3,
    [COST_TYPE_ENUM.DescriptiveOnly]: 4,
    [COST_TYPE_ENUM.OwnersCare]: 5,
    [COST_TYPE_ENUM.NoCharge]: 6,
    [COST_TYPE_ENUM.Quote]: 7,
    [COST_TYPE_ENUM.None]: 8,
};

export class CostTypesListItem implements IItemsCount, IEnumLookup<CostTypeEnumId> {
    // IEnumLookup members
    public id: CostTypeEnumId;
    public label: string;
    public isActive: boolean;
    public code: string;

    // extra members
    public itemsCount = 0;
    public readonly plainLabel: string;

    constructor(
        costType: IEnumLookup<CostTypeEnumId>,
        itemCount: number
    ) {
        Object.assign(this, costType);
        this.plainLabel = this.label.split(' (').shift();
        this.itemsCount = itemCount;
        this.updateLabel();
    }

    private updateLabel(): void {
        this.label = `${this.plainLabel} (${this.itemsCount})`;
    }
}
