import {BuildProgrammePermissionEnum} from '@app/shared/enums/permissions';
import {BasePermissions, IBaseSystemAreaPermissions} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';


export interface IBuildProgrammePermissions extends IBaseSystemAreaPermissions {
    canView(): boolean;
    canCreate(): boolean;
    canEdit(): boolean;
    canViewAll(): boolean;
    canSearch(): boolean;
}

@Injectable()
export class BuildProgrammePermissions
    extends BasePermissions<BuildProgrammePermissionEnum>
    implements IBuildProgrammePermissions {
    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('buildprogramme', BuildProgrammePermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(BuildProgrammePermissionEnum.CanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(BuildProgrammePermissionEnum.CanCreate);
    }

    public canEdit(): boolean {
        return this.permissionObject(BuildProgrammePermissionEnum.CanEdit);
    }

    public canViewAll(): boolean {
        return this.permissionObject(BuildProgrammePermissionEnum.CanViewAll);
    }

    public canSearch(): boolean {
        return this.permissionObject(BuildProgrammePermissionEnum.CanSearch);
    }
}
