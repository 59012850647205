import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ILotBuildOrdersSearch } from '@app/core/services/user-cache/user-cache-areas';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { IPurchaseOrderMappedItem, PurchaseOrdersLogicService } from '@app/logic/purchase-orders';
import { LotBuildPermissions, LotPermissions } from '@app/core/permissions';
import { ILotContingencyInfoDto, MANUAL_PURCHASE_ORDER_ORIGIN_ENUM, PURCHASE_ORDER_STATUS_ENUM, PURCHASE_ORDER_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { EditContingencyAmountDialogComponent } from '../edit-contingency-amount-dialog/edit-contingency-amount-dialog.component';
import { EditManualOrderDialogComponent } from '../edit-manual-order-dialog/edit-manual-order-dialog.component';
import { IPurchaseOrderDto } from '@app/logic/purchase-orders/interfaces/i.purchase-order.dto';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ViewManualOrderDialogComponent } from '../view-manual-order-dialog/view-manual-order-dialog.component';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-lot-build-orders-search',
    templateUrl: './lot-build-orders-search.component.html',
    styleUrls: ['./lot-build-orders-search.component.scss']
})
export class LotBuildOrdersSearchComponent extends BaseSearchViewDirective<ILotBuildOrdersSearch> implements AfterViewInit {

    public searchResults: IPurchaseOrderMappedItem[] = [];
    public lotContingencyInfo: ILotContingencyInfoDto;
    @ViewChild('infiniteScrollContainer', { static: true }) public infiniteScrollContainer: { nativeElement: { scrollHeight: number } };

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
            this.refreshContingencyInfo();
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public readonly displayedColumns = [
        'orderNumber',
        'poDate',
        'businessAccount',
        'activity',
        'amount',
        'status',
        'actions',
    ];

    public readonly resultOrders = [
        { id: 'poDate', label: 'PO Date' },
        { id: 'poNumber', label: 'PO Number' },
        { id: 'status', label: 'Status' },
    ];

    public readonly resultDirections = [
        { id: 'asc', label: 'Ascending' },
        { id: 'desc', label: 'Descending' },
    ];

    public readonly resultType = PURCHASE_ORDER_TYPE_ENUM.toLookup();
    public readonly orderStatus = PURCHASE_ORDER_STATUS_ENUM.toLookup();
    public readonly PURCHASE_ORDER_STATUS_ENUM = PURCHASE_ORDER_STATUS_ENUM;

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly purchaseOrdersLogicService: PurchaseOrdersLogicService,
        public readonly lotsLogicService: LotsLogicService,
        public readonly toastService: ToastService,
        public readonly userCacheService: UserCacheService,
        public readonly lotBuildPermissions: LotBuildPermissions,
        public readonly lotPermissions: LotPermissions,
    ) {
        super(
            purchaseOrdersLogicService,
            toastService,
            userCacheService,
            USER_CACHE_AREA.LotBuildOrdersSearch
        );
    }

    private readonly refreshContingencyInfo = (): void => {
        this.lotsLogicService.getContingencyInfo(this.lotMappedItem.id).subOnce({
            next: this.setContingencyAmount
        });
    };

    private readonly setContingencyAmount = (contingencyInfo: ILotContingencyInfoDto): void => {
        this.lotContingencyInfo = contingencyInfo;
    };

    public ngAfterViewInit(): void {
        this.loadSearchParams();
    }

    public viewManualOrder(manualOrder: IPurchaseOrderMappedItem): void {
        this.cbDialog.open(
            ViewManualOrderDialogComponent,
            {
                data: {
                    mappedItem: manualOrder,
                    origin: MANUAL_PURCHASE_ORDER_ORIGIN_ENUM.LotBuild,
                    lotDto: this.lotMappedItem.$getMappedDtoItem(),
                    permissions: this.lotBuildPermissions,
                },
                minWidth: '66%',
            }
        );
    }

    protected getSearchParams(): ILotBuildOrdersSearch & { lotId: number } {
        const data = this.userCacheItem.copyData();
        return {
            ...data,
            lotId: this.lotMappedItem.id,
        };
    }

    public doSearch = (): void => {
        if (this.shouldDoSearch()) {
            this.doSearchIfNoScrollBar();
            this.currentPage++;
            this.searchIsLoading = true;
            this.purchaseOrdersLogicService
                .getMappedSearchList({
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    ...this.getSearchParams()
                })
                .subOnce({ next: this.handleSearchResult, error: this.handleSearchError });
        }
    };

    public createManualOrder(): void {
        const mappedItem = this.purchaseOrdersLogicService.$createMappedItem({
            lotId: this.lotMappedItem.id,
            originId: MANUAL_PURCHASE_ORDER_ORIGIN_ENUM.LotBuild,
            orderDate: new Date().toJSON(),
            activityDuration: 1,
        } as IPurchaseOrderDto);

        this.cbDialog.open(
            EditManualOrderDialogComponent,
            {
                data: {
                    mappedItem,
                    regionId: this.lotMappedItem.location.region.id,
                    lotDto: this.lotMappedItem.$getMappedDtoItem(),
                },
                minWidth: '33%',
            }
        )
            .afterClosed()
            .subOnce({
                next: (result) => {
                    if (result) {
                        mappedItem.$reload().subOnce();
                        // assigning new array to this.searchResults to trigger angular datasource binding update
                        this.searchResults = [...this.searchResults, mappedItem];
                        this.viewManualOrder(mappedItem);
                    }
                }
            });
    }

    public editContingencyAmount(): void {
        const lot = this.lotMappedItem;
        this.cbDialog.open(
            EditContingencyAmountDialogComponent,
            {
                data: {
                    mappedItem: lot,
                },
                minWidth: '33%',
            }
        )
            .afterClosed()
            .subOnce({
                next: (result) => {
                    if (result) {
                        this.refreshContingencyInfo();
                    }
                }
            });
    }

    public canCreate(): boolean {
        return this.lotBuildPermissions.getManualPurchaseOrderPermissions().canManageManualOrder();
    }

    public canEditLotContingencyAmount(): boolean {
        return this.lotPermissions.canEditLotContingencyAmount() && !this.lotMappedItem.hasUnits;
    }
}
