import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotChangesListSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { IVariationMappedItem } from '@app/logic/variation/interfaces/i.variation.mapped';
import { VariationLogicService } from '@app/logic/variation/variation.logic-service';
import { UtilIcons } from '@app/shared/utils/util.icons';
import {
    CHANGE_STATUS_ENUM,
    CostNatureEnumId,
    COST_NATURE_ENUM,
    IChangeOptionDto,
    IChangeRecordDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import _ from 'lodash';

@Component({
    selector: 'cb-lot-changes-list',
    templateUrl: './lot-changes-list.component.html',
    styleUrls: ['./lot-changes-list.component.scss']
})
export class LotChangesListComponent implements OnInit {

    public CHANGE_STATUS_ENUM = CHANGE_STATUS_ENUM;
    public COST_NATURE_ENUM = COST_NATURE_ENUM;

    private _changeRecordMappedItems: IChangeRecordMappedItem[];
    @Input() public set changeRecordMappedItems(changeRecordMappedItems: IChangeRecordMappedItem[]) {
        const mustFilterChangeRecords = this._changeRecordMappedItems;
        this._changeRecordMappedItems = changeRecordMappedItems;
        if (mustFilterChangeRecords) {
            this.setFilteredChangeRecords();
        }
    }

    public get changeRecordMappedItems(): IChangeRecordMappedItem[] {
        return this._changeRecordMappedItems;
    }


    @Input() public lotId: number;
    @Output() public readonly changeRecordSelected = new EventEmitter<IChangeRecordMappedItem>();

    public variationMappedItems: IVariationMappedItem[];
    public filteredchangeRecords: IChangeRecordMappedItem[];

    public collapsed = false;
    public currentChangeId: number | null;

    public searchQuery = '';
    public variationsSelectList: SelectListItem[] = [];
    public costNatures: SelectListItem[];
    public changeStatus: SelectListItem[];

    public filterredChangeIds: number[] = [];

    public get userCacheItem(): UserCacheItem<ILotChangesListSearch> {
        return this.userCacheService.lotChangesListSearch;
    }


    constructor(
        public readonly userCacheService: UserCacheService,
        private readonly variationLogicService: VariationLogicService,
        private readonly navigationService: NavigationService
    ) {
        this.costNatures = COST_NATURE_ENUM.toSelectList();
        this.changeStatus = CHANGE_STATUS_ENUM.toSelectList();
    }

    public ngOnInit(): void {
        this.loadFilterredChips();
        this.loadVariations();
        // Only filter once we've populated our cached items
        this.userCacheItem.promise.then(() => {
            this.setFilteredChangeRecords();
        });
    }

    public loadFilterredChips(): void {
        const navParams = this.navigationService.getQueryParams<{ changeRecordIds: string[] }>();
        const changeRecordIds = navParams.changeRecordIds;
        if (changeRecordIds) {
            if (typeof (changeRecordIds) === 'string') {
                this.filterredChangeIds.push(+changeRecordIds);
            } else {
                this.filterredChangeIds = changeRecordIds.map(x => +x);

                // Clear user cached filters otherwise the changerecords we received (via the url)
                // wont show up and it looks like it doesn't exist
                this.userCacheItem.silentData.selectedCostNatures = [];
                this.userCacheItem.silentData.selectedVariations = [];
                this.userCacheItem.silentData.selectedStatus = [];
                this.userCacheItem.save();
            }
        }
    }

    public removeFilterChip(id: number): void {
        if (id > 0) {
            this.filterredChangeIds = _.remove(this.filterredChangeIds, id);
            this.setFilteredChangeRecords();
        }
    }

    private loadVariations(): void {
        this.variationLogicService.getByLot(this.lotId).subOnce((results) => {
            this.variationMappedItems = results;
            this.setVariationsSelectList();
        });
    }

    public setVariationsSelectList(): void {
        this.variationsSelectList = this.variationMappedItems?.map(x => ({
            id: x.variationNumber,
            label: `VO# ${x.variationNumber} - ${x.costNatureName}`,
            disabled: false,
            checked: false,
        }));
    }

    public showVariationNumber(changeRecord: IChangeRecordMappedItem): boolean {
        return this.isCompleted(changeRecord) && changeRecord.isExtraToClient && changeRecord.variationNumber > 0;
    }

    public showCostChangeAmountGST(changeRecord: IChangeRecordMappedItem): boolean {
        return this.isOptionAccepted(changeRecord.changeOptions) && changeRecord.isExtraToClient;
    }

    public showCostChangeAmount(changeRecord: IChangeRecordMappedItem): boolean {
        return this.isOptionAccepted(changeRecord.changeOptions) && !changeRecord.isExtraToClient;
    }

    public isOptionAccepted(changeOptions: Array<IChangeOptionDto>): boolean {
        const acceptedOption = _.find(changeOptions, { isSelectedChangeOption: true });
        if (acceptedOption) {
            return true;
        } else {
            return false;
        }
    }

    public isCompleted(changeRecord: IChangeRecordDto): boolean {
        return changeRecord.changeStatus === CHANGE_STATUS_ENUM.Completed;
    }

    public getCostNatureIcon = (costNature: CostNatureEnumId): string => UtilIcons.getCostNatureIcon(costNature);

    public getCostNatureIconTitle = (costNature: CostNatureEnumId): string => UtilIcons.getCostNatureIconTitle(costNature);

    public getStatusIconIcon(changeRecord: IChangeRecordDto): string {
        switch (changeRecord.changeStatus) {
            case CHANGE_STATUS_ENUM.OnHold:
            case CHANGE_STATUS_ENUM.ResponseRequired:
            case CHANGE_STATUS_ENUM.WaitingReview:
                return 'pause_circle_filled';
            case CHANGE_STATUS_ENUM.Cancelled:
            case CHANGE_STATUS_ENUM.Declined:
                return 'delete_forever';
            case CHANGE_STATUS_ENUM.Completed:
            case CHANGE_STATUS_ENUM.Accepted:
                return 'check_box';
            case CHANGE_STATUS_ENUM.InDesign:
            case CHANGE_STATUS_ENUM.Pending:
                return 'timelapse';
            case CHANGE_STATUS_ENUM.InProgress:
            case CHANGE_STATUS_ENUM.InQueue:
                return 'assignment';
            default:
                return 'timelapse';
        }
    }

    public getStatusIconClass(changeRecord: IChangeRecordDto): string {
        switch (changeRecord.changeStatus) {
            case CHANGE_STATUS_ENUM.OnHold:
            case CHANGE_STATUS_ENUM.ResponseRequired:
            case CHANGE_STATUS_ENUM.WaitingReview:
            case CHANGE_STATUS_ENUM.InProgress:
            case CHANGE_STATUS_ENUM.InQueue:
            case CHANGE_STATUS_ENUM.InDesign:
            case CHANGE_STATUS_ENUM.Pending:
                return 'mat-accent';
            case CHANGE_STATUS_ENUM.Cancelled:
            case CHANGE_STATUS_ENUM.Declined:
                return 'mat-warn';
            case CHANGE_STATUS_ENUM.Completed:
            case CHANGE_STATUS_ENUM.Accepted:
                return 'mat-primary ';
            default:
                return 'none';
        }
    }

    public getStatusIconTitle(changeRecord: IChangeRecordDto): string {
        switch (changeRecord.changeStatus) {
            case CHANGE_STATUS_ENUM.Accepted:
                return 'Accepted';
            case CHANGE_STATUS_ENUM.Cancelled:
                return 'Cancelled';
            case CHANGE_STATUS_ENUM.Completed:
                return 'Completed';
            case CHANGE_STATUS_ENUM.Declined:
                return 'Declined';
            case CHANGE_STATUS_ENUM.InDesign:
                return 'In Design';
            case CHANGE_STATUS_ENUM.InProgress:
                return 'In Progress';
            case CHANGE_STATUS_ENUM.InQueue:
                return 'In Queue';
            case CHANGE_STATUS_ENUM.OnHold:
                return 'On Hold';
            case CHANGE_STATUS_ENUM.Pending:
                return 'Pending';
            case CHANGE_STATUS_ENUM.ResponseRequired:
                return 'Response Required';
            case CHANGE_STATUS_ENUM.WaitingReview:
                return 'Awaiting Review';
            default:
                return 'none';
        }
    }

    public changeRecordClicked(changeRecord: IChangeRecordMappedItem): void {
        this.currentChangeId = changeRecord.id;
        this.changeRecordSelected.emit(changeRecord);
    }

    public setFilteredChangeRecords(): void {
        this.filteredchangeRecords = this.changeRecordMappedItems && this.changeRecordMappedItems.filter(x => {
            return [x.changeDetails]
                .filter(y => y != null)
                .join(' ')
                .toLowerCase()
                .trim()
                .includes(
                    this.searchQuery
                        .toLowerCase()
                        .trim()
                );
        }).filter(cr => {
            return this.userCacheItem?.silentData?.selectedCostNatures?.includes(cr.costNature) ||
                !(this.userCacheItem?.silentData?.selectedCostNatures?.length > 0);
        }).filter(cr => {
            return this.userCacheItem?.silentData?.selectedStatus?.includes(cr.changeStatus) ||
                !(this.userCacheItem?.silentData?.selectedStatus?.length > 0);
        }).filter(cr => {
            return this.userCacheItem?.silentData?.selectedVariations?.includes(cr.variationNumber) ||
                !(this.userCacheItem?.silentData?.selectedVariations?.length > 0);
        }).filter(cr => {
            return this.filterredChangeIds?.includes(cr.id) ||
                this.filterredChangeIds?.length <= 0;
        });
    }

    public selectedCostNaturesChanged(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.silentData.selectedCostNatures = selectMultipleElement.value;
        this.setFilteredChangeRecords();
        this.userCacheItem.save();
    }

    public selectedVariationsChanged(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.silentData.selectedVariations = selectMultipleElement.value;
        this.setFilteredChangeRecords();
        this.userCacheItem.save();
    }

    public selectedStatusChanged(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.silentData.selectedStatus = selectMultipleElement.value;
        this.setFilteredChangeRecords();
        this.userCacheItem.save();
    }

}
