<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">event_available</mat-icon>
            <b>Milestones</b>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex-col">
        <div class="flex-row">
            <cb-alert-card-new class="flex-50"
                               type="{{ lotMappedItem.hasClientSale ? 'success' : '' }}"
                               message="Lot has Client Sale">
            </cb-alert-card-new>
            <cb-alert-card-new class="flex-50"
                               type="{{ lotMappedItem.hasUnconditionalContract ? 'success' : '' }}"
                               message="Unconditional Contract">
            </cb-alert-card-new>
        </div>
        <div class="flex-row">
            <cb-alert-card-new class="flex-50"
                               type="{{ hasTitle() ? 'success' : '' }}"
                               message="Lot has Title">
            </cb-alert-card-new>
            <cb-alert-card-new class="flex-50"
                               type="{{ hasConsent() ? 'success' : '' }}"
                               message="Consent">
            </cb-alert-card-new>
        </div>
        <div class="flex-row">
            <cb-alert-card-new class="flex-50"
                               type="{{ hasFloorDown() ? 'success' : '' }}"
                               message="Floor Down">
            </cb-alert-card-new>
            <cb-alert-card-new class="flex-50"
                               type="{{ hasPracticalCompletion() ? 'success' : '' }}"
                               message="Practical Completion">
            </cb-alert-card-new>
        </div>
        <div class="flex-row">
            <cb-alert-card-new class="flex-50"
                               type="{{ hasCodeCompliance() ? 'success' : '' }}"
                               message="Code of Compliance">
            </cb-alert-card-new>
            <cb-alert-card-new class="flex-50"
                               type="{{ hasHandover() ? 'success' : '' }}"
                               message="Handover">
            </cb-alert-card-new>
        </div>
    </div>
</mat-expansion-panel>
