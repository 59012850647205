import {Component, ContentChild, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {AutocompleteScrollerComponent} from '../autocomplete-scroller';
import {BaseFormComponentDirective, getBaseFormComponentDirectiveProvider} from '../base-form-component';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';

interface IItemDto { id: number;[key: string]: any }

@Component({
    selector: 'cb-select-items-autocomplete-scroller',
    templateUrl: './select-items-autocomplete-scroller.component.html',
    styleUrls: ['./select-items-autocomplete-scroller.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(SelectItemsAutocompleteScrollerComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class SelectItemsAutocompleteScrollerComponent extends BaseFormComponentDirective {
    @ContentChild(AutocompleteScrollerComponent) public autocompleteScrollerComponent: any;
    @Input() public heading: string;
    @Input() public selectedItem: IItemDto;
    @Input() public displayFn: (item: IItemDto) => string;
    public items: Array<IItemDto>;
    public filteredOptions: Observable<IItemDto[]>;
    constructor() {
        super();
    }

    public readonly displayedColumns: string[] = [
        'item',
        'actions'
    ];

    public addItem(): void {
        if (this.selectedItem) {
            this.value = this.value ? [...this.value, this.selectedItem] : [this.selectedItem];
        }
        this.selectedItem = undefined;
        this.autocompleteScrollerComponent.value = undefined;
        this.refreshTable();
    }

    public getDisabled(): boolean {
        return !this.selectedItem || this.value?.some(x => x.id === this.selectedItem.id);
    }

    public removeItem(index: number): void {
        this.value.splice(index, 1);
        this.refreshTable();
    }

    private refreshTable(): void {
        // create new update forces table refresh
        this.value = Array.from(this.value);
    }
}
