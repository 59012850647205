/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, OnDestroy } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { DragulaService } from 'ng2-dragula';
import { Observable, Subscription } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ExternalSystemFieldsLogicService } from '@app/logic/external-system-fields/external-system-fields.logic-service';
import { ExternalSystemFieldMappedItem } from '@app/logic/external-system-fields/external-system-fields.mapped';
import { EXTERNAL_SYSTEM_ENUM, EXTERNAL_SYSTEM_FIELD_TYPE_ENUM, IExternalSystemFieldTemplateItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ExternalSystemFieldDialogComponent } from './external-system-field-dialog/external-system-field-dialog.component';

const DRAGULA_IGNORE = 'cb-table-footer-row';

@Component({
    selector: 'cb-external-system-fields',
    templateUrl: './external-system-fields.component.html',
    styleUrls: ['./external-system-fields.component.scss']
})
export class ExternalSystemFieldsComponent extends BaseSimpleListViewDirective<IExternalSystemFieldTemplateItemDto, ExternalSystemFieldsLogicService> implements OnDestroy {

    public subscriptions = new Subscription();
    public readonly EXTERNAL_SYSTEM_FIELDS = 'EXTERNAL_SYSTEM_FIELDS';
    public dragulaModel: number[] = [];
    public sortOrderChanged = false;

    public readonly displayedColumns: string[] = ['External Field Name', 'Field Type', 'Field Length', 'Active', ''];

    public EXTERNAL_SYSTEM_FIELD_TYPE_ENUM = EXTERNAL_SYSTEM_FIELD_TYPE_ENUM;

    public savingOrder = false;

    constructor(
        public readonly toastService: ToastService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        private readonly dragulaService: DragulaService,
        private readonly externalSystemFieldsLogicService: ExternalSystemFieldsLogicService,
    ) {
        super(
            dialog,
            externalSystemFieldsLogicService,
            ExternalSystemFieldDialogComponent,
            'External System Fields',
            ExternalSystemFieldMappedItem,
            permissionsPermissions,
            true,
            '50%'
        );
    }

    public ngOnInit(): void {
        this.setupDragula();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.dragulaService.destroy(this.EXTERNAL_SYSTEM_FIELDS);
    }

    public getSearchResults(): Observable<any> {
        return this.logicService.getListBySystemId(EXTERNAL_SYSTEM_ENUM.CostX);
    }

    protected handleNewItem = (item: any) => {
        super.handleNewItem(item);
        this.setDragulaModel();
    };

    protected handleSearchResults = (searchResults: Array<IExternalSystemFieldTemplateItemDto>) => {
        super.handleSearchResults(searchResults);
        this.setDragulaModel();
    };

    public saveOrder(): void {
        this.dialog
            .confirm({
                message: 'Are you sure you want to save the order of the External System Fields?',
                declined: () => {
                    this.savingOrder = false;
                },
                confirmed: this.handleSaveOrder,
            });
    }

    private readonly handleSaveOrder = (): void => {
        this.externalSystemFieldsLogicService
            .saveOrder(this.dragulaModel, EXTERNAL_SYSTEM_ENUM.CostX)
            .subOnce((result) => {
                this.searchResults = result;
                this.setDragulaModel();
                this.sortOrderChanged = false;
                this.savingOrder = false;
            });
    };

    public deleteItem(item: IExternalSystemFieldTemplateItemDto): void {
        this.deleteItemClicked(item, { heading: 'External System Fields', message: `Are you sure you want to delete ${item.fieldName} External System Fields?` });
    }

    /** Creates dragula group for aExternal System Fields table and subscribes to dragula observables */
    private setupDragula(): void {
        this.dragulaService.createGroup(
            this.EXTERNAL_SYSTEM_FIELDS,
            {
                removeOnSpill: false,
                accepts: (el: Element) => !el.classList.contains(DRAGULA_IGNORE), // do not allow move of mat-header-row
                moves: (el: Element) => !el.classList.contains(DRAGULA_IGNORE), // do not allow move of mat-header-row
                revertOnSpill: true
            }
        );

        this.subscriptions.add(
            this.dragulaService.dropModel(this.EXTERNAL_SYSTEM_FIELDS)
                .subscribe(
                    ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                        if (sourceIndex === targetIndex) {
                            return;
                        }
                        moveItemInArray(this.searchResults, sourceIndex, targetIndex);
                        this.setDragulaModel();
                        this.sortOrderChanged = true;
                    }
                )
        );
    }

    /** Sets the dragula model for drag and drop - separate to the actual array of data because
     * the dragula model needs an item for the header row as well...
     */
    private setDragulaModel(): void {
        this.dragulaModel = this.searchResults.map(x => x.id);
    }

}
