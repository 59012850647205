import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { ILotMappedItem } from '@app/logic/lots';
import { BehaviorSubject } from 'rxjs';
import { ManageSchemeDataService } from './manage-scheme-data.service';

@Component({
    selector: 'cb-manage-scheme-view',
    templateUrl: './manage-scheme-view.component.html',
    styleUrls: ['./manage-scheme-view.component.scss'],
    providers: [
        ManageSchemeDataService,
    ]
})
export class ManageDesignSchemeViewComponent {

    @Output() public readonly goBack = new EventEmitter();
    @Input() public readonly lotMappedItem: ILotMappedItem;

    public lastSelectedTabIndex: number;

    public mappedItem$$: BehaviorSubject<IDesignSchemeMappedItem> = new BehaviorSubject(null);

    @Input() public set mappedItem(_designSchemeMappedItem: IDesignSchemeMappedItem) {
        if (_designSchemeMappedItem) {
            _designSchemeMappedItem.$reload().subOnce();
            this.mappedItem$$.next(_designSchemeMappedItem);
        }
    }
    public get mappedItem(): IDesignSchemeMappedItem {
        return this.mappedItem$$.value;
    }

    constructor(
        public readonly manageSchemeData: ManageSchemeDataService) {
    }

    public storeLastIndex(event): void {
        this.lastSelectedTabIndex = event.index;
    }
}
