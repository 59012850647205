<div class="cb-margin-top-3x cb-margin-bottom-3x start-center flex-row flex">
    #{{item.itemId}}
    <div class="cb-margin cb-no-margin-top flex-col flex">
        <div class="cb-no-margin flex-col flex">
            <span>
                {{item.description}}
            </span>
        </div>
    </div>
    <div class="row end-end">
        <button mat-icon-button
                class="change-record-list-button"
                (click)="editItemClicked()">
            <mat-icon>mode_edit</mat-icon>
        </button>
        <button mat-icon-button
                class="change-record-list-button"
                (click)="deleteItemClicked(item)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
