import { ReassignCouncilRfiDialogComponent } from './../reassign-council-rfi-dialog/reassign-council-rfi-dialog.component';
import { CouncilRfiViewService } from './../service/council-rfi-view.service';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ICouncilRfiMappedItem } from '@app/logic/council-rfis';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { LotDesignPermissions } from '@app/core/permissions';
import { COUNCIL_RFI_STATUS_ENUM, CouncilRfiStatusEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { EditCouncilRfiDialogComponent } from '../edit-council-rfi-dialog/edit-council-rfi-dialog.component';

@Component({
    selector: 'cb-council-rfi-action-bar',
    templateUrl: './council-rfi-action-bar.component.html',
    styleUrls: ['./council-rfi-action-bar.component.scss']
})
export class CouncilRfiActionBarComponent {
    @Input() public readonly councilRfi: ICouncilRfiMappedItem;
    @Input() public readonly dependantsComplete: boolean;

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly councilRfiViewService: CouncilRfiViewService,
        private readonly cdRef: ChangeDetectorRef
    ) { }

    public editCouncilRfi(): void {
        this.councilRfi.$reload()
            .subOnce(() => {
                this.cbDialog.open(EditCouncilRfiDialogComponent, {
                    data: {
                        councilRfi: this.councilRfi.$clone(),
                    }
                });
            });
    }

    public canDeleteRFI(): boolean {
        return this.lotDesignPermissions.canDeleteRFI();
    }

    public canEditRFI(): boolean {
        return this.lotDesignPermissions.canEditRFI();
    }

    public canChangeRFIStatus(): boolean {
        return this.lotDesignPermissions.canChangeRFIStatus();
    }

    public canComplete(): boolean {
        return !this.councilRfi?.isComplete && this.councilRfi?.canBeCompleted && this.dependantsComplete;
    }

    public setInQueue(): void {
        this.setStatusAndSave(COUNCIL_RFI_STATUS_ENUM.InQueue);
    }

    public setOnHold(): void {
        this.setStatusAndSave(COUNCIL_RFI_STATUS_ENUM.OnHold);
    }

    public setInProgress(): void {
        this.setStatusAndSave(COUNCIL_RFI_STATUS_ENUM.InProgress);
    }

    public setOnComplete(): void {
        if (!this.canComplete()) {
            return;
        }
        this.cbDialog.confirm({
            dialogHeading: 'Complete Council RFI',
            message: 'Are you sure you want to mark this Council RFI as completed?',
            confirmed: () => {
                this.setStatusAndSave(COUNCIL_RFI_STATUS_ENUM.Complete);
            }
        });
    }

    public assignCouncilRfi(): void {
        this.councilRfi.$reload()
            .subOnce(() => {
                this.cbDialog.open(ReassignCouncilRfiDialogComponent, {
                    data: {
                        councilRfi: this.councilRfi.$clone(),
                    }
                });
            });
    }

    public backToList(): void {
        this.councilRfiViewService.backToList();
    }

    public deleteCouncilRfi(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Council RFI from this Lot',
            message: 'Are you sure you want to delete this Council RFI?',
            confirmed: () => {
                this.councilRfi.delete().subOnce((result) => {
                    if (result) {
                        this.councilRfiViewService.councilRfiDeleted(this.councilRfi.id);
                    }
                });
            }
        });
    }

    private setStatusAndSave(status: CouncilRfiStatusEnumId): void {
        this.councilRfi.statusId = status;
        this.councilRfi.$save().subOnce();
        this.cdRef.detectChanges();
    }
}
