import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {IPreConsentPlanMappedItem} from '@app/logic/pre-consent-plans';
import {IPreConsentPlanItemDto} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/PreConsentPlanItemDto';
import {
    RequestPreConsentEditItemDialogComponent
} from '@app/views/lot/design/pre-consent/request-preconsent-dialog-wizard-steps/request-pre-consent-edit-item-dialog/request-pre-consent-edit-item-dialog.component';

@Component({
    selector: 'cb-pre-consent-plan-item',
    templateUrl: './pre-consent-plan-item.component.html',
    styleUrls: ['./pre-consent-plan-item.component.scss']
})
export class PreConsentPlanItemComponent {

    @Input() public mappedItem: IPreConsentPlanMappedItem;
    @Output() public mappedItemChange = new EventEmitter<IPreConsentPlanMappedItem>();
    @Input() public designerView: boolean;
    @Input() public item: IPreConsentPlanItemDto;
    constructor(
        public readonly cbDialog: CbDialogService
    ) {}

    public editItemClicked(): void {
        const param = {
            designerView: this.designerView,
            item: this.item,
            mappedItem: this.mappedItem,
            mode: FormMode.Edit,
        };

        this.cbDialog.open(RequestPreConsentEditItemDialogComponent, {
            data: param
        });
    }

    public deleteItemClicked(item: IPreConsentPlanItemDto): void {
        if (!(item.id > 0)) {
            const index = this.mappedItem.items.findIndex(_item => _item === item);
            this.mappedItem.items.splice(index, 1);
            this.mappedItemChange.emit(this.mappedItem);
        } else {
            item.isDeleted = true;
        }
    }
}

