import { BaseFormComponentDirective, getBaseFormComponentDirectiveWithoutValueAccessorProvider } from '../base-form-component';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { GstService } from './../../../../core/services/gst/gst.service';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { removeCurrencyFormatting } from './currency.util';
import { NgControl } from '@angular/forms';

@Component({
    selector: 'cb-currency',
    templateUrl: './currency.component.html',
    styleUrls: ['./currency.component.scss'],
    providers: [
        // not providing NG_VALUE_ACCESSOR because
        // valueAccessor is set in the constructor by injecting NgControl
        // because refreshValue() needs access to NgControl
        ...getBaseFormComponentDirectiveWithoutValueAccessorProvider(CurrencyComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class CurrencyComponent extends BaseFormComponentDirective {
    public static readonly GST_INCLUSIVE_SUFFIX = ' - (Gst Inclusive)';
    private static readonly EMPTY_SETTER_RESULT = {
        value: null,
        modelValue: null
    };

    private readonly maxMaxLength = 10;
    private _maxlength = this.maxMaxLength;
    public get maxlength(): number {
        return this._maxlength;
    }
    @Input() public set maxlength(val: number) {
        if (+val > this.maxMaxLength) {
            // decimal types are restricted to this size in the api
            throw new Error(`CurrencyComponent [maxlength] is restricted to a maximum value of ${this.maxMaxLength}, this excludes , and .`);
        }
        this._maxlength = val;
    }

    @Input() public formatCredit = true;
    @Input() public formatCreditBrackets = false;

    private _inclGst = false;
    public get inclGst(): boolean {
        return this._inclGst;
    }
    @Input() public set inclGst(val: boolean) {
        if (typeof (val) !== 'boolean') {
            throw new Error('cb-currency inclGst binding must be a boolean');
        }
        this._inclGst = val;
        this._addGstToLabel();
        this.refreshValue();
    }

    private _gstRate: number;
    public get gstRate(): number {
        return this._gstRate;
    }
    @Input() public set gstRate(v: number) {
        this._gstRate = v;
        if (this.inclGst) {
            // if inclGst is enabled, ensure that the value is updated with the new gstRate
            this.refreshValue();
        }
    }

    public get label(): string {
        return this._label;
    }
    @Input() public set label(label: string) {
        this._label = label;
        this._addGstToLabel();
    }

    public readonly pattern = /^([-]?[0-9]\d{0,2}(,[0-9]\d{0,3})*(\.[0-9]+)?|\.[0-9]+)$/;

    @ViewChild('inputRef', { static: true }) public inputRef: ElementRef;
    public cursorPosition: number;

    public get valueFormatCharCount(): number {
        return ((this.value && (this.value as string).toString().match(/[,.]/g)) || []).length;
    }

    constructor(
        public readonly ngControl: NgControl,
        public readonly gstService: GstService,
    ) {
        super();
        // not using NG_VALUE_ACCESSOR because
        // valueAccessor is set in the constructor by injecting NgControl
        // because refreshValue() needs access to NgControl
        if (this.ngControl == null) {
            throw new Error('NgControl must be injected');
        }
        this.ngControl.valueAccessor = this;
    }

    @Debounce(300)
    private refreshValue(): void {
        const wasPristine = this.ngControl.pristine;
        this.setValue(this.value);
        if (wasPristine) {
            this.ngControl.control.markAsPristine();
        }
    }

    public valueSetter(v: any): { value: any; modelValue: any } {
        if (isNullOrWhiteSpace(v)) {
            return CurrencyComponent.EMPTY_SETTER_RESULT;
        }

        let cleanModelValue = this.cleanCurrencyValue(v);

        if (isNaN(cleanModelValue)) {
            return CurrencyComponent.EMPTY_SETTER_RESULT;
        }

        const displayValue = cleanModelValue.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0
        });

        this._updateCursorPositionValue(displayValue);

        if (this.inclGst) {
            cleanModelValue = this.gstService.toGstExclusive(cleanModelValue, this.gstRate);
        }

        return {
            value: displayValue,
            modelValue: cleanModelValue
        };
    }

    public getIsCreditClass(): boolean {
        const theValue = this.cleanCurrencyValue(this.value);
        return theValue < 0 && this.formatCredit;
    }

    public displayCreditBrackets(): boolean {
        const theValue = this.cleanCurrencyValue(this.value);
        return theValue < 0 && this.formatCredit && this.formatCreditBrackets;
    }

    public displayCredit(): boolean {
        const theValue = this.cleanCurrencyValue(this.value);
        return theValue < 0 && this.formatCredit && !this.formatCreditBrackets;
    }

    public displayNonCredit(): boolean {
        const theValue = this.cleanCurrencyValue(this.value);
        return theValue >= 0 || !this.formatCredit;
    }

    public writeValue(value: any): void {
        if (!isNullOrWhiteSpace(value) && this.inclGst) {
            value = this.gstService.toGstInclusive(this.cleanCurrencyValue(value), this.gstRate);
        }
        super.writeValue(value);
    }

    public emitChange(): void {
        this._updateElementCursorPosition();
        super.emitChange();
    }

    private _updateCursorPositionValue(newInputDisplayValue: string): void {
        if (!this.inputRef) {
            return;
        }

        let newCommaCount = 0;
        if (!isNullOrWhiteSpace(this.value)) {
            const current = ((this.value as string).match(/[,.]/g) || []).length;
            const newCommas = (newInputDisplayValue.match(/[,.]/g) || []).length;
            newCommaCount = newCommas - current;
        }
        this.cursorPosition = +(this.inputRef.nativeElement.selectionStart) + newCommaCount;
    }

    @Debounce(0)
    private _updateElementCursorPosition(): void {
        if (this.inputRef) {
            this.inputRef.nativeElement.selectionStart = this.cursorPosition;
            this.inputRef.nativeElement.selectionEnd = this.cursorPosition;
        }
    }

    private _addGstToLabel(): void {
        if (!this.inclGst || this.label?.includes(CurrencyComponent.GST_INCLUSIVE_SUFFIX)) {
            return;
        }
        this._label = this.label + CurrencyComponent.GST_INCLUSIVE_SUFFIX;
    }

    public cleanCurrencyValue(value: number | string): number {
        return parseFloat(removeCurrencyFormatting(value?.toString()));
    }
}
