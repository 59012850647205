import { ICategoryDto, IChangeOptionDto, IChangeOptionLineDto, IQuoteLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IChangeOptionLogicService } from './interfaces/i.change-option.logic-service';
import { IChangeOptionMappedItem } from './interfaces/i.change-option.mapped';



export class ChangeOptionMappedItem
    extends BaseMappedItem<IChangeOptionDto, IChangeOptionMappedItem, IChangeOptionLogicService>
    implements IChangeOptionMappedItem {

    @DtoProp public id: number;
    @DtoProp public lotId: number;
    @DtoProp public itemToCredit: IChangeOptionDto;
    @DtoProp public currentLotSpecItem: string;
    @DtoProp public slotCategory: ICategoryDto;
    @DtoProp public optionNumber: number;
    @DtoProp public description: string;
    @DtoProp public price: number;
    @DtoProp public otherCosts: number;
    @DtoProp public marginPercentage: number;
    @DtoProp public onCharge: number;
    @DtoProp public designChangeCost: number;
    @DtoProp public designChangesRequired: boolean;
    @DtoProp public clientApprovalRequired: boolean;
    @DtoProp public isSelectedChangeOption: boolean;
    @DtoProp public changeRecordId: number;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public isDeleted: boolean;
    @DtoProp public notifyQsIfSelected: boolean;
    @DtoProp public lotSpecItemId: number;
    @DtoProp public lotSpecItemLabel: string;
    @DtoProp public quoteLineIds: number[];
    @DtoProp public quoteLines: IQuoteLineDto[];
    @DtoProp public allocatedQuoteLines: IQuoteLineDto[];
    @DtoProp public changeOptionLines: IChangeOptionLineDto[];

    public changeOptionLinestoDelete: number[] = [];

    constructor(
        sourceData: IChangeOptionDto,
        logicService: IChangeOptionLogicService
    ) {
        super(sourceData, logicService, ChangeOptionMappedItem);
    }

    public deleteOption(): Observable<boolean> {
        return this.$logicService.deleteOption(this.id);
    }

    public saveOption(): Observable<IChangeOptionDto> {
        return this.$logicService.saveChangeOption(this.$getMappedDtoItem());
    }
}
