<mat-card class="flex-col"
          [ngClass]="{ 'cb-current-lot-council-rfi': rfiNo === councilRfiSearchItem.id }">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="status.value.title">
            <mat-icon [ngClass]="status.value.class">{{status.value.icon}}</mat-icon>
        </div>
        <mat-card-title>RFI #{{councilRfiSearchItem.id}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-col">
        <div class="flex-row">
            <cb-display-value-new class="flex"
                                  label="Received from"
                                  [value]="councilRfiSearchItem?.receivedFrom"></cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Working Drawing Revision"
                                  [value]="councilRfiSearchItem?.workingDrawingNumber"></cb-display-value-new>
        </div>
        <cb-display-value-new label="External Reference"
                              [value]="councilRfiSearchItem?.externalReference"></cb-display-value-new>
        <div class="flex-row">
            <cb-display-value-new class="flex"
                                  label="RFI Date"
                                  [value]="councilRfiSearchItem?.rfiDate| date"></cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Due Date"
                                  [value]="councilRfiSearchItem?.rfiDueDate | date"></cb-display-value-new>
        </div>
        <cb-display-value-new label="Assigned To">
            {{councilRfiSearchItem?.assignedToTeamName}} >
            {{councilRfiSearchItem?.assignedToUserName}}</cb-display-value-new>
    </mat-card-content>
    <mat-card-actions class="flex-col">
        <mat-divider></mat-divider>
        <div class="cb-margin-top flex-row">
            <button mat-flat-button
                    (click)="downloadLatestRfiDocument()">
                <mat-icon>file_download</mat-icon>
                &nbsp;Download
            </button>
            <span class="flex"></span>
            <button mat-stroked-button
                    (click)="view()"
                    color="accent">View</button>
        </div>
    </mat-card-actions>
</mat-card>
