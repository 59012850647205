import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ICouncilMappedItem } from '@app/logic/councils/interfaces/i.council.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CouncilLogicService } from '@app/logic/councils';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CouncilMappedItem } from '@app/logic/councils/council.mapped';
import { NgForm } from '@angular/forms';
import { ICouncilLogicService } from '@app/logic/councils/interfaces/i.council.logic.service';
import { ILookupDto } from '@app/shared/interfaces/i.lookup.dto';
import { ILocationDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ICouncilDto } from '@app/logic/councils/interfaces/i.council.dto';

interface IData {
    mappedItem: CouncilMappedItem;
}

@Component({
    selector: 'cb-council-dialog',
    templateUrl: './council-dialog.component.html',
    styleUrls: ['./council-dialog.component.scss'],
    providers: [CouncilLogicService]
})
export class CouncilDialogComponent extends
    BaseDialogFormViewDirective<ICouncilDto, ICouncilMappedItem, ICouncilLogicService> {
    @ViewChild('councilForm', { static: true }) public councilsForm: NgForm;
    public mappedItem: CouncilMappedItem;
    public selectedItem: ILookupDto<number>;
    public selectedLocationModel: ILocationDto;
    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<CouncilDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly councilLogicService: CouncilLogicService
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
    }

    public displayFn(item): string | undefined {
        return item ? item.label ? item.label : item.path : '';
    }

    public getDisabled(): boolean {
        return !this.councilsForm.dirty || !this.councilsForm.valid;
    }

    public itemSelected(item: ILookupDto<number>): void {
        this.selectedItem = item;
    }
}
