import { ClientSaleClientAccountUpdateDto } from './../../../../logic/client-sale/dtos/client-sale-client-account-update.dto';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IContactSearchDto } from '@app/logic/contacts';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ACCOUNT_TYPE_ENUM, IClientSaleClientAccountUpdateDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { isEmpty } from 'lodash';
import { isNullOrWhiteSpace, cleanObjectDeep } from 'cb-hub-lib';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

interface IData {
    clientSale: IClientSaleMappedItem;
    editAddressOnly: boolean;
}

@Component({
    selector: 'cb-client-account-edit-dialog',
    templateUrl: './client-account-edit-dialog.component.html',
    styleUrls: ['./client-account-edit-dialog.component.scss']
})
export class ClientAccountEditDialogComponent extends BaseDialogFormViewDirective<IClientSaleClientAccountUpdateDto, any, any> implements OnInit {

    public clientAccountDetails = {} as IClientSaleClientAccountUpdateDto;

    public readonly accountTypeLookup = ACCOUNT_TYPE_ENUM.toLookup();

    public selectedContact: IContactSearchDto;

    constructor(
        public readonly dialogRef: MatDialogRef<ClientAccountEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.clientAccountDetails = new ClientSaleClientAccountUpdateDto(this.data.clientSale);

        this.selectedContact = {
            id: this.clientAccountDetails.mainContactId,
            name: this.data.clientSale.clientAccount.mainContactName
        } as IContactSearchDto;
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public save(): void {
        this.clientAccountDetails.mainContactId = this.selectedContact.id;
        if (isEmpty(cleanObjectDeep(this.clientAccountDetails.physicalAddress, isNullOrWhiteSpace))) {
            this.clientAccountDetails.physicalAddress = null;
        }
        this.data.clientSale.updateClientAccountDetails(this.clientAccountDetails)
            .subOnce({
                next: this.handleNext,
                error: this.handleError
            });
    }
}
