import { NOTIFICATION_ENTITY_TYPES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';

export const getPrimaryEntityType = (notification: any): string => {
    switch (notification.primaryEntityType) {
        case 'ClientSale':
            return `/clientsales/view/${notification.primaryEntityId}`;
        case 'Lot':
            return `/lots/${notification.primaryEntityId}`;
        case 'BusinessAccount':
            return `/businessaccounts/edit/${notification.primaryEntityId}`;
        case 'BuildProgramme':
            return `/lots/${notification.primaryEntityId}/build/programme`;
        case 'Project':
            return `/projects/${notification.primaryEntityId}/releases/${notification.secondaryEntityId}`;
        case 'Lead':
            return `/leads/${notification.primaryEntityId}`;
        default:
            return `/${notification.primaryEntityType.toLocaleLowerCase()}s/edit/${notification.primaryEntityId}`;
    }
};

export const simplifySecondaryEntityType = (notification: any): string => {

    let returnValue: string;

    switch (notification.secondaryEntityType) {
        case 'WorkingDrawing':
            returnValue = '/design/working-drawings';
            break;
        case 'DesignScheme':
            returnValue = '/design/schemes';
            break;
        case 'PricingRevision':
            returnValue = '/costs/pricerevisions';
            break;
        case 'ChangeRecord':
            returnValue = '/changes/changes';
            break;
        case 'PurchaseOrder':
            returnValue = '/build/orders';
            break;
        case 'DocumentEntity':
            returnValue = '/documents';
            break;
        case 'Activity':
            returnValue = `/activity/${notification.secondaryEntityId}`;
            break;
        case 'TakeOffTask':
            returnValue = '/costs/takeoffs';
            break;
        case 'CouncilRfi':
            returnValue = '/design/council-rfis';
            break;
        case 'PreConsent':
            returnValue = '/design/pre-consent';
            break;
        case 'PreConsentPlan':
            returnValue = '/design/pre-consent';
            break;
        case 'Variation':
            returnValue = '/changes/variations';
            break;
        case 'Task':
            returnValue = '/tasks';
            break;
        case 'BuildingConsent':
            returnValue = '/design/consent';
            break;
        case 'BuildProgrammeActivity':
            returnValue = '/build/programme';
            break;
        case NOTIFICATION_ENTITY_TYPES_CONST.BUILD_PROGRAMME_DOCUMENT:
            returnValue = '/build/documents';
            break;
        default:
            return returnValue;
    }

    return returnValue;
};
