import {LotBuildPermissionEnum} from '../../shared/enums/permissions/lot.build.permission';
import {Injectable} from '@angular/core';
import {CurrentUserService} from '../authentication/current.user';
import {HttpClient} from '@angular/common/http';
import {BasePermissions, IBaseSystemAreaPermissions, IDocumentPermissions, IHaveManualPurchaseOrdersPermissions, IManualPurchaseOrderPermissions} from './base.permissions';
import {Router} from '@angular/router';

export interface ILotBuildPermissions extends IBaseSystemAreaPermissions, IHaveManualPurchaseOrdersPermissions {
    canGenerateBuildProgramme(): boolean;
    canSetQSApproval(): boolean;
    canSetConstructionManager(): boolean;
    canGenerateBuildProgrammeDocument(): boolean;
    canGenerateSSR(): boolean;
    canEditSSR(): boolean;
    canCancelSSR(): boolean;
    canSplitSSR(): boolean;
    canAbandonSSR(): boolean;
    canConfirmSSR(): boolean;
    canCompleteSSR(): boolean;
    canEditActivity(): boolean;
    canSetSupplier(): boolean;
    canViewGantt(): boolean;
    canManageGantt(): boolean;
    canViewOrdersTab(): boolean;
    canDownloadSSR(): boolean;
    canDownloadPO(): boolean;
    getDocumentPermissions(): IDocumentPermissions;
}

const METHOD_NOT_IMPLEMENTED = 'Method not implemented';

@Injectable()
export class LotBuildPermissions extends BasePermissions<LotBuildPermissionEnum> implements ILotBuildPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lotbuild', LotBuildPermissionEnum, currentUser, http, router);
    }

    public canGenerateBuildProgramme(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanGenerateBuildProgramme);
    }

    public canSetQSApproval(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanSetQSApproval);
    }

    public canSetConstructionManager(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanSetConstructionManager);
    }

    public canGenerateBuildProgrammeDocument(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanGenerateBuildProgrammeDocument);
    }

    public canGenerateSSR(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanGenerateSSR);
    }

    public canEditSSR(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanEditSSR);
    }

    public canCancelSSR(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanCancelSSR);
    }

    public canSplitSSR(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanSplitSSR);
    }

    public canAbandonSSR(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanAbandonSSR);
    }

    public canConfirmSSR(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanConfirmSSR);
    }

    public canCompleteSSR(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanCompleteSSR);
    }

    public canEditActivity(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanEditActivity);
    }

    public canSetSupplier(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanSetSupplier);
    }

    public canViewGantt(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanViewGantt);
    }

    public canManageGantt(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanManageGantt);
    }

    public canViewOrdersTab(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanViewOrdersTab);
    }

    public canDownloadSSR(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanDownloadSSR);
    }

    public canDownloadPO(): boolean {
        return this.permissionObject(LotBuildPermissionEnum.CanDownloadPO);
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => this.permissionObject(LotBuildPermissionEnum.DocumentsCanDelete),
            canEditDocuments: () => this.permissionObject(LotBuildPermissionEnum.DocumentsCanEdit),
            canReviewDocuments: () => this.permissionObject(LotBuildPermissionEnum.DocumentsCanReview),
            canUploadDocument: () => this.permissionObject(LotBuildPermissionEnum.DocumentsCanUpload),
            canViewDocuments: () => this.permissionObject(LotBuildPermissionEnum.DocumentsCanView)
        };
    }

    public getManualPurchaseOrderPermissions(): IManualPurchaseOrderPermissions {
        return {
            canApproveManualOrder: () => this.permissionObject(LotBuildPermissionEnum.CanApproveManualOrder),
            canManageManualOrder: () => this.permissionObject(LotBuildPermissionEnum.CanManageManualOrder),
            canSubmitManualOrder: () => this.permissionObject(LotBuildPermissionEnum.CanSubmitManualOrder)
        };
    }

    public canView(): boolean {
        throw new Error(METHOD_NOT_IMPLEMENTED);
    }

    public canEdit(): boolean {
        throw new Error(METHOD_NOT_IMPLEMENTED);
    }

    public canCreate(): boolean {
        throw new Error(METHOD_NOT_IMPLEMENTED);
    }
}
