import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { DocumentGroupLogicService, DocumentsLogicService } from '@app/logic/documents';
import { ProductLogicService } from '@app/logic/products';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ButtonControl } from '@app/shared/directives/button-control/button-control.directive';
import { FILE_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { forEach } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, combineLatest, exhaustMap, filter, map, Observable, startWith, switchMapTo, take, tap, withLatestFrom } from 'rxjs';


@Component({
    selector: 'cb-product-catalogue-items-tab',
    templateUrl: './product-catalogue-items-tab.component.html',
    styleUrls: ['./product-catalogue-items-tab.component.scss'],
    providers: [
        DocumentGroupLogicService,
        DocumentTypesLogicService,
        ProductLogicService,
        DocumentsLogicService
    ]
})
export class ProductCatalogueItemsTabComponent implements AfterViewInit, OnInit {
    public FILE_TYPE_ENUM = FILE_TYPE_ENUM;
    public dateForCatalogueItems: Date;
    public uploadDisabled$ = new BehaviorSubject<boolean>(true).asObservable();
    public readonly uploadButton = new ButtonControl();
    public file$ = new BehaviorSubject<File>(undefined);
    public isFileValidAndUploaded$ = new BehaviorSubject<boolean>(false);
    public isLoading$ = new BehaviorSubject<boolean>(false);
    public catalogueUploadSheets: {};
    public catalogueUploadErrors: any[];
    public catalogueUploadResults: any;
    public catalogUploadProgress: boolean;
    public logLevel = 1;
    public get productCatalogueImportForm(): NgForm {
        return this.productCatalogueImportFormParent?.first;
    }
    @ViewChildren('productCatalogueImportForm') public productCatalogueImportFormParent;

    constructor(
        public readonly documentGroupLogic: DocumentGroupLogicService,
        public readonly documentTypeLogic: DocumentTypesLogicService,
        protected readonly productsLogicService: ProductLogicService,
        public readonly cbDialog: CbDialogService,
        public readonly documentsLogic: DocumentsLogicService) { }

    public ngOnInit(): void {
        this.uploadButton.clicked.pipe(
            withLatestFrom(this.isFileValidAndUploaded$),
            map(([_, isFileValidAndUploaded]) => isFileValidAndUploaded),
            filter(isFileValidAndUploaded => !!isFileValidAndUploaded && !!this.dateForCatalogueItems),
            switchMapTo(this.file$.pipe(take(1))),
            tap(_ => this.isLoading$.next(true)),
            exhaustMap(file => this.uploadCatalog(file, this.dateForCatalogueItems))
        ).subscribe({
            next: x => {
                this.handlecatalogueUploadResults(x);
                this.isLoading$.next(false);
            },
            complete: () => {
                this.isLoading$.next(false);
            },
            error: err => {
                this.isLoading$.next(false);
            }
        });
    }

    public ngAfterViewInit(): void {
        this.uploadDisabled$ = combineLatest([
            this.isFileValidAndUploaded$.pipe(startWith(false)),
            this.productCatalogueImportForm.valueChanges.pipe(startWith()),
            this.isLoading$
        ]).pipe(
            map(
                ([fileValid, formControlValue, isLoading]) => {
                    return !fileValid ||
                        isLoading ||
                        this.productCatalogueImportForm.pristine ||
                        this.productCatalogueImportForm.invalid;
                }
            )
        );
    }

    public uploadCatalog(file: File, dateForCatalogueItems: Date): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('dateForCatalogueItems', moment(dateForCatalogueItems).format());
        return this.productsLogicService.uploadCatalog(formData);
    }

    public uploadCatalogByFileId(fileId: number): Observable<any> {
        const formData = new FormData();
        formData.append('dateForCatalogueItems', moment(this.dateForCatalogueItems).format());
        formData.append('transferHistoryId', fileId.toString());
        return this.productsLogicService.uploadCatalog(formData);
    }

    public handlecatalogueUploadResults = (results: any) => {
        if (results.importResult === 10) {
            this.showDateWarning(results.excelTransferHistoryId, results);
        } else {
            const logResult = {};
            const errors = [];
            forEach(results.records, (record) => {
                if (record.sheetName) {
                    let sheetList = logResult[record.sheetName];
                    if (!sheetList) {
                        sheetList = [];
                        logResult[record.sheetName] = sheetList;
                    }
                    sheetList.push(record);
                } else {
                    errors.push(record);
                }
            });
            this.catalogueUploadSheets = logResult;
            this.catalogueUploadErrors = errors;

            this.catalogueUploadResults = results;
            this.catalogUploadProgress = false;
        }
    };

    public logFilter = (log) => {
        return log.logLevel > this.logLevel;
    };

    public showDateWarning(transferHistoryId: number, originalResults: any): void {
        this.cbDialog.confirm({
            dialogHeading: 'Import Warning',
            message: 'The workbook is out of date.  Do you wish to continue?',
            confirmed: () => {
                this.isLoading$.next(true);
                this.uploadCatalogByFileId(transferHistoryId).subOnce({
                    next: x => {
                        this.isLoading$.next(false);
                        this.handlecatalogueUploadResults(x);
                    },
                    complete: () => {
                        this.isLoading$.next(false);
                    },
                    error: err => {
                        this.isLoading$.next(false);
                    }
                });
            },
            declined: () => {
                originalResults.importResult = 0;
                this.handlecatalogueUploadResults(originalResults);
            }
        });
    }

    public exportCatalogue(): void {
        this.productsLogicService.exportCatalogue().subOnce();
    }

    public resetCatalog = () => {
        this.dateForCatalogueItems = undefined;
        this.productCatalogueImportForm.form.markAsPristine();
        this.productCatalogueImportForm.form.markAsUntouched();
        this.catalogueUploadResults = undefined;
        this.catalogUploadProgress = false;
        this.file$.next(undefined);
        this.isFileValidAndUploaded$.next(false);
        this.isLoading$.next(false);
    };
}
