import { Injectable, Injector } from '@angular/core';

import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { ISearchIndexesLogicService } from './interfaces/i.search-indexes.logic.service';
import { Observable } from 'rxjs';

@Injectable()
export class SearchIndexesLogicService
    extends BaseLogicService<any, Object>
    implements ISearchIndexesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('index', Object);
    }

    public reindex(index: number): Observable<any> {
        return this.$http
            .get<Array<any>>(`${this.$baseUri}/reset`, { index });
    }
}
