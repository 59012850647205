import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbDisplayValueModule } from '../display-value/display-value.module';
import { ElapsedTimeComponent } from './elapsed-time/elapsed-time.component';
@NgModule({
    declarations: [
        ElapsedTimeComponent
    ],
    imports: [
        CommonModule,
        CbDisplayValueModule,
    ],
    exports: [
        ElapsedTimeComponent,
    ]
})

export class CbElapsedTimeModule { }
