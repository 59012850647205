import { OnChanges, OnDestroy, Directive } from '@angular/core';

import { ClientSaleDocumentsService } from '../documents-service/client-sale-documents.service';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { Subscription } from 'rxjs';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Directive()
export abstract class BaseClientSaleCardDirective implements OnChanges, OnDestroy {
    public abstract readonly clientSale: IClientSaleMappedItem;

    public abstract get isComplete(): boolean;

    public readonly cardIcon = new ComputedProperty(() => {
        if (this.isComplete) {
            return 'check';
        }
        return 'error';
    });

    public readonly cardIconClass = new ComputedProperty(() => {
        if (this.isComplete) {
            return 'mat-primary';
        }
        return 'cb-error';
    });

    public readonly tooltipMessage = new ComputedProperty(() => {
        if (this.isComplete) {
            return 'Section Complete';
        }
        return 'Details are required to complete this section';
    });

    public readonly subscription$ = new Subscription();

    constructor(
        public readonly clientSaleDocuments: ClientSaleDocumentsService,
    ) {
        this.subscription$ = this.clientSaleDocuments.documentsLoaded$.subscribe(() => this.recomputeIconProps());
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    public ngOnChanges(): void {
        this.recomputeIconProps();
    }

    public recomputeIconProps(): void {
        this.cardIcon?.recompute();
        this.cardIconClass?.recompute();
        this.tooltipMessage?.recompute();
    }
}
