import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILotSpecItemMappedItem } from '@app/logic/lot-spec-item';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { COST_TYPE_ENUM, ILotSpecItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IHaveLotSpecItemUiActions, LotSpecItemUIActions } from '../lot-spec-item-card/lot-spec-item-ui-actions';
import { ColWidthSyncService } from '../services/col-width-sync.service';


@Component({
    selector: 'cb-lot-spec-item-table-row',
    templateUrl: './lot-spec-item-table-row.component.html',
    styleUrls: ['./lot-spec-item-table-row.component.scss'],
    providers: [
        LotSpecItemUIActions,
    ]
})
export class LotSpecItemTableRowComponent implements IHaveLotSpecItemUiActions {

    @Input() public readonly lotSpec: ILotSpecMappedItem;
    @Input() public readonly mappedItem: ILotSpecItemMappedItem;
    @Output() public readonly itemDeleted = new EventEmitter<ILotSpecItemDto>();
    @Output() public readonly itemEdited = new EventEmitter<ILotSpecItemDto>();

    public readonly COST_TYPE_ENUM = COST_TYPE_ENUM;

    constructor(
        public readonly uiActions: LotSpecItemUIActions,
        /** provided by LotSpecItemsListComponent */
        public readonly colWidthSyncService: ColWidthSyncService,
    ) {
        this.uiActions.setController(this);
    }
}
