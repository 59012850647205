import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldValidationItem } from '@app/core/services/field-validation/field.validation.item';
import { LOT_COMPONENT_TYPE } from '@app/core/services/field-validation/lot-field-validator/interfaces/i.lot.field.rules.service';
import { LotFieldValidatorService } from '@app/core/services/field-validation/lot-field-validator/lot-field-validator-service';
import { ILotSummary } from '@app/core/services/user-cache/user-cache-areas';
import { ILotMappedItem } from '@app/logic/lots';
import {
    CONTRACT_STATUS_ENUM,
    LOT_CONTRACT_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { LotSummaryPinnablePanelDirective } from '../../shared/lot-summary-pinnable-panel';

@Component({
    selector: 'cb-lot-summary-purchaser',
    templateUrl: './lot-summary-purchaser.component.html',
    styleUrls: ['./lot-summary-purchaser.component.scss']
})

export class LotSummaryPurchaserComponent extends LotSummaryPinnablePanelDirective {

    @Input() public lotMappedItem: ILotMappedItem;
    @Input() public pinnedPanels: ILotSummary;
    @Input() public silentData: ILotSummary;
    @Output() public togglePanelPinnedEvent: EventEmitter<string> = new EventEmitter();

    public clientRoute: string;
    public clientSaleRoute: string;

    public CONTRACT_STATUS_ENUM = CONTRACT_STATUS_ENUM;
    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    // Field Validation Items
    public clientAccountLegalNameField = new FieldValidationItem();
    public contractStatusField = new FieldValidationItem();
    public contractTypeField = new FieldValidationItem();
    public jobNumberField = new FieldValidationItem();
    public vendorNameThirdPartyField = new FieldValidationItem();
    public contactsAssignedToAccountField = new FieldValidationItem();

    constructor(private readonly lotFieldValidatorService: LotFieldValidatorService) {
        super();
    }

    public ngOnInit(): void {
        this.lotFieldValidatorService.dto = this.lotMappedItem.$getMappedDtoItem();
        this.populateFieldValidationItems();
    }

    private populateFieldValidationItems(): void {
        this.clientAccountLegalNameField = this.lotFieldValidatorService.clientAccountLegalName(LOT_COMPONENT_TYPE.Purchaser);
        this.contractStatusField = this.lotFieldValidatorService.contractStatus(LOT_COMPONENT_TYPE.Purchaser);
        this.contractTypeField = this.lotFieldValidatorService.contractType(LOT_COMPONENT_TYPE.Purchaser);
        this.jobNumberField = this.lotFieldValidatorService.jobNumber(LOT_COMPONENT_TYPE.Purchaser);
        this.vendorNameThirdPartyField = this.lotFieldValidatorService.vendorNameThirdParty(LOT_COMPONENT_TYPE.Purchaser);
        this.contactsAssignedToAccountField = this.lotFieldValidatorService.contactsAssignedToAccount(LOT_COMPONENT_TYPE.Purchaser);

        this.clientRoute = `/accounts/${this.lotMappedItem?.clientAccountId}/summary`;
        this.clientSaleRoute = `clientsales/view/${this.lotMappedItem?.clientSaleId}/details`;
    }

}
