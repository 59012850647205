<mat-card class="cb-margin flex-col">
    <mat-card-header>
        <mat-card-title>
            {{lotDocumentDto?.siteNumber}}
        </mat-card-title>
        <span class="flex"></span>
        <div class="help-cursor">
            <mat-icon class="cb-grey"
                      *ngIf="(lotDocumentDto?.hasExteriorImage && !lotDocumentDto?.hasFloorPlanImage) || (!lotDocumentDto?.hasExteriorImage && lotDocumentDto?.hasFloorPlanImage)"
                      [title]="lotDocumentDto?.hasExteriorImage ? 'Lot has an exterior image' : 'Lot has a floor plan image'">
                photo
            </mat-icon>
            <mat-icon class="cb-grey"
                      *ngIf="lotDocumentDto?.hasExteriorImage && lotDocumentDto?.hasFloorPlanImage"
                      title="Lot has an exterior image and floor plan">
                collections
            </mat-icon>
            <mat-icon class="cb-grey"
                      *ngIf="lotDocumentDto?.specTemplateId"
                      title="Lot has an applied Spec Template">
                style
            </mat-icon>
        </div>
    </mat-card-header>
    <mat-card-content class="flex">
        <div class="flex-col">
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Lot Number"
                                      [value]="lotDocumentDto?.lotNumber"></cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Status"
                                      [value]="lotDocumentDto?.jobStatus"></cb-display-value-new>
            </div>
            <cb-display-value-new class="flex"
                                  label="Project"
                                  [value]="lotDocumentDto?.projectName"></cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Address">
                <div class="flex-col">
                    <span>
                        {{lotDocumentDto?.addressStreet}}
                    </span>
                    <span>
                        {{lotDocumentDto?.addressSuburb}}
                    </span>
                    <span>
                        {{lotDocumentDto?.addressCity}}
                    </span>
                </div>
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Building Consent"
                                  [value]="lotDocumentDto?.consentNumber"></cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Client Account"
                                  [value]="lotDocumentDto?.clientAccount"></cb-display-value-new>
        </div>
    </mat-card-content>
    <div position="relative">
        <mat-divider></mat-divider>
    </div>
    <mat-card-actions class="flex-row end-center">
        <button mat-stroked-button
                color="accent"
                (click)="viewLot()">
            View
        </button>
    </mat-card-actions>
</mat-card>
