import {Injectable} from '@angular/core';
import {IEnumLookup} from '@classictechsolutions/typescriptenums';
import {HttpWrapperService} from '../http-wrapper/http-wrapper.service';
import {BaseLookupService} from './base-lookup-service';

@Injectable({
    providedIn: 'root',
})
export class LookupService extends BaseLookupService {

    public readonly AGES = this.defineLookup('ageRange');
    public readonly BUILD_STAGES = this.defineLookup('buildStages');
    public readonly BUILD_TYPE = this.defineLookup('lotBuildType');
    public readonly BUILD_TYPES = this.defineLookup('buildTypes');
    public readonly CALLBACK_TASK_OUTCOMES = this.defineLookup('callbackTaskOutcomes');
    public readonly DESIGN_COMPLEXITY = this.defineLookup('designComplexity');
    public readonly DOCUMENT_TYPES = this.defineLookup('documentTypes');
    public readonly GENERAL_TASK_OUTCOMES = this.defineLookup('generalTaskOutcomes');
    public readonly MEETING_TASK_OUTCOMES = this.defineLookup('meetingTaskOutcomes');
    public readonly NOTIFICATION_TEMPLATE = this.defineLookup('notificationTemplateEnum');
    public readonly PRICING_COMPLEXITY = this.defineLookup('pricingComplexity');
    public readonly QUESTION_TASK_OUTCOMES = this.defineLookup('questionTaskOutcomes');
    public readonly REGIONS = this.defineLookup('regions');
    public readonly SEARCH_INDEXES = this.defineLookup('searchIndex');
    public readonly SPEC_TPL_AREA = this.defineLookup('specTemplateAreas');
    public readonly SYSTEM_AREAS = this.defineLookup('permissionArea');
    public readonly TRADE_TYPES = this.defineLookup('tradeTypes');

    constructor(protected readonly http: HttpWrapperService) {
        super(http);
    }

    public getLookup(key: string): Promise<IEnumLookup<number>[]> {
        return this.getLookupFromCache(key);
    }
}
