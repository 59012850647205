<div class="view-container flex-row flex">
    <div class="cb-margin flex-col flex"
         *cbWaitFor="mappedItem && lotMappedItem">
        <cb-working-drawing-action-bar (goBack)="goBack.emit()"
                                       [mappedItem]="mappedItem"
                                       (mappedItemChange)="workingDrawingMappedItemChanged()"
                                       (lotMappedItemChange)="lotMappedItemChanged()"
                                       [lotMappedItem]="lotMappedItem"></cb-working-drawing-action-bar>
        <div class="card-container flex-row layoutGap-10 flex">
            <div class="flex-col flex-20">
                <cb-working-drawing-side-bar class="flex flex-row"
                                             [mappedItem]="mappedItem"></cb-working-drawing-side-bar>
            </div>
            <div class="flex-col flex">
                <cb-working-drawing-view-tabs class="flex flex-row"
                                              [mappedItem]="mappedItem"
                                              [lotMappedItem]="lotMappedItem">
                </cb-working-drawing-view-tabs>
            </div>
        </div>
    </div>
</div>
