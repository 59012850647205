import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IStandardPlanCategoryDto} from '@classictechsolutions/hubapi-transpiled-enums';
import { ICategoriesLogicService } from './interfaces/i.category.logic.service';
import { ICategoryMappedItem } from './interfaces/i.category.mapped';
import { CategoryMappedItem } from './category.mapped';


@Injectable()
export class CategoriesLogicService
    extends BaseLogicService<IStandardPlanCategoryDto, ICategoryMappedItem>
    implements ICategoriesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'standardplans/categories',
            CategoryMappedItem,
        );
    }

}
