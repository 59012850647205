import { IDocumentNotificationTriggerDto, IIntLookupDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IDocumentNotificationTriggerLogicService } from './interfaces/i.document-notification-trigger.logic.service';
import { IDocumentNotificationTriggerMappedItem } from './interfaces/i.document-notification-trigger.mapped';

export class DocumentNotificationTriggerMappedItem
    extends BaseMappedItem<IDocumentNotificationTriggerDto, IDocumentNotificationTriggerMappedItem, IDocumentNotificationTriggerLogicService>
    implements IDocumentNotificationTriggerMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public documentType: IIntLookupDto;
    @DtoProp public userTag: IIntLookupDto;
    @DtoProp public team: IIntLookupDto;
    @DtoProp public documentAction: number;
    @DtoProp public notificationTemplate: IIntLookupDto;
    @DtoProp public isActive: boolean;

    constructor(
        sourceData: IDocumentNotificationTriggerDto,
        logicService: IDocumentNotificationTriggerLogicService
    ) {
        super(
            sourceData,
            logicService,
            DocumentNotificationTriggerMappedItem
        );
    }


}
