<cb-dialog dialogHeading="Building Consultants With Lot Interest">
    <form class="flex-col flex"
          #createLotForm="ngForm">
        <cb-display-value-new class="flex"
                              label="Building Consultants With Lot Interest">
            <div *ngFor="let buildingConsultant of buildingConsultantsWithLotInterest">
                <span class="mat-body-1">
                    {{getBuildingConsultantText(buildingConsultant)}}
                </span>
            </div>
        </cb-display-value-new>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
