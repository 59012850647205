import { Component, Input } from '@angular/core';
import { FieldValidationItem } from '@app/core/services/field-validation/field.validation.item';
import { LOT_COMPONENT_TYPE } from '@app/core/services/field-validation/lot-field-validator/interfaces/i.lot.field.rules.service';
import { LotFieldValidatorService } from '@app/core/services/field-validation/lot-field-validator/lot-field-validator-service';
import { ILotMappedItem } from '@app/logic/lots';
import {
    LAND_TITLE_STATUS_ENUM,
    LOT_TYPE_ENUM,
    COMPASS_DIRECTION_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-summary-info',
    templateUrl: './lot-summary-info.component.html',
    styleUrls: ['./lot-summary-info.component.scss']
})
export class LotSummaryInfoComponent {

    @Input() public lotMappedItem: ILotMappedItem;
    public LOT_COMPONENT_TYPE = LOT_COMPONENT_TYPE;

    public LOT_TYPE_ENUM = LOT_TYPE_ENUM;
    public LAND_TITLE_STATUS_ENUM = LAND_TITLE_STATUS_ENUM;
    public COMPASS_DIRECTION_TYPE_ENUM = COMPASS_DIRECTION_TYPE_ENUM;

    // Field Validation Items
    public lotTypeField = new FieldValidationItem();
    public lotNumberField = new FieldValidationItem();
    public dpNumberField = new FieldValidationItem();
    public landTitleField = new FieldValidationItem();
    public expectedTitleDateField = new FieldValidationItem();
    public developerProjectNameField = new FieldValidationItem();
    public roadDirectionField = new FieldValidationItem();
    public clientOwnsLandField = new FieldValidationItem();
    public isShowHomeField = new FieldValidationItem();

    constructor(private lotFieldValidatorService: LotFieldValidatorService) { }

    public ngOnInit(): void {
        this.lotFieldValidatorService.dto = this.lotMappedItem.$getMappedDtoItem();
        this.populateFieldValidationItems();
    }

    public shouldShowComponent(componentType: LOT_COMPONENT_TYPE): boolean {
        return this.lotFieldValidatorService.shouldShowComponent(componentType);
    }

    protected populateFieldValidationItems(): void {
        this.lotTypeField = this.lotFieldValidatorService.lotType(LOT_COMPONENT_TYPE.InformationDetails);
        this.lotNumberField = this.lotFieldValidatorService.lotNumber(LOT_COMPONENT_TYPE.InformationDetails);
        this.dpNumberField = this.lotFieldValidatorService.dpNumber(LOT_COMPONENT_TYPE.InformationDetails);
        this.landTitleField = this.lotFieldValidatorService.landTitle(LOT_COMPONENT_TYPE.InformationDetails);
        this.expectedTitleDateField = this.lotFieldValidatorService.expectedTitleDate(LOT_COMPONENT_TYPE.InformationDetails);
        this.developerProjectNameField = this.lotFieldValidatorService.developerProjectName(LOT_COMPONENT_TYPE.InformationDetails);
        this.roadDirectionField = this.lotFieldValidatorService.roadDirection(LOT_COMPONENT_TYPE.InformationDetails);
        this.clientOwnsLandField = this.lotFieldValidatorService.clientOwnsLand(LOT_COMPONENT_TYPE.InformationDetails);
        this.isShowHomeField = this.lotFieldValidatorService.isShowHome(LOT_COMPONENT_TYPE.InformationDetails);
    }
}
