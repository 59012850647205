import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { ClientSaleDocumentsService } from './../../documents-service/client-sale-documents.service';
import { ClientSalePermissions } from '@app/core/permissions';
import { Component, Input } from '@angular/core';
import { BaseClientSaleCardDirective } from '../base-client-sale-card';
import { getDocumentUploadLabel } from '../../document-upload-label.util';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Component({
    selector: 'cb-client-sale-sale-purchase-agreement-card',
    templateUrl: './sale-purchase-agreement-card.component.html',
    styleUrls: ['./sale-purchase-agreement-card.component.scss']
})
export class ClientSaleSalePurchaseAgreementCardComponent extends BaseClientSaleCardDirective {

    @Input() public readonly stepNumber: number;

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public get isComplete(): boolean {
        return this.clientSale?.stepCompletion?.saleAndPurchase;
    }

    public readonly uploadLabels = new ComputedProperty(() => {
        return {
            spInfo: getDocumentUploadLabel(
                this.clientSale?.documentInfo?.spInformationUploadDate,
                this.clientSaleDocuments?.documents?.spInfo?.entity?.documentStatus
            ),
            clientSpec: getDocumentUploadLabel(
                this.clientSale?.documentInfo?.clientSpecificationUploadDate,
                this.clientSaleDocuments?.documents?.clientSpec?.entity?.documentStatus
            ),
        };
    });

    constructor(
        public readonly permissions: ClientSalePermissions,
        public readonly clientSaleDocuments: ClientSaleDocumentsService,
    ) {
        super(clientSaleDocuments);
    }

    public recomputeIconProps(): void {
        super.recomputeIconProps();
        this.uploadLabels.recompute();
    }

    public isLotSpecScheduleTemplate(): boolean {
        return this.clientSale?.lot?.hasScheduleSpecTemplate ?? false;
    }

    public downloadCreateSchedule(): void {
        if (!this.permissions.canDownloadCreateSchedule()) {
            return;
        }
        this.clientSale.downloadCreateSchedule(this.clientSale?.lot?.hasScheduleSpecTemplate)
            .subOnce();
    }

    public downloadSalePurchaseInformation(): void {
        if (!this.permissions.canGenerateSaleAndPurchaseInfo()) {
            return;
        }
        this.clientSale.downloadSalePurchaseInformation()
            .subOnce();
    }
}
