import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IWorkingDrawingMappedItem, WorkingDrawingsLogicService } from '@app/logic/working-drawings';
import { IWorkingDrawingsLogicService } from '@app/logic/working-drawings/interfaces/i.working-drawings-logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { IWorkingDrawingDto, IWorkingDrawingItemDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-request-working-drawing-edit-item-dialog-component',
    templateUrl: './request-working-drawing-edit-item-dialog-component.component.html',
    styleUrls: ['./request-working-drawing-edit-item-dialog-component.component.scss']
})
export class RequestWorkingDrawingEditItemDialogComponentComponent extends
    BaseDialogFormViewDirective<IWorkingDrawingDto, IWorkingDrawingMappedItem, IWorkingDrawingsLogicService> implements OnInit {

    public static readonly MIN_WIDTH = '900px';
    public mappedItem: IWorkingDrawingMappedItem;
    public mode: FormMode;
    public itemId: number;
    public description: string;
    public item: IWorkingDrawingItemDto;
    public tempItem: IWorkingDrawingItemDto;

    public edit = FormMode.Edit;
    public add = FormMode.Add;
    constructor(
        private readonly toastService: ToastService,
        private readonly logicService: WorkingDrawingsLogicService,
        public readonly cbDialog: CbDialogService,
        public readonly dialogRef: MatDialogRef<RequestWorkingDrawingEditItemDialogComponentComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IWorkingDrawingMappedItem> &
        { item: IWorkingDrawingItemDto; mode: FormMode; designerView: boolean; nextItemNumber: number },
    ) {

        super(dialogRef, toastService, cbDialog);
        this.mappedItem = data.mappedItem;
        this.item = data.item;
        this.mode = data.mode;
    }

    public ngOnInit(): void {
        this.tempItem = cloneDeepSafe(this.item);
    }

    public saveDialogItem(): void {

        if (!this.mappedItem.items) {
            this.mappedItem.items = [];
        }

        this.mappedItem.items.push(this.tempItem);

        this.cancel();
    }

    public saveDialogEditItem(): void {

        const index = this.mappedItem.items.findIndex(item => item.itemNumber === this.item.itemNumber);

        // Replace the item by index.
        this.mappedItem.items.splice(index, 1, this.tempItem);

        this.cancel();
    }

}
