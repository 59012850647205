import { exportDto } from '@app/shared/logic-utils/export-dto.util';
import { Prop } from '@app/shared/decorators/prop.decorator';
import { IClientSaleUpdateDto } from '../interfaces/i.client-sale-update.dto';
import { PurchaseReasonEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

class ClientSaleUpdateDto implements IClientSaleUpdateDto {
    @Prop public readonly id: number;
    @Prop public buildingConsultantId: string;
    @Prop public purchaseReasonId: PurchaseReasonEnumId;
    @Prop public insuredPartyName: string;
    @Prop public insuredPartyEmail: string;
    @Prop public allowNominee: boolean;
    @Prop public buildDuration: number;
    @Prop public sunsetDate: string | Date;
}

export const clientSaleUpdateDto = exportDto<IClientSaleUpdateDto>(ClientSaleUpdateDto);
