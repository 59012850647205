
import { Injectable, Injector } from '@angular/core';
import { ITempLotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { INumberId } from '../../shared/interfaces/i.number-id';
import { BaseLogicService } from '../base/base-logic.service';
import { DocumentsLogicService } from '../documents';
import { ITempLotMappedItem } from './interfaces/i.temp-lot.mapped';
import { ITempLotsLogicService } from './interfaces/i.temp-lots.logic.service';
import { TempLotMappedItem } from './temp-lot.mapped';

@Injectable()
export class TempLotsLogicService
    extends BaseLogicService<ITempLotDto, ITempLotMappedItem>
    implements ITempLotsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        protected readonly documentLogic: DocumentsLogicService
    ) {
        super(
            'templots',
            TempLotMappedItem
        );
    }

    public saveOrder(addressRegions: INumberId[]): Observable<ITempLotMappedItem[]> {
        const payload = addressRegions.map(x => ({ id: x.id }));

        return this.$http
            .post<Array<ITempLotDto>>(`${this.$baseUri}/reorder`, payload)
            .pipe(
                map(items => items.map(x => this.$createMappedItem(x)))
            );
    }

    public uploadTempLotBrochure(tempLotId: number, file: FormData): Observable<ITempLotDto> {
        return this.$http.post(`${this.$baseUri}/${tempLotId}/uploadbrochure`, file);
    }

    public downloadTempLotBrochure(documentId: number): Observable<any> {
        return this.documentLogic.downloadDocument(documentId);
    }

    public deleteTempLotBrochure(documentEntityId: number): Observable<any> {
        return this.documentLogic.deleteDocumentEntity(documentEntityId);
    }
}
