<cb-dialog [dialogHeading]="'Progress Payment Version History'">
    <form>
        <cb-table class="cb-margin-top"
                  label="progress payment history entries"
                  [loaded]="paymentHistoryItems !== undefined"
                  [columns]="displayedColumns"
                  [minWidth]="750">
            <tbody cbTableBody>
                <tr cbTableRow
                    *ngFor="let payment of paymentHistoryItems">
                    <cb-td-text *ngIf="payment.buildStageId"
                                [value]="payment.buildStageLabel">
                </cb-td-text>
                    <cb-td-enum *ngIf="!payment.buildStageId"
                                [value]="payment.trigger"
                                [enum]="BUILD_STAGE_EVENT_ENUM">
                </cb-td-enum>
                    <td>{{ payment.previousDollarValue | cbCurrency}}</td>
                    <td>{{ payment.dollarValue | cbCurrency}}</td>
                    <cb-td-text [value]="payment.createdDate | date:'dd MMM yyyy hh:mm a'"></cb-td-text>
                    <cb-td-text [value]="payment.createdByName"></cb-td-text>
                    <cb-td-text [value]="payment.changeReasonDescription"></cb-td-text>
                </tr>
            </tbody>
        </cb-table>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                (click)="closeDialog()">Close</button>
    </footer>
</cb-dialog>
