import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { IDesignSchemesLogicService } from './interfaces/i.design-schemes-logic.service';
import { IDesignSchemeMappedItem } from './interfaces/i.design-scheme.mapped';
import { DesignSchemeMappedItem } from './design-scheme.mapped';
import { Observable } from 'rxjs';
import { ISearchResult } from '@app/shared/components/search/i.search';
import {
    IDesignSchemeDocumentDto,
    IDesignSchemeDto,
    IDesignSchemeLineDto,
    IDesignSchemeRejectionDto,
    IDesignSchemeRequestPricingDto,
    IDesignSchemeRequestPricingForLotDto,
    ILotAmenitiesDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { cleanObjectDeep } from 'cb-hub-lib';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { IDesignTeamSchemesSearch } from '@app/core/services/user-cache/user-cache-areas';

@Injectable()
export class DesignSchemesLogicService
    extends BaseLogicServiceWithSearch<
    IDesignSchemeDto,
    IDesignSchemeMappedItem,
    IDesignTeamSchemesSearch,
    IDesignSchemeDocumentDto
    >
    implements IDesignSchemesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('designschemes', DesignSchemeMappedItem);
    }

    public updateAmenities(id: number, data: ILotAmenitiesDto): Observable<IDesignSchemeDto> {
        return this.$http.post(`${this.$baseUri}/${id}/udpateamenities`, data);
    }

    public canRequestPricing(designSchemeId: number): Observable<boolean> {
        return this.$http.get(`${this.$baseUri}/${designSchemeId}/canrequestpricing`);
    }

    public getDesignSchemeLines(designSchemeId: number): Observable<IDesignSchemeLineDto[]> {
        return this.$http.get(`${this.$baseUri}/${designSchemeId}/lines`);
    }

    public requestDesignScheme(lotId: number): Observable<IDesignSchemeDto> {
        return this.$http.post(`${this.$baseUri}/${lotId}/requestdesignscheme`, { id: lotId });
    }

    public requestPricing(designSchemeId: number, useLastestLotSpecVersion: boolean): Observable<IDesignSchemeDto> {
        return this.$http.post(`${this.$baseUri}/${designSchemeId}/requestpricing`, { useLastestLotSpecVersion, designSchemeId } as IDesignSchemeRequestPricingDto);
    }

    public requestPricingForLot(lotId: number, useLastestLotSpecVersion: boolean): Observable<IDesignSchemeDto> {
        return this.$http.post(`${this.$baseUri}/requestpricingforlot/${lotId}`, { useLastestLotSpecVersion } as IDesignSchemeRequestPricingForLotDto);
    }

    public getSearchList(params: any): Observable<ISearchResult<IDesignSchemeDocumentDto>> {
        const cleanParams = cleanObjectDeep(cloneDeepSafe(params));
        return this.$http.get<ISearchResult<IDesignSchemeDocumentDto>>(`${this.$baseUri}/search`, cleanParams);
    }

    public getElapsedTime(designSchemeId: number): Observable<number> {
        return this.$http.get<number>(`${this.$baseUri}/elapsedtime/${designSchemeId}`);
    }

    public request(payload: IDesignSchemeDto): Observable<IDesignSchemeDto> {
        return this.$http.post(`${this.$baseUri}/request`, payload);
    }

    public rejectDesignScheme(designSchemeId: number, comments: string): Observable<IDesignSchemeDto> {
        return this.$http.post(`${this.$baseUri}/${designSchemeId}/reject`, { designSchemeId, comments } as IDesignSchemeRejectionDto);
    }

    public resubmitDesignScheme(designSchemeId: number): Observable<IDesignSchemeDto> {
        return this.$http.post(`${this.$baseUri}/${designSchemeId}/resubmitrequest`);
    }
}
