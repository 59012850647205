import {BasePermissions, IBaseSystemAreaPermissions} from './base.permissions';
import {StandardPlanPermissionEnum} from '../../shared/enums/permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

export interface IStandardPlanPermissions extends IBaseSystemAreaPermissions {
    canSearch(): boolean;
    canView(): boolean;
    canEdit(): boolean;
    canCreate(): boolean;
    canViewUnpublished(): boolean;
}

@Injectable()
export class StandardPlanPermissions extends BasePermissions<StandardPlanPermissionEnum> implements IStandardPlanPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('standardplan', StandardPlanPermissionEnum, currentUser, http, router);
    }

    public canSearch(): boolean {
        return this.permissionObject(StandardPlanPermissionEnum.CanSearch);
    }
    public canView(): boolean {
        return this.permissionObject(StandardPlanPermissionEnum.CanView);
    }
    public canEdit(): boolean {
        return this.permissionObject(StandardPlanPermissionEnum.CanEdit);
    }
    public canCreate(): boolean {
        return this.permissionObject(StandardPlanPermissionEnum.CanCreate);
    }
    public canViewUnpublished(): boolean {
        return this.permissionObject(StandardPlanPermissionEnum.CanViewUnpublished);
    }
}
