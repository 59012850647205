<cb-document class="flex"
             *cbWaitFor="mappedItem?.id && systemArea"
             [entity]="mappedItem"
             [systemArea]="systemArea"
             [permissions]="documentPermissions"
             [includeLinked]="true"
             [hideRenewalColumn]="true"
             [hideDeletedToggle]="true"
             (documentsChange)="onDocumentsChange($event)"
             (documentUploaded)="complianceDocumentUploaded($event)"
             [noCard]="true"></cb-document>
