import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { ILotsLogicService } from '@app/logic/lots/interfaces/i.lots.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ILotContingencyAmounDto, ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';

interface IData {
    mappedItem: ILotMappedItem;
}

@Component({
    selector: 'cb-edit-contingency-amount-dialog',
    templateUrl: './edit-contingency-amount-dialog.component.html',
    styleUrls: ['./edit-contingency-amount-dialog.component.scss']
})
export class EditContingencyAmountDialogComponent extends BaseDialogFormViewDirective<ILotDto, ILotMappedItem, ILotsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '33%';

    public get mappedItem(): ILotMappedItem {
        return this.data.mappedItem;
    }

    public lotContingencyAmountDto: ILotContingencyAmounDto;

    constructor(
        public readonly dialogRef: MatDialogRef<EditContingencyAmountDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LotsLogicService) public readonly lotsLogicService: LotsLogicService
    ) {
        super(dialogRef, toastService);
    }

    public saveMethod(): Observable<ILotContingencyAmounDto> {
        return this.mappedItem.updateContingencyAmount(this.lotContingencyAmountDto);
    }

    public ngOnInit(): void {
        this.lotsLogicService.getContingencyInfo(this.mappedItem.id).subOnce({
            next: this.setContingencyAmount
        });
    }

    private readonly setContingencyAmount = (contingencyAmount: ILotContingencyAmounDto): void => {
        this.lotContingencyAmountDto = contingencyAmount;
    };
}
