<button class="cb-margin-right"
        mat-raised-button
        color="primary"
        [matMenuTriggerFor]="actionsMenu">
    <mat-icon>arrow_drop_down</mat-icon>
    &nbsp;Actions
</button>
<mat-menu #actionsMenu="matMenu">
    <button mat-menu-item
            *ngIf="canChangeSpecification()"
            [disabled]="lotSpec.isLocked"
            (click)="addLotSpecItem()">
        Add Item
    </button>
    <button mat-menu-item
            *ngIf="canChangeSpecification() && !lotSpec?.lotSpecScheduleItems?.length"
            (click)="addLotSpecColourItem()">
        Add Colour Item
    </button>
    <button mat-menu-item
            *ngIf="canSetAttributeValue()"
            [disabled]="disableExpandAllAndFilterAndManageColourItems()"
            (click)="openManageAttributesDialog()">
        Manage Attribute Items
    </button>
    <button mat-menu-item
            *ngIf="canSetAttributeValue()"
            [disabled]="disableExpandAllAndFilterAndManageColourItems()"
            (click)="manageLotSpecHouseItems()">
        Manage Colour Schedule
    </button>
    <button mat-menu-item
            *ngIf="isSIMSchedule()"
            [disabled]="disableExpandAllAndFilterAndManageColourItems()"
            (click)="manageCreateScheduleLotSpecHouseItems()">
        Manage Flyer / Create Schedule
    </button>
    <button mat-menu-item
            *ngIf="canChangeSpecification()"
            [disabled]="!canClearLotSpec()"
            (click)="clearLotSpec()">
        <mat-icon>delete</mat-icon>
        &nbsp;Clear Lot Specification
    </button>
</mat-menu>
