import {IEnumNestedLookup} from '@app/shared/enums/IEnumNestedLookup';
import {IEnumLookup} from '@classictechsolutions/typescriptenums';
import {sortBy, startCase} from 'lodash';
import {IPromised} from 'projects/cb-hub-lib/src/public-api';
import {SelectListItem} from '../enum/enum.service';

export class Lookup {
    constructor(
        private readonly cache: () => Promise<IEnumNestedLookup<number>[]>
    ) { }

    public toLookup(labelFormatter: (str: string) => string = startCase): IPromised<IEnumLookup<number>[]> {
        return this.createPromisedArray(
            this.cache().then(results => {
                results.forEach(x => x.label = labelFormatter(x.label));
                return results;
            })
        );
    }

    public toNestedLookup(labelFormatter: (str: string) => string = startCase): IPromised<IEnumNestedLookup<number>[]> {
        return this.createPromisedArray(
            this.cache().then(results => {
                results.forEach(x => x.label = labelFormatter(x.label));
                return results;
            })
        );
    }

    public toSelectList(labelFormatter: (str: string) => string = startCase): SelectListItem[] {
        return this.createPromisedArray(
            this.cache().then(results =>
                sortBy(results.map((x) => ({
                    checked: false,
                    disabled: false,
                    id: x.id,
                    label: labelFormatter(x.label),
                })), ['label'])
            )
        );
    }

    private createPromisedArray<T extends(IEnumLookup<string | number> | SelectListItem)[]>(promise: Promise<T>): IPromised<T> {
        const result = [] as T as any;
        result.$resolved = false;
        result.$promise = promise as any;
        result.$promise.then((r) => {
            result.push(...r);
            result.$resolved = true;
        });
        return result;
    }
}
