import { Injectable } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { SpecificationTemplatePermissions } from '@app/core/permissions';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { ISkinnyScheduleTemplateDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { includes } from 'lodash';
import { UsersLogicService } from '../users';

@Injectable()
export class ScheduleTemplatePermissionService {

    constructor(
        public readonly specTemplatePermissions: SpecificationTemplatePermissions,
        public readonly featureToggle: FeatureToggleStatesService,
        public readonly currentUserService: CurrentUserService,
        public readonly usersLogicService: UsersLogicService,
    ) { }

    public canEdit(specTemplate: ISkinnyScheduleTemplateDto): boolean {
        let canEdit = false;

        const businessRegionsids = this.currentUserService.regionsids;

        if (this.specTemplatePermissions.canEditAll()) {
            canEdit = true;
        } else {
            canEdit = specTemplate.regionIds.some(r => {
                return includes(businessRegionsids, r) && this.specTemplatePermissions.canEdit();
            });
        }
        return canEdit && this.canEditSpecTemplateForFeatureToggle(specTemplate);
    }

    private canEditSpecTemplateForFeatureToggle(specTemplate: ISkinnyScheduleTemplateDto): boolean {
        return (this.featureToggle.isScheduleItemSpecTemplatesEnabled && specTemplate.isScheduleSpecTemplate)
            || (!this.featureToggle.isScheduleItemSpecTemplatesEnabled && !specTemplate.isScheduleSpecTemplate);
    }


}
