import { ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import { BusinessAccountPermissions } from '@app/core/permissions';
import { IPurchaseOrderDto, PurchaseOrdersLogicService, IPurchaseOrderSearchParams, IPurchaseOrderSearchDto } from '@app/logic/purchase-orders';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { IIdAndLabelDto } from '@app/shared/interfaces/i.idandlabel.dto';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'cb-business-account-orders',
    templateUrl: './business-account-orders.component.html',
    styleUrls: ['./business-account-orders.component.scss']
})
export class BusinessAccountOrdersComponent {
    @Input() public accountId: number;

    public isInitialised = false;
    public orders: IPurchaseOrderDto[];
    public filters: IPurchaseOrderSearchParams = {};
    public refreshData$ = new Subject();
    public sortOrders: IIdAndLabelDto<string>[] = [
        { id: 'poDate', label: 'PO Date' },
        { id: 'poNumber', label: 'PO Number' },
        { id: 'status', label: 'Status' },
    ];
    public sortDirections: IIdAndLabelDto<string>[] = [
        { id: 'desc', label: 'Descending' },
        { id: 'asc', label: 'Ascending' },
    ];

    constructor(
        public readonly permissions: BusinessAccountPermissions,
        protected readonly purchaseOrdersLogicService: PurchaseOrdersLogicService,
        private readonly cdr: ChangeDetectorRef,
        private readonly ngZone: NgZone,
    ) {
    }

    public ngAfterViewInit(): void {
        this.filters = {
            currentPage: 1,
            supplierId: this.accountId,
            order: this.sortOrders[0].id,
            direction: this.sortDirections[0].id
        };
        this.cdr.detectChanges();

        this.ngZone.run(() => {
            setTimeout(() => {
                this.refreshData();
            });
        });
    }

    public refreshData(): void {
        this.orders = [];
        this.isInitialised = true;
        this.refreshData$.next(null);
        this.cdr.detectChanges();
    }

    public search(): Observable<ISearchResult<IPurchaseOrderSearchDto>> {
        return this.purchaseOrdersLogicService.$getSearchList(this.filters);
    }

    public downloadPurchaseOrder(order: IPurchaseOrderDto): void {
        this.purchaseOrdersLogicService.downloadManualPO(order.id).subOnce();
    }
}
