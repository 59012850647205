<div class="flex-row flex">
    <div class="cb-table flex-col flex">
        <mat-table [dataSource]="value">
            <ng-container matColumnDef="item">
                <th mat-header-cell
                    *matHeaderCellDef>{{heading}}</th>
                <td mat-cell
                    *matCellDef="let item">{{displayFn(item)}} </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell
                    *matHeaderCellDef></th>
                <td mat-cell
                    *matCellDef="let item; let i = index;">
                    <button mat-icon-button
                            (click)="removeItem(i)">
                        <mat-icon>remove_circle</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="autocomplete">
                <th mat-header-cell
                    *matHeaderCellDef
                    style="background-color: #fff; color: #000; max-height: 1000px">
                    <ng-content></ng-content>
                </th>
            </ng-container>
            <ng-container matColumnDef="addItemButton">
                <th mat-header-cell
                    *matHeaderCellDef
                    style="background-color: #fff; color: #000">
                    <button mat-icon-button
                            (click)="addItem()"
                            [disabled]="getDisabled()">
                        <mat-icon>add</mat-icon>
                    </button>
                </th>
            </ng-container>
            <tr mat-header-row
                *matHeaderRowDef="['item', 'actions']">
            </tr>
            <tr mat-header-row
                *matHeaderRowDef="['autocomplete', 'addItemButton']">
            </tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"></tr>
        </mat-table>
    </div>
</div>
