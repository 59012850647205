import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotificationsDialogComponent } from '@app/views/app-root/components/notifications-dialog/notifications-dialog.component';
import { BuildStagesLogicService } from '@app/logic/build-stages';
import { PurchaseOrdersLogicService } from '@app/logic/purchase-orders';
import { NonWorkingDayLogicService } from '@app/logic/non-working-day';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { SearchDialogComponent } from '@app/shared/components/search-dialog/search-dialog.component';
import { UserSearchService } from '@app/core/services/search/user.search.service';
import { NgForm } from '@angular/forms';
import { LEAD_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILeadDto, LeadLogicService } from '@app/logic/leads';
import { Observable } from 'rxjs';

interface IData {
    mappedItem: ILeadMappedItem;
}

@Component({
    selector: 'cb-lead-details-dialog',
    templateUrl: './lead-details-edit-dialog.component.html',
    styleUrls: ['./lead-details-edit-dialog.component.scss'],
    providers: [
        BuildStagesLogicService,
        PurchaseOrdersLogicService,
        UserSearchService,
        NonWorkingDayLogicService
    ]
})
export class LeadDetailsEditDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '33%';

    public mappedItem: ILeadMappedItem;
    public statusList: SelectListItem[];
    public userSearchParams = {
        tag: 'BLDGCONSLT',
        active: true
    };
    public get editLeadForm(): NgForm {
        return this.editLeadFormParent?.first;
    }
    @ViewChildren('editLeadForm') public editLeadFormParent;

    constructor(
        public readonly dialogRef: MatDialogRef<NotificationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialog: MatDialog,
        public searchService: UserSearchService,
        public toastService: ToastService,
        private readonly logic: LeadLogicService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }

    public ngOnInit(): void {
        this.statusList = LEAD_STATUS_ENUM.toSelectList()
            .filter(x => x.id === LEAD_STATUS_ENUM.Hot || x.id === LEAD_STATUS_ENUM.Cold);
    }

    public openSearchDialog(): void {
        this.dialog
            .open(
                SearchDialogComponent,
                {
                    minWidth: '95%',
                    data: {
                        extraSearchParams: {
                            tag: 'BLDGCONSLT',
                            active: true
                        },
                        placeholder: 'Type First Name, Last Name, or Email',
                        dialogHeading: 'Lookup User',
                        contentHeading: 'User Search',
                        searchService: this.searchService
                    },
                    panelClass: 'cb-dialog-container'
                }
            )
            .afterClosed()
            .subOnce((result?: { buildingConsultantId: string; buildingConsultantName: string }) => {
                if (result) {
                    const { buildingConsultantId, buildingConsultantName } = result;
                    this.mappedItem.buildingConsultantId = buildingConsultantId;
                    this.mappedItem.buildingConsultantName = buildingConsultantName;
                    this.editLeadForm.form.controls.buildingConsultant.setValue(buildingConsultantName);
                    this.editLeadForm.form.markAsDirty();
                }
            });
    }

    // Base Dialog Overrides

    public saveMethod(): Observable<ILeadDto> {
        return this.logic.manuallyUpdateStatus(this.mappedItem.$getMappedDtoItem());
    }

    protected handleNext = (x: any): void => {
        this.mappedItem.$updateThisAndOriginal(x);
        this.dialogRef.close(x);
        this.toastSerivce.saveSuccess();
    };
}
