<mat-card class="cb-margin flex-col flex">
    <form class="flex-row flex">
        <cb-input label="Search"
                  name="query"
                  class="cb-margin-right-2x"
                  [(ngModel)]="filters.query"
                  (change)="refreshData()"></cb-input>
        <cb-select label="Sort Order"
                   name="accountType"
                   class="cb-margin-right-2x"
                   [(ngModel)]="filters.order"
                   [options]="sortOrders"
                   (change)="refreshData()"></cb-select>
        <cb-select label="Sort Direction"
                   name="tradeType"
                   class="cb-margin-right-2x"
                   [(ngModel)]="filters.direction"
                   [options]="sortDirections"
                   (change)="refreshData()"></cb-select>
    </form>
    <cb-table class="flex"
              *cbWaitFor="isInitialised"
              maxHeightOffset="255"
              [loaded]="true"
              [infiniteScrollEnabled]="true"
              [fetch]="search()"
              [queryUpdated]="refreshData$"
              [(results)]="orders"
              [(currentPage)]="filters.currentPage">
        <thead cbTableHead>
            <tr>
                <td>PO #</td>
                <td>Code</td>
                <td>PO Date</td>
                <td>Job #</td>
                <td>Activity</td>
                <td>Amount</td>
                <td>Status</td>
                <td></td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr *ngFor="let order of orders">
                <td>{{order.orderNumber}}</td>
                <td>{{order.activityCode}}</td>
                <td>{{order.orderDate | cbDate}}</td>
                <td>{{order.jobNumber}}</td>
                <td>{{order.activityName}}</td>
                <td style="text-align: right;">{{order.orderSubtotal | cbCurrency}}</td>
                <td>{{order.statusLabel}}</td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="downloadPurchaseOrder(order)">
                        <mat-icon>file_download</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</mat-card>
