import { BaseMappedItem } from '../base/base-mapped-item';
import { ICouncilLogicService } from './interfaces/i.council.logic.service';
import { ICouncilMappedItem } from './interfaces/i.council.mapped';
import { ICouncilDto } from './interfaces/i.council.dto';
import { DtoProp } from '../base/dto-prop.decorator';
import { ILocation } from '../location/interfaces/location.model';

export class CouncilMappedItem
    extends BaseMappedItem<ICouncilDto, ICouncilMappedItem, ICouncilLogicService>
    implements ICouncilMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public isActive: boolean;
    @DtoProp public locationIds: number[];
    @DtoProp public locations: ILocation[];

    constructor(
        sourceData: ICouncilDto,
        logicService: ICouncilLogicService
    ) {
        super(
            sourceData,
            logicService,
            CouncilMappedItem
        );
    }

    protected $preSave(toSave: ICouncilDto): void {
        if (toSave.locations) {
            toSave.locationIds = toSave.locations.map(x => x.id);
        }
    }
}
