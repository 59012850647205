import { ChangeDirectionEnumId, CostTypeEnumId, ICategoryDto, IChangeOptionLineDto, IIdAndNameDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IChangeOptionLineLogicService } from './interfaces/i.change-option-line.logic.service';
import { IChangeOptionLineMappedItem } from './interfaces/i.change-option-line.mapped';


export class ChangeOptionLineMappedItem
    extends BaseMappedItem<IChangeOptionLineDto, IChangeOptionLineMappedItem, IChangeOptionLineLogicService>
    implements IChangeOptionLineMappedItem {

    @DtoProp public itemDetails: string;
    @DtoProp public quantity: number;
    @DtoProp public changeDirection: ChangeDirectionEnumId;
    @DtoProp public rate: number;
    @DtoProp public costAmount: number;
    @DtoProp public costType: CostTypeEnumId;
    @DtoProp public gST: number;
    @DtoProp public quoted: boolean;
    @DtoProp public comments: string;
    @DtoProp public offeringId: number;
    @DtoProp public changeOptionId: number;
    @DtoProp public specGroupId: number;
    @DtoProp public lotSpecItemId: number;
    @DtoProp public quoteLineId: number;
    @DtoProp public lotSpecItemHasQuoteLines: boolean;
    @DtoProp public selectedProduct: IIdAndNameDto;
    @DtoProp public showInClientSpecification: boolean;
    @DtoProp public showInColourYourDreams: boolean;
    @DtoProp public manualColourEntryRequired: boolean;
    @DtoProp public slotDto: IIdAndNameDto;
    @DtoProp public specGroupName: string;
    @DtoProp public id: number;
    @DtoProp public category: IIdAndNameDto;

    public lotId: number;
    public itemToCredit: IChangeOptionLineDto;
    public currentLotSpecItem: string;
    public slotCategory: ICategoryDto;

    constructor(
        sourceData: IChangeOptionLineDto,
        logicService: IChangeOptionLineLogicService
    ) {
        super(sourceData, logicService, ChangeOptionLineMappedItem);
    }


    public getExistingScheduleItem(): Observable<IChangeOptionLineDto> {
        return this.$logicService.getExistingScheduleItem(this.lotId, this.slotDto.id);
    }

}
