import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { IBuildActivitiesLogicService } from './interfaces/i.build-activities.logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ISkinnyBuildActivityDto } from './interfaces/i.skinny-build-activity.dto';
import { ISkinnyBuildActivitySearchParams } from './interfaces/I.skinny-build-activity-search-parms';
import { IBuildActivityDto } from './interfaces/i.build-activity.dto';
import { IBuildActivityMappedItem } from './interfaces/i.build-activity.mapped';
import { BuildActivityMappedItem } from './build-activity.mapped';
import { ISkinnyBuildActivityMappedItem } from './interfaces/i.skinny-build-activity.mapped';
import { SkinnyBuildActivityMappedItem } from './skinny-build-activity.mapped';

@Injectable()
export class BuildActivitiesLogicService extends BaseLogicService<IBuildActivityDto, IBuildActivityMappedItem> implements IBuildActivitiesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildactivities',
            BuildActivityMappedItem,
        );
    }

    public createSkinnyMappedItem(defaultData?: Partial<ISkinnyBuildActivityDto>): ISkinnyBuildActivityMappedItem {
        return this.$createMappedItem(defaultData, SkinnyBuildActivityMappedItem);
    }

    public getSkinnyList(): Observable<ISkinnyBuildActivityDto[]> {
        return this.$http
            .get(`${this.$baseUri}/all`);
    }

    public $getSearchList(params: ISkinnyBuildActivitySearchParams): Observable<ISkinnyBuildActivityDto[]> {
        if (!params.query) {
            delete params.query;
        }
        if (!params.activityCode) {
            delete params.activityCode;
        } else {
            params.c = params.activityCode;
            delete params.activityCode;
        }
        if (!params.activityName) {
            delete params.activityName;
        } else {
            params.n = params.activityName;
            delete params.activityName;
        }

        return this.$http
            .get<Array<ISkinnyBuildActivityDto>>(`${this.$baseUri}`, params);
    }

    public generateReport(): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/report`, 'Generating Build Activities Report...');
    }

    public generateActivityReport(buildActivityId: number): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/${buildActivityId}/report`, 'Generating Build Activity Report...');
    }

    public saveSkinnyItem(buildActivity: ISkinnyBuildActivityDto): Observable<ISkinnyBuildActivityDto> {
        let uri = this.$baseUri;
        if (buildActivity.id > 0) {
            uri += `/${buildActivity.id}`;
        }
        return this.$http
            .post<ISkinnyBuildActivityDto>(uri, buildActivity);
    }
}
