<cb-display-value-new *ngIf="label && floatingLabel"
                      label="{{label}}">
    <span class="flex"
          [ngClass]="textClass">{{userFullNameDiplay.value}} {{formattedDate.value}}</span>
</cb-display-value-new>
<span class="flex"
      [ngClass]="textClass"
      *ngIf="label && !floatingLabel">
    {{label}} {{userFullNameDiplay.value}}
    {{formattedDate.value}}
</span>
<span class="flex"
      *ngIf="!label">
    {{userFullNameDiplay.value}} {{formattedDate.value}}
</span>
