<mat-icon [matTooltip]="'Alphabetical Sort '"
          matTooltipPosition="right"
          style="opacity: 0.3;"
          *ngIf="noSorting()">
    swap_vert
</mat-icon>
<mat-icon [matTooltip]="'Descending'"
          matTooltipPosition="right"
          *ngIf="isDescending()">arrow_downward</mat-icon>
<mat-icon [matTooltip]="'Ascending'"
          matTooltipPosition="right"
          *ngIf="isAscending()">arrow_upward</mat-icon>