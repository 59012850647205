import { Injectable, Injector } from '@angular/core';
import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { ITradeTypeDto } from './interfaces/i.trade-type.dto';
import { ITradeTypeMappedItem } from './interfaces/i.trade-type.mapped';
import { ITradeTypesLogicService } from './interfaces/i.trade-types.logic.service';
import { TradeTypeMappedItem } from './trade-type.mapped';

@Injectable()
export class TradeTypesLogicService
    extends BaseLogicService<ITradeTypeDto, ITradeTypeMappedItem>
    implements ITradeTypesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'tradetypes',
            TradeTypeMappedItem,
        );
    }

    public downloadTradeTypesReport(): Observable<void> {
        return this.$http.download(`${this.$baseUri}/report`);
    }
}
