<div class="cb-margin flex-col">
    <cb-standard-plan-search *ngIf="this.standardPlanssLoaded && this.addressRegionsLoaded"
                             (emitFilterEvent)="getFilterredPlans($event)"
                             [(standardPlans)]="this.standardPlans"
                             [addressRegions]="this.addressRegions"></cb-standard-plan-search>
    <cb-standard-plan-card *ngIf="this.standardPlanssLoaded && this.addressRegionsLoaded && this.stylesLoaded"
                           [standardPlans]="this.standardPlans"
                           [addressRegions]="this.addressRegions"
                           [styles]="this.styles"></cb-standard-plan-card>
    <cb-loading-spinner *ngIf="!this.standardPlanssLoaded || !this.addressRegionsLoaded || !this.stylesLoaded"
                        message="Loading standard plans"></cb-loading-spinner>
    <cb-info-message message="There are no Standard Plans found"
                     *ngIf="this.standardPlanssLoaded && this.standardPlans.length < 1">
    </cb-info-message>
</div>
