import { Component, Input } from '@angular/core';
import { ILotMappedItem } from '@app/logic/lots';

@Component({
    selector: 'cb-lot-summary-website-publishing',
    templateUrl: './lot-summary-website-publishing.component.html',
    styleUrls: ['./lot-summary-website-publishing.component.scss']
})
export class LotSummaryWebsitePublishingComponent {

    @Input() public lotMappedItem: ILotMappedItem;

    constructor() { }


}
