import { Component, Inject } from '@angular/core';
import {
    CostTypeEnumId,
    COST_TYPE_ENUM,
    IBundleTemplateItemDto,
    IProductOfferingDto,
    ISlotDto,
    ISpecGroupDto,
    LOT_SPEC_TEMPLATE_COST_TYPE_ENUM,
    UNIT_OF_MEASURE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { find, forEach, orderBy } from 'lodash';
import { IProductMappedItem, ProductLogicService } from '@app/logic/products';
import { UntypedFormGroup } from '@angular/forms';
import { ISpecTemplateDto, ISpecTemplateItemDto, SpecTemplateLogicService } from '@app/logic/spec-template';
import { IIdAndLabelDto } from '@app/shared/interfaces/i.idandlabel.dto';

@Component({
    selector: 'cb-spec-template-item-dialog',
    templateUrl: './spec-template-item-dialog.component.html',
    styleUrls: ['./spec-template-item-dialog.component.scss']
})
export class SpecTemplateItemDialogComponent {
    public static readonly MIN_WIDTH = '80%';
    public item: ISpecTemplateItemDto;
    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public template: ISpecTemplateDto;
    public selectedHouseArea: ISpecGroupDto;
    public uploadingImage = false;
    public selectedArea: ISpecGroupDto;
    public houseAreas: ISpecGroupDto[];
    public scheduleTemplateCostTypes = LOT_SPEC_TEMPLATE_COST_TYPE_ENUM.toLookup();
    public isBundleItem: boolean;
    public bundleTemplateItem: IBundleTemplateItemDto;
    public selectedProduct: IProductMappedItem;
    public offeringCategoryId: number;
    public productSearchText: string;
    public productUom: string;
    public slot: ISlotDto;
    public categoryLoaded: boolean;
    public suppliers: IIdAndLabelDto<number>[];

    constructor(
        private readonly specGroupsLogicService: SpecGroupsLogicService,
        private readonly dialogRef: MatDialogRef<SpecTemplateItemDialogComponent>,
        private readonly productLogicService: ProductLogicService,
        private readonly specTemplateLogicService: SpecTemplateLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data
    ) {
        this.item = data.item;
        this.template = data.template;
        this.item.specGroupId = this.data.specGroupId;

        this.specGroupsLogicService.$getList().subOnce(result => this.houseAreas = orderBy(result ?? [], 'sortOrder'));

        if (this.item?.product?.id) {
            this.productLogicService.$getMappedItem(this.item.product.id).subOnce(product => {
                this.selectedProduct = product;
                this.setSelectedProductImage();
            });
        } else {
            if (this.item.productOther) {
                this.productSearchText = this.item.productOther;
                this.selectedProduct = { name: this.item.productOther } as IProductMappedItem;
            }
        }
    }

    public get shouldHideQuantity(): boolean {
        return this.item.costType === COST_TYPE_ENUM.Provisional || !this.item.costType;
    }

    public isSupplierViewable = (): boolean => {
        const currentCostType = find(this.scheduleTemplateCostTypes, { id: this.item.costType });
        if (!currentCostType || (!this.template.regionIds?.length && !this.template.project)) {
            return false;
        }
        return (this.item.costType === COST_TYPE_ENUM.Actual && this.selectedProduct !== undefined);
    };

    public productSearchTextChanged(str: string): void {
        this.productSearchText = str;
        this.item.product = null;
        this.productUom = null;
    }

    public productUpdate = (data?: IProductOfferingDto): void => {
        if (data != null) {
            if (data.id) {
                this.productLogicService.$getMappedItem(data.id).subOnce(res => {
                    this.selectedProduct = res;
                    this.setSelectedProductImage();
                    this.setProductValues(data);
                });
            } else {
                this.selectedProduct = data as unknown as IProductMappedItem;
            }

            if (this.selectedProduct?.images && this.selectedProduct?.images[0]?.id) {
                this.item.hasProductImage = true;
            } else {
                this.item.hasProductImage = false;
            }
            this.setProductValues(data);
        } else if (!this.selectedProduct) {
            this.selectedProduct = null;
            this.item.hasProductImage = false;
            this.item.product = null;
            if (this.item?.costType !== COST_TYPE_ENUM.DescriptiveOnly) {
                this.productSearchText = null;
            }
        }
    };

    public onCostTypeChange(costType: CostTypeEnumId): void {
        this.item.product = undefined;
        this.selectedProduct = undefined;
        this.productSearchText = undefined;
        if (costType === COST_TYPE_ENUM.Actual) {
            this.item.productOther = undefined;
        }
        if (costType === COST_TYPE_ENUM.Estimate || costType === COST_TYPE_ENUM.Provisional) {
            this.item.rateSnapshot = 0;
        }
    }

    public shouldShowQuoteRequired = (): boolean => {
        return this.item.costType === COST_TYPE_ENUM.Provisional
            || this.item.costType === COST_TYPE_ENUM.Estimate
            || this.item.costType === COST_TYPE_ENUM.NoCharge;
    };

    private setSelectedProductImage(): void {
        if (this.selectedProduct?.images[0]?.id) {
            this.selectedProduct.baseImageUrl += `${this.selectedProduct.images[0].id}.${this.selectedProduct.images[0].extension}`;
        } else {
            this.selectedProduct.baseImageUrl = undefined;
        }
    }

    public removeImage(form: UntypedFormGroup): void {
        this.item.$shouldRemoveImageOnSave = true;
        this.item.scheduleItemImageUrl = undefined;
        form.markAsDirty();
    }

    private setProductValues(data: IProductOfferingDto): void {
        if (this.selectedProduct.images?.length) {
            this.item.productImageUrl = this.selectedProduct.baseImageUrl;
        }
        this.productSearchText = data.name;
        this.productUom = UNIT_OF_MEASURE_ENUM[data.uom];
        if (this.selectedProduct && this.item?.product?.id !== this.selectedProduct?.id) {
            this.item.productOther = null;
            this.loadProduct();
        }
    }

    public clearProductForDescriptive(): void {
        if (this.item.costType === COST_TYPE_ENUM.DescriptiveOnly) {
            this.selectedProduct = undefined;
            this.productUpdate();
        }
    }

    public getRateLabel(): string {
        const costType = find(this.scheduleTemplateCostTypes, { id: this.item.costType });
        if (costType) {
            return costType.label + ' Amount';
        }
        return 'Amount';
    }

    public presave(): void {
        if (this.shouldHideQuantity) {
            this.item.quantity = 1;
        }
        if (this.item.supplierId === 0) {
            delete this.item.supplierId;
        }
    }

    public selectFile = (newFile: File, form: UntypedFormGroup): void => {
        if (this.uploadingImage) {
            return;
        }
        this.item.$scheduleItemImageFile = newFile;

        const reader = new FileReader();
        reader.addEventListener(
            'load', () => {
                this.item.scheduleItemImageUrl = reader.result as string;
                this.item.$shouldRemoveImageOnSave = false;
                form.markAsDirty();
            },
            false
        );

        if (newFile) {
            reader.readAsDataURL(newFile);
        }
    };

    public loadProduct = (): void => {
        if (!this.selectedProduct) {
            return;
        }

        this.suppliers = [{ id: 0, label: 'Not Specified' }];
        forEach(this.selectedProduct.rates, (rate) => {
            if (rate.businessAccountId && !find(this.suppliers, { id: rate.businessAccountId })) {
                this.suppliers.push({ id: rate.businessAccountId, label: rate.vendor });
            }
        });

        if (!this.item?.product) {
            this.item.product = { id: 0, name: '' };
        }
        if (this.selectedProduct.hasProductImage && this.selectedProduct?.images?.length) {
            this.item.productImageUrl = this.selectedProduct.imageUrls[0];
        } else {
            this.item.productImageUrl = null;
        }
        this.item.product.id = this.selectedProduct?.id;
        this.item.product.name = this.selectedProduct?.name;
        this.productUom = UNIT_OF_MEASURE_ENUM[this.selectedProduct?.uom];
    };

    public save(): void {
        const modelItem: ISpecTemplateItemDto = {
            id: this.item.id,
            costType: this.item.costType,
            productOther: this.item.productOther,
            rateSnapshot: this.item.rateSnapshot,
            specTemplateGroupId: this.item.specGroupId,
            supplierOther: '',
            options: []
        } as ISpecTemplateItemDto;

        this.specTemplateLogicService.addEditItem(this.template.id, this.item.specGroupId, modelItem).subOnce(() => this.dialogRef.close());
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
