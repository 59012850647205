import { Component, Input } from '@angular/core';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';

@Component({
    selector: 'cb-nullable-boolean',
    templateUrl: './nullable-boolean.component.html',
    styleUrls: ['./nullable-boolean.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(NullableBooleanComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class NullableBooleanComponent extends BaseFormComponentDirective {

    private static readonly yesLabel = 'Yes';
    private static readonly noLabel = 'No';
    private static readonly notSetLabel = 'Not Set';

    public readonly boolOptions = [
        { id: true, label: NullableBooleanComponent.yesLabel },
        { id: false, label: NullableBooleanComponent.noLabel },
        { id: null, label: NullableBooleanComponent.notSetLabel }
    ];

    public get trueLabel(): string {
        return this.boolOptions[0].label;
    }
    @Input() public set trueLabel(val: string) {
        this.setLabel(val, 0, NullableBooleanComponent.yesLabel);
    }

    public get falseLabel(): string {
        return this.boolOptions[1].label;
    }
    @Input() public set falseLabel(val: string) {
        this.setLabel(val, 1, NullableBooleanComponent.noLabel);
    }

    public get nullLabel(): string {
        return this.boolOptions[2].label;
    }
    @Input() public set nullLabel(val: string) {
        this.setLabel(val, 2, NullableBooleanComponent.notSetLabel);
    }

    private setLabel(val: string, index: number, defaultValue: string) {
        if (val != null && typeof (val) === 'string') {
            this.boolOptions[index].label = val;
        } else {
            this.boolOptions[index].label = defaultValue;
        }
    }
}
