import { Component, OnInit } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Observable } from 'rxjs';
import { SsrsLogicService } from '@app/logic/ssrs';
import { LotsLogicService } from '@app/logic/lots';
import { LeadLogicService } from '@app/logic/leads';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { ClientSaleLogicService } from '@app/logic/client-sale';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { each, some } from 'lodash';
import { SettingsPermissionsController } from '../../settings.permissions.class';

@Component({
    selector: 'cb-maintenance-settings',
    templateUrl: './maintenance-settings.component.html',
    styleUrls: ['./maintenance-settings.component.scss'],
})
export class MaintenanceSettingsComponent extends SettingsPermissionsController implements OnInit {
    public maintenanceItems: Array<MaintenanceItem>;
    public columns = ['Maintenance Item', ''];

    constructor(
        protected readonly permissionsPermissions: PermissionsPermissions,
        private readonly toastService: ToastService,
        private readonly ssrsLogicService: SsrsLogicService,
        private readonly lotsLogicService: LotsLogicService,
        private readonly leadLogicService: LeadLogicService,
        private readonly buildProgrammeLogicService: BuildProgrammeLogicService,
        private readonly clientSaleLogicService: ClientSaleLogicService,
        private readonly httpWrapperService: HttpWrapperService
    ) {
        super(permissionsPermissions);
    }

    public ngOnInit(): void {
        this.setupMaintenanceItems();
    }

    public isRunAllDisabled(): boolean {
        return !some(this.maintenanceItems, (item: MaintenanceItem) => !item.isRunning);
    }

    public onRunAllClicked(): void {
        each(this.maintenanceItems, (item: MaintenanceItem) => this.onItemClicked(item));
    }

    public onItemClicked(item: MaintenanceItem): void {
        if (item.isRunning) { return; }

        item.isRunning = true;
        item.action.subOnce({
            next: (result: string): any => {
                this.toastService.showToast(`"${item.label}" completed - ${result}`);
            },
            error: (): any => this.toastService.showToast(`An error occurred while running "${item.label}"`),
            complete: (): any => item.isRunning = false,
        });
    }

    private setupMaintenanceItems(): void {
        this.maintenanceItems = [
            new MaintenanceItem(
                Maintenance.Ssrs,
                'Denormalize legacy Ssrs',
                this.ssrsLogicService.flattenAllSsrs()
            ),
            new MaintenanceItem(
                Maintenance.LotFlags,
                'Recalculate all Lot Flags',
                this.lotsLogicService.recalculateAllLotFlags()
            ),
            new MaintenanceItem(
                Maintenance.LotDates,
                'Recalculate all Block Level Lot Dates',
                this.lotsLogicService.recalculateParentLotDates()
            ),
            new MaintenanceItem(
                Maintenance.SpecCache,
                'Rebuild all Lot Spec Cache',
                this.lotsLogicService.rebuildLotSpecCache()
            ),
            new MaintenanceItem(
                Maintenance.DesignSchemes,
                'Apply Finalised Design Schemes Amenities to Lots',
                this.lotsLogicService.applyDesignSchemesToLots()
            ),
            new MaintenanceItem(
                Maintenance.LeadFlags,
                'Recalculate all Lead Flags',
                this.leadLogicService.recalculateAllLeadFlags()
            ),
            new MaintenanceItem(
                Maintenance.ComplianceDocuments,
                'Regenerate Compliance Document Stubs',
                this.buildProgrammeLogicService.regenerateComplianceDocuments()
            ),
            new MaintenanceItem(
                Maintenance.HasSignedContractFlags,
                'Reevaluate Client Sale HasSignedContract flag',
                this.clientSaleLogicService.reevaluateClientSalesHaveSignedContract()
            ),
            new MaintenanceItem(
                Maintenance.CancelDocuments,
                'Cancel Documents for all lost Client Sales',
                this.clientSaleLogicService.cancelAllSaleLostDocuments()
            ),
            new MaintenanceItem(
                Maintenance.CopyImage,
                'Copy Images to Cache Bucket',
                this.httpWrapperService.get('/images/products/cache')
            ),
            new MaintenanceItem(
                Maintenance.IntegrationLogs,
                'Rebuild integration logs',
                this.httpWrapperService.post('/dailymaintenance/integrationlogs')
            ),
            new MaintenanceItem(
                Maintenance.RatesAndProductActivation,
                'Rates Inactivator',
                this.httpWrapperService.post('/dailymaintenance/offeringandrateactivation')
            )
        ];
    }
}

class MaintenanceItem {
    public isRunning: boolean;
    constructor(
        public id: number,
        public label: string,
        public action: Observable<any>) {
    }
}

enum Maintenance {
    Ssrs,
    LotFlags,
    LotDates,
    SpecCache,
    DesignSchemes,
    LeadFlags,
    ComplianceDocuments,
    HasSignedContractFlags,
    CancelDocuments,
    CopyImage,
    IntegrationLogs,
    RatesAndProductActivation
}

