export enum LeadPermissionEnum {
    None                    = 0x00,

    CanSearch               = 0x01 << 0,
    CanView                 = 0x01 << 1,
    CanCreate               = 0x01 << 2,
    CanEdit                 = 0x01 << 3,
    CanQualify              = 0x01 << 4,
    CanMarkAsLost           = 0x01 << 5,


    LotInterestCanAdd       = 0x01 << 6,
    LotInterestCanRemove    = 0x01 << 7,
    LotInterestCanSetMain   = 0x01 << 8,

    LeadContactCanAdd       = 0x01 << 9,
    LeadContactCanRemove    = 0x01 << 10,
    MainContactCanChange    = 0x01 << 11,

    NoteCanView             = 0x01 << 12,
    NoteCanAdd              = 0x01 << 13,
    NoteCanEdit             = 0x01 << 14,
    NoteCanDelete           = 0x01 << 15,

    TaskCanView             = 0x01 << 16,
    TaskCanAdd              = 0x01 << 17,
    TaskCanComplete         = 0x01 << 18,

    DocumentsCanView        = 0x01 << 19,
    DocumentsCanUpload      = 0x01 << 20,

    HistoryCanView          = 0x01 << 21,

    DocumentsCanEdit        = 0x01 << 22,
    DocumentsCanReview      = 0x01 << 23,
    DocumentsCanDelete      = 0x01 << 24,

    CanViewAll              = 0x01 << 25,
    CanViewForBusinesEntity = 0x01 << 26,

    TaskCanEdit             = 0x01 << 27
}
