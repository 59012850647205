import { IPurchaseOrderDto } from '@app/logic/purchase-orders/interfaces/i.purchase-order.dto';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { COST_NATURE_ENUM, MANUAL_ORDER_REASON_ENUM, PURCHASE_ORDER_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { IAllManualOrderSearch } from '@app/core/services/user-cache/user-cache-areas';
import { IPurchaseOrderMappedItem } from '@app/logic/purchase-orders';
import { IAllManualOrderSearchParams, ManualOrdersLogicService } from '@app/logic/all-manual-orders/manual-orders.logic.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PermissionsPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';


@Component({
    selector: 'cb-all-manual-orders',
    templateUrl: './all-manual-orders.component.html',
    styleUrls: ['./all-manual-orders.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllManualOrdersComponent implements AfterViewInit, OnDestroy {

    public results: IPurchaseOrderMappedItem[] = [];
    public readonly PURCHASE_ORDER_STATUS_ENUM = PURCHASE_ORDER_STATUS_ENUM;
    public statusList = PURCHASE_ORDER_STATUS_ENUM.toSelectList();
    public orderReasonList = MANUAL_ORDER_REASON_ENUM.toSelectList();
    public costNatureList = COST_NATURE_ENUM.toSelectList();

    private readonly _subscriptions = new Subscription();
    public searchFiltersLoaded$ = new Subject();
    public searchEnabled = false;
    public currentPage: number;
    public readonly searchFiltersChanged$ = new Subject();
    public loaded = false;

    public readonly displayedColumns: string[] = [
        'businessEntityName',
        'projectName',
        'jobNumber',
        'orderNumber',
        'orderDate',
        'activityName',
        'supplierName',
        'amount',
        'costNature',
        'manualOrderReason',
        'reasonDescription',
        'createdBy',
        'statusLabel',
        'actions'
    ];


    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: MatDialog,
        public readonly manualOrdersLogicService: ManualOrdersLogicService,
        public readonly toastService: ToastService,
        public readonly userCacheService: UserCacheService,
        private readonly navigationService: NavigationService,

    ) {

    }

    public fetchResults(): Observable<IPurchaseOrderDto[]> {
        if (!this.searchEnabled) {
            return;
        }

        this.loaded = true;
        return this.manualOrdersLogicService.$getSearchList(this._queryParams);
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public ngAfterViewInit(): void {
        this._initSearchFiltersCache();
    }

    public get searchFilters(): UserCacheItem<IAllManualOrderSearch> {
        return this.userCacheService.allManualOrderSearch;
    }

    private _initSearchFiltersCache(): void {
        this.searchFilters.init().then(() => {
            this.searchFiltersLoaded$.next(true);
            this.searchEnabled = true;

            this._subscriptions.add(
                this.searchFilters.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );
            this._onSearchFiltersChanged();
        });
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    private get _queryParams(): IAllManualOrderSearchParams {

        return this.searchEnabled ?
            {
                ...this.searchFilters.copyData(),
                currentPage: this.currentPage,
            } :
            undefined;
    }


    public viewLot(purchaseOrder: IPurchaseOrderDto): void {
        this.navigationService.navigate([`/lots/${purchaseOrder.lotId}/summary`]);
    }

}
