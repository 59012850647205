<cb-dialog class="flex-col"
           dialogHeading="View Message">
    <cb-display-value-new label="Message Datetime"
                          [value]="data.message.date | date:'dd/MM/yyyy hh:mm:ss'"></cb-display-value-new>
    <cb-display-value-new label="Instance ID"
                          [value]="data.message.instanceId"></cb-display-value-new>
    <cb-display-value-new label="Instance Private IP"
                          [value]="data.message.instancePrivateIp"></cb-display-value-new>
    <cb-display-value-new label="Sent/Received"
                          [value]="data.message.sentOrReceived"></cb-display-value-new>
    <cb-display-value-new label="Message Name"
                          [value]="data.message.name"></cb-display-value-new>
    <cb-display-value-new label="Message Version"
                          [value]="data.message.version"></cb-display-value-new>
    <cb-display-value-new class="cb-margin-top flex"
                          label="Message Body">
        <pre class="body-json">
            {{bodyJson | json}}
        </pre>
    </cb-display-value-new>
    <footer class="end-center flex-row">
        <button mat-raised-button
                (click)="close()">
            Close
        </button>
    </footer>
</cb-dialog>
