import { Component } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { DocumentNotificationTriggerLogicService } from '@app/logic/document-notification-trigger/document-notification-trigger.logic.service';
import { DocumentNotificationTriggerMappedItem } from '@app/logic/document-notification-trigger/document-notification-trigger.mapped';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { DOCUMENT_ACTION_ENUM, IDocumentNotificationTriggerDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { DocumentNotificationsDialogComponent } from './document-notifications-dialog/document-notifications-dialog.component';

@Component({
    selector: 'cb-document-notifications',
    templateUrl: './document-notifications.component.html',
    styleUrls: ['./document-notifications.component.scss']
})
export class DocumentNotificationsComponent extends BaseSimpleListViewDirective<IDocumentNotificationTriggerDto, DocumentNotificationTriggerLogicService> {
    public readonly columns = [
        'Document Type',
        'User Tag',
        'Team',
        'Document Action',
        'Notification Template',
        'Active',
        ''
    ];

    public DOCUMENT_ACTION_ENUM = DOCUMENT_ACTION_ENUM;

    constructor(
        protected readonly documentNotificationTriggerLogicService: DocumentNotificationTriggerLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly cbDialog: CbDialogService
    ) {
        super(
            cbDialog,
            documentNotificationTriggerLogicService,
            DocumentNotificationsDialogComponent,
            'Document Notification Trigger',
            DocumentNotificationTriggerMappedItem,
            permissionsPermissions,
            true,
            '25%'
        );
    }
}
