<div class="flex-row flex-100">
    <mat-tab-group class="flex"
                   [selectedIndex]="currentTabIndex"
                   (selectedIndexChange)="tabIndexChanged($event)">
        <mat-tab label="Business Account Details">
            <ng-template [matTabContent]>
                <cb-business-account-details [(account)]="account"
                                             [isCreate$]="isCreate$"
                                             [isEditing$]="isEditing$"
                                             (editFinanceInformationClicked)="onEditFinanceInformationClicked()"
                                             (accountUpdated)="accountUpdated()">
                </cb-business-account-details>
            </ng-template>
        </mat-tab>
        <mat-tab label="Locations & Contacts"
                 [disabled]="!accountId || isEditing$.value">
            <ng-template [matTabContent]>
                <div class="cb-margin cb-no-padding">
                    <mat-tab-group>
                        <mat-tab label="Locations">
                            <ng-template [matTabContent]>
                                <cb-business-account-locations *cbWaitFor="account"
                                                               [account]="account"
                                                               class="cb-padding-2x flex-row"></cb-business-account-locations>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Contacts">
                            <ng-template [matTabContent]>
                                <cb-business-account-contacts *cbWaitFor="account"
                                                              [account]="account"
                                                              class="cb-padding-2x flex-row"></cb-business-account-contacts>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Catalogue"
                 [disabled]="!accountId || isEditing$.value">
            <ng-template [matTabContent]>
                <cb-business-account-catalogues *cbWaitFor="account"
                                                [accountId]="accountId"></cb-business-account-catalogues>
            </ng-template>
        </mat-tab>
        <mat-tab label="Quotes"
                 [disabled]="!accountId || isEditing$.value">
            <ng-template [matTabContent]>
                <cb-business-account-quotes *cbWaitFor="account"
                                            [accountId]="accountId"></cb-business-account-quotes>
            </ng-template>
        </mat-tab>
        <mat-tab label="Orders"
                 [disabled]="!accountId || isEditing$.value">
            <ng-template [matTabContent]>
                <cb-business-account-orders *cbWaitFor="account"
                                            [accountId]="accountId"></cb-business-account-orders>
            </ng-template>
        </mat-tab>
        <mat-tab label="Documents"
                 [disabled]="!accountId || isEditing$.value">
            <ng-template [matTabContent]>
                <cb-document *cbWaitFor="account$ | async"
                             [entity]="account"
                             [permissions]="permissions.getDocumentPermissions()"
                             [includeLinked]="false"
                             [systemArea]="systemArea"></cb-document>
            </ng-template>
        </mat-tab>
        <mat-tab label="Notes"
                 [disabled]="!accountId || isEditing$.value">
            <ng-template [matTabContent]>
                <cb-notes-new *ngIf="account.id"
                              [entity]="account"
                              [edit]="true"
                              [permissions]="permissions.getNotePermissions()"></cb-notes-new>
            </ng-template>
        </mat-tab>
        <mat-tab label="Child Accounts"
                 [disabled]="!accountId || isEditing$.value">
            <ng-template [matTabContent]>
                <cb-business-account-child-accounts *cbWaitFor="account"
                                                    [accountId]="accountId"></cb-business-account-child-accounts>
            </ng-template>
        </mat-tab>
        <mat-tab label="Health & Safety"
                 [disabled]="true">
            <ng-template [matTabContent]>
                Health & Safety
            </ng-template>
        </mat-tab>
        <mat-tab label="History"
                 [disabled]="!accountId || isEditing$.value">
            <ng-template [matTabContent]>
                <cb-history-list [entityId]="accountId"
                                 entity="businessaccount"></cb-history-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <div class="side-panel cb-margin"
         [style.minWidth]="collapsed$.value ? 'unset' : '280px'">
        <button mat-mini-fab
                color="accent"
                class="collapse-button"
                (click)="collapsed$.next(!collapsed$.value)"
                [matTooltip]="collapsed$.value ? 'Expand' : 'Collapse'">
            <mat-icon>
                {{ collapsed$.value ? 'chevron_left' : 'chevron_right '}}
            </mat-icon>
        </button>
        <mat-card class="collapsible-card flex-col">
            <div class="flex-row"
                 *ngIf="(collapsed$ | async)">
                <h1 class="mat-title collapsed-title">{{account.tradingName}}</h1>
            </div>
            <div class="flex-col flex"
                 *ngIf="!(collapsed$ | async)">
                <div class="flex-col">
                    <div class="flex-col"
                         *ngIf="account.id">
                        <label class="mat-subheading-2 cb-margin-bottom">{{account.tradingName}}</label>
                        <mat-divider></mat-divider>
                        <cb-display-value-new label="Account Status"
                                              class="cb-margin-top"
                                              [value]="account.status | cbEnumToLabel: BUSINESS_ACCOUNT_STATUS_ENUM">
                        </cb-display-value-new>
                        <cb-display-value-new label="Account Code"
                                              class="cb-margin-top"
                                              [value]="account.accountCode">
                        </cb-display-value-new>
                        <cb-display-value-new label="ABM Code"
                                              class="cb-margin-top"
                                              [value]="account.abmCode">
                        </cb-display-value-new>
                        <div class="flex-col flex"
                             *ngIf="!isEditing$.value && currentTabIndex === 0">
                            <button mat-raised-button
                                    color="primary"
                                    class="cb-margin-top-2x"
                                    [disabled]="!account.canEdit() || !permissions.canEdit()"
                                    (click)="isEditing$.next(true)">
                                Edit Account
                            </button>
                            <button mat-raised-button
                                    color="primary"
                                    class="cb-margin-top-2x"
                                    [disabled]="!account.canEditFinanceInformation() || !permissions.canEditFinanceInformation()"
                                    (click)="onEditFinanceInformationClicked()">
                                Edit Finance Information
                            </button>
                            <button mat-raised-button
                                    color="primary"
                                    class="cb-margin-top-2x"
                                    [disabled]="!account.canRequestSevenDays() || !permissions.canChangePaymentFrequency()"
                                    (click)="onRequestSevenDaysClicked()">
                                Request 7 Days
                            </button>
                            <button mat-raised-button
                                    color="primary"
                                    class="cb-margin-top-2x"
                                    [disabled]="!account.canAddAccountHold() || !permissions.canApplyHold()"
                                    (click)="onAddAccountHoldClicked()">
                                Add Account Hold
                            </button>
                            <button mat-raised-button
                                    color="primary"
                                    class="cb-margin-top-2x"
                                    [disabled]="!account.canClose() || !permissions.canCloseAccount()"
                                    (click)="onCloseClicked()">
                                Close Account
                            </button>
                        </div>
                    </div>
                    <div class="flex-col flex"
                         *ngIf="!account.id || isEditing$.value">
                        <button mat-raised-button
                                color="primary"
                                class="cb-margin-top"
                                [disabled]="!canSave()"
                                (click)="onSaveClicked()">
                            Save
                        </button>
                        <button mat-raised-button
                                class="cb-margin-top"
                                (click)="onCancelClicked()">
                            Cancel
                        </button>
                    </div>
                    <div class="flex-col"
                         *ngIf="account.id">
                        <cb-user-date-time-new *ngIf="account.createdByName && account.createdDate"
                                               class="cb-margin-top-3x"
                                               [label]="'Created'"
                                               [userFullName]="account.createdByName"
                                               [date]="account.createdDate"
                                               [displayTime]="true"
                                               [floatingLabel]="true">
                        </cb-user-date-time-new>
                        <cb-user-date-time-new *ngIf="account.updatedByName && account.updatedDate"
                                               [label]="'Updated'"
                                               [userFullName]="account.updatedByName"
                                               [date]="account.updatedDate"
                                               [displayTime]="true"
                                               [floatingLabel]="true">
                        </cb-user-date-time-new>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
