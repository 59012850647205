import * as _moment from 'moment';

import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment, default as _rollupMoment } from 'moment';

import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


@Component({
    selector: 'cb-year-month-picker',
    templateUrl: './year-month-picker.component.html',
    styleUrls: ['./year-month-picker.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        ...getBaseFormComponentDirectiveProvider(YearMonthPickerComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class YearMonthPickerComponent extends BaseFormComponentDirective {
    public date = new UntypedFormControl(moment());

    public chosenMonthHandler(normalizedDate: Moment, datepicker: MatDatepicker<Moment>): void {
        this.date = new UntypedFormControl(moment([normalizedDate.year(), normalizedDate.month(), 1]));
        this.value = this.date.value;
        datepicker.close();
    }
}
