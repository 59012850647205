import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ILotSpecScheduleManageAttributeItemDto, ILotSpecScheduleManageAttributesDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { ILotSpecScheduleManageAttributeItemLogicService } from './interfaces/i.lot-spec-schedule-manage-attribute-item-logic.service';
import { ILotSpecScheduleManageAttributeItemMappedItem } from './interfaces/i.lot-spec-schedule-manage-attribute-item.mapped';
import { LotSpecScheduleManageAttributeItemMappedItem } from './lot-spec-schedule-manage-attribute-item.mapped';

@Injectable()
export class LotSpecScheduleManageAttributeItemLogicService
    extends BaseLogicService<
    ILotSpecScheduleManageAttributeItemDto,
    ILotSpecScheduleManageAttributeItemMappedItem>
    implements ILotSpecScheduleManageAttributeItemLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'lotspecschedule/attributeitems',
            LotSpecScheduleManageAttributeItemMappedItem
        );
    }

    public getLotSpecAndColourItemsWithAttributes(lotId: number): Observable<ILotSpecScheduleManageAttributesDto> {
        return this.$http.get<ILotSpecScheduleManageAttributesDto>(`${lotId}/${this.$baseUri}`);
    }

    public saveLotSpecAndColourItemsWithAttributes(lotId: number, dtos: ILotSpecScheduleManageAttributesDto): Observable<ILotSpecScheduleManageAttributesDto> {
        return this.$http.post<ILotSpecScheduleManageAttributesDto>(`${lotId}/${this.$baseUri}`, dtos);
    }
}
