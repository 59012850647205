import { BaseMappedItem } from '../base/base-mapped-item';
import { ISkinnyBuildProgrammeDto } from './interfaces/i.skinny-build-programme.dto';
import { ISkinnyBuildProgrammeMappedItem } from './interfaces/i.skinny-build-programme.mapped';
import { BuildProgrammeStatusEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { ISkinnyBuildProgrammeLogicService } from './interfaces/i.skinny-build-programme-logic.service';
import { DtoProp } from '../base/dto-prop.decorator';

export class SkinnyBuildProgrammeMappedItem
    extends BaseMappedItem<ISkinnyBuildProgrammeDto, ISkinnyBuildProgrammeMappedItem, ISkinnyBuildProgrammeLogicService>
    implements ISkinnyBuildProgrammeMappedItem {

    @DtoProp public id: number;
    @DtoProp public jobNumber: string;
    @DtoProp public region: string;
    @DtoProp public lot: string;
    @DtoProp public project: string;
    @DtoProp public assignedTo: string;
    @DtoProp public status: BuildProgrammeStatusEnumId;

    public get documentEntityId(): number {
        return this.id;
    }
    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    constructor(
        sourceData: ISkinnyBuildProgrammeDto,
        logicService: ISkinnyBuildProgrammeLogicService
    ) {
        super(
            sourceData,
            logicService,
            SkinnyBuildProgrammeMappedItem);
    }
}
