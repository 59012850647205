<div class="start-center flex-row">
    <h3 class="mat-title flex">
        Payment Templates
    </h3>
    <button mat-raised-button
            color="primary"
            (click)="addPaymentTemplate()"
            [disabled]="!canEdit()">
        Add Payment Template
    </button>
</div>
<cb-table class="cb-margin-top"
          label="Payment Templates"
          [columns]="columns"
          [loaded]="paymentTemplates.$resolved">
    <tr cbTableRow
        *ngFor="let paymentTemplate of paymentTemplates">
        <cb-td-text [value]="paymentTemplate.label"></cb-td-text>
        <cb-td-checkbox [value]="paymentTemplate.isActive"></cb-td-checkbox>
        <td cbTableShrinkCol>
            <button mat-icon-button
                    (click)="editPaymentTemplate(paymentTemplate)"
                    [disabled]="!canEdit()">
                <mat-icon>
                    edit
                </mat-icon>
            </button>
        </td>
    </tr>
</cb-table>
