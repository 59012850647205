import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LotQuotePermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem } from '@app/logic/lots';
import { IQuoteLogicService, IQuoteMappedItem, QuoteLineLogicService, QuoteLogicService } from '@app/logic/quote';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { UtilIcons } from '@app/shared/utils/util.icons';
import {
    COST_TYPE_ENUM,
    CostNatureEnumId,
    IQuoteDto,
    IQuoteLineDto,
    ISpecGroupDto,
    QUOTE_STATUS_ENUM,
    UNIT_OF_MEASURE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import moment from 'moment';
import { LotQuoteCreateDialogComponent } from '../lot-quote-create-dialog/lot-quote-create-dialog.component';
import { LotQuoteLineDialogComponent } from '../lot-quote-line-dialog/lot-quote-line-dialog.component';
import { LotQuoteSubmitDialogComponent } from '../lot-quote-submit-dialog/lot-quote-submit-dialog.component';

interface IData {
    quoteMappedItem: IQuoteMappedItem;
    isLotSpecLocked: boolean;
    lotMappedItem: ILotMappedItem;
}


@Component({
    selector: 'cb-lot-quote-dialog',
    templateUrl: './lot-quote-dialog.component.html',
    styleUrls: ['./lot-quote-dialog.component.scss']
})
export class LotQuoteDialogComponent extends BaseDialogFormViewDirective<IQuoteDto, IQuoteMappedItem, IQuoteLogicService> {

    public static readonly MIN_WIDTH = '980px';
    public QUOTE_STATUS_ENUM = QUOTE_STATUS_ENUM;
    public UNIT_OF_MEASURE_ENUM = UNIT_OF_MEASURE_ENUM;
    public isLotSpecLocked: boolean;
    public houseAreas: ISpecGroupDto[];
    public submitQuoteInProgress = false;
    public lotMappedItem: ILotMappedItem;
    public excludedSlotIds: number[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<LotQuoteDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(QuoteLogicService) public readonly logicService: QuoteLogicService,
        @Inject(LotQuotePermissions) private readonly lotQuotePermissions: LotQuotePermissions,
        @Inject(SpecGroupsLogicService) private readonly specGroupLogic: SpecGroupsLogicService,
        @Inject(QuoteLineLogicService) private readonly quoteLineLogic: QuoteLineLogicService,
        @Inject(NavigationService) public readonly navigationService: NavigationService,
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = this.data.quoteMappedItem;
        this.isLotSpecLocked = this.data.isLotSpecLocked;
        this.lotMappedItem = this.data.lotMappedItem;

        this.loadUIFlags();
        this.loadHouseAreas();
        this.loadExcludedSlots();
    }

    private readonly loadUIFlags = (): void => {
        this.mappedItem.changeRecordRequired = !this.isLotSpecLocked ? this.mappedItem.changeRecordRequired : true;
    };

    private loadHouseAreas(): void {
        this.specGroupLogic.$getList().subOnce((results) => {
            this.houseAreas = results;
        });
    }

    private loadExcludedSlots(): void {
        this.logicService.getExcludedSlots(this.mappedItem.id).subOnce(slotIds => {
            this.excludedSlotIds = slotIds;
        });
    }

    public canSubmitQuote(): boolean {
        return this.lotQuotePermissions.canSubmit();
    }

    public canAbandonQuote(): boolean {
        return this.lotQuotePermissions.canAbandon()
            && (this.mappedItem?.quoteStatus !== this.QUOTE_STATUS_ENUM.Abandoned)
            && ((this.mappedItem?.isScheduleSpecTemplate && !this.isLotSpecLocked && !this.mappedItem?.lotHasADesignConcept)
                || this.mappedItem?.quoteStatus === this.QUOTE_STATUS_ENUM.Required
                || this.mappedItem?.quoteStatus === this.QUOTE_STATUS_ENUM.Pending);
    }

    public abandonQuote(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Abandon Quote',
            message: 'Are you sure you want to Abandon this Quote?',
            confirmed: () => {
                this.logicService.abandonQuote(this.mappedItem.id).subOnce((result) => {
                    this.mappedItem.$updateThisAndOriginal(this.logicService.$createMappedItem(result));
                    this.dialogRef.close(result);
                });
            }
        });
    }

    public viewChangeRecords(): void {
        this.dialogRef.close();
        this.navigationService.navigate([`lots/${this.mappedItem.lotId}/changes/changes`], undefined, {
            queryParams: {
                changeRecordIds: this.mappedItem.changeRecordIds
            }
        });
    }

    public canOpenEditDialog(): boolean {
        const status = this.mappedItem.quoteStatus;
        return status === QUOTE_STATUS_ENUM.Required || status === QUOTE_STATUS_ENUM.Pending && this.canEditQuote();
    }

    public canEditQuote(): boolean {
        return this.lotQuotePermissions.canEdit();
    }

    public quoteLineCreateDisabled(): boolean {
        return this.mappedItem.quoteStatus !== this.QUOTE_STATUS_ENUM.Pending || this.mappedItem.quoteTotal === this.lineTotal();
    }

    public unallocatedAmount(): number {
        const sum = this.mappedItem.quoteLines.reduce(
            (a, b) => {
                return b.isRemoved ? Number(a) : Number(a) - (Number(b.allocatedAmount) || 0);
            },
            this.mappedItem.quoteTotal);

        return Number(sum.toFixed(2));
    }

    public lineTotal(): number {
        const filteredLines = this.mappedItem.quoteLines.filter(item => !item.isRemoved);
        return filteredLines.length > 0 ?
            filteredLines.reduce((a, b) => {
                return Number(a) + Number(b.allocatedAmount);
            }, 0) : 0;
    }

    public hasExpired(): boolean {
        return moment().format() > this.mappedItem?.expiryDate;
    }

    public downloadQuote(): void {
        this.logicService.downloadQuote(this.mappedItem.id).subOnce();
    }

    public editQuote(): void {
        this.cbDialog.open(LotQuoteCreateDialogComponent, {
            data: {
                mappedItem: this.mappedItem,
                lotMappedItem: this.lotMappedItem,
            }
        });
    }

    public submitQuote(): void {
        this.cbDialog
            .open(LotQuoteSubmitDialogComponent, {
                data: this.mappedItem
            })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.logicService
                        .submitQuote(this.mappedItem.id, this.mappedItem.$getMappedDtoItem())
                        .subOnce(quote => {
                            this.mappedItem = this.logicService.$createMappedItem(quote);
                            this.dialogRef.close(quote);
                        });
                }
            });
    }

    // Overriding closing of dialog on save/ Submit
    protected handleNext = (): void => { };


    public createQuoteLine(form: UntypedFormGroup): void {
        const _quoteLineDto = {
            quoteId: this.mappedItem.id,
            allocatedAmount: 0,
            amount: 0,
            assignedAmount: 0,
            gstApplies: true,
        } as IQuoteLineDto;

        if (this.mappedItem.isScheduleSpecTemplate) {
            _quoteLineDto.costTypeId = COST_TYPE_ENUM.Quote;
        }

        this.mappedItem.quoteLines.push(_quoteLineDto);
        const elementsIndex = this.mappedItem.quoteLines.indexOf(_quoteLineDto);

        const dialogRef = this.cbDialog.open(LotQuoteLineDialogComponent, {
            data: {
                quoteLineDto: this.mappedItem.quoteLines[elementsIndex],
                quoteMappedItem: this.mappedItem,
                houseAreas: this.houseAreas,
                excludedSlotIds: this.excludedSlotIds,
                formMode: FormMode.Add
            }
        });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {
                // Add Recently Added Slot To Existing List Of Slot to Exclude from dropdown
                this.excludedSlotIds.push(result?.slot?.id);

                form.markAsDirty();
            } else {
                // If no changes applied remove the item
                this.mappedItem.quoteLines.splice(elementsIndex, 1);
            }
        });
    }


    public editQuoteLine(quoteLine: IQuoteLineDto, form: UntypedFormGroup): void {
        const originalquoteLine = cloneDeepSafe(this.mappedItem.quoteLines.find( x => x.id === quoteLine.id));
        const existingSlotId = quoteLine?.slot?.id;

        const foundQuoteLine = this.mappedItem.quoteLines.find( x => x.id === quoteLine.id);
        const foundQuoteLineIndex = this.mappedItem.quoteLines.findIndex(x => x.id === quoteLine.id);
        const dialogRef = this.cbDialog.open(LotQuoteLineDialogComponent, {
            data: {
                quoteLineDto: foundQuoteLine,
                quoteMappedItem: this.mappedItem,
                houseAreas: this.houseAreas,
                excludedSlotIds: this.excludedSlotIds,
                formMode: FormMode.Edit
            }
        });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {

                //  if the Schedule Item been changed,
                if (existingSlotId !== result?.slot?.id) {

                    // Revove the existing Slot From List of Ids to Excluded Slot
                    const existingSlotIndex = this.excludedSlotIds.indexOf(existingSlotId);
                    if (existingSlotIndex > -1) {
                        this.excludedSlotIds.splice(existingSlotIndex, 1);
                    }

                    // Add New Slot to the list of Ids to Exclude
                    this.excludedSlotIds.push(result?.slot?.id);
                }

                form.markAsDirty();
            }

            else {
                // If no changes applied revert back to original state
                this.mappedItem.quoteLines[foundQuoteLineIndex] = originalquoteLine;
            }
        });
    }


    public removeQuoteLine(quoteLine: IQuoteLineDto, form: UntypedFormGroup): void {
        // find actual quoteLine and quoteLine Index
        const foundQuoteLine = this.mappedItem.quoteLines.find( x => x.id === quoteLine.id);
        const foundQuoteLineIndex = this.mappedItem.quoteLines.findIndex(x => x.id === quoteLine.id);

        // If already exist replace the selected items
        if (foundQuoteLineIndex > -1) {
            this.mappedItem.quoteLines.splice(foundQuoteLineIndex, 1);
            form.markAsDirty();
        }

        // Remove Slot Id From List of excludedSlotIds
        const existingSlotIndex = this.excludedSlotIds.indexOf(foundQuoteLine?.slot?.id);
        if (existingSlotIndex > -1) {
            this.excludedSlotIds.splice(existingSlotIndex, 1);
        }
    }


    public finaliseQuoteLine(quoteLine: IQuoteLineDto): void {
        this.cbDialog.confirm({
            message: 'Are you sure you want to finalise this Quote Line?',
            confirmed: () => this.handleFinaliseQuoteLine(quoteLine)
        });
    }

    private readonly handleFinaliseQuoteLine = (quoteLine: IQuoteLineDto): void => {
        this.quoteLineLogic.finaliseQuoteLine(quoteLine.id).subOnce((quoteLineDto) => {

            const elementsIndex = this.mappedItem.quoteLines.findIndex(element => element.id === quoteLineDto.id);

            // If already exist replace the selected items
            if (elementsIndex > -1) {
                this.mappedItem.quoteLines[elementsIndex] = quoteLineDto;
            }
        });
    };

    public viewFinalised(_quoteLineDto: IQuoteLineDto): void {
        this.cbDialog.open(LotQuoteLineDialogComponent, {
            data: {
                quoteLineDto: _quoteLineDto,
                quoteMappedItem: this.mappedItem,
                houseAreas: this.houseAreas,
                excludedSlotIds: this.excludedSlotIds,
                formMode: FormMode.View
            }
        });
    }

    public saveQuote(form: UntypedFormGroup): void {
        this.mappedItem.$save().subOnce((result) => {
            this.mappedItem = this.logicService.$createMappedItem(result);
        });

        form.markAsPristine();
        form.markAsUntouched();
    }

    public getCostNatureIcon = (costNature: CostNatureEnumId): string => UtilIcons.getCostNatureIcon(costNature);

    public getCostNatureIconTitle = (costNature: CostNatureEnumId): string => UtilIcons.getCostNatureIconTitle(costNature);


}


