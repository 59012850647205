'use strict';


export class StringMask {

    tokens = {
        '#': { optional: true, pattern: /\d/, recursive: true },
        $: { escape: true },
        '+': { pattern: /[+]?/ },
        0: { _default: '0', pattern: /\d/ },
        9: { optional: true, pattern: /\d/ },
        A: { pattern: /[a-zA-Z0-9]/ },
        L: { pattern: /[a-zA-Z]/, transform(c) { return c.toLocaleLowerCase(); } },
        S: { pattern: /[a-zA-Z]/ },
        U: { pattern: /[a-zA-Z]/, transform(c) { return c.toLocaleUpperCase(); } },
    };

    static TYPES = {
        BANK: {
            allowsChars: '0-9',
            mask: '00-0000-0000000-009',
        },
        GST: {
            allowsChars: '0-9',
            mask: '900-000-000',
        },
        IRD: {
            allowsChars: '0-9',
            mask: '900-000-000',
        },
        LANDLINE: {
            allowsChars: '[0-9]',
            mask: '+999999999999999',
        },
        MOBILE: {
            allowsChars: '[0-9]',
            mask: '+999999999999999',
        }
    };


    options;
    pattern;
    regexPattern;

    private cleanRegExpression: RegExp;
    private trimRegExpression: RegExp;

    private static maskObjects: any = {};

    static filter() {
        return (input, filterName) => {
            //  filter stuff here
            const stringMask = StringMask.GetStringMask(filterName);

            if (input) {
                return stringMask.process(input).result;
            }

            return '';
        };
    }

    static GetStringMask(type: string): StringMask {
        if (StringMask.maskObjects[type] == undefined) {
            StringMask.maskObjects[type] = new StringMask(type);
        }
        return StringMask.maskObjects[type];
    }

    constructor(type, opt?) {
        this.options = opt || {};
        this.options = {
            reverse: this.options.reverse || false,
            usedefaults: this.options.usedefaults || this.options.reverse
        };
        this.pattern = StringMask.TYPES[type].mask;
        const allowedChars = StringMask.TYPES[type].allowsChars;
        // create regular expressions
        this.cleanRegExpression = new RegExp('[^' + allowedChars + ']', 'g');
        this.trimRegExpression = new RegExp(
            '^([\s])+|([\s]|[^' +
            allowedChars + '])+$',
            'g');
    }

    process = (value): { result: string; cleaned: string; valid: boolean } => {
        const cleanedValue = this.cleanValue(value);
        const result = this.format(cleanedValue);
        return {
            cleaned: cleanedValue,
            result: result.result,
            valid: result.valid
        };
    };


    apply = (value) => {
        return this.process(value).result;
    };

    validate = (value) => {
        return this.process(value).valid;
    };

    /**
     * will remove all disallowed characters
     *
     * @param value the string to remove from
     */
    cleanValue = (value: string) => {
        if (value) {
            value = value.toString();
            return value.replace(this.cleanRegExpression, '');
        } else {
            return undefined;
        }
    };

    /**
     * will form that value to check it validity and show on the screen
     */
    private format(value: string, returnValid?: boolean): { result: string; valid: boolean } {

        // format using the mask
        const data = this.processMask(value);

        // trim any trailing characters
        data.result = data.result.replace(this.trimRegExpression, '');

        return data;
    }


    private processMask(value): { result: string; valid: boolean } {
        if (!value) {
            return { result: '', valid: false };
        }
        value = value + '';
        let pattern2 = this.pattern;
        let valid = true;
        let formatted = '';
        let valuePos = this.options.reverse ? value.length - 1 : 0;
        let patternPos = 0;
        let optionalNumbersToUse = this.calcOptionalNumbersToUse(pattern2, value);
        let escapeNext = false;
        const recursive = [];
        let inRecursiveMode = false;

        const steps = {
            end: this.options.reverse ? -1 : pattern2.length,
            inc: this.options.reverse ? -1 : 1,
            start: this.options.reverse ? pattern2.length - 1 : 0
        };

        const continueCondition = (options) => {
            if (!inRecursiveMode && !recursive.length && this.hasMoreTokens(pattern2, patternPos, steps.inc)) {
                // continue in the normal iteration
                return true;
            } else if (!inRecursiveMode && recursive.length &&
                this.hasMoreRecursiveTokens(pattern2, patternPos, steps.inc)) {
                // continue looking for the recursive tokens
                // Note: all chars in the patterns after the recursive portion will be handled as static string
                return true;
            } else if (!inRecursiveMode) {
                // start to handle the recursive portion of the pattern
                inRecursiveMode = recursive.length > 0;
            }

            if (inRecursiveMode) {
                const pc = recursive.shift();
                recursive.push(pc);
                if (options.reverse && valuePos >= 0) {
                    patternPos++;
                    pattern2 = this.insertChar(pattern2, pc, patternPos);
                    return true;
                } else if (!options.reverse && valuePos < value.length) {
                    pattern2 = this.insertChar(pattern2, pc, patternPos);
                    return true;
                }
            }
            return patternPos < pattern2.length && patternPos >= 0;
        };

        /**
         * Iterate over the pattern's chars parsing/matching the input value chars
         * until the end of the pattern. If the pattern ends with recursive chars
         * the iteration will continue until the end of the input value.
         *
         * Note: The iteration must stop if an invalid char is found.
         */
        for (patternPos = steps.start; continueCondition(this.options); patternPos = patternPos + steps.inc) {
            // Value char
            const vc = value.charAt(valuePos);
            // Pattern char to match with the value char
            const pc = pattern2.charAt(patternPos);

            let token = this.tokens[pc];
            if (recursive.length && token && !token.recursive) {
                // In the recursive portion of the pattern: tokens not recursive must be seen as static chars
                token = undefined;
            }

            // 1. Handle escape tokens in pattern
            // go to next iteration: if the pattern char is a escape char or was escaped
            if (!inRecursiveMode || vc) {
                if (this.options.reverse && this.isEscaped(pattern2, patternPos)) {
                    // pattern char is escaped, just add it and move on
                    formatted = this.concatChar(formatted, pc, this.options, token);
                    // skip escape token
                    patternPos = patternPos + steps.inc;
                    continue;
                } else if (!this.options.reverse && escapeNext) {
                    // pattern char is escaped, just add it and move on
                    formatted = this.concatChar(formatted, pc, this.options, token);
                    escapeNext = false;
                    continue;
                } else if (!this.options.reverse && token && token.escape) {
                    // mark to escape the next pattern char
                    escapeNext = true;
                    continue;
                }
            }

            // 2. Handle recursive tokens in pattern
            // go to next iteration: if the value str is finished or
            //                       if there is a normal token in the recursive portion of the pattern
            if (!inRecursiveMode && token && token.recursive) {
                // save it to repeat in the end of the pattern and handle the value char now
                recursive.push(pc);
            } else if (inRecursiveMode && !vc) {
                // in recursive mode but value is finished. Add the pattern char if it is not a recursive token
                formatted = this.concatChar(formatted, pc, this.options, token);
                continue;
            } else if (!inRecursiveMode && recursive.length > 0 && !vc) {
                // recursiveMode not started but already in the recursive portion of the pattern
                continue;
            }

            // 3. Handle the value
            // break iterations: if value is invalid for the given pattern
            if (!token) {
                // add char of the pattern
                formatted = this.concatChar(formatted, pc, this.options, token);
                if (!inRecursiveMode && recursive.length) {
                    // save it to repeat in the end of the pattern
                    recursive.push(pc);
                }
            } else if (token.optional) {
                // if token is optional, only add the value char if it matchs the token pattern
                //                       if not, move on to the next pattern char
                if (token.pattern.test(vc) && optionalNumbersToUse) {
                    formatted = this.concatChar(formatted, vc, this.options, token);
                    valuePos = valuePos + steps.inc;
                    optionalNumbersToUse--;
                } else if (recursive.length > 0 && vc) {
                    valid = false;
                    break;
                }
            } else if (token.pattern.test(vc)) {
                // if token isn't optional the value char must match the token pattern
                formatted = this.concatChar(formatted, vc, this.options, token);
                valuePos = valuePos + steps.inc;
            } else if (!vc && token._default && this.options.usedefaults) {
                // if the token isn't optional and has a default value, use it if the value is finished
                formatted = this.concatChar(formatted, token._default, this.options, token);
            } else {
                // the string value don't match the given pattern
                valid = false;
                break;
            }
        }

        return { result: formatted, valid };
    }

    private isEscaped(pattern, pos) {
        let count = 0;
        let i = pos - 1;
        let token = { escape: true };
        while (i >= 0 && token && token.escape) {
            token = this.tokens[pattern.charAt(i)];
            count += token && token.escape ? 1 : 0;
            i--;
        }
        return count > 0 && count % 2 === 1;
    }

    private calcOptionalNumbersToUse(pattern, value) {
        const numbersInP = pattern.replace(/[^0]/g, '').length;
        const numbersInV = value.replace(/[^\d]/g, '').length;
        return numbersInV - numbersInP;
    }

    private concatChar(text, character, options, token) {
        if (token && typeof token.transform === 'function') {
            character = token.transform(character);
        }
        if (options.reverse) {
            return character + text;
        }
        return text + character;
    }

    private hasMoreTokens(pattern, pos, inc) {
        const pc = pattern.charAt(pos);
        const token = this.tokens[pc];
        if (pc === '') {
            return false;
        }
        return token && !token.escape ? true : this.hasMoreTokens(pattern, pos + inc, inc);
    }

    private hasMoreRecursiveTokens(pattern, pos, inc) {
        const pc = pattern.charAt(pos);
        const token = this.tokens[pc];
        if (pc === '') {
            return false;
        }
        return token && token.recursive ? true : this.hasMoreRecursiveTokens(pattern, pos + inc, inc);
    }

    private insertChar(text, char, position) {
        const t = text.split('');
        t.splice(position, 0, char);
        return t.join('');
    }
}
