<div class="cb-toast start-center"
     [@openClose]="isOpen ? 'open' : 'closed'"
     (@openClose.done)="onAnimationFinished($event)"
     [ngStyle]="{'top': toast.top + 'px'}">
    <div class="text-center flex">
        <span>{{toast.content}}</span>
        <span class="flex"></span>
        <button mat-stroked-button
                (click)="toast.triggerButtonAction()"> {{toast.buttonLabel}}
        </button>
    </div>
</div>
