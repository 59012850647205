import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IPreConsentPlanMappedItem } from '@app/logic/pre-consent-plans';
import { IPreConsentPlansLogicService } from '@app/logic/pre-consent-plans/interfaces/i.pre-consent-plans-logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IDesignComplexityDto, IPreConsentPlanDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    preConsentPlanMappedItem: IPreConsentPlanMappedItem;
}

@Component({
    selector: 'cb-manage-pre-consent-dialog',
    templateUrl: './manage-pre-consent-dialog.component.html',
    styleUrls: ['./manage-pre-consent-dialog.component.scss']
})
export class ManagePreConsentDialogComponent extends BaseDialogFormViewDirective<IPreConsentPlanDto, IPreConsentPlanMappedItem, IPreConsentPlansLogicService> {
    public static readonly MIN_WIDTH = '70%';

    public designComplexities: IDesignComplexityDto[];
    public designComplexityId: number;

    public get mappedItem(): IPreConsentPlanMappedItem {
        this.designComplexityId = this.data.preConsentPlanMappedItem?.designComplexity?.id;
        return this.data.preConsentPlanMappedItem;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<ManagePreConsentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
    }

    public saveAndSetPristine(form: NgForm): void {
        super.save();
        form.form.markAsPristine();
    }
}
