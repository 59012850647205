<td class="activity-icons-col actions">
    <cb-lot-build-programme-activity-icons [lotDto]="lotDto"
                                           [buildProgrammeActivity]="activity">
    </cb-lot-build-programme-activity-icons>
</td>
<td>{{activity?.activity?.code | cbDasher}}</td>
<td>{{activity?.activity?.name | cbDasher}}</td>
<td *ngIf="displayUnitNumber">{{activity?.lotNumber | cbDasher}}</td>
<td>{{activity?.supplierName | cbDasher}}
</td>
<td>{{(activity?.actualStartDate != undefined ? activity?.actualStartDate : activity?.estimatedStartDate) | date}}
</td>
<td>{{(activity?.actualEndDate != undefined ? activity?.actualEndDate : activity?.estimatedEndDate) | date}}
</td>
<td [ngClass]="{ 'table-accent' :
                !activity?.activityDurationDays
                && activity?.statusId !==BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned}">
    {{activity?.activityDurationDays | cbDasher}}
</td>
<td>
    {{ activity?.purchaseOrderSummaryDto?.orderSubtotal | currency }}
</td>
<td class="actions">
    <cb-lot-build-programme-activity-menu *ngIf="activity.statusId !== BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned"
                                          [lot]="lotDto"
                                          [buildProgrammeActivity]="activity"
                                          [downloadOnly]="downloadOnly">
    </cb-lot-build-programme-activity-menu>
</td>
