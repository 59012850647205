import {Component, HostBinding} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { BuildActivitiesLogicService, ISkinnyBuildActivityDto } from '@app/logic/build-activities';
import { takeOne } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider } from '../../autocomplete-scroller.component';

@Component({
    selector: 'cb-build-activity-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(BuildActivityAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(BuildActivityAutocompleteScrollerComponent),
        SearchService,
    ]
})
export class BuildActivityAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent {

    public results: ISkinnyBuildActivityDto[] = [];
    private resultsLoaded = false;

    @HostBinding('class') class = 'flex-row flex';
    constructor(
        public readonly buildActivityLogic: BuildActivitiesLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: SearchService
    ) {
        super(dialog, searchService);
        this.searchService.doSearch = this.doSearch;
    }

    public doSearch = (query: string, page: number): Observable<any[]> => {
        return this.getResults()
            .pipe(takeOne(), map((results) => {
                const take = 10;
                const skip = page > 0 ? (page - 1) * take : 0;

                const filtered = results
                    .filter(x =>
                        ((this.displayWith(x) || '').toLowerCase().indexOf(query.toLowerCase()) > -1)
                    );

                return filtered.slice(skip, skip + take);
            }));
    };

    public displayWith(item: ISkinnyBuildActivityDto): string {
        return item ? `${item.code} - ${item.name}` : null;
    }

    /** Reloads results if applicable */
    private getResults(): Observable<ISkinnyBuildActivityDto[]> {
        return new Observable<ISkinnyBuildActivityDto[]>((subscriber) => {
            if (!this.resultsLoaded) {
                this.resultsLoaded = true;

                const resultsObservable = this.buildActivityLogic.getSkinnyList();

                resultsObservable
                    .subOnce(x => {
                        this.results = x;
                        subscriber.next(this.results);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.results);
                subscriber.complete();
            }
        });
    }
}
