import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouncilRfiListCardComponent } from './council-rfi-list-card.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';



@NgModule({
    declarations: [
        CouncilRfiListCardComponent,
    ],
    exports: [
        CouncilRfiListCardComponent,
    ],
    imports: [
        CommonModule,
        CbDisplayValueModule,
        MatIconModule,
        MatDividerModule,
        MatButtonModule,
        MatCardModule,
        MatTooltipModule,
    ]
})
export class CouncilRfiListCardModule { }
