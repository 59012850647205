import { Component, Inject } from '@angular/core';
import { IARVariationDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IARVariationLogicService } from '@app/logic/ar-variation/interfaces/i.ar.variation.logic-service';
import { IARVariationMappedItem } from '@app/logic/ar-variation/interfaces/i.ar.variation.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';

export interface IData {
    mappedItem: IARVariationMappedItem;
}

@Component({
    selector: 'ar-edit-variation-dialog',
    templateUrl: './ar-edit-variation-dialog.component.html',
    styleUrls: ['./ar-edit-variation-dialog.component.scss'],
})
export class AREditVariationDialogComponent extends BaseDialogFormViewDirective<IARVariationDto, IARVariationMappedItem, IARVariationLogicService> {
    public static readonly MIN_WIDTH = '66%';

    public get mappedItem(): IARVariationMappedItem {
        return this.data.mappedItem;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<AREditVariationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
        /// This is expected behavior to not allow null in arChangeDetails and return changeDetails
        this.data.mappedItem.arChangeDetails = !this.data.mappedItem.arChangeDetails ? this.data.mappedItem.changeDetails : this.data.mappedItem.arChangeDetails;
    }

    public saveArDetails(): void {

        this.saveInProgress = true;
        this.mappedItem.$save()
            .subOnce({
                next: (success: IARVariationMappedItem) => {
                    if (success) {
                        this.toastService.saveSuccess();
                        this.dialogRef.close(this.mappedItem);
                    }else {
                        this.toastService.saveError('There was an error saving the updated details. Please try again.');
                    }
                },
                error: (error) => {
                    this.handleError(this.handleSaveError(error));
                },
                complete: () => {
                    this.saveInProgress = false;
                }
            });
    }

    public cancelDialog(): void {
        return this.dialogRef.close();
    }

}
