import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';
import {ClientAccountLogicService} from '@app/logic/client-account/client-account.logic.service';
import {IClientAccountDto} from '@app/logic/client-account/interfaces/i.client-account.dto';

@Injectable()
export class ClientAccountSearchService extends SearchService {

    constructor(
        public readonly clientAccountLogic: ClientAccountLogicService,
    ) {
        super();
    }

    public getResults(query: string, currentpage: number): Observable<IClientAccountDto[]> {
        return new Observable<IClientAccountDto[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;

                const resultsObservable = this.clientAccountLogic.search({
                    query,
                    currentpage,
                    ...this.extraSearchParams
                });

                if (!resultsObservable) {
                    return;
                }

                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<IClientAccountDto>(x);
                        subscriber.next(this.searchResults$.value as IClientAccountDto[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as IClientAccountDto[]);
                subscriber.complete();
            }
        });
    }
}
