import { Component, Input, OnDestroy } from '@angular/core';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { DateFormats } from '@app/shared/declarations/date-formats.constants';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { DESIGN_SCHEME_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cb-scheme-details',
    templateUrl: './scheme-details.component.html',
    styleUrls: ['./scheme-details.component.scss']
})
export class SchemeDetailsComponent implements OnDestroy {
    private sub$ = new Subscription();
    private _mappedItem: IDesignSchemeMappedItem;
    @Input() public set mappedItem(designScheme: IDesignSchemeMappedItem) {
        this._mappedItem = designScheme;
        this.sub$.unsubscribe();
        if (this.mappedItem) {
            this.sub$ = this.mappedItem.$updated.subscribe(() => {
                this.rejectionHistories.recompute();
            });
        }
    }
    public get mappedItem(): IDesignSchemeMappedItem {
        return this._mappedItem;
    }

    public readonly rejectionHistories = new ComputedProperty(() => {
        const rejectionHistory = this.mappedItem
            ?.designSchemeHistories
            ?.filter((x) => x.toStatus === DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution);

        return orderBy(rejectionHistory ?? [], (x) => x.createdDate);
    });

    @Input() public readonly displayName: boolean;
    public buildTypes: { [buildTypeId: number]: string } = {};

    public readonly REJECTION_DATE_FORMAT = DateFormats.TIMEDATE_STANDARD;

    constructor(
        private readonly lookups: LookupService,
    ) {
        this.lookups.BUILD_TYPES.toLookup().$promise.then((result) => {
            this.buildTypes = result.reduce((prev, curr) => {
                prev[curr.id] = curr.label;
                return prev;
            }, {} as { [buildTypeId: number]: string });
        });
    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    public displayRejectionNotes(): boolean {
        return this.mappedItem?.statusId === DESIGN_SCHEME_STATUS_ENUM.Declined;
    }
}
