<div class="flex-row flex">
    <cb-datepicker class="cb-margin-right"
                   [label]="startDateLabel"
                   [name]="name + '_startDateInputName'"
                   [(ngModel)]="startDateLocal"
                   (change)="dateChanged()"
                   [required]="required"
                   [nonWorkingDays]="nonWorkingDays"
                   [pickerType]="pickerType"
                   [disabled]="disabled">
    </cb-datepicker>
    <cb-input class="cb-margin-right"
              label="Duration (Days)"
              type="number"
              [minimum]="MIN_DURATION"
              [maximum]="MAX_DURATION"
              [name]="name + '_durationInputName'"
              [(ngModel)]="durationLocal"
              (ngModelChange)="dateChanged()"
              [required]="required"
              [disabled]="disabled">
    </cb-input>
    <cb-loading-spinner *ngIf="calculatingEndDate"
                        message="">
    </cb-loading-spinner>
    <cb-display-value-new *ngIf="!calculatingEndDate"
                          label="End Date"
                          [value]="endDate | date"></cb-display-value-new>
</div>
