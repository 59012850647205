import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { LotBuildProgrammeEventService } from '@app/views/lot/build/services/lot-build-programme-event.service';
import { IBuildProgrammeActivityMappedItem, IBuildProgrammeActivityDto } from '@app/logic/build-programme-activity';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import moment from 'moment';


@Component({
    selector: 'cb-edit-build-programme-activity',
    templateUrl: './edit-build-programme-activity.component.html',
    styleUrls: ['./edit-build-programme-activity.component.scss'],
    viewProviders: [
        provideParentForm()
    ]
})
export class EditBuildProgrammeActivityComponent {

    private buildProgrammeActivityCopy: IBuildProgrammeActivityDto;
    private _buildProgrammeActivity: IBuildProgrammeActivityMappedItem;
    @HostBinding('class') class = 'flex-row';
    @Input() public set buildProgrammeActivity(v: IBuildProgrammeActivityMappedItem) {
        this._buildProgrammeActivity = v;
        this.buildProgrammeActivityCopy = v.$getMappedDtoItem();
    }
    public get buildProgrammeActivity(): IBuildProgrammeActivityMappedItem {
        return this._buildProgrammeActivity;
    }

    @Output() public readonly dateCalculating = new EventEmitter<boolean>();
    @Output() public readonly durationChanged = new EventEmitter<number>();

    public datesHaveChanged = false;

    constructor(
        public readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService
    ) { }


    public durationHasChanged(newDuration: number): void {
        this.buildProgrammeActivity.activityDurationDays = newDuration;
        this.durationChanged.emit(newDuration);
    }

    @Debounce(500)
    public dateChange(): void {
        this.datesHaveChanged = this.datesNotEqual(this.buildProgrammeActivity.actualStartDate, this.buildProgrammeActivityCopy.actualStartDate)
            || this.datesNotEqual(this.buildProgrammeActivity.estimatedStartDate, this.buildProgrammeActivityCopy.estimatedStartDate)
            || this.buildProgrammeActivity.activityDurationDays?.toString()?.trim() !== this.buildProgrammeActivityCopy.activityDurationDays?.toString()?.trim();
        if (!this.datesHaveChanged) {
            this.buildProgrammeActivity.moveConfirmedDescendants = false;
        }
    }

    private datesNotEqual(newValue: Date | string, prevValue: Date | string): boolean {
        return newValue != null && (prevValue == null || (prevValue != null && !moment(newValue).isSame(prevValue, 'day')));
    }
}
