import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {NewHttpErrorsService} from '../services/http-errors/http-errors.service';
import {Inject} from '@angular/core';

export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        @Inject(NewHttpErrorsService) public readonly httpErrors: NewHttpErrorsService
    ) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(this.handleErrorResponse.bind(this, req))
        );
    }

    private readonly handleErrorResponse = (req: HttpRequest<any>, err: HttpErrorResponse): Observable<HttpEvent<any>> => {
        // re-throw error so that it can be caught again by a subscriber or pipe
        this.httpErrors.log(req, err);
        return throwError(err);
    };
}
