import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import humanizeString from 'humanize-string';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IPaymentFrequencyDto } from '@app/logic/payment-frequencies/interfaces/i.payment-frequency.dto';
import { ToastService } from '@app/core/services/toast/toast.service';
import { PAYMENT_FREQUENCY_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPaymentFrequencyMappedItem } from '@app/logic/payment-frequencies/interfaces/i.payment-frequency.mapped';
import { IPaymentFrequenciesLogicService } from '@app/logic/payment-frequencies/interfaces/i.payment-frequencies.logic.service';

@Component({
    selector: 'cb-payment-frequency-dialog',
    templateUrl: './payment-frequency-dialog.component.html',
    styleUrls: ['./payment-frequency-dialog.component.scss']
})
export class PaymentFrequencyDialogComponent extends BaseDialogFormViewDirective<IPaymentFrequencyDto, IPaymentFrequencyMappedItem, IPaymentFrequenciesLogicService> implements OnInit {

    constructor(
        public readonly dialogRef: MatDialogRef<PaymentFrequencyDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: IPaymentFrequencyMappedItem }
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }

    public ngOnInit(): void {
        const label = this.getLabelForEnum(this.mappedItem.id);
        this.mappedItem.label = humanizeString(label);
    }

    public getLabelForEnum(id: number): string {
        return PAYMENT_FREQUENCY_ENUM[id];
    }
}
