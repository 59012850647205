import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {ProjectPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {IProjectDocumentDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-project-card',
    templateUrl: './project-card.component.html',
    styleUrls: ['./project-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCardComponent {

    @Input() public dto: IProjectDocumentDto;

    constructor(
        @Inject(NavigationService) public readonly navigationService: NavigationService,
        public readonly projectPermissions: ProjectPermissions
    ) { }

    public viewProject(projectId: number): void {
        this.navigationService.navigate([`projects/${projectId}/view`]);
    }

    public canView(): boolean {
        return this.projectPermissions.canView();
    }

}
