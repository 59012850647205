<form class="flex-col flex"
      #form="ngForm">
    <mat-card class="cb-margin flex-col flex">
        <div class="start-start flex-row">
            <div class="cb-margin-right-2x flex-row"
                 style="margin-top: 5px;">
                <mat-icon [ngClass]="getStatusIconClass()"
                          class="help-cursor"
                          [matTooltip]="getStatusIconTitle()">{{getStatusIconIcon()}}</mat-icon>
            </div>
            <span class="mat-title">{{mappedItem.label}}</span>
        </div>
        <mat-card-content class="flex">
            <div class="flex-col flex">
                <cb-display-value-new label="Status"
                                      [value]="mappedItem?.statusLabel">
                </cb-display-value-new>
                <cb-display-value-new label="Assigned To"
                                      [value]="mappedItem?.assignedToUserName">
                </cb-display-value-new>
            </div>
            <div class="flex-col flex"
                 *ngIf="showCompletedDetails">
                <cb-user-date-time-new label="Completed by"
                                       [floatingLabel]="true"
                                       [date]="mappedItem.completedDate"
                                       [userFullName]="mappedItem.completedByName"></cb-user-date-time-new>
                <cb-display-value-new label="Elapsed Time"
                                      [value]="elapsedTime$$.value">
                </cb-display-value-new>
            </div>
        </mat-card-content>
        <div position="relative">
            <mat-divider></mat-divider>
        </div>
        <mat-card-actions class="end-center flex-row">
            <span class="flex"></span>
            <cb-button color="accent"
                       [isStroked]="true"
                       *ngIf="displayViewManageButton$ | async"
                       (clicked)="viewTakeOffTask()">
                {{mappedItem.$canEditTakeOffTask ? 'Manage' : 'View'}}
            </cb-button>
        </mat-card-actions>
    </mat-card>
</form>
