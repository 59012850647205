import { Component, Input, OnInit } from '@angular/core';
import { FILE_TYPE_ENUM, FileTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject } from 'rxjs';
import { DragAndDropDocumentComponent } from '../drag-and-drop-document/drag-and-drop-document.component';
import { includes } from 'lodash';

@Component({
    selector: 'cb-drag-and-drop-file',
    templateUrl: './drag-and-drop-file.component.html',
    styleUrls: ['./drag-and-drop-file.component.scss']
})
export class DragAndDropFileComponent implements OnInit {
    @Input() public maximumFileSizeInMegaBytes = 20;
    @Input() public description = '';
    @Input() public allowedFileTypes: Array<FileTypeEnumId>;
    @Input() public isFileValidAndUploaded$: BehaviorSubject<boolean>;
    private fileValue$: BehaviorSubject<File>;

    @Input()
    public get file$(): BehaviorSubject<File> {
        return this.fileValue$;
    }

    public set file$(value) {
        this.fileValue$ = value;
    }

    public file: File;
    public fileExtension: string;
    public isFilesizeValid = true;
    public isFileExtensionValid = true;
    public fileSize: number;
    public fileSizeFormatted: string;
    public FILE_TYPE_ENUM = FILE_TYPE_ENUM;

    public ngOnInit(): void {
        this.allowedFileTypes = [...new Set(this.allowedFileTypes)];
    }

    public uploadFile = (uploadedFile: File): void => {
        this.clearOldFileReference();
        this.checkFileSize(uploadedFile[0]);
        this.checkFileExtension(uploadedFile[0]);
        this.setDocument(uploadedFile[0]);
    };

    public checkFileSize = (uploadedFile: File): void => {
        if (uploadedFile) {
            this.fileSize = (uploadedFile.size / 1024 / 1024);
            this.fileSizeFormatted = `${this.fileSize.toFixed(2)}MB`;

            if (this.maximumFileSizeInMegaBytes > this.fileSize) {
                this.isFilesizeValid = true;
            } else {
                this.isFilesizeValid = false;
                this.isFileValidAndUploaded$.next(false);
            }
        }
    };

    private readonly checkFileExtension = (uploadedFile: File): void => {
        this.fileExtension = DragAndDropDocumentComponent.extensionRegularExpresson.exec(uploadedFile.name)[1];
        const fileTypeEnum = FILE_TYPE_ENUM[`${this.fileExtension[0].toUpperCase()}${this.fileExtension.slice(1)}`];

        if (this.fileExtension && this.allowedFileTypes) {
            this.fileExtension = this.fileExtension.toLowerCase();
            if (includes(this.allowedFileTypes as any, fileTypeEnum)) {
                this.isFileExtensionValid = true;
            } else {
                this.isFileExtensionValid = false;
                this.isFileValidAndUploaded$.next(false);
            }
        }
    };

    private readonly setDocument = (uploadedFile: File): void => {
        if (this.isFilesizeValid && this.isFileExtensionValid) {
            this.file$?.next(uploadedFile);
            this.isFileValidAndUploaded$.next(true);
        }
    };

    private readonly clearOldFileReference = (): void => {
        this.file$?.next(undefined);
    };
}
