import { Component, Input } from '@angular/core';
import { ILotSpecItemMappedItem } from '@app/logic/lot-spec-item';
import { COST_TYPE_ENUM, OFFERING_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-spec-item-icons',
    templateUrl: './lot-spec-item-icons.component.html',
    styleUrls: ['./lot-spec-item-icons.component.scss']
})
export class LotSpecItemIconsComponent {

    @Input() public readonly mappedItem: ILotSpecItemMappedItem;
    public readonly OFFERING_STATUS_ENUM = OFFERING_STATUS_ENUM;
    public readonly COST_TYPE_ENUM = COST_TYPE_ENUM;

    constructor() { }
}
