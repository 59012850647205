import { Component } from '@angular/core';
import { GlobalSettingsLogicService } from '@app/logic/global-settings/global-settings.logic.service';
import { IGlobalSettingsDto } from '@app/logic/global-settings';
import { GlobalSettingsMappedItem } from '@app/logic/global-settings/global-settings.mapped';
import { GlobalSettingsDialogComponent } from './global-settings-dialog/global-settings-dialog.component';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-global-settings',
    templateUrl: './global-settings.component.html',
    styleUrls: ['./global-settings.component.scss'],
    providers: [GlobalSettingsLogicService]
})
export class GlobalSettingsComponent extends BaseSimpleListViewDirective<IGlobalSettingsDto, GlobalSettingsLogicService> {
    public readonly displayedColumns: string[] = [
        'settingsKey',
        'description',
        'value',
        'isActive',
        'actions'
    ];

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly globalSettingsLogicService: GlobalSettingsLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
    ) {
        super(
            cbDialog,
            globalSettingsLogicService,
            GlobalSettingsDialogComponent,
            'Global Settings',
            GlobalSettingsMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<IGlobalSettingsDto>): Array<IGlobalSettingsDto> {
        return searchResults.sort((a, b) => this.globalSettingsLogicService.getLabelFromEnum(a).localeCompare(this.globalSettingsLogicService.getLabelFromEnum(b)));
    }
}



