
import { IPermissionDto, PermissionAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IPermissionLogicService } from './interfaces/i.permission.logic-service';
import { IPermissionMappedItem, IPermissionOption } from './interfaces/i.permission.mapped';

export class PermissionMappedItem
    extends BaseMappedItem<IPermissionDto, IPermissionMappedItem, IPermissionLogicService>
    implements IPermissionMappedItem {
    @DtoProp public area: PermissionAreaEnumId;
    @DtoProp public name: string;
    @DtoProp public explicitAllow: boolean;
    @DtoProp public inheritedAllow: boolean;

    public $value: IPermissionOption;
    public $areaLabel: string;
    public $label: string;
    public $statusOptions: IPermissionOption[];

    constructor(
        sourceData: IPermissionDto,
        logicService: IPermissionLogicService
    ) {
        super(sourceData, logicService, PermissionMappedItem);
    }
}

