import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { UsersLogicService } from '@app/logic/users';
import { IUserMappedItem } from '@app/logic/users/interfaces/i.user.mapped';

@Directive({
    selector: '[cbUniqueUserName]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: UniqueUserNameValidatorDirective,
        multi: true
    }, UsersLogicService]
})
export class UniqueUserNameValidatorDirective implements Validator {
    @Input() public cbUniqueUserName: IUserMappedItem;

    constructor(private logicService: UsersLogicService) { }

    public validate(control: AbstractControl): ValidationErrors {
        if (!control.value || control.value.length <= 0) {
            return new Promise(resolve => resolve(null));
        }
        else if (this.cbUniqueUserName.$isCloned && this.cbUniqueUserName.activeDirectoryUsername === this.cbUniqueUserName.$original.activeDirectoryUsername) {
            return new Promise(resolve => resolve(null));
        }
        return new Promise(resolve =>
            this.logicService.fetchIsUsernameUnique(this.cbUniqueUserName.id, control.value).subscribe(result => {
                if (result) {
                    resolve(null);
                }
                else {
                    resolve({ activeDirectoryUsername: `Username ${control.value} is already in use` });
                }
            }));
    }
}
