import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { Subject, takeUntil, tap } from 'rxjs';

export interface IAutoCompleteScrollEvent {
    autoComplete: MatAutocomplete;
    scrollEvent: Event;
}

@Directive({
    selector: 'mat-autocomplete[optionsScroll]'
})
export class OptionsScrollDirective implements OnDestroy {

    @Input() public thresholdPercent = 0.8;
    @Output() public optionsScroll = new EventEmitter<IAutoCompleteScrollEvent>();
    private readonly onDestroy = new Subject();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened.pipe(
            tap(() => {
                setTimeout(() => {
                    this.removeScrollEventListener();
                    this.autoComplete?.panel?.nativeElement
                        .addEventListener('scroll', this.onScroll.bind(this));
                });
            }),
            takeUntil(this.onDestroy)).subscribe();

        this.autoComplete.closed.pipe(
            tap(() => this.removeScrollEventListener()),
            takeUntil(this.onDestroy)).subscribe();
    }

    public ngOnDestroy(): void {
        this.removeScrollEventListener();

        this.onDestroy.next(null);
        this.onDestroy.complete();
    }

    private removeScrollEventListener(): void {
        if (this.autoComplete.panel) {
            this.autoComplete?.panel?.nativeElement
                .removeEventListener('scroll', this.onScroll);
        }
    }

    public onScroll(event: Event): void {
        if (this.thresholdPercent == null) {
            this.optionsScroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
        } else {
            const target = event.target as HTMLElement;
            const threshold = this.thresholdPercent * 100 * target.scrollHeight / 100;
            const current = target.scrollTop + target.clientHeight;

            if (current > threshold) {
                this.optionsScroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
            }
        }
    }
}
