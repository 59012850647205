import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from './../base-form-component';

@Component({
    selector: 'cb-input:not([svgIcon]):not([icon])',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(InputComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class InputComponent extends BaseFormComponentDirective {
    public static readonly DOUBLE_MARGIN_WIDTH = 16;

    @Input() public type = 'text';
    @Input() public autocomplete = 'off';
    @Input() public pattern: RegExp;
    @Input() public maxlength: number;

    @Input() public minimum: number;
    @Input() public maximum: number;
    @Output() public changeEvent: EventEmitter<string> = new EventEmitter<string>();

    @HostBinding('class') public class = 'flex-row';

    private _suffix = '';
    @Input() public set suffix(v: string) {
        this._suffix = v;
        this.setSuffixWidth();
    }
    public get suffix(): string {
        return this._suffix;
    }

    public suffixWidth = 0;
    @ViewChild('suffixEle') public readonly suffixEle: ElementRef<HTMLSpanElement>;

    @Debounce(50)
    public setSuffixWidth(): void {
        let width = this.suffixEle?.nativeElement?.offsetWidth ?? 0;
        if (width > 0) {
            width += InputComponent.DOUBLE_MARGIN_WIDTH;
        }
        this.suffixWidth = width;
    }

    @Debounce(300)
    public emitChange(): void {
        super.emitChange();
    }

    /** This method gets overriden when the maskType directive is applied to a cb-input */
    public valueSetter(v: any): { value: any; modelValue: any } {
        return {
            value: v,
            modelValue: v
        };
    }

    /* This fixes the issue where pressing enter on an input causees some buttons to be pressed*/
    public enterPressed(e: Event): void {
        e.stopPropagation();
        e.preventDefault();
    }

    public modelChanged($event: string): void {
        this.changeEvent.next($event);
    }
}
