import {
    BasePermissions,
    IBaseSystemAreaPermissions,
    IDocumentPermissions,
    IhaveNotePermissions,
    IhaveTaskPermissions,
    INotePermissions,
    ITaskPermissions,
} from './base.permissions';

import {ClientSalePermissionEnum} from '../../shared/enums/permissions';
import {CurrentUserService} from '../authentication/current.user';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {USER_TAG_CONSTANTS_CONST} from '@classictechsolutions/hubapi-transpiled-enums';

export interface IClientSalePermissions extends IBaseSystemAreaPermissions, IhaveNotePermissions, IhaveTaskPermissions {
    canSearch(): boolean;
    canChangeContractType(): boolean;
    canGenerateSaleAndPurchaseInfo(): boolean;
    canConfirmSaleLost(): boolean;
    canMarkSaleLost(): boolean;
    canSeeComponent(): boolean;
    canViewHistory(): boolean;
    canViewPayments(): boolean;
    canAddPayment(): boolean;
    canEditPayment(): boolean;
    canDownloadCreateSchedule(): boolean;
    canGenerateContractFee(): boolean;
    canGenerateDesignFee(): boolean;
    getNotePermissions(): INotePermissions;
    getTaskPermissions(): ITaskPermissions;
    getDocumentPermissions(): IDocumentPermissions;
}

@Injectable()
export class ClientSalePermissions
    extends BasePermissions<ClientSalePermissionEnum>
    implements IClientSalePermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('clientsale', ClientSalePermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanView);
    }

    /** This is unused. It has to be here to extend IBaseSystemAreaPermissions. */
    public canCreate(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanCreate);
    }

    public canChangeContractType(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanChangeContractType);
    }

    public canGenerateSaleAndPurchaseInfo(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanGenerateSaleAndPurchaseInfo);
    }

    public canDownloadCreateSchedule(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.canDownloadCreateSchedule);
    }

    public canGenerateContractFee(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanGenerateContractFee);
    }

    public canGenerateDesignFee(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanGenerateDesignFee);
    }

    public canConfirmSaleLost(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanConfirmSaleLost);
    }

    public canMarkSaleLost(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanMarkSaleLost);
    }

    public canEdit(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanEdit);
    }

    public canSearch(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanSearch);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanView);
    }

    public canViewHistory(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.HistoryCanView);
    }

    public canViewPayments(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanViewPayments);
    }

    public canAddPayment(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.PaymentsCanAdd);
    }

    public canEditPayment(): boolean {
        return this.permissionObject(ClientSalePermissionEnum.CanEditPayment);
    }

    public canRevertUnconditional(): boolean {
        return this.currentUser.hasTag(USER_TAG_CONSTANTS_CONST.SYSTEMADMIN);
    }

    public getNotePermissions(): INotePermissions {
        return {
            canCreateNote: () => this.permissionObject(ClientSalePermissionEnum.NoteCanAdd),
            canDeleteNote: () => this.permissionObject(ClientSalePermissionEnum.NoteCanDelete),
            canEditNote: () => this.permissionObject(ClientSalePermissionEnum.NoteCanEdit),
            canViewNotes: () => this.permissionObject(ClientSalePermissionEnum.NoteCanView)
        };
    }

    public getTaskPermissions(): ITaskPermissions {
        return {
            canAddTask: () => this.permissionObject(ClientSalePermissionEnum.TaskCanAdd),
            canCompleteTask: () => this.permissionObject(ClientSalePermissionEnum.TaskCanComplete),
            canEditTask: () => this.permissionObject(ClientSalePermissionEnum.TaskCanEdit),
            canViewTask: () => this.permissionObject(ClientSalePermissionEnum.TaskCanView)
        };
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => this.permissionObject(ClientSalePermissionEnum.DocumentsCanDelete),
            canEditDocuments: () => this.permissionObject(ClientSalePermissionEnum.DocumentsCanEdit),
            canReviewDocuments: () => this.permissionObject(ClientSalePermissionEnum.DocumentsCanReview),
            canUploadDocument: () => this.permissionObject(ClientSalePermissionEnum.DocumentsCanUpload),
            canViewDocuments: () => this.permissionObject(ClientSalePermissionEnum.DocumentsCanView),
        };
    }
}
