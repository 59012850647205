import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';
import {Observable} from 'rxjs';
import {ILeadSearchDto, ILeadSearchParams, LeadLogicService} from '@app/logic/leads';

@Injectable()
export class LeadSearchService extends SearchService<ILeadSearchDto> {

    constructor(
        public readonly leadLogicService: LeadLogicService,
    ) {
        super();
    }

    public canSearch(): boolean {
        return true;
    }

    public getResults(query: string, currentpage: number): Observable<ILeadSearchDto[]> {
        return new Observable<ILeadSearchDto[]>((subscriber) => {
            if (!this.canSearch()) {
                subscriber.next([]);
                subscriber.complete();
                return;
            }
            this.currentPage = currentpage;
            if (!this.searchResultsLoaded) {
                const resultsObservable = this.leadLogicService.search({
                    query,
                    currentpage,
                    ...this.extraSearchParams
                } as ILeadSearchParams);
                resultsObservable
                    .subOnce((x) => {
                        this.setSearchResults(x);
                        subscriber.next(this.searchResults$.value);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value);
                subscriber.complete();
            }
        });
    }
}
