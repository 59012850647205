<cb-dialog [dialogHeading]="dialogHeading()">
    <form #lotSpecItemForm="ngForm">
        <cb-select label="House Area"
                   name="name"
                   [(ngModel)]="mappedItem.specGroupId"
                   [options]="houseAreas"
                   [disabled]="data.lockedSpecGroupId || data.locked || !isEditOrAdd()"
                   [required]="true"></cb-select>
        <cb-select label="Cost Type"
                   name="costType"
                   [(ngModel)]="mappedItem.costType"
                   [options]="lotSpecCostTypes"
                   [disabled]="data.locked || isQuoted() || !isEditOrAdd()"
                   [required]="true"
                   (change)="clearProductForCostType()">
        </cb-select>
        <div class="start-center flex-row"
             *ngIf="displayProductSearch()">
            <cb-product-autocomplete-scroller class="cb-margin-right flex"
                                              label="Product"
                                              name="product"
                                              [(ngModel)]="selectedProduct"
                                              [required]="true"
                                              [requireMatch]="isActual()"
                                              [matchProps]="['id']"
                                              [hideCompositeItems]="false"
                                              (searchTextChanged)="productSearchTextChanged($event)"
                                              (optionSelected)="productUpdate($event)">
            </cb-product-autocomplete-scroller>

            <!-- <div ng-messages="searchForm.product.$error"
                 ng-if="searchForm.product.$touched">
                <div ng-message="mat-require-match">Please select a Product</div>
            </div> -->
            <cb-product-input-find name="selectedProduct"
                                   (productSelected)="productUpdate($event)"
                                   [required]="true"
                                   [buttonOnly]="true"
                                   [activeOnly]="true"
                                   [hideCompositeItems]="true"></cb-product-input-find>
        </div>
        <cb-text-area *ngIf="!canSelectProduct() || data.locked"
                      label="Product Description"
                      name="productDesc"
                      [(ngModel)]="productSearchText"
                      [readonly]="data.locked || !isEditOrAdd()"
                      (change)="clearProductForDescriptive()"
                      [required]="true"
                      [maxlength]="250"></cb-text-area>
        <mat-card class="cb-margin-2x flex-row"
                  *ngIf="productSearchText || selectedProduct">
            <div id="imageUploadDropzone"
                 class="cb-margin-right flex-col"
                 style="max-width: 180px;">
                <div class="start-center flex-row flex">
                    <cb-image-button class="flex"
                                     *ngIf="isActual() || !specImageUrl"
                                     [baseUrl]="mappedItem.image"></cb-image-button>
                    <cb-image-button class="flex"
                                     *ngIf="!isActual() && specImageUrl"
                                     [baseUrl]="specImageUrl"></cb-image-button>
                </div>
                <input type="file"
                       hidden
                       #fileInput
                       (click)="$event.target.value = null"
                       accept=".jpg,.jpeg,.png,.gif,image/jpeg,image/gif,image/png"
                       (change)="uploadFile(lotSpecItemForm, $event.target.files)"
                       [disabled]="uploadingImage || isActual()"/>
                <button class="cb-margin-top"
                        mat-raised-button
                        (click)="fileInput.click()"
                        *ngIf="!isActual()"
                        [disabled]="uploadingImage">
                    <mat-icon>file_upload</mat-icon>
                    <span *ngIf="mappedItem.image || specImageUrl">&nbsp;Replace
                        Image</span>
                    <span *ngIf="!mappedItem.image && !specImageUrl">&nbsp;Upload
                        Image</span>
                </button>
                <button class="cb-margin-top"
                        mat-raised-button
                        [hidden]="!mappedItem.canRemoveImage || isActual() || !specImageUrl"
                        (click)="removeImage(lotSpecItemForm)">
                    <mat-icon>delete</mat-icon>
                    &nbsp;Remove Image
                </button>
            </div>
            <div class="flex-col flex"
                 *ngIf="productSearchText && !isDescriptiveOnly()">
                <span class="mat-body-1 cb-margin-bottom">{{getProductPath()}}</span>
                <cb-select *ngFor="let attr of skinnyProduct?.restrictions"
                           [name]="attr.name"
                           [label]="attr.name"
                           [(ngModel)]="productOptions[attr.attributeId]"
                           noOptionsMessage="-"
                           [readonly]="!isEditOrAdd()"
                           [options]="attr.options"></cb-select>
                <cb-input *ngIf="displayQuantity() && !data.locked"
                          type="number"
                          label="Quantity"
                          name="quantity"
                          [(ngModel)]="mappedItem.quantity"
                          [required]="true"
                          [suffix]="productUom">
                </cb-input>
                <cb-display-value-new *ngIf="displayQuantity() && data.locked"
                                      label="Quantity">
                    {{mappedItem.quantity}} x {{productUom}}
                </cb-display-value-new>
                <cb-currency class="currency-width"
                             *ngIf="showRate() && !(isProvisional() || isQuoted())"
                             [label]="getRateLabel()"
                             name="rateInput"
                             [(ngModel)]="mappedItem.rate"
                             [readonly]="data.locked || !isEditOrAdd()"
                             [required]="true"></cb-currency>
                <cb-currency class="currency-width"
                             *ngIf="(isProvisional() || isQuoted())"
                             [label]="getRateLabel()"
                             name="rateInputGst"
                             [inclGst]="true"
                             [(ngModel)]="mappedItem.rate"
                             [readonly]="data.locked || !isEditOrAdd()"
                             [required]="isRateRequired()"></cb-currency>
                <cb-select *ngIf="displaySupplier()"
                           label="Supplier"
                           name="supplier"
                           [(ngModel)]="mappedItem.supplierId"
                           [options]="suppliers"
                           [readonly]="!canEditSupplier()"
                           [required]="isRateRequired()"></cb-select>
            </div>
        </mat-card>
        <cb-checkbox *ngIf="shouldShowQuoteRequired()"
                     label="Quote Required"
                     name="quoteRequired"
                     [(ngModel)]="mappedItem.quoteRequired"
                     [readonly]="!isEditOrAdd()">
        </cb-checkbox>
        <div class="cb-checkbox-list flex-col"
             *ngIf="isEditOrAdd()">
            <cb-checkbox label="Include in Flyer / Create Schedule"
                         name="includeCreateSchedule"
                         [(ngModel)]="mappedItem.showInClientSpecification"></cb-checkbox>
            <cb-checkbox label="Include In Colour Schedule"
                         name="showInColourYourDreams"
                         [(ngModel)]="mappedItem.showInColourYourDreams"
                         #includeInColourSchedule></cb-checkbox>
            <cb-checkbox label="Manual Colour Entry Required"
                         name="manualColourEntryRequired"
                         (change)="manualColourEntryRequiredChanged($event)"
                         [(ngModel)]="mappedItem.manualColourEntryRequired"></cb-checkbox>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="saveDisabled(lotSpecItemForm)"
                (cbClick)="saveItem(lotSpecItemForm)"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (cbClick)="cancel(lotSpecItemForm.dirty)">Cancel</button>
    </footer>
</cb-dialog>
