import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IPermissionGroupAreaDto, IPermissionGroupDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPermissionGroupMappedItem } from './interfaces/i.permission-group.mapped';
import { IPermissionGroupLogicService } from './interfaces/i.permission-group.logic.service';

export class PermissionGroupMappedItem
    extends BaseMappedItem<IPermissionGroupDto, IPermissionGroupMappedItem, IPermissionGroupLogicService>
    implements IPermissionGroupMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public areas: IPermissionGroupAreaDto[];
    @DtoProp public isActive: boolean;

    constructor(
        sourceData: IPermissionGroupDto,
        logicService: IPermissionGroupLogicService
    ) {
        super(sourceData, logicService, PermissionGroupMappedItem);
    }
}
