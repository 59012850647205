import {Component} from '@angular/core';
import {BuildOverheadCostLogicService} from '@app/logic/build-overhead-costs';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {BaseSimpleListViewDirective} from '@app/shared/base-views/base-simple-list-view.directive';
import {IBuildOverheadCostDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {ToastService} from '@app/core/services/toast/toast.service';
import {PermissionsPermissions} from '@app/core/permissions';
import {BuildOverheadCostMappedItem} from '@app/logic/build-overhead-costs/build-overhead-cost.mapped';
import {
    ProductBuildOverheadCostsDialogComponent
} from '@app/views/products/tabs/product-build-overhead-costs/product-build-overhead-costs-dialog/product-build-overhead-costs-dialog.component';

@Component({
    selector: 'cb-product-build-overhead-costs',
    templateUrl: './product-build-overhead-costs.component.html',
    styleUrls: ['./product-build-overhead-costs.component.scss']
})
export class ProductBuildOverheadCostsComponent extends BaseSimpleListViewDirective<IBuildOverheadCostDto, BuildOverheadCostLogicService> {
    public readonly displayedColumns: string[] = ['Id', 'Name', 'Description', 'Finance Code', 'Active', ''];

    constructor(
        public readonly toastService: ToastService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        public readonly logicService: BuildOverheadCostLogicService,
    ) {
        super(
            dialog,
            logicService,
            ProductBuildOverheadCostsDialogComponent,
            'Build Overhead',
            BuildOverheadCostMappedItem,
            permissionsPermissions
        );
    }
}
