<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <mat-card-title>{{dto.name}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex">
        <ng-container>
            <div class="flex-col">
                <cb-display-value-new label="Account Type"
                                      [value]="dto.accountType"></cb-display-value-new>
                <cb-display-value-new label="ABM Code"
                                      [value]="dto.abmCode"></cb-display-value-new>
                <cb-display-value-new label="Account Status"
                                      [value]="CLIENT_ACCOUNT_STATUS_ENUM[dto.status]">
                </cb-display-value-new>
            </div>
        </ng-container>
    </mat-card-content>
    <mat-card-actions class="end-center flex-row">
        <button mat-stroked-button
                color="accent"
                (click)="selectionChosen(dto)">
            Add Account
        </button>
    </mat-card-actions>
</mat-card>
