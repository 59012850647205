import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { Observable, of, Subject, Subscriber, Subscription, map } from 'rxjs';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { orderBy } from 'lodash';
import { DesignTeamPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import {
    DESIGN_SCHEME_STATUS_ENUM,
    IDesignConceptDocumentDto,
    IDesignSchemeDocumentDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IDesignTeamImageRequestSearch } from '@app/core/services/user-cache/user-cache-areas';
import { DesignConceptsLogicService } from '@app/logic/design-concepts';

@Component({
    selector: 'cb-design-team-image-requests',
    templateUrl: './design-team-image-requests.component.html',
    styleUrls: ['./design-team-image-requests.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignTeamImageRequestsComponent implements OnDestroy, OnInit {

    public resultOrders$ = of([
        { id: 'jobNumber', label: 'Job Number' },
        { id: 'exteriorRequestDate', label: 'Exterior Date' },
        { id: 'floorPlanRequestDate', label: 'Floor Plan Date' }
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    private _subscriptions = new Subscription();
    public searchEnabled = false;
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public teamCode: string;
    public loaded = false;
    public cacheLoaded$ = new Subject();
    public results: IDesignSchemeDocumentDto[] = [];
    public readonly allUsers = '999';
    public readonly allStatuses = 999;
    public readonly unassigned = undefined;

    public hasPermissionsToEdit$ = of(this.designTeamPermissions.canEditScheme());

    public canEdit$(scheme: IDesignSchemeDocumentDto): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            subscriber.next(scheme.statusId !== DESIGN_SCHEME_STATUS_ENUM.Accepted);
        });
    }

    public teamUsersOptions$ = this.teamsLogicService
        .loadAllMembersByKey('DESIGNTEAM').pipe(
            map(users => {
                users = orderBy(users, 'firstName');
                users.unshift({ id: this.allUsers, label: 'All' } as any);
                return users;
            })
        );

    public get searchFilters(): UserCacheItem<IDesignTeamImageRequestSearch> {
        return this.userCacheService.designTeamImageRequestSearch;
    }

    constructor(
        public readonly designTeamPermissions: DesignTeamPermissions,
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: DesignConceptsLogicService,
        private readonly userCacheService: UserCacheService,
        private readonly navigationService: NavigationService
    ) {

    }

    public ngOnInit(): void {
        this._initSearchFilters();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public fetchResults(): Observable<IDesignConceptDocumentDto[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.$getSearchList(this._queryParams);
    }

    public hasPermissionsToEditScheme = (): boolean => {
        if (this.designTeamPermissions) {
            return this.designTeamPermissions.canEditScheme();
        }

        return false;
    };

    public getStreetAddress(imageRequest: IDesignConceptDocumentDto): string {
        return `${imageRequest.addressStreet}, ${imageRequest.addressCity}`;
    }

    public viewConcept(concept: IDesignConceptDocumentDto): void {
        this.navigationService.navigate([`/lots/${concept.lotId}/design/concepts/${concept.id}`]);
    }

    private get _queryParams(): IDesignTeamImageRequestSearch {
        return this.searchEnabled ?
            {
                ...this.searchFilters.copyData(),
                userId: this.searchFilters.data.userId === this.allUsers ?
                    undefined :
                    this.searchFilters.data.userId ?
                        this.searchFilters.data.userId :
                        undefined,
                statusId: (this
                    .searchFilters.data.statusId as any) === this.allStatuses ?
                    undefined :
                    this.searchFilters.data.statusId,
                currentPage: this.currentPage
            } :
            undefined;
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    public _initSearchFilters(): void {
        this.searchFilters.init().then(() => {
            this.cacheLoaded$.next(true);
            this.searchEnabled = true;
            this._onSearchFiltersChanged();
            this._subscriptions.add(
                this.searchFilters.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );

            this.searchFilters.silentData.canSeeAll = this.designTeamPermissions.canSeeAll();
            if (!this.searchFilters.silentData.canSeeAll) {
                this.searchFilters
                    .silentData
                    .userId = this.searchFilters.currentUserId;
            }
        });
    }
}
