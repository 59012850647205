import { ExternalSystemEnumId, ExternalSystemFieldTypeEnumId, EXTERNAL_SYSTEM_ENUM, IExternalSystemFieldTemplateItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IExternalSystemFieldsLogicService } from './interfaces/i.external-system-fields.logic-service';
import { IExternalSystemFieldMappedItem } from './interfaces/i.external-system-fields.mapped';

export class ExternalSystemFieldMappedItem
    extends BaseMappedItem<IExternalSystemFieldTemplateItemDto, IExternalSystemFieldMappedItem, IExternalSystemFieldsLogicService>
    implements IExternalSystemFieldMappedItem {

    @DtoProp public id: number;
    @DtoProp public fieldName: string;
    @DtoProp public fieldTypeId: ExternalSystemFieldTypeEnumId;
    @DtoProp public fieldLength: number;
    @DtoProp public isActive: boolean;
    @DtoProp public sortOrder: number;
    @DtoProp public externalSystemId: ExternalSystemEnumId;

    constructor(
        sourceData: IExternalSystemFieldTemplateItemDto,
        logicService: IExternalSystemFieldsLogicService
    ) {
        super(sourceData, logicService, ExternalSystemFieldMappedItem, {
            isActive: true,
            externalSystemId: EXTERNAL_SYSTEM_ENUM.CostX
        });
    }

}
