import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BuildProgrammeLogicService, IBuildProgrammeIndexedDto } from '@app/logic/build-programme';
import { IDValidationItem } from '@app/shared/interfaces/i.d.validate.item';
import { BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM, CONTRACT_STATUS_ENUM, ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject } from 'rxjs';
import { LotBuildProgrammeEventService } from '../../../services/lot-build-programme-event.service';
@Component({
    selector: 'cb-lot-build-programme',
    templateUrl: './lot-build-programme.component.html',
    styleUrls: ['./lot-build-programme.component.scss']
})
export class LotBuildProgrammeComponent implements OnInit {

    public lotDto$$: BehaviorSubject<ILotDto> = new BehaviorSubject(null);
    public CONTRACT_STATUS_ENUM = CONTRACT_STATUS_ENUM;
    public hasInvalidItems = false;
    public invalidItems = [] as IDValidationItem[];

    @Output() public reloadLotMappedItem = new EventEmitter();

    @Input() public set lotDto(lotDto: ILotDto) {
        if (lotDto) {
            this.lotDto$$.next(lotDto);
        }
    }

    public get lotDto(): ILotDto {
        return this.lotDto$$?.value;
    }

    public get buildProgramme(): IBuildProgrammeIndexedDto {
        return (!this.lotBuildProgrammeEvents?.buildProgramme ||
            this.lotBuildProgrammeEvents?.buildProgramme === null)
            ? undefined
            : this.lotBuildProgrammeEvents?.buildProgramme;
    }

    public get shouldDisplayNoAlertsMessage(): boolean {
        return (!this.invalidItems || this.invalidItems.length <= 0) && !this.lotDto.isPrimaryUnit;
    }

    constructor(
        public readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
        public readonly buildProgrammeLogic: BuildProgrammeLogicService,) { }

    public ngOnInit(): void {
        this.checkAllItemsValidExceptBuildProgrammeTemplate();
    }

    private getNumberUnconfirmedActivities(): number {
        if (!this.buildProgramme?.id) {
            return -1;
        }

        let unconfirmedActivityCount = 0;

        Object.values(this.buildProgramme?.buildProgrammeActivities).forEach(activity => {
            if (activity.statusId === BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.NotStarted ||
                activity.statusId === BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.None) {
                unconfirmedActivityCount++;
            }
        });

        return unconfirmedActivityCount;
    }


    public getUnconfirmedActivityMilestoneLabel(): string | undefined {
        if (!this.buildProgramme?.stages && this.buildProgramme?.id) {
            return 'Unconfirmed Activities';
        } else if (this.getNumberUnconfirmedActivities() === 1) {
            return 'There is 1 Unconfirmed Activity';
        } else if (this.getNumberUnconfirmedActivities() > 1) {
            return `There are ${this.getNumberUnconfirmedActivities()} Unconfirmed Activities`;
        } else if (this.getNumberUnconfirmedActivities() === 0 && Object.values(this.buildProgramme?.buildProgrammeActivities).length > 0) {
            return 'All Activities have been Confirmed';
        } else if (this.buildProgramme?.buildProgrammeActivities &&
            !(Object.values(this.buildProgramme?.buildProgrammeActivities).length > 0)) {
            return 'There are no Activities';
        } else if (this.getNumberUnconfirmedActivities() === -1) {
            return 'Still loading...';
        }
    }

    public getUncofirmedActivitiesMilestoneComplete(): boolean {
        return this.buildProgramme?.stages &&
            this.getNumberUnconfirmedActivities() === 0 &&
            Object.values(this.buildProgramme?.buildProgrammeActivities).length > 0;
    }

    private checkAllItemsValidExceptBuildProgrammeTemplate(): Promise<void> {
        return new Promise((resolve) => {
            this.buildProgrammeLogic.getValidationItems(this.lotDto.id).subOnce(x => {
                this.invalidItems = x.filter(i => i.fieldName !== 'Build Programme Template' && (i.inValid === true  || i.isWarning === true));

                const blockerItems = x.filter(i => i.fieldName !== 'Build Programme Template' && i.inValid === true);
                this.hasInvalidItems = blockerItems !== undefined && blockerItems?.length > 0;

                resolve();
            });
        });
    }

    public getItemLabel(item: IDValidationItem): string {
        return item.objectName;
    }

    public getItemMessage(item: IDValidationItem): string {
        if (item.fieldName.toLocaleLowerCase() === 'a takeoff') {
            return item.fieldName + ' needs to be imported';
        }

        if (item.fieldName.toLocaleLowerCase() === 'changes and variations') {
            return 'An incomplete Change or Variation exists';
        }

        if (item.overrideMessage !== undefined && item.overrideMessage !== '') {
            return item.overrideMessage;
        }

        return item.fieldName + ' has not been populated';
    }
}
