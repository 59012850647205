import { Buffer } from 'buffer';
import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import { isNullOrWhiteSpace } from 'cb-hub-lib';

const MAX_DEPTH_SAFE_GUARD = 999;
const CIRCULAR_REFERENCE_DETECTED = 'cloneDeepSafe: circular reference detected:';
const MAX_CLONE_DEPTH_REACHED = 'cloneDeepSafe: Maximum clone depth reached! The object/array you are trying to clone has too many nested members.';
const PATH_SEPARATOR = '.';
const PATH_ROOT = '__root';

class CircularReferenceStack {
    /** saved references to objects that are being cloned */
    private _objects = {} as { [path: string]: Object };

    /** current object depth level that the clone operation is at */
    public currentDepth = 0;

    /** increments currentDepth and saves obj reference
     *
     *  @param obj - to be saved for later referencing during clone
     */
    public add<Obj extends Object>(path: string, obj: Obj): void {
        if (obj == null || !(obj instanceof Object)) {
            return;
        }
        this.currentDepth = (path.match(/./g) || []).length;
        this._objects[path] = obj;
    }

    /** @returns false if circular reference does not exist
     * @returns true if circular reference exists and the reference should be excluded/removed
     * @exception if circular reference exist and the reference should NOT be excluded/remove
     */
    public handleExcludeCircularReference(path: string, source: Object, key: string | number, excludeCircularReferences: boolean): boolean {
        this.add(path, source[key]);
        const circRef = this._findCircularReference(path, source[key]);
        if (!circRef) {
            return false;
        }
        if (excludeCircularReferences) {
            return true;
        }
        throw new Error(`${CIRCULAR_REFERENCE_DETECTED} ${circRef}`);
    }

    /** @returns true if circular reference found */
    private _findCircularReference<Obj extends Object>(fullPath: string, obj: Obj): false | string {
        if (obj == null || !(obj instanceof Object)) {
            return false;
        }
        let currPath = '';
        let circRefFound = false;
        const splitPath = fullPath.split(PATH_SEPARATOR);
        for (const section of splitPath) {
            currPath = _constructObjPathStr(currPath, section);
            if (currPath === fullPath) {
                continue;
            }
            circRefFound = this._objects[currPath] === obj;
            if (circRefFound) {
                break;
            }
        }
        return circRefFound && `${fullPath.replace(PATH_ROOT, 'cloneSource')} -> ${currPath.replace(PATH_ROOT, 'cloneSource')}`;
    }
}

/** **Recursive** - Clones entire object/array, it's child objects and arrays. \
 * This function is only recommended where recursive deep cloning is necessary. \
 * If a MappedItem is in the cloneSource, it will be cloned using $getMappedDtoItem().
 *
 * @param cloneSource - the object/array/data to be deep cloned.
 * @param excludeCircularReferences - false by default, true will exclude circular references from the returned cloned item.
 * @returns a deep clone of the cloneSource param.
 */
export const cloneDeepSafe = <ClonedType>(cloneSource: ClonedType, excludeCircularReferences = false): ClonedType => {
    return _cloneDeepSafe(PATH_ROOT, cloneSource, excludeCircularReferences, new CircularReferenceStack());
};

/** **Recursive** - Clones entire object/array, it's child objects and arrays. \
 * This function is only recommended where recursive deep cloning is necessary.
 */
function _cloneDeepSafe<ClonedType>(path: string, cloneSource: ClonedType, excludeCircularReferences: boolean, circRefStack: CircularReferenceStack): ClonedType {
    if (circRefStack.currentDepth >= MAX_DEPTH_SAFE_GUARD) {
        throw new Error(MAX_CLONE_DEPTH_REACHED);
    }
    if (cloneSource instanceof Date) {
        return new Date(cloneSource) as unknown as ClonedType;
    }
    if (cloneSource instanceof File) {
        // File object cannot be copied/cloned normally, a new File must be instantiated instead
        return new File([cloneSource], cloneSource.name, { type: cloneSource.type, lastModified: cloneSource.lastModified }) as unknown as ClonedType;
    }
    if (cloneSource instanceof Blob) {
        // Blob object cannot be copied/cloned normally, a new Blob must be instantiated instead
        return new Blob([cloneSource], { type: cloneSource.type }) as unknown as ClonedType;
    }
    if (Buffer.isBuffer(cloneSource) || cloneSource instanceof ArrayBuffer) {
        // buffer cannot be copied/cloned normally
        return cloneSource.slice(0) as unknown as ClonedType;
    }
    if (cloneSource instanceof BaseMappedItem) {
        // transform mapped item into a deepcloned object with only dto props
        return cloneSource.$getMappedDtoItem();
    }
    if (cloneSource instanceof Array) {
        circRefStack.add(path, cloneSource);
        return _cloneArrayDeep(path, cloneSource, excludeCircularReferences, circRefStack);
    }
    // always check object last because everything else inherits object
    if (cloneSource instanceof Object) {
        circRefStack.add(path, cloneSource);
        return _cloneObjectDeep(path, cloneSource, excludeCircularReferences, circRefStack);
    }
    return cloneSource;
}

/** **Recursive** - Clones entire object, it's child objects and arrays */
function _cloneObjectDeep<Obj extends Object>(path: string, cloneSourceObj: Obj, excludeCircularReferences: boolean, circRefStack: CircularReferenceStack): Obj {
    const target = {} as Obj;
    for (const key in cloneSourceObj) {
        if (!Object.prototype.hasOwnProperty.call(cloneSourceObj, key)) {
            continue;
        }
        const currPath = _constructObjPathStr(path, key);
        if (circRefStack.handleExcludeCircularReference(currPath, cloneSourceObj, key, excludeCircularReferences)) {
            // circular reference exists and the reference should be excluded/removed
            continue;
        }
        target[key] = _cloneDeepSafe(currPath, cloneSourceObj[key], excludeCircularReferences, circRefStack);
    }
    return target as Obj;
}

/** **Recursive** - Clones entire array, it's child arrays and objects */
function _cloneArrayDeep<Arr extends Array<any>>(path: string, cloneSourceArr: Arr, excludeCircularReferences: boolean, circRefStack: CircularReferenceStack): Arr {
    const target = [] as Arr;
    let keyIdx = cloneSourceArr.length;
    while (keyIdx) {
        keyIdx--;
        const currPath = _constructObjPathStr(path, keyIdx);
        if (circRefStack.handleExcludeCircularReference(currPath, cloneSourceArr, keyIdx, excludeCircularReferences)) {
            // circular reference exists and the reference should be excluded/removed
            continue;
        }
        target.push(_cloneDeepSafe(currPath, cloneSourceArr[keyIdx], excludeCircularReferences, circRefStack));
    }
    return target;
}

function _constructObjPathStr(path: string, nextMember: string | number): string {
    return isNullOrWhiteSpace(path) ? nextMember.toString() : `${path}${PATH_SEPARATOR}${nextMember}`;
}
