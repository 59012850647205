import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { IBusinessEntityDefaultProductSupplierDto } from '@app/logic/business-entity/interfaces/i.business-entity.dto';
import { IBusinessEntityDefaultSupplierMappedItem, IBusinessEntityMappedItem } from '@app/logic/business-entity/interfaces/i.business-entity.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IBusinessEntityDefaultSuppliersLogicService } from '@app/logic/business-entity/interfaces/i.business-entity.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { FormMode } from '@app/shared/enums/form';
import { BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM, BUSINESS_ACCOUNT_STATUS_ENUM, ISupplyTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: IBusinessEntityDefaultSupplierMappedItem;
    otherData: { region: IBusinessEntityMappedItem };
}

@Component({
    selector: 'cb-default-supplier-dialog',
    templateUrl: './default-supplier-dialog.component.html',
    styleUrls: ['./default-supplier-dialog.component.scss'],
    providers: [BusinessEntitiesLogicService]
})
export class DefaultSupplierDialogComponent
    extends BaseDialogFormViewDirective<
    IBusinessEntityDefaultProductSupplierDto,
    IBusinessEntityDefaultSupplierMappedItem,
    IBusinessEntityDefaultSuppliersLogicService
    > implements OnInit {
    public businessEntities: IBusinessEntityDto[];
    public mappedItem: IBusinessEntityDefaultSupplierMappedItem;
    public region: IBusinessEntityMappedItem;
    public availableSupplyTypes: ISupplyTypeDto[];
    public selectedSupplyTypeId: number;
    public selectedSupplier: any;
    public invalidSupplier: boolean;
    public businessAccountSearchParams: { [param: string]: any };
    public validLocationIds: number[];
    public supplyTypesLoaded: boolean;
    public locationsLoaded: boolean;

    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<DefaultSupplierDialogComponent>,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.region = data.otherData.region;
        this.region.getValidInstallerLocations().subOnce((locationList) => {
            this.validLocationIds = locationList;
            this.invalidSupplier = this.isInvalidLocationConfiguration();
            this.setBusinessAccountSearchParams();
            this.locationsLoaded = true;
        });
    }

    public ngOnInit(): void {
        if (this.mappedItem.businessEntityId) {
            this.formMode = FormMode.Edit;
            this.selectedSupplyTypeId = this.mappedItem.supplyType.id;
            this.selectedSupplier = {
                id: this.mappedItem.supplier.id,
                tradingName: this.mappedItem.supplier.label
            };
            this.supplyTypesLoaded = true;
        } else {
            this.formMode = FormMode.Add;
            this.businessEntitiesLogicService
                .getAvailableSupplyTypes(this.region.id)
                .subOnce({
                    next: x => {
                        this.availableSupplyTypes = x;
                        this.supplyTypesLoaded = true;
                        this.setBusinessAccountSearchParams();
                    }
                });
        }
    }

    public selectedSupplyTypeChanged(): void {
        this.resetForm();
        this.mappedItem.supplyType = { id: this.selectedSupplyTypeId, label: undefined, path: undefined };
        this.setBusinessAccountSearchParams();
    }

    private resetForm(): void {
        this.selectedSupplier = undefined;
    }

    private isInvalidLocationConfiguration(): boolean {
        return this.validLocationIds.length === 0;
    }

    private setBusinessAccountSearchParams(): void {
        // We have to filter the business accounts as supply types dont make use of contacts which is what the trade types
        // use to filter out invalid suppliers
        this.businessAccountSearchParams = {
            reg: this.validLocationIds,
            ro: false, // not rates only
            s: [BUSINESS_ACCOUNT_STATUS_ENUM.Active, BUSINESS_ACCOUNT_STATUS_ENUM.Overridden],
            t: BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM.SupplierOnly,
            supt: this.selectedSupplyTypeId
        };
    }

    public save(): void {
        this.dialogRef.close(this.data.mappedItem);
    }

    public supplierChanged(): void {
        this.mappedItem.supplier = {
            label: this.selectedSupplier.tradingName,
            id: this.selectedSupplier.id
        };
    }
}
