import { Inject, Component, ViewChild } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotSpecScheduleItemLogicService, ILotSpecScheduleItemMappedItem } from '@app/logic/lot-spec-schedule-item';
import { ProductLogicService } from '@app/logic/products';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { NullableBooleanComponent } from '@app/shared/components/forms/nullable-boolean/nullable-boolean.component';
import { ViewImageComponent } from '@app/shared/components/image-handlers/view-image/view-image.component';
import { FormMode } from '@app/shared/enums/form';
import {
    COST_TYPE_ENUM,
    ICategoryDto,
    IIdAndLabelDto,
    ILotSpecScheduleItemDto,
    ILotSpecScheduleRestrictionDto,
    IOfferingLookupDto,
    IProductOfferingDto,
    ISlotDto,
    ISpecGroupDto,
    UNIT_OF_MEASURE_ENUM,
} from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { Dictionary, find, orderBy } from 'lodash';
import { Observable } from 'rxjs';

interface IData {
    mappedItem: ILotSpecScheduleItemMappedItem;
    houseAreas?: ISpecGroupDto[];
    lotId: number;
    formMode: FormMode;
    slotIdListByGroupId: Dictionary<number[]>;
    isLotSpecLocked$: Observable<boolean>;
}

@Component({
    selector: 'cb-lot-spec-schedule-item-dialog',
    templateUrl: './lot-spec-schedule-item-dialog.component.html',
    styleUrls: ['./lot-spec-schedule-item-dialog.component.scss']
})
export class LotSpecScheduleItemDialogComponent
    extends BaseDialogFormViewDirective<ILotSpecScheduleItemDto, ILotSpecScheduleItemMappedItem, ILotSpecScheduleItemLogicService> {

    public static readonly MIN_WIDTH = '40%';

    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public scheduleTemplateCostTypes = COST_TYPE_ENUM.toSelectList().filter(x => x.id !== COST_TYPE_ENUM.None && x.id !== COST_TYPE_ENUM.Quote);
    public suppliers: IIdAndLabelDto[];
    public productSearchText: string;
    public selectedProduct: IProductOfferingDto | string;
    public productUom: string;
    public productOptions: { [attrId: number]: number } = {};

    public readonly PLACEHOLDER_URL = 'assets/img/no-image-placeholder.png';
    private readonly DEFAULT_UOM = 'Each';
    public readonly uom = UNIT_OF_MEASURE_ENUM.toLookup();
    public readonly houseAreas: ISpecGroupDto[];
    public selectedScheduleItem: ISlotDto;
    public hasProductSearchTextFirstEmissionBeen = false;
    public isLotSpecLocked$: Observable<boolean>;
    public strictSearch: boolean;

    @ViewChild('itemForm') public itemForm: NgForm;
    @ViewChild('includeInColourSchedule') public showInColourYourDreamsComponent: NullableBooleanComponent;

    constructor(
        public readonly dialogRef: MatDialogRef<LotSpecScheduleItemDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ProductLogicService) public readonly productLogic: ProductLogicService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = this.data.mappedItem;
        this.isLotSpecLocked$ = this.data.isLotSpecLocked$;
        this.isLotSpecLocked$.subOnce();
        this.mappedItem.lotId = this.data.lotId;
        this.formMode = this.data.formMode;
        // When editing the spec, houseAreas will be empty, so handle it gracefully
        this.houseAreas = this.data.houseAreas && this.data.houseAreas.sort((a, b) => a.label > b.label ? 1 : -1) || [];

        if (this.mappedItem?.product?.id && (this.mappedItem?.product?.id > 0)) {
            this.selectedProduct = {
                id: this.data.mappedItem.product.id,
                name: this.data.mappedItem.product.name
            } as IProductOfferingDto;
        } else if (this.isProvisional() || this.mappedItem.productLabel) {
            this.selectedProduct = { name: this.mappedItem.productLabel } as IProductOfferingDto;
        } else {
            this.selectedProduct = {} as IProductOfferingDto;
        }

        this.loadProduct();
    }

    public dialogHeading(): string {
        return this.formMode === FormMode.Edit ? 'Edit Lot Specification Item' : 'Add Lot Specification Item';
    }

    public cancel(): void {
        if (this.itemForm.form.dirty) {
            this.cbDialog.confirm({
                dialogHeading: 'Cancel Editing Lot Specification Item?',
                message: 'Are you sure you want to cancel editing this Lot Specification Item?',
                confirmed: () => {
                    super.cancel();
                }
            });
        } else {
            super.cancel();
        }
    }

    public isQuoted(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Quote;
    }

    public isActual(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Actual;
    }

    public isDescriptiveOnly(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.DescriptiveOnly;
    }

    public isEstimated(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Estimate;
    }

    public isProvisional(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Provisional;
    }

    public isNotDescriptive(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Actual ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Provisional ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Estimate ||
            this.mappedItem?.costType === COST_TYPE_ENUM.NoCharge ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Quote ||
            this.mappedItem?.costType === COST_TYPE_ENUM.OwnersCare;
    }

    private isProductSelected(): boolean {
        return (this.selectedProduct as IProductOfferingDto)?.id > 0;
    }

    public getImageUrl(): string {
        return !isNullOrWhiteSpace(this.mappedItem?.scheduleItemImageUrl) ? this.mappedItem?.scheduleItemImageUrl : this.mappedItem?.productImageUrl;
    }

    public showAmount(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Estimate ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Provisional;
    }

    public getAmountLabel(): string {
        switch (this.mappedItem?.costType) {
            case COST_TYPE_ENUM.Estimate:
                return 'Estimate Amount';
            case COST_TYPE_ENUM.Provisional:
                return 'Provisional Amount';
        }
    }

    public canUploadImage(): boolean {
        return this.mappedItem?.costType !== COST_TYPE_ENUM.Actual && !(this.mappedItem?.product?.id > 0) && isNullOrWhiteSpace(this.mappedItem?.scheduleItemImageUrl);
    }

    public canRemoveImage(): boolean {
        return !isNullOrWhiteSpace(this.mappedItem?.scheduleItemImageUrl);
    }

    public viewImage(): void {
        this.cbDialog.open(ViewImageComponent,
            {
                data: {
                    name: 'Product Image',
                    url: this.getImageUrl()
                },
                panelClass: 'cb-full-width-dialog'
            }
        );
    }

    public uploadImage($event, form: UntypedFormGroup): void {
        const reader = new FileReader();
        const file = $event.target.files[0];

        if ($event.target.files && $event.target.files[0]) {

            this.mappedItem.specScheduleItemImageFile = file;

            reader.readAsDataURL(file);

            reader.onload = () => {
                this.mappedItem.productImageUrl = reader.result as string;
                form.markAsDirty();
            };

        }
    }

    public removeImage(form?: UntypedFormGroup): void {
        this.mappedItem.removeImageOnSave = true;
        this.mappedItem.scheduleItemImageUrl = null;
        form?.markAsDirty();
    }

    public saveItem(form: NgForm): void {

        if (this.saveDisabled(form)) {
            return;
        }
        const block = this.cbDialog.block('Saving Item...');

        if (!this.selectedProduct && !this.isDescriptiveOnly()) {
            this.mappedItem.productLabel = this.productSearchText;
        }
        else if (typeof (this.selectedProduct) === 'string' && !this.isDescriptiveOnly()) {
            this.mappedItem.productLabel = this.selectedProduct;
        }

        this.mappedItem.$save()
            .subOnce({
                next: result => {
                    this.dialogRef.close(result);
                    block.close();
                },
                error: () => {
                    block.close();
                }
            });
    }

    public clearProductForCostType(): void {
        this.mappedItem.rateSnapshot = 0;

        if (this.isDescriptiveOnly()) {
            this.selectedProduct = {} as IProductOfferingDto;
            this.productUpdate();
            this.productSearchTextChanged(null);
            this.mappedItem.productLabel = null;
            this.mappedItem.quantity = 1;
            this.mappedItem.category = {} as ICategoryDto;
        }

        else if (this.isActual() && !this.isProductSelected()) {
            this.selectedProduct = {} as IProductOfferingDto;
            this.mappedItem.quantity = 1;
            this.removeImage();
            this.productUpdate();
            this.productSearchTextChanged(null);
        }
    }

    public productSearchTextChanged(str: string): void {
        if (this.hasProductSearchTextFirstEmissionBeen) {
            this.productSearchText = str;
            this.mappedItem.restrictions = null;
            this.mappedItem.product.id = null;
            this.productUom = null;
        }
        this.hasProductSearchTextFirstEmissionBeen = true;
    }

    public descriptionTextChanged(): void {
        this.mappedItem.restrictions = null;
        this.mappedItem.product.id = null;
        this.mappedItem.product.name = null;
        this.productUom = null;
    }

    public productUpdate = (data?: IProductOfferingDto | null): void => {
        if (data != null) {

            // This will mark the product field as dirty once the product is selected
            this.itemForm?.form?.controls?.product?.markAsDirty();

            this.selectedProduct = data;
            this.productSearchText = data.name;
            if (this.selectedProduct && this.mappedItem?.product?.id !== this.selectedProduct.id) {
                this.mappedItem.productLabel = null;
                this.loadProduct(true);
            }
        } else if (!this.selectedProduct) {
            this.mappedItem.restrictions = null;
            this.mappedItem.product = {} as IOfferingLookupDto;
            this.mappedItem.productImageUrl = null;
            if (!this.isDescriptiveOnly()) {
                this.productSearchText = null;
            }
        }
        this._addNoneToRestrictionsOptions();
    };

    public manualColourEntryRequiredChanged(value: boolean): void {
        if (value === true) {
            this.mappedItem.showInColourYourDreams = true;

            // There was a weird bug where baseFormComponent.writeValue was occasionally
            // not being called after the above line should have triggered it - so calling it manually.
            this.showInColourYourDreamsComponent.writeValue(true);
        }
        else {
            this.mappedItem.manualColourName = '';
            this.mappedItem.manualColourNotes = '';

        }
    }

    public loadProduct = (isProductUpdated = false): void => {
        if (!this.selectedProduct) {
            return;
        }

        this.mappedItem.product.id = (this.selectedProduct as IProductOfferingDto)?.id;

        if (!this.mappedItem.supplierId) {
            this.mappedItem.supplierId = null;
        }

        if (!(this.selectedProduct as IProductOfferingDto)?.id) {
            return;
        }

        this._addNoneToRestrictionsOptions();
        this.productLogic
            .getOfferingInfoForLotSpecScheduleItem((this.selectedProduct as IProductOfferingDto)?.id)
            .subOnce((result) => {
                this.mappedItem.suppliers = orderBy(result.suppliers.map((supplier) => ({ id: supplier.id, label: supplier.label })), 'label', 'asc');
                this.mappedItem?.suppliers.unshift({ id: null, label: 'Not Specified' });

                const productUom = find(this.uom, { id: result.uom });
                this.productUom = (productUom) ? productUom.label : this.DEFAULT_UOM;

                this._setupRestrictions(result.restrictions);
                this.mappedItem.category.categoryPath = result?.categoryPath?.path;

                this.mappedItem.productImageUrl = result?.productImageUrl;

                if (isProductUpdated) {
                    this.mappedItem.clientFriendlyDescription = result?.clientSpecDescription;
                } else {
                    if (this.mappedItem.clientFriendlyDescription == null) {
                        this.mappedItem.clientFriendlyDescription = result?.clientSpecDescription;
                    }
                }

                // If a value is already set, dont overwrite it with the product default
                if (this.mappedItem.showInClientSpecification === undefined) {
                    this.mappedItem.showInClientSpecification = result?.includeInCreateSchedule;
                }
                // If a value is already set, dont overwrite it with the product default
                if (this.mappedItem.showInColourYourDreams === undefined) {
                    this.mappedItem.showInColourYourDreams = result?.includeInColourSchedule;
                }
                if (this.mappedItem.manualColourEntryRequired === undefined) {
                    this.mappedItem.manualColourEntryRequired = result?.manualColourEntryRequired;
                }
            });
    };

    public saveDisabled(lotSpecItemForm: NgForm): boolean {
        return lotSpecItemForm.invalid
            || lotSpecItemForm.pristine
            || (
                this.isActual() && !this.isProductSelected()
            );
    }

    public scheduleItemSelected(slot: ISlotDto): void {
        if (slot) {
            this.mappedItem.category = slot?.categories?.[0] ?? {} as ICategoryDto;
            this.mappedItem.slotId = slot?.id;
            this.mappedItem.slotCategory = slot?.categories?.[0];
            this.mappedItem.costType = slot?.costType;
        }
    }

    private _setupRestrictions(restrictions: ILotSpecScheduleRestrictionDto[]): void {
        if (!this.mappedItem.restrictions ||
            this.mappedItem.restrictions.length < 1) {
            this.mappedItem.restrictions = restrictions;
        }
        this.mappedItem.restrictions.forEach(restriction => {
            if (!restriction.selectedOptionId && restriction.options.length === 1) {
                restriction.selectedOptionId = restriction.options[0].id;
            }
        });
    }

    private _getMappedItemRestrictionId(restriction): number {
        return restriction.attributeId ?? restriction.id;
    }

    private _addNoneToRestrictionsOptions(): void {
        if ((this.selectedProduct as IProductOfferingDto).id) {
            this.mappedItem.restrictions = this.mappedItem?.restrictions?.map(restriction => {
                const restrictionWithNoneOption = this._addRestrictionNoneOption(restriction);
                this._setRestrictionSelectedOptionId(restrictionWithNoneOption);
                return restrictionWithNoneOption;
            });
        }
    }

    private _addRestrictionNoneOption(restriction: any): ILotSpecScheduleRestrictionDto {
        let newRestriction: ILotSpecScheduleRestrictionDto;
        if (!restriction.options.some(option => option.id === 0)) {
            newRestriction = {
                ...restriction, options: [{
                    id: 0,
                    name: 'None'
                },
                ...restriction.options]
            };
        } else {
            newRestriction = restriction;
        }
        return newRestriction;
    }

    private _setRestrictionSelectedOptionId(newRestriction: ILotSpecScheduleRestrictionDto): void {
        const mappedItemRestriction = this
            .mappedItem.restrictions
            .find(mappedRestriction => (
                this._getMappedItemRestrictionId(mappedRestriction) === (newRestriction as any).attributeId));
        if (mappedItemRestriction && !mappedItemRestriction.selectedOptionId) {
            newRestriction.selectedOptionId = 0;
        } else if (mappedItemRestriction) {
            newRestriction.selectedOptionId = mappedItemRestriction?.selectedOptionId;
        }
    }
}
