import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { VendorsLogicService } from '@app/logic/vendors/vendors.logic.service';
import { VendorsInputFindDialogDirective } from './vendors-input-find-dialog-directive';

interface IData {
    mappedItem: any;
    searchService: SearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

@Component({
    selector: 'cb-vendors-input-find-dialog-cards',
    templateUrl: '../../input-find-dialog-cards/input-find-dialog-cards.component.html',
    styleUrls: ['../../input-find-dialog-cards/input-find-dialog-cards.component.scss'],
    providers: [VendorsLogicService]
})
export class VendorsInputFindDialogCardsComponent extends VendorsInputFindDialogDirective {
    constructor(
        public readonly dialogRef: MatDialogRef<VendorsInputFindDialogDirective>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public logicService: VendorsLogicService,
        public cdRef: ChangeDetectorRef
    ) {
        super(
            dialogRef,
            data,
            logicService,
            cdRef,
        );
    }
}

