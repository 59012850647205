import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WipMainPageComponent } from './wip-main-page/wip-main-page.component';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { WipTableComponent } from '@app/views/wip/wip-table/wip-table.component';

export const WIP_HOME_ROUTE = 'wip';
const WIP_ROUTE_DATA = {
    breadcrumb: 'Wip'
};

@NgModule({
    declarations: [
        WipMainPageComponent
    ],
    exports: [
    ],
    imports: [
        RouterModule.forRoot([
            {
                component: WipMainPageComponent,
                path: WIP_HOME_ROUTE,
                data: WIP_ROUTE_DATA
            }
        ], {}),
        MatLegacyTableModule,
        WipTableComponent,
    ]
})
export class WipModule { }
