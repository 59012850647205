import { BaseFormComponentDirective } from '../base-form-component';
import { Input, OnInit, Directive } from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive()
export class BaseInputFindDirective extends BaseFormComponentDirective implements OnInit {
    @Input() public canCreate: boolean;
    @Input() public cursorPointer = true;
    @Input() public containingForm: NgForm;
    @Input() public id: string | number;
    @Input() public modelOptions = { allowInvalid: true };
    @Input() public removeable: boolean;
    @Input() public searchParams: { [param: string]: any };
    @Input() public text: string;
    @Input() public buttonOnly = false;
    @Input() public maxlength: number;

    public openSearchDialog: () => any;
    public viewItem: () => any;

    public ngOnInit(): void {

        this.setId();
    }

    protected setId(): void {
        if (this.value && this.value.id) {
            this.id = this.value.id;
        }
    }

    public clear(): void {
        this.text = null;
        this.populateData(null);
    }

    public populateData(result: any): void {
        this.value = result;
        if (this.containingForm) {
            this.containingForm.form.markAsDirty();
        }
        if (this.change) {
            this.emitChange();
        }
    }
}
