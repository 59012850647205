import { Component, Input } from '@angular/core';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { getBaseFormComponentDirectiveProvider } from '../../base-form-component';
import { InputComponent } from '../input.component';

@Component({
    selector: 'cb-input[icon], cb-input[svgIcon]',
    templateUrl: './icon-input.component.html',
    styleUrls: ['./icon-input.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(IconInputComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ],
})
export class IconInputComponent extends InputComponent {
    @Input() public icon: string;
    @Input() public svgIcon: string;
}
