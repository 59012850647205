import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, NgZone, Output } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { ISlotSearchFilters } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ISlotMappedItem } from '@app/logic/slots/interfaces/i.slot.mapped';
import { SlotsLogicService } from '@app/logic/slots/slots.logic-service';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { COST_TYPE_ENUM, ISlotDto, ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { toPromisedArray } from 'cb-hub-lib';
import { keys, orderBy, pickBy, remove } from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { map, Subject, Subscription } from 'rxjs';
import { ManageSlotDialogComponent } from '../slot-dialog/manage-slot-dialog.component';

@Component({
    selector: 'cb-house-area-slots-table',
    templateUrl: './house-area-slots-table.component.html',
    styleUrls: ['./house-area-slots-table.component.scss'],
})
export class HouseAreaSlotsTableComponent {
    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public subscriptions = new Subscription();
    public selectedItems: { [slotId: number]: boolean } = {};
    public expandedParents = {} as { [parentId: number]: boolean };
    @Input() public slotTags: ITagDto[];
    @Output() public itemSelectedEmitter = new EventEmitter();

    @Output() public parentSlotCountEmitter = new EventEmitter<number>();

    private slotsUpdated$ = new Subscription();
    @Input() public set slotsUpdated(x: EventEmitter<ISlotDto[]>) {
        this.slotsUpdated$.unsubscribe();
        if (x) {
            this.slotsUpdatedEmitter$ = x;
            // filter out slots that don't not belong to this housearea
            this.slotsUpdated$ = x.pipe(
                map(x => x.filter(x => x.houseArea.id === this.houseAreaId))
            ).subscribe(this.handleSlotsUpdatedEvent);
        }
    }

    private slotsUpdatedEmitter$: EventEmitter<ISlotDto[]>;


    private queryUpdated$ = new Subscription();
    @Input() public set queryUpdated(x: Subject<void>) {
        this.queryUpdated$.unsubscribe();
        if (x) {
            this.queryUpdated$ = x.subscribe(this.runQueryUpdate);
        }
    }

    public houseAreas = toPromisedArray(this.specGroupsService.$getList().pipe(
        map(results => orderBy(results, 'sortOrder', 'asc'))));
    public currentPage = 1;
    public queryUpdate = new Subject<void>();

    public get userCacheItem(): UserCacheItem<ISlotSearchFilters> {
        return this.userCacheService.slotSearch;
    }

    private parentSlotsBackup: ISlotDto[];

    public _parentSlotsArray = [] as ISlotDto[];

    public get parentSlotsArray(): ISlotDto[] {
        return this._parentSlotsArray || [];
    }

    @Input() public set parentSlotsArray(val: ISlotDto[]) {
        this._parentSlotsArray = val;
        this.parentSlotCountEmitter.emit(this.parentSlotsArray.length);
    }

    public parentSlotsDict = {} as { [slotId: number]: ISlotDto };
    public childSlotsDict = {} as { [parentSlotId: number]: ISlotDto[] };

    @Output() public readonly sortOrderChanged = new EventEmitter<{ [slotId: number]: number }>();

    private _houseAreaId: number;

    @Input() public set houseAreaId(x: number) {
        this._houseAreaId = x;
        this.dragulaService.destroy(this.SCHEDULE_ITEMS);
        this.SCHEDULE_ITEMS = `SCHEDULE_ITEMS_${x}`;
        this.setupDragula();
        this.runQueryUpdate();
    }

    public get houseAreaId(): number {
        return this._houseAreaId;
    }

    public SCHEDULE_ITEMS: string;
    @Input() public searchEnabled = false;
    public dragulaModel: number[] = [];
    public readonly ENABLE_REORDERING = 'enable-reordering';
    public readonly DISABLE_REORDERING = 'disable-reordering';
    public savingOrder = false;

    private reorderCancelled$ = new Subscription();
    private emitter: Subject<void>;
    @Input() public set reorderCancelled(x: Subject<void>) {
        this.reorderCancelled$.unsubscribe();
        if (x) {
            this.emitter = x;
            this.reorderCancelled$ = x.subscribe(() => {
                this.parentSlotsArray = cloneDeepSafe(this.parentSlotsBackup);
                this.setDragulaModel();
            });
        }
    }


    private resetSelectedTags$ = new Subscription();
    private resetSelectedTagemitter: Subject<void>;
    @Input() public set resetSelectedTags(x: Subject<void>) {
        this.resetSelectedTags$.unsubscribe();
        if (x) {
            this.resetSelectedTagemitter = x;
            this.resetSelectedTags$ = x.subscribe(() => {
                this.selectedItems = {};
            });
        }
    }


    private _reorderingEnabled: boolean;
    @Input() public set reorderingEnabled(v: boolean) {
        this._reorderingEnabled = v;
        // backup results
        if (v) {
            this.parentSlotsBackup = cloneDeepSafe(this.parentSlotsArray);
            this.runQueryUpdate();
        }
    }
    public get reorderingEnabled(): boolean {
        return this._reorderingEnabled;
    }

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialogService: CbDialogService,
        private readonly slotsLogicService: SlotsLogicService,
        private readonly dragulaService: DragulaService,
        public readonly userCacheService: UserCacheService,
        private readonly zone: NgZone,
        @Inject(SpecGroupsLogicService) public readonly specGroupsService: SpecGroupsLogicService,
        public cdRef: ChangeDetectorRef
    ) {
    }

    public ngOnDestroy(): void {
        this.slotsUpdated$.unsubscribe();
        this.queryUpdated$.unsubscribe();
        this.reorderCancelled$.unsubscribe();
        this.resetSelectedTags$.unsubscribe();
        this.subscriptions.unsubscribe();
        this.dragulaService.destroy(this.SCHEDULE_ITEMS);
    }

    public readonly runQueryUpdate = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.parentSlotsArray = [];
        this.parentSlotsDict = {};
        this.childSlotsDict = {};
        this.fetchResultsForHouseArea();
    };

    public fetchResultsForHouseArea(houseAreaId: number[] = [this.houseAreaId]): void {
        if (!this.searchEnabled || !houseAreaId[0]) {
            return;
        }
        let params = { specgroupId: houseAreaId, currentPage: this.currentPage, pageSize: 9999 } as ISlotSearchFilters & { currentPage: number; pageSize?: number };
        if (!this.reorderingEnabled) {
            params = { ...this.userCacheItem.copyData(), ...params };
        }
        this.slotsLogicService.$getSearchList(params).subOnce(searchResult => {
            this.mapUpdatedSlotsToResults(searchResult.items);
            if (searchResult.total !== -1 && searchResult.total > 0) {
                this.currentPage += 1;
                this.fetchResultsForHouseArea();
            }
            this.updateSelectedItems();
        });
    }

    private readonly handleSlotsUpdatedEvent = (slots: ISlotDto[]): void => {
        if (slots?.length < 1) {
            return;
        }
        this.mapUpdatedSlotsToResults(slots);
        this.updateSelectedItems();
    };

    /**
     * Used to update the slot search results after any change to any slot.
     * It should be the ***only*** method used to update the search results after a change.
     *
     * @param updatedSlots An array of the slots that have changed. ***Can be just one - also can be a new slot***.
     *
     */
    private mapUpdatedSlotsToResults = (updatedSlots: ISlotDto[]): void => {
        if (!updatedSlots?.length) {
            return;
        }
        this.performMapUpdatedSlotsToResults(updatedSlots);
        this.setParentSlotsArray();
    };

    /** updates which slots are selected based on which slots are in the results arrays */
    private updateSelectedItems(): void {
        this.selectedItems = Object.keys(this.selectedItems).reduce((result, key) => {
            const itemId = +key;
            const foundParent = this.parentSlotsArray.find(x => x.id === itemId);
            if (foundParent) {
                result[itemId] = this.selectedItems[itemId];
                return result;
            }
            const foundChild = Object.values(this.childSlotsDict).reduce((curr, prev) => [...curr, ...prev], []).find(x => x.id === itemId);
            if (foundChild) {
                result[itemId] = this.selectedItems[itemId];
                return result;
            }
            return result;
        }, {});
        this.emitSelectedItem();
    }

    private performMapUpdatedSlotsToResults = (updatedSlots: ISlotDto[]): void => {
        for (const currentSlotOfLoop of updatedSlots) {
            if (currentSlotOfLoop.houseArea.id !== this.houseAreaId) {
                this.handleChangeOfHouseAreaParent(currentSlotOfLoop);
                continue;
            }
            if (!currentSlotOfLoop.parentSlotId) {
                // Slot is not a child
                this.parentSlotsDict[currentSlotOfLoop.id] = currentSlotOfLoop;
                // Since slot is not a child, remove it from child array
                this.removeChildSlot(currentSlotOfLoop.id);
            } else {
                // Slot is a child
                this.mapChildSlotToResults(currentSlotOfLoop);
            }
        }
    };

    private removeChildSlot = (childSlotToRemoveId: number): void => {
        for (const parentSlotId of Object.keys(this.childSlotsDict)) {
            const childSlotsOfParentDict = this.childSlotsDict[parentSlotId];
            const index = childSlotsOfParentDict.findIndex(x => x.id === childSlotToRemoveId);
            if (index < 0) {
                continue;
            }
            childSlotsOfParentDict.splice(index, 1);
            if (childSlotsOfParentDict.length < 1) {
                delete this.childSlotsDict[parentSlotId];
            }
            break;
        }
    };

    private mapChildSlotToResults = (currentSlotOfLoop: ISlotDto): void => {
        const childSlotsOfParentDict = (this.childSlotsDict[currentSlotOfLoop.parentSlotId] ?? [])
            .reduce((toReturn, curr) => {
                toReturn[curr.id] = curr;
                return toReturn;
            }, {}) as { [slotId: number]: ISlotDto };
        childSlotsOfParentDict[currentSlotOfLoop.id] = currentSlotOfLoop;
        this.childSlotsDict[currentSlotOfLoop.parentSlotId] = orderBy(Object.values(childSlotsOfParentDict).filter(x => x?.id > 0), (x) => x.reportOrder);
        if (!this.parentSlotsDict[currentSlotOfLoop.parentSlotId]) {
            this.parentSlotsDict[currentSlotOfLoop.parentSlotId] = currentSlotOfLoop.parent;
        }
        // if necessary, remove slot from its previous position in parent dictionary
        if (this.parentSlotsDict[currentSlotOfLoop.id]) {
            delete this.parentSlotsDict[currentSlotOfLoop.id];
        }
    };

    private setParentSlotsArray(): void {
        this.parentSlotsArray = orderBy(Object.values(this.parentSlotsDict).filter(x => x?.id > 0), (x) => x.reportOrder);
        this.setDragulaModel();
    }

    private handleChangeOfHouseAreaParent(currentSlotOfLoop: ISlotDto): void {
        if (!currentSlotOfLoop.parentSlotId) {
            delete this.parentSlotsDict[currentSlotOfLoop.id];
            // if necessary, remove slot from its previous position in child array
            this.removeChildSlot(currentSlotOfLoop.id);
        } else {
            remove(this.childSlotsDict[currentSlotOfLoop.parentSlotId], (x) => x.id === currentSlotOfLoop.id);
        }
        this.slotsUpdatedEmitter$.emit([currentSlotOfLoop]);
        this.setParentSlotsArray();
    }

    public openDialog(mappedItem: ISlotMappedItem, dialogHeading: string): any {
        return this.dialogService
            .open(
                ManageSlotDialogComponent,
                {
                    data: {
                        slotTags: this.slotTags,
                        dialogHeading,
                        mappedItem,
                    },
                }
            );
    }

    public editItemClicked = (dto: ISlotDto): void => {
        let dialogHeading: string;
        if (dto.parentSlotId || dto.parent) {
            dialogHeading = 'Edit Child Schedule Item';
        } else {
            dialogHeading = 'Edit Schedule Item';
        }
        this.openDialog(this.slotsLogicService.$createMappedItem(dto), dialogHeading)
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.mapUpdatedSlotsToResults(result);
                }
            });
    };

    public itemSelected(item: ISlotDto): void {
        this.selectedItems[item.id] = !this.selectedItems[item.id];
        this.emitSelectedItem();
    }

    private emitSelectedItem(): void {
        const selecteditemIds = keys(pickBy(this.selectedItems, (value, key) => value));
        const selectedItem = {
            area: this.houseAreaId,
            ids: selecteditemIds
        };
        this.itemSelectedEmitter.emit(selectedItem);
    }

    /** Creates dragula group for address regions table and subscribes to dragula observables */
    private setupDragula(): void {
        this.dragulaService.createGroup(
            this.SCHEDULE_ITEMS,
            {
                removeOnSpill: false,
                accepts: (el: Element) =>
                    this.reorderingEnabled
                    && el.classList.contains(this.ENABLE_REORDERING),
                moves: (el: Element) => this.reorderingEnabled
                    && el.classList.contains(this.ENABLE_REORDERING),
                revertOnSpill: true
            }
        );

        this.subscriptions.add(
            this.dragulaService.dropModel(this.SCHEDULE_ITEMS)
                .subscribe(
                    ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                        // index adjustment seems to be required when using multiple tbodys?
                        sourceIndex = sourceIndex - 1;
                        targetIndex = targetIndex - 1;
                        if (sourceIndex === targetIndex) {
                            return;
                        }
                        moveItemInArray(this.parentSlotsArray, sourceIndex, targetIndex);
                        /** key = Slot.ID, value = Slot.ReportOrder */
                        const reportOrders: { [slotId: number]: number } = {};
                        this.parentSlotsArray.forEach((x, i) => {
                            x.reportOrder = i + 1;
                            reportOrders[x.id] = x.reportOrder;
                        });
                        this.setDragulaModel();
                        this.sortOrderChanged.emit(reportOrders);
                    }
                )
        );
    }

    private setDragulaModel(): void {
        this.dragulaModel = [...this.parentSlotsArray?.filter(x => x.id > 0)?.map(x => x.id) ?? []];
    }
}
