import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IStandardPlanCostDto } from './interfaces/i.standard-plan-cost.dto';
import { IStandardPlanCostLogicService } from './interfaces/i.standard-plan-cost.logic.service';
import { IStandardPlanCostMappedItem } from './interfaces/i.standard-plan-cost.mapped';
import { StandardPlanCostMappedItem } from './standard-plan-cost.mapped';

@Injectable()
export class StandardPlanCostLogicService
    extends BaseLogicService<IStandardPlanCostDto, IStandardPlanCostMappedItem>
    implements IStandardPlanCostLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'costs',
            StandardPlanCostMappedItem,
        );
    }

    public saveStandardPlanCost(entityUri: string, cost: IStandardPlanCostDto): Observable<IStandardPlanCostDto> {
        if (cost.id > 0) {
            return this.$http.post(`${entityUri}/${this.$baseUri}/${cost.id}`, cost);
        }
        return this.$http.post(`${entityUri}/${this.$baseUri}/create`, cost);
    }


    public createMappedCostItem(entityUri: string, entityId: number, cost?: IStandardPlanCostDto): IStandardPlanCostMappedItem {
        const result = this.$createMappedItem(cost);
        result.entityUri = entityUri;
        result.entityId = entityId;
        result.standardPlanId = entityId;
        return result;
    }

    public getMappedStandardPlanCosts(entityUri: string, entityId: number): Observable<IStandardPlanCostMappedItem[]> {
        return this.$http.get<IStandardPlanCostDto[]>(`${entityUri}/${entityId}/${this.$baseUri}`)
            .pipe(
                map(results => results.map(value => this.createMappedCostItem(entityUri, entityId, value)))
            );
    }
}
