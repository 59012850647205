export abstract class ArrayUtils {

    /** Move item in array from one index to another index.
     *
     * @param array The array to perform on.
     * @param fromIndex The initial index of the item to move.
     * @param toIndex The desired index to move the item to.
     */
    public static moveItemInArrayFromIndexToIndex = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
        if (fromIndex === toIndex) {
            return array;
        }

        const newArray = [...array];

        const target = newArray[fromIndex];
        const inc = toIndex < fromIndex ? -1 : 1;

        for (let i = fromIndex; i !== toIndex; i += inc) {
            newArray[i] = newArray[i + inc];
        }

        newArray[toIndex] = target;

        return newArray;
    };
}
