export enum HelpPermissionEnum {
    None = 0x00,
    CanView = 0x01 << 0,
    CanViewUnpublished = 0x01 << 1,
    CanReorder = 0x01 << 2,
    CanEdit = 0x01 << 3,
    CanCreate = 0x01 << 4,
    CanDelete = 0x01 << 5,
    CanManageCategories = 0x01 << 6,
}
