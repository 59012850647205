import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IPaymentFrequenciesLogicService } from './interfaces/i.payment-frequencies.logic.service';
import { IPaymentFrequencyDto } from './interfaces/i.payment-frequency.dto';
import { IPaymentFrequencyMappedItem } from './interfaces/i.payment-frequency.mapped';
import { PaymentFrequencyMappedItem } from './payment-frequency.mapped';


@Injectable()
export class PaymentFrequenciesLogicService
    extends BaseLogicService<IPaymentFrequencyDto, IPaymentFrequencyMappedItem>
    implements IPaymentFrequenciesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'paymentfrequencies',
            PaymentFrequencyMappedItem,
        );
    }
}
