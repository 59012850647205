import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbDasherModule, CbInfiniteScrollModule, CbInfoPopupModule } from 'cb-hub-lib';
import { CbInfoMessageModule } from 'cb-hub-lib';
import { CbLoadingSpinnerModule } from 'cb-hub-lib';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { StandardTableComponent } from './standard-table/standard-table.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CB_TABLE_EXPORTS } from './table/exports';

const COMPONENTS = [
    StandardTableComponent,
    ...CB_TABLE_EXPORTS,
];

@NgModule({
    declarations: [
        COMPONENTS
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        CbInfoPopupModule,
        CbDasherModule,
        CbInfoMessageModule,
        CbCurrencyModule,
        MatSelectModule,
        MatOptionModule,
        MatCardModule,
        MatCheckboxModule,
        CbLoadingSpinnerModule,
        MatTableModule,
        InfiniteScrollModule,
        CbInfiniteScrollModule,
    ],
    exports: COMPONENTS,
})
export class CbHubTableModule { }
