import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DesignTeamPermissions, LotDesignPermissions } from '@app/core/permissions';
import { DocumentsLogicService, IDocumentEntityMappedItem } from '@app/logic/documents';
import { ILotMappedItem } from '@app/logic/lots';
import { TakeoffsLogicService } from '@app/logic/takeoffs/takeoffs.logic.service';
import { IWorkingDrawingMappedItem, WorkingDrawingsLogicService } from '@app/logic/working-drawings';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { SimpleFormInputType } from '@app/shared/components/dialog/simple-form-dialog/simple-form-dialog.component';
import {
    DOCUMENT_STATUS_ENUM,
    DOCUMENT_TYPE_CODES_CONST,
    WorkingDrawingStatusEnumId,
    WORKING_DRAWING_CHANGE_STATUS_ENUM,
    WORKING_DRAWING_ITEM_STATUS_ENUM,
    WORKING_DRAWING_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'projects/cb-hub-lib/src/lib/utils/string.util';
import { FinaliseWorkingDrawingDialogComponent } from '../finalise-working-drawing-dialog/finalise-working-drawing-dialog.component';
import { WorkingDrawingDialogComponent } from '../working-drawing-dialog/working-drawing-dialog.component';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { ToastService } from '@app/core/services/toast/toast.service';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
@Component({
    selector: 'cb-working-drawing-action-bar',
    templateUrl: './working-drawing-action-bar.component.html',
    styleUrls: ['./working-drawing-action-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkingDrawingActionBarComponent implements OnInit {

    @Output() public readonly goBack = new EventEmitter<void>();

    @Input() public mappedItem: IWorkingDrawingMappedItem;
    @Output() public mappedItemChange = new EventEmitter<IWorkingDrawingMappedItem>();
    @Output() public lotMappedItemChange = new EventEmitter();

    @Input() public lotMappedItem: ILotMappedItem;

    public WORKING_DRAWING_STATUS_ENUM = WORKING_DRAWING_STATUS_ENUM;
    public documents: IDocumentEntityMappedItem[];
    public isResubmitWorkingDrawingsEnabled = true;


    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly workingDrawingsLogicService: WorkingDrawingsLogicService,
        private readonly takeoffsLogicService: TakeoffsLogicService,
        private readonly lotDesignPermissions: LotDesignPermissions,
        public readonly documentsLogicService: DocumentsLogicService,
        private readonly cdRef: ChangeDetectorRef,
        public readonly currentUser: CurrentUserService,
        private readonly toastService: ToastService,
        public readonly featureToggleStateService: FeatureToggleStatesService,
        public readonly designTeamPermissions: DesignTeamPermissions
    ) {
    }

    public ngOnInit(): void {
        this.loadDocuments();
    }


    public canResubmit(): boolean {
        return this.mappedItem.statusId === WorkingDrawingStatusEnumId.Rejected;
    }

    public loadDocuments(): void {
        this.documentsLogicService
            .getMappedDocumentEntities(this.mappedItem.documentEntityUri, this.mappedItem.documentEntityId, false, false)
            .subOnce((docs) => {
                this.documents = docs;
                this.cdRef.detectChanges();
            });
    }

    public canEditWorkingDrawing(): boolean {
        return this.lotDesignPermissions.canEditWorkingDrawing();
    }

    public canReviewWorkingDrawing(): boolean {
        return this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.DesignReviewRequired
            && this.workingDrawingsRequireReview()
            && this.mappedItem?.reviewerId === this.currentUser.guid;
    }

    public canManageWorkingDrawing(): boolean {
        return this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Pending
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.InQueue
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.InQueueForRework
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.InProgress
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.OnHold
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.DesignReviewRequired
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.WaitingForReview;
    }

    public canChangeWorkingDrawingStatus(): boolean {
        return (this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Pending
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.InQueue
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.InQueueForRework
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.InProgress
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.OnHold)
            && this.hasPopulatedRequestedData();
    }
    public canCancelWorkingDrawing(): boolean {
        return !(this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Accepted
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Completed
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Declined
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Cancelled);
    }


    // Checks whether data not set when requesting a working drawing has been populated.
    private hasPopulatedRequestedData(): boolean {
        return !isNullOrWhiteSpace(this.mappedItem.dueDate)
            && this.mappedItem.designComplexityId > 0
            && !isNullOrWhiteSpace(this.mappedItem.approverId);
    }

    public canSetWorkingDrawingInProgress(): boolean {
        return !isNullOrWhiteSpace(this.mappedItem.assignedToId)
            && this.canChangeWorkingDrawingStatus();
    }


    public canFinaliseWorkingDrawing(): boolean {
        return this.canChangeWorkingDrawingStatus()
            && !this.canNotFinaliseWorkingDrawing()
            && this.hasCompletedAllItemsAndChanges()
            && this.documents?.some(x =>
                x.documentType
                && x.documentType.codeName === DOCUMENT_TYPE_CODES_CONST.WORKING_DRAWINGS && x.document.isDeleted === false
                && (x.documentStatus === DOCUMENT_STATUS_ENUM.Uploaded || x.documentStatus === DOCUMENT_STATUS_ENUM.Reviewed)
            );
    }

    private hasCompletedAllItemsAndChanges(): boolean {
        return this.mappedItem.items?.every(item => item.statusId === WORKING_DRAWING_ITEM_STATUS_ENUM.Completed
            || item.statusId === WORKING_DRAWING_ITEM_STATUS_ENUM.NotDoing);
    }

    private canNotFinaliseWorkingDrawing(): boolean {
        return this.mappedItem.changes?.some(element => element.statusId !== WORKING_DRAWING_CHANGE_STATUS_ENUM.Completed);
    }

    public canRequestTakeoff(): boolean {
        return this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Accepted && this.lotMappedItem.lotSpecLocked;
    }

    public workingDrawingsRequireReview(): boolean {
        return !isNullOrWhiteSpace(this.mappedItem.reviewerId) && this.mappedItem.designReviewRequired === true;
    }

    public backToWorkingDrawings(): void {
        this.goBack.emit();
    }

    public setStatusAndSave(status: WorkingDrawingStatusEnumId): void {
        const blockingDialog = this.cbDialog.block('Saving status...');

        this.mappedItem.statusId = status;
        this.mappedItem.$save().subOnce(() => {
            this.cdRef.detectChanges();
            blockingDialog.close();
            this.mappedItemChange.emit(this.mappedItem);
            this.toastService.showToast('Saved Successfully', 'OK');
        });
    }

    public finaliseWorkingDrawing(): void {
        const dialogRef = this.cbDialog.open(FinaliseWorkingDrawingDialogComponent, {
            data: {
                mappedItem: this.mappedItem,
                lotMappedItem: this.lotMappedItem
            }
        });

        dialogRef.afterClosed().subOnce(_mappedItem => {
            if (_mappedItem) {
                const needsReview = this.workingDrawingsRequireReview();
                const status = needsReview ? WORKING_DRAWING_STATUS_ENUM.DesignReviewRequired : WORKING_DRAWING_STATUS_ENUM.WaitingForReview;
                this.setStatusAndSave(status);
            }
        });
    }

    public cancelWorkingDrawing(): void {
        const blockingDialog = this.cbDialog.block('Saving status...');
        this.cbDialog.confirm({
            dialogHeading: 'Cancel Working Drawing',
            message: 'Are you sure you want to cancel this Working Drawing?',
            confirmed: () => {
                this.mappedItem.statusId = WORKING_DRAWING_STATUS_ENUM.Cancelled;
                this.mappedItem.$save().subOnce(() => {
                    blockingDialog.close();
                    this.mappedItemChange.emit(this.mappedItem);
                    this.toastService.showToast('Saved Successfully', 'OK');
                });
            }
        });
    }

    public canRejectWorkingDrawingRequest(): boolean {
        return this.designTeamPermissions.canRejectWorkingDrawingRequest();
    }

    public cannotRejectWorkingDrawing(): boolean {
        return (this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.DesignReviewRequired
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.WaitingForReview
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Accepted
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Cancelled
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Declined
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Completed
        );
    }


    public rejectWorkingDrawing(): void {
        if (!this.mappedItem.canManage) {
            return;
        }
        this.cbDialog.simpleFormDialog({
            dialogHeading: 'Reject Working Drawings',
            yesLabel: 'Submit',
            noLabel: 'Cancel',
            value: '',
            formInputConfig: {
                type: SimpleFormInputType.Textarea,
                label: 'Comments',
                maxlength: 1024,
                required: true,
            },
            confirmed: (result) => {
                const blocker = this.cbDialog.block('Rejecting Working Drawing...');
                this.mappedItem.reject(result).subOnce(() => {
                    this.mappedItem.$reload().subOnce(() => {
                        this.mappedItemChange.emit(this.mappedItem);
                        this.cdRef.detectChanges();
                    });
                    blocker.close();
                });
            }
        });
    }

    public resubmit(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Resubmit Working Drawings',
            message: 'Are you sure you want to resubmit this Working Drawing?',
            confirmed: () => {
                this.isResubmitWorkingDrawingsEnabled = false;
                this.mappedItem.resubmit().subOnce(() => {
                    this.mappedItem.$reload().subOnce(() => {
                        this.isResubmitWorkingDrawingsEnabled = true;
                        this.mappedItemChange.emit(this.mappedItem);
                        this.cdRef.detectChanges();
                    });
                });
            }
        });
    }

    public manageWorkingDrawing(): void {
        this.cbDialog.open(WorkingDrawingDialogComponent, {
            data: {
                mappedItem: this.mappedItem.$clone(),
                lotMappedItem: this.lotMappedItem.$clone()
            }
        }).afterClosed().subOnce(result => {
            if (result) {
                if (this.mappedItem.designReviewRequired === false) {
                    this.mappedItem.reviewerId = undefined;
                }
                this.mappedItemChange.emit(this.mappedItem);
                this.lotMappedItemChange.emit();
                this.cdRef.detectChanges();
            }
        });
    }

    public reviewWorkingDrawing(): void {
        this.cbDialog
            .confirm({
                dialogHeading: 'Review Working Drawing',
                message: 'Allow Working Drawing to proceed for acceptance',
                yesLabel: 'Proceed Working Drawing',
                noLabel: 'Reject Working Drawing',
                disableDeclineEventOnCloseIcon: true,
                confirmed: () => {
                    this.setStatusAndSave(WORKING_DRAWING_STATUS_ENUM.WaitingForReview);
                },
                declined: () => {

                    this.cbDialog.simpleFormDialog({
                        dialogHeading: 'Reject Working Drawing',
                        yesLabel: 'Confirm',
                        noLabel: 'Close',
                        value: '',
                        formInputConfig: {
                            type: SimpleFormInputType.Textarea,
                            label: 'Reason for Rejection',
                            required: true,
                        },
                        confirmed: (result) => {
                            this.mappedItem.rejectionNotes = result;
                            this.mappedItem.failedReview = true;
                            this.setStatusAndSave(WORKING_DRAWING_STATUS_ENUM.InQueueForRework);
                        }
                    });

                }
            });
    }

    public requestTakeoff(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Request Takeoff',
            message: 'Are you sure you want to Request a Takeoff?',
            confirmed: () => {
                this.takeoffsLogicService.requestTakeOffTaskForWorkingDrawing(this.mappedItem.id).subOnce();
            }
        });
    }

    public downloadConsentDocuments(): void {
        this.workingDrawingsLogicService.generateConsentDocuments(this.mappedItem.id).subOnce();
    }

}
