import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ComputedProperty } from './../../../utils/computed-property.util';
import { IBaseLogicService } from '@app/logic/base/interfaces/i.base-logic.service';
import { IBaseMappedItem } from '@app/logic/base/interfaces/i.base-mapped-item';
import { INoteEntity } from './../../../../logic/notes/interfaces/i.note-entity';
import { INoteMappedItem } from '@app/logic/notes';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { filter, orderBy } from 'lodash';
import { CbDialogService } from '../../dialog/cb-dialog.service';
import { INotePermissions } from '@app/core/permissions/base.permissions';
import moment from 'moment';


@Component({
    selector: 'cb-notes-list',
    templateUrl: './notes-list.component.html',
    styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent implements OnChanges {

    @Input() public readonly permissions: INotePermissions;
    @Input() public notes: INoteMappedItem[];
    @Output() public notesChange = new EventEmitter<INoteMappedItem[]>();
    @Input() public readonly edit: boolean;
    @Input() public readonly entity: IBaseMappedItem<any, any, IBaseLogicService<any, any>> & INoteEntity<string | number>;
    @Input() public readonly notesLoaded: boolean;

    public canEdit = new ComputedProperty(() => this.permissions && this.permissions.canEditNote() && this.edit, false);
    public canDelete = new ComputedProperty(() => this.permissions && this.permissions.canDeleteNote() && this.edit, false);
    public noNotes = new ComputedProperty(() => this.notesLoaded && (!this.notes || this.notes.length < 1), false);

    constructor(
        public readonly cbDialog: CbDialogService,
    ) { }

    public ngOnChanges(): void {
        this.canEdit.recompute();
        this.canDelete.recompute();
        this.noNotes.recompute();
    }

    public getSortedNotes(notes: INoteMappedItem[]): INoteMappedItem[] {
        return orderBy(notes, ['isImportant', 'createdDate'], ['desc', 'desc']);
    }

    public editNote(note: INoteMappedItem): void {
        this.cbDialog.open(NoteDialogComponent, {
            data: {
                dialogHeading: 'Edit Note',
                note: note.$clone()
            },
            minWidth: 400
        });
    }

    public deleteNote(note: INoteMappedItem): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Note',
            message: 'Are you sure you want to delete this note?',
            confirmed: this.handleDeleteNote.bind(this, note)
        });
    }

    public relativeDate = (date: Date): string => {
        return moment(date).fromNow();
    };

    private readonly handleDeleteNote = (note: INoteMappedItem): void => {
        note.deleteNote()
            .subOnce(_note => {
                this.notes = filter(this.notes, note => note.id !== _note.id);
                this.notesChange.emit(this.notes);
            });
    };
}
