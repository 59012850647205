import { AfterViewChecked, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { ToastService } from '@app/core/services/toast/toast.service';
import { GenericQuestionsLogicService, IGenericQuestionMappedItem, IGenericQuestionsLogicService } from '@app/logic/generic-questions';
import { LotsLogicService } from '@app/logic/lots';
import { NonWorkingDayLogicService } from '@app/logic/non-working-day';
import { PricingComplexityLogicService } from '@app/logic/pricing-complexity';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { UsersLogicService } from '@app/logic/users';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { IDesignSchemeLineDto, IGenericQuestionDto, SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, combineLatest, Subject, map, mergeMap, skipWhile, startWith, switchMap } from 'rxjs';

@Component({
    selector: 'cb-manage-question-dialog',
    templateUrl: './manage-question-dialog.component.html',
    styleUrls: ['./manage-question-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageQuestionDialogComponent extends BaseDialogFormViewDirective<
IGenericQuestionDto,
IGenericQuestionMappedItem,
IGenericQuestionsLogicService> implements OnInit, OnDestroy, AfterViewChecked {
    public static readonly MIN_WIDTH = '500px';
    public childItems$ = new Subject<IDesignSchemeLineDto[]>();
    public set childItems(value: IDesignSchemeLineDto[]) {
        this.childItems$.next(value);
    }
    public childItemLabel: string;
    public childSystemArea: SystemAreaEnumId;
    public entityId: number;
    public lotId: number;
    public recipient = { name: '', id: '' };
    private _isFormInitialised = false;

    @ViewChild(NgForm) public formLiteral: NgForm;
    private _form$ = new BehaviorSubject<NgForm>(null);

    @ViewChild(NgForm) public set form(form: NgForm) {
        if ((form && Object.keys(form.form?.controls).length > 0)) {
            this._form$?.next(form);
        }
    }

    public recipientTeams$ = this._form$.asObservable().pipe(
        skipWhile(form => !form),
        switchMap(form => form.form.controls.recipient?.valueChanges.pipe(
            startWith(this.recipient)
        )),
        mergeMap(recipient => {
            if (recipient.id) {
                const user$ = this.usersLogicService.$getItem(this.recipient.id);
                return combineLatest([
                    user$,
                    this.teams$,
                ]).pipe(
                    map(([user, teams]) => {
                        const recipientTeams = teams.filter(team => user.selectedTeams.includes(team.id));
                        if (recipientTeams.length > 0 && !this.mappedItem.teamCode) {
                            this.mappedItem.teamCode = recipientTeams[0].key;
                        }
                        return recipientTeams;
                    })
                );
            }
        })
    );

    private _mappedItem$ = new BehaviorSubject<IGenericQuestionMappedItem>(null);

    public set mappedItem(mappedItem: IGenericQuestionMappedItem) {
        this.recipient.name = mappedItem.assignedToUserName;
        this.recipient.id = mappedItem.assignedToUserId;
        this._mappedItem$?.next(mappedItem);
    }

    public get mappedItem(): IGenericQuestionMappedItem {
        return this._mappedItem$?.value;
    }

    public teams$ = this.teamsLogicService.$getList();

    public currentUserTeams$ = this.teams$.pipe(
        map(
            teams => {
                const userTeams = teams.filter(
                    team => this.currentUser.teams.includes(team.key)
                );

                if (userTeams.length > 0 && this.mappedItem.createdByTeamCode) {
                    this.mappedItem.createdByTeamCode = userTeams[0].key;
                }

                return userTeams;
            }
        )
    );

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly usersLogicService: UsersLogicService,
        private readonly pricingComplexityLogicService: PricingComplexityLogicService,
        private readonly logicService: GenericQuestionsLogicService,
        private readonly lotLogicService: LotsLogicService,
        private readonly nonWorkingDaysLogicService: NonWorkingDayLogicService,
        public readonly toastSerivce: ToastService,
        public readonly currentUser: CurrentUserService,
        public readonly dialogRef: MatDialogRef<ManageQuestionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IGenericQuestionMappedItem> & {
            childItemLabel: string;
            childItems: IDesignSchemeLineDto[];
            childSystemArea: SystemAreaEnumId;
            systemArea: SystemAreaEnumId;
            entityId: number;
            lotId: number;
            mode: FormMode;
        },
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.childItems = data.childItems;
        this.childItemLabel = data.childItemLabel;
        this.childSystemArea = data.childSystemArea;
        this.entityId = data.entityId;
        this.lotId = data.lotId;
        this.formMode$.next(data.mode);
    }

    public ngAfterViewChecked(): void {
        if (Object.keys(this.formLiteral?.controls)
            .length > 0 && !this._isFormInitialised) {
            this.form = this.formLiteral;
            this._isFormInitialised = true;
        }
    }

    public save(): void {
        this.mappedItem.assignedToUserId = this.recipient.id;

        if (this.isAdd$$) {
            this.mappedItem.entityId = this.entityId;
            this.mappedItem.lotId = this.lotId;
            this.mappedItem.childSystemArea = this.childSystemArea;
        }

        super.save();
    }
}
