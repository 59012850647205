import { ClientSalePermissions } from '@app/core/permissions';
import { Component, Input } from '@angular/core';
import { ContractPricingDetailsEditDialogComponent } from '../../dialogs/contract-pricing-details-edit-dialog/contract-pricing-details-edit-dialog.component';
import { ChangeContractTypeDialogComponent } from '../../dialogs/change-contract-type-dialog/change-contract-type-dialog.component';
import { BaseClientSaleCardDirective } from '../base-client-sale-card';
import { ClientSaleDocumentsService } from '../../documents-service/client-sale-documents.service';
import { LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { DocumentTemplateLogicService } from '@app/logic/document-template';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Component({
    selector: 'cb-client-sale-contract-pricing-card',
    templateUrl: './contract-pricing-card.component.html',
    styleUrls: ['./contract-pricing-card.component.scss'],
    providers: [
        DocumentTemplateLogicService,
    ]
})
export class ClientSaleContractPricingCardComponent extends BaseClientSaleCardDirective {

    @Input() public readonly stepNumber: number;

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public get isComplete(): boolean {
        return this.clientSale?.stepCompletion?.contractPricing;
    }

    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissions: ClientSalePermissions,
        public readonly clientSaleDocuments: ClientSaleDocumentsService
    ) {
        super(clientSaleDocuments);
    }

    public openChangeContractTypeDialog(): void {
        if (!this.permissions.canChangeContractType()) {
            return;
        }
        this.cbDialog
            .open(ChangeContractTypeDialogComponent, {
                data: { clientSale: this.clientSale.$clone() },
                panelClass: 'cb-dialog-container',
                minWidth: 400
            })
            .afterClosed()
            .subOnce({ next: this.recomputeIconProps });
    }

    public openEditDialog(): void {
        if (!this.permissions.canEdit()) {
            return;
        }
        this.cbDialog
            .open(ContractPricingDetailsEditDialogComponent, {
                data: { clientSale: this.clientSale.$clone() },
                panelClass: 'cb-dialog-container',
                minWidth: 900
            })
            .afterClosed()
            .subOnce({ next: this.recomputeIconProps });
    }

}
