<cb-dialog class="flex-fill"
           [dialogHeading]="data.lotMappedItem.jobNumber + ' - Lot Specification'">
    <cb-lot-spec-view class="flex-fill"
                      [houseAreas]="data.houseAreas"
                      [lotMappedItem]="data.lotMappedItem"
                      [lotSpec]="data.lotSpec"
                      [fullscreen]="data.fullscreen"
                      [isSkinnyView]="data.isSkinnyView"
                      [lotSpecVersion]="data.lotSpecVersion">
    </cb-lot-spec-view>
</cb-dialog>
