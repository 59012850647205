import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { IDesignSchemesLogicService } from '@app/logic/design-schemes/interfaces/i.design-schemes-logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { DESIGN_SCHEME_STATUS_ENUM, IDesignSchemeDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    templateUrl: './reject-dialog.component.html',
    styleUrls: ['./reject-dialog.component.scss']
})
export class DesignSchemeRejectDialogComponent extends BaseDialogFormViewDirective<IDesignSchemeDto, IDesignSchemeMappedItem, IDesignSchemesLogicService> {
    public static readonly MIN_WIDTH = '80%';

    public readonly DESIGN_SCHEME_STATUS_ENUM = DESIGN_SCHEME_STATUS_ENUM;
    public reasonType = DESIGN_SCHEME_STATUS_ENUM.InQueueForRework;

    public get mappedItem(): IDesignSchemeMappedItem {
        return this.data.mappedItem;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<DesignSchemeRejectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IDesignSchemeMappedItem;
            hideRejectionOptions: boolean;
        },
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public isSaveDisabled(form: NgForm): boolean {
        return form.invalid || form.pristine;
    }

    public rejectDesignScheme(): void {
        if (this.reasonType === DESIGN_SCHEME_STATUS_ENUM.InQueueForRework) {
            this.mappedItem.statusId = DESIGN_SCHEME_STATUS_ENUM.InQueueForRework;
            this.mappedItem.failedReview = true;
        } else {
            this.mappedItem.statusId = DESIGN_SCHEME_STATUS_ENUM.Declined;
        }
        this.mappedItem.$save().subOnce(result => this.dialogRef.close(result));
    }
}
