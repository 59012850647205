<div class="flex-row">
    <cb-my-client-sales-side-panel class="cb-margin"
                                   *ngIf="isBuildingConsultant.value"
                                   [clientSaleUpdated]="clientSaleUpdated"
                                   [currentClientSaleId]="currentClientSaleId"
                                   (currentClientSaleIdChange)="currentClientSaleChange($event)">
    </cb-my-client-sales-side-panel>
    <cb-client-sale-view class="flex"
                         *ngIf="currentClientSaleId"
                         [clientSaleId]="currentClientSaleId"
                         [showTitle]="isBuildingConsultant.value"
                         (clientSaleUpdated)="clientSaleUpdated.next($event)">
    </cb-client-sale-view>
    <div class="cb-margin-2x  flex-col"
         *ngIf="!currentClientSaleId">
        <cb-info-message *ngIf="!loading"
                         message="No Client Sale has been selected"
                         iconClass="cb-grey"></cb-info-message>
        <cb-loading-spinner *ngIf="loading"></cb-loading-spinner>
    </div>
</div>
