<cb-dialog dialogHeading="{{dialogHeading}}">
    <form class="flex-col flex"
          #changeOptionForm="ngForm">
        <div class="cb-margin-bottom-2x flex-row flex-100">
            <div class="flex-col flex-50">
                <span class="cb-margin-bottom-2x">
                    <b>Change Option Details</b>
                </span>
                <cb-text-area label="Description - Appears as the Change Option description and Variation line description"
                              name="question"
                              [(ngModel)]="mappedItem.description"
                              [maxlength]="1024"
                              [required]="true"
                              [readonly]="getViewMode()"></cb-text-area>
                <cb-checkbox class="cb-margin-bottom"
                             style="height:25px"
                             name="clientApprovalRequired"
                             label="Client Approval is Required for this Change"
                             [(ngModel)]="mappedItem.clientApprovalRequired"
                             [disabled]="disableClientApprovalCheckbox() || getViewMode()">
                </cb-checkbox>
                <cb-checkbox class="cb-margin-bottom"
                             style="height:25px"
                             name="isActive"
                             label="Notify QS when this Change Option is Completed"
                             [(ngModel)]="mappedItem.notifyQsIfSelected"
                             [disabled]="getViewMode()">
                </cb-checkbox>
                <cb-checkbox class="cb-margin-bottom"
                             style="height:25px"
                             name="designChangesRequired"
                             label="Design Changes are Required"
                             [(ngModel)]="mappedItem.designChangesRequired"
                             [disabled]="getViewMode() || mappedItem.isSelectedChangeOption">
                </cb-checkbox>
                <div class="flex-col flex"
                     *ngIf="mappedItem.designChangesRequired">
                    <div class="flex-col"
                         *ngIf="!mappedItem.isSelectedChangeOption && !getViewMode()">
                        <div class="flex-col">
                            <cb-currency label="Design Change Cost"
                                         name="designChangeCost2"
                                         [(ngModel)]="mappedItem.designChangeCost"
                                         required="true"
                                         [disabled]="getViewMode()"
                                         [inclGst]="false"></cb-currency>
                        </div>
                    </div>
                    <cb-currency *ngIf="getViewMode()"
                                 label="Design Change Cost"
                                 name="designChangeCost1"
                                 [(ngModel)]="mappedItem.designChangeCost"
                                 required="true"
                                 [disabled]="getViewMode()"
                                 [inclGst]="false"></cb-currency>
                </div>
            </div>
            <div class="cb-margin-left-3x flex-col flex-50">
                <div class="flex-row start-center"
                     *ngIf="!mappedItem.isSelectedChangeOption && !isLotSpecItemReversal() && !getViewMode()">
                    <div class="flex-col flex">
                        <table class="flex">
                            <tr>
                                <td style="width: 105px">Lines Total</td>
                                <td colspan="2">
                                    <span> {{netChange | cbCurrency}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 105px">+ Other Costs</td>
                                <td style="width: 195px">
                                    <cb-input type="number"
                                              name="otherCosts"
                                              [(ngModel)]="mappedItem.otherCosts"
                                              required="true"
                                              [disabled]="getViewMode()">
                                    </cb-input>
                                </td>
                                <td>
                                    <span>
                                        <b>{{calculatedTotalPlusOther | cbCurrency}}</b>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 105px">Margin %</td>
                                <td style="width: 195px">
                                    <cb-input type="number"
                                              [(ngModel)]="mappedItem.marginPercentage"
                                              [readonly]="!marginIsEditable()"
                                              name="marginPercentage">
                                    </cb-input>
                                </td>
                                <td>
                                    <span>
                                        <b>{{calculatedMarginAmount | cbCurrency}}</b>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <mat-divider class="cb-margin-bottom-2x"></mat-divider>
                                </td>
                            </tr>
                            <tr style="height: 30px;">
                                <td colspan="2">Total (GST Exclusive)</td>
                                <td>
                                    <span class="flex">
                                        <b>{{calculatedTotalWithMargin || 0 | cbCurrency}} </b>
                                    </span>
                                </td>
                            </tr>
                            <tr style="height: 30px;">
                                <td colspan="2">Total (GST Inclusive)</td>
                                <td>
                                    <span class="flex">
                                        <b>
                                            {{calculatedTotalWithMargin || 0 | cbCurrency:'addGst'}}
                                            (Inc Gst)
                                        </b>
                                    </span>
                                </td>
                            </tr>
                            <tr style="height: 30px;">
                                <cb-currency *ngIf="isExtraToSupplier ||  isExtraToClient"
                                             label="Oncharge Amount"
                                             name="onCharge"
                                             type="number"
                                             [inclGst]="true"
                                             [(ngModel)]="mappedItem.onCharge"
                                             required="true"
                                             [readonly]="getViewMode()">
                                </cb-currency>
                            </tr>
                        </table>
                    </div>
                </div>
                <cb-currency *ngIf="!mappedItem.isSelectedChangeOption && isLotSpecItemReversal()"
                             label="Change Cost"
                             name="calculatedPriceForQuoteLines"
                             type="number"
                             [(ngModel)]="calculatedPriceForQuoteLines"
                             [readonly]="true">
                </cb-currency>
                <cb-currency *ngIf="getViewMode()"
                             label="Change Cost"
                             name="price"
                             type="number"
                             [(ngModel)]="mappedItem.price"
                             [readonly]="true">
                </cb-currency>
                <cb-display-value-new class="flex"
                                      label="Margin"
                                      *ngIf="getViewMode()">
                    <div class="flex-col">
                        <span>Percentage: {{mappedItem.marginPercentage}}% </span>
                        <span>Margin Amount:
                            {{calculatedMarginAmount | cbCurrency:'addGst'}}
                        </span>
                        <span>Change Cost (Incl Margin):
                            {{changeOptionPriceInclMargin | cbCurrency}}
                        </span>
                        <span *ngIf="isExtraToSupplier || isExtraToClient">
                            Oncharge Amount (Gst Inclusive):
                            {{mappedItem.onCharge | cbCurrency:'addGst'}}
                        </span>
                    </div>
                </cb-display-value-new>
            </div>
        </div>
        <span class="cb-margin-bottom-2x">
            <b>If this Option is Selected, specify the changes that need to be applied
                to the Lot Specification?</b>
        </span>
        <cb-schedule-items-add-credit-table *ngIf="lotMappedItem.hasScheduleSpecTemplate"
                                            [(changeOptionMappedItem)]="mappedItem"
                                            [(changeRecordMappedItem)]="changeRecordMappedItem"
                                            [(parentForm)]="changeOptionForm.form">
            <div netChange
                 [class.is-credit]="netChange < 0">
                <b>Lines Total {{netChange | cbCurrency}}</b>
            </div>
        </cb-schedule-items-add-credit-table>
        <cb-spec-items-add-credit-table *ngIf="!lotMappedItem.hasScheduleSpecTemplate"
                                        [(changeOptionMappedItem)]="mappedItem"
                                        [(changeRecordMappedItem)]="changeRecordMappedItem"
                                        [(parentForm)]="changeOptionForm.form">
            <div netChange
                 class="cb-margin-bottom-2x"
                 [class.is-credit]="netChange < 0">
                <b>Lines Total {{netChange | cbCurrency}}</b>
            </div>
        </cb-spec-items-add-credit-table>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="!changeOptionForm.valid || !changeOptionForm.dirty"
                (cbClick)="saveChangeOption()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
