import { Component, Input } from '@angular/core';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';

@Component({
    selector: 'cb-lot-build-reports-menu',
    templateUrl: './lot-build-programme-reports-menu.component.html',
    styleUrls: ['./lot-build-programme-reports-menu.component.scss']
})
export class LotBuildReportsMenuComponent {

    @Input() public readonly lot: ILotDto;

    constructor(
        private cbDialog: CbDialogService,
        private readonly buildProgrammeLogic: BuildProgrammeLogicService
    ) { }

    public generateJobBuildReport(): void {
        this.cbDialog
            .confirm({
                dialogHeading: 'Generate Job Build Report',
                message: 'Are you sure you want to generate a Job Build Report?',
                confirmed: () => this.buildProgrammeLogic.generateJobBuildReport(this.lot.id).subOnce()
            });
    }
}
