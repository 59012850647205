import { ClientSalePermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { LotDetailsEditDialogComponent } from './../../dialogs/lot-details-edit-dialog/lot-details-edit-dialog.component';
import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BaseClientSaleCardDirective } from '../base-client-sale-card';
import { LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { ClientSaleDocumentsService } from '../../documents-service/client-sale-documents.service';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Component({
    selector: 'cb-client-sale-lot-details-card',
    templateUrl: './lot-details-card.component.html',
    styleUrls: ['./lot-details-card.component.scss'],
    providers: [NavigationService]
})
export class ClientSaleLotDetailsCardComponent extends BaseClientSaleCardDirective {

    @Input() public readonly stepNumber: number;

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    public get isComplete(): boolean {
        return this.clientSale?.stepCompletion?.lotDetails;
    }

    constructor(
        public readonly matDialog: MatDialog,
        public readonly navigation: NavigationService,
        public readonly permissions: ClientSalePermissions,
        public readonly clientSaleDocuments: ClientSaleDocumentsService,
    ) {
        super(clientSaleDocuments);
    }

    public openEditDialog(): void {
        if (!this.permissions.canEdit()) {
            return;
        }
        this.matDialog
            .open(LotDetailsEditDialogComponent, {
                data: { clientSale: this.clientSale.$clone() },
                panelClass: 'cb-dialog-container',
                minWidth: 400
            })
            .afterClosed()
            .subOnce({ next: this.recomputeIconProps });
    }

    public viewLot(event: MouseEvent): void {
        this.navigation.navigate([`/lots/${this.clientSale.lot.id}/summary`], event);
    }
}
