import { CbInfoPopupModule } from 'cb-hub-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CbSortableHeaderCellModule } from './../sortable-header-cell/sortable-header-cell.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, UpperCasePipe } from '@angular/common';
import { DocumentComponent } from './components/document/document.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { DocumentInfoDialogComponent } from './components/document-info-dialog/document-info-dialog';
import { DocumentReviewDialogComponent } from './components/document-review-dialog/document-review-dialog';
import { DocumentUploadDialogComponent } from './components/document-upload-dialog/document-upload-dialog';
import { DocumentUploadComponent } from './components/document-upload/document-upload.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbLoadingSpinnerModule } from 'cb-hub-lib';
import { CbInfoMessageModule } from 'cb-hub-lib';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { CbDialogModule } from '../dialog/dialog.module';
import { BrowserModule } from '@angular/platform-browser';
import { CbDasherModule } from 'cb-hub-lib';
import { MatSortModule } from '@angular/material/sort';
import { DragAndDropDocumentComponent } from './components/drag-and-drop-document/drag-and-drop-document.component';
import { DragAndDropFileComponent } from './components/drag-and-drop-file/drag-and-drop-file.component';
import { CbHubTableModule } from '../table/table.module';
import { CbTableModule } from 'cb-hub-lib';
import { CdkTableModule } from '@angular/cdk/table';
import { DownloadMultipleDocumentsDialogComponent } from './components/download-multiple-documents-dialog/download-multiple-documents-dialog.component';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CbFormsModule } from '../forms/forms.module';
import { DocumentHistoryComponent } from './components/document-history/document-history.component';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { DocumentStubDialogComponent } from './components/document-stub-dialog/document-stub-dialog.component';
import {CbButtonModule} from '@app/shared/components/button/button.module';

const EXPORTS = [
    DocumentComponent,
    DocumentListComponent,
    DocumentInfoDialogComponent,
    DocumentReviewDialogComponent,
    DocumentUploadDialogComponent,
    DocumentUploadComponent,
    DragAndDropDocumentComponent,
    DragAndDropFileComponent,
    DocumentHistoryComponent,
];

const IMPORT_ONLY = [
    DragDropDirective,
    DownloadMultipleDocumentsDialogComponent,
];

@NgModule({
    declarations: [
        ...EXPORTS,
        ...IMPORT_ONLY,
        DocumentStubDialogComponent,
    ],
    exports: EXPORTS,
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        MatTooltipModule,
        MatMenuModule,
        MatIconModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatCardModule,
        CbSortableHeaderCellModule,
        MatSortModule,
        MatTableModule,
        MatListModule,
        MatRadioModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        CbInfoPopupModule,
        CbEnumToLabelModule,
        CbSortByModule,
        CbDialogModule,
        CbDasherModule,
        CbHubTableModule,
        CbTableModule,
        CdkTableModule,
        CbClickModule,
        MatProgressSpinnerModule,
        CbFormsModule,
        CbWaitForModule,
        CbTableModule,
        CbButtonModule
    ],
    providers: [
        DatePipe,
        UpperCasePipe,
    ]
})

export class CbDocumentsModule { }
