import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LotDesignPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotMappedItem } from '@app/logic/lots';
import { IPreConsentPlanMappedItem, PreConsentPlansLogicService } from '@app/logic/pre-consent-plans';
import { PreConsentsLogicService } from '@app/logic/pre-consents';
import { IPreConsentMappedItem } from '@app/logic/pre-consents/interfaces/i.pre-consent.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { DocumentService } from '@app/shared/components/documents/services/document.service';
import { RequestPreconsentDialogComponent } from '@app/views/lot/design/pre-consent/request-preconsent-dialog/request-preconsent-dialog.component';
import { LOT_JOB_STATUS_ENUM, PRECONSENT_PLAN_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, Subscription, mergeMap, of } from 'rxjs';

const LOT_SPEC_HAS_INCOMPLETE_ITEMS_MESSAGE = 'Lot spec has incomplete items';

@Component({
    selector: 'cb-pre-consent',
    templateUrl: './pre-consent.component.html',
    styleUrls: ['./pre-consent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreConsentComponent implements OnInit, OnDestroy {
    private _lotMappedItemSubscription: Subscription;
    private _preConsentMappedItemSubscription: Subscription;

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    public mappedItem$$: BehaviorSubject<IPreConsentMappedItem> = new BehaviorSubject(null);
    public selectedPreConsentPlan$$: BehaviorSubject<IPreConsentPlanMappedItem> = new BehaviorSubject(null);
    public canGeneratePreconsentValueFromApi: boolean;
    public lotSpecHasIncompleteItems: boolean;
    public preconsentBlockingReason: string;
    public get selectedPreConsentPlan(): IPreConsentPlanMappedItem {
        return this.selectedPreConsentPlan$$?.value;
    }

    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem && mappedItem !== this.lotMappedItem$$.value) {
            this.lotMappedItem$$.next(mappedItem);
            this._lotMappedItemSubscription?.unsubscribe();
            this._lotMappedItemSubscription = mappedItem.$updated.subscribe(() => {
                this._checkPreconsentId(mappedItem);
                this.cdRef.detectChanges();
            });
            this._checkPreconsentId(mappedItem);
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    @Output() public lotMappedItemChange = new EventEmitter<ILotMappedItem>();

    public set mappedItem(mappedItem: IPreConsentMappedItem) {
        this.mappedItem$$.next(mappedItem);
    }

    public get mappedItem(): IPreConsentMappedItem {
        return this.mappedItem$$.value;
    }

    constructor(
        private readonly preConsentsLogicService: PreConsentsLogicService,
        private readonly preConsentPlansLogicService: PreConsentPlansLogicService,
        private readonly lotDesignPermissions: LotDesignPermissions,
        private readonly cbDialog: CbDialogService,
        private readonly navigationService: NavigationService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly documentService: DocumentService
    ) { }

    public ngOnInit(): void {
        this._updateCanGeneratePreconsentFromApi();
        this.loadQueryParams();
    }

    private loadQueryParams(): void {
        const queryParams = this.navigationService.getQueryParams<{ paramEntityId?: string }>();
        if (queryParams?.paramEntityId) {
            this.loadPreConsentPlan(Number(queryParams.paramEntityId));
        }
    }

    private loadPreConsentPlan(preConsentPlanId: number): void {
        this.preConsentPlansLogicService
            .$getMappedItem(preConsentPlanId)
            .subOnce((preConsentPlanMappedItem) => {
                this.handleViewPreConsent(preConsentPlanMappedItem);
            });
    }

    private _checkPreconsentId(lotMappedItem: ILotMappedItem): void {
        if (lotMappedItem?.preConsentId > 0) {
            this.preConsentsLogicService.$getMappedItem(lotMappedItem.preConsentId).subOnce((result) => {
                if (result !== this.mappedItem$$.value) {
                    this.mappedItem$$.next(result);
                    this._preConsentMappedItemSubscription?.unsubscribe();
                    this._preConsentMappedItemSubscription = result.$updated.subscribe(() => {
                        this.cdRef.detectChanges();
                    });
                }
                this.cdRef.detectChanges();
            });
        }
    }

    private _updateCanGeneratePreconsentFromApi(): void {
        this.preConsentsLogicService.canGeneratePreConsent(this.lotMappedItem.id).pipe(
            mergeMap((canGeneratePreconsentFromApi) => {
                return of(this.canGeneratePreConsent() && canGeneratePreconsentFromApi);
            }))
            .subscribe(result => {
                this.canGeneratePreconsentValueFromApi = result.item1;
                const blockingReason = result.item2;
                this.setPreConsentBlockingReason(blockingReason);
                this.cdRef.detectChanges();
            });
    }

    private setPreConsentBlockingReason(additionalReasons: string): void{
        this.preconsentBlockingReason = additionalReasons;
        this.lotSpecHasIncompleteItems = this.preconsentBlockingReason === LOT_SPEC_HAS_INCOMPLETE_ITEMS_MESSAGE;

        if (this.canGeneratePreconsentValueFromApi){
            return;
        }

        if (this.lotSpecHasIncompleteItems)
        {
            /*
            Overwrite the blocking reason with a more extensive version of the error message
             */
            this.preconsentBlockingReason = 'There are items on your Lot Specification that are missing information. ' +
                'Please check all your Lot Spec Items have a Cost Type and Product Name/Description';
        }
    }

    public ngOnDestroy(): void {
        this._lotMappedItemSubscription?.unsubscribe();
        this._preConsentMappedItemSubscription?.unsubscribe();
    }

    public generatePreConsent(): void {
        const mappedItem = this.preConsentPlansLogicService.$createMappedItem({
            lotId: this.lotMappedItem.id
        });

        const dialogRef = this.cbDialog.open(RequestPreconsentDialogComponent, {
            data: {
                lotMappedItem: this.lotMappedItem,
                mappedItem
            },
        });

        dialogRef.afterClosed().subOnce((result) => {
            if (result) {
                this.lotMappedItem.$reload().subOnce();
            }
        });
    }

    public canGeneratePreConsent(): boolean {
        return !(this.lotMappedItem.preConsentId > 0) &&
            this.lotMappedItem.activeDesignConceptId > 0 &&
            this.lotDesignPermissions.canGeneratePreConsent();
    }

    public goToLotSpecification(): void {
        this.navigationService.navigate([`/lots/${this.lotMappedItem.id}/spec/lotspec`]);
    }

    public canCreatePreConsent(): boolean {
        return this.lotDesignPermissions.canManagePreconsentPlan();
    }

    public isCreatePreConsentDisabled(): boolean {
        return this.lotMappedItem.jobStatus === LOT_JOB_STATUS_ENUM.Cancelled
            || this.lotMappedItem.jobStatus === LOT_JOB_STATUS_ENUM.Handover
            || this.lotMappedItem.jobStatus === LOT_JOB_STATUS_ENUM.None
            || this.lotMappedItem.jobStatus === LOT_JOB_STATUS_ENUM.Scheme
            || !this.mappedItem
            || (this.mappedItem && !this.mappedItem.id)
            || this.hasActivePlan();
    }

    // has other than finalised, cancelled and completed.
    public hasActivePlan(): boolean {
        let returnValue = false;
        this.mappedItem.preConsentPlans.forEach(element => {

            if (element.preConsentPlanStatus.id !== PRECONSENT_PLAN_STATUS_ENUM.Finalised
                && element.preConsentPlanStatus.id !== PRECONSENT_PLAN_STATUS_ENUM.Cancelled
                && element.preConsentPlanStatus.id !== PRECONSENT_PLAN_STATUS_ENUM.Completed) {
                returnValue = true;
            }
        });

        return returnValue;
    }

    public createPreConsentPlan(): void {
        const mappedItem = this.preConsentPlansLogicService.$createMappedItem({
            lotId: this.lotMappedItem.id
        });

        const dialogRef = this.cbDialog.open(RequestPreconsentDialogComponent, {
            data: {
                lotMappedItem: this.lotMappedItem,
                mappedItem,
                preConsentId: this.mappedItem.id
            },
        });

        dialogRef.afterClosed().subOnce((result) => {
            if (result) {
                this.lotMappedItem.$reload().subOnce();
            }
        });
    }

    public notifyLotMappedItemChanged(): void {
        this.lotMappedItemChange.emit(this.lotMappedItem);
        this.mappedItem.$reload().subOnce(() => {
            this.documentService.refreshDocumentList.next(true);
        });
    }

    public handleViewPreConsent(event: IPreConsentPlanMappedItem): void {
        this.selectedPreConsentPlan$$.next(event);
    }

    public handleGoBack(): void {
        this.lotMappedItem.$reload().subOnce();
        this.selectedPreConsentPlan$$.next(null);
    }
}
