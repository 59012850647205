import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbEnumAsLookupPipe } from './enum-as-lookup.pipe';



@NgModule({
    declarations: [CbEnumAsLookupPipe],
    imports: [
        CommonModule
    ],
    exports: [
        CbEnumAsLookupPipe
    ]
})
export class CbEnumAsLookupModule { }
