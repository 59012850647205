import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { capitalize, filter, find } from 'lodash';
import { DocumentTemplateDialogComponent } from './document-template-dialog/document-template-dialog.component';
import { DocumentTemplateHistoryDialogComponent } from './document-template-history-dialog/document-template-history-dialog.component';
import {
    DocumentTemplateLogicService, DocumentTemplateMappedItem,
    IDocumentTemplateDto, IDocumentTemplateMappedItem, IDocumentTemplateTreeDto
} from '@app/logic/document-template';

@Component({
    selector: 'cb-document-templates',
    templateUrl: './document-templates.component.html',
    styleUrls: ['./document-templates.component.scss'],
})
export class DocumentTemplatesComponent extends BaseSimpleListViewDirective<IDocumentTemplateDto, DocumentTemplateLogicService> {
    public readonly displayedColumns: string[] = ['templateName', 'templateCode', 'fileName', 'dateUploaded', 'isActive', 'actions'];
    public documentTemplateTree: IDocumentTemplateTreeDto[];

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly documentTemplateLogicService: DocumentTemplateLogicService,
    ) {
        super(
            cbDialog,
            documentTemplateLogicService,
            DocumentTemplateDialogComponent,
            'Document Template',
            DocumentTemplateMappedItem,
            permissionsPermissions,
            false
        );
        this.documentTemplateLogicService.getTree().subOnce((result: IDocumentTemplateTreeDto[]) => {
            result.forEach((item: IDocumentTemplateTreeDto) => {
                item.label = capitalize(item.category);
                item.children = item.children.sort((a, b) => a.code.localeCompare(b.code));
            });
            this.documentTemplateTree = result;
            this.searchResultsLoaded = true;
        });
    }

    public getDocumentTemplateLabel(documentTemplate: IDocumentTemplateTreeDto): string {
        return `${documentTemplate.label} (${documentTemplate.children?.length ?? 0})`;
    }

    public openDialog(mappedItem: IDocumentTemplateMappedItem, dialogHeading: string): MatDialogRef<any> {
        return this.cbDialog.open(DocumentTemplateDialogComponent, {
            minWidth: '50%',
            data: {
                dialogHeading,
                mappedItem,
                documentTemplateTree: this.documentTemplateTree,
            } as any,
        });
    }

    public onUploadClicked(mappedItem: IDocumentTemplateMappedItem): MatDialogRef<any> {
        mappedItem = mappedItem instanceof DocumentTemplateMappedItem
            ? mappedItem.$clone()
            : this.logicService.$createMappedItem(mappedItem, DocumentTemplateMappedItem as any);
        const dialog = this.cbDialog.open(DocumentTemplateDialogComponent, {
            minWidth: '50%',
            data: {
                dialogHeading: 'Upload Template File',
                mappedItem,
                isUploadOnly: true,
                documentTemplateTree: this.documentTemplateTree,
            } as any,
        });
        dialog.afterClosed().subOnce((item: IDocumentTemplateMappedItem) => this.handleEditItem(item));
        return dialog;
    }

    public onDownloadClicked(documentTemplate: IDocumentTemplateDto): void {
        this.documentTemplateLogicService.downloadTemplate(documentTemplate.id).subOnce();
    }

    public onHistoryClicked(mappedItem: IDocumentTemplateDto): MatDialogRef<any> {
        return this.cbDialog.open(DocumentTemplateHistoryDialogComponent, {
            data: {
                dialogHeading: 'Template History',
                mappedItem,
            },
        });
    }

    protected handleNewItem(rawItem: IDocumentTemplateMappedItem): void {
        if (rawItem) {
            this.documentTemplateLogicService.$getItem(rawItem.id).subOnce((dbItem: IDocumentTemplateDto) => {
                const category = find(this.documentTemplateTree, { category: dbItem.category });
                if (category) {
                    category.children = [...category.children, dbItem];
                    category.children.sort((a, b) => a.code.localeCompare(b.code));
                }
            });
        }
    }

    public handleEditItem = (rawItem: IDocumentTemplateMappedItem): void => {
        if (rawItem) {
            this.documentTemplateLogicService.$getItem(rawItem.id).subOnce((dbItem: IDocumentTemplateDto) => {
                const category = find(this.documentTemplateTree, { category: dbItem.category });
                if (category) {
                    const match = find(category.children, { id: dbItem.id });
                    if (match) {
                        Object.assign(match, dbItem);
                    } else {
                        category.children = [...category.children, dbItem];
                        const needToCleanCategory = find(this.documentTemplateTree, node =>
                            find(node.children, item => item.id === rawItem.id && item.category !== rawItem.category));
                        if (needToCleanCategory) {
                            needToCleanCategory.children = filter(needToCleanCategory.children.slice(), item => item.id !== rawItem.id);
                        }
                    }
                    category.children.sort((a, b) => a.code.localeCompare(b.code));
                }
            });
        }
    };
}
