<div class="flex-col">
    <cb-dialog dialogHeading="Document Upload"></cb-dialog>
    <div class="document-container flex-col">
        <form class="flex-col flex"
              #documentUploadForm="ngForm">

            <!-- Show Only On Document Upload - NOT REUPLOAD -->
            <div *ngIf="!this.data.isDocumentReupload">
                <mat-form-field>
                    <mat-select [(value)]="this.selectedGroup"
                                placeholder="Document Group"
                                (selectionChange)="this.onGroupSelection($event.value)">
                        <mat-option *ngFor="let group of this.availableForUploadDocumentGroups"
                                    [value]="group">
                            {{ group.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="this.showTypeSelector">
                    <mat-select [(value)]="this.selectedType"
                                placeholder="Document Type"
                                (selectionChange)="this.onTypeSelection($event.value)">
                        <mat-option *ngFor="let type of this.availableForUploadDocumentTypes"
                                    [value]="type">
                            {{ type.documentType.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Show Only On Document Upload - NOT REUPLOAD -->
            <cb-document-upload [hidden]="!this.showDragDrop"
                                class="cb-margin-bottom-2x"
                                [documentEntityMappedItem]="this.data.documentEntityMappedItem">
            </cb-document-upload>
            <div *ngIf="displayDocumentReviewSection()">
                <cb-select label="Document Review"
                           [(ngModel)]="tempDocumentReviewDto.status"
                           name="reveiwStatus"
                           [required]="true"
                           [options]="documentReviewStatuses">
                </cb-select>
                <cb-input class="cb-padding-top"
                          name="rejectedReason"
                          label="Rejected Reason"
                          [required]="true"
                          *ngIf="tempDocumentReviewDto.status === DOCUMENT_REVIEW_STATUS_ENUM.Rejected"
                          [(ngModel)]="tempDocumentReviewDto.comments">
                </cb-input>
            </div>
        </form>
        <footer class="cb-margin-top-3x end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    class="save-button"
                    [disabled]="documentUploadForm.invalid"
                    (click)="this.onSubmit()">Upload</button>
            <button class="raised"
                    mat-raised-button
                    (click)="cancel()">Close</button>
        </footer>
    </div>
</div>
