import { CurrentUserService } from '@app/core/authentication/current.user';
import { BuildProgrammePermissions, LotBuildPermissions } from '@app/core/permissions';

export class LotBuildProgrammeActivityMenuPermissions {
    constructor(
        public readonly lotBuildPermissions: LotBuildPermissions,
        public readonly buildProgrammePermission: BuildProgrammePermissions,
        public readonly currentUser: CurrentUserService) { }

    public readonly canGenerateSSR = this.lotBuildPermissions?.canGenerateSSR();

    public readonly canEditSSR = this.lotBuildPermissions?.canEditSSR();

    public readonly canViewManualOrder = this.lotBuildPermissions
        ?.getManualPurchaseOrderPermissions()
        ?.canManageManualOrder();

    public readonly canAbandonManualOrder = this.lotBuildPermissions
        ?.getManualPurchaseOrderPermissions()
        ?.canManageManualOrder();

    public readonly canCancelSSR = this.lotBuildPermissions?.canCancelSSR();

    public readonly canCompleteSSR = this.lotBuildPermissions?.canCompleteSSR();

    public readonly canEditActivity = this.lotBuildPermissions?.canEditActivity();

    public readonly canDownloadPO = this.lotBuildPermissions?.canDownloadPO();

    public readonly canSetSupplier = this.lotBuildPermissions?.canSetSupplier();

    public readonly canDownloadSSR = this.lotBuildPermissions?.canDownloadSSR();

    public readonly canSplitSSR = this.lotBuildPermissions.canSplitSSR();

    public readonly canAbandonSSR = this.lotBuildPermissions.canAbandonSSR();

    public readonly canConfirmSSR = this.lotBuildPermissions.canConfirmSSR();

    public readonly canRedraftSSR = this.lotBuildPermissions.canEditSSR();

    public readonly canViewPoHistory = this.buildProgrammePermission.canView();
}
