import { Injectable, Injector } from '@angular/core';
import { LotContractTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IPaymentTemplateDto } from './interface/i.payment-template.dto';
import { IPaymentTemplateMappedItem } from './interface/i.payment-template.mapped';
import { IPaymentTemplatesLogicService } from './interface/i.payment-templates.logic.service';
import { PaymentTemplateMappedItem } from './payment-template.mapped';

@Injectable()
export class PaymentTemplatesLogicService
    extends BaseLogicService<IPaymentTemplateDto, IPaymentTemplateMappedItem>
    implements IPaymentTemplatesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'paymenttemplates',
            PaymentTemplateMappedItem,
        );
    }

    public getListByContractTypeAndBuildType(contractType: LotContractTypeEnumId, buildTypeId: number): Observable<IPaymentTemplateDto[]> {
        return this.$http
            .get<IPaymentTemplateDto[]>(`${this.$baseUri}/bycontracttype/${contractType}/bybuildtype/${buildTypeId}`);
    }

    public getDefaultByContractTypeAndBuildType(contractType: LotContractTypeEnumId, buildTypeId: number): Observable<IPaymentTemplateDto> {
        return this.$http
            .get<IPaymentTemplateDto>(`${this.$baseUri}/bycontracttype/${contractType}/bybuildtype/${buildTypeId}/default`);
    }
}
