import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { BuildProgrammeActivityLogicService } from '@app/logic/build-programme-activity/build-programme-activity-logic.service';
import { IBuildProgrammeActivityDto } from '@app/logic/build-programme-activity/interfaces/i.build-programme-activity.dto';
import { IBuildProgrammeActivityMappedItem } from '@app/logic/build-programme-activity/interfaces/i.build-programme-activity.mapped';
import {
    IBuildProgrammeActivitySummaryDto,
    IBuildProgrammeIndexedDto,
    IBuildProgrammeStageIndexedDto
} from '@app/logic/build-programme/interfaces/i.build-programme-indexed.dto';
import { BuildStagesLogicService, IBuildStageDto } from '@app/logic/build-stages';
import { IPromised, toPromisedArray } from 'cb-hub-lib';
import { BehaviorSubject, filter, map, Subscription } from 'rxjs';
import { LotBuildProgrammeEvents } from './lot-build-programme-events';
import { LotBuildProgrammeNgJsService } from './lot-build-programme-ngjs.service';

@Injectable({
    providedIn: 'root'
})
export class LotBuildProgrammeEventService extends LotBuildProgrammeEvents implements OnDestroy {
    /** Set to true once build programme has been received */
    public resolved$$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    /** Non-preconsent Active Build Stages - not Build Programme Stages */
    public buildStages: IPromised<IBuildStageDto[]>;
    /** All (incl Non-preconsent) Active Build Stages - not Build Programme Stages */
    public allbuildStages: IBuildStageDto[];
    public lotId: number;
    /** Current Build Programme Loaded */
    public buildProgramme: IBuildProgrammeIndexedDto;
    public hasSummaries = false;

    public stages: IBuildProgrammeStageIndexedDto[] = [];
    /** indexed by BuildProgrammeStage.buildStageId */
    public buildStageIndex: { [buildStageId: number]: IBuildProgrammeStageIndexedDto } = {};

    /** indexed by BuildProgrammeStage.buildStageId */
    public activities: { [buildStageId: number]: IBuildProgrammeActivityMappedItem[] } = {};
    /** indexed by BuildStage.Id */
    public summaries: { [buildStageId: number]: IBuildProgrammeActivitySummaryDto[] } = {};
    /** indexed by BuildStage.Id then BuildProgrammeActivity.Activity.Id */
    public summaryActivties: { [buildStageId: number]: { [buildActivityId: number]: IBuildProgrammeActivityMappedItem[] } } = {};
    /** indexed by BuildStage.Id then BuildProgrammeActivity.Activity.Id then BuildProgrammeActivity.Id */
    public summaryActivtiesIndex: { [buildStageId: number]: { [buildActivityId: number]: { [buildProgrammeActivityId: number]: IBuildProgrammeActivityMappedItem } } } = {};
    /** indexed by BuildProgrammeActivity.Id */
    public activityIndex: { [activityId: number]: IBuildProgrammeActivityMappedItem } = {};

    private subs$ = new Subscription();

    constructor(
        private readonly buildStageLogic: BuildStagesLogicService,
        private readonly buildProgrammeLogic: BuildProgrammeLogicService,
        private readonly buildProgrammeActivityLogic: BuildProgrammeActivityLogicService,
        private readonly lotBuildProgrammeNgJsService: LotBuildProgrammeNgJsService,
        private readonly ngZone: NgZone,
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.subs$.unsubscribe();
    }

    /** Clears all data in this service - due to ngOnDestroy not being triggered because this service is provided in root for AngularJs old build programme */
    public manualDestroy(): void {
        this.resetService();
        this.ngOnDestroy();
        this.subs$ = null;
    }

    private resetService(): void {
        this.resolved$$.next(false);
        this.lotId = null;
        this.resetBuildProgrammeData();
        this.buildStages = null;
        this.allbuildStages = null;
    }

    private resetBuildProgrammeData(): void {
        this.buildProgramme = null;
        this.stages = [];
        this.activities = {};
        this.activityIndex = {};
        this.summaries = {};
        this.summaryActivties = {};
        this.summaryActivtiesIndex = {};
    }

    /** Initialise the service and loads the Lot Build Programme */
    public initLoad(lotId: number): void {
        this.resetService();
        this.lotId = lotId;
        this.buildStages = toPromisedArray(
            this.buildStageLogic
                .getAllNonPreConsentStageBuildStages()
                .pipe(
                    map(x => x.filter(y => y.isActive))
                )
        );
        this.allbuildStages = toPromisedArray(
            this.buildStageLogic
                .$getList()
                .pipe(
                    map(x => x.filter(y => y.isActive))
                )
        );
        this.setupSubscriptions();
        this.loadBuildProgramme();
    }

    /** Refresh/reload the Build Programme for the current Lot */
    public reload(): void {
        // load build programme using this.lotId
        this.buildProgrammeLogic
            .getIndexedBuildProgrammeByLotId(this.lotId).subOnce((result) => {
                // clear current build programme data
                this.resetBuildProgrammeData();
                // receive new build programme data
                this.BP_RECEIVED.next(result);
            });
    }

    /** Loads the Build Programme for the current Lot */
    public loadBuildProgramme(): void {
        // load build programme using this.lotId
        this.buildProgrammeLogic
            .getIndexedBuildProgrammeByLotId(this.lotId).subOnce((result) => {
                this.BP_RECEIVED.next(result);
            });
    }

    /** Remove activity from this build programme.
     *
     * @returns true if successfully removed. false if unsuccessful.
     */
    public removeBuildProgrammeActivity(buildProgrammeActivityId: number): boolean {
        const buildProgrammeActivity = this.activityIndex[buildProgrammeActivityId];
        if (!buildProgrammeActivity?.buildStageId) {
            return false;
        }
        const index = this.activities[buildProgrammeActivity.buildStageId]?.findIndex(x => x.id === buildProgrammeActivityId);
        if (index > -1) {
            this.activities[buildProgrammeActivity.buildStageId].splice(index, 1);
        }
        delete this.activityIndex[buildProgrammeActivityId];
        this.removeBuildProgrammeActivityFromSummary(buildProgrammeActivity);
        this.STAGE_REQUIRES_UPDATE.next(buildProgrammeActivity.buildStageId);
        return true;
    }

    private removeBuildProgrammeActivityFromSummary(buildProgrammeActivity: IBuildProgrammeActivityMappedItem): void {
        const summaryActivityIndex = this.getSummaryActivity(buildProgrammeActivity.buildStageId, buildProgrammeActivity.activity.id)
            ?.findIndex(x => x.id === buildProgrammeActivity.id);
        if (summaryActivityIndex > -1) {
            this.getSummaryActivity(buildProgrammeActivity.buildStageId, buildProgrammeActivity.activity.id).splice(summaryActivityIndex, 1);
        }
        delete this.getSummaryActivitIndex(buildProgrammeActivity.buildStageId, buildProgrammeActivity.activity.id)[buildProgrammeActivity.id];
    }

    private getSummaryActivity(buildStageId: number, buildActivityId: number): IBuildProgrammeActivityMappedItem[] {
        return (this.summaryActivties[buildStageId]
            && this.summaryActivties[buildStageId][buildActivityId]) ?? [];
    }

    private getSummaryActivitIndex(buildStageId: number, buildActivityId: number): { [buildProgrammeActivityId: number]: IBuildProgrammeActivityMappedItem } {
        return (this.summaryActivtiesIndex[buildStageId]
            && this.summaryActivtiesIndex[buildStageId][buildActivityId]) ?? {};
    }

    private setupSubscriptions(): void {
        this.subs$?.unsubscribe();
        this.subs$ = new Subscription();
        this.subToRefreshBuildProgramme();
        this.subToBuildProgrammeReceived();
        this.subToStageReceived();
        this.subToActivityReceived();
        this.subToActivitySummaryReceived();
    }

    private subToRefreshBuildProgramme(): void {
        this.subs$.add(
            this.lotBuildProgrammeNgJsService.REFRESH_BUILD_PROGRAMME.subscribe(() => {
                this.reload();
            })
        );
    }

    private subToBuildProgrammeReceived(): void {
        this.subs$.add(
            this.BP_RECEIVED
                .pipe(filter(x => x?.lotId === this.lotId))
                .subscribe((buildProgramme: IBuildProgrammeIndexedDto) => {
                    this.buildProgramme = buildProgramme;
                    this.hasSummaries = this.buildProgramme.buildProgrammeActivitySummaries?.length > 0;

                    this.STAGE_RECEIVED.next(Object.values(this.buildProgramme.stages));
                    this.ACTIVITY_RECEIVED.next(Object.values(this.buildProgramme.buildProgrammeActivities));
                    this.SUMMARY_RECEIVED.next(this.buildProgramme.buildProgrammeActivitySummaries);
                    // redefine stages and buildProgrammeActivities as getters
                    delete this.buildProgramme.stages;
                    delete this.buildProgramme.buildProgrammeActivities;
                    Object.defineProperty(this.buildProgramme, 'stages', {
                        get: () => {
                            return this.buildStageIndex;
                        }
                    });
                    Object.defineProperty(this.buildProgramme, 'buildProgrammeActivities', {
                        get: () => {
                            return this.activityIndex;
                        }
                    });
                    this.ngZone.run(() => {
                        setTimeout(() => {
                            this.resolved$$.next(true);
                        });
                    });
                })
        );
    }

    private subToStageReceived(): void {
        this.subs$.add(
            this.STAGE_RECEIVED
                .pipe(map(x => x.filter(y =>
                    y.buildProgrammeId === this.buildProgramme?.id
                    || y.buildProgrammeId === this.buildProgramme?.parentBuildProgrammeId
                )))
                .subscribe(this.handleStageReceived)
        );
    }

    private readonly handleStageReceived = (stages: IBuildProgrammeStageIndexedDto[]): void => {
        stages.forEach(stage => {
            this.buildStageIndex[stage.buildStageId] = stage;
            if (!this.activities[stage.buildStageId]) {
                this.activities[stage.buildStageId] = [];
            }
        });
        this.stages = Object.values(this.buildStageIndex);
    };

    private subToActivityReceived(): void {
        this.subs$.add(
            this.ACTIVITY_RECEIVED
                .pipe(
                    map(x => x.filter(y =>
                        y != null
                        && (y.buildProgrammeId === this.buildProgramme?.id
                            || y.parentBuildProgrammeId === this.buildProgramme?.id
                            || y.buildProgrammeId === this.buildProgramme?.parentBuildProgrammeId
                        )
                    ))
                )
                .subscribe((activities: IBuildProgrammeActivityDto[]) => {
                    const updatedStageIds = new Set<number>();
                    activities.forEach(activity => {
                        this.indexActivity(activity);
                        this.indexActivityStage(activity);
                        updatedStageIds.add(activity.buildStageId);
                    });
                    updatedStageIds.forEach(id => {
                        this.STAGE_REQUIRES_UPDATE.next(id);
                        this.activities[id].sort((a, b) => a.sortOrder - b.sortOrder);
                    });
                })
        );
    }

    private subToActivitySummaryReceived(): void {
        this.subs$.add(
            this.SUMMARY_RECEIVED
                .pipe(map(x => x?.filter(y =>
                    this.hasSummaries
                    && (
                        y.buildProgrammeId === this.buildProgramme?.id
                        || y.buildProgrammeId === this.buildProgramme?.parentBuildProgrammeId
                    )
                )))
                .subscribe((summaries: IBuildProgrammeActivitySummaryDto[]) => {
                    if (!summaries) {
                        return;
                    }
                    summaries.forEach(x => {
                        if (!this.summaries[x.buildStageId]) {
                            this.summaries[x.buildStageId] = [];
                        }
                        this.summaries[x.buildStageId].push(x);
                        this.summaries[x.buildStageId].sort((x, y) => x.sortOrder - y.sortOrder);
                    });
                    Object.keys(this.summaries).forEach(buildStageId => {
                        if (!this.summaryActivties[buildStageId]) {
                            this.summaryActivties[buildStageId] = {};
                        }
                        Object.values(this.summaries[buildStageId]).forEach((summary: IBuildProgrammeActivitySummaryDto) => {
                            if (!this.summaryActivties[buildStageId][summary.buildActivityId]) {
                                this.summaryActivties[buildStageId][summary.buildActivityId] = [];
                            }
                        });
                    });
                    this.indexSummaryActivities();
                })
        );
    }

    /** Updates or Adds a Build Programme Activity in the activityIndex */
    private indexActivity(activity: IBuildProgrammeActivityDto): void {
        if (this.activityIndex[activity.id]) {
            this.activityIndex[activity.id].$updateThisAndOriginal(activity);
        } else {
            this.activityIndex[activity.id] = this.buildProgrammeActivityLogic.$createMappedItem(activity, undefined, this);
            this.subs$.add(
                this.activityIndex[activity.id].CHANGED.subscribe((result) => {
                    this.STAGE_REQUIRES_UPDATE.next(result.buildStageId);
                })
            );
        }
        this.indexSummaryActivity(activity);
    }

    /** Updates or Adds a Build Programme Acivity to it's respective buildStageId Activity Index */
    private indexActivityStage(activity: IBuildProgrammeActivityDto): void {
        this.initStageForActivity(activity);
        if (this.activities[activity.buildStageId] == null) {
            this.activities[activity.buildStageId] = [];
        }
        const index = this.activities[activity.buildStageId].findIndex(x => x.id === activity.id);
        if (index > -1) {
            this.activities[activity.buildStageId][index] = this.activityIndex[activity.id];
        } else {
            this.activities[activity.buildStageId].push(this.activityIndex[activity.id]);
        }
        this.activities[activity.buildStageId].sort((x, y) => x.sortOrder - y.sortOrder);
    }

    /** Updates or Adds a Build Programme Acivity to it's respective Build Activity Summary Index */
    private indexSummaryActivity(activity: IBuildProgrammeActivityDto): void {
        if (!this.hasSummaries) {
            return;
        }
        this.addActivityToSummaryIndex(activity);
        this.calcSummaryDates(activity.buildStageId, Number(activity.activity.id));
    }

    /** Updates or Adds a Build Programme Acivity to it's respective Build Activity Summary Index */
    private indexSummaryActivities(): void {
        if (!this.hasSummaries) {
            return;
        }
        Object.keys(this.activities).forEach(buildStageId => {
            this.summaryActivtiesIndex[buildStageId] = {};
            this.activities[buildStageId].forEach(this.addActivityToSummaryIndex);
        });
        this.calcAllSummaryDates();
    }

    /** Updates or Adds a Build Programme Acivity to it's respective Build Activity Summary Index */
    private readonly addActivityToSummaryIndex = (activity: IBuildProgrammeActivityDto): void => {
        if (!this.summaryActivtiesIndex[activity.buildStageId]) {
            this.summaryActivtiesIndex[activity.buildStageId] = {};
            this.summaryActivties[activity.buildStageId] = {};
        }
        if (!this.summaryActivtiesIndex[activity.buildStageId][activity.activity.id]) {
            this.summaryActivtiesIndex[activity.buildStageId][activity.activity.id] = {};
        }
        this.summaryActivtiesIndex[activity.buildStageId][activity.activity.id][activity.id] = this.activityIndex[activity.id];
        const arr = Object.values(this.summaryActivtiesIndex[activity.buildStageId][activity.activity.id]);
        this.summaryActivties[activity.buildStageId][activity.activity.id] = [
            // put block activties first
            ...arr
                .filter(x => x.lotId === this.lotId)
                // order by activity start date
                .sort(this.compareActivityStartDates),
            // put unit activties after block activties
            ...arr
                .filter(x => x.lotId !== this.lotId)
                .sort((a, b) =>
                    // order by unit construction order
                    a.unitConstructionOrder - b.unitConstructionOrder
                    // then by activity start date
                    || this.compareActivityStartDates(a, b)
                ),
        ];
    };

    private readonly compareActivityStartDates = (a: IBuildProgrammeActivityDto, b: IBuildProgrammeActivityDto): number => {
        return Number(new Date(a.actualStartDate ?? a.estimatedStartDate)) - Number(new Date(b.actualStartDate ?? b.estimatedStartDate));
    };

    public calcAllSummaryDates(): void {
        if (!this.hasSummaries) {
            return;
        }
        Object.keys(this.summaryActivtiesIndex).forEach(buildStageId => {
            Object.keys(this.summaryActivtiesIndex[buildStageId]).forEach(buildActivityId => {
                this.calcSummaryDates(Number(buildStageId), Number(buildActivityId));
            });
        });
    }

    private calcSummaryDates(buildStageId: number, buildActivityId: number): void {
        if (!this.summaries[buildStageId]) {
            return;
        }
        const summary = this.summaries[buildStageId].find(summary => summary.buildActivityId === buildActivityId);
        if (!summary) {
            return;
        }
        const activities = this.summaryActivties[buildStageId][buildActivityId];
        const startDates = activities.map(x => Number(new Date(x.actualStartDate ?? x.estimatedStartDate)));
        const endDates = activities.map(x => Number(new Date(x.actualEndDate ?? x.estimatedEndDate)));
        summary.startDate = new Date(Math.min(...startDates)).toJSON();
        summary.endDate = new Date(Math.max(...endDates)).toJSON();
    }

    /** Initialises a Build Programme Stage for the `activity` if the stage doesn't exist in the index */
    private initStageForActivity(activity: IBuildProgrammeActivityDto): void {
        if (this.buildStageIndex[activity.buildStageId] != null) {
            return;
        }
        const buildStage = this.allbuildStages.find(x => x.id === activity.buildStageId);
        if (!buildStage) {
            return;
        }
        const newBuildProgrammeStage: IBuildProgrammeStageIndexedDto = {
            buildProgrammeActivities: [],
            buildStageCode: buildStage.code,
            buildStageId: activity.buildStageId,
            buildStageLabel: buildStage.label,
            buildStageSortOrder: buildStage.sortOrder,
            id: activity.buildProgrammeStageId,
            sortOrder: 0,
            buildProgrammeId: this.buildProgramme?.id,
        };
        this.handleStageReceived([newBuildProgrammeStage]);
    }
}

