import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDesignConceptMappedItem } from '@app/logic/design-concepts';
import { DESIGN_CONCEPT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-design-concept-card',
    templateUrl: './design-concept-card.component.html',
    styleUrls: ['./design-concept-card.component.scss']
})
export class DesignConceptCardComponent {

    public DESIGN_CONCEPT_STATUS_ENUM = DESIGN_CONCEPT_STATUS_ENUM;

    @Input() public designConcept: IDesignConceptMappedItem;
    @Output() public readonly conceptSelected = new EventEmitter<IDesignConceptMappedItem>();

    constructor() { }

    public getStatusIconIcon(): string {
        switch (this.designConcept.statusId) {
            case DESIGN_CONCEPT_STATUS_ENUM.Available:
                return 'check_circle';
            case DESIGN_CONCEPT_STATUS_ENUM.Pending:
                return 'timelapse';
            case DESIGN_CONCEPT_STATUS_ENUM.Abandoned:
                return 'delete_forever';
            case DESIGN_CONCEPT_STATUS_ENUM.Applied:
                return 'style';
            case DESIGN_CONCEPT_STATUS_ENUM.Unavailable:
                return 'indeterminate_check_box';
            default:
                return 'timelapse';
        }
    }
    public getStatusIconClass(): string {
        switch (this.designConcept.statusId) {
            case DESIGN_CONCEPT_STATUS_ENUM.Available:
            case DESIGN_CONCEPT_STATUS_ENUM.Applied:
                return 'mat-primary';
            case DESIGN_CONCEPT_STATUS_ENUM.Unavailable:
            case DESIGN_CONCEPT_STATUS_ENUM.Pending:
                return 'mat-accent';
            case DESIGN_CONCEPT_STATUS_ENUM.Abandoned:
                return 'cb-error';
            default:
                return 'mat-accent';
        }
    }
    public getStatusIconTitle(): string {
        switch (this.designConcept.statusId) {
            case DESIGN_CONCEPT_STATUS_ENUM.Available:
                return 'Available';
            case DESIGN_CONCEPT_STATUS_ENUM.Unavailable:
                return 'Unavailable';
            case DESIGN_CONCEPT_STATUS_ENUM.Applied:
                return 'Applied';
            case DESIGN_CONCEPT_STATUS_ENUM.Abandoned:
                return 'Abandoned';
            case DESIGN_CONCEPT_STATUS_ENUM.Pending:
                return 'Pending';
            default:
                return 'none';
        }
    }

    public viewDesignConcept(): void {
        this.conceptSelected.emit(this.designConcept);
    }

}
