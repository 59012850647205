import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'cb-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements AfterContentInit {

    @Input() public readonly images: string[];
    @Input() public selectedIndex = 0;
    @Input() public selectedImage: string;
    @ViewChild('imageThumbs') public $element: ElementRef;

    public ngAfterContentInit(): void {
        this.selectImage(0);
    }

    public selectImage(index: number): void {
        this.selectedIndex = index;
        this.selectedImage = this.images[this.selectedIndex];
        const imageThumb = this.$element?.nativeElement.querySelectorAll(`.image-thumb-${index}`)[0];
        if (imageThumb) {
            const topPos = imageThumb.offsetLeft;
            this.$element.nativeElement.querySelectorAll('.scroller')[0].scrollLeft = topPos - 60;
        }
    }

    public nextImage(): void {
        this.selectedIndex++;
        if (this.selectedIndex >= this.images.length) {
            this.selectedIndex = 0;
        }
        this.selectImage(this.selectedIndex);
    }

    public previousImage(): void {
        this.selectedIndex--;
        if (this.selectedIndex < 0) {
            this.selectedIndex = this.images.length - 1;
        }
        this.selectImage(this.selectedIndex);
    }
}
