import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatMenuModule } from '@angular/material/menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbModelDebounceModule } from '@app/shared/directives/model-debounce/model-debounce.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import {
    CompleteMultipleActivitiesDialogComponent
} from '@app/views/teams/all-teams/incomplete-build-activities/complete-multiple-activities-dialog/complete-multiple-activities-dialog.component';
import { CbDasherModule, CbLoadingSpinnerModule, CbSelectListModule, CbTableModule } from 'cb-hub-lib';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IncompleteBuildActivitiesComponent } from './incomplete-build-activities.component';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CbClickModule} from '@app/shared/directives/click/click.module';

@NgModule({
    declarations: [
        IncompleteBuildActivitiesComponent,
        CompleteMultipleActivitiesDialogComponent
    ],
    imports: [
        CbFormsModule,
        CbLoadingSpinnerModule,
        CbSelectListModule,
        CommonModule,
        FormsModule,
        InfiniteScrollModule,
        MatButtonModule,
        MatCardModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatTableModule,
        ReactiveFormsModule,
        CbDasherModule,
        CbHubTableModule,
        CbTableModule,
        MatMenuModule,
        CbModelDebounceModule,
        CbWaitForModule,
        MatTooltipModule,
        CbDialogModule,
        MatProgressSpinnerModule,
        CbClickModule
    ],
    exports: [
        IncompleteBuildActivitiesComponent,
        CompleteMultipleActivitiesDialogComponent
    ]
})
export class IncompleteBuildActivitiesModule { }
