import * as _ from 'lodash';

import { Injectable, Injector } from '@angular/core';
import { IDocumentGroupFrontEndDto, IDocumentGroupTypeDto } from './interfaces/i.document-group.dto';
import { IDocumentEntityDto, IDocumentReviewDto, IDocumentUploadDto } from './interfaces/i.document.dto';

import { HttpResponse } from '@angular/common/http';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import {DocumentGroupCodesConstId, IDocumentDto, IDocumentTypeDto, SystemAreaEnumId} from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { DocumentEntityMappedItem } from './document.mapped';
import { IDocumentsLogicService } from './interfaces/i.document.logic.service';
import { IDocumentEntityMappedItem } from './interfaces/i.document.mapped';

@Injectable()
export class DocumentsLogicService
    extends BaseLogicService<IDocumentEntityDto, IDocumentEntityMappedItem>
    implements IDocumentsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        public readonly currentUser: CurrentUserService,
    ) {
        super(
            'documents',
            DocumentEntityMappedItem
        );
    }

    public createMappedDocumentEntityItem<DtoType extends IDocumentEntityDto>(
        entityUri: string,
        entityId: number | string,
        document = {} as IDocumentEntityDto,
        dto?: DtoType
    ): IDocumentEntityMappedItem {
        const result = new DocumentEntityMappedItem(document, this, dto);
        result.entityUri = entityUri;
        result.entityId = entityId;
        return result;
    }

    public getMappedDocumentEntities<DtoType extends IDocumentEntityDto>(
        entityUri: string,
        entityId: number | string,
        includeLinked: boolean,
        includeDeleted = true,
        dto?: DtoType,
        groupCodeName?: string,
    ): Observable<IDocumentEntityMappedItem[]> {
        let uri = `${entityUri}/${entityId}/documents?includeDeleted=${includeDeleted}&includeLinked=${includeLinked}`;
        if (!isNullOrWhiteSpace(groupCodeName)) {
            uri = `${uri}&groupCodeName=${groupCodeName}`;
        }
        return this.$http.get<IDocumentEntityDto[]>(uri)
            .pipe(
                map(results => results.map(value => this.createMappedDocumentEntityItem(entityUri, entityId, value, dto)))
            );
    }

    public saveDocumentEntity(documentEntity: IDocumentEntityDto): Observable<IDocumentEntityDto> {

        const documentUpdateDto = {  // : IDocumentUpdateDto
            name: documentEntity.document.name,
            description: documentEntity.document.description
        };

        if (documentEntity.id > 0) {
            return this.$http.post(`${this.$baseUri}/${documentEntity.id}`, documentUpdateDto);
        }
        return this.$http.post(`${this.$baseUri}/${documentEntity.id}`, documentUpdateDto);
    }

    public getDocumentsInGroupCode(
        entityUri: string,
        entityId: string | number,
        groupCodeName: DocumentGroupCodesConstId,
        includeLinked = false
    ): Observable<IDocumentEntityMappedItem[]> {
        return this.$http.get<IDocumentEntityDto[]>(`${entityUri}/${entityId}/documents?groupCodeName=${groupCodeName}&includeLinked=${includeLinked}`)
            .pipe(
                map(results => results.map(value => this.createMappedDocumentEntityItem(entityUri, entityId, value)))
            );
    }

    public reUploadDocumentEntity(uploadedDocument: IDocumentUploadDto): Observable<IDocumentEntityDto> {

        const formData = new FormData();
        formData.append('file', uploadedDocument.file);
        formData.append('documentGroupId', uploadedDocument.documentGroupId as any);
        formData.append('documentTypeId', uploadedDocument.documentTypeId as any);
        formData.append('documentEntityId', uploadedDocument.documentEntityId as any);
        formData.append('name', uploadedDocument.name);

        if (!isNullOrWhiteSpace(uploadedDocument.description)) {
            formData.append('description', uploadedDocument.description);
        }
        if (uploadedDocument.renewalDate) {
            formData.append('renewalDate', uploadedDocument.renewalDate);
        }
        if (uploadedDocument?.reviewStatus !== null
            && uploadedDocument?.reviewStatus !== undefined) {
            formData.append('reviewStatus', uploadedDocument?.reviewStatus as any);
        }
        if (uploadedDocument?.reviewComments !== null
            && uploadedDocument?.reviewComments !== undefined) {
            formData.append('reviewComments', uploadedDocument?.reviewComments as any);
        }

        return this.$http
            .post(`${this.$baseUri}/${uploadedDocument.documentEntityId}/upload`, formData);

    }

    public uploadDocumentEntity(entityUri: string, entityId: number | string, uploadedDocument: IDocumentUploadDto): Observable<IDocumentEntityDto> {

        const formData = new FormData();
        formData.append('file', uploadedDocument.file);
        formData.append('documentGroupId', uploadedDocument.documentGroupId as any);
        formData.append('documentTypeId', uploadedDocument.documentTypeId as any);
        formData.append('name', uploadedDocument.name);

        if (!isNullOrWhiteSpace(uploadedDocument.description)) {
            formData.append('description', uploadedDocument.description);
        }
        if (uploadedDocument.renewalDate) {
            formData.append('renewalDate', uploadedDocument.renewalDate);
        }
        if (uploadedDocument?.reviewStatus !== null
            && uploadedDocument?.reviewStatus !== undefined) {
            formData.append('reviewStatus', uploadedDocument?.reviewStatus as any);
        }
        if (uploadedDocument?.reviewComments !== null
            && uploadedDocument?.reviewComments !== undefined) {
            formData.append('reviewComments', uploadedDocument?.reviewComments as any);
        }
        return this.$http
            .post(`${entityUri}/${entityId}/documents`, formData);

    }

    public deleteDocumentEntity(documentEntityId: number): Observable<IDocumentEntityDto> {
        return this.$http.delete(`${this.$baseUri}/${documentEntityId}`);
    }

    public deleteDocumentStub(documentEntityId: number): Observable<IDocumentEntityDto> {
        return this.$http.delete(`${this.$baseUri}/${documentEntityId}/stub`);
    }

    /** @param documentId is not a documentEntityId, it is the literal documentId */
    public downloadDocument(documentId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/${documentId}`, 'Downloading Documents...');
    }

    /** @param documentId is not a documentEntityId, it is the literal documentId */
    public downloadDocumentSilent(documentId: number): Observable<HttpResponse<ArrayBuffer>> {
        return this.$http.downloadRequest(`${this.$baseUri}/${documentId}`);
    }

    public reviewDocumentEntity(documentEntity: IDocumentEntityDto): Observable<IDocumentEntityDto> {

        if (documentEntity.id <= 0) { return; }

        const documentReviewDto: IDocumentReviewDto = {
            status: documentEntity.document.approvalStatuses[0].documentReviewStatus,
            comments: documentEntity.document.approvalStatuses[0].reviewComments
        };

        return this.$http.post(`${this.$baseUri}/${documentEntity.id}/review`, documentReviewDto);

    }



    public filterAvailableGroups(
        documentGroups: IDocumentGroupFrontEndDto[], systemArea: SystemAreaEnumId): IDocumentGroupFrontEndDto[] {

        return _(documentGroups)
            .filter((documentGroup) => {
                if (documentGroup.codeOnly) {
                    return false;
                }

                if (!documentGroup.isActive) {
                    return false;
                }

                if (systemArea && !(documentGroup.systemAreas.some(el => el.isDefaultGroup === true && el.systemArea === systemArea))) {
                    return false;
                }

                documentGroup.tempFilteredTypes = this.filterAvailableTypes(documentGroup.types);
                return documentGroup.tempFilteredTypes.length > 0;
            })
            .orderBy((documentGroup) => documentGroup.name)
            .value();
    }

    public filterAvailableTypes(documentTypes: IDocumentGroupTypeDto[]): IDocumentGroupTypeDto[] {
        return _(documentTypes)
            .filter((documentType) => {

                // allow uploads of this type if multiples is allowed
                // or there is not a document already uploaded of this type
                if (documentType.codeOnly) { return false; }
                if (!documentType.isActive || !documentType.documentType.isActive) { return false; }
                return true;
            }).orderBy((documentType) => documentType.documentType.label)
            .value();
    }

    public getDocumentPrecursors(documentId: number): Observable<any[]> {
        return this.$http.get<IDocumentDto[]>(`${this.$baseUri}/${documentId}/precursors`);
    }

    public ensureDocumentTypes(entityId: number, systemArea: number, payload: IDocumentTypeDto[], documentGroupCode?: string): Observable<boolean> {
        return this.$http
            .post<boolean>(`${this.$baseUri}/${entityId}/ensuredocumenttypes/${documentGroupCode}/${systemArea}`, payload);
    }
}
