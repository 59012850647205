import { CbTooltipModule } from './../../components/tooltip/tooltip.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsisDirective } from './ellipsis.directive';



@NgModule({
    declarations: [EllipsisDirective],
    exports: [EllipsisDirective],
    imports: [
        CommonModule,
        CbTooltipModule
    ]
})
export class CbEllipsisModule { }
