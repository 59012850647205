import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IBuildTypeLogicService } from './interfaces/i.build-type-logic.service';
import { IBuildTypeDto } from './interfaces/i.build-type.dto';
import { IBuildTypeMappedItem } from './interfaces/i.build-type.mapped';
import { IBuildTypePaymentTemplateDto } from './interfaces/i.build-type-payment-template.dto';
import { IBuildTypeBuildProgrammeTemplateDto } from './interfaces/i.build-type-build-programme-template.dto';

export class BuildTypeMappedItem
    extends BaseMappedItem<IBuildTypeDto, IBuildTypeMappedItem, IBuildTypeLogicService>
    implements IBuildTypeMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public code: string;
    @DtoProp public label: string;
    @DtoProp public isActive: boolean;
    @DtoProp public lotType: number[];
    @DtoProp public paymentTemplates: IBuildTypePaymentTemplateDto[];
    @DtoProp public buildProgrammeTemplates: IBuildTypeBuildProgrammeTemplateDto[];

    constructor(
        sourceData: IBuildTypeDto,
        logicService: IBuildTypeLogicService
    ) {
        super(
            sourceData,
            logicService,
            BuildTypeMappedItem
        );
    }
}
