import { Component, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DATE_CALC_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BuildProgrammeTemplateActivityPredecessorDialogComponent }
    from '../build-programme-template-activity-predecessor-dialog/build-programme-template-activity-predecessor-dialog.component';
import {
    BuildProgrammeTemplateLogicService, IBuildProgrammeTemplateDto, IBuildTemplateActivityDto, IBuildTemplateActivityRelationshipDto,
    IBuildTemplateStageDto
} from '@app/logic/build-programme-template';
import { filter } from 'lodash';
import { DragulaService } from 'ng2-dragula';

interface IData {
    template: IBuildProgrammeTemplateDto;
    stage: IBuildTemplateStageDto;
    activity: IBuildTemplateActivityDto;
    refresh$: BehaviorSubject<boolean>;
}

@Component({
    selector: 'cb-build-programme-template-activity-dialog',
    templateUrl: './build-programme-template-activity-dialog.component.html',
    styleUrls: ['./build-programme-template-activity-dialog.component.scss']
})
export class BuildProgrammeTemplateActivityDialogComponent {
    public static readonly MIN_WIDTH = '90%';
    public activityColumns = ['Activity', 'Date Calculation Basis', 'Set Start Date', 'Lag Days', ''];
    public dateCalculationTypes = DATE_CALC_TYPE_ENUM.toLookup();
    public DATE_CALC_TYPE_ENUM = DATE_CALC_TYPE_ENUM;
    public readonly PREDECESSOR = 'PREDECESSOR';

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<BuildProgrammeTemplateActivityDialogComponent>,
        private readonly cbDialog: CbDialogService,
        private readonly buildProgrammeTemplateLogicService: BuildProgrammeTemplateLogicService,
        private readonly dragulaService: DragulaService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        this.setupDragula();
    }

    public ngOnDestroy(): void {
        this.dragulaService.destroy(this.PREDECESSOR);
    }

    public getTitle(): string {
        return this.data.activity && this.data.stage
            ? `(${this.data.stage.buildStageCode}) ${this.data.stage.buildStageLabel} - (${this.data.activity.activity.code}) ${this.data.activity.activity.name}`
            : '';
    }

    public openPredecessorDialog(predecessor?: IBuildTemplateActivityRelationshipDto): void {
        const dialog = this.cbDialog.open(BuildProgrammeTemplateActivityPredecessorDialogComponent, {
            data: {
                dialogHeading: predecessor ? 'Edit Predecessor' : 'Add Predecessor',
                template: this.data.template,
                stage: this.data.stage,
                activity: this.data.activity,
                predecessor
            }
        });
        dialog.afterClosed().subOnce(() => this.data.refresh$.next(!this.data.refresh$.value));
    }

    public predecessorRemoveClicked(predecessor: IBuildTemplateActivityRelationshipDto): void {
        this.data.activity.relationships = filter(this.data.activity.relationships, (item: IBuildTemplateActivityRelationshipDto) =>
            item.predecessorBuildProgrammeActivityId !== predecessor.predecessorBuildProgrammeActivityId);
    }

    public saveClicked(): void {
        if (!this.data.activity.isHorizontalPredecessor) {
            this.data.activity.horizontalPredecessorDateCalcType = 0;
        }
        if (this.data.activity.relationships.length) {
            for (let i = 0; i < this.data.activity.relationships.length; i++) {
                this.data.activity.relationships[i].sortOrder = i;
            }
        }
        this.buildProgrammeTemplateLogicService.saveActivities(this.data.template.id, this.data.activity).subOnce(() => this.dialogRef.close());
    }

    private setupDragula(): void {
        this.dragulaService.createGroup(this.PREDECESSOR, {
            removeOnSpill: false,
            revertOnSpill: true,
            accepts: (el: Element) => el.classList.contains('dragula-allow'),
            moves: (el: Element) => el.classList.contains('dragula-allow'),
        });
    }
}
