import { Inject, Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { IFileResponse } from '@app/shared/interfaces/i.file-response.dto';
import {
    ConstructionManagerRoleEnumId,
    CostNatureEnumId,
    ExternalSystemEnumId,
    IARPaymentScheduleDto,
    IDesignSchemeDto,
    ILotContingencyAmounDto,
    ILotContingencyInfoDto,
    ILotCostsAreasDto,
    ILotDocumentDto,
    ILotDto,
    ILotFinancialDetailsDto,
    IQuantityAccountDto,
    ISkinnyLeadDto,
    ISkinnyLotDto,
    ISkinnySpecTemplateDto,
    ITakeOffDtoSummary,
    IUnassignedChangeRecordDto,
    LotContractTypeEnumId,
    LotTypeEnumId,
    MarketStatusEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import { cleanObjectDeep, isNullOrWhiteSpace } from 'cb-hub-lib';
import { Observable, map } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { LotSpecLogicService } from '../lot-spec/lot-spec.logic.service';
import { ITakeoffMappedItem } from '../takeoffs/interfaces/i.takeoff.mapped';
import { TakeoffsLogicService } from '../takeoffs/takeoffs.logic.service';
import { ISetConstructionManagerRoleResponse } from './interfaces/i.construction-manager-role-response';
import { ILotProgressMilestoneDto } from './interfaces/i.lot-progress-milestones.dto';
import { ILotMappedItem } from './interfaces/i.lot.mapped';
import { ILotsLogicService } from './interfaces/i.lots.logic.service';
import { LotMappedItem } from './lot.mapped';


export type LotSearchParams = LotExtraSearchParams & {
    query: string;
    currentPage: number;
    pageSize: number;
};

export type AvailableLotSearchParams = LotSearchParams & {
    landArea: { min: number; max: number };
    price: { min: number; max: number };
    lotContractTypes?: LotContractTypeEnumId[];
};

export interface LotExtraSearchParams {
    floorArea: boolean;
    locationId: number;
    numberOfBathrooms: number;
    numberOfBedrooms: number;
    numberOfGarages: number;
    numberOfLivingRooms: number;
    order: 'projectLots' | null;
    lotType: LotTypeEnumId;
    landArea: { min: number; max: number } | null;
    price: { min: number; max: number } | null;
}


@Injectable()
export class LotsLogicService extends BaseLogicService<ILotDto, ILotMappedItem> implements ILotsLogicService {
    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        @Inject(TakeoffsLogicService) public readonly takeoffsLogicService: TakeoffsLogicService,
    ) {
        super('lots', LotMappedItem);
    }

    public search(params: Partial<LotSearchParams>, ignoreEmptyQueries = true): Observable<ISearchResult<ILotDocumentDto>> {
        if (ignoreEmptyQueries && (params.query == null || params.query.trim().length < 1)) {
            return;
        }
        return this.$http
            .get<ISearchResult<ILotDocumentDto>>(`${this.$baseUri}/search`, cleanObjectDeep(params, isNullOrWhiteSpace));
    }

    public searchAvailable(params: Partial<AvailableLotSearchParams>, ignoreEmptyQueries = true): Observable<ISearchResult<ILotDocumentDto>> {
        if (ignoreEmptyQueries && (params.query == null || params.query.trim().length < 1)) {
            return;
        }
        return this.$http
            .get<ISearchResult<ILotDocumentDto>>(`${this.$baseUri}/search/available`, cleanObjectDeep(params));
    }

    public getContingencyInfo(lotId: number): Observable<ILotContingencyInfoDto> {
        return this.$http
            .get<ILotContingencyInfoDto>(`${this.$baseUri}/${lotId}/contingencyinfo`);
    }

    public updateContingencyAmount(lotId: number, entity: ILotContingencyAmounDto): Observable<ILotContingencyAmounDto> {
        let uri = this.$baseUri;
        if (lotId > 0) {
            uri += `/${lotId}/updatecontingencyamount`;
        }
        return this.$http.post<ILotContingencyAmounDto>(uri, { contingencyAmount: entity.contingencyAmount });
    }

    public getBlockLotCosts(lotId: number): Observable<ILotFinancialDetailsDto[]> {
        return this.$http.get(`${this.$baseUri}/${lotId}/blockcosts`);
    }

    public getUnits(lotId: number): Observable<ILotDto[]> {
        return this.$http.get(`${this.$baseUri}/${lotId}/units`);
    }

    public reorderUnits(lotId: number, unitOrder: { [lotId: number]: number }): Observable<{ [lotId: number]: number }> {
        return this.$http.post(`${this.$baseUri}/${lotId}/reorderunits`, unitOrder);
    }

    public recalculateParentLotDates(): Observable<number> {
        return this.$http.post(`${this.$baseUri}/resetparentlotdates`);
    }

    public applyDesignSchemesToLots(): Observable<number> {
        return this.$http.post(`${this.$baseUri}/applylotdesignschemes`);
    }

    public getDesignSchemesForLot(lotId: number): Observable<IDesignSchemeDto[]> {
        return this.$http.get(`${this.$baseUri}/${lotId}/designschemes`);
    }

    public getDesignSchemesAvailableToPriceRevision(
        lotId: number,
        pricingRevisionId: number
    ): Observable<IDesignSchemeDto[]> {
        return this.$http.get(
            `${this.$baseUri}/${lotId}/designschemes/availableToPricingRevision/${pricingRevisionId}`
        );
    }

    public getHasIncompleteLotSpecItems(id: number): Observable<boolean> {
        return this.$http.get(`${this.$baseUri}/${id}/hasincompletelotscheduleitems`);
    }

    public getHasincompletelotspecitemattributes(id: number): Observable<boolean> {
        return this.$http.get(`${this.$baseUri}/${id}/hasincompletelotspecitemattributes`);
    }


    public getLotMilestones(lotId: number): Observable<ILotProgressMilestoneDto> {
        return this.$http.get(`${this.$baseUri}/${lotId}/milestones`);
    }

    public getShowhomes(): Observable<ILotDto[]> {
        return this.$http.get(`${this.$baseUri}/showhome`);
    }

    public generateSiteEvaluation(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generatesiteevaluation`, 'Generating Site Evaluation Form...',
            undefined,
            'post'
        );
    }

    public getMaxMinCostArea(): Observable<ILotCostsAreasDto> {
        return this.$http
            .get(`${this.$baseUri}/maxmincostsareas`);
    }

    public updateMarketStatus(ids: string[], status: MarketStatusEnumId): Observable<ISkinnyLotDto[]> {
        const payload = {
            lotIds: ids,
            statusId: status
        };
        return this.$http.post(`${this.$baseUri}/updatemarketstatus`, payload);
    }

    public deleteLot(lotId: number): Observable<any> {
        return this.$http.delete(`${this.$baseUri}/${lotId}`);

    }

    public setConstructionManagerRole(lotId: number, roleId: ConstructionManagerRoleEnumId): Observable<ISetConstructionManagerRoleResponse> {
        return this.$http.post(`${this.$baseUri}/${lotId}/setconstructionmanagerrole/${roleId}`);
    }

    public setLotHandedOver(lotId: number): Observable<boolean> {
        return this.$http.post(`${this.$baseUri}/${lotId}/handover`);
    }

    public getProgressPayments(lotId: number): Observable<IARPaymentScheduleDto> {
        return this.$http.get(`${this.$baseUri}/${lotId}/progressPayments`);
    }


    // #region Report / Generate Documents

    public generateDesignAndBuildContractLetter(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/designandbuildcontractletter`, 'Generating Design & Build Contract Letter...',
            undefined,
            'post'
        );
    }

    public generateCreateBookCoverPage1(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generatecreatebookcoverpage1`, 'Generating Create Book Cover Page 1...',
            undefined,
            'post'
        );
    }

    public generateCreateBookCoverPage2(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generatecreatebookcoverpage2`, 'Generating Create Book Cover Page 2...',
            undefined,
            'post'
        );
    }

    public generateFinalLotSpec(lotId: number, hasScheduleSpecTemplate: boolean): Observable<IFileResponse> {
        if (hasScheduleSpecTemplate) {
            return this.$http.download<IFileResponse>(
                `${this.$baseUri}/${lotId}/simliveschedule`, 'Generating Final Lot Spec...',
                undefined,
                'post'
            );
        } else {
            return this.$http.download<IFileResponse>(
                `${this.$baseUri}/${lotId}/finalLotSpecReport`, 'Generating Final Lot Spec...',
                undefined,
                'post'
            );
        }
    }

    public generateLotSpecReport(lotId: number, specVersion?: number | null): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generatelotspecreport`, 'Generating Lot Spec...',
            { lotSpecVersion: specVersion },
            'post'
        );
    }

    public generateClientPropertyInformation(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generateclientpropertyinformation`, 'Generating Client Property Information...',
            undefined,
            'post'
        );
    }

    public generatePropertyInformation(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generatepropertyinformation`, 'Generating Property Information...',
            undefined,
            'post'
        );
    }

    public generateColourYourDreams(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generatecolouryourdreams`, 'Generating Colour Your Dreams...',
            undefined,
            'post'
        );
    }

    public generateHLFlyer(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generateHlFlyer`, 'Generating H&L Flyer...',
            undefined,
            'get'
        );
    }

    public generateExternalSystemExportReport(lotId: number, externalSystem: ExternalSystemEnumId, lotSpecVersion: number): Observable<IFileResponse> {
        const requestUrl = (lotSpecVersion)
            ? `${this.$baseUri}/${lotId}/generateexternalsystemexport/${externalSystem}/${lotSpecVersion}`
            : `${this.$baseUri}/${lotId}/generateexternalsystemexport/${externalSystem}`;

        return this.$http.download<IFileResponse>(
            requestUrl, 'Generating export ...',
            undefined,
            'post'
        );
    }
    public generateSimColourSchedule(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generatesimcolourschedule`, 'Generating Colour Schedule...',
            undefined,
            'post'
        );
    }

    public generateDraftSimCreateSchedule(lotId: number, clientSaleId: number | null): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/draftcreateSimscheduleform`, 'Generating Draft Create Schedule...',
            clientSaleId,
            'post'
        );
    }


    public generateCpc(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/${lotId}/generatecpc`, 'Generating Practical Completion...',
            undefined,
            'post'
        );
    }

    // #endregion

    public getAvailableSpecTemplates(lotId: number): Observable<ISkinnySpecTemplateDto[]> {
        return this.$injector.get(LotSpecLogicService).getAvailableSpecTemplates(lotId);
    }


    public getUnassignedChangeRecordsForVariationCount(lotId: number): Observable<number> {
        return this.$http.get(`${this.$baseUri}/${lotId}/changerecords/unassignedtovariationscount`);
    }

    public getUnassignedChangeRecordsForVariation(lotId: number, costNature: CostNatureEnumId, supplierId?: number): Observable<IUnassignedChangeRecordDto[]> {

        if (supplierId > 0) {
            return this.$http.get(`${this.$baseUri}/${lotId}/changerecords/unassignedtovariations/${costNature}/${supplierId}`);
        } else {
            return this.$http.get(`${this.$baseUri}/${lotId}/changerecords/unassignedtovariations/${costNature}`);
        }

    }

    public recalculateAllLotFlags(): Observable<void> {
        return this.$http.post(`${this.$baseUri}/resetflags`);
    }

    public rebuildLotSpecCache(): Observable<void> {
        return this.$http.post(`${this.$baseUri}/rebuildcache`);
    }

    public getLotLeads(lotId: number): Observable<ISkinnyLeadDto[]> {
        return this.$http.get(`${this.$baseUri}/${lotId}/leads`);
    }

    public getTakeOffs(lotId: number): Observable<ITakeOffDtoSummary[]> {
        return this.$http.get(`${this.$baseUri}/${lotId}/takeoff`);
    }

    public getMappedTakeOffs(lotId: number): Observable<ITakeoffMappedItem[]> {
        return this.getTakeOffs(lotId).pipe(
            map(dtos => dtos.map(dto => this.takeoffsLogicService.$createMappedItem(dto)))
        );
    }

    public getLotActualBuildCost(lotId: number): Observable<number> {
        return this.$http.get(`${this.$baseUri}/${lotId}/actualbuildcost`);
    }

    public getQuantityAccount(lotId: number): Observable<IQuantityAccountDto> {
        return this.$http.get(`${this.$baseUri}/${lotId}/quantityaccount`);
    }

    public generateARReport(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(`${this.$baseUri}/${lotId}/ARReport`, 'Generating AR Report...');
    }

}
