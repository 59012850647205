import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

export interface CanComponentDeactivate {
    canDeactivate: () => boolean | Promise<boolean>;
}

@Injectable({
    providedIn: 'root'
})
export class ConfirmDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    public canDeactivate(component: CanComponentDeactivate): boolean | Promise<boolean> {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
