import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ILotSpecColourItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { ILotSpecColourItemLogicService } from './interfaces/i.lot-spec-colour-item-logic.service';
import { ILotSpecColourItemMappedItem } from './interfaces/i.lot-spec-colour-item.mapped';
import { LotSpecColourItemMappedItem } from './lot-spec-colour-item.mapped';

@Injectable()
export class LotSpecColourItemLogicService
    extends BaseLogicService<ILotSpecColourItemDto, ILotSpecColourItemMappedItem>
    implements ILotSpecColourItemLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'lotspecs',
            LotSpecColourItemMappedItem
        );
    }

    public $getItem(entityId: number): Observable<ILotSpecColourItemDto> {
        return this.$http.get<ILotSpecColourItemDto>(`${this.$baseUri}/colouritems/${entityId}`)
            .pipe(
                this.$retryRequestWhen()
            );
    }

    public getItemAtVersion(lotOrLotSpecId: number, entityId: number, versionNumber: number): Observable<ILotSpecColourItemDto> {
        return this.$http.get<ILotSpecColourItemDto>(`${this.$baseUri}/${lotOrLotSpecId}/colouritems/${entityId}/version/${versionNumber}`)
            .pipe(
                this.$retryRequestWhen()
            );
    }

    public $getMappedItem(entityId: number): Observable<ILotSpecColourItemMappedItem> {
        return super.$getMappedItem(entityId);
    }

    public $saveItem(entity: ILotSpecColourItemDto): Observable<ILotSpecColourItemDto> {
        let uri = `${this.$baseUri}/${entity.lotId}/colouritems`;
        if (entity.id) {
            uri += `/${entity.id}`;
        }
        return this.$http.post<ILotSpecColourItemDto>(uri, entity);
    }

    public $deleteItem<ReturnType>(lotId: number, lotSpecColourItemId: number, lotSpecVersion?: number): Observable<ReturnType> {
        let url = `${this.$baseUri}/${lotId}/colouritems/${lotSpecColourItemId}`;
        if (lotSpecVersion) {
            url = `${url}/version/${lotSpecVersion}`;
        }
        return this.$http.delete(url);
    }

    public $getList(lotId: number): Observable<Array<ILotSpecColourItemDto>> {
        return this.$http.get(`${this.$baseUri}/${lotId}/lotSpecColourItems`);
    }

    public $getMappedList(lotId: number): Observable<Array<ILotSpecColourItemMappedItem>> {
        return super.$getMappedList(lotId);
    }

    public uploadColourItemImageAtVersion(lotId: number, lotSpecColourItemId: number, versionNumber: number, file: File): Observable<ILotSpecColourItemDto> {
        const fd = new FormData();
        fd.append('file', file);
        return this.$http.post(`${this.$baseUri}/${lotId}/colouritems/${lotSpecColourItemId}/image/version/${versionNumber}`, fd);
    }

    public removeColourItemImage(lotId: number, lotSpecColourItemId: number): Observable<boolean> {
        return this.$http.delete(`${this.$baseUri}/${lotId}/colouritems/${lotSpecColourItemId}/image`);
    }
}
