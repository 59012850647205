import {FormsModule, ReactiveFormsModule, ɵInternalFormsSharedModule} from '@angular/forms';
import {AccountListComponent} from './account-list/account-list.component';
import {AccountListDialogComponent} from './account-list-dialog/account-list-dialog.component';
import {CbDialogModule} from './../../shared/components/dialog/dialog.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {CbEnumToLabelModule} from './../../shared/pipe/enum-to-label/enum-to-label.module';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbHistoryModule} from '@app/shared/components/history/history.module';
import {CbDasherModule, CbInfoMessageModule, CbInfoPopupModule, CbLoadingSpinnerModule, CbSelectListModule, CbTableModule} from 'cb-hub-lib';
import {CbNotesModule} from './../../shared/components/notes/notes.module';
import {CbSideBarModule} from '@app/shared/components/side-bar/side-bar.module';
import {CbTabRouteModule} from '@app/shared/directives/tab-route/tab-route.module';
import {CbHubTableModule} from './../../shared/components/table/table.module';
import {CbWaitForModule} from '@app/shared/directives/wait-for/wait-for.module';
import {CommonModule} from '@angular/common';
import {ContactCreateLoginDialogComponent} from './contact-create-login-dialog/contact-create-login-dialog.component';
import {ContactDetailsComponent} from './contact-details/contact-details.component';
import {ContactEditComponent} from './contact-edit/contact-edit.component';
import {ContactLeadsComponent} from './contact-leads/contact-leads.component';
import {ContactSearchComponent} from './contact-search/contact-search.component';
import {ContactViewComponent} from './contact-view/contact-view.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {LeadListDialogComponent} from './lead-list-dialog/lead-list-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MyClassicComponent} from './my-classic/my-classic.component';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CreateContactDialogComponent} from './create-contact-dialog/create-contact-dialog.component';
import {CreateContactSelectExistingDialogComponent} from './create-contact-select-existing-dialog/create-contact-select-existing-dialog.component';
import {AddContactDialogComponent} from './add-contact-dialog/add-contact-dialog.component';
import {CbClickModule} from '@app/shared/directives/click/click.module';
import {ContactRightSidePanelComponent} from './contact-right-side-panel';

export const CONTACT_FULL_ROUTE = 'contacts/:id/:cbTabRouteId';

const CONTACT_ROUTE_DATA = {
    breadcrumb: 'View Contact',
    breadcrumbPredecessors: [
        { label: 'Contact Search', url: '/contacts' }
    ]
};

@NgModule({
    declarations: [
        ContactSearchComponent,
        ContactViewComponent,
        ContactDetailsComponent,
        AccountListComponent,
        ContactLeadsComponent,
        MyClassicComponent,
        ContactCreateLoginDialogComponent,
        ContactEditComponent,
        AccountListDialogComponent,
        LeadListDialogComponent,
        AddContactDialogComponent,
        CreateContactDialogComponent,
        CreateContactSelectExistingDialogComponent,
        ContactRightSidePanelComponent
    ],
    exports: [
        ContactRightSidePanelComponent
    ],
    imports: [
        RouterModule.forRoot([
            {
                component: ContactViewComponent,
                path: CONTACT_FULL_ROUTE,
                data: CONTACT_ROUTE_DATA
            },
            {
                component: ContactViewComponent,
                path: 'contacts/:id',
                data: CONTACT_ROUTE_DATA
            },
            {
                component: ContactSearchComponent,
                path: 'contacts',
                data: {
                    breadcrumb: 'Contact Search'
                }
            }
        ], {}),
        CbDisplayValueModule,
        CbFormsModule,
        CbLoadingSpinnerModule,
        CbSideBarModule,
        CbTabRouteModule,
        CbWaitForModule,
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatTableModule,
        CbInfoMessageModule,
        CbNotesModule,
        CbHistoryModule,
        CbHubTableModule,
        CbTableModule,
        CbDialogModule,
        CbEnumToLabelModule,
        MatRadioModule,
        InfiniteScrollModule,
        MatDividerModule,
        CbSelectListModule,
        CbClickModule,
        CbDasherModule,
        ɵInternalFormsSharedModule,
        CbInfoPopupModule
    ]
})
export class ContactsModule { }
