import {IContactSearchDto} from '@app/logic/contacts';
import {ContactsLogicService} from './../../../logic/contacts/contacts-logic.service';
import {Observable} from 'rxjs';
import {takeOne} from 'cb-hub-lib';
import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';

@Injectable()
export class ContactsSearchService extends SearchService {

    constructor(
        public readonly contactsLogic: ContactsLogicService,
    ) {
        super();
    }

    public getResults(query: string, currentpage: number): Observable<IContactSearchDto[]> {
        return new Observable<IContactSearchDto[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;

                const resultsObservable = this.contactsLogic.search({
                    query,
                    currentpage,
                    ...this.extraSearchParams,
                    excludeUnlinked: false,
                });

                if (!resultsObservable) {
                    return;
                }

                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<IContactSearchDto>(x);
                        subscriber.next(this.searchResults$.value as IContactSearchDto[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as IContactSearchDto[]);
                subscriber.complete();
            }
        });
    }

    public readonly doSearch = (query: string, page: number): Observable<IContactSearchDto[]> => {
        this.searchResultsLoaded = false;
        return this.getResults(query, page).pipe(takeOne());
    };
}
