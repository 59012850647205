import { Pipe, PipeTransform } from '@angular/core';
import { StringMask } from '@app/shared/components/forms/input/string.mask';

@Pipe({
    name: 'cbPhoneNumber'
})
export class CbPhoneNumberPipe implements PipeTransform {
    private _mask: StringMask;

    constructor() {
        this._mask = StringMask.GetStringMask('LANDLINE');
    }

    public transform(value: any): string {
        const transformedValue = this._mask.process(value);
        const ttt = transformedValue;
        const sss = ttt.result;
        return sss || '-';
    }

}
