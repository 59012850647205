import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbFilterChipsModule} from './../../shared/components/filter-chips/filter-chips.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbAmenitiesModule} from './../../shared/components/amenities/amenities.module';
import {CbCurrencyModule} from './../../shared/pipe/currency/currency.module';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {CbDocumentsModule} from '@app/shared/components/documents/documents.module';
import {CbEnumAsLookupModule} from '@app/shared/pipe/enum-as-lookup/enum-as-lookup.module';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbHistoryModule} from '@app/shared/components/history/history.module';
import {CbNotesModule} from './../../shared/components/notes/notes.module';
import {CbPrettyBoolModule} from '@app/shared/pipe/pretty-bool/pretty-bool.module';
import {CbSideBarModule} from '@app/shared/components/side-bar/side-bar.module';
import {CbStepperModule} from '@app/shared/components/stepper/stepper.module';
import {CbTabRouteModule} from '@app/shared/directives/tab-route/tab-route.module';
import {CbTasksModule} from '@app/shared/components/tasks/tasks.module';
import {CbWaitForModule} from './../../shared/directives/wait-for/wait-for.module';
import {ChangeContractTypeDialogComponent} from './dialogs/change-contract-type-dialog/change-contract-type-dialog.component';
import {ChangeDesignConceptDialogComponent} from './dialogs/change-design-concept-dialog/change-design-concept-dialog.component';
import {ClassicCareEditDialogComponent} from './dialogs/classic-care-edit-dialog/classic-care-edit-dialog.component';
import {ClientAccountEditDialogComponent} from './dialogs/client-account-edit-dialog/client-account-edit-dialog.component';
import {ClientSaleBuildContractCardComponent} from './detail-cards/build-contract-card/build-contract-card.component';
import {ClientSaleClassicCareCardComponent} from './detail-cards/classic-care-card/classic-care-card.component';
import {ClientSaleClientAccountCardComponent} from './detail-cards/client-account-card/client-account-card.component';
import {ClientSaleContractPricingCardComponent} from './detail-cards/contract-pricing-card/contract-pricing-card.component';
import {ClientSaleDesignCardComponent} from './detail-cards/design-card/design-card.component';
import {ClientSaleDetailsCardComponent} from './detail-cards/details-card/details-card.component';
import {ClientSaleDetailsComponent} from './client-sale-details/client-sale-details.component';
import {ClientSaleLotDetailsCardComponent} from './detail-cards/lot-details-card/lot-details-card.component';
import {ClientSaleSalePurchaseAgreementCardComponent} from './detail-cards/sale-purchase-agreement-card/sale-purchase-agreement-card.component';
import {ClientSaleStepperComponent} from './client-sale-stepper/client-sale-stepper.component';
import {ClientSaleUnconditionalCardComponent} from './detail-cards/unconditional-card/unconditional-card.component';
import {ClientSaleViewComponent} from './client-sale-view/client-sale-view.component';
import {CommonModule} from '@angular/common';
import {ContractPricingDetailsEditDialogComponent} from './dialogs/contract-pricing-details-edit-dialog/contract-pricing-details-edit-dialog.component';
import {LotDetailsEditDialogComponent} from './dialogs/lot-details-edit-dialog/lot-details-edit-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SaleDetailsEditDialogComponent} from './dialogs/sale-details-edit-dialog/sale-details-edit-dialog.component';
import {LostOrStalledDialogComponent} from './dialogs/lost-or-stalled-dialog/lost-or-stalled-dialog.component';
import {SetUnconditionalDialogComponent} from './dialogs/set-unconditonal-dialog/set-unconditional-dialog.component';
import {MyClientSalesViewComponent} from './my-client-sales-view/my-client-sales-view.component';
import {MyClientSalesSidePanelComponent} from './my-client-sales-side-panel/my-client-sales-side-panel.component';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatMenuModule} from '@angular/material/menu';
import {CreateClientSaleDialogComponent} from './dialogs/create-client-sale-dialog/create-client-sale-dialog.component';
import {CbClickModule} from '@app/shared/directives/click/click.module';
import {MYCLIENTSALES_TAB_FULL_ROUTE} from './client-sales-route.const';
import {ClientSaleSearchComponent} from '@app/views/client-sales/client-sale-search/client-sale-search.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ProgressPaymentsTableComponent} from './dialogs/contract-pricing-details-edit-dialog/progress-payments-table/progress-payments-table.component';
import {CbSortByModule} from '@app/shared/pipe/sort-by/sort-by.module';
import {CbInfiniteScrollModule, CbInfoMessageModule, CbLoadingSpinnerModule, CbSelectListModule, CbTableModule} from 'cb-hub-lib';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';

const MYCLIENTSALES_ROUTE_DATA = {
    breadcrumb: 'View Client Sale',
    breadcrumbPredecessors: [
        { label: 'Client Sale Search', url: '/clientsales/search' }
    ]
};

@NgModule({
    declarations: [
        ProgressPaymentsTableComponent,
        ClientSaleSearchComponent,
        ClientSaleViewComponent,
        ClientSaleDetailsComponent,
        ClientSaleDetailsCardComponent,
        ClientSaleLotDetailsCardComponent,
        ClientSaleClientAccountCardComponent,
        ClientSaleContractPricingCardComponent,
        ClientSaleDesignCardComponent,
        ClientSaleSalePurchaseAgreementCardComponent,
        ClientSaleUnconditionalCardComponent,
        ClientSaleBuildContractCardComponent,
        ClientSaleClassicCareCardComponent,
        SaleDetailsEditDialogComponent,
        LotDetailsEditDialogComponent,
        ClientAccountEditDialogComponent,
        ContractPricingDetailsEditDialogComponent,
        ClassicCareEditDialogComponent,
        ChangeContractTypeDialogComponent,
        LostOrStalledDialogComponent,
        ChangeDesignConceptDialogComponent,
        ClientSaleStepperComponent,
        SetUnconditionalDialogComponent,
        MyClientSalesViewComponent,
        MyClientSalesSidePanelComponent,
        CreateClientSaleDialogComponent,
    ],
    imports: [
        RouterModule.forRoot(
            [
                {
                    component: MyClientSalesViewComponent,
                    path: 'clientsales',
                    data: MYCLIENTSALES_ROUTE_DATA
                },
                {
                    component: MyClientSalesViewComponent,
                    path: 'clientsales/view/:id',
                    data: MYCLIENTSALES_ROUTE_DATA
                },
                {
                    component: MyClientSalesViewComponent,
                    path: MYCLIENTSALES_TAB_FULL_ROUTE,
                    data: MYCLIENTSALES_ROUTE_DATA
                },
                {
                    component: ClientSaleSearchComponent,
                    path: 'clientsales/search',
                    data: {
                        breadcrumb: 'Client Sale Search',
                    },
                },
            ],
            {
    onSameUrlNavigation: 'reload'
}
        ),
        CommonModule,
        MatCardModule,
        CbInfiniteScrollModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatInputModule,
        CbDisplayValueModule,
        MatTabsModule,
        CbNotesModule,
        CbHistoryModule,
        CbSortByModule,
        CbTasksModule,
        CbSideBarModule,
        CbDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        CbAmenitiesModule,
        CbPrettyBoolModule,
        CbEnumAsLookupModule,
        CbStepperModule,
        CbFormsModule,
        CbTabRouteModule,
        CbWaitForModule,
        CbCurrencyModule,
        CbDocumentsModule,
        CbInfoMessageModule,
        MatListModule,
        MatMenuModule,
        CbLoadingSpinnerModule,
        CbClickModule,
        CbSelectListModule,
        CbFilterChipsModule,
        InfiniteScrollModule,
        CbHubTableModule,
        CbTableModule
    ]
})
export class ClientSalesModule { }
