import {
    BasePermissions,
    IBaseSystemAreaPermissions,
    IDocumentPermissions,
    IhaveDocumentPermissions,
    IhaveLotInterestPermissions,
    IhaveNotePermissions,
    IhaveTaskPermissions,
    ILotInterestPermissions,
    INotePermissions,
    ITaskPermissions,
} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {LeadPermissionEnum} from '../../shared/enums/permissions';
import {IContactPermissions} from '.';

export interface ILeadPermissions
    extends IBaseSystemAreaPermissions, IhaveLotInterestPermissions,
    IhaveDocumentPermissions, IhaveNotePermissions, IhaveTaskPermissions {
    canSearch(): boolean;
    canSeeComponent(): boolean;
    canQualify(): boolean;
    canMarkAsLost(): boolean;
    canViewHistory(): boolean;
    canAddLotInterest(): boolean;
    canRemoveLotInterest(): boolean;
    canSetLotInterestAsMain(): boolean;
    getDocumentPermissions(): IDocumentPermissions;
    getNotePermissions(): INotePermissions;
    getTaskPermissions(): ITaskPermissions;
    getContactPermissions(): IContactPermissions;
    getLotInterestPermissions(): ILotInterestPermissions;
    canViewAll(): boolean;
    canViewForBusinessEntity(): boolean;
}

@Injectable()
export class LeadPermissions
    extends BasePermissions<LeadPermissionEnum>
    implements ILeadPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lead', LeadPermissionEnum, currentUser, http, router);
    }

    public canSearch(): boolean {
        return this.permissionObject(LeadPermissionEnum.CanSearch);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(LeadPermissionEnum.CanSearch);
    }

    public canView(): boolean {
        return this.permissionObject(LeadPermissionEnum.CanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(LeadPermissionEnum.CanCreate);
    }

    public canEdit(): boolean {
        return this.permissionObject(LeadPermissionEnum.CanEdit);
    }

    public canQualify(): boolean {
        return this.permissionObject(LeadPermissionEnum.CanQualify);
    }

    public canMarkAsLost(): boolean {
        return this.permissionObject(LeadPermissionEnum.CanMarkAsLost);
    }

    public canViewHistory(): boolean {
        return this.permissionObject(LeadPermissionEnum.HistoryCanView);
    }

    public canAddLotInterest(): boolean {
        return this.permissionObject(LeadPermissionEnum.LotInterestCanAdd);
    }

    public canRemoveLotInterest(): boolean {
        return this.permissionObject(LeadPermissionEnum.LotInterestCanRemove);
    }

    public canSetLotInterestAsMain(): boolean {
        return this.permissionObject(LeadPermissionEnum.LotInterestCanSetMain);
    }

    public canViewAll(): boolean {
        return this.permissionObject(LeadPermissionEnum.CanViewAll);
    }

    public canViewForBusinessEntity(): boolean {
        return this.permissionObject(LeadPermissionEnum.CanViewForBusinesEntity);
    }

    public getContactPermissions(): any {
        return {
            canAddContact: () => this.permissionObject(LeadPermissionEnum.LeadContactCanAdd),
            canChangeMainContact: () => this.permissionObject(LeadPermissionEnum.MainContactCanChange),
            canRemoveContact: () => this.permissionObject(LeadPermissionEnum.LeadContactCanRemove)
        };
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => this.permissionObject(LeadPermissionEnum.DocumentsCanDelete),
            canEditDocuments: () => this.permissionObject(LeadPermissionEnum.DocumentsCanEdit),
            canReviewDocuments: () => this.permissionObject(LeadPermissionEnum.DocumentsCanReview),
            canUploadDocument: () => this.permissionObject(LeadPermissionEnum.DocumentsCanUpload),
            canViewDocuments: () => this.permissionObject(LeadPermissionEnum.DocumentsCanView)
        };
    }

    public getLotInterestPermissions(): ILotInterestPermissions {
        return {
            canAddLotInterest: () => this.permissionObject(LeadPermissionEnum.LotInterestCanAdd),
            canRemoveLotInterest: () => this.permissionObject(LeadPermissionEnum.LotInterestCanRemove),
            canSetLotInterestasMain: () => this.permissionObject(LeadPermissionEnum.LotInterestCanSetMain)
        };
    }

    public getNotePermissions(): INotePermissions {
        return {
            canCreateNote: () => this.permissionObject(LeadPermissionEnum.NoteCanAdd),
            canDeleteNote: () => this.permissionObject(LeadPermissionEnum.NoteCanDelete),
            canEditNote: () => this.permissionObject(LeadPermissionEnum.NoteCanEdit),
            canViewNotes: () => this.permissionObject(LeadPermissionEnum.NoteCanView)
        };
    }

    public getTaskPermissions(): ITaskPermissions {
        return {
            canAddTask: () => this.permissionObject(LeadPermissionEnum.TaskCanAdd),
            canCompleteTask: () => this.permissionObject(LeadPermissionEnum.TaskCanComplete),
            canEditTask: () => this.permissionObject(LeadPermissionEnum.TaskCanEdit),
            canViewTask: () => this.permissionObject(LeadPermissionEnum.TaskCanView)
        };
    }
}
