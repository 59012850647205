import {Component, Input} from '@angular/core';
import {LOT_CONTRACT_TYPE_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {ILeadMappedItem} from '@app/logic/leads/interfaces/i.lead.mapped';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {ClientSaleLogicService} from '@app/logic/client-sale';
import {LeadPermissions} from '@app/core/permissions';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {Location} from '@angular/common';
import {LeadLostDialogComponent} from './lead-lost-dialog/lead-lost-dialog.component';
import {LeadQualifyHouseLandDialogComponent} from './lead-qualify-house-land-dialog/lead-qualify-house-land-dialog.component';
import {LeadQualifyDesignBuildDialogComponent} from './lead-qualify-design-build-dialog/lead-qualify-design-build-dialog.component';
import {viewLeadUrl} from '@app/views/leads/lead-routes';

@Component({
    selector: 'cb-lead-qualify-card',
    templateUrl: './lead-qualify-card.component.html',
    styleUrls: ['./lead-qualify-card.component.scss']
})
export class LeadQualifyCardComponent {
    @Input() public mappedItem: ILeadMappedItem;
    @Input() public canEdit: boolean;
    @Input() public stepNumber: string;
    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    constructor(
        private readonly navigationService: NavigationService,
        private readonly clientSaleLogicService: ClientSaleLogicService,
        private readonly leadPermissions: LeadPermissions,
        private readonly location: Location,
        private readonly cbDialog: CbDialogService,
    ) { }


    public lotDetailsEntered(): boolean {
        if (this.isDesignAndBuild()) {
            return this.mappedItem.stepDesignAndBuildComplete;
        }
        return this.mappedItem.stepHouseAndLandComplete;
    }

    public isDesignAndBuild(): boolean {
        return this.mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild;
    }

    public numberOfHouseAndLandInterests(): number {
        if (!this.mappedItem.interests) {
            return 0;
        }
        return this.mappedItem.interests.filter(x => x.contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand).length;
    }

    public numberOfDesignAndLandInterests(): number {
        if (!this.mappedItem.interests) {
            return 0;
        }
        return this.mappedItem.interests.filter(x => x.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand).length;
    }

    public qualifyLead(): void {
        const primary = this.mappedItem.getReadOnlyPrimaryInterest();
        if (!this.canQualify() || primary == null) { return; }
        if (primary.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            this.cbDialog
                .open(LeadQualifyDesignBuildDialogComponent, {
                    data: { mappedItem: this.mappedItem.$clone() },
                    width: '700px'
                })
                .afterClosed()
                .subOnce(result => {
                    if (result.qualifiedLotIds && result.qualifiedLotIds.length === 1) {
                        this.goToLotClientSale(result.qualifiedLotIds.pop());
                    }
                });
        } else {
            this.cbDialog
                .open(LeadQualifyHouseLandDialogComponent, {
                    data: { mappedItem: this.mappedItem.$clone() },
                    minWidth: '85%'
                })
                .afterClosed()
                .subOnce(result => {
                    if (result.qualifiedLotIds && result.qualifiedLotIds.length === 1) {
                        this.goToLotClientSale(result.qualifiedLotIds.pop());
                    }
                });
        }
    }

    public goToLotClientSale(lotId: number): void {
        this.clientSaleLogicService.getClientSaleIdForLot(lotId).subOnce({
            next: forLotResult => {
                if (forLotResult && forLotResult.clientSaleId > 0) {
                    this.navigationService.navigate([`/clientsales/view/${forLotResult.clientSaleId}/details`]);
                }
            }
        });
    }

    public markAsLost(): void {
        if (!this.canMarkAsLost()) { return; }

        this.cbDialog
            .open(LeadLostDialogComponent, {
                data: {
                    mappedItem: this.mappedItem
                },
                minWidth: '40%',
            })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.location.go(viewLeadUrl(this.mappedItem.id));
                }
            });
    }

    public canSeeMarkAsLost(): boolean {
        return this.leadPermissions.canMarkAsLost() && this.canEdit;
    }

    public canMarkAsLost(): boolean {
        return this.leadPermissions.canMarkAsLost() && this.mappedItem.isHotOrCold() && this.canEdit;
    }

    public canSeeQualify(): boolean {
        return this.leadPermissions.canQualify() && this.canEdit;
    }

    public canQualify(): boolean {
        return this.mappedItem.canQualify
            && this.leadPermissions.canQualify()
            && !this.mappedItem.isQualified()
            && this.canEdit;
    }

}
