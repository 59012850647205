import { ComputedProperty } from './../../../../../shared/utils/computed-property.util';
import { ICouncilRfiConsentDto, RFI_ASPECT_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, Input, OnChanges } from '@angular/core';
import { ICouncilRfiMappedItem } from '@app/logic/council-rfis';
import { Subscription } from 'rxjs';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ConsentType } from '@app/logic/lot-consents';

@Component({
    selector: 'cb-council-rfi-details',
    templateUrl: './council-rfi-details.component.html',
    styleUrls: ['./council-rfi-details.component.scss']
})
export class CouncilRfiDetailsComponent implements OnChanges {
    @Input() public readonly councilRfi: ICouncilRfiMappedItem;

    public readonly RELATES_TO = RFI_ASPECT_ENUM.toLookup();
    public readonly rFIAspects = new ComputedProperty(() => {
        return this.councilRfi?.rfiAspects?.map(x => ({ id: x }));
    });

    public sub$ = new Subscription();

    constructor(
        public readonly navigate: NavigationService,
    ) { }

    public ngOnChanges(): void {
        this.rFIAspects.recompute();

        this.sub$.unsubscribe();

        this.sub$ = this.councilRfi.$updated.subscribe(() => this.rFIAspects.recompute());
    }

    public viewLotConsent(event: MouseEvent, consent: ICouncilRfiConsentDto): void {
        const consentType = consent?.label?.toLowerCase()?.includes('building consent') ? ConsentType.BuildingConsent : ConsentType.ResourceConsent;
        this.navigate.navigate([`/lots/${this.councilRfi.lotId}/design/consent`], event, { queryParams: { lotConsentId: consent.id, consentType } });
    }

}
