import {ISpecGroupDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {IItemsCount} from './i.items-count';

export class HouseAreaListItem implements IItemsCount, ISpecGroupDto {
    // ISpecGroupDto members
    public label: string;
    public sortOrder: number;
    public isQsOnly: boolean;
    public id: number;

    // extra members
    public readonly plainLabel: string;
    public itemsCount = 0;
    public get isVisible(): boolean {
        return !this.isQsOnly || this.currentUserIsQS;
    }

    constructor(
        private readonly currentUserIsQS: boolean,
        specGroup: ISpecGroupDto,
        itemsCount: number
    ) {
        Object.assign(this, specGroup);
        this.plainLabel = this.label;

        this.itemsCount = itemsCount;
        this.updateLabel();
    }

    private updateLabel(): void {
        this.label = `${this.plainLabel} (${this.itemsCount})`;
    }
}
