import { Component } from '@angular/core';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { IStyleMappedItem } from '@app/logic/styles/interfaces/i.style.mapped';
import { IStandardPlanStyleDto } from '@app/logic/styles/interfaces/i.style.dto';
import { PermissionsPermissions } from '@app/core/permissions';
import { StylesLogicService } from '@app/logic/styles/style.logic.service';
import { StyleMappedItem } from '@app/logic/styles/style.mapped';
import { StyleDialogComponent } from './style-dialog/style-dialog.component';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-styles',
    templateUrl: './styles.component.html',
    styleUrls: ['./styles.component.scss'],
    providers: [StylesLogicService]
})
export class StylesComponent extends BaseSimpleListViewDirective<IStandardPlanStyleDto, StylesLogicService> {

    public styles: IStyleMappedItem[] = [];
    public stylesLoaded = false;

    public readonly displayedColumns: string[] = ['id', 'name', 'isActive', 'actions'];

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        private readonly stylesLogicService: StylesLogicService,
    ) {
        super(
            dialog,
            stylesLogicService,
            StyleDialogComponent,
            'Styles',
            StyleMappedItem,
            permissionsPermissions
        );
    }
}
