import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { IVariationMappedItem } from '@app/logic/variation/interfaces/i.variation.mapped';
import { Component, OnInit, Inject } from '@angular/core';
import { DocumentGroupLogicService, DocumentsLogicService, IDocumentEntityDto } from '@app/logic/documents';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import {
    IDocumentUploadDto,
    IVariationDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IVariationLogicService } from '@app/logic/variation/interfaces/i.variation.logic-service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { VariationLogicService } from '@app/logic/variation/variation.logic-service';

interface IData {
    variationMappedItem: IVariationMappedItem;
    documentEntity: IDocumentEntityMappedItem;
}

@Component({
    selector: 'cb-lot-variation-reupload-dialog',
    templateUrl: './lot-variation-reupload-dialog.component.html',
    styleUrls: ['./lot-variation-reupload-dialog.component.scss']
})
export class LotVariationReuploadDialogComponent extends BaseDialogFormViewDirective<IVariationDto, IVariationMappedItem, IVariationLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '33%';

    public document: IDocumentEntityMappedItem;

    constructor(
        public readonly dialogRef: MatDialogRef<LotVariationReuploadDialogComponent>,
        private readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(DocumentGroupLogicService) public readonly documentGroupLogic: DocumentGroupLogicService,
        @Inject(DocumentsLogicService) public readonly documentsLogic: DocumentsLogicService,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(VariationLogicService) public readonly variationLogic: VariationLogicService,
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.loadDocumentEntityStub();
    }

    private loadDocumentEntityStub(): void {
        this.document = this.documentsLogic.$createMappedItem({
            documentGroup: this.data.documentEntity.documentGroup,
            documentType: this.data.documentEntity.documentType,
            document: {},
        } as IDocumentEntityDto);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public submit(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Confirm Signed Approval Replacement',
            message: 'Please confirm that you wish to upload this signed variation approval document, thereby replacing the existing approval document.',
            confirmed: () => {
                const blockedDialog = this.cbDialog.block('Uploading Approval  Document...');
                this.variationLogic.reuploadApprovedApprovalDocumenty(this.data.variationMappedItem.id, {
                    documentTypeId: this.data.documentEntity.documentType?.id,
                    documentGroupId: this.data.documentEntity.documentGroup?.id,
                    documentEntityId: this.data.variationMappedItem.approvalDocumentId,
                    file: this.document.document.uploadedFile,
                    name: this.document.document.name,
                } as IDocumentUploadDto)
                    .subOnce({
                        next: (result) => {
                            blockedDialog.close();
                            this.dialogRef.close(result);
                        },
                        error: () => {
                            blockedDialog.close();
                        }
                    });
            }
        });
    }
}
