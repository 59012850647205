import {Component, HostBinding, Input, OnChanges} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserSearchService } from '@app/core/services/search/user.search.service';
import { IUserSearch } from '@app/core/services/user-cache/user-cache-areas';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { getBaseAutocompleteScrollerProvider, AutocompleteScrollerComponent } from '../../autocomplete-scroller.component';


@Component({
    selector: 'cb-user-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(UserAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(UserAutocompleteScrollerComponent),
        UserSearchService,
    ]
})
export class UserAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent
    implements OnChanges {
    @Input() public readonly active: boolean;
    @Input() public readonly businessEntity: string;
    @Input() public readonly tag: string[];
    @Input() public readonly team: string[];
    @Input() public readonly tagOrTeam: boolean;
    @HostBinding('class') class = 'flex-row flex';
    public results: any[];

    constructor(
        public readonly dialog: MatDialog,
        public searchService: UserSearchService
    ) {
        super(dialog, searchService);
    }

    public ngOnChanges(): void {
        this.searchService.extraSearchParams = {
            tag: this.tag,
            active: this.active,
            businessEntity: this.businessEntity,
            team: this.team,
            tagOrTeam: this.tagOrTeam,
        } as IUserSearch;
    }
}
