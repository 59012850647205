import { NgModule } from '@angular/core';
import { SupplyTypesComponent } from '@app/views/settings/pages/supply-types/supply-types/supply-types.component';
import { MatButtonModule } from '@angular/material/button';
import { SupplyTypeDialogComponent } from '@app/views/settings/pages/supply-types/supply-type-dialog/supply-type-dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbTableModule } from 'cb-hub-lib';
import { MatIconModule } from '@angular/material/icon';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { SupplyTypesTableComponent } from '@app/views/settings/pages/supply-types/supply-types-table/supply-types-table.component';
import { CbAlertCardModule } from '@app/shared/components/alert-card/alert-card.module';

@NgModule({
    declarations: [
        SupplyTypesComponent,
        SupplyTypeDialogComponent,
        SupplyTypesTableComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        CbFormsModule,
        MatButtonModule,
        CbHubTableModule,
        CbTableModule,
        MatIconModule,
        CbDialogModule,
        CbClickModule,
        CbWaitForModule,
        CbAlertCardModule,
    ],
    exports: [
        SupplyTypesComponent
    ],
})
export class SupplyTypeModule {}
