import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {LotCostPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {ITakeoffTaskMappedItem} from '@app/logic/takeoff-tasks/interfaces/i.takeoff-task-mapped';
import {TakeoffsLogicService} from '@app/logic/takeoffs/takeoffs.logic.service';
import {PRICING_REVISION_STATUS_ENUM, TAKE_OFF_TASK_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {BehaviorSubject, map} from 'rxjs';
import moment from 'moment/moment';

@Component({
    selector: 'cb-takeoff-card',
    templateUrl: './takeoff-card.component.html',
    styleUrls: ['./takeoff-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeoffCardComponent {

    public PRICING_REVISION_STATUS_ENUM = PRICING_REVISION_STATUS_ENUM;
    private _mappedItem: ITakeoffTaskMappedItem;

    public elapsedTime$$: BehaviorSubject<string> = new BehaviorSubject(null);

    @Input()
    public set mappedItem(mappedItem: ITakeoffTaskMappedItem) {
        const elapsed = moment.duration(mappedItem.elapsedTime);

        if (elapsed !== undefined) {
            const elapsedTime = `${(Math.floor(elapsed.asHours())).toString()
            }:${moment(elapsed.asMilliseconds()).format('mm:ss').toString()}`;
            this.elapsedTime$$.next(elapsedTime);
        }

        this._mappedItem = mappedItem;

    }

    public get mappedItem(): ITakeoffTaskMappedItem {
        return this._mappedItem;
    }

    public get showCompletedDetails(): boolean {
        return (this.mappedItem.statusId === TAKE_OFF_TASK_STATUS_ENUM.Completed || this.mappedItem.statusId === TAKE_OFF_TASK_STATUS_ENUM.Current);
    }


    @Output() public readonly viewTakeOffTaskEventEmitter = new EventEmitter<ITakeoffTaskMappedItem>();
    public selectedTakeOffTaskIndex: number;

    public displayViewManageButton$ = this.lotCostPermissions.observable.pipe(
        map(() => {
            return (this.mappedItem.$canEditTakeOffTask && this.lotCostPermissions.canManageTakeoff() /* manage*/) ||
                !this.mappedItem.$canEditTakeOffTask;
        }));

    constructor(
        public readonly logicService: TakeoffsLogicService,
        private readonly lotCostPermissions: LotCostPermissions,
        private readonly navigationService: NavigationService) {
    }

    public canCreateTakeoff(): boolean {
        return this.lotCostPermissions.canCreateTakeoff();
    }

    public goToTakeOff(): void {
        this.navigationService.navigate([`/lots/${this.mappedItem.lotId}/costs/takeoffs`],
            undefined,
            { queryParams: { paramEntityId: this.mappedItem.id } });
    }

    public viewTakeOffTask(): void {
        this.viewTakeOffTaskEventEmitter.emit(this.mappedItem);
    }

    public getStatusIconIcon(): string {
        switch (this.mappedItem.statusId) {
            case TAKE_OFF_TASK_STATUS_ENUM.Completed:
            case TAKE_OFF_TASK_STATUS_ENUM.Current:
                return 'check_circle';
            case TAKE_OFF_TASK_STATUS_ENUM.InQueue:
                return 'timelapse';
            case TAKE_OFF_TASK_STATUS_ENUM.InProgress:
                return 'timer';
            case TAKE_OFF_TASK_STATUS_ENUM.Cancelled:
                return 'delete_forever';
            default:
                return 'timelapse';
        }
    }

    public getStatusIconClass(): string {
        switch (this.mappedItem.statusId) {
            case TAKE_OFF_TASK_STATUS_ENUM.Current:
                return 'mat-primary';
            case TAKE_OFF_TASK_STATUS_ENUM.InQueue:
            case TAKE_OFF_TASK_STATUS_ENUM.Completed:
            case TAKE_OFF_TASK_STATUS_ENUM.InProgress:
                return 'mat-accent';
            case TAKE_OFF_TASK_STATUS_ENUM.Cancelled:
                return 'mat-warn';
            default:
                return 'mat-accent';
        }
    }

    public getStatusIconTitle(): string {
        switch (this.mappedItem.statusId) {
            case TAKE_OFF_TASK_STATUS_ENUM.Current:
                return 'Current';
            case TAKE_OFF_TASK_STATUS_ENUM.InQueue:
                return 'In Queue';
            case TAKE_OFF_TASK_STATUS_ENUM.InProgress:
                return 'In Progress';
            case TAKE_OFF_TASK_STATUS_ENUM.Cancelled:
                return 'Cancelled';
            case TAKE_OFF_TASK_STATUS_ENUM.Completed:
                return 'Completed';
            default:
                return 'none';
        }
    }

    public get canViewTakeoff(): boolean {
        return this.lotCostPermissions.canViewTakeoffs();
    }
}
