import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SYSTEM_AREA_ENUM, TASK_TYPE_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';

import { CouncilRfiPermissions } from '@app/core/permissions';
import { CouncilRfiViewService } from './../service/council-rfi-view.service';
import { ICouncilRfiMappedItem } from '@app/logic/council-rfis';
import { ITaskType } from '@app/logic/tasks/interfaces/i.Task.dto';
import { Subscription } from 'rxjs';
import { NavigationService } from '@app/core/services/navigation/navigation.service';

enum TabIndexes {
    Details = 0,
    ChangeRecords = 1,
    Tasks = 2,
    Questions = 3,
    Files = 4,
}

@Component({
    selector: 'cb-manage-council-rfi',
    templateUrl: './manage-council-rfi.component.html',
    styleUrls: ['./manage-council-rfi.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageCouncilRfiComponent implements OnInit, OnDestroy {
    public taskTypes: Array<ITaskType> = [
        {
            canCreate: true,
            id: TASK_TYPE_CONSTANTS_CONST.MeetingTaskType,
            label: 'Meeting',
        },
        {
            canCreate: true,
            id: TASK_TYPE_CONSTANTS_CONST.GeneralTaskType,
            label: 'Task',
        }
    ];

    public readonly SYSTEM_AREA = SYSTEM_AREA_ENUM.CouncilRfi;

    private _councilRfi: ICouncilRfiMappedItem;
    private _subscription: Subscription;

    public get councilRfi(): ICouncilRfiMappedItem {
        return this._councilRfi;
    }

    public selectedTabIndex: number;

    public documentCount = 0;
    public taskCount = 0;
    public allTasksClosed = false;
    public changeRecordCount = 0;
    public allChangeRecordsComplete = false;
    public questionsCount = 0;

    constructor(
        public readonly councilRfiPermissions: CouncilRfiPermissions,
        public readonly councilRfiViewService: CouncilRfiViewService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly navigation: NavigationService,
    ) {
        this._councilRfi = this.councilRfiViewService.councilRfi;
        this._subscription = this._councilRfi.$updated.subscribe(() => {
            this.cdRef.detectChanges();
        });
    }
    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.loadSelectedTab();
    }

    public loadSelectedTab(): void {
        if (this.councilRfiViewService.questionNumber) {
            this.selectedTabIndex = TabIndexes.Questions;
        } else {
            const params = this.navigation.getQueryParams();
            if (params?.paramEntityId) {
                this.selectedTabIndex = TabIndexes.Tasks;
            }
        }
    }

}
