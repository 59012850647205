import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkingDrawingChangeStatusEnumId, WORKING_DRAWING_CHANGE_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-working-drawing-change-status-checkbox',
    templateUrl: './working-drawing-change-status-checkbox.component.html',
    styleUrls: ['./working-drawing-change-status-checkbox.component.scss']
})
export class WorkingDrawingChangeStatusCheckboxComponent implements OnInit {
    @Input() public edit: boolean;
    @Input() public changeStatus: number;
    @Output() public readonly statusUpdated = new EventEmitter<WorkingDrawingChangeStatusEnumId>();

    constructor() { }

    public ngOnInit(): void {
        if (this.changeStatus === WORKING_DRAWING_CHANGE_STATUS_ENUM.None) {
            this.setStatus(WORKING_DRAWING_CHANGE_STATUS_ENUM.NotStarted);
        }
    }

    public isCompletedChange(): boolean {
        return this.changeStatus === WORKING_DRAWING_CHANGE_STATUS_ENUM.Completed;
    }

    public isNotStartedChange(): boolean {
        return this.changeStatus === WORKING_DRAWING_CHANGE_STATUS_ENUM.NotStarted;
    }

    public completeChangeClicked(): void {
        // switch to NotDoing status
        this.setStatus(WORKING_DRAWING_CHANGE_STATUS_ENUM.NotStarted);
    }

    public notStartedChangeClicked(): void {
        // switch to Completed status
        this.setStatus(WORKING_DRAWING_CHANGE_STATUS_ENUM.Completed);
    }

    private setStatus(status: WorkingDrawingChangeStatusEnumId): void {
        this.changeStatus = status;
        this.statusUpdated.emit(status);
    }
}
