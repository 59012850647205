<div class="flex flex-row">
    <cb-business-account-location-picker class="flex-25"
                                         [region]="region"
                                         [district]="district"
                                         [area]="area"
                                         [availableLocations]="availableLocations"
                                         (locationChange)="onLocationChanged($event)">
    </cb-business-account-location-picker>
    <div class="cb-margin-left-3x locations-container flex-col flex">
        <form class="start-center flex-row">
            <cb-select label="Filter by Contract Type"
                       name="contactType"
                       [(ngModel)]="contactType"
                       [options]="contactTypes"
                       (change)="refreshData()"></cb-select>
            <div class="end-center flex-row flex">
                <button mat-raised-button
                        (click)="onManageLocationsClicked()"
                        color="primary">
                    Manage Locations
                </button>
            </div>
        </form>
        <div class="cb-margin-top-2x cb-margin-bottom-3x flex">
            <strong *ngIf="!region && !district && !area">All Locations</strong>
            <strong *ngIf="region">{{regionLabel}}</strong>
            <strong *ngIf="district">, {{districtLabel}}</strong>
            <strong *ngIf="area">, {{areaLabel}}</strong>
        </div>
        <div class="flex-wrap flex">
            <div class="cb-padding flex-33 flex-col md-50 lt-md-100"
                 *ngFor="let accountLocation of accountLocations">
                <mat-card>
                    <mat-card-header>
                        <mat-icon mat-card-avatar
                                  class="location-icon">{{accountLocation.contactTypeIcon}}</mat-icon>
                        <mat-card-title>
                            <small>{{accountLocation.name}}</small>
                        </mat-card-title>
                        <mat-card-subtitle>{{accountLocation.contactType}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="flex-row flex">
                        <form class="flex-col flex">
                            <cb-input label="Email"
                                      name="email"
                                      class="cb-margin-top-2x"
                                      [ngModel]="accountLocation.email"
                                      [readonly]="true"></cb-input>
                            <cb-input label="Postion"
                                      name="position"
                                      class="cb-margin-top-2x"
                                      [ngModel]="accountLocation.position"
                                      [readonly]="true"></cb-input>
                            <cb-input label="Mobile"
                                      name="mobile"
                                      class="cb-margin-top-2x"
                                      [ngModel]="accountLocation.phoneMobile"
                                      [readonly]="true"></cb-input>
                            <mat-divider></mat-divider>
                            <cb-input label="Location"
                                      name="locationLabel"
                                      class="cb-margin-top-2x"
                                      [ngModel]="accountLocation.locationLabel"
                                      [readonly]="true"></cb-input>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
