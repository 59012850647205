<cb-dialog>
    <div class="flex-col flex">
        <p class="mat-body-1">{{data.message}}</p>
    </div>
    <footer class="center-center flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="yes-button"
                color="primary"
                (click)="yes()">{{data.yesLabel}}</button>
        <button mat-raised-button
                (click)="no()">{{data.noLabel}}</button>
    </footer>
</cb-dialog>
