import {Injectable} from '@angular/core';
import {StandardPlanLogicService} from '@app/logic/standard-plans';
import {IStandardPlanSearchFiltersItem} from '@app/logic/standard-plans/interfaces/i.standard-plan-search-filter';
import {takeOne} from 'cb-hub-lib';
import {Observable} from 'rxjs';
import {SearchService} from './base.search.service';

@Injectable({
    providedIn: 'root'
})
export class StandardPlanSearchServiceService extends SearchService {

    constructor(
        public readonly standardPlanLogicService: StandardPlanLogicService,
    ) {
        super();
    }

    public getResults(query: string): Observable<IStandardPlanSearchFiltersItem[]> {
        return new Observable<IStandardPlanSearchFiltersItem[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;

                const resultsObservable = this.standardPlanLogicService.$getSearchList({ query });

                if (!resultsObservable) {
                    return;
                }

                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<IStandardPlanSearchFiltersItem>(x);
                        subscriber.next(this.searchResults$.value as IStandardPlanSearchFiltersItem[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as IStandardPlanSearchFiltersItem[]);
                subscriber.complete();
            }
        });
    }

    public readonly doSearch = (query: string): Observable<IStandardPlanSearchFiltersItem[]> => {
        this.searchResultsLoaded = false;
        return this.getResults(query).pipe(takeOne());
    };
}
