import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/** Initialise the app */
function initApp(): void {
    if (environment.production) {
        enableProdMode();
    }

    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
}

// If the browser is IE do not start the app
if (!!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/MSIE/))) {
    window.location.replace('ie.html');
} else {
    initApp();
}
