import { NgModule } from '@angular/core';
import { TooltipComponent } from './tooltip/tooltip.component';



@NgModule({
    declarations: [TooltipComponent],
    imports: [],
    exports: [
        TooltipComponent,
    ]
})
export class CbTooltipModule { }
