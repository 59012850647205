import { Component, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { WipDocument, WipService } from '@app/logic/wip/wip.service';
import { CbTruncateModule } from '@app/shared/pipe/truncate/truncate.module';
import { finalize } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'cb-wip-table',
    templateUrl: './wip-table.component.html',
    styleUrls: ['./wip-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, CommonModule, CbTruncateModule],
})
export class WipTableComponent implements OnInit {
    public displayedColumns: string[] = [
        'jobNumber', 'lotNumber', 'jobStatus', 'projectName', 'projectReleaseStage',
        'address', 'contractType', 'client', 'buildingConsultant', 'constructionManager', 'buildType',
        'titleStatus', 'titleDate', 'consentLodgedDate', 'consentDueOutDate', 'consentApprovedDate', 'unconditionalDate'
    ];

    public maxLength = 20;
    public maxProjectLength = 25;
    public currentPage = 1;
    public dataSource: WipDocument[] = [];
    private hasMoreDocuments = true;
    private isLoading = false;
    private readonly pageSize = 100;
    private readonly preLoadDistance = 400;

    constructor(private wipService: WipService) {}

    public ngOnInit(): void {
        this.fetchWipDocuments();
    }

    public onScroll(event: Event): void {
        const target = event.target as HTMLElement;
        const position = target.scrollTop + target.clientHeight;
        const maxScroll = target.scrollHeight;

        if (position >= maxScroll - this.preLoadDistance && !this.isLoading && this.hasMoreDocuments) {
            this.fetchWipDocuments();
        }
    }

    private fetchWipDocuments(): void {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        this.wipService.loadMoreWipDocuments(this.currentPage).pipe(
            finalize(() => this.isLoading = false)
        ).subscribe({
            next: (documents) => {
                this.dataSource = [...this.dataSource, ...documents];
                this.hasMoreDocuments = documents.length === this.pageSize;
                if (this.hasMoreDocuments) {
                    this.currentPage++;
                }
            },
            error: (error) => {
                console.error('Failed to fetch WIP documents', error);
            }
        });
    }

    public onJobNumberClick(id: string): void {
        const url = `${environment.uri}/lots/${id}/summary`;
        const newTab = window.open(url, '_blank');

        if (newTab) {
            newTab.focus();
        }
    }
}
