import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IWorkingDrawingMappedItem, WorkingDrawingsLogicService } from '@app/logic/working-drawings';
import { IWorkingDrawingChangeDto, WorkingDrawingChangeStatusEnumId, WORKING_DRAWING_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-working-drawing-side-bar',
    templateUrl: './working-drawing-side-bar.component.html',
    styleUrls: ['./working-drawing-side-bar.component.scss']
})
export class WorkingDrawingSideBarComponent implements OnInit {

    public WORKING_DRAWING_STATUS_ENUM = WORKING_DRAWING_STATUS_ENUM;
    public changesBeingEdited: any = {};
    public changesPreviousComments: any = {};
    public changesPreviousStatus: any = {};
    public isEditingAChange = false;

    @Input() public mappedItem: IWorkingDrawingMappedItem;

    public elapsedTime: string;

    constructor(
        private readonly workingDrawingsLogicService: WorkingDrawingsLogicService,
        private readonly cdRef: ChangeDetectorRef,
    ) {
    }

    public ngOnInit(): void {
        this.getElapsedTime();
    }

    public getElapsedTime(): void {
        this.mappedItem.getElapsedTime().subOnce(x => this.elapsedTime = x);
    }

    public isChangeBeingEdited(changeId: number): boolean {
        return this.changesBeingEdited[changeId];
    }

    public isEditWorkingDrawingChangeDisabled = (): boolean => {
        return this.isEditingAChange || this.mappedItem.statusId !== WORKING_DRAWING_STATUS_ENUM.InProgress;
    };

    public changeEditClicked(change: IWorkingDrawingChangeDto): void {
        this.isEditingAChange = true;

        if (this.changesBeingEdited[change.id] === undefined) {
            this.changesBeingEdited[change.id] = true;
        } else {
            this.changesBeingEdited[change.id] = !this.changesBeingEdited[change.id];
        }

        this.changesPreviousComments[change.id] = change.comments;
        this.changesPreviousStatus[change.id] = change.statusId;
    }

    public changeSaveClicked(change: IWorkingDrawingChangeDto): void {
        this.workingDrawingsLogicService.updateWorkingDrawingChange(change)
            .subOnce(() => {
                this.changesBeingEdited[change.id] = false;
                this.isEditingAChange = false;
                this.cdRef.detectChanges();
            });
    }

    public changeCancelClicked(change: IWorkingDrawingChangeDto): void {
        this.changesBeingEdited[change.id] = false;
        change.comments = this.changesPreviousComments[change.id];
        change.statusId = this.changesPreviousStatus[change.id];
        this.isEditingAChange = false;
    }

    public statusUpdated(change: IWorkingDrawingChangeDto, status: WorkingDrawingChangeStatusEnumId): void {
        change.statusId = status;
    }
}
