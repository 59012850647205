import { Component, OnInit, Inject } from '@angular/core';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IDocumentEntityDto } from '@app/logic/documents';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IDocumentTableExtraColumn } from '../../interfaces/i.document-table-column';
import { toPromise } from 'cb-hub-lib';
import { HttpErrorResponse } from '@angular/common/http';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { NZ_LOCALE } from '@app/shared/declarations/app.constants';

interface IData {
    documents: IDocumentEntityMappedItem[];
    prefixColumns: IDocumentTableExtraColumn[];
}

@Component({
    templateUrl: './download-multiple-documents-dialog.component.html',
    styleUrls: ['./download-multiple-documents-dialog.component.scss']
})
export class DownloadMultipleDocumentsDialogComponent extends BaseDialogFormViewDirective<IDocumentEntityDto, IDocumentEntityMappedItem, any> implements OnInit {
    public static readonly MIN_WIDTH = '980px';
    public downloadableDocuments = [] as IDocumentEntityMappedItem[];
    public loaded = false;
    public zipFileName = `${new Date().toLocaleDateString(NZ_LOCALE).split('/').reverse().join('-')}-documents`;

    public downloading = false;
    public successful = {} as { [documentEntityId: number]: boolean };
    public failed = {} as { [documentEntityId: number]: string };

    constructor(
        public readonly dialogRef: MatDialogRef<DownloadMultipleDocumentsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.downloadableDocuments.push(...this.data.documents.filter(x => x.document?.id > 0));
        this.loaded = true;
    }

    public async download(): Promise<void> {
        this.successful = {};
        this.failed = {};
        this.downloading = true;
        const zip = new JSZip();
        for (const doc of this.downloadableDocuments) {
            await toPromise(doc.downloadDocumentSilent())
                .then((response) => {
                    const fileType = response.headers.get('content-type').trim();
                    this.successful[doc.id] = true;
                    zip.file(
                        response.headers.get('content-disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
                        new Blob([response.body], { type: fileType })
                    );
                })
                .catch((ex: HttpErrorResponse) => {
                    this.failed[doc.id] = ex.message;
                });
        }
        if (Object.values(this.successful).some(x => x)) {
            zip.generateAsync({ type: 'blob' }).then((zipFile) => {
                FileSaver.saveAs(zipFile, `${this.zipFileName}.zip`);
                this.downloading = false;
            });
        } else {
            this.downloading = false;
        }
    }
}
