<div class="cb-margin">
    <mat-card class="cb-margin-bottom flex-col">
        <form #form="ngForm">
            <div class="start-center flex-row flex">
                <mat-icon>search</mat-icon>
                <cb-input type="text"
                          label="Search"
                          [(ngModel)]="searchFiltersCache.data.query"
                          (ngModelChange)="this.searchFiltersChanged$.next($event)"
                          name="search"
                          class="cb-margin-right-2x flex">
                </cb-input>
                <span class="flex"></span>
                <cb-button class="cb-margin"
                           (clicked)="createRevision()">
                    Create Revision
                </cb-button>
            </div>
        </form>
    </mat-card>
    <div class="scroll-container"
         [cbInfiniteScroll]="listContainer"
         [fetch]="fetchResults()"
         *cbWaitFor="cacheLoaded$ | async"
         [queryUpdated]="searchFiltersChanged$"
         [enabled]="searchEnabled$ | async"
         [(results)]="results"
         [(currentPage)]="currentPage">
        <div #listContainer
             class="list-container flex-wrap xs-column flex">
            <cb-loading-spinner *ngIf="results == null"
                                message="Fetching results">
            </cb-loading-spinner>
            <cb-info-message *ngIf="results != null && results.length == 0"
                             [message]="'There were no Price Revisions found'"
                             [icon]="'info_circle'"></cb-info-message>
            <div class="flex-col xs-100 flex-25"
                 *ngFor="let result of results">
                <cb-pricing-revision-card class="flex"
                                          [mappedItem]="result"
                                          (viewClick)="viewPricingRevision($event)">
                </cb-pricing-revision-card>
            </div>
        </div>
    </div>
</div>
