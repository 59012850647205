export enum TabNames {
    AddressRegions = 'address-regions',
    BankAccounts = 'bank-accounts',
    BuildActivities = 'build-activities',
    BuildStages = 'build-stages',
    BundleTemplates = 'bundle-templates',
    BusinessAccounts = 'business-accounts',
    BusinessEntities = 'business-entities',
    BuildTypes = 'build-types',
    ComplianceRegistration = 'compliance-registration',
    ColourItems = 'colour-items',
    Councils = 'councils',
    DesignWorkflow = 'design-workflow',
    DocumentGroups = 'document-groups',
    DocumentNotifications = 'document-notifications',
    DocumentTypes = 'document-types',
    DocumentTemplates = 'document-templates',
    ExternalSystemFields = 'external-system-fields',
    FeatureToggles = 'feature-toggles',
    GlobalSettings = 'global-settings',
    HouseAreas = 'house-areas',
    IntegrationLogs = 'integration-logs',
    Locations = 'locations',
    Maintenance = 'maintenance',
    NonWorkingDays = 'non-working-days',
    NotificationTemplates = 'notification-templates',
    PaymentFrequencies = 'payment-frequencies',
    PriceOptions = 'price-options',
    PaymentTemplates = 'payment-templates',
    QSWorkflow = 'qs-workflow',
    SearchIndexes = 'search-indexes',
    ScheduleItemExternal = 'schedule-item-external',
    ScheduleItemTags = 'schedule-item-tags',
    ScheduleItems = 'schedule-items',
    StandardPlans = 'standard-plans',
    SystemLogs = 'system-logs',
    Tags = 'tags',
    TaskOutcomes = 'task-outcomes',
    Teams = 'teams',
    TradeTypes = 'trade-types',
    SupplyTypes= 'supply-types'
}
