import { Component, Input, OnDestroy, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { IBusinessEntityMappedItem } from '@app/logic/business-entity/interfaces/i.business-entity.mapped';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { BaseSearchViewDirective, SearchFilters } from '@app/shared/base-views/base-search-view.directive';
import { TableColumn } from '@app/shared/components/table/base-table.component';
import { FormMode } from '@app/shared/enums/form';
import { IAddressDto } from '@app/shared/interfaces/i.address.dto';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

@Component({
    selector: 'cb-business-entities',
    templateUrl: './business-entities.component.html',
    styleUrls: ['./business-entities.component.scss'],
    providers: [BusinessEntitiesLogicService, UsersLogicService]
})
export class BusinessEntitiesComponent extends BaseSearchViewDirective<any> implements OnDestroy {
    public formMode: FormMode;
    public searchResults: IBusinessEntityDto[] = [];
    public activityCode: string;
    public activityName: string;
    public selectedActivity: IBusinessEntityDto;
    public regions: IBusinessEntityDto[];
    public isSupplierTypesEnabled: boolean;
    @Input() public mappedItem: IBusinessEntityMappedItem;
    public isElasticSearch = false;
    public get businessEntityForm(): NgForm {
        return this.businessEntityFormParent.first;
    }
    public sideBarBusinessEntityCode: string;
    public sideBarBusinessEntityName: string;
    @ViewChildren('businessEntityForm') public businessEntityFormParent: any;
    @ViewChild('infiniteScrollContainerBusinessEntities', { static: true }) public infiniteScrollContainer: { nativeElement: { scrollHeight: number } };

    public readonly columns: Array<TableColumn> = [
        { name: 'Business Entity Name', valueProp: 'name', type: 'text' },
        { name: 'Active', valueProp: 'isActive', type: 'checkbox' },
        {
            name: '', valueProp: 'actions', type: 'actions', actions: [
                { function: this.editClicked.bind(this), icon: 'edit' }
            ]
        }
    ];

    constructor(
        public readonly navigationService: NavigationService,
        public readonly toastService: ToastService,
        public readonly currentUser: CurrentUserService,
        public readonly userCacheService: UserCacheService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: MatDialog,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        private readonly featureToggleService: FeatureToggleStatesService
    ) {
        super(
            businessEntitiesLogicService,
            toastService,
            userCacheService
        );
        this.formMode = FormMode.View;
        featureToggleService.init().then(() => {
            this.isSupplierTypesEnabled = featureToggleService.isSupplierTypesEnabled;
        });
        this.businessEntitiesLogicService.$getList().subOnce(x => {
            this.searchResults = this.sortSearchResults(x);
        });
    }

    protected getExtraSearchFilters(): any {
        return {};
    }

    public formDirty = (): boolean => {
        if (!this.businessEntityForm) {
            return false;
        } else {
            return this.businessEntityForm.dirty;
        }
    };

    public formValid = (): boolean => {
        if (!this.businessEntityForm) {
            return false;
        } else {
            return this.businessEntityForm.valid;
        }
    };

    public saveMethod(): any {
        return this.businessEntitiesLogicService.editBusinessEntity(this.mappedItem.$getMappedDtoItem());
    }

    public goBack(): void {
        this.businessEntityForm.form.reset({ physicalAddress: {}, postalAddress: {} });
        this.mappedItem = undefined;
        this.formMode = FormMode.View;
    }

    public newBusinessEntityClicked(): void {
        this.mappedItem = this.businessEntitiesLogicService.$createMappedItem({ physicalAddress: {} as IAddressDto, postalAddress: {} as IAddressDto, });
        this.sideBarBusinessEntityCode = '';
        this.sideBarBusinessEntityName = '';
        this.formMode = FormMode.Edit;
    }

    public generateReport(): void {
        this.businessEntitiesLogicService
            .generateReport()
            .subOnce({ next: undefined, error: this.handleError });
    }

    protected handleError = (err): void => {
        console.error(err);
    };

    public handleSaveSuccess = (_: IBusinessEntityDto): void => {
        this.businessEntityForm.form.markAsPristine();
        this.formMode = FormMode.View;
        this.businessEntitiesLogicService.$getList().subOnce(x => {
            this.searchResults = this.sortSearchResults(x);
        });
    };

    public sortSearchResults = (searchResults: Array<IBusinessEntityDto>): Array<IBusinessEntityDto> => {
        return searchResults.sort((a, b) => a.name.localeCompare(b.name));
    };

    public applySavedSearchFiltersToComponent = (searchFilters: SearchFilters): void => {
        this.activityCode = searchFilters.activityCode;
        this.activityName = searchFilters.activityName;
    };

    public editClicked(businessEntity: IBusinessEntityDto): void {
        this.selectedActivity = businessEntity;

        this.businessEntitiesLogicService
            .$getItem(this.selectedActivity.id)
            .subOnce(x => {
                this.mappedItem = this.businessEntitiesLogicService
                    .$createMappedItem(x);
                this.sideBarBusinessEntityName = x.name;
            });
        this.formMode = FormMode.Edit;
    }
}
