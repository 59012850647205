import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { LotDesignPermissions } from '@app/core/permissions';
import { IDesignConceptMappedItem } from '@app/logic/design-concepts';
import { LotsLogicService, ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { SimpleFormInputType } from '@app/shared/components/dialog/simple-form-dialog/simple-form-dialog.component';
import { DESIGN_CONCEPT_STATUS_ENUM, MARKET_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { DesignConceptSellPriceDialogComponent } from '../design-concept-sell-price-dialog/design-concept-sell-price-dialog.component';

@Component({
    selector: 'cb-design-concept',
    templateUrl: './design-concept.component.html',
    styleUrls: ['./design-concept.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignConceptComponent {

    @Input() public designConcept: IDesignConceptMappedItem;
    @Output() public designConceptChange = new EventEmitter<IDesignConceptMappedItem>();
    @Output() public lotMappedItemChange = new EventEmitter();

    @Output() public readonly backToSearch = new EventEmitter();

    @Input() public lot: ILotMappedItem;

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        private readonly lotsLogicService: LotsLogicService,
        private readonly cdref: ChangeDetectorRef
    ) { }


    public goBackToSearch(): void {
        this.backToSearch.emit();
    }


    public canApplyToLot(): boolean {
        return this.lot &&
            this.lot.canApplyConcept &&
            this.designConcept &&
            this.designConcept.statusId !== DESIGN_CONCEPT_STATUS_ENUM.Applied &&
            this.designConcept.sellingPrice > 0;
    }

    public hasPermissionToSetLotAvailableForSale(): boolean {
        return this.lotDesignPermissions.canSetLotAvailableForSale();
    }

    public canSetLotAvailableForSale(): boolean {
        return this.lot.marketStatus !== MARKET_STATUS_ENUM.Available &&
            this.lot.activeDesignConceptId > 0 &&
            !this.lot.hasClientSale;
    }

    public canSetConceptSellPrice(): boolean {
        return this.lotDesignPermissions.canSetConceptSellPrice();
    }

    public canSetConceptExpiryDate(): boolean {
        return this.lotDesignPermissions.canSetConceptExpiryDate();
    }

    public canApplyConcept(): boolean {
        return this.lotDesignPermissions.canApplyConcept();
    }

    public canRequestImages(): boolean {
        return this.lotDesignPermissions.canRequestImages();
    }

    public canAbandonConcept(): boolean {
        return this.lotDesignPermissions.canAbandonConcept();
    }

    public abandonDisabled = (): boolean => {
        return this.designConcept.statusId === DESIGN_CONCEPT_STATUS_ENUM.Applied
            || this.designConcept.statusId === DESIGN_CONCEPT_STATUS_ENUM.Abandoned;
    };

    public optionsMenuDisabled(): boolean {
        return this.designConcept.statusId === DESIGN_CONCEPT_STATUS_ENUM.Abandoned;
    }

    public applyDesignConceptToLot(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Apply Concept To Lot',
            message: 'Are you sure you want to apply this Concept to the Lot?',
            confirmed: () => {
                const ref = this.cbDialog.block('Applying Concept to Lot...');
                this.designConcept.applyToLot()
                    .subOnce({
                        next: (result) => {
                            this.reloadLotAndUpdateDesignConcept();
                            ref.close();
                        },
                        error: () => {
                            ref.close();
                        }
                    });
            }
        });
    }

    public setExpiryDate(): void {
        this.cbDialog.simpleFormDialog({
            dialogHeading: 'Set Concept Expiry Date',
            value: '',
            formInputConfig: {
                type: SimpleFormInputType.DatePicker,
                label: 'Expiry Date',
                required: true,
            },
            confirmed: (result) => {
                this.designConcept.pricingExpiryDate = result;
                this.designConcept.$save().subOnce(() => {
                    this.reloadLotAndUpdateDesignConcept();
                });
            }
        });
    }

    public makeLotAvailableForSale(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Make Lot Available For Sale',
            message: 'Are you sure you want to make this Lot Available for sale?',
            confirmed: () => {
                const ids: string[] = [this.lot.id.toString()];
                this.lotsLogicService.updateMarketStatus(ids, MARKET_STATUS_ENUM.Available).subOnce(() => {
                    this.reloadLotAndUpdateDesignConcept();
                });
            }
        });
    }

    public setSellPrice(): void {
        this.cbDialog.open(DesignConceptSellPriceDialogComponent, {
            data: {
                mappedItem: this.designConcept,
                showClientSaleLostWarning: this.lot.previousClientSaleLost && this.lot.hasExtraToClientVariations
            },
        }).afterClosed().subOnce(() => {
            this.reloadLotAndUpdateDesignConcept();
        });
    }

    private reloadLotAndUpdateDesignConcept(): void {
        this.designConceptChange.emit(this.designConcept);
        this.lot.$reload().subOnce(() => {
            this.lotMappedItemChange.emit();
            this.cdref.detectChanges();
        });
    }

    public requestExteriorImage(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Request Exterior Image',
            message: 'Are you sure you want to request an Exterior Image?',
            confirmed: () => {
                this.designConcept.requestExteriorImage();
            }
        });
    }


    public requestFloorPlanImage(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Request Floor Plan Image',
            message: 'Are you sure you want to request a Floor Plan Image?',
            confirmed: () => {
                this.designConcept.requestFloorPlanImage();
            }
        });
    }

    public abandonConcept(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Abandon Concept',
            message: 'Are you sure you want to cancel this Concept?',
            confirmed: () => {
                this.designConcept.abandonConcept();
            }
        });
    }

}
