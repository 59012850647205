<cb-dialog [dialogHeading]="title.value">
    <form class="flex-col"
          #form="ngForm">
        <cb-input type="text"
                  label="CostX Rate Library Name"
                  name="libraryName"
                  [(ngModel)]="mappedItem.name"
                  required></cb-input>
        <cb-select label="Hub Rate Location"
                   name="hubRateLocation"
                   [(ngModel)]="mappedItem.locationId"
                   [options]="rateLocations"
                   labelProp="code"
                   required></cb-select>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                (cbClick)="save()"
                [clickLimit]="1"
                [disabled]="!rateLocations.$resolved || form.invalid || form.pristine">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
