<mat-drawer-container class="setting-container"
                      autosize>
    <mat-drawer #drawer
                class="setting-sidenav"
                mode="side"
                opened="true">

        <mat-selection-list [multiple]="false"
                            #list
                            (selectionChange)="tabIndexChanged($event, list)">
            <ng-container *ngFor="let setting of settingTabs">
                <mat-list-option *ngIf="setting?.canView"
                                 [value]="setting.url"
                                 [selected]="currentTab === setting.url">
                    {{setting.name}}
                </mat-list-option>
            </ng-container>
        </mat-selection-list>

    </mat-drawer>

    <div class="cb-margin-top">
        <router-outlet>

        </router-outlet>
    </div>
</mat-drawer-container>
