<form #systemAccessForm="ngForm"
      style="overflow: hidden">
    <div class="cb-margin flex-row flex"
         *cbWaitFor="mappedItem">
        <div class="cb-margin-right-3x flex-col flex">
            <h4 class="cb-title">System Areas</h4>
            <mat-divider class="cb-margin-bottom">
            </mat-divider>
            <span class="mat-caption">
                Choose the Areas of the system the User needs access to
            </span>
            <cb-selection-list name="selectedAreas"
                               [ngModel]="mappedItem.selectedAreas"
                               (ngModelChange)="selectedAreasChanged($event, systemAccessForm.form)"
                               [options]="systemAreasOptions$ | async"
                               [readonly]="isView$ | async"
                               [slim]="true"
                               [maxHeight]="(windowHeight - 260) + 'px'"
                               style="margin-bottom: -30px">
            </cb-selection-list>
        </div>
        <div class="cb-margin-right-3x flex-col flex">
            <h4 class="cb-title">Roles</h4>
            <mat-divider class="cb-margin-bottom">
            </mat-divider>
            <span class="mat-caption">
                Choose the Security Roles that apply to this User
            </span>
            <cb-selection-list name="selectedRoles"
                               [ngModel]="mappedItem.selectedRoles"
                               (ngModelChange)="selectedRolesChanged($event, systemAccessForm.form)"
                               [options]="rolesOptions$ | async"
                               [readonly]="isView$ | async"
                               [slim]="true"
                               [maxHeight]="(windowHeight - 260) + 'px'"
                               style="margin-bottom: -30px">
            </cb-selection-list>
        </div>
    </div>
</form>
