import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { IPricingRevisionMappedItem } from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import { PricingRevisionsLogicService } from '@app/logic/pricing-revisions/pricing-revisions.logic.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LOTS_ROUTE } from '../../lots-route.constant';
import { TakeoffCompareService } from '../lot-costs-takeoffs/takeoff-compare/takeoff-compare.service';
import { LotCostPermissions } from '@app/core/permissions';

@Component({
    selector: 'cb-lot-costs-area',
    templateUrl: './lot-costs-area.component.html',
    styleUrls: ['./lot-costs-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotCostsAreaComponent implements OnDestroy, AfterViewInit {

    public readonly LOTS_ROUTE = LOTS_ROUTE;
    public selectedTabIndex: number;
    private _subscriptions = new Subscription();

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    @Output() public reloadLotMappedItem = new EventEmitter();
    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public previousMappedItem: IPricingRevisionMappedItem;
    public selectedPricingRevision$$: BehaviorSubject<IPricingRevisionMappedItem> = new BehaviorSubject(null);
    public get selectedPricingRevision(): IPricingRevisionMappedItem {
        return this.selectedPricingRevision$$?.value;
    }

    constructor(
        private readonly takeoffCompareService: TakeoffCompareService,
        private readonly logicService: LotsLogicService,
        private readonly pricingRevisionsLogicService: PricingRevisionsLogicService,
        private readonly cdRef: ChangeDetectorRef,
        public readonly lotCostPermissions: LotCostPermissions,
    ) {
    }

    public get canViewCostSummary(): boolean {
        return this.lotCostPermissions.canViewCostSummary();
    }
    public get canViewPriceRevisions(): boolean {
        return this.lotCostPermissions.canViewPriceRevisions();
    }
    public get canViewTakeoffs(): boolean {
        return this.lotCostPermissions.canViewTakeoffs();
    }
    public get canViewConfirmedQuantities(): boolean {
        return this.lotCostPermissions.canViewConfirmedQuantities();
    }
    public get canViewActualCosts(): boolean {
        return this.lotCostPermissions.canViewActualCosts();
    }

    public ngAfterViewInit(): void {
        this._subscriptions.add(this.takeoffCompareService.takeoffSaved$.subscribe(() => {
            this.selectedTabIndex = 3;
            this.handleReloadLotMappedItem();
        }));
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public onTabChanged(selectedTabIndex: number): void {
        this.selectedTabIndex = selectedTabIndex;
    }

    public readonly pricingRevisionSelected = (event: IPricingRevisionMappedItem): void => {
        this.selectedPricingRevision$$.next(event);
    };

    public readonly setPreviousMappedItem = (event: IPricingRevisionMappedItem): void => {
        this.previousMappedItem = event;
    };

    public handleGoBack(): void {
        this.lotMappedItem.$reload().subOnce();
        this.selectedPricingRevision$$.next(null);
    }

    public handleReloadSelectedPricingRevision(): void {
        this.selectedPricingRevision.$reload().subOnce((_pricingRevisionDto) => {
            const pricingRevisionMappedItem = this.pricingRevisionsLogicService.$createMappedItem(_pricingRevisionDto);
            this.selectedPricingRevision$$.next(pricingRevisionMappedItem);
            this.cdRef.detectChanges();
        });
    }

    public handleReloadLotMappedItem(): void {
        this.lotMappedItem.$reload().subOnce((_lotDto) => {
            this.lotMappedItem = this.logicService.$createMappedItem(_lotDto);
            this.lotMappedItem$$.next(this.lotMappedItem);
            this.cdRef.detectChanges();
            this.reloadLotMappedItem.emit();
        });
    }
}
