import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CommonModule } from '@angular/common';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserDetailsDialogComponent } from './user-details-dialog/user-details-dialog.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CbInfiniteScrollModule } from 'projects/cb-hub-lib/src/lib/directives/infinite-scroll/infinite-scroll.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { UserSearchComponent } from './user-search/user-search.component';
import { CbInfoMessageModule } from 'projects/cb-hub-lib/src/lib/components/info-message/info-message.module';
import { RouterModule } from '@angular/router';
import { UserSearchCardComponent } from './user-search/user-search-card/user-search-card.component';
import { CbDasherModule } from 'projects/cb-hub-lib/src/lib/pipe/dasher/dasher.module';
import { CbButtonModule } from '@app/shared/components/button/button.module';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UniqueUserNameValidatorDirective } from './user-details/unique-username-validator.directive';
import { UserSideBarCardComponent } from './user-side-bar-card/user-side-bar-card.component';
import { UserAreaComponent } from './user-area/user-area.component';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { SystemAccessComponent } from './system-access/system-access.component';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { PermissionsComponent } from './permissions/permissions.component';
import { CbSortByImpureModule } from '@app/shared/pipe/sort-by-impure/sort-by-impure.module';

const MATERIAL_MODULES = [
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    MatChipsModule,
    MatRadioModule,
    MatTabsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
];

const CB_MODULES = [
    CbInfiniteScrollModule,
    CbDisplayValueModule,
    CbWaitForModule,
    CbDialogModule,
    CbFormsModule,
    CbInfoMessageModule,
    CbDasherModule,
    CbButtonModule,
    CbTabRouteModule,
    CbSortByModule,
    CbSortByImpureModule,
];

const MISCELLANEOUS_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
];

@NgModule({
    declarations: [
        UniqueUserNameValidatorDirective,
        UserDetailsDialogComponent,
        UserSearchComponent,
        UserSearchCardComponent,
        UserDetailsComponent,
        UserSideBarCardComponent,
        UserAreaComponent,
        SystemAccessComponent,
        PermissionsComponent
    ],
    exports: [
        UserDetailsDialogComponent
    ],
    imports: [
        RouterModule.forRoot(
            [{
                component: UserSearchComponent,
                path: 'users/search',
                data: { breadcrumb: 'User Search' }
            },
            {
                component: UserAreaComponent,
                path: 'users',
                data: {
                    breadcrumb: 'User Details',
                    breadcrumbPredecessors: [
                        { label: 'User Search', url: '/users/search' }
                    ]
                },
            },
            {
                component: UserAreaComponent,
                path: 'users/:id',
                data: {
                    breadcrumb: 'View User',
                    breadcrumbPredecessors: [
                        { label: 'User Search', url: '/users/search' }
                    ]
                }
            },
            {
                component: UserAreaComponent,
                path: 'users/:id/details',
                data: {
                    breadcrumb: 'View User',
                    breadcrumbPredecessors: [
                        { label: 'User Search', url: '/users/search' }
                    ]
                }
            },
            {
                component: UserAreaComponent,
                path: 'users/:id/system-access',
                data: {
                    breadcrumb: 'System Access',
                    breadcrumbPredecessors: [
                        { label: 'User Search', url: '/users/search' }
                    ]
                }
            },
            {
                component: UserAreaComponent,
                path: 'users',
                data: {
                    breadcrumb: 'View User',
                    breadcrumbPredecessors: [
                        { label: 'User Search', url: '/users/search' }
                    ]
                }
            },
            {
                component: UserAreaComponent,
                path: 'users/:id/permissions',
                data: {
                    breadcrumb: 'Permissions',
                    breadcrumbPredecessors: [
                        { label: 'User Search', url: '/users/search' }
                    ]
                }
            },
            {
                component: UserAreaComponent,
                path: 'users/:id/systemAccess',
                data: {
                    breadcrumb: 'System Access',
                    breadcrumbPredecessors: [
                        { label: 'User Search', url: '/users/search' }
                    ]
                }
            }],
            {
    onSameUrlNavigation: 'reload'
}
        ),
        ...MATERIAL_MODULES,
        ...CB_MODULES,
        ...MISCELLANEOUS_MODULES
    ]
})
export class CbUsersModule { }
