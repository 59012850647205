export enum LotQuotePermissionEnum {
    None = 0x00,
    CanView = 0x01 << 0,
    CanRequest = 0x01 << 1,
    CanCreate = 0x01 << 2,
    CanManage = 0x01 << 3,
    CanEdit = 0x01 << 4,
    CanSubmit = 0x01 << 5,
    CanAbandon = 0x01 << 6
}
