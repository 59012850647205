import { CostNatureEnumId, COST_NATURE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

export class UtilIcons {
    public static getCostNatureIcon(costNature: CostNatureEnumId): string {
        switch (costNature) {
            case COST_NATURE_ENUM.ExtraToClassicNZ:
                return 'location_city';
            case COST_NATURE_ENUM.ExtraToClient:
                return 'person';
            case COST_NATURE_ENUM.ExtraToSupplier:
                return 'local_shipping';
            case COST_NATURE_ENUM.ExtraToBranch:
                return 'home';
            default:
                return 'help';
        }
    }

    public static getCostNatureIconTitle(costNature: CostNatureEnumId): string {
        switch (costNature) {
            case COST_NATURE_ENUM.ExtraToClassicNZ:
                return 'Extra to Classic';
            case COST_NATURE_ENUM.ExtraToClient:
                return 'Extra to Client';
            case COST_NATURE_ENUM.ExtraToSupplier:
                return 'Extra to Supplier';
            case COST_NATURE_ENUM.ExtraToBranch:
                return 'Extra to Branch';
            default:
                return 'help';
        }
    }
}
