import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DocumentsLogicService, IDocumentEntityMappedItem } from '@app/logic/documents';
import { ILotMappedItem } from '@app/logic/lots';
import { INonWorkingDayDto, NonWorkingDayLogicService } from '@app/logic/non-working-day';
import { ITakeoffTaskMappedItem } from '@app/logic/takeoff-tasks/interfaces/i.takeoff-task-mapped';
import { ITakeoffTasksLogicService } from '@app/logic/takeoff-tasks/interfaces/i.takeoff-tasks.logic.service';
import { TakeoffsLogicService } from '@app/logic/takeoffs/takeoffs.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ICostXImportResultDto, ITakeOffDtoSummary, ITakeOffTaskBaseDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPromised, toPromisedArray } from 'cb-hub-lib';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'cb-upload-takeoff-dialog',
    templateUrl: './upload-takeoff-dialog.component.html',
    styleUrls: ['./upload-takeoff-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadTakeoffDialogComponent extends BaseDialogFormViewDirective<
ITakeOffTaskBaseDto,
ITakeoffTaskMappedItem,
ITakeoffTasksLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '400px';
    public canSetStartDate = false;
    public canSetEndDate = false;
    public readonly lot: ILotMappedItem;
    public takeOffResult: ICostXImportResultDto;
    public takeOffTaskId: number;
    public takeOffs: ITakeOffDtoSummary[];
    public document: IDocumentEntityMappedItem;
    public nonWorkingDays: IPromised<INonWorkingDayDto[]>;
    public lotEstimatedStartDate: Date;
    public lotEstimatedEndDate: Date;

    constructor(
        private readonly dialogService: CbDialogService,
        @Inject(DocumentsLogicService) private readonly documentsLogic: DocumentsLogicService,
        private readonly takeoffsLogicService: TakeoffsLogicService,
        public readonly dialogRef: MatDialogRef<UploadTakeoffDialogComponent>,
        private readonly nonWorkingDaysLogicService: NonWorkingDayLogicService,
        public readonly toastSerivce: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            document: IDocumentEntityMappedItem;
            lot: ILotMappedItem;
            takeOffResult: ICostXImportResultDto;
            takeOffTaskId: number;
            takeOffs: ITakeOffDtoSummary[];
        }
    ) {
        super(dialogRef, toastSerivce);
        this.lot = data.lot;
        this.document = data.document;
        this.takeOffs = data.takeOffs;
        this.takeOffTaskId = data.takeOffTaskId;
        this.takeOffResult = data.takeOffResult;
    }

    public ngOnInit(): void {
        this.nonWorkingDays = toPromisedArray(this.nonWorkingDaysLogicService.getNonWorkingDaysForLot(this.lot.id));
        this.canSetStartDate = !(this.lot.jobStart.actual ?? this.lot.jobStart.estimate);
        this.canSetEndDate = !(this.lot.jobComplete.actual ?? this.lot.jobComplete.estimate);
    }

    public saveDisabled(): boolean {
        return !((this.document?.document?.uploadedFile)
            && ((this.canSetStartDate && this.lotEstimatedStartDate !== undefined) || !this.canSetStartDate)
            && ((this.canSetEndDate && this.lotEstimatedEndDate !== undefined) || !this.canSetEndDate));
    }

    public saveMethod(): Observable<ICostXImportResultDto> {
        const fd = new FormData();
        fd.append('file', this.document.document.uploadedFile);
        fd.append('takeOffTaskId', this.takeOffTaskId as any);
        if (this.canSetStartDate) {
            fd.append('lotEstimatedStartDate', this.lotEstimatedStartDate.toJSON());
        }
        if (this.canSetEndDate) {
            fd.append('lotEstimatedEndDate', this.lotEstimatedEndDate.toJSON());
        }

        const blockRef = this.dialogService.block('Please wait... uploading file');
        return this.takeoffsLogicService.upload(fd as any).pipe(
            tap((_) => { blockRef.close(); })
        );
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
