<cb-dialog dialogHeading="Lead Details">
    <form class="flex-col"
          #editLeadForm="ngForm">
        <div class="flex-col">
            <cb-input class="flex"
                      label="Lead Name"
                      type="text"
                      name="name"
                      [(ngModel)]="mappedItem.name"
                      [required]="true">
            </cb-input>
            <cb-select class="flex"
                       label="Status"
                       name="status"
                       [(ngModel)]="mappedItem.status"
                       [options]="statusList"
                       [required]="true">
            </cb-select>
            <div class="start-start flex-row"
                 id="building-consultant">
                <cb-users-input-find-table class="flex"
                                           label="Select Building Consultant"
                                           [(ngModel)]="mappedItem.buildingConsultantId"
                                           name="selectedBuildingConsultant"
                                           [text]="mappedItem?.buildingConsultantName"
                                           [removeable]="true"
                                           [required]="true"
                                           [searchParams]="userSearchParams">
                </cb-users-input-find-table>
            </div>
        </div>
        <footer class="end-center flex-row flex">
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (click)="save()"
                    [disabled]="!editLeadForm.dirty || !editLeadForm.valid">Save</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
