import { Component, OnInit } from '@angular/core';
import { sonarQubeExportData } from './sonar-data';
import { copyElementContentsToClipboard } from '@app/shared/utils/copy-select-html.util';
import { orderBy } from 'lodash';

interface ISonarJsonData {
    total: number;
    p: number;
    ps: number;
    rules: ISonarRules[];
}

interface ISonarRules {
    key: string;
    repo: string;
    name: string;
    createdAt: string;
    htmlDesc: string;
    mdDesc: string;
    severity: string;
    status: string;
    isTemplate: boolean;
    tags: [];
    sysTags: string[];
    lang: string;
    langName: string;
    params: [];
    defaultDebtRemFnType: string;
    defaultDebtRemFnOffset: string;
    debtOverloaded: boolean;
    debtRemFnType: string;
    debtRemFnOffset: string;
    defaultRemFnType: string;
    defaultRemFnBaseEffort: string;
    remFnType: string;
    remFnBaseEffort: string;
    remFnOverloaded: boolean;
    scope: string;
    isExternal: boolean;
    type: string;
}

@Component({
    selector: 'cb-sonar-table',
    templateUrl: './sonar-table.component.html',
    styleUrls: ['./sonar-table.component.scss']
})
export class SonarTableComponent implements OnInit {

    public readonly data = sonarQubeExportData as ISonarJsonData;

    public loaded = false;

    constructor() {
        this.data.rules = orderBy(orderBy(this.data.rules, 'type', 'asc'), (rule) => {
            switch (rule.severity) {
                case 'BLOCKER':
                    return 1;
                case 'CRITICAL':
                    return 2;
                case 'MAJOR':
                    return 3;
                case 'MINOR':
                    return 4;
                default:
                    return 5;
            }
        }) as ISonarRules[];
    }

    public ngOnInit(): void {
        setTimeout(() => {
            this.loaded = true;
        }, 200);
    }

    public copyInfoToClipboard(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        copyElementContentsToClipboard('#info-table');
    }
}
