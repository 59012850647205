import { Component, Input, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { LOT_TYPE_ENUM, LOT_JOB_STATUS_ENUM, MARKET_STATUS_ENUM, ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { DragulaService } from 'ng2-dragula';
import { BehaviorSubject, Subscription } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { orderBy } from 'lodash';
import { MatLegacySlideToggleChange as MatSlideToggleChange, MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

const MAT_HEADER_ROW_CLASSNAME = 'mat-header-row';
const NUM_HEADER_ROWS = 1;
const ORDER_PROP = 'unitConstructionOrder';

@Component({
    selector: 'cb-lot-units-list',
    templateUrl: './lot-units-list.component.html',
    styleUrls: ['./lot-units-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotUnitsListComponent implements OnDestroy {
    public readonly LOT_TYPE_ENUM = LOT_TYPE_ENUM;
    public readonly LOT_JOB_STATUS_ENUM = LOT_JOB_STATUS_ENUM;
    public readonly MARKET_STATUS_ENUM = MARKET_STATUS_ENUM;
    public readonly LOT_UNITS = 'LOT_UNITS';
    public dragulaModel: number[] = [];
    public sortOrderChanged = false;
    public subscriptions$ = new Subscription();
    public lotsLoaded = false;
    public reorderEnabled = false;

    public editableOrder: { [lotId: number]: number } = {};
    private originalOrder: { [lotId: number]: number } = {};

    public readonly displayedColumns: string[] = [
        'lotNumber',
        'address',
        'lotType',
        'buildLink',
        'floorArea',
        'landPrice',
        'sellPrice',
        'contractPrice',
        'jobNumber',
        'jobStatus',
        'bed',
        'bath',
        'living',
        'garage',
        'marketStatus',
        'actions'
    ];

    public lots: ILotDto[] = [];

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);

    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);

            this.lotsLogic
                .getUnits(mappedItem.id)
                .subOnce(units => {
                    this.lots = units;
                    this.loadLots();
                });
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public readonly reorderingAllowed = new ComputedProperty(() => {
        return !this.lots?.find(x => x.isPrimaryUnit)?.hasBuildProgrammeGenerated;
    });

    constructor(
        private readonly lotsLogic: LotsLogicService,
        private readonly dragulaService: DragulaService,
        private readonly navigation: NavigationService,
        private readonly cbDialog: CbDialogService,
        private readonly cdRef: ChangeDetectorRef,
    ) {
        this.setupDragula();
    }

    public toggleReorder(event: MatSlideToggleChange): void {
        if (!event.checked && this.sortOrderChanged) {
            this.cbDialog.confirm({
                dialogHeading: 'Discard Changes',
                message: 'Are you sure you want to discard your changes?',
                confirmed: () => {
                    this.setReorderToggle(false, event.source);
                    this.updateLotOrder();
                },
                declined: () => {
                    this.setReorderToggle(true, event.source);
                }
            });
        } else {
            this.setReorderToggle(event.checked, event.source);
            this.editableOrder = {};
        }
    }

    public saveOrder(): void {
        this.lotsLogic
            .reorderUnits(this.lotMappedItem.id, this.editableOrder)
            .subOnce(result => {
                this.originalOrder = result;
                this.setReorderToggle(false);
                this.updateLotOrder();
            });
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.dragulaService.destroy(this.LOT_UNITS);
    }

    public viewLot(event: MouseEvent, lotId: number): void {
        this.navigation.redirectTo(`lots/${lotId}/summary`);
    }

    private setReorderToggle(value: boolean, source?: MatSlideToggle): void {
        this.reorderEnabled = value;
        if (source) {
            source.checked = value;
        }
        this.cdRef.detectChanges();
    }

    /** Creates dragula group for address regions table and suscribes to dragula observables */
    private setupDragula(): void {
        this.dragulaService.createGroup(
            this.LOT_UNITS,
            {
                removeOnSpill: false,
                accepts: (el: Element) => !el.classList.contains(MAT_HEADER_ROW_CLASSNAME), // do not allow move of mat-header-row
                moves: (el: Element) => this.reorderingAllowed.value
                    && this.reorderEnabled
                    && !el.classList.contains(MAT_HEADER_ROW_CLASSNAME), // do not allow move of mat-header-row
                revertOnSpill: true
            }
        );

        this.subscriptions$.add(
            this.dragulaService.dropModel(this.LOT_UNITS)
                .subscribe(
                    ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                        if (sourceIndex === targetIndex) {
                            return;
                        }
                        moveItemInArray(this.lots, sourceIndex - NUM_HEADER_ROWS, targetIndex - NUM_HEADER_ROWS);
                        this.lots.forEach((lot, index) => {
                            this.editableOrder[lot.id] = index + 1;
                        });
                        this.setDragulaModel();
                        this.sortOrderChanged = true;
                    }
                )
        );
    }
    /** Sets the dragula model for drag and drop - seperate to the actual array of data because
     * the dragula model needs an item for the header row aswell...
     */
    private setDragulaModel(): void {
        this.dragulaModel = this.lots.map(x => x.id);
        // add a placeholder row for each header row delcared
        for (let headerRow = 0; headerRow < NUM_HEADER_ROWS; headerRow++) {
            this.dragulaModel.unshift(0);
        }
    }

    private loadLots(): void {
        this.lots = orderBy(this.lots, ORDER_PROP);
        this.lots.forEach((lot) => {
            this.originalOrder[lot.id] = lot.unitConstructionOrder;
        });
        this.setDragulaModel();
        this.lotsLoaded = true;
        this.cdRef.detectChanges();
        this.reorderingAllowed.recompute();
    }

    private updateLotOrder(): void {
        this.lots.forEach((lot) => {
            lot.unitConstructionOrder = this.originalOrder[lot.id];
        });
        this.editableOrder = {};
        const ordered = orderBy(this.lots, ORDER_PROP);
        this.sortOrderChanged = false;
        // set the lots to empty and detect changes before reordering, otherwise the reordering is not rendered in the ui
        this.lots = [];
        this.cdRef.detectChanges();
        this.lots = ordered;
        this.setDragulaModel();
        this.cdRef.detectChanges();
    }
}
