import {Injectable} from '@angular/core';
import {ILotDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {FieldValidationItem} from '../field.validation.item';
import {IProjectReleaseLotFieldValidatorService} from './interfaces/i.project-release-lot-field-validator.service';
import {ProjectReleaseLotFieldRulesService} from './project-release-lot-field-rules.service';


@Injectable()
export class ProjectReleaseLotFieldValidatorService implements IProjectReleaseLotFieldValidatorService {

    public dto: ILotDto;
    public hasPriceRevisions: boolean;

    constructor(protected projectReleaseLotRulesService: ProjectReleaseLotFieldRulesService) {

    }

    public amenitiesFloorArea(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.amenitiesFloorAreaEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.amenitiesFloorAreaRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.amenitiesFloorAreaVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public amenitiesLandArea(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.amenitiesLandAreaEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.amenitiesLandAreaVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public amenitiesNoBathrooms(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.amenitiesNoBathroomsEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.amenitiesNoBathroomsRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.amenitiesNoBathroomsVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public amenitiesNoBedrooms(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.amenitiesNoBedroomsEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.amenitiesNoBedroomsRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.amenitiesNoBedroomsVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public amenitiesNoGarages(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.amenitiesNoGaragesEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.amenitiesNoGaragesRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.amenitiesNoGaragesVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public amenitiesNoCarparks(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.amenitiesNoCarparksEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.amenitiesNoCarparksRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.amenitiesNoCarparksVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public amenitiesNoLivingRooms(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.amenitiesNoLivingRoomsEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.amenitiesNoLivingRoomsRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.amenitiesNoLivingRoomsVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public buildType(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.buildTypeEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.buildTypeRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);


        item.isVisible = this.projectReleaseLotRulesService.buildTypeVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public landPrice(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.financialsLandPriceEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.financialsLandPriceRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.financialsLandPriceVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public lotAddress(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.lotAddressEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.lotAddressRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);


        item.isVisible = this.projectReleaseLotRulesService.lotAddressVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public lotNumber(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.lotNumberEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isRequired = this.projectReleaseLotRulesService.lotNumberRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.lotNumberVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public jobNumber(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.jobNumberEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions,
            this.dto.lotSpecLocked);

        item.isRequired = this.projectReleaseLotRulesService.jobNumberRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions,
            this.dto.lotSpecLocked);

        item.isVisible = this.projectReleaseLotRulesService.jobNumberVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public sellingPrice(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.financialsSellPriceEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);


        item.isRequired = this.projectReleaseLotRulesService.financialsSellPriceRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.financialsSellPriceVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public roadDirection(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.roadDirectionEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);


        item.isRequired = this.projectReleaseLotRulesService.roadDirectionRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.roadDirectionVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public lotHoldingDeposit(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.lotHoldingDepositEditable(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);


        item.isRequired = this.projectReleaseLotRulesService.lotHoldingDepositRequired(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        item.isVisible = this.projectReleaseLotRulesService.lotHoldingDepositVisible(
            this.dto.lotType,
            this.dto.contractType,
            this.hasPriceRevisions);

        return item;
    }

    public hasExternalLandVendor(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.hasExternalLandVendorEditable(
            this.dto.contractType);

        item.isRequired = this.projectReleaseLotRulesService.hasExternalLandVendorRequired(
            this.dto.contractType);

        item.isVisible = this.projectReleaseLotRulesService.hasExternalLandVendorVisible(
            this.dto.contractType);

        return item;
    }

    public lotPurchasePrice(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.lotPurchasePriceEditable(
            this.dto.contractType);

        item.isRequired = this.projectReleaseLotRulesService.lotPurchasePriceRequired(
            this.dto.contractType);

        item.isVisible = this.projectReleaseLotRulesService.lotPurchasePriceVisible(
            this.dto.contractType);

        return item;
    }

    public lotDeposit(): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isEditable = this.projectReleaseLotRulesService.lotDepositEditable(
            this.dto.contractType);

        item.isRequired = this.projectReleaseLotRulesService.lotDepositRequired(
            this.dto.contractType);

        item.isVisible = this.projectReleaseLotRulesService.lotDepositVisible(
            this.dto.contractType);

        return item;
    }
}
