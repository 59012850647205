<cb-loading-spinner *ngIf="loading"
                    message="Please wait loading.."
                    class="cb-margin">
</cb-loading-spinner>
<cb-info-message *ngIf="!loading && (quantityAccount == null || quantityAccount?.buildStages == null || quantityAccount?.buildStages?.length == 0)"
                 message="There are no Confirmed Quantities for this Lot"
                 icon="info_circle"
                 class="cb-margin"></cb-info-message>
<ng-container *ngIf="!loading && quantityAccount?.buildStages?.length">
    <div style="margin-bottom:0;"
         class="top-panel cb-margin end-center flex-row">
        <button class="cb-margin-left"
                mat-raised-button
                color="primary"
                (click)="expandCollapseAll()">
            <span class="start-center flex-row"
                  *ngIf="!getAllExpanded()">
                <span>Expand All&nbsp;</span>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </span>
            <span class="start-center flex-row"
                  *ngIf="getAllExpanded()">
                <span>Collapse All&nbsp;</span>
                <mat-icon>keyboard_arrow_up</mat-icon>
            </span>
        </button>
    </div>
    <mat-accordion [multi]="true"
                   class="accordion cb-padding-bottom">
        <mat-expansion-panel class="panel"
                             *ngFor="let stage of quantityAccount?.buildStages"
                             [(expanded)]="expandedAreas['stage' + stage?.id]"
                             (expandedChange)="expandedChange()">
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title>
                    <span class="mat-body-2 cb-margin-top">
                        {{stage?.label}}&nbsp;({{stage?.buildActivities?.length}})
                    </span>
                </mat-panel-title>
                <mat-panel-description class="end-center flex-row">
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="flex-col"
                 *ngFor="let activity of stage?.buildActivities">
                <span class="mat-body-2">
                    <mat-icon *ngIf="activity.notes"
                              class="mat-accent help-cursor"
                              title="{{activity.notes}}">
                        insert_drive_file
                    </mat-icon>
                    &#9;{{activity.code}} - {{activity.name}}{{getCostXName(activity)}}
                </span>
                <span *ngIf="activity.hasMultipleSuppliers"
                      style="color: red; margin-left: 20px;"
                      class="mat-body-2">
                    <mat-icon class="mat-warn">warning</mat-icon>
                    &nbsp;
                    Activity lines contain multiple Suppliers
                </span>
                <cb-table class="cb-margin-top flex"
                          label="Confirmed Quantities"
                          maxHeightOffset="280"
                          [loaded]="true"
                          [(results)]="stage.buildActivities">
                    <thead cbTableHead>
                        <tr>
                            <td></td>
                            <td>Product ID</td>
                            <td>Product Name</td>
                            <td>Supplier</td>
                            <td class="right-align">Quantity</td>
                            <td class="right-align">UOM</td>
                            <td class="right-align">Rate</td>
                            <td class="right-align">Subtotal</td>
                            <td *ngIf="isTakeOffBudgetsEnabled()"
                                class="right-align">QS Budget</td>
                            <td *ngIf="isAiRateSystemEnabled()"
                                class="right-align"></td>
                        </tr>
                    </thead>
                    <tbody cbTableBody>
                        <ng-container *ngFor="let item of activity?.takeOffItems">
                            <tr>
                                <td>
                                    <mat-icon *ngIf="(isDescriptiveOnly$(item) | async)"
                                              value="notes">
                                    </mat-icon>
                                </td>
                                <td>
                                    <cb-td-text [value]="item.code"></cb-td-text>
                                </td>
                                <td>
                                    <cb-td-text [value]="item.name"></cb-td-text>
                                </td>
                                <td>
                                    <cb-td-text [value]="item.supplier"></cb-td-text>
                                </td>
                                <td align="right">
                                    <cb-td-text [value]="item.quantity"></cb-td-text>
                                </td>
                                <td align="right">
                                    <cb-td-text [value]="item.uom"></cb-td-text>
                                </td>
                                <td class="right-align">
                                    <cb-currency name="rate"
                                                 [ngModel]="item.takeOffRate"
                                                 readonly="true"></cb-currency>
                                </td>
                                <td class="right-align">
                                    <cb-currency name="subTotal"
                                                 [ngModel]="calculateSubTotal(item.takeOffRate, item.quantity)"
                                                 readonly="true"></cb-currency>
                                </td>
                                <td class="right-align"
                                    *ngIf="isTakeOffBudgetsEnabled()">
                                    <cb-currency name="budget"
                                                 [ngModel]="item.budget"
                                                 readonly="true"></cb-currency>
                                </td>
                                <td *ngIf="isAiRateSystemEnabled()"
                                    class="right-align">
                                    <button mat-icon-button
                                            *ngIf="CanQueryThisRate(item)"
                                            (click)="queryRate(item, activity)">
                                        <mat-icon [matTooltip]="'Ask AI about this rate and QS budget'">fingerprint</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngFor="let item of activity?.takeOffItems">
                            <tr *ngIf="item?.notes">
                                <td style="width: 30px;">
                                    <mat-icon class="cb-small-icon">insert_drive_file</mat-icon>
                                </td>
                                <td></td>
                                <td>
                                    <span class="mat-body-1 preserve-line-breaks">{{item.notes}}</span>
                                </td>
                                <td colspan="5"></td>
                                <td *ngIf="isTakeOffBudgetsEnabled()"></td>
                                <td *ngIf="isAiRateSystemEnabled()"></td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td colspan="7"></td>
                            <td class="mat-body-2 right-align">
                                <cb-currency name="activitySubtotal"
                                             [ngModel]="activity?.subtotal"
                                             readonly="true"></cb-currency>
                            </td>
                            <td *ngIf="isTakeOffBudgetsEnabled()"
                                class="mat-body-2 right-align">
                                <cb-currency name="budgetSubtotal"
                                             [ngModel]="getBudgetTotalsForTakeoffItems(activity?.takeOffItems)"
                                             readonly="true"></cb-currency>
                            </td>
                            <td *ngIf="isAiRateSystemEnabled()"></td>
                        </tr>
                        <tr *ngIf="activity?.costXGroupName?.length > 0">
                            <td colspan="7"
                                class="right-align"
                                style="font-style: italic;">Split Activity Subtotal</td>
                            <td class="mat-body-2 right-align"
                                style="font-style: italic;">
                                <cb-currency name="costXGroupSubtotal"
                                             [ngModel]="activity?.costXGroupSubtotal"
                                             readonly="true"></cb-currency>
                            </td>
                            <td *ngIf="isAiRateSystemEnabled()"></td>
                        </tr>
                    </tbody>
                </cb-table>
            </div>
            <div class="cb-margin flex-row">
                <div class="flex-col flex"></div>
                <div class="flex-col">
                    <h3>{{ getStageLabel(stage) }}</h3>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
