<cb-dialog>
    <form class="flex-col flex"
          #defaultInstallerForm="ngForm">
        <cb-display-value-new *ngIf="isEdit()"
                              label="Trade Type"
                              [value]="mappedItem?.tradeType?.label">
        </cb-display-value-new>
        <cb-select *ngIf="isAdd()"
                   [required]="true"
                   name="selectedTradeTypeId"
                   label="Trade Type"
                   [(ngModel)]="selectedTradeTypeId"
                   (change)="selectedTradeTypeChanged()"
                   [options]="availableTrades">
        </cb-select>
        <cb-business-accounts-input-find-cards label="Business Account"
                                               [(ngModel)]="selectedSupplier"
                                               name="selectedSupplier"
                                               [text]="selectedSupplier?.tradingName"
                                               [disabled]="false"
                                               [required]="true"
                                               (change)="supplierChanged()">
        </cb-business-accounts-input-find-cards>
        <div class="flex-row"
             *ngIf="invalidSupplier">
            <mat-card class="cb-error-card">
                <mat-card-content>
                    No Installer contact has been set up for this business account and trade type combination at this location.
                </mat-card-content>
            </mat-card>
        </div>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="defaultInstallerForm.invalid || defaultInstallerForm.pristine || invalidSupplier">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
