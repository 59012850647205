import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {LotBuildPermissions} from '@app/core/permissions';
import {IProductDto, IRateDto} from '@app/logic/products/interfaces/i.product.dto';
import {IProductMappedItem, ProductLogicService} from '@app/logic/products';
import {IProductLogicService} from '@app/logic/products/interfaces/i.product.logic.service';
import {FormMode} from '@app/shared/enums/form';
import {LookupService} from '@app/core/services/lookup/lookup.service';
import {BundleTemplatesLogicService} from '@app/logic/bundle-templates';
import {TradeTypesLogicService} from '@app/logic/trade-types/trade-types.logic.service';
import {CategoryLogicService} from '@app/logic/product-categories';
import {filter, find, flatMap, forEach, orderBy} from 'lodash';
import {BusinessAccountLogicService} from '@app/logic/business-accounts';
import {Observable} from 'rxjs';
import {BUSINESS_ACCOUNT_STATUS_ENUM, UNIT_OF_MEASURE_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {LocationLogicService} from '@app/logic/location';
import {ILocationItemDto, ILocationTreeDto} from '@app/logic/location/interfaces/i.location.dto';
import {ReleaseStagesLogicService} from '@app/logic/release-stages/release-stages.logic.service';
import {ProjectsLogicService} from '@app/logic/projects';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {IEnum} from '@app/shared/interfaces/i.lookup.dto';

interface IData {
    mappedItem: IProductMappedItem;
    rate: IRateDto;
}

@Component({
    templateUrl: './manage-rates-dialog.component.html',
    styleUrls: ['./manage-rates-dialog.component.scss'],
    providers: [
        LookupService,
        BundleTemplatesLogicService,
        ProductLogicService,
        TradeTypesLogicService,
        CategoryLogicService,
        ReleaseStagesLogicService
    ]
})
export class ManageRatesDialogComponent
    extends BaseDialogFormViewDirective<IProductDto, IProductMappedItem, IProductLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '70%';

    public uom = UNIT_OF_MEASURE_ENUM.toLookup();
    public unit: string;
    public enumNotSelected: IEnum = { id: 0, label: 'None' } as IEnum;

    // external resources
    public regions: ILocationItemDto[];
    public areas: ILocationItemDto[];
    public districts: ILocationItemDto[];
    public rateAreas: any;
    public projects: IEnum[];
    public releases: IEnum[];
    public hasProjectsLoaded = false;
    public hasReleasesLoaded = false;

    public regionSelected = 0;
    public districtSelected = 0;
    public areaSelected = 0;
    public projectSelected = 0;
    public releaseSelected = 0;

    public allSelected: ILocationItemDto = { id: 0, label: 'All', name: 'All' } as ILocationItemDto;

    public rate: IRateDto; // passed in
    public dateRateAppliesObject: Date;

    public supplier: any;
    public mappedItem: IProductMappedItem;

    public businessAccountSearchParams = {
        s: [
            BUSINESS_ACCOUNT_STATUS_ENUM.Active,
            BUSINESS_ACCOUNT_STATUS_ENUM.Overridden,
            BUSINESS_ACCOUNT_STATUS_ENUM.Pending,
            BUSINESS_ACCOUNT_STATUS_ENUM.OnHold
        ]
    };

    constructor(
        public readonly dialogRef: MatDialogRef<ManageRatesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LookupService) public readonly lookupService: LookupService,
        @Inject(LotBuildPermissions) public readonly lotBuildPermissions: LotBuildPermissions,
        protected readonly bundleTemplatesLogicService: BundleTemplatesLogicService,
        protected readonly productCategoriesLogicService: CategoryLogicService,
        protected readonly productLogicService: ProductLogicService,
        protected readonly businessAccountLogicService: BusinessAccountLogicService,
        protected readonly tradeTypesLogicService: TradeTypesLogicService,
        protected readonly cbDialog: CbDialogService,
        protected readonly locationLogicService: LocationLogicService,
        protected readonly releaseStagesLogicService: ReleaseStagesLogicService,
        protected readonly projectsLogicService: ProjectsLogicService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.rate = data.rate;
        if (this.mappedItem.id) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }
    }

    public ngOnInit(): void {
        if (this.rate !== undefined) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }
        this.supplier = { tradingName: this.rate.vendor };
        this.districts = [this.allSelected];
        this.areas = [this.allSelected];
        this.projects = [this.enumNotSelected];
        this.releases = [this.enumNotSelected];
        this.unit = find(this.uom, { id: this.mappedItem.uom }).label;
        this.getLocationTree(this.rate.businessAccountId).subOnce({
            next: x => this.rateAreas = x
        });
        this.rate.appliedRatesFromObject = this.rate.appliedRatesFrom ?
            new Date(this.rate.appliedRatesFrom) : new Date();
        this.getLocationTree(this.rate.businessAccountId).subOnce(() => {
            [
                this.regionSelected = 0,
                this.districtSelected = 0,
                this.areaSelected = 0
            ] = flatMap(this.rate.areaPath, area => area.id);
            this.projectSelected = this.rate.projectId;
            this.releaseSelected = this.rate.releaseStageId;
            this.initLocations();
        });
    }

    private initLocations(): void {
        this.setRegions();
        if (!this.regionSelected) {
            return;
        }
        this.setDistricts(this.regionSelected);
        if (!this.districtSelected) {
            return;
        }
        this.setAreas(this.districtSelected);
        if (!this.areaSelected) {
            return;
        }
        this.setProjects(this.areaSelected);
        if (!this.projectSelected) {
            return;
        }
        this.setReleases(this.projectSelected);
    }

    public hasProjectSelected(): boolean {
        return this.projectSelected > 0;
    }

    public getLocationTree(businessAccountId?: number): Observable<ILocationTreeDto> {

        if (businessAccountId) {
            return this.businessAccountLogicService.orderContactAreas(businessAccountId);
        }

        return this.locationLogicService.getLocationTree();
    }

    public supplierSelected(): void {
        this.rate.vendor = this.supplier.tradingName;
        this.rate.businessAccountId = this.supplier.id;

        this.regions = [this.allSelected];
        this.districts = [this.allSelected];
        this.areas = [this.allSelected];
        this.projects = [this.enumNotSelected];
        this.releases = [this.enumNotSelected];

        this.getLocationTree(this.rate.businessAccountId).subOnce({
            next: x => {
                this.rateAreas = x;
                this.setRegions();
                this.setDistricts(this.regionSelected);
                this.setAreas(this.districtSelected);
            }
        });
    }

    public selectLocationExists(areas: ILocationItemDto[], id): boolean {

        const area = find(areas, { id });

        return (area != null);
    }

    public setRegions(): void {

        const locations = (this.rateAreas?.treeItems || this.rateAreas) as ILocationItemDto[];


        const regionAreas = locations?.filter((a) => {
            return a?.locationType === 1; // regions
        });


        this.regions = orderBy(regionAreas, ['name'], ['asc']);

        if (!this.selectLocationExists(regionAreas, this.regionSelected)) {
            this.regionSelected = 0;
        }

        this.regions.unshift(this.allSelected);
    }

    public setDistricts(regionId: number): void {

        if (regionId <= 0) {
            this.districtSelected = 0;
            this.areaSelected = 0;
            return;
        }

        const parent: ILocationItemDto[] = this.regions.filter((region) => {
            return region.id === regionId;
        });

        if (parent.length <= 0) {
            return;
        }


        this.districts = orderBy(parent[0].children, ['name'], ['asc']);
        if (!this.selectLocationExists(this.districts, this.districtSelected)) {

            this.districtSelected = 0;
        }

        this.districts.unshift(this.allSelected);
    }

    public setAreas(districtId: number): void {

        if (districtId <= 0) {
            this.areaSelected = 0;
            return;
        }

        const parent: ILocationItemDto[] = this.districts.filter((district) => {
            return district.id === districtId;
        });

        if (parent.length <= 0) {
            return;
        }

        this.areas = orderBy(parent[0].children, ['name'], ['asc']);

        if (!this.selectLocationExists(this.areas, this.areaSelected)) {
            this.areaSelected = 0;
        }

        this.areas.unshift(this.allSelected);
    }

    public setProjects(areaId: number): void {

        if (areaId <= 0) {
            this.hasProjectsLoaded = false;
            return;
        }

        this.projectsLogicService.getProjectsByArea(areaId).subOnce((projects) => {
            this.hasProjectsLoaded = true;
            projects.unshift(this.enumNotSelected);
            const filtered = filter(projects, (o) => (o as any).hasProjectLevelRates);
            forEach(filtered, (o) => (o as any).label = (o as any).projectName);
            this.projects = filtered as unknown as IEnum[];
        });
    }

    public setReleases(projectId: number): void {
        if (projectId <= 0) {
            this.hasReleasesLoaded = false;
            return;
        }

        const releaseStages = this.projectsLogicService.getProjectReleaseLabels(projectId);
        releaseStages.subOnce({
            next: (results: any) => {
                this.releases.unshift(this.enumNotSelected);
                this.releases = results;
                this.hasReleasesLoaded = true;
            }
        });
    }

    public regionChanged(): void {
        this.districtSelected = 0;
        this.areaSelected = 0;
        this.districts = [this.allSelected];
        this.areas = [this.allSelected];
        this.setDistricts(this.regionSelected);
    }

    public districtChanged(): void {
        this.areaSelected = 0;
        this.areas = [this.allSelected];
        this.setAreas(this.districtSelected);
    }

    public save(): void {
        this.rate.locationId = this.areaSelected || this.districtSelected || this.regionSelected;
        this.rate.projectId = this.projectSelected || undefined;
        this.rate.releaseStageId = this.releaseSelected || undefined;

        if (this.rate.locationId === 0) {
            this.rate.locationId = undefined;
        }
        const observer = result => {
            if (result) {
                this.dialogRef.close(result);
                this.toastSerivce.saveSuccess();
                return;
            }
            this.cancel();
        };
        this.mappedItem.saveProductRate(this.rate, observer);
    }
}
