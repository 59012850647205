import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ClassCtor } from '@app/shared/types/classctor.type';
import { ILotSpecScheduleItemDto, IQuoteLineRefDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import {
    ILotSpecScheduleItemMappedItem,
    ILotSpecScheduleItemLogicService
} from '.';
import { BaseLogicService } from '../base/base-logic.service';
import { LotSpecScheduleItemMappedItem } from './lot-spec-schedule-item.mapped';


@Injectable()
export class LotSpecScheduleItemLogicService
    extends BaseLogicService<ILotSpecScheduleItemDto, ILotSpecScheduleItemMappedItem>
    implements ILotSpecScheduleItemLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'lotspecschedule',
            LotSpecScheduleItemMappedItem
        );
    }

    /**
     * Override $createMappedItem to convert childItems to mappedItems also.
     */
    public $createMappedItem<ReturnType = ILotSpecScheduleItemMappedItem>(
        defaultData = {} as Partial<ILotSpecScheduleItemDto>,
        mappedItemClass = this.$defaultMappedItemCtor as unknown as ClassCtor<ReturnType>
    ): ReturnType {
        const mappedItem = super.$createMappedItem(defaultData);
        mappedItem.childItems = mappedItem
            .childItems.map(childItems => super.$createMappedItem(childItems));
        return mappedItem as unknown as ReturnType;
    }

    public $getItem(entityId: number, lotId: number): Observable<ILotSpecScheduleItemDto> {
        return this.$http.get<ILotSpecScheduleItemDto>(`${lotId}/${this.$baseUri}/item/${entityId}`);
    }

    public $getMappedItem(entityId: number, lotId: number): Observable<ILotSpecScheduleItemMappedItem> {
        return super.$getMappedItem(entityId, lotId);
    }

    public $saveItem(entity: ILotSpecScheduleItemDto, lotId?: number): Observable<ILotSpecScheduleItemDto> {
        return this.$http.post<ILotSpecScheduleItemDto>(`${lotId}/${this.$baseUri}/item/${entity.id}`, entity);
    }

    public $addItem(entity: ILotSpecScheduleItemDto, lotId?: number): Observable<ILotSpecScheduleItemDto> {
        return this.$http.post<ILotSpecScheduleItemDto>(`${lotId}/${this.$baseUri}/item/create`, entity);
    }

    public uploadScheduleItemImage(lotSpecScheduleItemVersionId: number, lotId: number, formFile: FormData): Observable<ILotSpecScheduleItemDto> {
        return this.$http.post(`${lotId}/${this.$baseUri}/item/${lotSpecScheduleItemVersionId}/image`, formFile);
    }

    public removeScheduleItemImage(lotSpecScheduleItemVersionId: number, lotId: number): Observable<boolean> {
        return this.$http.delete(`${lotId}/${this.$baseUri}/item/${lotSpecScheduleItemVersionId}/image`);
    }

    public delete(entity: ILotSpecScheduleItemDto, lotId: number): Observable<boolean> {
        return this.$http.delete(`${lotId}/${this.$baseUri}/item/${entity.lotSpecScheduleItemVersionId}`);
    }

    public getQuoteLine(lotScheduleItemId: number): Observable<IQuoteLineRefDto> {
        return this.$http.get(`${this.$baseUri}/quoteline/${lotScheduleItemId}`);
    }

    public getIsLotSpecLocked(lotSpecScheduleItemId: number): Observable<boolean> {
        return this.$http.get<boolean>(`${this.$baseUri}/item/${lotSpecScheduleItemId}/islotspeclocked`);
    }
}
