import {Injectable} from '@angular/core';
import {remove} from 'lodash';
import {Toast} from '@app/views/app-root/components/toasts/toast.class';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    public toasts = new Array<Toast>();

    public get lastToast(): Toast {
        return this.toasts[this.toasts.length - 1];
    }
    constructor(
    ) { }

    public showToast(
        content: string,
        buttonLabel = 'Ok',
        hideDelay?: number,
        buttonAction?: Function,
        uniqueKey?: string,
        canBeCleared?: boolean,
        templateCtrl?: Object
    ): Toast {
        const toast = new Toast(
            content,
            this,
            buttonLabel,
            hideDelay,
            buttonAction,
            uniqueKey,
            canBeCleared,
            templateCtrl
        );
        toast.id = !this.lastToast ? 1 : this.lastToast.id + 1;
        this.toasts.push(toast);
        toast.top = toast.fromTop;

        // hiding is handled in the ToastComponent
        return toast;
    }

    public saveSuccess = (): void => {
        this.showToast('Saved successfully', 'OK', 5000, this.deleteToast.bind(this));
    };

    public saveError = (message): void => {
        this.showToast(message);
    };

    public clearAll(toastKey?: string): void {
        this.toasts = this.toasts.filter(toast => !(toast.canBeCleared || toast.uniqueKey === toastKey));
    }

    public deleteToast(id: number): void {
        remove(this.toasts, t => t.id === id);
    }
}
