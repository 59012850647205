import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { FormsModule } from '@angular/forms';
import { LotAmenitiesDialogComponent } from './amenities-dialog/amenities-dialog.component';
import {LotSwitchReleaseStageDialogComponent} from '@app/views/lot/shared/lot-switch-release-stage-dialog/lot-switch-release-stage-dialog.component';
import {CbWaitForModule} from '@app/shared/directives/wait-for/wait-for.module';



@NgModule({
    declarations: [
        LotAmenitiesDialogComponent,
        LotSwitchReleaseStageDialogComponent
    ],
    exports: [
        LotAmenitiesDialogComponent,
        LotSwitchReleaseStageDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        CbDialogModule,
        CbFormsModule,
        MatButtonModule,
        CbWaitForModule,
        CbClickModule,
    ]
})
export class LotSharedModule { }
