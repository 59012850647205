/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {Injectable} from '@angular/core';
import {LOT_CONTRACT_TYPE_ENUM, LOT_TYPE_ENUM, LotContractTypeEnumId, LotTypeEnumId} from '@classictechsolutions/hubapi-transpiled-enums';
import {ILotFieldRulesService, LOT_COMPONENT_TYPE} from './interfaces/i.lot.field.rules.service';

@Injectable()
export class LotFieldRulesService implements ILotFieldRulesService {

    private isTerracedOrDuplex(lotType: LotTypeEnumId): boolean {
        return lotType === LOT_TYPE_ENUM.TerracedBlock || lotType === LOT_TYPE_ENUM.Duplex;
    }

    private isHouseAndLandOrDesignAndBuild(lotContractType: LotContractTypeEnumId): boolean {
        return lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild;
    }

    public appliedDesignConceptVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return !this.isTerracedOrDuplex(lotType);
    }

    public actualCompletionDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE,
        isStarted: boolean): boolean {
        if (componentType === LOT_COMPONENT_TYPE.EditLotDetails || componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            if (lotType !== LOT_TYPE_ENUM.Land && isStarted) {
                return true;
            }
        }

        return false;
    }

    public actualStartDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE,
        isStarted: boolean): boolean {
        if (componentType === LOT_COMPONENT_TYPE.EditLotDetails || componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            if (lotType !== LOT_TYPE_ENUM.Land && isStarted) {
                return true;
            }
        }

        return false;
    }

    public amenitiesFloorAreaVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.InsuranceDetails || componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public amenitiesFloorAreaEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesFloorAreaVisible(lotType, lotContractType, componentType);
    }

    public amenitiesFloorAreaRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return false;
    }

    public amenitiesLandAreaVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.InformationDetails || componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {

                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {

                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {

                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {

                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {

                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public amenitiesLandAreaEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesLandAreaVisible(lotType, lotContractType, componentType);
    }

    public amenitiesLandAreaRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return false;
    }

    public amenitiesNoBathroomsVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public amenitiesNoBathroomsEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoBathroomsRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoBedroomsVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoBedroomsEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBedroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoBedroomsRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBedroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoGaragesVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoGaragesEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoGaragesRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoLivingRoomsVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoLivingRoomsEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType);
    }

    public amenitiesNoLivingRoomsRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType);
    }

    public buildProgrammeTemplateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (this.isTerracedOrDuplex(lotType)) {
            return false;
        }
        return (componentType === LOT_COMPONENT_TYPE.BuildDetails);
    }


    public buildTypeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.InformationDetails || componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }
    }

    public businessEntityVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts || componentType === LOT_COMPONENT_TYPE.InformationDetails) {

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public classicCareContactVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return true;
    }

    public classicCareContactMethodVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return true;
    }

    public classicCareContactDetailVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return true;
    }

    public clientAccountVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.Purchaser) {

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public clientAccountSolicitorVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.Purchaser) {

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }


    public clientAccountSolicitorEmailVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.clientAccountSolicitorVisible(lotType, lotContractType, componentType);
    }

    public clientAccountLegalNameVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.clientAccountVisible(lotType, lotContractType, componentType);
    }

    public clientOwnsLandVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.clientAccountSolicitorVisible(lotType, lotContractType, componentType);
    }

    public consentDueVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.KeyDates) {

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public consentDueEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.consentDueVisible(lotType, lotContractType, componentType);
    }

    public consentNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.LotDetailsSummary) {

            if (this.isTerracedOrDuplex(lotType)) {
                return false;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public consentNumberEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.consentNumberVisible(lotType, lotContractType, componentType);
    }

    public contactsAssignedToAccountVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.clientAccountVisible(lotType, lotContractType, componentType);
    }

    public contractGenerationFeeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (lotType === LOT_TYPE_ENUM.Land
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
            && componentType === LOT_COMPONENT_TYPE.LotDetailsSummary) {
            return true;
        } else if (lotType === LOT_TYPE_ENUM.Land
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
            && componentType === LOT_COMPONENT_TYPE.LotDetailsSummary) {
            return true;
        }

        return false;
    }

    public contractStatusVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.Purchaser) {
            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }

    contactsBuildingConsultantVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts) {

            if (this.isTerracedOrDuplex(lotType)) {
                return false;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }
        }

        return false;
    }

    contactsBuildingCoordinatorVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts) {
            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }
        }

        return false;
    }

    contactsDesignerVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts) {

            if (this.isTerracedOrDuplex(lotType)) {
                return false;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }
        }

        return false;
    }

    contactsFoundationManagerVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts) {
            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }
        }

        return false;
    }

    contactsFramingManagerVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts) {

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }


            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }
        }

        return false;
    }

    contactsHouseAndLandCoordinatorVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts) {
            if ((lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.ApartmentBlock
                || this.isTerracedOrDuplex(lotType)
                || lotType === LOT_TYPE_ENUM.Unit)
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if ((lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.ApartmentBlock
                || this.isTerracedOrDuplex(lotType)
                || lotType === LOT_TYPE_ENUM.Unit)
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }

    contactsQualityAssuranceManagerVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts) {
            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.ApartmentBlock &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.ApartmentBlock &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }
        }

        return false;
    }

    contactsQuantitySurveyorVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts) {

            if (this.isTerracedOrDuplex(lotType)) {
                return false;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild)) {
                return true;
            }
        }

        return false;
    }

    contactsSalesPersonVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.ClassicContacts) {
            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone &&
                (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }

    contractTypeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.Purchaser) {
            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public developerProjectNameVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
            return true;
        }

        return false;
    }

    dpNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.LotDetailsSummary) {

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    dpNumberEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.dpNumberVisible(lotType, lotContractType, componentType);
    }


    estimatedCompletionDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.EditLotDetails || componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            if (lotType !== LOT_TYPE_ENUM.Land) {
                return true;
            }
        }

        return false;
    }

    estimatedStartDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.EditLotDetails || componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            if (lotType !== LOT_TYPE_ENUM.Land) {
                return true;
            }
        }

        return false;
    }

    expectedTitleDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.EditLotDetails || componentType === LOT_COMPONENT_TYPE.LotDetailsSummary) {
            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType)
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType)
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }

    expectedTitleDateEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.EditLotDetails) {
            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }
        }

        return false;
    }

    expectedTitleDateRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.expectedTitleDateEditable(lotType, lotContractType, componentType);
    }

    financialsBuildPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.Purchaser) {
            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }

    financialsContractPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.Purchaser) {
            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }

    financialsLandPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.LotDetailsSummary) {
            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }

    financialsSellPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.LotDetailsSummary) {
            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }

    hasFlyer(document: { documentEntityId: number; documentId: number }): boolean {
        return document && document.documentId > 0;
    }

    hasTitleVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.InformationDetails) {
            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }
        }

        return false;
    }

    hasTitleEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.hasTitleVisible(lotType, lotContractType, componentType);
    }

    insuranceCoverNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.insuranceInterestedPartyVisible(lotType, lotContractType, componentType);
    }


    insuranceInterestedPartyVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.InsuranceDetails) {
            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }
        return false;
    }


    insuranceInterestedPartyEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.insuranceInterestedPartyVisible(lotType, lotContractType, componentType);
    }

    insuranceInterestedPartyEmailVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.InsuranceDetails) {
            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    insuranceInterestedPartyEmailEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.insuranceInterestedPartyEmailVisible(lotType, lotContractType, componentType);
    }

    insuranceEndDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.insurancePriceVisible(lotType, lotContractType, componentType);
    }

    insurancePriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.InsuranceDetails) {
            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    insurancePriceEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.insurancePriceVisible(lotType, lotContractType, componentType);
    }

    insuranceStartDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.insurancePriceVisible(lotType, lotContractType, componentType);
    }

    isShowHomeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (lotType === LOT_TYPE_ENUM.Standalone
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
            && componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.Standalone
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
            && componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            return true;
        }

        return false;
    }

    isShowHomeEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.isShowHomeVisible(lotType, lotContractType, componentType);
    }

    lotAddressVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (lotType === LOT_TYPE_ENUM.Standalone
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.Standalone
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.ApartmentBlock
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.ApartmentBlock
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.ApartmentBlock
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.ApartmentBlock
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            return true;
        }

        if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.Unit
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.Unit
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.Unit
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.Land
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            return true;
        }

        if (lotType === LOT_TYPE_ENUM.Land
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return true;
        }

        return false;
    }

    lotAddressEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.lotAddressVisible(lotType, lotContractType, componentType);
    }

    lotAddressRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.lotAddressVisible(lotType, lotContractType, componentType);
    }

    lotContractTypeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.LotDetailsSummary) {
            return true;
        }

        return false;
    }

    lotNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.InformationDetails || componentType === LOT_COMPONENT_TYPE.LotDetailsCard) {
            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    lotNumberEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.lotNumberVisible(lotType, lotContractType, componentType);
    }

    lotTypeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.InformationDetails || componentType === LOT_COMPONENT_TYPE.LotDetailsCard) {

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    lotTypeEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.InformationDetails) {

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    jobCompleteActualVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.KeyDates) {

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }


            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }


    jobCompleteEstimateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.jobCompleteActualVisible(lotType, lotContractType, componentType);
    }


    jobCompleteEstimateEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.jobCompleteEstimateVisible(lotType, lotContractType, componentType);
    }

    jobNumberEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE,
        lotSpecLocked: boolean,
    ) {
        return (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
            || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)
            && !lotSpecLocked;
    }

    jobNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.LotDetailsSummary
            || componentType === LOT_COMPONENT_TYPE.Purchaser
            || componentType === LOT_COMPONENT_TYPE.LotDetailsCard) {
            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }


            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }



    jobStartActualVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.jobCompleteActualVisible(lotType, lotContractType, componentType);
    }


    jobStartEstimateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.jobCompleteActualVisible(lotType, lotContractType, componentType);
    }

    jobStartEstimateEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.jobStartEstimateVisible(lotType, lotContractType, componentType);
    }

    jobStatusVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.KeyDates) {
            if (lotType === LOT_TYPE_ENUM.Standalone) {
                return (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand);
            }

            if (lotType === LOT_TYPE_ENUM.Standalone) {
                return (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand);
            }


            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }
        }

        return false;
    }

    landTitleVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.InformationDetails) {
            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }
        }

        return false;
    }

    marketStatusVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.LotDetailsSummary) {

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Land
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Standalone
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }
        }

        return false;
    }

    permitLodgedVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.KeyDates) {

            if (lotType === LOT_TYPE_ENUM.Standalone) {
                return (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand);
            }


            if (lotType === LOT_TYPE_ENUM.Standalone) {
                return (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
                    || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand);
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }


            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

        }

        return false;
    }

    permitLodgedEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.permitLodgedVisible(lotType, lotContractType, componentType);
    }

    parentLotVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.BuildDetails) {
            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.ApartmentBlock
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if (this.isTerracedOrDuplex(lotType) && this.isHouseAndLandOrDesignAndBuild(lotContractType)) {
                return true;
            }

            if (lotType === LOT_TYPE_ENUM.Unit
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public projectNameVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.LotDetailsCard && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            return (lotType === LOT_TYPE_ENUM.Land || lotType === LOT_TYPE_ENUM.Standalone || lotType === LOT_TYPE_ENUM.Unit);
        }

        if (componentType === LOT_COMPONENT_TYPE.LotDetailsCard && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return (lotType === LOT_TYPE_ENUM.Land || lotType === LOT_TYPE_ENUM.Standalone || lotType === LOT_TYPE_ENUM.Unit);
        }

        return false;
    }

    projectReleaseStageNameVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        if (componentType === LOT_COMPONENT_TYPE.LotDetailsCard && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            return (lotType === LOT_TYPE_ENUM.Land || lotType === LOT_TYPE_ENUM.Standalone || lotType === LOT_TYPE_ENUM.Unit);
        }

        if (componentType === LOT_COMPONENT_TYPE.LotDetailsCard && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return (lotType === LOT_TYPE_ENUM.Land || lotType === LOT_TYPE_ENUM.Standalone || lotType === LOT_TYPE_ENUM.Unit);
        }

        return false;
    }

    roadDirectionVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.developerProjectNameVisible(lotType, lotContractType, componentType);
    }

    shouldShowComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        switch (componentType) {
            case LOT_COMPONENT_TYPE.InformationDetails:
                return this.shouldShowInformationDetailsComponent(lotType, lotContractType, componentType);
            case LOT_COMPONENT_TYPE.LotDetailsSummary:
                return this.shouldShowLotDetailsComponent(lotType, lotContractType, componentType);
            case LOT_COMPONENT_TYPE.BuildDetails:
                return this.shouldShowBuildDetailsComponent(lotType, lotContractType, componentType);
            case LOT_COMPONENT_TYPE.ClassicContacts:
                return this.shouldShowClassicContactsComponent(lotType, lotContractType, componentType);
            case LOT_COMPONENT_TYPE.Purchaser:
                return this.shouldShowPurchaserComponent(lotType, lotContractType, componentType);
            case LOT_COMPONENT_TYPE.InsuranceDetails:
                return this.shouldShowInsuranceDetailComponent(lotType, lotContractType, componentType);
            case LOT_COMPONENT_TYPE.GuaranteeDetails:
                return this.shouldShowGuaranteeDetailsComponent(lotType, lotContractType, componentType);
            case LOT_COMPONENT_TYPE.ClassicCare:
                return this.shouldShowClassicCareComponent(lotType, lotContractType, componentType);
            default:
                return false;
        }
    }

    vendorNameVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.Purchaser && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            return (lotType === LOT_TYPE_ENUM.Land || lotType === LOT_TYPE_ENUM.Standalone || lotType === LOT_TYPE_ENUM.Unit);
        }

        if (componentType === LOT_COMPONENT_TYPE.Purchaser && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return (lotType === LOT_TYPE_ENUM.Land || lotType === LOT_TYPE_ENUM.Standalone || lotType === LOT_TYPE_ENUM.Unit);
        }

        return false;
    }

    vendorNameThirdPartyVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {

        if (componentType === LOT_COMPONENT_TYPE.Purchaser) {
            if (lotType === LOT_TYPE_ENUM.Land || lotType === LOT_TYPE_ENUM.Standalone) {
                return lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand;
            }
        }

        return false;
    }

    private shouldShowBuildDetailsComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.buildTypeVisible(lotType, lotContractType, componentType)
            ||
            this.amenitiesFloorAreaVisible(lotType, lotContractType, componentType)
            ||
            this.amenitiesNoBedroomsVisible(lotType, lotContractType, componentType)
            ||
            this.amenitiesNoBathroomsVisible(lotType, lotContractType, componentType)
            ||
            this.amenitiesNoLivingRoomsVisible(lotType, lotContractType, componentType)
            ||
            this.amenitiesNoGaragesVisible(lotType, lotContractType, componentType)
            ||
            this.parentLotVisible(lotType, lotContractType, componentType);
    }

    private shouldShowInformationDetailsComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.businessEntityVisible(lotType, lotContractType, componentType)
            ||
            this.lotTypeVisible(lotType, lotContractType, componentType)
            ||
            this.lotNumberVisible(lotType, lotContractType, componentType)
            ||
            this.dpNumberVisible(lotType, lotContractType, componentType)
            ||
            this.amenitiesLandAreaVisible(lotType, lotContractType, componentType)
            ||
            this.expectedTitleDateVisible(lotType, lotContractType, componentType)
            ||
            this.developerProjectNameVisible(lotType, lotContractType, componentType);
    }

    private shouldShowLotDetailsComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.jobNumberVisible(lotType, lotContractType, componentType)
            ||
            this.jobStatusVisible(lotType, lotContractType, componentType)
            ||
            this.lotContractTypeVisible(lotType, lotContractType, componentType)
            ||
            this.marketStatusVisible(lotType, lotContractType, componentType)
            ||
            this.contractGenerationFeeVisible(lotType, lotContractType, componentType)
            ||
            this.financialsLandPriceVisible(lotType, lotContractType, componentType)
            ||
            this.financialsBuildPriceVisible(lotType, lotContractType, componentType)
            ||
            this.financialsSellPriceVisible(lotType, lotContractType, componentType)
            ||
            this.consentNumberVisible(lotType, lotContractType, componentType);
    }

    private shouldShowClassicContactsComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.contactsHouseAndLandCoordinatorVisible(lotType, lotContractType, componentType)
            ||
            this.contactsBuildingConsultantVisible(lotType, lotContractType, componentType)
            ||
            this.contactsBuildingCoordinatorVisible(lotType, lotContractType, componentType)
            ||
            this.contactsDesignerVisible(lotType, lotContractType, componentType)
            ||
            this.contactsQuantitySurveyorVisible(lotType, lotContractType, componentType)
            ||
            this.contactsFoundationManagerVisible(lotType, lotContractType, componentType)
            ||
            this.contactsFramingManagerVisible(lotType, lotContractType, componentType)
            ||
            this.contactsQualityAssuranceManagerVisible(lotType, lotContractType, componentType);
    }

    private shouldShowPurchaserComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return lotType !== LOT_TYPE_ENUM.ApartmentBlock
            && lotType !== LOT_TYPE_ENUM.TerracedBlock
            && lotType !== LOT_TYPE_ENUM.Duplex
            && (this.clientAccountVisible(lotType, lotContractType, componentType)
                ||
                this.contractStatusVisible(lotType, lotContractType, componentType)
                ||
                this.jobNumberVisible(lotType, lotContractType, componentType)
                ||
                this.vendorNameThirdPartyVisible(lotType, lotContractType, componentType));
    }

    private shouldShowInsuranceDetailComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.insurancePriceVisible(lotType, lotContractType, componentType)
            ||
            this.insuranceStartDateVisible(lotType, lotContractType, componentType)
            ||
            this.insuranceEndDateVisible(lotType, lotContractType, componentType)
            ||
            this.insuranceInterestedPartyVisible(lotType, lotContractType, componentType)
            ||
            this.insuranceInterestedPartyEmailVisible(lotType, lotContractType, componentType);
    }

    private shouldShowGuaranteeDetailsComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return true;
    }

    private shouldShowClassicCareComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean {
        return this.classicCareContactVisible(lotType, lotContractType, componentType)
            ||
            this.classicCareContactMethodVisible(lotType, lotContractType, componentType)
            ||
            this.classicCareContactMethodVisible(lotType, lotContractType, componentType);
    }
}
