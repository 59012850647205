import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'cbDasher'
})
export class CbDasherPipe implements PipeTransform {
    public transform(value: any, ...args: any[]): any {
        return value == null || value === '' ? '-' : value;
    }
}
