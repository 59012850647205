<form class="flex-col flex"
      #infiniteScrollContainer>
    <div class="flex-col flex-100">
        <div class="flex-col flex-100">
            <div class="cb-margin-bottom-3x flex-col">
                <div class="cb-margin-top cb-margin-bottom flex-row">
                    <cb-info-message *ngIf="!submittedForPricing()"
                                     class="cb-margin-top"
                                     [message]="'This Change needs to be Submitted for Pricing'"
                                     [icon]="'warning'"
                                     [iconClass]="'mat-warn'"></cb-info-message>
                    <cb-info-message *ngIf="this.changeRecordMappedItem.quoteId > 0"
                                     class="cb-margin-top"
                                     [message]="'This Change was generated from a Quote'"
                                     [icon]="'info_circle'"
                                     iconClass="cb-accent"></cb-info-message>
                    <span class="flex"></span>
                    <button mat-raised-button
                            class="cb-margin-right"
                            *ngIf="this.changeRecordMappedItem.quoteId > 0"
                            (click)="viewQuote()">
                        View Quote
                    </button>
                    <button mat-raised-button
                            class="cb-margin-right"
                            *ngIf="this.changeRecordMappedItem.variationNumber"
                            (click)="viewVariation()">
                        View Variation
                    </button>
                    <button mat-raised-button
                            class="cb-margin-right"
                            [matMenuTriggerFor]="menu"
                            [disabled]="isMenuDisabled()">
                        Options
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button *ngIf="canEditChange()"
                                mat-menu-item
                                [disabled]="!canRevertToInProgress()"
                                (click)="revertToInProgress()">
                            <mat-icon>undo</mat-icon>
                            Revert to In Progress
                        </button>
                        <button *ngIf="canEditChange()"
                                mat-menu-item
                                [disabled]="!canApproveAndAccept()"
                                (click)="approveAndAcceptOption()">
                            <mat-icon>check_circle</mat-icon>
                            Approve &amp; Accept
                        </button>
                        <button *ngIf="canEditChange()"
                                mat-menu-item
                                [disabled]="isEditChangeDetailsDisabled()"
                                (click)="editChangeDetails()">
                            <mat-icon>edit</mat-icon>
                            Edit Change Details
                        </button>
                        <button *ngIf="canManageOption()"
                                mat-menu-item
                                [disabled]="isAddAnotherOptionDisabled()"
                                (click)="addOption()">
                            <mat-icon>add</mat-icon>
                            Add Another Option
                        </button>
                        <button mat-menu-item
                                [disabled]="declineAllOptionsDisabled()"
                                (click)="declineOptionsConfirmDialog()">
                            <mat-icon>close</mat-icon>
                            Decline All Options
                        </button>
                        <button *ngIf="canReassignChange()"
                                mat-menu-item
                                [matMenuTriggerFor]="reassignMenu"
                                [disabled]="isMenuItemsDisabled()">
                            <mat-icon>person</mat-icon>
                            Reassign
                        </button>
                        <mat-menu #reassignMenu="matMenu">
                            <button [disabled]="isMenuItemsDisabled()"
                                    (click)="reassign()"
                                    mat-menu-item>
                                Reassign
                            </button>
                            <button [disabled]="isMenuItemsDisabled()"
                                    (click)="reassignReview()"
                                    mat-menu-item>
                                Reassign for Review
                            </button>
                            <button [disabled]="isMenuItemsDisabled()"
                                    (click)="reassignApproval()"
                                    mat-menu-item>
                                Reassign for Approval
                            </button>
                        </mat-menu>
                        <button *ngIf="canEditChange()"
                                mat-menu-item
                                [matMenuTriggerFor]="changeStatus"
                                [disabled]="isMenuItemsDisabled()">
                            <mat-icon>timeline</mat-icon>
                            Change Status
                        </button>
                        <mat-menu #changeStatus="matMenu">
                            <button [disabled]="!canChangeStatus()"
                                    (click)="setStatusInQueue()"
                                    mat-menu-item>
                                In Queue
                            </button>
                            <button [disabled]="!canChangeStatus()"
                                    (click)="setStatusInProgress()"
                                    mat-menu-item>
                                In Progress
                            </button>
                            <button [disabled]="!canChangeStatus()"
                                    (click)="setStatusWaitingReview()"
                                    mat-menu-item>
                                Awaiting Review
                            </button>
                            <button [disabled]="!canChangeStatus()"
                                    (click)="setStatusOnHold()"
                                    mat-menu-item>
                                On Hold
                            </button>
                        </mat-menu>
                        <button *ngIf="canCancelChange()"
                                mat-menu-item
                                (click)="cancelChangeRecordDialog()">
                            <mat-icon>close</mat-icon>
                            Cancel
                        </button>
                        <button mat-menu-item
                                [disabled]="isMenuItemsDisabled()"
                                (click)="generateChangeSummary()">
                            <mat-icon>insert_drive_file</mat-icon>
                            Change Summary
                        </button>
                    </mat-menu>
                </div>
                <div class="flex-row flex-100">
                    <div class="flex-col flex-50">
                        <cb-display-value-new label="Created"
                                              [value]="changeRecordMappedItem?.createdDate | date : 'medium'">
                        </cb-display-value-new>
                        <cb-display-value-new label="Due"
                                              [value]="changeRecordMappedItem?.dueBy | date"></cb-display-value-new>
                        <cb-display-value-new label="Change Source"
                                              [value]="changeRecordMappedItem?.changeSource | cbEnumToLabel: CHANGE_SOURCE_ENUM">
                        </cb-display-value-new>
                        <cb-display-value-new label="Change Type"
                                              [value]="changeRecordMappedItem?.costNature | cbEnumToLabel: COST_NATURE_ENUM">
                        </cb-display-value-new>
                        <cb-display-value-new label="Variation #"
                                              [value]="changeRecordMappedItem?.variationNumber"></cb-display-value-new>
                        <cb-display-value-new *ngIf="isCouncilRequest()"
                                              label="Council RFI #"
                                              [value]="changeRecordMappedItem?.councilRfiId"></cb-display-value-new>
                        <cb-currency class="flex"
                                     *ngIf="changeRecordMappedItem.isExtraToClient"
                                     label="Cost"
                                     name="gstCostChangeAmount"
                                     [(ngModel)]="changeRecordMappedItem.costChangeAmount"
                                     [inclGst]="true"
                                     [readonly]="true">
                        </cb-currency>
                        <cb-currency class="flex"
                                     *ngIf="!changeRecordMappedItem.isExtraToClient"
                                     label="Cost"
                                     name="costChangeAmount"
                                     [(ngModel)]="changeRecordMappedItem.costChangeAmount"
                                     [readonly]="true">
                        </cb-currency>
                    </div>
                    <div class="flex-col flex-50">
                        <cb-display-value-new label="Owner"
                                              [value]="changeRecordMappedItem?.owner"></cb-display-value-new>
                        <cb-display-value-new label="Assigned To Team"
                                              [value]="changeRecordMappedItem?.assignedTeam"></cb-display-value-new>
                        <cb-display-value-new label="Assigned To"
                                              [value]="changeRecordMappedItem?.assignedTo">
                        </cb-display-value-new>
                        <cb-display-value-new label="Change Status"
                                              [value]="changeRecordMappedItem?.changeStatus | cbEnumToLabel: CHANGE_STATUS_ENUM">
                        </cb-display-value-new>
                    </div>
                </div>
                <cb-display-value-new label="Change Details"
                                      [value]="changeRecordMappedItem?.changeDetails?.trim()"></cb-display-value-new>
            </div>
            <div>
                <h4 class="cb-title">Change Options</h4>
            </div>
            <mat-divider [inset]="false"
                         class="cb-margin-bottom-2x"> </mat-divider>
            <cb-lot-change-option *ngFor="let option of changeRecordMappedItem.changeOptions"
                                  [changeOption]="option"
                                  [changeRecordMappedItem]="changeRecordMappedItem"
                                  (optionUpdated)="true"
                                  [lotMappedItem]="lotMappedItem">
            </cb-lot-change-option>
        </div>
    </div>
</form>
