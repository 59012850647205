import { Component, Input } from '@angular/core';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { ILotProgressMilestoneDto } from '@app/logic/lots/interfaces/i.lot-progress-milestones.dto';
import { LOT_MILESTONE_PROGRESS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-summary-milestones',
    templateUrl: './lot-summary-milestones.component.html',
    styleUrls: ['./lot-summary-milestones.component.scss']
})
export class LotSummaryMilestonesComponent {
    private _lotMappedItem: ILotMappedItem;

    @Input() public set lotMappedItem(lotMappedItem: ILotMappedItem) {
        this._lotMappedItem = lotMappedItem;
    }

    public get lotMappedItem(): ILotMappedItem {
        return this._lotMappedItem;
    }

    @Input() public set blockLevelProgress(blockLevelProgress: ILotProgressMilestoneDto) {
        this._blockLevelProgress = blockLevelProgress;
    }

    public get blockLevelProgress(): ILotProgressMilestoneDto
    {
        return this._blockLevelProgress;
    }

    public _blockLevelProgress: ILotProgressMilestoneDto;

    constructor(private readonly lotsLogicService: LotsLogicService,) { }

    public hasTitle(): boolean {
        return this.lotMappedItem.hasTitle
            || this.blockLevelProgress?.hasTitle === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public hasConsent(): boolean {
        return this.lotMappedItem.hasConsent
            || this.blockLevelProgress?.consent === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public hasFloorDown(): boolean {
        return this.lotMappedItem.hasFloorDown
            || this.blockLevelProgress?.hasFloorDown === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public hasPracticalCompletion(): boolean {
        return this.lotMappedItem.hasPracticalCompletion
            || this.blockLevelProgress?.lotHasPracticalCompletion === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public hasCodeCompliance(): boolean {
        return this.lotMappedItem.hasCodeCompliance
            || this.blockLevelProgress?.hasCodeOfCompliance === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public hasHandover(): boolean {
        return this.lotMappedItem.isHandedOver
            || this.blockLevelProgress?.handover === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }
}
