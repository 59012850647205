import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'cb-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepComponent {
    @Input() public active: boolean;
    @Input() public complete: boolean;
    @Input() public label: string;
    @Input() public warning: boolean;
    @Input() public error: boolean;
    @Input() public divider?: boolean;
    @Input() public showMessage = false;
    @Input() public showCard = false;

    constructor() { }
}
