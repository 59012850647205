import { Component } from '@angular/core';
import { StandardPlanLogicService } from '@app/logic/standard-plans/standard-plan.logic.service';
@Component({
    selector: 'cb-standard-plans-settings',
    templateUrl: './standard-plans.component.html',
    styleUrls: ['./standard-plans.component.scss'],
    providers: [StandardPlanLogicService]
})
export class StandardPlansSettingsComponent {
    constructor(){}
}
