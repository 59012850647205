import {AfterViewInit, ChangeDetectorRef, Directive, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {ManageClientAccountDialogComponent} from '@app/views/client-accounts/manage-client-account-dialog/manage-client-account-dialog.component';
import {ClientAccountSearchService} from '@app/core/services/search/client-account-search.service';
import {ClientAccountLogicService} from '@app/logic/client-account/client-account.logic.service';
import {IClientAccountMappedItem} from '@app/logic/client-account/interfaces/i.client-account.mapped';
import {InputFindType} from '../../../input-find-type.enum';
import {BaseInputFindDialogDirective} from '../../base-input-find-dialog.component';

interface IData {
    mappedItem: IClientAccountMappedItem;
    searchService: ClientAccountSearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

/**
 * Compose this inside a concrete inputFindCardComponent and a concrete InputFindTableComponent
 */
@Directive()
export class ClientAccountsInputFindDialogDirective extends BaseInputFindDialogDirective<ClientAccountLogicService> implements AfterViewInit {
    public buttonLabel = 'Create Account';

    constructor(
        public readonly dialogRef: MatDialogRef<ClientAccountsInputFindDialogDirective>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly matDialog: MatDialog,
        public logicService: ClientAccountLogicService,
        public cdRef: ChangeDetectorRef,
        public readonly dialogService: CbDialogService,
    ) {
        super(
            dialogRef,
            data,
            logicService,
            cdRef,
            InputFindType.ClientAccount,
        );
    }

    public buttonClick = (): void => {
        this.dialogService
            .open(ManageClientAccountDialogComponent, {
                data: { mappedItem: undefined },
            })
            .afterClosed()
            .subOnce({
                next: (result) => {
                    if (result) {
                        this.dialogRef.close(result);
                    }
                }
            });
    };

    public ngAfterViewInit(): void {
        this.data.searchService.infiniteScrollContainer = this.scrollContainer;
        this.data.searchService.extraSearchParams = this.data.extraSearchParams;
        this.data.searchService.logicService = this.logicService;
        if (this.data.searchService.isPagination) {
            this.data.searchService.nextPage$.next({ addPage: 1 });
        } else {
            this.data.searchService.doSearchIfNoScrollBar();
        }
        this.cdRef.detectChanges();
    }
}

