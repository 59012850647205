import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ISlotSearchFilters } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { SlotTagsLogicService } from '@app/logic/slot-tags/slot-tags.logic-service';
import { ISlotMappedItem } from '@app/logic/slots/interfaces/i.slot.mapped';
import { SlotsLogicService } from '@app/logic/slots/slots.logic-service';
import { ISpecGroupDto, SpecGroupsLogicService } from '@app/logic/spec-groups';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { ILotSpecItemExpandedPanels } from '@app/views/lot/specification/lot-spec-items-list/lot-spec-items-list.component';
import { COST_TYPE_ENUM, ISlotDto, ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { toPromisedArray } from 'cb-hub-lib';
import { orderBy } from 'lodash';
import { map, Subject, Subscription } from 'rxjs';
import { PermissionsPermissions } from '../../../../core/permissions';
import { ManageSlotDialogComponent } from './slot-dialog/manage-slot-dialog.component';


@Component({
    selector: 'cb-slots',
    templateUrl: './slots.component.html',
    styleUrls: ['./slots.component.scss'],
})
export class SlotsComponent implements OnInit {
    @Output() public readonly expandedPanelsChange = new EventEmitter<ILotSpecItemExpandedPanels>();
    public expandedAreas = {} as ILotSpecItemExpandedPanels;
    public subscriptions = new Subscription();
    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public costTypes = COST_TYPE_ENUM
        .toSelectList()
        .filter(ct => ct.id !== COST_TYPE_ENUM.None &&
            ct.id !== COST_TYPE_ENUM.OwnersCare &&
            ct.id !== COST_TYPE_ENUM.Quote);
    public selectedLists: any[] = [];
    public selectedIdsLists: string[] = [];
    public slotTags: ITagDto[];
    public activeSlotTags: ITagDto[];
    public selectedTag: number;
    public allExpanded = false;
    public readonly slotsUpdated = new EventEmitter<ISlotDto[]>();
    public houseAreas = toPromisedArray(
        this.specGroupsService
            .$getList()
            .pipe(
                map(results => orderBy(results, 'sortOrder', 'asc'))
            )
    );

    // reordering
    /** key = Slot.ID, value = Slot.ReportOrder */
    public reportOrders: { [slotId: number]: number } = {};
    public reorderCancelled = new Subject<void>();

    // search
    public searchEnabled = false;
    public queryUpdated = new Subject<void>();
    public get userCacheItem(): UserCacheItem<ISlotSearchFilters> {
        return this.userCacheService.slotSearch;
    }

    // dragula
    public readonly SCHEDULE_ITEMS = 'SCHEDULE_ITEMS';
    public sortOrderChanged = false;
    public reorderingEnabled = false;

    public resetSelectedTags = new Subject<void>();

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialogService: CbDialogService,
        private readonly slotsLogicService: SlotsLogicService,
        public readonly userCacheService: UserCacheService,
        private readonly slotTagsLogicService: SlotTagsLogicService,
        @Inject(SpecGroupsLogicService) public readonly specGroupsService: SpecGroupsLogicService,
    ) {
        this.slotTagsLogicService.getLookup().subOnce((result) => {
            this.slotTags = result;
            this.activeSlotTags = result.filter(tag => tag.isActive);
        });
    }

    public ngOnInit(): void {
        Promise.all([
            this.userCacheItem.init(),
            this.houseAreas.$promise,
        ]).then(() => {
            this.searchEnabled = true;
            this.filteredHouseAreas.recompute();
            this.queryUpdated.next();
            this.subscriptions.add(
                this.userCacheItem.updated$.subscribe({
                    next: () => this.queryUpdated.next()
                })
            );
        });
    }

    public openDialog(mappedItem: ISlotMappedItem, dialogHeading: string): any {
        return this.dialogService
            .open(
                ManageSlotDialogComponent,
                {
                    data: {
                        slotTags: this.slotTags,
                        dialogHeading,
                        mappedItem,
                    },
                }
            );
    }


    public newItemClicked = (): void => {
        this.openDialog(this.slotsLogicService.$createMappedItem(), 'Add Schedule Item')
            .afterClosed()
            .subOnce(res => {
                if (res) {
                    this.emitSlotsUpdated(res);
                }
            });
    };

    public readonly filteredHouseAreas = new ComputedProperty(() => {
        if (!this.houseAreas) {
            return [];
        }

        if (this.userCacheItem?.silentData?.selectedHouseAreas?.length < 1) {
            return this.houseAreas;
        } else {
            return this.houseAreas
                .filter(x => this.userCacheItem.silentData.selectedHouseAreas.includes(x.id))
                .sort((a, b) => a.sortOrder - b.sortOrder);
        }
    });

    public expandCollapseAll = (): void => {
        this.setAllExpanded();
        const newStatus = !this.allExpanded;
        this.houseAreas.forEach((value) => {
            this.expandedAreas[value.id] = newStatus;
        });
        this.allExpanded = newStatus;
    };

    public handlePanelContentsDestroy(houseArea: ISpecGroupDto): void {
        this.handleListChanged({
            area: houseArea.id,
            ids: []
        });
    }

    public handleExpandChange(houseArea: ISpecGroupDto): void {
        this.handlePanelContentsDestroy(houseArea);
        this.setAllExpanded();
    }

    public setAllExpanded = (): void => {
        let expandedPanelsCount = 0;
        let panelCount = 0;
        this.houseAreas.forEach((houseArea) => {
            if (this.expandedAreas[houseArea.id]) {
                expandedPanelsCount++;
            }
            panelCount++;
        });

        if (expandedPanelsCount === panelCount) {
            this.allExpanded = true;
        } else {
            this.allExpanded = false;
        }
    };

    public handleListChanged(event: any): void {

        const elementsIndex = this.selectedLists.findIndex(element => element.area === event.area);

        // If already exist replace the selected items
        if (elementsIndex > -1) {
            this.selectedLists[elementsIndex] = event;
        } else {
            this.selectedLists.push(event);
        }

        const selectedIds = this.selectedLists.map(({ ids }) => ids);

        this.selectedIdsLists = [].concat(...selectedIds);
    }


    public applyTag(): void {
        this.slotsLogicService.applyTag(this.selectedIdsLists, this.selectedTag).subOnce(slot => {
            this.emitSlotsUpdated(slot);
            this.resetSelectedTags.next();
            this.selectedTag = null;
            this.selectedIdsLists = [];
            this.selectedLists = [];
        });
    }

    public generateSimReport(): void {
        this.slotsLogicService.generateSimReport().subOnce();
    }

    private readonly emitSlotsUpdated = (slotsResponse: ISlotDto[]): void => {
        if (!slotsResponse) {
            return;
        }
        this.slotsUpdated.emit(slotsResponse);
    };

    /*
        Dragula content.
    */

    /** @param reportOrders key = Slot.ID, value = Slot.ReportOrder */
    public sortOrderChange(reportOrders: { [slotId: number]: number } = {}): void {
        this.sortOrderChanged = true;
        Object.assign(this.reportOrders, reportOrders);
    }

    public enableReordering(): void {
        this.reorderingEnabled = true;
        this.resetReportOrders();
    }

    public resetReportOrders(): void {
        this.reportOrders = {};
        if (!this.reorderingEnabled) {
            this.reorderCancelled.next();
        }
    }

    public cancelReordering(): void {
        if (this.sortOrderChanged) {
            this.dialogService
                .confirm({
                    message: 'Are you sure you want to cancel reordering of the Schedule Items?',
                    confirmed: () => {
                        this.sortOrderChanged = false;
                        this.reorderingEnabled = false;
                        this.resetReportOrders();
                    }
                });
        } else {
            this.sortOrderChanged = false;
            this.reorderingEnabled = false;
            this.resetReportOrders();
        }
    }

    public saveOrder(): void {
        this.dialogService
            .confirm({
                message: 'Are you sure you want to save the order of the Schedule Items?',
                confirmed: () => {
                    this.slotsLogicService
                        .saveOrder(this.reportOrders)
                        .subOnce(() => {
                            this.resetReportOrders();
                            this.sortOrderChanged = false;
                            this.reorderingEnabled = false;
                        });
                }
            });
    }
}
