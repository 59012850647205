<cb-dialog>
    <form class="flex-col flex"
          #styleForm="ngForm">
        <mat-dialog-content>
            <cb-input type="text"
                      name="name"
                      label="Style Name"
                      [required]="true"
                      [(ngModel)]="mappedItem.name"></cb-input>
            <cb-checkbox name="isActive"
                         label="Is Active"
                         [(ngModel)]="mappedItem.isActive"></cb-checkbox>
        </mat-dialog-content>
        <footer class="cb-margin-top-2x end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (click)="save()"
                    [disabled]="!styleForm.dirty || !styleForm.valid ">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel()">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
