import {BasePermissions, IBaseLotPermissions, IBaseSystemAreaPermissions, IDocumentPermissions, IhaveNotePermissions, INotePermissions} from './base.permissions';
import {LotPermissionEnum} from '../../shared/enums/permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

export interface ILotPermissions extends IBaseLotPermissions, IBaseSystemAreaPermissions, IhaveNotePermissions {
    canSeeComponent(): boolean;
    canSearch(): boolean;
    canViewLot(): boolean;
    canEditLot(): boolean;
    canManageClassicContacts(): boolean;
    canViewSpecification(): boolean;
    canChangeSpecification(): boolean;
    canSetSpecificationAttributeValue(): boolean;
    canEditLotContingencyAmount(): boolean;
    canViewLeadInterests(): boolean;
    canViewHistory(): boolean;
    canViewCostSummary(): boolean;
    canViewDesign(): boolean;
    canViewBuild(): boolean;
    notePermissions(): INotePermissions;
    documentPermissions(): IDocumentPermissions;

    canView(): boolean;
    canCreate(): boolean;
    canEdit(): boolean;
    canRelease(): boolean;
    canImport(): boolean;
    canApplyLotSpec(): boolean;
    canCreateLotSpec(): boolean;
    getNotePermissions(): INotePermissions;
    getDocumentPermissions(): IDocumentPermissions;
}

@Injectable()
export class LotPermissions extends BasePermissions<LotPermissionEnum> implements ILotPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lot', LotPermissionEnum, currentUser, http, router);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(LotPermissionEnum.CanView);
    }

    public canSearch(): boolean {
        return this.permissionObject(LotPermissionEnum.CanSearch);
    }

    public canViewLot(): boolean {
        return this.permissionObject(LotPermissionEnum.CanView);
    }

    public canEditLot(): boolean {
        return this.permissionObject(LotPermissionEnum.CanEdit);
    }

    public canManageClassicContacts(): boolean {
        return this.permissionObject(LotPermissionEnum.CanManageClassicContacts);
    }

    public canViewSpecification(): boolean {
        return this.permissionObject(LotPermissionEnum.CanViewSpecification);
    }

    public canChangeSpecification(): boolean {
        return this.permissionObject(LotPermissionEnum.CanChangeSpecification);
    }

    public canSetSpecificationAttributeValue(): boolean {
        return this.permissionObject(LotPermissionEnum.CanSetSpecificationAttributeValue);
    }

    public canEditLotContingencyAmount(): boolean {
        return this.permissionObject(LotPermissionEnum.CanEditLotContingencyAmount);
    }

    public canViewLeadInterests(): boolean {
        return this.permissionObject(LotPermissionEnum.CanViewLeadInterests);
    }

    public canViewHistory(): boolean {
        return this.permissionObject(LotPermissionEnum.CanViewHistory);
    }

    public canViewCostSummary(): boolean {
        return this.permissionObject(LotPermissionEnum.CanViewCostSummary);
    }

    public canViewDesign(): boolean {
        return this.permissionObject(LotPermissionEnum.CanViewDesign);
    }

    public canViewBuild(): boolean {
        return this.permissionObject(LotPermissionEnum.CanViewBuild);
    }

    public canEditLotPriceOption(): boolean {
        return this.permissionObject(LotPermissionEnum.CanEditLotPriceOption);
    }

    public canViewHouseAndLandFlyer(): boolean {
        return this.permissionObject(LotPermissionEnum.CanViewHouseAndLandFlyer);
    }
    public notePermissions(): INotePermissions {
        return {
            canCreateNote: () => this.permissionObject(LotPermissionEnum.NoteCanAdd),
            canDeleteNote: () => this.permissionObject(LotPermissionEnum.NoteCanDelete),
            canEditNote: () => this.permissionObject(LotPermissionEnum.NoteCanEdit),
            canViewNotes: () => this.permissionObject(LotPermissionEnum.NoteCanView),
        };
    }

    public documentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => this.permissionObject(LotPermissionEnum.DocumentsCanDelete),
            canEditDocuments: () => this.permissionObject(LotPermissionEnum.DocumentsCanEdit),
            canReviewDocuments: () => this.permissionObject(LotPermissionEnum.DocumentsCanReview),
            canUploadDocument: () => this.permissionObject(LotPermissionEnum.DocumentsCanUpload),
            canViewDocuments: () => this.permissionObject(LotPermissionEnum.DocumentsCanView)
        };
    }

    public canView(): boolean {
        return true;
    }

    public canCreate(): boolean {
        return true;
    }

    public canEdit(): boolean {
        return true;
    }

    public canRelease(): boolean {
        return true;
    }

    public canImport(): boolean {
        return true;
    }

    public canApplyLotSpec(): boolean {
        return true;
    }

    public canCreateLotSpec(): boolean {
        return true;
    }

    public getNotePermissions(): INotePermissions {
        return {
            canCreateNote: () => true,
            canDeleteNote: () => true,
            canEditNote: () => true,
            canViewNotes: () => true,
        };
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => true,
            canEditDocuments: () => true,
            canReviewDocuments: () => true,
            canUploadDocument: () => true,
            canViewDocuments: () => true
        };
    }
}
