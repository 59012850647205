import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbCallbackPipe } from './callback.pipe';

@NgModule({
    declarations: [CbCallbackPipe],
    imports: [
        CommonModule
    ],
    exports: [CbCallbackPipe]
})
export class CbCallbackModule { }
