import { BaseMappedItem } from '../base/base-mapped-item';
import {
    IBusinessEntitiesLogicService,
    IBusinessEntityDefaultInstallersLogicService, IBusinessEntityDefaultSuppliersLogicService
} from './interfaces/i.business-entity.logic.service';
import {
    IBusinessEntityMappedItem,
    IBusinessEntityDefaultInstallerMappedItem,
    IBusinessEntityDefaultSupplierMappedItem
} from './interfaces/i.business-entity.mapped';
import {
    IBusinessEntityDto,
    IBusinessEntityDefaultSupplierDto,
    IIdAndLabelDto,
    ITradeTypeWithPath,
    IBusinessEntityDefaultProductSupplierDto, ISupplyTypeWithPath
} from './interfaces/i.business-entity.dto';
import { IAddressDto } from '@app/shared/interfaces/i.address.dto';
import { DtoProp } from '../base/dto-prop.decorator';
import { Observable } from 'rxjs';

export class BusinessEntityMappedItem
    extends BaseMappedItem<IBusinessEntityDto, IBusinessEntityMappedItem, IBusinessEntitiesLogicService>
    implements IBusinessEntityMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public legalEntityName: string;
    @DtoProp public physicalAddress: IAddressDto;
    @DtoProp public postalAddress: IAddressDto;
    @DtoProp public website: string;
    @DtoProp public accountsPhone: string;
    @DtoProp public creditorsEmailAddress: string;
    @DtoProp public invoiceEmailAddress: string;
    @DtoProp public complianceEmailAddress: string;
    @DtoProp public emailAddress: string;
    @DtoProp public ssrNumberPrefix: string;
    @DtoProp public isActive: boolean;
    @DtoProp public contractGenerationFee: number;
    @DtoProp public variationMarginPercentage: number;
    @DtoProp public defaultSuppliers: IBusinessEntityDefaultSupplierDto[];
    @DtoProp public defaultProductSuppliers: IBusinessEntityDefaultProductSupplierDto[];
    @DtoProp public mappedLocationRegionIds: number[];
    @DtoProp public defaultBankAccountId: number;
    @DtoProp public faxNumber: string;
    @DtoProp public abmId: string;
    @DtoProp public readonly defaultBankAccountLabel: string;
    @DtoProp public lotHoldingDeposit: number;
    @DtoProp public contingencyAmount: number;
    @DtoProp public contractGenerationFeeDays: number;
    @DtoProp public variationExpiryDays: number;
    @DtoProp public consentDays: number;
    @DtoProp public jobNumberPrefix: string;

    constructor(
        sourceData: IBusinessEntityDto,
        logicService: IBusinessEntitiesLogicService
    ) {
        super(sourceData, logicService, BusinessEntityMappedItem);
    }

    public getValidInstallerLocations(): Observable<number[]> {
        return this.$logicService.getValidInstallerLocations(this.id);
    }
}

export class BusinessEntityDefaultInstallerMappedItem
    extends BaseMappedItem<IBusinessEntityDefaultSupplierDto, IBusinessEntityDefaultInstallerMappedItem, IBusinessEntityDefaultInstallersLogicService>
    implements IBusinessEntityDefaultInstallerMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public businessEntityId: number;
    @DtoProp public tradeType: ITradeTypeWithPath;
    @DtoProp public contact: IIdAndLabelDto;
    @DtoProp public supplier: IIdAndLabelDto;

    constructor(
        sourceData: IBusinessEntityDefaultSupplierDto,
        logicService: IBusinessEntityDefaultInstallersLogicService
    ) {
        super(sourceData, logicService, BusinessEntityDefaultInstallerMappedItem);
    }
}

export class BusinessEntityDefaultSupplierMappedItem
    extends BaseMappedItem<IBusinessEntityDefaultProductSupplierDto, IBusinessEntityDefaultSupplierMappedItem, IBusinessEntityDefaultSuppliersLogicService>
    implements IBusinessEntityDefaultSupplierMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public businessEntityId: number;
    @DtoProp public supplyType: ISupplyTypeWithPath;
    @DtoProp public supplier: IIdAndLabelDto;

    constructor(
        sourceData: IBusinessEntityDefaultProductSupplierDto,
        logicService: IBusinessEntityDefaultSuppliersLogicService
    ) {
        super(sourceData, logicService, BusinessEntityDefaultSupplierMappedItem);
    }
}
