import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostLoadDirective } from './postLoad.directive';



@NgModule({
    declarations: [
        PostLoadDirective
    ],
    exports: [
        PostLoadDirective,
    ],
    imports: [
        CommonModule
    ]
})
export class CbPostLoadModule { }
