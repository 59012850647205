<cb-dialog>
    <form #addressRegionForm="ngForm"
          class="flex-col">
        <cb-input type="text"
                  name="name"
                  label="Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name"></cb-input>

        <mat-checkbox name="isActive"
                      [(ngModel)]="mappedItem.isActive">Is Active
        </mat-checkbox>
        <footer class="flex-row end-center cb-margin-top-2x">
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (cbClick)="save()"
                    [clickLimit]="1"
                    [disabled]="!addressRegionForm.dirty || !addressRegionForm.valid">Save
            </button>
            <button mat-raised-button
                    (click)="cancel()">Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
