import {Component, Input} from '@angular/core';
import {ILotSpecColourItemMappedItem} from '@app/logic/lot-spec-colour-item';

@Component({
    selector: 'cb-lot-spec-colour-item-icons',
    templateUrl: './lot-spec-colour-item-icons.component.html',
    styleUrls: ['./lot-spec-colour-item-icons.component.scss']
})
export class LotSpecColourItemIconsComponent {
    @Input() public readonly mappedItem: ILotSpecColourItemMappedItem;
}
