<ng-container *cbWaitFor="lotMappedItem$$ | async">
    <cb-lot-consents-list *ngIf="!lotConsentViewService.consent"
                          [lotDto]="lotDto"></cb-lot-consents-list>
    <cb-manage-building-consent *ngIf="lotConsentViewService.isBuildingConsent"
                                (reloadLotMappedItem)="reloadLotMappedItem.emit()"
                                class="flex-col flex"
                                [lotDto]="lotDto"></cb-manage-building-consent>
    <cb-manage-resource-consent *ngIf="lotConsentViewService.isResourceConsent"
                                class="flex-col flex"
                                [lotDto]="lotDto"
                                (reloadLotMappedItem)="reloadLotMappedItem.emit()"></cb-manage-resource-consent>
</ng-container>
