import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CbDasherModule } from 'cb-hub-lib';
import { ClientAccountInputFindCardComponent } from './client-account-input-find-card.component';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbTableModule } from 'cb-hub-lib';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';

@NgModule({
    declarations: [
        ClientAccountInputFindCardComponent
    ],
    exports: [
        ClientAccountInputFindCardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        MatTableModule,
        MatIconModule,
        CbDialogModule,
        CbDasherModule,
        CommonModule,
        FormsModule,
        MatInputModule,
        CbDisplayValueModule,
        MatListModule,
        CbWaitForModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCardModule,
        MatIconModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        MatTableModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        CbHubTableModule,
        CbTableModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule
    ]
})

export class CbClientAccountInputFindCardModule { }

