import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import FileSaver from 'file-saver';

@Component({
    selector: 'cb-open-pdf',
    templateUrl: './open-pdf.component.html',
    styleUrls: ['./open-pdf.component.scss']
})
export class OpenPdfComponent {

    constructor(
        protected readonly $http: HttpWrapperService,
        public readonly navigation: NavigationService,
        private route: ActivatedRoute,
        private readonly cbDialog: CbDialogService) {
        this.route.queryParams.subOnce((params: { [key: string]: string }) => {

            this.documentFetchUrl = params.documentFetchUrl as string;
            this.documentName = params.documentName as string;

            const blocker = this.cbDialog.block(`Loading ${this.documentName}`);

            this.$http.downloadRequest(this.documentFetchUrl, 'Loading Purchase Order...').subOnce((response) => {
                const fileType = response.headers.get('content-type').trim();
                this.pdfBlob = new Blob([response.body], { type: fileType });
                this.pdfFileName = response.headers.get('content-disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');

                const that = this;
                const reader = new FileReader();
                reader.readAsDataURL(this.pdfBlob);
                reader.onloadend = function() {
                    that.pdfBase64URL = reader.result as string;
                    that.isLoaded = true;
                    blocker.close();
                };

            });
        });
    }

    public documentFetchUrl: string;
    public documentName: string;

    public pdfBlob: Blob;
    public pdfFileName: string;
    public pdfBase64URL: string;
    public isLoaded = false;

    public ZOOM_STEP = 0.25;
    public DEFAULT_ZOOM = 1;
    public MAX_ZOOM = 2;
    public MIN_ZOOM = 0.25;

    public pdfZoom = this.DEFAULT_ZOOM;


    public download(): void {
        FileSaver.saveAs(this.pdfBlob, this.pdfFileName);
    }

    public zoomIn(): void {
        if (this.pdfZoom < this.MAX_ZOOM) {
            this.pdfZoom += this.ZOOM_STEP;
        }
    }

    public zoomOut(): void {
        if (this.pdfZoom > this.MIN_ZOOM) {
            this.pdfZoom -= this.ZOOM_STEP;
        }
    }

    public resetZoom(): void {
        this.pdfZoom = this.DEFAULT_ZOOM;
    }
}

