import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LotDesignPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { CompleteSchemeDialogComponent } from '@app/views/lot/design/schemes/search/complete-scheme-dialog/complete-scheme-dialog.component';
import { DesignSchemeRequestPricingDialogComponent } from '@app/views/lot/design/schemes/search/request-pricing-dialog/request-pricing-dialog.component';
import { DESIGN_SCHEME_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { mergeMap, Observable, of, Subscription } from 'rxjs';

@Component({
    selector: 'cb-design-scheme-card',
    templateUrl: './design-scheme-card.component.html',
    styleUrls: ['./design-scheme-card.component.scss'],
})
export class DesignSchemeCardComponent implements OnInit {

    @Input() public mappedItem: IDesignSchemeMappedItem;
    @Input() public lot: ILotMappedItem;
    @Output() public readonly viewClick = new EventEmitter<IDesignSchemeMappedItem>();
    public canRequestPricing$: Observable<boolean>;
    private canRequestPricingSubscription: Subscription;

    constructor(
        public readonly navigationService: NavigationService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        private readonly cbDialog: CbDialogService,
        private readonly cdref: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.canRequestPricing$ = this.mappedItem.canRequestPricing().pipe(
            mergeMap((canRequestPricingFromApi) => {
                return of(this.canRequestPricing() && canRequestPricingFromApi);
            }));

        this.canRequestPricingSubscription = this.canRequestPricing$.subscribe();
    }

    public ngOnDestroy(): void {
        this.canRequestPricingSubscription.unsubscribe();
    }

    public getStatusIconIcon(): string {
        switch (this.mappedItem.statusId) {
            case DESIGN_SCHEME_STATUS_ENUM.Accepted:
            case DESIGN_SCHEME_STATUS_ENUM.Completed:
                return 'check_circle';
            case DESIGN_SCHEME_STATUS_ENUM.Pending:
                return 'timelapse';
            case DESIGN_SCHEME_STATUS_ENUM.InQueue:
            case DESIGN_SCHEME_STATUS_ENUM.InQueueForRework:
            case DESIGN_SCHEME_STATUS_ENUM.InProgress:
            case DESIGN_SCHEME_STATUS_ENUM.InPricing:
            case DESIGN_SCHEME_STATUS_ENUM.WaitingForReview:
            case DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution:
                return 'assignment';
            case DESIGN_SCHEME_STATUS_ENUM.DesignReviewRequired:
            case DESIGN_SCHEME_STATUS_ENUM.OnHold:
                return 'pause_circle_filled';
            case DESIGN_SCHEME_STATUS_ENUM.Declined:
                return 'indeterminate_check_box';
            case DESIGN_SCHEME_STATUS_ENUM.Cancelled:
                return 'indeterminate_check_box';
            default:
                return 'assignment';
        }
    }

    public getStatusIconClass(): string {
        switch (this.mappedItem?.statusId) {
            case DESIGN_SCHEME_STATUS_ENUM.Accepted:
            case DESIGN_SCHEME_STATUS_ENUM.Completed:
                return 'mat-primary';
            case DESIGN_SCHEME_STATUS_ENUM.Pending:
                return 'mat-accent';
            case DESIGN_SCHEME_STATUS_ENUM.InQueue:
            case DESIGN_SCHEME_STATUS_ENUM.InQueueForRework:
            case DESIGN_SCHEME_STATUS_ENUM.InProgress:
            case DESIGN_SCHEME_STATUS_ENUM.InPricing:
            case DESIGN_SCHEME_STATUS_ENUM.WaitingForReview:
                return 'mat-accent';
            case DESIGN_SCHEME_STATUS_ENUM.DesignReviewRequired:
                return 'mat-accent';
            case DESIGN_SCHEME_STATUS_ENUM.OnHold:
                return 'mat-primary';
            case DESIGN_SCHEME_STATUS_ENUM.Declined:
                return 'mat-warn';
            case DESIGN_SCHEME_STATUS_ENUM.Cancelled:
                return 'mat-warn';
            default:
                return 'cb-grey';
        }
    }

    public getStatusIconTitle(): string {
        switch (this.mappedItem.statusId) {
            case DESIGN_SCHEME_STATUS_ENUM.Accepted:
                return 'Accepted';
            case DESIGN_SCHEME_STATUS_ENUM.Completed:
                return 'Completed';
            case DESIGN_SCHEME_STATUS_ENUM.Pending:
                return 'Pending';
            case DESIGN_SCHEME_STATUS_ENUM.InQueue:
                return 'In Queue';
            case DESIGN_SCHEME_STATUS_ENUM.InQueueForRework:
                return 'In Queue - Failed Review';
            case DESIGN_SCHEME_STATUS_ENUM.InProgress:
                return 'In Progress';
            case DESIGN_SCHEME_STATUS_ENUM.InPricing:
                return 'In Pricing';
            case DESIGN_SCHEME_STATUS_ENUM.DesignReviewRequired:
                return 'Design Review Required';
            case DESIGN_SCHEME_STATUS_ENUM.WaitingForReview:
                return 'Waiting for Review';
            case DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution:
                return DESIGN_SCHEME_STATUS_ENUM[DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution];
            case DESIGN_SCHEME_STATUS_ENUM.OnHold:
                return 'On Hold';
            case DESIGN_SCHEME_STATUS_ENUM.Declined:
                return 'Declined';
            case DESIGN_SCHEME_STATUS_ENUM.Cancelled:
                return 'Cancelled';
            default:
                return 'assignment';
        }
    }

    public goToLotSpecification(): void {
        this.navigationService.navigate([`lots/${this.lot.id}/spec/lotspec`]);
    }

    public canRequestPricing(): boolean {
        const can = this.mappedItem.statusId === DESIGN_SCHEME_STATUS_ENUM.Accepted &&
            this.lot?.canRequestDesignSchemePricing &&
            this.mappedItem.designSchemeDocumentDto.hasLotSpecItems &&
            this.lotDesignPermissions.canRequestSchemePricing();
        return can;
    }

    public completeScheme(): void {
        const dialogRef = this.cbDialog.open(CompleteSchemeDialogComponent, {
            data: {
                mappedItem: this.mappedItem.$clone(),
                lot: this.lot
            }
        });

        dialogRef.afterClosed()
            .subOnce(() => {
                this.lot.$reload().subOnce();
                this.cdref.detectChanges();
            });
    }

    public requestPricing(): void {
        if (!this.canRequestPricing()) {
            return;
        }

        const dialogRef = this.cbDialog.open(DesignSchemeRequestPricingDialogComponent, {
            data: {
                mappedItem: this.mappedItem.$clone(),
                lot: this.lot,
            },
        });

        dialogRef.afterClosed()
            .subOnce(() => {
                this.lot.$reload().subOnce();
                this.cdref.detectChanges();
            });
    }

    public viewDesignScheme(): void {
        this.viewClick.emit(this.mappedItem);
    }

}
