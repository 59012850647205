import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { DatePipe } from '@app/shared/pipe/date/date.pipe';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { INonWorkingDayDto, INonWorkingDayMappedItem, NonWorkingDayLogicService, NonWorkingDayMappedItem } from '@app/logic/non-working-day';
import { NonWorkingDayDialogComponent } from './non-working-day-dialog/non-working-day-dialog.component';
import { NonWorkingDayLocationDialogComponent } from './non-working-day-location-dialog/non-working-day-location-dialog.component';
import moment from 'moment';

@Component({
    selector: 'cb-non-working-days',
    templateUrl: './non-working-days.component.html',
    styleUrls: ['./non-working-days.component.scss'],
    providers: [DatePipe]
})
export class NonWorkingDaysComponent extends BaseSimpleListViewDirective<INonWorkingDayDto, NonWorkingDayLogicService> {
    public readonly displayedColumns: string[] = ['date', 'actions'];
    public years: IYear[];
    public currentYear: number;

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly nonWorkingDayLogicService: NonWorkingDayLogicService,
        protected readonly cbDate: DatePipe
    ) {
        super(
            cbDialog,
            nonWorkingDayLogicService,
            NonWorkingDayDialogComponent,
            'Non-Working Day',
            NonWorkingDayMappedItem,
            permissionsPermissions
        );
    }

    public getSearchResults(): Observable<any> {
        if (!this.years?.length) {
            this.initYearList();
        }
        return this.logicService.$getList(this.currentYear);
    }

    public onCurrentYearChanged(newYear: number): void {
        this.currentYear = newYear;
        this.logicService.$getList(newYear).subOnce(x => {
            this.handleSearchResults(x);
        });
    }

    public onDeleteClicked(item: INonWorkingDayDto): void {
        this.nonWorkingDayLogicService.updateRegions(item.id, []).subOnce(() =>
            this.deleteItemClicked(item, {
                heading: 'Delete Non-working Day',
                message: `Are you sure you want to delete "${this.cbDate.transform(item.date)}" Non-working Day?`
            })
        );
    }

    public openDialog(mappedItem: INonWorkingDayMappedItem, dialogHeading: string): MatDialogRef<any> {
        return this.cbDialog.open(NonWorkingDayDialogComponent, {
            data: {
                dialogHeading,
                mappedItem,
                year: this.currentYear,
            } as any,
        });
    }

    public onManageReionsClicked(mappedItem: INonWorkingDayMappedItem): MatDialogRef<any> {
        return this.cbDialog.open(NonWorkingDayLocationDialogComponent, {
            data: {
                dialogHeading: 'Manage Regions',
                mappedItem,
            }
        });
    }

    public sortSearchResults(searchResults: Array<INonWorkingDayDto>): Array<INonWorkingDayDto> {
        return searchResults.sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)));
    }

    private initYearList(): void {
        this.currentYear = new Date().getFullYear();
        const startYear = this.currentYear - 2;
        const endYear = this.currentYear + 2;
        this.years = [];
        for (let year = startYear; year <= endYear; year++) {
            this.years.unshift({ id: year, label: year.toString() });
        }
    }
}

interface IYear {
    id: number;
    label: string;
}
