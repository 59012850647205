import { ClientSalePermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ClientAccountEditDialogComponent } from '../../dialogs/client-account-edit-dialog/client-account-edit-dialog.component';
import { BaseClientSaleCardDirective } from '../base-client-sale-card';
import { ClientSaleDocumentsService } from '../../documents-service/client-sale-documents.service';
import { CLIENT_SALE_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Component({
    selector: 'cb-client-sale-client-account-card',
    templateUrl: './client-account-card.component.html',
    styleUrls: ['./client-account-card.component.scss'],
    providers: [NavigationService]
})
export class ClientSaleClientAccountCardComponent extends BaseClientSaleCardDirective {

    @Input() public readonly stepNumber: number;

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public get isComplete(): boolean {
        return this.clientSale?.stepCompletion?.clientAccount;
    }

    constructor(
        public readonly matDialog: MatDialog,
        public readonly navigation: NavigationService,
        public readonly permissions: ClientSalePermissions,
        public readonly clientSaleDocuments: ClientSaleDocumentsService,
    ) {
        super(clientSaleDocuments);
    }

    public openEditDialog(): void {
        if (!this.permissions.canEdit()) {
            return;
        }
        const editAddressOnly = this.clientSale?.statusId !== CLIENT_SALE_STATUS_ENUM.ContractPending && this.clientSale?.clientAccount?.physicalAddress?.id == null;
        this.matDialog
            .open(ClientAccountEditDialogComponent, {
                data: { clientSale: this.clientSale.$clone(), editAddressOnly },
                panelClass: 'cb-dialog-container',
                minWidth: 400
            })
            .afterClosed()
            .subOnce({ next: this.recomputeIconProps });
    }

    public viewClientAccount(event: MouseEvent): void {
        this.navigation.navigate([`accounts/${this.clientSale.clientAccount.id}/summary`], event);
    }
}
