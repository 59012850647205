import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[cbTotalNoStagesValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: TotalNoStagesValidatorDirective,
        multi: true
    }]
})
export class TotalNoStagesValidatorDirective implements Validator {
    @Input() public cbTotalNoStagesValidator: number;

    public validate(control: AbstractControl): ValidationErrors {
        if (control.value > 0) {
            if (parseInt(control.value, 10) >= this.cbTotalNoStagesValidator) {
                return null;
            } else {
                return { totalNoStages: `Total number of release stages cannot be less than current number of release stages (${this.cbTotalNoStagesValidator})` };
            }
        }
    }
}
