import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IBuildProgrammeTemplateDto, BuildProgrammeTemplateLogicService, BuildProgrammeTemplateMappedItem } from '@app/logic/build-programme-template';
import { BuildProgrammeTemplateDialogComponent } from './build-programme-template-dialog/build-programme-template-dialog.component';
import moment from 'moment';

@Component({
    selector: 'cb-build-programme-templates',
    templateUrl: './build-programme-templates.component.html',
    styleUrls: ['./build-programme-templates.component.scss']
})
export class BuildProgrammeTemplatesComponent extends BaseSimpleListViewDirective<IBuildProgrammeTemplateDto, BuildProgrammeTemplateLogicService> {

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly buildProgrammeTemplateLogicService: BuildProgrammeTemplateLogicService,
        protected readonly navigationService: NavigationService,
    ) {
        super(
            cbDialog,
            buildProgrammeTemplateLogicService,
            BuildProgrammeTemplateDialogComponent,
            'Build Programme Template',
            BuildProgrammeTemplateMappedItem,
            permissionsPermissions,
        );
    }

    public sortSearchResults(searchResults: IBuildProgrammeTemplateDto[]): IBuildProgrammeTemplateDto[] {
        return searchResults.sort((a, b) => moment.utc(a.updatedDate).diff(moment.utc(b.updatedDate)));
    }

    public viewTemplateClicked(mappedItem: BuildProgrammeTemplateMappedItem): void {
        this.navigationService.navigate([`/build-programme-templates/view/${mappedItem.id}/template`]);
    }

    public duplicateItemClicked(mappedItem: BuildProgrammeTemplateMappedItem): MatDialogRef<BuildProgrammeTemplateDialogComponent> {
        const newMappedItem = this.logicService.$createMappedItem(mappedItem, BuildProgrammeTemplateMappedItem);
        newMappedItem.id = undefined;
        newMappedItem.name = `Copy of ${newMappedItem.name}`;
        newMappedItem.buildProgrammeTemplateId = mappedItem.id;

        const dialog = this.cbDialog.open(BuildProgrammeTemplateDialogComponent, {
            data: {
                dialogHeading: 'Duplicate Build Programme Template',
                mappedItem: newMappedItem,
                baseTemplateName: mappedItem.name
            },
        });
        dialog.afterClosed().subOnce((item: BuildProgrammeTemplateMappedItem) => this.handleNewItem(item));
        return dialog;
    }

    protected handleNewItem(item: BuildProgrammeTemplateMappedItem): void {
        this.getSearchResults().subOnce(x => this.handleSearchResults(x));
    }

    public handleEditItem = (item: BuildProgrammeTemplateMappedItem): void => {
        this.getSearchResults().subOnce(x => this.handleSearchResults(x));
    };
}
