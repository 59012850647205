import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IPermissionGroupLogicService } from '@app/logic/security-role/interfaces/i.permission-group.logic.service';
import { IPermissionGroupMappedItem } from '@app/logic/security-role/interfaces/i.permission-group.mapped';
import { PermissionGroupAreaLogicService } from '@app/logic/security-role/permission-group-area.logic.service';
import { PermissionGroupLogicService } from '@app/logic/security-role/permission-group.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IPermissionGroupDto, IPermissionGroupSummaryDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { filter, map, some } from 'lodash';

@Component({
    selector: 'cb-security-role-report-dialog',
    templateUrl: './security-role-report-dialog.component.html',
    styleUrls: ['./security-role-report-dialog.component.scss'],
    providers: [
        PermissionGroupLogicService,
        PermissionGroupAreaLogicService
    ]
})
export class SecurityRoleReportDialogComponent
    extends BaseDialogFormViewDirective<IPermissionGroupDto, IPermissionGroupMappedItem, IPermissionGroupLogicService>
    implements OnInit {

    public static readonly MIN_WIDTH = '40%';
    public readonly roleOptions: IPermissionGroupSummaryDto[];
    public selectedRoleIds: { [key: number]: boolean } = {};
    public isSelectAll: boolean;

    constructor(
        public readonly dialogRef: MatDialogRef<SecurityRoleReportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IPermissionGroupSummaryDto[],
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(PermissionGroupLogicService) public readonly securityRoleLogicService: PermissionGroupLogicService,
    ) {
        super(dialogRef, toastService);
        this.roleOptions = data;
    }

    

    public onSelectAllChanged(): void {
        this.roleOptions.forEach(role => this.selectedRoleIds[role.id] = this.isSelectAll);
    }

    public onSelectItemChanged(): void {
        this.isSelectAll = !some(this.roleOptions, role => !this.selectedRoleIds[role.id]);
    }

    public generateRolePermissionReport(): void {
        const roleIds: number[] = map(filter(this.roleOptions, role => !!this.selectedRoleIds[role.id]), 'id');
        this.securityRoleLogicService.generateRolePermissionsReport(roleIds).subOnce(result => this.dialogRef.close(result));
    }
}
