import { BaseMappedItem } from '../base/base-mapped-item';
import { IPaymentFrequencyDto } from './interfaces/i.payment-frequency.dto';
import { IPaymentFrequencyMappedItem } from './interfaces/i.payment-frequency.mapped';
import { IPaymentFrequenciesLogicService } from './interfaces/i.payment-frequencies.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';


export class PaymentFrequencyMappedItem
    extends BaseMappedItem<IPaymentFrequencyDto, IPaymentFrequencyMappedItem, IPaymentFrequenciesLogicService>
    implements IPaymentFrequencyMappedItem {

    @DtoProp public id: number;
    @DtoProp public activityScheduleDescription: string;
    @DtoProp public label: string;

    constructor(
        sourceData: IPaymentFrequencyDto,
        logicService: IPaymentFrequenciesLogicService
    ) {
        super(sourceData, logicService, PaymentFrequencyMappedItem);
    }
}
