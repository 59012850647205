import {Component} from '@angular/core';
import {PRODUCT_TAB_FULL_ROUTE} from '../../products-route.const';

@Component({
    selector: 'cb-product-import-tab',
    templateUrl: './product-import-tab.component.html',
    styleUrls: ['./product-import-tab.component.scss']
})
export class ProductImportTabComponent {
    public readonly PRODUCT_TAB_FULL_ROUTE = PRODUCT_TAB_FULL_ROUTE;

    constructor() { }

}
