import { CbDateDurationCalculatorModule } from './../../shared/components/date-duration-calculator/date-duration-calculator.module';
import { CbFormsModule } from './../../shared/components/forms/forms.module';
import { NgModule } from '@angular/core';
import { CreateBuildProgrammeActivityDialogComponent } from './components/create-build-programme-activity-dialog/create-build-programme-activity-dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatRadioModule } from '@angular/material/radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
    declarations: [
        CreateBuildProgrammeActivityDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTabsModule,
        MatTooltipModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        CbFormsModule,
        CbDateDurationCalculatorModule
    ]
})
export class ManualOrdersModule { }
