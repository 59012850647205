import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILocationDto, LocationLogicService } from '@app/logic/location';
import { filter, find } from 'lodash';

@Component({
    selector: 'cb-business-account-location-picker',
    templateUrl: './business-account-location-picker.component.html',
    styleUrls: ['./business-account-location-picker.component.scss']
})
export class BusinessAccountLocationPickerComponent {
    @Input() public readonly isForm: boolean;

    @Input() public region: number;
    @Input() public district: number;
    @Input() public area: number;
    @Input() public availableLocations: number[];
    @Input() public disabled = false;
    @Output() public locationChange = new EventEmitter();

    public regions: ILocationDto[];
    public districts: ILocationDto[];
    public areas: ILocationDto[];
    public locations: ILocationDto[];

    private nullLocation: ILocationDto;

    constructor(
        private readonly locationLogicService: LocationLogicService,
    ) {
        this.locationLogicService.getFlatTree(false).subOnce(result => {
            this.locations = result;
            this.initRegions();
        });
    }

    public initRegions(): void {
        this.region = this.region || 0;
        this.district = this.district || 0;
        this.area = this.area || 0;
        this.nullLocation = { id: 0, label: this.isForm ? 'All' : 'None' } as ILocationDto;
        this.locationLogicService.getLocationList().subOnce(result => {
            this.regions = this.isForm ? filter(result, { isActive: true }) : [this.nullLocation, ...filter(result, { isActive: true })];
            this.regions = this.availableLocations?.length ? filter(this.regions, item => this.availableLocations.indexOf(item.id) > -1) : this.regions;
            this.districts = [this.nullLocation];
            this.areas = [this.nullLocation];
            if (this.region) {
                this.onRegionChanged(true);
            }
            if (this.district) {
                this.onDistrictChanged(true);
            }
        });
    }

    public onRegionChanged(isManual?: boolean): void {
        if (!isManual) {
            this.district = 0;
            this.area = 0;
        }
        this.onLocationChanged();
        if (this.region) {
            this.locationLogicService.getLocationList(this.region).subOnce(result => {
                this.districts = [this.nullLocation, ...filter(result, { isActive: true })];
                this.areas = [this.nullLocation];
            });
        } else {
            this.districts = [this.nullLocation];
            this.areas = [this.nullLocation];
        }
    }

    public onDistrictChanged(isManual?: boolean): void {
        if (!isManual) {
            this.area = 0;
        }
        this.onLocationChanged();
        if (this.district) {
            this.locationLogicService.getLocationList(this.district).subOnce(result => {
                this.areas = [this.nullLocation, ...filter(result, { isActive: true })];
            });
        } else {
            this.areas = [this.nullLocation];
        }
    }

    public onLocationChanged(): void {
        this.locationChange.emit({
            region: this.region,
            regionLabel: find(this.locations, { id: this.region })?.label || '',
            district: this.district,
            districtLabel: find(this.locations, { id: this.district })?.label || '',
            area: this.area,
            areaLabel: find(this.locations, { id: this.area })?.label || '',
        });
    }

    public clearLocation(): void {
        this.region = 0;
        this.onRegionChanged();
    }
}
