export const toHumanizeWords = (label: string): string => {
    const words = (label.charAt(0).toUpperCase() + label.substring(1)).match(/[A-Za-z][a-z]*/g) || [];
    return words.join(' ');
};


export const truncateText = (text: string, maxLength: number): string => {
    if (text !== undefined && text.length > maxLength) {
        text = text.substr(0, maxLength) + '...';
    }
    return text;
};
