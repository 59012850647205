import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { BuildProgrammeTemplatePermissions } from '@app/core/permissions';
import { IBuildProgrammeTemplateDto } from '@app/logic/build-programme-template';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { includes } from 'lodash';

enum Columns {
    TemplateName = 'templateName',
    CreatedBy = 'createdBy',
    UpdatedBy = 'updatedBy',
    LastUpdated = 'lastUpdated',
    IsActive = 'isActive',
    Actions = 'actions',
}

@Component({
    selector: 'cb-build-programme-template-table',
    templateUrl: './build-programme-template-table.component.html',
    styleUrls: ['./build-programme-template-table.component.scss']
})
export class BuildProgrammeTemplateTableComponent {
    @ViewChild(MatSort, { static: true }) public sort: MatSort;

    private _templates: IBuildProgrammeTemplateDto[] = [];
    @Input() public set templates(items: IBuildProgrammeTemplateDto[]) {
        this._templates = items;
        this.sortTemplates();
    }
    public get templates(): IBuildProgrammeTemplateDto[] {
        return this._templates;
    }

    @Output() public readonly viewTemplate = new EventEmitter<IBuildProgrammeTemplateDto>();
    @Output() public readonly editTemplate = new EventEmitter<IBuildProgrammeTemplateDto>();
    @Output() public readonly duplicateTemplate = new EventEmitter<IBuildProgrammeTemplateDto>();

    public readonly columnsEnum = Columns;
    public readonly displayedColumns = Object.values(Columns);
    public sortedTemplates: IBuildProgrammeTemplateDto[] = [];

    constructor(
        public readonly buildProgrammeTemplatePermissions: BuildProgrammeTemplatePermissions,
        protected readonly currentUserService: CurrentUserService,
    ) {
    }

    public sortTemplates(): void {
        const templates = this._templates.slice() ?? [];
        if (!this.sort?.active || isNullOrWhiteSpace(this.sort?.direction)) {
            this.sortedTemplates = templates;
            return;
        }
        this.sortedTemplates = templates.slice().sort((a, b) => {
            const isAsc = this.sort?.direction === 'asc';
            switch (this.sort.active) {
                case Columns.TemplateName: return this.compare(a.name, b.name, isAsc);
                case Columns.CreatedBy: return this.compare(a.createdByName, b.createdByName, isAsc);
                case Columns.UpdatedBy: return this.compare(a.updatedByName, b.updatedByName, isAsc);
                case Columns.LastUpdated: return this.compare(a.updatedDate, b.updatedDate, isAsc);
                default: return 0;
            }
        });
    }

    public canEdit(template: IBuildProgrammeTemplateDto): boolean {
        let canEdit = false;
        if (this.buildProgrammeTemplatePermissions.canEditAll()) {
            canEdit = true;
        } else {
            canEdit = template.regions.some(i => {
                return includes(this.currentUserService.regionsids ?? [], i) && this.buildProgrammeTemplatePermissions.canEdit();
            });
        }
        return canEdit;
    }

    public canDuplicate(template: IBuildProgrammeTemplateDto): boolean {
        return this.buildProgrammeTemplatePermissions.canDuplicate();
    }

    private compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
