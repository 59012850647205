import { Component, Input, OnDestroy } from '@angular/core';
import { IPricingRevisionMappedItem } from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cb-pricing-revision-side-bar-card',
    templateUrl: './pricing-revision-side-bar-card.component.html',
    styleUrls: ['./pricing-revision-side-bar-card.component.scss']
})
export class PricingRevisionSideBarCardComponent implements OnDestroy {
    private sub$ = new Subscription();
    private _mappedItem: IPricingRevisionMappedItem;
    @Input() public set mappedItem(item: IPricingRevisionMappedItem) {
        this._mappedItem = item;
        this.sub$.unsubscribe();
        if (this.mappedItem) {
            this.sub$ = this.mappedItem.$updated.subscribe(() => {
                this.pollElapsedTime();
            });
        }
    }
    public get mappedItem(): IPricingRevisionMappedItem {
        return this._mappedItem;
    }

    public clientsideElapsedTimeDuration: any = {};
    public durationText: string;
    private elapsedTimeDuration = moment.duration();
    private dateTimeLastPolled = moment();
    private timeout = 1000;

    constructor() { }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    // Get initial calculated elapsed time from the server.
    private readonly pollElapsedTime = (): void => {
        this.mappedItem.getElapsedTime().subOnce((result) => {
            this.dateTimeLastPolled = moment();

            const durationText = result.toString().replace(/\"/g, '');
            this.elapsedTimeDuration = moment.duration(durationText);
            setTimeout(this.updateElapsedTimeClientside, this.timeout);
        });
    };

    /* Calulate the elapsed time on clientside to avoid constant polling serverside */
    private readonly updateElapsedTimeClientside = (): void => {
        if (this.mappedItem.isInProgress) {
            const dateNow = moment();
            // Calculates how long since the initial elapsed time was updated form the server-side
            // and add duration to now.
            const duration = moment.duration(dateNow.diff(this.dateTimeLastPolled));

            // Now add that duration to initial duration to get real-time duration.
            this.clientsideElapsedTimeDuration = moment.duration(this.elapsedTimeDuration);
            this.clientsideElapsedTimeDuration.add(duration.asSeconds(), 's');

            // set model to allow duration input control to format duration as required
            setTimeout(this.updateElapsedTimeClientside, this.timeout);
        } else {
            // since not in progress no further time is elapsing.
            this.clientsideElapsedTimeDuration = moment.duration(this.elapsedTimeDuration);
        }

        this.formatText();
    };


    public formatText(): void {
        if (this.clientsideElapsedTimeDuration !== undefined) {
            try {
                const hours = Math.floor(this.clientsideElapsedTimeDuration.asHours());
                const minsSeconds = moment(this.clientsideElapsedTimeDuration.asMilliseconds()).format('mm:ss').toString();
                this.durationText = `${hours}:${minsSeconds}`;
            } catch (error) {
                this.durationText = '';
            }
        } else {
            this.durationText = '';
        }

    }

}
