import * as _ from 'lodash';

import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IBuildActivityMappedItem } from '@app/logic/build-activities/interfaces/i.build-activity.mapped';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { ISkinnyOfferingDto } from '@app/logic/offerings';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { CbTextAreaAutoUpdateHeightDirective } from '@app/shared/directives/text-area-auto-update-height/text-area-auto-update-height.directive';
import { FormMode } from '@app/shared/enums/form';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { BUILD_ACTIVITY_REQUIRED_LEVEL_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Subscription } from 'rxjs';
import { SelectListItem } from 'src/app/core/services/enum/enum.service';

@Component({
    selector: 'build-activity-schedule',
    templateUrl: './build-activity-schedule.component.html',
    styleUrls: ['./build-activity-schedule.component.scss'],
    providers: [
        UsersLogicService,
        BusinessEntitiesLogicService,
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class BuildActivityScheduleComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren('nationalSpecificationsTable') public nationalSpecificationsTable: QueryList<MatTable<any>>;
    @ViewChildren('nationalScopesTable') public nationalScopesTable: QueryList<MatTable<any>>;
    @ViewChildren('nationalProductsTable') public nationalProductsTable: QueryList<MatTable<any>>;
    @ViewChildren('regionalProductsTable') public regionalProductsTables: QueryList<MatTable<any>>;
    @ViewChildren('regionalScopesTable') public regionalScopesTables: QueryList<MatTable<any>>;
    @ViewChildren('regionalSpecificationsTable') public regionalSpecificationsTables: QueryList<MatTable<any>>;
    @ViewChildren(CbTextAreaAutoUpdateHeightDirective) public textAreas: QueryList<CbTextAreaAutoUpdateHeightDirective>;
    @Input() public buildActivity: IBuildActivityMappedItem;
    @Input() public buildActivityForm: NgForm;
    public regions: IBusinessEntityDto[];
    public selectedProduct: ISkinnyOfferingDto;
    public taskAnalysisFormRequired: boolean;
    public readonly specTableColumns: string[] = ['specifications', 'actions'];
    public readonly scopeTableColumns: string[] = ['scopes', 'actions'];
    public readonly productTableColumns: string[] = ['products', 'actions'];
    public formMode: FormMode;
    public buildActivityRequiredLevels: Array<SelectListItem>;
    private readonly subscriptions$ = new Subscription();

    constructor(
        public readonly navigationService: NavigationService,
        public readonly currentUser: CurrentUserService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: MatDialog,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService
    ) {
        this.formMode = FormMode.View;
        this.buildActivityRequiredLevels = BUILD_ACTIVITY_REQUIRED_LEVEL_ENUM.toSelectList();
    }

    public ngOnInit(): void {
        this.businessEntitiesLogicService.$getList().subOnce(x => {
            this.regions = x.filter(y => y.isActive);
        });
    }

    public ngAfterViewInit(): void {
        this.subscriptions$.add(
            this.nationalSpecificationsTable.changes.subscribe((comps: any) => {
                this.nationalSpecificationsTable = comps.find((element) => element._elementRef.nativeElement.dataset.name === 'nationalSpecificationsTable');
            })
        );
        this.subscriptions$.add(
            this.nationalScopesTable.changes.subscribe((comps: any) => {
                this.nationalScopesTable = comps.find((element) => element._elementRef.nativeElement.dataset.name === 'nationalScopesTable');
            })
        );
        this.subscriptions$.add(
            this.nationalProductsTable.changes.subscribe((comps: any) => {
                this.nationalProductsTable = comps.find((element) => element._elementRef.nativeElement.dataset.name === 'nationalProductsTable');
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    public regionalTabChanged(): void {
        this.textAreas.forEach(textArea => {
            textArea.delayedResize();
        });
    }

    public addLineItem(lineItemType: string, nationalTableName: string, regionalTableName: string): void {
        const nationalSchedule = this.buildActivity.getNationalSchedule()[lineItemType];
        if (nationalSchedule) {
            const lineItem = { sortOrder: nationalSchedule.length + 1, text: '' };

            nationalSchedule.push(lineItem);
            this.addToRegionalSchedules(lineItem, lineItemType);
        }
        this.renderNationalTables(nationalTableName);
        this.renderRegionalTables(regionalTableName);
    }

    private renderNationalTables(name: string): void {
        switch (name) {
            case 'nationalProductsTable':
                this.nationalProductsTable.forEach(table => {
                    table.renderRows();
                });
                break;
            case 'nationalScopesTable':
                this.nationalScopesTable.forEach(table => {
                    table.renderRows();
                });
                break;
            case 'nationalSpecificationsTable':
                this.nationalSpecificationsTable.forEach(table => {
                    table.renderRows();
                });
                break;
            default:
                break;
        }
    }

    private renderRegionalTables(name: string): void {
        switch (name) {
            case 'regionalProductsTable':
                this.regionalProductsTables.forEach(table => {
                    table.renderRows();
                });
                break;
            case 'regionalScopesTable':
                this.regionalScopesTables.forEach(table => {
                    table.renderRows();
                });
                break;
            case 'regionalSpecificationsTable':
                this.regionalSpecificationsTables.forEach(table => {
                    table.renderRows();
                });
                break;
            default:
                break;
        }
    }

    public copyContentFromNational = (regionId: number, lineItemsName: string) => {
        this.buildActivity.copyContentFromNational(regionId, lineItemsName);
        this.buildActivityForm.form.markAsDirty();
    };

    public addToRegionalSchedules(lineItem: { sortOrder: number; text: string }, lineItemType: string): void {
        this.regions.forEach((region) => {
            let regionalSchedule = this.buildActivity.getActivityScheduleByRegion(region.id);
            if (regionalSchedule == null) {
                regionalSchedule = _.find(this.buildActivity.buildActivitySchedules, { businessEntityId: region.id });
            }
            regionalSchedule[lineItemType].push(_.clone(lineItem));
        });
    }
}
