export enum BuildProgrammeActivityIcons {
    FileDocumentBoxMultiple = 'text-box-multiple',
    FileDocumentBoxMultipleOutline = 'text-box-multiple-outline'
}

export enum BuildProgrammeActivityIconClass {
    Primary = 'cb-primary',
    Accent = 'cb-accent',
    Warn = 'cb-warn',
    Error = 'cb-error',
    Grey = 'cb-grey',
    LightGrey = 'cb-light-grey',
}
