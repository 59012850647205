import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILotSpecItemMappedItem } from '@app/logic/lot-spec-item';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import {
    COST_TYPE_ENUM,
    ILotSpecItemDto,
    OFFERING_STATUS_ENUM,
    PRODUCT_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IHaveLotSpecItemUiActions, LotSpecItemUIActions } from './lot-spec-item-ui-actions';



@Component({
    selector: 'cb-lot-spec-item-card',
    templateUrl: './lot-spec-item-card.component.html',
    styleUrls: ['./lot-spec-item-card.component.scss'],
    providers: [
        LotSpecItemUIActions
    ]
})
export class LotSpecItemCardComponent implements IHaveLotSpecItemUiActions {

    @Input() public readonly lotSpec: ILotSpecMappedItem;
    @Input() public readonly mappedItem: ILotSpecItemMappedItem;
    @Output() public readonly itemDeleted = new EventEmitter<ILotSpecItemDto>();
    @Output() public readonly itemEdited = new EventEmitter<ILotSpecItemDto>();

    public readonly COST_TYPE_ENUM = COST_TYPE_ENUM;
    public readonly OFFERING_STATUS_ENUM = OFFERING_STATUS_ENUM;
    public readonly PRODUCT_TYPE_ENUM = PRODUCT_TYPE_ENUM;

    constructor(
        public readonly uiActions: LotSpecItemUIActions,
    ) {
        this.uiActions.setController(this);
    }

    public viewBundleItems(): void {
        // const bundleItems = this.getBundleItems(this.mappedItem.id as number);

        // return this.dialogLauncher.open(
        //     'view-bundle-lot-spec-items-dialog',
        //     event,
        //     { bundleItems, mappedItem: this.mappedItem, parentBundleItem: this.mappedItem, specVersion: this.specVersion }, 70);
    }
}
