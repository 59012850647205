import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {CbInfoMessageModule, CbInfoPopupModule, CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';
import {MaintenanceSettingsComponent} from '@app/views/settings/pages/maintenance-settings/maintenance-settings.component';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {CbHubTableModule} from '@app/shared/components/table/table.module';

@NgModule({
    declarations: [
        MaintenanceSettingsComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbHubTableModule,
        CbTableModule,
        CbInfoPopupModule
    ],
    exports: [
        MaintenanceSettingsComponent
    ]
})
export class MaintenanceSettingsModule { }
