<mat-card class="start-center flex-row">
    <button class="cb-margin-right"
            mat-button
            (click)="backToSchemes()">
        <mat-icon>arrow_back</mat-icon>
        &nbsp;Back to Schemes
    </button>
    <button class="cb-margin-right"
            color="primary"
            mat-raised-button
            [disabled]="!mappedItem.canReview"
            (click)="review()">
        <mat-icon>person</mat-icon>
        &nbsp;Review
    </button>
    <button class="cb-margin-right"
            color="primary"
            mat-raised-button
            (click)="manageScheme()"
            [disabled]="!mappedItem.canManage"
            *ngIf="canEditScheme()">
        <mat-icon>edit</mat-icon>
        &nbsp;Manage Scheme
    </button>
    <button class="cb-margin-right"
            color="primary"
            mat-raised-button
            (click)="editSchemeAmenities()"
            *ngIf="canEditAmenities()">
        <mat-icon>edit</mat-icon>
        &nbsp;Edit Scheme Amenities
    </button>
    <button class="cb-margin-right"
            color="primary"
            mat-raised-button
            (click)="resubmit()"
            *ngIf="mappedItem.canResubmit">
        <mat-icon>keyboard_return</mat-icon>
        &nbsp;Resubmit
    </button>
    <button *ngIf="canChangeStatus()"
            color="primary"
            mat-raised-button
            [matMenuTriggerFor]="statusMenu"
            (click)="$event.stopPropagation()">
        <mat-icon>arrow_drop_down</mat-icon>
        &nbsp;Scheme Status
    </button>
    <mat-menu #statusMenu="matMenu">
        <button mat-menu-item
                (click)="setInQueue()"
                [disabled]="!mappedItem.canChangeStatus || !mappedItem.canSetToInQueue">
            <mat-icon color="accent">timelapse</mat-icon>
            <span>&nbsp;In Queue</span>
        </button>
        <button mat-menu-item
                (click)="setInProgress()"
                [disabled]="!mappedItem.canChangeStatus || !mappedItem.canSetToInProgress">
            <mat-icon color="warn">check</mat-icon>
            <span>&nbsp;In Progress</span>
        </button>
        <button mat-menu-item
                (click)="setOnHold()"
                [disabled]="!mappedItem.canChangeStatus">
            <mat-icon color="primary">pause</mat-icon>
            <span>&nbsp;On Hold</span>
        </button>
        <button mat-menu-item
                (click)="setOnFinalise()"
                [disabled]="!canFinalise()">
            <mat-icon color="primary">send</mat-icon>
            <span>&nbsp;Finalise</span>
        </button>
        <button mat-menu-item
                *ngIf="userCanRejectDesignScheme"
                (click)="rejectDesignScheme()"
                [disabled]="!mappedItem.canManage">
            <mat-icon color="accent">keyboard_return</mat-icon>
            <span>&nbsp;Reject</span>
        </button>
        <button mat-menu-item
                (click)="cancelDesignScheme()"
                [disabled]="!mappedItem.canCancel">
            <mat-icon color="primary">cancel</mat-icon>
            <span>&nbsp;Cancel</span>
        </button>
    </mat-menu>
</mat-card>
