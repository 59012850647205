import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CouncilRfiViewService } from '../service/council-rfi-view.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotMappedItem } from '@app/logic/lots';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'cb-council-rfi-main',
    templateUrl: './council-rfi-main.component.html',
    styleUrls: ['./council-rfi-main.component.scss'],
    providers: [
        CouncilRfiViewService
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouncilRfiMainComponent implements OnInit {

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public sub$: Subscription;

    constructor(
        public readonly councilRfiViewService: CouncilRfiViewService,
        public readonly navigation: NavigationService,
        public readonly cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.loadFromParams();
        this.sub$ = this.councilRfiViewService.councilRfiChanged$.subscribe(x => {
            this.cdRef.detectChanges();
        });
    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    public loadFromParams(): void {
        const params = this.navigation.getQueryParams();
        if (params?.rfiId) {
            this.councilRfiViewService.loadCouncilRfi(params.rfiId as number, params.qnsId as number);
        }

        if (params?.paramEntityId) {
            this.councilRfiViewService.loadCouncilRfi(params.paramEntityId as number, params.qnsId as number);
        }
        this.cdRef.detectChanges();
    }
}
