import { ChangeDetectorRef, Component } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IIncompleteBuildActivitySearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { BuildProgrammeActivityLogicService } from '@app/logic/build-programme-activity';
import { IPurchaseOrderSearchDto, PurchaseOrdersLogicService } from '@app/logic/purchase-orders';
import { IPurchaseOrderSearchParams } from '@app/logic/purchase-orders/purchase-orders.logic.service';
import { SsrsLogicService } from '@app/logic/ssrs';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import {
    CompleteMultipleActivitiesDialogComponent
} from '@app/views/teams/all-teams/incomplete-build-activities/complete-multiple-activities-dialog/complete-multiple-activities-dialog.component';
import { IStringIdAndLabelDto, IUserDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'cb-incomplete-build-activities',
    templateUrl: './incomplete-build-activities.component.html',
    styleUrls: ['./incomplete-build-activities.component.scss']
})
export class IncompleteBuildActivitiesComponent extends BaseSearchViewDirective<IIncompleteBuildActivitySearch> {

    public results: IPurchaseOrderSearchDto[] = [];
    public currentPage: number;
    public constructionManagerFullInfoList: IUserDetailsDto[];
    public constructionManagers: IStringIdAndLabelDto[];
    public selectedConstructionManager: string;

    /** checked document entity ids */
    public checkedPurchaseOrderIds: { [purchaseOrderId: number]: boolean } = {};
    public allChecked = false;

    public readonly orders = [
        {
            id: 0,
            label: 'PO Number (Smallest > Largest)'
        },
        {
            id: 'enddate',
            label: 'Activity End Date (Closest >  Furthest)'
        }
    ];

    public readonly displayedColumns: string[] = [
        'PO #',
        'Job Number',
        'Lot Number + Street',
        'Client',
        'Activity',
        'End Date',
        'Supplier',
        'Construction Manager',
        'PO Value',
        'Compliance',
        'Invoice Status',
        '',
    ];

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly cbDialog: CbDialogService,
        public readonly purchaseOrderLogic: PurchaseOrdersLogicService,
        public readonly toastService: ToastService,
        public readonly userCacheService: UserCacheService,
        private readonly navigationService: NavigationService,
        private readonly currentUser: CurrentUserService,
        private readonly ssrLogic: SsrsLogicService,
        private readonly buildProgrammeActivityLogic: BuildProgrammeActivityLogicService,
        private readonly teamsLogic: TeamsLogicService,
        private readonly cdRef: ChangeDetectorRef
    ) {
        super(
            purchaseOrderLogic,
            toastService,
            userCacheService,
            USER_CACHE_AREA.IncompleteBuildActivities
        );

        this.userCacheItem.init().then(() => {
            this.loadConstructionManagers();
            this.userCacheItem.data.businessEntityId = null;
        });
    }

    public search(): Observable<ISearchResult<IPurchaseOrderSearchDto>> {
        return this.purchaseOrderLogic.$getSearchList(this.getSearchParams(), (v) => !v);
    }

    protected getSearchParams(): IPurchaseOrderSearchParams & IIncompleteBuildActivitySearch {
        return {
            ...this.userCacheItem.copyData(),
            constructionManagerId: this.userCacheItem.data.constructionManagerId,
            constructionManagerTags: this.getConstructionManagerTagList(),
            businessEntityId: this.getConstructionManagerBusinessEntityList(),
            currentPage: this.currentPage
        };
    }

    public markComplete(purchaseOrder: IPurchaseOrderSearchDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Complete Activity',
            message: 'Are you sure you want to complete this PO?',
            confirmed: () => {
                if (purchaseOrder.isManualOrder) {
                    this.buildProgrammeActivityLogic
                        .completeManualOrder(purchaseOrder.buildProgrammeActivityId)
                        .subOnce(() => this.removePurhcaseOrderResult(purchaseOrder.id));
                } else {
                    this.ssrLogic
                        .completeSsr(purchaseOrder.ssrId)
                        .subOnce(() => this.removePurhcaseOrderResult(purchaseOrder.id));
                }
            }
        });
    }

    public viewActivity(purchaseOrder: IPurchaseOrderSearchDto, event: MouseEvent): void {
        this.navigationService.navigate([`/lots/${purchaseOrder.lotId}/build/programme`], event, {
            queryParams: {
                buildProgrammeActivityId: purchaseOrder.buildProgrammeActivityId,
            }
        });
    }

    public downloadPo(purchaseOrder: IPurchaseOrderSearchDto): void {
        if (purchaseOrder.isManualOrder) {
            this.purchaseOrderLogic
                .downloadManualPO(purchaseOrder.id)
                .subOnce();
        } else {
            this.purchaseOrderLogic
                .downloadSsrPO(purchaseOrder.ssrId)
                .subOnce();
        }
    }

    public readonly removePurhcaseOrderResult = (purchaseOrderId: number): void => {
        const index = this.results.findIndex(x => x.id === purchaseOrderId);
        if (index > -1) {
            this.results.splice(index, 1);
            this.cdRef.detectChanges();
        }
    };

    public selectConstructionManager(): void {
        this.userCacheItem.data.constructionManagerId = [this.selectedConstructionManager];
        this.checkedPurchaseOrderIds ={};
        this.allChecked = false;
    }

    private loadConstructionManagers(): void {
        // Get the list of allowed construction managers we can see
        // Depending on the tags your user has, this list varies greatly
        this.teamsLogic.getConstructionManagersAllowedByUser(this.currentUser.guid)
            .subOnce((results) => {

                this.constructionManagerFullInfoList = results;
                this.constructionManagers = results
                    .map(x => ({
                        id: x.id,
                        label: x.firstName + ' ' + x.lastName,
                    }));

                this.selectedConstructionManager = null;
                if (this.constructionManagers?.length > 0) {
                    // If our user is present in the list, then set us as the selected user
                    // Otherwise, default to the first user in the list
                    const selectedIndex = this.constructionManagers.findIndex(x => x.id === this.currentUser.guid);
                    this.selectedConstructionManager = selectedIndex > -1
                        ? this.constructionManagers[selectedIndex].id
                        : this.constructionManagers[0].id;
                }

                this.userCacheItem.data.constructionManagerId = [this.selectedConstructionManager];
            });
    }

    private getConstructionManagerTagList(): string[] {
        if (!this.constructionManagerFullInfoList) {
            return [];
        }

        const constructionManagerId = (this.userCacheItem.data.constructionManagerId)
            ? this.userCacheItem.data.constructionManagerId.toString()
            : [];

        const index = this.constructionManagerFullInfoList.findIndex(x => x.id === constructionManagerId);
        if (index === -1) {
            return [];
        }

        return this.constructionManagerFullInfoList[index].selectedTags.map(x => x.code);
    }

    private getConstructionManagerBusinessEntityList(): number[] {
        if (!this.constructionManagerFullInfoList) {
            return [];
        }

        const constructionManagerId = (this.userCacheItem.data.constructionManagerId)
            ? this.userCacheItem.data.constructionManagerId.toString()
            : [];

        const index = this.constructionManagerFullInfoList.findIndex(x => x.id === constructionManagerId);
        if (index === -1) {
            return [];
        }

        return this.constructionManagerFullInfoList[index].selectedBusinessEntities;
    }

    public get completeMultipleLabel(): string {
        const str =  'Complete Selected';
        return str;
    }

    public get isAnyPurchaseOrderSelected(): boolean {
        return Object.values(this.checkedPurchaseOrderIds)?.some(x => x);
    }

    public get toggleAllLabel(): string {
        const label = this.allChecked ? 'Deselect All' : 'Select All';
        return label;
    }

    public toggleAllChecked(event: MouseEvent | null, value = !this.allChecked): void {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.allChecked = value;
        if (this.allChecked) {
            this.results.forEach(x => this.checkedPurchaseOrderIds[x.id] = true);
        } else {
            this.results.forEach(x => this.checkedPurchaseOrderIds[x.id] = false);
        }
    }

    public completeAllChecked(): void {
        const dialog = this.cbDialog.open(CompleteMultipleActivitiesDialogComponent, {
            data: {
                purchaseOrders: this.results.filter(x => this.checkedPurchaseOrderIds[x.id]),
                prefixColumns: this.displayedColumns,
            },
            minWidth: 980,
            maxWidth: '95%',
        });

        dialog.afterClosed().subOnce(() => {
            this.userCacheItem.data.constructionManagerId = null;
            this.selectConstructionManager();
        });
    }

}
