<div class="flex-col flex">
    <cb-document *ngIf="mappedItem$$ | async"
                 [entity]="mappedItem"
                 [permissions]="documentPermissions"
                 (documentsChange)="lotMappedItemChange.emit($event)"
                 [includeLinked]="true"
                 [includeDeleted]="false"
                 [hideDeletedToggle]="true"
                 [systemArea]="systemArea"
                 [documentStubGroupCode]="documentStubGroupCode"
                 [hideDocumentStubButton]="false"
                 [documentStubDialogTitle]="'Design Requirements'">
    </cb-document>
</div>
