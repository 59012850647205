<div class="flex-col flex">
    <div class="end-center flex-row flex">
        <button mat-raised-button
                class="cb-margin-bottom-2x cb-margin-top-2x cb-margin-right-2x"
                color="primary"
                (cbClick)="releaseToSalesDialog()"
                [disabled]="lots?.length < 1"
                *ngIf="projectReleaseStagePermissions.getLotPermissions().canRelease()">
            Release Lots to Sales
        </button>
        <button mat-raised-button
                class="cb-margin-bottom-2x  cb-margin-top-2x"
                color="primary"
                (cbClick)="importLotsDialog()"
                [disabled]="!canImportLots()"
                *ngIf="projectReleaseStagePermissions.getLotPermissions().canImport()">
            Import Lots
        </button>
    </div>
    <cb-table *cbWaitFor="mappedItem"
              class="cb-margin-top cb-margin-bottom-3x flex"
              [loaded]="loaded">
        <thead cbTableHead>
            <tr>
                <td></td>
                <td></td>
                <td>LOT #</td>
                <td>Address</td>
                <td>Lot Type</td>
                <td>Build Link</td>
                <td>Floor Area</td>
                <td>Land Price</td>
                <td>Sell Price</td>
                <td>Contract Price</td>
                <td>Job #</td>
                <td>Job Status</td>
                <td>Bed</td>
                <td>Bath</td>
                <td>Living</td>
                <td>Garage</td>
                <td>Carpark</td>
                <td>Study</td>
                <td style="text-align: center;">Mkt Status</td>
                <td></td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr cbTableRow
                *ngFor="let lot of lots | cbSortByPipe: 'lotNumber': 'asc'">
                <td>
                    <mat-icon class="cb-grey"
                              *ngIf="lot.numberConceptImages === 1">photo</mat-icon>
                    <mat-icon class="cb-grey"
                              *ngIf="lot.numberConceptImages === 2">collections</mat-icon>
                </td>
                <td>
                    <mat-icon class="cb-grey"
                              *ngIf="lot.specTemplateId > 0">photo</mat-icon>
                </td>
                <cb-td-text [value]="lot.lotNumber"></cb-td-text>
                <td>
                    {{lot.lotAddress.street}}
                    <br/>
                    {{lot.lotAddress.suburb}}
                </td>
                <td>
                    {{LOT_TYPE_ENUM[lot.lotType]}}
                    <span *ngIf="lot.buildType.label">({{lot.buildType.code}})</span>
                    <br/>
                    {{LOT_CONTRACT_TYPE_ENUM[lot.contractType || lot.lotContractType]}}
                </td>
                <cb-td-text [value]="lot.lotBuildTypeLink"></cb-td-text>
                <td>
                    {{lot.amenities.floorArea}} m²
                </td>
                <cb-td-currency [value]="lot.financials.landPrice"></cb-td-currency>
                <cb-td-currency [value]="lot.financials.sellPrice"></cb-td-currency>
                <cb-td-currency [value]="lot.financials.contractPrice"></cb-td-currency>
                <cb-td-text [value]="lot.jobNumber"></cb-td-text>
                <cb-td-text [value]="LOT_JOB_STATUS_ENUM[lot.jobStatus]"></cb-td-text>
                <td style="white-space: nowrap;">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey"
                                  *ngIf="!isBlockLotType(lot.lotType)">hotel</mat-icon>
                        <span>&nbsp;{{getAmenitiesDisplay(lot.amenities.noBedrooms, lot.lotType)}}</span>
                    </div>
                </td>
                <td style="white-space: nowrap;">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey"
                                  *ngIf="!isBlockLotType(lot.lotType)">wc</mat-icon>
                        <span>&nbsp;{{getAmenitiesDisplay(lot.amenities.noBathrooms, lot.lotType)}}</span>
                    </div>
                </td>
                <td style="white-space: nowrap;">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey"
                                  *ngIf="!isBlockLotType(lot.lotType)">weekend</mat-icon>
                        <span>&nbsp;{{getAmenitiesDisplay(lot.amenities.noLivingRooms, lot.lotType)}}</span>
                    </div>
                </td>
                <td style="white-space: nowrap;">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey"
                                  *ngIf="!isBlockLotType(lot.lotType)">home</mat-icon>
                        <span>&nbsp;{{getAmenitiesDisplay(lot.amenities.noGarages, lot.lotType)}}</span>
                    </div>
                </td>
                <td style="white-space: nowrap;">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey">directions_car
                        </mat-icon>
                        <span>&nbsp;{{lot.amenities.noCarparks}}</span>
                    </div>
                </td>
                <td style="white-space: nowrap;">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey">import_contacts
                        </mat-icon>
                        <span>&nbsp;{{lot.amenities.noStudy}}</span>
                    </div>
                </td>
                <cb-td-icon class="center-icon-col cb-grey"
                            [value]="getMarketStatusIcon(lot)"></cb-td-icon>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <button [matMenuTriggerFor]="menu"
                            #menuTrigger="matMenuTrigger"
                            mat-icon-button>
                        <mat-icon class="cb-grey">more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu>
                        <button mat-menu-item
                                *ngIf="lotPermissions.canView()"
                                (click)="viewLot(lot)">
                            <mat-icon>pageview</mat-icon>
                            &nbsp;
                            View Lot
                        </button>
                        <button *ngIf="lotPermissions.canEdit()"
                                mat-menu-item
                                [disabled]="!canEditLot(lot) || lot.hasClientSale"
                                (click)="editLot(lot)">
                            <mat-icon>mode_edit</mat-icon>
                            &nbsp;
                            Edit Lot
                        </button>
                        <button mat-menu-item
                                *ngIf="lotPermissions.canView()"
                                (click)="viewLotDetails(lot)">
                            <mat-icon>mode_edit</mat-icon>
                            &nbsp;View Lot Details
                        </button>
                        <button *ngIf="lotPermissions.canRelease()"
                                mat-menu-item
                                [disabled]="!canMakeAvailableForSale(lot)"
                                (click)="makeAvailableForSale(lot)">
                            <mat-icon>library_books</mat-icon>
                            &nbsp;Make Available for Sale
                        </button>
                        <button *ngIf="lotPermissions.canRelease()"
                                mat-menu-item
                                [disabled]="!canMakeUnavailableForSale(lot)"
                                (click)="makeUnavailableForSale(lot)">
                            <mat-icon>library_books</mat-icon>
                            &nbsp;Make Unavailable for Sale
                        </button>
                        <button *ngIf="this.projectReleaseStagePermissions.getLotPermissions().canSetAsExternalSale()"
                                mat-menu-item
                                [disabled]="lot.lotContractType !== LOT_CONTRACT_TYPE_ENUM.HouseAndLand"
                                (click)="setAsExternalSale(lot)">
                            <mat-icon>library_books</mat-icon>
                            &nbsp;Set as External Sale
                        </button>
                        <button *ngIf="projectReleaseStagePermissions.getLotPermissions().canSwitchReleaseStage()"
                                mat-menu-item
                                [disabled]="!canSwitchReleaseStage(lot)"
                                (click)="switchReleaseStage(lot)">
                            <mat-icon>library_books</mat-icon>
                            &nbsp;Switch Release Stage
                        </button>
                        <button *ngIf="this.projectReleaseStagePermissions.getLotPermissions().canRemoveLotFromRelease()"
                                mat-menu-item
                                [disabled]="!canRemoveLotFromRelease(lot)"
                                (click)="removeLotFromRelease(lot)">
                            <mat-icon>delete</mat-icon>
                            &nbsp;Remove Lot from Release
                        </button>
                    </mat-menu>
                </td>
            </tr>
            <tr *ngIf="lots?.length < 1 && loaded">
                <td colspan="17">
                    <cb-info-message message="No Lots have been added to this Release Stage"></cb-info-message>
                </td>
            </tr>
        </tbody>
    </cb-table>
</div>
