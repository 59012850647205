import {Component, Input} from '@angular/core';
import {LotSummaryPinnablePanelDirective} from '@app/views/lot/shared/lot-summary-pinnable-panel';
import {ILotMappedItem} from '@app/logic/lots';

@Component({
    selector: 'cb-lot-description',
    templateUrl: './lot-description.component.html',
    styleUrls: ['./lot-description.component.scss']
})
export class LotDescriptionComponent extends LotSummaryPinnablePanelDirective {

    @Input() public lotMappedItem: ILotMappedItem;
}
