import { Component } from '@angular/core';
import { BundleTemplatesLogicService } from '@app/logic/bundle-templates/bundle-templates.logic.service';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { BundleTemplateMappedItem } from '@app/logic/bundle-templates/bundle-template.mapped';
import { BundleTemplateDialogComponent } from './bundle-template-dialog/bundle-template-dialog.component';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IBundleTemplateDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-bundle-templates',
    templateUrl: './bundle-templates.component.html',
    styleUrls: ['./bundle-templates.component.scss'],
    providers: [BundleTemplatesLogicService, UsersLogicService]
})
export class BundleTemplatesComponent extends BaseSimpleListViewDirective<IBundleTemplateDto, BundleTemplatesLogicService> {
    public readonly displayedColumns: string[] = [
        'name',
        'isActive',
        'actions'
    ];

    constructor(
        public readonly dialogRef: CbDialogService,
        protected readonly bundleTemplatesLogicService: BundleTemplatesLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
    ) {
        super(
            dialogRef,
            bundleTemplatesLogicService,
            BundleTemplateDialogComponent,
            'Bundle Template',
            BundleTemplateMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<IBundleTemplateDto>): Array<IBundleTemplateDto> {
        return searchResults.sort((a, b) => a.name.localeCompare(b.name));
    }
}



