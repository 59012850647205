<form class="flex-col flex"
      #form="ngForm">
    <mat-card class="cb-margin flex-col flex">
        <mat-card-header>
            <div class="start-start flex-row">
                <div class="cb-margin-right-2x flex-row"
                     style="margin-top: 5px;">
                    <mat-icon [ngClass]="getStatusIconClass()"
                              class="help-cursor"
                              [matTooltip]="getStatusIconTitle()">{{getStatusIconIcon()}}</mat-icon>
                </div>
                <span class="mat-title">{{mappedItem.name}}</span>

                <!-- <mat-icon *ngIf="mappedItem.isConceptAppliedToLot"
                          class="help-cursor mat-primary"
                          matTooltip="Applied to Lot"
                          svgIcon="bookmark-check">
                </mat-icon> -->

                <!-- <mat-icon *ngIf="mappedItem.hasUnansweredQuestion"
                          class="help-cursor mat-warn"
                          matTooltip="Has unanswered question"
                          svgIcon="comment-question-outline">
                </mat-icon> -->
            </div>
        </mat-card-header>
        <mat-card-content class="flex">
            <div class="flex-row flex">
                <div class="flex-col flex">
                    <cb-display-value-new label="Assigned to"
                                          [value]="mappedItem.assignedToUserName">
                    </cb-display-value-new>
                    <cb-display-value-new label="Reviewer"
                                          [value]="mappedItem.reviewerName">
                    </cb-display-value-new>
                    <cb-display-value-new class="flex"
                                          label="Status"
                                          [value]="mappedItem.statusId | cbEnumToLabel: PRICING_REVISION_STATUS_ENUM">
                    </cb-display-value-new>
                    <cb-user-date-time-new label="Created"
                                           [floatingLabel]="true"
                                           [userFullName]="mappedItem.createdByName"
                                           *ngIf="!mappedItem.assignedToUserId"
                                           [date]="mappedItem.createdDate">
                    </cb-user-date-time-new>
                    <cb-display-value-new class="flex"
                                          label="Waiting for Review by"
                                          *ngIf="mappedItem.isAwaitingReview"
                                          [value]="mappedItem.reviewerName">
                    </cb-display-value-new>
                    <cb-user-date-time-new label="Due"
                                           [floatingLabel]="true"
                                           [date]="mappedItem.dueBy">
                    </cb-user-date-time-new>
                    <cb-user-date-time-new label="Accepted"
                                           [floatingLabel]="true"
                                           *ngIf="mappedItem.isAccepted"
                                           [date]="mappedItem.statusChangedDate">
                    </cb-user-date-time-new>
                    <cb-user-date-time-new label="Declined"
                                           [floatingLabel]="true"
                                           *ngIf="mappedItem.isDeclined"
                                           [date]="mappedItem.statusChangedDate">
                    </cb-user-date-time-new>

                    <!-- <cb-user-date-time-new label="Completed"
                                           class="flex"
                                           *ngIf="mappedItem.isCompleted"
                                           [value]="mappedItem.completedDate">
                    </cb-user-date-time-new> -->
                    <cb-datepicker label="Expiry Date"
                                   name="pricingExpiryDate"
                                   ngDefaultControl
                                   [(ngModel)]="mappedItem.pricingExpiryDate"
                                   pickerType="calendar">
                    </cb-datepicker>
                    <div class="start-start flex-row"
                         *ngIf="mappedItem.isOnHold">
                        <div class="flex-col">
                            <mat-icon class="mat-warn cb-margin">warning</mat-icon>
                        </div>
                        <div class="flex-col flex">
                            <cb-user-date-time-new label="On Hold since"
                                                   [date]="mappedItem.statusChangedDate">
                            </cb-user-date-time-new>
                        </div>
                    </div>
                    <div class="start-start flex-row"
                         *ngIf="mappedItem.isAwaitingReview">
                        <div class="flex-col">
                            <mat-icon class="mat-accent">person</mat-icon>
                        </div>
                        <div class="flex-col flex">
                            <cb-display-value-new class="flex"
                                                  label="Review Required by"
                                                  [value]="mappedItem.reviewerName">
                            </cb-display-value-new>
                        </div>
                    </div>
                    <div class="start-start flex-row"
                         *ngIf="mappedItem.hasBeenReviewed">
                        <div class="flex-col">
                            <mat-icon class="mat-accent">person</mat-icon>
                        </div>
                        <div class="flex-col flex">
                            <cb-display-value-new class="flex"
                                                  label="Review Required by"
                                                  [value]="mappedItem.reviewerName">
                            </cb-display-value-new>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <div position="relative">
            <mat-divider></mat-divider>
        </div>
        <mat-card-actions class="end-center flex-row">
            <span class="flex"></span>
            <cb-button [isStroked]="true"
                       color="accent"
                       *ngIf="canViewPricingRevision"
                       (clicked)="viewPricingRevision()">
                View
            </cb-button>
        </mat-card-actions>
    </mat-card>
</form>
