import {Component, Input} from '@angular/core';
import {IBuildProgrammeActivityMappedItem} from '@app/logic/build-programme-activity';
import {BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM, ILotDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-build-programme-activity-row',
    templateUrl: './lot-build-programme-activity-row.component.html',
    styleUrls: ['./lot-build-programme-activity-row.component.scss']
})
export class LotBuildProgrammeActivityRowComponent {
    @Input() public readonly activity: IBuildProgrammeActivityMappedItem;
    @Input() public readonly lotDto: ILotDto;
    @Input() public readonly highlightedActivityId: number | null;
    @Input() public readonly displayUnitNumber = false;
    @Input() public readonly downloadOnly = false;
    public readonly BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM = BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM;

    constructor() { }


}
