import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILeadDto, ILeadSearchDto, LeadLogicService } from '@app/logic/leads';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { Subject, Subscription } from 'rxjs';
import { viewLeadUrl } from '../../lead-routes';

@Component({
    selector: 'cb-my-leads',
    templateUrl: './my-leads.component.html',
    styleUrls: ['./my-leads.component.scss']
})
export class MyLeadsComponent implements OnDestroy {
    public selectedTab: string;
    public currentLeadId: number;
    public lead: ILeadDto;
    public leadUpdated = new Subject<ILeadSearchDto>();
    public readonly isBuildingConsultant = new ComputedProperty(() => this.currentUser.hasBuildingConsultant());

    public sub$ = new Subscription();

    constructor(
        public readonly currentUser: CurrentUserService,
        private readonly leadLogicService: LeadLogicService,
        private router: Router,
        private readonly navigationService: NavigationService,
        route: ActivatedRoute,
    ) {

        this.sub$.add(
            route.params.subscribe(val => {
                // put the code from `ngOnInit` here
                this.currentLeadId = val.id;
            })
        );

        this.currentUser.$promise.then(() => {
            this.isBuildingConsultant.recompute();
        });

        if (this.currentLeadId) {
            this.leadLogicService.$getItem(this.currentLeadId).subOnce(lead => this.lead = lead);
        }
    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    public leadSelected(leadId: number): void {
        this.currentLeadId = undefined;
        setTimeout(() => {
            this.navigationService.navigate([viewLeadUrl(leadId)]).then(() => {
                this.currentLeadId = leadId;
            });
        });
    }

    public updateLead(mappedItem: ILeadMappedItem): void {
        this.lead = mappedItem.$getMappedDtoItem();
        this.leadUpdated.next(mappedItem.leadSearchDto.$getImmutableData());
    }
}
