<cb-dialog>
    <form class="flex-col flex"
          #specGroupForm="ngForm">
        <cb-input type="text"
                  name="specGroup"
                  label="House Area"
                  [required]="true"
                  [(ngModel)]="mappedItem.label">
        </cb-input>
        <cb-input type="text"
                  name="sortOrder"
                  label="Sort Order"
                  [required]="true"
                  [(ngModel)]="mappedItem.sortOrder">
        </cb-input>
        <cb-checkbox name="isQsOnly"
                     label="QS Only"
                     [(ngModel)]="mappedItem.isQsOnly">
        </cb-checkbox>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!specGroupForm.dirty || !specGroupForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
