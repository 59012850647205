import { Component, Input, OnInit } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { UserDetailsDialogComponent } from '@app/views/users/user-details-dialog/user-details-dialog.component';
import { IUserDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-project-team-table',
    templateUrl: './project-team-table.component.html',
    styleUrls: ['./project-team-table.component.scss']
})
export class ProjectTeamTableComponent implements OnInit {
    @Input() public mappedItem: IProjectMappedItem;
    public loaded: boolean;

    constructor(
        public securityPermissions: PermissionsPermissions,
        public readonly cbDialog: CbDialogService
    ) { }

    public ngOnInit(): void {
        this.loaded = true;
    }

    public viewUser(user: IUserDto) {
        this.cbDialog
            .open(UserDetailsDialogComponent, {
                data: { dto: user },
                minWidth: '500px',
            })
            .afterClosed()
            .subOnce();
    }

}
