import { ComponentType } from '@angular/cdk/portal';
import { Directive } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SellingAgentSearchService } from '@app/core/services/search/sellingagent.search.service';
import { ISellingAgentDto } from '@app/logic/selling-agents/interfaces/i.sellingagent.dto';
import { SellingAgentsLogicService } from '@app/logic/selling-agents/sellingagents.logic.service';
import { takeOne } from 'cb-hub-lib';
import { NavigationService } from '../../../../../../core/services/navigation/navigation.service';
import { BaseInputFindDirective } from '../../base-input-find.directive';
import { SellingAgentsInputFindDialogDirective } from '../../dialog/extensions/selling-agents/sellingagents-input-find-dialog-directive';


@Directive()
export class SellingAgentsInputFindDirective extends BaseInputFindDirective {
    protected _myDialog: ComponentType<SellingAgentsInputFindDialogDirective>;
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly sellingagentsLogicService: SellingAgentsLogicService,
        public readonly navigationService: NavigationService,
        public readonly searchService: SellingAgentSearchService) {
        super();
    }

    public openSearchDialog = (): void => {
        this.dialogRef.open(
            this._myDialog,
            {
                minWidth: '95%',
                data: {
                    extraSearchParams: this.searchParams,
                    placeholder: 'Type in Legal Name or Business Entity Name',
                    dialogHeading: 'Choose Selling Agent',
                    contentHeading: 'Search Business Accounts and Business Entities',
                    searchService: this.searchService
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .pipe(takeOne())
            .subscribe({
                next: (agent: ISellingAgentDto) => {
                    if (agent.legalName) {
                        this.text = agent.legalName;
                        this.value = agent;
                    } else {
                        this.text = agent.legalEntityName;
                        this.value = agent;
                    }
                }
            });
    };
}
