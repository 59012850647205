import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CostTypeEnumId, COST_TYPE_ENUM, ILotSpecColourItemDto, ILotSpecItemDto, ProductTypeEnumId, PRODUCT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { filter, orderBy } from 'lodash';
import { ILotSpecMappedItem, IListItem, ListItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { MatAccordion } from '@angular/material/expansion';



interface IGroup {
    id: number;
    name: string;
}

interface IOrderChangedItem {
    specGroupId: number;
    lotSpecItemOrder: ILotSpecItemOrder[];
}

interface ILotSpecItemOrder {
    id: number;
    orderId: number;
}

interface IFlagChangedItem {
    id: number;
    showInColourYourDreams: boolean;
}


@Component({
    selector: 'cb-view-house-land-specification',
    templateUrl: './view-house-land-specification.component.html',
    styleUrls: ['./view-house-land-specification.component.scss']
})
export class ViewHouseLandSpecificationComponent implements OnInit {
    @Input() public mappedItem: ILotSpecMappedItem;
    @ViewChild('firstAccordion') public firstAccordion: MatAccordion;
    public questionCount: number;
    public documentCount: number;
    public expandedAreas: any = {};
    public shouldCollapse = false;
    public items: IListItem[] = [];
    public costTypes = COST_TYPE_ENUM.toLookup();
    public allExpanded = false;
    public isExpanded = true;
    public initialAreas;

    public groups: IGroup[] = [];
    public groupedItems;

    public finishedLoading = false;
    public isAllAccordionItemsExpanded = true;

    public readonly displayedColumns = [
        'Product',
        'Options',
        'Quantity'
    ];

    constructor(
        public specGroupLogic: SpecGroupsLogicService,
        private readonly cdRef: ChangeDetectorRef,
    ) { }

    public ngOnInit(): void {
        this.loadAreas();
        this.populateItems();
    }

    public expandAllItems(): void {
        this.isAllAccordionItemsExpanded = !this.isAllAccordionItemsExpanded;
        this.mappedItem.$areas = [];
        this.initialAreas.forEach(
            item => {
                const setItem: ListItem = {
                    expanded: true,
                    ...item
                };
                this.mappedItem.$areas.push(setItem);
            }
        );
    }

    public collapseAllItems(): void {
        this.isAllAccordionItemsExpanded = !this.isAllAccordionItemsExpanded;
        this.mappedItem.$areas = [];
        this.initialAreas.forEach(
            item => {
                const setItem: ListItem = {
                    expanded: false,
                    ...item
                };
                this.mappedItem.$areas.push(setItem);
            }
        );
    }

    public expandedChange(isOpening: boolean): void {
        if (!isOpening) {
            this.isAllAccordionItemsExpanded = false;
        }
    }

    public openAllFirst(): void {
        this.firstAccordion.openAll();
    }

    public closeAllFirst(): void {
        this.firstAccordion.closeAll();
    }

    public getQuantity(theItem: ILotSpecItemDto): string | number {
        if (theItem.costType === COST_TYPE_ENUM.Provisional ||
            theItem.costType === COST_TYPE_ENUM.DescriptiveOnly) {
            return '-';
        } else {
            return theItem.quantity;
        }
    }


    public getHouseAreaDisplay(area): string {
        if (area.items) {
            return area.houseArea.label + ' ( ' + area.items.length + ' )';
        } else {
            return area.houseArea.label + ' ( 0 )';
        }
    }

    public populateItems(): void {
        this.items = [];
        this.mappedItem.items.forEach((lotSpecProduct: ILotSpecItemDto) => {
            if (!lotSpecProduct.isDeleted && lotSpecProduct.showInClientSpecification) {
                const listItem = new ListItem();
                listItem.isProduct = true;

                if (lotSpecProduct.textForSpecification && lotSpecProduct.textForSpecification !== '') {
                    listItem.displayText = lotSpecProduct.textForSpecification;
                } else {
                    listItem.displayText = (lotSpecProduct.productDisplay ? lotSpecProduct.productDisplay : lotSpecProduct.productOther);
                }

                listItem.item = lotSpecProduct;
                this.items.push(listItem);
            }
        });

        this.mappedItem.colourItems.forEach((colourItem: ILotSpecColourItemDto) => {
            if (!colourItem.isDeleted) {
                const listItem = new ListItem();
                listItem.isProduct = false;

                if (colourItem.textForSpecification && colourItem.textForSpecification !== '') {
                    listItem.displayText = colourItem.textForSpecification;
                } else {
                    listItem.displayText = colourItem.colourItem.name;
                }

                listItem.item = colourItem;
                this.items.push(listItem);
            }
        });
    }

    public loadAreas = () => this.specGroupLogic.$getList().subOnce((results: any) => {
        this.filterHouseAreas(results);
    });

    public filterHouseAreas = (areas) => {
        const removedNoItemsAreas = areas.filter(this.areaHasItems);
        const onlyVisibleAreas = removedNoItemsAreas.filter(area => this.isHouseAreaVisible(area));
        this.mappedItem.$areas = orderBy(onlyVisibleAreas, 'sortOrder', 'asc');
        this.initialAreas = this.mappedItem.$areas;
        this.collapseAllItems();
    };

    public isHouseAreaVisible = (area: any): boolean => (area && this.getItemsForHouseArea(area.id).length > 0);

    public areaHasItems = (area) => {
        if (this.getItemsForHouseArea(area.id).length > 0) {
            return true;
        } else {
            return false;
        }
    };

    public getAreaTitle(area: any): string {
        let itemCount = 0;
        if (this.getItemsForHouseArea(area.id)) {
            itemCount = this.getItemsForHouseArea(area.id).length;
        }
        const title = `${area.label} (${itemCount})`;

        return title;
    }

    public getItemsForHouseArea(areaId: number, showAll?: boolean): any[] {
        let returnAll = false;
        if (!areaId && showAll === true) {
            returnAll = true;
        }

        const items = filter(this.items, (item) => {
            if (item.isProduct) {
                return (item.item.specGroupId === areaId && !item.item.bundleId)
                    || returnAll && !item.item.bundleId;
            } else {
                return (item.item.specGroupId === areaId);
            }

        });

        return items;
    }

    public isEstimate(costType: CostTypeEnumId): boolean {
        return costType === COST_TYPE_ENUM.Estimate;
    }

    public isProvisional(costType: CostTypeEnumId): boolean {
        return costType === COST_TYPE_ENUM.Provisional;
    }

    public isBundle(offeringType: ProductTypeEnumId): boolean {
        return offeringType === PRODUCT_TYPE_ENUM.Bundle;
    }

}
