export function removeCurrencyFormatting(value: string): string {
    return value?.replace(/,/g, '');
}


export function toCurrency(num: number): string {

    /* #toCurrency - Returns number as a formatted currency string.
      Arguments:
      1. num     (Number) : Number to be formatted. (Required)
    */
    return '$' + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
