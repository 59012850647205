import {Injectable} from '@angular/core';
import {ILotDto, LOT_JOB_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {FieldValidationItem} from '../field.validation.item';
import {ILotFieldValidatorService} from './interfaces/i.lot-field-validator.service';
import {LOT_COMPONENT_TYPE} from './interfaces/i.lot.field.rules.service';
import {LotFieldRulesService} from './lot.field.rules.service';

@Injectable()
export class LotFieldValidatorService implements ILotFieldValidatorService {

    public dto: ILotDto;

    constructor(protected lotFieldRulesService: LotFieldRulesService) {

    }

    public appliedDesignConcept(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.appliedDesignConceptVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }

    // START actualStartDate
    public actualStartDate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.actualStartDateVisible(
            this.dto.lotType, this.dto.contractType, componentType, this.dto.jobStatus === LOT_JOB_STATUS_ENUM.Build);

        return item;
    }
    // END actualStartDate

    // START actualCompletionDate
    public actualCompletionDate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.actualCompletionDateVisible(
            this.dto.lotType, this.dto.contractType, componentType, this.dto.jobStatus === LOT_JOB_STATUS_ENUM.Build);

        return item;
    }
    // END actualCompletionDate


    // START AmenitiesLandArea
    public amenitiesFloorArea(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .amenitiesFloorAreaVisible(this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END AmenitiesLandArea

    // START AmenitiesLandArea
    public amenitiesLandArea(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .amenitiesLandAreaVisible(this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END AmenitiesLandArea

    // START amenitiesNoBathrooms
    public amenitiesNoBathrooms(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .amenitiesNoBathroomsVisible(
                this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END amenitiesNoBathrooms

    // START amenitiesNoBedrooms
    public amenitiesNoBedrooms(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .amenitiesNoBedroomsVisible(
                this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END amenitiesNoBedrooms

    // START amenitiesNoGarages
    public amenitiesNoGarages(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .amenitiesNoGaragesVisible(
                this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END amenitiesNoGarages

    // START amenitiesNoLivingRooms
    public amenitiesNoLivingRooms(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .amenitiesNoLivingRoomsVisible(
                this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END amenitiesNoLivingRooms

    // START building consultant
    public buildingConsultant(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .contactsBuildingConsultantVisible(
                this.dto.lotType,

                this.dto.contractType,
                componentType);

        return item;
    }
    // END building consultant

    // START building coordinator
    public buildingCoordinator(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .contactsBuildingCoordinatorVisible(
                this.dto.lotType,

                this.dto.contractType,
                componentType);

        return item;
    }
    // END building coordinator

    // START buildProgrammeTemplate
    public buildProgrammeTemplate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .buildProgrammeTemplateVisible(this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END buildType

    // START buildType
    public buildType(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService
            .buildTypeVisible(this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END buildType

    // START businessEntity
    public businessEntity(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.businessEntityVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END businessEntity

    public classicCareContact(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.classicCareContactVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }

    public classicCareContactMethod(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.classicCareContactMethodVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }

    public classicCareContactDetail(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.classicCareContactDetailVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }

    // START clientAccount
    public clientAccount(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.clientAccountVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END clientAccount

    // START clientAccountSolicitor
    public clientAccountSolicitor(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.clientAccountSolicitorVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }

    // END clientAccountSolicitor
    public clientAccountLegalName(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.clientAccountLegalNameVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }

    // START clientAccountSolicitorEmail
    public clientAccountSolicitorEmail(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.clientAccountSolicitorEmailVisible(
            this.dto.lotType,
            this.dto.contractType,
            componentType);

        return item;
    }
    // END clientAccountSolicitorEmail

    // START clientOwnsLand
    public clientOwnsLand(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.clientOwnsLandVisible(
            this.dto.lotType,
            this.dto.contractType,
            componentType);

        return item;
    }
    // END clientOwnsLand

    // START consentDue
    public consentDue(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.consentDueVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END consentDue


    // START consentNumber
    public consentNumber(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.consentNumberVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END consentNumber

    // START contractGenerationFee
    public contractGenerationFee(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contractGenerationFeeVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END

    // START contractStatus
    public contractStatus(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contractStatusVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contractStatus

    // START contactsBuildingConsultant
    public contactsBuildingConsultant(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsBuildingConsultantVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }

    // END contactsBuildingCoordinator

    // START contactsBuildingCoordinator
    public contactsBuildingCoordinator(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsBuildingCoordinatorVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contactsBuildingCoordinator

    // START contactsDesigner
    public contactsDesigner(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsDesignerVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contactsDesigner

    // START contactsFoundationManager
    public contactsFoundationManager(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsFoundationManagerVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contactsFoundationManager

    // START contactsFramingManager
    public contactsFramingManager(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsFramingManagerVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contactsFramingManager

    // START contactsHouseAndLandCoordinator
    public contactsHouseAndLandCoordinator(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsHouseAndLandCoordinatorVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contactsHouseAndLandCoordinator

    // START contactsQualityAssuranceManager
    public contactsQualityAssuranceManager(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsQualityAssuranceManagerVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contactsQualityAssuranceManager

    // START contactsQuantitySurveyor
    public contactsQuantitySurveyor(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsQuantitySurveyorVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contactsQuantitySurveyor

    // START contactsSalesPerson
    public contactsSalesPerson(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsSalesPersonVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contactsSalesPerson

    // START contractType
    public contractType(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contractTypeVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END contractType

    // START developerProjectName
    public developerProjectName(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.developerProjectNameVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END developerProjectName

    // START dpNumber
    public dpNumber(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.dpNumberVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END dpNumber

    // START estimatedCompletionDate
    public estimatedCompletionDate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.estimatedCompletionDateVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END estimatedCompletionDate

    // START estimatedStartDate
    public estimatedStartDate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.estimatedStartDateVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END estimatedStartDate

    // START expectedTitleDate
    public expectedTitleDate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.expectedTitleDateVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END expectedTitleDate

    // START financialsBuildPrice
    public financialsBuildPrice(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.financialsBuildPriceVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END financialsBuildPrice

    // START financialsContractPrice
    public financialsContractPrice(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.financialsContractPriceVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END financialsContractPrice

    // START financialsLandPrice
    public financialsLandPrice(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.financialsLandPriceVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END financialsLandPrice

    // START financialsSellPrice
    public financialsSellPrice(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.financialsSellPriceVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END financialsSellPrice

    // START hasFlyer
    public hasFlyer(): boolean {
        return this.lotFieldRulesService.hasFlyer(this.dto.houseAndLandBrochure);
    }
    // END hasFlyer

    // START hasTitle
    public hasTitle(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.hasTitleVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END hasTitle

    // START insuranceCoverNumber
    public insuranceCoverNumber(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.insuranceCoverNumberVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END insuranceCoverNumber

    // START insuranceInterestedParty
    public insuranceInterestedParty(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.insuranceInterestedPartyVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END insuranceInterestedParty

    // START insuranceInterestedPartyEmail
    public insuranceInterestedPartyEmail(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.insuranceInterestedPartyEmailVisible(

            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END insuranceInterestedPartyEmail

    // START insurancePrice
    public insurancePrice(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.insurancePriceVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END insurancePrice

    // START insuranceStartDate
    public insuranceStartDate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.insuranceStartDateVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END insuranceStartDate

    // START insuranceEndDate
    public insuranceEndDate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.insuranceEndDateVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END insuranceEndDate

    // START isShowHome
    public isShowHome(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.isShowHomeVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END isShowHome

    // START lotAddress
    public lotAddress(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.lotAddressVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END lotAddress

    // START lotNumber
    public lotNumber(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.lotNumberVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END lotNumber

    // START lotType
    public lotType(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.lotTypeVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END lotType

    // START lotContractType(componentType: LOT_COMPONENT_TYPE): FieldValidationItem;
    public lotContractType(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.lotContractTypeVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END

    // START jobCompleteActual
    public jobCompleteActual(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.jobCompleteActualVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END jobCompleteActual

    // START jobNumber
    public jobNumber(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.jobNumberVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        item.isEditable = this.lotFieldRulesService.jobNumberEditable(
            this.dto.lotType, this.dto.contractType, componentType, this.dto.lotSpecLocked);

        return item;
    }
    // END jobNumber

    // START jobStartEstimate
    public jobStartEstimate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.jobStartEstimateVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END jobStartEstimate

    // START jobStartActual
    public jobStartActual(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.jobStartActualVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END jobStartActual

    // START jobCompleteEstimate
    public jobCompleteEstimate(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.jobCompleteEstimateVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END jobCompleteEstimate

    // START jobStatus
    public jobStatus(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.jobStatusVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END jobStatus

    // START landTitle
    public landTitle(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.landTitleVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END landTitle

    // START marketStatus
    public marketStatus(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.marketStatusVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END marketStatus

    // START parentLot
    public parentLot(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.parentLotVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END parentLot

    // START permitLodged
    public permitLodged(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.permitLodgedVisible(
            this.dto.lotType, this.dto.contractType, componentType);

        return item;
    }
    // END permitLodged

    // START projectReleaseStageName
    public projectReleaseStageName(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.projectReleaseStageNameVisible(
            this.dto.lotType,
            this.dto.contractType,
            componentType);

        return item;
    }
    // END projectReleaseStageName

    // START roadDirection
    public roadDirection(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.roadDirectionVisible(
            this.dto.lotType,
            this.dto.contractType,
            componentType);

        return item;
    }
    // END roadDirection


    // START shouldShowComponent
    public shouldShowComponent(componentType: LOT_COMPONENT_TYPE): boolean {
        if (this.dto !== undefined) {
            return this.lotFieldRulesService.shouldShowComponent(
                this.dto.lotType, this.dto.contractType, componentType);
        }

        return false;
    }
    // END shouldShowComponent

    // START vendor
    public vendorName(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.vendorNameVisible(
            this.dto.lotType,
            this.dto.contractType,
            componentType);

        return item;
    }
    // END vendor

    // START vendor third party
    public vendorNameThirdParty(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.vendorNameThirdPartyVisible(
            this.dto.lotType,
            this.dto.contractType,
            componentType);

        return item;
    }
    // END vendor

    // START vendor third party
    public contactsAssignedToAccount(componentType: LOT_COMPONENT_TYPE): FieldValidationItem {
        const item = new FieldValidationItem();

        item.isVisible = this.lotFieldRulesService.contactsAssignedToAccountVisible(
            this.dto.lotType,
            this.dto.contractType,
            componentType);

        return item;
    }
    // END vendor
}
