import {BasePermissions, IBaseSystemAreaPermissions, IDocumentPermissions, IhaveNotePermissions, INotePermissions} from './base.permissions';
import {LotDesignPermissionEnum} from '@app/shared/enums/permissions';
import {CurrentUserService} from '../authentication/current.user';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

export interface IBaseDesignSchemePermissions {
    canCreate(): boolean;
    canEdit(): boolean;
    canView(): boolean;
}

export interface IDesignSchemePermissions extends IBaseDesignSchemePermissions, IBaseSystemAreaPermissions, IhaveNotePermissions {
    getDocumentPermissions(): IDocumentPermissions;
}


@Injectable()
export class DesignSchemePermissions extends BasePermissions<LotDesignPermissionEnum> implements IDesignSchemePermissions {
    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lotdesign', LotDesignPermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return true;
    }

    public canCreate(): boolean {
        return true;
    }

    public canEdit(): boolean {
        return true;
    }

    public canRelease(): boolean {
        return true;
    }

    public canImport(): boolean {
        return true;
    }

    public canApplyLotSpec(): boolean {
        return true;
    }

    public canCreateLotSpec(): boolean {
        return true;
    }

    public getNotePermissions(): INotePermissions {
        return {
            canCreateNote: () => {
                return true;
            },
            canDeleteNote: () => {
                return true;
            },
            canEditNote: () => {
                return true;
            },
            canViewNotes: () => {
                return true;
            },
        };
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => {
                return true;
            },
            canEditDocuments: () => {
                return true;
            },
            canReviewDocuments: () => {
                return true;
            },
            canUploadDocument: () => {
                return true;
            },
            canViewDocuments: () => {
                return true;
            }
        };
    }

}
