import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISellingAgentDto } from '@app/logic/selling-agents/interfaces/i.sellingagent.dto';

@Component({
    selector: 'cb-sellingagent-input-find-card',
    templateUrl: './sellingagent-input-find-card.component.html',
    styleUrls: ['./sellingagent-input-find-card.component.scss']
})
export class SellingAgentInputFindCardComponent {
    @Input() public dto: ISellingAgentDto;
    @Output() public readonly selectionChosenEmitter = new EventEmitter();

    constructor() { }

    public selectionChosen(val: ISellingAgentDto): void {
        this.selectionChosenEmitter.emit(val);
    }

    public getDisplayName(): string {
        if (this.dto.legalName) {
            return this.dto.legalName;
        } else {
            return this.dto.legalEntityName;
        }
    }

}
