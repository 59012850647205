import { Subscription } from 'rxjs';
import { Directive, Input, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ignoreDirty][ngModel], input[ignoreDirty][ngModel]'
})

export class IgnoreDirtyDirective implements OnDestroy {

    @Input() public readonly ignoreDirty: boolean | undefined;

    private readonly subs$ = new Subscription();

    constructor(private readonly control: NgControl) {
        this.subs$.add(
            this.control.valueChanges.subscribe(v => {
                if (this.ignoreDirty !== false && this.control.dirty) {
                    this.control.control.markAsPristine();
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this.subs$.unsubscribe();
    }
}
