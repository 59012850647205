import { Provider } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

/** Use as a ViewProvider */
export function provideParentForm(): Provider {
    return {
        provide: ControlContainer,
        useExisting: NgForm,
        deps: [NgForm]
    };
}
