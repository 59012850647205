import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LoadingSpinnerComponent} from './loading-spinner.component';

const COMPONENTS = [
    LoadingSpinnerComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
    ],
    exports: COMPONENTS
})
export class CbLoadingSpinnerModule {
}
