import { CbDasherModule } from 'cb-hub-lib';
import { CbDisplayValueModule } from './../display-value/display-value.module';
import { AmenitiesComponent } from './amenities.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    declarations: [
        AmenitiesComponent
    ],
    imports: [
        CommonModule,
        CbDisplayValueModule,
        MatIconModule,
        CbDasherModule
    ],
    exports: [
        AmenitiesComponent
    ]
})
export class CbAmenitiesModule { }
