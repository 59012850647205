<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">
                Business Accounts
            </h3>
        </div>
        <div class="flex-col">
            <div class="flex-row flex">
                <div class="flex-col flex"
                     id="account-types">
                    <cb-standard-table [value]="requiredDocsForBusinessAccountTypes"
                                       [columns]="accountTypeColumns"
                                       label="For Account Types"
                                       valueLabel="Account Types">
                    </cb-standard-table>
                </div>
                <div class="cb-table flex">
                    <h3 class="mat-title"
                        id="label"
                        style="margin-bottom: 0">
                        For Trade Types
                    </h3>
                    <table mat-table
                           [dataSource]="parentTradeTypes"
                           multiTemplateDataRows>
                        <ng-container matColumnDef="label">
                            <th mat-header-cell
                                *matHeaderCellDef>
                                Trade
                            </th>
                            <td mat-cell
                                *matCellDef="let element"
                                class="typeLabelClass">
                                {{element.label}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="documents">
                            <th mat-header-cell
                                *matHeaderCellDef>
                                Documents
                            </th>
                            <td mat-cell
                                *matCellDef="let element"></td>
                        </ng-container>

                        <!-- Expanded Content Column -->
                        <ng-container matColumnDef="expandedDetailLabel">
                            <th mat-header-cell
                                *matHeaderCellDef>
                                Trade
                            </th>
                            <td mat-cell
                                *matCellDef="let element"
                                [attr.colspan]="2"
                                style="padding: 0">
                                <div class="example-element-detail"
                                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                    <cb-standard-table [value]="sortedDocsForTradeTypes[element.id]"
                                                       [columns]="innerTradeTypeColumns"
                                                       [hideHeader]="true"
                                                       valueLabel="Trade Types Stuffs"
                                                       style="width: 100%">
                                    </cb-standard-table>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row
                            *matHeaderRowDef="['label', 'documents']"
                            class="typeDocumentRow"></tr>
                        <tr mat-row
                            *matRowDef="let element; columns: ['label', 'documents']"
                            class="example-element-row"
                            [class.example-expanded-row]="expandedElement === element"
                            (click)="expandedElement = expandedElement === element ? null : element">
                        </tr>
                        <tr mat-row
                            *matRowDef="let row; columns: ['expandedDetailLabel']"
                            class="example-detail-row"></tr>
                    </table>
                </div>

                <div *ngIf="isSupplierTypesEnabled" class="cb-table flex">
                    <h3 class="mat-title"
                        id="labelSupplyType"
                        style="margin-bottom: 0">
                        For Supply Types
                    </h3>
                    <table mat-table
                           [dataSource]="parentSupplyTypes"
                           multiTemplateDataRows>
                        <ng-container matColumnDef="label">
                            <th mat-header-cell
                                *matHeaderCellDef>
                                Supply
                            </th>
                            <td mat-cell
                                *matCellDef="let element"
                                class="typeLabelClass">
                                {{element.label}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="documents">
                            <th mat-header-cell
                                *matHeaderCellDef>
                                Documents
                            </th>
                            <td mat-cell
                                *matCellDef="let element"></td>
                        </ng-container>

                        <!-- Expanded Content Column -->
                        <ng-container matColumnDef="expandedDetailLabel">
                            <th mat-header-cell
                                *matHeaderCellDef>
                                Supply
                            </th>
                            <td mat-cell
                                *matCellDef="let element"
                                [attr.colspan]="2"
                                style="padding: 0">
                                <div class="example-element-detail"
                                     [@detailExpand]="element == expandedSupplyTypeElement ? 'expanded' : 'collapsed'">
                                    <cb-standard-table [value]="sortedDocsForSupplyTypes[element.id]"
                                                       [columns]="innerSupplyTypeColumns"
                                                       [hideHeader]="true"
                                                       valueLabel="Supply Types"
                                                       style="width: 100%">
                                    </cb-standard-table>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row
                            *matHeaderRowDef="['label', 'documents']"
                            class="typeDocumentRow"></tr>
                        <tr mat-row
                            *matRowDef="let element; columns: ['label', 'documents']"
                            class="example-element-row"
                            [class.example-expanded-row]="expandedSupplyTypeElement === element"
                            (click)="expandedSupplyTypeElement = expandedSupplyTypeElement === element ? null : element">
                        </tr>
                        <tr mat-row
                            *matRowDef="let row; columns: ['expandedDetailLabel']"
                            class="example-detail-row"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
