import {extendPrototype} from './extension.util';
import * as moment from 'moment';


// Extension name definitions
const BUSINESS_ADD = 'businessAdd';


/** moment with ClassicHub Module Augmentations */
declare module 'moment' {
    export interface Moment {
        [BUSINESS_ADD](days: number): moment.Moment;
    }
}

const indexOfLastDayOfWeek = 6;

export function businessAdd(days: number): moment.Moment {
    let i = 0;
    while (i < days) {
        this.add(1, 'days');
        if (this.day() > 0 && this.day() < indexOfLastDayOfWeek) {
            i++;
        }
    }
    return this;
}

extendPrototype(moment.prototype, BUSINESS_ADD, businessAdd);
