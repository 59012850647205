import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Declare a variable in the template.
 * Eg. <i *cbVar="false as variable">{{ variable | json }}</i>
 */


@Directive({
    selector: '[cbVar]'
})
export class VarDirective {
    public context: any = {};

    constructor(
        private vcRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
    ) { }

    @Input()
    public set cbVar(context: any) {
        this.context.$implicit = this.context.cbVar = context;
        this.updateView();
    }

    private updateView(): void {
        this.vcRef.clear();
        this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }
}
