import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GstService } from '@app/core/services/gst/gst.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BuildOverheadCostLogicService } from '@app/logic/build-overhead-costs';
import { ILotBuildOverheadCostMappedItem, LotBuildOverheadCostLogicService } from '@app/logic/lot-build-overhead-costs';
import { ILotBuildOverheadCostLogicService } from '@app/logic/lot-build-overhead-costs/interfaces/i.lot-build-overhead-cost-logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import { IBuildOverheadCostDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ILotBuildOverheadCostsDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/LotBuildOverheadCostsDto';
import { filter } from 'lodash';

export interface IData {
    mappedItem: ILotBuildOverheadCostMappedItem;
    otherData: { lotId: number; reloadFn: () => void };
}
@Component({
    selector: 'cb-lot-build-overhead-costs-dialog',
    templateUrl: './lot-build-overhead-costs-dialog.component.html',
    styleUrls: ['./lot-build-overhead-costs-dialog.component.scss']
})
export class LotBuildOverheadCostsDialogComponent extends
    BaseDialogFormViewDirective<ILotBuildOverheadCostsDto, ILotBuildOverheadCostMappedItem, ILotBuildOverheadCostLogicService> implements OnInit {
    private buildOverheadCosts: IBuildOverheadCostDto[];
    protected readonly FormMode = FormMode;

    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<LotBuildOverheadCostsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly buildOverheadCostsLogicService: BuildOverheadCostLogicService,
        public readonly lotBuildOverheadCostsLogicService: LotBuildOverheadCostLogicService,
        public readonly gstService: GstService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.mappedItem.lotId = data.otherData.lotId;
    }

    public ngOnInit(): void {
        this.buildOverheadCostsLogicService.getActive().subOnce(activeCosts => {
            this.buildOverheadCosts = activeCosts;
        });

        super.ngOnInit();
    }

    public querySearch = (searchText: string): IBuildOverheadCostDto[] => {
        return filter(this.buildOverheadCosts, (buildOverheadCost: IBuildOverheadCostDto) => {
            return buildOverheadCost.name.toLowerCase().includes(searchText.toLowerCase());
        });
    };

    public saveAndNext(form: NgForm): void {
        const lotId = this.mappedItem.lotId;
        this.saveInProgress = true;
        this.mappedItem.$save().subOnce(x => {
            form.resetForm();
            this.mappedItem = this.lotBuildOverheadCostsLogicService.$createMappedItem();
            this.mappedItem.lotId = lotId;
            this.saveInProgress = false;
            this.data.otherData.reloadFn();
        });
    }

    public updateGstInclusive(value: any): void {
        this.mappedItem.value = value;
        this.mappedItem.valueGstIncl = this.gstService.toGstInclusive(value);
    }

    public updateGstExclusive(value: any): void {
        this.mappedItem.value = this.gstService.toGstExclusive(value);
        this.mappedItem.valueGstIncl = value;
    }
}
