import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Inject} from '@angular/core';
import {catchError, map, Observable, Subscriber, take, throwError} from 'rxjs';
import {AuthService} from '../authentication/auth.service';
import {CurrentHttpRequestsService} from '../services/current-http-requests/current-http-requests.service';

export class AddHeaderInterceptor implements HttpInterceptor {
    constructor(
        @Inject(AuthService) private readonly authService: AuthService,
        @Inject(CurrentHttpRequestsService) private readonly currentHttpRequestsService: CurrentHttpRequestsService
    ) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // .Net Core does not support parameters in the querystring if they are
        // set as 'undefined' and will error on the back-end and return a '400 (Bad Request)'.
        // This means the front-end needs to strip out any 'undefined' values before sending
        // it on to the API
        req = this.stripUndefinedParams(req);

        this.currentHttpRequestsService.addRequest(req.urlWithParams);

        if (req.url.indexOf('api') === -1) {
            return next.handle(req).pipe(
                map(this.handleGenericResponse),
                catchError(this.handleErrorResponse)
            );
        }

        if (req.url.indexOf('fetchclaimtoken') !== -1) {
            return this.populateAADToken(req, next).pipe(
                map(this.handleGenericResponse),
                catchError(this.handleErrorResponse)
            );
        }

        return this.populateAPIToken(req, next).pipe(
            map(this.handleGenericResponse),
            catchError(this.handleErrorResponse)
        );
    }

    public populateAADToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return new Observable((observer) => {
            this.authService.getAADToken((err, token) => this.handleTokenResponse(err, token, req, next, observer));
        });
    }

    public populateAPIToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return new Observable((observer) => {
            this.authService.getAPIToken((err, token) => this.handleTokenResponse(err, token, req, next, observer));
        });
    }

    private handleTokenResponse(err: string, token: string, req: HttpRequest<any>, next: HttpHandler, observer: Subscriber<HttpEvent<any>>): void {
        let request: HttpRequest<any>;
        if (err) {
            request = req;
        } else {
            request = this.addTokenToRequest(req, token);
        }
        next.handle(request)
            // take 1 does not work in this instance (the request gets cancelled)
            // take 2 instead - which allows the request to continue for some reason...
            .pipe(take(2))
            .subscribe(
                (event) => {
                    observer.next(event);
                },
                (error) => {
                    observer.error(error);
                },
                () => {
                    observer.complete();
                }
            );
    }

    private readonly handleGenericResponse = (response: HttpResponse<any>): HttpResponse<any> => {
        if (response instanceof HttpResponse) {
            this.currentHttpRequestsService.removeRequest(response.url);
        }
        return response;
    };

    private readonly handleErrorResponse = (response: HttpErrorResponse): Observable<never> => {
        if (response instanceof HttpErrorResponse) {
            this.currentHttpRequestsService.removeRequest(response.url);
        }
        // re-throw error so that it can be caught again by a subscriber or pipe
        return throwError(response);
    };

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
    }

    private stripUndefinedParams(request: HttpRequest<any>): HttpRequest<any> {
        // If we have no parameters, then there is nothing to strip out so just
        // return the original request
        if (!request || !request.params || request.params.keys.length === 0) {
            return request;
        }

        let params = request.params;
        for (const key of request.params.keys()) {
            if (params.get(key) === undefined) {
                params = params.delete(key, undefined);
            }
        }
        return request.clone({ params });
    }
}
