<cb-dialog dialogHeading="Request Primary Building Consent">
    <span class="mat-body-1 cb-margin-bottom">
        Please confirm you want to request Primary Building Consent for this Lot. Upload Signed Working Drawings if you
        have them available.
    </span>
    <cb-document-upload class="cb-margin-bottom"
                        *cbWaitFor="document"
                        [(documentEntityMappedItem)]="document"
                        [hideName]="true"
                        [hideDescription]="true"></cb-document-upload>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                (cbClick)="submit()"
                [clickLimit]="1">Submit
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
