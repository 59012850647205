<cb-dialog>
    <form class="flex-col flex"
          #nonWorkingDayForm="ngForm">
        <cb-datepicker name="date"
                       label="Date"
                       [required]="true"
                       [pickerType]="'calendar'"
                       [(ngModel)]="mappedItem.date"
                       [nonWorkingDays]="nonWorkingDays"></cb-datepicker>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!nonWorkingDayForm.dirty || !nonWorkingDayForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
