<ng-container *ngIf="!mappedItem?.isFromManualPurchaseOrder">
    <button mat-icon-button
            [disabled]="mappedItem?.isControlledByParentLot || mappedItem?.allRulesFalse"
            [matMenuTriggerFor]="nonManualMenu">
        <mat-icon svgIcon="dots-horizontal"></mat-icon>
    </button>
    <mat-menu #nonManualMenu>
        <button mat-menu-item
                *ngIf="permissions.canViewPoHistory"
                (click)="viewHistory(mappedItem)">
            <mat-icon>history</mat-icon>
            View History
        </button>

        <!-- Edit SSR Activity -->
        <button mat-menu-item
                *ngIf="mappedItem.ssrId && !downloadOnly && permissions.canEditActivity"
                [disabled]="!mappedItem?.rules?.canEditActivity"
                (click)="editActivity()">
            <mat-icon svgIcon="pencil"></mat-icon>
            Edit Activity
        </button>

        <!-- Edit Non SSR Activity -->
        <button mat-menu-item
                *ngIf="!mappedItem.ssrId && !downloadOnly && permissions.canEditActivity"
                [disabled]="!mappedItem?.rules?.canEditActivity"
                (click)="editBuildProgrammeActivity()">
            <mat-icon svgIcon="pencil"></mat-icon>
            Edit Activity
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly && permissions.canGenerateSSR"
                [disabled]="!mappedItem?.rules?.canGenerateSsr"
                (click)="createSsr()">
            <mat-icon svgIcon="content-copy"></mat-icon>
            Generate SSR
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly && permissions.canSetSupplier"
                [disabled]="!mappedItem?.rules?.canSetSupplier"
                (click)="setSupplier()">
            <mat-icon svgIcon="pencil"></mat-icon>
            Set Supplier
        </button>
        <button mat-menu-item
                *ngIf="permissions.canDownloadPO"
                [disabled]="!mappedItem?.rules?.canDownloadPO"
                (click)="mappedItem?.downloadPo()">
            <mat-icon svgIcon="file-download"></mat-icon>
            Download PO
        </button>
        <button mat-menu-item
                *ngIf="permissions.canDownloadSSR"
                [disabled]="!mappedItem?.rules?.canDownloadSsr"
                (click)="mappedItem?.downloadSsr()">
            <mat-icon svgIcon="file-download"></mat-icon>
            Download SSR
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly && permissions.canSplitSSR"
                [disabled]="!mappedItem?.rules?.canSplitActivity"
                (click)="splitActivity()">
            <mat-icon svgIcon="call-split"></mat-icon>
            Split Activity
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly && permissions.canAbandonSSR"
                [disabled]="!mappedItem?.rules?.canAbandon"
                (click)="setToAbandoned()">
            <mat-icon svgIcon="cancel"></mat-icon>
            Abandon
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly"
                [disabled]="!mappedItem?.rules?.canRedraftSsr
                && !mappedItem?.rules?.canConfirmSsr
                && !mappedItem?.rules?.canCompleteSsr
                && !mappedItem?.rules?.canCancelSsr"
                [matMenuTriggerFor]="ssrStateMenu">
            <mat-icon svgIcon="chart-timeline-variant"></mat-icon>
            Status
        </button>
        <mat-menu #ssrStateMenu>
            <button mat-menu-item
                    *ngIf="permissions.canRedraftSSR"
                    [disabled]="!mappedItem?.rules?.canRedraftSsr"
                    (click)="redraftSsr()">
                <mat-icon svgIcon="undo"></mat-icon>
                Redraft
            </button>
            <button mat-menu-item
                    *ngIf="permissions.canConfirmSSR"
                    [disabled]="!mappedItem?.rules?.canConfirmSsr"
                    (click)="confirmSsr()">
                <mat-icon svgIcon="circle"></mat-icon>
                Confirm
            </button>
            <button mat-menu-item
                    *ngIf="permissions.canCompleteSSR"
                    [disabled]="!mappedItem?.rules?.canCompleteSsr"
                    (click)="completeSsr()">
                <mat-icon svgIcon="circle"></mat-icon>
                Complete
            </button>
            <button mat-menu-item
                    *ngIf="permissions.canCancelSSR"
                    [disabled]="!mappedItem?.rules?.canCancelSsr"
                    (click)="cancelSsr()">
                <mat-icon svgIcon="cancel"></mat-icon>
                Cancel
            </button>
        </mat-menu>
        <button mat-menu-item
                *ngIf="!downloadOnly"
                [disabled]="!mappedItem?.rules?.canConvertToManualOrder"
                (click)="convertToManualOrder()">
            <mat-icon svgIcon="swap-horizontal-variant"></mat-icon>
            Convert to Manual Order
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly && toggleControlData.value.showButton"
                [disabled]="!toggleControlData.value.enableButton"
                (click)="toggleControl()">
            <mat-icon [svgIcon]="toggleControlData.value.icon"></mat-icon>
            {{toggleControlData.value.buttonLabel}}
        </button>
    </mat-menu>
</ng-container>
<ng-container *ngIf="mappedItem?.isFromManualPurchaseOrder">
    <button mat-icon-button
            [disabled]="mappedItem?.isControlledByParentLot || mappedItem?.allRulesFalse"
            [matMenuTriggerFor]="manualMenu">
        <mat-icon svgIcon="dots-horizontal"></mat-icon>
    </button>
    <mat-menu #manualMenu>
        <button mat-menu-item
                *ngIf="permissions.canViewPoHistory"
                (click)="viewHistory(mappedItem)">
            <mat-icon>history</mat-icon>
            View History
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly && permissions.canEditActivity"
                [disabled]="!mappedItem?.rules?.canEditActivity"
                (click)="editBuildProgrammeActivity()">
            <mat-icon svgIcon="pencil"></mat-icon>
            Edit Activity
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly && permissions.canSetSupplier"
                [disabled]="!mappedItem?.rules?.canSetSupplier || (this.mappedItem.manualOrderStatusId !== 2 && this.mappedItem.manualOrderStatusId !== 5) || !this.mappedItem.isTransformedToManualOrder"
                (click)="setSupplier()">
            <mat-icon svgIcon="pencil"></mat-icon>
            Set Supplier
        </button>
        <button mat-menu-item
                *ngIf="permissions.canViewManualOrder"
                (click)="viewManualOrder()">
            <mat-icon svgIcon="card-search"></mat-icon>
            View Manual Order
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly && permissions.canEditActivity"
                [disabled]="!mappedItem?.rules?.canRedraftSsr"
                (click)="redraftManualOrder()">
            <mat-icon svgIcon="undo"></mat-icon>
            Redraft
        </button>
        <button mat-menu-item
                *ngIf="!downloadOnly && permissions.canSplitSSR"
                [disabled]="!mappedItem?.rules?.canSplitActivity"
                (click)="splitActivity()">
            <mat-icon svgIcon="call-split"></mat-icon>
            Split Activity
        </button>

        <ng-container *ngIf="!mappedItem.isTransformedToManualOrder">
            <button mat-menu-item
                    *ngIf="!downloadOnly && permissions.canAbandonManualOrder"
                    [disabled]="!mappedItem?.rules?.canAbandon"
                    (click)="setToAbandoned()">
                <mat-icon svgIcon="cancel"></mat-icon>
                Abandon
            </button>
        </ng-container>

        <ng-container *ngIf="mappedItem.isTransformedToManualOrder">
            <button mat-menu-item
                    *ngIf="!downloadOnly && permissions.canCancelSSR"
                    [disabled]="!mappedItem?.rules?.canCancelSsr"
                    (click)="setToAbandoned(true)">
                <mat-icon svgIcon="cancel"></mat-icon>
                Cancel
            </button>
        </ng-container>

        <button mat-menu-item
                *ngIf="!downloadOnly"
                [disabled]="!mappedItem?.rules?.canCompleteSsr"
                (click)="completeManualOrder()">
            <mat-icon svgIcon="circle"></mat-icon>
            Complete
        </button>
    </mat-menu>
</ng-container>
