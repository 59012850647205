<mat-card class="flex-col flex"
          *cbWaitFor="mappedItem?.id > 0">
    <span class="cb-title">{{getHeading()}}</span>
    <cb-display-value-new label="Status"
                          [value]="mappedItem?.preConsentPlanStatus?.label"></cb-display-value-new>
    <cb-display-value-new label="Complexity"
                          [value]="mappedItem?.designComplexity?.complexity"></cb-display-value-new>
    <cb-display-value-new label="Assigned To"
                          [value]="mappedItem?.assignedToUser?.label"></cb-display-value-new>
    <cb-display-value-new label="Reviewer"
                          [value]="mappedItem?.reviewerName"></cb-display-value-new>
    <cb-display-value-new label="Due Date"> {{mappedItem?.dueDate | date: "dd MMM yyyy"}}</cb-display-value-new>
    <cb-display-value-new label="Elapsed Time"
                          [value]="elapsedTime"></cb-display-value-new>
</mat-card>
