import { Component, Input, OnDestroy, AfterContentInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ToastService } from '@app/core/services/toast/toast.service';
import { remove } from 'lodash';
import { Toast } from '../toast.class';

@Component({
    selector: 'cb-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('0.5s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ]),
    ],
})
export class ToastComponent implements AfterContentInit, OnDestroy {
    @Input() public toast: Toast;
    public isOpen = false;
    public firstRun = true;

    constructor(public toastService: ToastService) {}

    public ngAfterContentInit(): void {
        this.isOpen = true;
        if (this.toast.hideDelay !== Infinity) {
            setTimeout(
                () => {
                    this.toggle();
                    this.firstRun = false;
                },
                this.toast.hideDelay
            );
        }
    }

    public ngOnDestroy(): void {
        this.toggle();
    }

    public buttonClicked(): void {
        this.toast.buttonAction();
    }

    public toggle(): void {
        this.isOpen = !this.isOpen;
    }

    public deleteToast(): void {
        remove(this.toastService.toasts, t => t.id === this.toast.id);
    }

    public onAnimationFinished($event: AnimationEvent | any): void {
        if (!this.firstRun && $event.toState === 'closed') {
            this.deleteToast();
        }
    }
}
