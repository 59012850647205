<mat-checkbox class="flex"
              *ngIf="!readonly"
              [ngClass]="{'read-only': readonly, 'mat-form-field': !slim, 'cb-required': required }"
              [name]="internalName"
              [(ngModel)]="value"
              [required]="required"
              [indeterminate]="indeterminate"
              [labelPosition]="labelPosition"
              [disabled]="disabled"
              (click)="clicked($event)">
    {{label}}
    <ng-content></ng-content>
</mat-checkbox>
<mat-checkbox class="flex"
              *ngIf="readonly"
              [ngClass]="{'read-only': readonly, 'mat-form-field': !slim}"
              [checked]="value"
              [disableRipple]="true"
              [disabled]="true">
    {{label}}
    <ng-content></ng-content>
</mat-checkbox>
