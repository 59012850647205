import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { UsersLogicService } from '@app/logic/users';
import { IClassicUserDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-user-search-card',
    templateUrl: './user-search-card.component.html',
    styleUrls: ['./user-search-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSearchCardComponent {

    @Input() public dto: IClassicUserDocumentDto;

    constructor(
        @Inject(NavigationService) public readonly navigationService: NavigationService,
        public readonly permissionsPermissions: PermissionsPermissions,
        private readonly logicService: UsersLogicService
    ) { }

    public viewUser(userId: string): void {
        this.navigationService
            .navigate(
                [`/users/${userId}`],
                undefined,
                {
                    queryParams: {
                        mappedItem: this.logicService.$createMappedItem(this.dto)
                    }
                } as NavigationExtras
            );
    }

    public canView(): boolean {
        return this.permissionsPermissions.canViewUsers();
    }

    public canEdit(): boolean {
        return this.permissionsPermissions.canEditUsers();
    }
}
