import { ISearchResult } from '@app/shared/components/search/i.search';
import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { ISystemLogSearchDto } from './interfaces/i.system-log-search.dto';
import { ISystemLogsLogicService } from './interfaces/i.system-logs-logic.service';
import { cleanObjectDeep } from 'cb-hub-lib';

const NOT_IMPLEMENTED_EXCEPTION = 'Method not implemented';
@Injectable()
export class SystemLogsLogicService
    extends BaseLogicService<ISystemLogSearchDto, Object>
    implements ISystemLogsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'hubapilog',
            Object
        );
    }

    public $getItem(entityId: number): Observable<ISystemLogSearchDto> {
        throw new Error(NOT_IMPLEMENTED_EXCEPTION);
    }

    public $saveItem(entity: ISystemLogSearchDto): Observable<ISystemLogSearchDto> {
        throw new Error(NOT_IMPLEMENTED_EXCEPTION);
    }

    public $deleteItem<ReturnType>(id: number): Observable<ReturnType> {
        throw new Error(NOT_IMPLEMENTED_EXCEPTION);
    }

    public $getList(): Observable<Array<ISystemLogSearchDto>> {
        throw new Error(NOT_IMPLEMENTED_EXCEPTION);
    }

    public search(
        params: { currentpage: number; query?: string; dateFrom?: DateString; dateTo?: DateString }
    ): Observable<ISearchResult<ISystemLogSearchDto>> {
        cleanObjectDeep(params);
        return this.$http
            .get<ISearchResult<ISystemLogSearchDto>>(`${this.$baseUri}/search`, params);
    }
}
