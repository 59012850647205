import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { BusinessAccountPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { BusinessAccountLogicService, BusinessAccountMappedItem, IBusinessAccountMappedItem } from '@app/logic/business-accounts';
import { BUSINESS_ACCOUNT_STATUS_ENUM, SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BusinessAccountAddAccountHoldDialogComponent } from '../business-account-add-account-hold-dialog/business-account-add-account-hold-dialog.component';
import { BusinessAccountCloseAccountDialogComponent } from '../business-account-close-account-dialog/business-account-close-account-dialog.component';
import { BusinessAccountEditFinancialInfoDialogComponent } from '../business-account-edit-financial-info-dialog/business-account-edit-financial-info-dialog.component';
import { BusinessAccountRequestSevenDaysDialogComponent } from '../business-account-request-seven-days-dialog/business-account-request-seven-days-dialog.component';
import { cloneDeep } from 'lodash';

enum TabNames {
    Details = 'details',
    LocationsContacts = 'locations-contacts',
    Catalogue = 'catalogue',
    Quotes = 'quotes',
    Orders = 'orders',
    Documents = 'documents',
    Notes = 'notes',
    ChildAccounts = 'child-accounts',
    HealthSafety = 'health-safety',
    History = 'history',
}

enum Tabs {
    Details,
    LocationsContacts,
    Catalogue,
    Quotes,
    Orders,
    Documents,
    Notes,
    ChildAccounts,
    HealthSafety,
    History,
}

@Component({
    selector: 'cb-business-account-view',
    templateUrl: './business-account-view.component.html',
    styleUrls: ['./business-account-view.component.scss']
})
export class BusinessAccountViewComponent {
    public accountId: number;
    public currentTabIndex = Tabs.Details;
    public account: IBusinessAccountMappedItem = {} as IBusinessAccountMappedItem;
    public readonly account$ = new BehaviorSubject<IBusinessAccountMappedItem>(undefined);
    public isCreate$ = new BehaviorSubject<boolean>(undefined);
    public isEditing$ = new BehaviorSubject<boolean>(undefined);
    public collapsed$ = new BehaviorSubject<boolean>(false);
    public systemArea = SYSTEM_AREA_ENUM.BusinessAccount;
    public BUSINESS_ACCOUNT_STATUS_ENUM = BUSINESS_ACCOUNT_STATUS_ENUM;

    private getAccountDelayTimeout = 500;

    constructor(
        public readonly permissions: BusinessAccountPermissions,
        public readonly cdRef: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly navigationService: NavigationService,
        private readonly cbDialog: CbDialogService,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
        private readonly ngZone: NgZone,
    ) {
        this.isEditing$.subscribe((isEditing: boolean) => {
            if (isEditing === false) {
                this.refresh();
            }
        });
    }

    public ngOnInit(): void {
        this.route.params.subOnce((params: { [key: string]: string }) => {
            this.accountId = params.id as unknown as number;
            this.currentTabIndex = Tabs[Object.keys(TabNames)[Object.values(TabNames).indexOf(params.tabName as TabNames)]] ?? Tabs.Details;
            this.ngZone.run(() => {
                setTimeout(() => this.refresh(), this.getAccountDelayTimeout);
            });
        });
        this.route.url.subOnce(url => {
            this.isCreate$.next(url?.length && url[url.length - 1]?.path === 'create');
        });
    }

    public tabIndexChanged(tabIndex: Tabs): void {
        this.currentTabIndex = tabIndex;
        const tabName = TabNames[Tabs[tabIndex]] ?? TabNames.Details;
        this.navigationService.navigate([`/business-accounts/edit/${this.accountId}/${tabName}`]);
        if (tabIndex === Tabs.Details) {
            this.refresh();
        }
    }

    public refresh(): void {
        if (this.accountId) {
            this.businessAccountLogicService.$getMappedItem(this.accountId).subOnce(result => {
                this.account = result;
                this.account$.next(this.account);
            });
        }
    }

    public onEditFinanceInformationClicked(): void {
        const dialog = this.cbDialog.open(BusinessAccountEditFinancialInfoDialogComponent, {
            data: {
                dialogHeading: 'Edit Finance Information',
                account: this.account
            },
        });
        dialog.afterClosed().subOnce(() => this.refresh());
    }

    public onRequestSevenDaysClicked(): void {
        const dialog = this.cbDialog.open(BusinessAccountRequestSevenDaysDialogComponent, {
            data: {
                dialogHeading: 'Request 7 days',
                account: this.account
            },
        });
        dialog.afterClosed().subOnce(() => this.refresh());
    }

    public onAddAccountHoldClicked(): void {
        const dialog = this.cbDialog.open(BusinessAccountAddAccountHoldDialogComponent, {
            data: {
                dialogHeading: 'Apply Hold to Business Account',
                account: this.account
            },
        });
        dialog.afterClosed().subOnce(() => this.refresh());
    }

    public onCloseClicked(): void {
        const dialog = this.cbDialog.open(BusinessAccountCloseAccountDialogComponent, {
            data: {
                dialogHeading: 'Close Business Account',
                account: this.account
            },
        });
        dialog.afterClosed().subOnce(() => this.refresh());
    }

    public onCancelClicked(): void {
        if (this.account.id) {
            this.isEditing$.next(false);
        } else {
            this.navigationService.navigate(['/business-accounts']);
        }
    }

    public canSave(): boolean {
        return this.canSavePhysicalAddress()
            && this.canSavePostalAddress()
            && !!this.account.requestedById
            && this.canSaveBankAccountDetails()
            && !!this.account.tradingName
            && !!this.account.businessName
            && !!this.account.phoneNumber
            && !!this.account.email
            && !!this.account.locations?.length
            && (this.account.isSupplier || this.account.isInstaller)
            && (this.account.isSupplier ? true : this.account.isInstaller ? !!this.account.tradeTypes?.length : true)
            && (this.account.id ? !!this.account.gstNumber : true);
    }

    public onSaveClicked(): void {
        if (this.canSave()) {
            if (this.account.id) {
                const mappedItem = cloneDeep(this.account);
                mappedItem.$save().subOnce(() => this.isEditing$.next(false));
            } else {
                const mappedItem = this.businessAccountLogicService.$createMappedItem(cloneDeep(this.account), BusinessAccountMappedItem);
                mappedItem.$save().subOnce((savedAccount) => {
                    if (savedAccount.id) {
                        this.navigationService.navigate([`/business-accounts/edit/${savedAccount.id}/details`]);
                    }
                });
            }
        }
    }

    private canSavePhysicalAddress(): boolean {
        return this.account.physicalAddress?.street && this.account.physicalAddress?.suburb && this.account.physicalAddress?.city && this.account.physicalAddress?.region;
    }

    private canSavePostalAddress(): boolean {
        return (this.account.postalAddress?.street && this.account.postalAddress?.suburb && this.account.postalAddress?.city && this.account.postalAddress?.region)
            || (!this.account.postalAddress?.street && !this.account.postalAddress?.suburb && !this.account.postalAddress?.city && !this.account.postalAddress?.region);
    }

    public accountUpdated(): void {
        this.account.$reload().subOnce(() => {
            this.cdRef.detectChanges();
        });
    }

    private canSaveBankAccountDetails(): boolean {
        return !!this.account.bankName &&
            !!this.account.bankAccountNumber &&
            (this.account.bankAccountNumber.length === 15 || this.account.bankAccountNumber.length === 16);
    }
}
