import {BasePermissions, IBaseSystemAreaPermissions, IHaveManualPurchaseOrdersPermissions, IManualPurchaseOrderPermissions} from './base.permissions';
import {QSTeamPermissionEnum} from '../../shared/enums/permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

export interface IQSTeamPermissions extends IBaseSystemAreaPermissions, IHaveManualPurchaseOrdersPermissions {
    canSeeComponent(): boolean;
    canView(): boolean;
    canSeeAll(): boolean;
    canEditRevision(): boolean;
    canFinaliseRevision(): boolean;
}

@Injectable()
export class QSTeamPermissions
    extends BasePermissions<QSTeamPermissionEnum>
    implements IQSTeamPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('qsteam', QSTeamPermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(QSTeamPermissionEnum.CanView);
    }

    public canSeeAll(): boolean {
        return this.permissionObject(QSTeamPermissionEnum.CanSeeAll);
    }

    public canEditRevision(): boolean {
        return this.permissionObject(QSTeamPermissionEnum.CanEditRevision);
    }

    public canFinaliseRevision(): boolean {
        return this.permissionObject(QSTeamPermissionEnum.CanFinaliseRevision);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(QSTeamPermissionEnum.CanView);
    }

    public canEdit(): boolean {
        return false;
    }
    public canCreate(): boolean {
        return false;
    }

    public getManualPurchaseOrderPermissions(): IManualPurchaseOrderPermissions {
        return {
            canApproveManualOrder: () => this.permissionObject(QSTeamPermissionEnum.CanApproveManualOrder),
            canManageManualOrder: () => false,
            canSubmitManualOrder: () => false
        };
    }
}
