import {Component, Input} from '@angular/core';
import {ILeadMappedItem} from '@app/logic/leads/interfaces/i.lead.mapped';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {ENQUIRY_ORIGIN_ENUM, ILotDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {LotsLogicService} from '@app/logic/lots';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {LeadEnquiryOriginEditDialogComponent} from './lead-enquiry-origin-edit-dialog/lead-enquiry-origin-edit-dialog.component';
import {IIdAndLabelDto} from '@app/shared/interfaces/i.idandlabel.dto';
import {LeadLogicService} from '@app/logic/leads';

@Component({
    selector: 'cb-lead-enquiry-origin-card',
    templateUrl: './lead-enquiry-origin-card.component.html',
    styleUrls: ['./lead-enquiry-origin-card.component.scss']
})
export class LeadEnquiryOriginCardComponent {
    @Input() public mappedItem: ILeadMappedItem;
    @Input() public canEdit: boolean;
    public ENQUIRY_ORIGIN_ENUM = ENQUIRY_ORIGIN_ENUM;
    public showHomes: ILotDto[];
    public showHomeToDisplay: IIdAndLabelDto<number>;

    constructor(
        private readonly navigationService: NavigationService,
        private readonly lotsLogicService: LotsLogicService,
        public readonly cbDialog: CbDialogService,
        private readonly leadLogicService: LeadLogicService
    ) {
        this.lotsLogicService.getShowhomes().subOnce(showHomes => {
            this.showHomes = showHomes;
            this.showHomeToDisplay = this.showHomes.find(sh => sh.id === this.mappedItem.showHomeId) as unknown as IIdAndLabelDto<number>;
        });
    }


    public getReferredByName(): string {
        if (!this.mappedItem.referredById) {
            return;
        }
        return this.mappedItem.referredBy;
    }

    public viewReferrer(id: string, event: MouseEvent): void {
        this.navigationService.navigate([`/contacts/${id}/summary`], event);
    }

    public getDisplayShowhomeSelect = (): boolean => {
        return this.mappedItem.showHomeId > 0;
    };

    public editEnquiryOriginDetails(event: Event): void {
        if (!this.canEdit) { return; }
        this.cbDialog.open(LeadEnquiryOriginEditDialogComponent, {
            data: {
                mappedItem: this.mappedItem.$clone()
            },
            minWidth: 600
        })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.mappedItem = this.leadLogicService.$createMappedItem(result);
                }
            });
    }
}
