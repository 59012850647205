<ng-container *ngIf="canViewPaymentsTable">
    <p class="mat-body-1">Payments (GST Inclusive)</p>
    <cb-table class="cb-margin-top"
              label="Payments"
              [loaded]="true"
              [minWidth]="750">
        <thead cbTableHead>
            <tr cbTableRow>
                <td>Event/Stage</td>
                <td>Type</td>
                <td>%</td>
                <td>Payment Amount</td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr cbTableRow
                *ngFor="let progressPayment of contractPricingUpdateDto.lotProgressPayments | cbSortByPipe: 'sortOrder' : 'asc'; let index = index;">
                <cb-td-text *ngIf="progressPayment.buildStageId"
                            [value]="progressPayment.buildStageLabel">
                </cb-td-text>
                <cb-td-enum *ngIf="!progressPayment.buildStageId"
                            [value]="progressPayment.trigger"
                            [enum]="BUILD_STAGE_EVENT_ENUM">
                </cb-td-enum>
                <cb-td-enum [value]="progressPayment.amountType"
                            [enum]="PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM">
                </cb-td-enum>
                <td>
                    <cb-input type="number"
                              (ngModelChange)="progressPayment.percentage=$event"
                              [ngModelOptions]="{updateOn: 'blur'}"
                              [ngModel]="+(progressPayment.percentage || 0 | number:'1.2-2')"
                              [readonly]="percentageReadOnly(progressPayment)"
                              [name]="progressPayment.buildStage?.label + '_percentage_' + index"
                              (change)="setDollarValueFromPercentage(progressPayment)">
                    </cb-input>
                </td>
                <td>
                    <cb-currency [name]="progressPayment.buildStage?.label + '_dollar_value_' + index"
                                 [ngModel]="progressPayment.dollarValue | number:'1.2-2'"
                                 (ngModelChange)="progressPayment.dollarValue=$event"
                                 [readonly]="dollarValueReadOnly"
                                 (change)="setPercentageFromDollarValue(progressPayment)">
                    </cb-currency>
                </td>
            </tr>
            <tr cbTableRow
                class="table-pink">
                <cb-td-text value="Balance"></cb-td-text>
                <td></td>
                <td>
                    <cb-display-value-new class="cb-margin-bottom-nil">
                        {{percentTotal | number:'1.2-2'}}
                    </cb-display-value-new>
                </td>
                <td>
                    <cb-display-value-new class="cb-margin-bottom-nil">
                        {{balance | cbCurrency}}
                    </cb-display-value-new>
                </td>
            </tr>
        </tbody>
    </cb-table>
</ng-container>
