<div class="start-center flex-row">
    <h3 class="mat-title flex">
        Trade Types
    </h3>
    <button mat-raised-button
            class="cb-margin-right"
            color="primary"
            (click)="generateReport()">
        Trade Types Report
    </button>
    <button mat-raised-button
            color="primary"
            (click)="addTradeType()"
            [disabled]="!permissions?.canEdit()">
        Add Trade Types
    </button>
</div>
<form #filterForm="ngForm"
      class="cb-margin-top flex-row">
    <cb-input class="filter-input"
              label="Filter Trade Types"
              name="filterTradeTypes"
              [(ngModel)]="filterText"
              (keyup)="filterChanged()"></cb-input>
</form>
<cb-trade-types-table [tradeTypes]="filteredResults.value"
                      [loaded]="loaded"
                      [expanded]="expanded"
                      (reloadTradeTypes)="loadTradeTypes()"></cb-trade-types-table>
