import { Component, Input } from '@angular/core';
import { ILotMappedItem } from '@app/logic/lots';
import { BehaviorSubject } from 'rxjs';
import { LOTS_ROUTE } from '../../lots-route.constant';

@Component({
    selector: 'cb-lot-change-area',
    templateUrl: './lot-change-area.component.html',
    styleUrls: ['./lot-change-area.component.scss']
})
export class LotChangeAreaComponent {

    public readonly LOTS_ROUTE = LOTS_ROUTE;
    public selectedTabIndex: number;

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);

    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    constructor() { }

    public onTabChanged(selectedTabIndex: number): void {
        this.selectedTabIndex = selectedTabIndex;
    }

}
