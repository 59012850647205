import { Component, OnDestroy } from '@angular/core';
import { WipService } from '@app/logic/wip/wip.service';
import { HeaderControlService } from '@app/shared/services/header.control.service';

@Component({
    selector: 'cb-wip-main-page',
    templateUrl: './wip-main-page.component.html',
    styleUrls: ['./wip-main-page.component.scss']
})
export class WipMainPageComponent implements OnDestroy {
    constructor(private wipService: WipService, private headerControl: HeaderControlService) {
        const btn = document.createElement('button');
        btn.innerHTML = 'Update WIP';

        this.headerControl.updateHeaderButton({
            element: btn,
            onClick: this.updateWip.bind(this)
        });
    }

    private updateWip(): void {
        this.wipService.refreshDocuments().subOnce(x=>{
            window.location.reload();
        });
    }

    public ngOnDestroy(): void {
        this.headerControl.updateHeaderButton(null);
    }
}
