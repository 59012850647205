import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {RESOURCE_CONSENT_STATUS_ENUM, ResourceConsentStatusEnumId} from '@classictechsolutions/hubapi-transpiled-enums';

import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {ConsentCouncilReceievedDialogComponent} from '../../consent-council-received-dialog/consent-council-received-dialog.component';
import {EditConsentNumberDialogComponent} from './../../edit-consent-number-dialog/edit-consent-number-dialog.component';
import {EditResourceConsentDialogComponent} from '../edit-dialog/edit-dialog.component';
import {GrantConsentDialogComponent} from '../../grant-consent-dialog/grant-consent-dialog.component';
import {IResourceConsentMappedItem} from '@app/logic/lot-consents';
import {LotConsentViewService} from './../../service/lot-consent-view.service';
import {LotDesignPermissions} from '@app/core/permissions';
import {ReassignResourceConsentDialogComponent} from '../reassign-dialog/reassign-dialog.component';
import {SubmittedToCouncilDilaogComponent} from '../../submitted-to-council-dilaog/submitted-to-council-dilaog.component';

@Component({
    selector: 'cb-lot-resource-consent-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceConsentActionBarComponent {
    @Input() public readonly resourceConsent: IResourceConsentMappedItem;
    @Input() public readonly hasResourceConsentDocument: boolean;

    /** replace this with "this.resourceConsentViewService.backToList()" like council rfis */
    @Output() public readonly backToList = new EventEmitter<void>();
    @Output() public reloadLotMappedItem = new EventEmitter();

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly lotConsentViewService: LotConsentViewService,
        private readonly cdRef: ChangeDetectorRef
    ) { }


    public editResourceConsent(): void {
        this.resourceConsent.$reload()
            .subOnce(() => {
                this.cdRef.detectChanges();
                this.cbDialog.open(EditResourceConsentDialogComponent, {
                    data: {
                        resourceConsent: this.resourceConsent.$clone(),
                    },
                    minWidth: '33%',
                }).afterClosed().subOnce(() => {
                    this.cdRef.detectChanges();
                });
            });
    }

    public canDeleteConsent(): boolean {
        return this.lotDesignPermissions.canDeleteConsent();
    }

    public canEditConsent(): boolean {
        return this.lotDesignPermissions.canEditConsent();
    }

    public canChangeConsentStatus(): boolean {
        return this.lotDesignPermissions.canEditConsent();
    }

    public setInQueue(): void {
        this.setStatusAndSave(RESOURCE_CONSENT_STATUS_ENUM.InQueue);
    }

    public setOnHold(): void {
        this.setStatusAndSave(RESOURCE_CONSENT_STATUS_ENUM.OnHold);
    }

    public setInProgress(): void {
        this.setStatusAndSave(RESOURCE_CONSENT_STATUS_ENUM.InProgress);
    }

    public setSubmittedToCouncil(): void {
        this.cbDialog.open(SubmittedToCouncilDilaogComponent, {
            data: {
                mappedItem: this.resourceConsent.$clone(),
            },
            minWidth: '33%',
        }).afterClosed().subOnce(() => {
            this.cdRef.detectChanges();
        });
    }

    public editConsentNumber(): void {
        this.cbDialog.open(EditConsentNumberDialogComponent, {
            data: {
                mappedItem: this.resourceConsent.$clone(),
            },
            minWidth: '33%',
        }).afterClosed().subOnce(() => {
            this.cdRef.detectChanges();
        });
    }

    public setCouncilReceived(): void {
        this.cbDialog.open(ConsentCouncilReceievedDialogComponent, {
            data: {
                mappedItem: this.resourceConsent.$clone(),
            },
            minWidth: '33%',
        }).afterClosed().subOnce(() => {
            this.cdRef.detectChanges();
        });
    }

    public grantConsent(): void {
        if (!this.hasResourceConsentDocument) {
            return;
        }
        this.cbDialog.open(GrantConsentDialogComponent, {
            data: {
                mappedItem: this.resourceConsent.$clone(),
            },
            minWidth: '33%',
        }).afterClosed().subOnce(() => {
            this.reloadLotMappedItem.emit();
            this.cdRef.detectChanges();
        });
    }

    public denyConsent(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Consent Denied',
            message: 'Are you sure you want to mark this Resource Consent as denied?',
            confirmed: () => {
                this.setStatusAndSave(RESOURCE_CONSENT_STATUS_ENUM.Declined);
            }
        });
    }

    public assignResourceConsent(): void {
        this.resourceConsent.$reload()
            .subOnce(() => {
                this.cdRef.detectChanges();
                this.cbDialog.open(ReassignResourceConsentDialogComponent, {
                    data: {
                        resourceConsent: this.resourceConsent.$clone(),
                    },
                    minWidth: '33%',
                });
            });
    }

    public goBackToList(): void {
        // this.resourceConsentViewService.backToList();
        /** replace this with "this.resourceConsentViewService.backToList()" like council rfis */
        this.backToList.emit();
        this.lotConsentViewService.backToList();
    }

    public deleteResourceConsent(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Resource Consent from this Lot',
            message: 'Are you sure you want to delete this Resource Consent?',
            confirmed: () => {
                this.resourceConsent.delete().subOnce((result) => {
                    if (result) {
                        this.goBackToList();
                    }
                });
            }
        });
    }

    private setStatusAndSave(status: ResourceConsentStatusEnumId): void {
        this.resourceConsent.consentStatus = status;
        this.resourceConsent.$save().subOnce(() => {
            this.cdRef.detectChanges();
        });
    }
}
