<mat-card class="cb-margin flex-row">
    <mat-card-content class="start-start flex-col flex"
                      *cbWaitFor="mappedItem">
        <div class="flex-row"
             style="width: 100%;">
            <div class="cb-margin flex-col flex-33">
                <h3 class="mat-title"
                    style="margin-bottom: 0">
                    Project Details
                </h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-display-value-new label="Project Status"
                                      [value]="PROJECT_STATUS_ENUM[mappedItem.status]">
                </cb-display-value-new>
                <cb-display-value-new label="Principal"
                                      [value]="mappedItem.accountOwner.label"
                                      [showBtn]="true"
                                      (click)="viewPrincipal(mappedItem.accountOwner.id)">
                </cb-display-value-new>
                <cb-input name="projectName"
                          label="Project Name"
                          [required]="true"
                          maxlength="100"
                          [disabled]="false"
                          [readonly]="true"
                          [(ngModel)]="mappedItem.projectName">
                </cb-input>
                <cb-text-area label="Marketing Description"
                              name="question"
                              [readonly]="true"
                              [(ngModel)]="mappedItem.description"
                              [maxlength]="1000"
                              [required]="true">
                </cb-text-area>
                <cb-input name="lot"
                          label="Lot"
                          [required]="true"
                          maxlength="100"
                          [readonly]="true"
                          [(ngModel)]="mappedItem.lot">
                </cb-input>
                <cb-input name="dpNumber"
                          label="DP Number"
                          [required]="true"
                          maxlength="10"
                          [readonly]="true"
                          [(ngModel)]="mappedItem.dpNumber">
                </cb-input>
                <cb-input name="totalNoStages"
                          label="Total Release Stages"
                          [required]="true"
                          [readonly]="true"
                          [(ngModel)]="mappedItem.totalNoStages">
                </cb-input>
                <h3 class="mat-title"
                    style="margin-bottom: 0">
                    Project Address
                </h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-address-new [(ngModel)]="mappedItem.address"
                                [hideHeading]="true"
                                [readonly]="true"
                                name="address">
                </cb-address-new>
            </div>
            <div class="cb-margin flex-col flex-66">
                <h3 class="mat-title"
                    style="margin-bottom: 0">
                    Account Contacts
                </h3>
                <cb-table class="cb-margin-top cb-margin-bottom-3x"
                          [loaded]="loaded">
                    <thead cbTableHead>
                        <tr>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Home Phone</td>
                            <td>Work Phone</td>
                            <td>Mobile Phone</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tr cbTableRow
                        *ngFor="let result of mappedItem.contacts | cbSortByPipe: 'firstName': 'asc'">
                        <cb-td-text [value]="SALUTATION_ENUM[result.title] + ' ' + result.firstName + ' ' +result.lastName">
                        </cb-td-text>
                        <cb-td-text [ngClass]="ContactUtilities.isEmailPreferred(result) ? 'cb-accent' : 'cb-dark-grey'"
                                    [value]="result.email"></cb-td-text>
                        <cb-td-text [ngClass]="ContactUtilities.isHomePreferred(result) ? 'cb-accent' : 'cb-dark-grey'"
                                    [value]="result.phoneHome"></cb-td-text>
                        <cb-td-text [ngClass]="ContactUtilities.isWorkPreferred(result) ? 'cb-accent' : 'cb-dark-grey'"
                                    [value]="result.phoneWork"></cb-td-text>
                        <cb-td-text [ngClass]="ContactUtilities.isMobilePreferred(result) ? 'cb-accent' : 'cb-dark-grey'"
                                    [value]="result.phoneMobile"></cb-td-text>
                        <td cbTableStickyCol="end"
                            cbTableShrinkCol>
                            <button mat-icon-button
                                    (cbClick)="viewContact(result.id)">
                                <mat-icon class="cb-grey">pageview</mat-icon>
                            </button>
                        </td>
                    </tr>
                </cb-table>
                <cb-project-team-table [mappedItem]="mappedItem"></cb-project-team-table>
                <cb-checkbox [(ngModel)]="mappedItem.hasProjectLevelRates"
                             label="Product Rates can be loaded against this Project's Releases"
                             [readonly]="true">
                </cb-checkbox>
                <cb-display-value-new label="Project Code for Import"
                                      [value]="mappedItem.code">
                </cb-display-value-new>
                <cb-display-value-new label="Area"
                                      [value]="locationLabel">
                </cb-display-value-new>
                <cb-display-value-new label="Build Consent Council"
                                      [value]="councilLabel">
                </cb-display-value-new>
                <cb-checkbox [(ngModel)]="mappedItem.areAllLotsToBeSoldToOneProjectClient"
                             label="Are all lots to be sold to one project client?"
                             [readonly]="true">
                </cb-checkbox>
            </div>
        </div>
    </mat-card-content>
</mat-card>
