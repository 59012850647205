<cb-dialog dialogHeading="Manage Availability">
    <form class="flex-col flex"
          #manageAvailabilityForm="ngForm">
        <div class="flex-row flex">
            <h3 class="mat-title">
                {{mappedItem.code}} {{mappedItem.name}}
            </h3>
        </div>
        <div class="cb-margin-right-3x flex-col flex">
            <mat-radio-group aria-label="Product Status"
                             [(ngModel)]="mappedItem.status"
                             name="type"
                             class="cb-margin-bottom-2x flex-col"
                             [required]="true">
                <mat-radio-button class="cb-margin-right"
                                  [value]="OFFERING_STATUS_ENUM.Active">
                    Available
                </mat-radio-button>
                <mat-radio-button [value]="OFFERING_STATUS_ENUM.TemporarilyUnavailable">
                    Temporarily Unavailable
                </mat-radio-button>
                <div class="flex-25">
                    <cb-datepicker name="unavailableStartDateObject"
                                   [(ngModel)]="mappedItem.unavailableStartDateObject"
                                   [required]="mappedItem.status === OFFERING_STATUS_ENUM.TemporarilyUnavailable"
                                   label="Unavailable Start Date"
                                   pickerType="calendar"
                                   [disabled]="mappedItem.status !== OFFERING_STATUS_ENUM.TemporarilyUnavailable">
                    </cb-datepicker>
                </div>
                <cb-datepicker name="unavailableEndDateObject"
                               [(ngModel)]="mappedItem.unavailableEndDateObject"
                               [required]="mappedItem.status === OFFERING_STATUS_ENUM.TemporarilyUnavailable"
                               label="Unavailable End Date"
                               pickerType="calendar"
                               [minDate]="mappedItem.unavailableStartDateObject"
                               [disabled]="mappedItem.status !== OFFERING_STATUS_ENUM.TemporarilyUnavailable">
                </cb-datepicker>
                <mat-radio-button [value]="3"
                                  (click)="mappedItem.unavailableEndDateObject = undefined">
                    Permanently Unavailable
                </mat-radio-button>
                <div class="flex-row flex">
                    <cb-datepicker name="unavailableStartDateObject1"
                                   class="cb-margin-right-2x flex"
                                   [(ngModel)]="mappedItem.unavailableStartDateObject"
                                   [required]="mappedItem.status === OFFERING_STATUS_ENUM.PermanentlyUnavailable"
                                   label="Unavailable Start Date"
                                   pickerType="calendar"
                                   [disabled]="mappedItem.status !== OFFERING_STATUS_ENUM.PermanentlyUnavailable">
                    </cb-datepicker>
                    <cb-select class="flex"
                               label="Due to"
                               name="dueTo"
                               [options]="unavailableReasons"
                               [(ngModel)]="mappedItem.unavailableReason"
                               [disabled]="mappedItem.status !== OFFERING_STATUS_ENUM.PermanentlyUnavailable">
                    </cb-select>
                </div>
            </mat-radio-group>
            <div class="flex-col flex"
                 *ngIf="mappedItem.status !== OFFERING_STATUS_ENUM.Active">
                <cb-product-autocomplete-scroller class="flex"
                                                  #autocompleteControl
                                                  label="Alternative Product"
                                                  name="altProduct"
                                                  [displayCategories]="true"
                                                  [requireMatch]="true"
                                                  [(ngModel)]="selectedProduct"
                                                  (optionSelected)="productSelected($event)"
                                                  [hideCompositeItems]="true">
                </cb-product-autocomplete-scroller>
                <cb-text-area name="comments"
                              label="Comments"
                              [required]="true"
                              [(ngModel)]="mappedItem.statusComment"
                              maxlength="500">
                </cb-text-area>
            </div>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="manageAvailabilityForm.pristine || manageAvailabilityForm.invalid"
                (click)="save()">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
