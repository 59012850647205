<form class="flex-row layoutGap-10"
      *cbWaitFor="(sourceEntity$$ | async) && (mappedItem$$ | async)  && (isbuildTypesLoaded$$ | async)">
    <div class="flex-col flex-50">
        <h4 class="mat-h4 cb-margin-top cb-margin-bottom">
            <b>{{sourceEntity.name}}</b>
        </h4>
        <mat-divider class="cb-margin-bottom-2x">
        </mat-divider>
        <div class="flex-row">
            <cb-display-value-new class="flex"
                                  *ngIf="sourceEntity?.buildType"
                                  label="Build Type"
                                  [value]="sourceEntity?.buildType "></cb-display-value-new>
            <cb-display-value-new class="flex"
                                  *ngIf="sourceEntity?.buildType"
                                  label="Build Type Description">
                {{getBuildTypeDescription(sourceEntity.buildType)}}
            </cb-display-value-new>
        </div>
        <div class="flex-row">
            <cb-display-value-new class="flex"
                                  label="Floor Area">
                <div class="start-center">
                    <mat-icon class="cb-grey"
                              svgIcon="floor-plan"></mat-icon>
                    <span class="mat-body-1">{{sourceEntity.amenities.floorArea | cbDasher}}m²</span>
                </div>
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Land Area">
                <div class="start-center">
                    <mat-icon class="cb-grey">border_style</mat-icon>
                    <span class="mat-body-1">{{sourceEntity.amenities.landArea | cbDasher}}m²</span>
                </div>
            </cb-display-value-new>
        </div>
        <cb-amenities [amenities]="amenities"></cb-amenities>
        <cb-text-area label="Design Requirements"
                      name="designRequirements"
                      [(ngModel)]="sourceEntity.designRequirements"
                      [readonly]="true"></cb-text-area>
        <cb-display-value-new label="Standard Plan"
                              name="designRequirements"
                              [value]="sourceEntity?.standardPlanLabel"></cb-display-value-new>
        <cb-checkbox name="resourceConsentRequired"
                     label="Resource Consent Required"
                     [readonly]="true"
                     [(ngModel)]="sourceEntity.resourceConsentRequired">
        </cb-checkbox>
        <h4 class="mat-h4 cb-margin-top cb-margin-bottom">
            <b>Lot Specfication</b>
        </h4>
        <mat-divider class="cb-margin-bottom-2x">
        </mat-divider>
        <cb-display-value-new label="Spec Version"
                              name="designRequirements"
                              [value]="mappedItem?.lotSpecVersion"></cb-display-value-new>
    </div>
    <div class="flex-col flex-50">
        <h4 class="mat-h4 cb-margin-top cb-margin-bottom">
            <b>{{mappedItem.pricingRevisionId > 0 ? mappedItem.pricingRevision.name : 'Financials'}}</b>
        </h4>
        <mat-divider class="cb-margin-bottom-2x">
        </mat-divider>
        <cb-currency *ngIf="mappedItem.pricingRevision"
                     label="Land Price"
                     name="landPrice"
                     [readonly]="true"
                     [(ngModel)]="mappedItem.pricingRevision.landPrice">
        </cb-currency>
        <cb-currency *ngIf="mappedItem.pricingRevision"
                     label="Build Cost"
                     name="buildCost"
                     [readonly]="true"
                     [(ngModel)]="mappedItem.pricingRevision.buildCost">
        </cb-currency>
        <div class="flex-row"
             *ngIf="mappedItem.pricingRevision">
            <cb-display-value-new class="flex"
                                  label="Build Price"
                                  name="sellingPrice">
                {{mappedItem.pricingRevision.buildPrice | cbCurrency}}
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Build Price - (Gst Inclusive)"
                                  name="sellingPriceGst">
                {{mappedItem.pricingRevision.buildPrice | cbCurrency:'addGst'}}
            </cb-display-value-new>
        </div>
        <div class="flex-row"
             *ngIf="mappedItem.pricingRevision">
            <cb-display-value-new class="flex"
                                  label="Sell Price"
                                  name="sellingPrice">
                {{mappedItem.sellingPrice | cbCurrency}}
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Sell Price - (Gst Inclusive)"
                                  name="sellingPriceGst">
                {{mappedItem.sellingPrice | cbCurrency:'addGst'}}
            </cb-display-value-new>
        </div>
        <cb-display-value-new label="Cost m²">
            <div class="start-center">
                <span class="mat-body-1">{{(mappedItem.pricingRevision.buildCost/sourceEntity.amenities.floorArea) |
                    cbCurrency }}</span>
            </div>
        </cb-display-value-new>
        <cb-checkbox name="isEstimatedPrice"
                     label="Price Estimated"
                     [(ngModel)]="mappedItem.isEstimatedPrice">
        </cb-checkbox>
        <cb-display-value-new label="Price Expires"
                              name="pricingExpiryDate"
                              [value]="mappedItem?.pricingExpiryDate | date"></cb-display-value-new>
    </div>
</form>
