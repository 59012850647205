export enum LotDesignPermissionEnum {
    None = 0x00,
    CanChangeStatus = 0x01 << 0,
    CanRequestImages = 0x01 << 1,
    CanRequestScheme = 0x01 << 2,
    CanCreateScheme = 0x01 << 3,
    CanEditScheme = 0x01 << 4,
    CanEditSchemeSpecs = 0x01 << 5,
    CanRequestSchemePricing = 0x01 << 6,
    CanSetConceptSellPrice = 0x01 << 7,
    CanSetConceptExpiryDate = 0x01 << 8,
    CanApplyConcept = 0x01 << 9,
    CanAbandonConcept = 0x01 << 10,
    CanGeneratePreconsent = 0x01 << 11,
    CanManagePreconsentStubs = 0x01 << 12,
    CanUploadPreconsentDocuments = 0x01 << 13,
    CanDeletePreconsentDocuments = 0x01 << 14,
    CanEditPreconsentDocuments = 0x01 << 15,
    CanReviewPreconsentDocuments = 0x01 << 16,
    CanViewPreconsentDocuments = 0x01 << 17,
    CanCreateConsent = 0x01 << 18,
    CanEditConsent = 0x01 << 19,
    CanDeleteConsent = 0x01 << 20,
    CanUploadConsentDocuments = 0x01 << 21,
    CanDeleteConsentDocuments = 0x01 << 22,
    CanEditConsentDocuments = 0x01 << 23,
    CanReviewConsentDocuments = 0x01 << 24,
    CanViewConsentDocuments = 0x01 << 25,
    CanRequestWorkingDrawing = 0x01 << 26,
    CanCreateWorkingDrawing = 0x01 << 27,
    CanCreateRFI = 0x01 << 28,
    CanEditRFI = CanCreateRFI * 2,
    CanChangeRFIStatus = CanEditRFI * 2,
    CanDeleteRFI = CanChangeRFIStatus * 2,
    CanManageManualOrder = CanDeleteRFI * 2,
    CanSubmitManualOrder = CanManageManualOrder * 2,
    CanManageImages = CanSubmitManualOrder * 2,
    CanManagePreconsentPlan = CanManageImages * 2,
    CanEditWorkingDrawing = CanManagePreconsentPlan * 2,
    CanUpdatePreconsentStatus = CanEditWorkingDrawing * 2,
    CanAddPreconsentStubs = CanUpdatePreconsentStatus * 2,
    CanEditConsentNumber = CanAddPreconsentStubs * 2,
    CanSetLotAvailableForSale = CanEditConsentNumber * 2,
    CanEditSchemeAmentiesAfterCompleted = CanSetLotAvailableForSale * 2,
    CanEditPreconsent = CanEditSchemeAmentiesAfterCompleted * 2
}
