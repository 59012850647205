export const floorAreaSelect = [
    {
        id: 0,
        label: '0 -99',
        minValue: 0,
        maxValue: 99
    },
    {
        id: 1,
        label: '100 -199',
        minValue: 100,
        maxValue: 199
    },
    {
        id: 1.5,
        label: '200 - 299',
        minValue: 200,
        maxValue: 299
    },
    {
        id: 2,
        label: '300 - Any',
        minValue: 300,
    }
];
