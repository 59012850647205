import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FillHeightDirective } from './fill-height.directive';



@NgModule({
    declarations: [FillHeightDirective],
    exports: [FillHeightDirective],
    imports: [
        CommonModule
    ]
})
export class CbFillHeightModule { }
