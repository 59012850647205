<cb-dialog class="flex-col"
           dialogHeading="Complete Multiple Activities">
    <cb-table [loaded]="data">
        <thead cbTableHead>
            <tr>
                <td></td>
                <td>PO #</td>
                <td>Job Number</td>
                <td>Activity</td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr *ngFor="let element of data.purchaseOrders"
                class="overflow-hidden">
                <td class="overflow-hidden">
                    <mat-icon class="cb-grey"
                              *ngIf="!inProgress[element.id] && !successful[element.id] && !failed[element.id]"
                              matTooltip="Complete not in progress">radio_button_unchecked</mat-icon>
                    <mat-spinner *ngIf="inProgress[element.id] && !successful[element.id] && !failed[element.id]"
                                 diameter="20"
                                 matTooltip="Complete in progress"></mat-spinner>
                    <mat-icon *ngIf="successful[element.id]"
                              color="primary"
                              matTooltip="Complete Succeeded">check_circle </mat-icon>
                    <mat-icon *ngIf="failed[element.id]"
                              class="cb-error"
                              [matTooltip]="failed[element.id]">cancel </mat-icon>
                </td>
                <td>{{element.orderNumber}}</td>
                <td>{{element.jobNumber}}</td>
                <td>{{element.activityName}}</td>
            </tr>
        </tbody>
    </cb-table>
    <footer class="end-center flex-row">
        <button mat-raised-button
                (cbClick)="completeAll()"
                [clickLimit]="1"
                color="primary">Complete All</button>
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </footer>
</cb-dialog>
