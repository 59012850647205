<cb-dialog dialogHeading="{{this.getDialogHeader()}}">
    <form class="flex-col"
          #standardPlanForm="ngForm">
        <mat-dialog-content>
            <mat-form-field [class.no-line]="this.isView()">
                <input matInput
                       type="text"
                       name="planName"
                       placeholder="Plan Name"
                       required
                       [(ngModel)]="this.data.plan.name"
                       [disabled]="this.isView()"/>
            </mat-form-field>
            <mat-form-field [class.no-line]="this.isView()">
                <textarea #description
                          matInput
                          type="text"
                          name="description"
                          placeholder="Plan Description"
                          maxlength="1000"
                          [(ngModel)]="this.data.plan.description"
                          [disabled]="this.isView()">
                </textarea>
                <mat-hint *ngIf="this.isEdit()"
                          align="end">{{description.value.length}} / 1000</mat-hint>
            </mat-form-field>
            <div class="image-tabs"
                 *ngIf="this.data.plan && data.plan.stylesWithUrl && !this.isAdd()">
                <mat-tab-group>
                    <mat-tab *ngFor="let style of data.plan.stylesWithUrl"
                             label="{{style.name}}">
                        <div class="flex-row flex">
                            <img #planImage
                                 mat-card-image
                                 src="{{style.url}}?w=600&h=400&scale=canvas"
                                 cbHandleInvalidImage
                                 [height]="400"
                                 [width]="600"
                                 alt="Plan Image"/>
                            <div class="bottom-right end-center flex-row">
                                <button *ngIf="!style.isPlaceHolderImage"
                                        mat-raised-button
                                        (click)="this.viewImage(style)"
                                        class="button-right-8px">
                                    <mat-icon>search</mat-icon>
                                    &nbsp;View Image
                                </button>
                                <button mat-raised-button
                                        class="button-right-8px"
                                        (click)="this.uploadImage(style)"
                                        *ngIf="this.isEdit() && !style.isPlaceHolderImage">
                                    <mat-icon>cloud_upload</mat-icon>
                                    &nbsp;Change Image
                                </button>
                                <button mat-raised-button
                                        class="button-right-8px"
                                        (click)="this.uploadImage(style)"
                                        *ngIf="this.isEdit() && style.isPlaceHolderImage">
                                    <mat-icon>cloud_upload</mat-icon>
                                    &nbsp;Upload Image
                                </button>
                                <button mat-raised-button
                                        class="button-right-8px"
                                        (click)="this.deleteImage(style)"
                                        *ngIf="this.isEdit() && !style.isPlaceHolderImage">
                                    <mat-icon>delete</mat-icon>
                                    &nbsp;Delete Image
                                </button>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <mat-tab-group>
                <mat-tab label="Plan Details">
                    <div class="flex-row flex">
                        <cb-standard-plan-details-view class = "flex"
                                                       [plan]="this.data.plan"
                                                       *ngIf="this.isView()"></cb-standard-plan-details-view>
                        <cb-standard-plan-details-edit class = "flex"
                                                       [plan]="this.data.plan"
                                                       *ngIf="this.isEdit() || this.isAdd()">
                        </cb-standard-plan-details-edit>
                    </div>
                </mat-tab>
                <mat-tab label="Costs"
                         *ngIf="this.data.plan && this.data.addressRegions && this.data.plan.planCosts ">
                    <div class="flex-row flex">
                        <cb-standard-plan-costs class= "flex"
                                                [plan]="this.data.plan"
                                                [addressRegions]="this.data.addressRegions"
                                                [formMode]="this.data.formMode"></cb-standard-plan-costs>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-dialog-content>
    </form>
    <footer class="flex-row">
        <button mat-raised-button
                *ngIf="this.isView()"
                (click)="duplicate()">Duplicate</button>
        <button mat-raised-button
                *ngIf="this.isEdit()"
                (click)="deleteStandardPlan()">Delete</button>
        <div class="flex"></div>
        <button *ngIf="this.isView()"
                mat-raised-button
                (click)="edit()">Edit</button>
        <button *ngIf="this.isView()"
                mat-raised-button
                (click)="close()">Close</button>
        <button *ngIf="this.isEdit() || this.isAdd()"
                [disabled]=" (!standardPlanForm.dirty && this.isEdit()) || !standardPlanForm.valid"
                mat-raised-button
                (click)="save()">Save</button>
        <button *ngIf="this.isEdit() || this.isAdd()"
                mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
