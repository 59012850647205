import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CONTRACT_STATUS_ENUM, ContractStatusEnumId, LOT_JOB_STATUS_ENUM, LotJobStatusEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { IProductItemUsageLotSpecSearch } from '@app/core/services/user-cache/user-cache-areas';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ProductLogicService } from '@app/logic/products';
import { ProductLotSpecUsageParams } from '@app/logic/products/product.logic.service';
import { TableComponent } from 'cb-hub-lib';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';

@Component({
    selector: 'cb-item-usage-lot-specification-tab',
    templateUrl: './item-usage-lot-specification-tab.component.html',
    styleUrls: ['./item-usage-lot-specification-tab.component.scss'],
    providers: [NavigationService, UserCacheService, ProductLogicService, ToastService]
})
export class ItemUsageLotSpecificationTabComponent extends BaseSearchViewDirective<IProductItemUsageLotSpecSearch> implements OnInit, AfterContentChecked {
    @Input() public productId: number;
    public jobStatuses: IEnumLookup<LotJobStatusEnumId>[];
    public lotStatuses: IEnumLookup<ContractStatusEnumId>[];
    @ViewChild('infiniteScrollTable', { static: false }) public infiniteScrollTable: TableComponent;

    public viewLot = (specTemplate: any): void => {
        this.navigationService.navigate([`lots/${specTemplate.lotId}`], undefined);
    };

    constructor(
        public readonly navigationService: NavigationService,
        public readonly productLogicService: ProductLogicService,
        public readonly toastSerivce: ToastService,
        public readonly userCacheService: UserCacheService,
        private readonly cdref: ChangeDetectorRef) {

        super(
            productLogicService,
            toastSerivce,
            userCacheService,
            USER_CACHE_AREA.ProductItemUsageLotSpecSearch,
        );
        this.lotStatuses = CONTRACT_STATUS_ENUM.toLookup({
            transform: lotStatuses => {
                lotStatuses.unshift({ id: undefined, label: 'All' } as any);
                return lotStatuses;
            }
        });
        this.jobStatuses = LOT_JOB_STATUS_ENUM.toLookup({
            transform: (jobStatuses) => {
                jobStatuses.unshift({ id: undefined, label: 'All' } as any);
                return jobStatuses;
            }
        });
    }

    public ngOnInit(): void {
        this.loadSearchParams();
    }

    public ngAfterContentChecked(): void {
        this.cdref.detectChanges();
    }

    public doSearch = (): void => {
        if (this.shouldDoSearch()) {
            this.doSearchIfNoScrollBar();
            this.currentPage++;
            this.searchIsLoading = true;
            this.productLogicService
                .getProductLotSpecUsage(this.productId, {
                    ...this.getSearchParams()
                }).subOnce({
                    next: this.handleSearchResult,
                    error: this.handleSearchError
                });
        }
    };

    public getSearchParams(): ProductLotSpecUsageParams {
        return {
            hasClientSale: this.userCacheService.productItemUsageLotSpecSearch.data.hasClientSale,
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            lotStatus: this.userCacheService.productItemUsageLotSpecSearch.data.lotStatus,
            jobStatus: this.userCacheService.productItemUsageLotSpecSearch.data.jobStatus,
        };
    }
}
