import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cbTruncate'
})
export class TruncatePipe implements PipeTransform {
    public transform(value: string, maxLength: number): string {
        if (!value) {
            return value;
        }
        if (value.length > maxLength) {
            return value.slice(0, maxLength) + '...';
        }
        return value;
    }
}
