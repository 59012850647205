import { IDocumentEntityDto, IDocumentDto } from '../interfaces/i.document.dto';
import { Prop } from '@app/shared/decorators/prop.decorator';
import { exportDto } from '@app/shared/logic-utils/export-dto.util';
import { IDocumentGroupDto } from '../interfaces/i.document-group.dto';
import { BuildProgrammeActivityStatusEnumId, DocumentStatusEnumId, IDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

class LotBuildProgrammeDocumentEntityDto implements IDocumentEntityDto {
    @Prop public id: number;
    @Prop public documentId?: number;
    @Prop public document: IDocumentDto;
    @Prop public primaryDocumentEntityId: number;
    @Prop public isLinkedDocument: boolean;
    @Prop public documentGroup?: IDocumentGroupDto;
    @Prop public documentType?: IDocumentTypeDto;
    @Prop public canSetRenewalDate: boolean;
    @Prop public defaultMonthsUntilRenewal: number;
    @Prop public allowMultiples: boolean;
    @Prop public documentRequiredType: number;
    @Prop public documentApprovalType: number;
    @Prop public documentStatus: DocumentStatusEnumId;
    @Prop public extraInformation: string;
    @Prop public createdDate: string;
    @Prop public createdByName: string;
    @Prop public updatedDate: string;
    @Prop public updatedByName: string;
    // unique props below
    @Prop public supplier: string;
    @Prop public buildProgrammeActivityStatus: BuildProgrammeActivityStatusEnumId;
}

export const lotBuildProgrammedocumentEntityDto = exportDto<LotBuildProgrammeDocumentEntityDto>(LotBuildProgrammeDocumentEntityDto);
