<cb-dialog dialogHeading="View Quote Variation Orders">
    <cb-info-message *ngIf="isLoaded &&  variationOrders.length < 1"
                     message="There are no variations to display">
    </cb-info-message>
    <cb-loading-spinner *ngIf="!isLoaded"></cb-loading-spinner>
    <ng-container *ngIf="isLoaded && variationOrders.length > 0">
        <mat-list-item *ngFor="let variation of variationOrders">
            <div mat-line>
                VO #{{variation.variationNumber}} - {{ variation.costNature | cbEnumToLabel: COST_NATURE_ENUM
                }}
                <span *ngIf="variation.costNature === COST_NATURE_ENUM.ExtraToClient">{{ variation.variationTotal |
                    cbCurrency:'addGst'}}</span>
                <span *ngIf="variation.costNature !== COST_NATURE_ENUM.ExtraToClient">{{ variation.variationTotal |
                    cbCurrency}}</span>
            </div>
        </mat-list-item>
    </ng-container>
</cb-dialog>
