import {Observable, take} from 'rxjs';

/** This utility provides methods to transform Angular7+/rxjs observables
 * into Promises and data that mimics angularJs resources with $promise and $resolved props
 */

/** Observables will unsubscribe after this many observers have completed - use case observable.pipe(take(SUBSCRIBE_COUNT)) */
const SUBSCRIBE_COUNT = 1;
const PROMISE_PROP = '$promise';
const RESOLVED_PROP = '$resolved';

export type IPromised<ValueType> = ValueType & {
    [PROMISE_PROP]: Promise<ValueType>;
    [RESOLVED_PROP]: boolean;
};

/** Transforms an Observable<any> into a Promise */
export function toPromise<ValueType>(observable: Observable<ValueType>): Promise<ValueType> {
    // subscribe to observable and transform to promise
    const promise = new Promise((resolve, reject) => {
        observable
            .pipe(take(SUBSCRIBE_COUNT))
            .subscribe(
                (value) => {
                    resolve(value);
                },
                (error) => {
                    reject(error);
                }
            );
    });
    // return promise regardless of observables status
    return promise as Promise<ValueType>;
}

/** Transforms an Observable<any[]> into an array that contains $promise and $resolved - the returned array will also be populated with the results */
export function toPromisedArray<ValueType extends Array<any>>(observable: Observable<ValueType>): IPromised<ValueType> {
    return toPromisedItem(observable, [] as any);
}

/** Transforms an Observable<{}> into an object that contains $promise and $resolved - the returned object will also be populated with the results */
export function toPromisedObject<ValueType extends Object>(observable: Observable<ValueType>): IPromised<ValueType> {
    return toPromisedItem(observable, {} as any);
}

/** Transforms an Observable<{} | any[]> into an object/array that contains $promise and $resolved - the returned object/array will also be populated with the results */
function toPromisedItem<T extends Observable<T2>, T2 extends any | any[]>(observable: T, objOrArr: T2): T2 {
    // promise is not resolved yet - set $resolved to false
    Object.defineProperty(objOrArr, RESOLVED_PROP, {
        enumerable: false,
        writable: true,
        configurable: false,
        value: false,
    });
    // instantiate object or array with promise
    Object.defineProperty(objOrArr, PROMISE_PROP, {
        enumerable: false,
        writable: true,
        configurable: false,
        value: new Promise((resolve, reject) => {
            observable
                .pipe(take(SUBSCRIBE_COUNT))
                .subscribe(
                    (value) => {
                        resolve(value);
                        updatePromisedObjOrArrWithNewData(objOrArr, value);
                        objOrArr[RESOLVED_PROP] = true;
                    },
                    (error) => {
                        objOrArr[RESOLVED_PROP] = true;
                        reject(error);
                    }
                );
        })
    });
    // return the newly created object regardless of promise status
    return objOrArr;
}

/** Updates an object or array with new data - excludes $promise and $resolved props */
function updatePromisedObjOrArrWithNewData(objOrArr: any, value: any): void {
    // remove old props - excluding $promise and $resolved
    for (const key of Object.keys(objOrArr)) {
        if (key === PROMISE_PROP || key === RESOLVED_PROP) {
            continue;
        }
        delete objOrArr[key];
    }
    // update objOrArr with new data
    Object.assign(objOrArr, value);
}
