<div class="cb-margin"
     *ngIf="!(selectedMappedItem$$ | async)">
    <div class="cb-margin-bottom flex-col">
        <form #form="ngForm">
            <div class="start-center flex-row flex">
                <span class="flex"></span>
                <cb-button class="cb-margin"
                           (clicked)="createTakeoff()"
                           [disabled]="!(canCreateTakeOffTask$ | async)">
                    Create Takeoff
                </cb-button>
            </div>
        </form>
    </div>
    <div class="scroll-container">
        <div #listContainer
             class="list-container flex-wrap flex"
             *ngIf="(results$ | async) as results; else loading">
            <cb-info-message *ngIf="results != null && results.length == 0"
                             [message]="'There are no take offs'"
                             [icon]="'info_circle'"></cb-info-message>
            <div class="md-33 sm-50 xs-100 flex-25"
                 *ngFor="let result of results | cbSortByPipe: 'createdDate': 'desc'">
                <cb-takeoff-card (viewTakeOffTaskEventEmitter)="viewTakeOffTask($event)"
                                 [mappedItem]="result">
                </cb-takeoff-card>
            </div>
        </div>
        <ng-template #loading>
            <cb-loading-spinner message="Fetching results">
            </cb-loading-spinner>
        </ng-template>
    </div>
</div>
<div class="cb-margin"
     *ngIf="selectedMappedItem$$ | async">
    <cb-takeoff-task [mappedItem]="selectedMappedItem"
                     [lot]="lot$ | async"
                     (goBack)="handleGoBack()"
                     (reloadLotMappedItem)="reloadLotMappedItemOnParent()">
    </cb-takeoff-task>
</div>
