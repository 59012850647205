import {Component, Input} from '@angular/core';
import {getBaseFormComponentDirectiveProvider} from '../../base-form-component';
import {SelectComponent} from '../select.component';


interface IOption {
    id: number | string;
    name?: string;
    label?: string;
}

@Component({
    selector: 'cb-garage-select',
    templateUrl: '../select.component.html',
    styleUrls: ['../select.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(GarageSelectComponent),
    ],
})
export class GarageSelectComponent extends SelectComponent {
    @Input() public options = [
        { id: 0, label: '0' },
        { id: 1, label: '1' },
        { id: 1.5, label: '1.5' },
        { id: 2, label: '2' },
        { id: 2.5, label: '2.5' },
        { id: 3, label: '3' },
        { id: 4, label: '4' },
        { id: 5, label: '5' }
    ] as IOption[];
}
