import { Component, Input } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BusinessAccountLogicService, IBusinessAccountContactDto, IBusinessAccountMappedItem } from '@app/logic/business-accounts';
import { ContactsLogicService } from '@app/logic/contacts';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { AddContactDialogComponent } from '@app/views/contacts/add-contact-dialog/add-contact-dialog.component';
import { BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { cloneDeep, find, orderBy } from 'lodash';
import { BusinessAccountContactRolesDialogComponent } from '../business-account-contact-roles-dialog/business-account-contact-roles-dialog.component';

@Component({
    selector: 'cb-business-account-contacts',
    templateUrl: './business-account-contacts.component.html',
    styleUrls: ['./business-account-contacts.component.scss']
})
export class BusinessAccountContactsComponent {
    @Input() public account: IBusinessAccountMappedItem;

    public contacts: IBusinessAccountContactDto[];
    public cacheContacts: IBusinessAccountContactDto[];
    public isEditing: { [key: number]: boolean };

    public hasDuplicateEmail: { [key: number]: boolean } = {};

    constructor(
        public readonly permissions: PermissionsPermissions,
        protected readonly businessAccountLogicService: BusinessAccountLogicService,
        protected readonly contactsLogicService: ContactsLogicService,
        protected readonly cbDialog: CbDialogService,
        protected readonly navigationService: NavigationService,
        protected readonly toastService: ToastService,
    ) {
    }

    public ngOnInit(): void {
        this.isEditing = {};
        this.refreshData();
    }

    public onAddClicked(): void {
        const contacts = [];
        const dialog = this.cbDialog.open(AddContactDialogComponent, {
            data: {
                account: this.account,
                contacts
            },
        });
        dialog.afterClosed().subOnce((isAdding: boolean) => {
            if (isAdding && contacts?.length && contacts[0].id) {
                const found = find(this.contacts, { contactId: contacts[0].id });
                if (found) {
                    this.toastService.showToast(`${contacts[0].name} already added`);
                } else {
                    this.businessAccountLogicService.addContact(this.account.id, contacts[0].id).subOnce(() => this.refreshData());
                }
            }
        });
    }

    public onSwapClicked(contact: IBusinessAccountContactDto): void {
        const contacts = [];
        const dialog = this.cbDialog.open(AddContactDialogComponent, {
            data: { contacts },
        });
        dialog.afterClosed().subOnce((isAdding: boolean) => {
            this.isEditing[contact.id] = false;
            if (isAdding && contacts?.length && contacts[0].id) {
                const isExisiting = find(this.contacts, { contactId: contacts[0].id });
                if (isExisiting) {
                    this.toastService.showToast(`${contacts[0].name} already added.`);
                } else {
                    this.businessAccountLogicService.editContact(this.account.id, contact.id, contacts[0].id, contact.position).subOnce((result) => {
                        const found = find(this.contacts, { id: contact.id });
                        const foundInCache = find(this.cacheContacts, { id: contact.id });
                        if (found && foundInCache) {
                            Object.assign(found, result);
                            Object.assign(foundInCache, result);
                        }
                    });
                }
            }
        });
    }

    public onManageRolesClicked(contact: IBusinessAccountContactDto): void {
        const dialog = this.cbDialog.open(BusinessAccountContactRolesDialogComponent, {
            data: {
                dialogHeading: 'Manage Roles',
                account: this.account,
                contact,
            },
        });
        dialog.afterClosed().subOnce((savedContact: IBusinessAccountContactDto) => {
            const found = find(this.contacts, { id: contact.id });
            const foundInCache = find(this.cacheContacts, { id: contact.id });
            if (found && foundInCache) {
                Object.assign(found, savedContact);
                Object.assign(foundInCache, savedContact);
            }
        });
    }

    public onViewClicked(contactId: string): void {
        this.navigationService.navigate([`contacts/${contactId}/summary`]);
    }

    public onSaveClicked(contact: IBusinessAccountContactDto): void {
        contact.receivesDailyEmails = contact.receivesDailyEmails && contact.isPortalAccessEnabled;
        this.businessAccountLogicService.editContact(this.account.id, contact.id, contact.contactId, contact.position, contact.isPortalAccessEnabled, contact.receivesDailyEmails)
            .subOnce(() => {
                this.isEditing[contact.id] = false;
            });
    }

    public onCancelClicked(contact: IBusinessAccountContactDto): void {
        this.isEditing[contact.id] = false;
        const found = find(this.contacts, { contactId: contact.contactId });
        const foundInCache = find(this.cacheContacts, { contactId: contact.contactId });
        if (found && foundInCache) {
            Object.assign(found, foundInCache);
        }
    }

    public onDeleteClicked(contact: IBusinessAccountContactDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Are you sure?',
            message: `Are you sure you want to remove this contact: ${contact.name}?`,
            confirmed: (): void => {
                this.businessAccountLogicService.deleteContact(this.account.id, contact.id).subOnce(() => {
                    this.toastService.showToast(`Contact ${contact.name} has been deleted.`);
                    this.refreshData();
                });
            }
        });
    }

    public contactIsSales(contact: IBusinessAccountContactDto): boolean {
        return contact?.areas?.some(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Sales);
    }

    public contactIsOrders(contact: IBusinessAccountContactDto): boolean {
        return contact?.areas?.some(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Orders);
    }

    public contactIsAccounts(contact: IBusinessAccountContactDto): boolean {
        return contact?.areas?.some(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Accounts);
    }

    public contactIsInstaller(contact: IBusinessAccountContactDto): boolean {
        return contact?.areas?.some(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Installation);
    }

    public contactIsHealthAndSafety(contact: IBusinessAccountContactDto): boolean {
        return contact?.areas?.some(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.HealthAndSafety);
    }

    private refreshData(): void {
        this.contacts = [];
        this.cacheContacts = [];
        this.businessAccountLogicService.getAccountContacts(this.account.id).subOnce(contacts => {
            contacts = orderBy(contacts, 'name');
            this.contacts = contacts;
            this.cacheContacts = cloneDeep(contacts);
        });
    }

    public editContact(contact: IBusinessAccountContactDto): void {
        this.contactsLogicService
            .findExistingByEmailAddress(contact.email)
            .subOnce((results) => {
                const duplicates = results.filter(x => x.id !== contact.contactId);
                this.hasDuplicateEmail[contact.id] = duplicates?.length > 0;
            });
        this.isEditing[contact.id] = true;
    }
}
