import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbTableModule} from 'cb-hub-lib';
import {DocumentNotificationsDialogComponent} from './document-notifications-dialog/document-notifications-dialog.component';
import {DocumentNotificationsComponent} from './document-notifications.component';

@NgModule({
    declarations: [
        DocumentNotificationsComponent,
        DocumentNotificationsDialogComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        CbTableModule,
        CbFormsModule,
        CbDialogModule,
        FormsModule,
    ],
    exports: [
        DocumentNotificationsComponent
    ]
})
export class DocumentNotificationsModule { }
