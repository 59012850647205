import {Component, Input} from '@angular/core';
import {isNullOrWhiteSpace} from '../../utils/string.util';

@Component({
    selector: 'cb-info-popup',
    templateUrl: './info-popup.component.html',
    styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent {

    @Input() public readonly label: string;
    @Input() public readonly tooltip: string;
    @Input() public readonly icon: string = 'info';

    constructor() {
    }

    public isValidToolTip(): boolean {
        return this.tooltip && this.tooltip != null && this.tooltip !== 'null' && !isNullOrWhiteSpace(this.tooltip);
    }

}
