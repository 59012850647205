import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Output, EventEmitter, Input, forwardRef, Provider, Directive } from '@angular/core';

@Directive()
export abstract class BaseFormComponentDirective implements ControlValueAccessor {
    public internalNgModel: any;
    public internalName: string;
    @Input() public softRequired: boolean;
    @Input() public maskType: string;
    @Input() public required = false;
    @Input() public disabled = false;
    @Input() public readonly = false;
    @Input() public readonly ignoreDirty: boolean = false;
    @Output() public readonly change = new EventEmitter();
    public readonly valueChange = new EventEmitter<{ value: any; modelValue: any }>();

    protected _label: string;
    public get label(): string {
        return this._label;
    }
    @Input() public set label(value: string) {
        this._label = value;
    }

    private _name: string;
    public get name(): string {
        return this._name;
    }
    @Input() public set name(val: string) {
        this._name = val;
        this.internalName = `${val}_Internal`;
    }

    private _value: any;
    public get value(): any { return this._value; }
    public set value(v: any) {
        this.setValue(v);
    }

    /** setter called for set value() */
    protected setValue(v: any): void {
        const set = this.valueSetter(v);
        this._value = set.value;
        this.updateNgModel(set.modelValue);
        this.valueChange.next(set);
    }

    public writeValue(value: any): void {
        // do not trigger updateNgModel here - because this method has been triggered as a result of the ngModel being updated!
        this._value = this.valueSetter(value).value;
    }

    public valueSetter(v: any): { value: any; modelValue: any } {
        return {
            value: v,
            modelValue: v
        };
    }

    public updateNgModel(value: any): any {
        if (this.internalNgModel !== value) {
            this.internalNgModel = value;
            this.onChange(this.internalNgModel);
            this.emitChange();
        }
        this.onTouched();
    }

    public onChange = (_) => { };
    public onTouched = () => { };
    public registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
    public registerOnTouched(fn: () => void): void { this.onTouched = fn; }

    public emitChange(): void {
        this.change.next(this.internalNgModel);
    }
}

export function getBaseFormComponentDirectiveProvider(component: any): Provider[] {
    return [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => component),
        },
        {
            provide: BaseFormComponentDirective,
            useExisting: forwardRef(() => component),
        }
    ];
}

export function getBaseFormComponentDirectiveWithoutValueAccessorProvider(component: any): Provider[] {
    return [
        {
            provide: BaseFormComponentDirective,
            useExisting: forwardRef(() => component),
        }
    ];
}
