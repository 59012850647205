import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { IProductCategoriesLogicService } from './interfaces/i.product-categories.logic.service';
import { IProductCategoryDto, ICategoryAttributeDto } from './interfaces/i.product-category.dto';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { IProductCategoryMappedItem } from './interfaces/i.product-category.mapped';
import { ProductCategoryMappedItem } from './product-category.mapped';

export interface CategorySearchParams {
    query: string;
    currentPage: number;
    includeDeleted: boolean;
}

@Injectable()
export class CategoryLogicService
    extends BaseLogicService<IProductCategoryDto, IProductCategoryMappedItem>
    implements IProductCategoriesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('products/categories', ProductCategoryMappedItem);
    }

    public getCategoriesByParentId(includeDeleted: boolean, parentId: number): Observable<IProductCategoryDto[]> {
        return this.$http
            .get<Array<IProductCategoryDto>>(this.$baseUri, { includeDeleted, parentId });
    }

    public $getSearchList(params: CategorySearchParams): Observable<ISearchResult<IProductCategoryDto[]>> {
        Object.keys(params).forEach(key => {
            if (params[key] == null || params[key] === '' && key !== 'query') {
                delete params[key];
            }
        });

        return this.$http
            .get<ISearchResult<IProductCategoryDto[]>>(`${this.$baseUri}/search`, params);
    }

    public getAttributeValues(id: number, attribId: number): Observable<any[]> {
        return this.$http
            .get<Array<IProductCategoryDto>>(`${this.$baseUri}/${id}/attributes/${attribId}/options`);
    }

    public addAttribute(categoryId: number, attribute: ICategoryAttributeDto): Observable<any> {
        return this.$http.post<IProductCategoryDto>(`${this.$baseUri}/${categoryId}/attributes`, attribute);
    }

    public updateAttribute(categoryId: number, attributeId: number, attribute: ICategoryAttributeDto): Observable<any> {
        return this.$http.post<IProductCategoryDto>(`${this.$baseUri}/${categoryId}/attributes/${attributeId}`, attribute);
    }
}
