import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DialogLoadingSpinnerComponent } from 'cb-hub-lib';
import { DocumentService } from '../../services/document.service';
import { DocumentsLogicService } from '@app/logic/documents/documents.logic.service';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { IDocumentGroupFrontEndDto, IDocumentGroupTypeDto } from '@app/logic/documents/interfaces/i.document-group.dto';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    DOCUMENT_APPROVAL_TYPE_ENUM,
    DOCUMENT_REVIEW_STATUS_ENUM,
    IDocumentReviewDto,
    SystemAreaEnumId,
    USER_TAG_CONSTANTS_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { CurrentUserService } from '@app/core/authentication/current.user';

@Component({
    selector: 'cb-document-upload-dialog',
    templateUrl: './document-upload-dialog.html',
    styleUrls: ['./document-upload-dialog.scss']
})
export class DocumentUploadDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    @Output() public pushAndRefreshDocumentsListEmitter: EventEmitter<IDocumentEntityMappedItem> = new EventEmitter();

    public availableForUploadDocumentGroups: IDocumentGroupFrontEndDto[];
    public availableForUploadDocumentTypes: IDocumentGroupTypeDto[];

    public showTypeSelector = false;
    public showDragDrop = false;
    public showDocumentReviewSection = true;
    public DOCUMENT_REVIEW_STATUS_ENUM = DOCUMENT_REVIEW_STATUS_ENUM;
    public documentReviewStatuses = DOCUMENT_REVIEW_STATUS_ENUM
        .toLookup()
        .map(status => {
            if (status.id === DOCUMENT_REVIEW_STATUS_ENUM.None) {
                status.label = 'Upload for Review';
            }
            return status;
        });
    public USER_TAG_CONSTANTS_CONST = USER_TAG_CONSTANTS_CONST;
    public documentReviewDto: IDocumentReviewDto;
    public selectedGroup: IDocumentGroupFrontEndDto;
    public selectedType: IDocumentGroupTypeDto;
    public tempDocumentReviewDto = { status: DOCUMENT_REVIEW_STATUS_ENUM.Approved } as IDocumentReviewDto;

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly documentsLogicService: DocumentsLogicService,
        public readonly dialogRef: MatDialogRef<DocumentUploadDialogComponent>,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
        private readonly documentService: DocumentService,
        @Inject(MAT_DIALOG_DATA)
        public data: IData

    ) { }

    public ngOnInit(): void {
        if (!this.data.isDocumentReupload) {
            this.filterAvailableGroups();
            if (this.availableForUploadDocumentGroups?.length === 1) {
                this.selectedGroup = this.availableForUploadDocumentGroups[0];
                this.onGroupSelection(this.selectedGroup);
            }

            if (this.availableForUploadDocumentTypes?.length === 1) {
                this.selectedType = this.availableForUploadDocumentTypes[0];
                this.onTypeSelection(this.selectedType);
            }

        } else {
            this.showDragDrop = true;
            this.selectedGroup = this.data?.documentGroups?.find(dg => dg.id === this.data?.documentEntityMappedItem?.documentGroup?.id);
            this.selectedType = this.selectedGroup?.types?.find(type => type.documentTypeId === this.data?.documentEntityMappedItem?.documentType?.id);
        }

        if (this.data.selectedDocumentGroupId) {
            this.selectedGroup = this.availableForUploadDocumentGroups
                .find(dg => dg.id === this.data?.selectedDocumentGroupId);
            this.onGroupSelection(this.selectedGroup);
        }
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public displayDocumentReviewSection = (): boolean => {
        const allowReviewAtUpload = this.data?.allowReviewAtUpload
            && this.currentUser.hasTag(USER_TAG_CONSTANTS_CONST.COMPLIANCE_DOCUMENT_REVIEWER)
            && this.selectedType?.approvalTags?.some(tag => this.currentUser.hasTag(tag.key));

        if (this.data?.documentEntityMappedItem?.id < 1) {
            return allowReviewAtUpload
                && this.selectedType?.approvalType === DOCUMENT_APPROVAL_TYPE_ENUM.ApprovalRequired;
        }

        // Some older document types were configured with different document approval types for the same document type
        // so have to check the document entity approval type if the document entity is pre-existing
        return allowReviewAtUpload
            && this.data?.documentEntityMappedItem?.documentApprovalType === DOCUMENT_APPROVAL_TYPE_ENUM.ApprovalRequired;
    };

    public onDocumentUpdated(): void {
        this.data?.documentEntityMappedItem.$recomputeProps();
    }

    public onSubmit(): void {
        const message = 'Uploading Document...';
        const loadingDialog = this.dialog.open(
            DialogLoadingSpinnerComponent,
            {
                data: { message },
                width: '400px',
                height: '100px',
                panelClass: 'cb-dialog-translucent-bg'
            }
        );

        if (this.displayDocumentReviewSection()) {
            this.documentReviewDto = this.tempDocumentReviewDto;
        }

        this.data.documentEntityMappedItem
            .postDocument(this.documentReviewDto)
            .subOnce(response => {

                // This will push the currently uploaded document to the available list and refresh the component locally
                const toPush = this.documentsLogicService.createMappedDocumentEntityItem(this.data.$baseUri, this.data.$documentEntityId, response);
                this.pushAndRefreshDocumentsListEmitter.emit(toPush);

                this.dialogRef.close(response);
                loadingDialog.close();
                this.openSnackBar('Document Uploaded', 'OK');

                // Notifies subscribers that a document has been uploaded
                this.documentService.documentUploaded.next(this.data.documentEntityMappedItem);
            });
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['snackbar']
        });
    }

    public filterAvailableGroups(): void {
        this.availableForUploadDocumentGroups =
            this.documentsLogicService.filterAvailableGroups(this.data.documentGroups, this.data.systemArea);
    }

    public onGroupSelection(value: IDocumentGroupFrontEndDto): void {
        this.data.documentEntityMappedItem.documentGroup = value;
        this.availableForUploadDocumentTypes = value.tempFilteredTypes;
        this.showTypeSelector = true;
    }

    public onTypeSelection(value: IDocumentGroupTypeDto): void {
        this.data.documentEntityMappedItem.documentType = value.documentType;
        this.showDragDrop = true;
    }
}


export interface IData {
    documentEntityMappedItem: IDocumentEntityMappedItem;
    documentGroups: IDocumentGroupFrontEndDto[];
    isDocumentReupload: boolean;
    $baseUri: string;
    $documentEntityId: string | number;
    systemArea: SystemAreaEnumId;
    allowReviewAtUpload: boolean;
    selectedDocumentGroupId: number;
}
