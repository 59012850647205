import { Component } from '@angular/core';
import { PricingComplexityLogicService } from '@app/logic/pricing-complexity/pricing-complexity.logic.service';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { IPricingComplexityDto } from '@app/logic/pricing-complexity';
import { PricingComplexityMappedItem } from '@app/logic/pricing-complexity/pricing-complexity.mapped';
import { PricingComplexityDialogComponent } from './pricing-complexity-dialog/pricing-complexity-dialog.component';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-pricing-complexity',
    templateUrl: './pricing-complexity.component.html',
    styleUrls: ['./pricing-complexity.component.scss'],
    providers: [PricingComplexityLogicService, UsersLogicService]
})
export class PricingComplexityComponent extends BaseSimpleListViewDirective<IPricingComplexityDto, PricingComplexityLogicService> {
    public readonly displayedColumns: string[] = [
        'complexity',
        'targetWorkHours',
        'targetCompletionHours',
        'isActive',
        'actions'
    ];

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly pricingComplexityLogicService: PricingComplexityLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
    ) {
        super(
            cbDialog,
            pricingComplexityLogicService,
            PricingComplexityDialogComponent,
            'Pricing Complexity',
            PricingComplexityMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<IPricingComplexityDto>): Array<IPricingComplexityDto> {
        return searchResults.sort((a, b) => a.complexity.localeCompare(b.complexity));
    }
}



