import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PermissionsPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {UsersLogicService} from '@app/logic/users';
import {IUserMappedItem} from '@app/logic/users/interfaces/i.user.mapped';
import {NonDialogFormViewDirective} from '@app/shared/base-views/non-dialog-form-view.directive';
import {FormMode} from '@app/shared/enums/form';
import {BehaviorSubject, combineLatest, of, skipWhile, Subscription, switchMap} from 'rxjs';
import {PermissionsComponent} from '../permissions/permissions.component';
import {SystemAccessComponent} from '../system-access/system-access.component';
import {UserDetailsComponent} from '../user-details/user-details.component';
import {UserSideBarCardComponent} from '../user-side-bar-card/user-side-bar-card.component';

@Component({
    selector: 'cb-user-area',
    templateUrl: './user-area.component.html',
    styleUrls: ['./user-area.component.scss']
})
export class UserAreaComponent implements AfterViewInit, OnDestroy {
    @ViewChild(UserDetailsComponent, {}) public userDetailsComponent: UserDetailsComponent;
    @ViewChild(SystemAccessComponent, {}) public systemAccessComponent: SystemAccessComponent;
    @ViewChild(PermissionsComponent, {}) public permissionsComponent: PermissionsComponent;
    @ViewChild(UserSideBarCardComponent, {}) public userSideBarCardComponent: UserSideBarCardComponent;

    public collapsed$ = new BehaviorSubject<boolean>(false);
    public readonly USER_ROUTE = 'users/:id/:cbTabRouteId';
    public selectedTabIndex: number;
    public isViewUser = false;
    public activeComponent$ = new BehaviorSubject<NonDialogFormViewDirective<any, any, any>>(null);
    public mappedItem$ = new BehaviorSubject<IUserMappedItem>(null);
    public userId$ = new BehaviorSubject<string>(null);

    public set mappedItem(mappedItem: IUserMappedItem) {
        this.mappedItem$.next(mappedItem);
        this.userId$.next(mappedItem.$id as string);
    }

    public get clone(): IUserMappedItem {
        return this.mappedItem$?.value?.$clone();
    }

    private _subscriptions = new Subscription();

    constructor(
        public readonly permissionPermissions: PermissionsPermissions,
        public readonly logicService: UsersLogicService,
        private readonly navigationService: NavigationService,
        private route: ActivatedRoute,
    ) {
    }

    public ngAfterViewInit(): void {
        this._handleParams();
        this._setFormMode();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public onTabChanged(selectedTabIndex: number): void {
        this.selectedTabIndex = selectedTabIndex;

        switch (selectedTabIndex) {
            case 0:
                this.userSideBarCardComponent.activeComponent = this.userDetailsComponent;
                this.activeComponent$.next(this.userDetailsComponent);
                break;
            case 1:
                this.userSideBarCardComponent.activeComponent = this.systemAccessComponent;
                this.activeComponent$.next(this.systemAccessComponent);
                break;
            case 2:
                this.userSideBarCardComponent.activeComponent = this.permissionsComponent;
                this.activeComponent$.next(this.permissionsComponent);
                break;
            default:
                this.userSideBarCardComponent.activeComponent = this.userDetailsComponent;
                this.activeComponent$.next(this.userDetailsComponent);
                break;
        }
    }

    private _setFormMode(): void {
        this._subscriptions.add(
            combineLatest([this.activeComponent$, this.mappedItem$]).pipe(
                skipWhile((res) => res[0] === null || res[1] === null))
                .subscribe((result) => {
                    const mappedItem = result[1];
                    if (!mappedItem?.id && !this.isViewUser) {
                        this.activeComponent$.value.formMode$$ = FormMode.Add;
                        this.activeComponent$.value.formMode = FormMode.Add;
                    }
                }));
    }

    private _handleParams(): void {
        if (this.route.snapshot?.params?.id && this.route.snapshot?.params?.id !== 'details') {
            this._handleUrlParams();
        } else {
            this._handleNavigationServiceParams();
        }
    }

    private _handleNavigationServiceParams(): void {
        const navigationServiceParams = this.navigationService.getQueryParams<{ mappedItem?: IUserMappedItem }>();
        if (navigationServiceParams?.mappedItem) {
            this.mappedItem = navigationServiceParams.mappedItem;
        } else {
            // User has refreshed while in create new user mode
            this.mappedItem = this.logicService.$createMappedItem();
        }
        if (this.userSideBarCardComponent) {
            this.userSideBarCardComponent.activeComponent = this.userDetailsComponent;
        }
        this.activeComponent$.next(this.userDetailsComponent);
    }

    private _handleUrlParams(): void {
        this.route.params.pipe(
            switchMap((params: { id: string }) => {
                if (params.id) {
                    return this.logicService.$getMappedItem(params.id);
                } else {
                    return of(undefined);
                }
            })
        ).subOnce((mappedItem) => {
            if (mappedItem) {
                this.isViewUser = true;
                this.mappedItem = mappedItem;
            } else {
                this.mappedItem = this.logicService.$createMappedItem();
            }
        });

        this.route.url.subOnce(url => {
            if (url[2]?.path) {
                switch (url[2].path) {
                    case 'details':
                        this.activeComponent$.next(this.userDetailsComponent);
                        this.selectedTabIndex = 0;
                        break;
                    case 'system-access':
                        this.activeComponent$.next(this.systemAccessComponent);
                        this.selectedTabIndex = 1;
                        break;
                    case 'permissions':
                        this.activeComponent$.next(this.permissionsComponent);
                        this.selectedTabIndex = 2;
                        break;
                    default:
                        this.activeComponent$.next(this.userDetailsComponent);
                        this.selectedTabIndex = 0;
                        break;
                }
            } else if (url[1]?.path) {
                switch (url[1].path) {
                    case 'details':
                        this.activeComponent$.next(this.userDetailsComponent);
                        this.selectedTabIndex = 0;
                        break;
                    case 'system-access':
                        this.activeComponent$.next(this.systemAccessComponent);
                        this.selectedTabIndex = 1;
                        break;
                    case 'permissions':
                        this.activeComponent$.next(this.permissionsComponent);
                        this.selectedTabIndex = 2;
                        break;
                    default:
                        this.activeComponent$.next(this.userDetailsComponent);
                        this.selectedTabIndex = 0;
                        break;
                }
            } else {
                this.activeComponent$.next(this.userDetailsComponent);
                this.selectedTabIndex = 0;
            }
        });
    }
}
