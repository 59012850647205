import {
    IDesignConceptDto,
    ITakeOffDtoSummary,
    ITakeOffTaskDetailsDto,
    IWorkingDrawingDto,
    TAKE_OFF_STATUS_ENUM,
    TAKE_OFF_TASK_STATUS_ENUM,
    TakeOffTaskStatusEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import {Observable, tap} from 'rxjs';
import {BaseMappedItem} from '../base/base-mapped-item';
import {DtoProp} from '../base/dto-prop.decorator';
import {ITakeoffTaskMappedItem} from './interfaces/i.takeoff-task-mapped';
import {ITakeoffTasksLogicService} from './interfaces/i.takeoff-tasks.logic.service';

export class TakeoffTaskMappedItem
    extends BaseMappedItem<ITakeOffTaskDetailsDto,
    ITakeoffTaskMappedItem, ITakeoffTasksLogicService>
    implements ITakeoffTaskMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public lotId: number;
    @DtoProp public designConceptId: number;
    @DtoProp public workingDrawingId: number;
    @DtoProp public label: string;
    // eslint-disable-next-line id-blacklist
    @DtoProp public number: number;
    @DtoProp public statusId: TakeOffTaskStatusEnumId;
    @DtoProp public statusLabel: string;
    @DtoProp public pricingComplexityId: number;
    @DtoProp public pricingComplexityLabel: string;
    @DtoProp public assignedToUserId: string;
    @DtoProp public assignedToUserName: string;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public targetWorkHours: number;
    @DtoProp public targetCompletionHours: number;
    @DtoProp public takeOffs: ITakeOffDtoSummary[];
    @DtoProp public dueBy: string;
    @DtoProp public elapsedTime: string;
    @DtoProp public elapsedTimeAt: string;
    @DtoProp public designConcept: IDesignConceptDto;
    @DtoProp public workingDrawing: IWorkingDrawingDto;
    @DtoProp public completedDate: string;
    @DtoProp public completedByName: string;


    public $designConceptId: number;
    public $workingDrawingId: number;

    constructor(
        sourceData: ITakeOffTaskDetailsDto,
        logicService: ITakeoffTasksLogicService
    ) {
        super(sourceData, logicService, TakeoffTaskMappedItem);
    }

    public get $canEditTakeOffTask(): boolean {
        return this.statusId !== TAKE_OFF_TASK_STATUS_ENUM.Completed
            && this.statusId !== TAKE_OFF_TASK_STATUS_ENUM.Cancelled
            && this.statusId !== TAKE_OFF_TASK_STATUS_ENUM.Current;
    }

    public get $canImportTakeOff(): boolean {
        return this.takeOffs !== undefined
            && this.statusId === TAKE_OFF_TASK_STATUS_ENUM.InProgress
            && !this.takeOffs.some(takeoff =>
                takeoff.status !== TAKE_OFF_STATUS_ENUM.Abandoned
                && takeoff.status !== TAKE_OFF_STATUS_ENUM.Rejected
            );
    }

    public updateStatus(status: TakeOffTaskStatusEnumId): Observable<ITakeOffTaskDetailsDto> {
        return this.$logicService.updateStatus(this.id, status)
            .pipe(
                tap(result => result && this.$updateThisAndOriginal(result))
            );
    }


}

