import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IPurchaseOrderMappedItem, PurchaseOrdersLogicService } from '@app/logic/purchase-orders';
import { IPurchaseOrderSearch } from '@app/core/services/user-cache/user-cache-areas';
import { cleanObjectDeep, USER_CACHE_AREA } from 'cb-hub-lib';
import {
    MANUAL_PURCHASE_ORDER_ORIGIN_ENUM,
    PURCHASE_ORDER_STATUS_ENUM,
    COST_NATURE_ENUM,
    USER_TAG_CONSTANTS_CONST,
    INVOICE_STATUS_ENUM,
    PURCHASE_ORDER_APPROVAL_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseSearchViewDirective } from '../../../../shared/base-views/base-search-view.directive';
import { IUserDto } from '@app/logic/users';
import { NavigationExtras } from '@angular/router';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { PermissionsPermissions, AbmIntegrationPermissions } from '../../../../core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { UsersLogicService } from '../../../../logic/users/users.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-purchase-orders',
    templateUrl: './purchase-orders.component.html',
    styleUrls: ['./purchase-orders.component.scss'],
    providers: [PurchaseOrdersLogicService, UsersLogicService]
})
export class PurchaseOrdersComponent extends BaseSearchViewDirective<IPurchaseOrderSearch> implements AfterViewInit, OnInit, OnDestroy {
    public searchResults: IPurchaseOrderMappedItem[] = [];
    public readonly statusList = PURCHASE_ORDER_STATUS_ENUM.toSelectList();
    public readonly invoiceStatusList = INVOICE_STATUS_ENUM.toSelectList().map((x) => ({
        checked: false,
        disabled: false,
        // Have to map 0 and 1 to false and true because endpoint needs bool values
        id: x.id >= 0 ? x.id === 1 : null,
        label: x.label,
    }));

    public constructionManagersList: IUserDto[];
    public constructionManager = new UntypedFormControl();

    public readonly costNatureList = COST_NATURE_ENUM.toSelectList({
        transform: (list) => {
            list.unshift({ id: 0, label: 'None', disabled: false, checked: false });
            return list;
        }
    });

    @ViewChild('infiniteScrollContainer', { static: true }) public infiniteScrollContainer: { nativeElement: { scrollHeight: number } };

    public readonly displayedColumns: string[] = [
        'jobNumber',
        'orderNumber',
        'statusLabel',
        'costNature',
        'memoStatus',
        'invoiceStatus',
        'supplierName',
        'costCode',
        'activityName',
        'activityStartDate',
        'activityEndDate',
        'amount',
        'constructionManagerName',
        'actions'
    ];
    public form: UntypedFormGroup;

    public readonly PURCHASE_ORDER_STATUS_ENUM = PURCHASE_ORDER_STATUS_ENUM;
    public readonly COST_NATURE_ENUM = COST_NATURE_ENUM;
    public canSeeAbmReconfirmButton: boolean;

    public query: string;

    constructor(
        public readonly navigationService: NavigationService,
        private readonly usersLogicService: UsersLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly cbDialog: CbDialogService,
        private readonly purchaseOrdersLogicService: PurchaseOrdersLogicService,
        public readonly toastService: ToastService,
        public readonly userCacheService: UserCacheService,
        private readonly abmIntegrationPermissions: AbmIntegrationPermissions
    ) {
        super(
            purchaseOrdersLogicService,
            toastService,
            userCacheService,
            USER_CACHE_AREA.PurchaseOrderSearch
        );

        this.form = new UntypedFormGroup({
            statuses: new UntypedFormControl(this.statusList)
        });
        this.query = '';
    }

    public ngOnInit(): void {
        this.loadConstructionManagers();
    }

    public ngAfterViewInit(): void {
        this.loadSearchParams();
        this.canSeeAbmReconfirmButton = this.abmIntegrationPermissions.canResendConfirmToAbm();
    }

    public downloadDisabled(purchaseOrder: IPurchaseOrderMappedItem): boolean {
        return purchaseOrder && purchaseOrder.statusId === PURCHASE_ORDER_STATUS_ENUM.Draft;
    }

    public downloadPO(purchaseOrder: IPurchaseOrderMappedItem): void {
        if (this.downloadDisabled(purchaseOrder)) {
            return;
        }

        if (purchaseOrder.ssrId != null) {
            this.purchaseOrdersLogicService.downloadSsrPO(purchaseOrder.ssrId).subOnce({ error: this.handleSearchError });
        } else {
            this.purchaseOrdersLogicService.downloadManualPO(purchaseOrder.id).subOnce({ error: this.handleSearchError });
        }
    }



    public canResendAbmConfirm(purchaseOrder: IPurchaseOrderMappedItem): boolean {
        // Purchase order is defined
        return purchaseOrder &&
            // We're not waiting on any approval
            purchaseOrder.approvalStatusId !== PURCHASE_ORDER_APPROVAL_STATUS_ENUM.Pending &&
            purchaseOrder.approvalStatusId !== PURCHASE_ORDER_APPROVAL_STATUS_ENUM.Rejected &&
            // PO is not none, draft or abandoned
            (purchaseOrder.statusId === PURCHASE_ORDER_STATUS_ENUM.Confirmed || purchaseOrder.statusId === PURCHASE_ORDER_STATUS_ENUM.Completed) &&
            // There is no previously matched invoice/payments
            !purchaseOrder.hasMatchedInvoices && !purchaseOrder.hasPayments;
    }

    public resendAbmConfirmMessage(purchaseOrder: IPurchaseOrderMappedItem): void {
        if (!this.canResendAbmConfirm(purchaseOrder)) {
            return;
        }

        this.cbDialog.confirm({
            dialogHeading: 'Confirm Resend PO to ABM',
            message: `Resend PO# ${purchaseOrder.orderNumber} for '${purchaseOrder.supplierName}' to ABM?`,
            confirmed: () => {
                this.cbDialog.block('Sending confirm message to ABM...');

                this.purchaseOrdersLogicService
                    .resendAbmConfirmMessage(purchaseOrder.id)
                    .subOnce(
                        ({
                            next: result => {
                                if (result) {
                                    this.toastService.showToast('Confirm message successfully sent to ABM.');
                                } else {
                                    this.toastService.showToast('Server error. Confirm message failed to send to ABM.');
                                }
                            },
                            error: () => {
                                this.cbDialog.closeAll();
                            },
                            complete: () => {
                                this.cbDialog.closeAll();
                            },
                        })
                    );
            }
        });
    }

    public goToBuildProgramme(
        lotId: number,
        buildProgrammeActivityId: number,
        item: IPurchaseOrderMappedItem,
        event: MouseEvent
    ): void {
        if (!item.originId || item.originId === MANUAL_PURCHASE_ORDER_ORIGIN_ENUM.LotBuild) {
            this.navigationService.navigate([`lots/${lotId}/build/programme`], event, { queryParams: { buildProgrammeActivityId } } as NavigationExtras);
        } else if (item.originId === MANUAL_PURCHASE_ORDER_ORIGIN_ENUM.LotDesign) {
            this.navigationService.navigate([`lots/${lotId}/design/manualorders`], event, { queryParams: { paramEntityId: item.orderNumber } } as NavigationExtras);
        }
    }

    public loadConstructionManagers(): void {
        this.usersLogicService.getListByTag({ tag: USER_TAG_CONSTANTS_CONST.CONSTRUCTION_MANAGER }).subOnce(result => {
            this.constructionManagersList = result;
        });
    }

    public selectedStatusesChanged(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.data.status = selectMultipleElement.value;
    }

    public selectedInvoiceStatusChanged(selectSingleElement: UntypedFormControl): void {
        this.userCacheItem.data.invoiceReceived = selectSingleElement.value;
    }

    public selectedCostNatureChanged(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.data.costNature = selectMultipleElement.value;
    }

    public selectedConstructionManagerChanged(selectSingleElement: UntypedFormControl): void {
        this.userCacheItem.data.constructionManagerId = selectSingleElement.value;
    }

    public changeQuery(query: string): void {
        this.query = query;
    }

    protected getSearchParams(): IPurchaseOrderSearch {
        this.userCacheItem.data.query = this.query;
        const data = this.userCacheItem.copyData();
        return {
            query: data.query,
            constructionManagerId: data.constructionManagerId,
            status: data.status,
            costNature: data.costNature,
            invoiceReceived: data.invoiceReceived
        };
    }


    public exportToCSV(): void {
        const params = cleanObjectDeep(this.getSearchParams());
        this.purchaseOrdersLogicService.exportPOSearchToCSV(params).subOnce();
    }
}
