import { SystemAreaEnumId, SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

export function getBaseTasksLocation(
    systemArea: SystemAreaEnumId,
    entityId = '{{entityId}}',
    parentEntityId = '{{parentEntityId}}',
    childEntityId = undefined
): string {
    switch (systemArea) {
        case SYSTEM_AREA_ENUM.Lot:
            return `/lots/${entityId}/tasks`;
        case SYSTEM_AREA_ENUM.Lead:
            return `/leads/${entityId}/tasks`;
        case SYSTEM_AREA_ENUM.Project:
            return `/projects/edit/${entityId}/tasks`;
        case SYSTEM_AREA_ENUM.ProjectReleaseStage:
            return `/projects/${parentEntityId}/releases/${entityId}/tasks`;
        case SYSTEM_AREA_ENUM.ClientSale:
            return `/clientsales/view/${entityId}/tasks`;
        case SYSTEM_AREA_ENUM.CouncilRfi:
            return `/lots/${parentEntityId}/design/council-rfis/${childEntityId}`;
        default:
            return '/';
    }
}
