import {Injectable, Injector} from '@angular/core';
import {ILotPriceRevisionSearch, ITeamPricingRevisionSearch} from '@app/core/services/user-cache/user-cache-areas';
import {cloneDeepSafe} from '@app/shared/utils/clone-object.util';
import {
    IPricingRevisionDocumentDto,
    IPricingRevisionDto,
    IPricingRevisionGrossMarginDto,
    IPricingRevisionMarginHistoryDto,
    IPricingRevisionPriceHistoryDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import {cleanObjectDeep} from 'cb-hub-lib';
import * as _ from 'lodash';
import {map, Observable} from 'rxjs';
import {HttpWrapperService} from '../../core/services/http-wrapper/http-wrapper.service';
import {BaseLogicServiceWithSearch} from '../base/base-logic-service-with-search.component';
import {IPricingRevisionMappedItem} from './interfaces/i.pricing-revision.mapped';
import {IPricingRevisionsLogicService} from './interfaces/i.pricing-revisions.logic.service';
import {PricingRevisionMappedItem} from './pricing-revision.mapped';

@Injectable()
export class PricingRevisionsLogicService
    extends BaseLogicServiceWithSearch<
    IPricingRevisionDto,
    IPricingRevisionMappedItem,
    ITeamPricingRevisionSearch,
    IPricingRevisionDocumentDto>
    implements IPricingRevisionsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('pricingrevisions', PricingRevisionMappedItem);
    }

    public getElapsedTime(pricingRevisionId: number): Observable<number> {
        return this.$http.get<number>(`${this.$baseUri}/${pricingRevisionId}/elapsedtime`);
    }

    public cancel(id: number): Observable<IPricingRevisionDto> {
        return this.$http.delete(`/${this.$baseUri}/${id}`);
    }

    public submitForReview(pricingRevisionId: number, dto: IPricingRevisionDto): Observable<IPricingRevisionDto> {
        return this.$http
            .post<IPricingRevisionDto>(`/${this.$baseUri}/${pricingRevisionId}/submitForReview`, dto);
    }

    public reprice(pricingRevisionId: number, dto: IPricingRevisionDto): Observable<IPricingRevisionDto> {
        return this.$http
            .post<IPricingRevisionDto>(`/${this.$baseUri}/${pricingRevisionId}/reprice`, dto);
    }

    public finalise(pricingRevisionId: number, dto: IPricingRevisionDto): Observable<IPricingRevisionDto> {
        return this.$http
            .post<IPricingRevisionDto>(`/${this.$baseUri}/${pricingRevisionId}/finalize`, dto);
    }

    public getGrossMargin(pricingRevisionId: number): Observable<number> {
        return this.$http.get<number>(`/${this.$baseUri}/${pricingRevisionId}/grossmargin`);
    }

    public updateGrossMargin(pricingRevisionId: number, dto: IPricingRevisionGrossMarginDto, isAuditMarginHistory: boolean): Observable<boolean> {
        return this.$http.post<boolean>(`/${this.$baseUri}/${pricingRevisionId}/grossmargin/${isAuditMarginHistory}`, dto);
    }

    public getMappedLotPricingRevisionSearchList(params: ILotPriceRevisionSearch): Observable<IPricingRevisionMappedItem[]> {
        return this.$http.get<any>(`${this.$baseUri}/search`, cleanObjectDeep(cloneDeepSafe(params)))
            .pipe(map(results => {
                let res = [];
                if (results.items) {
                    res = results.items.map(result => this.$createMappedItem(result));
                } else {
                    res = results.map(result => this.$createMappedItem(result));
                }
                res = _.orderBy(res, (result) => {
                    return result.name;
                }, 'desc');

                return res;
            }));
    }

    public getRevisionPriceHistory(pricingRevisionId: number): Observable<IPricingRevisionPriceHistoryDto[]> {
        return this.$http.get<IPricingRevisionPriceHistoryDto[]>(`${this.$baseUri}/${pricingRevisionId}/pricinghistory`);
    }

    public getRevisionPriceMarginHistory(pricingRevisionId: number): Observable<IPricingRevisionMarginHistoryDto[]> {
        return this.$http.get<IPricingRevisionMarginHistoryDto[]>(`${this.$baseUri}/${pricingRevisionId}/marginhistory`);
    }
}
