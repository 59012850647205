<cb-stepper class="cb-margin flex-row"
            *ngIf="clientSale">
    <cb-step label="Pending Contract"
             [active]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending"
             [complete]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.AwaitingAgreement
             || clientSale.statusId === CLIENT_SALE_STATUS_ENUM.Conditional
             || clientSale.statusId === CLIENT_SALE_STATUS_ENUM.UnConditional
             || clientSale.statusId === CLIENT_SALE_STATUS_ENUM.Complete">
    </cb-step>
    <cb-step label="Awaiting Agreement"
             *ngIf="clientSale?.isDesignAndBuild"
             [active]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.AwaitingAgreement"
             [complete]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.Conditional
             || clientSale.statusId === CLIENT_SALE_STATUS_ENUM.UnConditional
             || clientSale.statusId === CLIENT_SALE_STATUS_ENUM.Complete"></cb-step>
    <cb-step label="Unconditional"
             [active]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.Conditional"
             [complete]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.UnConditional || clientSale.statusId === CLIENT_SALE_STATUS_ENUM.Complete">
    </cb-step>
    <cb-step label="Complete"
             [active]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.UnConditional"
             [complete]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.Complete"></cb-step>
    <cb-step label="Stalled Sale"
             [warning]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.StalledSale"
             [active]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.StalledSale"
             *ngIf="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.StalledSale"></cb-step>
    <cb-step label="Lost"
             [error]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.SaleLost"
             *ngIf="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.SaleLost"
             [active]="clientSale.statusId === CLIENT_SALE_STATUS_ENUM.SaleLost"></cb-step>
</cb-stepper>
