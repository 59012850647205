import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { find } from 'lodash';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IBuildActivitiesLogicService } from './interfaces/i.build-activities.logic.service';
import { IBuildActivityScheduleDto } from './interfaces/i.build-activity-schedule.dto';
import { IBuildActivityDto } from './interfaces/i.build-activity.dto';
import { IBuildActivityMappedItem } from './interfaces/i.build-activity.mapped';

export class BuildActivityMappedItem
    extends BaseMappedItem<IBuildActivityDto, IBuildActivityMappedItem, IBuildActivitiesLogicService>
    implements IBuildActivityMappedItem {

    @DtoProp public activityDurationDays: number;
    @DtoProp public requiredComplianceId: number;
    @DtoProp public shouldExcludeRequiredComplianceForDuplicates: boolean;
    @DtoProp public taskAnalysisFormRequiredLevel: number;
    @DtoProp public labourRates: any;
    @DtoProp public labourRatesList: any;
    @DtoProp public version: number;
    @DtoProp public buildActivitySchedules: IBuildActivityScheduleDto[];
    @DtoProp public tradeTypes: any;
    @DtoProp public supplyTypes: any;
    @DtoProp public tradeTypesList: any;
    @DtoProp public supplyTypesList: any;
    @DtoProp public documentTypes: any;
    @DtoProp public documentTypesList: any;
    @DtoProp public buildActivityRelatedActivities: any;
    @DtoProp public code: string;
    @DtoProp public name: string;
    @DtoProp public isActive: boolean;
    @DtoProp public retain: number;
    @DtoProp public hasCompliance: boolean;
    @DtoProp public isComplianceDoneOrNotRequired: boolean;
    @DtoProp public id: number;
    @DtoProp public canPerformPriorToBuildingConsent: boolean;
    @DtoProp public canPerformBeforeQsConsentTakeOffCheck: boolean;
    @DtoProp public excludeFromPracticalCompletion: boolean;
    @DtoProp public showOnClientsConstructionPlan: boolean;
    @DtoProp public canDetermineBuildProgrammeStart: boolean;
    @DtoProp public showDraftActivityToSupplier: boolean;

    constructor(
        sourceData: IBuildActivityDto,
        logicService: IBuildActivitiesLogicService
    ) {
        super(sourceData, logicService, BuildActivityMappedItem, {
            version: 1,
            retain: 0,
            buildActivitySchedules: [],
        });
    }

    public getNationalSchedule(): IBuildActivityScheduleDto {
        if (!this.buildActivitySchedules || !this.buildActivitySchedules[0]) {
            this.createActivitySchedule();
        }
        return this.buildActivitySchedules[0];
    }

    public createActivitySchedule(id?: number): IBuildActivityScheduleDto {
        const obj = {
            buildActivityScheduleSpecificationsLineItems: [{ sortOrder: 1, text: '' }],
            businessEntityId: id,
            complianceLineItems: [{ sortOrder: 1, text: '' }],
            paymentTermsLineItems: [{
                buildActivitySchedulePaymentTermsId: 0,
                id: 0,
                sortOrder: 1,
                text: '',
            }],
            productsSuppliedByLineItems: [{ sortOrder: 1, text: '' }],
            scopeOfWorkLineItems: [{ sortOrder: 1, text: '' }],
            shouldHideSsrLineQuantities: false,
            shouldHideSsrLineSubtotals: false
        };
        if (!this.buildActivitySchedules) {
            this.buildActivitySchedules = [];
        }
        this.buildActivitySchedules.push(obj);
        return obj;
    }

    public incrementVersion(): void {
        if (this.version && this.version >= 0) {
            this.version++;
        }
    }

    public getActivityScheduleByRegion(id: number): IBuildActivityScheduleDto {
        const regionalSchedule = find(this.buildActivitySchedules, { businessEntityId: id });
        if (!regionalSchedule) {
            return this.createActivitySchedule(id);
        }
        return regionalSchedule;
    }

    public copyContentFromNational(id: number, prop: string): void {
        this.getActivityScheduleByRegion(id)[prop] = cloneDeepSafe(this.buildActivitySchedules[0][prop]);
    }

    protected $preSave(toSave: IBuildActivityDto): void {
        if (!toSave.taskAnalysisFormRequiredLevel) {
            toSave.taskAnalysisFormRequiredLevel = 0;
        }
    }
}
