import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';

@Directive({
    selector: '[cbEllipsis]',
    providers: [MatTooltip]
})
export class EllipsisDirective {

    private elementNode: HTMLElement;

    constructor(
        private elementRef: ElementRef,
        private tooltip: MatTooltip
    ) {
        this.elementNode = this.elementRef.nativeElement;
    }

    @HostListener('mouseover')
    public onHover(): void {
        if (this.elementNode.offsetWidth < this.elementNode.scrollWidth) {
            this.tooltip.tooltipClass = 'cb-dark-gray-bg';
            this.tooltip.message = this.elementNode.innerHTML;
            this.tooltip.show();
        }
    }

    @HostListener('mouseout')
    public hide(): void {
        this.tooltip.hide();
    }


}
