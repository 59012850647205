import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {CurrentUserService} from '../authentication/current.user';
import {BasePermissions, IBaseSystemAreaPermissions} from './base.permissions';
import {Injectable} from '@angular/core';
import {LotQuotePermissionEnum} from '@app/shared/enums/permissions/lot.quote.permission';

export interface ILotQuotePermissions extends IBaseSystemAreaPermissions {
    canView(): boolean;
    canRequest(): boolean;
    canCreate(): boolean;
    canManage(): boolean;
    canEdit(): boolean;
    canSubmit(): boolean;
    canAbandon(): boolean;
}

@Injectable()
export class LotQuotePermissions extends BasePermissions<LotQuotePermissionEnum> implements ILotQuotePermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lotquote', LotQuotePermissionEnum, currentUser, http, router);
    }
    public canView(): boolean {
        return this.permissionObject(LotQuotePermissionEnum.CanView);
    }

    public canRequest(): boolean {
        return this.permissionObject(LotQuotePermissionEnum.CanRequest);
    }

    public canCreate(): boolean {
        return this.permissionObject(LotQuotePermissionEnum.CanCreate);
    }

    public canManage(): boolean {
        return this.permissionObject(LotQuotePermissionEnum.CanManage);
    }

    public canEdit(): boolean {
        return this.permissionObject(LotQuotePermissionEnum.CanEdit);
    }

    public canSubmit(): boolean {
        return this.permissionObject(LotQuotePermissionEnum.CanSubmit);
    }

    public canAbandon(): boolean {
        return this.permissionObject(LotQuotePermissionEnum.CanAbandon);
    }
}

