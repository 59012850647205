import { Directive, Input, ViewChild } from '@angular/core';

import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { CTSEnum } from '@classictechsolutions/typescriptenums';
import { isEmpty } from 'lodash';

/** @deprecated Do not use anymore, due to lack of strong typing with the string based properties in the column definitions
 * ***Use cb-table instead***
 */
@Directive()
export class BaseTableComponentDirective<TableDataType> {
    @ViewChild(MatTable, {}) public table: MatTable<any>;
    private firstRun = true;
    private _value: Array<any>;
    @Input() public set value(v: Array<any>) {
        this._value = v;
        if (!this.firstRun || v != null) {
            this.isLoading = false;
        }
        this.firstRun = false;
    }
    public get value(): Array<any> {
        return this._value;
    }
    @Input() public label: string;
    @Input() public height = '500px';
    @Input() public extraRowFunction: () => any;
    @Input() public columns: Array<TableColumn>;
    @Input() public footerColumns: Array<TableColumn>;
    @Input() public footerBold = true;
    @Input() public valueLabel: string;
    @Input() public hideHeader: boolean;
    @Input() public shouldDisplayNoItemsMessageInsideFooter = true;

    public displayedColumns: Array<string>;
    public displayedFooterColumns: Array<string>;
    public isDisplayingNoItemsMessageInsideFooter = false;
    public isEmpty = isEmpty;
    public isLoading = true;

    public setColumns(): void {
        this.displayedColumns = this.columns.map(col => col.name || 'actions');
    }

    public setFooterColumns(): void {
        if (!this.footerColumns) {
            return;
        }
        this.displayedFooterColumns = this.footerColumns.map(column => column.name || 'actions');
    }

    public displayFooterCell(column: TableColumn): boolean {
        if (!this.footerColumns) {
            return false;
        }
        return this.footerColumns.some(footerColumns => footerColumns.name === column.name);
    }

    public displayFooterAction(column: TableColumn): boolean {
        if (!this.footerColumns) {
            return false;
        }
        return this.footerColumns.some(fd => fd.name === column.name && fd.footerAction);
    }

    public footerActionClicked(): any {
        if (!this.footerColumns) {
            return;
        }
        const footerCell = this.footerColumns.find(footerColumns => footerColumns.footerAction);
        footerCell.footerAction.click();
    }

    public getFooterActionIcon(): string {
        if (!this.footerColumns) {
            return '';
        }
        const footerCell = this.footerColumns.find(footerColumns => footerColumns.footerAction);
        return footerCell.footerAction.icon;
    }

    public getFooterValue(column: TableColumn): string {
        if (!this.footerColumns) {
            return '';
        }
        const footerCell = this.footerColumns.find(footerColumn => footerColumn.name === column.name);
        return footerCell.value;
    }

    public shouldDisplayNoItemsMessage(name: string): boolean {
        const shouldDisplay = name === this.footerColumns[0].name &&
            !this.footerColumns[0].value &&
            this.shouldDisplayNoItemsMessageInsideFooter;

        if (shouldDisplay) {
            this.isDisplayingNoItemsMessageInsideFooter = true;
        }

        return shouldDisplay;
    }

    public redraw(): void {
        this.table.renderRows();
    }

    public getArrayText(element: any, column: TableColumn): string {
        let value = '';
        if (column.valueProp2) {
            const array = element[column.valueProp];
            array.forEach((el, idx, arr) => {
                if (idx !== arr.length - 1) {
                    value += `${el[column.valueProp2]}, `;
                } else {
                    value += el[column.valueProp2];
                }
            });
        } else {
            const text = element[column.valueProp];
            if (text) {
                text.forEach((el, idx, array) => {
                    if (idx !== array.length - 1) {
                        value += `${el}, `;
                    } else {
                        value += el;
                    }
                });
            }
        }
        return value;
    }

    public getListItemLabel(element: any, column: TableColumn): string {
        if (column.valueProp2) {
            return element[column.valueProp2];
        } else {
            return element;
        }
    }

    public displayInfoMessageDiv(): boolean {
        return this.value && this.value.length === 0 && !this.isLoading && !this.isDisplayingNoItemsMessageInsideFooter;
    }

    public displayLoadingSpinnerDiv(): boolean {
        return this.isLoading && !this.isDisplayingNoItemsMessageInsideFooter;
    }

    public displayLoadingSpinnerDivOrInfoMessageDiv(): boolean {
        return this.displayLoadingSpinnerDiv() || this.displayInfoMessageDiv();
    }

    public getTextFieldText(element: any, column: TableColumn): string {
        if (column.valueProp2) {
            return element[column.valueProp][column.valueProp2];
        } else {
            return element[column.valueProp];
        }
    }

    public getEnumFieldText(element: any, column: TableColumn): string {
        return column.enum[element[column.valueProp]];
    }

    public getDictionaryFieldText(element: any, column: TableColumn): string {
        return column.dictionary[element[column.valueProp]];
    }

    public actionClicked = (item: TableDataType, items: Array<TableDataType>, action: Action) => {
        if (action.extraParams) {
            action.function(item, items, action.extraParams(item));
        } else {
            action.function(item, items);
        }
    };

    public actionDisabled = (item: TableDataType, items: Array<TableDataType>, action: Action) => {
        if (action.extraParams) {
            return action.disabledFunction(item, items, action.extraParams(item));
        } else {
            return action.disabledFunction(item, items);
        }
    };

    public getNoItemsMessage(): string {
        return `There are no ${this.valueLabel} to display`;
    }
}

/** @deprecated Use cb-table instead.
Do not use anymore, due to lack of strong typing with the string based properties in the column definitions */
export interface Action {
    function: Function;
    disabled?: boolean;
    disabledFunction?: Function;
    icon: string;
    extraParams?: (item: any) => Object;
}

type TableColumnType = 'date' | 'text' | 'enum' | 'array' | 'list' | 'checkbox' | 'icon' | 'svg-icon' |
'multiple-icons' | 'info-popup' | 'actions' | 'currency' | 'percent' | 'bread-crumbs' | 'dictionary';

/** @deprecated Use cb-table instead.
Do not use anymore, due to lack of strong typing with the string based properties in the column definitions */
export interface TableColumn {
    name: string;
    type?: TableColumnType;
    valueProp?: string;
    valueProp2?: string;
    actions?: Array<Action>;
    width?: string;
    icons?: { true: string; false: string };
    multipleIcons?: Array<{ prop: string; title: string; icon: string; isSvg: boolean; class: string }>;
    enum?: CTSEnum<any> | any;
    dictionary?: { [key: string]: any;[key: number]: any };
    trueTitle?: string;
    falseTitle?: string;
    footerActionClicked?: any;
    footerAction?: { click: () => any; icon: string };
    value?: string;
}
