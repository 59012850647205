import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbInfoMessageModule, CbInfoPopupModule } from 'cb-hub-lib';
import { CbDateDurationCalculatorModule } from '@app/shared/components/date-duration-calculator/date-duration-calculator.module';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbLoadingSpinnerModule, CbSelectListModule } from 'cb-hub-lib';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ManageLotSpecColourItemDialogComponent } from './manage-lot-spec-colour-item/manage-lot-spec-colour-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { LotSpecViewComponent } from './lot-spec-view/lot-spec-view.component';
import { LotSpecItemsListComponent } from './lot-spec-items-list/lot-spec-items-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LotSpecReportsMenuComponent } from './lot-spec-reports-menu/lot-spec-reports-menu.component';
import { LotSpecActionsMenuComponent } from './lot-spec-actions-menu/lot-spec-actions-menu.component';
import { ApplySpecTemplateDialogComponent } from './apply-spec-template-dialog/apply-spec-template-dialog.component';
import { EditLotSpecItemDialogComponent } from './edit-lot-spec-item-dialog/edit-lot-spec-item-dialog.component';
import { CbProductInputFindModule } from '@app/shared/components/forms/individual/product-input-find/product-input-find.module';
import { CbImageButtonModule } from '@app/shared/components/image-handlers/image-button/image-button.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { LotSpecItemCardComponent } from './lot-spec-item-card/lot-spec-item-card.component';
import { CbTruncateModule } from '@app/shared/pipe/truncate/truncate.module';
import { EditLotSpecColourItemDialogComponent } from './edit-lot-spec-colour-item-dialog/edit-lot-spec-colour-item-dialog.component';
import { LotSpecColourItemCardComponent } from './lot-spec-colour-item-card/lot-spec-colour-item-card.component';
import { ManageLotSpecItemAttributesDialogComponent } from './manage-lot-spec-item-attributes-dialog/manage-lot-spec-item-attributes-dialog.component';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbTableModule } from 'cb-hub-lib';
import { LotSpecQuoteRefsDialogComponent } from './lot-spec-quote-refs-dialog/lot-spec-quote-refs-dialog.component';
import { LotSpecPurchaseOrdersDialogComponent } from './lot-spec-purchase-orders-dialog/lot-spec-purchase-orders-dialog.component';
import { CbPostLoadModule } from '@app/shared/directives/postload/postload.module';
import { CbFillHeightModule } from '@app/shared/directives/fill-height/fill-height.module';
import { LotSpecItemListTableComponent } from './lot-spec-item-list-table/lot-spec-item-list-table.component';
import { LotSpecItemIconsComponent } from './lot-spec-item-icons/lot-spec-item-icons.component';
import { LotSpecColourItemIconsComponent } from './lot-spec-colour-item-icons/lot-spec-colour-item-icons.component';
import { LotSpecItemTableRowComponent } from './lot-spec-item-table-row/lot-spec-item-table-row.component';
import { LotSpecColourItemTableRowComponent } from './lot-spec-colour-item-table-row/lot-spec-colour-item-table-row.component';
import { CbToggleListFilterMenuModule } from '@app/shared/components/toggle-list-filter-menu/toggle-list-filter-menu.module';
import { LotSpecScheduleItemsListComponent } from './lot-spec-schedule-items-list/lot-spec-schedule-items-list.component';
import { LotSpecScheduleItemsTableComponent } from './lot-spec-schedule-items-table/lot-spec-schedule-items-table.component';
import { LotSpecScheduleItemsTableRowComponent } from './lot-spec-schedule-items-table-row/lot-spec-schedule-items-table-row.component';
import { LotSpecScheduleItemDialogComponent } from './lot-spec-schedule-item-dialog/lot-spec-schedule-item-dialog.component';
import { CbHandleInvalidImageModule } from '@app/shared/directives/handle-invalid-image/handle-invalid-image.module';
import { ManageLotSpecScheduleItemAttributesDialogComponent } from './manage-lot-spec-schedule-item-attributes-dialog/manage-lot-spec-schedule-item-attributes-dialog.component';
import { CbSortByImpureModule } from '@app/shared/pipe/sort-by-impure/sort-by-impure.module';
import { LotSpecScheduleQuoteRefsDialogComponent } from './lot-spec-schedule-quote-refs-dialog/lot-spec-schedule-quote-refs-dialog.component';
import { LotSpecViewFullScreenDialogComponent } from './lot-spec-view-fullscreen-dialog/lot-spec-view-fullscreen-dialog.component';
import { LotSpecificationAreaComponent } from './lot-specification-area/lot-specification-area.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { LotQuotesModule } from '../quotes/quotes.module';
import { LotSpecScheduleItemsRemoveHouseAreaDialogComponent } from './lot-spec-schedule-items-remove-house-area-dialog/lot-spec-schedule-items-remove-house-area-dialog.component';
import { ManageLotSpecCreateScheduleItemDialogComponent } from './manage-lot-spec-create-schedule-item/manage-lot-spec-create-schedule-item-dialog.component';

@NgModule({
    declarations: [
        ManageLotSpecColourItemDialogComponent,
        LotSpecViewComponent,
        LotSpecItemsListComponent,
        LotSpecReportsMenuComponent,
        LotSpecActionsMenuComponent,
        ApplySpecTemplateDialogComponent,
        EditLotSpecItemDialogComponent,
        LotSpecItemCardComponent,
        EditLotSpecColourItemDialogComponent,
        LotSpecColourItemCardComponent,
        ManageLotSpecItemAttributesDialogComponent,
        LotSpecQuoteRefsDialogComponent,
        LotSpecScheduleQuoteRefsDialogComponent,
        LotSpecPurchaseOrdersDialogComponent,
        LotSpecItemListTableComponent,
        LotSpecItemIconsComponent,
        LotSpecColourItemIconsComponent,
        LotSpecItemTableRowComponent,
        LotSpecColourItemTableRowComponent,
        LotSpecScheduleItemsListComponent,
        LotSpecScheduleItemsTableComponent,
        LotSpecScheduleItemsTableRowComponent,
        LotSpecScheduleItemDialogComponent,
        ManageLotSpecScheduleItemAttributesDialogComponent,
        LotSpecViewFullScreenDialogComponent,
        LotSpecificationAreaComponent,
        LotSpecScheduleItemsRemoveHouseAreaDialogComponent,
        ManageLotSpecCreateScheduleItemDialogComponent
    ],
    exports: [
        LotSpecViewComponent,
        EditLotSpecItemDialogComponent,
        EditLotSpecColourItemDialogComponent,
        ManageLotSpecScheduleItemAttributesDialogComponent,
        LotSpecificationAreaComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        CbToggleListFilterMenuModule,
        InfiniteScrollModule,
        MatCardModule,
        CbLoadingSpinnerModule,
        MatIconModule,
        CbFormsModule,
        MatButtonModule,
        MatMenuModule,
        MatTabsModule,
        CbTabRouteModule,
        CbCurrencyModule,
        CbDialogModule,
        CbDisplayValueModule,
        CbUserDateTimeModule,
        CbDateDurationCalculatorModule,
        CbDialogModule,
        CbSelectListModule,
        CbEnumToLabelModule,
        DragulaModule.forRoot(),
        MatExpansionModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        ScrollingModule,
        MatExpansionModule,
        CbSortByModule,
        CbWaitForModule,
        MatTooltipModule,
        CbProductInputFindModule,
        CbImageButtonModule,
        CbClickModule,
        CbTruncateModule,
        CbHubTableModule,
        CbTableModule,
        CbPostLoadModule,
        CbFillHeightModule,
        CbInfoMessageModule,
        CbInfoPopupModule,
        CbHandleInvalidImageModule,
        CbSortByImpureModule,
        LotQuotesModule,
    ]
})

export class LotSpecificationModule { }
