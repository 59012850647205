import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import {
    ILotAmenitiesDto,
    IWorkingDrawingChangeDto,
    IWorkingDrawingDto,
    IWorkingDrawingHistoryDto,
    IWorkingDrawingItemDto,
    IWorkingDrawingRejectionHistoryDto,
    WorkingDrawingStatusEnumId,
    WORKING_DRAWING_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { filter, orderBy } from 'lodash';
import { Observable, tap } from 'rxjs';
import { IWorkingDrawingMappedItem } from '.';
import { Computed } from '../base/computed-prop.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { IWorkingDrawingsLogicService } from './interfaces/i.working-drawings-logic.service';


export class WorkingDrawingMappedItem
    extends BaseMappedItem<IWorkingDrawingDto, IWorkingDrawingMappedItem, IWorkingDrawingsLogicService>
    implements IWorkingDrawingMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public lotId: number;
    @DtoProp public name: string;
    @DtoProp public designComplexityId: number;
    @DtoProp public dueDate: DateString;
    @DtoProp public estimatedHours: number;
    @DtoProp public designReviewRequired: boolean;
    @DtoProp public designConceptId: number;
    @DtoProp public designConceptLabel: string;
    @DtoProp public amenities: ILotAmenitiesDto;
    @DtoProp public failedReview: boolean;
    @DtoProp public statusId: WorkingDrawingStatusEnumId;
    @DtoProp public statusLabel: string;
    @DtoProp public numberOfAssignedChanges: number;
    @DtoProp public approverId: string;
    @DtoProp public approverName: string;
    @DtoProp public reviewerId: string;
    @DtoProp public reviewerName: string;
    @DtoProp public assignedToId: string;
    @DtoProp public assignedToName: string;
    @DtoProp public projectName: string;
    @DtoProp public createdDate: DateString;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: DateString;
    @DtoProp public updatedByName: string;
    @DtoProp public designComplexityLabel: string;
    @DtoProp public changes: IWorkingDrawingChangeDto[];
    @DtoProp public changesCount: number;
    @DtoProp public rejectionNotes: string;
    @DtoProp public workingDrawingHistories: IWorkingDrawingHistoryDto[];
    @DtoProp public workingDrawingRejectionHistories: IWorkingDrawingRejectionHistoryDto[];
    @DtoProp public items: IWorkingDrawingItemDto[];
    @DtoProp public itemsCount: number;

    @Computed() public get failedReviewHistories(): IWorkingDrawingHistoryDto[] {
        const branchRejected: IWorkingDrawingHistoryDto[] = filter(this.workingDrawingHistories,
            { fromStatus: WORKING_DRAWING_STATUS_ENUM.WaitingForReview, toStatus: WORKING_DRAWING_STATUS_ENUM.InQueueForRework });
        const failedDesignReview: IWorkingDrawingHistoryDto[] = filter(this.workingDrawingHistories,
            { fromStatus: WORKING_DRAWING_STATUS_ENUM.DesignReviewRequired, toStatus: WORKING_DRAWING_STATUS_ENUM.InQueueForRework });
        return orderBy(branchRejected.concat(failedDesignReview), [function(i): IWorkingDrawingHistoryDto[] { return i.createdDate; }], 'desc');
    }

    @Computed() public get sortedRejectionHistories(): IWorkingDrawingRejectionHistoryDto[] {
        return orderBy(this.workingDrawingRejectionHistories, [function(i) { return i.createdDate; }], 'desc');
    }

    public $hasUnansweredQuestion: boolean;
    public $addressCity: string;
    public $addressStreet: string;
    public $jobNumber: string;
    public $lotNumber: number;
    public $clientAccountName: string;
    public $designComplexityLabel: string;
    public $dueBy: string;

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    constructor(
        sourceData: IWorkingDrawingDto,
        logicService: IWorkingDrawingsLogicService
    ) {
        super(sourceData, logicService, WorkingDrawingMappedItem);
    }


    public getElapsedTime(): Observable<string> {
        return this.$logicService.getElapsedTime(this.id);
    }

    public get canManage(): boolean {
        return this.statusId === WORKING_DRAWING_STATUS_ENUM.Pending
            || this.statusId === WORKING_DRAWING_STATUS_ENUM.InQueue
            || this.statusId === WORKING_DRAWING_STATUS_ENUM.InQueueForRework
            || this.statusId === WORKING_DRAWING_STATUS_ENUM.InProgress
            || this.statusId === WORKING_DRAWING_STATUS_ENUM.OnHold
            || this.statusId === WORKING_DRAWING_STATUS_ENUM.WaitingForReview;
    }

    public request(): any {
        const schemeToRequest = this.$getMappedDtoItem();
        return this.$logicService.request(schemeToRequest).pipe(
            tap(result => this.$updateThisAndOriginal(result)
            )
        );
    }

    public reject(comments: string): Observable<IWorkingDrawingDto> {
        return this.$logicService.reject(comments, this.id).pipe(
            tap(result => this.$updateThisAndOriginal(result)
            ));
    }

    public resubmit(): Observable<IWorkingDrawingDto> {
        return this.$logicService.resubmit(this.id).pipe(
            tap(result => this.$updateThisAndOriginal(result)
            ));
    }
}
