import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IRoleDto } from './interfaces/i.role.dto';
import { IRoleMappedItem } from './interfaces/i.roles.mapped';
import { IRolesLogicService } from './interfaces/i.roles.logic-service';
import { RoleMappedItem } from './roles.mapped';

@Injectable()
export class RolesLogicService
    extends BaseLogicService<IRoleDto, IRoleMappedItem>
    implements IRolesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('auth/roles', RoleMappedItem);
    }
}
