<cb-dialog>
    <form class="flex-col flex"
          #form="ngForm">
        <cb-select label="Template Section"
                   name="specGroupId"
                   [required]="true"
                   [options]="houseAreas"
                   [(ngModel)]="item.specGroupId"></cb-select>
        <cb-select label="Cost Type"
                   name="costType"
                   [options]="scheduleTemplateCostTypes"
                   [required]="true"
                   [(ngModel)]="item.costType">
        </cb-select>
        <cb-product-autocomplete-scroller *ngIf="item.costType !== COST_TYPE_ENUM.DescriptiveOnly"
                                          class="cb-margin-right"
                                          label="Product"
                                          name="selectProduct"
                                          [(ngModel)]="selectedProduct"
                                          [required]="true"
                                          [isInvalid]="!selectedProduct?.id"
                                          [requireMatch]="true"
                                          [matchProps]="['id']"
                                          [categoryId]="offeringCategoryId"
                                          [hideCompositeItems]="true"
                                          (searchTextChanged)="productSearchTextChanged($event)"
                                          (optionSelected)="productUpdate($event)">
        </cb-product-autocomplete-scroller>
        <cb-input *ngIf="item.costType === COST_TYPE_ENUM.DescriptiveOnly"
                  label="Description"
                  name="productOther"
                  [required]="true"
                  [(ngModel)]="item.productOther"></cb-input>
        <mat-card class="product-card cb-margin-bottom-2x flex-row flex">
            <div class="flex-col"
                 id="imageUploadDropzone"
                 style="max-width: 180px;">
                <div class="start-center flex-row">
                    <cb-image-button [baseUrl]="item.productImageUrl"
                                     class="image-button"
                                     *ngIf="item.costType === COST_TYPE_ENUM.Actual || !item.specItemImage">
                    </cb-image-button>
                    <cb-image-button [baseUrl]="item.specItemImage"
                                     class="image-button"
                                     *ngIf="item.costType !== COST_TYPE_ENUM.Actual && item.specItemImage">
                    </cb-image-button>
                </div>
                <input hidden
                       type="file"
                       #fileInput
                       (change)="selectFile($event.target.files[0], form.form)"
                       (click)="$event.target.value = null"
                       [disabled]="uploadingImage || item.costType === COST_TYPE_ENUM.Actual"
                       accept=".jpg,.jpeg,.png,.gif,image/jpeg,image/gif,image/png"/>
                <div>
                    <button mat-button
                            (click)="fileInput.click()"
                            style="display: block;"
                            [disabled]="uploadingImage || item.costType === COST_TYPE_ENUM.Actual">
                        <mat-icon>delete</mat-icon>
                        <span *ngIf="!item.productImageUrl && !item.specItemImage">
                            &nbsp;Upload Image
                        </span>
                        <span *ngIf="item.productImageUrl || item.specItemImage">
                            &nbsp;Replace Image
                        </span>
                    </button>
                    <button mat-button
                            style="display: block;"
                            (click)="removeImage(form.form)"
                            *ngIf="item.specItemImage && item.costType !== COST_TYPE_ENUM.Actual">
                        <mat-icon>delete</mat-icon>
                        &nbsp;Remove Image
                    </button>
                </div>
            </div>
            <div class="flex-col flex">
                <ul class="simple-breadcrumbs"
                    *ngIf="!item.productOther">
                    <li *ngFor="let cat of selectedProduct?.categoryPath">{{cat?.label || cat}}</li>
                </ul>
                <h3 class="mat-subheading-1"
                    *ngIf="!item.productOther">{{selectedProduct?.code}}
                    {{selectedProduct?.name ? '-' : ''}} {{selectedProduct?.name}}
                </h3>
                <cb-input name="quantity"
                          label="Quantity"
                          type="number"
                          [required]="true"
                          [suffix]="productUom"
                          *ngIf="!shouldHideQuantity"
                          [(ngModel)]="item.quantity">
                </cb-input>
                <cb-select *ngIf="isSupplierViewable()"
                           name="supplierSelect"
                           label="Supplier"
                           [options]="suppliers"
                           [(ngModel)]="item.supplierId">
                </cb-select>
                <cb-currency [label]="getRateLabel()"
                             name="rateSnapshot"
                             *ngIf="item.costType && item.costType !== COST_TYPE_ENUM.Actual && item.costType !== COST_TYPE_ENUM.NoCharge && item.costType !== COST_TYPE_ENUM.DescriptiveOnly"
                             [(ngModel)]="item.rateSnapshot"
                             [required]="item.costType !== COST_TYPE_ENUM.Actual && item.costType !== COST_TYPE_ENUM.NoCharge"
                             [inclGst]="item.costType === COST_TYPE_ENUM.Provisional">
                </cb-currency>
            </div>
        </mat-card>
        <cb-nullable-boolean label="Include in Flyer / Create Schedule"
                             name="showInClientSpecification"
                             [(ngModel)]="item.showInClientSpecification"
                             nullLabel="Not Set (Inherit from Product)">
        </cb-nullable-boolean>
        <cb-nullable-boolean label="Include in Colour Schedule"
                             name="showInColourYourDreams"
                             [(ngModel)]="item.showInColourYourDreams"
                             nullLabel="Not Set (Inherit from Product)">
        </cb-nullable-boolean>
        <cb-nullable-boolean label="Manual Colour Entry Required"
                             name="manualColourEntryRequired"
                             [(ngModel)]="item.manualColourEntryRequired"
                             nullLabel="Not Set (Inherit from Product)">
        </cb-nullable-boolean>
        <footer class="cb-margin-top-2x start-center flex-row">
            <span class="flex"></span>
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (click)="save()"
                    [disabled]="form.invalid">Save</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
