<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
    <div class="start-center flex-row">
        <h3 class="mat-title flex">
            Teams
        </h3>
        <button mat-raised-button
                (click)="newItemClicked();"
                [disabled]="!canEdit()"
                color="primary">
            New Team
        </button>
    </div>
    <div class="cb-table cb-margin-top">
        <mat-table [dataSource]="searchResults">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>
                    Team Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="key">
                <mat-header-cell *matHeaderCellDef>
                    Key
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.key }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef>
                    Active
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon>
                        {{ element.isActive ? "check_box" : "check_box_outline_blank" }}
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions"
                          stickyEnd>
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button
                            matTooltip="Edit"
                            matTooltipPosition="left"
                            (click)="editItemClicked(element);"
                            [disabled]="!canEdit()">
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <cb-info-message *ngIf="searchResultsLoaded && searchResults.length <= 0"
                         [message]="'There are no Teams to display'"
                         [icon]="'info_circle'"></cb-info-message>
        <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
    </div>
</div>
