<button mat-mini-fab
        color="accent"
        (click)="toggle()"
        class="toggle-button">
    <mat-icon>{{expandIcon}}</mat-icon>
</button>
<mat-card class="flex-row"
          *ngIf="expanded">
    <ng-content></ng-content>
</mat-card>
