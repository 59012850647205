<cb-dialog>
    <form class="flex-col flex"
          #councilForm="ngForm">
        <cb-input type="text"
                  name="description"
                  label="Council Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name">
        </cb-input>
        <cb-checkbox name="isActive"
                     label="Active"
                     [(ngModel)]="mappedItem.isActive"
                     style="margin-top: 20px">
        </cb-checkbox>
        <h3 class="mat-title">
            Consent Areas
        </h3>
        <cb-select-items-autocomplete-scroller class="flex"
                                               heading="Area"
                                               [displayFn]="displayFn"
                                               name="location1"
                                               [(ngModel)]="mappedItem.locations"
                                               [selectedItem]="selectedItem">
            <cb-location-autocomplete-scroller class="cb-margin-top flex"
                                               label="Area"
                                               name="location"
                                               [(ngModel)]="selectedLocationModel"
                                               (optionSelected)="itemSelected($event)"
                                               [ignoreDirty]="true">
            </cb-location-autocomplete-scroller>
        </cb-select-items-autocomplete-scroller>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="getDisabled()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
