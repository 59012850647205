import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '@app/logic/base/base-logic.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { cleanObjectDeep } from 'cb-hub-lib';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { ILotSpecItemDto, IQuoteDocumentDto, IQuoteDto, IVariationDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { IQuoteLogicService } from '.';
import { QuoteMappedItem } from './quote.mapped';
@Injectable()
export class QuoteLogicService
    extends BaseLogicService<IQuoteDto, QuoteMappedItem>
    implements IQuoteLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'quotes',
            QuoteMappedItem
        );
    }

    public getExcludedSlots(quoteId: number): Observable<number[]> {
        return this.$http.get(`${this.$baseUri}/${quoteId}/excludedslots`);
    }

    public $getSearchList(params: any): Observable<ISearchResult<IQuoteDocumentDto>> {
        const cleanParams = cleanObjectDeep(cloneDeepSafe(params));
        return this.$http.get<ISearchResult<IQuoteDocumentDto>>(`${this.$baseUri}/search`, cleanParams);
    }

    public abandonQuote(quoteId: number): Observable<IQuoteDto> {
        return this.$http.post<IQuoteDto>(`${this.$baseUri}/${quoteId}/abandon`);
    }

    public submitQuote(quoteId: number, quoteDto: IQuoteDto): Observable<IQuoteDto> {
        return this.$http.post<IQuoteDto>(`${this.$baseUri}/${quoteId}/submit`, quoteDto);
    }

    public duplicateQuote(quoteId: number): Observable<IQuoteDto> {
        return this.$http.post<IQuoteDto>(`${this.$baseUri}/${quoteId}/clone`);
    }

    public downloadQuote(quoteId: number): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/${quoteId}/download`, 'Generating Quote...');
    }

    public getVariationOrders(quoteId: number): Observable<IVariationDto[]> {
        return this.$http.get<IVariationDto[]>(`${this.$baseUri}/${quoteId}/variationorders`);
    }

    public getUnassignedLotSpecItems(lotId: number): Observable<ILotSpecItemDto[]> {
        return this.$http.get<ILotSpecItemDto[]>(`lots/${lotId}/${this.$baseUri}/lotspecitems`);
    }


}
