import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { ILotSummary } from '@app/core/services/user-cache/user-cache-areas';

@Directive()
export abstract class LotSummaryPinnablePanelDirective {

    @Input() public pinnedPanels: ILotSummary;
    @Input() public silentData: ILotSummary;
    @Input() public silentDataKey: string;
    @Output() public togglePanelPinnedEvent: EventEmitter<string> = new EventEmitter();

    public togglePanelPinned(event: MouseEvent, key: string): void {
        event.stopPropagation();
        this.togglePanelPinnedEvent.emit(key);
    }
}
