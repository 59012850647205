import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ColourItemsLogicService, IColourItemDto } from '@app/logic/colour-items';
import { ISpecGroupDto, SpecGroupsLogicService } from '@app/logic/spec-groups';
import { ISpecTemplateColourItemDto, ISpecHouseAreaDto, SpecTemplateLogicService } from '@app/logic/spec-template';
import { orderBy, cloneDeep, find } from 'lodash';

interface IData {
    item: ISpecTemplateColourItemDto;
    specTemplateId: number;
    houseAreas: ISpecHouseAreaDto[];
}

@Component({
    selector: 'cb-spec-template-colour-item-dialog',
    templateUrl: './spec-template-colour-item-dialog.component.html',
    styleUrls: ['./spec-template-colour-item-dialog.component.scss']
})
export class SpecTemplateColourItemDialogComponent {
    public static readonly MIN_WIDTH = '80%';
    public houseAreas: ISpecGroupDto[];
    public colourItems: IColourItemDto[];

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<SpecTemplateColourItemDialogComponent>,
        private readonly specGroupsLogicService: SpecGroupsLogicService,
        private readonly colourItemsLogicService: ColourItemsLogicService,
        private readonly specTemplateLogicService: SpecTemplateLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        this.specGroupsLogicService.$getList().subOnce(result => this.houseAreas = orderBy(result ?? [], 'sortOrder'));
        this.colourItemsLogicService.$getList().subOnce(result => this.colourItems = result);
        data.item.colourItem = data.item.colourItem ? data.item.colourItem : {} as ISpecTemplateColourItemDto;
        data.item.specTemplateId = this.data.specTemplateId ? this.data.specTemplateId : data.item.specTemplateId;
    }

    public saveClicked(): void {
        const colourItem: ISpecTemplateColourItemDto = cloneDeep(this.data.item);
        colourItem.showInClientSpecification = colourItem.colourItem.showInClientSpecification;
        if (!colourItem.id) {
            const found = find(this.data.houseAreas, houseArea => houseArea?.houseArea.id === this.data.item.specGroupId);
            colourItem.sortOrder = found && found.items ? found.items.length : 0;
            this.specTemplateLogicService.addColourItem(this.data.item.specTemplateId, this.data.item.specGroupId, colourItem).subOnce(() => this.dialogRef.close());
        } else {
            this.specTemplateLogicService.editColourItem(colourItem.id, colourItem).subOnce(() => this.dialogRef.close());
        }
    }

    public cancelClicked(): void {
        this.dialogRef.close();
    }
}
