import { ACCOUNT_TYPE_ENUM, CLIENT_ACCOUNT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, Input, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ContactsLogicService } from '@app/logic/contacts/contacts-logic.service';
import { IBusinessAccountContactDto, IBusinessAccountDto } from '@app/logic/business-accounts';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { PermissionsPermissions } from '@app/core/permissions';
import { switchMap } from 'rxjs';
import { toPromise } from 'cb-hub-lib';
import { IContactDto } from '@app/logic/contacts/interfaces/i.contact.dto';

@Component({
    selector: 'cb-account-list',
    templateUrl: './account-list.component.html',
    styleUrls: ['./account-list.component.scss'],
    providers: [ContactsLogicService]
})
export class AccountListComponent implements OnInit {

    @Input() public contactId: string;

    public CLIENT_ACCOUNT_STATUS_ENUM = CLIENT_ACCOUNT_STATUS_ENUM;
    public ACCOUNT_TYPE_ENUM = ACCOUNT_TYPE_ENUM;

    public readonly clientAccountColumns: string[] = [
        'abmCode',
        'status',
        'name',
        'type',
        'lots',
        'address',
        'city',
        'region',
        'actions'
    ];

    public readonly businessAccountColumns: string[] = [
        'abmCode',
        'tradingName',
        'legalName',
        'type',
        'status',
        'actions'
    ];

    public businessAccounts: IBusinessAccountDto[];
    public clientAccounts: IBusinessAccountDto[];

    public businessAccountsLoaded = false;
    public clientAccountsLoaded = false;

    constructor(
        public readonly navigationService: NavigationService,
        public readonly dialogRef: MatDialog,
        protected readonly contactsLogicService: ContactsLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly route: ActivatedRoute,
    ) { }

    public ngOnInit(): void {

        toPromise(this.route.params.pipe(
            switchMap((params: { id: string }) => {
                return this.contactsLogicService.getBusinessAccountsList(params.id);
            })
        )).then((mappedItems: IBusinessAccountDto[]) => {
            this.businessAccounts = mappedItems;
            this.businessAccountsLoaded = true;
        });

        toPromise(this.route.params.pipe(
            switchMap((params: { id: string }) => {
                return this.contactsLogicService.getClientAccountsList(params.id);
            })
        )).then((mappedItem: IBusinessAccountDto[]) => {
            this.clientAccounts = mappedItem;
            this.clientAccountsLoaded = true;
        });

    }

    public sortSearchResults(searchResults: Array<IContactDto>): Array<IContactDto> {
        return searchResults.sort((a, b) => a.guid.localeCompare(b.guid));
    }

    public viewClientAccount(account: IBusinessAccountDto, event: MouseEvent): void {
        this.navigationService.navigate([`accounts/${account.id}/summary`], event);
    }

    public viewBusinessAccount(account: IBusinessAccountContactDto, event: MouseEvent): void {
        this.navigationService.navigate([`/business-accounts/edit/${account.id}/details`], event);
    }
}
