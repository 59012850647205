import { ClientAccountLogicService } from './../../../../../../../logic/client-account/client-account.logic.service';
import { ClientAccountSearchService } from './../../../../../../../core/services/search/client-account-search.service';
import { IClientAccountMappedItem } from './../../../../../../../logic/client-account/interfaces/i.client-account.mapped';
import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ClientAccountsInputFindDialogDirective } from './client-account-input-find-dialog.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';


interface IData {
    mappedItem: IClientAccountMappedItem;
    searchService: ClientAccountSearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

@Component({
    selector: 'cb-client-account-input-find-dialog-cards',
    templateUrl: '../../input-find-dialog-cards/input-find-dialog-cards.component.html',
    styleUrls: ['../../input-find-dialog-cards/input-find-dialog-cards.component.scss'],
    providers: [ClientAccountLogicService]
})
export class ClientAccountsInputFindDialogCardsComponent extends ClientAccountsInputFindDialogDirective {

    constructor(
        public readonly dialogRef: MatDialogRef<ClientAccountsInputFindDialogDirective>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public logicService: ClientAccountLogicService,
        public readonly matDialog: MatDialog,
        public cdRef: ChangeDetectorRef,
        public readonly dialogService: CbDialogService
    ) {
        super(
            dialogRef,
            data,
            matDialog,
            logicService,
            cdRef,
            dialogService
        );
    }
}

