<mat-form-field class="flex"
                [ngClass]="{'cb-soft-required': softRequired && !required }"
                *ngIf="!readonly">
    <input matInput
           class="input"
           [name]="internalName"
           [(ngModel)]="value"
           [ignoreDirty]="ignoreDirty"
           [type]="type"
           [min]="minimum"
           [max]="maximum"
           [placeholder]="label"
           [required]="required"
           [email]="type === 'email'"
           [maxlength]="maxlength"
           [autocomplete]="autocomplete"
           [pattern]="pattern"
           [disabled]="disabled"
           [maskType]="maskType"
           (ngModelChange)="modelChanged($event)"
           (keydown.enter)="enterPressed($event)"
           [ngStyle]="{ 'padding-right': suffixWidth + 'px' }" />
    <span #suffixEle
          class="suffix mat-body-1">{{suffix}}</span>
</mat-form-field>
<ng-container *ngIf="readonly">
    <cb-display-value-new [label]="label">
        {{value}} {{suffix}}
    </cb-display-value-new>
</ng-container>