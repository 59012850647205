<div class="flex-col flex"
     *cbWaitFor="mappedItem">
    <div class="flex-row flex">
        <div class="cb-margin flex-col flex">
            <div style="height: 45px"
                 class="cb-margin start-center flex-row">
                <h3 class="mat-title with-divider">
                    Details
                </h3>
            </div>
            <mat-divider></mat-divider>
            <div class="cb-margin-top">
                <cb-input name="name"
                          label="Display Name"
                          [required]="true"
                          maxlength="150"
                          [(ngModel)]="mappedItem.name">
                </cb-input>
                <cb-input name="legalEntityName"
                          label="Legal Entity Name"
                          [required]="true"
                          maxlength="250"
                          [(ngModel)]="mappedItem.legalEntityName">
                </cb-input>
                <cb-input name="website"
                          label="Website"
                          [required]="true"
                          maxlength="100"
                          [(ngModel)]="mappedItem.website">
                </cb-input>
                <cb-input name="accountsPhone"
                          label="Accounts Phone"
                          [required]="true"
                          [maskType]="'LANDLINE'"
                          [(ngModel)]="mappedItem.accountsPhone">
                </cb-input>
                <cb-input name="creditorsEmailAddress"
                          label="Creditors Email Address"
                          [required]="true"
                          maxlength="100"
                          type="email"
                          [(ngModel)]="mappedItem.creditorsEmailAddress">
                </cb-input>
                <cb-input name="invoiceEmailAddress"
                          label="Invoice Email Address"
                          [required]="true"
                          maxlength="100"
                          type="email"
                          [(ngModel)]="mappedItem.invoiceEmailAddress">
                </cb-input>
                <cb-input name="complianceEmailAddress"
                          label="Compliance Email Address"
                          [required]="true"
                          maxlength="100"
                          type="email"
                          [(ngModel)]="mappedItem.complianceEmailAddress">
                </cb-input>
                <cb-input name="emailAddress"
                          label="General Email Address"
                          [required]="true"
                          maxlength="100"
                          type="email"
                          [(ngModel)]="mappedItem.emailAddress">
                </cb-input>
                <cb-input name="faxNumber"
                          label="Fax Number"
                          [maskType]="'LANDLINE'"
                          maxlength="100"
                          [(ngModel)]="mappedItem.faxNumber">
                </cb-input>
                <cb-input name="abmId"
                          label="ABM ID"
                          [required]="false"
                          maxlength="100"
                          [(ngModel)]="mappedItem.abmId">
                </cb-input>
            </div>
        </div>
        <div class="cb-margin flex-col flex">
            <cb-address-new [(ngModel)]="mappedItem.physicalAddress"
                            addressTitle="Physical Address"
                            name="physicalAddress">
            </cb-address-new>
        </div>
        <div class="cb-margin flex-col flex">
            <cb-address-new [(ngModel)]="mappedItem.postalAddress"
                            addressTitle="Postal Address"
                            name="postalAddress">
            </cb-address-new>
        </div>
    </div>
    <div class="cb-margin flex-col flex">
        <h3 class="mat-title with-divider flex">
            Configuration
        </h3>
        <mat-divider></mat-divider>
    </div>
    <div class="flex-row flex">
        <div class="cb-margin flex-col flex-33">
            <cb-currency type="number"
                         label="Contract Generation Fee"
                         [inclGst]="true"
                         name="contractGenerationFee"
                         [(ngModel)]="mappedItem.contractGenerationFee">
            </cb-currency>
            <cb-currency type="number"
                         label="Lot Holding Deposit"
                         [inclGst]="true"
                         name="lotHoldingDeposit"
                         [(ngModel)]="mappedItem.lotHoldingDeposit">
            </cb-currency>
            <cb-input name="contingencyAmount"
                      label="Build Cost Contingency Percentage"
                      type="number"
                      [(ngModel)]="mappedItem.contingencyAmount">
            </cb-input>
            <cb-input name="contractGenerationFeeDays"
                      label="Contract Generation Fee Days"
                      type="number"
                      [(ngModel)]="mappedItem.contractGenerationFeeDays">
            </cb-input>
            <cb-input name="variationMarginPercentage"
                      label="Variation Margin Percentage"
                      type="number"
                      [(ngModel)]="mappedItem.variationMarginPercentage"
                      type="number">
            </cb-input>
            <cb-input name="variationExpiryDays"
                      label="Variation Expiry Days"
                      [(ngModel)]="mappedItem.variationExpiryDays"
                      type="number">
            </cb-input>
            <cb-input name="consentDays"
                      label="Consent Days"
                      type="number"
                      [(ngModel)]="mappedItem.consentDays">
            </cb-input>
            <cb-checkbox name="isActive"
                         label="Is Business Entity Active?"
                         [(ngModel)]="mappedItem.isActive">
            </cb-checkbox>
        </div>
        <div class="cb-margin flex-col flex-33">
            <cb-input name="ssrNumberPrefix"
                      label="SSR Number Prefix"
                      [required]="true"
                      maxlength="5"
                      [(ngModel)]="mappedItem.ssrNumberPrefix">
            </cb-input>
            <cb-input name="jobNumberPrefix"
                      label="Job Number Prefix"
                      [required]="true"
                      maxlength="5"
                      [(ngModel)]="mappedItem.jobNumberPrefix">
            </cb-input>
            <cb-select *ngIf="mappedItem.id > 0 && availableBusinessEntities"
                       label="Default Bank Account"
                       name="defaultBankAccountId"
                       [(ngModel)]="mappedItem.defaultBankAccountId"
                       [options]="availableBusinessEntities">
            </cb-select>
        </div>
        <span class="flex-33"></span>
    </div>
</div>
