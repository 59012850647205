import { Component, Input } from '@angular/core';

import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'cb-sortable-header-cell',
    templateUrl: './sortable-header-cell.component.html',
    styleUrls: ['./sortable-header-cell.component.scss']
})
export class SortableHeaderCellComponent {

    @Input() public sort: MatSort;

    @Input() public cell: string;

    constructor() { }

    public noSorting(): boolean {
        return (this?.sort?.direction === '' && this?.sort?.active === this.cell)
            || this?.sort?.active == null
            || this?.sort?.active !== this.cell;
    }

    public isDescending(): boolean {
        return this?.sort?.direction === 'desc' && this?.sort?.active === this.cell;
    }

    public isAscending(): boolean {
        return this?.sort?.direction === 'asc' && this?.sort?.active === this.cell;
    }
}
