import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { LeadPermissions } from '@app/core/permissions';
import { LOT_CONTRACT_TYPE_ENUM, SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { INotePermissions, IDocumentPermissions, ITaskPermissions } from '@app/core/permissions/base.permissions';
import { ILeadDto, LeadLogicService } from '@app/logic/leads';
import { Subscription } from 'rxjs';
import { LEADS_ROUTE } from '../../lead-routes';

@Component({
    selector: 'cb-lead-edit',
    templateUrl: './lead-edit.component.html',
    styleUrls: ['./lead-edit.component.scss']
})
export class LeadEditComponent implements OnInit, OnDestroy {
    @Input() public id: number;
    @Input() public isBuildingConsultant: boolean;
    @Output() public readonly leadChanged = new EventEmitter<ILeadMappedItem>();
    public systemArea = SYSTEM_AREA_ENUM.Lead;
    public mappedItem: ILeadMappedItem;
    public selectedTabIndex = 0;
    public subscription$ = new Subscription();
    public readonly LEADS_ROUTE = LEADS_ROUTE;

    constructor(
        public leadPermissions: LeadPermissions,
        private leadLogicService: LeadLogicService
    ) { }

    public ngOnInit(): void {
        this.leadLogicService.$getMappedItem(this.id).subOnce(lead => {
            this.mappedItem = lead;
            this.subscription$.unsubscribe();
            this.subscription$ = this.mappedItem.$updated.subscribe(() => {
                this.leadChanged.emit(this.mappedItem);
            });
        });
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    public get leadDto(): ILeadDto {
        return this?.mappedItem?.$getMappedDtoItem();
    }

    public get notePermissions(): INotePermissions {
        return this.leadPermissions.getNotePermissions();
    }

    public get documentPermissions(): IDocumentPermissions {
        return this.leadPermissions.getDocumentPermissions();
    }

    public get taskPermissions(): ITaskPermissions {
        return this.leadPermissions.getTaskPermissions();
    }

    protected onCancelCreate(): void {
        throw new Error('Method not implemented.');
    }

    public canViewHistory(): boolean {
        return this.leadPermissions && this.leadPermissions.canViewHistory();
    }

    public canViewDocuments(): boolean {
        return this.documentPermissions && this.documentPermissions.canViewDocuments();
    }

    public canEditLead(): boolean {
        return this.leadPermissions && this.leadPermissions.canEdit();
    }

    public canViewNotes(): boolean {
        return this.notePermissions && this.notePermissions.canViewNotes();
    }

    public canViewTasks(): boolean {
        return this.taskPermissions && this.taskPermissions.canViewTask();
    }

    public getTitle(): string {
        const data = this.mappedItem;
        let title = data?.name ?? '';
        if (data?.contractType != null && data?.contractType !== LOT_CONTRACT_TYPE_ENUM.None) {
            title += ` for ${LOT_CONTRACT_TYPE_ENUM[data?.contractType]}`;
        }
        return title ?? '';
    }

    public updateLead(mappedItem: ILeadMappedItem): void {
        this.mappedItem = mappedItem;
        this.leadChanged.emit(this.mappedItem);
    }

}
