import { BaseMappedItem } from '../base/base-mapped-item';
import { IBuildActivitiesLogicService } from './interfaces/i.build-activities.logic.service';
import { ISkinnyBuildActivityMappedItem } from './interfaces/i.skinny-build-activity.mapped';
import { ISkinnyBuildActivityDto } from './interfaces/i.skinny-build-activity.dto';
import { DtoProp } from '../base/dto-prop.decorator';

export class SkinnyBuildActivityMappedItem
    extends BaseMappedItem<ISkinnyBuildActivityDto, ISkinnyBuildActivityMappedItem, IBuildActivitiesLogicService>
    implements ISkinnyBuildActivityMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public activityDurationDays: number;
    @DtoProp public canPerformBeforeQsConsentTakeOffCheck: boolean;
    @DtoProp public canPerformPriorToBuildingConsent: boolean;
    @DtoProp public code: string;
    @DtoProp public excludeFromPracticalCompletion: boolean;
    @DtoProp public hasCompliance: boolean;
    @DtoProp public isActive: boolean;
    @DtoProp public name: string;
    @DtoProp public retain: number;
    @DtoProp public shouldExcludeRequiredComplianceForDuplicates: boolean;
    @DtoProp public version: number;
    @DtoProp public showDraftActivityToSupplier: boolean;


    constructor(
        sourceData: ISkinnyBuildActivityDto,
        logicService: IBuildActivitiesLogicService
    ) {
        super(sourceData, logicService, SkinnyBuildActivityMappedItem);
    }
}
