<div class="flex-row flex"
     style="overflow: hidden; padding-top: 10px;">
    <div class="cb-margin-right-3x cb-padding-right-3x flex-col flex">
        <mat-form-field>
            <input matInput
                   type="number"
                   name="bedrooms"
                   required
                   [(ngModel)]="this.plan.amenities.bedrooms"
                   placeholder="Bedrooms"/>
        </mat-form-field>
        <mat-form-field>
            <input matInput
                   type="number"
                   name="bathrooms"
                   required
                   [(ngModel)]="this.plan.amenities.bathrooms"
                   placeholder="Bathrooms"/>
        </mat-form-field>
        <mat-form-field>
            <input matInput
                   type="number"
                   name="living"
                   required
                   [(ngModel)]="this.plan.amenities.living"
                   placeholder="Living Rooms"/>
        </mat-form-field>
        <cb-select [required]="true"
                   name="garages"
                   label="Garages"
                   [(ngModel)]="this.plan.amenities.garages"
                   [options]="garageTypes">
        </cb-select>
        <mat-form-field>
            <input matInput
                   type="number"
                   name="floorArea"
                   required
                   [(ngModel)]="this.plan.floorArea"
                   placeholder="Floor Area"/>
        </mat-form-field>
    </div>
    <div class="cb-margin-left-3x cb-padding-left-3x flex-col flex">
        <cb-select [required]="true"
                   name="category"
                   label="Category"
                   [options]="categories"
                   [valueProp]="WHOLE_OPTION_VALUE_PROP"
                   [(ngModel)]="plan.category">
        </cb-select>
        <cb-checkbox name="isPublished"
                     label="Published"
                     [(ngModel)]="this.plan.isPublished"></cb-checkbox>
    </div>
</div>
