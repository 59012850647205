import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem } from '@app/logic/lots';
import { ILotsLogicService } from '@app/logic/lots/interfaces/i.lots.logic.service';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ILotDto, IProjectReleaseStageDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { filter, orderBy } from 'lodash';

export interface ILotSwitchReleaseStageDialogData {
    mappedItem: ILotMappedItem;
}

@Component({
    selector: 'cb-lot-switch-release-stage-dialog',
    templateUrl: './lot-switch-release-stage-dialog.component.html',
    styleUrls: ['./lot-switch-release-stage-dialog.component.scss']
})
export class LotSwitchReleaseStageDialogComponent extends BaseDialogFormViewDirective<ILotDto, ILotMappedItem, ILotsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '50%';
    public mappedItem: ILotMappedItem;
    public releaseStages: IProjectReleaseStageDto[];
    public selectedReleaseStageId: number;

    constructor(
        private readonly releaseStagesLogicService: ReleaseStagesLogicService,
        public readonly dialogRef: MatDialogRef<LotSwitchReleaseStageDialogComponent>,
        private readonly toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: ILotSwitchReleaseStageDialogData,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }

    public save() {
        this.mappedItem.projectReleaseStage.id = this.selectedReleaseStageId;
        super.save();
    }

    public ngOnInit(): void {
        this.releaseStagesLogicService.getProjectReleaseStagesByProjectId(this.mappedItem.project.id).subOnce(result => {
            this.releaseStages = result;
            this.releaseStages = filter(this.releaseStages, (item) => {
                return item.id !== this.mappedItem.projectReleaseStage.id &&
                    item.allowedLotContractTypes.includes(this.mappedItem.contractType);
            });
            this.releaseStages = orderBy(this.releaseStages, 'name', 'asc');
        });
    }
}
