<div class="flex-row flex-fill">
    <mat-tab-group class="flex"
                   [selectedIndex]="selectedTabIndex"
                   [cbTabRoute]="PROJECT_ROUTE">
        <mat-tab label="Details"
                 cbTabRouteId="details">
            <form #leadEditForm="ngForm">
                <cb-manage-project-details *cbWaitFor="mappedItem$$ | async"
                                           [mappedItem]="mappedItem">
                </cb-manage-project-details>
            </form>
        </mat-tab>
        <mat-tab label="Release Stages"
                 cbTabRouteId="release-stages">
            <ng-template matTabContent>
                <cb-project-releases-list *cbWaitFor="mappedItem$$ | async"
                                          [mappedItem]="mappedItem">
                </cb-project-releases-list>
            </ng-template>
        </mat-tab>
        <mat-tab label="Notes"
                 cbTabRouteId="notes"
                 *ngIf="canViewNotes()">
            <ng-template matTabContent>
                <cb-notes-new *cbWaitFor="mappedItem$$ | async"
                              [entity]="mappedItem"
                              [edit]="true"
                              [permissions]="notePermissions">
                </cb-notes-new>
            </ng-template>
        </mat-tab>
        <mat-tab label="Documents"
                 cbTabRouteId="documents"
                 *ngIf="canViewDocuments()">
            <ng-template matTabContent>
                <cb-document *cbWaitFor="mappedItem$$ | async"
                             [entity]="mappedItem"
                             [permissions]="documentPermissions"
                             [includeLinked]="true"
                             [systemArea]="systemArea">
                </cb-document>
            </ng-template>
        </mat-tab>
        <mat-tab label="History"
                 cbTabRouteId="history"
                 *ngIf="canViewHistory()">
            <ng-template matTabContent>
                <cb-history-list *cbWaitFor="mappedItem$$ | async"
                                 [entityId]="mappedItem?.id"
                                 entity="project">
                </cb-history-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <cb-manage-project-side-bar-card *ngIf="mappedItem"
                                     [mappedItem]="mappedItem"
                                     class="cb-margin"
                                     (projectUpdated)="reloadMappedItem()">
    </cb-manage-project-side-bar-card>
</div>
