<form *cbWaitFor="mappedItem"
      class="cb-margin-top flex-col">
    <div class="flex-col layoutGap-10 flex">
        <div class="flex-row flex">
            <cb-display-value-new class="flex"
                                  label="Land Area">
                <div class="start-center">
                    <mat-icon class="cb-grey">border_style</mat-icon>
                    <span class="mat-body-1">&nbsp;{{
                        mappedItem.amenities.landArea | cbDasher
                        }}m²</span>
                </div>
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Floor Area">
                <div class="start-center">
                    <mat-icon class="cb-grey"
                              svgIcon="floor-plan"></mat-icon>
                    <span class="mat-body-1">&nbsp;{{
                        mappedItem.amenities.floorArea | cbDasher
                        }}m²</span>
                </div>
            </cb-display-value-new>
        </div>
        <div class="flex-row flex">
            <cb-display-value-new class="flex"
                                  label="Bedroom">
                <div class="start-center">
                    <mat-icon class="cb-grey">hotel</mat-icon>
                    <span class="mat-body-1">&nbsp;{{
                        mappedItem.amenities.noBedrooms | cbDasher
                        }}</span>
                </div>
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Bathroom">
                <div class="start-center">
                    <mat-icon class="cb-grey">wc</mat-icon>
                    <span class="mat-body-1">&nbsp;{{
                        mappedItem.amenities.noBathrooms | cbDasher
                        }}</span>
                </div>
            </cb-display-value-new>
        </div>
        <div class="flex-row flex">
            <cb-display-value-new class="flex"
                                  label="Living">
                <div class="start-center">
                    <mat-icon class="cb-grey">weekend</mat-icon>
                    <span class="mat-body-1">&nbsp;{{
                        mappedItem.amenities.noLivingRooms | cbDasher
                        }}</span>
                </div>
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Garage">
                <div class="start-center">
                    <mat-icon class="cb-grey">directions_car</mat-icon>
                    <span class="mat-body-1">&nbsp;{{
                        mappedItem.amenities.noGarages | cbDasher
                        }}</span>
                </div>
            </cb-display-value-new>
        </div>
    </div>
    <div class="flex-col">
        <cb-display-value-new label="Failed Review History">
            <div class="flex-row flex">
                <div class="flex-row">
                    <mat-divider [vertical]="true"></mat-divider>
                </div>
                <ul class="cb-list flex-col flex">
                    <li *ngFor="let item of mappedItem.failedReviewHistories">
                        <span>Rejected by {{item.updatedByName}} on {{item.updatedDate | date: 'dd MMM YYYY h:mma'
                            }}</span>
                        <p>&emsp;{{item.notes}}</p>
                    </li>
                </ul>
            </div>
        </cb-display-value-new>
    </div>
    <cb-display-value-new label="Approver"
                          [value]="mappedItem.approverName"></cb-display-value-new>
    <cb-user-date-time-new label="Requested by"
                           [userFullName]="mappedItem.createdByName"
                           [date]="mappedItem.createdDate"
                           [floatingLabel]="true"
                           [displayTime]="false"></cb-user-date-time-new>
    <div class="flex-col">
        <cb-display-value-new label="Rejection Histories">
            <div class="flex-row flex">
                <div class="flex-row">
                    <mat-divider [vertical]="true"></mat-divider>
                </div>
                <ul class="cb-list flex-col flex">
                    <li *ngFor="let item of mappedItem.sortedRejectionHistories">
                        <span>Rejected By {{item.rejectedBy}} on {{item.rejectedDate | date: 'dd MMM YYYY
                            h:mma'}}</span>
                        <p>&emsp;{{item.rejectionReason}}</p>
                    </li>
                </ul>
            </div>
        </cb-display-value-new>
    </div>
</form>
