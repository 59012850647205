import { Component } from '@angular/core';
import { ILotSearchDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'app-house-and-land',
    templateUrl: './house-and-land.component.html',
    styleUrls: ['./house-and-land.component.scss']
})
export class HouseAndLandComponent {

    public lots: ILotSearchDto[] = [];
    public lotsLoaded = false;
    public noMoreResults = false;
    public defaultCardSize = '25%';

    constructor() { }

    public getFilterredLots($event): void {
        this.lots = $event.lots;
        this.noMoreResults = $event.noMoreResults;
        this.lotsLoaded = true;
    }

    public setResultSize(size: string): void {
        this.defaultCardSize = size + '%';
    }

}
