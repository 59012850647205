import {Component, Inject} from '@angular/core';
import {LEAD_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {ILeadMappedItem} from '@app/logic/leads/interfaces/i.lead.mapped';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';

interface IData {
    mappedItem: ILeadMappedItem;
}

@Component({
    selector: 'app-lead-finance-info-edit-dialog',
    templateUrl: './lead-finance-info-edit-dialog.component.html',
    styleUrls: ['./lead-finance-info-edit-dialog.component.scss']
})
export class LeadFinanceInfoEditDialogComponent extends BaseDialogFormViewDirective<any, any, any> {
    public static readonly MIN_WIDTH = '33%';

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        public readonly cbDialog: CbDialogService,
        public readonly dialogRef: MatDialogRef<LeadFinanceInfoEditDialogComponent>,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }

    public updateLeadStatus(): void {

        const hasFinance = this.mappedItem.hasFinance;

        if (hasFinance) {
            this.mappedItem.status = LEAD_STATUS_ENUM.Hot;
            this.mappedItem.requireClassicAdvantageCall = false;
        } else if (hasFinance === false) {
            this.mappedItem.status = LEAD_STATUS_ENUM.Cold;
            this.mappedItem.hasApproval = false;
            this.mappedItem.financeIsCash = false;
        } else {
            this.mappedItem.status = LEAD_STATUS_ENUM.Cold;
            this.mappedItem.hasApproval = undefined;
            this.mappedItem.financeIsCash = undefined;
        }
    }

}
