import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Directive, Input, ViewContainerRef, OnDestroy } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: 'mat-select[cbRequireSelectionValidator][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: RequireSelectionValidatorDirective, multi: true }
    ]
})
export class RequireSelectionValidatorDirective implements OnDestroy {

    @Input() public cbRequireSelectionValidator: boolean;

    public matSelect: MatSelect;

    public subscriptions = new Subscription();

    constructor(public viewContainerRef: ViewContainerRef) { }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (!this.cbRequireSelectionValidator) {
            return null;
        }

        this.loadMatSelect(control);

        let hasSelection = false;

        try {
            hasSelection = this.matSelect.selected != null;
        } catch (_) {
            // swallow this error - don't care if it fails
        }

        if (!this.matSelect || hasSelection) {
            return null;
        }

        return { selectionRequired: 'cb-select requires an option to be select' };
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private loadMatSelect(control: AbstractControl): void {
        if (this.matSelect) {
            return;
        }

        this.matSelect = this.viewContainerRef.injector.get(MatSelect);

        this.subscriptions.add(
            this.matSelect.stateChanges.subscribe(x => {
                control.updateValueAndValidity();
            })
        );
    }
}
