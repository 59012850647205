<cb-dialog [dialogHeading]="data.dialogHeading || ''">
    <div class="start-center flex-row">
        <span class="flex">{{data.message}}</span>
    </div>
    <footer class="center-center flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                (click)="close()">Close</button>
    </footer>
</cb-dialog>
