import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IProductCategoryDto } from '@app/logic/product-categories/interfaces/i.product-category.dto';
import { NgForm } from '@angular/forms';
import { IBundleTemplateItemDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    item: IBundleTemplateItemDto;
}

@Component({
    selector: 'cb-bundle-template-Item-dialog',
    templateUrl: './bundle-template-Item-dialog.component.html',
    styleUrls: ['./bundle-template-Item-dialog.component.scss']
})
export class BundleTemplateItemDialogComponent implements OnInit {
    public item: IBundleTemplateItemDto;
    public selectedProductCategoryModel: IProductCategoryDto | any;
    @ViewChild('bundleTemplateItemForm', { static: true }) public bundleTemplateItemForm: NgForm;

    constructor(
        public readonly dialogRef: MatDialogRef<BundleTemplateItemDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        this.item = data.item;
    }

    public ngOnInit(): void {
        if (this.item.categoryId) {
            this.selectedProductCategoryModel = {};
            this.selectedProductCategoryModel.id = this.item.categoryId;
        } else {
            this.selectedProductCategoryModel = undefined;
        }
        if (this.item.categoryPath) {
            this.selectedProductCategoryModel.treeDisplay = this.item.categoryPath;
        }
    }

    public productCategorySelected(value: IProductCategoryDto | void | any): void {
        if (value) {
            this.item.categoryId = value.id;
            this.item.categoryPath = value.treeDisplay;
        }
    }

    public apply(): void {
        this.dialogRef.close(this.item);
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public getDisabled(): boolean {
        return this.bundleTemplateItemForm.invalid || this.bundleTemplateItemForm.pristine;
    }
}
