import { Injectable, Injector } from '@angular/core';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { cleanObjectDeep } from 'cb-hub-lib';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { ISlotExternalSystemFieldValueDocumentDto, ISlotExternalSystemFieldValueDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ISlotExternalSystemFieldValuesLogicService } from './interfaces/i.slot-external-system-field-value.logic-service';
import { ISlotExternalSystemFieldValueMappedItem } from './interfaces/i.slot-external-system-field-value.mapped';
import { SlotExternalSystemFieldValueMappedItem } from './slot-external-system-field-value.mapped';

@Injectable()
export class SlotExternalSystemFieldValuesLogicService
    extends BaseLogicService<ISlotExternalSystemFieldValueDto, ISlotExternalSystemFieldValueMappedItem>
    implements ISlotExternalSystemFieldValuesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'externalsystemfieldtemplatevalues',
            SlotExternalSystemFieldValueMappedItem
        );
    }

    public $getSearchList(params: any): Observable<ISearchResult<ISlotExternalSystemFieldValueDocumentDto>> {
        return this.$http
            .get<ISearchResult<ISlotExternalSystemFieldValueDocumentDto>>(`${this.$baseUri}/search`, cleanObjectDeep(cloneDeepSafe(params)));
    }


    public getSlotExternaReferenceCombinationExists(dto: ISlotExternalSystemFieldValueDto): Observable<boolean> {
        return this.$http
            .post<boolean>(`${this.$baseUri}/slotexternalreferencecombinationexists`, dto);
    }
}
