import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CbSortByImpurePipe} from './sort-by-impure.pipe';
import {CbSortByPipe} from '../sort-by/sort-by.pipe';


@NgModule({
    declarations: [
        CbSortByImpurePipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        CbSortByImpurePipe
    ],
    providers: [
        CbSortByPipe
    ]
})
export class CbSortByImpureModule { }
