import { Component, Input, OnInit } from '@angular/core';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { BusinessAccountLogicService } from '@app/logic/business-accounts';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { ProjectsLogicService } from '@app/logic/projects';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { TagsLogicService } from '@app/logic/tags';
import {
    DESIGN_AND_BUILD_LOT_CONTRACT_TYPE_ENUM,
    HOUSE_AND_LAND_LOT_CONTRACT_TYPE_ENUM,
    ITagDto,
    LandTitleStatusEnumId,
    LAND_TITLE_STATUS_ENUM,
    MARKETING_TYPE_ENUM,
    PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM,
    PROJECT_RELEASE_STAGE_TYPE_ENUM,
    SHOW_HOME_LEVEL_ENUM,
    USER_TAG_CONSTANTS_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { filter, find, orderBy } from 'lodash';

@Component({
    selector: 'cb-manage-project-release-stage-details',
    templateUrl: './manage-release-details.component.html',
    styleUrls: ['./manage-release-details.component.scss']
})
export class ManageProjectReleaseStageDetailsComponent implements OnInit {
    @Input() public mappedItem: IReleaseStageMappedItem;

    public projectMappedItem: IProjectMappedItem;
    public isVendorValid: boolean;
    public contractTypes: SelectListItem[];
    public businessEntities: IBusinessEntityDto[];
    public tags: ITagDto[];
    public PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM = PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM;
    public houseAndLandlotContractTypeEnum = HOUSE_AND_LAND_LOT_CONTRACT_TYPE_ENUM;
    public LAND_TITLE_STATUS_ENUM = LAND_TITLE_STATUS_ENUM;
    public houseAndLandlotContractTypes = HOUSE_AND_LAND_LOT_CONTRACT_TYPE_ENUM
        .toLookup()
        .filter(x => x.id !== HOUSE_AND_LAND_LOT_CONTRACT_TYPE_ENUM.None);
    public designAndBuildlotContractTypes = DESIGN_AND_BUILD_LOT_CONTRACT_TYPE_ENUM.toLookup();
    public DESIGN_AND_BUILD_LOT_CONTRACT_TYPE_ENUM = DESIGN_AND_BUILD_LOT_CONTRACT_TYPE_ENUM;
    public PROJECT_RELEASE_STAGE_TYPE_ENUM = PROJECT_RELEASE_STAGE_TYPE_ENUM;
    public MARKETING_TYPE_ENUM = MARKETING_TYPE_ENUM;
    public SHOW_HOME_LEVEL_ENUM = SHOW_HOME_LEVEL_ENUM;
    public landTitleStatus: IEnumLookup<LandTitleStatusEnumId>[];
    public houseAndLandConsults;

    public setBusinessEntityList(): void {
        this.businessEntitiesLogicService.$getList().subOnce((result) => {
            this.businessEntities = result;
        });
    }

    constructor(
        private businessEntitiesLogicService: BusinessEntitiesLogicService,
        private readonly clientAccountsLogicService: ClientAccountLogicService,
        private readonly businessAccountsLogicService: BusinessAccountLogicService,
        private projectsLogicService: ProjectsLogicService,
        private tagsLogicService: TagsLogicService
    ) { }

    public ngOnInit(): void {
        this.setBusinessEntityList();
        this.setVendor();
        this.setAgent();

        this.projectsLogicService.$getMappedItem(this.mappedItem.projectId).subOnce(projectResult => {
            this.projectMappedItem = projectResult;
            this.tagsLogicService.$getList().subOnce(tags => {
                this.tags = tags;
                const houseAndLandUserTagId = find(this.tags, (o) => {
                    return o.key === USER_TAG_CONSTANTS_CONST.HOUSE_AND_LAND_COORDINATOR;
                }).id;
                this.houseAndLandConsults = filter(this.projectMappedItem.teamMembers, (teamMember: any) => {
                    return teamMember.selectedTags.some(tag => tag.id === houseAndLandUserTagId);
                });
                this.houseAndLandConsults = orderBy(this.houseAndLandConsults, ['firstName', 'lastName'], ['asc', 'asc']);
            });
            if (!this.mappedItem.businessEntityId) {
                this.mappedItem.businessEntityId = this.projectMappedItem.businessEntity.id;
            }
            this.isVendorValid = this.mappedItem.vendorValid;
        });
    }

    private setVendor(): void {
        if (this.mappedItem.vendorBusinessEntityId) {
            this.businessEntitiesLogicService.$getItem(this.mappedItem.vendorBusinessEntityId)
                .subOnce(result => {
                    this.mappedItem.vendorBusinessEntity = { ...result, label: result.legalEntityName };
                });
        } else if (this.mappedItem.vendorClientAccountId) {
            this.clientAccountsLogicService.$getItem(this.mappedItem.vendorClientAccountId)
                .subOnce(result => {
                    this.mappedItem.vendorClientAccount = { ...result, label: result.legalName };
                });
        }
    }

    private setAgent(): void {
        if (this.mappedItem.agentBusinessEntityId) {
            this.businessEntitiesLogicService.$getItem(this.mappedItem.agentBusinessEntityId)
                .subOnce(result => {
                    this.mappedItem.agentBusinessEntity = { ...result, label: result.legalEntityName };
                });
        } else if (this.mappedItem.agentBusinessAccountId) {
            this.businessAccountsLogicService.$getItem(this.mappedItem.agentBusinessAccountId)
                .subOnce(result => {
                    this.mappedItem.agentBusinessAccount = { ...result, label: result.businessName };
                });
        }
    }
}
