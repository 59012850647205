import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { LotTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { IBuildTypeLogicService } from './interfaces/i.build-type-logic.service';
import { IBuildTypeDto } from './interfaces/i.build-type.dto';
import { IBuildTypeMappedItem } from './interfaces/i.build-type.mapped';
import { BuildTypeMappedItem } from './build-type.mapped';

@Injectable()
export class BuildTypeLogicService
    extends BaseLogicService<IBuildTypeDto, IBuildTypeMappedItem>
    implements IBuildTypeLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildtypes',
            BuildTypeMappedItem,
        );
    }

    public getListByLotType(lotType: LotTypeEnumId): Observable<IBuildTypeDto[]> {
        return this.$http
            .get<IBuildTypeDto[]>(`lottypes/${lotType}/${this.$baseUri}`);
    }
}
