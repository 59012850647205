import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { IColourItemDto } from './interfaces/i.colour-item.dto';
import { IColourItemsLogicService } from './interfaces/i.colour-items.logic.service';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ColourItemMappedItem } from './colour-item.mapped';
import { IColourItemMappedItem } from './interfaces/i.colour-item.mapped';

@Injectable()
export class ColourItemsLogicService
    extends BaseLogicService<IColourItemDto, IColourItemMappedItem>
    implements IColourItemsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'colouritems',
            ColourItemMappedItem
        );
    }

    public $saveItem(entity: any): Observable<IColourItemDto> {
        return this.$http.post<IColourItemDto>(`${this.$baseUri}/${entity.id || 0}`, entity);
    }

    public removeImage(colourItemId: number): Observable<IColourItemDto> {
        return this.$http.delete(`/${this.$baseUri}/${colourItemId}/image`);
    }

    public uploadImage(colourItemId: number, image: FormData): Observable<IColourItemDto> {
        return this.$http.post(`/${this.$baseUri}/${colourItemId}/image`, image);
    }
}
