<div class="flex-col flex">
    <div class="cb-table flex-col flex">
        <cb-table class="cb-margin-top"
                  [columns]="displayedColumns"
                  [loaded]="true">
            <tbody cbTableBody
                   [dragula]="dragulaId"
                   [(dragulaModel)]="dragulaModel">
                <tr cbTableRow
                    *ngFor="let collectionItem of collection; let index = index;">
                    <ng-container *ngIf="collectionItemColumns"
                                  [ngTemplateOutlet]="collectionItemColumns"
                                  [ngTemplateOutletContext]="{$implicit:collectionItem}">
                    </ng-container>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-icon-button
                                (click)="removeItem(index)">
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                    </td>
                </tr>
                <tr class="cb-table-footer-row"
                    cbTableRow>
                    <ng-content select="[addCollectionItemRow]"></ng-content>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-icon-button
                                (click)="addItem()"
                                [disabled]="addButtonDisabled">
                            <mat-icon>add</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </cb-table>
    </div>
</div>
