import {CurrentUserService} from '@app/core/authentication/current.user';
import {BaseMappedItem} from '@app/logic/base/base-mapped-item';
import {
    DESIGN_SCHEME_LINE_STATUS_ENUM,
    DESIGN_SCHEME_STATUS_ENUM,
    DesignSchemeLineStatusEnumId,
    DesignSchemeStatusEnumId,
    IDesignComplexityDto,
    IDesignSchemeDocumentDto,
    IDesignSchemeDto,
    IDesignSchemeHistoryDto,
    IDesignSchemeLineDto,
    ILotAmenitiesDto,
    ILotDto,
    USER_TAG_CONSTANTS_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import {isNullOrWhiteSpace} from 'cb-hub-lib';
import {filter} from 'lodash';
import {Observable, tap} from 'rxjs';
import {Computed} from '../base/computed-prop.decorator';
import {DepInject} from '../base/dep-inject.decorator';
import {DtoProp} from '../base/dto-prop.decorator';
import {ISwapMap} from '../base/interfaces/i.swap-map';
import {SwapMap} from '../base/swap-map';
import {IBuildTypeDto} from '../build-type';
import {IDocumentUploadDto} from '../documents/interfaces/i.document.dto';
import {designSchemeDocumentDto} from './dtos/design-scheme-document.dto';
import {IDesignRequirementItemDto} from './interfaces/i.design-requirement.dto';
import {IDesignSchemeMappedItem} from './interfaces/i.design-scheme.mapped';
import {IDesignSchemesLogicService} from './interfaces/i.design-schemes-logic.service';

export class DesignSchemeMappedItem
    extends BaseMappedItem<IDesignSchemeDto, IDesignSchemeMappedItem, IDesignSchemesLogicService>
    implements IDesignSchemeMappedItem {
    public lotSpecChangesMadePreperation: boolean;

    @DtoProp public readonly id: number;
    @DtoProp public clientSaleId: number;
    @DtoProp public dpNumber: string;
    @DtoProp public buildTypeId: number;
    @DtoProp public projectName: string;
    @DtoProp public standardPlanId: number;
    @DtoProp public standardPlanLabel: string;
    @DtoProp public currentDepartment: number;
    @DtoProp public designScheme: number;
    @DtoProp public designComplexity: IDesignComplexityDto;
    @DtoProp public dueBy: string;
    @DtoProp public designSchemeLineStatus: number;
    @DtoProp public lotId: number;
    @DtoProp public name: string;
    @DtoProp public revisionTypeId: number;
    @DtoProp public revisionNumber: string;
    @DtoProp public reassignedUserNotes: string;
    @DtoProp public rejectionNotes: string;
    @DtoProp public pricingRevisionName: string;
    @DtoProp public pricingRevisionBuildPrice: number;
    @DtoProp public reviewerId: string;
    @DtoProp public lotSpecVersion: number;
    @DtoProp public reviewerName: string;
    @DtoProp public status: string;
    @DtoProp public statusId: DesignSchemeStatusEnumId;
    @DtoProp public targetWorkHours: number;
    @DtoProp public estimatedHours: number;
    @DtoProp public assignedToUserName: string;
    @DtoProp public assignedToUserId: string;
    @DtoProp public resourceConsentRequired: boolean;
    @DtoProp public approverUserName: string;
    @DtoProp public approverUserId: string;
    @DtoProp public acceptedUserName: string;
    @DtoProp public acceptedUserId: string;
    @DtoProp public lotType: number;
    @DtoProp public failedReview: boolean;
    @DtoProp public reviewRequired: boolean;
    @DtoProp public addressCity: string;
    @DtoProp public addressStreet: string;
    @DtoProp public hasUnansweredQuestion: boolean;
    @DtoProp public designSchemeLines: IDesignSchemeLineDto[];
    @DtoProp public assignedChangeRecords: IDesignSchemeLineDto[];
    @DtoProp public unassignedChangeRecords: IDesignSchemeLineDto[];
    @DtoProp public designSchemeHistories: IDesignSchemeHistoryDto[];
    @DtoProp public amenities: ILotAmenitiesDto;
    @DtoProp public designRequirementItems: IDesignRequirementItemDto[];
    @DtoProp public documentsList: IDocumentUploadDto[];
    @DtoProp public buildTypes: IBuildTypeDto[];
    @DtoProp public createdDate: string;
    @DtoProp public createdById: string;
    @DtoProp public createdByUserName: string;

    @DtoProp public designRequirements: string;
    @DtoProp public buildType: string;
    @DtoProp public revisionType: string;
    @DtoProp public statusChangedUserId: string;
    @DtoProp public statusChangedUserName: string;
    @DtoProp public acceptedByUserId: string;
    @DtoProp public acceptedByUserName: string;
    @DtoProp public acceptedDate: string;
    @DtoProp public clientBudget: number;
    @DtoProp public finalisedDate: string;
    @DtoProp public finalisedByUserName: string;
    @DtoProp public lotNumber: number;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;

    @SwapMap<IDesignSchemeDocumentDto, IDesignSchemeDto>({
        dto: designSchemeDocumentDto,
        up: (target, src) => {
            target.id = src.id;
            target.name = src.name;
            target.lotSpecVersion = src.lotSpecVersion;
            target.hasUnansweredQuestion = src.hasUnansweredQuestion;
            target.revisionTypeId = src.revisionTypeId;
            target.revisionType = src.revisionType;
            target.rejectionNotes = src.rejectionNotes;
            target.clientBudget = src.clientBudget;
            target.finalisedDate = src.finalisedDate;
            target.lotNumber = src.lotNumber;
            target.lotId = src.lotId;
            target.revisionNumber = src.revisionNumber;
            target.reviewerName = src.reviewerName;
            target.status = src.status;
            target.statusId = src.statusId;
            target.createdDate = src.createdDate;
            target.createdByUserName = src.createdByUserName;
            target.assignedToUserName = src.assignedToUserName;
            target.assignedToUserId = src.assignedUserId;
            target.dueBy = src.dueBy;
        },
        down: (target, src) => {
            target.id = src.id;
            target.name = src.name;
            target.lotSpecVersion = src.lotSpecVersion;
            target.hasUnansweredQuestion = src.hasUnansweredQuestion;
            target.revisionTypeId = src.revisionTypeId;
            target.revisionType = src.revisionType;
            target.rejectionNotes = src.rejectionNotes;
            target.clientBudget = src.clientBudget;
            target.finalisedDate = src.finalisedDate;
            target.lotNumber = src.lotNumber;
            target.lotId = src.lotId;
            target.revisionNumber = src.revisionNumber;
            target.reviewerName = src.reviewerName;
            target.status = src.status;
            target.statusId = src.statusId;
            target.createdDate = src.createdDate;
            target.createdByUserName = src.createdByUserName;
            target.assignedToUserName = src.assignedToUserName;
            target.assignedUserId = src.assignedToUserId;
            target.dueBy = src.dueBy;
        }
    }) public readonly designSchemeDocumentDto: ISwapMap<IDesignSchemeDocumentDto, IDesignSchemeDto>;

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    @Computed() public get canResubmit(): boolean {
        return this.statusId === DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution;
    }

    @Computed() public get hasPricingRevisionInfo(): boolean {
        return !isNullOrWhiteSpace(this.pricingRevisionName);
    }

    @Computed() public get canCompleteSearchItem(): boolean {
        return this.statusId === DESIGN_SCHEME_STATUS_ENUM.WaitingForReview;
    }

    @Computed() public get inProgress(): boolean {
        return this.statusId === DESIGN_SCHEME_STATUS_ENUM.InProgress;
    }

    @Computed() public get canChangeStatus(): boolean {
        const result = this.statusId === DESIGN_SCHEME_STATUS_ENUM.Pending
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InQueue
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InQueueForRework
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InProgress
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.OnHold
            || this.designReviewRequiredAndUserIsDesignSupervisor();

        return result && this.hasAssignedUser();
    }

    @Computed() public get canManage(): boolean {
        return this.statusId === DESIGN_SCHEME_STATUS_ENUM.Pending
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InQueue
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InQueueForRework
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InProgress
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.OnHold
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution
            || this.designReviewRequiredAndUserIsDesignSupervisor();
    }

    @Computed() public get canFinalise(): boolean {
        return this.getStatusOK(this.statusId)
            && this.getFieldsOK()
            && this.getLinesOK(this.designSchemeLines)
            && this.hasAssignedUser()
            && !this.hasUnansweredQuestion
            && !this._hasUncompletedItems()
            && !this._hasUncompletedChanges();
    }

    @Computed() public get canReview(): boolean {
        return Number(this.statusId) === Number(DESIGN_SCHEME_STATUS_ENUM.DesignReviewRequired)
            && !isNullOrWhiteSpace(this.currentUser?.guid?.toString())
            && !isNullOrWhiteSpace(this.reviewerId?.toString())
            && this.currentUser?.guid?.toString() === this.reviewerId?.toString();
    }


    @Computed() public get hasUncompletedItems(): boolean {
        return this._hasUncompletedItems();
    }

    @Computed() public get hasUncompletedChanges(): boolean {
        return this._hasUncompletedChanges();
    }

    @Computed() public get canCancel(): boolean {
        return !(this.statusId === DESIGN_SCHEME_STATUS_ENUM.Accepted
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.Completed
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.Declined
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.Cancelled);
    }

    @Computed() public get canSetToInQueue(): boolean {
        return this.hasComplexity();
    }

    @Computed() public get canSetToInProgress(): boolean {
        if (this.statusId === DESIGN_SCHEME_STATUS_ENUM.InQueue) {
            return this.hasAssignedUser() && this.hasComplexity();
        }
        return this.hasComplexity();
    }

    @Computed() public get isCompleted(): boolean {
        return this.statusId === DESIGN_SCHEME_STATUS_ENUM.Completed;
    }

    @Computed() public get canAddDocuments(): boolean {
        return this.statusId === DESIGN_SCHEME_STATUS_ENUM.Pending
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InQueue
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InQueueForRework
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InProgress
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.OnHold
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.DesignReviewRequired
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.WaitingForReview
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution
            || (
                (this.statusId === DESIGN_SCHEME_STATUS_ENUM.InPricing ||
                    this.statusId === DESIGN_SCHEME_STATUS_ENUM.Accepted ||
                    this.statusId === DESIGN_SCHEME_STATUS_ENUM.Declined ||
                    this.statusId === DESIGN_SCHEME_STATUS_ENUM.Cancelled ||
                    this.statusId === DESIGN_SCHEME_STATUS_ENUM.Completed
                ) && this.currentUser.isDesignTeam());
    }

    @Computed() public get canAddQuestions(): boolean {
        return this.statusId === DESIGN_SCHEME_STATUS_ENUM.Pending
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InQueue
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InQueueForRework
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InProgress
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.OnHold
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.InPricing
            || this.statusId === DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution;
    }

    @Computed() public get failedReviewHistories(): IDesignSchemeHistoryDto[] {
        return filter(this.designSchemeHistories, { fromStatus: DESIGN_SCHEME_STATUS_ENUM.InQueueForRework });
    }

    @DepInject(CurrentUserService) private readonly currentUser: CurrentUserService;

    constructor(
        sourceData: IDesignSchemeDto,
        logicService: IDesignSchemesLogicService
    ) {
        super(sourceData, logicService, DesignSchemeMappedItem);
    }

    public getElapsedTime(): Observable<number> {
        return this.$logicService.getElapsedTime(this.id);
    }

    public canRequestPricing(): Observable<boolean> {
        return this.$logicService.canRequestPricing(this.id);
    }

    public updateAmenities(amenities: ILotAmenitiesDto): Observable<IDesignSchemeDto> {
        return this.$logicService
            .updateAmenities(this.id, amenities)
            .pipe(
                tap(result => {
                    this.$updateThisAndOriginal(result);
                })
            );
    }

    public getLines(): Observable<IDesignSchemeLineDto[]> {
        return this.$logicService.getDesignSchemeLines(this.id);
    }

    public request(): any {
        const schemeToRequest = this.$getMappedDtoItem();
        return this.$logicService.request(schemeToRequest).pipe(
            tap(result => this.$updateThisAndOriginal(result)
            )
        );
    }

    public applyLotData(lotDto: ILotDto, latestCompletedDesignScheme: IDesignSchemeDto | undefined): void {
        this.lotId = lotDto.id;
        this.dpNumber = lotDto.dpNumber;
        this.buildTypeId = lotDto.buildType.id;
        // this.standardPlanId = lotDto.standardPlanId; TODO: get this uncommented
        this.assignedToUserId = lotDto.contacts?.designer?.id;
        this.amenities = latestCompletedDesignScheme?.amenities ?? lotDto.amenities;
    }

    public reject(comments: string): Observable<IDesignSchemeDto> {
        return this.$logicService
            .rejectDesignScheme(this.id, comments)
            .pipe(this._updateMappedItem);
    }

    public requestPricing(useLastestLotSpecVersion: boolean): Observable<IDesignSchemeDto> {
        return this.$logicService
            .requestPricing(this.id, useLastestLotSpecVersion)
            .pipe(this._updateMappedItem);
    }

    private _updateMappedItem = (dtoObservable: Observable<IDesignSchemeDto>): Observable<IDesignSchemeDto> => {
        return dtoObservable.pipe(
            tap((result) => {
                if (result) {
                    this.$updateThisAndOriginal(result);
                }
            })
        );
    };

    public resubmit(): Observable<IDesignSchemeDto> {
        return this.$logicService.resubmitDesignScheme(this.id)
            .pipe(
                tap((result) => {
                    if (result) {
                        this.$updateThisAndOriginal(result);
                    }
                })
            );
    }

    private _hasUncompletedItems(): boolean {
        let hasUncompletedItems = false;
        this.designRequirementItems?.forEach(requirementItem => {
            if (requirementItem.designSchemeLineStatus !== DESIGN_SCHEME_LINE_STATUS_ENUM.NotDoing
                && requirementItem.designSchemeLineStatus !== DESIGN_SCHEME_LINE_STATUS_ENUM.Completed) {
                hasUncompletedItems = true;
                return hasUncompletedItems;
            }
        });
        return hasUncompletedItems;
    }

    private _hasUncompletedChanges(): boolean {
        let hasUncompletedChanges = false;
        this.designSchemeLines?.forEach(element => {
            if (element.designSchemeLineStatus !== DESIGN_SCHEME_LINE_STATUS_ENUM.Completed) {
                hasUncompletedChanges = true;
                return hasUncompletedChanges;
            }
        });
        return hasUncompletedChanges;
    }

    private designReviewRequiredAndUserIsDesignSupervisor(): boolean {
        return this.reviewRequired &&
            this.statusId !== DESIGN_SCHEME_STATUS_ENUM.Cancelled &&
            this.statusId !== DESIGN_SCHEME_STATUS_ENUM.Completed &&
            this.statusId !== DESIGN_SCHEME_STATUS_ENUM.Declined &&
            this.currentUser.tags.indexOf(USER_TAG_CONSTANTS_CONST.DESIGNSUPERVISOR) > -1;
    }

    private hasAssignedUser(): boolean {
        return !isNullOrWhiteSpace(this.assignedToUserId);
    }

    private getFieldsOK(): boolean {
        return !!this.designComplexity &&
            !!this.designComplexity.id &&
            !!this.dueBy;
    }

    private getStatusOK(status: number): boolean {
        return status === DESIGN_SCHEME_STATUS_ENUM.Pending
            || status === DESIGN_SCHEME_STATUS_ENUM.InQueue
            || status === DESIGN_SCHEME_STATUS_ENUM.InQueueForRework
            || status === DESIGN_SCHEME_STATUS_ENUM.InProgress
            || status === DESIGN_SCHEME_STATUS_ENUM.OnHold;
    }

    private getLinesOK(lines: IDesignSchemeLineDto[]): boolean {
        let allLinesFinished = true;
        lines.forEach(line => {
            const lineNotFinished = this.getLineNotFinished(line.designSchemeLineStatus);
            if (lineNotFinished) {
                allLinesFinished = false;
            }
        });
        return allLinesFinished;
    }

    private getLineNotFinished(lineStatus: DesignSchemeLineStatusEnumId): boolean {
        return lineStatus === DESIGN_SCHEME_LINE_STATUS_ENUM.None
            || lineStatus === DESIGN_SCHEME_LINE_STATUS_ENUM.NotStarted;
    }

    private hasComplexity(): boolean {
        return this.designComplexity?.id > 0;
    }

}
