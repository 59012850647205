<cb-dialog dialogHeading="Add Client Account">
    <form #addAccountForm="ngForm">
        <cb-client-account-edit *cbWaitFor="mappedItem && contacts"
                                [(account)]="mappedItem"
                                [(contacts)]="contacts">
        </cb-client-account-edit>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="addAccountForm.pristine || addAccountForm.invalid || contacts.length < 1"
                    (click)="save()">Save</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
