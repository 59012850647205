import {AfterContentInit, ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, NgZone, OnDestroy, Output, ViewContainerRef,} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ISearchResult} from '../../interfaces/search-result.interface';

@Directive({
    selector: '[cbInfiniteScroll]'
})
export class InfiniteScrollDirective implements OnDestroy, AfterContentInit {
    private bindCbInfiniteScrollResolver: (value?: unknown) => void;
    private bindFetchResolver: (value?: unknown) => void;
    private bindEnabledResolver: (value?: unknown) => void;
    private contentInitResolver: (value?: unknown) => void;
    private readonly bindCbInfiniteScrollPromise = new Promise((resolve) => this.bindCbInfiniteScrollResolver = resolve);
    private readonly bindFetchPromise = new Promise((resolve) => this.bindFetchResolver = resolve);
    private bindEnabledPromise = new Promise((resolve) => this.bindEnabledResolver = resolve);
    private readonly bindContentInitPromise = new Promise((resolve) => this.contentInitResolver = resolve);
    private timeout: NodeJS.Timeout;

    private _cbInfiniteScroll: HTMLElement;
    /** [cbInfiniteScroll] - the element that is scrolling (usually the child of the element this directive is applied to)  */
    @Input()
    public set cbInfiniteScroll(v: HTMLElement) {
        this._cbInfiniteScroll = v;
        this.bindCbInfiniteScrollResolver();
    }

    public get cbInfiniteScroll(): HTMLElement {
        return this._cbInfiniteScroll;
    }

    private _fetch: Observable<any>;
    /** [cbInfiniteScroll] - the observable that emits and handles the search results */
    @Input()
    public set fetch(v: Observable<any>) {
        this._fetch = v;
        this.bindFetchResolver();
    }

    public get fetch(): Observable<any> {
        return this._fetch;
    }

    private _enabled: Observable<boolean>;
    /** [cbInfiniteScroll] - infinite scroll will comence once this is set to true */
    @Input()
    public set enabled(v: Observable<boolean>) {
        this._enabled = v;
        if (this._enabled) {
            this.bindEnabledResolver();
            this.debounceFetch();
        } else {
            this.bindEnabledPromise = new Promise((resolve) => this.bindEnabledResolver = resolve);
        }
    }

    public get enabled(): Observable<boolean> {
        return this._enabled;
    }

    private sub$ = new Subscription();
    private _queryUpdated: Observable<any>;
    /** [cbInfiniteScroll] - when this Observable emits, a fetch will be triggered */
    @Input()
    public set queryUpdated(v: Observable<any>) {
        if (v) {
            this._queryUpdated = v;
            this.sub$.unsubscribe();
            this.sub$ = this.queryUpdated?.subscribe(() => {
                this.resetInfiniteScrollData();
                this.debounceFetch();
            });
        }
    }

    public get queryUpdated(): Observable<any> {
        return this._queryUpdated;
    }

    /** [cbInfiniteScroll] - scroll buffer, larger value = less buffer (empty space below scroll bar handle) */
    @Input() public scrollBuffer = 1.5;

    /** [cbInfiniteScroll] - currentPage of query, two way binding */
    private _currentPage = 1;
    @Input()
    public set currentPage(v: number) {
        if (isNaN(Number(v))) {
            return;
        }
        this._currentPage = Number(v);
    }

    public get currentPage(): number {
        return this._currentPage;
    }

    @Output() public readonly currentPageChange = new EventEmitter<number>();

    /** [cbInfiniteScroll] - search results, two way binding */
    @Input() public results: any[] = [];
    @Output() public readonly resultsChange = new EventEmitter<any[]>();

    /** true when last query returned [] */
    @Input() public noMoreResults = false;
    @Output() public readonly noMoreResultsChange = new EventEmitter<boolean>();
    @Input() public fetchInProgress = false;
    @Output() public readonly fetchInProgressChange = new EventEmitter<boolean>();
    private previousPage: number | null;

    constructor(
        private readonly ref: ViewContainerRef,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly zone: NgZone,
    ) {
        this.currentPageChange.emit(this.currentPage);
        this.resultsChange.emit(this.results);
    }

    public ngAfterContentInit(): void {
        this.contentInitResolver();
    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    @HostListener('scroll', ['$event.target'])
    public onScroll(scrollEle: HTMLElement): void {
        this.fetchUntilScrolled(scrollEle);
    }

    /**
     * Typescript Decorators share their functionality across all instances of the class
     * so cannot use a typescript decorator to do this debounce.
     */
    public debounceFetch(): void {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.fetchUntilScrolled(), 400);
    }

    public fetchUntilScrolled(scrollEle: HTMLElement = this.ref.element.nativeElement): void {
        // running this code within zone.run() and setTimeout prevents angular call stack/timing issues e.g. same search request being made multiple times
        this.zone.run(() => {
            setTimeout(() => {
                Promise
                    .all([
                        this.bindCbInfiniteScrollPromise,
                        this.bindFetchPromise,
                        this.bindEnabledPromise,
                        this.bindContentInitPromise,
                    ])
                    .then(() => {
                        // detectChanges to ensure "scrollEle" and "cbInfiniteScroll" property values are current ( e.g. scrollHeight )
                        this.changeDetector.detectChanges();

                        if (this.currentPage === 1) {
                            this.resetInfiniteScrollData();
                        }

                        if (this.fetch == null
                            || this.previousPage === this.currentPage
                            || this.fetchInProgress
                            || !this.enabled
                            || this.noMoreResults
                            || !this.needMoreResults(scrollEle)
                        ) {
                            return;
                        }

                        this.fetchInProgress = true;
                        this.fetchInProgressChange.emit(true);

                        this.fetch.subOnce(result => this.handleFetchResponse(scrollEle, result));
                    });
            });
        });
    }

    private handleFetchResponse(scrollEle: HTMLElement, result: ISearchResult<any> | any[]): void {

        const fetchPromise = new Promise((resolve, reject) => {

            if (this.elementsDisconnected(scrollEle)) {
                return;
            }

            // get result items array from results
            const arr = (result as ISearchResult<any>)?.items != null ? (result as ISearchResult<any>).items : result as any[];

            // save previous page for checking in fetchUntilScrolled()
            this.previousPage = this.currentPage ?? 1; // Set previous page to default 1

            // increment currentPage
            this.currentPage += 1;
            this.currentPageChange.emit(this.currentPage);

            // concat results if there is new results
            if ((arr?.length ?? 0) > 0) {
                this.results = this.results.concat(arr);
                this.resultsChange.emit(this.results);
            }

            this.noMoreResults = (arr?.length ?? 0) < 1;
            this.noMoreResultsChange.emit((arr?.length ?? 0) < 1);

            resolve(true);

        });

        fetchPromise.then(() => {
            this.fetchInProgress = false;
            this.fetchInProgressChange.emit(false);
            this.fetchUntilScrolled(scrollEle);
        });

    }

    private elementsDisconnected(scrollEle: HTMLElement): boolean {
        return isNaN(Number(scrollEle?.clientHeight))
            || isNaN(Number(this.cbInfiniteScroll?.scrollHeight))
            || !scrollEle.isConnected
            || !this.cbInfiniteScroll.isConnected;
    }

    private needMoreResults(scrollEle: HTMLElement): boolean {
        if (this.elementsDisconnected(scrollEle)) {
            return false;
        }
        const hasScrollBar = this.cbInfiniteScroll.scrollHeight > scrollEle.clientHeight
            || this.cbInfiniteScroll.scrollHeight === 0
            || scrollEle.clientHeight === 0;
        const scrollHeightDiff = scrollEle.scrollHeight - scrollEle.clientHeight;
        const scrollHeightDiffThin = scrollHeightDiff * 0.9;
        const scrollMargin = (scrollHeightDiffThin - (scrollEle.clientHeight / this.scrollBuffer));
        return !hasScrollBar || scrollHeightDiff < 100 || scrollEle.scrollTop > scrollMargin;
    }

    private resetInfiniteScrollData(): void {
        this.previousPage = null;
        this.noMoreResults = false;
        this.noMoreResultsChange.emit(false);
        this.currentPage = 1;
        this.currentPageChange.emit(this.currentPage);
        this.results = [];
        this.resultsChange.emit(this.results);
    }
}
