import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectReleaseStagePermissions } from '@app/core/permissions/project-release-stage.permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ProjectsLogicService } from '@app/logic/projects';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ProjectReleaseStageTypeEnumId, PROJECT_RELEASE_STAGE_TYPE_ENUM, PROJECT_RELEASE_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { sortBy } from 'lodash';
import { flatMap } from 'rxjs';
import { ManageProjectReleaseStageDetailsDialogComponent } from '../release/details/manage-release-details-dialog/manage-release-details-dialog.component';

@Component({
    selector: 'cb-project-releases-list',
    templateUrl: './project-releases-list.component.html',
    styleUrls: ['./project-releases-list.component.scss']
})
export class ProjectReleasesListComponent implements OnInit {
    @Input() public mappedItem: IProjectMappedItem;
    public loaded = false;
    public releases: IReleaseStageMappedItem[];
    public readonly PROJECT_RELEASE_STAGE_TYPE_ENUM = PROJECT_RELEASE_STAGE_TYPE_ENUM;
    public readonly PROJECT_RELEASE_STATUS_ENUM = PROJECT_RELEASE_STATUS_ENUM;

    constructor(
        private releaseStagesLogicService: ReleaseStagesLogicService,
        private navigationService: NavigationService,
        public releaseStagePermissions: ProjectReleaseStagePermissions,
        private readonly projectsLogicService: ProjectsLogicService,
        public readonly cbDialog: CbDialogService,
        private route: ActivatedRoute,
    ) { }

    public ngOnInit(
    ): void {
        if (!this.mappedItem) {
            this.route.params.pipe(
                flatMap(urlParam => this.projectsLogicService.$getMappedItem(urlParam.id)))
                .subOnce(projectMapped => {
                    this.mappedItem = projectMapped;
                    this.sortReleasesList();
                });
        } else {
            this.sortReleasesList();
        }
    }

    private sortReleasesList() {
        if (this.mappedItem?.releaseStages?.length > 1) {
            this.mappedItem.releaseStages = sortBy(this.mappedItem.releaseStages, (result: any) => {
                return result.createdDate;
            }).reverse();

            this.loaded = true;
        } else {

            this.loaded = true;
        }
    }

    public isHouseAndLand(type: ProjectReleaseStageTypeEnumId): boolean {
        return PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand === type;
    }

    public viewRelease(release: any): void {
        this.navigationService.navigate([`projects/${this.mappedItem.id}/releases/${release.id}/details`]);
    }

    public canAddProjectRelease(): boolean {
        return this.mappedItem.totalNoStages > this.releases.length;
    }

    public openAddReleaseDialog() {
        this.cbDialog
            .open(ManageProjectReleaseStageDetailsDialogComponent, {
                data: { mappedItem: this.releaseStagesLogicService.$createMappedItem({ projectId: this.mappedItem.id }) },
                minWidth: '70%',
            })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.mappedItem.releaseStages = this.mappedItem.releaseStages.concat(result);
                    this.mappedItem.releaseStages = sortBy(this.mappedItem.releaseStages, (result: any) => {
                        return result.createdDate;
                    }).reverse();
                }
            });
    }

}
