import {Component, Input} from '@angular/core';
import {IWorkingDrawingMappedItem} from '@app/logic/working-drawings';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {IWorkingDrawingItemDto} from '@classictechsolutions/hubapi-transpiled-enums';
// eslint-disable-next-line max-len
import {
    RequestWorkingDrawingEditItemDialogComponentComponent
} from '../../request-working-drawing-edit-item-dialog-component/request-working-drawing-edit-item-dialog-component.component';

@Component({
    selector: 'cb-request-working-drawing-item-component',
    templateUrl: './request-working-drawing-item-component.component.html',
    styleUrls: ['./request-working-drawing-item-component.component.scss']
})
export class RequestWorkingDrawingItemComponentComponent {

    @Input() public mappedItem: IWorkingDrawingMappedItem;
    @Input() public item: IWorkingDrawingItemDto;
    constructor(
        public readonly cbDialog: CbDialogService
    ) { }

    public editItemClicked(): void {
        const param = {

            item: this.item,
            mappedItem: this.mappedItem,
            mode: FormMode.Edit,
        };

        this.cbDialog.open(RequestWorkingDrawingEditItemDialogComponentComponent, {
            data: param
        });
    }

    public deleteItemClicked(item: IWorkingDrawingItemDto): void {
        item.isDeleted = true;
    }



}
