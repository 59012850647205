import { Component, Input } from '@angular/core';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';

@Component({
    selector: 'cb-agree-to-condition',
    templateUrl: './agree-to-condition.component.html',
    styleUrls: ['./agree-to-condition.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(AgreeToConditionComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class AgreeToConditionComponent extends BaseFormComponentDirective {
    @Input() public slim = true;
    @Input() public indeterminate = false;
    @Input() public labelPosition: 'before' | 'after' = 'after';

    public color(): string {
        if (!this.value && !this.disabled) {
            return 'warn';
        } else if (this.value && !this.disabled) {
            return 'primary';
        }
        return null;
    }
}
