import { Injectable, Injector } from '@angular/core';
import { IARVariationDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IARVariationLogicService } from './interfaces/i.ar.variation.logic-service';
import { IARVariationMappedItem } from './interfaces/i.ar.variation.mapped';
import { ARVariationMappedItem } from './ar-variation.mapped';
import { Observable } from 'rxjs';

@Injectable()
export class ARVariationLogicService
    extends BaseLogicService<IARVariationDto, IARVariationMappedItem>
    implements IARVariationLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'variations',
            ARVariationMappedItem
        );
    }

    public saveArChangeOptionDetails(dto: IARVariationDto): Observable<IARVariationDto> {
        return this.$http.post<IARVariationDto>(`${this.$baseUri}/updateardetails`, dto);
    }

}
