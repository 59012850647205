export enum PermissionsPermissionEnum {
    None                = 0x00,
    SettingsCanView     = 0x01 << 2,
    SettingsCanEdit     = 0x01 << 3,
    UsersCanView	    = 0x01 << 4,
    UsersCanEdit	    = 0x01 << 5,
    RolesCanView	    = 0x01 << 6,
    RolesCanEdit	    = 0x01 << 7,
    CanViewConnection   = 0x01 << 8
}
