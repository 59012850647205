import { Inject, Injectable, Injector } from '@angular/core';
import { IPermissionDto, IPermissionGroupAreaDto, IPermissionGroupDto, IPermissionGroupSummaryDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IPermissionGroupLogicService } from './interfaces/i.permission-group.logic.service';
import { IPermissionGroupMappedItem } from './interfaces/i.permission-group.mapped';
import { PermissionGroupMappedItem } from './permission-group.mapped';
import { Observable } from 'rxjs';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import _ from 'lodash';
import { IEnumNestedLookup } from '@app/shared/enums/IEnumNestedLookup';
import { PermissionGroupAreaLogicService } from './permission-group-area.logic.service';
import { BoolIdAndLabelDto } from '@app/shared/dtos/id-label.dto';
import { IFileResponse } from '@app/shared/interfaces/i.file-response.dto';

@Injectable()
export class PermissionGroupLogicService
    extends BaseLogicService<IPermissionGroupDto, IPermissionGroupMappedItem>
    implements IPermissionGroupLogicService {

    public permissionOptions: BoolIdAndLabelDto[] = [
        { id: true, label: 'Allow' },
        { id: undefined, label: 'Not Set' },
        { id: false, label: 'Deny' }
    ];

    public systemAreas: IEnumNestedLookup<number>[];

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        @Inject(LookupService) public readonly lookupService: LookupService,
        @Inject(PermissionGroupAreaLogicService) public readonly permissionGroupAreaService: PermissionGroupAreaLogicService
    ) {
        super('auth/roles', PermissionGroupMappedItem);
    }

    // Override base logic service save to use HTTP-put and no id
    public $saveItemReturnId(entity: IPermissionGroupDto): Observable<number> {
        return this.$http.put<number>(this.$baseUri, entity);
    }

    public async loadSystemAreasWithNestedPermissionsEnums(): Promise<void> {

        // get areas if required
        if (!this.systemAreas) {

            // Get permission 'area' labels
            await this.lookupService.SYSTEM_AREAS.toNestedLookup().$promise.then((systemAreasResult: IEnumNestedLookup<number>[]) => {
                this.systemAreas = systemAreasResult;

                this.setAreaPermissions();
            });
        }
    }


    private setAreaPermissions(): void {
        _.forEach(this.systemAreas, (systemArea) => {
            const enumName = _.lowerFirst(systemArea.label.replace(/\s/g, '')) + 'Permission';
            this.lookupService.getLookup(enumName).then((permissionResult: IEnumLookup<number>[]) => {
                systemArea.permissions = permissionResult;
            });
        });
    }

    public getPermissionGroupSummaries(): Observable<IPermissionGroupSummaryDto[]> {
        return this.$http.get<IPermissionGroupSummaryDto[]>(`${this.$baseUri}`);
    }

    public generateReport(): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(`${this.$baseUri}/report`,
            'Generating security roles report...',
            'get');
    }

    public generateRolePermissionsReport(roleIds: number[]): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(`${this.$baseUri}/export`,
            'Generating security role permissions report...',
            { roleIds },
            'get');
    }

    public async createNewPermissionGroup(): Promise<IPermissionGroupDto> {

        await this.loadSystemAreasWithNestedPermissionsEnums();

        const newPermissionsGroup: IPermissionGroupDto = {
            id: 0,
            name: '',
            isActive: true,
            areas: [] as IPermissionGroupAreaDto[],
        };

        this.systemAreas.forEach((systemArea) => {

            const areaPermissions: IPermissionGroupAreaDto = {
                area: systemArea.id,
                permissions: [] as IPermissionDto[]
            };

            _.forEach(systemArea.permissions, (permission: IEnumLookup<boolean>) => {
                const rolePermission: IPermissionDto = {
                    area: systemArea.id,
                    explicitAllow: null,
                    inheritedAllow: undefined,
                    name: permission.label.replace(/\s/g, ''),
                };
                areaPermissions.permissions.push(rolePermission);
            });

            newPermissionsGroup.areas.push(areaPermissions);
        });

        return newPermissionsGroup;
    }
}
