import {CurrentUserService} from '@app/core/authentication/current.user';
import FileSaver from 'file-saver';
import {ToastService} from '@app/core/services/toast/toast.service';
import {HttpWrapperService} from '@app/core/services/http-wrapper/http-wrapper.service';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {stringifyReadableError, toPromise} from 'cb-hub-lib';

type JsonString = string | null;

interface IHttpRequest {
    body: JsonString;
    hasToken: boolean;
    method: string;
    url: string;
    urlWithParams: string;
}

interface IHttpErrorLog {
    error: JsonString;
    message: string;
    status: number;
    statusText: string;
    url: string;
    request: IHttpRequest;
    origin: 'AngularX' | 'AngularJS';
    timestamp: Date;
}

export interface IHubAPIError {
    message: string;
    modelState: { [key: string]: string[] };
}

@Injectable({
    providedIn: 'root'
})
export class NewHttpErrorsService {

    public errors: IHttpErrorLog[] = [];

    public get sessionStorageErrorsKey(): string {
        return `httperrorlogs_${this.currentUser.guid}`;
    }

    constructor(
        private readonly toastService: ToastService,
        private readonly http: HttpWrapperService,
        private readonly currentUser: CurrentUserService
    ) {
    }
    public test404(): void {
        toPromise(this.http.get('/dev/tools/test404'));
    }

    public testError(): void {
        toPromise(this.http.get('/dev/tools/testerror'));
    }

    public clearLogs(): void {
        sessionStorage.removeItem(this.sessionStorageErrorsKey);
    }

    public downloadLogs(): void {
        const now = JSON.stringify(new Date()); // using JSON.stringify here because it formats the date nicely for the file name e.g. 2019-11-18T02_03_14.066Z
        const fileName = `UserSessionHttpErrorLog${now}${this.currentUser.userName}_${this.currentUser.guid}.json`;
        const file = new Blob([sessionStorage.getItem(this.sessionStorageErrorsKey)], { type: 'application/json' });
        FileSaver.saveAs(file, fileName);
    }

    public log(req: HttpRequest<any>, err: HttpErrorResponse): void {
        const stringified = this.stringifyOrNull(err.error);

        const log: IHttpErrorLog = {
            origin: 'AngularX',
            timestamp: new Date(),
            error: stringified,
            message: err.message,
            status: err.status,
            statusText: err.statusText,
            url: err.url,
            request: {
                body: req.body ? JSON.stringify(req.body) : null,
                hasToken: !!req.headers.get('Authorization'),
                method: req.method,
                url: req.url,
                urlWithParams: req.urlWithParams
            },
        };

        this.logError(log, stringifyReadableError(err.error));
    }

    private logError(log: IHttpErrorLog, errorMessage: string): void {
        const key = this.sessionStorageErrorsKey;
        const logs = (JSON.parse(sessionStorage.getItem(key)) || []) as IHttpErrorLog[];
        logs.push(log);
        sessionStorage.setItem(key, JSON.stringify(logs));
        this.toastService.showToast(`${log.statusText} - ${errorMessage}`);
    }

    private stringifyOrNull(obj: Object | any): string | null {
        return obj ? JSON.stringify(obj) : null;
    }
}
