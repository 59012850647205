import {BaseMappedItem} from '@app/logic/base/base-mapped-item';
import {IDesignRequirementsMappedItem} from '@app/logic/design-requirements/interfaces/i.design-requirements.mapped';
import {IDesignRequirementsLogicService} from '@app/logic/design-requirements/interfaces/i.design-requirements-logic.service';
import {DtoProp} from '@app/logic/base/dto-prop.decorator';
import {IDesignRequirementsDto} from '@classictechsolutions/hubapi-transpiled-enums';

export class DesignRequirementsMappedItem
    extends BaseMappedItem<IDesignRequirementsDto, IDesignRequirementsMappedItem, IDesignRequirementsLogicService>
    implements IDesignRequirementsMappedItem {
    @DtoProp public lotId: number;

    constructor(
        sourceData: IDesignRequirementsDto,
        logicService: IDesignRequirementsLogicService
    ) {
        super(sourceData, logicService, DesignRequirementsMappedItem);
    }

    public get documentEntityId(): number {
        return this.lotId;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }
}
