
/**
 * Selects html element contents by selector
 *
 * @returns boolean with indicates success or failure
 */
export function selectElementContents(selector: string): boolean {
    const el = document.querySelector(selector);

    if (!el) {
        return false;
    }

    try {
        const body = document.body; let range; let sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(el);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(el);
                sel.addRange(range);
            }
            // eslint-disable-next-line @typescript-eslint/dot-notation
        } else if (body['createTextRange']) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            range = body['createTextRange']();
            range.moveToElementText(el);
            range.select();
        }
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Selects html element contents by selector and then copys it to the users clipboard
 *
 * @returns boolean with indicates success or failure
 */
export function copyElementContentsToClipboard(selector: string): boolean {
    const result = selectElementContents(selector);
    if (result) {
        return document.execCommand('copy');
    }
    return result;
}
