import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {CbInfiniteScrollModule} from '../../directives/infinite-scroll/infinite-scroll.module';
import {CbDasherModule} from '../../pipe/dasher/dasher.module';
import {CbInfoMessageModule} from '../info-message/info-message.module';
import {CbInfoPopupModule} from '../info-popup/info-popup.module';
import {CbLoadingSpinnerModule} from '../loading-spinner/loading-spinner.module';
import {CB_TABLE_EXPORTS} from './exports';

const COMPONENTS = [
    ...CB_TABLE_EXPORTS,
];

@NgModule({
    declarations: [
        COMPONENTS
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        CbInfoPopupModule,
        CbDasherModule,
        CbInfoMessageModule,
        MatSelectModule,
        MatOptionModule,
        MatCardModule,
        MatCheckboxModule,
        CbLoadingSpinnerModule,
        MatTableModule,
        InfiniteScrollModule,
        CbInfiniteScrollModule,
    ],
    exports: COMPONENTS,
})
export class CbTableModule {
}
