<cb-dialog *cbWaitFor="data"
           dialogHeading="{{data.formMode === FormMode.Edit ? 'Edit' : 'Add' }} Item">
    <form class="flex-col flex"
          #itemForm="ngForm">
        <cb-input type="number"
                  name="itemNumber"
                  label="Item#"
                  [required]="true"
                  [(ngModel)]="tempItem.itemNumber"></cb-input>
        <cb-text-area name="description"
                      label="Item Description"
                      [required]="true"
                      maxlength="1000"
                      [(ngModel)]="tempItem.description">
        </cb-text-area>
        <div *ngIf="data.formMode === FormMode.Edit">
            <cb-select name="itemStatus"
                       label="Item Status"
                       [required]="true"
                       [options]="this.statuses"
                       [ngModel]="tempItem.statusId"
                       (ngModelChange)="statusChanged($event)">
            </cb-select>
            <cb-text-area name="comments"
                          label="Comments"
                          [required]="commentRequired"
                          maxlength="1000"
                          [(ngModel)]="tempItem.comments"
                          [label]="commentRequired ? commentRequiredMessage : 'Comments'">
            </cb-text-area>
        </div>
    </form>
    <footer class="end-center">
        <button *ngIf="!this.data.addAndSave"
                mat-raised-button
                [disabled]="!itemForm.dirty || !itemForm.valid"
                color="primary"
                (click)="addToList()">
            {{data.formMode === FormMode.Edit ? 'Save' : 'Add' }}
        </button>
        <button *ngIf="this.data.addAndSave"
                mat-raised-button
                [disabled]="!itemForm.dirty || !itemForm.valid"
                color="primary"
                (click)="saveItem()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
