import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { CbDialogContainerModule } from '@app/shared/components/dialog/dialog-container/dialog-container.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbSortByImpureModule } from '@app/shared/pipe/sort-by-impure/sort-by-impure.module';
import { CbTableModule } from 'cb-hub-lib';
import { CbInfoPopupModule } from '../../../../../projects/cb-hub-lib/src/lib/components/info-popup/info-popup.module';
import { LotBuildDocumentsModule } from './documents/documents.module';
import { LotBuildAreaComponent } from './lot-build-area/lot-build-area.component';
import { LotBuildOverheadCostsDialogComponent } from './lot-build-overhead-costs/lot-build-overhead-costs-dialog/lot-build-overhead-costs-dialog.component';
import { LotBuildOverheadCostsComponent } from './lot-build-overhead-costs/lot-build-overhead-costs.component';
import { LotBuildOrdersModule } from './orders/orders.module';
import { LotBuildProgrammeModule } from './programme/programme.module';
import { CbDisplayValueModule } from "../../../shared/components/display-value/display-value.module";


@NgModule({
    declarations: [LotBuildAreaComponent, LotBuildOverheadCostsComponent, LotBuildOverheadCostsDialogComponent],
    exports: [
        LotBuildAreaComponent,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        CbTabRouteModule,
        LotBuildOrdersModule,
        LotBuildProgrammeModule,
        LotBuildDocumentsModule,
        CbWaitForModule,
        CbTableModule,
        MatButtonModule,
        MatIconModule,
        CbDialogContainerModule,
        CbFormsModule,
        FormsModule,
        MatDialogModule,
        MatInputModule,
        MatAutocompleteModule,
        CbInfoPopupModule,
        CbUserDateTimeModule,
        CbSortByImpureModule,
        CbCurrencyModule,
        CbDisplayValueModule
    ]
})

export class LotBuildModule {
}
