import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BuildTypeLogicService } from '@app/logic/build-type';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { IDesignSchemesLogicService } from '@app/logic/design-schemes/interfaces/i.design-schemes-logic.service';
import { ILotMappedItem } from '@app/logic/lots';
import { StandardPlanLogicService } from '@app/logic/standard-plans';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { toPromisedArray } from 'cb-hub-lib';
import { IDesignSchemeDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-finalise-dialog',
    templateUrl: './finalise-dialog.component.html',
    styleUrls: ['./finalise-dialog.component.scss']
})
export class DesignSchemeFinaliseDialogComponent extends BaseDialogFormViewDirective<IDesignSchemeDto, IDesignSchemeMappedItem, IDesignSchemesLogicService> {
    public static readonly MIN_WIDTH = '66%';
    public buildTypes = toPromisedArray(this.buildTypeLogic.getListByLotType(this.data.lotMappedItem.lotType));
    public standardPlans = toPromisedArray(this.standardPlanLogic.$getList());
    public designComplexities = this.lookups.DESIGN_COMPLEXITY.toLookup();

    public get mappedItem(): IDesignSchemeMappedItem {
        return this.data.mappedItem;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<DesignSchemeFinaliseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IDesignSchemeMappedItem;
            lotMappedItem: ILotMappedItem;
        },
        @Inject(CbDialogService) private readonly cbDialog: CbDialogService,
        @Inject(BuildTypeLogicService) private readonly buildTypeLogic: BuildTypeLogicService,
        @Inject(StandardPlanLogicService) private readonly standardPlanLogic: StandardPlanLogicService,
        @Inject(LookupService) private readonly lookups: LookupService,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public isSaveDisabled(form: NgForm): boolean {
        return form.invalid;
    }

    public confirmSubmitScheme(): void {
        this.cbDialog
            .confirm({
                dialogHeading: 'Submit Design Scheme',
                message: 'Are you sure you want to submit this Design Scheme?',
                confirmed: () => {
                    this.mappedItem
                        .$save()
                        .subOnce(result => this.dialogRef.close(result));
                }
            });
    }
}
