import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export enum SimpleFormInputType {
    Textarea = 1,
    DatePicker = 2
}

@Component({
    selector: 'cb-simple-form-dialog',
    templateUrl: './simple-form-dialog.component.html',
    styleUrls: ['./simple-form-dialog.component.scss'],
})
export class SimpleFormDialogComponent {
    public readonly SIMPLE_FORM_INPUT_TYPE = SimpleFormInputType;

    constructor(
        public readonly dialogRef: MatDialogRef<SimpleFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            heading: string;
            yesLabel: string;
            noLabel: string;
            value: string;
            formInputConfig: {
                type: SimpleFormInputType;
                label: string;
                maxlength?: number;
                required: boolean;
            };
        },
    ) {
        data.yesLabel = data.yesLabel ?? 'Yes';
        data.noLabel = data.noLabel ?? 'No';
    }

    public save(): void {
        this.dialogRef.close(this.data.value);
    }

    public close(): void {
        this.dialogRef.close(false);
    }
}
