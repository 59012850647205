import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortableHeaderCellComponent } from './sortable-header-cell.component';

const COMPONENTS = [
    SortableHeaderCellComponent
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        CommonModule,
        MatIconModule,
        MatToolbarModule,
        MatTooltipModule
    ]
})
export class CbSortableHeaderCellModule { }
