import { Component, Input, OnInit } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LotDesignPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { BuildTypeLogicService } from '@app/logic/build-type';
import { DesignSchemesLogicService, IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { LotsLogicService } from '@app/logic/lots';
import { StandardPlanLogicService } from '@app/logic/standard-plans';
import { TagsLogicService } from '@app/logic/tags/tags.logic.service';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import {
    IBuildTypeDto,
    IStandardPlanDto,
    LotTypeEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { approverTags } from '../../../shared/approver-tags.data';

@Component({
    selector: 'cb-request-design-scheme-step-one',
    templateUrl: './request-scheme-step-one.component.html',
    styleUrls: ['./request-scheme-step-one.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class RequestDesignSchemesStepOneComponent implements OnInit {
    @Input() public mappedItem: IDesignSchemeMappedItem;
    @Input() public lotType: LotTypeEnumId;

    public buildTypes: IBuildTypeDto[];
    public standardPlans: IStandardPlanDto[];
    public selectedStandardPlan: IStandardPlanDto;
    public selectedApprover: { name: string; id: string };
    public readonly approverTags = approverTags;

    constructor(
        public readonly userCacheService: UserCacheService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly currentUser: CurrentUserService,
        public logicService: DesignSchemesLogicService,
        public lotsLogicService: LotsLogicService,
        public readonly buildTypesLogicService: BuildTypeLogicService,
        public readonly standardPlanLogicService: StandardPlanLogicService,
        public readonly teamsLogicService: TeamsLogicService,
        public readonly tagsLogicService: TagsLogicService,
        public readonly navigationService: NavigationService,
        public readonly cbDialog: CbDialogService
    ) {
    }

    public approverChanged(): void {
        if (this.selectedApprover) {
            this.mappedItem.approverUserId = this.selectedApprover.id;
            this.mappedItem.approverUserName = this.selectedApprover.name;
        }
    }

    public ngOnInit(): void {
        this.buildTypesLogicService.getListByLotType(this.lotType).subOnce(types => this.buildTypes = types);
        this.standardPlanLogicService.$getList(list => this.standardPlans = list);
        this.setSelectedApprover();
    }

    private setSelectedApprover(): void {
        if (!isNullOrWhiteSpace(this.mappedItem.approverUserId)) {
            this.selectedApprover = {
                id: this.mappedItem.approverUserId,
                name: this.mappedItem.approverUserName
            };
        } else if (!(this.mappedItem.id > 0)) {
            this.selectedApprover = {
                id: this.currentUser.guid,
                name: this.currentUser.profile.name
            };
        }
        this.approverChanged();
    }
}
