
import { IProductAttributeDto } from '@app/logic/product-attributes/interfaces/i.product-attribute.dto';
import {
    ISaveableLotSpecItemAttributeManageDto,
    LotSpecItemManageAttributeEntityTypeEnumId,
    LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { merge } from 'lodash';

const ENTITY_TYPE_ICON = {
    [LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM.LotSpecItem]: 'style',
    [LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM.ColourItem]: 'format_paint',
};

const ENTITY_TYPE_TITLE = {
    [LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM.LotSpecItem]: 'Lot Specification Item',
    [LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM.ColourItem]: 'Colour Item'
};

export class LotSpecItemAttributeManage {
    public readonly id: number;
    public readonly entityType: LotSpecItemManageAttributeEntityTypeEnumId;
    public readonly label: string;
    public readonly specGroupId: number;
    public readonly specGroupLabel: string;
    public readonly manualColourEntryRequired: boolean;
    public readonly attributes: IProductAttributeDto[];

    public selectedOptions: { [key: number]: number };
    public colourName: string;
    public notes: string;

    public readonly icon: string;
    public readonly title: string;
    public readonly notesRowSpan: number;
    public hasChanged = false;

    constructor(private init: Partial<LotSpecItemAttributeManage>) {
        merge(this, this.init);
        this.icon = ENTITY_TYPE_ICON[this.entityType];
        this.title = ENTITY_TYPE_TITLE[this.entityType];

        const hasAttributes = this.attributes != undefined;

        if (hasAttributes) {
            this.attributes.forEach(a => {
                a.options.unshift({ id: undefined, name: 'None' } as any);
            });
        }

        const itemsCount = hasAttributes ? this.attributes.length : 0;
        this.notesRowSpan = itemsCount + (this.manualColourEntryRequired ? 2 : 1);
    }

    /** returns truthy if this item has an empty attribute or colour field */
    public anyEmptyAttributes(): boolean {
        let anyEmptyAttributes = this.attributes != undefined
            && this.attributes.some(a =>
                // if there are no valid/selectable options this should not be considered an empty attribute
                a.options.filter(opt => opt.id > 0).length > 0
                // if there are valid/selectable options then check that an option has been selected
                && this.selectedOptions[a.attributeId] == undefined);

        if (anyEmptyAttributes === false && this.manualColourEntryRequired) {
            anyEmptyAttributes = !this.colourName || this.colourName.trim().length < 1;
        }

        return anyEmptyAttributes;
    }

    /** returns saveable item dto if it has been changed */
    public getSaveableItem(): ISaveableLotSpecItemAttributeManageDto {
        if (!this.hasChanged) {
            return;
        }

        return {
            colourName: this.manualColourEntryRequired ? this.colourName : undefined,
            entityType: this.entityType,
            id: this.id,
            notes: this.notes,
            selectedOptions: this.selectedOptions
        } as ISaveableLotSpecItemAttributeManageDto;
    }
}
