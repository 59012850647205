import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { IPreConsentPlansLogicService } from './interfaces/i.pre-consent-plans-logic.service';
import {
    IPreConsentPlanDto,
    IPreConsentPlanDocumentDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IPreConsentPlanMappedItem } from './interfaces/i.pre-consent-plan.mapped';
import { PreConsentPlanMappedItem } from './pre-consent-plan.mapped';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { IDesignTeamPreconsentPlansSearch } from '@app/core/services/user-cache/user-cache-areas';

@Injectable()
export class PreConsentPlansLogicService
    extends BaseLogicServiceWithSearch<
    IPreConsentPlanDto,
    IPreConsentPlanMappedItem,
    IDesignTeamPreconsentPlansSearch,
    IPreConsentPlanDocumentDto>
    implements IPreConsentPlansLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('preconsentplans', PreConsentPlanMappedItem);
    }

    public request(dto: IPreConsentPlanDto): Observable<IPreConsentPlanDto> {
        return this.$http.post<IPreConsentPlanDto>(`${this.$baseUri}/request`, dto);
    }

    public create(dto: IPreConsentPlanDto): Observable<IPreConsentPlanDto> {
        return this.$http.post<IPreConsentPlanDto>(`${this.$baseUri}/create`, dto);
    }

    public $saveItem<ReturnType>(preConsentPlan: IPreConsentPlanDto): Observable<ReturnType> {
        return this.$http
            .post<ReturnType>(this.$baseUri, preConsentPlan);
    }

    public finalise(preConsentPlanId: number): Observable<IPreConsentPlanDto> {
        return this.$http
            .post<IPreConsentPlanDto>(`${this.$baseUri}/${preConsentPlanId}/finalise`);
    }

    public reject(preConsentPlanId: number, dto: IPreConsentPlanDto): Observable<IPreConsentPlanDto> {
        return this.$http
            .post<IPreConsentPlanDto>(`${this.$baseUri}/${preConsentPlanId}/reject`, dto);
    }

    public getElapsedTime(preConsentPlanId: number): Observable<string> {
        return this.$http.get<string>(`preconsents/elapsedtime/${preConsentPlanId}`);
    }
}
