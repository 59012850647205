import {Component, Input} from '@angular/core';
import {CurrentUserService} from '@app/core/authentication/current.user';
import {LotDesignPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {UserCacheService} from '@app/core/services/user-cache/user-cache.service';
import {IDesignSchemeMappedItem} from '@app/logic/design-schemes';
import {ITeamsMappedItem} from '@app/logic/teams/interfaces/i.teams.mapped';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {IBuildTypeDto, IDesignRequirementItemDto, IStandardPlanDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {RequestDesignSchemesEditItemDialogComponent} from '../request-schemes-edit-item-dialog/request-schemes-edit-item-dialog.component';


@Component({
    selector: 'cb-request-schemes-item',
    templateUrl: './request-schemes-item.component.html',
    styleUrls: ['./request-schemes-item.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class RequestSchemesItemComponent {

    @Input() public mappedItem: IDesignSchemeMappedItem;
    @Input() public designerView: boolean;
    @Input() public item: IDesignRequirementItemDto;

    public buildTypes: IBuildTypeDto[];
    public standardPlans: IStandardPlanDto[];
    public selectedStandardPlan: IStandardPlanDto;

    public approverUsers: ITeamsMappedItem[];
    constructor(
        public readonly userCacheService: UserCacheService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly currentUser: CurrentUserService,
        public readonly navigationService: NavigationService,
        public readonly cbDialog: CbDialogService
    ) {
    }

    public editItemClicked(): void {
        const param = {
            designerView: this.designerView,
            item: this.item,
            mappedItem: this.mappedItem,
            mode: FormMode.Edit,
        };

        this.cbDialog.open(RequestDesignSchemesEditItemDialogComponent, {
            data: param
        });
    }

    public deleteItemClicked(item: IDesignRequirementItemDto): void {
        item.isDeleted = true;
    }
}
