import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IBuildingConsentMappedItem, IBuildingConsentDto, IBuildingConsentLogicService } from '@app/logic/lot-consents';
import { Observable } from 'rxjs';
import { USER_TAG_CONSTANTS_CONST, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    buildingConsent: any;
    hasPrimaryBuildingConsent: boolean;
}

@Component({
    selector: 'cb-edit-building-consent-dialog',
    templateUrl: './edit-dialog.component.html',
    styleUrls: ['./edit-dialog.component.scss']
})
export class EditBuildingConsentDialogComponent
    extends BaseDialogFormViewDirective<IBuildingConsentDto, IBuildingConsentMappedItem, IBuildingConsentLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '33%';

    public get mappedItem(): IBuildingConsentMappedItem {
        return this.data.buildingConsent;
    }

    public assignedTo = {} as { name: string; id: string };

    public readonly DESIGN_TEAM = TEAM_CODES_CONST.DesignTeamKey;
    public readonly BUILDING_COORDINATOR = USER_TAG_CONSTANTS_CONST.BUILDING_COORDINATOR;

    constructor(
        public readonly dialogRef: MatDialogRef<EditBuildingConsentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.assignedTo = {
            name: this.mappedItem.assignedToUserName,
            id: this.mappedItem.assignedToUserId,
        };
    }

    public saveMethod(): Observable<IBuildingConsentDto> {
        this.mappedItem.assignedToUserId = this.assignedTo.id;
        return this.mappedItem.$save();
    }

}
