import { EventEmitter, Injectable } from '@angular/core';
import { LotPermissions } from '@app/core/permissions';
import { ILotSpecColourItemMappedItem } from '@app/logic/lot-spec-colour-item';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ILotSpecColourItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { EditLotSpecColourItemDialogComponent } from '../edit-lot-spec-colour-item-dialog/edit-lot-spec-colour-item-dialog.component';


export interface IHaveLotSpecColourItemUiActions {
    readonly uiActions: LotSpecColourItemUIActions;
    readonly lotSpec: ILotSpecMappedItem;
    readonly mappedItem: ILotSpecColourItemMappedItem;
    readonly itemDeleted: EventEmitter<ILotSpecColourItemDto>;
    readonly itemEdited: EventEmitter<ILotSpecColourItemDto>;

}

@Injectable()
export class LotSpecColourItemUIActions {

    private ctrl: IHaveLotSpecColourItemUiActions;

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly lotPermissions: LotPermissions,
    ) { }

    public setController(ctrl: IHaveLotSpecColourItemUiActions): void {
        this.ctrl = ctrl;
    }

    public editColourItem(): void {
        const originalSpecGroupId = this.ctrl.mappedItem.specGroupId;

        const dialogRef = this.cbDialog.open(
            EditLotSpecColourItemDialogComponent,
            {
                minWidth: '45%',
                data: {
                    locked: this.ctrl.lotSpec.isLocked,
                    mappedItem: this.ctrl.mappedItem.$clone(),
                    lotSpec: this.ctrl.lotSpec,
                }
            }
        );

        dialogRef.afterClosed().subOnce(result => {
            if (result && (originalSpecGroupId !== result.specGroupId)) {
                this.ctrl.itemEdited.emit(result);
            }
        });
    }

    public canChangeSpecification(): boolean {
        return this.lotPermissions.canChangeSpecification();
    }

    public removeColourItem(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Colour Item',
            message: 'Are you sure you want delete this colour item from the Lot Specification?',
            confirmed: () => {
                this.ctrl.mappedItem.delete()
                    .subOnce((result) => {
                        if (result) {
                            this.ctrl.itemDeleted.emit(this.ctrl.mappedItem.$getMappedDtoItem());
                        }
                    });
            }
        });
    }

}
