import { BaseMappedItem } from '../base/base-mapped-item';
import { IDesignComplexityLogicService } from './interfaces/i.design-complexity.logic.service';
import { IDesignComplexityMappedItem } from './interfaces/i.design-complexity.mapped';
import { IDesignComplexityDto } from './interfaces/i.design-complexity.dto';
import { DtoProp } from '../base/dto-prop.decorator';

export class DesignComplexityMappedItem
    extends BaseMappedItem<IDesignComplexityDto, IDesignComplexityMappedItem, IDesignComplexityLogicService>
    implements IDesignComplexityMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public complexity: string;
    @DtoProp public targetWorkHours: number;
    @DtoProp public targetCompletionHours: number;
    @DtoProp public isActive: boolean;

    constructor(
        sourceData: IDesignComplexityDto,
        logicService: IDesignComplexityLogicService
    ) {
        super(
            sourceData,
            logicService,
            DesignComplexityMappedItem
        );
    }
}
