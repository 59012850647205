<cb-dialog dialogHeading="Apply Specification Template">
    <form #applySpecTemplateForm="ngForm">
        <p class="cb-margin-bottom">Create your specification from an existing Template. Select the appropriate option
            below: </p>
        <cb-display-value-new label="Lot Site/Job Number"
                              [value]="data.lot.jobNumber"></cb-display-value-new>
        <cb-display-value-new label="Lot Address">
            <span class="flex">
                {{data.lot.lotAddress.street}}
                <br/>
                {{data.lot.lotAddress.suburb}}
            </span>
        </cb-display-value-new>
        <cb-select label="Spec Template to Apply"
                   name="specTemplate"
                   [required]="true"
                   [(ngModel)]="selectedTemplateId"
                   [options]="templates"
                   labelProp="name">
        </cb-select>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                (cbClick)="confirmApplySpecTemplate()"
                [disabled]="applyInProgress || applySpecTemplateForm.invalid || applySpecTemplateForm.pristine">Apply</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
