import { Component } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
    BuildProgrammeTemplateLogicService, IBuildProgrammeTemplateDto, IBuildTemplateActivityDto, IBuildTemplateActivityRelationshipDto,
    IBuildTemplateStageDto
} from '@app/logic/build-programme-template';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { cloneDeep, filter, find, findIndex, flatten, includes, map, some } from 'lodash';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { BuildProgrammeTemplatePermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { BuildProgrammeTemplateActivityDialogComponent } from '../build-programme-template-activity-dialog/build-programme-template-activity-dialog.component';
import { BuildStagesLogicService, IBuildStageDto } from '@app/logic/build-stages';
import { BuildActivitiesLogicService, ISkinnyBuildActivityDto } from '@app/logic/build-activities';
import { DragulaService } from 'ng2-dragula';

enum TabNames {
    Template = 'template',
    History = 'history',
}

enum Tabs {
    Template,
    History,
}

@Component({
    selector: 'cb-build-programme-template-view',
    templateUrl: './build-programme-template-view.component.html',
    styleUrls: ['./build-programme-template-view.component.scss']
})
export class BuildProgrammeTemplateViewComponent {

    public templateId: number;
    public currentTabIndex = Tabs.Template;
    public template: IBuildProgrammeTemplateDto;
    public isEditing$ = new BehaviorSubject<boolean>(undefined);
    public refresh$ = new BehaviorSubject<boolean>(undefined);
    public expansionPanelStates: { [key: number]: boolean } = {};
    public buildStages: IBuildStageDto[];
    public availableStages: IBuildStageDto[];
    public buildActivities: ISkinnyBuildActivityDto[];
    public availableActivities: ISkinnyBuildActivityDto[];
    public activityFilter: string;
    public subscriptions$ = new Subscription();

    public readonly activityColumns = ['', 'Code', 'Name', 'Duration', ''];
    public readonly BUILD_STAGES = 'BUILD_STAGES';
    public readonly BUILD_ACTIVITIES = 'BUILD_ACTIVITIES';

    constructor(
        private readonly route: ActivatedRoute,
        private readonly navigationService: NavigationService,
        private readonly currentUserService: CurrentUserService,
        private readonly cbDialog: CbDialogService,
        private readonly buildProgrammeTemplatePermissions: BuildProgrammeTemplatePermissions,
        private readonly buildProgrammeTemplateLogic: BuildProgrammeTemplateLogicService,
        private readonly buildStagesLogic: BuildStagesLogicService,
        private readonly buildActivitiesLogic: BuildActivitiesLogicService,
        private readonly dragulaService: DragulaService,
    ) {
        this.isEditing$.subscribe((isEditing: boolean) => {
            if (isEditing === false) {
                this.refresh();
            }
            this.setAvailableStages();
            this.setAvailableActivities();
        });
        this.refresh$.subscribe(() => this.refresh());
        this.buildStagesLogic.getAllNonPreConsentStageBuildStages().subOnce(result => this.buildStages = result ?? []);
        this.buildActivitiesLogic.getSkinnyList().subOnce(result => this.buildActivities = result ?? []);
        this.setupDragula();
    }

    public ngOnInit(): void {
        this.route.params.subOnce((params: { [key: string]: string }) => {
            this.templateId = params.id as unknown as number;
            this.currentTabIndex = params.tabName === TabNames.Template ? Tabs.Template : Tabs.History;
            this.refresh();
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.dragulaService.destroy(this.BUILD_STAGES);
        this.dragulaService.destroy(this.BUILD_ACTIVITIES);
    }

    public tabIndexChanged(tabIndex: Tabs): void {
        const tabName = tabIndex === Tabs.Template ? TabNames.Template : TabNames.History;
        this.navigationService.navigate([`/build-programme-templates/view/${this.templateId}/${tabName}`]);
    }

    public refresh(): void {
        if (this.templateId) {
            this.buildProgrammeTemplateLogic.$getItem(this.templateId).subOnce((result: IBuildProgrammeTemplateDto) => this.template = result);
        }
    }

    public expandOrCollapseAll(): void {
        if (this.template.stages?.length) {
            if (this.isAllExpanded()) {
                this.template.stages.forEach(stage => this.expansionPanelStates[stage.buildStageId] = false);
            } else {
                this.template.stages.forEach(stage => this.expansionPanelStates[stage.buildStageId] = true);
            }
        }
    }

    public isAllExpanded(): boolean {
        return this.template?.stages?.length ? !find(this.template?.stages, stage => !this.expansionPanelStates[stage.buildStageId]) : false;
    }

    public canEdit(): boolean {
        let canEdit = false;
        if (this.buildProgrammeTemplatePermissions.canEditAll()) {
            canEdit = true;
        } else if (this.template) {
            canEdit = this.template.regions.some(i => {
                return includes(this.currentUserService.regionsids ?? [], i) && this.buildProgrammeTemplatePermissions.canEdit();
            });
        }
        return canEdit;
    }

    public canRemoveActivity(activity: IBuildTemplateActivityDto): boolean {
        if (activity.hasDependencies === undefined) {
            activity.hasDependencies = (this.template && find(this.template.stages, (stage) => {
                return find(stage.activities, (stageActivity: IBuildTemplateActivityDto) => {
                    return find(stageActivity.relationships, (dependency: IBuildTemplateActivityRelationshipDto) => {
                        return dependency.predecessorBuildProgrammeActivityId === activity.id;
                    });
                });
            })) !== undefined;
        }

        return !activity.hasDependencies;
    }

    public activityEditClicked(stage: IBuildTemplateStageDto, activity: IBuildTemplateActivityDto): void {
        const dialog = this.cbDialog.open(BuildProgrammeTemplateActivityDialogComponent, {
            data: {
                dialogHeading: 'Edit Activity',
                template: this.template,
                stage,
                activity,
                refresh$: this.refresh$,
            }
        });
        dialog.afterClosed().subOnce(() => this.refresh());
    }

    public removeStageClicked(stage: IBuildTemplateStageDto): void {
        this.template.stages = filter(this.template.stages, (item: IBuildTemplateStageDto) => item.buildStageId !== stage.buildStageId);
        this.setAvailableStages();
    }

    public removeActivityClicked(stage: IBuildTemplateStageDto, activity: IBuildTemplateActivityDto): void {
        stage.activities = filter(stage.activities, (item: IBuildTemplateActivityDto) => item.activity.id !== activity.activity.id);
        this.setAvailableActivities();
    }

    public saveClicked(): void {
        const stages = map(this.template.stages, (stage: IBuildTemplateStageDto) => {
            return {
                buildStageId: stage.buildStageId,
                activities: stage.activities ?? []
            } as IBuildTemplateStageDto;
        });
        if (stages.length) {
            for (let i = 0; i < stages.length; i++) {
                stages[i].sortOrder = i;
                if (stages[i].activities?.length) {
                    for (let j = 0; j < stages[i].activities.length; j++) {
                        stages[i].activities[j].sortOrder = j;
                    }
                }
            }
        }
        this.buildProgrammeTemplateLogic.saveStages(this.templateId, stages).subOnce(() => this.isEditing$.next(false));
    }

    public setAvailableActivities(): void {
        const exisitingActivities = flatten(map(this.template?.stages ?? [], (stage: IBuildTemplateStageDto) => stage.activities ?? []));
        this.availableActivities = this.buildActivities
            ? filter(this.buildActivities.slice(), (activity: ISkinnyBuildActivityDto) => !some(exisitingActivities,
                (item: IBuildTemplateActivityDto) => item.activity.id === activity.id)
                && (this.activityFilter ? activity.code.toLowerCase().includes(this.activityFilter) || activity.name.toLowerCase().includes(this.activityFilter) : true))
            : [];
    }

    private setAvailableStages(): void {
        this.availableStages = this.buildStages && this.template?.stages
            ? filter(this.buildStages.slice(), (stage: IBuildStageDto) => !some(this.template.stages, { buildStageId: stage.id }))
            : [];
    }

    private setupDragula(): void {
        this.dragulaService.createGroup(this.BUILD_STAGES, {
            removeOnSpill: false,
            revertOnSpill: true,
            accepts: (el: Element, container: Element) => el.classList.contains('dragula-stage') && container.classList.contains('dragula-stage-drop'),
            moves: (el: Element, container: Element, target: Element) => target.classList.contains('dragula-stage-handler'),
        });

        this.dragulaService.createGroup(this.BUILD_ACTIVITIES, {
            removeOnSpill: false,
            revertOnSpill: true,
            accepts: (el: Element, container: Element) => el.classList.contains('dragula-activity') && container.classList.contains('dragula-activity-drop'),
            moves: (el: Element) => el.classList.contains('dragula-activity'),
        });

        this.subscriptions$.add(
            this.dragulaService.dropModel(this.BUILD_STAGES).subscribe(({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                if (!item.buildStageId && target !== source) {
                    const cloneForSource = cloneDeep(item);
                    sourceModel.splice(sourceIndex, cloneForSource);

                    const cloneForTarget = cloneDeep(item);
                    cloneForTarget.buildStageId = item.id;
                    cloneForTarget.buildStageCode = item.code;
                    cloneForTarget.buildStageLabel = item.label;
                    cloneForTarget.activities = [];
                    delete cloneForTarget.id;
                    targetIndex = targetModel?.length === 1 ? 0 : targetIndex;
                    targetModel[targetIndex] = cloneForTarget;

                    el.remove();
                    setTimeout(this.setAvailableStages.bind(this));
                }
            }));

        this.subscriptions$.add(
            this.dragulaService.dropModel(this.BUILD_ACTIVITIES).subscribe(({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                if (!item.activity && target !== source) {
                    const cloneForSource = cloneDeep(item);
                    sourceModel.splice(sourceIndex, cloneForSource);

                    const cloneForTarget = cloneDeep(item);
                    cloneForTarget.activity = {
                        id: cloneForTarget.id,
                        name: cloneForTarget.name,
                        code: cloneForTarget.code
                    };
                    cloneForTarget.relationships = [];
                    delete cloneForTarget.id;

                    targetIndex = targetIndex === -1 ? findIndex(targetModel, { code: item.code }) : targetModel?.length === 1 ? 0 : targetIndex;
                    targetModel[targetIndex] = cloneForTarget;

                    el.remove();
                    setTimeout(this.setAvailableActivities.bind(this));
                }
            }));
    }
}
