<cb-dialog dialogHeading="{{getTitle()}}">
    <form class="flex-col flex"
          #addProductToBundleForm="ngForm">
        <cb-display-value-new label="Bundle Item"
                              [value]="bundleTemplateItem.name">
          </cb-display-value-new>
        <cb-display-value-new label="Select Product from Category"
                              [value]="bundleTemplateItem.categoryPath">
          </cb-display-value-new>
        <cb-product-autocomplete-scroller class="flex"
                                          #autocompleteControl
                                          label="Product"
                                          name="product"
                                          [displayCategories]="true"
                                          [productType]="PRODUCT_SEARCH_TYPE_ENUM.ExcludeBundles"
                                          [categoryId]="bundleTemplateItem.categoryId"
                                          [(ngModel)]="selectedProduct"
                                          (optionSelected)="productSelected($event)"
                                          [hideCompositeItems]="true"
                                          required="true">
          </cb-product-autocomplete-scroller>
        <cb-input type="number"
                  name="quantity"
                  label="Quantity"
                  maxlength="10"
                  [required]="true"
                  [(ngModel)]="bundleProductItem.quantity">
        </cb-input>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="addProductToBundleForm.pristine || addProductToBundleForm.invalid"
                (click)="saveBundleProduct()">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
