<div class="button-container">
    <button mat-button
            [color]="color"
            type="submit"
            (cbClick)="onClick($event)"
            [clickLimit]="clickLimit"
            [ngClass]="{
              'mat-stroked-button': isStroked,
              'mat-raised-button': !isStroked,
              'disabled-button': disabled$ | async
          }"
            [disabled]="disabled$ | async"
            [ngStyle]="{ 'color': color !== 'white' && !isStroked ? 'white' : '' }"
            *ngIf="!icon && !isMenuItem && !menu">
        <div class="center-center"><ng-content></ng-content></div>
    </button>

    <!-- The popup only appears when a popup is set and the button is disabled -->
    <cb-popup-tip *ngIf="popup && (disabled$ | async)"
                  class="blocking-reason-popup"
                  icon="help"
                  iconColour="cb-accent"
                  [horizontalPosition]="popup.horizontalPosition"
                  [verticalPosition]="popup.verticalPosition"
                  [cssPosition]="popup.cssPosition">
        <div class="flex-col">
        <span class="mat-body-1 cb-margin" style="white-space: normal;">
            {{ popup.description }}
        </span>
            <button class="cb-margin-top"
                    mat-raised-button
                    color="primary"
                    [hidden]="popup.buttonIsHidden"
                    [disabled]="false"
                    (cbClick)="onPopupClick($event)">
                {{ popup.buttonText }}
            </button>
        </div>
    </cb-popup-tip>
</div>
<button *ngIf="icon && !isMenuItem && !buttonLabel"
        mat-icon-button
        [color]="color"
        (click)="onClick($event)"
        [disabled]="disabled$ | async">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()">{{icon}}</mat-icon>
</button>
<button *ngIf="icon && buttonLabel"
        mat-button
        [color]="color"
        (click)="onClick($event)"
        [disabled]="disabled$ | async"
        [ngClass]="isStroked ? 'mat-stroked-button' : 'mat-raised-button'">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()">{{icon}}</mat-icon>
    {{buttonLabel}}
</button>
<button *ngIf="menu && !menuButtonLabel"
        [matMenuTriggerFor]="menu"
        mat-icon-button
        (click)="onClick($event)"
        [disabled]="disabled$ | async">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()">more_horiz</mat-icon>
</button>
<button *ngIf="menu && menuButtonLabel"
        [matMenuTriggerFor]="menu"
        mat-raised-button
        (click)="onClick($event)"
        [disabled]="disabled$ | async">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()">arrow_drop_down</mat-icon>
    {{menuButtonLabel}}
</button>
<button *ngIf="isMenuItem"
        mat-menu-item
        [disabled]="disabled$ | async"
        (click)="onClick($event)">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()">{{icon}}</mat-icon>
    {{menuItemLabel}}
</button>
