import { Component, Input } from '@angular/core';
import { ILotSpecScheduleItemMappedItem, LotSpecScheduleItemLogicService } from '@app/logic/lot-spec-schedule-item';
import { MappedItemUtils } from '@app/shared/utils/mapped-item.util';
import { remove } from 'lodash';
import { Subscription } from 'rxjs';
import { LotSpecItemUIActions } from '../lot-spec-item-card/lot-spec-item-ui-actions';
import { LotSpecificationService } from '../services/lot-specification.service';

export interface IHouseAreaIdWithItems {
    houseAreaId: number;
    mappedItems: ILotSpecScheduleItemMappedItem[];
}

export interface IHouseAreaWithOneMappedItem {
    houseAreaId: number;
    parentMappedItemId: number;
    mappedItem: ILotSpecScheduleItemMappedItem;
}

@Component({
    selector: 'cb-lot-spec-schedule-items-table',
    templateUrl: './lot-spec-schedule-items-table.component.html',
    styleUrls: ['./lot-spec-schedule-items-table.component.scss'],
    providers: [
        LotSpecItemUIActions
    ]
})
export class LotSpecScheduleItemsTableComponent {

    public expandedParents = {} as { [parentId: number]: boolean };

    @Input() public readonly lotId: number;

    private _houseAreaIdWithItems: IHouseAreaIdWithItems;

    @Input() public set houseAreaIdWithItems(data: IHouseAreaIdWithItems) {
        this._houseAreaIdWithItems = {
            houseAreaId: data.houseAreaId,
            // Allow to set with either mappedItem or dto
            mappedItems: data.mappedItems.map(dto => MappedItemUtils.isMappedItem(dto) ? dto : this.logicService.$createMappedItem(dto))
        } as IHouseAreaIdWithItems;
    }

    public get houseAreaIdWithItems(): IHouseAreaIdWithItems {
        return this._houseAreaIdWithItems;
    }

    @Input() public isSkinnyView: boolean;

    private readonly subscriptions$ = new Subscription();

    constructor(
        private readonly lotSpecificationService: LotSpecificationService,
        private readonly logicService: LotSpecScheduleItemLogicService
    ) {}

    public ngOnInit(): void {
        this.subscriptions$.add(
            this.lotSpecificationService.childLotScheduleItemEdited.subscribe((data: IHouseAreaWithOneMappedItem) => {
                if(this._houseAreaIdWithItems.houseAreaId === data.houseAreaId && data.parentMappedItemId) {
                    this._mapUpdatedChildItemToTableData(data);
                }
            })
        );

        this.subscriptions$.add(

            // Parent Item removed
            this.lotSpecificationService.lotScheduleParentItemRemoved.subscribe((itemId) => {
                remove(this._houseAreaIdWithItems?.mappedItems, { id: itemId });

            })
        );

        this.subscriptions$.add(

            // Child Item Removed
            this.lotSpecificationService.lotScheduleChildItemRemoved.subscribe(({ parentId, childId }) => {

                const parentIndex = this._houseAreaIdWithItems?.mappedItems.findIndex(item => item.id === parentId);

                if (parentIndex > -1) {

                    const childIndex = this._houseAreaIdWithItems?.mappedItems[parentIndex]?.childItems?.findIndex(item => item.id === childId);

                    if (childIndex > -1) {
                        remove(this._houseAreaIdWithItems?.mappedItems[parentIndex]?.childItems, { id: childId });
                    }
                }
            })
        );
    }

    private _mapUpdatedChildItemToTableData(data: IHouseAreaWithOneMappedItem): void {
        this._houseAreaIdWithItems
            .mappedItems.forEach(mapped => {
                if (mapped.id === data.parentMappedItemId) {
                    mapped.childItems?.forEach(childMappedItem => {
                        if (childMappedItem.id === data.mappedItem.id) {
                            childMappedItem = data.mappedItem;
                        }
                    });
                }
            });
        const newhouseAreaIdWithItems = this._houseAreaIdWithItems;
        // Trigger the setter to ensure houseAreaIdWithItems is up to date
        this.houseAreaIdWithItems = newhouseAreaIdWithItems;
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
