import { BaseMappedItem } from '../base/base-mapped-item';
import { ILocationLogicService } from './interfaces/i.location.logic.service';
import { ILocationMappedItem } from './interfaces/i.location.mapped';
import { ILocationDto } from './interfaces/i.location.dto';
import { DtoProp } from '../base/dto-prop.decorator';
import { IIdAndLabelDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class LocationMappedItem
    extends BaseMappedItem<ILocationDto, ILocationMappedItem, ILocationLogicService>
    implements ILocationMappedItem {
    @DtoProp public isActive: boolean;
    @DtoProp public id: number;
    @DtoProp public canHaveChildren: boolean;
    @DtoProp public name: string;
    @DtoProp public label: string;
    @DtoProp public businessEntityId: number;
    @DtoProp public addressRegionId: number;
    @DtoProp public code: string;
    @DtoProp public parentId: number;
    @DtoProp public path?: [any];
    @DtoProp public locationType: number;
    @DtoProp public businessEntity: IIdAndLabelDto;
    @DtoProp public addressRegion: IIdAndLabelDto;
    @DtoProp public region: IIdAndLabelDto;
    @DtoProp public district: IIdAndLabelDto;
    @DtoProp public area: IIdAndLabelDto;
    @DtoProp public hasProjects: boolean;
    @DtoProp public expanded: boolean;
    @DtoProp public type: number;
    @DtoProp public visible: boolean;
    @DtoProp public sortString: string;

    constructor(
        sourceData: ILocationDto,
        logicService: ILocationLogicService
    ) {
        super(
            sourceData,
            logicService,
            LocationMappedItem
        );
    }
}
