import { ToastService } from '@app/core/services/toast/toast.service';
import {
    CostTypeEnumId,
    COST_TYPE_ENUM,
    ICategoryDto,
    IIdAndNameDto,
    IScheduleTemplateItemDto,
    IScheduleTemplateListChildItemDto,
    ISpecGroupDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IScheduleTemplateItemMappedItem } from './interfaces/i.schedule-template-item.mapped';
import { IScheduleTemplateItemsLogicService } from './interfaces/i.schedule-template-items.logic-service';

export class ScheduleTemplateItemMappedItem
    extends BaseMappedItem<
    IScheduleTemplateItemDto, IScheduleTemplateItemMappedItem, IScheduleTemplateItemsLogicService>
    implements IScheduleTemplateItemMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public specTemplateId: number;
    @DtoProp public product: IIdAndNameDto;
    @DtoProp public category: ICategoryDto;
    @DtoProp public productOther: string;
    @DtoProp public costType: CostTypeEnumId;
    /** @deprecated */
    @DtoProp public costAmount: number;
    @DtoProp public quantity: number;
    @DtoProp public showInClientSpecification: boolean;
    @DtoProp public showInColourYourDreams: boolean;
    @DtoProp public manualColourEntryRequired: boolean;
    @DtoProp public parentScheduleTemplateItemId: number;
    @DtoProp public parentSlotId: number;
    @DtoProp public isActive: boolean;
    @DtoProp public specGroupDto: ISpecGroupDto;
    @DtoProp public slotId: number;
    @DtoProp public sortOrder: number;
    @DtoProp public specGroupId: number;
    @DtoProp public isRequired: boolean;
    @DtoProp public slotName: string;
    @DtoProp public slotDescription: string;
    @DtoProp public tags: string[];
    @DtoProp public childItems: IScheduleTemplateListChildItemDto[];
    @DtoProp public hasProductImage: boolean;
    @DtoProp public isNational: boolean;
    @DtoProp public supplierId: number;
    @DtoProp public rateSnapshot: number;
    @DtoProp public quoteRequired: boolean;
    @DtoProp public imageKeyPlusExtension: string;
    @DtoProp public imageKeys: IIdAndNameDto[];
    @DtoProp public scheduleItemImageUrl: string;
    @DtoProp public productImageUrl: string;
    @DtoProp public productLabel: string;
    @DtoProp public productImageKeyPlusExtension: string;
    @DtoProp public scheduleItemImageKeyPlusExtension: string;
    @DtoProp public parentScheduleTemplateItemSlotName: string;
    @DtoProp public clientSpecDescription: string;


    public $shouldRemoveImageOnSave: boolean;
    public $scheduleItemImageFile: File;

    constructor(
        sourceData: IScheduleTemplateItemDto,
        logicService: IScheduleTemplateItemsLogicService,
        public readonly toastService: ToastService
    ) {
        super(sourceData, logicService, ScheduleTemplateItemMappedItem);
    }

    public $save(): Observable<IScheduleTemplateItemDto> {
        const toSave = this.$getMappedDtoItem();
        this.$preSave(toSave);
        return this.$logicService
            .$saveItem(toSave)
            .pipe(
                map((response) => {
                    if ((this.$scheduleItemImageFile !== undefined
                        && this.$scheduleItemImageFile !== null)
                        && this.costType !== COST_TYPE_ENUM.Actual) {
                        return this.uploadImage();
                    } else if (
                        this.$shouldRemoveImageOnSave === true
                        || (this.costType === COST_TYPE_ENUM.Actual
                            && this.scheduleItemImageUrl)) {
                        this.removeImage();
                    }
                    this.$updateThisAndOriginal(response);
                    return response;
                })
            );
    }

    private uploadImage(): IScheduleTemplateItemDto {
        if (!this.$scheduleItemImageFile) {
            return;
        }
        const fd = new FormData();
        fd.append('file', this.$scheduleItemImageFile);

        this.$logicService
            .uploadImage(this.id, fd)
            .subOnce(newSpecTemplateItem => {
                if (newSpecTemplateItem) {
                    this.$scheduleItemImageFile = undefined;
                    this.toastService.saveSuccess();
                    return newSpecTemplateItem;
                }
            });
    }

    private removeImage(): any {
        this.$shouldRemoveImageOnSave = false;
        this.$logicService
            .removeImage(this.id).subOnce(res => {
                if (res) {
                    this.scheduleItemImageUrl = undefined;
                    this.scheduleItemImageUrl = undefined;
                }
            });
    }

}
