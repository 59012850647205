import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { DocumentGroupMappedItem, IDocumentGroupTypeApproverDto, IDocumentGroupTypeDto } from '@app/logic/documents';
import { ITagDto, TagsLogicService } from '@app/logic/tags';
import { DOCUMENT_REQUIRED_TYPE_ENUM, DOCUMENT_APPROVAL_TYPE_ENUM, IDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { filter, find, findIndex, orderBy, some } from 'lodash';

interface IData {
    documentGroup: DocumentGroupMappedItem;
    documentType: IDocumentGroupTypeDto;
}

@Component({
    selector: 'cb-document-group-type-dialog',
    templateUrl: './document-group-type-dialog.component.html',
    styleUrls: ['./document-group-type-dialog.component.scss']
})
export class DocumentGroupTypeDialogComponent {
    public static readonly MIN_WIDTH = '65%';
    public documentTypes: IDocumentTypeDto[];
    public requiredTypes = DOCUMENT_REQUIRED_TYPE_ENUM.toLookup();
    public approvalTypes = DOCUMENT_APPROVAL_TYPE_ENUM.toLookup();
    public tags: ITagDto[] = [];
    public availableTags: ITagDto[];
    public selectedTagId: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<DocumentGroupTypeDialogComponent>,
        protected readonly documentTypeLogicService: DocumentTypesLogicService,
        protected readonly tagsLogicService: TagsLogicService,
    ) {
        this.documentTypeLogicService.$getList().subOnce(documentTypes => this.documentTypes = documentTypes as IDocumentTypeDto[]);
        this.tagsLogicService.$getList().subOnce(tags => {
            this.tags = tags;
            this.refreshAvailableTags();
        });
        this.data.documentType.approvalTags = this.data.documentType.approvalTags ?? [];
    }

    public querySearch = (searchText: string): IDocumentTypeDto[] => {
        return filter(this.documentTypes, (documentType: IDocumentTypeDto) => {
            const isExisitng = some(this.data.documentGroup.types, { documentTypeId: documentType.id });
            return !isExisitng && documentType.label.toLowerCase().includes(searchText.toLowerCase());
        });
    };

    public canSave(): boolean {
        return !!this.data.documentType?.documentType?.id;
    }

    public onSaveClicked(): void {
        if (this.canSave()) {
            this.data.documentType.documentTypeId = this.data.documentType.documentType.id;
            this.dialogRef.close(this.data.documentType);
        }
    }

    public onAddTagCliced(): void {
        if (this.selectedTagId) {
            const found = some(this.data.documentType.approvalTags, { userTagId: this.selectedTagId });
            if (!found) {
                this.data.documentType.approvalTags.push({ userTagId: this.selectedTagId } as IDocumentGroupTypeApproverDto);
                this.selectedTagId = null;
                this.refreshAvailableTags();
            }
        }
    }

    public onRemoveTagClick(deletedTag: IDocumentGroupTypeApproverDto): void {
        const indexToRemove = findIndex(this.data.documentType.approvalTags, { userTagId: deletedTag.userTagId });
        if (indexToRemove >= 0) {
            this.data.documentType.approvalTags.splice(indexToRemove, 1);
            this.refreshAvailableTags();
        }
    }

    public getTagDisplayLabel(tag: IDocumentGroupTypeApproverDto): string {
        const found = find(this.tags, { id: tag.userTagId });
        return found ? found.name : '';
    }

    private refreshAvailableTags(): void {
        this.availableTags = orderBy(
            filter(this.tags, tag => {
                return findIndex(this.data.documentType.approvalTags, itemTag => {
                    return tag.id === itemTag.userTagId;
                }) < 0;
            }), 'name');
    }
}
