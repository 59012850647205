import { CHANGE_STATUS_ENUM, COST_NATURE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ISkinnyChangeRecordDto } from '@app/logic/change-records';
import { ICouncilRfiMappedItem } from '@app/logic/council-rfis';
import { NavigationService } from '@app/core/services/navigation/navigation.service';

@Component({
    selector: 'cb-council-rfi-change-records',
    templateUrl: './council-rfi-change-records.component.html',
    styleUrls: ['./council-rfi-change-records.component.scss']
})
export class CouncilRfiChangeRecordsComponent implements OnInit {

    public changeRecords: ISkinnyChangeRecordDto[];
    @Input() public councilRfi: ICouncilRfiMappedItem;
    @Output() public readonly changeRecordCountChange = new EventEmitter<number>();
    @Output() public readonly allChangeRecordsComplete = new EventEmitter<boolean>();

    public readonly COST_NATURE_ENUM = COST_NATURE_ENUM;
    public readonly CHANGE_STATUS_ENUM = CHANGE_STATUS_ENUM;

    public readonly displayedColumns = [
        'Change Number',
        'Change Type',
        'Description',
        'Assigned To',
        'Status',
        ''
    ];

    constructor(
        public readonly navigation: NavigationService,
    ) { }

    public ngOnInit(): void {
        this.councilRfi.getChangeRecords().subOnce((changeRecords) => {
            this.changeRecords = changeRecords;
            this.changeRecordCountChange.emit(this.changeRecords?.length ?? 0);
            this.allChangeRecordsComplete.emit(
                this.changeRecords.every(x =>
                    x.changeStatus === CHANGE_STATUS_ENUM.Completed
                    || x.changeStatus === CHANGE_STATUS_ENUM.Accepted
                    || x.changeStatus === CHANGE_STATUS_ENUM.Cancelled
                    || x.changeStatus === CHANGE_STATUS_ENUM.Declined
                )
            );
        });
    }

    public viewChangeRecord(event: MouseEvent, changeRecord: ISkinnyChangeRecordDto): void {
        this.navigation.navigate([`lots/${changeRecord.lotId}/changes`], event, { queryParams: { changeRecordId: changeRecord.id } });
    }
}
