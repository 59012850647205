import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BusinessAccountLogicService, BusinessAccountMappedItem, IBusinessAccountDto } from '@app/logic/business-accounts';
import { LocationLogicService } from '@app/logic/location';
import { filter, find, some } from 'lodash';

interface IData {
    accountId: number;
}

@Component({
    selector: 'cb-business-account-manage-locations-dialog',
    templateUrl: './business-account-manage-locations-dialog.component.html',
    styleUrls: ['./business-account-manage-locations-dialog.component.scss']
})
export class BusinessAccountManageLocationsDialogComponent {
    public static readonly MIN_WIDTH = '50%';
    public regions: any[];
    public account: IBusinessAccountDto;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<BusinessAccountManageLocationsDialogComponent>,
        protected readonly toastService: ToastService,
        protected readonly locationLogicService: LocationLogicService,
        protected readonly businessAccountLogicService: BusinessAccountLogicService
    ) {
        this.locationLogicService.$getList().subOnce(result => this.regions = filter(result, { isActive: true }));
        this.businessAccountLogicService.$getItem(data.accountId).subOnce(result => this.account = result);
    }

    public isRegionChecked(regionId: number): boolean {
        return this.account && find(this.account?.locations ?? [], { id: regionId });
    }

    public onRegionChanged(regionId: number, value: boolean): void {
        const found = some(this.account.locations, { id: regionId });
        if (value && !found) {
            this.account.locations.push({ id: regionId } as any);
        } else if (!value) {
            this.account.locations = filter(this.account.locations, location => location.id !== regionId);
        }
    }

    public saveClicked(): void {
        if (this.account.id) {
            const mappedItem = this.businessAccountLogicService.$createMappedItem(this.account, BusinessAccountMappedItem);
            mappedItem.$save().subOnce(result => {
                if (result?.id) {
                    this.toastService.showToast('Account Locations are updated successfully', 'OK', 1000, this.toastService.deleteToast.bind(this));
                    this.dialogRef.close();
                } else {
                    this.toastService.showToast('Ops! Something went wrong while we were trying to save Account Locations', 'OK', 1000, this.toastService.deleteToast.bind(this));
                }
            });
        }
    }
}
