import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ILotSpecScheduleItemDto, ILotSpecScheduleListItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IHouseAreaWithOneMappedItem } from '../lot-spec-schedule-items-table/lot-spec-schedule-items-table.component';


@Injectable({
    providedIn: 'root'
})
export class LotSpecificationService {
    public readonly lotScheduleItemAdded = new Subject<ILotSpecScheduleItemDto>();
    public readonly childLotScheduleItemEdited = new Subject<IHouseAreaWithOneMappedItem>();
    public readonly lotScheduleParentItemRemoved = new Subject<number>();
    public readonly lotScheduleChildItemRemoved = new Subject<{ parentId: number; childId: number }>();

    public getProductName(item: ILotSpecScheduleListItemDto): string {
        let returnValue: string;

        if (!this.hasQuoteLineId(item) && item?.product?.id > 0) {
            returnValue = item?.product?.name;
        } else {
            returnValue = item.productLabel;
        }
        return returnValue;
    }

    public hasQuoteLineId(item: ILotSpecScheduleListItemDto): boolean {
        return item.quoteLineId > 0;
    }
}
