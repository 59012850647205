import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IAddressRegionsLogicService } from './interfaces/i.address-regions.logic.service';
import { IAddressRegionMappedItem } from './interfaces/i.address-region.mapped';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class AddressRegionMappedItem
    extends BaseMappedItem<IAddressRegionDto, IAddressRegionMappedItem, IAddressRegionsLogicService>
    implements IAddressRegionMappedItem {

    @DtoProp public id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public name: string;

    constructor(
        sourceData: IAddressRegionDto,
        logicService: IAddressRegionsLogicService
    ) {
        super(sourceData, logicService, AddressRegionMappedItem);
    }
}
