import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from './../base-form-component';
import { BehaviorSubject } from 'rxjs';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { SelectComparator as SelectComparator, WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';

@Component({
    selector: 'cb-selection-list',
    templateUrl: './selection-list.component.html',
    styleUrls: ['./selection-list.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(SelectionListComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class SelectionListComponent<Option extends { id: string | number; label?: string; name?: string }> extends BaseFormComponentDirective implements OnInit {
    @Input() public slim = false;
    @Input() public checkboxPosition: 'before' | 'after';
    @Input() public valueProp: string;
    @Input() public wholeOptionComparisonProp = 'id';
    @Input() public maxHeight = '200px';
    @Input() public strikeThroughInactive = false;
    public selectComparator: SelectComparator;

    public WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;
    public touched: boolean;

    private resolveLoad: (value?: unknown) => void;
    private readonly loadPromise = new Promise((resolve) => this.resolveLoad = resolve);
    // using a BehaviorSubject with a delay, so that the value/ngModel can intially load before the options
    public readonly options$ = new BehaviorSubject<Option[]>(undefined);
    @Input() public set options(v: Option[]) {
        setTimeout(() => {
            // ngZone run to prevent change detector erros
            this.zone.run(() => {
                // using a promise to allow the value/ngModel to load before the options
                this.loadPromise.then(() => {
                    this.options$.next(v);
                });
            });
        }, 100);
    }
    public get options(): Option[] {
        return this.options$.value;
    }

    public ngOnInit(): void {
        this.selectComparator = new SelectComparator(this.wholeOptionComparisonProp, this.valueProp);
    }

    @Input() public optionLabel: (option: Option) => string = (option: Option): string => {
        return option?.label ?? option?.name ?? '';
    };

    constructor(
        private readonly zone: NgZone,
    ) {
        super();
    }

    public valueSetter(v: any): { value: any; modelValue: any } {
        // using a promise to allow the value/ngModel to load before the options
        this.resolveLoad();
        return {
            value: v,
            modelValue: v
        };
    }

    public onSelection = (): void => {
        this.touched = true;
    };

    public isChecked(id: number): boolean {
        return this.value?.some(x => x.id === id || x === id);
    }
}
