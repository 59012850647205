import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

interface IDialogData {
    readonly message: string;
    readonly yesLabel: string;
    readonly noLabel: string;
}

@Component({
    selector: 'cb-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    public readonly data = {
        message: 'Are you sure?',
        yesLabel: 'Yes',
        noLabel: 'No'
    } as IDialogData;

    constructor(
        public readonly dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly dialogData: IDialogData
    ) {
        for (const key in this.dialogData) {
            if (this.dialogData.hasOwnProperty(key) && this.dialogData[key] != null && this.data[key] != null) {
                this.data[key] = this.dialogData[key];
            }
        }
    }

    public yes(): void {
        this.dialogRef.close(true);
    }

    public no(): void {
        this.dialogRef.close(false);
    }
}
