import {Injectable} from '@angular/core';
import {ISellingAgentDto} from '@app/logic/selling-agents/interfaces/i.sellingagent.dto';
import {SellingAgentsLogicService} from '@app/logic/selling-agents/sellingagents.logic.service';
import {Observable} from 'rxjs';
import {SearchService} from './base.search.service';

@Injectable()
export class SellingAgentSearchService extends SearchService<ISellingAgentDto> {

    constructor(
        public readonly sellingagentsLogicService: SellingAgentsLogicService,
    ) {
        super();
    }

    public getResults(query: string, currentpage: number): Observable<ISellingAgentDto[]> {
        return new Observable<ISellingAgentDto[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;

                const resultsObservable = this.sellingagentsLogicService.search({
                    query,
                    currentPage: currentpage,
                    ...this.extraSearchParams
                }, false);

                if (!resultsObservable) {
                    return;
                }

                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<ISellingAgentDto>(x);
                        subscriber.next(this.searchResults$.value as ISellingAgentDto[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as ISellingAgentDto[]);
                subscriber.complete();
            }
        });
    }
}
