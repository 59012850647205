<mat-icon class="cb-grey cb-help-cursor"
          matTooltip="Colour Item">
        format_paint
    </mat-icon>
<mat-icon class="cb-grey cb-help-cursor"
          *ngIf="mappedItem.colourItem.showInClientSpecification"
          aria-hidden="false"
          matTooltip="Included in Flyer / Create Schedule">
        style
    </mat-icon>
