import { ContactsInputFindDirective } from './contacts-input-find.directive';
import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ContactsSearchService } from '@app/core/services/search/contacts-search.service';
import { ContactsLogicService } from '@app/logic/contacts';
import { ContactsInputFindDialogCardsComponent } from '../../dialog/extensions/contacts-input-find-dialogs/contacts-input-find-dialog-cards.component';

@Component({
    selector: 'cb-contacts-input-find-cards',
    templateUrl: '../../base-input-find.directive.html',
    styleUrls: ['../../base-input-find.directive.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(ContactsInputFindCardsComponent),
        ContactsSearchService,
        provideParentForm()
    ]
})
export class ContactsInputFindCardsComponent extends ContactsInputFindDirective {
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly contactLogicService: ContactsLogicService,
        public readonly navigationService: NavigationService,
        public readonly searchService: ContactsSearchService) {
        super(
            dialogRef,
            router,
            contactLogicService,
            navigationService,
            searchService
        );
        this._myDialog = ContactsInputFindDialogCardsComponent;
    }
}
