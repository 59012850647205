/* eslint-disable @typescript-eslint/indent */
import { Component } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { IPermissionGroupLogicService } from '@app/logic/security-role/interfaces/i.permission-group.logic.service';
import { PermissionGroupLogicService } from '@app/logic/security-role/permission-group.logic.service';
import { PermissionGroupMappedItem } from '@app/logic/security-role/permission-group.mapped';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IPermissionGroupSummaryDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { SecurityRoleReportDialogComponent } from './security-role-report-dialog/security-role-report-dialog.component';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { PermissionGroupAreaLogicService } from '@app/logic/security-role/permission-group-area.logic.service';

@Component({
  selector: 'cb-security-role-list',
  templateUrl: './security-role-list.component.html',
  styleUrls: ['./security-role-list.component.scss'],
  providers: [
    PermissionGroupLogicService,
    PermissionGroupAreaLogicService
  ]
})
export class SecurityRoleListComponent
  extends BaseSimpleListViewDirective<IPermissionGroupSummaryDto, IPermissionGroupLogicService> {
  public readonly displayedColumns: string[] = ['Name', 'No. Members', 'Active', ''];
  public permissionGroupSummaries: IPermissionGroupSummaryDto[];

  constructor(
    public readonly cbDialog: CbDialogService,
    protected readonly securityRoleService: PermissionGroupLogicService,
    public readonly permissionsPermissions: PermissionsPermissions,
    private readonly navigationService: NavigationService,
  ) {
    super(
      cbDialog,
      securityRoleService,
      SecurityRoleReportDialogComponent,
      'Security Role',
      PermissionGroupMappedItem,
      permissionsPermissions,
    );
  }

  protected handleSearchResults = (searchResults: Array<IPermissionGroupSummaryDto>): void => {
    super.handleSearchResults(searchResults);
  };

  public createRole(event: MouseEvent): void {
    this.navigate('/security-roles/create', event);
  }

  public viewRole(role, event: MouseEvent): void {
    this.navigate('/security-roles/view/' + role.id, event);
  }

  public navigate(path: string, event: MouseEvent): void {
    this.navigationService
      .navigate([path], event);
  }

  public canView(): boolean {
    return this.permissionsPermissions.canViewRoles();
  }

  public canEdit(): boolean {
    return this.permissionsPermissions.canEditRoles();
  }

  public generateReport(): void {
    this.securityRoleService.generateReport().subOnce();
  }

  public generateRolePermissionReport(): void {
    this.cbDialog
      .open(SecurityRoleReportDialogComponent, { data: this.searchResults });
  }
}
