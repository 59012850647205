import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IAreaPermissionsDto, IRoleDto } from './interfaces/i.role.dto';
import { IRolesLogicService } from './interfaces/i.roles.logic-service';
import { IRoleMappedItem } from './interfaces/i.roles.mapped';


export class RoleMappedItem
    extends BaseMappedItem<IRoleDto, IRoleMappedItem, IRolesLogicService>
    implements IRoleMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public members: number;
    @DtoProp public isActive: boolean;
    @DtoProp public areas: IAreaPermissionsDto[];

    constructor(
        sourceData: IRoleDto,
        logicService: IRolesLogicService
    ) {
        super(sourceData, logicService, RoleMappedItem);
    }
}

