import { Injectable, Injector } from '@angular/core';
import { IFileResponse } from '@app/shared/interfaces/i.file-response.dto';
import { IContractGenerationDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';

import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';


@Injectable()
export class ContractGenerationLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) { }

    public generateContract(contractGenerationDto: IContractGenerationDto): Observable<any> {
        return this.$http.download<IFileResponse>(
            'contractgeneration',
            'Generating Contract...',
            undefined,
            'post',
            contractGenerationDto
        );

    }
}
