import {BasePermissions, IBaseSystemAreaPermissions, IContactPermissions, IhaveContactPermissions, IhaveNotePermissions, INotePermissions} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ClientAccountPermissionEnum} from '../../shared/enums/permissions';


export interface IClientAccountPermissions extends IBaseSystemAreaPermissions, IhaveNotePermissions, IhaveContactPermissions {
    canSearch(): boolean;
    canView(): boolean;
    canCreate(): boolean;
    canEdit(): boolean;

    canSeeComponent(): boolean;
    canViewHistory(): boolean;
    getNotePermissions(): INotePermissions;
    getContactPermissions(): IContactPermissions;
    mainContactCanChange(): boolean;

    clientSalesCanView(): boolean;
    leadsCanView(): boolean;
    projectsCanView(): boolean;
}

@Injectable()
export class ClientAccountPermissions
    extends BasePermissions<ClientAccountPermissionEnum>
    implements IClientAccountPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('clientaccount', ClientAccountPermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.CanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.CanCreate);
    }

    public canEdit(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.CanEdit);
    }

    public canSearch(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.CanSearch);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.CanSearch);
    }

    public canViewHistory(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.HistoryCanView);
    }

    public mainContactCanChange(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.MainContactCanChange);
    }

    public clientSalesCanView(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.ClientSalesCanView);
    }

    public leadsCanView(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.LeadsCanView);
    }

    public projectsCanView(): boolean {
        return this.permissionObject(ClientAccountPermissionEnum.ProjectsCanView);
    }

    public getContactPermissions(): any {
        return {
            canAddContact: () => this.permissionObject(ClientAccountPermissionEnum.ContactCanAdd),
            canChangeMainContact: () => this.permissionObject(ClientAccountPermissionEnum.MainContactCanChange),
            canRemoveContact: () => this.permissionObject(ClientAccountPermissionEnum.ContactCanRemove)
        };
    }

    public getNotePermissions(): INotePermissions {
        return {
            canCreateNote: () => this.permissionObject(ClientAccountPermissionEnum.NoteCanAdd),
            canDeleteNote: () => this.permissionObject(ClientAccountPermissionEnum.NoteCanDelete),
            canEditNote: () => this.permissionObject(ClientAccountPermissionEnum.NoteCanEdit),
            canViewNotes: () => this.permissionObject(ClientAccountPermissionEnum.NoteCanView)
        };
    }
}
