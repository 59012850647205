export enum BusinessAccountPermissionEnum {
    None = 0x00,
    CanSearch = 0x01 << 0,
    CanView = 0x01 << 1,
    CanEdit = 0x01 << 2,
    CanCreate = 0x01 << 3,
    CanEditFinanceInformation = 0x01 << 4,
    CanViewHs = 0x01 << 5,
    CanApproveHs = 0x01 << 6,
    CanViewDocuments = 0x01 << 7,
    CanUploadDocuments = 0x01 << 8,
    CanEditDocuments = 0x01 << 9,
    CanReviewDocuments = 0x01 << 10,
    CanDeleteDocuments = 0x01 << 11,
    CanApproveContracts = 0x01 << 12,
    CanChangePaymentFrequency = 0x01 << 13,
    CanViewContacts = 0x01 << 14,
    CanManageContacts = 0x01 << 15,
    CanManageRegions = 0x01 << 16,
    CanViewQuotes = 0x01 << 17,
    CanApplyHold = 0x01 << 18,
    CanReleaseHold = 0x01 << 19,
    CanViewNote = 0x01 << 20,
    CanEditNote = 0x01 << 21,
    CanAddNote = 0x01 << 22,
    CanDeleteNote = 0x01 << 23,
    CanViewHistory = 0x01 << 24,
    CanViewCatalogueItems = 0x01 << 25,
    CanCloseAccount = 0x01 << 26,
    CanGenerateContracts = 0x01 << 27,
    CanViewOrders = 0x01 << 28,
    CanExportBusinessAccounts = 0x01 << 29,

}
