import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ILotSummary} from '@app/core/services/user-cache/user-cache-areas';
import {UserCacheItem} from '@app/core/services/user-cache/user-cache-item';
import {UserCacheService} from '@app/core/services/user-cache/user-cache.service';
import {ILotMappedItem, LotsLogicService} from '@app/logic/lots';
import {BehaviorSubject, Subject} from 'rxjs';
import {ILotProgressMilestoneDto} from '@app/logic/lots/interfaces/i.lot-progress-milestones.dto';

@Component({
    selector: 'cb-lot-summary',
    templateUrl: './lot-summary.component.html',
    styleUrls: ['./lot-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotSummaryComponent implements OnInit {

    public readonly lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    public blockLevelProgress: ILotProgressMilestoneDto;

    @Input() public set lotMappedItem(lotMappedItem: ILotMappedItem) {
        if (lotMappedItem) {
            this.lotMappedItem$$.next(lotMappedItem);
            this._getMilestones();
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public cacheLoaded$ = new Subject();

    public get userCacheItem(): UserCacheItem<ILotSummary> {
        return this.userCacheService.lotSummary;
    }

    public pinnedPanels: ILotSummary;
    public silentData: ILotSummary;

    constructor(
        public readonly userCacheService: UserCacheService,
        private readonly lotsLogicService: LotsLogicService,
        private readonly cdr: ChangeDetectorRef,
    ) { }

    public ngOnInit(): void {
        this.initUserCache();
        this._getMilestones();
    }

    private _getMilestones(): void {
        if (this.lotMappedItem.hasUnits) {
            this.lotsLogicService
                .getLotMilestones(this.lotMappedItem.id)
                .subOnce((x) => {
                    this.blockLevelProgress = x;
                    this.cdr.detectChanges();
                });
        }
        else {
            this.cdr.detectChanges();
        }
    }

    private initUserCache(): void {
        this.userCacheService.lotSummary.init().then(() => {
            this.pinnedPanels = this.userCacheService.lotSummary.copyData();
            this.silentData = this.userCacheService.lotSummary.silentData;
            this.cacheLoaded$.next(true);
        });
    }

    public togglePanelPinned(key: string): void {
        this.userCacheItem.data[key] = !this.userCacheItem.silentData[key];
        this.silentData = this.userCacheService.lotSummary.silentData;
    }
}
