import {Component} from '@angular/core';
import {BaseSimpleListViewDirective} from '@app/shared/base-views/base-simple-list-view.directive';
import {ColourItemMappedItem, ColourItemsLogicService, IColourItemDto} from '@app/logic/colour-items';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {ToastService} from '@app/core/services/toast/toast.service';
import {PermissionsPermissions} from '../../../../core/permissions';
import {ColourItemDialogComponent} from './dialog/colour-item-dialog.component';

@Component({
    selector: 'cb-colour-items',
    templateUrl: './colour-items.component.html',
    styleUrls: ['./colour-items.component.scss'],
    providers: [
        ColourItemsLogicService
    ]
})
export class ColourItemsComponent extends BaseSimpleListViewDirective<IColourItemDto, ColourItemsLogicService> {

    public readonly columns = ['Code', 'Name', 'Active', ''];

    constructor(
        public readonly toastService: ToastService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        _colourItemsLogicService: ColourItemsLogicService
    ) {
        super(
            dialog,
            _colourItemsLogicService,
            ColourItemDialogComponent,
            'Colour Item',
            ColourItemMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<IColourItemDto>): Array<IColourItemDto> {
        return searchResults.sort((a, b) => a.name.localeCompare(b.name));
    }

    public handleEditItem = (item: any): void => {
        this.getSearchResults().subOnce(x => {
            this.handleSearchResults(x);
        });
    };
}
