import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CurrentUserService} from '@app/core/authentication/current.user';
import {LotChangePermissions, LotVariationPermissions} from '@app/core/permissions';
import {ToastService} from '@app/core/services/toast/toast.service';
import {ChangeRecordLogicService} from '@app/logic/change-records/change-record.logic-service';
import {IChangeRecordMappedItem} from '@app/logic/change-records/interfaces/i.change-record.mapped';
import {ILotMappedItem} from '@app/logic/lots';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {CHANGE_STATUS_ENUM, ITeamDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {Subscription} from 'rxjs';
import {LotChangesVariationsDataService} from '../lot-changes-variations.data.service';
import {LotCreateChangeDialogComponent} from '../lot-create-change-dialog/lot-create-change-dialog.component';
import {LotGenerateVariationDialogComponent} from '../lot-generate-variation-dialog/lot-generate-variation-dialog.component';
import {LotSubmitChangesDialogComponent} from '../lot-submit-changes-dialog/lot-submit-changes-dialog.component';

@Component({
    selector: 'cb-lot-changes-action-bar',
    templateUrl: './lot-changes-action-bar.component.html',
    styleUrls: ['./lot-changes-action-bar.component.scss'],
})
export class LotChangesActionBarComponent implements OnInit, OnDestroy {
    @Input() public lotMappedItem: ILotMappedItem;
    @Input() public changeRecordMappedItems: IChangeRecordMappedItem[];

    private _teams: ITeamDto[];
    @Input() public set teams(teams: ITeamDto[]) {
        this._teams = teams;
        this.setCurrentUserTeams(teams);
    }

    public get teams(): ITeamDto[] {
        return this._teams;
    }

    @Output() public readonly changeRecordCreated = new EventEmitter<IChangeRecordMappedItem>();
    @Output() public readonly changesUpdated = new EventEmitter<number[]>();

    public currentUserTeams: ITeamDto[] = [];

    public $numberofUnassignedChangeRecords: number;

    private readonly subscription: Subscription;

    constructor(
        private readonly changeRecordLogicService: ChangeRecordLogicService,
        private readonly changePermissions: LotChangePermissions,
        private readonly lotVariationPermissions: LotVariationPermissions,
        public readonly cbDialog: CbDialogService,
        public readonly toast: ToastService,
        public lotChangesVariationsDataService: LotChangesVariationsDataService,
        public readonly currentUser: CurrentUserService
    ) {
        this.subscription = this.lotChangesVariationsDataService.getnumberofUnassignedChangeRecordsCount()
            .subscribe(count => { this.$numberofUnassignedChangeRecords = count; });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.loadUnassignedChangeRecordsCount();
    }

    public canAddChange(): boolean {
        return this.changePermissions.canCreate();
    }

    public canSubmitChangeForPricing(): boolean {
        return this.changePermissions.canSubmitForPricing();
    }

    public canGenerateChangesSignOffDocument(): boolean {
        return this.changePermissions.canGenerateChangesSignOffDocument();
    }

    public canCreateVariation(): boolean {
        return this.lotVariationPermissions.canCreate();
    }

    public canGenerateVariations(): boolean {
        return this.lotMappedItem.canProcessVariations && this.$numberofUnassignedChangeRecords > 0;
    }

    public isGenerateChangesSignOffDocumentEnabled(): boolean {
        return this.changeRecordMappedItems.filter(x => x.changeStatus === CHANGE_STATUS_ENUM.ResponseRequired).length > 0;
    }

    private setCurrentUserTeams = (teams: ITeamDto[]): void => {
        this.currentUserTeams = teams.filter(team => this.currentUser.teams.includes(team.key));
    };

    private readonly loadUnassignedChangeRecordsCount = (): void => {
        this.lotChangesVariationsDataService.loadUnassignedChangeRecordsCount(this.lotMappedItem.id);
    };

    public get numberofPendingChangeRecords(): number {
        return this.changeRecordMappedItems.filter(changeRecord => {
            return changeRecord.changeStatus === CHANGE_STATUS_ENUM.Pending;
        }).length;
    }

    public createChange(): void {
        const changeOption: any = {
            changeOptionLines: [],
        };

        const dialogRef = this.cbDialog.open(LotCreateChangeDialogComponent,
            {
                data: {
                    mappedItem: this.changeRecordLogicService.$createMappedItem({
                        lotId: this.lotMappedItem.id,
                        changeOptions: [changeOption]
                    }),
                    lotMappedItem: this.lotMappedItem,
                    mode: FormMode.Add,
                    teams: this.teams,
                },
            });

        dialogRef.afterClosed().subOnce(result => {
            if (result && result.id > 0) {
                const mappedResult = this.changeRecordLogicService.$createMappedItem(result);
                this.changeRecordCreated.emit(mappedResult);
            }
        });

    }

    public submitChanges(): void {
        const pendingChanges = this.changeRecordMappedItems.filter(changeRecord => {
            return changeRecord.changeStatus === CHANGE_STATUS_ENUM.Pending;
        });

        const dialogRef = this.cbDialog.open(LotSubmitChangesDialogComponent,
            {
                data: {
                    pendingChanges
                }
            });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {
                this.changesUpdated.emit(result);
            }
        });
    }



    public openGenerateVariationDialog(event: Event): void {

        const dialogRef = this.cbDialog.open(LotGenerateVariationDialogComponent,
            {
                data: { lotId: this.lotMappedItem.$id }
            });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {
                this.loadUnassignedChangeRecordsCount();
            }
        });
    }


    public generateChangesSignOffDocument(): void {
        this.changeRecordLogicService.generateChangesSignOffDocument(this.lotMappedItem.id).subOnce({
            next: () => {
                this.toast.showToast('Dowload Successful');
            }
        });
    }


}
