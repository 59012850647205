<div class="cb-margin-top cb-margin-bottom flex-row">
    <div class="flex-col flex">
        <div class="cb-image-button"
             [ngStyle]="!isImageInvalid ? {'cursor': 'zoom-in'} : {'cursor': 'default'}">
            <mat-icon *ngIf="!isImageInvalid"
                      class="cb-image-button-icon">zoom_in</mat-icon>
            <img class="flex"
                 #img
                 alt="Main Image"
                 (click)="launchLightbox()"
                 [src]="getImageUrl()"
                 cbHandleInvalidImage
                 (isImageInvalid)="isImageInvalid = $event"/>
        </div>
    </div>
</div>
