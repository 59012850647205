
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IBuildStageMappedItem, IBuildStagesLogicService, IBuildStageDto } from '@app/logic/build-stages';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CONSTRUCTION_MANAGER_ROLE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-build-stage-dialog',
    templateUrl: './build-stage-dialog.component.html',
    styleUrls: ['./build-stage-dialog.component.scss']
})
export class BuildStageDialogComponent extends BaseDialogFormViewDirective<IBuildStageDto, IBuildStageMappedItem, IBuildStagesLogicService> {

    public constructionManagerRoles = CONSTRUCTION_MANAGER_ROLE_ENUM.toSelectList();

    constructor(
        public readonly dialogRef: MatDialogRef<BuildStageDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: IBuildStageMappedItem }
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }
}
