import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { INotificationDto } from './interfaces/i.notification.dto';
import { INotificationLogicService } from './interfaces/i.notification.logic.service';

@Injectable()
export class NotificationLogicService extends BaseLogicService<INotificationDto, Object> implements INotificationLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('notifications', Object);
    }

    public getNotifications(pageNumber = 1, searchText?: string): Observable<ISearchResult<INotificationDto>> {
        return this.$http.get(`/${this.$baseUri}/all`, { pg: pageNumber, q: searchText });
    }

    public markAsRead(id: number): Observable<INotificationDto> {
        return this.$http.post(`/${this.$baseUri}/${id}/read`, { id });
    }

    public markAllAsRead(): Observable<INotificationDto[]> {
        return this.$http.post(`/${this.$baseUri}/readall`);
    }

    public sendNotificationRead(id: string, message: string): Observable<void> {
        return this.$http.post(`/${this.$baseUri}/sendmessage/${id}?message=${message}`);
    }
}
