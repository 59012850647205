<cb-dialog>
    <form class="flex-col flex"
          #complianceRegistrationForm="ngForm">
        <cb-input type="text"
                  name="registrationType"
                  label="Registration Type"
                  [required]="true"
                  [(ngModel)]="mappedItem.label">
        </cb-input>
        <cb-select-items-autocomplete-scroller class="cb-margin-bottom-2x flex"
                                               heading="Area"
                                               [displayFn]="displayFn"
                                               name="documentType1"
                                               [(ngModel)]="mappedItem.documentTypes"
                                               [selectedItem]="selectedItem">
            <cb-document-type-autocomplete-scroller class="cb-margin-top flex"
                                                    label="Document Type"
                                                    name="documentType"
                                                    [(ngModel)]="selectedDocumentTypeModel"
                                                    (optionSelected)="itemSelected($event)"
                                                    [ignoreDirty]="true">
            </cb-document-type-autocomplete-scroller>
        </cb-select-items-autocomplete-scroller>
        <cb-checkbox name="isActive"
                     label="Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!complianceRegistrationForm.dirty || !complianceRegistrationForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
