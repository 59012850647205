import {LotContractTypeEnumId, LotTypeEnumId} from '@classictechsolutions/hubapi-transpiled-enums';

export enum LOT_COMPONENT_TYPE {
    BuildDetails,
    ClassicCare,
    ClassicContacts,
    EditLotDetails,
    GuaranteeDetails,
    InformationDetails,
    InsuranceDetails,
    KeyDates,
    LotDetailsCard,
    LotDetailsSummary,
    Purchaser,
}


export interface ILotFieldRulesService {
    appliedDesignConceptVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    actualCompletionDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE,
        isStarted: boolean): boolean;

    actualStartDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE,
        isStarted: boolean): boolean;

    amenitiesFloorAreaVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    amenitiesLandAreaVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    amenitiesNoBathroomsVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    amenitiesNoBedroomsVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    amenitiesNoGaragesVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    amenitiesNoLivingRoomsVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    buildTypeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    buildProgrammeTemplateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;


    businessEntityVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    classicCareContactVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    classicCareContactMethodVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    classicCareContactDetailVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    clientAccountVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    clientAccountSolicitorVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    clientAccountSolicitorEmailVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    clientAccountLegalNameVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    clientOwnsLandVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    consentDueVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    consentNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsBuildingConsultantVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsAssignedToAccountVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsBuildingCoordinatorVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsDesignerVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsFoundationManagerVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsFramingManagerVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsHouseAndLandCoordinatorVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsQualityAssuranceManagerVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsQuantitySurveyorVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contactsSalesPersonVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contractGenerationFeeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contractStatusVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    contractTypeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    developerProjectNameVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    dpNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    estimatedCompletionDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    estimatedStartDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    expectedTitleDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    financialsBuildPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    financialsContractPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    financialsLandPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    financialsSellPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    hasFlyer(lotContractType: { documentEntityId: number; documentId: number }): boolean;

    hasTitleVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    insuranceCoverNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    insuranceInterestedPartyVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    insuranceInterestedPartyEmailVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    insuranceEndDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    insurancePriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    insuranceStartDateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    isShowHomeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    lotAddressVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    lotContractTypeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    lotNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    lotTypeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    jobCompleteActualVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    jobCompleteEstimateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    jobNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    jobNumberEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE,
        lotSpecLocked: boolean): boolean;

    jobStartEstimateVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    jobStartActualVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    jobStatusVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    landTitleVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    marketStatusVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    permitLodgedVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    parentLotVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE,
        parentId?: number): boolean;

    projectReleaseStageNameVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    roadDirectionVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    shouldShowComponent(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    vendorNameVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;

    vendorNameThirdPartyVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        componentType: LOT_COMPONENT_TYPE): boolean;
}
