import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { BrowserModule } from '@angular/platform-browser';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { CbDasherModule, CbInfoMessageModule, CbInfoPopupModule, CbLoadingSpinnerModule } from 'cb-hub-lib';
import { FilterChipListComponent } from './filter-chip-list/filter-chip-list.component';

const EXPORTS = [
    FilterChipListComponent
];

@NgModule({
    declarations: [
        ...EXPORTS,
    ],
    exports: EXPORTS,
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        MatIconModule,
        MatFormFieldModule,
        MatChipsModule,
        MatCardModule,
        MatSortModule,
        MatListModule,
        MatSelectModule,
        MatButtonModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        CbInfoPopupModule,
        CbEnumToLabelModule,
        CbSortByModule,
        CbDasherModule,
    ]
})

export class CbFilterChipsModule { }
