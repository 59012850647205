import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ChangeRecordLogicService } from '@app/logic/change-records/change-record.logic-service';
import { DesignSchemesLogicService, IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { DESIGN_SCHEME_LINE_STATUS_ENUM, IDesignSchemeLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cb-design-scheme-assigned-changes',
    templateUrl: './design-scheme-assigned-changes.component.html',
    styleUrls: ['./design-scheme-assigned-changes.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class DesignSchemeAssignedChangesComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'flex-row';
    @Input() public mappedItem: IDesignSchemeMappedItem;

    @Input() public parentForm: NgForm;

    public readonly ITEMS = 'ITEMS';

    public DESIGN_SCHEME_LINE_STATUS_ENUM = DESIGN_SCHEME_LINE_STATUS_ENUM;

    public assignedChangeRecords: IDesignSchemeLineDto[] = [];
    public unassignedChangeRecords: IDesignSchemeLineDto[] = [];

    public subscriptions$ = new Subscription();

    constructor(
        private dragulaService: DragulaService,
        public readonly logicService: DesignSchemesLogicService,
        public readonly changeRecordLogicService: ChangeRecordLogicService,
    ) {
        this.setupDragula();
    }


    public ngOnInit(): void {
        this.loadAssignedChangeRecords();
        this.loadUnassignedChangeRecords();
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.dragulaService.destroy(this.ITEMS);
    }

    private loadAssignedChangeRecords(): void {
        this.assignedChangeRecords = this.mappedItem.designSchemeLines;
        if (!this.assignedChangeRecords || this.assignedChangeRecords.length < 1) {
            this.assignedChangeRecords = [];
        }
    }

    private loadUnassignedChangeRecords(): void {
        this.changeRecordLogicService
            .getUnassignedChangeRecords(this.mappedItem.lotId)
            .subOnce((results: any) => {
                this.unassignedChangeRecords = results;
                if (!this.unassignedChangeRecords || this.unassignedChangeRecords.length < 1) {
                    this.unassignedChangeRecords = [];
                }
            });
    }

    private setupDragula(): void {

        this.dragulaService.createGroup(this.ITEMS, {
            removeOnSpill: true,
            accepts: (el: Element) => !el.classList.contains('dragula-ignore'), // do not allow move of info
            moves: (el: Element) => !el.classList.contains('dragula-ignore'), // do not allow move of info
        });

        this.subscriptions$.add(
            this.dragulaService.drop().subscribe(
                args => {
                    this.mappedItem.designSchemeLines = this.assignedChangeRecords;
                    this.parentForm.form.markAsDirty();
                }
            )
        );

    }



}
