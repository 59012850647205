
export enum BusinessAccountContactTypeIcon {
    Orders = 'shopping_cart',
    Accounts = 'attach_money',
    Sales = 'info',
    Installation = 'build',
    HealthAndSafety = 'enhanced_encryption',
}

export enum BusinessAccountContactType {
    Orders = 'Orders',
    Accounts = 'Accounts',
    Sales = 'Sales',
    Installation = 'Installation',
    HealthAndSafety = 'Health And Safety',
}
