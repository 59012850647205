import { Injectable, Injector } from '@angular/core';
import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { ISlotTagsLogicService } from './interfaces/i.slot-tags.logic-service';
import { ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ISlotTagMappedItem } from './interfaces/i.slot-tag.mapped';
import { SlotTagMappedItem } from './slot-tag.mapped';
import { Observable } from 'rxjs';

@Injectable()
export class SlotTagsLogicService
    extends BaseLogicService<ITagDto, ISlotTagMappedItem>
    implements ISlotTagsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('slots/tags', SlotTagMappedItem);
    }

    /** lookup adds "(Inactive)" to the tag name where the tag is inactive */
    public getLookup(): Observable<ITagDto[]> {
        return this.$http.get<ITagDto[]>(`${this.$baseUri}/lookup`);
    }

    public $saveItem(entity: any): Observable<ITagDto> {
        const uri = this.$baseUri;
        return this.$http.post<ITagDto>(uri, entity);
    }
}
