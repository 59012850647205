import * as _ from 'lodash';
import {Injectable} from '@angular/core';

export class SelectListItem {
    public id: number;
    public label: string;
    public disabled: boolean;
    public checked: boolean;
}

export interface ITypescriptEnum {
    [id: number]: string;
}

export interface IEnumArray {
    [id: number]: string;
}

export interface ICbEnumService {
    getSelectList(enumType: ITypescriptEnum): SelectListItem[];
    getSelectListFromValues(enumType: ITypescriptEnum, values: number[], showAll: boolean): SelectListItem[];
    getValueListFromFlag(flag: number): number[];
    getDisplayFromValue(enumType: ITypescriptEnum, value: number): string;
    getEnumAsArray(enumType: ITypescriptEnum): IEnumArray[];
}

@Injectable()
export class CbEnumService implements ICbEnumService {

    public getSelectList(enumType: ITypescriptEnum, labelFormatter: (str: string) => string = _.startCase): SelectListItem[] {
        const result: SelectListItem[] = [];
        const keys = Object.keys(enumType);
        for (let x = keys.length / 2; x < keys.length; x++) {
            if (enumType[keys[x]] === 0 || keys[x].indexOf('WF_') === 0) {
                continue;
            }
            const entry = {
                checked: false,
                disabled: false,
                id: enumType[keys[x]],
                label: labelFormatter(keys[x]),
            };
            result.push(entry);
        }

        return _.sortBy(result, ['label']);
    }

    public getSelectListFromValues(enumType: ITypescriptEnum, values: number[], showAll = false): SelectListItem[] {

        let result = this.getSelectList(enumType);

        // set disabled states
        result.forEach(element => {
            element.disabled = values.indexOf(element.id) === -1;
        });

        // if we are not showing all, remove disabled items
        if (!showAll) {
            result = _.filter(result, (element) => !element.disabled);
        }

        return result;
    }

    public getValueListFromFlag(flag: number): number[] {
        const list: number[] = [];
        for (let i = 1; i <= flag; i = i << 1) {
            if (flag & i) {
                list.push(i);
            }
        }
        return list;
    }

    public getDisplayFromValue(enumType: ITypescriptEnum, value: number): string {
        return _.startCase(enumType[value]);
    }

    /**
     * Project an enum to array
     *
     * @param ITypescriptEnum enumType
     * @returns IEnumArray[]
     */
    public getEnumAsArray(enumType: ITypescriptEnum): IEnumArray[] {
        return Object.keys(enumType).filter(k => typeof enumType[k as any] === 'number');
    }
}
