import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { ContactsLogicService } from '@app/logic/contacts';
import { LeadPermissions } from '@app/core/permissions';
import { IContactMappedItem } from '@app/logic/contacts/interfaces/i.contact.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { AddContactDialogComponent } from '@app/views/contacts/add-contact-dialog/add-contact-dialog.component';
import { MatButton } from '@angular/material/button';
import { LeadLogicService } from '@app/logic/leads';
import { ArrayUtils } from '@app/shared/utils/array.util';
import { CreateContactDialogComponent } from '@app/views/contacts/create-contact-dialog/create-contact-dialog.component';
import { sortBy } from 'lodash';
import { IContactDto } from '@app/logic/contacts/interfaces/i.contact.dto';

@Component({
    selector: 'cb-lead-client-details-card',
    templateUrl: './lead-client-details-card.component.html',
    styleUrls: ['./lead-client-details-card.component.scss'],
    providers: [
        MatButton,
    ]
})
export class LeadClientDetailsCardComponent {
    @Input() public mappedItem: ILeadMappedItem;
    @Input() public canEdit: boolean;
    @Output() public leadChanged: EventEmitter<any> = new EventEmitter<any>();

    public maxLength = 30;
    public getPreferredContactDetails(text: string): string {
        if (text.length > this.maxLength) {
            return text.substring(0, this.maxLength) + '...';
        }
        return text;
    }

    public get sortedMappedItemContacts(): IContactDto[] {
        const alphabeticallySortedContacts = sortBy(this.mappedItem.contacts, ['firstName', 'lastName']);
        const mainContactIndex = alphabeticallySortedContacts.findIndex(c => c.id === this.mappedItem.mainContactId);
        if (mainContactIndex >= 0) {
            return ArrayUtils.moveItemInArrayFromIndexToIndex<IContactDto>(alphabeticallySortedContacts, mainContactIndex, 0);
        } else {
            return alphabeticallySortedContacts;
        }
    }

    constructor(
        private readonly contactsLogicService: ContactsLogicService,
        private readonly leadPermissions: LeadPermissions,
        public readonly cbDialog: CbDialogService,
        private readonly leadLogicService: LeadLogicService
    ) {
    }

    public getSearchParams(): any {
        return {
            excludedContactIds: this.mappedItem?.contacts.map(x => x.id as string)
        };
    }

    public isMainContact(contact: IContactDto): boolean {
        return this.mappedItem?.mainContactId === contact?.id;
    }

    public openAddContactDialog(event): void {
        if (!this.canAddContact()) {
            return;
        }

        const originalContacts = this.mappedItem.contacts.map(contact => contact);

        this.cbDialog.open(AddContactDialogComponent, {
            data: {
                contacts: this.mappedItem?.contacts
            },
            minWidth: 600
        })
            .afterClosed()
            .subOnce((result) => {
                if (result) {
                    const newContact = this.mappedItem.contacts.find(c => {
                        return !originalContacts.some(oc => oc.id === c.id);
                    });
                    this.mappedItem?.addContactToLead(newContact.id).subOnce(newLead => {
                        this.mappedItem.$updateThisData(newLead);
                        this.leadChanged.emit(this.mappedItem);
                    });
                }
            });
    }

    public removeContact(event: Event, delContact: IContactDto): void {
        if (!this.canRemoveContact()) {
            return;
        }

        this.cbDialog.confirm({
            dialogHeading: 'Remove Contact from Lead',
            message: 'Are you sure you want to remove this Contact from this Lead?',
            confirmed: () => {
                this.mappedItem?.removeContactFromLead(delContact?.id);
            }
        });
    }

    public viewContact(contact: IContactDto): void {
        this.contactsLogicService.viewContact(contact?.id as string);
    }

    public editContact(contact: IContactDto): void {
        const mappedItem = this.contactsLogicService.$createMappedItem(contact);
        this.openCreateContactDialog(mappedItem);
    }

    public updateMainContact(event: Event, mainContact: IContactDto): void {
        if (!this.canRemoveContact()) {
            return;
        }
        this.cbDialog.confirm({
            dialogHeading: 'Update Lead Main Contact',
            message: 'Are you sure you want to make this the Main Contact for this Lead?',
            confirmed: () => {
                this.mappedItem?.setLeadContactAsMainContact(mainContact?.id);
            }
        });
    }

    public canAddContact(): boolean {
        // only allow adding of contacts if user has permission and is in add/edit mode.
        return this.leadPermissions.getContactPermissions().canAddContact() && this.canEdit;
    }

    public canRemoveContact(): boolean {
        // only allow adding of contacts if user has permission and is in add/edit mode.
        return this.leadPermissions.getContactPermissions().canRemoveContact() && this.canEdit;
    }

    public canEditContact(): boolean {
        // only allow adding of contacts if user has permission and is in add/edit mode.
        return this.leadPermissions.canEdit() && this.canEdit;
    }

    public canChangeMainContact(): boolean {
        return this.leadPermissions.getContactPermissions().canChangeMainContact() && this.canEdit;
    }

    private openCreateContactDialog(mappedItem: IContactMappedItem = undefined as IContactMappedItem): void {
        this.cbDialog
            .open(CreateContactDialogComponent, {
                data: { ...(mappedItem && { mappedItem }) },
                minWidth: '85%'
            })
            .afterClosed()
            .subOnce((contact: false | IContactDto) => {
                if (contact && !mappedItem) {
                    this.mappedItem?.addContactToLead(contact?.id).subOnce(res => {
                        this.mappedItem = this.leadLogicService.$createMappedItem(res);
                        this.leadChanged.emit(this.mappedItem);
                    });
                } else if (contact && mappedItem) {
                    this.mappedItem.$reload().subOnce(res => {
                        this.mappedItem = this.leadLogicService.$createMappedItem(res);
                        this.leadChanged.emit(this.mappedItem);
                    });
                }
            });
    }

}
