
import { Component, OnInit, Input } from '@angular/core';
import { LotsLogicService } from '@app/logic/lots';
import { ILotDto, ILotFinancialDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-block-costs',
    templateUrl: './block-costs.component.html',
    styleUrls: ['./block-costs.component.scss']
})
export class BlockCostsComponent implements OnInit {

    @Input() public lot: ILotDto;

    public unitCosts: ILotFinancialDetailsDto[];

    constructor(
        public readonly lotLogic: LotsLogicService,
    ) { }

    public ngOnInit(): void {
        this.lotLogic.getBlockLotCosts(this.lot.id).subOnce(costs => {
            this.unitCosts = costs;
        });
    }

}
