<div class="cb-margin-2x end flex-row">
    <button class="header-button"
            mat-raised-button
            color="primary"
            (click)="exportARInformation()">
        Export AR Information
    </button>
</div>
<div class="cb-margin-2x"
     *ngIf="lotMappedItem.hasClientSale">
    <h3>Payment Schedule</h3>
    <div class="cb-margin-2x end flex-row">
        <ng-container *ngIf="lotHasProgressPayments(); then hasProgressPayments; else noProgressPayments">
        </ng-container>
        <ng-template #hasProgressPayments>
            <div *ngIf="!canEditProgressPayments() || !this.lotIsUnconditional()"
                 class="cb-warn cb-margin-right-2x">
                <mat-icon ngClass="mat-warn"
                          style="vertical-align: middle;">error</mat-icon>
                {{getEditPaymentIconTitle()}}
            </div>
            <button *ngIf="canEditProgressPayments() && this.lotIsUnconditional()"
                    mat-raised-button
                    class="cb-margin-right"
                    color="primary"
                    (click)="editProgressPayments()"
                    [matTooltip]="getEditPaymentIconTitle()">
                <mat-icon svgIcon="pencil"></mat-icon>
                Edit Progress Payments
            </button>
            <button mat-raised-button
                    class="cb-margin-right"
                    color="primary"
                    (click)="viewProgressPaymentHistory()">
                <mat-icon>history</mat-icon>
                View History
            </button>
        </ng-template>
        <ng-template #noProgressPayments>
            <div class="cb-warn cb-margin-bottom-2x">
                <mat-icon ngClass="mat-warn"
                          style="vertical-align: middle;">error</mat-icon>
                Lot has no progress payments
            </div>
        </ng-template>
    </div>
    <cb-table class="cb-margin-top"
              label="Payments"
              [loaded]="progressPaymentLoaded"
              [minWidth]="750">
        <thead cbTableHead>
            <tr cbTableRow>
                <td>Payment Amount</td>
                <td>Event/Stage</td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr cbTableRow
                *ngFor="let payment of progressPaymentDto?.payments | cbSortByPipe: 'sortOrder' : 'asc'; let index = index;">
                <td>{{payment.dollarValue | cbCurrency}}</td>
                <cb-td-text *ngIf="payment.buildStageId"
                            [value]="payment.buildStageLabel">
                </cb-td-text>
                <cb-td-enum *ngIf="!payment.buildStageId"
                            [value]="payment.trigger"
                            [enum]="BUILD_STAGE_EVENT_ENUM">
                </cb-td-enum>
            </tr>
            <tr *ngIf="lotMappedItem.hasScheduleSpecTemplate"
                cbTableRow>
                <td>
                    <cb-display-value-new class="cb-margin-bottom-nil">
                        {{ balance | cbCurrency}}
                    </cb-display-value-new>
                </td>
                <cb-td-text value="Balance"></cb-td-text>
            </tr>
            <tr cbTableRow
                class="total-row">
                <td>
                    <cb-display-value-new class="cb-margin-bottom-nil">
                        {{ progressPaymentDto?.contractPriceWithGst | cbCurrency}}
                    </cb-display-value-new>
                </td>
                <cb-td-text value="Contract Amount"></cb-td-text>
            </tr>
            <tr cbTableRow
                class="total-row">
                <td>
                    <cb-display-value-new class="cb-margin-bottom-nil">
                        {{ progressPaymentDto?.acceptedVarianceTotalWithGst | cbCurrency}}
                    </cb-display-value-new>
                </td>
                <cb-td-text value="Accepted Variance Total"></cb-td-text>
            </tr>
            <tr cbTableRow
                class="total-row">
                <td>
                    <cb-display-value-new class="cb-margin-bottom-nil">
                        {{ (progressPaymentDto?.contractPriceWithGst + progressPaymentDto?.acceptedVarianceTotalWithGst)
                        | cbCurrency}}
                    </cb-display-value-new>
                </td>
                <cb-td-text value="Contract Total Inc. Variations"></cb-td-text>
            </tr>
        </tbody>
    </cb-table>
</div>
<div class="cb-margin-2x"
     *ngIf="variations?.length > 0">
    <h3>Variation Orders</h3>
    <cb-table class="cb-margin-top"
              label="Payments"
              [loaded]="true"
              [minWidth]="750">
        <thead cbTableHead>
            <tr cbTableRow>
                <td>Variation Order</td>
                <td>Change</td>
                <td>Description</td>
                <td>Amount $</td>
                <td>Status</td>
                <td></td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr cbTableRow
                *ngFor="let variation of variations | cbSortByPipe: 'sortOrder' : 'asc'; let index = index;"
                [ngClass]="isVariationPendingOrAwaiting(variation) ? 'table-pink' : ''">
                <td>VO{{variation.variationNumber }}</td>
                <td>{{variation.changeNumber }}</td>
                <td>{{variation.arChangeDetails ? variation.arChangeDetails : variation.changeDetails}}</td>
                <td>{{variation.lineCostWithGst | cbCurrency }}</td>
                <td>{{VARIATION_STATUS_ENUM[variation.variationStatus]}}</td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            [disabled]="!accountsReceivablePermissions.canEditPaymentSchedule()"
                            (click)="editVariationLine(variation)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </tr>
            <tr cbTableRow
                class="total-row">
                <cb-td-text value="Totals"></cb-td-text>
                <td></td>
                <td></td>
                <td>{{ variationTotals |cbCurrency }}</td>
                <td></td>
            </tr>
        </tbody>
    </cb-table>
</div>
