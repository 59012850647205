import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';

import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ProductLogicService } from '@app/logic/products';
import { PurchaseOrderUsageReportDialogComponent } from '../purchase-order-usage-report-dialog/purchase-order-usage-report-dialog/purchase-order-usage-report-dialog.component';
import { SSR_STATE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { SsrsLogicService } from '@app/logic/ssrs';
import { TableComponent, USER_CACHE_AREA } from 'cb-hub-lib';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';

@Component({
    selector: 'cb-item-usage-purchase-order-tab',
    templateUrl: './item-usage-purchase-order-tab.component.html',
    styleUrls: ['./item-usage-purchase-order-tab.component.scss']
})
export class ItemUsagePurchaseOrderTabComponent extends BaseSearchViewDirective<any> implements OnInit, AfterContentChecked {
    @Input() public productId: number;
    @ViewChild('infiniteScrollTable', { static: false }) public infiniteScrollTable: TableComponent;
    public SSR_STATE_ENUM = SSR_STATE_ENUM;

    public downloadPurchaseOrder = (purchaseOrder: any): void => {
        this.ssrLogicService.downloadPo(purchaseOrder.sSrId).subOnce();
    };

    constructor(
        public readonly navigationService: NavigationService,
        public readonly productLogicService: ProductLogicService,
        public readonly toastSerivce: ToastService,
        public readonly userCacheService: UserCacheService,
        public readonly cbDialogService: CbDialogService,
        public readonly ssrLogicService: SsrsLogicService,
        private readonly cdref: ChangeDetectorRef) {

        super(
            productLogicService,
            toastSerivce,
            userCacheService,
            USER_CACHE_AREA.BuildActivitySearch,
        );
    }

    public ngOnInit(): void {
        this.loadSearchParams();
    }

    public ngAfterContentChecked(): void {
        this.cdref.detectChanges();
    }

    public doSearch = (): void => {
        if (this.shouldDoSearch()) {
            this.doSearchIfNoScrollBar();
            this.currentPage++;
            this.searchIsLoading = true;
            this.productLogicService
                .getProductPurchaseOrderUsage(this.productId, {
                    currentPage: this.currentPage,
                    pageSize: this.pageSize
                }).subOnce({
                    next: this.handleSearchResult,
                    error: this.handleSearchError
                });
        }
    };

    public downloadUsageReport(): void {
        this.cbDialogService.open(
            PurchaseOrderUsageReportDialogComponent,
            {
                data: {
                    productId: this.productId
                },
                fullWidth: true,
                minWidth: 600,
            }
        )
            .afterClosed()
            .subOnce();
    }
}
