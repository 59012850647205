<div class="flex-wrap sm-column xs-column">
    <div class="flex-col md-50 lg-33 sm-50 xl-33 xs-50">
        <cb-lead-client-details-card *cbWaitFor="mappedItem"
                                     [mappedItem]="mappedItem"
                                     [canEdit]="canEdit"
                                     (leadChanged)="updateLead($event)"
                                     class="cb-margin flex-row flex">
        </cb-lead-client-details-card>
    </div>
    <div class="flex-col md-50 lg-33 sm-50 xl-33 xs-50">
        <cb-lead-contract-type-tbc-card *ngIf="isContractTypeTbc"
                                        [mappedItem]="mappedItem"
                                        [canEdit]="canEdit"
                                        class="cb-margin flex-col flex">
        </cb-lead-contract-type-tbc-card>
        <cb-lead-house-land-interests-card *ngIf="shouldShowHouseLandInterest"
                                           [mappedItem]="mappedItem"
                                           [canEdit]="canEdit"
                                           class="cb-margin flex-col flex">
        </cb-lead-house-land-interests-card>
        <cb-lead-design-build-card *ngIf="isDesignAndBuild"
                                   [mappedItem]="mappedItem"
                                   [canEdit]="canEdit"
                                   class="cb-margin flex-col flex">
        </cb-lead-design-build-card>
    </div>
    <div class="flex-col md-50 lg-33 sm-50 xl-33 xs-50">
        <cb-lead-enquiry-origin-card [mappedItem]="mappedItem"
                                     [canEdit]="canEdit"
                                     class="cb-margin flex-col flex">
        </cb-lead-enquiry-origin-card>
    </div>
    <div class="flex-col md-50 lg-33 sm-50 xl-33 xs-50">
        <cb-lead-finance-info-card [mappedItem]="mappedItem"
                                   [canEdit]="canEdit"
                                   class="cb-margin flex-col flex">
        </cb-lead-finance-info-card>
    </div>
    <div class="flex-col md-50 lg-33 sm-50 xl-33 xs-50">
        <cb-lead-qualify-card [mappedItem]="mappedItem"
                              [canEdit]="canEdit"
                              [stepNumber]="qualifyStepNumber()"
                              class="cb-margin flex-col flex">
        </cb-lead-qualify-card>
    </div>
    <div class="flex-col md-50 lg-33 sm-50 xl-33 xs-50">
        <cb-lead-followup-card [mappedItem]="mappedItem"
                               [canEdit]="canEdit"
                               (leadChanged)="updateLead($event)"
                               class="cb-margin flex-col flex">
        </cb-lead-followup-card>
    </div>
</div>
