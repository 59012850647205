import { Injectable, Injector } from '@angular/core';
import { SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { DocumentGroupMappedItem, IDocumentGroupDto, IDocumentGroupMappedItem } from '../documents';
import { ISystemAreaLogicService } from './interfaces/i.system-area.logic.service';

@Injectable()
export class SystemAreaLogicService
    extends BaseLogicService<IDocumentGroupDto, IDocumentGroupMappedItem>
    implements ISystemAreaLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'systemareas',
            DocumentGroupMappedItem,
        );
    }

    public getMappedDocumentGroups(entityUri: string, entityId: number | string, systemArea: number): Observable<IDocumentGroupMappedItem[]> {
        return this.$http.get<IDocumentGroupDto[]>(`${this.$baseUri}/${systemArea}/${entityUri}`)
            .pipe(
                map(results => results.map(value => this.createMappedDocumentGroupItem('documentsgroups', entityId, value)))
            );
    }

    public getBySystemArea(systemAreaId: SystemAreaEnumId): Observable<IDocumentGroupDto[]> {
        return this.$http.get(`${this.$baseUri}/${systemAreaId}/documentsgroups`);
    }

    private createMappedDocumentGroupItem(entityUri: string, entityId: number | string, document?: IDocumentGroupDto): IDocumentGroupMappedItem {
        const result = this.$createMappedItem(document);
        result.entityUri = entityUri;
        result.entityId = entityId;
        return result;
    }
}
