<cb-dialog class="flex-col"
           dialogHeading="Request Scheme - Step {{selectedTabStep + 1}}"
           (cancelDialog)="cancel(true)">
    <form class="flex-col flex"
          #requestSchemeForm="ngForm"
          *cbWaitFor="mappedItem">
        <mat-tab-group class="cb-wizard"
                       [dynamicHeight]="true"
                       [(selectedIndex)]="selectedTabStep">
            <mat-tab>
                <form class="flex-col flex"
                      #step1Form="ngForm">
                    <cb-request-design-scheme-step-one [lotType]="lotMappedItem.lotType"
                                                       [mappedItem]="mappedItem">
                    </cb-request-design-scheme-step-one>
                </form>
            </mat-tab>
            <mat-tab>
                <form class="flex-col flex"
                      #step2Form="ngForm">
                    <cb-request-design-scheme-step-two [lotType]="lotMappedItem.lotType"
                                                       [mappedItem]="mappedItem">
                    </cb-request-design-scheme-step-two>
                </form>
            </mat-tab>
            <mat-tab>
                <form class="flex-col flex"
                      #step3Form="ngForm">
                    <cb-request-design-scheme-step-three [childForm]="step3Form.form"
                                                         [form]="requestSchemeForm.form"
                                                         [documentsToUpload]="documentsToUpload"
                                                         [lotId]="lotMappedItem.id"
                                                         [lotContractType]="lotMappedItem.contractType"
                                                         [mode]="mode"
                                                         [mappedItem]="mappedItem">
                    </cb-request-design-scheme-step-three>
                </form>
            </mat-tab>
            <mat-tab>
                <form class="flex-col flex"
                      #step4Form="ngForm">
                    <cb-request-design-scheme-step-four class="flex flex-row"
                                                        [lotMappedItem]="lotMappedItem"
                                                        [documentsToUpload]="documentsToUpload">
                    </cb-request-design-scheme-step-four>
                </form>
            </mat-tab>
            <mat-tab>
                <form class="flex-col flex"
                      #step5Form="ngForm">
                    <cb-request-design-scheme-step-five [childForm]="step5Form.form"
                                                        [form]="requestSchemeForm.form"
                                                        [documentsToUpload]="documentsToUpload"
                                                        [lotId]="lotMappedItem.id"
                                                        [mode]="mode"
                                                        [mappedItem]="mappedItem">
                    </cb-request-design-scheme-step-five>
                </form>
            </mat-tab>
            <mat-tab>
                <form class="flex-col flex"
                      #step6Form="ngForm">
                    <cb-request-design-scheme-step-six [form]="requestSchemeForm.form"
                                                       [mode]="mode"
                                                       [mappedItem]="mappedItem">
                    </cb-request-design-scheme-step-six>
                </form>
            </mat-tab>
            <mat-tab>
                <form class="flex-col flex"
                      #step7Form="ngForm">
                    <cb-request-design-scheme-step-seven [documentsToUpload]="documentsToUpload"
                                                         [mappedItem]="mappedItem">
                    </cb-request-design-scheme-step-seven>
                </form>
            </mat-tab>
        </mat-tab-group>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="back()"
                    *ngIf="canGoBack()">
                Back
            </button>
            <button mat-raised-button
                    color="primary"
                    *ngIf="canGoNext()"
                    [disabled]="!isStepComplete(step1Form.form, step2Form.form, step3Form.form, step4Form.form, step5Form.form, step6Form.form, step7Form.form)"
                    (cbClick)="next()">
                Next
            </button>
            <button mat-raised-button
                    color="primary"
                    [disabled]="isSaveDisabled(step7Form.form)"
                    (cbClick)="requestDesignScheme()"
                    *ngIf="canSeeSave()"
                    [clickLimit]="1">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel(true)">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
