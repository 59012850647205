import {BaseMappedItem} from '../base/base-mapped-item';
import {IStandardPlanCategoryDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {ICategoryMappedItem} from './interfaces/i.category.mapped';
import {ICategoriesLogicService} from './interfaces/i.category.logic.service';
import {DtoProp} from '../base/dto-prop.decorator';

export class CategoryMappedItem
    extends BaseMappedItem<IStandardPlanCategoryDto, ICategoryMappedItem, ICategoriesLogicService>
    implements ICategoryMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public name: string;

    constructor(
        sourceData: IStandardPlanCategoryDto,
        logicService: ICategoriesLogicService
    ) {
        super(sourceData, logicService, CategoryMappedItem);
    }
}
