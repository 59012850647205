import { Input, Component, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChild, AfterViewChecked, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { DesignTeamPermissions, IDesignTeamPermissions, IQSTeamPermissions, QSTeamPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ITeamTaskSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { TasksLogicService } from '@app/logic/tasks';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { getBaseTasksLocation } from '@app/shared/utils/task-path-util';
import { ITaskWithEntityDetailsDto, SYSTEM_AREA_ENUM, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from '@classichub/cb-hub-lib';
import { orderBy } from 'lodash';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject, Subscription, map, skipWhile, switchMap, tap } from 'rxjs';
import { CurrentUserService } from '@app/core/authentication/current.user';


@Component({
    selector: 'cb-teams-tasks',
    templateUrl: './team-tasks.component.html',
    styleUrls: ['./team-tasks.component.scss'],
})
export class TeamsTasksComponent implements OnDestroy, AfterViewInit, AfterViewChecked, OnInit {

    public resultOrders$ = of([
        { id: 'assignedToName', label: 'Assigned To' },
        { id: 'dueDate', label: 'Due Date' }
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    @Input() public get searchFilters(): UserCacheItem<ITeamTaskSearch> {
        return this.userCacheService.qsTeamTaskSearch;
    }

    @Input() public set teamCode(value: string) {
        this._teamCode = value;
        this._teamCode$.next(value);
    }

    private _teamCode$ = new BehaviorSubject<string>(null);
    private _teamCode: string;
    public isUnassigned: boolean;

    private _subscriptions = new Subscription();
    public searchFiltersLoaded$ = new BehaviorSubject(null);
    public searchEnabled$ = new BehaviorSubject(null);
    public readonly searchFiltersTaskd$ = new Subject();
    public currentPage: number;
    public readonly allUsers = '999';
    public readonly allStatuses = 999;
    public readonly unassigned = undefined;
    public loaded = false;
    public permissions: IQSTeamPermissions | IDesignTeamPermissions;

    private _isFormInitialised = false;

    @ViewChild(NgForm) public formLiteral: NgForm;
    private _form$ = new BehaviorSubject<NgForm>(null);

    @ViewChild(NgForm) public set form(form: NgForm) {
        if ((form && Object.keys(form.form?.controls).length > 0)) {
            this._form$?.next(form);
        }
    }

    public get searchEnabled(): boolean {
        return this.searchEnabled$.value;
    }

    public get searchFiltersLoaded(): boolean {
        return this.searchFiltersLoaded$.value;
    }

    public formValueChanged$ = this._form$.asObservable().pipe(
        skipWhile(form => !form),
        switchMap(form => form.valueChanges)
    );

    public results$ = this._teamCode$.asObservable().pipe(
        skipWhile(code => !code),
        switchMap(code => iif(() => code === TEAM_CODES_CONST.QsTeamKey,
            this.logicService.getTasksForTeam('qsteam'),
            this.logicService.getTasksForTeam('designteam')
        )),
        tap(() => this.loaded = true)
    );

    public sortedResult$ = combineLatest([
        this.results$,
        this.formValueChanged$,
    ]).pipe(
        map(([results, formValue]) => {
            const filteredResults = results.filter(
                result => (formValue.userId === this.allUsers ||
                    result.assignedToId === formValue.userId ||
                    (!result.assignedToId && formValue.userId === this.unassigned) ||
                    // when 'view all' is not allowed the form control for userId does not exist, in that case just allowed tasks for the current user
                    (!formValue.userId && result.assignedToId === this.currentUser.guid)) &&
                    (
                        isNullOrWhiteSpace(formValue.search) ||
                        result.subject?.toLocaleUpperCase().includes(formValue.search.toLocaleUpperCase()) ||
                        formValue.search.toLocaleUpperCase().includes(result.subject?.toLocaleUpperCase()) ||
                        result.comments?.toLocaleUpperCase().includes(formValue.search.toLocaleUpperCase()) ||
                        formValue.search.toLocaleUpperCase().includes(result.comments?.toLocaleUpperCase())
                    )
            );
            const sortedResults = orderBy(
                filteredResults,
                [formValue.resultOrders],
                [formValue.direction]
            );
            return sortedResults;
        })
    );

    public teamUsersOptions$ = this._teamCode$.asObservable().pipe(
        skipWhile(code => !code),
        switchMap(code => this.teamsLogicService.loadAllMembersByKey(code)),
        map(users => {
            users = orderBy(users, 'firstName');
            users.unshift({ id: this.allUsers, label: 'All' } as any);
            users.unshift({ id: this.unassigned, label: 'Unassigned' } as any);
            return users;
        })
    );

    private _setPermissions = (teamCode: string): QSTeamPermissions | DesignTeamPermissions => {
        switch (teamCode) {
            case TEAM_CODES_CONST.QsTeamKey:
                return this.qsTeamPermissions;
            case TEAM_CODES_CONST.DesignTeamKey:
                return this.designTeamPermissions;
            default:
                return this.qsTeamPermissions;
        }
    };

    private _permissions$ = new BehaviorSubject<QSTeamPermissions | DesignTeamPermissions>(null);
    public permissions$ = this._teamCode$.asObservable().pipe(
        skipWhile(code => !code),
        map(this._setPermissions)
    );

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: TasksLogicService,
        public cdRef: ChangeDetectorRef,
        private readonly navigationService: NavigationService,
        public readonly qsTeamPermissions: QSTeamPermissions,
        public readonly designTeamPermissions: DesignTeamPermissions,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
        private readonly currentUser: CurrentUserService,
    ) {
        this.permissions$.subscribe(this._permissions$);
    }

    public ngOnInit(): void {
        this.setPermissions();
    }
    public ngAfterViewInit(): void {
        this._initSearchFiltersCache();
        this.cdRef.detectChanges();
    }

    private setPermissions = (): void => {
        switch (this._teamCode) {
            case TEAM_CODES_CONST.QsTeamKey:
                this.permissions = this.qsTeamPermissions;
                break;
            case TEAM_CODES_CONST.DesignTeamKey:
                this.permissions = this.designTeamPermissions;
                break;
            default:
                break;
        }
    };

    public ngAfterViewChecked(): void {
        if (this.formLiteral?.controls && Object.keys(this.formLiteral?.controls)
            .length > 0 && !this._isFormInitialised) {
            this.form = this.formLiteral;
            this._isFormInitialised = true;
        }
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public viewTask(task: ITaskWithEntityDetailsDto): void {
        switch (task.systemArea) {
            case SYSTEM_AREA_ENUM.CouncilRfi:
                this.navigationService.navigate([getBaseTasksLocation(
                    task.systemArea,
                    task.entityId.toString(),
                    task.parentEntityId ? task.parentEntityId.toString() : undefined,
                    task.entityId
                )], undefined, { queryParams: { paramEntityId: task.entityId } } as NavigationExtras);
                break;
            case SYSTEM_AREA_ENUM.ClientSale:
                this.navigationService.navigate([getBaseTasksLocation(
                    task.systemArea,
                    task.entityId.toString(),
                    task.parentEntityId ?
                        task.parentEntityId.toString() :
                        undefined
                )]);
                break;
            default:
                this.navigationService.navigate([getBaseTasksLocation(
                    task.systemArea,
                    task.entityId.toString(),
                    task.parentEntityId ?
                        task.parentEntityId.toString() :
                        undefined
                )]);
                break;
        }
    }

    private _initSearchFiltersCache(): void {
        this.searchFilters.init().then(() => {
            this.searchFiltersLoaded$.next(true);
            this.searchEnabled$.next(true);
            this.searchFilters.data.userId = this.allUsers;
            if (this.searchFilters.data.order === null) {
                this.searchFilters.data.order = 'dueDate';
            }
            this._onSearchFiltersChanged();
            this._subscriptions.add(
                this.searchFilters.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );
            this._onSearchFiltersChanged();
        });
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersTaskd$.next(null);
    };
}

