import { Computed } from '@app/logic/base/computed-prop.decorator';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ContactStatusEnumId, CONTACT_METHOD_ENUM, CONTACT_STATUS_ENUM, IBusinessAccountContactAreaDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IAddressDto } from '@app/shared/interfaces/i.address.dto';
import { IContactMappedItem } from './interfaces/i.contact.mapped';
import { IContactsLogicService } from '.';
import { cleanObjectDeep } from 'cb-hub-lib';
import { isEmpty } from 'lodash';
import { DtoProp } from '../base/dto-prop.decorator';
import { IContactDto } from './interfaces/i.contact.dto';

export class ContactMappedItem
    extends BaseMappedItem<IContactDto, IContactMappedItem, IContactsLogicService>
    implements IContactMappedItem {

    @DtoProp public id: string;
    @DtoProp public guid: string;
    @DtoProp public email: string;
    @DtoProp public areas: IBusinessAccountContactAreaDto[];
    @DtoProp public title: number;
    @DtoProp public firstName: string;
    @DtoProp public isDefaultSupplier: boolean;
    @DtoProp public lastName: string;
    @DtoProp public preferredContact: number;
    @DtoProp public receivesDailyEmails: boolean;
    @DtoProp public phoneHome: string;
    @DtoProp public phoneWork: string;
    @DtoProp public phoneMobile: string;
    @DtoProp public physicalAddress: IAddressDto;
    @DtoProp public postalAddress: IAddressDto;
    @DtoProp public isMainContact: boolean;
    @DtoProp public contactComplianceRegistrations: any[];
    @DtoProp public hasBusinessAccount: boolean;
    @DtoProp public gender: string;
    @DtoProp public label: string;
    @DtoProp public status: ContactStatusEnumId;
    @DtoProp public portalUsername: string;
    @DtoProp public isPortalAccessEnabled: boolean;
    @DtoProp public preferredContactDetails: string;
    @DtoProp public incomplete: boolean;

    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;

    @DtoProp public ageRange: number;
    @DtoProp public getsSurveys: boolean;
    @DtoProp public getsNewsletter: boolean;
    @DtoProp public isStaffMember: boolean;

    public get noteEntityId(): string {
        return this.id;
    }

    public get noteEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    @Computed() public get isActiveStatus(): boolean {
        return this.status === CONTACT_STATUS_ENUM.Active;
    }

    constructor(
        sourceData: IContactDto,
        logicService: IContactsLogicService
    ) {
        super(sourceData, logicService, ContactMappedItem,
            // default data
            {
                physicalAddress: {} as IAddressDto,
                postalAddress: {} as IAddressDto,
                status: CONTACT_STATUS_ENUM.Active,
                incomplete: false,
            }
        );
    }

    protected $preSave(toSave: IContactDto): void {
        cleanObjectDeep(toSave.postalAddress);
        cleanObjectDeep(toSave.physicalAddress);

        if (isEmpty(toSave.postalAddress)) {
            delete toSave.postalAddress;
        }

        if (isEmpty(toSave.physicalAddress)) {
            delete toSave.physicalAddress;
        }
    }
    public hasPhoneHome(): boolean {
        return this.phoneHome && this.phoneHome.length > 0;
    }

    public isHomePreferred(): boolean {
        return this.preferredContact === CONTACT_METHOD_ENUM.HomePhone;
    }

    public hasPhoneWork(): boolean {
        return this.phoneWork && this.phoneWork.length > 0;
    }

    public isWorkPreferred(): boolean {
        return this.preferredContact === CONTACT_METHOD_ENUM.WorkPhone;
    }

    public hasPhoneMobile(): boolean {
        return this.phoneMobile && this.phoneMobile.length > 0;
    }

    public isMobilePreferred(): boolean {
        return this.preferredContact === CONTACT_METHOD_ENUM.MobilePhone;
    }

    public isEmailPreferred(): boolean {
        return this.preferredContact === CONTACT_METHOD_ENUM.Email;
    }
}
