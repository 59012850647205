import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbFilterChipsModule } from '@app/shared/components/filter-chips/filter-chips.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHistoryModule } from '@app/shared/components/history/history.module';
import { CbImageButtonModule } from '@app/shared/components/image-handlers/image-button/image-button.module';
import { CbNotesModule } from '@app/shared/components/notes/notes.module';
import { CbTasksModule } from '@app/shared/components/tasks/tasks.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbFillHeightModule } from '@app/shared/directives/fill-height/fill-height.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbCallbackModule } from '@app/shared/pipe/callback/callback.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbHouseLandModule } from '@app/views/house-land/house-land.module';
import { LeadEditComponent } from '@app/views/leads/pages/lead-edit';
import { LeadSearchCardComponent, LeadSearchComponent } from '@app/views/leads/pages/lead-search';
import {
    LeadClientDetailsCardComponent, LeadContractTypeTbcCardComponent, LeadDesignBuildCardComponent,
    LeadEnquiryOriginCardComponent,
    LeadFinanceInfoCardComponent, LeadFollowupCardComponent, LeadHouseLandInterestsCardComponent, LeadQualifyCardComponent, LeadViewComponent
} from '@app/views/leads/pages/lead-view';
import { MyLeadsComponent } from '@app/views/leads/pages/my-leads';
import { CbDasherModule, CbInfiniteScrollModule, CbInfoMessageModule, CbSelectListModule } from 'cb-hub-lib';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CbSearchDialogModule } from '../../shared/components/search-dialog/search-dialog.module';
import { ContactsModule } from '../contacts/contacts.module';
import { LotBuildDocumentsModule } from '../lot/build/documents/documents.module';
import { CreateLeadDialogComponent } from './create-lead-dialog/create-lead-dialog.component';
import { MyLeadsSidePanelComponent } from './my-leads-side-panel/my-leads-side-panel.component';
import { LeadDetailsEditDialogComponent } from './pages/dialogs/lead-details-edit-dialog.component';
import { LeadSideBarCardNewComponent } from './pages/lead-side-bar-card';
import { LeadChooseContractTypeDialogComponent } from './pages/lead-view/lead-contract-type-tbc-card/lead-choose-contract-type-dialog';
import { LeadEnquiryOriginEditDialogComponent } from './pages/lead-view/lead-enquiry-origin-card';
import { LeadFinanceInfoEditDialogComponent } from './pages/lead-view/lead-finance-info-card/lead-finance-info-edit-dialog/lead-finance-info-edit-dialog.component';
import { AddLotDialogComponent } from './pages/lead-view/lead-house-land-interests-card';
import { LeadLostDialogComponent } from './pages/lead-view/lead-qualify-card';
import { LeadQualifyDesignBuildDialogComponent } from './pages/lead-view/lead-qualify-card/lead-qualify-design-build-dialog/lead-qualify-design-build-dialog.component';
import { LeadQualifyHouseLandDialogComponent } from './pages/lead-view/lead-qualify-card/lead-qualify-house-land-dialog/lead-qualify-house-land-dialog.component';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';

@NgModule({
    declarations: [
        LeadDetailsEditDialogComponent,
        CreateLeadDialogComponent,
        MyLeadsSidePanelComponent,
        LeadSearchComponent,
        LeadSearchCardComponent,
        LeadViewComponent,
        MyLeadsComponent,
        LeadEditComponent,
        LeadClientDetailsCardComponent,
        LeadDesignBuildCardComponent,
        LeadEnquiryOriginCardComponent,
        LeadFinanceInfoCardComponent,
        LeadQualifyCardComponent,
        LeadFollowupCardComponent,
        LeadContractTypeTbcCardComponent,
        LeadHouseLandInterestsCardComponent,
        LeadSideBarCardNewComponent,
        LeadChooseContractTypeDialogComponent,
        AddLotDialogComponent,
        LeadEnquiryOriginEditDialogComponent,
        LeadFinanceInfoEditDialogComponent,
        LeadLostDialogComponent,
        LeadQualifyHouseLandDialogComponent,
        LeadQualifyDesignBuildDialogComponent
    ],
    exports: [
        LeadEnquiryOriginEditDialogComponent
    ],
    imports: [
        RouterModule.forRoot(
            [
                {
                    component: MyLeadsComponent,
                    path: 'leads',
                    data: {
                        breadcrumb: 'View Lead',
                        breadcrumbPredecessors: [
                            { label: 'Lead Search', url: '/leads/search' }
                        ]
                    }
                },
                {
                    component: LeadSearchComponent,
                    path: 'leads/search',
                    data: {
                        breadcrumb: 'Lead Search'
                    }
                },
                {
                    component: MyLeadsComponent,
                    path: 'leads/:id/:cbTabRouteId',
                    data: {
                        breadcrumb: 'View Lead',
                        breadcrumbPredecessors: [
                            { label: 'Lead Search', url: '/leads/search' }
                        ]
                    }
                }
            ],
            {
    onSameUrlNavigation: 'reload'
}
        ),
        InfiniteScrollModule,
        CommonModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatTabsModule,
        MatTooltipModule,
        MatInputModule,
        MatFormFieldModule,
        CbCurrencyModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatButtonModule,
        CbDialogModule,
        CbFormsModule,
        CbClickModule,
        MatCardModule,
        MatMenuModule,
        CbFillHeightModule,
        CbWaitForModule,
        MatListModule,
        CbInfoMessageModule,
        CbInfiniteScrollModule,
        CbFilterChipsModule,
        CbSelectListModule,
        CbDisplayValueModule,
        CbUserDateTimeModule,
        CbCallbackModule,
        CbDasherModule,
        ContactsModule,
        CbTasksModule,
        CbNotesModule,
        CbDocumentsModule,
        CbHistoryModule,
        CbImageButtonModule,
        LotBuildDocumentsModule,
        CbHouseLandModule,
        CbTabRouteModule,
        CbSearchDialogModule,
    ]
})

export class LeadsModule { }
