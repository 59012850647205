import { Component, Input } from '@angular/core';

@Component({
    selector: 'cb-basic-list-item',
    templateUrl: './basic-list-item.component.html',
    styleUrls: ['./basic-list-item.component.scss']
})
export class BasicListItemComponent {
    @Input() public readonly itemId: number | string;
    @Input() public readonly description: string;

}
