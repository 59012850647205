import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { delay, mergeMap, Observable, of, retryWhen, throwError } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ILocationDto, ILocationTreeDto } from './interfaces/i.location.dto';
import { ILocationLogicService } from './interfaces/i.location.logic.service';
import { ILocationMappedItem } from './interfaces/i.location.mapped';
import { LocationMappedItem } from './location.mapped';

@Injectable()
export class LocationLogicService extends BaseLogicService<ILocationDto, ILocationMappedItem> implements ILocationLogicService {
    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        protected readonly httpClient: HttpClient,
    ) {
        super('locations', LocationMappedItem);
    }

    public getAreasLookup(): Observable<ILocationDto[]> {
        return this.$http
            .get<Array<ILocationDto>>(`${this.$baseUri}/areaslookup`);
    }

    public getAreasByRegionId(params: { addressRegionId: number }): Observable<ILocationDto[]> {
        return this.$http
            .get<Array<ILocationDto>>(`${this.$baseUri}/areasfromaddressregion`, params);
    }

    public getLocationList(parentLocationId?: number): Observable<ILocationDto[]> {
        if (parentLocationId) {
            return this.$http
                .get<Array<ILocationDto>>(`${this.$baseUri}/${parentLocationId}`);
        } else {
            return this.$getList();
        }
    }

    public $getItem(entityId: string | number): Observable<ILocationDto> {
        return this.$http.get<ILocationDto>(`${this.$baseUri}/${entityId}/details`).pipe(
            retryWhen(err => {
                let retries = 2;
                return err.pipe(
                    delay(1000),
                    mergeMap(error => {
                        retries--;
                        if (retries > 0) {
                            return of(error);
                        } else {
                            return throwError(error);
                        }
                    }));
            })
        );
    }

    public getLocationTree(): Observable<ILocationTreeDto> {
        return this.$http.get(`${this.$baseUri}/locationtree`);
    }

    public saveItem(globalSettings: ILocationDto): Observable<ILocationDto> {
        let uri = this.$baseUri;
        if (globalSettings.id > 0) {
            uri += `/${globalSettings.id}`;
        }
        return this.$http
            .post<ILocationDto>(uri, globalSettings);
    }

    public export(showActiveOnly: boolean): Observable<any> {
        return this.$http.download(`${this.$baseUri}/export?activeOnly=${showActiveOnly}`);
    }

    public getFlatTree(showActiveOnly: boolean): Observable<ILocationDto[]> {
        return this.$http.get(`${this.$baseUri}/flattree?activeOnly=${showActiveOnly}`);
    }
}
