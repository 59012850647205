import { AfterContentChecked, Directive, ElementRef, Input } from '@angular/core';
import { isNullOrWhiteSpace } from 'cb-hub-lib';

/** Applies config.class to config.ele if this.ref has child elements  */
@Directive({
    selector: '[cbApplyClassIfHasContent]'
})
export class ApplyClassIfHasContentDirective implements AfterContentChecked {

    @Input('cbApplyClassIfHasContent') public readonly config: { ele: HTMLElement; class: string };

    public get hasClass(): boolean {
        return this.config.ele.classList.contains(this.config?.class);
    }

    constructor(private readonly ref: ElementRef<HTMLElement>) { }

    public ngAfterContentChecked(): void {
        // const hasContent = this.ref.nativeElement?.childElementCount > 0;
        const hasContent = !isNullOrWhiteSpace(this.ref?.nativeElement?.innerText);
        if (hasContent && !this.hasClass) {
            this.config.ele.classList.add(this.config.class);
            return;
        } else if (!hasContent && this.hasClass) {
            this.config.ele.classList.remove(this.config.class);
            return;
        }
    }
}
