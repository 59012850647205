<div class="flex-col"
     id="user-info">
    <div class="details flex-row"
         *ngIf="authService.isAuthenticated">
        <div class="flex-col">
            <span class="mat-title">{{currentUser.profile.name}}</span>
            <span class="mat-body-2">{{currentUser.userName}}</span>
        </div>
    </div>
    <div class="flex-row"
         *ngIf="authService.isAuthenticated">
        <button mat-raised-button
                matTooltip="Logout"
                (click)="authService.logout()">Logout</button>
        <span class="flex"></span>
        <button mat-icon-button
                matTooltip="Settings"
                (click)="navigateToUserSettings($event)">
            <mat-icon>settings</mat-icon>
        </button>
    </div>
    <span class="mat-subhead"
          *ngIf="!authService.isAuthenticated">Please log on</span>
</div>
