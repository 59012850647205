import {HttpClient} from '@angular/common/http';
import {LotVariationPermissionEnum} from '@app/shared/enums/permissions/lot.variation.permission';
import {Router} from '@angular/router';
import {CurrentUserService} from '../authentication/current.user';
import {BasePermissions, IBaseSystemAreaPermissions, IhaveNotePermissions, INotePermissions} from './base.permissions';
import {Injectable} from '@angular/core';


export interface ILotVariationPermissions extends IBaseSystemAreaPermissions, IhaveNotePermissions {
    canSeeComponent(): boolean;
    canView(): boolean;
    canCreate(): boolean;
    canEdit(): boolean;
    canDelete(): boolean;
    canGenerateReport(): boolean;
    canFinalise(): boolean;
}


@Injectable()
export class LotVariationPermissions extends BasePermissions<LotVariationPermissionEnum> implements ILotVariationPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lotvariation', LotVariationPermissionEnum, currentUser, http, router);
    }


    public canSeeComponent(): boolean {
        return this.permissionObject(LotVariationPermissionEnum.LotChangeCanViewVariation);
    }

    public canView(): boolean {
        return this.permissionObject(LotVariationPermissionEnum.LotChangeCanViewVariation);
    }

    public canEdit(): boolean {
        return this.permissionObject(LotVariationPermissionEnum.LotChangeCanEditVariation);
    }

    public canDelete(): boolean {
        return this.permissionObject(LotVariationPermissionEnum.LotChangeCanDeleteVariation);
    }

    public canCreate(): boolean {
        return this.permissionObject(LotVariationPermissionEnum.LotChangeCanCreateVariation);
    }

    public canGenerateReport(): boolean {
        return this.permissionObject(LotVariationPermissionEnum.LotChangeCanGenerateVariationReport);
    }

    public canFinalise(): boolean {
        return this.permissionObject(LotVariationPermissionEnum.LotChangeCanFinaliseVariation);
    }

    public getNotePermissions(): INotePermissions {
        throw new Error('Method not implemented');
    }
}
