import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CommonModule } from '@angular/common';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatMenuModule } from '@angular/material/menu';
import { CbInfoMessageModule } from 'projects/cb-hub-lib/src/lib/components/info-message/info-message.module';
import { CbDasherModule } from 'projects/cb-hub-lib/src/lib/pipe/dasher/dasher.module';
import { CbButtonModule } from '@app/shared/components/button/button.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { CbSortByImpureModule } from '@app/shared/pipe/sort-by-impure/sort-by-impure.module';
import { CbInfiniteScrollModule, CbInfoPopupModule, CbLoadingSpinnerModule, CbTableModule } from 'cb-hub-lib';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { BlockCostsComponent } from './block-costs/block-costs.component';
import { LotCostsSummarysComponent } from './lot-costs-summary/lot-costs-summary.component';
import { LotCostsAreaComponent } from './lot-costs-area/lot-costs-area.component';
import { PricingRevisionsSearchComponent } from './lot-costs-pricing-revisions/lot-costs-pricing-revisions-search/pricing-revisions-search.component';
import { ManagePricingRevisionDialogComponent } from './lot-costs-pricing-revisions/manage-pricing-revision-dialog/manage-pricing-revision-dialog.component';
import { FinalisePricingRevisionDialogComponent } from './lot-costs-pricing-revisions/finalise-pricing-revision-dialog/finalise-pricing-revision-dialog.component';
import { CbPricingRevisionCardModule } from '@app/shared/components/search-results/cards/pricing-revision-card/pricing-revision-card.module';
import { LotCostsConfirmedQuantitiesComponent } from './lot-costs-confirmed-quantities/lot-costs-confirmed-quantities.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LotCostsActualComponent } from './lot-costs-actual/lot-costs-actual.component';
import { CbTakeoffCardModule } from '@app/shared/components/search-results/cards/takeoff-card/takeoff-card.module';
import { TakeoffTasksSearchComponent } from './lot-costs-takeoffs/tasks/takeoff-tasks-search/takeoff-tasks-search.component';
import { TakeoffTaskDialogComponent } from './lot-costs-takeoffs/tasks/takeoff-task-dialog/takeoff-task-dialog.component';
import { TakeoffTaskComponent } from './lot-costs-takeoffs/tasks/takeoff-task/takeoff-task.component';
import { CbDateDurationCalculatorModule } from '@app/shared/components/date-duration-calculator/date-duration-calculator.module';
import { CbElapsedTimeModule } from '@app/shared/components/elapsed-time/elapsed-time.module';
import { LotTakeoffCardComponent } from './lot-costs-takeoffs/lot-takeoff-card/lot-takeoff-card.component';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { UploadTakeoffDialogComponent } from './lot-costs-takeoffs/upload-takeoff-dialog/upload-takeoff-dialog.component';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { TakeoffCompareComponent } from './lot-costs-takeoffs/takeoff-compare/takeoff-compare.component';
import { CbAlertCardModule } from '@app/shared/components/alert-card/alert-card.module';
import { CbCallbackModule } from '@app/shared/pipe/callback/callback.module';
import { TakeoffRateVariationsDialogComponent } from './lot-costs-takeoffs/takeoff-compare/takeoff-rate-variations-dialog/takeoff-rate-variations-dialog.component';
import { TakeoffErrorsDialogComponent } from './lot-costs-takeoffs/taskoff-errors-dialog/takeoff-errors-dialog.component';
import { PricingRevisionActionBarComponent } from './lot-costs-pricing-revisions/lot-costs-pricing-revision-action-bar/pricing-revision-action-bar.component';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { PricingRevisionViewComponent } from './lot-costs-pricing-revisions/lot-costs-pricing-revision-view/pricing-revision-view.component';
import { PricingRevisionSideBarCardComponent } from './lot-costs-pricing-revisions/lot-costs-pricing-revision-side-bar-card/pricing-revision-side-bar-card.component';
import { PricingRevisionTabsComponent } from './lot-costs-pricing-revisions/lot-costs-pricing-revision-tabs/pricing-revision-tabs.component';
import { PricingRevisionsDesignSchemeTabComponent } from
    './lot-costs-pricing-revisions/lot-costs-pricing-revisions-design-scheme-tab/pricing-revisions-design-scheme-tab.component';
import { PricingRevisionSpecificationTabComponent } from './lot-costs-pricing-revisions/lot-costs-pricing-revision-specification-tab/pricing-revision-specification-tab.component';
import { CbGenericQuestionsModule } from '@app/shared/components/generic-questions/generic-questions.module';
import { PricingRevisionHistoryTabComponent } from './lot-costs-pricing-revisions/lot-costs-pricing-revision-history-tab/pricing-revision-history-tab.component';
import { LotSpecificationModule } from '../specification/specification.module';
import { LotCostsMarginsComponent } from './lot-costs-pricing-revisions/lot-costs-margins/lot-costs-margins.component';
import { CbAiRateModule } from '@app/shared/components/ai-rate/ai-rate.module';


const MATERIAL_MODULES = [
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    MatTooltipModule,
    MatMenuModule,
    MatTabsModule,
    MatTabsModule,
    ScrollingModule,
    CdkAccordionModule,
    CbGenericQuestionsModule,
    CbAiRateModule
];

const CB_MODULES = [
    CbDisplayValueModule,
    CbWaitForModule,
    CbDialogModule,
    CbFormsModule,
    CbInfoMessageModule,
    CbDasherModule,
    CbButtonModule,
    CbTabRouteModule,
    CbLoadingSpinnerModule,
    CbSortByModule,
    CbSortByImpureModule,
    CbTableModule,
    CbHubTableModule,
    CbCurrencyModule,
    CbPricingRevisionCardModule,
    CbTakeoffCardModule,
    CbDateDurationCalculatorModule,
    CbInfiniteScrollModule,
    CbElapsedTimeModule,
    CbUserDateTimeModule,
    CbDocumentsModule,
    CbInfoPopupModule,
    CbAlertCardModule,
    CbCallbackModule,
    CbDatePipeModule,
    LotSpecificationModule,
];

const MISCELLANEOUS_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
];

@NgModule({
    declarations: [
        BlockCostsComponent,
        ManagePricingRevisionDialogComponent,
        FinalisePricingRevisionDialogComponent,
        LotCostsSummarysComponent,
        LotCostsAreaComponent,
        PricingRevisionsSearchComponent,
        LotCostsConfirmedQuantitiesComponent,
        LotCostsActualComponent,
        TakeoffTasksSearchComponent,
        TakeoffTaskDialogComponent,
        TakeoffTaskComponent,
        UploadTakeoffDialogComponent,
        LotTakeoffCardComponent,
        TakeoffCompareComponent,
        TakeoffRateVariationsDialogComponent,
        TakeoffErrorsDialogComponent,
        PricingRevisionViewComponent,
        PricingRevisionActionBarComponent,
        PricingRevisionSideBarCardComponent,
        PricingRevisionTabsComponent,
        PricingRevisionsDesignSchemeTabComponent,
        PricingRevisionSpecificationTabComponent,
        PricingRevisionHistoryTabComponent,
        LotCostsMarginsComponent
    ],
    exports: [
        BlockCostsComponent,
        ManagePricingRevisionDialogComponent,
        FinalisePricingRevisionDialogComponent,
        LotCostsAreaComponent,
        TakeoffTaskComponent,
        LotTakeoffCardComponent,
        UploadTakeoffDialogComponent,
        TakeoffRateVariationsDialogComponent,
        TakeoffErrorsDialogComponent
    ],
    imports: [
        ...MATERIAL_MODULES,
        ...MISCELLANEOUS_MODULES,
        ...CB_MODULES,
    ]
})

export class CbLotCostsModule { }
