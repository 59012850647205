import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDateTimeNewComponent } from './user-data-time-new/user-date-time-new.component';
import { CbDisplayValueModule } from '../display-value/display-value.module';



@NgModule({
    declarations: [UserDateTimeNewComponent],
    imports: [
        CommonModule,
        CbDisplayValueModule,
    ],
    exports: [
        UserDateTimeNewComponent,
    ]
})
export class CbUserDateTimeModule { }
