import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IDesignSchemeMappedItem} from '@app/logic/design-schemes';
import {DocumentTypesLogicService} from '@app/logic/document-types';
import {DocumentGroupLogicService, DocumentsLogicService} from '@app/logic/documents';
import {FormMode} from '@app/shared/enums/form';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';

@Component({
    selector: 'cb-request-design-scheme-step-six',
    templateUrl: './request-schemes-step-six.component.html',
    styleUrls: ['./request-schemes-step-six.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class RequestDesignSchemesStepSixComponent {
    @Input() public mappedItem: IDesignSchemeMappedItem;
    @Input() public form: UntypedFormGroup;
    @Input() public mode: FormMode;

    constructor(
        public readonly documentsLogicService: DocumentsLogicService,
        public readonly documentGroupLogicService: DocumentGroupLogicService,
        public readonly documentTypesLogicService: DocumentTypesLogicService
    ) {
    }
}
