import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { IBasePermissions } from '@app/core/permissions/base.permissions';

export interface IResult extends Object {
    id?: number | string;
}

@Injectable()
export class SearchService<ResultType extends IResult = IResult> implements OnDestroy {
    public noMoreResults = false;
    public searchResults = {} as ISearchResult<any>;
    public searchIsLoading = false;
    public ignoreEmptyQueries = false;
    public pageSize = 10;
    public hasSearched = false;
    public doSearchInterval = 1000;
    public permissions: IBasePermissions;
    public expanded = false;
    public hasDuplicates = false;
    public totalItemCount = 0;
    public queryUpdate: Subject<Object>;
    public infiniteScrollContainer: ElementRef;
    public currentPage = 0;
    public query: string;
    public numPages: number;
    public searchResultsLoaded = false;
    public setExtraSearchParams: (...args: Array<string | number | boolean | string[] | number[] | Object>) => void;
    public searchResults$: BehaviorSubject<ResultType[]>;
    public readonly nextPage$ = new Subject();
    public isPagination = false;
    public logicService;
    public readonly subscription$ = new Subscription();
    private intervalId: NodeJS.Timeout;

    public get extraSearchParams(): Object {
        return this._extraSearchParams;
    }
    public set extraSearchParams(value: Object) {
        this._extraSearchParams = value;
    }
    private _extraSearchParams: Object;

    constructor(
    ) {
        this.searchResults$ = new BehaviorSubject(undefined);
        this.subscription$.add(
            this.nextPage$.subscribe({
                next: (data: { addPage: number }) => {
                    this.currentPage = this.currentPage + data.addPage;
                    this.handleSearch(this.query, this.currentPage)
                        .subOnce();
                }
            })
        );
    }

    @Debounce()
    public handleInputChange(): void {
        this.currentPage = 0;
        this.searchResults$.next([]);
        this.numPages = null;
        this.noMoreResults = false;
        this.doSearchIfNoScrollBar();
        this.handleSearch(this.query, 1)
            .subOnce();
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
        clearInterval(this.intervalId);
    }

    public sortSearchResults(searchResults: Array<ResultType>): Array<ResultType> { return searchResults; }

    public doSearchIfNoScrollBar(): void {
        clearInterval(this.intervalId);
        this.intervalId = setInterval(() => {
            if (!this.infiniteScrollContainer?.nativeElement) {
                clearInterval(this.intervalId);
                return;
            }
            const isVerticalScrollbar = this.infiniteScrollContainer.nativeElement.scrollHeight > this.infiniteScrollContainer.nativeElement.offsetHeight;
            if (!isVerticalScrollbar && !this.noMoreResults && !this.searchIsLoading) {
                this.nextPage$.next({ addPage: 1 });
            } else {
                clearInterval(this.intervalId);
            }
        }, 300);
    }

    public handleSearch = (startsWith: string = this.query || '', page: number): Observable<ResultType[]> => this.doSearch(startsWith, page);

    public prev = (): void => {
        this.nextPage$.next({ addPage: -1 });
    };

    public next = (): void => {
        this.nextPage$.next({ addPage: 1 });
    };

    public setSearchResults<T extends ResultType>(searchResults: ISearchResult<T>): void {
        this.hasSearched = true;
        this.numPages = Math.ceil(searchResults.total / 10);
        if (this.isPagination) {
            this.searchResults$.next(searchResults.items);
        } else {
            const currentResults = this.searchResults$.getValue();
            let hasDuplicates = false;
            if (currentResults &&
                currentResults.length > 0 &&
                searchResults.items.some((e: T) => e.id === currentResults[0].id)
            ) {
                hasDuplicates = true;
            }

            if (currentResults && this.currentPage > 1 && !hasDuplicates) {
                this.searchResults$.next(this.sortSearchResults(currentResults.concat(searchResults.items as T[])));
            } else if (currentResults && this.currentPage > 1 && hasDuplicates) {
                // It has searched one too many times, do nothing
            } else if ((searchResults.total > this.pageSize * this.currentPage) && (!currentResults || this.currentPage === 0)) {
                // they have typed, and it is the first search since they typed
                this.searchResults$.next(this.sortSearchResults(searchResults.items));
            } else if (searchResults.total > this.pageSize * this.currentPage && currentResults) {
                // they have typed, and scrolled, so it needs to concat results (have scrolled)
                this.searchResults$.next(this.sortSearchResults(currentResults.concat(searchResults.items as T[])));
            } else {
                this.searchResults$.next(this.sortSearchResults((searchResults.items)));
            }
        }
        if (!searchResults.items || searchResults.items.length < 1) {
            this.noMoreResults = true;
        }
        this.searchIsLoading = false;
        this.searchResultsLoaded = true;
    }

    public getResults(query: string, currentPage: number): Observable<ResultType[]> {
        return new Observable<ResultType[]>((subscriber) => {
            this.currentPage = currentPage;
            if (!this.searchResultsLoaded) {
                const resultsObservable = this.logicService.$getSearchList({
                    query,
                    currentPage,
                    ...(this.pageSize && { pageSize: this.pageSize }),
                    ...this.extraSearchParams
                });
                resultsObservable?.subOnce((x: ISearchResult<ResultType>) => {
                    this.hasDuplicates = false;
                    this.setSearchResults(x);
                    if (!this.hasDuplicates) {
                        subscriber.next(this.searchResults$.value);
                    }
                    subscriber.complete();
                });
            } else {
                subscriber.next(this.searchResults$.value);
                subscriber.complete();
            }
        });
    }

    public doSearch = (query: string, page: number): Observable<ResultType[]> => {
        if (!this.hasSearched || this.currentPage <= this.numPages || this.isPagination) {
            this.searchIsLoading = true;
            this.searchResultsLoaded = false;
            return this.getResults(query, page);
        }
        return this.resultsAsCompletedObservable();
    };

    public resultsAsCompletedObservable(): Observable<ResultType[]> {
        return new Observable<ResultType[]>((subscriber) => {
            subscriber.next(this.searchResults$.value);
            subscriber.complete();
        });
    }
}
