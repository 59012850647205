import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ViewReleaseBreadcrumbResolver  {

    constructor() { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const projectId = route.params.id;
        const releaseId = route.params.releaseId;
        const breadcrumb = {
            breadcrumb: 'View Release',
            breadcrumbPredecessors: [
                { label: 'Project Search', url: '/projects/search' },
                { label: 'View Project', url: `/projects/${projectId}/view` }
            ]
        };

        return { ...breadcrumb };
    }

}
