import {Component, Input} from '@angular/core';

@Component({
    selector: 'cb-td-icon[value], cb-td-icon[svgValue]',
    templateUrl: './icon.component.html',
    styleUrls: ['./../table-cell.scss']
})
export class TdIconComponent {
    @Input() public readonly value: string;
    @Input() public readonly svgValue: string;
    @Input() public readonly toolTip = '';
    @Input() public readonly class = 'cb-grey';
}
