import {Component, EventEmitter, Input, Output} from '@angular/core';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {IPreConsentPlanMappedItem} from '@app/logic/pre-consent-plans';
import {
    RequestPreConsentEditItemDialogComponent
} from '@app/views/lot/design/pre-consent/request-preconsent-dialog-wizard-steps/request-pre-consent-edit-item-dialog/request-pre-consent-edit-item-dialog.component';

@Component({
    selector: 'cb-request-pre-consent-plan-items',
    templateUrl: './request-pre-consent-plan-items.component.html',
    styleUrls: ['./request-pre-consent-plan-items.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class RequestPreConsentPlanItemsComponent {

    @Input() public mappedItem: IPreConsentPlanMappedItem;
    @Output() public mappedItemChange = new EventEmitter<IPreConsentPlanMappedItem>();

    constructor(
        public readonly cbDialog: CbDialogService
    ) {
    }

    public addPreConsentPlanItems(): void {
        let currentItemSize = 1;

        if (this.mappedItem.items) {
            currentItemSize = this.mappedItem.items.length + 1;
        }

        this.cbDialog
            .open(RequestPreConsentEditItemDialogComponent, {
                data: {mappedItem: this.mappedItem, item: {itemNumber: currentItemSize}, mode: FormMode.Add},
            }).afterClosed().subOnce(_value => {
                if (_value) {
                    this.mappedItemChange.emit(this.mappedItem);
                }
            });
    }
}
