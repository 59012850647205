<mat-card class="top-panel button-row start-center flex-row">
    <button mat-raised-button
            (click)="backToList()">
        <mat-icon>arrow_back</mat-icon>
        Back to RFIs
    </button>
    <button mat-raised-button
            *ngIf="canEditRFI()"
            color="primary"
            (click)="editCouncilRfi()"
            [disabled]="councilRfi?.isComplete">
        <mat-icon>edit</mat-icon>
        Manage RFI
    </button>
    <button *ngIf="canChangeRFIStatus()"
            mat-raised-button
            aria-label="Change RFI Status"
            color="primary"
            [matMenuTriggerFor]="menu"
            [disabled]="councilRfi?.isComplete">
        <mat-icon>arrow_drop_down</mat-icon>
        RFI Status
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                (click)="setInQueue()"
                [disabled]="councilRfi?.isComplete">
            <mat-icon mat-menu-align-target
                      color="accent">timelapse</mat-icon>
            In Queue
        </button>
        <button mat-menu-item
                (click)="setInProgress()"
                [disabled]="councilRfi?.isComplete">
            <mat-icon mat-menu-align-target
                      color="warn">check</mat-icon>
            In Progress
        </button>
        <button mat-menu-item
                (click)="setOnHold()"
                [disabled]="councilRfi?.isComplete">
            <mat-icon mat-menu-align-target
                      color="primary">pause</mat-icon>
            On Hold
        </button>
        <button mat-menu-item
                (click)="setOnComplete()"
                [disabled]="!canComplete()">
            <mat-icon mat-menu-align-target
                      color="primary">send</mat-icon>
            Complete
        </button>
    </mat-menu>
    <button mat-raised-button
            *ngIf="canEditRFI()"
            color="primary"
            (click)="assignCouncilRfi()"
            [disabled]="councilRfi?.isComplete">
        <mat-icon>person</mat-icon>
        Reassign
    </button>
    <button mat-raised-button
            color="primary"
            *ngIf="canDeleteRFI()"
            [disabled]="councilRfi?.isComplete"
            (click)="deleteCouncilRfi()">
        <mat-icon>delete</mat-icon>
        Delete
    </button>
</mat-card>
