<div class="cb-margin flex-col">
    <mat-tab-group (selectedIndexChange)="onTabChanged($event);"
                   [selectedIndex]="selectedTabIndex"
                   cbTabUrlParam="cbTabRouteId"
                   [cbTabRoute]="ALL_TEAMS_ROUTE">
        <mat-tab label="Build Programmes"
                 cbTabRouteId="build-programmes"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-team-build-programmes [resultDirections$]="resultDirections$">
                </cb-team-build-programmes>
            </ng-template>
        </mat-tab>
        <mat-tab label="Manual Orders"
                 cbTabRouteId="manual-orders"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-all-manual-orders id="team-manual-orders-tabscontent">
                </cb-all-manual-orders>
            </ng-template>
        </mat-tab>
        <mat-tab label="Incomplete Build Activities"
                 cbTabRouteId="incomplete-build-activities"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-incomplete-build-activities></cb-incomplete-build-activities>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
