<div class="cb-margin-2x flex-col">
    <p class="mat-body-1j cb-margin-bottom-3x">To request Pre-consent, a signed Scheme Plan must be provided.</p>
    <cb-table [loaded]="true">
        <thead cbTableHead>
            <tr>
                <td></td>
                <td>Document Type</td>
                <td>File Types</td>
                <td>File Name</td>
                <td></td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr id="{{documentType?.dropZoneId}}"
                *ngFor="let documentType of defaultDocumentTypes">
                <td>
                    <mat-icon matTooltip="Requirement Fufilled"
                              class="cb-help-cursor cb-primary"
                              *ngIf="documentTypeRequired[documentType?.documentTypeId] === false">
                    check_circle</mat-icon>
                    <mat-icon matTooltip="Required"
                              class="cb-help-cursor cb-error"
                              *ngIf="documentTypeRequired[documentType?.documentTypeId] === true">error
                </mat-icon>
                    <mat-icon matTooltip="Not Required"
                              class="cb-help-cursor cb-grey"
                              *ngIf="documentTypeRequired[documentType?.documentTypeId] == null">info
                </mat-icon>
                </td>
                <td>
                    <span class="mat-body-1">{{documentType?.label}}</span>
                    <dl class="cb-error">
                        <dd *ngFor="let error of documentTypeFileErrors[documentType?.indexId]">
                        {{error}}
                    </dd>
                    </dl>
                </td>
                <td>{{documentType?.fileTypesLabel}}</td>
                <td>{{getFileName(documentType) | cbDasher}}</td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <label class="mat-icon-button cb-margin-right"
                           style="margin-bottom: 3px; margin-top: 3px"
                           [ngClass]="{ 'cb-disabled ': disableUpload(documentType) }">
                        <input #fileInput
                               style="display: none;"
                               type="file"
                               [disabled]="disableUpload(documentType)"
                               [attr.id]="documentType.fileInputId"
                               accept="{{documentType.accepts}}"
                               (change)="fileUploaded(documentType.indexId, $event)"/>
                        <button mat-icon-button
                                [disabled]="disableUpload(documentType)"
                                (click)="triggerFileInput(fileInput)"
                                [ngClass]="{ 'cb-disabled ': disableUpload(documentType) }">
                            <mat-icon>file_upload</mat-icon>
                        </button>
                    </label>
                    <button mat-icon-button
                            [disabled]="!files[documentType?.indexId]"
                            (cbClick)="removeFile(documentType)">
                        <mat-icon>clear</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</div>
