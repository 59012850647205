import {BUILDING_CONSENT_STATUS_ENUM, ILotDto, RESOURCE_CONSENT_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {IBuildingConsentDto, IResourceConsentDto} from '@app/logic/lot-consents';

import {ILotConsentDto} from '@app/logic/lot-consents/interfaces/i.lot-consent.dto';
import {LotConsentLogicService} from '@app/logic/lot-consents/lot-consent-logic.service';
import {LotConsentViewService} from '../service/lot-consent-view.service';

@Component({
    selector: 'cb-lot-consents-list',
    templateUrl: './lot-consents-list.component.html',
    styleUrls: ['./lot-consents-list.component.scss'],

    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotConsentsListComponent {

    private _lot: ILotDto;
    @Input() public set lotDto(v: ILotDto) {
        this._lot = v;
        if (v?.id) {
            this.lotConsentLogic.getByLot(v.id).subOnce((lotConsents) => {
                this.lotConsents = lotConsents;
                this.handleConsentListUpdate();
                this.cdRef.detectChanges();
            });
        }
    }
    public get lotDto(): ILotDto {
        return this._lot;
    }

    public lotConsents: ILotConsentDto[];
    public hasConsents = false;

    constructor(
        public readonly lotConsentLogic: LotConsentLogicService,
        public readonly lotConsentViewService: LotConsentViewService,
        public cdRef: ChangeDetectorRef
    ) { }

    public addConsent(newConsent: IBuildingConsentDto | IResourceConsentDto): void {
        this.lotConsents.unshift(newConsent as any);
        this.handleConsentListUpdate();
        if (!this.lotDto.canCreateRFI) {
            this.lotDto.canCreateRFI =
                newConsent.consentTypeLabel?.toLowerCase().includes('building consent') ?
                    (newConsent.consentStatus === BUILDING_CONSENT_STATUS_ENUM.OnHold
                        || newConsent.consentStatus === BUILDING_CONSENT_STATUS_ENUM.Pending
                        || newConsent.consentStatus === BUILDING_CONSENT_STATUS_ENUM.InQueue
                        || newConsent.consentStatus === BUILDING_CONSENT_STATUS_ENUM.InProgress
                        || newConsent.consentStatus === BUILDING_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation
                        || newConsent.consentStatus === BUILDING_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed)
                    : (newConsent.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.OnHold
                        || newConsent.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.Pending
                        || newConsent.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.InQueue
                        || newConsent.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.InProgress
                        || newConsent.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation
                        || newConsent.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed);
        }
    }

    private handleConsentListUpdate(): void {
        this.lotConsentViewService.hasPrimaryBuildingConsent = this.lotConsents.findIndex(x =>
            x.isPrimary &&
            !x.isDeleted &&
            x.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.Declined) > -1;
        this.hasConsents = this.lotConsents?.length > 0;
    }
}
