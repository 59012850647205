<cb-dialog class="flex-col"
           dialogHeading="Release Lots to Sales">
    <form class="flex-col flex"
          #form="ngForm">
        <div class="end-center flex-row">
            <cb-select label="Set Market Status To"
                       name="selectedMarketStatus"
                       [options]="marketStatuses"
                       [(ngModel)]="selectedMarketStatus"
                       [required]="true"
                       (change)="onStatusChange()">
            </cb-select>
        </div>
        <cb-table class="cb-margin-top cb-margin-bottom-3x"
                  [loaded]="loaded">
            <thead cbTableHead>
                <tr>
                    <td>Select</td>
                    <td>LOT</td>
                    <td>DP No.</td>
                    <td>Address</td>
                    <td>Build Type</td>
                    <td>Lot Market Status</td>
                    <td>Job Status</td>
                </tr>
            </thead>
            <tbody cbTableBody>
                <tr cbTableRow
                    *ngFor="let lot of lots | cbSortByPipe: 'lotNumber': 'asc'; let $index = index">
                    <td>
                        <mat-checkbox [checked]="selectedLots[lot.id]"
                                      (change)="clicked(lot.id)"
                                      [disabled]="!canCheck(lot)"
                                      *ngIf="canCheck(lot)">
                        </mat-checkbox>
                        <mat-icon *ngIf="!canCheck(lot)"
                                  style="display: table-header-group;">block</mat-icon>
                    </td>
                    <cb-td-text [value]="lot.lotNumber"></cb-td-text>
                    <cb-td-text [value]="lot.dpNumber"></cb-td-text>
                    <td>
                        {{lot.lotAddress.street}}
                        <br/>
                        {{lot.lotAddress.suburb}}
                    </td>
                    <td>
                        {{lot.buildType.label}}
                    </td>
                    <td style="white-space: nowrap;">
                        <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.Available">
                            <mat-icon class="cb-grey">thumb_up</mat-icon>
                            Available
                        </ng-container>
                        <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.NotAvailable">
                            <mat-icon class="cb-grey">lock_outline</mat-icon>
                            Not Available
                        </ng-container>
                        <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.PreRelease">
                            <mat-icon class="cb-grey">flag</mat-icon>
                            PreRelease
                        </ng-container>
                        <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.Sold">
                            <mat-icon class="cb-grey">monetization_on</mat-icon>
                            Sold
                        </ng-container>
                        <ng-container *ngIf="lot.marketStatus === MARKET_STATUS_ENUM.SaleLost">
                            <mat-icon class="cb-grey">thumbs_down</mat-icon>
                            Sale Lost
                        </ng-container>
                    </td>
                    <cb-td-enum [value]="lot.jobStatus"
                                [enum]="LOT_JOB_STATUS_ENUM">
                    </cb-td-enum>
                </tr>
                <tr *ngIf="lots?.length < 1 && loaded">
                    <td colspan="17">
                        <mat-icon class="cb-grey">info</mat-icon>
                        &nbsp;No Lots have been added to this Release Stage
                    </td>
                </tr>
            </tbody>
        </cb-table>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="!canSave() || form.invalid || form.pristine"
                    (cbClick)="save()"
                    [clickLimit]="1">Save</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
