import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AccountsReceivablePermissionEnum} from '@app/shared/enums/permissions';
import {CurrentUserService} from '../authentication/current.user';
import {BasePermissions} from './base.permissions';


export interface IAccountsReceivablePermissions {
    canView(): boolean;
    canEditPaymentSchedule(): boolean;
}

@Injectable()
export class AccountsReceivablePermissions
    extends BasePermissions<AccountsReceivablePermissionEnum>
    implements IAccountsReceivablePermissions {
    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('accountsreceivable', AccountsReceivablePermissionEnum, currentUser, http, router);
    }
    public canView(): boolean {
        return this.permissionObject(AccountsReceivablePermissionEnum.CanView);
    }
    public canEditPaymentSchedule(): boolean {
        return this.permissionObject(AccountsReceivablePermissionEnum.CanEditPaymentSchedule);
    }
}
