import { BaseMappedItem } from '../base/base-mapped-item';
import { IPricingComplexityLogicService } from './interfaces/i.pricing-complexity.logic.service';
import { IPricingComplexityMappedItem } from './interfaces/i.pricing-complexity.mapped';
import { IPricingComplexityDto } from './interfaces/i.pricing-complexity.dto';
import { DtoProp } from '../base/dto-prop.decorator';

export class PricingComplexityMappedItem
    extends BaseMappedItem<IPricingComplexityDto, IPricingComplexityMappedItem, IPricingComplexityLogicService>
    implements IPricingComplexityMappedItem {
    @DtoProp public id: number;
    @DtoProp public complexity: string;
    @DtoProp public targetWorkHours: number;
    @DtoProp public targetCompletionHours: number;
    @DtoProp public isActive: boolean;

    constructor(
        sourceData: IPricingComplexityDto,
        logicService: IPricingComplexityLogicService
    ) {
        super(
            sourceData,
            logicService,
            PricingComplexityMappedItem
        );
    }
}
