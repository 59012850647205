<cb-dialog *cbWaitFor="data"
           dialogHeading="{{data.formMode === FormMode.Edit ? 'Edit' : 'Add' }} Item">
    <form class="flex-col flex"
          #itemForm="ngForm">
        <cb-input type="number"
                  name="itemId"
                  label="Item#"
                  [required]="true"
                  [(ngModel)]="tempItem.itemId"></cb-input>
        <cb-text-area name="description"
                      label="Item Description"
                      [required]="true"
                      maxlength="1000"
                      [(ngModel)]="tempItem.description">
        </cb-text-area>
        <div *ngIf="data.formMode === FormMode.Edit">
            <cb-checkbox name="designSchemeLineStatus"
                         label="Completed by Designer"
                         [ngModel]="lineStatus === DESIGN_SCHEME_LINE_STATUS_ENUM.Completed"
                         [indeterminate]="lineStatus === DESIGN_SCHEME_LINE_STATUS_ENUM.NotDoing"
                         (ngModelChange)="checkStatus(lineStatus)">
            </cb-checkbox>
            <cb-text-area name="comments"
                          label="Comments"
                          [required]="false"
                          maxlength="1000"
                          [(ngModel)]="tempItem.comments">
            </cb-text-area>
        </div>
    </form>
    <footer class="end-center">
        <button *ngIf="!this.data.addAndSave"
                mat-raised-button
                [disabled]="!itemForm.dirty || !itemForm.valid"
                color="primary"
                (click)="addToList()">
            Add
        </button>
        <button *ngIf="this.data.addAndSave"
                mat-raised-button
                [disabled]="!itemForm.dirty || !itemForm.valid"
                color="primary"
                (click)="saveItem()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
