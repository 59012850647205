import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { IPermissionMappedItem } from './interfaces/i.permission.mapped';
import { BaseLogicService } from '../base/base-logic.service';
import { PermissionMappedItem } from './permission.mapped';
import { IPermissionDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPermissionLogicService } from './interfaces/i.permission.logic-service';

@Injectable()
export class PermissionLogicService
    extends BaseLogicService<IPermissionDto, IPermissionMappedItem>
    implements IPermissionLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('auth/users/permission', PermissionMappedItem);
    }
}
