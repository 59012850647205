import {Injectable, Injector} from '@angular/core';
import {HttpWrapperService} from '@app/core/services/http-wrapper/http-wrapper.service';
import {BaseLogicService} from '@app/logic/base/base-logic.service';
import {ILotBuildOverheadCostMappedItem} from '@app/logic/lot-build-overhead-costs/interfaces/i.lot-build-overhead-cost-mapped';
import {ILotBuildOverheadCostsDto} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/LotBuildOverheadCostsDto';
import {ILotBuildOverheadCostLogicService} from '@app/logic/lot-build-overhead-costs/interfaces/i.lot-build-overhead-cost-logic.service';
import {LotBuildOverheadCostMappedItem} from '@app/logic/lot-build-overhead-costs/lot-build-overhead-cost.mapped';
import {Observable} from 'rxjs';

@Injectable()
export class LotBuildOverheadCostLogicService extends BaseLogicService<ILotBuildOverheadCostsDto, ILotBuildOverheadCostMappedItem> implements ILotBuildOverheadCostLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'lotbuildoverheadcosts',
            LotBuildOverheadCostMappedItem,
        );
    }

    public $getList(lotId: number): Observable<Array<ILotBuildOverheadCostsDto>> {
        return this.$http
            .get<Array<ILotBuildOverheadCostsDto>>(`${this.$baseUri}/${lotId}`);
    }
}
