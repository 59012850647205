import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { INonWorkingDayLogicService } from './interfaces/i.non-working-day.logic.service';
import { INonWorkingDayDto } from './interfaces/i.non-working-day.dto';
import { INonWorkingDayMappedItem } from './interfaces/i.non-working-day.mapped';
import { DateUtil } from '@app/shared/utils/date.util';

export class NonWorkingDayMappedItem
    extends BaseMappedItem<INonWorkingDayDto, INonWorkingDayMappedItem, INonWorkingDayLogicService>
    implements INonWorkingDayMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public date: string;
    @DtoProp public dateObject: Date;
    @DtoProp public label: string;
    @DtoProp public regionIds: number[];
    @DtoProp public isSelected: boolean;

    constructor(
        sourceData: INonWorkingDayDto,
        logicService: INonWorkingDayLogicService
    ) {
        super(
            sourceData,
            logicService,
            NonWorkingDayMappedItem
        );
    }

    protected $preSave(toSave: INonWorkingDayDto): void {
        toSave.date = toSave.date ? DateUtil.formatDateWithoutTimeZone(toSave.date) : undefined;
    }
}
