<div class="cb-margin flex-row flex">
    <div class="cb-margin flex-col flex"
         [cbInfiniteScroll]="leftContainer">
        <span class="mat-body-2 cb-margin-bottom">
            Assigned Design Changes
        </span>
        <div #leftContainer
             class="scroll-container flex-col"
             dragula="ITEMS"
             [(dragulaModel)]="assignedChangeRecords">
            <cb-info-message class="cb-margin dragula-ignore "
                             *ngIf="!assignedChangeRecords || assignedChangeRecords.length <  1 "
                             [message]="'There are no assigned change records'"
                             [icon]="'info_circle'"></cb-info-message>
            <mat-card class="cb-margin flex-col"
                      *ngFor="let changeRecord of assignedChangeRecords">
                <mat-checkbox style="padding: 0"
                              [indeterminate]="changeRecord.designSchemeLineStatus === DESIGN_SCHEME_LINE_STATUS_ENUM.NotDoing"
                              [checked]="changeRecord.designSchemeLineStatus === DESIGN_SCHEME_LINE_STATUS_ENUM.Completed"
                              (click)="$event.stopPropagation()"
                              [disabled]="true">Change Number: {{changeRecord.changeNumber }}
                </mat-checkbox>
                <br/>
                <span>Change Details: {{ changeRecord.changeRecordDetails }}</span>
            </mat-card>
        </div>
    </div>
    <mat-divider class="cb-margin"
                 [vertical]="true"></mat-divider>
    <div [cbInfiniteScroll]="rightContainer"
         class="cb-margin flex-col flex">
        <span class="mat-body-2 cb-margin-bottom">
            Unassigned Design Changes
        </span>
        <div #rightContainer
             class="scroll-container flex-col"
             dragula="ITEMS"
             [(dragulaModel)]="unassignedChangeRecords">
            <cb-info-message class="cb-margin dragula-ignore"
                             *ngIf=" !unassignedChangeRecords || unassignedChangeRecords.length <  1 "
                             [message]="'There are no unassigned change records'"
                             [icon]="'info_circle'"></cb-info-message>
            <mat-card class="cb-margin flex-col"
                      *ngFor="let changeRecord of unassignedChangeRecords">
                <span>Change Number: {{ changeRecord.changeNumber }}</span>
                <br/>
                <span>Change Details: {{ changeRecord.changeRecordDetails }}</span>
            </mat-card>
        </div>
    </div>
</div>
