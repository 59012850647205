import {EventEmitter, Injectable} from '@angular/core';
import {LotPermissions} from '@app/core/permissions';
import {ILotSpecItemMappedItem} from '@app/logic/lot-spec-item';
import {ILotSpecMappedItem} from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {ILotSpecItemDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {EditLotSpecItemDialogComponent} from '../edit-lot-spec-item-dialog/edit-lot-spec-item-dialog.component';
import {LotSpecPurchaseOrdersDialogComponent} from '../lot-spec-purchase-orders-dialog/lot-spec-purchase-orders-dialog.component';
import {LotSpecQuoteRefsDialogComponent} from '../lot-spec-quote-refs-dialog/lot-spec-quote-refs-dialog.component';

export interface IHaveLotSpecItemUiActions {
    readonly uiActions: LotSpecItemUIActions;
    readonly lotSpec: ILotSpecMappedItem;
    readonly mappedItem: ILotSpecItemMappedItem;
    readonly itemDeleted: EventEmitter<ILotSpecItemDto>;
    readonly itemEdited: EventEmitter<ILotSpecItemDto>;
}

@Injectable()
export class LotSpecItemUIActions {

    public ctrl: IHaveLotSpecItemUiActions;

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly lotPermissions: LotPermissions,
    ) { }

    public setController(ctrl: IHaveLotSpecItemUiActions): void {
        this.ctrl = ctrl;
    }

    public hideQrefsButton(): boolean {
        return !this.ctrl.mappedItem.quoteRequired && !this.ctrl.mappedItem.isManualQuote;
    }

    public quoteRefsDialog(): void {
        this.cbDialog.open(
            LotSpecQuoteRefsDialogComponent,
            {
                minWidth: '75%',
                data: {
                    lotSpecItem: this.ctrl.mappedItem,
                }
            }
        );
    }

    public showPurchaseOrders() {
        this.cbDialog.open(
            LotSpecPurchaseOrdersDialogComponent,
            {
                minWidth: '75%',
                data: {
                    lotSpecItem: this.ctrl.mappedItem,
                }
            }
        );
    }

    public splitProduct(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Split Item',
            message: 'Are you sure you want to split this item?',
            confirmed: () => {
                const block = this.cbDialog.block('Splitting Item...');
                this.ctrl.mappedItem.split().subOnce((result) => {
                    if (result) {
                        block.close();
                    }
                });
            }
        });
    }

    public showSplitSpecItem(): boolean {
        return this.ctrl.mappedItem.isActualCost || this.ctrl.mappedItem.isNoChargeCost || this.ctrl.mappedItem.isOwnersCareCost;
    }

    public editLotSpecItem(): void {
        const originalSpecGroupId = this.ctrl.mappedItem.specGroupId;

        const dialogRef = this.cbDialog.open(
            EditLotSpecItemDialogComponent,
            {
                minWidth: '40%',
                data: {
                    locked: this.ctrl.lotSpec.isLocked,
                    lotSpec: this.ctrl.lotSpec,
                    mappedItem: this.ctrl.mappedItem.$clone(),
                    removeOwnersCareCostType: false,
                }
            }
        );

        dialogRef.afterClosed().subOnce(result => {
            if (result && (originalSpecGroupId !== result.specGroupId)) {
                this.ctrl.itemEdited.emit(result);
            }
        });
    }

    public canChangeSpecification() {
        return this.lotPermissions.canChangeSpecification();
    }

    public removeLotSpecItem(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Item from the Lot Specification',
            message: 'Are you sure you want to delete this item for the Lot Specification?',
            confirmed: () => {
                const block = this.cbDialog.block('Removing Item...');
                this.ctrl.mappedItem.delete().subOnce((result) => {
                    if (result) {
                        this.ctrl.itemDeleted.emit(result);
                        block.close();
                    }
                });
            }
        });
    }

}
