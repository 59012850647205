import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { IWorkingDrawingMappedItem, WorkingDrawingsLogicService } from '@app/logic/working-drawings';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-working-drawings',
    templateUrl: './working-drawings.component.html',
    styleUrls: ['./working-drawings.component.scss']
})
export class WorkingDrawingsComponent implements OnInit {

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public selectedItem: IWorkingDrawingMappedItem;

    @Output() public lotMappedItemChange = new EventEmitter();

    constructor(
        public readonly lotLogicService: LotsLogicService,
        private readonly navigationService: NavigationService,
        private readonly workingDrawingLogic: WorkingDrawingsLogicService
    ) { }

    public ngOnInit(): void {
        this.loadQueryParams();
    }

    private loadQueryParams(): void {
        const queryParams = this.navigationService.getQueryParams<{ paramEntityId?: string }>();
        if (queryParams?.paramEntityId) {
            this.loadWorkingDrawing(Number(queryParams.paramEntityId));
        }
    }

    private loadWorkingDrawing(workingDrawingId: number): void {
        this.workingDrawingLogic
            .$getMappedItem(workingDrawingId)
            .subOnce(this.workingDrawingSelected);
    }

    public readonly workingDrawingSelected = (event: IWorkingDrawingMappedItem): void => {
        this.selectedItem = event;
    };

    public handleGoBack(): void {
        this.selectedItem = null;
    }

    public lotMappedItemChanged(): void {
        this.lotMappedItemChange.emit();
    }
}
