import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {IClientSaleLogicService} from '@app/logic/client-sale/interfaces/i.client-sale-logic.service';
import {ToastService} from '@app/core/services/toast/toast.service';
import {IIdAndLabelDto, LOT_CONTRACT_TYPE_ENUM, LotContractTypeEnumId} from '@classictechsolutions/hubapi-transpiled-enums';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {IClientSaleMappedItem} from '@app/logic/client-sale/interfaces/i.client-sale.mapped';
import {IClientSaleDto} from '@app/logic/client-sale';

interface IData {
    clientSale: IClientSaleMappedItem;
}

@Component({
    selector: 'cb-change-contract-type-dialog',
    templateUrl: './change-contract-type-dialog.component.html',
    styleUrls: ['./change-contract-type-dialog.component.scss'],
})
export class ChangeContractTypeDialogComponent extends BaseDialogFormViewDirective<IClientSaleDto, IClientSaleMappedItem, IClientSaleLogicService> implements OnInit {

    public static readonly MIN_WIDTH = '400px';
    private readonly contractTypesDesignAndBuild = [
        { id: LOT_CONTRACT_TYPE_ENUM.DesignAndBuild, label: 'Design and Build' },
        { id: LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand, label: '3rd Party House and Land (Cannot change back to Design and Build' }
    ];

    private readonly contractTypesHouseAndLand = [
        { id: LOT_CONTRACT_TYPE_ENUM.HouseAndLand, label: 'House and Land' },
        { id: LOT_CONTRACT_TYPE_ENUM.LandOnly, label: 'Land Only' }
    ];

    public contractTypes: IIdAndLabelDto[];
    public selectedContractType: LotContractTypeEnumId;

    constructor(
        public readonly dialogRef: MatDialogRef<ChangeContractTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        switch (this.data.clientSale.lot.lotContractTypeId) {
            case LOT_CONTRACT_TYPE_ENUM.DesignAndBuild:
                this.contractTypes = this.contractTypesDesignAndBuild;
                this.selectedContractType = LOT_CONTRACT_TYPE_ENUM.DesignAndBuild;
                break;
            case LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand:
                this.selectedContractType = LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand;
                break;
            case LOT_CONTRACT_TYPE_ENUM.HouseAndLand:
                this.contractTypes = this.contractTypesHouseAndLand;
                this.selectedContractType = LOT_CONTRACT_TYPE_ENUM.HouseAndLand;
                break;
            case LOT_CONTRACT_TYPE_ENUM.LandOnly:
                this.contractTypes = this.contractTypesHouseAndLand;
                this.selectedContractType = LOT_CONTRACT_TYPE_ENUM.LandOnly;
                break;
            default:
                break;
        }
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public save(): void {
        this.cbDialog.confirm({
            confirmed: () => this.data.clientSale
                .changeContractType(this.selectedContractType)
                .subOnce({
                    next: this.handleNext,
                    error: this.handleError
                })
        });
    }
}
