import { Component } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SignalrService } from '@app/core/services/signalr/signalr.service';
import {CurrentHttpRequestsService} from '@app/core/services/current-http-requests/current-http-requests.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import {AuthService} from '@app/core/authentication/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '@src/environments/environment';
import {Subscription} from "rxjs";

@Component({
    selector: 'app-root',
    styleUrls: ['./app-root.component.scss'],
    templateUrl: './app-root.component.html',
})
export class AppRootComponent {

    public sidenav: MatSidenav;
    public isIframe = false;

    public isLogoutActioned = false;

    public sub: Subscription;

    constructor(
        private readonly signalrService: SignalrService,
        public readonly currentHttpRequestsService: CurrentHttpRequestsService,
        public readonly navigationService: NavigationService,
        public readonly authService: AuthService,
        private route: ActivatedRoute,
        public readonly router: Router
    ) {
        this.route.queryParams.subscribe(params => {
            this.isLogoutActioned = params['isLogoutActioned'];
            if(this.isLogoutActioned){
                this.navigationService.clearQueryParams();
                setTimeout(()=>{
                    window.sessionStorage.clear();
                    this.authService.clearTokens();
                    this.isLogoutActioned = false;
                    window.close();
                    window.open(environment.uri);
                }, 300);
            }
        });
    }

    public ngOnInit(): void {
        this.authService.isApiAuthed?.subscribe(() =>{
            this.signalrService.initiateSignalRConnection();
        });
        this.isIframe = window !== window.parent && !window.opener;
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
