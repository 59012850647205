import { GstService } from '@app/core/services/gst/gst.service';
import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'cbCurrency',
})
export class CbCurrencyPipe implements PipeTransform {

    constructor(
        @Inject(LOCALE_ID) private readonly _locale: string,
        private readonly gstService: GstService
    ) { }

    public transform(value: any, gstMode?: 'addGst' | 'removeGst', gstRate?: number, shouldDisplayDashForZero = false, ...args: any[]): string {
        if (gstMode === 'addGst') {
            value = this.gstService.toGstInclusive(value, gstRate);
        } else if (gstMode === 'removeGst') {
            value = this.gstService.toGstExclusive(value, gstRate);
        }

        if (!value && shouldDisplayDashForZero) {
            return '-';
        }
        return new CurrencyPipe(this._locale).transform(value, ...args);
    }

}
