export enum MESSAGE_NAMES {
    PoConfirmed = 'POConfirmed',
    POCancelled = 'POCancelled',
    PORedrafted = 'PORedrafted',
    POCanBePaid = 'POCanBePaid',
    POVersionUpdate = 'POVersionUpdate',
    InvoiceReceived = 'InvoiceReceived',
    InvoiceMatched = 'InvoiceMatched',
    InvoiceUnmatched = 'InvoiceUnmatched',
    InvoiceHasPayments = 'InvoiceHasPayments',
    InvoiceDeleted = 'InvoiceDeleted',
    SupplierCreditReceived = 'SupplierCreditReceived',
    SupplierCreditDeleted = 'SupplierCreditDeleted',
    BusinessAccountPaymentHold = 'BusinessAccountPaymentHold',
    BusinessAccountPaymentHoldRelease = 'BusinessAcccountPaymentHoldRelease',
    Ping = 'Ping',
    Pong = 'Pong',
}
