import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IClientAccountMappedItem } from '@app/logic/client-account/interfaces/i.client-account.mapped';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IClientAccountLogicService } from '@app/logic/client-account/interfaces/i.client-account.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { IContactDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IClientAccountDto } from '@app/logic/client-account/interfaces/i.client-account.dto';

@Component({
    selector: 'cb-manage-client-account-dialog',
    templateUrl: './manage-client-account-dialog.component.html',
    styleUrls: ['./manage-client-account-dialog.component.scss']
})

export class ManageClientAccountDialogComponent extends BaseDialogFormViewDirective<IClientAccountDto, IClientAccountMappedItem, IClientAccountLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '90%';

    public addAccountForm: NgForm;
    public mappedItem: IClientAccountMappedItem;
    public contacts: IContactDto[] = [];

    constructor(
        private readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<ManageClientAccountDialogComponent>,
        protected readonly clientAccountLogic: ClientAccountLogicService,
        private readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IClientAccountMappedItem>,
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = data.mappedItem;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        if (this.formMode === FormMode.Add) {
            this.mappedItem = this.clientAccountLogic.$createMappedItem();
            this.mappedItem.contactIds = [];
        }
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
