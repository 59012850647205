<div class="flex-row flex">
    <div class="flex-col flex">
        <h3 class="mat-title"
            *ngIf="label"
            id="label">{{label}}</h3>
        <div class="cb-table">
            <mat-table #table
                       [dataSource]="value">
                <ng-container *ngFor="let column of columns; let index = index;"
                              matColumnDef="{{column.name || 'actions'}}">
                    <mat-header-cell *matHeaderCellDef
                                     [ngStyle]="{'text-align': column.type === 'currency' ? 'right' : 'auto'}">
                        {{column.name}}
                    </mat-header-cell>
                    <div *ngIf="column.type === 'text'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            {{getTextFieldText(element, column) | cbDasher}}
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'date'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            {{getTextFieldText(element, column) | date: 'dd MMM yyyy' | cbDasher}}
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'percent'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            {{getTextFieldTextPercent(element, column) | cbDasher}}
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'bread-crumbs'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            <ul class="simple-breadcrumbs"
                                *ngIf="element[column.valueProp] && element[column.valueProp].length > 0; else elseBlock1">
                                <li *ngFor="let item of element[column.valueProp]; let index = index;">
                                    {{getListItemLabel(element[column.valueProp][index], column) | cbDasher}}
                                </li>
                            </ul>
                            <ng-template #elseBlock1>-</ng-template>
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'currency'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;"
                                  style="text-align: right;">
                            {{getTextFieldText(element, column) | cbCurrency}}
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'enum'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            {{getEnumFieldText(element, column) | cbDasher}}
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'dictionary'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            {{getDictionaryFieldText(element, column) | cbDasher}}
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'array'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            {{getArrayText(element, column) | cbDasher}}
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'list'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            <ul class="list">
                                <div *ngIf="element[column.valueProp] && element[column.valueProp].length > 0; else elseBlock2">
                                    <li *ngFor="let item of element[column.valueProp]; let index = index;">
                                        {{getListItemLabel(element[column.valueProp][index], column) | cbDasher}}
                                    </li>
                                </div>
                                <ng-template #elseBlock2>-</ng-template>
                            </ul>
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'checkbox'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            <mat-icon class="cb-grey"
                                      *ngIf="element[column.valueProp] === true">
                                check_box
                            </mat-icon>
                            <mat-icon class="cb-grey"
                                      *ngIf="element[column.valueProp] === false">
                                check_box_outline_blank
                            </mat-icon>
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'icon'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;"
                                  style="width:30px">
                            <mat-icon *ngIf="element[column.valueProp] === true">
                                {{column.icons.true}}
                            </mat-icon>
                            <mat-icon *ngIf="element[column.valueProp] === false">
                                {{column.icons.false}}
                            </mat-icon>
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'svg-icon'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;"
                                  style="width:30px">
                            <mat-icon title="{{column.trueTitle}}"
                                      *ngIf="element[column.valueProp] === true"
                                      svgIcon="{{column.icons.true}}"></mat-icon>
                            <mat-icon title="{{column.falseTitle}}"
                                      *ngIf="element[column.valueProp] === false"
                                      svgIcon="{{column.icons.false}}"></mat-icon>
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'multiple-icons'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;"
                                  style="width:30px">
                            <div class="flex-row">
                                <div *ngFor="let icon of column.multipleIcons"
                                     title="{{icon.title}}">
                                    <mat-icon *ngIf="element[icon.prop] && icon.isSvg"
                                              svgIcon="{{icon.icon}}"
                                              class="{{icon.class}}"></mat-icon>
                                    <mat-icon *ngIf="element[icon.prop] && !icon.isSvg"
                                              class="{{icon.class}}">{{icon.icon}}</mat-icon>
                                </div>
                            </div>
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'info-popup'">
                        <mat-cell [ngStyle]="{'width': column.width ? column.width : 'auto'}"
                                  *matCellDef="let element;">
                            <cb-info-popup [label]="element[column.valueProp]"
                                           [tooltip]="element[column.valueProp2]">
                            </cb-info-popup>
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!shouldDisplayNoItemsMessage(column.name)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="shouldDisplayNoItemsMessage(column.name)">
                                    <cb-info-message *ngIf="value && value.length === 0 && !isLoading"
                                                     [message]="getNoItemsMessage()"
                                                     [icon]="'info_circle'">
                                    </cb-info-message>
                                    <cb-loading-spinner *ngIf="isLoading"></cb-loading-spinner>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                    <div *ngIf="column.type === 'actions' || displayFooterAction(column)">
                        <mat-cell *matCellDef="let element;">
                            <div *ngIf="!isEmpty(element)">
                                <button *ngFor="let action of column.actions"
                                        mat-icon-button
                                        class="cb-margin-right"
                                        [disabled]="action.disabledFunction ? actionDisabled(element, value, action) : action.disabled"
                                        (click)="actionClicked(element, value, action)">
                                    <mat-icon>{{action.icon}}</mat-icon>
                                </button>
                            </div>

                            <!-- this is the "extra row" -->
                            <div *ngIf="isEmpty(element)">
                                <button mat-icon-button
                                        (click)="extraRowFunction()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </mat-cell>
                        <div *ngIf="displayFooterCell(column)">
                            <mat-footer-cell class="mat-footer-cell"
                                             *matFooterCellDef
                                             [ngStyle]="{'width': column.width ? column.width : 'auto'}">
                                <span *ngIf="!displayFooterAction(column)"
                                      [ngClass]="footerBold ? 'mat-body-2' : 'mat-body-1'">
                                    {{getFooterValue(column)}}
                                </span>
                                <div *ngIf="displayFooterAction(column)"
                                     style="position: absolute;right: 30px;margin-top: -20px;">
                                    <button mat-icon-button
                                            (click)="footerActionClicked()">
                                        <mat-icon>{{getFooterActionIcon()}}</mat-icon>
                                    </button>
                                </div>
                            </mat-footer-cell>
                        </div>
                    </div>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
                                [ngStyle]="{'display': hideHeader ? 'none' : 'table-row'}"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                <ng-container *ngIf="footerColumns">
                    <mat-footer-row style="border-top: 1px solid lightgrey; width: 100%; display: table-row"
                                    *matFooterRowDef="displayedFooterColumns; sticky: true"
                                    class="mat-row">
                    </mat-footer-row>
                </ng-container>
            </mat-table>
            <div class="bottom-div"
                 *ngIf="displayLoadingSpinnerDivOrInfoMessageDiv()">
                <cb-info-message *ngIf="displayInfoMessageDiv()"
                                 [message]="getNoItemsMessage()"
                                 [icon]="'info_circle'">
                </cb-info-message>
                <cb-loading-spinner *ngIf="displayLoadingSpinnerDiv()"></cb-loading-spinner>
            </div>
        </div>
    </div>
</div>
