<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="pinnedPanels[silentDataKey]"
                     #purchaserExpansionPanel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">people</mat-icon>
            <b>Purchaser</b>
        </mat-panel-title>
        <mat-panel-description class="end-center flex-row">
            <button *ngIf="purchaserExpansionPanel.expanded || silentData[silentDataKey]"
                    mat-icon-button
                    (click)="togglePanelPinned($event, silentDataKey)">
                <mat-icon svgIcon="pin"
                          [ngClass]="{'cb-accent' : silentData[silentDataKey]}">
                </mat-icon>
            </button>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex-col">
        <cb-display-value-new class="flex-50"
                              *ngIf="clientAccountLegalNameField.isVisible"
                              label="Account"
                              [showBtn]="true"
                              [btnHref]="clientRoute"
                              value="{{lotMappedItem?.clientAccount?.legalName | cbDasher}}">
        </cb-display-value-new>
        <div class="flex-wrap flex">
            <ng-container *ngIf="contactsAssignedToAccountField.isVisible">
                <cb-display-value-new class="flex-50"
                                      *ngFor="let clientAccount of lotMappedItem.clientAccountContactIds"
                                      label="Contact Assigned">
                    {{clientAccount?.label | cbDasher}}
                </cb-display-value-new>
            </ng-container>
            <cb-display-value-new class="flex-50"
                                  *ngIf="contractTypeField.isVisible"
                                  label="Contract Type">
                {{lotMappedItem?.contractType | cbEnumToLabel:LOT_CONTRACT_TYPE_ENUM | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="contractStatusField.isVisible"
                                  label="Contract Status">
                {{lotMappedItem?.contractStatus | cbEnumToLabel:CONTRACT_STATUS_ENUM | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="jobNumberField.isVisible"
                                  label="Client Sale Number"
                                  [showBtn]="true"
                                  [btnHref]="clientSaleRoute"
                                  value="{{lotMappedItem?.clientSale?.saleNumber | cbDasher}}">
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="vendorNameThirdPartyField.isVisible"
                                  label="Vendor">
                {{lotMappedItem?.thirdPartyLotVendor | cbDasher}}
            </cb-display-value-new>
        </div>
    </div>
</mat-expansion-panel>
