<div class="pricing-revision-container flex">
    <div class="cb-margin flex-col flex"
         *cbWaitFor="mappedItem$$ | async">
        <cb-pricing-revision-action-bar [(mappedItem)]="mappedItem"
                                        [previousMappedItem]="previousMappedItem"
                                        [lotMappedItem]="lotMappedItem"
                                        (goBack)="goBack.emit()"
                                        (mappedItemChange)="reloadPricingRevisionMappedItemOnParent()"></cb-pricing-revision-action-bar>
        <div class="card-container flex-row flex">
            <div class="flex-col flex-40">
                <cb-pricing-revision-side-bar-card class="cb-margin-top flex flex-row"
                                                   [(mappedItem)]="mappedItem"></cb-pricing-revision-side-bar-card>
            </div>
            <div class="flex-col flex">
                <cb-pricing-revision-tabs [lotMappedItem]="lotMappedItem"
                                          [(mappedItem)]="mappedItem"
                                          class="cb-margin-top cb-margin-left flex flex-row"
                                          [selectedIndex]="lastSelectedTabIndex"
                                          (selectedTabChanged)="storeLastIndex($event)"></cb-pricing-revision-tabs>
            </div>
        </div>
    </div>
</div>
