import { AfterViewChecked, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[cbStepCardHeight]'
})
export class StepCardHeightDirective implements AfterViewChecked {

    @Input() public readonly cbStepCardHeight: HTMLElement;

    constructor(private readonly ref: ElementRef<HTMLElement>) { }

    public ngAfterViewChecked(): void {
        this.setHeight();
    }

    @HostListener('window:resize')
    public onResize(): void {
        this.setHeight();
    }

    private setHeight(): void {
        if (this.cbStepCardHeight?.clientHeight === this.ref.nativeElement?.clientHeight) {
            return;
        }
        this.ref.nativeElement.style.height = `${this.cbStepCardHeight?.clientHeight ?? 0}px`;
    }
}
