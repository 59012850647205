import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LotDesignPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { DesignSchemesLogicService, IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { SimpleFormInputType } from '@app/shared/components/dialog/simple-form-dialog/simple-form-dialog.component';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { ILotAmenitiesDialogData, LotAmenitiesDialogComponent, LotAmenitiesDialogRequiredMode } from '@app/views/lot/shared/amenities-dialog/amenities-dialog.component';
import {
    DesignSchemeStatusEnumId,
    DESIGN_SCHEME_STATUS_ENUM,
    DOCUMENT_STATUS_ENUM,
    DOCUMENT_TYPE_CODES_CONST,
    IDesignSchemeDto,
    ILotAmenitiesDto,
    USER_TAG_CONSTANTS_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { DesignSchemeDialogComponent } from '../design-scheme-dialog/design-scheme-dialog.component';
import { DesignSchemeFinaliseDialogComponent } from '../finalise-dialog/finalise-dialog.component';
import { ManageSchemeDataService } from '../manage-scheme-view/manage-scheme-data.service';
import { DesignSchemeRejectDialogComponent } from '../reject-dialog/reject-dialog.component';

@Component({
    selector: 'cb-design-scheme-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss'],
})
export class DesignSchemeActionBarComponent {
    @Input() public readonly mappedItem: IDesignSchemeMappedItem;
    @Output() public mappedItemChange = new EventEmitter<IDesignSchemeMappedItem>();

    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public readonly goBack = new EventEmitter<void>();

    private readonly _userCanRejectDesignScheme = new ComputedProperty(() => this.currentUser?.hasTag(USER_TAG_CONSTANTS_CONST.DESIGNSUPERVISOR) ?? false);
    public get userCanRejectDesignScheme(): boolean {
        return this._userCanRejectDesignScheme.value;
    }

    constructor(
        public readonly userCacheService: UserCacheService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly currentUser: CurrentUserService,
        public readonly logicService: DesignSchemesLogicService,
        public readonly cbDialog: CbDialogService,
        private readonly manageSchemeData: ManageSchemeDataService,
        private readonly toastService: ToastService,
    ) {
        this.currentUser.$promise.then(() => this._userCanRejectDesignScheme.recompute());
    }

    public rejectDesignScheme(): void {
        if (!this.userCanRejectDesignScheme || !this.mappedItem.canManage) {
            return;
        }
        this.cbDialog.simpleFormDialog({
            dialogHeading: 'Reject Design Scheme',
            yesLabel: 'Submit',
            noLabel: 'Cancel',
            value: '',
            formInputConfig: {
                type: SimpleFormInputType.Textarea,
                label: 'Comments',
                maxlength: 1024,
                required: true,
            },
            confirmed: (result) => {
                const blocker = this.cbDialog.block('Rejecting Design Scheme...');
                this.mappedItem.reject(result).subOnce(() => {
                    blocker.close();
                });
            }
        });
    }

    public canEditScheme(): boolean {
        return this.lotDesignPermissions.canEditScheme();
    }

    public canEditAmenities(): boolean {
        return this.mappedItem.statusId === DESIGN_SCHEME_STATUS_ENUM.Completed
            && this.lotDesignPermissions.canEditSchemeAmentiesAfterCompleted();
    }

    public canFinalise(): boolean {
        return this.mappedItem.canFinalise
            && this.manageSchemeData.documents
            && this.manageSchemeData.documents.length > 0
            && this.manageSchemeData.documents.some(x =>
                x.documentType
                && x.documentType.codeName === DOCUMENT_TYPE_CODES_CONST.DESIGN_SCHEME_PLAN && x.document?.isDeleted === false
                && (x.documentStatus === DOCUMENT_STATUS_ENUM.Uploaded || x.documentStatus === DOCUMENT_STATUS_ENUM.Reviewed)
            );
    }

    public canChangeStatus(): boolean {
        return this.mappedItem.canManage
            && this.mappedItem.statusId !== DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution;
    }

    public manageScheme(): void {
        this.cbDialog
            .open(DesignSchemeDialogComponent, {
                data: {
                    mappedItem: this.mappedItem.$clone(),
                    lotTypeEnumId: this.lotMappedItem.lotType
                }
            });
    }

    public editSchemeAmenities(): void {
        if (!this.canEditAmenities()) {
            return;
        }
        this.cbDialog
            .open(LotAmenitiesDialogComponent, {
                data: {
                    amenities: this.mappedItem.amenities,
                    required: LotAmenitiesDialogRequiredMode.All,
                } as ILotAmenitiesDialogData,
            })
            .afterClosed()
            .subOnce((result: ILotAmenitiesDto | false) => {
                if (result) {
                    this.mappedItem
                        .updateAmenities(result)
                        .subOnce(() => {
                            this.lotMappedItem.$reload();
                            this.mappedItemChange.emit(this.mappedItem);
                        });
                }
            });
    }

    public backToSchemes(): void {
        this.goBack.emit();
    }

    public review(): void {
        this.cbDialog
            .confirm({
                dialogHeading: 'Review Design Scheme',
                message: 'Allow Design Scheme to proceed for acceptance',
                yesLabel: 'Proceed Scheme',
                noLabel: 'Reject Scheme',
                confirmed: () => {
                    this.saveStatus(DESIGN_SCHEME_STATUS_ENUM.WaitingForReview);
                },
                declined: () => {
                    this.rejectSchemeReview();
                }
            });
    }

    private rejectSchemeReview(): void {
        this.cbDialog
            .open(DesignSchemeRejectDialogComponent, {
                data: {
                    mappedItem: this.mappedItem.$clone(),
                    hideRejectionOptions: true,
                }
            }).afterClosed().subOnce(() => {
                this.mappedItemChange.emit(this.mappedItem);
            });
    }

    public setInQueue(): void {
        this.saveStatus(DESIGN_SCHEME_STATUS_ENUM.InQueue);
    }

    public setOnHold(): void {
        this.saveStatus(DESIGN_SCHEME_STATUS_ENUM.OnHold);
    }

    public setInProgress(): void {
        this.saveStatus(DESIGN_SCHEME_STATUS_ENUM.InProgress);
    }

    public setOnFinalise(): void {
        if (!this.canFinalise()) {
            return;
        }
        this.cbDialog
            .open(DesignSchemeFinaliseDialogComponent, {
                data: {
                    mappedItem: this.mappedItem.$clone(),
                    lotMappedItem: this.lotMappedItem,
                }
            })
            .afterClosed()
            .subOnce((result: IDesignSchemeDto | false) => {
                if (result) {
                    const needsReview = this.mappedItem.reviewerId != null && this.mappedItem.reviewRequired;
                    this.saveStatus(needsReview ? DESIGN_SCHEME_STATUS_ENUM.DesignReviewRequired : DESIGN_SCHEME_STATUS_ENUM.WaitingForReview);
                }
            });
    }

    public cancelDesignScheme(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Cancel Design Scheme',
            message: 'Are you sure you want to Cancel this Design Scheme?',
            confirmed: () => {
                this.saveStatus(DESIGN_SCHEME_STATUS_ENUM.Cancelled);
                this.mappedItemChange.emit(this.mappedItem);
            }
        });
    }

    public resubmit(): void {
        const blockingDialog = this.cbDialog.block('Saving status...');

        this.cbDialog.confirm({
            dialogHeading: 'Resubmit Design Scheme',
            message: 'Are you sure you want to resubmit this Design Scheme?',
            confirmed: () => {
                this.mappedItem.resubmit().subOnce(() => {
                    this.mappedItemChange.emit(this.mappedItem);
                    blockingDialog.close();
                    this.toastService.showToast('Saved Successfully', 'OK');
                });
            }
        });
    }


    private saveStatus(status: DesignSchemeStatusEnumId): void {
        const dialog = this.cbDialog.block('Saving status...');
        this.mappedItem.statusId = status;
        this.mappedItem
            .$save()
            .subOnce(() => {
                this.mappedItemChange.emit(this.mappedItem);
                dialog.close();
                this.toastService.showToast('Saved Successfully', 'OK');
            });
    }
}
