import {Component, HostBinding, Inject, Input, OnInit} from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { BuildTypeLogicService } from '@app/logic/build-type';
import { DesignComplexityLogicService } from '@app/logic/design-complexity';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { NonWorkingDayLogicService } from '@app/logic/non-working-day';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import {
    IBuildTypeDto,
    IDesignComplexityDto,
    LotTypeEnumId,
    TEAM_CODES_CONST,
    USER_TAG_CONSTANTS_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { find } from 'lodash';
import { approverTags } from '../../shared/approver-tags.data';
import moment from 'moment';

@Component({
    selector: 'cb-design-scheme-details',
    templateUrl: './design-scheme-details.component.html',
    styleUrls: ['./design-scheme-details.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class DesignSchemeDetailsComponent implements OnInit {
    @HostBinding('class') class = 'flex-row';
    private _mappedItem: IDesignSchemeMappedItem;
    @Input() public set mappedItem(item: IDesignSchemeMappedItem) {
        this.designComplexityId = item.designComplexity?.id;
        this._mappedItem = item;
    }
    public get mappedItem(): IDesignSchemeMappedItem {
        return this._mappedItem;
    }

    @Input() public lotTypeEnumId: LotTypeEnumId;
    public readonly approverTags = approverTags;

    public designComplexities = this.lookups.DESIGN_COMPLEXITY.toLookup();
    public fullDesignComplexities: IDesignComplexityDto[];
    public designComplexityId: number;
    public selectedStandardPlan: { name: string; id: number };

    public buildTypes: IBuildTypeDto[];

    public readonly DESIGN_TEAM = TEAM_CODES_CONST.DesignTeamKey;

    public readonly DESIGN_SUPERVISOR = USER_TAG_CONSTANTS_CONST.DESIGNSUPERVISOR;

    public assignedUser: { name: string; id: string };
    public approverUser: { name: string; id: string };
    public reviewerUser: { name: string; id: string };

    constructor(
        @Inject(LookupService) private readonly lookups: LookupService,
        public readonly buildTypeLogic: BuildTypeLogicService,
        public readonly currentUser: CurrentUserService,
        public readonly designComplexityLogicService: DesignComplexityLogicService,
        public readonly nonWorkingDayLogicService: NonWorkingDayLogicService,
    ) { }

    public ngOnInit(): void {
        this.designComplexityLogicService.$getList().subOnce(result => {
            this.fullDesignComplexities = result;
        });
        this.buildTypeLogic
            .getListByLotType(this.lotTypeEnumId)
            .subOnce(x => this.buildTypes = x);
        this.setupUsers();
        this.setupStandardPlan();
    }

    public updatedDesignComplexity(value: number): void {
        this.designComplexityId = value;
        if (!this.mappedItem.designComplexity) {
            this.mappedItem.designComplexity = {} as IDesignComplexityDto;
        }
        this.mappedItem.designComplexity.id = value;
        const selectedDesignComplexity = find(this.fullDesignComplexities, { id: this.mappedItem.designComplexity.id });
        if (selectedDesignComplexity) {
            this.mappedItem.targetWorkHours = selectedDesignComplexity.targetWorkHours;
            const startDate = this.mappedItem.createdDate != null ? this.mappedItem.createdDate : moment().format();
            this.nonWorkingDayLogicService.getDueDate(startDate, selectedDesignComplexity.targetCompletionHours).subOnce(this.setDueDate);
        }
    }

    private setDueDate = (dateResult: DateString): void => {
        this.mappedItem.dueBy = dateResult;
    };

    public assignedChanged(): void {
        if (this.assignedUser) {
            this.mappedItem.assignedToUserId = this.assignedUser.id;
        }
    }

    public approverChanged(): void {
        if (this.approverUser) {
            this.mappedItem.approverUserId = this.approverUser.id;
        }
    }

    public reviewerChanged(): void {
        if (this.reviewerUser) {
            this.mappedItem.reviewerId = this.reviewerUser.id;
        }
    }

    private setupUsers(): void {
        if (!isNullOrWhiteSpace(this.mappedItem?.assignedToUserId)) {
            this.assignedUser = {
                id: this.mappedItem.assignedToUserId,
                name: this.mappedItem.assignedToUserName
            };
        }

        if (!isNullOrWhiteSpace(this.mappedItem?.reviewerId)) {
            this.reviewerUser = {
                id: this.mappedItem.reviewerId,
                name: this.mappedItem.reviewerName
            };
        }

        if (!isNullOrWhiteSpace(this.mappedItem.approverUserId)) {
            this.approverUser = {
                id: this.mappedItem.approverUserId,
                name: this.mappedItem.approverUserName
            };
        } else if (!(this.mappedItem.id > 0)) {
            this.approverUser = {
                id: this.currentUser.guid,
                name: this.currentUser.profile.name
            };
        }
    }

    private setupStandardPlan(): void {
        if (this.mappedItem.standardPlanId > 0) {
            this.selectedStandardPlan = {
                id: this.mappedItem.standardPlanId,
                name: this.mappedItem.standardPlanLabel
            };
        }
    }

    public standardPlanChanged(): void {
        if (this.selectedStandardPlan) {
            this.mappedItem.standardPlanId = this.selectedStandardPlan.id;
        }
    }
}
