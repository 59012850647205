import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {LotPermissions} from '@app/core/permissions';
import {IDocumentPermissions} from '@app/core/permissions/base.permissions';
import {ToastService} from '@app/core/services/toast/toast.service';
import {IDesignSchemeMappedItem} from '@app/logic/design-schemes';
import {IDesignSchemesLogicService} from '@app/logic/design-schemes/interfaces/i.design-schemes-logic.service';
import {DocumentsLogicService} from '@app/logic/documents';
import {ILotMappedItem} from '@app/logic/lots';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {IManageDialogData} from '@app/shared/interfaces/i.manage-dialog-data';
import {IDesignSchemeDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {combineLatest, mergeMap, Observable, Observer} from 'rxjs';
import {IRequestDesignSchemeDocumentsToUpload} from './i.request-design-scheme-documents-to-upload';
import {DESIGN_SCHEME_WIZARD_STEP} from './request-design-schemes-dialog.enum';
import {DesignRequirementsLogicService, IDesignRequirementsMappedItem} from '@app/logic/design-requirements';

@Component({
    selector: 'cb-request-design-schemes-dialog',
    templateUrl: './request-design-schemes-dialog.component.html',
    styleUrls: ['./request-design-schemes-dialog.component.scss']
})
export class RequestDesignSchemesDialogComponent extends BaseDialogFormViewDirective<IDesignSchemeDto, IDesignSchemeMappedItem, IDesignSchemesLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '1400px';

    public mappedItem: IDesignSchemeMappedItem;
    public lotMappedItem: ILotMappedItem;
    public mode: FormMode;
    public selectedTabStep = 0;
    public documentPermissions: IDocumentPermissions;
    public documentsToUpload: IRequestDesignSchemeDocumentsToUpload = {
        designInfoDocuments: [],
        designSchemeDocuments: [],
        lotSitePhotoDocuments: [],
    };
    private designRequirementsMappedItem: IDesignRequirementsMappedItem;

    constructor(
        private readonly toastService: ToastService,
        private readonly designRequirementsLogicService: DesignRequirementsLogicService,
        public readonly documentsLogicService: DocumentsLogicService,
        public readonly lotPermissions: LotPermissions,
        public readonly dialogRef: MatDialogRef<RequestDesignSchemesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IDesignSchemeMappedItem> & { lotMappedItem: ILotMappedItem; mode: FormMode },
        public readonly cbDialog: CbDialogService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = data.mappedItem;
        this.lotMappedItem = data.lotMappedItem;
        this.mode = data.mode;
        this.lotPermissions.observable.subOnce(() => {
            this.documentPermissions = this.lotPermissions.getDocumentPermissions();
        });
    }

    public ngOnInit(): void {
        this.formMode$.next(FormMode.Add);
        this.loadDesignRequirementsMappedItem();
    }

    public loadDesignRequirementsMappedItem(): void {
        this.designRequirementsLogicService.$getMappedItem(this.lotMappedItem.id).subOnce(item => {
            this.designRequirementsMappedItem = item;
        });
    }

    public isStepComplete(
        step1Form: UntypedFormGroup,
        step2Form: UntypedFormGroup,
        step3Form: UntypedFormGroup,
        step4Form: UntypedFormGroup,
        step5Form: UntypedFormGroup,
        step6Form: UntypedFormGroup,
        step7Form: UntypedFormGroup,
    ): boolean {
        switch (this.selectedTabStep) {
            case DESIGN_SCHEME_WIZARD_STEP.step1:
                return step1Form.valid;
            case DESIGN_SCHEME_WIZARD_STEP.step2:
                return step2Form && step2Form.valid && this.mappedItem?.designRequirementItems?.length > 0;
            case DESIGN_SCHEME_WIZARD_STEP.step3:
                return step3Form && step3Form.valid;
            case DESIGN_SCHEME_WIZARD_STEP.step4:
                return step4Form && (step4Form.pristine || step4Form.valid);
            case DESIGN_SCHEME_WIZARD_STEP.step5:
                return step5Form && step5Form.valid;
            case DESIGN_SCHEME_WIZARD_STEP.step6:
                return step6Form && step6Form.valid;
            case DESIGN_SCHEME_WIZARD_STEP.step7:
                return step7Form && step7Form.valid;
            default:
                return undefined;
        }
    }

    public isSaveDisabled(step7Form: UntypedFormGroup): boolean {
        return (step7Form.invalid || step7Form.pristine || !this.mappedItem.lotSpecChangesMadePreperation);
    }

    public canSeeSave(): boolean {
        return this.selectedTabStep === 6;
    }

    public canGoNext(): boolean {
        return this.selectedTabStep < 6;
    }

    public canGoBack(): boolean {
        return this.selectedTabStep > 0;
    }

    public next(): void {
        this.selectedTabStep++;
    }

    public back(): void {
        this.selectedTabStep--;
    }

    // requestDesignScheme
    public requestDesignScheme(): void {
        const loadingDialog = this.cbDialog.block('Please wait... your request is being processed');
        const observer = {
            next: _ => {
                loadingDialog.close();
                this.toastService.saveSuccess();
                this.dialogRef.close(true);
            },
            error: error => {
                loadingDialog.close();
                this.toastService.saveError(error.message ?? 'Something went wrong...');
                this.dialogRef.close(false);
            }
        } as Observer<any>;
        this.mappedItem.request().pipe(mergeMap(this.handleRequestDocuments)).subOnce(observer);
    }

    private handleRequestDocuments = (): Observable<any> => {
        const observables = [];

        this.documentsToUpload.designSchemeDocuments.forEach((document) => {
            observables.push(this.documentsLogicService.uploadDocumentEntity(this.mappedItem.documentEntityUri, this.mappedItem.documentEntityId, document));
        });

        this.documentsToUpload.designInfoDocuments.forEach((document, index) => {
            if (document.documentEntityId > 0) {
                observables.push(this.documentsLogicService.reUploadDocumentEntity(document));
            } else {
                observables.push(this.documentsLogicService.uploadDocumentEntity(
                    this.designRequirementsMappedItem.documentEntityUri,
                    this.designRequirementsMappedItem.documentEntityId,
                    document));
            }
        });

        this.documentsToUpload.lotSitePhotoDocuments.forEach((document) => {
            observables.push(this.documentsLogicService.uploadDocumentEntity(this.mappedItem.documentEntityUri, this.mappedItem.documentEntityId, document));
        });

        return combineLatest(observables);

    };

}
