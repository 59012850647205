import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { DepInject } from '../base/dep-inject.decorator';
import { IGenericQuestionDto } from './interfaces/i.generic-question.dto';
import { IGenericQuestionMappedItem } from './interfaces/i.generic-question.mapped';
import { IGenericQuestionsLogicService } from './interfaces/i.generic-questions-logic.service';
import { Observable } from 'rxjs';
import { SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { DtoProp } from '../base/dto-prop.decorator';

export class GenericQuestionMappedItem
    extends BaseMappedItem<IGenericQuestionDto, IGenericQuestionMappedItem, IGenericQuestionsLogicService>
    implements IGenericQuestionMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public entityId: number;
    @DtoProp public systemArea: SystemAreaEnumId;
    @DtoProp public questionNumber: number;
    @DtoProp public assignedToUserId: string;
    @DtoProp public assignedToUserName: string;
    @DtoProp public assignedToTeamId: number;
    @DtoProp public isActive: boolean;
    @DtoProp public isAwaitingResponse: boolean;
    @DtoProp public request: string;
    @DtoProp public response: string;
    @DtoProp public responseDate: string;
    @DtoProp public externalReference: string;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public createdByUser: string;
    @DtoProp public createdById: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public createdByTeam: string;
    @DtoProp public createdByTeamCode: string;
    @DtoProp public assignedToTeam: string;
    @DtoProp public teamCode: string;
    @DtoProp public lotId: number;
    @DtoProp public childEntityId: number;
    @DtoProp public childSystemArea: SystemAreaEnumId;

    @DepInject(CurrentUserService) private readonly currentUser: CurrentUserService;

    constructor(
        sourceData: IGenericQuestionDto,
        logicService: IGenericQuestionsLogicService
    ) {
        super(
            sourceData,
            logicService,
            GenericQuestionMappedItem,
            { id: 0 },
        );
    }

    private baseCanEdit(): boolean {
        return this.isAwaitingResponse
            && this.isActive;
    }

    @Computed() public get canCancel(): boolean {
        return this.baseCanEdit();
    }

    @Computed() public get canEdit(): boolean {
        return this.createdById === this.currentUser.guid
            && this.baseCanEdit();
    }

    @Computed() public get isRequestingUser(): boolean {
        return this.createdById === this.currentUser.guid;
    }

    @Computed() public get canRespond(): boolean {
        return this.assignedToUserId === this.currentUser.guid
            && this.baseCanEdit();
    }

    @Computed() public get canView(): boolean {
        return (this.assignedToUserId === this.currentUser.guid)
            && !this.isAwaitingResponse
            && this.isActive;
    }

    public cancel(): Observable<IGenericQuestionDto> {
        return new Observable((observer) => {
            if (!this.canCancel) {
                observer.error('canCancel is false');
                return observer.complete();
            }
            this.isActive = false;
            this.$save()
                .subOnce({
                    next: n => observer.next(n),
                    error: e => observer.error(e),
                    complete: () => observer.complete(),
                });
        });
    }
}
