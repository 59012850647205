import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DOCUMENT_STATUS_ENUM, DOCUMENT_TYPE_CODES_CONST, SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { IBuildingConsentMappedItem, IResourceConsentMappedItem } from '@app/logic/lot-consents';

import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { IDocumentPermissions } from '@app/core/permissions/base.permissions';
import { LotDesignPermissions } from '@app/core/permissions';

@Component({
    selector: 'cb-lot-consent-documents',
    templateUrl: './lot-consent-documents.component.html',
    styleUrls: ['./lot-consent-documents.component.scss']
})
export class LotConsentDocumentsComponent {

    @Input() public readonly mappedItem: IBuildingConsentMappedItem | IResourceConsentMappedItem;
    @Input() public readonly systemArea: SystemAreaEnumId;
    @Output() public readonly documentCount = new EventEmitter<number>();
    @Output() public readonly hasBuildingConsentDocument = new EventEmitter<boolean>();
    @Output() public readonly hasResourceConsentDocument = new EventEmitter<boolean>();
    @Output() public reloadLotMappedItem = new EventEmitter();
    @Input() public edit: boolean;

    public documents: IDocumentEntityMappedItem[];
    public documentPermissions: IDocumentPermissions;

    constructor(
        public readonly permissions: LotDesignPermissions,
    ) {
        this.documentPermissions = permissions.getConsentDocumentPermissions();
    }

    public onDocumentsChange = (documents: IDocumentEntityMappedItem[]) => {
        this.documents = documents;
        this.documentCount.emit(this.documents?.length ?? 0);
        this.setHasBuildingConsentDocument();
        this.setHasResourceConsentDocument();
    };

    public complianceDocumentUploaded(document: IDocumentEntityMappedItem): void {
        if (document?.documentType?.codeName === DOCUMENT_TYPE_CODES_CONST.CODE_OF_COMPLIANCE) {
            this.reloadLotMappedItem.emit();
        }
    }

    private readonly setHasBuildingConsentDocument = () => {
        this.hasBuildingConsentDocument.emit(
            this.documents?.some((doc) =>
                doc.documentType.codeName === DOCUMENT_TYPE_CODES_CONST.BUILDING_CONSENT
                && doc.documentStatus !== DOCUMENT_STATUS_ENUM.Empty
            )
        );

    };

    private readonly setHasResourceConsentDocument = () => {
        this.hasResourceConsentDocument.emit(
            this.documents?.some((doc) =>
                doc.documentType.codeName === DOCUMENT_TYPE_CODES_CONST.RESOURCE_CONSENT
                && doc.documentStatus !== DOCUMENT_STATUS_ENUM.Empty
            )
        );
    };

}
