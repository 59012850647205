import {cloneDeepSafe} from '@app/shared/utils/clone-object.util';
import {cleanObjectDeep} from 'cb-hub-lib';
import {map, Observable} from 'rxjs';
import {BaseLogicService} from './base-logic.service';


export abstract class BaseLogicServiceWithSearch<DtoType, MappedItemType, SearchParamsType, SearchResultType>
    extends BaseLogicService<DtoType, MappedItemType> {
    public $getSearchList(params: SearchParamsType): Observable<SearchResultType[] | any> {
        return this.$http.get<any>(`${this.$baseUri}/search`, cleanObjectDeep(cloneDeepSafe(params)))
            .pipe(map(results => {
                if (results.items) {
                    return results.items;
                } else {
                    return results;
                }
            }));
    }

    public $getMappedSearchList(params: SearchParamsType): Observable<MappedItemType[]> {
        return this.$http.get<any>(`${this.$baseUri}/search`, cleanObjectDeep(cloneDeepSafe(params)))
            .pipe(map(results => {
                if (results.items) {
                    return results.items.map(result => this.$createMappedItem(result));
                } else {
                    return results.map(result => this.$createMappedItem(result));
                }
            }));
    }
}
