import {Component, Input} from '@angular/core';

@Component({
    selector: 'cb-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
    @Input() public readonly message: string = 'Please wait, loading...';
}
