import { Component } from '@angular/core';
import { DashboardBaseWidgetDirective, IItemRequiringActionDto } from '../dashboard-base-widget/dashboard-base-widget.directive';

@Component({
    selector: 'cb-dashboard-widget-grouped-action-list',
    templateUrl: './dashboard-widget-grouped-action-list.component.html',
    styleUrls: ['./dashboard-widget-grouped-action-list.component.scss']
})
export class DashboardWidgetGroupedAcitonListComponent extends DashboardBaseWidgetDirective<IItemRequiringActionDto[]> {
    public groups: Array<{
        children: Array<{ [key: string]: string | number; label: string }>;
        entityId: number;
        key: number;
    }> = [];

    public setData(data: IItemRequiringActionDto[]): void {
        this.data = data;
        Object.keys(data).forEach((key) => {
            if (key.toLowerCase() === key.valueOf().toLowerCase() && data[key] && data[key][0]) {
                this.groups.push({
                    children: data[key].map(element => {
                        return { ...element, label: element.documentType.label };
                    }),
                    entityId: data[key][0].viewingAreaEntityId,
                    key: data[key][0].info
                });
            }
        });
    }
}
