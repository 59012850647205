import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CLIENT_ACCOUNT_STATUS_ENUM, IClientAccountDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-client-account-input-find-card',
    templateUrl: './client-account-input-find-card.component.html',
    styleUrls: ['./client-account-input-find-card.component.scss']
})
export class ClientAccountInputFindCardComponent {
    @Input() public dto: IClientAccountDto;
    @Output() public readonly selectionChosenEmitter = new EventEmitter();
    public CLIENT_ACCOUNT_STATUS_ENUM = CLIENT_ACCOUNT_STATUS_ENUM;

    constructor() { }

    public selectionChosen(val: IClientAccountDto): void {
        this.selectionChosenEmitter.emit(val);
    }

}
