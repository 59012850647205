import { ChangeDetectorRef, Component, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject, Subscription, map, skipWhile, switchMap } from 'rxjs';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { DesignTeamPermissions, QSTeamPermissions } from '@app/core/permissions';
import { orderBy } from 'lodash';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CHANGE_STATUS_ENUM, IChangeRecordDocumentDto, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { ChangeRecordLogicService } from '@app/logic/change-records/change-record.logic-service';
import { ITeamChangeRecordSearch } from '@app/core/services/user-cache/user-cache-areas';

@Component({
    selector: 'cb-teams-changes',
    templateUrl: './teams-changes.component.html',
    styleUrls: ['./teams-changes.component.scss'],
})
export class TeamsChangesComponent implements OnDestroy, AfterViewInit {

    public resultOrders$ = of([
        { id: 'changeNumber', label: 'Change Number' },
        { id: 'assignedTo', label: 'Assigned To' },
        { id: 'createdDate', label: 'Created Date' },
        { id: 'dueDate', label: 'Due Date' },
        { id: 'status', label: 'Status' }
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    @Input() public get searchFilters(): UserCacheItem<ITeamChangeRecordSearch> {
        return this.userCacheService.qsTeamChangeRecordSearch;
    }

    @Input() public set teamCode(value: string) {
        this._teamCode$.next(value);
    }

    private _permissions$ = new BehaviorSubject<QSTeamPermissions | DesignTeamPermissions>(null);
    private _teamCode$ = new BehaviorSubject<string>(null);
    public isUnassigned: boolean;

    private _subscriptions = new Subscription();
    public searchFiltersLoaded$ = new BehaviorSubject(null);
    public searchEnabled$ = new BehaviorSubject(null);
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public readonly allUsers = '999';
    public readonly unassigned = undefined;
    public loaded = false;
    public results: IChangeRecordDocumentDto[] = [];

    public get searchEnabled(): boolean {
        return this.searchEnabled$.value;
    }

    public get searchFiltersLoaded(): boolean {
        return this.searchFiltersLoaded$.value;
    }

    public changeStatuses$ = of(CHANGE_STATUS_ENUM.toSelectList())
        .pipe(
            map(changeStatuses => {
                const orderedChangeStatuses = orderBy(changeStatuses, tag => tag.label.toLowerCase());
                return orderedChangeStatuses;
            })
        );

    public teamUsersOptions$ = this._teamCode$.asObservable().pipe(
        skipWhile(code => !code),
        switchMap(code => this.teamsLogicService.loadAllMembersByKey(code)),
        map(users => {
            users = orderBy(users, 'firstName');
            users.unshift({ id: this.allUsers, label: 'All' } as any);
            users.unshift({ id: this.unassigned, label: 'Unassigned' } as any);
            return users;
        })
    );

    private _setPermissions = (teamCode: string): QSTeamPermissions | DesignTeamPermissions => {
        switch (teamCode) {
            case TEAM_CODES_CONST.QsTeamKey:
                return this.qsTeamPermissions;
            case TEAM_CODES_CONST.DesignTeamKey:
                return this.designTeamPermissions;
            default:
                return this.qsTeamPermissions;
        }
    };

    public permissions$ = this._teamCode$.asObservable().pipe(
        skipWhile(code => !code),
        map(this._setPermissions)
    );

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: ChangeRecordLogicService,
        public cdRef: ChangeDetectorRef,
        private readonly navigationService: NavigationService,
        public readonly qsTeamPermissions: QSTeamPermissions,
        public readonly designTeamPermissions: DesignTeamPermissions,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
    ) {
        this.permissions$.subscribe(this._permissions$);
    }

    public ngAfterViewInit(): void {
        this._initSearchFiltersCache();
        this.cdRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public fetchResults(): Observable<IChangeRecordDocumentDto[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.$getSearchList(this._queryParams);
    }

    public getStreetAddress(lotChange: IChangeRecordDocumentDto): string {
        return `${lotChange.addressStreet}, ${lotChange.addressCity}`;
    }

    public viewLotChanges(lotId: number): void {
        this.navigationService.navigate([`/lots/${lotId}/changes`]);
    }

    private get _queryParams(): ITeamChangeRecordSearch {
        return this.searchEnabled ?
            {
                ...this.searchFilters.copyData(),
                userId: this.searchFilters.data.userId === this.allUsers ?
                    undefined :
                    this.searchFilters.data.userId ?
                        this.searchFilters.data.userId :
                        undefined,
                statusId: this.searchFilters.data.statusId,
                currentPage: this.currentPage,
                isUnassigned: !this.searchFilters.data.userId
            } :
            undefined;
    }

    private _initSearchFiltersCache(): void {
        this.searchFilters.init().then(() => {
            this.searchFiltersLoaded$.next(true);
            this.searchEnabled$.next(true);
            this._subscriptions.add(
                this.searchFilters.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );

            this.searchFilters.silentData.canSeeAll = this.qsTeamPermissions.canSeeAll();
            if (!this.searchFilters.silentData.canSeeAll) {
                this.searchFilters
                    .silentData
                    .userId = this.searchFilters.currentUserId;
            }
            else {
                this.searchFilters.data.userId = this.allUsers;
            }
            this._onSearchFiltersChanged();
        });
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };
}
