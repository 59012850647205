import { Injectable, Injector } from '@angular/core';

import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { IRevisionReportLogicService } from './interfaces/i.revision-report.logic.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';

@Injectable()
export class RevisionReportLogicService
    extends BaseLogicService<Object, Object>
    implements IRevisionReportLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('', Object);
    }

    public exportToCsv(year: number, month: number): Observable<any> {
        return this.$http.download(`teams/exportdesignrevisionreport/${year}/${month + 1}`, 'Exporting to CSV...');
    }

}
