<cb-dialog dialogHeading="Set Concept Sell Price">
    <form class="flex-col flex"
          #conceptSellPriceForm="ngForm">
        <cb-info-message *ngIf="data.showClientSaleLostWarning"
                         message="Client Variations from Lost Sale exist"
                         icon="warning">
        </cb-info-message>
        <cb-currency class="flex"
                     label="Build Price"
                     [inclGst]="true"
                     name="buildPriceGst"
                     [readonly]="true"
                     [(ngModel)]="data.mappedItem.pricingRevision.buildPrice">
        </cb-currency>
        <cb-currency class="flex"
                     label="Sell Price"
                     [inclGst]="true"
                     name="sellingPriceGst"
                     [(ngModel)]="data.mappedItem.sellingPrice">
        </cb-currency>
        <cb-currency class="flex"
                     label="Sell Price - (Gst Exclusive)"
                     name="sellingPrice"
                     [readonly]="true"
                     [(ngModel)]="data.mappedItem.sellingPrice">
        </cb-currency>
    </form>
    <footer class="end-center">
        <button mat-raised-button
                [disabled]="!conceptSellPriceForm.dirty || !conceptSellPriceForm.valid"
                color="primary"
                (click)="save()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
