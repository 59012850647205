export enum LotChangePermissionEnum {
    None                = 0x00,
    CanView             = 0x01 << 0,
    CanCreate           = 0x01 << 1,
    CanEdit             = 0x01 << 2,
    CanSubmitForPricing = 0x01 << 3,
    CanCancel           = 0x01 << 4,
    CanReassign         = 0x01 << 5,
    CanManageOption     = 0x01 << 6,
    CanChooseOption     = 0x01 << 7,
    CanManageOwnChange  = 0x01 << 8,
    CanGenerateChangesSignOffDocument = 0x01 << 9
}
