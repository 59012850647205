import { Injectable, Injector } from '@angular/core';
import { IProductDto, IRateDto } from './interfaces/i.product.dto';

import { ISearchResult } from '@app/shared/components/search/i.search';
import { IFileResponse } from '@app/shared/interfaces/i.file-response.dto';
import { ICreateUpdateOfferingDto, ILotScheduleOfferingDto, IOfferingDto, ISkinnyProductOfferingDto, IUpdateBundleProductDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { cleanObjectDeep, isNullOrWhiteSpace } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IProductSearchDto } from './i.product-search.dto';
import { IProductLogicService } from './interfaces/i.product.logic.service';
import { IProductMappedItem } from './interfaces/i.product.mapped';
import { ProductMappedItem } from './product.mapped';
import { IProductRateExceptionSearchDto } from '@app/logic/products/i.product-rate-exception-search.dto';

export interface ProductSearchParams {
    query: string;
    currentPage: number;
    pageSize: number;
    activeOnly: boolean;
    cat: number;
    incCategories: boolean;
    t: number;
}

export interface ProductUsageExportParams {
    id: number;
    businessAccountId: number;
    createdDateFrom: Date;
    createdDateTo: Date;
    activityStartDateFrom: Date;
    activityEndDateTo: Date;
    jobNo: string;
    tradeTypeId: number;
}
export interface ProductRateExceptionSearchParams {
    poNo: number;
    businessAccountId: number[];
    createdDateFrom: Date;
    createdDateTo: Date;
    jobNo: string;
    supplierId: number;
    activityStartDate: Date;
    activityEndDate: Date;
    offeringId: number;
}

export interface ProductLotSpecUsageParams {
    currentPage: number;
    pageSize: number;
    lotStatus: number;
    jobStatus: number;
    hasClientSale: boolean;
}

export interface BasicSearchParams {
    currentPage: number;
    pageSize: number;
}

@Injectable()
export class ProductLogicService
    extends BaseLogicService<IProductDto, IProductMappedItem>
    implements IProductLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('products', ProductMappedItem);
    }

    public getOfferingInfoForLotSpecScheduleItem(productId: number): Observable<ILotScheduleOfferingDto> {
        return this.$http
            .get(`${this.$baseUri}/${productId}/attributesandsuppliers`);
    }

    public getSkinnyItem(productId: number): Observable<ISkinnyProductOfferingDto> {
        return this.$http
            .get(`${this.$baseUri}/${productId}/skinny`);
    }

    public uploadCatalog(formData: FormData): Observable<any> {
        return this.$http.post<any>(`${this.$baseUri}/import`, formData);
    }

    public uploadRates(formData: FormData): Observable<any> {
        return this.$http.post<any>(`${this.$baseUri}/import/rates`, formData);
    }

    public exportProductUsage(params: ProductUsageExportParams): Observable<any> {
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http.download<any>(`${this.$baseUri}/rateusage`, 'Exporting Product Usage...', params);
    }

    public getRateExceptionList(params: ProductRateExceptionSearchParams): Observable<IProductRateExceptionSearchDto[]> {
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http.get<IProductRateExceptionSearchDto[]>(`${this.$baseUri}/rateexceptions`, params);
    }

    public $getSearchList(params: ProductSearchParams): Observable<ISearchResult<IProductSearchDto>> {
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http
            .get<ISearchResult<IProductSearchDto>>(`${this.$baseUri}/search`, params)
            .pipe(map((result) => {
                result.items = result.items.map(item => {
                    if (!item.isCompositeItem) {
                        return item;
                    }
                    if (item.componentItems?.length > 0) {
                        item.isCompositeItemWithAssignedItems = true;
                    } else {
                        item.isCompositeItemWithoutAssignedItems = true;
                    }
                    return item;
                });
                return result;
            }));
    }

    public addAttribute(productId: number, attribute: any): Observable<any> {
        return this.$http
            .post<Array<IProductDto>>(`${this.$baseUri}/${productId}/attributes`, attribute);
    }

    public updateAttributes(productId: number, attributeId: any, attributes): Observable<any> {
        return this.$http
            .post<Array<IProductDto>>(`${this.$baseUri}/${productId}/attributes/${attributeId}`, attributes);
    }

    public removeCompositeItem(productId: number): Observable<IProductDto> {
        return this.$http
            .post(`${this.$baseUri}/removefromcomposite/${productId}`);
    }

    public uploadImage(productId: number, image: any): Observable<IProductDto> {
        return this.$http.post(`${this.$baseUri}/${productId}/images`, image);
    }

    public createRate(productId: number, rate: IRateDto): Observable<IRateDto> {
        return this.$http
            .post(`${this.$baseUri}/${productId}/rates/`, rate);
    }

    public saveRate(productId: number, rateId: number, rate: IRateDto): Observable<IRateDto> {
        return this.$http
            .post(`${this.$baseUri}/${productId}/rates/${rateId}`, rate);
    }

    public removeBundleItem(productId: number, bundleProductId: number): Observable<IProductDto> {
        return this.$http
            .delete(`${this.$baseUri}/${productId}/bundleproduct/${bundleProductId}`);
    }

    public saveItem(product: ICreateUpdateOfferingDto): Observable<IOfferingDto> {
        let uri = this.$baseUri;
        if (product.id > 0) {
            uri += `/${product.id}`;
        }
        return this.$http
            .post<IOfferingDto>(uri, product);
    }

    public exportToCsv(): Observable<any> {
        return this.$http.download(`${this.$baseUri}/exportsearch`, 'Exporting to CSV...');
    }

    public getRateForLot(productId: number, lotId: number): Observable<IRateDto> {
        return this.$http
            .get(`${this.$baseUri}/${productId}/ratesforlot/${lotId}`);
    }

    public getRateForManualPurchaseOrder(productId: number, purchaseOrderId: number): Observable<IRateDto> {
        return this.$http
            .get(`${this.$baseUri}/${productId}/ratesforpurchaseorder/${purchaseOrderId}`);
    }

    public getRateValeForManualPurchaseOrder(productId: number, purchaseOrderId: number): Observable<number> {
        return this.getRateForManualPurchaseOrder(productId, purchaseOrderId)
            .pipe(
                map(rate => {
                    if (rate == null
                        || rate.id === 0
                    ) { return; }
                    return rate.value;
                })
            );
    }

    public saveBundleProduct(productId: number, bundleProductId: number, payload: IUpdateBundleProductDto): Observable<IProductDto> {
        if (bundleProductId) {
            return this.$http
                .post<IProductDto>(`${this.$baseUri}/${productId}/bundleproduct/${bundleProductId}`, payload);
        } else {
            return this.$http
                .post<IProductDto>(`${this.$baseUri}/${productId}/bundleproduct`, payload);
        }
    }

    public getDefaultSupplierRateForLot(productId: number, lotId: number, supplierId: number): Observable<number> {
        return this.getRateForLot(productId, lotId)
            .pipe(
                map(rate => {
                    if (rate == null
                        || rate.id === 0
                        // only compare rate supplier if it is a valid id
                        || (rate.businessAccountId > 0 && rate.businessAccountId !== supplierId)
                    ) { return; }
                    return rate.value;
                })
            );
    }

    public saveImageOrder(productId: number, imageIds: Array<string>): Observable<IProductDto> {
        return this.$http.post(`${this.$baseUri}/${productId}/images/sort`, imageIds);
    }

    public deleteImage(productId: number, imageId: string): Observable<IProductDto> {
        return this.$http.delete(`${this.$baseUri}/${productId}/images/${imageId}`);
    }

    public getProductCards(productId: number): Observable<Array<any>> {
        return this.$http.get(`${this.$baseUri}/cards/${productId}`);
    }

    public getProductSpecTemplateUsage(productId: number, params: BasicSearchParams): Observable<ISearchResult<any>> {
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http.get<ISearchResult<any>>(`${this.$baseUri}/specTemplateUsage/${productId}`, params);
    }

    public getProductLotSpecUsage(productId: number, params: ProductLotSpecUsageParams): Observable<ISearchResult<any>> {
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http.get<ISearchResult<any>>(`${this.$baseUri}/lotusage/${productId}`, params);
    }

    public getProductConfirmedQuantityUsage(productId: number, params: BasicSearchParams): Observable<ISearchResult<any>> {
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http.get<ISearchResult<any>>(`${this.$baseUri}/lotQuantityBankUsage/${productId}`, params);
    }

    public getProductBuildProgrammeUsage(productId: number, params: BasicSearchParams): Observable<ISearchResult<any>> {
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http.get<ISearchResult<any>>(`${this.$baseUri}/buildprogrammeusage/${productId}`, params);
    }

    public getProductPurchaseOrderUsage(productId: number, params: BasicSearchParams): Observable<ISearchResult<any>> {
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http.get<ISearchResult<any>>(`${this.$baseUri}/orderUsage/${productId}`, params);
    }

    public downloadPurchaseOrdersUsageReport(
        productId: number,
        startDate: any,
        endDate: any
    ): Observable<any> {
        return this.$http.download(`${this.$baseUri}/rateusage/${productId}?start=${startDate}&end=${endDate}`, 'Downloading Usage Report...');
    }

    public exportCatalogue(): Observable<any> {
        return this.$http.download<any>(`${this.$baseUri}/export`, 'Exporting Catalogue...');
    }

    public exportSupplierItems(vendorId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(`${this.$baseUri}/export/suppliercatalog/${vendorId}`, 'Exporting Supplier Items...');
    }

    public exportCreateBook(): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(`${this.$baseUri}/export/createbook`, 'Exporting Create Book...');
    }

    public exportAllRates(): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>('/rates/all/export', 'Exporting Rates...');
    }

    public exportSupplierRates(vendorId: number, params: { currentRates: boolean }): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(`${this.$baseUri}/export/rates/${vendorId ? vendorId : ''}`, 'Exporting Supplier Rates...', params);
    }
}
