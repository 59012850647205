import {BusinessAccountLogicService, IBusinessAccountMappedItem} from '@app/logic/business-accounts';
import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BusinessAccountsInputFindSearchService} from '@app/core/services/search/business-accounts-input-find-search.service';
import {BusinessAccountsInputFindDialogDirective} from './business-accounts-input-find-dialog.directive';

interface IData {
    mappedItem: IBusinessAccountMappedItem;
    searchService: BusinessAccountsInputFindSearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

/**
 * This component is a text input that when clicked opens up a search dialog with cards for the results
 */
@Component({
    selector: 'cb-business-account-input-find-dialog-cards',
    templateUrl: '../../input-find-dialog-cards/input-find-dialog-cards.component.html',
    styleUrls: ['../../input-find-dialog-cards/input-find-dialog-cards.component.scss'],
    providers: [BusinessAccountLogicService]
})
export class BusinessAccountsInputFindDialogCardsComponent extends BusinessAccountsInputFindDialogDirective {

    constructor(
        public readonly dialogRef: MatDialogRef<BusinessAccountsInputFindDialogDirective>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public logicService: BusinessAccountLogicService,
        public cdRef: ChangeDetectorRef
    ) {
        super(
            dialogRef,
            data,
            logicService,
            cdRef
        );
    }
}

