import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AbmIntegrationPermissionEnum} from '@app/shared/enums/permissions';
import {CurrentUserService} from '../authentication/current.user';
import {BasePermissions} from './base.permissions';

export interface IAbmIntegrationPermissions {
    canResendConfirmToAbm(): boolean;
}

@Injectable()
export class AbmIntegrationPermissions
    extends BasePermissions<AbmIntegrationPermissionEnum>
    implements IAbmIntegrationPermissions {
    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('abmintegration', AbmIntegrationPermissionEnum, currentUser, http, router);
    }

    public canResendConfirmToAbm(): boolean {
        return this.permissionObject(AbmIntegrationPermissionEnum.CanResendConfirmToAbm);
    }
}
