export enum SIGNALR_EVENT_LISTENERS {
    SendNotification = 'sendNotification',
    ConfirmNotificationAsRead = 'confirmNotificationAsRead',
    ConfirmMultipleNotificationsAsRead = 'confirmMultipleNotificationsAsRead',
    SendConnectedUsers = 'sendConnectedUsers',
    SendSimpleMessage = 'sendSimpleMessage',
    ClientVersionUpdated = 'clientBuildVersionUpdated'
}


export enum SIGNALR_CONNECTIONS {
    RegisterForNotifications = 'registerForNotifications',
    RegisterForClientBuildVersionUpdates = 'registerForClientBuildVersionUpdates'
}
