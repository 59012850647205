import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CbButtonModule } from '@app/shared/components/button/button.module';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbProductInputFindModule } from '@app/shared/components/forms/individual/product-input-find/product-input-find.module';
import { CbHistoryModule } from '@app/shared/components/history/history.module';
import { CbImageButtonModule } from '@app/shared/components/image-handlers/image-button/image-button.module';
import { CbImageGalleryModule } from '@app/shared/components/image-handlers/image-gallery/image-gallery.module';
import { CbLightboxModule } from '@app/shared/components/image-handlers/lightbox/lightbox.module';
import { CbNotesModule } from '@app/shared/components/notes/notes.module';
import { CbProjectCardModule } from '@app/shared/components/search-results/cards/project-card/project-card.module';
import { CbSideBarModule } from '@app/shared/components/side-bar/side-bar.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbButtonControlModule } from '@app/shared/directives/button-control/button-control.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbCallbackModule } from '@app/shared/pipe/callback/callback.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbPhoneNumberModule } from '@app/shared/pipe/phone-number/phone-number.module';
import { CbPrettyBoolModule } from '@app/shared/pipe/pretty-bool/pretty-bool.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { CbDasherModule, CbInfoMessageModule, CbLoadingSpinnerModule, CbSelectListModule, CbTableModule } from 'cb-hub-lib';
import { DragulaModule } from 'ng2-dragula';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CbUsersModule } from '../users/users.module';
import { LotBuildModule } from './build/build.module';
import { LotChangesModule } from './changes/changes.module.ts';
import { CbLotCostsModule } from './costs/costs.module';
import { LotDesignModule } from './design/design.module';
import { CbLotLeadsModule } from './leads/leads.module';
import { LotArModule } from './lot-ar/lot-ar.module';
import { LotSearchModule } from './lot-search/lot-search.module';
import { LotSearchComponent } from './lot-search/lot-search/lot-search.component';
import { CbLotSidebarModule } from './lot-sidebar/lot-sidebar.module';
import { LotSummaryModule } from './lot-summary/lot-summary.module';
import { LotsAreaComponent } from './lots-area/lots-area.component';
import { LotQuestionsComponent } from './questions/lot-questions/lot-questions.component';
import { LotSharedModule } from './shared/shared.module';
import { LotSpecificationModule } from './specification/specification.module';
import { LotUnitsModule } from './units/units.module';

const MAT_MODULES = [
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatMenuModule,
    MatChipsModule,
    MatRadioModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSidenavModule
];
@NgModule({
    declarations: [
        LotsAreaComponent,
        LotQuestionsComponent,
    ],
    imports: [
        RouterModule.forRoot(
            [
                { path: 'lots/:id', redirectTo: 'lots/:id/summary', pathMatch: 'full' },

                {
                    component: LotSearchComponent,
                    path: 'lots',
                    data: { breadcrumb: 'Lot Search' },
                },
                {
                    component: LotsAreaComponent,
                    path: 'lots/:id/:cbTabRouteId',
                    data: {
                        breadcrumb: 'View Lot',
                        breadcrumbPredecessors: [
                            { label: 'Lot Search', url: '/lots' }
                        ]
                    },
                },
                {
                    component: LotsAreaComponent,
                    path: 'lots/:id/:cbTabRouteId/:cbTabRouteId2',
                    data: {
                        breadcrumb: 'View Lot',
                        breadcrumbPredecessors: [
                            { label: 'Lot Search', url: '/lots' }
                        ]
                    },
                },
                {
                    component: LotsAreaComponent,
                    path: 'lots/:id/:cbTabRouteId/:cbTabRouteId2/:id2',
                    data: {
                        breadcrumb: 'View Lot',
                        breadcrumbPredecessors: [
                            { label: 'Lot Search', url: '/lots' }
                        ]
                    },
                }],
            {
    onSameUrlNavigation: 'reload'
}
        ),
        CommonModule,
        FormsModule,
        DragulaModule,
        ReactiveFormsModule,
        BrowserModule,
        CbFormsModule,
        CbWaitForModule,
        InfiniteScrollModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        CbUsersModule,
        CbDialogModule,
        CbPhoneNumberModule,
        CbSelectListModule,
        CbProjectCardModule,
        CbPrettyBoolModule,
        CbSortByModule,
        CbDocumentsModule,
        CbHubTableModule,
        CbTableModule,
        CbButtonControlModule,
        CbNotesModule,
        CbDasherModule,
        CbSideBarModule,
        CbEnumToLabelModule,
        CbHistoryModule,
        CbSelectListModule,
        CbLightboxModule,
        CbImageGalleryModule,
        CbImageButtonModule,
        CbCallbackModule,
        CbDisplayValueModule,
        CbClickModule,
        CbTabRouteModule,
        CbUserDateTimeModule,
        CbProductInputFindModule,
        CbLotCostsModule,
        LotSummaryModule,
        LotUnitsModule,
        CbLotLeadsModule,
        LotSpecificationModule,
        LotDesignModule,
        LotChangesModule,
        LotBuildModule,
        CbLotSidebarModule,
        LotSearchModule,
        LotSharedModule,
        CbButtonModule,
        LotArModule,
        ...MAT_MODULES
    ],
})

export class LotModule { }
