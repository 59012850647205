import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbDialogModule } from './../../shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbEnumToLabelModule } from './../../shared/pipe/enum-to-label/enum-to-label.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHistoryModule } from '@app/shared/components/history/history.module';
import { CbNotesModule } from './../../shared/components/notes/notes.module';
import { CbSideBarModule } from '@app/shared/components/side-bar/side-bar.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbHubTableModule } from './../../shared/components/table/table.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { ClientAccountDetailsComponent } from './client-account-details/client-account-details.component';
import { ClientAccountEditComponent } from './client-account-edit/client-account-edit.component';
import { ClientAccountLeadsComponent } from './client-account-leads/client-account-leads.component';
import { ClientAccountProjectsComponent } from './client-account-projects/client-account-projects.component';
import { ClientAccountSalesComponent } from './client-account-sales/client-account-sales.component';
import { ClientAccountSearchComponent } from './client-account-search/client-account-search.component';
import { ClientAccountViewComponent } from './client-account-view/client-account-view.component';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CLIENT_ACCOUNT_FULL_ROUTE } from './client-account-route.const';
import { ManageClientAccountDialogComponent } from './manage-client-account-dialog/manage-client-account-dialog.component';
import {CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';


const CLIENT_ACCOUNT_ROUTE_DATA = {
    breadcrumb: 'View Client Account',
    breadcrumbPredecessors: [
        { label: 'Client Account Search', url: '/accounts' }
    ]
};

@NgModule({
    declarations: [
        ClientAccountSearchComponent,
        ClientAccountViewComponent,
        ClientAccountDetailsComponent,
        ClientAccountEditComponent,
        ClientAccountDetailsComponent,
        ClientAccountLeadsComponent,
        ClientAccountProjectsComponent,
        ClientAccountSalesComponent,
        ManageClientAccountDialogComponent
    ],
    imports: [
        RouterModule.forRoot([
            {
                component: ClientAccountViewComponent,
                path: CLIENT_ACCOUNT_FULL_ROUTE,
                data: CLIENT_ACCOUNT_ROUTE_DATA
            },
            {
                component: ClientAccountViewComponent,
                path: 'accounts/:id',
                data: CLIENT_ACCOUNT_ROUTE_DATA
            },
            {
                component: ClientAccountSearchComponent,
                path: 'accounts',
                data: {
                    breadcrumb: 'Client Account Search'
                }
            }
        ], {}),
        CbDisplayValueModule,
        CbFormsModule,
        CbFormsModule,
        CbLoadingSpinnerModule,
        CbSideBarModule,
        CbTabRouteModule,
        CbWaitForModule,
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatTableModule,
        CbInfoMessageModule,
        CbNotesModule,
        CbHistoryModule,
        CbHubTableModule,
        CbTableModule,
        CbDialogModule,
        CbEnumToLabelModule,
        InfiniteScrollModule,
        MatDividerModule,
        CbClickModule,
    ]
})
export class ClientAccountModule { }
