import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {PriceOptionDto} from '@app/logic/price-options/price-option.dto';
import {IPriceOptionsService} from '@app/logic/price-options/IPriceOptionsService';
import {BaseLogicService} from '@app/logic/base/base-logic.service';
import {HttpWrapperService} from '@app/core/services/http-wrapper/http-wrapper.service';

@Injectable({
    providedIn: 'root'
})
export class PriceOptionsService extends BaseLogicService<PriceOptionDto, Object> implements IPriceOptionsService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'priceOptions',
            Object,
        );
    }

    getAll(): Observable<PriceOptionDto[]> {
        return this.$http.get<PriceOptionDto[]>(`${this.$baseUri}/getAll`);
    }

    getAllActive(): Observable<PriceOptionDto[]> {
        return this.$http.get<PriceOptionDto[]>(`${this.$baseUri}/getAllActive`);
    }

    save(model: PriceOptionDto): Observable<any> {
        return this.$http.post(`${this.$baseUri}/save`, model);
    }

    delete(id: number): Observable<any> {
        return this.$http.delete(`${this.$baseUri}/delete/${id}`);
    }
}
