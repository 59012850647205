import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cbPrettyBool'
})
export class CbPrettyBoolPipe implements PipeTransform {
    public transform(value: boolean, ...args: any[]): any {
        return value ? 'Yes' : 'No';
    }
}
