/* eslint-disable max-len */

import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ILotMappedItem } from '@app/logic/lots';
import { ILotProgressMilestoneDto } from '@app/logic/lots/interfaces/i.lot-progress-milestones.dto';
import { NZ_LOCALE } from '@app/shared/declarations/app.constants';
import {
    BUILDING_CONSENT_STATUS_ENUM,
    COUNCIL_RFI_STATUS_ENUM,
    DESIGN_SCHEME_STATUS_ENUM,
    LotJobStatusEnumId,
    LOT_JOB_STATUS_ENUM,
    LOT_MILESTONE_PROGRESS_ENUM,
    PRECONSENT_PLAN_STATUS_ENUM,
    PRICING_REVISION_STATUS_ENUM,
    WORKING_DRAWING_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'projects/cb-hub-lib/src/lib/utils/string.util';
import { LotSummaryPinnablePanelDirective } from '../../shared/lot-summary-pinnable-panel';

@Component({
    selector: 'cb-lot-summary-stepper',
    templateUrl: './lot-summary-stepper.component.html',
    styleUrls: ['./lot-summary-stepper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotSummaryStepperComponent extends LotSummaryPinnablePanelDirective implements OnInit {

    public LOT_JOB_STATUS_ENUM = LOT_JOB_STATUS_ENUM;

    private _lotMappedItem: ILotMappedItem;
    @Input() public set lotMappedItem(lotMappedItem: ILotMappedItem) {

        if (this._lotMappedItem !== lotMappedItem) {
            this._lotMappedItem = lotMappedItem;
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this._lotMappedItem;
    }

    @Input() public set blockLevelProgress(blockLevelProgress: ILotProgressMilestoneDto) {
        this._blockLevelProgress = blockLevelProgress;
    }

    public get blockLevelProgress(): ILotProgressMilestoneDto
    {
        return this._blockLevelProgress;
    }

    public stepCardExpanded: boolean;

    private _blockLevelProgress: ILotProgressMilestoneDto;

    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.stepCardExpanded = this.pinnedPanels[this.silentDataKey];
    }

    public stepCardExpandCollapse($event): void {
        $event.stopPropagation();
        this.stepCardExpanded = !this.stepCardExpanded;
    }

    public hasFirstScheme(): boolean {
        return this.lotMappedItem.hasFirstScheme
            || this.blockLevelProgress?.scheme === LOT_MILESTONE_PROGRESS_ENUM.Started;
    }

    public hasFirstAcceptedScheme(): boolean {
        return this.lotMappedItem.hasFirstAcceptedScheme
            || this.blockLevelProgress?.scheme === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public showSchemeCard(): boolean {
        return this.lotMappedItem?.jobStatus === LOT_JOB_STATUS_ENUM.Scheme ||
            this.lotMappedItem?.jobStatus === LOT_JOB_STATUS_ENUM.Concepts;
    }

    public isSchemeCardShown(): boolean {
        return (this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.Pending ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InQueue ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InQueueForRework ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InProgress ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.OnHold ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.DesignReviewRequired ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.WaitingForReview ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.Accepted ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InPricing ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution ||

            this.lotMappedItem?.latestPriceRevision?.status?.id === PRICING_REVISION_STATUS_ENUM.InQueue ||
            this.lotMappedItem?.latestPriceRevision?.status?.id === PRICING_REVISION_STATUS_ENUM.InProgress ||
            this.lotMappedItem?.latestPriceRevision?.status?.id === PRICING_REVISION_STATUS_ENUM.OnHold ||
            this.lotMappedItem?.latestPriceRevision?.status?.id === PRICING_REVISION_STATUS_ENUM.WaitingForReview
        );
    }

    public getSchemeCardText(): string {
        if (!this.shouldShowSchemeCardText()) {
            return '';
        }

        const showDate = this.shouldShowDateOnSchemeCardText();

        return `${this.lotMappedItem?.latestScheme?.revisionName} -
             ${this.lotMappedItem?.latestScheme?.status?.label}
               ${!isNullOrWhiteSpace(this.lotMappedItem?.latestScheme?.dueDate) && showDate ? '- '.concat(this.lotMappedItem?.latestScheme?.dueDate) : ''}`;
    }

    // If the scheme has one of these statuses it should show the card text
    private shouldShowSchemeCardText(): boolean {
        return this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.Pending ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InQueue ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InQueueForRework ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InProgress ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.OnHold ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.Accepted ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InPricing ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.DesignReviewRequired ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.WaitingForReview ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution;
    }

    // If the scheme has one of these statuses it should show the date on the card text
    private shouldShowDateOnSchemeCardText(): boolean {
        return this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.Pending ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InQueue ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InQueueForRework ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.InProgress ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.OnHold ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.DesignReviewRequired ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.WaitingForReview ||
            this.lotMappedItem?.latestScheme?.status?.id === DESIGN_SCHEME_STATUS_ENUM.WaitingForRejectionResolution;
    }

    public getPricingRevisionCardText(): string {
        if (this.lotMappedItem?.latestPriceRevision?.status?.id === PRICING_REVISION_STATUS_ENUM.InQueue ||
            this.lotMappedItem?.latestPriceRevision?.status?.id === PRICING_REVISION_STATUS_ENUM.InProgress ||
            this.lotMappedItem?.latestPriceRevision?.status?.id === PRICING_REVISION_STATUS_ENUM.OnHold ||
            this.lotMappedItem?.latestPriceRevision?.status?.id === PRICING_REVISION_STATUS_ENUM.WaitingForReview
        ) {
            return `${this.lotMappedItem?.latestPriceRevision?.revisionName} -
             ${this.lotMappedItem?.latestPriceRevision?.status?.label}
               ${!isNullOrWhiteSpace(this.lotMappedItem?.latestPriceRevision?.dueDate) ? '- '.concat(this.lotMappedItem?.latestPriceRevision?.dueDate) : ''}`;
        } else {
            return '';
        }
    }

    public conceptStarted(): boolean {
        return this.hasFirstAcceptedScheme()
            || this.blockLevelProgress?.concept === LOT_MILESTONE_PROGRESS_ENUM.Started;
    }

    public appliedDesignConceptIsInvalid(): boolean {
        return this.lotMappedItem.activeDesignConceptId > 0
            && this.lotMappedItem.canApplyConcept
            && (this.lotMappedItem.hasEstimatedPriceRevisionOnAppliedConcept || this.lotMappedItem.hasExpiredPriceRevisionOnAppliedConcept);
    }

    public conceptComplete(): boolean {
        return (this.lotMappedItem.activeDesignConceptId > 0 && !this.appliedDesignConceptIsInvalid())
            || this.blockLevelProgress?.concept === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public conceptWarning(): boolean {
        return this.appliedDesignConceptIsInvalid();
    }

    public showConceptCard(): boolean {
        return (!this.isSchemeCardShown() && this.lotMappedItem?.activeDesignConceptId === null && this.lotMappedItem?.pendingConcepts.length > 0) ||
            (!this.isSchemeCardShown() && this.lotMappedItem?.jobStatus === LOT_JOB_STATUS_ENUM.Concepts);
    }

    public getConceptCardText(): string {
        if (this.lotMappedItem.pendingConcepts && this.lotMappedItem.pendingConcepts.length === 1) {
            return `${this.lotMappedItem?.pendingConcepts[0]} - Pending`;
        } else if (this.lotMappedItem?.pendingConcepts && this.lotMappedItem?.pendingConcepts?.length > 1) {
            return `There are ${this.lotMappedItem.pendingConcepts.length} Pending Concepts`;
        } else {
            return '';
        }
    }

    public hasPreconsentDocument(): boolean {
        return this.lotMappedItem.hasPreconsentDocument
            || this.blockLevelProgress?.preconsent === LOT_MILESTONE_PROGRESS_ENUM.Started;
    }

    public hasCompletedAPreConsentPlan(): boolean {
        return this.lotMappedItem.hasCompletedAPreConsentPlan
                || this.blockLevelProgress?.preconsent === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public hasCreatedFirstWorkingDrawing(): boolean {
        return this.lotMappedItem.hasCreatedFirstWorkingDrawing
            || this.blockLevelProgress?.workingDrawings === LOT_MILESTONE_PROGRESS_ENUM.Started;
    }

    public hasApprovedWorkingDrawing(): boolean {
        return this.lotMappedItem.hasCompletedWorkingDrawing
            || this.blockLevelProgress?.workingDrawings === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public hasPrimaryBuildingConsent(): boolean {
        return !!this.lotMappedItem.buildingConsent
            || this.blockLevelProgress?.consent === LOT_MILESTONE_PROGRESS_ENUM.Started;
    }

    public isConsentWarning(): boolean {
        return this.lotMappedItem.hasPrimaryBuildingConsentWithRfi;
    }

    public hasGrantedPrimaryBuildingConsent(): boolean {
        return this.lotMappedItem.hasGrantedPrimaryBuildingConsent
            || this.blockLevelProgress?.consent === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public getPreConsentStatusCardText(): string {
        switch (this.lotMappedItem?.latestPreConsentPlan?.status?.id) {
            case PRECONSENT_PLAN_STATUS_ENUM.Pending:
            case PRECONSENT_PLAN_STATUS_ENUM.InQueue:
            case PRECONSENT_PLAN_STATUS_ENUM.InProgress:
            case PRECONSENT_PLAN_STATUS_ENUM.OnHold:
            case PRECONSENT_PLAN_STATUS_ENUM.Rejected:
            case PRECONSENT_PLAN_STATUS_ENUM.WaitingForReview:
            case PRECONSENT_PLAN_STATUS_ENUM.DesignReviewRequired:
            case PRECONSENT_PLAN_STATUS_ENUM.InQueueForRework:
                return `${this.lotMappedItem?.latestPreConsentPlan?.revisionName} -
                        ${this.lotMappedItem?.latestPreConsentPlan?.status?.label}
                        ${!isNullOrWhiteSpace(this.lotMappedItem?.latestPreConsentPlan?.dueDate) ? '- '.concat(this.lotMappedItem?.latestPreConsentPlan?.dueDate) : ''}`;
            default:
                return '';
        }
    }

    public getPreConsentDocumentCountCardText(): string {
        if (this.lotMappedItem.preConsentRequiredDocumentCount === 1) {
            return `There is ${this.lotMappedItem.preConsentRequiredDocumentCount} document still required`;
        } else if (this.lotMappedItem.preConsentRequiredDocumentCount > 1) {
            return `There are ${this.lotMappedItem.preConsentRequiredDocumentCount} documents still required`;
        } else {
            return '';
        }
    }

    public getWorkingDrawingsCardText(): string {
        if (this.lotMappedItem?.latestWorkingDrawing?.status?.id === WORKING_DRAWING_STATUS_ENUM.Pending ||
            this.lotMappedItem?.latestWorkingDrawing?.status?.id === WORKING_DRAWING_STATUS_ENUM.InQueue ||
            this.lotMappedItem?.latestWorkingDrawing?.status?.id === WORKING_DRAWING_STATUS_ENUM.InQueueForRework ||
            this.lotMappedItem?.latestWorkingDrawing?.status?.id === WORKING_DRAWING_STATUS_ENUM.InProgress ||
            this.lotMappedItem?.latestWorkingDrawing?.status?.id === WORKING_DRAWING_STATUS_ENUM.OnHold ||
            this.lotMappedItem?.latestWorkingDrawing?.status?.id === WORKING_DRAWING_STATUS_ENUM.DesignReviewRequired ||
            this.lotMappedItem?.latestWorkingDrawing?.status?.id === WORKING_DRAWING_STATUS_ENUM.WaitingForReview
        ) {
            return `${this.lotMappedItem?.latestWorkingDrawing?.revisionName} -
             ${this.lotMappedItem?.latestWorkingDrawing?.status?.label}
               ${!isNullOrWhiteSpace(this.lotMappedItem?.latestWorkingDrawing?.dueDate) ? '- '.concat(this.lotMappedItem?.latestWorkingDrawing?.dueDate) : ''}`;
        } else if (this.lotMappedItem?.latestWorkingDrawing?.status?.id === WORKING_DRAWING_STATUS_ENUM.Rejected) {
            return `${this.lotMappedItem?.latestWorkingDrawing?.revisionName} -
            ${this.lotMappedItem?.latestWorkingDrawing?.status?.label}`;
        } else {
            return '';
        }
    }

    public getConsentStatusCardText(): string {
        if (this.lotMappedItem?.buildingConsent?.consentStatus === BUILDING_CONSENT_STATUS_ENUM.Pending ||
            this.lotMappedItem?.buildingConsent?.consentStatus === BUILDING_CONSENT_STATUS_ENUM.InQueue ||
            this.lotMappedItem?.buildingConsent?.consentStatus === BUILDING_CONSENT_STATUS_ENUM.InProgress ||
            this.lotMappedItem?.buildingConsent?.consentStatus === BUILDING_CONSENT_STATUS_ENUM.OnHold ||
            this.lotMappedItem?.buildingConsent?.consentStatus === BUILDING_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation ||
            this.lotMappedItem?.buildingConsent?.consentStatus === BUILDING_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed
        ) {
            return `${this.lotMappedItem?.buildingConsent?.consentNumber != null ? this.lotMappedItem.buildingConsent.consentNumber : 'Building Consent'} -
                ${this.lotMappedItem?.buildingConsent?.consentStatusLabel}
            ${!isNullOrWhiteSpace(this.lotMappedItem?.buildingConsent?.consentDueDate) ? '- '.concat(new DatePipe(NZ_LOCALE).transform(this.lotMappedItem?.buildingConsent?.consentDueDate, 'd MMM y')) : ''} `;
        } else {
            return '';
        }
    }

    public getRfiCardText(): string {
        const activeRfis = this.lotMappedItem.rfis.filter(x => x.status.id !== COUNCIL_RFI_STATUS_ENUM.Complete);

        if (this.lotMappedItem?.buildingConsent?.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.Approved &&
            activeRfis?.length && activeRfis?.length === 1) {
            return `RFI  ${activeRfis[0]?.revisionName} -
                ${activeRfis[0]?.status?.label}
             ${!isNullOrWhiteSpace(activeRfis[0]?.dueDate) ? '- '.concat(activeRfis[0]?.dueDate) : ''} `;

        } else if (this.lotMappedItem?.buildingConsent?.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.Approved &&
            activeRfis?.length && activeRfis?.length > 1) {
            return `There are ${activeRfis.length} RFIs`;
        } else {
            return '';
        }
    }


    public buildPillActive(): boolean {
        return this.lotMappedItem.hasBuildProgrammeTemplateApplied
            || this.blockLevelProgress?.build === LOT_MILESTONE_PROGRESS_ENUM.Started;
    }

    public buildPillComplete(): boolean {
        return this.lotMappedItem.hasBuildProgrammeTemplateApplied
            || this.blockLevelProgress?.build === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public handoverPillActive(): boolean {
        return this.lotMappedItem.hasBuildProgrammeTemplateApplied
            || this.blockLevelProgress?.handover === LOT_MILESTONE_PROGRESS_ENUM.Started;
    }

    public handoverPillComplete(): boolean {
        return this.lotMappedItem.isHandedOver
            || this.blockLevelProgress?.handover === LOT_MILESTONE_PROGRESS_ENUM.Complete;
    }

    public showStepCard(lotJobStatusEnumId: LotJobStatusEnumId): boolean {
        return (this.lotMappedItem?.jobStatus === lotJobStatusEnumId);
    }

}
