import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '@app/core/services/toast/toast.service';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, from, Observable, Subscription, concatMap, map, skipWhile } from 'rxjs';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { IPermissmissionsLookup } from '@app/shared/enums/IEnumNestedLookup';
import { orderBy } from 'lodash';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { IUserPermissionsDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { FormMode } from '@app/shared/enums/form';
import { IPermissionsMappedItem } from '@app/logic/permissions/interfaces/i.permissions.mapped';
import { IPermissionsLogicService } from '@app/logic/permissions/interfaces/i.permissions.logic-service';
import { PermissionsLogicService } from '@app/logic/permissions/permissions.logic.service';
import { IPermissionMappedItem } from '@app/logic/permissions/interfaces/i.permission.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { NonDialogFormViewDirective } from '@app/shared/base-views/non-dialog-form-view.directive';

@Component({
    selector: 'cb-permissions',
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.scss'],
    providers: [PermissionsLogicService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionsComponent extends NonDialogFormViewDirective<
IUserPermissionsDto,
IPermissionsMappedItem,
IPermissionsLogicService> implements OnInit {

    @ViewChild('permissionsForm', {}) public baseForm: NgForm;
    @Input() public userId$: BehaviorSubject<string>;
    public windowHeight: number;
    public expandedPanels: { [areaId: string]: boolean } = {};
    public mappedItem$ = new BehaviorSubject<IPermissionsMappedItem>(this.logicService.$createMappedItem());
    public allExpanded = false;
    public mappedItemClone: IPermissionsMappedItem;

    public WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;

    private _subscriptions = new Subscription();

    public set mappedItem(mappedItem: IPermissionsMappedItem) {
        mappedItem.hydrate$Permissions().then(_ => {
            this.mappedItemClone = mappedItem?.$clone();
            this.mappedItem$.next(mappedItem);
        });
    }

    public get mappedItem(): IPermissionsMappedItem {
        return this.mappedItem$.value;
    }

    public systemAreasOptions$ = this.mappedItem$.asObservable().pipe(
        skipWhile((res) => res === null),
        concatMap(_ => this._orderedSystemAreasWithPermissions$)
    );

    private _systemAreas$ = from(
        this.lookupService.SYSTEM_AREAS.toNestedLookup()
            .$promise as unknown as Observable<IPermissmissionsLookup[]>
    );

    private _orderedSystemAreasWithPermissions$ = this._systemAreas$.pipe(
        map(systemAreas => orderBy(systemAreas, systemArea => systemArea?.label?.toLowerCase())),
        map(systemAreas => systemAreas.map(area => ({
            ...area,
            permissions: this._getPermissionsForArea(area.id)
        })))
    );

    private _systemAreasOptionsBehaviourSubject$ = new BehaviorSubject<IEnumLookup<number>[]>(null);

    constructor(
        private readonly logicService: PermissionsLogicService,
        private readonly lookupService: LookupService,
        private readonly toastService: ToastService,
        private readonly dialogService: CbDialogService,
        public readonly route: ActivatedRoute,
    ) {
        super(toastService);

        this._subscriptions.add(
            this.systemAreasOptions$.subscribe(
                this._systemAreasOptionsBehaviourSubject$)
        );
    }

    public ngOnInit(): void {
        this.userId$.subscribe(id => {
            if (id !== null && id !== undefined) {
                this.logicService.$getItem(id)
                    .subOnce(result => {
                        this.mappedItem = this.logicService.$createMappedItem(result);
                    });
            }
        });
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public handleSaveSuccess(result: any): void {
        this.formMode$$ = FormMode.View;
        this.formMode = FormMode.View;
    }

    public onEditClicked(): void {
        const existingMappedItem = this.logicService.$createMappedItem(this.mappedItem.$getMappedDtoItem());
        this.mappedItem = existingMappedItem;
    }

    public onCancelClicked(): void {
        this.mappedItemClone.hydrate$Permissions()
            .then(_ => this.mappedItem$.next(this.mappedItemClone));
    }

    public explain(permission: IPermissionMappedItem, event: Event): void {
        this.mappedItem.explain(permission, event).subOnce(response => {
            let message: string;
            if (response.source === 'User') {
                message = (response.value ? 'Allowed, ' : 'Denied, ') + 'set explicity for user';
            } else if (response.source) {
                const role = response.source.substring('Group:'.length);
                message = (response.value ? 'Allowed, ' : 'Denied, ') + 'set by Role ' + role;
            } else {
                message = 'Denied - not set in any Role';
            }
            this.dialogService.simpleMessageDialog(message, `${permission.$areaLabel} - ${permission.$label}`);
        });
    }

    public setAllExpanded = (systemAreas = this._systemAreasOptionsBehaviourSubject$.value): void => {
        let expandedPanelsCount = 0;
        let panelCount = 0;
        systemAreas.forEach((value) => {
            if (this.expandedPanels[value.id]) {
                expandedPanelsCount++;
            }
            panelCount++;
        });

        if (expandedPanelsCount === panelCount) {
            this.allExpanded = true;
        } else {
            this.allExpanded = false;
        }
    };

    public async save(): Promise<void> {
        this.saveInProgress = true;
        this._preSave();
        return this.saveMethod().toPromise().then((result) => {
            this.handleNext(this.handleSaveSuccess(result));
            this.saveInProgress = false;
        });
    }

    private _preSave(): void {
        this.mappedItem.permissions = this.mappedItem.$permissions.map(permission => {
            switch (permission.$value.value) {
                case true:
                    permission.explicitAllow = true;
                    permission.inheritedAllow = null;
                    break;
                case false:
                    permission.explicitAllow = false;
                    permission.inheritedAllow = null;
                    break;
                case null:
                    permission.explicitAllow = null;
                    permission.inheritedAllow = null;
                    break;
                default:
                    permission.explicitAllow = null;
                    permission.inheritedAllow = null;
                    break;
            }
            return permission.$getMappedDtoItem();
        });
    }

    private _getPermissionsForArea(areaId: number): IPermissionMappedItem[] {
        return this.mappedItem?.$permissions?.filter(permission => permission.area === areaId);
    }
}
