import { map } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { remove } from 'lodash';
import { FormMode } from '@app/shared/enums/form';
import { ComplianceRegistrationLogicService, IComplianceRegistrationDto } from '@app/logic/compliance-registration';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { ISupplyTypeTreeDto } from '@app/logic/supply-types/interfaces/i.supply-type-tree.dto';
import { ISupplyTypeTreeMappedItem } from '@app/logic/supply-types/interfaces/i.supply-type-tree.mapped';
import { ISupplyTypesTreeLogicService } from '@app/logic/supply-types/interfaces/i.supply-types-tree.logic.service';
import { SupplyTypesLogicService } from '@app/logic/supply-types/supply-types.logic.service';
import { SupplyTypesTreeLogicService } from '@app/logic/supply-types/supply-types-tree.logic.service';
import { ISupplyTypeDto } from '@app/logic/supply-types/interfaces/i.supply-type.dto';

@Component({
    templateUrl: './supply-type-dialog.component.html',
    styleUrls: ['./supply-type-dialog.component.scss'],
    providers: [
        ComplianceRegistrationLogicService,
        SupplyTypesLogicService,
        SupplyTypesTreeLogicService,
    ]
})
export class SupplyTypeDialogComponent extends BaseDialogFormViewDirective<Partial<ISupplyTypeTreeDto>, ISupplyTypeTreeMappedItem, ISupplyTypesTreeLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '50%';

    public readonly columns = ['Compliance Registrations', ''];
    public loaded = true;
    public activeChildren: boolean;
    public searchText: string;
    public availableSupplyTypes: ISupplyTypeDto[];
    public complianceRegs: IComplianceRegistrationDto[];
    public selectedCompReg: IComplianceRegistrationDto;
    public complianceRegsChanged = false;
    public autocompleteLoading = false;

    constructor(
        public readonly dialogRef: MatDialogRef<SupplyTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: ISupplyTypeTreeMappedItem },
        public readonly toastService: ToastService,
        private readonly complianceRegLogicService: ComplianceRegistrationLogicService,
        private readonly supplyTypeLogicService: SupplyTypesLogicService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }

    public ngOnInit(): void {
        this.formMode = this.mappedItem?.id > 0 ? FormMode.Edit : FormMode.Add;

        this.setAvailableSupplyTypes();
        this.setComplianceRegistrations();
        this.setHasActiveChildren();
    }

    public setHasActiveChildren(): void {
        if (this.mappedItem.mappedChildren && this.mappedItem.mappedChildren.length > 0) {
            for (const child of this.mappedItem.mappedChildren) {
                if (child.isActive) {
                    this.activeChildren = true;
                    break;
                }
            }
        }
    }

    public setComplianceRegistrations(): void {
        this.complianceRegLogicService.$getList()
            .pipe(
                map(x => x.filter(y => y.isActive))
            )
            .subOnce(results => this.complianceRegs = results);
    }

    public setAvailableSupplyTypes(): void {
        this.supplyTypeLogicService.$getList(false)
            .pipe(
                map(x => x.filter(y => !y.parentId &&
                    (!this.mappedItem?.id || y.id !== this.mappedItem?.id)))
            )
            .subOnce(results => this.availableSupplyTypes = results);
    }

    public displayWith(lookup: IComplianceRegistrationDto): string | null {
        return lookup ? lookup.label : null;
    }

    public querySearch = (searchText: string): IComplianceRegistrationDto[] => {
        const unusedRegs = this.complianceRegs?.filter(x => !this.mappedItem.requiredComplianceRegistrations.map(req => req.id).includes(x.id)) ?? [];
        if (isNullOrWhiteSpace(searchText)) {
            return unusedRegs;
        }
        return unusedRegs.filter((item: IComplianceRegistrationDto) => {
            return item.label.toLowerCase().includes(searchText.toLowerCase());
        });
    };

    public disableAddCompReg(): boolean {
        if (this.selectedCompReg) {
            return this.mappedItem.requiredComplianceRegistrations.findIndex(x => x.id === this.selectedCompReg.id) !== -1;
        }
        return true;
    }

    public addCompReg(): void {
        if (!this.selectedCompReg) {
            return;
        }
        this.autocompleteLoading = true;
        this.mappedItem.requiredComplianceRegistrations.push(this.selectedCompReg);
        this.selectedCompReg = null;
        this.complianceRegsChanged = true;
        setTimeout(() => {
            this.autocompleteLoading = false;
        });
    }

    public removeCompReg(compRegId): void {
        this.autocompleteLoading = true;
        remove(this.mappedItem.requiredComplianceRegistrations, { id: compRegId });
        this.complianceRegsChanged = true;
        setTimeout(() => {
            this.autocompleteLoading = false;
        });
    }
}
