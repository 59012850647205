import { IDocumentEntityMappedItem } from './../../../../../logic/documents/interfaces/i.document.mapped';
import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DatePipe } from '@angular/common';


interface IData {
    isEdit: boolean;
    documentEntityMappedItem: IDocumentEntityMappedItem;
}

@Component({
    selector: 'cb-document-info-dialog',
    templateUrl: './document-info-dialog.html',
    styleUrls: ['./document-info-dialog.scss']
})
export class DocumentInfoDialogComponent {
    public static readonly MIN_WIDTH = '400px';
    // #region INITIALIZER

    constructor(
        private readonly datePipe: DatePipe,
        public readonly dialogRef: MatDialogRef<DocumentInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IData) { }

    // #endregion


    // #region BUTTON's ACTION

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public onSubmit(): void {
        this.data.documentEntityMappedItem.saveDocument()
            .subOnce((response) => {
                this.dialogRef.close(response);
            });
    }

    // #endregion


    // #region  DATA GETTERS / METHODS

    public getUserDateUpdatedInfo(): string {
        return `${this.data.documentEntityMappedItem.document.updatedByName} on ${this.datePipe.transform(
            this.data.documentEntityMappedItem.document.updatedDate,
            'dd MMM yyyy hh:mm:ss a'
        )}`;
    }

    public getUserDateCreatedInfo(): string {
        return `${this.data.documentEntityMappedItem.document.updatedByName} on  ${this.datePipe.transform(
            this.data.documentEntityMappedItem.document.updatedDate,
            'dd MMM yyyy hh:mm:ss a'
        )}`;

    }

    public hideDescriptionField(): boolean {
        return (!this.data.isEdit && this.data.documentEntityMappedItem.document.description.length < 1);
    }

    // #endregion

}
