<div class="flex-col">
    <div class="flex-row">
        <span class="mat-body-2 cb-margin-top-3x cb-margin-bottom-3x flex">Add Specific Items that the Designer needs to complete as part of this Working Drawing</span>
        <div>
            <button mat-raised-button
                    color="primary"
                    (cbClick)="addItems()"
                    class="cb-margin-top-3x cb-margin-bottom-3x">
                Add Item
            </button>
        </div>
    </div>
    <div style="position: relative;">
        <mat-divider></mat-divider>
    </div>
    <div *ngFor="let item of mappedItem.items">
        <div *ngIf="!item.isDeleted">
            <cb-request-working-drawing-item-component [item]="item"
                                                       [mappedItem]="mappedItem">

            </cb-request-working-drawing-item-component>

            <!-- <cb-request-schemes-item [designerView]="false"
                                     [item]="item"
                                     [mappedItem]="mappedItem"></cb-request-schemes-item> -->
            <div style="position: relative;">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <cb-info-message class="cb-margin"
                     *ngIf="!hasItems()"
                     message="Add at least one Item for this Working Drawing"
                     icon="warning"
                     iconClass="cb-warn">
    </cb-info-message>
</div>
