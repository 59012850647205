
import { IBuildStageMappedItem } from './interfaces/i.build-stage.mapped';
import { BaseMappedItem } from '../base/base-mapped-item';
import { IBuildStageDto } from './interfaces/i.build-stage.dto';
import { IBuildStagesLogicService } from './interfaces/i.build-stages-logic.service';
import { DtoProp } from '../base/dto-prop.decorator';

export class BuildStageMappedItem
    extends BaseMappedItem<IBuildStageDto, IBuildStageMappedItem, IBuildStagesLogicService>
    implements IBuildStageMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public code: string;
    @DtoProp public label: string;
    @DtoProp public isActive: boolean;
    @DtoProp public isPreconsentStage: boolean;
    @DtoProp public sortOrder: number;
    @DtoProp public constructionManagerRole: number;

    constructor(
        sourceData: IBuildStageDto,
        logicService: IBuildStagesLogicService
    ) {
        super(sourceData, logicService, BuildStageMappedItem);
    }
}
