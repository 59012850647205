import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbTableModule} from 'cb-hub-lib';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {BrowserModule} from '@angular/platform-browser';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatMenuModule} from '@angular/material/menu';
import {SystemLogsComponent} from './system-logs/system-logs.component';
import {SystemLogViewDialogComponent} from './system-log-view-dialog/system-log-view-dialog.component';


@NgModule({
    declarations: [SystemLogsComponent, SystemLogViewDialogComponent],
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        CbHubTableModule,
        CbTableModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        CbFormsModule,
        MatTabsModule,
        CbDisplayValueModule,
        CbDialogModule,
        MatChipsModule,
        MatMenuModule,
    ],
    exports: [
        SystemLogsComponent
    ],
})
export class SystemLogsModule { }
