export enum ProjectReleaseStagePermissionEnum {
    None = 0x00,
    ReleaseStageCanView = 0x01 << 0,
    ReleaseStageCanCreate = 0x01 << 1,
    ReleaseStageCanEdit = 0x01 << 2,
    ReleaseStageCanCancel = 0x01 << 3,
    ReleaseTaskCanView = 0x01 << 4,
    ReleaseTaskCanAdd = 0x01 << 5,
    ReleaseTaskCanComplete = 0x01 << 6,
    ReleaseNoteCanView = 0x01 << 7,
    ReleaseNoteCanAdd = 0x01 << 8,
    ReleaseNoteCanEdit = 0x01 << 9,
    ReleaseNoteCanDelete = 0x01 << 10,
    ReleaseDocumentCanView = 0x01 << 11,
    ReleaseDocumentCanUpload = 0x01 << 12,
    ReleaseDocumentCanDelete = 0x01 << 13,
    ReleaseDocumentCanEdit = 0x01 << 14,
    ReleaseDocumentCanReview = 0x01 << 15,
    ReleaseHistoryCanView = 0x01 << 16,
    LotRecordCanView = 0x01 << 17,
    LotRecordCanEdit = 0x01 << 18,
    LotsCanImport = 0x01 << 19,
    LotSpecCanCreate = 0x01 << 20,
    LotSpecCanApply = 0x01 << 21,
    LotCanRelease = 0x01 << 22,
    LotCanSetExternalSale = 0x01 << 23,
    LotCanSwitchReleaseStage = 0x01 << 24,
    ReleaseCanViewDesignSummary = 0x01 << 25,
    ReleaseCanViewCostSummary = 0x01 << 26,
    LotCanRequestImages = 0x01 << 27,
    LotCanRequestScheme = 0x01 << 28,
    LotCanRequestPricing = 0x01 << 29,
    ReleaseCanManageDefaultInstallers = 0x01 << 30,
    ReleaseTaskCanEdit = ReleaseCanManageDefaultInstallers * 2,
    CanRemoveLotFromRelease = ReleaseTaskCanEdit * 2,
    ReleaseCanManageDefaultSuppliers = CanRemoveLotFromRelease * 2
}
