import { AllManualOrdersModule } from './teams/all-teams/all-manual-orders/all-manual-orders.module';
import { ClientAccountModule } from './client-accounts/client-account.module';
import { ClientSalesModule } from './client-sales/client-sales.module';
import { ContactsModule } from './contacts/contacts.module';
import { LeadsModule } from './leads/leads.module';
import { LotModule } from './lot/lot.module';
import { ManualOrdersModule } from './manual-orders/manual-orders.module';
import { NgModule } from '@angular/core';
import { ProductsModule } from './products/products.module';
import { PurchaseOrdersModule } from './purchase-orders/purchase-orders.module';
import { SettingsModule } from './settings/settings.module';
import { TempLotsModule } from './temp-lots/temp-lots.module';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { SonarTableModule } from './sonar-table/sonar-table.module';
import { HouseAndLandModule } from './house-and-land/house-and-land.module';
import { ProjectsModule } from './projects/projects.module';
import { CbUsersModule } from './users/users.module';
import { SpecTemplatesModule } from './spec-templates/spec-templates.module';
import { ScheduleTemplateModule } from './schedule-template/schedule-template.module';
import { SecurityRolesModule } from './security-role/security-role.module';
import { BuildProgrammeTemplatesModule } from './build-programme-templates/build-programme-templates.module';
import { CbQsTeamModule } from './teams/qs-team/qs-team.module';
import { CbAllTeamsModule } from './teams/all-teams/all-teams.module';
import { RevisionReportModule } from './teams/design-team/revision-report/revision-report.module';
import { DesignTeamWorkLoadDialogModule } from './teams/design-team/work-load-dialog/design-team-work-load-dialog.module';
import { CbDesignTeamModule } from './teams/design-team/design-team.module';
import { BusinessAccountsModule } from './business-accounts/business-accounts.module';
import { ConnectionsModule } from './connections/connections.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { NotificationsModule } from './notifications/notifications.module';
import { SalesDashboardModule } from './sales-dashboard/sales-dashboard.module';
import { ContractGenerationModule } from './contract-generation/contract-generation.module';
import { OpenPdfModule } from './open-pdf/open-pdf.module';
import {XpathHelperModule} from "@app/views/xpath-helper-page/xpath-helper.module";
import { WipModule } from '@app/views/wip/wip.module';

// export all view modules used for a root route here
@NgModule({
    exports: [
        SecurityRolesModule,
        SettingsModule,
        LeadsModule,
        ProjectsModule,
        UserSettingsModule,
        CbUsersModule,
        CbQsTeamModule,
        CbDesignTeamModule,
        CbAllTeamsModule,
        PurchaseOrdersModule,
        ManualOrdersModule,
        ClientSalesModule,
        TempLotsModule,
        LotModule,
        XpathHelperModule,
        ProductsModule,
        AllManualOrdersModule,
        RevisionReportModule,
        ContactsModule,
        ClientAccountModule,
        SonarTableModule,
        HouseAndLandModule,
        WipModule,
        ScheduleTemplateModule,
        DesignTeamWorkLoadDialogModule,
        SpecTemplatesModule,
        BuildProgrammeTemplatesModule,
        BusinessAccountsModule,
        ConnectionsModule,
        DashboardModule,
        NotificationsModule,
        SalesDashboardModule,
        ContractGenerationModule,
        OpenPdfModule
    ]
})
/** Exports all root view modules for importing in the AppRootModule */
export class RootViewsModule { }
