import {Component, Input} from '@angular/core';
import {IPricingRevisionMappedItem} from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import {BehaviorSubject} from 'rxjs';
import {PricingRevisionsLogicService} from '@app/logic/pricing-revisions/pricing-revisions.logic.service';
import {IPricingRevisionGrossMarginDto, IPricingRevisionMarginHistoryDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-costs-margins',
    templateUrl: './lot-costs-margins.component.html',
    styleUrls: ['./lot-costs-margins.component.scss']
})
export class LotCostsMarginsComponent {

    public isEdit = false;
    public pricingRevisionMappedItem$ = new BehaviorSubject<IPricingRevisionMappedItem>(null);

    @Input()
    public set pricingRevisionMappedItem(mappedItem: IPricingRevisionMappedItem) {
        this.pricingRevisionMappedItem$?.next(mappedItem);

        this.pricingRevisionsLogicService.getGrossMargin(mappedItem.id).subOnce(gm => {
            this.grossMargin = gm || 0;
            this.updateGrossMarginPercent();
        });

        this.loadPricingRevisionsHistory(mappedItem.id);

    }

    public loadPricingRevisionsHistory(id: number): void {
        this.pricingRevisionsLogicService.getRevisionPriceMarginHistory(id).subOnce((_historyItems) => {
            this.historyItems$$.next(_historyItems);
        });
    }

    public get pricingRevisionMappedItem(): IPricingRevisionMappedItem {
        return this.pricingRevisionMappedItem$?.value;
    }

    public originalGrossMargin: number;
    public grossMargin: number;
    public grossMarginPercent: number;

    public historyItems$$: BehaviorSubject<IPricingRevisionMarginHistoryDto[]> = new BehaviorSubject(null); // PricingRevisionMarginHistoryDto
    public readonly displayedColumns: string[] = ['Date', 'Updated By', 'Gross Margin $', 'Gross Margin %'];


    constructor(public pricingRevisionsLogicService: PricingRevisionsLogicService) {
    }

    public updateGrossMargin(grossMargin: number): void {
        this.grossMargin = grossMargin;
        this.updateGrossMarginPercent();
    }

    public updateGrossMarginPercent(): void {
        let val = 0.00;
        if (this.grossMargin > 0 && this.pricingRevisionMappedItem.buildPrice > 0) {
            val = (this.grossMargin / this.pricingRevisionMappedItem.buildCost) * 100;
        }
        this.grossMarginPercent = Number(val.toFixed(2));
    }

    public edit(): void {
        this.isEdit = true;
        this.originalGrossMargin = this.grossMargin;
    }

    public cancelEdit(): void {
        this.isEdit = false;
        this.grossMargin = this.originalGrossMargin;
    }

    public saveMargin(): void {
        if (this.grossMargin) {
            this.pricingRevisionsLogicService.updateGrossMargin(
                this.pricingRevisionMappedItem.id,
                {grossMargin: this.grossMargin} as IPricingRevisionGrossMarginDto, true
            ).subOnce((x) => {
                this.isEdit = false;
                this.loadPricingRevisionsHistory(this.pricingRevisionMappedItem.id);
            });
        }
    };

}
