<div class="cb-alert-card cb-agree-to-condition flex-row"
     [ngClass]="{'warning' : !value, 'disabled': disabled && !value }"
     style="padding-left: 10px;">
    <mat-checkbox class="flex"
                  *ngIf="!readonly"
                  [color]="color()"
                  [ngClass]="{'read-only': readonly, 'mat-form-field': !slim}"
                  [name]="internalName"
                  [(ngModel)]="value"
                  [required]="required"
                  [indeterminate]="indeterminate"
                  [labelPosition]="labelPosition"
                  [disabled]="disabled">
        {{label}}
        <ng-content></ng-content>
    </mat-checkbox>
    <mat-checkbox class="flex"
                  *ngIf="readonly"
                  [ngClass]="{'read-only': readonly, 'mat-form-field': !slim}"
                  [checked]="value"
                  [disableRipple]="true"
                  [disabled]="true">
        {{label}}
        <ng-content></ng-content>
    </mat-checkbox>
</div>
