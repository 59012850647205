import { ILotDto, RESOURCE_CONSENT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { LotConsentViewService } from '../../service/lot-consent-view.service';
import { IResourceConsentMappedItem } from '@app/logic/lot-consents';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cb-manage-resource-consent',
    templateUrl: './manage-resource-consent.component.html',
    styleUrls: ['./manage-resource-consent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageResourceConsentComponent implements OnInit, OnDestroy {
    @Input() public lotDto: ILotDto;
    @Output() public reloadLotMappedItem = new EventEmitter();

    public elapsedTime: string;
    public hasResourceConsentDocument = false;
    public selectedTabIndex = 0;
    public councilRfiCount = 0;
    public documentCount = 0;

    private _subsription: Subscription;

    public get resourceConsent(): IResourceConsentMappedItem {
        return this.lotConsentViewService?.resourceConsent ?? {} as IResourceConsentMappedItem;
    }

    public readonly RESOURCE_CONSENT_STATUS_ENUM = RESOURCE_CONSENT_STATUS_ENUM;

    constructor(
        public readonly lotConsentViewService: LotConsentViewService,
        private readonly cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this._subsription = this.resourceConsent.$updated.subscribe(() => {
            this.cdRef.detectChanges();
        });
        this.getElapsedTime();
    }
    public ngOnDestroy(): void {
        this._subsription?.unsubscribe();
    }


    public getElapsedTime(): void {
        this.resourceConsent.getElapsedTime().subOnce(x => this.elapsedTime = x);
    }
}
