import { Component, Input } from '@angular/core';

@Component({
    selector: 'cb-alert-card-new',
    templateUrl: './alert-card.component.html',
    styleUrls: ['./alert-card.component.scss']
})
export class AlertCardComponent {

    @Input() public type: 'info' | 'warning' | 'success' | 'error';
    @Input() public message: string;
    @Input() public removeElevation = false;


    constructor() { }

    public get alertClass(): string {
        switch (this.type) {
            case 'info':
                return 'mat-accent';
            case 'warning':
                return 'mat-warn';
            case 'success':
                return 'mat-primary';
            case 'error':
                return 'mat-error';
            default:
                return '';
        }
    }

    public get icon(): string {
        switch (this.type) {
            case 'info':
                return 'info';
            case 'warning':
                return 'warning';
            case 'success':
                return ' check_circle';
            case 'error':
                return 'error';
            default:
                return '';
        }
    }


}
