import { Component, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IStandardPlanStyleDto } from '@app/logic/styles/interfaces/i.style.dto';
import { IStyleMappedItem } from '@app/logic/styles/interfaces/i.style.mapped';
import { IStylesLogicService } from '@app/logic/styles/interfaces/i.style.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';

@Component({
    selector: 'cb-style-dialog',
    templateUrl: './style-dialog.component.html',
    styleUrls: ['./style-dialog.component.scss']
})
export class StyleDialogComponent extends BaseDialogFormViewDirective<IStandardPlanStyleDto, IStyleMappedItem, IStylesLogicService> {

    constructor(
        public readonly dialogRef: MatDialogRef<StyleDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: IStyleMappedItem }
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }
}
