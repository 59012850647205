import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ILotMappedItem} from '@app/logic/lots';
import {ILotDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LOTS_ROUTE} from '../../lots-route.constant';
import {LotBuildOverheadCostsPermissions} from '@app/core/permissions';

@Component({
    selector: 'cb-lot-build-area',
    templateUrl: './lot-build-area.component.html',
    styleUrls: ['./lot-build-area.component.scss'],
})
export class LotBuildAreaComponent implements OnDestroy {

    public readonly LOTS_ROUTE = LOTS_ROUTE;
    public selectedTabIndex: number;

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    public lotDto$$: BehaviorSubject<ILotDto> = new BehaviorSubject(null);

    public _subscription: Subscription;

    @Output() public reloadLotMappedItem = new EventEmitter();

    constructor(public readonly lotBuildOverheadCostsPermissions: LotBuildOverheadCostsPermissions) {
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    @Input()
    public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {

            this.lotMappedItem$$.next(mappedItem);

            this._subscription?.unsubscribe();
            this._subscription = mappedItem.$updated.subscribe(() => {
                const lot = mappedItem.$getMappedDtoItem();
                this.lotDto$$.next(lot);
            });
            this.lotDto$$.next(mappedItem.$getMappedDtoItem());
        }
    }

    public get lotDto(): ILotDto {
        return this.lotDto$$?.value;
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public onTabChanged(selectedTabIndex: number): void {
        this.selectedTabIndex = selectedTabIndex;
    }

}
