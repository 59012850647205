import { Component, ContentChild, EventEmitter, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { DragulaService } from 'ng2-dragula';
import { Observable, Subscription } from 'rxjs';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';
import { moveItemInArray } from '@angular/cdk/drag-drop';

interface IItemDto { id: number;[key: string]: any }
const DRAGULA_IGNORE = 'cb-table-footer-row';

/**
 * Component that is used to build a collection of anything, using any form controls to build said collection.
 * Displays the collection in a cb-table.
 *
 * Sample usage:
 *
        <cb-collection-builder [displayedColumns]="displayedColumns"
                               [(newCollectionItem)]="newCollectionItem"
                               (collectionItemAdded)="collectionItemAdded($event)">
            <ng-container addCollectionItemRow>
                <td>
                    <cb-input type="text"
                              name="newItemExternalId"
                              [(ngModel)]="newCollectionItem.externalReferenceId">
                    </cb-input>
                </td>
                <td>
                    <cb-select name="newItemExternalSystem"
                               [(ngModel)]="newCollectionItem.externalSystem"
                               [options]="externalSystemList">
                    </cb-select>
                </td>
            </ng-container>
            <ng-template #collectionItemColumns
                         let-collectionItem>
                <cb-td-text [value]="collectionItem.externalReferenceId">
                </cb-td-text>
                <cb-td-enum [value]="collectionItem.externalSystem"
                            [enum]="EXTERNAL_SYSTEM_ENUM">
                </cb-td-enum>
            </ng-template>
        </cb-collection-builder>
 */
@Component({
    selector: 'cb-collection-builder',
    templateUrl: './collection-builder.component.html',
    styleUrls: ['./collection-builder.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(CollectionBuilderComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class CollectionBuilderComponent extends BaseFormComponentDirective implements OnDestroy {
    @Input() public displayedColumns: string[];
    @Input() public newCollectionItem: IItemDto;
    @Input() public addButtonDisabled: boolean;
    @Output() public collectionItemAdded = new EventEmitter();
    @Input() public collection: Array<IItemDto>;
    @Output() public collectionItemRemoved = new EventEmitter();

    @ContentChild('collectionItemColumns', { static: false }) public collectionItemColumns: TemplateRef<any>;

    public filteredOptions: Observable<IItemDto[]>;
    public COLLECTION_ITEMS: string;

    constructor(private readonly dragulaService: DragulaService) {
        super();
    }

    public addItem(): void {
        if (this.newCollectionItem) {
            this.collection = this.collection ? [...this.collection, this.newCollectionItem] : [this.newCollectionItem];
        }
        this.collectionItemAdded.emit(this.collection);
    }

    public removeItem(index: number): void {
        const removedItem = this.collection[index];
        this.collection.splice(index, 1);
        this.collectionItemRemoved.emit({ collection: this.collection, removedItem });
    }

    /* Dragula content is all at the bottom so it doesn't overpower the whole component.
       ***Dragula is optional***
    */
    @Input() public reorderingEnabled: boolean;
    @Output() public readonly sortOrderChanged = new EventEmitter<{ [collectionItemId: number]: number }>();
    @Input() public sortOrderValueProp: string;
    public subscriptions = new Subscription();
    public dragulaModel: number[] = [];

    private _dragulaId: string;
    @Input() public set dragulaId(newId: string) {
        this.dragulaService.destroy(this._dragulaId);
        this._dragulaId = newId;
        if (this._dragulaId) {
            this.setupDragula();
        }
    }

    public get dragulaId(): string {
        return this._dragulaId;
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.dragulaService.destroy(this._dragulaId);
    }

    private setDragulaModel(): void {
        this.dragulaModel = this.collection?.map(x => x.id) ?? [];
    }

    private setupDragula(): void {
        this.dragulaService.destroy(this._dragulaId);
        this.dragulaService.createGroup(
            this._dragulaId,
            {
                removeOnSpill: false,
                accepts: (el: Element) => this.reorderingEnabled && !el.classList.contains(DRAGULA_IGNORE),
                moves: (el: Element) => this.reorderingEnabled && !el.classList.contains(DRAGULA_IGNORE),
                revertOnSpill: true
            }
        );

        this.subscriptions.add(
            this.dragulaService.dropModel(this._dragulaId)
                .subscribe(
                    ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                        if (sourceIndex === targetIndex) {
                            return;
                        }
                        moveItemInArray(this.collection, sourceIndex, targetIndex);
                        const sortOrders: { [collectionItemId: number]: number } = {};
                        this.collection.forEach((x, i) => {
                            x[this.sortOrderValueProp] = i + 1;
                            sortOrders[x.id] = x[this.sortOrderValueProp];
                        });
                        this.setDragulaModel();
                        this.sortOrderChanged.emit(sortOrders);
                    }
                )
        );
    }
}
