import {Observable} from 'rxjs';
import {takeOne} from 'cb-hub-lib';
import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';
import {CouncilRfiLogicService, ICouncilRfiSearchDto} from '@app/logic/council-rfis';
import {IDesignTeamCounfilRfiSearch} from '../user-cache/user-cache-areas';

@Injectable()
export class CouncilRfiSearchService extends SearchService {

    constructor(
        public readonly councilRfiLogic: CouncilRfiLogicService,
    ) {
        super();
    }

    public getResults(query: string, currentpage: number): Observable<ICouncilRfiSearchDto[]> {
        return new Observable<ICouncilRfiSearchDto[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;

                const resultsObservable = this.councilRfiLogic.$getSearchList({
                    query,
                    currentpage,
                    ...this.extraSearchParams
                } as unknown as IDesignTeamCounfilRfiSearch);

                if (!resultsObservable) {
                    return;
                }

                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<ICouncilRfiSearchDto>(x);
                        subscriber.next(this.searchResults$.value as ICouncilRfiSearchDto[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as ICouncilRfiSearchDto[]);
                subscriber.complete();
            }
        });
    }

    public readonly doSearch = (query: string, page: number): Observable<ICouncilRfiSearchDto[]> => {
        this.searchResultsLoaded = false;
        return this.getResults(query, page).pipe(takeOne());
    };
}
