import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import { IPreConsentDto, IPreConsentPlanDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { DtoProp } from '../base/dto-prop.decorator';
import { IPreConsentMappedItem } from './interfaces/i.pre-consent.mapped';
import { IPreConsentsLogicService } from './interfaces/i.pre-consents.logic.service';

export class PreConsentMappedItem
    extends BaseMappedItem<IPreConsentDto, IPreConsentMappedItem, IPreConsentsLogicService>
    implements IPreConsentMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public lotId: number;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public preConsentPlans: IPreConsentPlanDto[];

    constructor(
        sourceData: IPreConsentDto,
        logicService: IPreConsentsLogicService
    ) {
        super(sourceData, logicService, PreConsentMappedItem);
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }


}
