import { Injectable, Injector } from '@angular/core';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { cleanObject } from 'cb-hub-lib';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IVendorDto } from './interfaces/i.vendor.dto';
import { IVendorMappedItem } from './interfaces/i.vendor.mapped';
import { IVendorsLogicService } from './interfaces/i.vendors.logic.service';
import { VendorMappedItem } from './vendor.mapped';

export type VendorSearchParams = {
    query: string;
    currentPage: number;
};


@Injectable()
export class VendorsLogicService
    extends BaseLogicService<IVendorDto, IVendorMappedItem>
    implements IVendorsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('vendors', VendorMappedItem);
    }

    public $getSearchList(params: { currentPage: number; query: string }): Observable<any> {
        if (params.query) {
            return this.$http.get<any>(`${this.$baseUri}/search`, params);
        }
    }

    public search(params: Partial<VendorSearchParams>, ignoreEmptyQueries = true): Observable<ISearchResult<IVendorDto>> {
        if (ignoreEmptyQueries && (params.query == null || params.query.trim().length < 1)) {
            return;
        }
        return this.$http
            .get<ISearchResult<IVendorDto>>(`${this.$baseUri}/search`, cleanObject(params));
    }
}
