import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'cb-view-image',
    templateUrl: './view-image.component.html',
    styleUrls: ['./view-image.component.scss']
})
export class ViewImageComponent {
    public static readonly MIN_WIDTH = '400px';

    constructor(
        public readonly dialogRef: MatDialogRef<ViewImageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,) { }
}
