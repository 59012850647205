import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';


@Directive({
    selector: '[cbDragDrop]'
})
export class DragDropDirective {
    @Output() public onFileDropped = new EventEmitter<any>();

    @HostBinding('style.background-color') private background = '#efefef';
    @HostBinding('style.opacity') private opacity = '1';


    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#F7f7f7';
        this.opacity = '1';
    }
    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#efefef';
        this.opacity = '1';
    }
    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#F7f7f7';
        this.opacity = '1';
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files);
        }

    }

}
