import {IBaseMappedItem} from './interfaces/i.base-mapped-item';
import {IBaseMappedItemPrivate} from './interfaces/i.base-mapped-item-private';

/** ***For use with mapped items only*** \
 * Defines a property that can be mapped from a dto into a mapped item. \
 * These properties should match those in the dtos
 * i.e. all properties from the interface ```IAddressRegionDto``` should be decorated with ```@DtoProp``` on ```AddressRegionMappedItem```
 */
export const DtoProp = <Target extends IBaseMappedItem<any, any, any>>(target: Target, key: string | symbol) => {
    const exposedTarget = target as unknown as IBaseMappedItemPrivate;
    if (exposedTarget.$dtoPropKeys == null) {
        (exposedTarget.$dtoPropKeys as string[]) = [];
    }

    exposedTarget.$dtoPropKeys.push(key.toString());
};
