<section class="main-container" (scroll)="onScroll($event)">
    <table class="scroll-container mat-elevation-z8" mat-table [dataSource]="dataSource" >

        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">No.</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.position }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="constructionManager">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Construction Manager</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center" title="{{ element.constructionManager }}">
                    {{ element.constructionManager | cbTruncate:maxLength }}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="jobNumber">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Job Number</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">
                    <a (click)="onJobNumberClick(element.id)" class="job-number-link">{{ element.jobNumber }}</a>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="lotNumber">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Lot Number</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.lotNumber }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="jobStatus">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Job Status</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.jobStatus }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="projectReleaseStage">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Release Stage</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center" title="{{ element.projectReleaseStage }}">
                    {{ element.projectReleaseStage | cbTruncate:maxProjectLength }}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Project Name</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center" title="{{ element.projectName }}">
                    {{ element.projectName | cbTruncate:maxLength }}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Address</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center" title="{{ element.address }}">
                    {{ element.address | cbTruncate:maxLength}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="contractType">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Contract Type</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.contractType }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="buildType">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Build Type</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.buildType }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Client</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center" title="{{ element.client }}">
                    {{ element.client | cbTruncate:maxLength}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="buildingConsultant">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Building Consultant</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center" title="{{ element.buildingConsultant }}">
                    {{ element.buildingConsultant | cbTruncate:maxLength}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="titleDate">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Title Date</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.titleDate | date:'d MMM y' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="unconditionalDate">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Unconditional Date</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.unconditionalDate | date:'d MMM y' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="titleStatus">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Title Status</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.titleStatus }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="consentLodgedDate">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Consent Lodged Date</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.consentLodgedDate | date:'d MMM y' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="consentDueOutDate">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Consent Due Out Date</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.consentDueOutDate | date:'d MMM y' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="consentApprovedDate">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap">Consent Approved Date</div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center">{{ element.consentApprovedDate | date:'d MMM y' }}</div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" class="header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</section>
