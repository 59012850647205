<cb-dialog class="flex-col"
           dialogHeading="User Details">
    <div class="cb-margin-bottom-2x start-center flex-row">
        <div mat-card-avatar
             class="cb-card-avatar-icon cb-light-grey cb-margin-right-2x"
             style="height: auto; width: auto">
            <mat-icon style="transform: scale(2);">
                person
            </mat-icon>
        </div>
        <div class="flex-col">
            <h1 class="mat-body-2 cb-margin-bottom-nil">{{fullName}}</h1>
            <span class="mat-body-1 cb-margin-bottom-nil">{{dto.jobTitle}}</span>
        </div>
    </div>
    <div class="flex-col flex">
        <cb-display-value-new label="Mobile Phone"
                              [value]="dto.phoneMobile">
         </cb-display-value-new>
        <cb-display-value-new label="Email"
                              [value]="dto.email">
         </cb-display-value-new>
        <div style="position: relative"
             class="cb-margin-bottom-2x"
             style="margin-right: -16px; margin-left: -16px">
            <mat-divider></mat-divider>
        </div>
        <cb-display-value-new label="Company"
                              [value]="dto.company">
         </cb-display-value-new>
        <cb-display-value-new label="Primary Business Entity"
                              [value]="dto.region">
         </cb-display-value-new>
    </div>
    <div style="position: relative; margin-right: -16px; margin-left: -16px">
        <mat-divider></mat-divider>
    </div>
    <footer class="end-center flex-row">
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </footer>
</cb-dialog>
