import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DesignSchemePermissions} from '@app/core/permissions';
import {IDocumentPermissions} from '@app/core/permissions/base.permissions';
import {ToastService} from '@app/core/services/toast/toast.service';
import {IDesignSchemeMappedItem} from '@app/logic/design-schemes';
import {IDesignSchemesLogicService} from '@app/logic/design-schemes/interfaces/i.design-schemes-logic.service';
import {ILotMappedItem} from '@app/logic/lots';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {
    DESIGN_SCHEME_STATUS_ENUM,
    DOCUMENT_GROUP_CODES_CONST,
    IDesignRequirementItemDto,
    IDesignSchemeDto,
    IDesignSchemeLineDto,
    SYSTEM_AREA_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import {DesignSchemeRejectDialogComponent} from '../../manage/reject-dialog/reject-dialog.component';

@Component({
    selector: 'cb-complete-scheme-dialog',
    templateUrl: './complete-scheme-dialog.component.html',
    styleUrls: ['./complete-scheme-dialog.component.scss']
})
export class CompleteSchemeDialogComponent extends BaseDialogFormViewDirective<IDesignSchemeDto, IDesignSchemeMappedItem, IDesignSchemesLogicService> {
    public static readonly MIN_WIDTH = '80%';

    public get mappedItem(): IDesignSchemeMappedItem {
        return this.data.mappedItem;
    }

    public readonly DESIGN_SCHEME_DOC_GROUP = DOCUMENT_GROUP_CODES_CONST.DESIGN_SCHEME_DOC_GROUP;
    public readonly systemArea = SYSTEM_AREA_ENUM.DesignScheme;
    private documentPermissions: IDocumentPermissions;

    constructor(
        public readonly dialogRef: MatDialogRef<CompleteSchemeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IDesignSchemeMappedItem;
            lot: ILotMappedItem;
        },
        private readonly designSchemePermissions: DesignSchemePermissions,
        private readonly cbDialog: CbDialogService,
        toastService: ToastService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.documentPermissions = this.designSchemePermissions.getDocumentPermissions();
        this.mappedItem.$reload().subOnce();
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return this.documentPermissions;
    }

    public acceptScheme(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Accept Design Scheme',
            message: 'Are you sure you want to accept this Design Scheme?',
            yesLabel: 'Confirm',
            noLabel: 'Cancel',
            confirmed: () => {
                this.mappedItem.statusId = DESIGN_SCHEME_STATUS_ENUM.Accepted;
                this.save();
            }
        });
    }

    public handleSaveSuccess(result: any): any {
        this.data.lot.$reload().subOnce();
        return super.handleSaveSuccess(result);
    }

    public rejectScheme(): void {
        this.cbDialog
            .open(DesignSchemeRejectDialogComponent, {
                data: {
                    mappedItem: this.mappedItem,
                }
            })
            .afterClosed()
            .subOnce((result) => {
                this.dialogRef.close(result);
            });
    }

    public filterDeletedLines = (items: IDesignSchemeLineDto[]): IDesignSchemeLineDto[] => {
        return items.filter(x => !x.isDeleted);
    };

    public filterDeletedRequirements = (items: IDesignRequirementItemDto[]): IDesignRequirementItemDto[] => {
        return items.filter(x => !x.isDeleted);
    };
}
