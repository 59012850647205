<div class="flex cb-margin-left-3x cb-margin-right-3x layoutGap-30 cb-margin-bottom-2x">
    <mat-slide-toggle [checked]="true">Show Unpublished</mat-slide-toggle>
    <mat-slide-toggle class="cb-margin-top cb-margin-right-2x">View All Stages</mat-slide-toggle>
</div>

<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-col layoutGap-col-10">
    <mat-radio-button class="direction-radio-button layoutGap-30">Credit</mat-radio-button>

    <mat-radio-group name="closetask-outcome-followup" class="layoutGap-30">
        <mat-radio-button>Approved</mat-radio-button>
        <mat-radio-button>Rejected</mat-radio-button>
    </mat-radio-group>
    <mat-radio-button>Design</mat-radio-button>
</div>

<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-col layoutGap-col-10">
    <form>
        <cb-checkbox label="Active" name="isLocked" [(ngModel)]="isLocked"></cb-checkbox>
    </form>
    <mat-checkbox name="showDeleted">Show Deleted</mat-checkbox>
    <mat-checkbox name="disabled" disabled="true">Disabled</mat-checkbox>
</div>

<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x">
    <button mat-raised-button color="primary" disabled="true">Add Widget</button>

    <button mat-icon-button>
        <mat-icon>filter_list</mat-icon>
    </button>

    <div class="start-center flex-row layoutGap-20 flex">
        <h1 class="mat-title">Name</h1>
        <mat-icon color="accent" matTooltip="Published">check_circle</mat-icon>
    </div>

    <button [matMenuTriggerFor]="menu"
            mat-icon-button
            matTooltip="Edit"
            matTooltipPosition="right"
            [disabled]="false">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
        >
            <mat-icon>help_outline</mat-icon>
            <span> View Details</span>
        </button>
        <button mat-menu-item
        >
            <mat-icon>rate_review</mat-icon>
            <span>Review Document</span>
        </button>
    </mat-menu>
</div>

