import {AfterContentChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild,} from '@angular/core';
import {Observable} from 'rxjs';

export type TableColumn = { label?: string; textAlign?: string; width?: number; minWidth?: number; maxWidth?: number } | string;

@Component({
    selector: 'cb-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements AfterContentChecked {

    public static readonly NO_STYLE = 'none';
    /** @deprecated use [infiniteScrollEnabled] instead */
    @ViewChild('infiniteScrollContainer', {static: false}) public infiniteScrollContainer: ElementRef;

    /** [cbInfiniteScroll] - the observable that emits and handles the search results */
    @Input() public fetch: Observable<any>;
    /** [cbInfiniteScroll] - when this Observable emits, a fetch will be triggered */
    @Input() public queryUpdated: Observable<any>;
    /** [cbInfiniteScroll] - infinite scroll will comence once this is set to true */
    @Input() public infiniteScrollEnabled = false;
    /** [cbInfiniteScroll] - currentPage of query, two way binding */
    @Input() public currentPage: number;
    @Output() public readonly currentPageChange = new EventEmitter<number>();
    /** [cbInfiniteScroll] - search results, two way binding */
    @Input() public results: any[];
    @Output() public readonly resultsChange = new EventEmitter<any[]>();

    @Input() public hideNoResultsMessage = false;
    @Input() public fetchInProgress = false;
    @Output() public readonly fetchInProgressChange = new EventEmitter<boolean>();

    @Input() public columns: Array<TableColumn>;
    @Input() public label: string;
    @Input() public emptyMessage: string | null;
    @Input() public hideDefaultTable = false;

    private _loaded = false;
    @Input()
    public set loaded(v: boolean) {
        this.zone.run(() => {
            this._loaded = !!v;
        });
    }

    public get loaded(): boolean {
        return this._loaded;
    }

    private maxHeightStyle = TableComponent.NO_STYLE;

    @Input()
    public set maxHeight(v: number | string) {
        this.zone.run(() => {
            if (Number(v) > 0) {
                this.maxHeightStyle = `${v}px`;
            } else {
                this.maxHeightStyle = TableComponent.NO_STYLE;
            }
        });
    }

    private maxHeightOffsetStyle = TableComponent.NO_STYLE;

    @Input()
    public set maxHeightOffset(v: number | string) {
        this.zone.run(() => {
            if (Number(v) > 0) {
                this.maxHeightOffsetStyle = `calc(100vh - ${v}px)`;
            } else {
                this.maxHeightOffsetStyle = TableComponent.NO_STYLE;
            }
        });
    }

    public get getMaxHeight(): string {
        return this.maxHeightOffsetStyle !== TableComponent.NO_STYLE ? this.maxHeightOffsetStyle : this.maxHeightStyle;
    }

    public minWidthStyle = TableComponent.NO_STYLE;

    @Input()
    public set minWidth(v: number | string) {
        this.zone.run(() => {
            if (Number(v) > 0) {
                this.minWidthStyle = `${v}px`;
            } else {
                this.minWidthStyle = TableComponent.NO_STYLE;
            }
        });
    }

    public get headContentEmpty(): boolean {
        return this.tableHeadRef == null || this.tableHeadRef?.children?.length < 1;
    }

    public get bodyContentEmpty(): boolean {
        return this.tableBodyRef == null || this.tableBodyRef?.children?.length < 1;
    }

    private tableHeadRef: HTMLElement | null;
    private tableBodyRef: HTMLElement | null;

    @ViewChild('cbTableRef')
    public set cbTableRef(v: ElementRef<HTMLTableElement> | HTMLTableElement) {
        const tableRef = ((v as ElementRef<HTMLTableElement>)?.nativeElement ?? v as HTMLTableElement);
        this.tableHeadRef = tableRef?.querySelector('[cbTableHead]');
        this.tableBodyRef = tableRef?.querySelector('[cbTableBody]');
    }

    constructor(private readonly cdref: ChangeDetectorRef, private readonly zone: NgZone) {
    }

    public ngAfterContentChecked(): void {
        this.zone.run(() => {
            this.cdref.detectChanges();
        });
    }

    public displayNoResultsMessage(tableBodyRow: ElementRef<HTMLTableRowElement> | HTMLTableRowElement): boolean {
        tableBodyRow = ((tableBodyRow as ElementRef<HTMLTableRowElement>)?.nativeElement ?? tableBodyRow as HTMLTableRowElement);
        return this.loaded && this.bodyContentEmpty && tableBodyRow?.children?.length < 1 && !this.fetchInProgress && !this.hideNoResultsMessage;
    }
}
