<div class="container"
     [cbInfiniteScroll]="cbTableRef"
     [fetch]="fetch"
     [queryUpdated]="queryUpdated"
     [enabled]="infiniteScrollEnabled"
     [fetchInProgress]="fetchInProgress"
     (fetchInProgressChange)="fetchInProgressChange.emit($event)"
     [results]="results"
     (resultsChange)="resultsChange.emit($event)"
     [currentPage]="currentPage"
     (currentPageChange)="currentPageChange.emit($event)">
    <div class="scroll-container"
         [ngStyle]="{'min-width': minWidthStyle, 'max-height': getMaxHeight}">
        <ng-content select="[cbTable]"></ng-content>
        <table [hidden]="hideDefaultTable"
               #cbTableRef>
            <ng-content select="[cbTableHead]"></ng-content>
            <thead>
            <tr>
                <td *ngFor="let col of columns"
                    [style.width.px]="col?.width"
                    [style.minWidth.px]="col?.minWidth"
                    [style.maxWidth.px]="col?.maxWidth"
                    [style.textAlign]="col?.textAlign">{{ col?.length != null ? col : col?.label || '' }}
                </td>
            </tr>
            </thead>

            <ng-content select="[cbTableFoot]"></ng-content>
            <tfoot>
            <ng-content select="[cbTableFooterRow]"></ng-content>
            </tfoot>

            <ng-content select="[cbTableBody]"></ng-content>
            <tbody #cbTableRowBody>
            <ng-content select="[cbTableRow]"></ng-content>
            </tbody>

            <tbody>
            <tr *ngIf="displayNoResultsMessage(cbTableRowBody)"
                class="cb-table-footer-row">
                <td colspan="100%">
                    <cb-info-message *ngIf="displayNoResultsMessage(cbTableRowBody)"
                                     [message]="emptyMessage || 'There are no '+ (label || 'items') +' to display'"
                                     [icon]="'info'"></cb-info-message>
                </td>
            </tr>

            <tr *ngIf="!loaded || fetchInProgress"
                class="cb-table-footer-row">
                <td colspan="100%">
                    <cb-loading-spinner></cb-loading-spinner>
                </td>
            </tr>
            </tbody>

            <tbody>

            </tbody>
        </table>
    </div>
</div>
