import {BasePermissions, IBaseLotChangePermissions, IBaseSystemAreaPermissions, IDocumentPermissions, IhaveNotePermissions, INotePermissions} from './base.permissions';
import {LotChangePermissionEnum} from '../../shared/enums/permissions/lot.change.permission';
import {CurrentUserService} from '../authentication/current.user';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';


export interface ILotChangePermissions extends IBaseLotChangePermissions, IBaseSystemAreaPermissions, IhaveNotePermissions {
    canSeeComponent(): boolean;
    canView(): boolean;
    canCreate(): boolean;
    canEdit(): boolean;
    canSubmitForPricing(): boolean;
    canCancel(): boolean;
    canReassign(): boolean;
    canManageOption(): boolean;
    canChooseOption(): boolean;
    getNotePermissions(): INotePermissions;
    getDocumentPermissions(): IDocumentPermissions;
    canManageOwnChange(): boolean;
    canGenerateChangesSignOffDocument(): boolean;
}

@Injectable()
export class LotChangePermissions extends BasePermissions<LotChangePermissionEnum> implements ILotChangePermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lotchange', LotChangePermissionEnum, currentUser, http, router);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanView);
    }

    public canView(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanCreate);
    }

    public canEdit(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanEdit);
    }

    public canSubmitForPricing(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanSubmitForPricing);
    }

    public canCancel(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanCancel);
    }

    public canReassign(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanReassign);
    }

    public canManageOption(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanManageOption);
    }

    public canChooseOption(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanChooseOption);
    }

    public canManageOwnChange(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanManageOwnChange);
    }

    public canGenerateChangesSignOffDocument(): boolean {
        return this.permissionObject(LotChangePermissionEnum.CanGenerateChangesSignOffDocument);
    }

    public getNotePermissions(): INotePermissions {
        throw new Error('Method not implemented');
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => true,
            canEditDocuments: () => true,
            canReviewDocuments: () => true,
            canUploadDocument: () => true,
            canViewDocuments: () => true
        };
    }

}
