<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">
                Document Templates
            </h3>
            <button mat-raised-button
                    (click)="newItemClicked();"
                    color="primary">
                Add Document Template
            </button>
        </div>
        <mat-tab-group class="cb-margin-top">
            <mat-tab *ngFor="let category of documentTemplateTree"
                     [label]="getDocumentTemplateLabel(category)">
                <div class="cb-table cb-margin-top">
                    <mat-table [dataSource]="category.children">
                        <ng-container matColumnDef="templateName">
                            <mat-header-cell *matHeaderCellDef>
                                Template Name
                            </mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                {{item.name}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="templateCode">
                            <mat-header-cell *matHeaderCellDef>
                                Template Code
                            </mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                {{item.code}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="fileName">
                            <mat-header-cell *matHeaderCellDef>
                                File Name
                            </mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                {{item.filename}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="dateUploaded">
                            <mat-header-cell *matHeaderCellDef>
                                Date Uploaded
                            </mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                {{item.dateUploaded | cbDate}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="isActive">
                            <mat-header-cell *matHeaderCellDef>
                                Active
                            </mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <mat-icon class="cb-grey">
                                    {{item.isActive ? "check_box" : "check_box_outline_blank"}}
                                </mat-icon>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions"
                                      stickyEnd>
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <button mat-icon-button
                                        [matMenuTriggerFor]="menu">
                                    <mat-icon>
                                        more_horiz
                                    </mat-icon>
                                </button>
                                <mat-menu #menu="matMenu"
                                          xPosition="before">
                                    <button mat-menu-item
                                            (click)="editItemClicked(item)">
                                        <mat-icon>
                                            edit
                                        </mat-icon>
                                        <span>
                                            Edit
                                        </span>
                                    </button>
                                    <button mat-menu-item
                                            (click)="onUploadClicked(item)">
                                        <mat-icon>
                                            file_upload
                                        </mat-icon>
                                        <span>
                                            Upload Template File
                                        </span>
                                    </button>
                                    <mat-divider></mat-divider>
                                    <button mat-menu-item
                                            (click)="onDownloadClicked(item)">
                                        <mat-icon>
                                            file_download
                                        </mat-icon>
                                        <span>
                                            Download File
                                        </span>
                                    </button>
                                    <button mat-menu-item
                                            (click)="onHistoryClicked(item)">
                                        <mat-icon>
                                            history
                                        </mat-icon>
                                        <span>
                                            View History
                                        </span>
                                    </button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <cb-info-message *ngIf="category.children && category.children.length === 0"
                                     [message]="'There are no Document Templates to display'"
                                     [icon]="'info_circle'"></cb-info-message>
                    <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
