import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { INotesLogicService } from './interfaces/i.note-logic.service';
import { INoteDto } from './interfaces/i.note.dto';
import { INoteMappedItem } from './interfaces/i.note.mapped';

export class NoteMappedItem
    extends BaseMappedItem<INoteDto, INoteMappedItem, INotesLogicService>
    implements INoteMappedItem {


    @DtoProp public readonly id: number;
    @DtoProp public body: string;
    @DtoProp public subject: string;
    @DtoProp public isImportant: boolean;
    @DtoProp public canEdit: boolean;
    @DtoProp public createdByName: string;
    @DtoProp public createdDate: DateString;
    @DtoProp public updatedByName: string;
    @DtoProp public updatedDate: DateString;
    @DtoProp public entityUri: string;
    @DtoProp public entityId: number | string;

    constructor(
        sourceData: INoteDto,
        logicService: INotesLogicService
    ) {
        super(sourceData, logicService, NoteMappedItem);
    }

    public saveNote(): Observable<INoteDto> {
        return this.$logicService.saveNote(this.entityUri, this.entityId, this.$getMappedDtoItem())
            .pipe(
                tap((item) => this.$updateThisAndOriginal(item))
            );
    }

    public deleteNote(): Observable<INoteDto> {
        return this.$logicService.deleteNote(this.entityUri, this.entityId, this.$getMappedDtoItem());
    }
}
