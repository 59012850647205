import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';

import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { LOT_JOB_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ProductLogicService } from '@app/logic/products';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { TableComponent, USER_CACHE_AREA } from 'cb-hub-lib';

@Component({
    selector: 'cb-item-usage-build-programme-tab',
    templateUrl: './item-usage-build-programme-tab.component.html',
    styleUrls: ['./item-usage-build-programme-tab.component.scss']
})
export class ItemUsageBuildProgrammeTabComponent extends BaseSearchViewDirective<any> implements OnInit, AfterContentChecked {
    @Input() public productId: number;
    @ViewChild('infiniteScrollTable', { static: false }) public infiniteScrollTable: TableComponent;
    public LOT_JOB_STATUS_ENUM = LOT_JOB_STATUS_ENUM;

    public viewBuildProgramme = (buildProgramme: any): void => {
        this.navigationService.navigate([`/lots/${buildProgramme.lotId}/build/programme`], undefined);
    };

    constructor(
        public readonly navigationService: NavigationService,
        public readonly productLogicService: ProductLogicService,
        public readonly toastSerivce: ToastService,
        public readonly userCacheService: UserCacheService,
        private readonly cdref: ChangeDetectorRef) {

        super(
            productLogicService,
            toastSerivce,
            userCacheService,
            USER_CACHE_AREA.BuildActivitySearch,
        );
    }

    public ngOnInit(): void {
        this.loadSearchParams();
    }

    public ngAfterContentChecked(): void {
        this.cdref.detectChanges();
    }

    public doSearch = (): void => {
        if (this.shouldDoSearch()) {
            this.doSearchIfNoScrollBar();
            this.currentPage++;
            this.searchIsLoading = true;
            this.productLogicService
                .getProductBuildProgrammeUsage(this.productId, {
                    currentPage: this.currentPage,
                    pageSize: this.pageSize
                }).subOnce({
                    next: this.handleSearchResult,
                    error: this.handleSearchError
                });
        }
    };
}
