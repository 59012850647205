import { Component } from '@angular/core';
import { SYSTEM_AREA_ENUM, DOCUMENT_GROUP_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import {FeatureToggleStatesService} from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

@Component({
    selector: 'cb-design-workflow',
    templateUrl: './design-workflow.component.html',
    styleUrls: ['./design-workflow.component.scss']
})
export class DesignWorkflowComponent {

    public readonly designSchemeArea = SYSTEM_AREA_ENUM.DesignScheme;
    public readonly preconsentArea = SYSTEM_AREA_ENUM.PreConsent;
    public readonly preconsentPlanArea = SYSTEM_AREA_ENUM.PreConsentPlan;
    public readonly designRequirementsArea = SYSTEM_AREA_ENUM.DesignRequirements;

    public readonly designSchemeDocGroups = [
        DOCUMENT_GROUP_CODES_CONST.DESIGN_INFO,
        DOCUMENT_GROUP_CODES_CONST.DESIGN_SCHEME_DOC_GROUP,
    ];

    public readonly preconsentDocGroups = [
        DOCUMENT_GROUP_CODES_CONST.DESIGN_INFO,
        DOCUMENT_GROUP_CODES_CONST.PRE_CONSENT_DOC_GROUP,
    ];

    public readonly preconsentPlanDocGroups = [
        DOCUMENT_GROUP_CODES_CONST.DESIGN_INFO,
        DOCUMENT_GROUP_CODES_CONST.DESIGN_PRE_CONSENT,
    ];

    public readonly designRequirementsDocGroups = [
        DOCUMENT_GROUP_CODES_CONST.DESIGN_INFO
    ];

    constructor(public readonly featureToggleStatesService: FeatureToggleStatesService) { }
}
