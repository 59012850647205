import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { LotQuotePermissions } from '@app/core/permissions/lot.quote.permission';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotQuoteSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ILotMappedItem } from '@app/logic/lots';
import { QuoteLogicService } from '@app/logic/quote';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { CHANGE_SOURCE_ENUM, COST_TYPE_ENUM, ILotSpecItemDto, IQuoteDocumentDto, QUOTE_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { LotQuoteCreateDialogComponent } from '../lot-quote-create-dialog/lot-quote-create-dialog.component';
import { LotQuoteDialogComponent } from '../lot-quote-dialog/lot-quote-dialog.component';
@Component({
    selector: 'cb-lot-quotes-list',
    templateUrl: './lot-quotes-list.component.html',
    styleUrls: ['./lot-quotes-list.component.scss']
})
export class LotQuotesListComponent extends BaseSearchViewDirective<ILotQuoteSearch> implements OnDestroy {

    public COST_TYPE_ENUM = COST_TYPE_ENUM;

    public mainPanelWidth = 100;
    public sidePanelWidth = 0;
    public showSpecificationItemsPanel = false;
    public quotedSpecItems: ILotSpecItemDto[] = [];

    public searchTimeout = 500;

    private _lotMappedItem: ILotMappedItem;
    @Input() public set lotMappedItem(_lotMappedItem: ILotMappedItem) {
        this._lotMappedItem = _lotMappedItem;
        this.loadSearchParams();
        this.loadQuotedSpecItems();
    }

    public get lotMappedItem(): ILotMappedItem {
        return this._lotMappedItem;
    }

    public quoteStatus = QUOTE_STATUS_ENUM.toSelectList();

    public get userCacheItem(): UserCacheItem<ILotQuoteSearch> {
        return this.userCacheService.lotQuoteSearch;
    }

    constructor(
        public readonly userCacheService: UserCacheService,
        public readonly lotQuotePermissions: LotQuotePermissions,
        public readonly quoteLogicService: QuoteLogicService,
        public readonly cbDialog: CbDialogService,
        public readonly toastService: ToastService,
        public cdRef: ChangeDetectorRef,
    ) {
        super(quoteLogicService, toastService, userCacheService, USER_CACHE_AREA.LotQuotesSearch);

    }

    public loadQuotedSpecItems(): void {
        this.quoteLogicService.getUnassignedLotSpecItems(this.lotMappedItem.id).subOnce((result) => {
            this.quotedSpecItems = result;
        });
    }

    public getSearchParams(): ILotQuoteSearch {
        const data = this.userCacheItem.copyData();
        return {
            ...data,
            lotId: this.lotMappedItem.id,
            order: 'id',
            direction: 'desc',
        } as ILotQuoteSearch;
    }


    public createQuoteRequired(): void {
        const dialogRef = this.cbDialog
            .open(LotQuoteCreateDialogComponent, {
                data: {
                    mappedItem: this.quoteLogicService.$createMappedItem({
                        lotId: this.lotMappedItem.id,
                    }),
                    lotMappedItem: this.lotMappedItem,
                    isRequiredQuote: true
                },
            });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {
                this.searchResults.unshift(result);
            }
        });
    }

    public createQuoteReceived(): void {
        const dialogRef = this.cbDialog
            .open(LotQuoteCreateDialogComponent, {
                data: {
                    mappedItem: this.quoteLogicService.$createMappedItem({
                        lotId: this.lotMappedItem.id,
                        changeSource: CHANGE_SOURCE_ENUM.ClassicRequest
                    }),
                    lotMappedItem: this.lotMappedItem,
                    isRequiredQuote: false
                },
            });

        dialogRef.afterClosed().subOnce(result => {
            if (result.id > 0) {
                this.quoteLogicService.$getMappedItem(result.id).subOnce((_quoteMappedItem) => {
                    this.searchResults.unshift(_quoteMappedItem);
                    this.cbDialog.open(LotQuoteDialogComponent, {
                        data: {
                            quoteMappedItem: _quoteMappedItem,
                            lotMappedItem: this.lotMappedItem,
                            isLotSpecLocked: this.lotMappedItem.lotSpecLocked
                        }
                    }).afterClosed().subOnce((result) => {
                        if (result) {
                            setTimeout(() => {
                                this.loadSearchParams();
                            }, this.searchTimeout);
                        }
                    });
                });
            }
        });
    }

    public quoteDuplicated(duplicatedQuote: IQuoteDocumentDto): void {
        if (duplicatedQuote) {
            this.searchResults.unshift(duplicatedQuote);

            this.cbDialog.open(LotQuoteDialogComponent, {
                data: {
                    quoteMappedItem: this.quoteLogicService.$createMappedItem(duplicatedQuote),
                    isLotSpecLocked: this.lotMappedItem.lotSpecLocked
                }
            }).afterClosed().subOnce((result) => {
                if (result) {
                    setTimeout(() => {
                        this.loadSearchParams();
                    }, this.searchTimeout);
                }
            });
        }
    }

    public toggeleColumnWitdh(): void {
        this.showSpecificationItemsPanel = !this.showSpecificationItemsPanel;

        if (this.showSpecificationItemsPanel) {
            this.mainPanelWidth = 80;
            this.sidePanelWidth = 20;
        } else {
            this.mainPanelWidth = 100;
            this.sidePanelWidth = 0;
        }
    }
}
