<div *ngIf="buildActivity"
     class="cb-margin-top flex-col flex">
    <cb-checkbox name="shouldExcludeRequiredComplianceForDuplicates"
                 label="Exclude required compliance for duplicate Activities in Build"
                 [(ngModel)]="buildActivity.shouldExcludeRequiredComplianceForDuplicates">
    </cb-checkbox>
    <body>
    <p>Select which type to link to this build activity or remove the type to change</p>
    </body>
    <mat-radio-group name="TypeState"
                     [(ngModel)]="triStateSelector"
                     [disabled]="isBuildActivityATradeType() || isBuildActivityASupplyType()">
        <mat-radio-button [value]="null">None</mat-radio-button>
        <mat-radio-button [value]="true">Trade Type</mat-radio-button>
        <mat-radio-button *ngIf="isSupplierTypesEnabled" [value]="false">Supply Type</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="isBuildActivityATradeType()">
        <cb-select-items-autocomplete-scroller class="flex"
                                               heading="Trade Types"
                                               [displayFn]="displayFnTrade"
                                               name="tradeType1"
                                               [(ngModel)]="buildActivity.tradeTypesList"
                                               (change)="tradeTypesChanged()"
                                               [selectedItem]="selectedTradeType">
            <cb-trade-type-autocomplete-scroller class="cb-margin-top flex flex-row"
                                                 label="Select Trade Type"
                                                 [disabled]="cannotAddTradeOrSupplyType"
                                                 name="tradeType"
                                                 [(ngModel)]="selectedTradeType"
                                                 [ignoreDirty]="true">
            </cb-trade-type-autocomplete-scroller>
        </cb-select-items-autocomplete-scroller>
        <div class="cb-margin flex-col flex">
            <mat-form-field *ngIf="availableDocTypes.$resolved">
                <mat-label>
                    Required Compliance
                </mat-label>
                <mat-select [(ngModel)]="buildActivity.requiredComplianceId"
                            name="requiredCompliance">
                    <mat-option *ngFor="let availableDocType of availableDocTypes"
                                [value]="availableDocType.id">
                        {{ availableDocType.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="isBuildActivityASupplyType()">
        <cb-select-items-autocomplete-scroller class="flex"
                                               heading="Supply Types"
                                               [displayFn]="displayFnSupply"
                                               name="supplyType1"
                                               [(ngModel)]="buildActivity.supplyTypesList"
                                               (change)="supplyTypesChanged()"
                                               [selectedItem]="selectedSupplyType">
            <cb-supply-type-autocomplete-scroller class="cb-margin-top flex flex-row"
                                                  label="Select Supply Type"
                                                  [disabled]="cannotAddTradeOrSupplyType"
                                                  name="supplyType"
                                                  [(ngModel)]="selectedSupplyType"
                                                  [ignoreDirty]="true">
            </cb-supply-type-autocomplete-scroller>
        </cb-select-items-autocomplete-scroller>
        <div class="cb-margin flex-col flex">
            <mat-form-field *ngIf="availableDocTypessupply.$resolved">
                <mat-label>
                    Required Compliance
                </mat-label>
                <mat-select [(ngModel)]="buildActivity.requiredComplianceId"
                            name="requiredCompliance">
                    <mat-option *ngFor="let availableDocTypessupply of availableDocTypessupply"
                                [value]="availableDocTypessupply.id">
                        {{ availableDocTypessupply.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
