import { Directive, EventEmitter, Input, Output } from '@angular/core';


/**
 * Show placeholder image if img src is not valid
 * Usage :
 *
 * This will replace the broken image with the default Image
 * <img [src]="{DESIRED_IMAGE_SOURCE}" cbHandleInvalidImage>
 *
 * OR
 * this will replace the the broken image with cbDefaultImageUrl
 * <img [src]="{DESIRED_IMAGE_SOURCE}" cbHandleInvalidImage cbDefaultImageUrl="{DESIRED_PLACEHOLDER_IMAGE_SOURCE}">
 */


@Directive({
    selector: '[cbHandleInvalidImage]',
    host: {
        '(error)': 'updateUrl()',
        '(success)': 'updateUrl()',
        '[src]': 'src'
    }
})
export class cbHandleInvalidImageDirective {

    private _src: string;
    @Input() public set src(src: string) {
        this._src = src;
        this.isImageInvalid.next(false);
    }

    public get src(): string {
        return this._src;
    }

    @Input() public cbDefaultImageUrl = 'assets/img/no-image-placeholder.png';
    @Output() public isImageInvalid = new EventEmitter<boolean>();
    @Input() public height = 300;
    @Input() public width = 500;

    public updateUrl(): void {
        this.src = `${this.cbDefaultImageUrl}?w=${this.width}&h=${this.height}&scale=canvas`;
        this.isImageInvalid.next(true);
    }

}
