import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ContactPermissions } from '@app/core/permissions';
import { ContactMappedItem } from '@app/logic/contacts/contact.mapped';
import { ContactsLogicService, IContactSearchDto } from '@app/logic/contacts';
import { CONTACT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-contact-card',
    templateUrl: './contact-card.component.html',
    styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent {
    @Input() public dto: IContactSearchDto;
    @Output() public readonly selectionChosenEmitter = new EventEmitter();
    public CONTACT_STATUS_ENUM = CONTACT_STATUS_ENUM;

    constructor(
        private readonly contactPermissions: ContactPermissions,
        private readonly contactLogicService: ContactsLogicService
    ) {
    }

    public selectionChosen(val: IContactSearchDto): void {
        this.selectionChosenEmitter.emit(this.contactLogicService.$createMappedItem(val, ContactMappedItem));
    }

}
