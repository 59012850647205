import { ISkinnyChangeRecordDto } from './../change-records/interfaces/i.skinny-change-record.dto';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ICouncilRfiMappedItem } from './interfaces/i.council-rfi.mapped';
import { ICouncilRfiLogicService } from './interfaces/i.council-rfi-logic.service';
import { ICouncilRfiConsentDto } from './interfaces/i.council-rfi-consent.dto';
import { RfiAspectEnumId, COUNCIL_RFI_STATUS_ENUM, ICouncilRfiDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { Computed } from '../base/computed-prop.decorator';
import { ICouncilRfiSearchDto } from './interfaces/i.council-rfi-search.dto';
import { councilRfiSearchDto } from './dtos/council-rfi-search.dto';
import { DtoProp } from '../base/dto-prop.decorator';

export class CouncilRfiMappedItem
    extends BaseMappedItem<ICouncilRfiDto, ICouncilRfiMappedItem, ICouncilRfiLogicService>
    implements ICouncilRfiMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public lotId: number;
    @DtoProp public rfiNumber: string;
    @DtoProp public receivedFrom: string;
    @DtoProp public externalReference: string;
    @DtoProp public notes: string;
    @DtoProp public statusId: number;
    @DtoProp public statusLabel: string;
    @DtoProp public rfiDate: string;
    @DtoProp public rfiDueDate: string;
    @DtoProp public assignedToTeamId: number;
    @DtoProp public assignedToTeamLabel: string;
    @DtoProp public assignedToUserId: string;
    @DtoProp public assignedToUserName: string;
    @DtoProp public isDeleted: boolean;
    @DtoProp public relatedConsentIds: number[];
    @DtoProp public relatedConsents: ICouncilRfiConsentDto[];
    @DtoProp public relatedChangeRecords: ISkinnyChangeRecordDto[];
    @DtoProp public councilName: string;
    @DtoProp public canBeCompleted: boolean;
    @DtoProp public createdDate: string;
    @DtoProp public createdByUserName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByUserName: string;
    @DtoProp public rfiAspects: RfiAspectEnumId[];
    @DtoProp public completedDate: string;
    @DtoProp public updatedById: string;
    @DtoProp public createdById: string;
    @DtoProp public workingDrawingId: number;
    @DtoProp public workingDrawingNumber: number;
    @DtoProp public rfiNumberForWorkingDrawing: number;

    public get taskEntityId(): number {
        return this.id;
    }
    public get taskEntityUri(): string {
        return this.$logicService.$baseUri;
    }
    public get documentEntityId(): number {
        return this.id;
    }
    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    constructor(
        sourceData: ICouncilRfiDto,
        logicService: ICouncilRfiLogicService
    ) {
        super(
            sourceData,
            logicService,
            CouncilRfiMappedItem
        );
    }

    @Computed() public get isComplete(): boolean {
        return Number(this.statusId) === COUNCIL_RFI_STATUS_ENUM.Complete;
    }

    public downloadLatestDocument(): Observable<void> {
        return this.$logicService.downloadLatestRfiDocument(this.id);
    }

    public mapToSearchDto(): ICouncilRfiSearchDto {
        return this.$mapToDtoItem(councilRfiSearchDto);
    }

    public delete(): Observable<boolean> {
        return this.$logicService.$deleteItem(this.id);
    }

    public getChangeRecords(): Observable<ISkinnyChangeRecordDto[]> {
        return this.$logicService.getChangeRecords(this.id);
    }
}
