import { Component } from '@angular/core';
import { AuthService } from '@app/core/authentication/auth.service';
@Component({
    selector: 'cb-auth-loading',
    templateUrl: './auth-loading.component.html',
    styleUrls: ['./auth-loading.component.scss']
})
export class AuthLoadingComponent {

    constructor(public readonly authService: AuthService) {
    }

}
