// header-control.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface HeaderButton {
    element: HTMLElement;
    onClick: () => void;
}

@Injectable({
    providedIn: 'root'
})
export class HeaderControlService {
    private headerButtonSource = new BehaviorSubject<HeaderButton | null>(null);
    public currentButton = this.headerButtonSource.asObservable();

    constructor() { }

    public updateHeaderButton(button: HeaderButton | null): void {
        this.headerButtonSource.next(button);
    }
}
