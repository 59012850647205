import {Component, Input, OnInit} from '@angular/core';
import {PermissionsPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {PermissionGroupAreaLogicService} from '@app/logic/security-role/permission-group-area.logic.service';
import {PermissionGroupLogicService} from '@app/logic/security-role/permission-group.logic.service';
import {IPermissionDto, IPermissionGroupAreaDto, IPermissionGroupDto, IPermissionGroupSummaryDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {toHumanizeWords} from '@src/app/shared/utils/string.util';

@Component({
    selector: 'cb-security-role-view',
    templateUrl: './security-role-view.component.html',
    styleUrls: ['./security-role-view.component.scss'],
    providers: [
        PermissionGroupLogicService,
        PermissionGroupAreaLogicService
    ]
})
export class SecurityRoleViewComponent implements OnInit {

    @Input() public role: IPermissionGroupSummaryDto;
    public roleDetails: IPermissionGroupDto;
    public toHumanizeWords: any;

    public permissionOptions;
    private _isEdit = false;
    private _isCreate = false;
    private _isView = false;

    constructor(
        public readonly securityRoleService: PermissionGroupLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        private readonly navigationService: NavigationService,
    ) {
        this.toHumanizeWords = toHumanizeWords;
        this.permissionOptions = securityRoleService.permissionOptions;
    }

    public ngOnInit(): void {
        const path = this.navigationService.currentPath.split('/');
        if (path[path.length - 1] !== 'create') {
            this._isView = true;
            this.loadRoles();
        } else {
            this.securityRoleService.createNewPermissionGroup()
                .then((role) => {
                    this.roleDetails = role;
                    this._isCreate = true;
                });
        }
    }

    public isEditOrCreate(): boolean {
        return this._isEdit || this._isCreate;
    }

    public isEdit(): boolean {
        return this._isEdit;
    }

    public isCreate(): boolean {
        return this._isCreate;
    }

    public isView(): boolean {
        return this._isView;
    }

    // Enable item editing
    public editItem(): void {
        this._isEdit = true;
    }

    public saveItem(): void {
        this._isEdit = false;
        this._isCreate = false;
        this.securityRoleService.$saveItemReturnId(this.roleDetails)
            .subOnce((resultId) => {
                this.roleDetails.id = resultId;
                this._isView = true;
            });
    }

    public cancel(event: MouseEvent): void {
        if (this._isCreate) {
            this.navigationService.navigate(['/security-roles'], event);
        } else {
            this._isEdit = false;
            this.loadRoles();
        }
    }

    public getLabel(permission: IPermissionDto): string {
        return permission.explicitAllow === null ? 'Not Set' : '';
    }

    public getAreaLabel(area: IPermissionGroupAreaDto): string {
        return this.securityRoleService.systemAreas.filter((a) => {
            return a.id === area.area;
        })[0]?.label;
    }

    public getArea(areaGroupId: number): IPermissionGroupAreaDto {
        return this.roleDetails.areas.filter((a) => {
            return a.area === areaGroupId;
        })[0];
    }

    public getAreaPermissions(areaGroupId: number): IPermissionDto[] {
        return this.roleDetails.areas.filter((a) => {
            return a.area === areaGroupId;
        })[0]?.permissions;
    }

    private loadRoles(): void {
        const path = this.navigationService.currentPath.split('/');
        const groupId = path[path.length - 1] as any;
        this.securityRoleService.loadSystemAreasWithNestedPermissionsEnums().then(() => {
            this.securityRoleService.$getItem(groupId as number)
                .subOnce((result) => {
                    // Add area labels to results
                    result.areas.forEach((area: any) => {
                        area.label = this.getAreaLabel(area);
                    });
                    this.roleDetails = result;
                });
        });
    }
}
