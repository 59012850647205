import { ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ToastService } from '@app/core/services/toast/toast.service';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { ITagDto, USER_TAG_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { TagsLogicService } from '@app/logic/tags';
import { BusinessEntitiesLogicService } from '@app/logic/business-entity';
import { FormMode } from '@app/shared/enums/form';
import { includes, orderBy } from 'lodash';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { map, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { IUserMappedItem } from '@app/logic/users/interfaces/i.user.mapped';
import { IUsersLogicService } from '@app/logic/users/interfaces/i.users.logic.service';
import { NonDialogFormViewDirective } from '@app/shared/base-views/non-dialog-form-view.directive';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IUserDto, UsersLogicService } from '@app/logic/users';

@Component({
    selector: 'cb-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent extends NonDialogFormViewDirective<
IUserDto,
IUserMappedItem,
IUsersLogicService> implements OnDestroy {

    @Input() public userId$: BehaviorSubject<string>;
    @ViewChild('userDetailsForm', { static: true }) public baseForm: NgForm;
    @Input() public readonly = false;
    @Input() public mappedItem$ = new BehaviorSubject(undefined);
    public mappedItemClone: IUserMappedItem;
    public isMobileMandatory: boolean;

    private _subscriptions = new Subscription();

    @Input() public set mappedItem(mappedItem: IUserMappedItem) {
        this.mappedItemClone = mappedItem?.$clone();
        this.mappedItem$?.next(mappedItem);
    }

    public get mappedItem(): IUserMappedItem {
        return this.mappedItem$?.value;
    }

    public mobileChanged(value: string): void {
        this.mappedItem.phoneMobile = value;
    }

    public teamsOptions$ = this.teamsLogicService.$getList();
    public userRoleTagsOptions$ = this.tagsLogicService.$getList().pipe(
        map(tags => orderBy(tags, 'name', 'asc'))
    );
    public userRoleTagsOptions: ITagDto[];

    public businessEntitiesOptions$ = this.businessEntitiesLogicService
        .getBusinessEntityLookupList()
        .pipe(
            map(businessEntities => {
                return orderBy(
                    businessEntities,
                    businessEntity => businessEntity?.label?.toLowerCase()
                );
            })
        );

    public selectedBusinessEntitiesOptionsAsDtos$ = combineLatest([
        this.businessEntitiesOptions$,
        this.mappedItem$
    ]).pipe(
        map(([businessEntities, mappedItem]) => {
            return businessEntities
                .filter(businessEntity => includes(
                    mappedItem.selectedBusinessEntities,
                    businessEntity.id)
                );
        })
    );

    public WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;

    constructor(
        private readonly logicService: UsersLogicService,
        private readonly teamsLogicService: TeamsLogicService,
        private readonly tagsLogicService: TagsLogicService,
        private readonly navigationService: NavigationService,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        private readonly toastService: ToastService,
        public cdRef: ChangeDetectorRef,
        public readonly route: ActivatedRoute,
    ) {
        super(toastService);
        this._subscriptions.add(
            this.userRoleTagsOptions$.subscribe(res => this.userRoleTagsOptions = res)
        );
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public onCancelClicked(): void {
        this.mappedItem$.next(this.mappedItemClone);
    }

    public onEditClicked(): void {
        const existingMappedItem = this.logicService.$createMappedItem(this.mappedItem.$getMappedDtoItem());
        this.mappedItem = existingMappedItem;
    }

    public selectedTagsChanged(tags: number[], form: UntypedFormGroup): void {
        form.markAsDirty();
        this.mappedItem.selectedTags = tags;

        const buildingConsultantTag = this.userRoleTagsOptions?.find(
            tag => tag.key === USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT);

        this.isMobileMandatory = this.mappedItem?.selectedTags?.some(tag => tag === buildingConsultantTag?.id);
    }

    public selectedTeamsChanged(teams: number[], form: UntypedFormGroup): void {
        form.markAsDirty();
        this.mappedItem.selectedTeams = teams;
    }

    public selectedBusinessEntitiesChanged(entities: number[], form: UntypedFormGroup): void {
        form.markAsDirty();
        this.mappedItem.selectedBusinessEntities = entities;
        this.mappedItem$.next(this.mappedItem);

        if (!this.mappedItem.primaryBusinessEntity) {
            form.controls.primaryBusinessEntity.setErrors({ invalid: true });
        }
    }

    public handleSaveSuccess(result: IUserDto): void {
        const userId = result?.id || this.mappedItem.id;
        this.userId$.next(userId);
        this.navigationService
            .navigate(
                [`/users/${userId}`],
                undefined,
                {
                    queryParams: {
                        mappedItem: result ? this.logicService.$createMappedItem(result) : this.mappedItem
                    }
                } as NavigationExtras
            );
        this.formMode$$ = FormMode.View;
        this.formMode = FormMode.View;
    }
}
