export enum SpecificationTemplatePermissionEnum {
    None = 0x00,
    CanView = 0x01 << 0,
    CanViewHistory = 0x01 << 1,
    CanEdit = 0x01 << 2,
    CanDuplicate = 0x01 << 3,
    CanViewAll = 0x01 << 4,
    CanEditAll = 0x01 << 5,
    CanCreate = 0x01 << 6,
}
