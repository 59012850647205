import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { DesignSchemeNotificationStageMappedItem } from './design-scheme-notification-stage.mapped';
import { IDesignSchemeNotificationStageDto } from './interfaces/i.design-scheme-notification-stage.dto';
import { IDesignSchemeNotificationStageLogicService } from './interfaces/i.design-scheme-notification-stage.logic.service';
import { IDesignSchemeNotificationStageMappedItem } from './interfaces/i.design-scheme-notification-stage.mapped';

@Injectable()
export class DesignSchemeNotificationStageLogicService
    extends BaseLogicService<IDesignSchemeNotificationStageDto, IDesignSchemeNotificationStageMappedItem>
    implements IDesignSchemeNotificationStageLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('designschemenotificationstages', DesignSchemeNotificationStageMappedItem);
    }
}
