import { AfterContentInit, AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

@Directive({
    selector: '[cbFillHeight]'
})
export class FillHeightDirective implements AfterViewInit, AfterContentInit {
    public static readonly UPDATE_HEIGHT_DELAY = 100;

    private readonly sub$ = new Subscription();
    private _updateHeight: Observable<any>;
    @Input() public set updateHeight(v: Observable<any>) {
        this._updateHeight = v;
        this.sub$.unsubscribe();
        this._updateHeight.subscribe(() => setTimeout(() => this.calculateAndSetElementHeight(), FillHeightDirective.UPDATE_HEIGHT_DELAY));
    }

    @Input() public footerElement = null;
    constructor(private readonly el: ElementRef) {
    }

    public ngAfterViewInit(): void {
        this.calculateAndSetElementHeight();
    }
    public ngAfterContentInit(): void {
        this.calculateAndSetElementHeight();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: any): void {
        this.calculateAndSetElementHeight();
    }

    private calculateAndSetElementHeight(): void {
        this.el.nativeElement.style.overflow = 'auto';
        const windowHeight = window.innerHeight;
        const elementOffsetTop = this.getElementOffsetTop();
        const footerElementMargin = this.getfooterElementMargin();

        this.el.nativeElement.style.height = `${windowHeight - footerElementMargin - elementOffsetTop}px`;
        this.el.nativeElement.style.maxHeight = this.el.nativeElement.style.height;
    }

    private getElementOffsetTop(): number {
        return this.el.nativeElement.getBoundingClientRect().top;
    }

    private getfooterElementMargin(): number {
        if (!this.footerElement) {
            return 0;
        }
        const footerStyle = window.getComputedStyle(this.footerElement);
        return parseInt(footerStyle.height, 10);
    }

}
