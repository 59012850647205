<div class="cb-margin-bottom-3x flex-col flex">
    <div class="cb-table cb-margin-top">
        <mat-table [dataSource]="dataSource"
                   matSort>
            <ng-container matColumnDef="regionName">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header>
                    Region
                    <cb-sortable-header-cell [sort]="this.sort"
                                             [cell]="'regionName'"></cb-sortable-header-cell>
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.regionName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="styleName">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header>
                    Style
                    <cb-sortable-header-cell [sort]="this.sort"
                                             [cell]="'styleName'"></cb-sortable-header-cell>
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.styleName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="totalCost">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header>
                    Total Cost
                    <cb-sortable-header-cell [sort]="this.sort"
                                             [cell]="'totalCost'"></cb-sortable-header-cell>
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> ${{element.totalCost}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="m2Cost">
                <mat-header-cell *matHeaderCellDef> M² Cost </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    ${{element.m2Cost > 0 ? element.m2Cost : (element.totalCost / this.plan.floorArea) | number : '1.0-2'}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="isActive">
                <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon>
                        {{element.isActive ? "check_box" : "check_box_outline_blank"}}
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions"
                          stickyEnd>
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button *ngIf="this.isEdit()"
                            mat-icon-button
                            (click)="editCost(element)"
                            [disabled]="false">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <cb-loading-spinner *ngIf="!costsLoaded"></cb-loading-spinner>
    </div>
    <cb-info-message *ngIf="!this.isEdit() && this.dataSource.data.length < 1"
                     message="There are no Costs specified">
    </cb-info-message>
    <div *ngIf="this.isEdit()"
         class="cb-table cb-padding cb-padding-right-2x  end-center"
         style="border: solid 1px rgba(0, 0, 0, 0.12)">
        <button mat-icon-button
                (click)="this.addCost()"
                [disabled]="false">
            <mat-icon>add_circle</mat-icon>
        </button>
    </div>
</div>
