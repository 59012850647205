<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">
                Bundle Templates
            </h3>
            <button mat-raised-button
                    (click)="newItemClicked();"
                    [disabled]="!canEdit()"
                    color="primary">
                Add Bundle Template
            </button>
        </div>
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="searchResults">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        Bundle Template Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.name}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isActive">
                    <mat-header-cell *matHeaderCellDef>
                        Active
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-icon>
                            {{element.isActive ? "check_box" : "check_box_outline_blank"}}
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-icon-button
                                [disabled]="!canEdit()"
                                (click)="editItemClicked(element)">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-info-message *ngIf="searchResultsLoaded && searchResults.length === 0"
                             [message]="'There are no Bundle Templates to display'"
                             [icon]="'info_circle'"></cb-info-message>
            <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
        </div>
    </div>
</div>
