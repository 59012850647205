import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabRouteDirective } from './tab-route.directive';



@NgModule({
    declarations: [
        TabRouteDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TabRouteDirective
    ]
})
export class CbTabRouteModule { }
