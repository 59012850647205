import {Component, Input} from '@angular/core';

@Component({
    selector: 'cb-td-checkbox[value]',
    styleUrls: ['./../table-cell.scss'],
    template: '<mat-icon class="cb-grey">{{icon}}</mat-icon>'
})
export class TdCheckboxComponent {
    public static readonly CHECKED = 'check_box';
    public static readonly EMPTY = 'check_box_outline_blank';

    public icon = TdCheckboxComponent.EMPTY;

    @Input()
    public set value(v: boolean) {
        this.icon = v ? TdCheckboxComponent.CHECKED : TdCheckboxComponent.EMPTY;
    }
}
