import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotBuildPermissions } from '@app/core/permissions';
import { IProductDto } from '@app/logic/products/interfaces/i.product.dto';
import { IProductMappedItem, ProductLogicService } from '@app/logic/products';
import { IProductLogicService } from '@app/logic/products/interfaces/i.product.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { BundleTemplatesLogicService } from '@app/logic/bundle-templates';
import { TradeTypesLogicService } from '@app/logic/trade-types/trade-types.logic.service';
import { CategoryLogicService } from '@app/logic/product-categories';
import { find, indexOf, forEach } from 'lodash';
import { IProductAttributeDto } from '@app/logic/product-attributes/interfaces/i.product-attribute.dto';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

interface IData {
    mappedItem: IProductMappedItem;
    attribute: IProductAttributeDto;
}

@Component({
    templateUrl: './attribute-options-dialog.component.html',
    styleUrls: ['./attribute-options-dialog.component.scss'],
    providers: [
        LookupService,
        BundleTemplatesLogicService,
        ProductLogicService,
        TradeTypesLogicService,
        CategoryLogicService
    ]
})
export class AttributeOptionsDialogComponent
    extends BaseDialogFormViewDirective<IProductDto, IProductMappedItem, IProductLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '600px';

    public selectedCategory: any;
    public attribute: IProductAttributeDto;
    public attributeValues: Array<any>;
    public newValue = '';

    public selectedOptions: { [id: string]: boolean } = {};

    constructor(
        public readonly dialogRef: MatDialogRef<AttributeOptionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LookupService) public readonly lookupService: LookupService,
        @Inject(LotBuildPermissions) public readonly lotBuildPermissions: LotBuildPermissions,
        protected readonly bundleTemplatesLogicService: BundleTemplatesLogicService,
        protected readonly productCategoriesLogicService: CategoryLogicService,
        protected readonly productLogicService: ProductLogicService,
        protected readonly tradeTypesLogicService: TradeTypesLogicService,
        protected readonly cbDialog: CbDialogService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.attribute = data.attribute;

        if (this.mappedItem.id) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }
        this.setAttributeValues();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        // this.setupWatches(this.$scope);
        if (this.attribute.id || this.attribute.attributeId) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }
        forEach(this.attribute.options, element => {
            this.attribute.selectedOptions[element.id] = true;
        });
    }

    public addValue(): void {
        const existsInNew = indexOf(this.attribute.newValues, this.newValue) !== -1;
        if (this.isAdd()) {
            const attribValues = [];
            const existsInExisting = attribValues && find(attribValues, { name: this.newValue });
            if (existsInNew || existsInExisting) {
                this.toastService.showToast('Attribute Option already exists');
                this.newValue = '';
                return;
            }
            this.attribute.newValues.push(this.newValue);
            this.newValue = '';
        } else {
            this.productCategoriesLogicService.getAttributeValues(this.attribute.category.id, this.attribute.attributeId).subOnce({
                next: (attribValues) => {
                    const existsInExisting = attribValues && find(attribValues, { name: this.newValue });
                    if (existsInNew || existsInExisting) {
                        this.toastService.showToast('Attribute Option already exists');
                        this.newValue = '';
                        return;
                    }
                    this.attribute.newValues.push(this.newValue);
                    this.newValue = '';
                }
            });
        }
    }

    public save = (): void => {
        const observer = {
            next: result => {
                if (result) {
                    this.toastSerivce.saveSuccess();
                    this.dialogRef.close(result);
                    return;
                }
                this.cancel();
            }
        };

        this.mappedItem.saveAttribute(this.attribute, observer).subOnce();
    };

    public setAttributeValues(): void {
        if (this.attributeValues == null && this.attribute.category) {
            this.productCategoriesLogicService.getAttributeValues(this.attribute.category.id, this.attribute.attributeId).subOnce({
                next: x => {
                    this.attributeValues = x;
                }
            });
        }
    }
}
