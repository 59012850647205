<mat-card class="cb-margin flex-row">
    <mat-card-content class="start-start flex-col flex"
                      style="width: 100%"
                      *cbWaitFor="mappedItem">
        <div class="flex-row flex"
             style="width: 100%">
            <div class="cb-margin-right-2x flex-col flex-33">
                <h3 class="mat-title"
                    style="margin-bottom: 0">
                    Release
                </h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-display-value-new label="Release Name"
                                      [value]="mappedItem.name">
                </cb-display-value-new>
                <cb-display-value-new label="Release Type"
                                      [value]="PROJECT_RELEASE_STAGE_TYPE_ENUM[mappedItem.type]">
                </cb-display-value-new>
                <cb-display-value-new label="Contract Type"
                                      [value]="mappedItem.getContractTypeDisplay()">
                </cb-display-value-new>
                <cb-selection-list name="allowedLotContractTypes"
                                   [(ngModel)]="mappedItem.allowedLotContractTypes"
                                   [ngModelOptions]="{standalone: true}"
                                   *ngIf="mappedItem.type == PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand"
                                   [options]="houseAndLandlotContractTypes"
                                   [readonly]="true"
                                   [slim]="true"
                                   label="Allowed Lot Contract Types">
                </cb-selection-list>
                <cb-selection-list name="allowedLotContractTypes"
                                   [(ngModel)]="mappedItem.allowedLotContractTypes"
                                   [ngModelOptions]="{standalone: true}"
                                   *ngIf="mappedItem.type == PROJECT_RELEASE_STAGE_TYPE_ENUM.DesignAndBuild"
                                   [options]="designAndBuildlotContractTypes"
                                   [readonly]="true"
                                   [slim]="true"
                                   label="Allowed Lot Contract Types">
                </cb-selection-list>
                <cb-select label="Business Entity"
                           name="businessEntityId"
                           [readonly]="true"
                           [(ngModel)]="mappedItem.businessEntityId"
                           [options]="businessEntities">
                </cb-select>
                <cb-display-value-new label="Vendor"
                                      [value]="mappedItem.vendorDisplay"
                                      *ngIf="mappedItem.type === PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand && !mappedItem.vendorBusinessEntity?.label && !mappedItem.vendorClientAccount?.label">
                </cb-display-value-new>
                <cb-display-value-new label="Vendor"
                                      [value]="mappedItem.vendorBusinessEntity.label"
                                      *ngIf="mappedItem.type === PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand && mappedItem.vendorBusinessEntity?.label">
                </cb-display-value-new>
                <cb-display-value-new label="Vendor"
                                      [value]="mappedItem.vendorClientAccount.label"
                                      *ngIf="mappedItem.type === PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand && mappedItem.vendorClientAccount?.label">
                </cb-display-value-new>
                <cb-display-value-new label="Selling Agent"
                                      [value]="mappedItem.agentDisplay"
                                      *ngIf="!mappedItem.agentBusinessEntity?.label && !mappedItem.agentBusinessAccount?.label">
                </cb-display-value-new>
                <cb-display-value-new label="Selling Agent"
                                      [value]="mappedItem.agentBusinessEntity.label"
                                      *ngIf="mappedItem.agentBusinessEntity?.label">
                </cb-display-value-new>
                <cb-display-value-new label="Selling Agent"
                                      [value]="mappedItem.agentBusinessAccount.label"
                                      *ngIf="mappedItem.agentBusinessAccount?.label">
                </cb-display-value-new>
                <cb-select label="HL Coordinator"
                           name="houseAndLandCoordinatorId"
                           [readonly]="true"
                           [(ngModel)]="mappedItem.houseAndLandCoordinatorId"
                           [options]="houseAndLandConsults">
                </cb-select>
            </div>
            <div class="cb-margin-right-2x flex-33">
                <h3 class="mat-title"
                    style="margin-bottom: 0">
                    Key Dates
                </h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-user-date-time-new label="Tender Due Date"
                                       [floatingLabel]="true"
                                       [date]="mappedItem.tenderDueDate"
                                       *ngIf="mappedItem.type !== PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand">
                </cb-user-date-time-new>
                <cb-user-date-time-new label="Project Release Due Date"
                                       [date]="mappedItem.dueDate"
                                       [floatingLabel]="true">
                </cb-user-date-time-new>
                <cb-display-value-new label="Land Title Status"
                                      [value]="LAND_TITLE_STATUS_ENUM[mappedItem.landTitleStatus]"
                                      *ngIf="mappedItem.type === PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand && mappedItem.vendorBusinessEntity">
                </cb-display-value-new>
                <cb-checkbox label="Lots Have Title"
                             name="lotsHaveTitle"
                             [(ngModel)]="mappedItem.lotsHaveTitle"
                             [readonly]="true">
                </cb-checkbox>
                <span class="cb-margin">
                </span>
                <cb-user-date-time-new label="Lot Title Date"
                                       [floatingLabel]="true"
                                       [date]="mappedItem.lotTitleDate">
                </cb-user-date-time-new>
                <cb-checkbox label="Lot Title Date Confirmed"
                             name="lotTitleDateConfirmed"
                             [(ngModel)]="mappedItem.lotTitleDateConfirmed"
                             [readonly]="true"
                             *ngIf="!mappedItem.lotsHaveTitle">
                </cb-checkbox>
                <h3 class="mat-title"
                    style="margin-bottom: 0">
                    Insurance
                </h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-display-value-new label="Insurance Interested Party"
                                      [value]="mappedItem.insuranceInterestedParty">
                </cb-display-value-new>
                <cb-display-value-new label="Insurance Interested Party Email"
                                      [value]="mappedItem.insuranceInterestedPartyEmail">
                </cb-display-value-new>
                <cb-display-value-new label="Contract Works Insurance will be maintained at">
                    {{this.mappedItem.isProjectLevelInsurance ? 'Project' : 'Lot'}} Level
                </cb-display-value-new>
                <div *ngIf="mappedItem.isProjectLevelInsurance">
                    <cb-user-date-time-new label="Insurance Start Date"
                                           name="startObject"
                                           [floatingLabel]="true"
                                           [(ngModel)]="mappedItem.insurance.start"
                                           *ngIf="mappedItem.insurance.end">
                    </cb-user-date-time-new>
                    <cb-user-date-time-new label="Insurance End Date"
                                           name="startObject"
                                           [floatingLabel]="true"
                                           [(ngModel)]="mappedItem.insurance.start"
                                           *ngIf="mappedItem.insurance.end">
                    </cb-user-date-time-new>
                </div>
            </div>
            <div class="flex-33"
                 *ngIf="mappedItem.type !== PROJECT_RELEASE_STAGE_TYPE_ENUM.DesignAndBuild">
                <h3 class="mat-title"
                    style="margin-bottom: 0">
                    Marketing
                </h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-display-value-new label="Marketing Type"
                                      [value]="MARKETING_TYPE_ENUM[mappedItem.marketingType]">
                </cb-display-value-new>
                <cb-user-date-time-new label="Market Release Date"
                                       name="marketReleaseDate"
                                       [floatingLabel]="true"
                                       [date]="mappedItem.marketReleaseDate">
                </cb-user-date-time-new>
                <cb-display-value-new label="Showhome Level"
                                      [value]="SHOW_HOME_LEVEL_ENUM[mappedItem.showHomeLevel]">
                </cb-display-value-new>
                <cb-user-date-time-new label="Showhome Completion Date"
                                       name="showHomeCompletionDate"
                                       [floatingLabel]="true"
                                       [date]="mappedItem.showHomeCompletionDate"
                                       *ngIf="mappedItem.isShowHomeSelected">
                </cb-user-date-time-new>
            </div>
        </div>
        <cb-project-team-table class="flex"
                               style="width: 100%"
                               [mappedItem]="projectMappedItem"></cb-project-team-table>
    </mat-card-content>
</mat-card>
