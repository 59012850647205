import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

interface IData {
    dialogHeading: string;
}

@Component({
    selector: 'cb-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

    @Input() public disableCloseButtonFocus = false;
    @Input() public dialogHeading: string;
    @Input() public canClose = true;
    @Output() public readonly cancelDialog: EventEmitter<any> = new EventEmitter();

    constructor(
        public readonly dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) {
    }

    public ngOnInit(): void {
        if (!this.dialogHeading) {
            this.dialogHeading = this.data.dialogHeading;
        }
    }

    public cancel(isFromCloseIcon: boolean): void {
        if (!this.canClose) {
            return;
        }
        if (this.cancelDialog.observers.length) {
            this.cancelDialog.emit();
        } else {
            this.dialogRef.close(isFromCloseIcon ? undefined : false);
        }
    }
}
