import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { IProjectReleaseLotDto, IProjectReleaseStageDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { RELEASE_LOTS_ROUTE } from './manage-release-lots-route';

@Component({
    selector: 'cb-manage-project-release-stage-lots',
    templateUrl: './release-lots.component.html',
    styleUrls: ['./release-lots.component.scss']
})
export class ManageProjectReleaseStageLotsComponent implements OnInit {
    @Input() public mappedItem: IReleaseStageMappedItem;
    @Input() public selectedTabIndex: number;

    public lots: IProjectReleaseLotDto[];
    public projectReleases: IProjectReleaseStageDto[];
    public RELEASE_LOTS_ROUTE = RELEASE_LOTS_ROUTE;

    constructor(
        private readonly releaseStagesLogicService: ReleaseStagesLogicService,
        private route: ActivatedRoute,
    ) { }

    public ngOnInit(): void {
        if (this.mappedItem) {
            this.releaseStagesLogicService.getProjectReleaseStagesByProjectId(this.mappedItem.projectId).subOnce(results => {
                this.projectReleases = results;
            });
        }
    }

}
