import {Component, Input, OnInit} from '@angular/core';

import {ActivatedRoute} from '@angular/router';
import {ClientAccountLogicService} from '@app/logic/client-account/client-account.logic.service';
import {IClientAccountMappedItem} from '@app/logic/client-account/interfaces/i.client-account.mapped';
import {LEAD_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {PermissionsPermissions} from '@app/core/permissions';
import {switchMap} from 'rxjs';
import {toPromise} from 'cb-hub-lib';
import {ISkinnyLeadDto} from '@app/logic/leads';

@Component({
    selector: 'cb-client-account-leads',
    templateUrl: './client-account-leads.component.html',
    styleUrls: ['./client-account-leads.component.scss']
})
export class ClientAccountLeadsComponent implements OnInit {

    @Input() public account: IClientAccountMappedItem;

    public LEAD_STATUS_ENUM = LEAD_STATUS_ENUM;

    public readonly displayedColumns: string[] = [
        'name',
        'status',
        'createdDate',
        'noContacts',
        'mainContact',
        'lotInterests',
        'buildingConsultant',
        'lastCallBackDate',
        'lostDate',
        'actions'
    ];

    public accountLeads: ISkinnyLeadDto[];
    public accountLeadsLoaded = false;

    constructor(
        public readonly dialogRef: MatDialog,
        protected readonly clientAccountLogicService: ClientAccountLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly route: ActivatedRoute,
        public readonly navigation: NavigationService,
    ) { }

    public ngOnInit(): void {

        toPromise(this.route.params.pipe(
            switchMap((params: { id: number }) => {
                return this.clientAccountLogicService.getLeads(params.id);
            })
        )).then((mappedItem: ISkinnyLeadDto[]) => {
            this.accountLeads = mappedItem;
            this.accountLeadsLoaded = true;
        });

    }

    public viewLead(id: string, event: MouseEvent): void {
        this.navigation.navigate([`leads/${id}/details`], event);
    }
}
