import { BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM, ILotDto, LOT_TYPE_ENUM, PURCHASE_ORDER_STATUS_ENUM, SSR_STATE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BuildProgrammeActivityIconClass, BuildProgrammeActivityIcons } from './build-programme-activity-icons';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { IBuildProgrammeActivityMappedItem } from '@app/logic/build-programme-activity';
import { Subscription } from 'rxjs';
import { isNullOrWhiteSpace } from 'cb-hub-lib';

interface IIconDetails {
    visible: boolean;
    icon: string;
    class: string;
    text: string;
}
@Component({
    selector: 'cb-lot-build-programme-activity-icons',
    templateUrl: './lot-build-programme-activity-icons.component.html',
    styleUrls: ['./lot-build-programme-activity-icons.component.scss']
})
export class LotBuildProgrammeActivityIconsComponent implements OnInit, OnDestroy {

    @Input() public readonly lotDto: ILotDto;
    @Input() public readonly buildProgrammeActivity: IBuildProgrammeActivityMappedItem;

    public readonly actvityStatus = new ComputedProperty<IIconDetails>(() => {

        const isManualOrder = this.buildProgrammeActivity?.isFromManualPurchaseOrder || this.buildProgrammeActivity?.isTransformedToManualOrder;

        const result = {
            visible: true,
            icon: 'checkbox-multiple-blank-outline',
            class: BuildProgrammeActivityIconClass.LightGrey,
            text: 'SSR has not been generated'
        };

        if (this.buildProgrammeActivity?.statusId === BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned) {
            result.icon = 'delete-forever';
            result.class = BuildProgrammeActivityIconClass.Error;
            result.text = 'Abandoned';
            return result;
        }

        else if (!isManualOrder &&
            this.buildProgrammeActivity?.ssrStateId === SSR_STATE_ENUM.None &&
            this.buildProgrammeActivity?.activity?.showDraftActivityToSupplier) {
            result.icon = 'checkbox-multiple-blank-outline';
            result.class = BuildProgrammeActivityIconClass.Error;
            result.text = 'SSR needs to be generated for Supplier preview';
            return result;
        }


        if (isManualOrder && this.buildProgrammeActivity?.manualOrderStatusId) {
            switch (this.buildProgrammeActivity?.manualOrderStatusId) {
                case PURCHASE_ORDER_STATUS_ENUM.Draft:
                    result.icon = BuildProgrammeActivityIcons.FileDocumentBoxMultipleOutline;
                    result.class = BuildProgrammeActivityIconClass.Grey;
                    result.text = 'Draft';
                    break;
                case PURCHASE_ORDER_STATUS_ENUM.SubmittedForApproval:
                    result.icon = BuildProgrammeActivityIcons.FileDocumentBoxMultipleOutline;
                    result.class = BuildProgrammeActivityIconClass.Warn;
                    result.text = 'Submitted for Approval';
                    break;
                case PURCHASE_ORDER_STATUS_ENUM.Confirmed:
                    result.icon = BuildProgrammeActivityIcons.FileDocumentBoxMultiple;
                    result.class = BuildProgrammeActivityIconClass.Accent;
                    result.text = 'Confirmed';
                    break;
                case PURCHASE_ORDER_STATUS_ENUM.Completed:
                    result.icon = BuildProgrammeActivityIcons.FileDocumentBoxMultiple;
                    result.class = BuildProgrammeActivityIconClass.Primary;
                    result.text = 'Completed';
                    break;
            }
        } else if (this.buildProgrammeActivity?.ssrStateId) {
            switch (this.buildProgrammeActivity?.ssrStateId) {
                case SSR_STATE_ENUM.Draft:
                    result.icon = BuildProgrammeActivityIcons.FileDocumentBoxMultipleOutline;
                    result.class = BuildProgrammeActivityIconClass.Grey;
                    result.text = 'Draft';
                    break;
                case SSR_STATE_ENUM.Confirmed:
                    result.icon = BuildProgrammeActivityIcons.FileDocumentBoxMultiple;
                    result.class = BuildProgrammeActivityIconClass.Accent;
                    result.text = 'Confirmed';
                    break;
                case SSR_STATE_ENUM.Completed:
                    result.icon = BuildProgrammeActivityIcons.FileDocumentBoxMultiple;
                    result.class = BuildProgrammeActivityIconClass.Primary;
                    result.text = 'Completed';
                    break;
            }
        }

        return result;
    });

    public readonly poDownloadStatus = new ComputedProperty<IIconDetails>(() => {
        const showForManualOrder = this.buildProgrammeActivity?.manualOrderStatusId != null
            && this.buildProgrammeActivity?.manualOrderStatusId !== PURCHASE_ORDER_STATUS_ENUM.Cancelled;
        const showForSsr = this.buildProgrammeActivity?.ssrStateId != null
            && this.buildProgrammeActivity?.ssrStateId !== SSR_STATE_ENUM.None
            && this.buildProgrammeActivity?.ssrStateId !== SSR_STATE_ENUM.Cancelled;
        const hasDownloaded = !isNullOrWhiteSpace(this.buildProgrammeActivity?.latestPortalSsrDownloadDate)
            && Date.parse(this.buildProgrammeActivity.latestPortalSsrDownloadDate) > Date.parse('1900-01-01');
        const hasDownloadedLatest = this.buildProgrammeActivity?.hasDownloadedCurrentVersion ?? false;
        return {
            visible: showForManualOrder || showForSsr,
            class: hasDownloaded
                ? hasDownloadedLatest
                    ? BuildProgrammeActivityIconClass.Primary
                    : BuildProgrammeActivityIconClass.Warn
                : BuildProgrammeActivityIconClass.Grey,
            icon: 'inbox-arrow-down',
            text: hasDownloaded
                ? hasDownloadedLatest
                    ? 'The latest PO / SSR has been downloaded from Supplier Portal'
                    : 'A previous version of the PO / SSR has been downloaded from the Supplier Portal'
                : 'PO / SSR has NOT been downloaded from Supplier Portal',
        };
    });

    public readonly complianceStatus = new ComputedProperty<IIconDetails>(() => {
        const result = {
            visible:
                this.buildProgrammeActivity?.activity.hasCompliance
                && this.buildProgrammeActivity?.statusId !== BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned
                && (this.buildProgrammeActivity?.statusId === BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Confirmed
                    || this.buildProgrammeActivity?.statusId === BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Complete
                    || this.buildProgrammeActivity?.statusId === BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.None
                    || this.buildProgrammeActivity?.ssrStateId === SSR_STATE_ENUM.Draft)
                && !this.buildProgrammeActivity?.isComplianceDoneOrNotRequired,
            class: BuildProgrammeActivityIconClass.Grey,
            icon: 'file-alert-outline',
            text: 'Compliance Required',
        };

        if (this.buildProgrammeActivity?.complianceRequiresReview) {
            result.visible = true;
            result.class = BuildProgrammeActivityIconClass.Accent;
            result.text = 'Compliance Requires Review';
            return result;
        }

        if (this.buildProgrammeActivity?.isComplianceRejected) {
            result.visible = true;
            result.class = BuildProgrammeActivityIconClass.Error;
            result.text = 'Compliance Rejected';
            return result;
        }

        if (this.buildProgrammeActivity?.isComplianceDone) {
            result.visible = true;
            result.class = BuildProgrammeActivityIconClass.Primary;
            result.icon = 'file-check';
            result.text = 'Compliance Approved';
            return result;
        }

        return result;
    });

    public readonly pausedStatus = new ComputedProperty(() => {
        const primaryConsentRequired = !this.buildProgrammeActivity?.activity?.canPerformPriorToBuildingConsent
            && this.buildProgrammeActivity?.lotId === this.lotDto?.id
            && !this.lotDto?.hasGrantedPrimaryBuildingConsent;
        const qsConsentRequired = !this.buildProgrammeActivity?.activity?.canPerformBeforeQsConsentTakeOffCheck && !this.buildProgrammeActivity?.hasQsConsent;

        const text = [
            (primaryConsentRequired && 'Primary Building Consent isn’t granted for the Lot'),
            (qsConsentRequired && 'QS Consent has not been granted for the Lot'),
        ].filter(x => x && !isNullOrWhiteSpace(x))
            .join(' & ');
        return {
            visible: primaryConsentRequired || qsConsentRequired,
            class: BuildProgrammeActivityIconClass.Error,
            icon: 'pause-circle-outline',
            text,
        };
    });

    public readonly blockLevelActivityStatus = new ComputedProperty<IIconDetails>(() => {
        const showblockLevelActivityIcon = this.buildProgrammeActivity?.isBlockLevelActivity;

        return {
            visible: showblockLevelActivityIcon,
            class: BuildProgrammeActivityIconClass.Grey,
            icon: 'page-layout-header',
            text: 'Block level Activity',
        };
    });

    public lotHasUnits(): boolean {
        return this.lotDto.lotType === LOT_TYPE_ENUM.ApartmentBlock ||
            this.lotDto.lotType === LOT_TYPE_ENUM.TerracedBlock ||
            this.lotDto.lotType === LOT_TYPE_ENUM.Duplex;
    }

    public readonly needsVariationAssociatedStatus = new ComputedProperty<IIconDetails>(() => {
        const needsVariationAssociatedIcon = this.buildProgrammeActivity?.rules?.needsVariationAssociated;

        return {
            visible: needsVariationAssociatedIcon,
            class: BuildProgrammeActivityIconClass.Warn,
            icon: 'file-document-edit',
            text: 'You must associate a Variation to this Manual Purchase Order',
        };
    });

    public readonly hasUnknownRateStatus = new ComputedProperty<IIconDetails>(() => {
        const hasUnknownRateIcon = this.buildProgrammeActivity?.rules?.hasUnknownRate;

        return {
            visible: hasUnknownRateIcon,
            class: BuildProgrammeActivityIconClass.Warn,
            icon: 'help-rhombus',
            text: 'Purchase Order has Unknown Rate',
        };
    });

    private readonly $subs = new Subscription();

    public ngOnInit(): void {
        this.recomputeProps();
        this.$subs.add(
            this.buildProgrammeActivity.CHANGED.subscribe(this.recomputeProps)
        );
    }

    public ngOnDestroy(): void {
        this.$subs.unsubscribe();
    }

    private readonly recomputeProps = (): void => {
        this.actvityStatus.recompute();
        this.poDownloadStatus.recompute();
        this.complianceStatus.recompute();
        this.pausedStatus.recompute();
        this.blockLevelActivityStatus.recompute();
        this.needsVariationAssociatedStatus.recompute();
        this.hasUnknownRateStatus.recompute();
    };

}
