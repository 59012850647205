<cb-dialog dialogHeading="{{getTitle()}}">
    <form class="flex-col flex"
          #addProductToBundleForm="ngForm">
        <cb-product-autocomplete-scroller class="flex"
                                          #autocompleteControl
                                          label="Product"
                                          name="product"
                                          [displayCategories]="true"
                                          [productType]="PRODUCT_SEARCH_TYPE_ENUM.ExcludeBundles"
                                          [(ngModel)]="selectedProduct"
                                          (optionSelected)="productSelected($event)"
                                          [hideCompositeItems]="true"
                                          required="true">
        </cb-product-autocomplete-scroller>

        <!-- <cb-display-value-new label="Product"
                              *ngIf="bundleItem"
                              [value]="bundleItem.offering">
        </cb-display-value-new> -->
        <cb-input type="number"
                  name="quantity"
                  label="Quantity"
                  maxlength="10"
                  [required]="true"
                  [(ngModel)]="quantity">
        </cb-input>
        <cb-checkbox name="isChangeable"
                     label="Is Changeable on Lot Specification"
                     [(ngModel)]="isChangeable">
        </cb-checkbox>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="addProductToBundleForm.pristine || addProductToBundleForm.invalid"
                (click)="saveBundleProduct()">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
