import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ITakeOffCompareItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { clone, sortBy } from 'lodash';
import { map, Observable } from 'rxjs';


@Component({
    selector: 'cb-takeoff-rate-variations-dialog',
    templateUrl: './takeoff-rate-variations-dialog.component.html',
    styleUrls: ['./takeoff-rate-variations-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeoffRateVariationsDialogComponent {
    public static readonly MIN_WIDTH = '500px';
    public rateVariations$: Observable<ITakeOffCompareItemDto[]>;
    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<TakeoffRateVariationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { rateVariations$: Observable<ITakeOffCompareItemDto[]> },
    ) {
        this.rateVariations$ = data.rateVariations$.pipe(
            map(rateVariations => this._rateVariationDefaults(rateVariations))
        );
    }

    public doneClicked = (): void => {
        this.dialogRef.close(this.rateVariations$);
    };

    private _rateVariationDefaults(originalVariations: ITakeOffCompareItemDto[]): ITakeOffCompareItemDto[] {
        const variations = this._setQuotedVariationsPriceOption(clone(originalVariations));

        return this._orderRateVariations(variations);
    }

    private _setQuotedVariationsPriceOption(variations: ITakeOffCompareItemDto[]): ITakeOffCompareItemDto[] {
        for (const variation of variations) {
            // eslint-disable-next-line eqeqeq
            if (variation.quoteLineId == undefined) { continue; }
            variation.priceOption = 1;
        }
        return variations;
    }

    private _orderRateVariations(variations: ITakeOffCompareItemDto[]): ITakeOffCompareItemDto[] {
        return sortBy(variations, (variation) => {
            return variation.name;
        });
    }
}
