import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ToastService} from '@app/core/services/toast/toast.service';
import {TeamsLogicService} from '@app/logic/teams/teams.logic.service';
import {ActivatedRoute} from '@angular/router';
import {map, Observable, of, Subject, Subscriber, Subscription} from 'rxjs';
import {UserCacheItem} from '@app/core/services/user-cache/user-cache-item';
import {UserCacheService} from '@app/core/services/user-cache/user-cache.service';
import {orderBy} from 'lodash';
import {DesignTeamPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {IDesignTeamPreconsentPlansSearch} from '@app/core/services/user-cache/user-cache-areas';
import {DESIGN_SCHEME_STATUS_ENUM, IDesignSchemeDocumentDto, IPreConsentPlanDocumentDto, PRECONSENT_PLAN_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {DesignTeamWorkLoadDialogComponent} from '../work-load-dialog/design-team-work-load-dialog';
import {PreConsentPlansLogicService} from '@app/logic/pre-consent-plans';

@Component({
    selector: 'cb-design-team-preconsent-plans',
    templateUrl: './design-team-preconsent-plans.component.html',
    styleUrls: ['./design-team-preconsent-plans.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignTeamPreconsentPlansComponent implements OnDestroy, OnInit {

    public resultOrders$ = of([
        { id: 'jobNumber', label: 'Job Number' },
        { id: 'assignedTo', label: 'Assigned To' },
        { id: 'createdDate', label: 'Created Date' },
        { id: 'dueDate', label: 'Due Date' },
        { id: 'status', label: 'Status' }
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    private _subscriptions = new Subscription();
    public searchEnabled = false;
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public teamCode: string;
    public loaded = false;
    public cacheLoaded$ = new Subject();
    public results: IDesignSchemeDocumentDto[] = [];
    public readonly allUsers = '999';
    public readonly allStatuses = 999;
    public readonly unassigned = undefined;

    public preconsentPlanStatuses$ = of(PRECONSENT_PLAN_STATUS_ENUM.toLookup())
        .pipe(
            map(preconsentPlanstatuses => {
                const orderedPreconsentPlanstatuses = orderBy(preconsentPlanstatuses, tag => tag.label.toLowerCase());
                return orderedPreconsentPlanstatuses;
            })
        );

    public hasPermissionsToEdit$ = of(this.designTeamPermissions.canEditScheme());

    public canEdit$(scheme: IDesignSchemeDocumentDto): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            subscriber.next(scheme.statusId !== DESIGN_SCHEME_STATUS_ENUM.Accepted);
        });
    }

    public teamUsersOptions$ = this.teamsLogicService
        .loadAllMembersByKey('DESIGNTEAM').pipe(
            map(users => {
                users = orderBy(users, 'firstName');
                users.unshift({ id: this.allUsers, label: 'All' } as any);
                return users;
            })
        );

    public get searchFiltersCache(): UserCacheItem<IDesignTeamPreconsentPlansSearch> {
        return this.userCacheService.designTeamPreconsentPlansSearch;
    }

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: PreConsentPlansLogicService,
        private readonly toastService: ToastService,
        public cdRef: ChangeDetectorRef,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
        private readonly dialogService: CbDialogService,
        public readonly designTeamPermissions: DesignTeamPermissions,
        private readonly navigationService: NavigationService
    ) {

    }

    public ngOnInit(): void {
        this._initSearchFiltersCache();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public fetchResults(): Observable<IPreConsentPlanDocumentDto[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.$getSearchList(this._queryParams);
    }

    public hasPermissionsToEditScheme = (): boolean => {
        if (this.designTeamPermissions) {
            return this.designTeamPermissions.canEditScheme();
        }

        return false;
    };

    public getStreetAddress(preConsentPlan: IPreConsentPlanDocumentDto): string {
        return `${preConsentPlan.addressStreet}, ${preConsentPlan.addressCity}`;
    }

    public openDesignTeamWorkLoadDialog(): void {
        this.dialogService
            .open(DesignTeamWorkLoadDialogComponent)
            .afterClosed()
            .subOnce();
    }

    public viewPlan(lotId: number): void {
        if (lotId) {
            this.navigationService.navigate([`/lots/${lotId}/design/pre-consent`]);
        }
    }

    private get _queryParams(): IDesignTeamPreconsentPlansSearch {
        return this.searchEnabled ?
            {
                ...this.searchFiltersCache.copyData(),
                userId: this.searchFiltersCache.data.userId === this.allUsers ?
                    undefined :
                    this.searchFiltersCache.data.userId ?
                        this.searchFiltersCache.data.userId :
                        undefined,
                statusId: (this
                    .searchFiltersCache.data.statusId as any) === this.allStatuses ?
                    undefined :
                    this.searchFiltersCache.data.statusId,
                currentPage: this.currentPage
            } :
            undefined;
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    public _initSearchFiltersCache(): void {
        this.searchFiltersCache.init().then(() => {
            this.cacheLoaded$.next(true);
            this.searchEnabled = true;
            this._onSearchFiltersChanged();
            this._subscriptions.add(
                this.searchFiltersCache.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );

            this.searchFiltersCache.silentData.canSeeAll = this.designTeamPermissions.canSeeAll();
            if (!this.searchFiltersCache.silentData.canSeeAll) {
                this.searchFiltersCache
                    .silentData
                    .userId = this.searchFiltersCache.currentUserId;
            }
        });
    }
}
