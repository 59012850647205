<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-col flex">
    <div class="flex-col flex-100">
        <form class="cb-margin-top flex-row">
            <div class="flex-col flex">
                <div class="flex-wrap">
                    <div class="cb-margin-right flex-row">
                        <mat-icon class="cb-margin-top-2x cb-margin-bottom cb-margin-right">search</mat-icon>
                        <cb-input type="text"
                                  label="Search"
                                  [(ngModel)]="userCacheItem.data.query"
                                  name="search">
                        </cb-input>
                    </div>
                    <cb-select class="cb-margin-right"
                               label="Type"
                               [(ngModel)]="userCacheItem.data.orderType"
                               name="orderType"
                               [options]="resultType">
                    </cb-select>
                    <cb-select-list class="cb-margin-right"
                                    label="Status"
                                    [multiple]="true"
                                    [allOptions]="orderStatus"
                                    [value]="userCacheItem.data.status"
                                    (selectChanged)="userCacheItem.data.status = $event.value">
                    </cb-select-list>
                    <cb-select class="cb-margin-right"
                               label="Order"
                               [(ngModel)]="userCacheItem.data.order"
                               name="order"
                               [options]="resultOrders">
                    </cb-select>
                    <cb-select class="cb-margin-right"
                               label="Direction"
                               [(ngModel)]="userCacheItem.data.direction"
                               name="direction"
                               [options]="resultDirections">
                    </cb-select>
                    <span class="flex"></span>
                    <button class="form-btn cb-margin-right-2x"
                            mat-raised-button
                            color="primary"
                            *ngIf="canEditLotContingencyAmount()"
                            (click)="editContingencyAmount()">
                        Edit Contingency Amount
                    </button>
                    <button class="form-btn"
                            mat-raised-button
                            color="primary"
                            *ngIf="canCreate()"
                            (click)="createManualOrder()">
                        Create Manual Order
                    </button>
                </div>
                <div class="cb-margin-top flex-row"
                     *ngIf="lotContingencyInfo">
                    <cb-currency class="cb-margin-right-2x"
                                 name="contingencyAmount"
                                 label="Contingency Amount"
                                 [readonly]="true"
                                 [(ngModel)]="lotContingencyInfo.contingencyAmount">
                    </cb-currency>
                    <cb-currency name="contingencyAmountRemaining"
                                 label="Contingency Amount Remaining"
                                 [readonly]="true"
                                 [(ngModel)]="lotContingencyInfo.contingencyAmountRemaining">
                    </cb-currency>
                </div>
            </div>
        </form>
        <div class="cb-table cb-margin-top">
            <mat-table infiniteScroll
                       [dataSource]="searchResults"
                       [immediateCheck]="false"
                       [fromRoot]="true"
                       [infiniteScrollContainer]="'#lot-view-tabscontent'"
                       (scrolled)="doSearch()">
                <ng-container matColumnDef="orderNumber">
                    <mat-header-cell *matHeaderCellDef> PO # </mat-header-cell>
                    <mat-cell *matCellDef="let element;">
                        {{element.orderNumber || '-'}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="poDate">
                    <mat-header-cell *matHeaderCellDef> PO Date </mat-header-cell>
                    <mat-cell *matCellDef="let element;"> {{element.orderDate | date}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="businessAccount">
                    <mat-header-cell *matHeaderCellDef> Business Account </mat-header-cell>
                    <mat-cell *matCellDef="let element;"> {{element.supplierName}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="activity">
                    <mat-header-cell *matHeaderCellDef> Activity </mat-header-cell>
                    <mat-cell *matCellDef="let element;"> {{element.activityCode}} - {{element.activityName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef> Amount (Ex GST) </mat-header-cell>
                    <mat-cell *matCellDef="let element;"> {{element.orderSubtotal | currency}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element;">
                        {{ PURCHASE_ORDER_STATUS_ENUM[element.statusId] }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element;">
                        <button mat-icon-button
                                (click)="element.download()"
                                [disabled]="!element.canDownload">
                            <mat-icon>file_download</mat-icon>
                        </button>
                        <button mat-icon-button
                                *ngIf="element.isManualOrder"
                                (click)="viewManualOrder(element);">
                            <mat-icon>open_in_browser</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
            <cb-info-message *ngIf="searchResultsLoaded && searchResults?.length < 1"
                             message="No results found"></cb-info-message>
        </div>
    </div>
</div>
