<mat-form-field class="flex"
                *ngIf="!readonly"
                [ngClass]="{'cb-soft-required': softRequired && !required }">
    <mat-label>{{label}}</mat-label>
    <mat-select [cbRequireSelectionValidator]="required"
                [required]="required"
                [disabled]="disabled"
                [ignoreDirty]="ignoreDirty"
                [name]="internalName"
                [(ngModel)]="value">
        <mat-option *ngFor="let opt of boolOptions"
                    [value]="opt.id">
            {{opt.label}}
        </mat-option>
    </mat-select>
</mat-form-field>
<div class="flex"
     *ngIf="readonly">
    <cb-display-value-new class="flex"
                          [label]="label">
        {{value?.label}}
    </cb-display-value-new>
</div>
