export enum GlobalSettingsType {
    GstName = 1,
    GstPercentage = 2,
    MinimumSalePurchaseDeposit = 3,
    NextClientSaleNumber = 4,
    NextJobNumber = 6,
    NextQuestionNumber = 7,
    NextSsrNumber = 8,
    ClientBuildVersion = 9,
    SendSupplierDailyEmail = 10,
}
