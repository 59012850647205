import { BaseMappedItem } from '../base/base-mapped-item';
import { IAiRateAnswerDto, IAiRateResponseDto, IAiUsageStatsDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { DtoProp } from '../base/dto-prop.decorator';
import { IAiRateMappedItem } from '@app/logic/ai-rate/interfaces/i.ai-rate.mapped';
import { IAiRateLogicService } from '@app/logic/ai-rate/interfaces/i.ai-rate-logic.service';

export class AiRateMappedItem
    extends BaseMappedItem<IAiRateResponseDto, IAiRateMappedItem, IAiRateLogicService>
    implements IAiRateMappedItem {

    @DtoProp public requestId: string;
    @DtoProp public fullExplanation: string;
    @DtoProp public answer: IAiRateAnswerDto;
    @DtoProp public usageStats: IAiUsageStatsDto;

    constructor(
        sourceData: IAiRateResponseDto,
        logicService: IAiRateLogicService
    ) {
        super(
            sourceData,
            logicService,
            AiRateMappedItem,
            { },
        );
    }
}
