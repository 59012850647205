import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { INotesLogicService } from './interfaces/i.note-logic.service';
import { INoteDto } from './interfaces/i.note.dto';
import { INoteMappedItem } from './interfaces/i.note.mapped';
import { NoteMappedItem } from './note.mapped';

@Injectable()
export class NotesLogicService
    extends BaseLogicService<INoteDto, INoteMappedItem>
    implements INotesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'notes',
            NoteMappedItem,
        );
    }

    public saveNote(entityUri: string, entityId: number | string, note: INoteDto): Observable<INoteDto> {
        if (note.id > 0) {
            return this.$http.post(`${entityUri}/${entityId}/${this.$baseUri}/${note.id}`, note);
        }
        return this.$http.post(`${entityUri}/${entityId}/${this.$baseUri}`, note);
    }

    public deleteNote(entityUri: string, entityId: number | string, note: INoteDto): Observable<INoteDto> {
        return this.$http.delete(`${entityUri}/${entityId}/${this.$baseUri}/${note.id}`);
    }

    public createMappedNoteItem(entityUri: string, entityId: number | string, note?: INoteDto): INoteMappedItem {
        const result = this.$createMappedItem(note);
        result.entityUri = entityUri;
        result.entityId = entityId;
        return result;
    }

    public getMappedNotes(entityUri: string, entityId: number | string): Observable<INoteMappedItem[]> {
        return this.$http.get<INoteDto[]>(`${entityUri}/${entityId}/${this.$baseUri}`)
            .pipe(
                map(results => results.map(value => this.createMappedNoteItem(entityUri, entityId, value)))
            );
    }
}
