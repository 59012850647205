import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { ILotCouncilRfiSearch } from '@app/core/services/user-cache/user-cache-areas';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { LotDesignPermissions } from '@app/core/permissions';
import { CouncilRfiLogicService, ICouncilRfiSearchParams } from '@app/logic/council-rfis';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { CouncilRfiSearchService } from '@app/core/services/search/council-rfis-search.service';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { EditCouncilRfiDialogComponent } from '../edit-council-rfi-dialog/edit-council-rfi-dialog.component';
import { CouncilRfiViewService } from '../service/council-rfi-view.service';
import { ICouncilRfiDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ILotMappedItem } from '@app/logic/lots';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@app/core/services/navigation/navigation.service';

@Component({
    selector: 'cb-council-rfi-list',
    templateUrl: './council-rfi-list.component.html',
    styleUrls: ['./council-rfi-list.component.scss'],
    providers: [
        CouncilRfiSearchService,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouncilRfiListComponent extends BaseSearchViewDirective<ILotCouncilRfiSearch> implements AfterViewInit, OnDestroy {

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public get userCacheItem(): UserCacheItem<ILotCouncilRfiSearch> {
        return this.userCacheService.lotCouncilRfiSearch;
    }

    public readonly sub$: Subscription;
    public readonly reloadSub$: Subscription;

    constructor(
        public readonly userCacheService: UserCacheService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly councilRfiLogic: CouncilRfiLogicService,
        public readonly cbDialog: CbDialogService,
        public readonly toastService: ToastService,
        public readonly councilRfiViewService: CouncilRfiViewService,
        public readonly cdRef: ChangeDetectorRef,
        public readonly route: ActivatedRoute,
        private readonly navigationService: NavigationService
    ) {
        super(councilRfiLogic, toastService, userCacheService, USER_CACHE_AREA.LotCouncilRfiSearch);

        this.sub$ = this.councilRfiViewService.councilRfiDeleted$.subscribe((id) => {
            const index = this.searchResults.findIndex(x => x.id === id);
            if (index > -1) {
                this.searchResults.splice(index, 1);
                this.cdRef.detectChanges();
            }
        });

        this.reloadSub$ = this.councilRfiViewService.reloadCounfilRfiList$.subscribe((result) => {
            if (result) {
                this.currentPage = 0;
                this.searchResults = undefined;
                this.noMoreResults = false;
                this.doSearch();
                this.cdRef.detectChanges();
            }
        });
    }

    public ngAfterViewInit(): void {
        this.loadSearchParams();
    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
        this.reloadSub$.unsubscribe();
    }

    public getSearchParams(): ICouncilRfiSearchParams {
        const data = this.userCacheItem.copyData();
        return {
            ...data,
            lotId: this.lotMappedItem.id,
            order: 'id',
            direction: 'desc',
        } as ICouncilRfiSearchParams;
    }

    public canCreateRFI(): boolean {
        return this.lotDesignPermissions.canCreateRFI();
    }

    public addCouncilRfi(): void {
        this.cbDialog.open(EditCouncilRfiDialogComponent, {
            data: {
                councilRfi: this.councilRfiLogic.$createMappedItem({ lotId: this.lotMappedItem.id, councilName: this.lotMappedItem.councilName, }),
                dialogHeading: 'Add Council RFI',
            }
        })
            .afterClosed()
            .subOnce((result: ICouncilRfiDto) => {
                if (result?.id > 0) {
                    this.searchResults.unshift(result);
                    this.cdRef.detectChanges();
                }
            });
    }

    public onReceivedSearchResults = (results: any): void => {
        const params = this.navigationService.getQueryParams();
        if (params?.rfiId) {
            this.councilRfiViewService.loadCouncilRfi(params.rfiId as number, params.qnsId as number);
        }

        if (params?.paramEntityId) {
            this.councilRfiViewService.loadCouncilRfi(params.paramEntityId as number, params.qnsId as number);
        }
        this.cdRef.detectChanges();
    };
}
