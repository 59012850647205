import { AfterViewInit, ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { ProjectPermissions } from '@app/core/permissions';
import { IDocumentPermissions, INotePermissions } from '@app/core/permissions/base.permissions';
import { ContactsLogicService } from '@app/logic/contacts';
import { ProjectsLogicService } from '@app/logic/projects';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';
import { IProjectDetailsDto, IProjectDocumentDto, SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { PROJECT_ROUTE } from '../manage-project-routes';

enum TabIndexes {
    Details = 0,
    ReleaseStages = 1,
    Notes = 2,
    Documents = 3,
    History = 4,
}

@Component({
    selector: 'cb-manage-project',
    templateUrl: './manage-project.component.html',
    styleUrls: ['./manage-project.component.scss'],
})
export class ManageProjectComponent implements AfterViewInit {
    public mappedItem$$: BehaviorSubject<IProjectMappedItem> = new BehaviorSubject(null);

    @Input() public set mappedItem(mappedItem: IProjectMappedItem) {
        if (mappedItem) {
            this.mappedItem$$.next(mappedItem);
        }
    }

    public get mappedItem(): IProjectMappedItem {
        return this.mappedItem$$?.value;
    }

    public dto: IProjectDocumentDto;
    public systemArea = SYSTEM_AREA_ENUM.Project;
    public PROJECT_ROUTE = PROJECT_ROUTE;

    public selectedTabIndex = 0;

    constructor(
        private readonly logicService: ProjectsLogicService,
        private readonly contactsLogicService: ContactsLogicService,
        private route: ActivatedRoute,
        private readonly ngZone: NgZone,
        public readonly projectPermissions: ProjectPermissions,
        public cdRef: ChangeDetectorRef,
    ) {
    }

    public ngAfterViewInit(): void {
        this.route.params.pipe(
            tap(this.setMappedItem),
            switchMap(_ => this.route.url))
            .subOnce(this.setTabIndex);
    }

    private setTabIndex = (urlSegments: UrlSegment[]): void => {
        this.ngZone.run(() => {
            setTimeout(() => {
                switch (urlSegments[urlSegments.length - 1]?.path) {
                    case 'release-stages':
                        this.selectedTabIndex = TabIndexes.ReleaseStages;
                        break;
                    case 'notes':
                        this.selectedTabIndex = TabIndexes.Notes;
                        break;
                    case 'documents':
                        this.selectedTabIndex = TabIndexes.Documents;
                        break;
                    case 'history':
                        this.selectedTabIndex = TabIndexes.History;
                        break;
                    default:
                        this.selectedTabIndex = TabIndexes.Details;
                }
            });
        });
    };

    private setMappedItem = (urlParam: { id: number }): void => {
        this.logicService.$getMappedItem(urlParam.id).subOnce(result => this.mappedItem = result);
    };

    public get leadDto(): IProjectDetailsDto {
        return this?.mappedItem?.$getMappedDtoItem();
    }

    public get notePermissions(): INotePermissions {
        return this.projectPermissions.getNotePermissions();
    }

    public get documentPermissions(): IDocumentPermissions {
        return this.projectPermissions.getDocumentPermissions();
    }

    public canViewHistory(): boolean {
        return this.projectPermissions && this.projectPermissions.canViewHistory();
    }

    public canViewDocuments(): boolean {
        return this.documentPermissions && this.documentPermissions.canViewDocuments();
    }

    public canEditProject(): boolean {
        return this.projectPermissions && this.projectPermissions.canEdit();
    }

    public canViewNotes(): boolean {
        return this.notePermissions && this.notePermissions.canViewNotes();
    }

    public reloadMappedItem(): void {
        this.logicService.$getMappedItem(this.mappedItem?.id).subOnce(result => {
            this.mappedItem$$.next(result);
            this.cdRef.detectChanges();
        });
    }

}
