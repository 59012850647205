import { Component, Input } from '@angular/core';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { IContactDto, LOT_CONTRACT_TYPE_ENUM, MarketStatusEnumId, MARKET_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { ILotInterestPermissions } from '@app/core/permissions/base.permissions';
import { LeadPermissions } from '@app/core/permissions';
import { toCurrency } from '@app/shared/components/forms/currency/currency.util';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { LotsLogicService, ILotMappedItem } from '@app/logic/lots';
import { MatButton } from '@angular/material/button';
import { ViewHouseLandDialogComponent } from '@app/views/house-land/dialogs';
import { map } from 'lodash';
import { AddLotDialogComponent } from './add-lot-dialog';
import { Location } from '@angular/common';
import { LeadLogicService } from '@app/logic/leads';
import { ArrayUtils } from '@app/shared/utils/array.util';
import { ILeadLotInterestDto } from '@app/logic/leads/interfaces/i.lead.lot.interest.dto';

@Component({
    selector: 'cb-lead-house-land-interests-card',
    templateUrl: './lead-house-land-interests-card.component.html',
    styleUrls: ['./lead-house-land-interests-card.component.scss'],
    providers: [
        MatButton
    ],
})
export class LeadHouseLandInterestsCardComponent {
    @Input() public mappedItem: ILeadMappedItem;
    @Input() public canEdit: boolean;
    public permissions: ILotInterestPermissions;
    public marketStatusEnum = MarketStatusEnumId;
    public MARKET_STATUS_ENUM = MARKET_STATUS_ENUM;

    public get sortedMappedItemInterests(): ILeadLotInterestDto | any[] {
        const numericallySortedInterests = this.mappedItem.interests.sort((interest1, interest2) => interest1.lot.lotNumber - interest2.lot.lotNumber);
        const primaryInterestIndex = numericallySortedInterests.findIndex(i => i.isPrimary);
        return ArrayUtils.moveItemInArrayFromIndexToIndex<ILeadLotInterestDto>(numericallySortedInterests, primaryInterestIndex, 0);
    }

    constructor(
        public leadPermissions: LeadPermissions,
        public readonly navigationService: NavigationService,
        private readonly location: Location,
        private readonly leadLogicService: LeadLogicService,
        public readonly cbDialog: CbDialogService,
        public readonly lotLogicService: LotsLogicService
    ) {
        this.permissions = leadPermissions?.getLotInterestPermissions();
    }

    public get isHouseAndLand(): boolean {
        return this.mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand;
    }

    public get isDesignAndLand(): boolean {
        return this.mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    public openAddLotDialog(event): void {
        const existingIds = map(this.mappedItem.interests, (interest) => interest.lot.id);
        this.cbDialog.open(AddLotDialogComponent, {
            data: {
                existingIds,
                lotTypeId: LOT_CONTRACT_TYPE_ENUM.HouseAndLand,
                leadMappedItem: this.mappedItem
            },
            minWidth: 600
        })
            .afterClosed()
            .subOnce(dialogResult => {
                if (dialogResult) {
                    this.mappedItem = this.leadLogicService.$createMappedItem(dialogResult);
                }
            });
    }

    public removeLotInterest(event: Event, interest: ILeadLotInterestDto): void {
        if (!this.canRemoveLotInterest() || interest.isPrimary) {
            return;
        }

        this.cbDialog.confirm({
            dialogHeading: 'Remove Lead Interest',
            message: 'Are you sure you want to remove this Lead Interest?',
            confirmed: () => {
                this.mappedItem.removeInterest(interest?.id);
            }
        });
    }

    public setPrimaryLot(event: Event, interest: ILeadLotInterestDto): void {
        if (!this.canSetLotInterestAsMain() || interest.isPrimary) {
            return;
        }

        this.cbDialog.confirm({
            dialogHeading: 'Change Primary Lead Interest',
            message: 'Are you sure you want to change this Leads Primary Interest?',
            confirmed: () => {
                this.mappedItem.setPrimaryLotInterest(interest?.id);
            }
        });
    }

    public canAddLotInterest(): boolean {
        return this.permissions.canAddLotInterest() && this.canEdit;
    }

    public canRemoveLotInterest(): boolean {
        return this.permissions.canRemoveLotInterest() && this.canEdit;
    }

    public canSetLotInterestAsMain(): boolean {
        return this.permissions.canSetLotInterestasMain() && this.canEdit;
    }

    public viewLot(event: Event, interest: any): void {
        const lot = interest.lot;
        if (interest.isQualified && lot.clientSaleId) {
            this.navigationService.navigate([`/clientsales/view/${lot.clientSaleId}/details`]);
            return;
        }
        this.lotLogicService.$getMappedItem(lot.id).subOnce((mappedItem) => this.openViewDialog(event, mappedItem));
    }

    private openViewDialog = (event, mappedItem: ILotMappedItem): void => {
        this.cbDialog
            .open(ViewHouseLandDialogComponent, {
                data: { canCreateLead: false, canAddExistingLead: false, mappedItem },
                minWidth: 700
            })
            .afterClosed()
            .subOnce((contact: false | IContactDto) => {
                if (contact && !mappedItem) {
                    this.mappedItem?.addContactToLead(contact?.id);
                } else if (contact && mappedItem) {
                    this.mappedItem.$reload().subOnce();
                }
            });
    };

    public getSellPrice(interest: any): string {
        let price = '-';
        if (interest.lot.financials.sellPrice) {
            price = toCurrency(interest.lot.financials.sellPrice);
        }
        return price;
    }

}
