import {Component, Input} from '@angular/core';
import {LotBuildPermissions} from '@app/core/permissions';
import {ISkinnyBuildProgrammeMappedItem, SkinnyBuildProgrammeLogicService} from '@app/logic/build-programme';
import {ILotDto, SYSTEM_AREA_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {lotBuildProgrammedocumentEntityDto} from '@app/logic/documents/dtos/document-entity.dto';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'cb-lot-build-documents',
    templateUrl: './lot-build-documents.component.html',
    styleUrls: ['./lot-build-documents.component.scss'],
})
export class LotBuildDocumentsComponent {

    public readonly systemArea = SYSTEM_AREA_ENUM.BuildProgramme;
    public readonly dtoDefinition = lotBuildProgrammedocumentEntityDto;
    public mappedItem: ISkinnyBuildProgrammeMappedItem;

    public lotDto$$: BehaviorSubject<ILotDto> = new BehaviorSubject(null);
    @Input() public set lotDto(lotDto: ILotDto) {
        if (lotDto) {
            this.lotDto$$.next(lotDto);
            this.buildProgrammeLogic.getMappedItemByLot(lotDto.id).subOnce(x => this.mappedItem = x);
        }
    }

    public get lotDto(): ILotDto {
        return this.lotDto$$?.value;
    }


    public readonly extraCol = [{ label: 'Activity Name', col: 'activityName', props: ['extraInformation', 'supplier'] }];


    constructor(
        public readonly lotBuildPermissions: LotBuildPermissions,
        public readonly buildProgrammeLogic: SkinnyBuildProgrammeLogicService,
    ) { }


}
