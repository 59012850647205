import { Component, Input, OnDestroy } from '@angular/core';
import { IBuildingConsentMappedItem, IResourceConsentMappedItem, BuildingConsentMappedItem } from '@app/logic/lot-consents';
import moment from 'moment';
import { Subscription } from 'rxjs';


@Component({
    selector: 'cb-consent-details',
    templateUrl: './consent-details.component.html',
    styleUrls: ['./consent-details.component.scss']
})
export class ConsentDetailsComponent implements OnDestroy {

    private _mappedItem: IBuildingConsentMappedItem | IResourceConsentMappedItem;
    @Input() public set mappedItem(v: IBuildingConsentMappedItem | IResourceConsentMappedItem) {
        this._mappedItem = v;
        this.setWorkingDaysSinceSubmission();
        this.subscribeToRecompute();
    }
    public get mappedItem(): IBuildingConsentMappedItem | IResourceConsentMappedItem {
        return this._mappedItem;
    }

    @Input() public numberOfRfis = 0;

    public get buildingConsent(): IBuildingConsentMappedItem {
        return this.mappedItem as IBuildingConsentMappedItem;
    }

    public get isBuildingConsent(): boolean {
        return this.mappedItem instanceof BuildingConsentMappedItem;
    }

    public workingDaysSinceSubmission: number;

    private sub$ = new Subscription();

    constructor() {
    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    private subscribeToRecompute(): void {
        this.sub$.unsubscribe();
        this.sub$ = this.mappedItem.$updated.subscribe(() => {
            this.setWorkingDaysSinceSubmission();
        });
    }

    private setWorkingDaysSinceSubmission(): void {
        if (!this.mappedItem.submittedToCouncilDate) {
            this.workingDaysSinceSubmission = 0;
            return;
        }
        this.workingDaysSinceSubmission = moment(this.mappedItem.submittedToCouncilDate).diff(moment(), 'days');
    }

}
