import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { DesignSchemeStatusEnumId, ILotAmenitiesDto, IPricingRevisionDto, PRICING_REVISION_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPricingRevisionMappedItem } from './interfaces/i.pricing-revision.mapped';
import { IPricingRevisionsLogicService } from './interfaces/i.pricing-revisions.logic.service';
import { Observable } from 'rxjs';

export class PricingRevisionMappedItem
    extends BaseMappedItem<IPricingRevisionDto,
    IPricingRevisionMappedItem, IPricingRevisionsLogicService>
    implements IPricingRevisionMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public margin: number;
    @DtoProp public landPrice: number;
    @DtoProp public buildCost: number;
    @DtoProp public qsNotes: string;
    @DtoProp public designSchemeStatus: DesignSchemeStatusEnumId;
    @DtoProp public isEstimatedPrice: boolean;
    @DtoProp public pricingExpiryDate: string;
    @DtoProp public designSchemeId: number;
    @DtoProp public designSchemeName: string;
    @DtoProp public pricingComplexityId: number;
    @DtoProp public amenities: ILotAmenitiesDto;
    @DtoProp public lotDescription: string;
    @DtoProp public lotBuildPrice: number;
    @DtoProp public lotLandPrice: number;
    @DtoProp public lotBuildCost: number;
    @DtoProp public buildPrice: number;
    @DtoProp public jobNumber: string;
    @DtoProp public completedByName: string;
    @DtoProp public completedById: string;
    @DtoProp public statusChangedDate: string;
    @DtoProp public completedDate: string;
    @DtoProp public statusChangedUserId: string;
    @DtoProp public statusChangedUserName: string;
    @DtoProp public finalisedDate: string;
    @DtoProp public finalisedByUserName: string;
    @DtoProp public reviewedDate: string;
    @DtoProp public requestedReviewDate: string;
    @DtoProp public complexityCode: string;
    @DtoProp public contingencyAmount: number;
    @DtoProp public lotNumber: number;
    @DtoProp public lotId: number;
    @DtoProp public revisionNumber: string;
    @DtoProp public reviewRequired: boolean;
    @DtoProp public reviewerName: string;
    @DtoProp public reviewerId: string;
    @DtoProp public name: string;
    @DtoProp public targetWorkHours: number;
    @DtoProp public statusId: number;
    @DtoProp public status: string;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public assignedToUserName: string;
    @DtoProp public createdByUserName: string;
    @DtoProp public assignedToUserId: string;
    @DtoProp public estimatedHours: number;
    @DtoProp public dueBy: string;

    constructor(
        sourceData: IPricingRevisionDto,
        logicService: IPricingRevisionsLogicService
    ) {
        super(sourceData, logicService, PricingRevisionMappedItem);
    }

    public submitForReview(): Observable<IPricingRevisionDto> {
        return this.$logicService.submitForReview(this.id, this.$getMappedDtoItem());
    }

    public reprice(): Observable<IPricingRevisionDto> {
        return this.$logicService.reprice(this.id, this.$getMappedDtoItem());
    }

    public finalise(): Observable<IPricingRevisionDto> {
        return this.$logicService.finalise(this.id, this.$getMappedDtoItem());
    }

    public get canReprice(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.Estimated
            || this.statusId === PRICING_REVISION_STATUS_ENUM.InProgress;
    }

    public get isAwaitingReview(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.WaitingForReview;
    }

    public get hasBeenReviewed(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.Completed
            && this.reviewerId !== ''
            && this.reviewedDate !== undefined;
    }

    public get isOnHold(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.OnHold;
    }

    public get inQueue(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.InQueue;
    }

    public get isInProgress(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.InProgress;
    }

    public get isCompleted(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.Completed;
    }

    public get isEstimated(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.Estimated;
    }

    public get isCancelled(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.Cancelled;
    }

    public get isDeclined(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.Declined;
    }

    public get isAccepted(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.Accepted;
    }

    public get isQueued(): boolean {
        return this.statusId === PRICING_REVISION_STATUS_ENUM.InQueue;
    }

    public get wasReviewed(): boolean {
        return this.reviewerId !== '' && this.reviewedDate !== undefined;
    }

    public get canEdit(): boolean {
        return (this.statusId === PRICING_REVISION_STATUS_ENUM.InQueue
            || this.statusId === PRICING_REVISION_STATUS_ENUM.InProgress
            || this.statusId === PRICING_REVISION_STATUS_ENUM.OnHold
            || this.statusId === PRICING_REVISION_STATUS_ENUM.WaitingForReview
            || this.statusId === PRICING_REVISION_STATUS_ENUM.ResponseRequired
            || this.statusId === PRICING_REVISION_STATUS_ENUM.Accepted
            || this.statusId === PRICING_REVISION_STATUS_ENUM.Estimated);
    }

    public get canChangeStatus(): boolean {
        return this.pricingComplexityId !== undefined
            && this.canEdit
            && this.hasAssignedUser();
    }

    public getElapsedTime(): Observable<number> {
        return this.$logicService.getElapsedTime(this.id);
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    private hasAssignedUser(): boolean {
        return (this.assignedToUserId !== '' && this.assignedToUserId !== undefined);
    }
}

