import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BusinessAccountLogicService, BusinessAccountMappedItem } from '@app/logic/business-accounts';

interface IData {
    account: BusinessAccountMappedItem;
}

@Component({
    selector: 'cb-business-account-request-seven-days-dialog',
    templateUrl: './business-account-request-seven-days-dialog.component.html',
    styleUrls: ['./business-account-request-seven-days-dialog.component.scss']
})
export class BusinessAccountRequestSevenDaysDialogComponent {
    public static readonly MIN_WIDTH = '60%';

    public description = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<BusinessAccountRequestSevenDaysDialogComponent>,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
    ) {
    }

    public canSave(): boolean {
        return !!this.data?.account?.id && !!this.description;
    }

    public onSaveClicked(): void {
        if (this.canSave()) {
            this.businessAccountLogicService.requestSevenDays(this.data.account.id, this.description)
                .subOnce(() => this.dialogRef.close());
        }
    }
}
