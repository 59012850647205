<cb-dialog class="flex-col"
           dialogHeading="Lead Contract Type"
           *cbWaitFor="mappedItem">
    <form class="flex-col flex"
          #chooseContractTypeForm="ngForm">
        <mat-radio-group class="flex-col"
                         aria-label="Build Interest"
                         name="lotInterestType"
                         [(ngModel)]="mappedItem.contractType"
                         [required]="true">
            <mat-radio-button [value]="LOT_CONTRACT_TYPE_ENUM.None">To be confirmed</mat-radio-button>
            <mat-radio-button [value]="LOT_CONTRACT_TYPE_ENUM.HouseAndLand">House &amp; Land</mat-radio-button>
            <mat-radio-button [value]="LOT_CONTRACT_TYPE_ENUM.DesignAndLand">Design &amp; Land</mat-radio-button>
            <mat-radio-button [value]="LOT_CONTRACT_TYPE_ENUM.DesignAndBuild">Design &amp; Build</mat-radio-button>
        </mat-radio-group>
        <cb-lot-autocomplete-scroller *ngIf="mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand"
                                      label="House & Land Lot"
                                      name="houseAndLandLot"
                                      [(ngModel)]="interest.lot"
                                      [availableOnly]="true">
        </cb-lot-autocomplete-scroller>
        <cb-lot-autocomplete-scroller *ngIf="mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand"
                                      label="Design & Land Lot"
                                      name="designAndLandLot"
                                      [(ngModel)]="interest.lot"
                                      [availableOnly]="true"
                                      [lotContractTypes]="[LOT_CONTRACT_TYPE_ENUM.DesignAndLand]">
        </cb-lot-autocomplete-scroller>
        <div class="flex-col"
             *ngIf="mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild">
            <label class="cb-margin-top-2x cb-margin-bottom">Does the client have land?</label>
            <mat-radio-group class="flex-col"
                             aria-label="Does the client have land?"
                             name="dbClientHasLand"
                             [(ngModel)]="dbClientHasLand"
                             [required]="true">
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <div class="flex-col"
                 *ngIf="dbClientHasLand && interest.lot">
                <cb-input label="Lot Number"
                          type="numeric"
                          name="dbLotNumber"
                          [(ngModel)]="interest.lot.lotNumber"
                          [required]="true"></cb-input>
                <cb-address-new name="dbAddress"
                                [(ngModel)]="interest.lot.lotAddress"
                                [required]="true"
                                [hideHeading]="true"
                                [(regionId$)]="regionId$">
                </cb-address-new>
                <cb-location-autocomplete-scroller label="Area"
                                                   name="dbLocation"
                                                   [(ngModel)]="location"
                                                   [required]="true"
                                                   [regionId]="regionId"
                                                   (optionSelected)="onAreaChanged()">
                </cb-location-autocomplete-scroller>
                <cb-select label="Build Consent Council"
                           name="sbBuildConsentCouncil"
                           [(ngModel)]="interest.buildingConsentCouncilId"
                           [options]="councils"
                           [required]="true">
                </cb-select>
                <cb-checkbox label="Client Owns Land"
                             name="dbClientOwnsLand"
                             [(ngModel)]="interest.lot.isOwned">
                </cb-checkbox>
                <cb-select label="Build Type"
                           name="BuildType"
                           [(ngModel)]="interest.buildTypeId"
                           [options]="buildTypes"
                           [required]="true">
                </cb-select>
            </div>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                (cbClick)="save()"
                [clickLimit]="1"
                [disabled]="chooseContractTypeForm.invalid || chooseContractTypeForm.pristine">Create</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
