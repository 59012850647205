<cb-dialog dialogHeading="Select Account">
    <div class="flex-col flex-100">
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="resultList">
                <ng-container matColumnDef="abmCode">
                    <mat-header-cell *matHeaderCellDef> ABM Number </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.abmCode}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{CONTACT_STATUS_ENUM[element.status]}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.legalName }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ACCOUNT_TYPE_ENUM[element.accountType] }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-icon-button
                                (click)="viewAccount(element, $event)">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
</cb-dialog>
