import { Directive, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IBaseMappedItem } from '@app/logic/base/interfaces/i.base-mapped-item';
import { FormMode } from '@app/shared/enums/form';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';

export interface BaseFormViewDirective<DtoType, MappedItemType extends IBaseMappedItem<DtoType, MappedItemType, LogicServiceType>, LogicServiceType> {
    mappedItem?: MappedItemType;
}
@Directive()
export abstract class BaseFormViewDirective<
    DtoType, MappedItemType extends IBaseMappedItem<DtoType, MappedItemType, LogicServiceType>, LogicServiceType> implements OnDestroy {
    public subscriptions = new Subscription();
    /**
     * @deprecated Use formMode$ instead as it works
     * with ChangeDetectionStrategy.OnPush
     */
    public formMode = FormMode.View;

    public formMode$ = new BehaviorSubject<FormMode>(FormMode.View);
    public set formMode$$(value: FormMode) {
        this.formMode$.next(value);
    }

    public get formMode$$(): FormMode {
        return this.formMode$.value;
    }

    /// Use in HTML templates
    public isEdit$ = this.formMode$.pipe(
        map(formMode => formMode === FormMode.Edit)
    );
    /// Disable manually emitting to isEdit$$ - it is piped from formMode$
    /// (emit to formMode$ instead)
    private _isEdit$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    /// For use outside of the HTML template
    public get isEdit$$(): boolean {
        return this._isEdit$.value;
    }

    /// Use in HTML templates
    public isAdd$ = this.formMode$.pipe(
        map(formMode => formMode === FormMode.Add)
    );
    /// Disable manually emitting to isAdd$$ - it is piped from formMode$
    /// (emit to formMode$ instead)
    private _isAdd$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    /// For use outside of the HTML template
    public get isAdd$$(): boolean {
        return this._isAdd$.value;
    }

    /// Use in HTML templates
    public isView$ = this.formMode$.pipe(
        map(formMode => formMode === FormMode.View)
    );
    /// Disable manually emitting to isView$$ - it is piped from formMode$
    /// (emit to formMode$ instead)
    private _isView$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    /// For use outside of the HTML template
    public get isView$$(): boolean {
        return this._isView$.value;
    }

    /// Use in HTML templates
    public isEditOrAdd$ = this.formMode$.pipe(
        map(formMode => formMode === FormMode.Edit ||
            formMode === FormMode.Add)
    );
    /// Disable manually emitting to isEditOrAdd$$ - it is piped from formMode$
    /// (emit to formMode$ instead)
    private _isEditOrAdd$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    /// For use outside of the HTML template
    public get isisEditOrAdd$$(): boolean {
        return this._isEditOrAdd$.value;
    }

    public baseForm?: NgForm;
    public saveInProgress = false;

    public handleSaveError(error: any): any { return error; }
    public handleSaveSuccess(result: any): any { return result; }

    constructor(public readonly toastSerivce: ToastService) {
        this.subscriptions.add(this.isEdit$.subscribe(this._isEdit$));
        this.subscriptions.add(this.isAdd$.subscribe(this._isAdd$));
        this.subscriptions.add(this.isView$.subscribe(this._isView$));
        this.subscriptions.add(this.isEditOrAdd$.subscribe(this._isEditOrAdd$));
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * @deprecated Use isView$ instead as it works
     * with ChangeDetectionStrategy.OnPush
     */
    public isView(): boolean {
        return this.formMode === FormMode.View;
    }

    /**
     * @deprecated Use isEdit$ instead as it works
     * with ChangeDetectionStrategy.OnPush
     */
    public isEdit(): boolean {
        return this.formMode === FormMode.Edit;
    }

    /**
     * @deprecated Use isAdd$ instead as it works
     * with ChangeDetectionStrategy.OnPush
     */
    public isAdd(): boolean {
        return this.formMode === FormMode.Add;
    }

    /**
     * @deprecated Use isEditOrAdd$ instead as it works
     * with ChangeDetectionStrategy.OnPush
     */
    public isEditOrAdd(): boolean {
        return this.isAdd() || this.isEdit();
    }

    public saveMethod(): Observable<any> {
        return this.mappedItem.$save();
    }

    public save(): void {
        this.saveInProgress = true;
        this.saveMethod().subOnce({
            next: (result) => {
                this.handleNext(this.handleSaveSuccess(result));
                this.saveInProgress = false;
            },
            error: (error) => {
                this.handleError(this.handleSaveError(error));
                this.saveInProgress = false;
            }
        });
    }

    protected handleNext(x: any): void {
        this.toastSerivce.saveSuccess();
    }

    protected handleError(x: any): void {
        this.toastSerivce.saveError(x);
    }
}
