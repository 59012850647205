import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import {
    DocumentGroupLogicService, DocumentGroupMappedItem, IDocumentGroupDto, IDocumentGroupLogicService, IDocumentGroupMappedItem,
    IDocumentGroupSystemArea,
    IDocumentGroupTypeDto
} from '@app/logic/documents';
import { DOCUMENT_GROUP_CODES_CONST, SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy, filter, findIndex, some, find, cloneDeep } from 'lodash';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { DocumentGroupTypeDialogComponent } from '../document-group-type-dialog/document-group-type-dialog.component';
import { IDocumentTypeDto } from '@app/logic/document-types';

interface IData {
    mappedItem: DocumentGroupMappedItem;
}

@Component({
    selector: 'cb-document-group-dialog',
    templateUrl: './document-group-dialog.component.html',
    styleUrls: ['./document-group-dialog.component.scss']
})
export class DocumentGroupDialogComponent extends BaseDialogFormViewDirective<IDocumentGroupDto, IDocumentGroupMappedItem, IDocumentGroupLogicService> {
    public static readonly MIN_WIDTH = '75%';
    public mappedItem: IDocumentGroupMappedItem = {} as IDocumentGroupMappedItem;
    public isCodeDuplicated: boolean;
    public documentGroupCodes = DOCUMENT_GROUP_CODES_CONST.toLookup({
        transform: (lookup) => {
            lookup.unshift({
                code: undefined,
                id: undefined,
                isActive: true,
                label: 'N/A',
            });
            return lookup;
        }
    });
    public SYSTEM_AREA_ENUM = SYSTEM_AREA_ENUM;
    public availableSystemAreas: IDocumentGroupSystemArea[];
    public selectedSystemAreaId: number;
    public documentTypes: IEnumLookup<number>[] = [];

    private readonly systemAreas = SYSTEM_AREA_ENUM.toLookup();

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<DocumentGroupDialogComponent>,
        protected readonly documentGroupLogicService: DocumentGroupLogicService,
        protected readonly lookupService: LookupService,
        protected readonly cbDialog: CbDialogService,
    ) {
        super(dialogRef, toastSerivce);
        if (data.mappedItem?.id) {
            this.documentGroupLogicService.$getMappedItem(data.mappedItem.id).subOnce(mappedItem => {
                this.mappedItem = mappedItem;
                this.mappedItem.systemAreas = this.mappedItem.systemAreas || [];
                this.mappedItem.types = this.mappedItem.types || [];
            });
        } else {
            this.mappedItem = this.data.mappedItem;
            this.mappedItem.systemAreas = [];
            this.mappedItem.types = [];
        }
        this.refreshAvailableSystemAreas();
        this.lookupService.getLookup('documentTypes').then(documentTypes => {
            this.documentTypes = documentTypes;
        });
    }

    public onCodeChanged(): void {
        this.isCodeDuplicated = false;
        this.documentGroupLogicService.checkCodeIsUnique(this.mappedItem?.id || 0, this.mappedItem.codeName).subOnce(result => this.isCodeDuplicated = !result);
    }

    public onAddSystemAreaCliced(): void {
        if (this.selectedSystemAreaId) {
            const found = some(this.mappedItem.systemAreas, { systemArea: this.selectedSystemAreaId });
            if (!found) {
                this.mappedItem.systemAreas.push({
                    systemArea: this.selectedSystemAreaId,
                    isDefaultGroup: this.mappedItem.systemAreas?.length === 0
                } as IDocumentGroupSystemArea);
                this.selectedSystemAreaId = null;
                this.refreshAvailableSystemAreas();
            }
        }
    }

    public onRemoveSystemAreaClick(deletedSystemArea: IDocumentGroupSystemArea): void {
        const indexToRemove = findIndex(this.mappedItem.systemAreas, { systemArea: deletedSystemArea.systemArea });
        if (indexToRemove >= 0) {
            this.mappedItem.systemAreas.splice(indexToRemove, 1);
            if (this.mappedItem.systemAreas?.length === 1) {
                this.mappedItem.systemAreas[0].isDefaultGroup = true;
            }
            this.refreshAvailableSystemAreas();
        }
    }

    public setDefaultSystemArea(selectedSystemAreas: IDocumentGroupSystemArea): void {
        this.mappedItem.systemAreas.forEach(item => item.isDefaultGroup = false);
        selectedSystemAreas.isDefaultGroup = true;
    }

    public getDocumentTypeDisplayLabel(documentType: IDocumentTypeDto): string {
        const found = find(this.documentTypes, { id: documentType.id });
        return found ? found.label : '';
    }

    public onAddEditDocumentTypeClick(documentType?: IDocumentGroupTypeDto): void {
        const dialog = this.cbDialog.open(DocumentGroupTypeDialogComponent, {
            data: {
                dialogHeading: `${documentType ? 'Edit ' : 'Add'} Document Type`,
                documentGroup: this.mappedItem,
                documentType: documentType ? cloneDeep(documentType) : {}
            }
        });
        dialog.afterClosed().subOnce(savedDocumentType => {
            if (savedDocumentType) {
                const found = find(this.mappedItem.types, { documentTypeId: savedDocumentType.documentTypeId });
                if (found) {
                    Object.assign(found, savedDocumentType);
                } else {
                    this.mappedItem.types.push(savedDocumentType);
                }
            }
        });
    }

    public canSave(): boolean {
        return !this.isCodeDuplicated && !!this.mappedItem.systemAreas?.length;
    }

    private refreshAvailableSystemAreas(): void {
        this.availableSystemAreas = orderBy(
            filter(this.systemAreas, systemArea => {
                return findIndex(this.mappedItem.systemAreas, itemSystemArea => {
                    return systemArea.id === itemSystemArea.systemArea;
                }) < 0;
            }), 'label') as unknown as IDocumentGroupSystemArea[];
    }
}
