import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IChangeOptionLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';

import { BaseLogicService } from '../base/base-logic.service';
import { ChangeOptionLineMappedItem } from './change-option-line.mapped';
import { IChangeOptionLineLogicService } from './interfaces/i.change-option-line.logic.service';
import { IChangeOptionLineMappedItem } from './interfaces/i.change-option-line.mapped';


@Injectable()
export class ChangeOptionLineLogicService
    extends BaseLogicService<IChangeOptionLineDto, IChangeOptionLineMappedItem>
    implements IChangeOptionLineLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'changeoptionlines',
            ChangeOptionLineMappedItem
        );
    }

    public getExistingScheduleItem(lotId: number, slotId: number): Observable<IChangeOptionLineDto> {
        return this.$http.get<IChangeOptionLineDto>(`lots/${lotId}/changerecords/getExistingScheduleItem/${slotId}`);
    }

    public deleteOptionLine(changeOptionLineId: number): Observable<boolean> {
        return this.$http.delete(`changerecords/changeoptions/${this.$baseUri}/${changeOptionLineId}`);
    }

}
