<div class="step-main-container">
    <div class="center-start flex-col"
         [ngClass]="{'inactive' : !warning && !complete && !active}">
        <mat-icon class="mat-primary"
                  *ngIf="complete">
            check_circle
        </mat-icon>
        <mat-icon class="mat-accent"
                  *ngIf="active && !complete && !warning && !error">
            label
        </mat-icon>
        <mat-icon class="mat-warn"
                  *ngIf="warning && !complete">
            warning
        </mat-icon>
        <mat-icon style="color: red;"
                  *ngIf="error && !complete">
            error
        </mat-icon>
        <mat-icon *ngIf="!active && !warning && !error && !complete">
            label_outline
        </mat-icon>
    </div>
    <div class="center-start flex-col">
        <span [ngClass]="active ? 'mat-body-2' : 'mat-body-1'">{{label}}</span>
    </div>
    <div class="divider start-center flex-row flex"
         *ngIf="divider !== false">
        <div class="flex-col flex">
            <mat-divider [inset]="true"></mat-divider>
        </div>
    </div>
</div>
<div *ngIf="showMessage"
     class="step-content-parent">
    <div class="shrinkable-child">
        <ng-container *ngTemplateOutlet="cbStepMessageTemplate"></ng-container>
    </div>
</div>
<ng-template #cbStepMessageTemplate>
    <ng-content select="[cbStepMessage]">
    </ng-content>
</ng-template>
<div *ngIf="showCard"
     class="step-card-container"
     #stepCardContainer>
    <div class="step-card-wrapper"
         cbStepWrapperMaxWidth
         #stepCardWrapper>
        <mat-card class="step-card"
                  [cbApplyClassIfHasContent]="{ ele: stepCardContainer, class: 'visible'  }">
            <ng-container *ngTemplateOutlet="cbStepCardTemplate"></ng-container>
        </mat-card>
    </div>
    <div class="step-card-height"
         [cbStepCardHeight]="stepCardWrapper"></div>
</div>
<ng-template #cbStepCardTemplate>
    <ng-content select="[cbStepCard]">
    </ng-content>
</ng-template>
