import {takeOne} from 'cb-hub-lib';
import {HttpWrapperService} from '@app/core/services/http-wrapper/http-wrapper.service';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs';
import {IGstDto} from '@app/shared/interfaces/i.gst.dto';

@Injectable({
    providedIn: 'root'
})
export class GstService {

    public GST_RATE = 0;

    private readonly _loadGst = this.http
        .get<IGstDto>('/gst/current')
        .pipe(takeOne(), tap(rate => this.GST_RATE = Number(rate.value)));

    constructor(
        private readonly http: HttpWrapperService
    ) {
        this._loadGst.subscribe();
    }

    public toGstInclusive(exclusiveValue: number, rate: number = this.GST_RATE): number {
        rate = rate / 100;
        const multiplerRate = Number(1 + rate);
        return exclusiveValue * multiplerRate;
    }

    public toGstExclusive(inclusiveValue: number, rate: number = this.GST_RATE): number {
        rate = rate / 100;
        const dividerRate = Number(1 + rate);
        return inclusiveValue / dividerRate;
    }
}
