import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ITaskOutcomeMappedItem } from '@app/logic/task-outcomes/interfaces/i.task-outcome.mapped';
import { taskTypesSelect } from '@app/logic/task-outcomes/task.constants';
import { CbSortByPipe } from '@app/shared/pipe/sort-by/sort-by.pipe';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ITaskOutcomeDto } from '@app/logic/task-outcomes/interfaces/i.task-outcome.dto';
import { ITaskOutcomesLogicService } from '@app/logic/task-outcomes/interfaces/i.task-outcomes.logic.service';
import { SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-task-outcome-dialog',
    templateUrl: './task-outcome-dialog.component.html',
    styleUrls: ['./task-outcome-dialog.component.scss']
})
export class TaskOutcomeDialogComponent extends BaseDialogFormViewDirective<ITaskOutcomeDto, ITaskOutcomeMappedItem, ITaskOutcomesLogicService> {

    public taskTypes = [];
    public systemAreas = [];

    public systemAreaChanged = false;

    constructor(
        public readonly dialogRef: MatDialogRef<TaskOutcomeDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: ITaskOutcomeMappedItem }
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.initialiseData();
    }

    public initialiseData(): void {
        this.taskTypes = taskTypesSelect;
        this.systemAreas = this.getSystemAreas();
        this.setSelectedAreas();
    }

    public onChange(event: any): void {
        this.systemAreaChanged = true;
        event.source.value.isSelected = event.checked;
        this.updateSelectedItems(event.source.value);
    }

    public updateSelectedItems(newItem: any): void {
        const index = this.systemAreas.indexOf(newItem);
        this.systemAreas[index] = newItem;

        const selectedAreas = [];

        this.systemAreas.forEach(element => {
            if (element.isSelected) {
                selectedAreas.push(element.id);
            }
        });

        this.mappedItem.systemAreas = selectedAreas;
    }

    public getSystemAreas(): any {
        let map = SYSTEM_AREA_ENUM.toLookup().filter(x => x.id > 0);
        map = new CbSortByPipe().transform(map, 'label', 'asc');
        return map;
    }

    public setSelectedAreas(): any {
        this.systemAreas.forEach(element => {
            if (this.mappedItem.systemAreas && this.mappedItem.systemAreas.indexOf(element.id) > -1) {
                element.isSelected = true;
            } else {
                element.isSelected = false;
            }
        });
    }

}
