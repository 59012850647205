import {Inject, Injectable, Injector} from '@angular/core';
import {HttpWrapperService} from '../../core/services/http-wrapper/http-wrapper.service';
import {BaseLogicServiceWithSearch} from '../base/base-logic-service-with-search.component';
import {ICostXImportResultDto, IDocumentUploadDto, ITakeOffCompareDto, ITakeOffDtoSummary, ITakeOffTaskDetailsDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {ITakeoffMappedItem} from './interfaces/i.takeoff.mapped';
import {IQsTeamTakeoffSearch} from '@app/core/services/user-cache/user-cache-areas';
import {ITakeoffsLogicService} from './interfaces/i.takeoffs.logic.service';
import {TakeoffMappedItem} from './takeoff.mapped';
import {Observable} from 'rxjs';
import {IFileResponse} from '@app/shared/interfaces/i.file-response.dto';
import {cleanObjectDeep} from 'cb-hub-lib';
import {cloneDeepSafe} from '@app/shared/utils/clone-object.util';
import {TakeoffTasksLogicService} from '../takeoff-tasks/takeoff-tasks-logic-service';

@Injectable()
export class TakeoffsLogicService
    extends BaseLogicServiceWithSearch<
    ITakeOffDtoSummary,
    ITakeoffMappedItem,
    IQsTeamTakeoffSearch,
    ITakeOffDtoSummary>
    implements ITakeoffsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        @Inject(TakeoffTasksLogicService) public readonly takeoffTasksLogicService: TakeoffTasksLogicService,
    ) {
        super('takeoffs', TakeoffMappedItem);
    }

    public $getSearchList(params: IQsTeamTakeoffSearch): Observable<ITakeOffDtoSummary[]> {
        return this.$http.get<ITakeOffDtoSummary[]>(`${this.$baseUri}`, cleanObjectDeep(cloneDeepSafe(params)));
    }

    public downloadTakeoffFile(transferHistoryId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `${this.$baseUri}/transferhistory/${transferHistoryId}/file`, 'Downloading takeoff file...',
            undefined,
            'get'
        );
    }

    public requestTakeOffTaskForWorkingDrawing(workingDrawingId: number): Observable<ITakeOffTaskDetailsDto> {
        return this.$http.post(`${this.$baseUri}tasks/workingdrawing/${workingDrawingId}/request`);
    }

    public upload(payload: IDocumentUploadDto): Observable<ICostXImportResultDto> {
        return this.$http.post(`${this.$baseUri}`, payload);
    }

    public getChanges(takeoffId: number): Observable<ITakeOffCompareDto> {
        return this.$http.get<ITakeOffCompareDto>(`${this.$baseUri}/${takeoffId}`);
    }

    public abandon(takeoffId: number): Observable<boolean> {
        return this.$http.get<boolean>(`${this.$baseUri}/${takeoffId}/abandon`);
    }

    public confirm(comparison: ITakeOffCompareDto): Observable<ITakeOffCompareDto> {
        return this.$http.post(`${this.$baseUri}/confirm`, comparison);
    }

    public getTransferHistoryErrors(id: number): Observable<IFileResponse> {
        return this.$http.download(`${this.$baseUri}/transferhistory/${id}`,
            'Please wait... your request is being processed',
            'get');
    }
}
