import {
    IAllManualOrderSearch,
    IBuildActivitySearch,
    IBuildProgrammePurchaseOrderSearch,
    IBuildProgrammeTemplateEdit,
    IBusinessAccountQuotes,
    IClientSaleSearch,
    IDashboardWidgetConfig,
    IDesignTeamCounfilRfiSearch,
    IDesignTeamImageRequestSearch,
    IDesignTeamPreconsentPlansSearch,
    IDesignTeamSchemesSearch,
    IDesignTeamWorkingDrawingsSearch,
    IHelpSearch,
    IHouseAndLandSearch,
    IIncompleteBuildActivitySearch,
    ILeadSearch,
    ILotBuildOrdersSearch,
    ILotBuildProgrammeView,
    ILotChangesListSearch,
    ILotConfirmedQuanitities,
    ILotCouncilRfiSearch,
    ILotDesignQuestionsSearch,
    ILotDesignSchemeSearch,
    ILotPriceRevisionSearch,
    ILotQuoteSearch,
    ILotSpecItemManageAttributes,
    ILotSpecPinned,
    ILotSpecSearch,
    ILotSummary,
    ILotWorkingDrawingSearch,
    IManualPurchaseOrderSearch,
    IManualPurchaseOrdersForApprovalSearch,
    IOldBuildActivitySearch,
    IProductCatalogSearch,
    IProductItemUsageLotSpecSearch,
    IProductRateExceptionSearch,
    IProductSearch,
    IPropertySearch,
    IPurchaseOrderSearch,
    IQsTeamTakeoffSearch,
    IScheduleItemExternalReferenceSearch,
    ISearchResult,
    ISidebarCacheArea,
    ISlotSearchFilters,
    ISpecTemplateView,
    IStandardPlansSearch,
    ITeamBuildProgrammeSearch,
    ITeamChangeRecordSearch,
    ITeamPricingRevisionSearch,
    ITeamQuestionSearch,
    ITeamTaskSearch,
    IUserSearch
} from './user-cache-areas';
import {USER_CACHE_AREA, USER_CACHE_CATEGORY} from 'cb-hub-lib';
import {BaseUserCacheService} from './base-user-cache-service';
import {Injectable} from '@angular/core';
import {LEAD_SEARCH_ORDER_ENUM, PRODUCT_SEARCH_TYPE_ENUM, PURCHASE_ORDER_TYPE_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {IBusinessAccountSearchParams} from '@app/logic/business-accounts';

@Injectable({
    providedIn: 'root',
})
export class UserCacheService extends BaseUserCacheService {
    // define UserCacheItems here

    public designTeamSchemesSearch = this.define<IDesignTeamSchemesSearch>({
        label: 'Design Team Schemes Search',
        key: USER_CACHE_AREA.DesignTeamSchemesSearch,
        category: USER_CACHE_CATEGORY.DesignTeam,
        defaultData: {
            query: null,
            userId: null,
            statusId: [],
            order: null,
            direction: 'asc',
            canSeeAll: false
        }
    });
    public designTeamPreconsentPlansSearch = this.define<IDesignTeamPreconsentPlansSearch>({
        label: 'Design Team Preconsent Plans Search',
        key: USER_CACHE_AREA.DesignTeamPreconsentPlansSearch,
        category: USER_CACHE_CATEGORY.DesignTeam,
        defaultData: {
            query: null,
            userId: null,
            statusId: [],
            order: '',
            direction: 'asc',
            canSeeAll: false,
            lotId: null,
        }
    });
    public designTeamWorkingDrawingsSearch = this.define<IDesignTeamWorkingDrawingsSearch>({
        label: 'Design Team Working Drawings Search',
        key: USER_CACHE_AREA.DesignTeamWorkingDrawingsSearch,
        category: USER_CACHE_CATEGORY.DesignTeam,
        defaultData: {
            query: null,
            userId: null,
            statusId: [],
            order: null,
            direction: 'asc',
            canSeeAll: false,
            lotId: null,
        }
    });
    public designTeamCouncilRfiSearch = this.define<IDesignTeamCounfilRfiSearch>({
        label: 'Design Team Council RFI Search',
        key: USER_CACHE_AREA.DesignTeamCouncilRfiSearch,
        category: USER_CACHE_CATEGORY.DesignTeam,
        defaultData: {
            query: null,
            userId: null,
            statusId: [],
            order: null,
            direction: 'asc',
            canSeeAll: false,
            lotId: null,
            teamCode: '',
        }
    });
    public designTeamImageRequestSearch = this.define<IDesignTeamImageRequestSearch>({
        label: 'Design Team Image Request Search',
        key: USER_CACHE_AREA.DesignTeamImageRequestSearch,
        category: USER_CACHE_CATEGORY.DesignTeam,
        defaultData: {
            query: null,
            userId: null,
            statusId: [],
            order: null,
            direction: 'asc',
            canSeeAll: false,
            lotId: null,
            isImageRequest: true,
        }
    });
    public designTeamQuestionSearch = this.define<ITeamQuestionSearch>({
        label: 'Design Team Question Search',
        key: USER_CACHE_AREA.DesignTeamQuestionSearch,
        category: USER_CACHE_CATEGORY.DesignTeam,
        defaultData: {
            query: null,
            awaitingResponseOnly: false,
            userId: null,
            order: null,
            direction: 'asc',
            canSeeAll: false,
            isUnassigned: false,
            teamCode: null,
        }
    });
    public qsTeamQuestionSearch = this.define<ITeamQuestionSearch>({
        label: 'QS Team Question Search',
        key: USER_CACHE_AREA.QsTeamQuestionSearch,
        category: USER_CACHE_CATEGORY.QsTeam,
        defaultData: {
            query: null,
            awaitingResponseOnly: false,
            userId: null,
            order: null,
            direction: 'asc',
            canSeeAll: false,
            isUnassigned: false,
            teamCode: null,
        }
    });
    public designTeamChangeRecordSearch = this.define<ITeamChangeRecordSearch>({
        label: 'Design Team Change Record Search',
        key: USER_CACHE_AREA.DesignTeamChangeRecordSearch,
        category: USER_CACHE_CATEGORY.DesignTeam,
        defaultData: {
            query: null,
            userId: null,
            order: null,
            direction: 'asc',
            canSeeAll: false,
            isUnassigned: false,
            teamCode: null,
            statusId: null,
        }
    });
    public qsTeamChangeRecordSearch = this.define<ITeamChangeRecordSearch>({
        label: 'QS Team Change Record Search',
        key: USER_CACHE_AREA.QsTeamChangeRecordSearch,
        category: USER_CACHE_CATEGORY.QsTeam,
        defaultData: {
            query: null,
            userId: null,
            order: null,
            direction: 'asc',
            canSeeAll: false,
            isUnassigned: false,
            teamCode: null,
            statusId: null,
        }
    });
    public designTeamTaskSearch = this.define<ITeamTaskSearch>({
        label: 'Design Team Task Search',
        key: USER_CACHE_AREA.DesignTeamTaskSearch,
        category: USER_CACHE_CATEGORY.DesignTeam,
        defaultData: {
            query: null,
            userId: null,
            order: null,
            direction: 'asc',
        }
    });
    public qsTeamTaskSearch = this.define<ITeamTaskSearch>({
        label: 'QS Team Task Search',
        key: USER_CACHE_AREA.QsTeamTaskSearch,
        category: USER_CACHE_CATEGORY.QsTeam,
        defaultData: {
            query: null,
            userId: null,
            order: null,
            direction: 'asc',
        }
    });
    public qsTeamPricingRevisionSearch = this.define<ITeamPricingRevisionSearch>({
        label: 'QS Team Pricing Revision Search',
        key: USER_CACHE_AREA.QsTeamPricingRevisionSearch,
        category: USER_CACHE_CATEGORY.QsTeam,
        defaultData: {
            query: null,
            department: null,
            userId: null,
            isUnassigned: null,
            statusId: null,
            order: null,
            direction: 'asc',
            lotId: null,
            canSeeAll: false
        }
    });
    public qsTeamTakeoffSearch = this.define<IQsTeamTakeoffSearch>({
        label: 'QS Team Takeoff Search',
        key: USER_CACHE_AREA.QsTeamTakeoffSearch,
        category: USER_CACHE_CATEGORY.QsTeam,
        defaultData: {
            query: null,
            userId: null,
            order: null,
            direction: 'asc',
            statusId: [],
            teamCode: null,
            lotId: null,
            canSeeAll: false,
            isUnassigned: false
        }
    });
    public standardPlansSearch = this.define<IStandardPlansSearch>({
        label: 'Standard Plan Search',
        key: USER_CACHE_AREA.StandardPlansSearch,
        category: USER_CACHE_CATEGORY.StandardPlans,
        defaultData: {
            order: 'asc',
            query: null,
            userId: null,
            canSeeAll: false,
            costToBuild: [],
            floorArea: [],
            numberOfBathrooms: null,
            numberOfBedrooms: null,
            numberOfGarages: null,
            numberOfLivingRooms: null,
            showUnpublished: true,
        }
    });
    public manualPurchaseOrdersForApprovalSearch = this.define<IManualPurchaseOrdersForApprovalSearch>({
        label: 'Manual Purchase Orders For Approval Search',
        key: USER_CACHE_AREA.ManualPurchaseOrdersForApprovalSearch,
        category: USER_CACHE_CATEGORY.QsTeam,
        defaultData: {
            selectedAssigneeId: null,
            selectedDirection: 'asc',
            selectedOrder: null,
            query: null,
        }
    });
    public clientSaleSearch = this.define<IClientSaleSearch>({
        label: 'Client Sale Search',
        key: USER_CACHE_AREA.ClientSaleSearch,
        category: USER_CACHE_CATEGORY.ClientSale,
        defaultData: {
            selectedAreaId: null,
            selectedDistrictId: null,
            selectedRegionId: null,
            query: '',
            locationId: null,
            jobStatus: null,
            contractTypes: [],
            includeLostJobs: true,
            buildingConsultants: [],
            ignoreEmptyQuery: false,
            order: [],
        }
    });

    public userSearch = this.define<IUserSearch>({
        label: 'User Search',
        key: USER_CACHE_AREA.UserSearch,
        category: USER_CACHE_CATEGORY.Users,
        defaultData: {
            query: '',
            currentPage: undefined,
            pageSize: undefined,
            tag: undefined,
            active: undefined,
            team: undefined,
            businessEntity: undefined,
            tagOrTeam: undefined
        }
    });

    public slotSearch = this.define<ISlotSearchFilters>({
        label: 'Schedule Items Search',
        key: USER_CACHE_AREA.SlotSearch,
        category: USER_CACHE_CATEGORY.Slots,
        defaultData: {
            query: '',
            isActive: true,
            costTypeId: [],
            specgroupId: [],
            excludeIds: [],
            excludeChildren: null,
            excludeParents: null,
            selectedHouseAreas: []
        }
    });
    public specTemplateView = this.define<ISpecTemplateView>({
        label: 'Spec Template View',
        key: USER_CACHE_AREA.SpecTemplateView,
        category: USER_CACHE_CATEGORY.SpecTemplates,
        defaultData: {}
    });
    public businessAccountQuoteSearch = this.define<IBusinessAccountQuotes>({
        label: 'Business Account Quote Search',
        key: USER_CACHE_AREA.BusinessAccountQuoteSearch,
        category: USER_CACHE_CATEGORY.BusinessAccounts,
        defaultData: {
            direction: 'asc',
            order: 'date',
            query: ''
        }
    });
    public buildProgrammeTemplateEdit = this.define<IBuildProgrammeTemplateEdit>({
        label: 'Build Programme Template Edit',
        key: USER_CACHE_AREA.BuildProgrammeTemplateEdit,
        category: USER_CACHE_CATEGORY.BuildProgramme,
        defaultData: {}
    });
    public helpSearch = this.define<IHelpSearch>({
        label: 'Help Search',
        key: USER_CACHE_AREA.HelpSearch,
        category: USER_CACHE_CATEGORY.HelpArea,
        defaultData: {
            query: '',
            showUnpublished: false,
        }
    });
    public lotBuildProgrammeView = this.define<ILotBuildProgrammeView>({
        label: 'Lot Build Programme View & Pinned Panels',
        key: USER_CACHE_AREA.LotBuildProgrammeView,
        category: USER_CACHE_CATEGORY.BuildProgramme,
        defaultData: {
            viewAllStages: false,
            viewManualOrders: true,
        }
    });
    public businessAccountsSearch = this.define<IBusinessAccountSearchParams>({
        label: 'Business Accounts Search',
        key: USER_CACHE_AREA.BusinessAccountSearch,
        category: USER_CACHE_CATEGORY.BusinessAccounts,
        defaultData: {
            currentpage: null,
            query: '',

            /** businessAccountSearchType */
            t: null,

            /** tradeTypeId */
            tt: null,

            /** BusinessAccountStatus */
            s: null,

            /** regionId */
            reg: null,

            /** districtId */
            dis: null,

            /** suburbId */
            sub: null,

            /** ratesOnly */
            ro: null,

            /** explicitSearch */
            ex: null
        }
    });
    public lotPriceRevisionSearch = this.define<ILotPriceRevisionSearch>({
        label: 'Lot Pricing Revision Search',
        key: USER_CACHE_AREA.LotPriceRevisionSearch,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {
            query: null,
            lotId: null
        }
    });
    public lotConfirmedQuantities = this.define<ILotConfirmedQuanitities>({
        label: 'Lot Confirmed Quantities',
        key: USER_CACHE_AREA.LotConfirmedQuanitities,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {}
    });
    public lotDesignSchemeSearch = this.define<ILotDesignSchemeSearch>({
        label: 'Lot Design Scheme Search',
        key: USER_CACHE_AREA.LotDesignSchemeSearch,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {
            query: null,
        }
    });
    public lotDesignQuestionsSearch = this.define<ILotDesignQuestionsSearch>({
        label: 'Lot Design Questions Search',
        key: USER_CACHE_AREA.LotDesignQuestionsSearch,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {
            assignedUserId: null,
            awaitingResponseOnly: false,
            order: null,
            query: null,
            teamCode: null,
            user: null,
            lotId: null,
            currentPage: 1
        }
    });
    public lotWorkingDrawingSearch = this.define<ILotWorkingDrawingSearch>({
        label: 'Lot Working Drawing Search',
        key: USER_CACHE_AREA.LotWorkingDrawingSearch,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {
            query: null,
        }
    });
    public lotSummary = this.define<ILotSummary>({
        label: 'Lot Summary',
        key: USER_CACHE_AREA.LotSummary,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {}
    });
    public lotSpecItemManageAttributes = this.define<ILotSpecItemManageAttributes>({
        label: 'Lot Spec Item Manage Attributes',
        key: USER_CACHE_AREA.LotSpecItemManageAttributes,
        category: USER_CACHE_CATEGORY.LotSpecification,
        defaultData: {}
    });
    public lotSpecSearch = this.define<ILotSpecSearch>({
        label: 'Lot Spec Search',
        key: USER_CACHE_AREA.LotSpecSearch,
        category: USER_CACHE_CATEGORY.LotSpecification,
        defaultData: {
            selectedCostTypes: [],
            selectedHouseAreas: [],
            selectedTags: [],
            selectedlotSpecItemEntityTypes: [],
            keyword: '',
            enableListView: false,
        }
    });
    public lotChangesListSearch = this.define<ILotChangesListSearch>({
        label: 'Lot Changes Search',
        key: USER_CACHE_AREA.LotChangesListSearch,
        category: USER_CACHE_CATEGORY.LotChange,
        defaultData: {
            query: '',
            selectedCostNatures: [],
            selectedVariations: [],
            selectedStatus: []
        }
    });
    public lotSpecPinned = this.define<ILotSpecPinned>({
        label: 'Lot Spec Pinned',
        key: USER_CACHE_AREA.LotSpecPinned,
        category: USER_CACHE_CATEGORY.LotSpecification,
        defaultData: {}
    });
    public buildProgrammePurchaseOrderSearch = this.define<IBuildProgrammePurchaseOrderSearch>({
        label: 'Build Programme Purchase Order Search',
        key: USER_CACHE_AREA.BuildProgrammePurchaseOrderSearch,
        category: USER_CACHE_CATEGORY.BuildProgramme,
        defaultData: {
            selectedDirection: 'asc',
            selectedOrder: null,
            query: null,
        }
    });
    public manualPurchaseOrderSearch = this.define<IManualPurchaseOrderSearch>({
        label: 'Manual Purchase Order Search',
        key: USER_CACHE_AREA.ManualPurchaseOrderSearch,
        category: USER_CACHE_CATEGORY.ManualOrders,
        defaultData: {
            selectedDirection: 'asc',
            selectedOrder: null,
            query: ''
        }
    });
    public productCatalogSearch = this.define<IProductCatalogSearch>({
        label: 'Product Catalogue Search',
        key: USER_CACHE_AREA.ProductCatalogSearch,
        category: USER_CACHE_CATEGORY.Products,
        defaultData: {
            query: '',
            productSearchTypeId: PRODUCT_SEARCH_TYPE_ENUM.All,
            activeProducts: false,
            selectedCategoryId: null,
            nullRatedFuture: false,
            strictSearch: false,
            nullRated: false,
            selectedCategories: [],
        }
    });
    public productRateExceptionSearch = this.define<IProductRateExceptionSearch>({
        label: 'Product Rate Exception Search',
        key: USER_CACHE_AREA.ProductRateExceptionSearch,
        category: USER_CACHE_CATEGORY.Products,
        defaultData: {
            query: '',
            selectedSupplier: null,
            selectedBusinessEntity: null,
            purchaseOrderNumber: null,
            createdFromDate: null,
            createdToDate: null,
            activityStartDate: null,
            activityEndDate: null,
            selectedProduct: null
        }
    });
    public oldBuildActivitySearch = this.define<IOldBuildActivitySearch>({
        label: 'Build Activity Search (Old)',
        key: USER_CACHE_AREA.OldBuildActivitySearch,
        category: USER_CACHE_CATEGORY.Settings,
        defaultData: {
            code: '',
            name: '',
        }
    });
    public dashboardWidgetConfig = this.define<IDashboardWidgetConfig>({
        label: 'Dashboard Widgets Configuration',
        key: USER_CACHE_AREA.DashboardWidgetConfig,
        category: USER_CACHE_CATEGORY.Dashboard,
        defaultData: {
            widgets: []
        }
    });
    public lotCouncilRfiSearch = this.define<ILotCouncilRfiSearch>({
        label: 'Lot Council Rfi Search',
        key: USER_CACHE_AREA.LotCouncilRfiSearch,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {
            query: null,
        }
    });
    public lotQuoteSearch = this.define<ILotQuoteSearch>({
        label: 'Lot Quote Search',
        key: USER_CACHE_AREA.LotQuotesSearch,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {
            direction: 'asc',
            statusIds: null,
            query: null,
            lotId: null,
            supplierId: null,
        }
    });
    public productSearch = this.define<IProductSearch>({
        label: 'Product Search',
        key: USER_CACHE_AREA.ProductSearch,
        category: USER_CACHE_CATEGORY.Products,
        defaultData: {
            selectedSupplier: 0,
            showFutureRates: false,
            showInactiveRates: false,
        }
    });
    public teamBuildProgrammeSearch = this.define<ITeamBuildProgrammeSearch>({
        label: 'Team Build Programme Search',
        key: USER_CACHE_AREA.TeamBuildProgrammeSearch,
        category: USER_CACHE_CATEGORY.BuildProgramme,
        defaultData: {
            query: null,
            order: null,
            direction: 'asc',
            statusId: null,
            framingManagerId: null,
            foundationManagerId: null,
        }
    });
    public productItemUsageLotSpecSearch = this.define<IProductItemUsageLotSpecSearch>({
        label: 'Product Item Usage Lot Spec Search',
        key: USER_CACHE_AREA.ProductItemUsageLotSpecSearch,
        category: USER_CACHE_CATEGORY.Products,
        defaultData: {
            hasClientSale: false,
            jobStatus: null,
            lotStatus: null,
        }
    });
    public sidebarBuildActivityView = this.define<ISidebarCacheArea>({
        label: 'Build Activity Sidebar',
        key: USER_CACHE_AREA.SidebarBuildActivityView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarBuildProgrammeDetailsView = this.define<ISidebarCacheArea>({
        label: 'Build Programme Details Sidebar',
        key: USER_CACHE_AREA.SidebarBuildProgrammeDetailsView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarBuildTemplateView = this.define<ISidebarCacheArea>({
        label: 'Build Template Sidebar',
        key: USER_CACHE_AREA.SidebarBuildTemplateView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarBusinessAccountView = this.define<ISidebarCacheArea>({
        label: 'Business Account Sidebar',
        key: USER_CACHE_AREA.SidebarBusinessAccountView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarBusinessEntityView = this.define<ISidebarCacheArea>({
        label: 'Business Entity Sidebar',
        key: USER_CACHE_AREA.SidebarBusinessEntityView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarClientAccountView = this.define<ISidebarCacheArea>({
        label: 'Client Account Sidebar',
        key: USER_CACHE_AREA.SidebarClientAccountView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarContactView = this.define<ISidebarCacheArea>({
        label: 'Contact Sidebar',
        key: USER_CACHE_AREA.SidebarContactView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarLeadView = this.define<ISidebarCacheArea>({
        label: 'Lead Sidebar',
        key: USER_CACHE_AREA.SidebarLeadView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarLotView = this.define<ISidebarCacheArea>({
        label: 'Lot Sidebar',
        key: USER_CACHE_AREA.SidebarLotView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarProductItemUsageView = this.define<ISidebarCacheArea>({
        label: 'Product Item Usage Sidebar',
        key: USER_CACHE_AREA.SidebarProductItemUsageView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarProductView = this.define<ISidebarCacheArea>({
        label: 'Product Sidebar',
        key: USER_CACHE_AREA.SidebarProductView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarProjectReleaseView = this.define<ISidebarCacheArea>({
        label: 'Project Release Sidebar',
        key: USER_CACHE_AREA.SidebarProjectReleaseView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarProjectView = this.define<ISidebarCacheArea>({
        label: 'Project Sidebar',
        key: USER_CACHE_AREA.SidebarProjectView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarSpecTemplateView = this.define<ISidebarCacheArea>({
        label: 'Spec Template Sidebar',
        key: USER_CACHE_AREA.SidebarSpecTemplateView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public sidebarUserView = this.define<ISidebarCacheArea>({
        label: 'User Sidebar',
        key: USER_CACHE_AREA.SidebarUserView,
        category: USER_CACHE_CATEGORY.Sidebars,
        defaultData: { isSidebarOpen: true },
    });
    public leadSearch = this.define<ILeadSearch>({
        label: 'Lead Search',
        key: USER_CACHE_AREA.LeadSearch,
        category: USER_CACHE_CATEGORY.Leads,
        defaultData: {
            query: '',
            buildingConsultantId: [],
            status: [],
            fromDate: null,
            toDate: null,
            contractTypes: [],
            order: null,
        },
    });
    public purchaseOrderSearch = this.define<IPurchaseOrderSearch>({
        label: 'Purchase Order Search',
        key: USER_CACHE_AREA.PurchaseOrderSearch,
        category: USER_CACHE_CATEGORY.PurchaseOrders,
        defaultData: {
            query: '',
            constructionManagerId: null,
            status: [],
            costNature: [],
            invoiceReceived: null
        },
    });
    public buildActivitySearch = this.define<IBuildActivitySearch>({
        label: 'Build Activity Search (New)',
        key: USER_CACHE_AREA.BuildActivitySearch,
        category: USER_CACHE_CATEGORY.Settings,
        defaultData: {
            activityCode: '',
            activityName: '',
        }
    });
    public lotSearch = this.define<IPropertySearch>({
        label: 'Lot Search',
        key: USER_CACHE_AREA.LotPropertySearch,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {
            floorAreaMin: null,
            floorAreaMax: null,

            landAreaMin: null,
            landAreaMax: null,

            priceMin: null,
            priceMax: null,

            numberOfBathrooms: null,
            numberOfBedrooms: null,
            numberOfGarages: null,
            numberOfLivingRooms: null,

            showUnpublished: null,
            lotStatuses: [],
            lotType: null,
            locationId: null,
            myLotsOnly: null,
            enableListView: false,

            query: null,
            order: null,
            lotFieldToSearch: 0,
        }
    });

    public houseLandSearch = this.define<IHouseAndLandSearch>({
        label: 'House and Land Search',
        key: USER_CACHE_AREA.HouseLandPropertySearch,
        category: USER_CACHE_CATEGORY.HouseAndLand,
        defaultData: {
            floorAreaMin: null,
            floorAreaMax: null,

            landAreaMin: null,
            landAreaMax: null,

            priceMin: null,
            priceMax: null,

            numberOfBathrooms: null,
            numberOfBedrooms: null,
            numberOfGarages: null,
            numberOfLivingRooms: null,

            locationId: null,
            resultSize: null,

            query: null,
            order: null,
        }
    });

    public accountSearch = this.define<ISearchResult>({
        label: 'Account Search',
        key: USER_CACHE_AREA.AccountSearch,
        category: USER_CACHE_CATEGORY.Account,
        defaultData: {
            query: null,
        }
    });

    public contactSearch = this.define<ISearchResult>({
        label: 'Contact Search',
        key: USER_CACHE_AREA.ContactSearch,
        category: USER_CACHE_CATEGORY.Contact,
        defaultData: {
            query: null,
        }
    });

    public projectSearch = this.define<ISearchResult>({
        label: 'Project Search',
        key: USER_CACHE_AREA.ProjectSearch,
        category: USER_CACHE_CATEGORY.Project,
        defaultData: {
            query: null,
        }
    });

    public addAccountSearch = this.define<ISearchResult>({
        label: 'Add Account Search',
        key: USER_CACHE_AREA.AddAccountSearch,
        category: USER_CACHE_CATEGORY.Account,
        defaultData: {
            query: null,
        }
    });

    public addBizAccountSearch = this.define<ISearchResult>({
        label: 'Add Biz Account Search',
        key: USER_CACHE_AREA.AddBizAccountSearch,
        category: USER_CACHE_CATEGORY.BusinessAccounts,
        defaultData: {
            query: null,
        }
    });

    public addContactSearch = this.define<ISearchResult>({
        label: 'Add Contact Search',
        key: USER_CACHE_AREA.AddContactSearch,
        category: USER_CACHE_CATEGORY.Contact,
        defaultData: {
            query: null,
        }
    });


    public addLeadSearch = this.define<ISearchResult>({
        label: 'Add Lead Search',
        key: USER_CACHE_AREA.AddLeadSearch,
        category: USER_CACHE_CATEGORY.Leads,
        defaultData: {
            query: null,
        }
    });

    public addVendorSearch = this.define<ISearchResult>({
        label: 'Add Vendor Search',
        key: USER_CACHE_AREA.AddVendorSearch,
        category: USER_CACHE_CATEGORY.Vendor,
        defaultData: {
            query: null,
        }
    });

    public lotBuildOrderSearch = this.define<ILotBuildOrdersSearch>({
        label: 'Lot Build Orders Search',
        key: USER_CACHE_AREA.LotBuildOrdersSearch,
        category: USER_CACHE_CATEGORY.Lot,
        defaultData: {
            query: null,
            orderType: null,
            order: null,
            status: null,
            direction: 'asc',
        }
    });

    public allManualOrderSearch = this.define<IAllManualOrderSearch>({
        label: 'All Manual Orders Search',
        key: USER_CACHE_AREA.AllManualOrderSearch,
        category: USER_CACHE_CATEGORY.ManualOrders,
        defaultData: {
            query: null,
            status: [],
            reasons: [],
            costNatures: [],
            direction: 'desc',
        }
    });
    public myLeadsSearch = this.define<ILeadSearch>({
        label: 'My Leads Search',
        key: USER_CACHE_AREA.MyLeadsSearch,
        category: USER_CACHE_CATEGORY.Leads,
        defaultData: {
            query: '',
            buildingConsultantId: [],
            status: [],
            fromDate: null,
            toDate: null,
            contractTypes: [],
            order: LEAD_SEARCH_ORDER_ENUM.None,
        },
    });
    public myClientSalesSearch = this.define<IClientSaleSearch>({
        label: 'My Client Sales Search',
        key: USER_CACHE_AREA.MyClientSalesSearch,
        category: USER_CACHE_CATEGORY.ClientSale,
        defaultData: {
            selectedAreaId: null,
            selectedDistrictId: null,
            selectedRegionId: null,
            query: '',
            locationId: null,
            jobStatus: null,
            contractTypes: [],
            includeLostJobs: true,
            buildingConsultants: [],
            ignoreEmptyQuery: false,
            order: [],
        }
    });

    public incompleteBuildActivitySearch = this.define<IIncompleteBuildActivitySearch>({
        label: 'Incomplete Build Activity Searchs',
        key: USER_CACHE_AREA.IncompleteBuildActivities,
        category: USER_CACHE_CATEGORY.AllTeams,
        defaultData: {
            query: '',
            orderType: PURCHASE_ORDER_TYPE_ENUM.Any,
            order: 'enddate',
            status: null,
            direction: 'asc',
            onlyCompletable: true,
            businessEntityId: null,
            constructionManagerId: null,
            constructionManagerTags: null
        }
    });


    public scheduleItemExternalReferenceSearch = this.define<IScheduleItemExternalReferenceSearch>({
        label: 'Schedule Item External Reference Search',
        key: USER_CACHE_AREA.ScheduleItemExternalReferences,
        category: USER_CACHE_CATEGORY.Settings,
        defaultData: {
            query: '',
            externalSystemId: null,
            activeSlotsOnly: null,
            externalFieldId: null,
        }
    });

}
