import { CLIENT_SALE_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, Input } from '@angular/core';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Component({
    selector: 'cb-client-sale-stepper',
    templateUrl: './client-sale-stepper.component.html',
    styleUrls: ['./client-sale-stepper.component.scss']
})
export class ClientSaleStepperComponent {

    @Input() public clientSale: IClientSaleMappedItem;

    public readonly CLIENT_SALE_STATUS_ENUM = CLIENT_SALE_STATUS_ENUM;

    constructor() { }

}
