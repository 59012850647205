<div class="cb-margin-2x flex-row">
    <div class="cb-margin-right-2x flex-col flex">
        <h3 class="mat-body-1 title-divider">Submission</h3>
        <cb-display-value-new label="Date Submitted"
                              [value]="mappedItem.submittedToCouncilDate | date"></cb-display-value-new>
        <cb-display-value-new label="Submitted by"
                              [value]="mappedItem.submittedToCouncilByUserName"></cb-display-value-new>
        <h3 class="mat-body-1 title-divider cb-margin-top">Processing</h3>
        <cb-display-value-new label="Received by Council"
                              [value]="mappedItem.receivedByCouncilDate | date"></cb-display-value-new>
        <cb-display-value-new label="Original Consent Due Date"
                              [value]="mappedItem.consentDueDate | date"></cb-display-value-new>
        <cb-display-value-new label="Number of RFIs"
                              [value]="numberOfRfis"></cb-display-value-new>
        <cb-display-value-new label="Estimated Consent Due Date"
                              [value]="mappedItem.consentEstimatedDueDate | date"></cb-display-value-new>
    </div>
    <div class="flex-col flex">
        <h3 class="mat-body-1 title-divider">Consent Granted</h3>
        <cb-display-value-new label="Consent Granted Date"
                              [value]="mappedItem.consentGrantedDate | date"></cb-display-value-new>
        <cb-display-value-new label="Working Days Since Submission"
                              [value]="workingDaysSinceSubmission"></cb-display-value-new>
        <cb-display-value-new *ngIf="isBuildingConsent"
                              label="Granted by"
                              [value]="buildingConsent.grantedByUserName"></cb-display-value-new>
        <cb-display-value-new label="Consent Expiry Date"
                              [value]="mappedItem.consentExpiryDate | date"></cb-display-value-new>
        <cb-display-value-new *ngIf="isBuildingConsent"
                              label="CCC Expiry Date"
                              [value]="buildingConsent.codeOfComplianceExpiryDate | date"></cb-display-value-new>
    </div>
</div>
