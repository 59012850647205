import { Component, OnInit } from '@angular/core';
import {
    BusinessAccountLogicService,
    DocTypeBizAccountTypesLogicService,
    DocTypeSupplyTypesLogicService,
    DocTypeTradeTypesLogicService
} from '@app/logic/business-accounts/business-account.logic.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PermissionsPermissions } from '@app/core/permissions';
import { IDocumentTypeDto } from '@app/logic/document-types';
import { ITradeTypeDto } from '@app/logic/trade-types/interfaces/i.trade-type.dto';
import { BusinessAccountTypeDocsDialogComponent } from './business-account-type-docs-dialog/business-account-type-docs-dialog.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TableColumn } from '@app/shared/components/table/base-table.component';
import { SettingsPermissionsController } from '../../settings.permissions.class';
import { IDocTypeBusinessAccountTypeDto, IDocTypeSupplyTypeDto, IDocTypeTradeTypeDto } from '@app/logic/business-accounts/interfaces/i.business-account.dto';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { ISupplyTypeDto } from '@app/logic/supply-types';

@Component({
    selector: 'cb-business-accounts',
    templateUrl: './business-accounts.component.html',
    styleUrls: ['./business-accounts.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [
        BusinessAccountLogicService,
        DocTypeTradeTypesLogicService,
        DocTypeSupplyTypesLogicService,
        DocTypeBizAccountTypesLogicService]
})
export class BusinessAccountsComponent extends SettingsPermissionsController implements OnInit {
    public expandedElement: any;
    public expandedSupplyTypeElement: any;
    public requiredDocsForTradeTypes: Array<IDocTypeTradeTypeDto>;
    public requiredDocsForSupplyTypes: Array<IDocTypeSupplyTypeDto>;
    public sortedDocsForTradeTypes: { [parentId: number]: any[] };
    public sortedDocsForSupplyTypes: { [parentId: number]: any[] };
    public requiredDocsForBusinessAccountTypes: Array<IDocumentTypeDto>;
    public expanded: number[] = [];
    public JSON;
    public parentTradeTypes: Array<ITradeTypeDto>;
    public parentSupplyTypes: Array<ISupplyTypeDto>;
    public isSupplierTypesEnabled: boolean;

    private documentObjectDefinition: TableColumn ={ name: 'Documents', valueProp: 'documentTypes', valueProp2: 'label', type: 'list' };

    public innerTradeTypeColumns: Array<TableColumn> = [
        { name: 'trade', valueProp: 'tradeType', valueProp2: 'label', type: 'text', width: '250px' },
        this.documentObjectDefinition,
        {
            name: '', valueProp: 'actions', type: 'actions', actions: [
                {
                    function: this.editTradeType.bind(this), icon: 'edit',
                    disabled: !this.canEdit()
                }
            ]
        }
    ];

    public innerSupplyTypeColumns: Array<TableColumn> = [
        { name: 'supply', valueProp: 'supplyType', valueProp2: 'label', type: 'text', width: '250px' },
        this.documentObjectDefinition,
        {
            name: '', valueProp: 'actions', type: 'actions', actions: [
                {
                    function: this.editSupplyType.bind(this), icon: 'edit',
                    disabled: !this.canEdit()
                }
            ]
        }
    ];

    public accountTypeColumns: Array<TableColumn> = [
        { name: 'Account Type', valueProp: 'businessAccountType.name', type: 'text' },
        this.documentObjectDefinition,
        {
            name: '', valueProp: 'actions', type: 'actions', actions: [
                {
                    function: this.editBizAccountType.bind(this), icon: 'edit',
                    disabled: !this.canEdit()
                }
            ]
        }
    ];

    public tradeTypeColumns: Array<TableColumn> = [
        {
            name: '', valueProp: 'actions', type: 'actions', actions: [
                {
                    function: this.editBizAccountType.bind(this), icon: 'arrow_drop_down',
                    disabled: !this.canEdit()
                }
            ]
        },
        { name: 'Trade', valueProp: 'label', type: 'text' },
        this.documentObjectDefinition
    ];

    public supplyTypeColumns: Array<TableColumn> = [
        {
            name: '', valueProp: 'actions', type: 'actions', actions: [
                {
                    function: this.editBizAccountType.bind(this), icon: 'arrow_drop_down',
                    disabled: !this.canEdit()
                }
            ]
        },
        { name: 'Supply', valueProp: 'label', type: 'text' },
        this.documentObjectDefinition
    ];

    constructor(
        public readonly dialogRef: MatDialog,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
        private readonly docTypeBizAccountTypesLogicService: DocTypeBizAccountTypesLogicService,
        private readonly docTypeTradeTypesLogicService: DocTypeTradeTypesLogicService,
        private readonly docTypeSupplyTypesLogicService: DocTypeSupplyTypesLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly featureToggleService: FeatureToggleStatesService
    ) {
        super(permissionsPermissions);
        this.JSON = JSON;
        featureToggleService.init().then(() => {
            this.isSupplierTypesEnabled = featureToggleService.isSupplierTypesEnabled;
            if (this.isSupplierTypesEnabled){
                this.loadSupplyTypeInformation();
            }
        });
    }

    public ngOnInit(): void {
        this.docTypeBizAccountTypesLogicService
            .getDocumentTypesList()
            .subOnce(result => {
                this.requiredDocsForBusinessAccountTypes = result;
            });
        this.businessAccountLogicService
            .getTradeTypes()
            .subOnce(result => {
                this.requiredDocsForTradeTypes = result;

                this.parentTradeTypes = this.requiredDocsForTradeTypes
                    .filter(t => !t.tradeType.parentId && t.tradeType.isActive)
                    .map(t => t.tradeType)
                    .sort((a, b) => a.label.localeCompare(b.label));

                this.sortedDocsForTradeTypes = {};
                const toRemove = [];
                this.parentTradeTypes.forEach(parent => {
                    this.sortedDocsForTradeTypes[parent.id] = this.requiredDocsForTradeTypes
                        .filter(t => t.tradeType.parentId === parent.id && t.tradeType.isActive)
                        .sort((a, b) => a.tradeType.label.localeCompare(b.tradeType.label));

                    if (this.sortedDocsForTradeTypes[parent.id].length === 0) {
                        toRemove.push(parent.id);
                    }
                });

                if (toRemove.length > 0) {
                    this.parentTradeTypes = this.parentTradeTypes.filter(ptt => toRemove.includes(ptt.id));
                }
            });
    }

    private loadSupplyTypeInformation(): void{
        this.businessAccountLogicService
            .getSupplyTypes()
            .subOnce((result: Array<IDocTypeSupplyTypeDto>) => {
                // Pre-sort all required documents
                this.requiredDocsForSupplyTypes = result
                    .filter(t => t.supplyType.isActive)
                    .sort((a, b) => a.supplyType.label.localeCompare(b.supplyType.label));

                // Create a map for efficient lookups for all parent types
                const docsByParentId = this.mapSupplyTypesToParentType();
                this.parentSupplyTypes = this.requiredDocsForSupplyTypes
                    .filter(t => !t.supplyType.parentId)
                    .map(t => t.supplyType);

                // Now process the parents and remove empty ones
                this.removeEmptyParentSupplyTypes(docsByParentId);
            });
    }

    private removeEmptyParentSupplyTypes(docsByParentId: Map<number, IDocTypeSupplyTypeDto[]>): void {
        this.sortedDocsForSupplyTypes = {};
        const toRemove: number[] = [];
        for (const parent of this.parentSupplyTypes) {
            // Get docs directly from the map
            this.sortedDocsForSupplyTypes[parent.id] = docsByParentId.get(parent.id) || [];
            if (this.sortedDocsForSupplyTypes[parent.id].length === 0) {
                toRemove.push(parent.id);
            }
        }
        // We found empty parents, so remove them from the main list
        if (toRemove.length > 0) {
            this.parentSupplyTypes = this.parentSupplyTypes.filter(supplyType => !toRemove.includes(supplyType.id));
        }
    }

    private mapSupplyTypesToParentType(): Map<number, IDocTypeSupplyTypeDto[]> {
        const docsByParentId = new Map<number, IDocTypeSupplyTypeDto[]>();
        this.requiredDocsForSupplyTypes
            .filter(t => t.supplyType.parentId)
            .forEach(doc => {
                const parentId = doc.supplyType.parentId;
                if (!docsByParentId.has(parentId)) {
                    docsByParentId.set(parentId, []);
                }
                docsByParentId.get(parentId).push(doc);
            });
        return docsByParentId;
    }

    public editBizAccountType(docTypeBizAccountType: IDocTypeBusinessAccountTypeDto): void {
        const mappedItem = this.docTypeBizAccountTypesLogicService
            .$createMappedItem(docTypeBizAccountType);
        this.dialogRef.open(
            BusinessAccountTypeDocsDialogComponent,
            {
                minWidth: '95%',
                data: {
                    dialogHeading: 'Required Document Types',
                    mappedItem
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .subOnce(this.handleEditItemBizType.bind(this));
    }

    public editTradeType(docTypeTradeType: IDocTypeTradeTypeDto): void {
        const mappedItem = this.docTypeTradeTypesLogicService
            .$createMappedItem(docTypeTradeType);
        this.dialogRef.open(
            BusinessAccountTypeDocsDialogComponent,
            {
                minWidth: '95%',
                data: {
                    dialogHeading: `Required Docs for ${docTypeTradeType.tradeType.label}`,
                    mappedItem
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .subOnce(this.handleEditItemTradeType.bind(this));
    }

    public editSupplyType(docTypeSupplyType: IDocTypeSupplyTypeDto): void {
        const mappedItem = this.docTypeSupplyTypesLogicService
            .$createMappedItem(docTypeSupplyType);
        this.dialogRef.open(
            BusinessAccountTypeDocsDialogComponent,
            {
                minWidth: '95%',
                data: {
                    dialogHeading: `Required Docs for ${docTypeSupplyType.supplyType.label}`,
                    mappedItem
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .subOnce(this.handleEditItemSupplyType.bind(this));
    }

    public handleEditItemBizType = (item: any): void => {
        if (item) {
            this.requiredDocsForBusinessAccountTypes.forEach(sg => {
                if (sg.id === item.id) {
                    Object.assign(sg, item);
                }
            });
        }
    };

    public handleEditItemTradeType = (item: any): void => {
        if (item) {
            this.requiredDocsForTradeTypes.forEach(sg => {
                if (sg.id === item.id) {
                    Object.assign(sg, item);
                }
            });
        }
    };

    public handleEditItemSupplyType = (item: any): void => {
        if (item) {
            this.requiredDocsForSupplyTypes.forEach(sg => {
                if (sg.id === item.id) {
                    Object.assign(sg, item);
                }
            });
        }
    };
}
