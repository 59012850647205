import { DisplayValueComponent } from './display-value/display-value.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CbDasherModule } from 'cb-hub-lib';
import { CbEllipsisModule } from '@app/shared/directives/ellipsis/ellipsis.module';

const COMPONENTS = [
    DisplayValueComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        CbDasherModule,
        CbEllipsisModule
    ],
    exports: COMPONENTS
})
export class CbDisplayValueModule { }
