import { Component, Input } from '@angular/core';
import { BaseClientSaleCardDirective } from '../base-client-sale-card';
import { ClientSaleDocumentsService } from '../../documents-service/client-sale-documents.service';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { getDocumentUploadLabel } from '../../document-upload-label.util';
import { ClientSalePermissions } from '@app/core/permissions';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Component({
    selector: 'cb-client-sale-build-contract-card',
    templateUrl: './build-contract-card.component.html',
    styleUrls: ['./build-contract-card.component.scss']
})
export class ClientSaleBuildContractCardComponent extends BaseClientSaleCardDirective {

    @Input() public readonly stepNumber: number;

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public get isComplete(): boolean {
        return this.clientSale?.stepCompletion?.buildContract;
    }

    public getBuildContractAlert(): string {
        if (!this.buildContractDownloaded) {
            return 'Download Signed Build Contract';
        } else if (!this.buildContractUploaded) {
            return 'Upload Signed Build Contract';
        } else if (!this.buildContractReviewed) {
            return 'Signed Build Contract requires review';
        } else {
            return null;
        }
    }

    public get buildContractDownloaded(): boolean {
        return !isNullOrWhiteSpace(this.clientSale?.documentInfo?.buildContractDownloadDate as string);
    }

    public get buildContractUploaded(): boolean {
        return !isNullOrWhiteSpace(this.clientSale?.documentInfo?.buildContractUploadDate as string);
    }

    public get buildContractReviewed(): boolean {
        return !isNullOrWhiteSpace(this.clientSale?.documentInfo?.buildContractReviewedDate as string);
    }

    public getCreateScheduleAlert(): string {
        if (!this.createScheduleDownloaded) {
            return 'Download Signed Create Schedule';
        } else if (!this.createScheduleUploaded) {
            return 'Upload Signed Create Schedule';
        } else if (!this.createScheduleReviewed) {
            return 'Signed Create Schedule requires review';
        } else {
            return null;
        }
    }

    public get createScheduleUploaded(): boolean {
        return !isNullOrWhiteSpace(this.clientSale?.documentInfo?.clientSpecificationUploadDate as string);
    }

    public get createScheduleDownloaded(): boolean {
        return !isNullOrWhiteSpace(this.clientSale?.documentInfo?.clientSpecificationDownloadDate as string);
    }

    public get createScheduleReviewed(): boolean {
        return !isNullOrWhiteSpace(this.clientSale?.documentInfo?.clientSpecificationReviewedDate as string);
    }

    public readonly uploadLabels = new ComputedProperty(() => {
        return {
            buildContract: getDocumentUploadLabel(
                this.clientSale?.documentInfo?.buildContractUploadDate,
                this.clientSaleDocuments?.documents?.buildContract?.entity?.documentStatus
            ),
        };
    });

    constructor(
        public readonly clientSaleDocuments: ClientSaleDocumentsService,
        public readonly clientSalePermissions: ClientSalePermissions,
    ) {
        super(clientSaleDocuments);
    }

    public recomputeIconProps(): void {
        super.recomputeIconProps();
        this.uploadLabels.recompute();
    }

    public downloadBuildContract(): void {
        this.clientSale.downloadBuildContract().subOnce();
    }

    public downloadCreateSchedule(): void {
        if (!this.clientSalePermissions.canDownloadCreateSchedule()) {
            return;
        }
        this.clientSale.downloadCreateSchedule(this.clientSale?.lot?.hasScheduleSpecTemplate)
            .subOnce();
    }

    public isLotSpecScheduleTemplate(): boolean {
        return this.clientSale?.lot?.hasScheduleSpecTemplate ?? false;
    }
}
