import {Component, Input, OnInit} from '@angular/core';
import {BaseSimpleListViewDirective} from '@app/shared/base-views/base-simple-list-view.directive';
import {ToastService} from '@app/core/services/toast/toast.service';
import {LotBuildOverheadCostsPermissions, PermissionsPermissions} from '@app/core/permissions';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {LotBuildOverheadCostLogicService} from '@app/logic/lot-build-overhead-costs';
import {ILotBuildOverheadCostsDto} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/LotBuildOverheadCostsDto';
import {LotBuildOverheadCostMappedItem} from '@app/logic/lot-build-overhead-costs/lot-build-overhead-cost.mapped';
import {LotBuildOverheadCostsDialogComponent} from '@app/views/lot/build/lot-build-overhead-costs/lot-build-overhead-costs-dialog/lot-build-overhead-costs-dialog.component';
import {Observable} from 'rxjs';
import {ILotMappedItem} from '@app/logic/lots';

@Component({
    selector: 'cb-lot-build-overhead-costs',
    templateUrl: './lot-build-overhead-costs.component.html',
    styleUrls: ['./lot-build-overhead-costs.component.scss']
})
export class LotBuildOverheadCostsComponent extends BaseSimpleListViewDirective<ILotBuildOverheadCostsDto, LotBuildOverheadCostLogicService> implements OnInit {
    @Input() public lotMappedItem: ILotMappedItem;
    public readonly displayedColumns: string[] = ['Name', 'Notes', 'Finance Code', 'Amount (GST Inclusive)', 'Amount (GST Exclusive)', 'Updated', ''];

    constructor(
        public readonly toastService: ToastService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        public readonly logicService: LotBuildOverheadCostLogicService,
        public readonly lotBuildOverheadCostsPermissions: LotBuildOverheadCostsPermissions
    ) {
        super(
            dialog,
            logicService,
            LotBuildOverheadCostsDialogComponent,
            'Build Overhead Cost',
            LotBuildOverheadCostMappedItem,
            permissionsPermissions,
            false,
            '33%'
        );
    }

    public ngOnInit(): void {
        this.otherData = { lotId: this.lotMappedItem.id, reloadFn: this.reloadFn };
        if (this.lotMappedItem.id > 0) {
            this.reloadFn();
        }
    }

    public deleteItem(dto: ILotBuildOverheadCostsDto): void {
        this.deleteItemClicked(dto, {
            heading: 'Build Overhead Cost',
            message: `Are you sure you want to remove this cost? (${dto.buildOverheadCost.name})`
        });
    }

    public reloadFn = (): void => {
        this.getSearchResults().subOnce(x => {
            this.handleSearchResults(x);
        });
    };

    public getSearchResults(): Observable<Array<ILotBuildOverheadCostsDto>> {
        return this.logicService.$getList(this.lotMappedItem?.id);
    }
}
