import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import {
    DesignConceptsLogicService,
    IDesignConceptMappedItem
} from '@app/logic/design-concepts';
import { ILotMappedItem } from '@app/logic/lots';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-design-concepts',
    templateUrl: './design-concepts.component.html',
    styleUrls: ['./design-concepts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignConceptsComponent implements OnInit {


    public selectedItem: IDesignConceptMappedItem;
    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    @Output() public lotMappedItemChange = new EventEmitter();

    constructor(
        private readonly navigationService: NavigationService,
        private readonly designConceptLogicService: DesignConceptsLogicService,
        public cdRef: ChangeDetectorRef,
    ) { }

    public ngOnInit(): void {
        this.loadQueryParams();
    }

    private loadQueryParams(): void {
        const queryParams = this.navigationService.getQueryParams<{ paramEntityId?: string }>();
        if (queryParams?.paramEntityId) {
            this.loadDesignConcept(Number(queryParams.paramEntityId));
        }
    }

    private loadDesignConcept(designConceptId: number): void {
        this.designConceptLogicService
            .$getMappedItem(designConceptId)
            .subOnce((e) => {
                this.designConceptSelected(e);
                this.cdRef.detectChanges();
            });
    }

    public designConceptSelected(event: IDesignConceptMappedItem): void {
        this.selectedItem = event;
    }

    public goBackToSearch(): void {
        this.selectedItem = undefined;
        this.navigationService.navigate([`/lots/${this.lotMappedItem.id}/design/concepts`]);
    }

    public lotMappedItemChanged(): void {
        this.lotMappedItemChange.emit();
    }
}
