import { ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import { BusinessAccountPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { BusinessAccountLogicService } from '@app/logic/business-accounts';
import { QuoteLineLogicService } from '@app/logic/quote';
import { IIdAndLabelDto } from '@app/shared/interfaces/i.idandlabel.dto';
import { IQuoteDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'cb-business-account-quotes',
    templateUrl: './business-account-quotes.component.html',
    styleUrls: ['./business-account-quotes.component.scss']
})
export class BusinessAccountQuotesComponent {
    @Input() public accountId: number;

    public isInitialised = false;
    public quotes: any[];
    public filters: any = {};
    public refreshData$ = new Subject();
    public sortOrders: IIdAndLabelDto<string>[] = [
        { id: 'date', label: 'Date' },
        { id: 'quoteNumber', label: 'Quote Number' },
    ];
    public sortDirections: IIdAndLabelDto<string>[] = [
        { id: 'desc', label: 'Descending' },
        { id: 'asc', label: 'Ascending' },
    ];

    constructor(
        public readonly permissions: BusinessAccountPermissions,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
        private readonly quoteLineLogicService: QuoteLineLogicService,
        private readonly navigationService: NavigationService,
        private readonly cdr: ChangeDetectorRef,
        private readonly ngZone: NgZone,
    ) {
    }

    public ngAfterViewInit(): void {
        this.filters = {
            currentpage: 1,
            pageSize: 10,
            sort: this.sortOrders[0].id,
            sortDirection: this.sortDirections[0].id,
        };
        this.cdr.detectChanges();

        this.ngZone.run(() => {
            setTimeout(() => {
                this.refreshData();
            });
        });
    }

    public refreshData(): void {
        this.quotes = [];
        this.isInitialised = true;
        this.refreshData$.next(null);
        this.cdr.detectChanges();
    }

    public search(): Observable<any> {
        return this.businessAccountLogicService.getQuotes(this.accountId, this.filters);
    }

    public downloadQuoteClicked(quoteId: number): void {
        this.quoteLineLogicService.download(quoteId).subOnce();
    }

    public viewLotClicked(quote: IQuoteDto): void {
        this.navigationService.navigate([`/lots/${quote.lotId}/summary`]);
    }

    public viewOrdersClicked(quote: IQuoteDto): void {
    }
}
