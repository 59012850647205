import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SearchIndexesLogicService } from '@app/logic/search-indexes/search-indexes.logic.service';
import { ISystemLogSearchDto, SystemLogsLogicService } from '@app/logic/system-logs';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { SEARCH_INDEX_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { debounceTime, defaultIfEmpty, filter, Observable, startWith, Subject, Subscription } from 'rxjs';
import { SystemLogViewDialogComponent } from '../system-log-view-dialog/system-log-view-dialog.component';


@Component({
    selector: 'cb-system-logs',
    templateUrl: './system-logs.component.html',
    styleUrls: ['./system-logs.component.scss']
})
export class SystemLogsComponent implements OnInit, OnDestroy {
    public static TRUNCATE_PERCENTAGE = 0.08; // 8%;

    public searchText = new UntypedFormControl();
    public dateFrom: DateString;
    public dateTo: DateString;

    public searchEnabled = false;
    public logs: ISystemLogSearchDto[] = [];
    public currentPage: number;
    public readonly queryUpdated = new Subject();

    public readonly subscriptions$ = new Subscription();

    public readonly isNullOrWhiteSpace = isNullOrWhiteSpace;

    public readonly columns = [
        { label: 'Datetime', width: 150 },
        { label: 'Instance ID', width: 150 },
        { label: 'Instance IP', width: 150 },
        'Source',
        'Details',
        ''
    ];

    constructor(
        private readonly systemLogsLogic: SystemLogsLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly searchIndexesLogic: SearchIndexesLogicService,
    ) { }

    public ngOnInit(): void {
        this.createSearchTextSub();
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    public clearFilters(): void {
        this.dateFrom = null;
        this.dateTo = null;
        this.searchText.setValue('');
    }

    public cleanSearch(): void {
        this.searchEnabled = true;
        this.queryUpdated.next(null);
    }

    public clearDateTo(): void {
        this.dateTo = null;
        this.cleanSearch();
    }

    public clearDateFrom(): void {
        this.dateFrom = null;
        this.cleanSearch();
    }

    public search(): Observable<ISearchResult<ISystemLogSearchDto>> {
        const params = {
            currentpage: this.currentPage,
            query: this.searchText.value ?? '',
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
        };
        return this.systemLogsLogic
            .search(params);
    }

    public viewSystemLog(log: ISystemLogSearchDto): void {
        this.cbDialog.open(SystemLogViewDialogComponent, {
            data: {
                log
            },
            minWidth: '40%',
        });
    }

    public reindexLogs(): void {
        const ref = this.cbDialog.block('Fetching Latest Logs...');
        this.searchIndexesLogic.reindex(SEARCH_INDEX_ENUM.HubApiLogs).subOnce(() => {
            setTimeout(() => {
                ref.close();
                this.cleanSearch();
            }, 1000);
        });
    }

    public getTruncatedDetails(str = ''): string {
        const maxLength = (window.innerWidth * SystemLogsComponent.TRUNCATE_PERCENTAGE);
        return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
    }

    private createSearchTextSub(): void {
        this.subscriptions$.add(
            this.searchText.valueChanges
                .pipe(
                    defaultIfEmpty(''),
                    startWith(''),
                    debounceTime(400),
                    filter(q => typeof q === 'string')
                )
                .subscribe(() => {
                    this.cleanSearch();
                })
        );
    }
}
