const defaultDeleteCheck = (value: any): boolean => value == null;

/** Removes all `null` or `undefined` props from an object; top level only \
 *  Use `cleanObjectDeep` for multi level cleaning
 */
export function cleanObject<Obj extends Object>(obj: Obj, deleteCheck = defaultDeleteCheck): Obj {
    Object.keys(obj).forEach(key => {
        if (deleteCheck(obj[key])) {
            return delete obj[key];
        }
    });

    return obj;
}

/** **Recursive** - Removes all `null` or `undefined` props from an object, it's child objects and arrays */
export function cleanObjectDeep<Obj extends Object>(obj: Obj, deleteCheck = defaultDeleteCheck): Obj {
    Object.keys(obj).forEach(key => {
        if (deleteCheck(obj[key])) {
            return delete obj[key];
        }

        if (obj[key] instanceof Array) {
            cleanArrayDeep(obj[key], deleteCheck);
        } else if (obj[key] instanceof Object) {
            cleanObjectDeep(obj[key], deleteCheck);
        }
    });

    return obj;
}

/** Removes all `null` or `undefined` values from an array; top level only \
 *  Use `cleanArrayDeep` for multi level cleaning
 */
export function cleanArray<Arr extends Array<any>>(arr: Arr, deleteCheck = defaultDeleteCheck): Arr {
    arr.forEach((val, index) => {
        if (deleteCheck(val)) {
            return arr.splice(index, 1);
        }
    });

    return arr;
}

/** **Recursive** -Removes all `null` or `undefined` values from an array, it's child arrays and objects */
export function cleanArrayDeep<Arr extends Array<any>>(arr: Arr, deleteCheck = defaultDeleteCheck): Arr {
    arr.forEach((val, index) => {
        if (deleteCheck(val)) {
            return arr.splice(index, 1);
        }

        if (val instanceof Array) {
            cleanArrayDeep(val, deleteCheck);
        } else if (val instanceof Object) {
            cleanObjectDeep(val, deleteCheck);
        }
    });

    return arr;
}

