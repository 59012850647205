import { Component } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { DocumentGroupLogicService, DocumentsLogicService } from '@app/logic/documents';
import { ITempLotMappedItem, TempLotMappedItem } from '@app/logic/temp-lots';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import {
    DOCUMENT_GROUP_CODES_CONST,
    DOCUMENT_TYPE_CODES_CONST,
    ITempLotDto,
    LOT_EXTERNAL_STATUS_ENUM,
} from '@classictechsolutions/hubapi-transpiled-enums';
import { combineLatest, Observable } from 'rxjs';

import { TempLotEditDialogComponent } from '../temp-lot-edit-dialog/temp-lot-edit-dialog.component';
import { UploadBrochureDialogComponent } from '../upload-brochure-dialog/upload-brochure-dialog.component';
import { TempLotsLogicService } from './../../../logic/temp-lots/temp-lots-logic.service';
import { IDocumentDto } from '@app/logic/documents/interfaces/i.document.dto';

@Component({
    selector: 'cb-temp-lot-list',
    templateUrl: './temp-lot-list.component.html',
    styleUrls: ['./temp-lot-list.component.scss']
})
export class TempLotListComponent extends BaseSimpleListViewDirective<ITempLotMappedItem, TempLotsLogicService> {

    public searchText = '';

    public filteredResults: Array<ITempLotDto>;

    public readonly LOT_EXTERNAL_STATUS_ENUM = LOT_EXTERNAL_STATUS_ENUM;

    public readonly displayedColumns: string[] = [
        'jobNumber',
        'lotNumber',
        'sellingPrice',
        'status',
        'address',
        'region',
        'project',
        'businessEntity',
        'isActive',
        'isPublished',
        'actions'
    ];

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        public readonly tempLotsLogic: TempLotsLogicService,
        public readonly documentGroupLogic: DocumentGroupLogicService,
        public readonly documentTypeLogic: DocumentTypesLogicService,
        public readonly documentsLogic: DocumentsLogicService,
        public readonly toast: ToastService,
    ) {
        super(
            dialog,
            tempLotsLogic,
            TempLotEditDialogComponent,
            'Temp Lot',
            TempLotMappedItem,
            permissionsPermissions
        );
    }

    public getSearchResults(): Observable<ITempLotMappedItem[]> {
        return this.logicService.$getMappedList();
    }

    @Debounce()
    public searchTextChange(): void {
        this.sortSearchResults(this.searchResults);
    }

    public filterResults(searchResults: Array<ITempLotMappedItem>): Array<ITempLotMappedItem> {
        return searchResults
            .filter(x => {
                const contents = this.extractObjectContents(x);
                return contents.includes(this.searchText.toLowerCase());
            });
    }

    public extractObjectContents(obj: Object): string {
        const toMap = obj instanceof BaseMappedItem ? obj.$getMappedDtoItem() : obj;
        return Object.values(toMap).map(y => {
            if (y == null || typeof y === 'boolean') {
                return;
            }
            if (y instanceof Object) {
                return this.extractObjectContents(y);
            }
            return y.toString().toLowerCase();
        }).filter(x => x != null).join(' ');
    }

    public sortSearchResults(searchResults: Array<ITempLotMappedItem>): Array<ITempLotMappedItem> {
        this.filteredResults = this.filterResults(searchResults);
        return searchResults;
    }

    public downloadBrochure(tempLot: ITempLotMappedItem): void {
        if (!tempLot.canDownloadBrochure) {
            return;
        }
        tempLot.downloadBrochure().subOnce({
            next: () => {
                this.toast.showToast('Dowload Successful');
            }
        });
    }

    public deleteBrochure(tempLot: ITempLotMappedItem): void {
        if (!tempLot.canDeleteBrochure) {
            return;
        }
        this.cbDialog.confirm({
            message: 'Are you should you want to delete the House & Land Brochure for this Temp Lot?',
            confirmed: () => {
                tempLot.deleteBrochure().subOnce({
                    next: () => {
                        this.toast.showToast('Delete Successful');
                    }
                });
            }
        });
    }

    public uploadBrochure(tempLot: ITempLotMappedItem): void {

        combineLatest([
            this.documentGroupLogic
                .getByCode(DOCUMENT_GROUP_CODES_CONST.LOT_PUBLIC_DOCS)
            ,
            this.documentTypeLogic
                .getByCode(DOCUMENT_TYPE_CODES_CONST.HOUSE_AND_LAND_BROCHURE)
        ])
            .subOnce({
                next: ([documentGroup, documentType]) => {
                    if (documentType == null) {
                        return;
                    }

                    const documentEntity = this.documentsLogic.$createMappedItem({
                        documentType,
                        document: {} as IDocumentDto,
                        documentGroup,
                    });

                    this.dialog
                        .open(UploadBrochureDialogComponent, {
                            data: {
                                documentEntity
                            },
                            minWidth: 400,
                        })
                        .afterClosed()
                        .subOnce({
                            next: (file) => {
                                if (!file) {
                                    return;
                                }
                                tempLot
                                    .uploadBrochure(file)
                                    .subOnce({
                                        next: () => {
                                            this.toast.saveSuccess();
                                        }
                                    });
                            }
                        });
                }
            });
    }
}
