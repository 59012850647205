import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IPurchaseOrderDto } from '@app/logic/purchase-orders/interfaces/i.purchase-order.dto';
import { IPurchaseOrderMappedItem } from '@app/logic/purchase-orders';
import { IPurchaseOrdersLogicService } from '@app/logic/purchase-orders/interfaces/i.purchase-orders.logic.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SelectVariationDialogComponent } from '../../build/orders/select-variation/select-variation.component';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotSpecItemColourYourDreamsLogicService } from '@app/logic/lot-spec/lot-spec.logic.service';
import { ILotSpecItemColourYourDreamsMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import _ from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { Subscription, Observable } from 'rxjs';
import { ILotSpecItemUpdateItemDto } from '@app/logic/lot-spec/interfaces/i.lot-spec-order.dto';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

interface IData {
    lotId: number;
    specVersion: IPurchaseOrderMappedItem;
    isLotSpecScheduleItem: boolean;
}

interface IGroup {
    id: number;
    name: string;
    sortOrder: number;
}

interface IGroupWithExpansion {
    id: number;
    name: string;
    expanded: boolean;
    sortOrder: number;
}

interface IOrderChangedItem {
    specGroupId: number;
    lotSpecItemOrder: ILotSpecItemOrder[];
}

interface ILotSpecItemOrder {
    id: number;
    orderId: number;
}

interface IFlagChangedItem {
    id: number;
    showInColourYourDreams: boolean;
}

@Component({
    selector: 'cb-manage-lot-spec-colour-item',
    templateUrl: './manage-lot-spec-colour-item.component.html',
    styleUrls: ['./manage-lot-spec-colour-item.component.scss']
})
export class ManageLotSpecColourItemDialogComponent
    extends BaseDialogFormViewDirective<IPurchaseOrderDto, IPurchaseOrderMappedItem, IPurchaseOrdersLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '70%';

    public isExpanded = true;

    public groups: IGroup[] = [];
    public groupedItems;
    public groupWithExpansion: IGroupWithExpansion[] = [];

    public finishedLoading = false;
    public expandAll = true;

    public dragulaGroup = 'COLOUR_ITEMS';
    public dragulaModel: number[] = [];
    public sortOrderChanged = false;

    public readonly displayedColumns: string[] = ['productDisplay'];
    private readonly $subs = new Subscription();

    public orderChangedItemsToSave: Array<IOrderChangedItem> = [];
    public flagChangedItemToSave: Array<IFlagChangedItem> = [];

    constructor(
        public readonly dialogRef: MatDialogRef<SelectVariationDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly lotSpecItemColourYourDreamsLogicService: LotSpecItemColourYourDreamsLogicService,
        public readonly dragulaService: DragulaService,
    ) {
        super(dialogRef, toastService);
    }


    public ngOnInit(): void {

        if (this.data.isLotSpecScheduleItem) {
            this.lotSpecItemColourYourDreamsLogicService.getMappedLotSpecSheduleItemColorSchedule(this.data.lotId)
                .subOnce(this.handleLoadItems);
        } else {
            /// Only load dragula for old spec items
            this.setDragulaModel();
            this.lotSpecItemColourYourDreamsLogicService.getMappedLotSpecItemColourYourDreams(this.data.lotId)
                .subOnce(this.handleLoadItems);
        }
    }


    private readonly handleLoadItems = (items: ILotSpecItemColourYourDreamsMappedItem[]): void => {
        this.groupedItems = _.groupBy(items, 'specGroupName');

        items.forEach(element => {

            if (!(this.groups.some(x => x.id === element.specGroupId))) {
                const group = { name: element.specGroupName, id: element.specGroupId, sortOrder: element.specGroupOrder };
                this.groups.push(group);
            }
        });

        this.expandCollapseAll();
        this.finishedLoading = true;
    };

    public expandCollapseAll(): void {
        this.expandAll = !this.expandAll;
        this.groupWithExpansion = [];

        this.groups.forEach(
            item => {
                const setItem: IGroupWithExpansion = {
                    expanded: this.expandAll,
                    id: item.id,
                    name: item.name,
                    sortOrder: item.sortOrder,
                };
                this.groupWithExpansion.push(setItem);
            }
        );

    }

    public resetAllExpanded(): boolean {
        return !this.isExpanded;
    }

    public getItemsOnThisGroup(groupName: string): ILotSpecItemColourYourDreamsMappedItem[] {
        return this.groupedItems[groupName];
    }

    public getItemsSizeOnThisGroup(groupName: string): number {
        return (this.groupedItems[groupName]).length;
    }

    public setDragulaModel(): void {
        this.dragulaService.destroy(this.dragulaGroup);
        this.dragulaService.createGroup(this.dragulaGroup, {
            moves: () => {
                return true;
            },
            invalid: (el) => el.classList.contains('dragula-ignore')
        });

        this.$subs.add(
            this.dragulaService.drop(this.dragulaGroup).subscribe(this.handleDrop)
        );
    }


    private readonly handleDrop = ({ name, el, target, source, sibling }): void => {

        const _specGroupId = Number(target?.id?.split('_').pop() ?? 0);

        const isGroupAlreeadyOnArray = this.orderChangedItemsToSave.some(x => x.specGroupId === _specGroupId);

        let indexofRecentItem;

        if (isGroupAlreeadyOnArray) {

            const recentItem = _.find(this.orderChangedItemsToSave, toSave => toSave.specGroupId === _specGroupId);

            indexofRecentItem = this.orderChangedItemsToSave.indexOf(recentItem);
            this.orderChangedItemsToSave[indexofRecentItem].lotSpecItemOrder = [];
        } else {
            const grouptoAdd = { specGroupId: _specGroupId, lotSpecItemOrder: [] };
            this.orderChangedItemsToSave.push(grouptoAdd);
            indexofRecentItem = this.orderChangedItemsToSave.indexOf(grouptoAdd);
        }

        target.childNodes.forEach(element => {
            const childId = Number(element?.id?.split('_').pop() ?? 0);
            const childIndex = element.rowIndex;

            if (childId > 0 && childIndex > 0) {
                this.orderChangedItemsToSave[indexofRecentItem].lotSpecItemOrder.push({ id: childId, orderId: childIndex });
            }
        });

        this.sortOrderChanged = true;
    };

    public saveMethod(): Observable<ILotSpecItemColourYourDreamsMappedItem[]> {
        const updateItems: ILotSpecItemUpdateItemDto = { lotSpecItemWithColourYourDreamFlags: this.flagChangedItemToSave, lotSpecItemsOrder: this.orderChangedItemsToSave };

        if (this.data.isLotSpecScheduleItem) {
            return this.lotSpecItemColourYourDreamsLogicService.updateColourSchedule(this.data.lotId, updateItems);
        } else {
            return this.lotSpecItemColourYourDreamsLogicService.updateColourItems(this.data.lotId, updateItems);
        }
    }

    public readonly handleSaveSuccess = (): void => {
        this.orderChangedItemsToSave = [];
        this.flagChangedItemToSave = [];
    };
    protected readonly handleNext = (): void => {
        this.dialogRef.close({ sortOrderChanged: this.sortOrderChanged });
        this.toastSerivce.saveSuccess();
    };

    public onColourYourDreamFlagChange(eve: MatCheckboxChange, item: ILotSpecItemColourYourDreamsMappedItem): void {

        const isChangeAlreeadyOnArray = this.flagChangedItemToSave.some(x => x.id === item.id);

        let indexofRecentItem;

        if (isChangeAlreeadyOnArray) {

            const recentItem = _.find(this.flagChangedItemToSave, source => source.id === item.id);
            indexofRecentItem = this.flagChangedItemToSave.indexOf(recentItem);
            this.flagChangedItemToSave[indexofRecentItem].showInColourYourDreams = eve.checked;

        } else {
            const itemToAdd = { id: item.id, showInColourYourDreams: eve.checked };
            this.flagChangedItemToSave.push(itemToAdd);
        }

    }

    public isFormEnabled(): boolean {
        return !this.saveInProgress && (this.orderChangedItemsToSave.length > 0 || this.flagChangedItemToSave.length > 0);
    }
}
