import { EventEmitter, Injectable } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    public readonly documentUploaded = new Subject<IDocumentEntityMappedItem>();
    public readonly documentReviewed = new Subject<IDocumentEntityMappedItem>();
    public readonly documentDeleted = new Subject<IDocumentEntityMappedItem>();
    /** emits on documentUploaded, documentReviewed or documentDeleted  */
    public readonly any$ = merge(this.documentUploaded, this.documentReviewed, this.documentDeleted);
    public readonly refreshDocumentList = new EventEmitter<boolean>();
}
