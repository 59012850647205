import { BaseMappedItem } from '../base/base-mapped-item';
import { ITaskOutcomeDto } from './interfaces/i.task-outcome.dto';
import { ITaskOutcomeMappedItem } from './interfaces/i.task-outcome.mapped';
import { ITaskOutcomesLogicService } from './interfaces/i.task-outcomes.logic.service';
import { TaskTypeConstantsConstId } from '@classictechsolutions/hubapi-transpiled-enums';
import { DtoProp } from '../base/dto-prop.decorator';

export class TaskOutcomeMappedItem
    extends BaseMappedItem<ITaskOutcomeDto, ITaskOutcomeMappedItem, ITaskOutcomesLogicService>
    implements ITaskOutcomeMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public code: string;
    @DtoProp public isActive: boolean;
    @DtoProp public name: string;
    @DtoProp public taskType: TaskTypeConstantsConstId;
    @DtoProp public systemAreas: number[];

    constructor(
        sourceData: ITaskOutcomeDto,
        logicService: ITaskOutcomesLogicService
    ) {
        super(sourceData, logicService, TaskOutcomeMappedItem);
    }
}
