import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ILeadDto, LeadLogicService} from '@app/logic/leads';
import {LOT_CONTRACT_TYPE_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {LeadPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {LeadMappedItem} from '@app/logic/leads/lead.mapped';
import {viewLeadUrl} from '@app/views/leads/lead-routes';

@Component({
    selector: 'cb-lead-card',
    templateUrl: './lead-card.component.html',
    styleUrls: ['./lead-card.component.scss']
})
export class LeadCardComponent {
    @Input() public dto: ILeadDto;
    @Output() public readonly selectionChosenEmitter = new EventEmitter();
    public get contractType(): string {
        return LOT_CONTRACT_TYPE_ENUM[this.dto.contractType];
    }

    constructor(
        private readonly leadPermissions: LeadPermissions,
        private readonly navigationService: NavigationService,
        private readonly leadLogicService: LeadLogicService
    ) {
    }

    public selectionChosen(val: ILeadDto): void {
        this.selectionChosenEmitter.emit(this.leadLogicService.$createMappedItem(val, LeadMappedItem));
    }

    public canView(): boolean {
        return this.leadPermissions.canView();
    }

    public viewLead(event): void {
        this.navigationService.navigate([viewLeadUrl(this.dto.id)], event);
    }

    public getCallbackDate(): string {
        if (!this.dto.callbackDate || (this.dto.status as any === 'Lost') || (this.dto.status as any === 'Qualified')) {
            return undefined;
        }
        return this.dto.callbackDate;
    }

}
