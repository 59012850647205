<cb-dialog>
    <form class="flex-col flex"
          #globalSettingsForm="ngForm">
        <cb-display-value-new label="Key"
                              [value]="key">
        </cb-display-value-new>
        <cb-input type="text"
                  name="description"
                  label="Description"
                  [(ngModel)]="mappedItem.description">
        </cb-input>
        <cb-input type="text"
                  name="value"
                  label="Value"
                  [(ngModel)]="mappedItem.value">
        </cb-input>
        <cb-checkbox name="isActive"
                     label="Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="getDisabled()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
