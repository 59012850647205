import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IChangeOptionDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';

import { BaseLogicService } from '../base/base-logic.service';
import { ChangeOptionMappedItem } from './change-option.mapped';
import { IChangeOptionLogicService } from './interfaces/i.change-option.logic-service';
import { IChangeOptionMappedItem } from './interfaces/i.change-option.mapped';



@Injectable()
export class ChangeOptionLogicService
    extends BaseLogicService<IChangeOptionDto, IChangeOptionMappedItem>
    implements IChangeOptionLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'changerecords/changeoptions',
            ChangeOptionMappedItem
        );
    }

    public deleteOption(changeOptionId: number): Observable<boolean> {
        return this.$http.delete(`${this.$baseUri}/${changeOptionId}`);
    }

    public saveChangeOption(changeOption: IChangeOptionDto): Observable<IChangeOptionDto> {
        if (changeOption.id > 0) {
            return this.$http.post(`${this.$baseUri}/${changeOption.id}`, changeOption);
        }
        return this.$http.post(`${this.$baseUri}/0`, changeOption);
    }

}
