import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ILotMappedItem} from '@app/logic/lots';
import {IPreConsentPlanMappedItem, PreConsentPlansLogicService} from '@app/logic/pre-consent-plans';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {IPreConsentPlanDto, PRECONSENT_PLAN_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {ReviewPreconsentPlanDialogComponent} from '@app/views/lot/design/pre-consent/review-preconsent-plan-dialog/review-preconsent-plan-dialog.component';

@Component({
    selector: 'cb-pre-consent-card',
    templateUrl: './pre-consent-card.component.html',
    styleUrls: ['./pre-consent-card.component.scss']
})
export class PreConsentCardComponent implements OnInit {
    public PRECONSENT_PLAN_STATUS_ENUM = PRECONSENT_PLAN_STATUS_ENUM;
    public mappedItem: IPreConsentPlanMappedItem;
    public elapsedTime: string;

    @Input() public set preConsentPlan(preConsentPlanDto: IPreConsentPlanDto) {
        this.mappedItem = this.preConsentPlansLogicService.$createMappedItem(preConsentPlanDto);
    }

    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public lotMappedItemChange = new EventEmitter<ILotMappedItem>();
    @Output() public viewPreConsentClicked = new EventEmitter<IPreConsentPlanMappedItem>();

    constructor(
        private readonly preConsentPlansLogicService: PreConsentPlansLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.getElapsedTime();
    }

    public getElapsedTime(): void {
        this.mappedItem.getElapsedTime().subOnce(x => this.elapsedTime = x);
    }

    public getStatusIconIcon(): string {
        switch (this.mappedItem.preConsentPlanStatus.id) {
            case PRECONSENT_PLAN_STATUS_ENUM.Finalised:
            case PRECONSENT_PLAN_STATUS_ENUM.Completed:
                return 'check_circle';
            case PRECONSENT_PLAN_STATUS_ENUM.Pending:
                return 'timelapse';
            case PRECONSENT_PLAN_STATUS_ENUM.InQueue:
            case PRECONSENT_PLAN_STATUS_ENUM.InProgress:
            case PRECONSENT_PLAN_STATUS_ENUM.Rejected:
            case PRECONSENT_PLAN_STATUS_ENUM.WaitingForReview:
                return 'assignment';
            case PRECONSENT_PLAN_STATUS_ENUM.OnHold:
                return 'pause_circle_filled';
            case PRECONSENT_PLAN_STATUS_ENUM.Cancelled:
                return 'indeterminate_check_box';
            default:
                return 'assignment';
        }
    }

    public getStatusIconClass(): string {
        switch (this.mappedItem.preConsentPlanStatus.id) {
            case PRECONSENT_PLAN_STATUS_ENUM.Finalised:
            case PRECONSENT_PLAN_STATUS_ENUM.Completed:
                return 'mat-primary';
            case PRECONSENT_PLAN_STATUS_ENUM.Rejected:
                return 'cb-grey';
            case PRECONSENT_PLAN_STATUS_ENUM.InQueue:
            case PRECONSENT_PLAN_STATUS_ENUM.OnHold:
            case PRECONSENT_PLAN_STATUS_ENUM.InProgress:
            case PRECONSENT_PLAN_STATUS_ENUM.Pending:
            case PRECONSENT_PLAN_STATUS_ENUM.WaitingForReview:
            case PRECONSENT_PLAN_STATUS_ENUM.DesignReviewRequired:
                return 'mat-accent';
            case PRECONSENT_PLAN_STATUS_ENUM.Cancelled:
                return 'mat-warn';
            default:
                return '';
        }
    }

    public getStatusIconTitle(): string {
        switch (this.mappedItem.preConsentPlanStatus.id) {
            case PRECONSENT_PLAN_STATUS_ENUM.Finalised:
                return 'Finalised';
            case PRECONSENT_PLAN_STATUS_ENUM.Pending:
                return 'Pending';
            case PRECONSENT_PLAN_STATUS_ENUM.InQueue:
                return 'In Queue';
            case PRECONSENT_PLAN_STATUS_ENUM.OnHold:
                return 'On Hold';
            case PRECONSENT_PLAN_STATUS_ENUM.Cancelled:
                return 'Cancelled';
            case PRECONSENT_PLAN_STATUS_ENUM.InProgress:
                return 'In Progress';
            case PRECONSENT_PLAN_STATUS_ENUM.Rejected:
                return 'Branch - To Resolve';
            case PRECONSENT_PLAN_STATUS_ENUM.WaitingForReview:
            case PRECONSENT_PLAN_STATUS_ENUM.DesignReviewRequired:
                return 'Waiting for Review';
            case PRECONSENT_PLAN_STATUS_ENUM.Completed:
                return 'Accepted';
            default:
                return '';
        }
    }

    public getHeading(): string {
        return this.mappedItem.planName ? `Pre-consent Plan ${this.mappedItem.planName}` : 'Pre-consent Plan';
    }

    public review(): void {
        const dialogRef = this.cbDialog.open(ReviewPreconsentPlanDialogComponent, {
            data: {
                mappedItem: this.mappedItem.$clone(),
                lot: this.lotMappedItem.$clone()
            }
        });

        dialogRef.afterClosed()
            .subOnce(() => {
                this.mappedItem.$reload().subOnce(x => {
                    this.lotMappedItem.$reload().subOnce(z => {
                        this.cdRef.detectChanges();
                    });
                });
            });
    }

    public viewPreConsentPlan(): void {
        this.viewPreConsentClicked.emit(this.mappedItem);
    }
}
