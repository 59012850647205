import { Injectable, Injector } from '@angular/core';
import { IDocumentTypeDto, SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPromised, toPromisedArray } from 'cb-hub-lib';
import { Observable, map } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { DocumentTypeMappedItem } from './document-type.mapped';
import { IDocumentTypeMappedItem } from './interfaces/i.document-type.mapped';
import { IDocumentTypesLogicService } from './interfaces/i.document-types.logic.service';

@Injectable()
export class DocumentTypesLogicService
    extends BaseLogicService<IDocumentTypeDto, IDocumentTypeMappedItem>
    implements IDocumentTypesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('documenttypes', DocumentTypeMappedItem);
    }

    public getByCode(code: string): Observable<IDocumentTypeDto> {
        return this.$http
            .get(`${this.$baseUri}/bycode`, { code });
    }

    public getDocumentTypesForTrades(trades: number[]): Observable<IDocumentTypeDto[]> {
        return this.$http
            .post<IDocumentTypeDto[]>(`${this.$baseUri}/fortrades`, trades);
    }

    public getDocumentTypesForSupplyTypes(trades: number[]): Observable<IDocumentTypeDto[]> {
        return this.$http
            .post<IDocumentTypeDto[]>(`${this.$baseUri}/forsupplytypes`, trades);
    }


    public loadDocTypesLookupForTrades(tradeTypesIds: number[]): IPromised<IDocumentTypeDto[]> {
        const noneOption = { id: 0, label: 'None', name: 'None' } as unknown as IDocumentTypeDto;
        if (!tradeTypesIds?.length) {
            return toPromisedArray(new Observable((sub) => sub.next([noneOption])));
        }

        return toPromisedArray(
            this.getDocumentTypesForTrades(tradeTypesIds)
                .pipe(
                    map(value => {
                        value.unshift(noneOption);
                        return value;
                    })
                )
        );
    }

    public loadDocTypesLookupForSupplyTypes(supplyTypesIds: number[]): IPromised<IDocumentTypeDto[]> {
        const noneOption = { id: 0, label: 'None', name: 'None' } as unknown as IDocumentTypeDto;
        if (!supplyTypesIds?.length) {
            return toPromisedArray(new Observable((sub) => sub.next([noneOption])));
        }

        return toPromisedArray(
            this.getDocumentTypesForSupplyTypes(supplyTypesIds)
                .pipe(
                    map(value => {
                        value.unshift(noneOption);
                        return value;
                    })
                )
        );
    }

    public getUnusedDocumenTypesForEntity(systemArea: SystemAreaEnumId, entityId: number, documentGroupCode: string): Observable<IDocumentTypeDto[]> {

        const payload = { documentGroupCode, entityId, systemArea };

        return this.$http
            .get<IDocumentTypeDto[]>(`${this.$baseUri}/unused`, payload);
    }
}
