import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplyClassIfHasContentDirective } from './apply-class-if-has-content.directive';



@NgModule({
    declarations: [
        ApplyClassIfHasContentDirective
    ],
    exports: [
        ApplyClassIfHasContentDirective
    ],
    imports: [
        CommonModule
    ]
})
export class ApplyClassIfHasContentModule { }
