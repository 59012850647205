import {
    BasePermissions,
    IBaseSystemAreaPermissions,
    IDocumentPermissions,
    IHaveManualPurchaseOrdersPermissions,
    IhaveNotePermissions,
    IManualPurchaseOrderPermissions,
    INotePermissions
} from './base.permissions';
import {LotDesignPermissionEnum} from '@app/shared/enums/permissions';
import {CurrentUserService} from '../authentication/current.user';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';


export interface ILotDesignPermissions extends IBaseSystemAreaPermissions, IhaveNotePermissions, IHaveManualPurchaseOrdersPermissions {
    canRequestScheme(): boolean;
    canCreateScheme(): boolean;
    canEditScheme(): boolean;
    canChangeStatus(): boolean;
    canEditSchemeSpecs(): boolean;
    canRequestSchemePricing(): boolean;
    canSetConceptSellPrice(): boolean;
    canSetConceptExpiryDate(): boolean;
    canApplyConcept(): boolean;
    canRequestImages(): boolean;
    canAbandonConcept(): boolean;
    canGeneratePreConsent(): boolean;
    canManagePreconsentPlan(): boolean;
    canManagePreconsentStubs(): boolean;
    getPreconsentDocumentPermissions(): IDocumentPermissions;
    canRequestWorkingDrawing(): boolean;
    canCreateWorkingDrawing(): boolean;
    canEditWorkingDrawing(): boolean;
    canCreateConsent(): boolean;
    canEditConsent(): boolean;
    canDeleteConsent(): boolean;
    canEditConsentNumber(): boolean;
    getConsentDocumentPermissions(): IDocumentPermissions;
    canCreateRFI(): boolean;
    canEditRFI(): boolean;
    canChangeRFIStatus(): boolean;
    canDeleteRFI(): boolean;
    canManageImages(): boolean;
    canUpdatePreConsentStatus(): boolean;
    canAddPreConsentStubs(): boolean;
    canSetLotAvailableForSale(): boolean;
    canEditSchemeAmentiesAfterCompleted(): boolean;
}


@Injectable()
export class LotDesignPermissions extends BasePermissions<LotDesignPermissionEnum> implements ILotDesignPermissions {
    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('lotdesign', LotDesignPermissionEnum, currentUser, http, router);
    }

    public canChangeStatus(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanChangeStatus);
    }

    public canRequestImages(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanRequestImages);
    }

    public canRequestScheme(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanRequestScheme);
    }

    public canCreateScheme(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanCreateScheme);
    }

    public canEditScheme(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanEditScheme);
    }

    public canEditSchemeSpecs(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanEditSchemeSpecs);
    }

    public canRequestSchemePricing(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanRequestSchemePricing);
    }

    public canSetConceptSellPrice(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanSetConceptSellPrice);
    }

    public canSetConceptExpiryDate(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanSetConceptExpiryDate);
    }

    public canApplyConcept(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanApplyConcept);
    }

    public canManageImages(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanManageImages);
    }

    public canAbandonConcept(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanAbandonConcept);
    }

    public canGeneratePreConsent(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanGeneratePreconsent);
    }

    public canManagePreconsentPlan(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanManagePreconsentPlan);
    }

    public canManagePreconsentStubs(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanManagePreconsentStubs);
    }

    public canUpdatePreConsentStatus(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanUpdatePreconsentStatus);
    }

    public canAddPreConsentStubs(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanAddPreconsentStubs);
    }

    public canSetLotAvailableForSale(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanSetLotAvailableForSale);
    }

    public canEditSchemeAmentiesAfterCompleted(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanEditSchemeAmentiesAfterCompleted);
    }

    public canEditPreConsent(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanEditPreconsent);
    }

    public getPreconsentDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => this.permissionObject(LotDesignPermissionEnum.CanDeletePreconsentDocuments),
            canEditDocuments: () => this.permissionObject(LotDesignPermissionEnum.CanEditPreconsentDocuments),
            canReviewDocuments: () => this.permissionObject(LotDesignPermissionEnum.CanReviewPreconsentDocuments),
            canUploadDocument: () => this.permissionObject(LotDesignPermissionEnum.CanUploadPreconsentDocuments),
            canViewDocuments: () => this.permissionObject(LotDesignPermissionEnum.CanViewPreconsentDocuments)
        };
    }

    public getConsentDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => this.permissionObject(LotDesignPermissionEnum.CanDeleteConsentDocuments),
            canEditDocuments: () => this.permissionObject(LotDesignPermissionEnum.CanEditConsentDocuments),
            canReviewDocuments: () => this.permissionObject(LotDesignPermissionEnum.CanReviewConsentDocuments),
            canUploadDocument: () => this.permissionObject(LotDesignPermissionEnum.CanUploadConsentDocuments),
            canViewDocuments: () => this.permissionObject(LotDesignPermissionEnum.CanViewConsentDocuments)
        };
    }

    public getWorkingDrawingDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => true,
            canEditDocuments: () => true,
            canReviewDocuments: () => true,
            canUploadDocument: () => true,
            canViewDocuments: () => true,
        };
    }

    public canRequestWorkingDrawing(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanRequestWorkingDrawing);
    }

    public canCreateWorkingDrawing(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanCreateWorkingDrawing);
    }

    public canEditWorkingDrawing(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanEditWorkingDrawing);
    }

    public canCreateConsent(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanCreateConsent);
    }

    public canEditConsent(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanEditConsent);
    }

    public canDeleteConsent(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanDeleteConsent);
    }

    public canEditConsentNumber(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanEditConsentNumber);
    }

    public canCreateRFI(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanCreateRFI);
    }

    public canEditRFI(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanEditRFI);
    }

    public canChangeRFIStatus(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanChangeRFIStatus);
    }

    public canDeleteRFI(): boolean {
        return this.permissionObject(LotDesignPermissionEnum.CanDeleteRFI);
    }

    public getManualPurchaseOrderPermissions(): IManualPurchaseOrderPermissions {
        return {
            /**
             * Not used for Lot Design - Approval is not available for Manual Orders created via Lot Design Area
             */
            canApproveManualOrder: () => false,
            canManageManualOrder: () => this.permissionObject(LotDesignPermissionEnum.CanManageManualOrder),
            canSubmitManualOrder: () => this.permissionObject(LotDesignPermissionEnum.CanSubmitManualOrder)
        };
    }

    public getNotePermissions(): INotePermissions {
        throw new Error('Method not implemented');
    }

    public canView(): boolean {
        throw new Error('Method not implemented');
    }

    public canEdit(): boolean {
        throw new Error('Method not implemented');
    }

    public canCreate(): boolean {
        throw new Error('Method not implemented');
    }
}
