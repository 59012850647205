import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotSpecItemLogicService, ILotSpecItemMappedItem, LotSpecItemLogicService } from '@app/logic/lot-spec-item';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { ProductLogicService } from '@app/logic/products';
import { ISpecGroupDto, SpecGroupsLogicService } from '@app/logic/spec-groups';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { CheckboxComponent } from '@app/shared/components/forms/checkbox/checkbox.component';
import { FormMode } from '@app/shared/enums/form';
import {
    COST_TYPE_ENUM,
    IIdAndLabelDto,
    ILotSpecItemDto,
    IOfferingCategoryAttributeOptionDto,
    IProductOfferingDto,
    ISkinnyProductOfferingDto,
    LOT_SPEC_COST_TYPE_ENUM,
    UNIT_OF_MEASURE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { find, map, orderBy } from 'lodash';

@Component({
    templateUrl: './edit-lot-spec-item-dialog.component.html',
    styleUrls: ['./edit-lot-spec-item-dialog.component.scss']
})
export class EditLotSpecItemDialogComponent
    extends BaseDialogFormViewDirective<ILotSpecItemDto, ILotSpecItemMappedItem, ILotSpecItemLogicService> {
    public static readonly MIN_WIDTH = '40%';

    public houseAreas: ISpecGroupDto[];
    public lotSpecCostTypes = LOT_SPEC_COST_TYPE_ENUM.toSelectList();
    public selectedProduct: IProductOfferingDto | string;
    public productSearchText: string;
    public skinnyProduct: ISkinnyProductOfferingDto;
    public productUom: string;
    public suppliers: IIdAndLabelDto[];
    public uploadingImage = false;
    public readonly uom = UNIT_OF_MEASURE_ENUM.toLookup();
    public readonly costTypes = COST_TYPE_ENUM.toLookup();
    public productOptions: { [attrId: number]: number } = {};
    public removeOwnersCareCostType: boolean;

    @ViewChild('includeInColourSchedule') public showInColourYourDreamsComponent: CheckboxComponent;

    private readonly DEFAULT_UOM = 'Each';

    private tempImage: string;
    public get specImageUrl(): string {
        return !this.mappedItem?.removeImageOnSave && (this.tempImage ?? this.mappedItem.specItemImage);
    }

    constructor(
        public readonly dialogRef: MatDialogRef<EditLotSpecItemDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: ILotSpecItemMappedItem;
            lotSpec: ILotSpecMappedItem;
            removeOwnersCareCostType: boolean | null;
            locked: boolean;
            lockedSpecGroupId: number;
        },
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(LotSpecItemLogicService) public readonly lotSpecItemLogic: LotSpecItemLogicService,
        @Inject(ProductLogicService) public readonly productLogic: ProductLogicService,
        @Inject(SpecGroupsLogicService) public readonly specGroupLogic: SpecGroupsLogicService,
    ) {
        super(dialogRef, toastService, cbDialog);

        this.removeOwnersCareCostType = data.removeOwnersCareCostType ?? true;

        this.formMode = FormMode.Edit;
        this.mappedItem = data.mappedItem;

        if (!this.mappedItem) {
            this.formMode = FormMode.Add;
            this.mappedItem = this.lotSpecItemLogic.$createMappedItem({
                lotId: data.lotSpec.lotId,
                versionNumber: data.lotSpec.specVersion,
                specGroupId: data.lockedSpecGroupId,
            });
        } else {
            this.mappedItem.$reload().subOnce(() => {
                // if item is quoted should not be able to change cost type
                if (this.isQuoted()) {
                    this.setLotSpecCostTypes();
                }

                this.productSearchText = this.mappedItem.productDisplay ?? this.mappedItem.productOther;
                if (this.mappedItem.productId && this.mappedItem.productId > 0) {
                    this.selectedProduct = {
                        id: this.mappedItem.productId,
                        name: this.mappedItem.productDisplay
                    } as IProductOfferingDto;
                    this.loadProduct();
                } else {
                    this.selectedProduct = { name: this.productSearchText } as IProductOfferingDto;
                }
            });
        }

        this.specGroupLogic.$getList().subOnce((results) => {
            this.houseAreas = orderBy(results, 'sortOrder', 'asc');
        });

        this.setLotSpecCostTypes();
    }

    public saveItem(form: NgForm): void {
        if (this.saveDisabled(form)) {
            return;
        }
        const block = this.cbDialog.block('Saving Item...');
        this.mappedItem.offeringOptions = map(this.productOptions, (value) => { return { id: value } as IOfferingCategoryAttributeOptionDto; });
        if (!this.selectedProduct) {
            this.mappedItem.productOther = this.productSearchText;
        }
        else if (typeof (this.selectedProduct) === 'string') {
            this.mappedItem.productOther = this.selectedProduct;
        }
        this.mappedItem
            .$save(this.data?.lotSpec?.specVersion)
            .subOnce({
                next: result => {
                    this.dialogRef.close(result);
                    block.close();
                },
                error: () => {
                    block.close();
                }
            });
    }

    public clickUpload(e: MouseEvent): void {
        e.stopPropagation();
        e.preventDefault();
    }

    public dialogHeading(): string {
        return this.mappedItem?.id > 0 ? 'Edit Lot Spec Item' : 'Add Lot Spec Item';
    }

    public uploadFile(form: NgForm, uploadedFile: File): void {
        this.mappedItem.specItemImageFile = uploadedFile[0];

        const reader = new FileReader();
        reader.addEventListener(
            'load', () => {
                this.tempImage = reader.result as string;
                this.mappedItem.removeImageOnSave = false;
                form.form.markAsDirty();
            },
            false
        );

        if (this.mappedItem.specItemImageFile) {
            reader.readAsDataURL(this.mappedItem.specItemImageFile);
        }
    }

    public setLotSpecCostTypes(): void {
        if (this.removeOwnersCareCostType) {
            this.lotSpecCostTypes = this.lotSpecCostTypes.filter(ct => ct.id !== LOT_SPEC_COST_TYPE_ENUM.OwnersCare);
        }
    }

    public isQuoted(): boolean {
        return this.mappedItem.costType === COST_TYPE_ENUM.Quote;
    }

    public isActual(): boolean {
        return this.mappedItem.costType === COST_TYPE_ENUM.Actual;
    }

    public isDescriptiveOnly(): boolean {
        return this.mappedItem.costType === COST_TYPE_ENUM.DescriptiveOnly;
    }

    public isProvisional(): boolean {
        return this.mappedItem.costType === COST_TYPE_ENUM.Provisional;
    }

    public isEstimated(): boolean {
        return this.mappedItem.costType === COST_TYPE_ENUM.Estimate;
    }

    public isNoCharge(): boolean {
        return this.mappedItem.costType === COST_TYPE_ENUM.NoCharge;
    }

    public isOwnersCare(): boolean {
        return this.mappedItem.costType === COST_TYPE_ENUM.OwnersCare;
    }

    public canSelectProduct(): boolean {
        return !this.isDescriptiveOnly();
    }

    public displayProductSearch(): boolean {
        return this.canSelectProduct() && !this.data.lotSpec.isLocked;
    }

    public clearProductForCostType(): void {
        this.clearProductForDescriptive();
        if (this.isActual() && !this.isProductSelected()) {
            this.selectedProduct = null;
            this.mappedItem.quantity = null;
            this.productUpdate(null);
            this.productSearchTextChanged(null);
        }
    }

    private isProductSelected(): boolean {
        return (this.selectedProduct as IProductOfferingDto)?.id > 0;
    }

    public clearProductForDescriptive(): void {
        if (this.isDescriptiveOnly()) {
            this.selectedProduct = null;
            this.productUpdate();
        }
    }

    public productSearchTextChanged(str: string): void {
        this.productSearchText = str;
        this.skinnyProduct = null;
        this.mappedItem.productId = null;
        this.productUom = null;
    }

    public productUpdate = (data?: IProductOfferingDto | null): void => {
        if (data != null) {
            this.selectedProduct = data;
            this.productSearchText = data.name;
            if (this.selectedProduct && this.mappedItem.productId !== this.selectedProduct.id) {
                this.mappedItem.productOther = null;
                this.loadProduct();
            }
        } else if (!this.selectedProduct) {
            this.skinnyProduct = null;
            this.mappedItem.productId = null;
            if (!this.isDescriptiveOnly()) {
                this.productSearchText = null;
            }
        }
    };

    public loadProduct = (): void => {
        if (!this.selectedProduct) {
            return;
        }

        this.mappedItem.productId = (this.selectedProduct as IProductOfferingDto)?.id;
        if (!this.mappedItem.supplierId) {
            this.mappedItem.supplierId = null;
        }

        this.productLogic
            .getSkinnyItem((this.selectedProduct as IProductOfferingDto)?.id)
            .subOnce((result) => {
                this.skinnyProduct = result;

                this.suppliers = orderBy(this.skinnyProduct.suppliers.map((supplier) => ({ id: supplier.id, label: supplier.label })), 'label', 'asc');
                this.suppliers.unshift({ id: null, label: 'Not Specified' });

                this.mappedItem.offeringOptions?.forEach(o => {
                    this.productOptions[o.categoryId] = o.id;
                });

                const productUom = find(this.uom, { id: this.skinnyProduct.uom });

                this.productUom = (productUom) ? productUom.label : this.DEFAULT_UOM;
            });
    };

    public manualColourEntryRequiredChanged(value: boolean): void {
        if (value === true) {
            this.mappedItem.showInColourYourDreams = true;

            // There was a weird bug where baseFormComponent.writeValue was occasionally
            // not being called after the above line should have triggered it - so calling it manually.
            this.showInColourYourDreamsComponent.writeValue(true);
        }
    }

    public shouldShowQuoteRequired(): boolean {
        const shouldShow =
            this.mappedItem.costType === COST_TYPE_ENUM.Provisional
            || this.mappedItem.costType === COST_TYPE_ENUM.Quote
            || this.mappedItem.costType === COST_TYPE_ENUM.Estimate
            || this.mappedItem.costType === COST_TYPE_ENUM.NoCharge;

        if (!shouldShow) {
            this.mappedItem.quoteRequired = null;
        }

        return shouldShow;
    }

    public getProductPath(): string {
        return this.skinnyProduct?.categoryPath?.map(c => c.label)?.join(' > ') ?? '';
    }

    public getRateLabel(): string {
        const costType = find(this.costTypes, { id: this.mappedItem.costType });
        if (costType) {
            return costType.label + ' Amount';
        }
        return 'Amount';
    }

    public displayQuantity(): boolean {
        return !(this.isProvisional() || this.isQuoted() || this.isEstimated());
    }

    public showRate(): boolean {
        return !(this.isActual() || this.isNoCharge() || this.isOwnersCare());
    }

    public isRateRequired(): boolean {
        return !(this.isActual() || this.isNoCharge() || this.isOwnersCare());
    }

    public canEditSupplier(): boolean {
        return this.isEditOrAdd() && !this.mappedItem.hasQuoteLines;
    }

    public displaySupplier(): boolean {
        return this.canSelectProduct()
            && !this.isOwnersCare()
            && !this.isProvisional()
            && !this.isQuoted();
    }

    public removeImage(lotSpecItemForm: NgForm): void {
        this.mappedItem.removeImageOnSave = true;
        this.mappedItem.specItemImage = null;
        lotSpecItemForm.form.markAsDirty();
    }

    public saveDisabled(lotSpecItemForm: NgForm): boolean {
        return lotSpecItemForm.invalid
            || lotSpecItemForm.pristine
            || (
                this.isActual() && !this.isProductSelected()
            );
    }
}
