<ng-container *ngIf="isChangeRecordsLoaded">
    <cb-lot-changes-action-bar *cbWaitFor="changeRecordMappedItems && lotMappedItem && teams"
                               [lotMappedItem]="lotMappedItem"
                               [changeRecordMappedItems]="changeRecordMappedItems"
                               [teams]="teams"
                               (changeRecordCreated)="changeRecordCreated($event)"
                               (changesSubmittedForPricing)="changesUpdated($event)">
    </cb-lot-changes-action-bar>
    <div class="cb-margin flex-row"
         style="gap: 16px">
        <cb-lot-changes-list class="flex-row"
                             *cbWaitFor="changeRecordMappedItems && lotMappedItem"
                             [changeRecordMappedItems]="changeRecordMappedItems"
                             [lotId]="lotMappedItem.id"
                             (changeRecordSelected)="changeRecordSelected($event)"></cb-lot-changes-list>
        <cb-lot-change-view class="flex flex-row"
                            *ngIf="selectedChangeRecord"
                            [changeRecordMappedItem]="selectedChangeRecord"
                            [lotMappedItem]="lotMappedItem"
                            [teams]="teams"></cb-lot-change-view>
        <cb-info-message *ngIf="!selectedChangeRecord"
                         [message]="'Select a Change to view the Change Details'"></cb-info-message>
    </div>
</ng-container>
<cb-loading-spinner *ngIf="!isChangeRecordsLoaded"></cb-loading-spinner>
