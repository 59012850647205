import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IWipService } from '@app/logic/wip/IWipService';

export interface WipDocument {
    consentApprovedDate: string;
    consentDueOutDate: string;
    unconditionalDate: string;
    constructionManager: string;
    jobNumber: string;
    jobStatus: string;
    lotNumber: number;
    projectReleaseStage: string;
    projectName: string;
    businessEntity: string;
    address: string;
    contractType: string;
    buildType: string;
    client: string;
    buildingConsultant: string;
    titleDate?: string;
    titleStatus: string;
    unconditional: boolean;
    consentLodgedDate: string;
    id: string;
}

@Injectable({
    providedIn: 'root'
})
export class WipService implements IWipService{
    private baseUrl = 'wip';

    constructor(
        protected readonly $http: HttpWrapperService) { }

    public getAllWipDocuments(): Observable<WipDocument[]> {
        return this.$http.get<ISearchResult<WipDocument>>(this.baseUrl +'/search/getAll' ).pipe(
            map(result => result.items)
        );
    }

    public refreshDocuments(): Observable<boolean> {
        return this.$http.get<boolean>(this.baseUrl + '/refresh');
    }

    public loadMoreWipDocuments(currentPage: number): Observable<WipDocument[]> {
        return this.$http.get<ISearchResult<WipDocument>>(this.baseUrl + '/search/get', { currentPage }).pipe(
            map(result => result.items)
        );
    }
}
