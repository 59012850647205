import * as _ from 'lodash';

import {HttpWrapperService} from '../services/http-wrapper/http-wrapper.service';
import {Injectable} from '@angular/core';
import {TEAM_CODES_CONST, USER_TAG_CONSTANTS_CONST} from '@classictechsolutions/hubapi-transpiled-enums';

export interface ICurrentUser {
    claims: { [key: string]: number };
    profile: IUserProfile;
    userName: string;
    tags: string[];
    region: string;
    guid: string;
    primaryregion: string;
    primaryregionId: number;
    regionsids: number[];
    teams: string[];
    isAdmin: boolean;
    /** resolves when claim has loaded */
    $promise: Promise<void>;
    $resolved: boolean;

    hasTag(tag: string): boolean;
    isDesignTeam(): boolean;
    isQSTeam(): boolean;
    isSalesTeam(): boolean;
    isARTeam(): boolean;
    isClassicCareTeam(): boolean;

    setAADInfo(authData: any): void;
    setAPIInfo(tokenParts: any): void;

    // ADDING STRINGLY TYPED TAG CHECKS
    hasBranchBusinessAccountRequestApprover(): boolean;
    hasBranchBusinessAccountRequester(): boolean;
    hasBuildingConsultant(): boolean;
    hasBusinessAccountOverrideApprover(): boolean;
    hasBusinessAccountRequestApprovalOverride(): boolean;
    hasContractManager(): boolean;
    hasHealthAndSafetyBusinessAccountApprover(): boolean;
    hasHouseAndLandCoordinator(): boolean;
    hasProcurementBusinessAccountApprover(): boolean;
    hasSevenDayPaymentsApprover(): boolean;
}

interface ITokenParts {
    tags: string[];
    teams: string[];
    region: string;
    useridentifier: string;
    primaryregion: string;
    primaryregionid: number;
    regionsids: string | number | null | string[];
    isadmin: 'True' | 'False';
}

interface IUserProfile {
    aio: string;
    amr: string[];
    aud: string;
    exp: number;
    /** e.g. "Bloggs" */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    family_name: string;
    /** e.g. "Joe" */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    given_name: string;
    iat: number;
    ipaddr: string;
    iss: string;
    /** given_name + family_name e.g. "Joe Bloggs" */
    name: string;
    nbf: number;
    nonce: string;
    oid: string;
    sub: string;
    tid: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unique_name: string;
    upn: string;
    uti: string;
    ver: '1.0';
}

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService implements ICurrentUser {
    public claims = {} as { [key: string]: number };
    public profile = {} as IUserProfile;
    public userName = '';
    public tags = [] as string[];
    public teams = [] as string[];
    public region = '';
    public guid = '';
    public isAdmin: boolean;
    public readonly baseUri = 'usersetting';
    public primaryregion = '';
    public primaryregionId = 0;
    public regionsids: number[] = [];

    public $resolved = false;
    public $promise = new Promise<void>((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
    });
    private resolvePromise;
    private rejectPromise;

    private readonly claimPart = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision';

    constructor(
        protected readonly $http: HttpWrapperService,
    ) {
        this.$promise
            .then(this.setResolved)
            .catch(this.setResolved);
    }

    public setAADInfo(authData: { userName: string; profile: {} }): void {
        this.userName = authData.userName;
        this.profile = authData.profile as IUserProfile;
    }

    public setAPIInfo(tokenParts: ITokenParts): void {
        try {
            const claimArray = tokenParts[this.claimPart];
            this.claims = this.processClaimArray(claimArray);

            if (_.isArray(tokenParts.tags)) {
                this.tags = tokenParts.tags;
            } else {
                this.tags = [tokenParts.tags] as any;
            }

            if (_.isArray(tokenParts.teams)) {
                this.teams = tokenParts.teams;
            } else {
                this.teams = [tokenParts.teams] as any;
            }

            this.region = tokenParts.region;
            this.guid = tokenParts.useridentifier;
            this.primaryregion = tokenParts.primaryregion;
            this.primaryregionId = tokenParts.primaryregionid;
            this.regionsids = this.mapRegionIds(tokenParts.regionsids);
            this.isAdmin = this.tags.includes(USER_TAG_CONSTANTS_CONST.SYSTEMADMIN);

            this.resolvePromise();
        } catch (error) {
            this.rejectPromise(error);
        }
    }

    public processClaimArray(claimArray: string[]): { [key: string]: number } {
        return _.reduce(
            claimArray,
            (claimObj: any, claimStr: string) => {
                const claimParts = claimStr.split(':');
                claimObj[claimParts[0]] = claimParts[1];
                return claimObj;
            },
            {}
        );
    }

    public hasTag(tag: string): boolean {
        return _.includes(this.tags, tag);
    }

    public isDesignTeam(): boolean {
        return this.hasTeam(TEAM_CODES_CONST.DesignTeamKey);
    }

    public isQSTeam(): boolean {
        return this.hasTeam(TEAM_CODES_CONST.QsTeamKey);
    }

    public isSalesTeam(): boolean {
        return this.hasTeam(TEAM_CODES_CONST.SalesTeamKey);
    }

    public isARTeam(): boolean {
        return this.hasTeam(TEAM_CODES_CONST.AccountReceivableKey);
    }

    public isClassicCareTeam(): boolean {
        return this.hasTeam(TEAM_CODES_CONST.ClassicCareTeamKey);
    }

    // ADDING STRINGLY TYPED TAG CHECKS
    public hasBranchBusinessAccountRequestApprover(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.BRANCH_BUSINESS_ACCOUNT_REQUEST_APPROVER);
    }

    public hasBranchBusinessAccountRequester(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.BRANCH_BUSINESS_ACCOUNT_REQUESTER);
    }

    public hasBuildingConsultant(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT);
    }

    public hasComplianceDocumentReview(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.COMPLIANCE_DOCUMENT_REVIEWER);
    }

    public hasBusinessAccountOverrideApprover(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.BUSINESS_ACCOUNT_OVERRIDE_APPROVER);
    }

    public hasBusinessAccountRequestApprovalOverride(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.BUSINESS_ACCOUNT_REQUEST_APPROVAL_OVERRIDE);
    }

    public hasContractManager(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.CONTRACT_MANAGER);
    }

    public hasHealthAndSafetyBusinessAccountApprover(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.HEALTH_AND_SAFETY_BUSINESS_ACCOUNT_APPROVER);
    }

    public hasHouseAndLandCoordinator(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.HOUSE_AND_LAND_COORDINATOR);
    }

    public hasProcurementBusinessAccountApprover(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.PROCUREMENT_BUSINESS_ACCOUNT_APPROVER);
    }

    public hasSevenDayPaymentsApprover(): boolean {
        return this.hasTag(USER_TAG_CONSTANTS_CONST.SEVEN_DAY_PAYMENTS_APPROVER);
    }

    private hasTeam(team: string): boolean {
        return _.includes(this.teams, team);
    }

    private readonly setResolved = (): void => {
        this.$resolved = true;
    };

    private mapRegionIds(regionids: string | number | null | string[]): number[] {
        if (regionids == null) {
            return [];
        }
        if (typeof (regionids) === 'string' || typeof (regionids) === 'number') {
            return [Number(regionids)];
        }
        return regionids.map(Number);
    }
}
