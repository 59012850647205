import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { DesignTeamPermissions, LotDesignPermissions } from '@app/core/permissions';
import { IPreConsentPlanMappedItem, PreConsentPlansLogicService } from '@app/logic/pre-consent-plans';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import {
    PreconsentPlanStatusEnumId,
    PRECONSENT_PLAN_STATUS_ENUM,
    PRE_CONSENT_PLAN_ITEM_STATUS_ENUM,
    DOCUMENT_TYPE_CODES_CONST,
    DOCUMENT_STATUS_ENUM,
    DOCUMENT_GROUP_CODES_CONST,
    DOCUMENT_REQUIRED_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { ManagePreConsentDialogComponent } from '../manage-pre-consent-dialog/manage-pre-consent-dialog.component';
import { RejectPreconsentDialogComponent } from '../reject-preconsent-dialog/reject-preconsent-dialog.component';
import { ToastService } from '@app/core/services/toast/toast.service';
import {FinalisePreconsentPlanDialogComponent} from '@app/views/lot/design/pre-consent/finalise-preconsent-plan-dialog/finalise-preconsent-plan-dialog.component';
import {ILotMappedItem} from '@app/logic/lots';
import {DocumentsLogicService, IDocumentEntityMappedItem} from '@app/logic/documents';

@Component({
    selector: 'cb-pre-consent-action-bar',
    templateUrl: './pre-consent-action-bar.component.html',
    styleUrls: ['./pre-consent-action-bar.component.scss']
})
export class PreConsentActionBarComponent implements OnInit{
    @Input() public mappedItem: IPreConsentPlanMappedItem;
    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public mappedItemChange = new EventEmitter<IPreConsentPlanMappedItem>();
    @Output() public readonly goBack = new EventEmitter<void>();
    @Input() public documents: IDocumentEntityMappedItem[];
    public PRECONSENT_PLAN_STATUS_ENUM = PRECONSENT_PLAN_STATUS_ENUM;
    public isResubmitDisabled = false;

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly preConsentPlansLogicService: PreConsentPlansLogicService,
        private readonly designTeamPermissions: DesignTeamPermissions,
        private readonly lotDesignPermissions: LotDesignPermissions,
        private readonly currentUserService: CurrentUserService,
        private readonly documentsLogicService: DocumentsLogicService,
        private readonly toastService: ToastService
    ) { }

    public ngOnInit(): void {
        this.loadDocuments();
    }

    public loadDocuments(): void {
        this.documentsLogicService
            .getMappedDocumentEntities(this.mappedItem.documentEntityUri, this.mappedItem.documentEntityId, false, false)
            .subOnce((docs) => {
                this.documents = docs;
                this.cdRef.detectChanges();
            });
    }

    public backToPreConsent(): void {
        this.goBack.emit();
    }

    public isNoLongerActive(): boolean {
        return this.mappedItem
            && this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Finalised ||
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.WaitingForReview ||
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.DesignReviewRequired ||
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Completed ||
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Cancelled;
    }

    public setStatus(status: PreconsentPlanStatusEnumId): void {

        this.mappedItem.preConsentPlanStatus = {
            id: status,
            label: undefined
        };

        this.mappedItem.$save().subOnce(() => {
            this.mappedItemChange.emit(this.mappedItem);
            this.cdRef.detectChanges();
        });
    }

    public managePreConsentPlan(): void {
        this.cbDialog.open(ManagePreConsentDialogComponent, {
            data: {
                preConsentPlanMappedItem: this.mappedItem.$clone()
            }
        }).afterClosed().subOnce(result => {
            if (result) {
                if (this.mappedItem.designReviewRequired === false) {
                    this.mappedItem.reviewerId = undefined;
                    this.mappedItem.reviewerName = undefined;
                }
                this.mappedItemChange.emit(this.mappedItem);
                this.cdRef.detectChanges();
            }
        });
    }

    public cancelPreConsentPlan(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Cancel Pre-consent Plan',
            message: 'Are you sure you want to Cancel this Pre-consent Plan?',
            confirmed: () => {
                this.mappedItem.preConsentPlanStatus = {
                    id: PRECONSENT_PLAN_STATUS_ENUM.Cancelled,
                    label: undefined
                };

                this.mappedItem.$save().subOnce(() => {
                    this.mappedItem?.$reload().subOnce();
                    this.mappedItemChange.emit(this.mappedItem);
                    this.cdRef.detectChanges();
                });
            }
        });
    }

    public finalisePreConsentPlan(): void {
        this.preConsentPlansLogicService.$getMappedItem(this.mappedItem.id).subOnce((_mappedItem) => {
            this.cbDialog.open(FinalisePreconsentPlanDialogComponent, {
                data: {
                    mappedItem: _mappedItem,
                    jobNumber: this.lotMappedItem.jobNumber
                }
            }).afterClosed().subOnce(result => {
                if (result) {
                    this.preConsentPlansLogicService.finalise(this.mappedItem.id).subOnce(() => {
                        this.mappedItem.$reload().subOnce(() => {
                            this.mappedItemChange.emit(this.mappedItem);
                            this.cdRef.detectChanges();
                        });
                    });
                }
            });
        });
    }

    public rejectPreConsentPlan(): void {
        this.preConsentPlansLogicService.$getMappedItem(this.mappedItem.id).subOnce((_mappedItem) => {
            this.cbDialog.open(RejectPreconsentDialogComponent, {
                data: {
                    preConsentPlanMappedItem: _mappedItem
                }
            }).afterClosed().subOnce(result => {
                if (result) {
                    this.mappedItem.$reload().subOnce(() => {
                        this.mappedItemChange.emit(this.mappedItem);
                        this.cdRef.detectChanges();
                    });
                }
            });
        });
    }

    public review(): void {
        this.cbDialog
            .confirm({
                dialogHeading: 'Review Pre-Consent',
                message: 'Allow Pre-Consent to proceed for acceptance',
                yesLabel: 'Proceed Pre-Consent',
                noLabel: 'Reject Pre-Consent',
                disableDeclineEventOnCloseIcon: true,
                confirmed: () => {
                    this.saveStatus(PRECONSENT_PLAN_STATUS_ENUM.WaitingForReview);
                },
                declined: () => {
                    this.rejectPreConsentReview();
                }
            });
    }

    private rejectPreConsentReview(): void {
        this.cbDialog
            .open(RejectPreconsentDialogComponent, {
                data: {
                    preConsentPlanMappedItem: this.mappedItem.$clone()
                }
            }).afterClosed().subOnce((result) => {
                if (result) {
                    this.mappedItem.$reload().subOnce(() => {
                        this.mappedItemChange.emit(this.mappedItem);
                        this.cdRef.detectChanges();
                    });
                }
            });
    }

    private saveStatus(statusId: PreconsentPlanStatusEnumId): void {
        const dialog = this.cbDialog.block('Saving status...');
        this.mappedItem.preConsentPlanStatus.id = statusId;
        this.mappedItem
            .$save()
            .subOnce(() => {
                this.mappedItemChange.emit(this.mappedItem);
                dialog.close();
                this.toastService.showToast('Saved Successfully', 'OK');
            });
    }

    public get isReviewDisabled(): boolean {
        return this.mappedItem.preConsentPlanStatus.id !== this.PRECONSENT_PLAN_STATUS_ENUM.DesignReviewRequired ||
            this.currentUserService?.guid !== this.mappedItem.reviewerId ||
            !this.designTeamPermissions.canReviewPreConsentPlans();
    }

    public get canRejectPreConsentPlans(): boolean {
        return this.designTeamPermissions.canRejectPreConsentPlans();
    }

    public isManageDisabled(): boolean {
        return this.isNoLongerActive() ||
            !this.lotDesignPermissions.canEditPreConsent() ||
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Rejected;
    }

    public resubmit(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Resubmit Pre-Consent',
            message: 'Are you sure you want to resubmit this Pre-Consent?',
            confirmed: () => {
                this.mappedItem.preConsentPlanStatus = {
                    id: PRECONSENT_PLAN_STATUS_ENUM.Pending,
                    label: undefined
                };
                this.isResubmitDisabled = true;

                this.mappedItem.$save().subOnce(() => {
                    this.isResubmitDisabled = false;
                    this.mappedItemChange.emit(this.mappedItem);
                    this.cdRef.detectChanges();
                });
            }
        });
    }

    public get isFinaliseDisabled(): boolean {
        return !this.hasCompletedAllItemsAndChanges() ||

            !(this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.InQueue ||
                this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.OnHold ||
                this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.InProgress ||
                this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.InQueueForRework) ||

            // Plan must have a design complexity in order to finalise - #32209
            !this.mappedItem.designComplexity ||

            // The Pre-Consent plan document must be uploaded
            !this.documents?.some(x =>
                x.documentType
                && x.documentType.codeName === DOCUMENT_TYPE_CODES_CONST.PRE_CONSENT_PLAN && x.document?.isDeleted === false
                && (x.documentStatus === DOCUMENT_STATUS_ENUM.Uploaded || x.documentStatus === DOCUMENT_STATUS_ENUM.Reviewed)
            ) ||

            // All required documents for pre consent plan must not be deleted or empty
            this.documents?.filter(x =>
                x.documentGroup &&
                x.documentRequiredType === DOCUMENT_REQUIRED_TYPE_ENUM.Required &&
                x.documentGroup.codeName === DOCUMENT_GROUP_CODES_CONST.DESIGN_PRE_CONSENT)
                .some(x =>
                    x.document &&
                    x.document.isDeleted ||
                    (x.documentStatus === DOCUMENT_STATUS_ENUM.Empty));
    }

    private hasCompletedAllItemsAndChanges(): boolean {
        return this.mappedItem.items?.every(item => item.statusId === PRE_CONSENT_PLAN_ITEM_STATUS_ENUM.Completed
            || item.statusId === PRE_CONSENT_PLAN_ITEM_STATUS_ENUM.NotDoing);
    }


    public get isRejectDisabled(): boolean {
        return this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Finalised ||
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Cancelled ||
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Rejected;
    }

    public get isInQueueDisabled(): boolean {

        // Disable if current selected status
        return this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.InQueue ||

            // 'Pending' and Unassigned - Only 'Cancel' is enabled
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Pending && this.mappedItem.assignedToUser === null ||

            // Plan must be Assigned in order to change status to 'In Queue', 'In Progress' or 'On Hold'
            this.mappedItem.assignedToUser === null ||

            // Plan must have a design complexity in order to change status to 'In Queue', 'In Progress' or 'On Hold' - #32209
            !this.mappedItem.designComplexity;

    }

    public get isOnHoldDisabled(): boolean {

        // Disable if current selected status
        return this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.OnHold ||

            // 'Pending' and Unassigned - Only 'Cancel' is enabled
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Pending && this.mappedItem.assignedToUser === null ||

            // Plan must be Assigned in order to change status to 'In Queue', 'In Progress' or 'On Hold'
            this.mappedItem.assignedToUser === null ||

            // Plan must have a design complexity in order to change status to 'In Queue', 'In Progress' or 'On Hold' - #32209
            !this.mappedItem.designComplexity;
    }

    public get isInProgressDisabled(): boolean {

        // Disable if current selected status
        return this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.InProgress ||

            // 'Pending' and Unassigned - Only 'Cancel' is enabled
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Pending && this.mappedItem.assignedToUser === null ||

            this.mappedItem.assignedToUser === null ||

            // Plan must have a design complexity in order to change status to 'In Queue', 'In Progress' or 'On Hold' - #32209
            !this.mappedItem.designComplexity;
    }

    public get isCancelDisabled(): boolean {

        return this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Cancelled;
        // Disable if current selected status

    }
}
