import {BaseMappedItem} from '../base/base-mapped-item';
import {DtoProp} from '../base/dto-prop.decorator';
import {ILotProgressPaymentDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {Observable} from 'rxjs';
import {IARProgressPaymentsMappedItem} from '@app/logic/ar-progress-payments/interfaces/i.ar.progress.payments.mapped';
import {IARProgressPaymentsLogicService} from '@app/logic/ar-progress-payments/interfaces/i.ar.progress.payments.logic-service';
import {IARPaymentScheduleDto} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/ARPaymentScheduleDto';

export class ARProgressPaymentsMappedItem
    extends BaseMappedItem<IARPaymentScheduleDto, IARProgressPaymentsMappedItem, IARProgressPaymentsLogicService>
    implements IARProgressPaymentsMappedItem {

    @DtoProp public payments: ILotProgressPaymentDto[];
    @DtoProp public contractPriceWithGst: number;
    @DtoProp public acceptedVarianceTotalWithGst: number;
    @DtoProp public changeReasonDescription: string;

    constructor(
        sourceData: IARPaymentScheduleDto,
        logicService: IARProgressPaymentsLogicService
    ) {
        super(sourceData, logicService, ARProgressPaymentsMappedItem);
    }

    public $save(): Observable<IARPaymentScheduleDto> {
        const mappedDto = this.$getMappedDtoItem();
        const lotId = mappedDto.payments.filter(x => x.lotId !== undefined && x.lotId > 0)[0].lotId;
        return this.$logicService.saveProgressPayments(lotId, mappedDto);
    }
}
