import { ToastService } from '@app/core/services/toast/toast.service';

export class Toast {
    public hideDelay = 5000;
    public id: number;
    public top: number;

    public get fromTop(): number {
        const index = this.toastService.toasts.indexOf(this) || 0;
        return (56 * index) + 8;
    }

    constructor(
        /** The message or template displayed in the body of the toast */
        public content: string,
        private readonly toastService: ToastService,
        /** The label for the toasts action button */
        public buttonLabel?: string,
        /** A number representing the time the toast is displayed for in milliseconds.
         * Use Infinity if you want the toast to persist infinitely or until close via the toasts button
         */
        _hideDelay?: number,
        /** The function that is called when clicking the toasts action button,
         * by default (if undefined) the button will dismiss the notification
         */
        public buttonAction?: Function,
        /** This will ensure that only one toast with this key can exist at once */
        public uniqueKey?: string,
        /** If set to false the ToastService clearAll all method will ignore this toast */
        public canBeCleared?: boolean,
        public templateCtrl?: Object
    ) {
        if (_hideDelay) {
            this.hideDelay = _hideDelay;
        }
        if (this.canBeCleared == null) {
            this.canBeCleared = true;
        }
    }

    /** Call button action and then delete the toast */
    public triggerButtonAction = (): void => {
        // use $timeout service to move this method to the front of the call stack
        setTimeout(
            () => {
                if (this.buttonAction) {
                    this.buttonAction(this);
                }
                this.toastService.deleteToast(this.id);
            },
            0
        );
    };
}
