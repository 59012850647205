import { Injectable, Injector } from '@angular/core';
import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { ISupplyTypeDto } from './interfaces/i.supply-type.dto';
import { ISupplyTypeMappedItem } from './interfaces/i.supply-type.mapped';
import { ISupplyTypesLogicService } from './interfaces/i.supply-types.logic.service';
import { SupplyTypeMappedItem } from './supply-type.mapped';

@Injectable()
export class SupplyTypesLogicService
    extends BaseLogicService<ISupplyTypeDto, ISupplyTypeMappedItem>
    implements ISupplyTypesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'supplytypes',
            SupplyTypeMappedItem,
        );
    }

    public downloadSupplyTypesReport(): Observable<void> {
        return this.$http.download(`${this.$baseUri}/report`);
    }

    public $getList(onlyIncludeActive = true): Observable<Array<ISupplyTypeDto>> {
        return this.$http
            .get<ISupplyTypeDto[]>(`${this.$baseUri}?onlyActive=${onlyIncludeActive}`);
    }
}
