import {CurrentUserService} from '@app/core/authentication/current.user';
import {Debounce} from '@app/shared/decorators/debounce.decorator';
import {cleanObjectDeep, toPromise, USER_CACHE_AREA, USER_CACHE_CATEGORY} from 'cb-hub-lib';
import {cloneDeep} from 'lodash';
import {Observable, Subject} from 'rxjs';
import {HttpWrapperService} from '../http-wrapper/http-wrapper.service';

export interface IUserCacheData {
    data: string;
    id: number;
    key: string;
    userId: string;
}

export class UserCacheItem<CacheItemDef> {

    public id = 0;
    public get dbKey(): string {
        return `user_cache_area_${this.key}`;
    }
    public get currentUserId(): string {
        return this.currentUser.guid;
    }

    private _data = this.newDataProxy();
    /** use this get/set properties (saving and update event will be handled automatically) */
    public get data(): CacheItemDef {
        if (!this.hasLoaded && !this.isLoading) {
            this.load();
        }
        return this._data;
    }
    public set data(val: CacheItemDef) {
        this.handleUpdateData(val);
    }

    private _silentData = this.newDataProxy();
    /** use this to get/set properties silently (without saving or firing update event)
     * - setting a property on this object will automatically update this.data silently
     */
    public get silentData(): CacheItemDef {
        if (!this.hasLoaded && !this.isLoading) {
            this.load();
        }
        return this._silentData;
    }
    public set silentData(val: CacheItemDef) {
        this._silentData = val;
    }

    /** cache of previous values of this.data props
     * - if a this.silentData prop is set, then respective this._previousDataValues \
     * prop will have the value of this.silentData prop before it was changed
     */
    private _previousDataValues = {} as CacheItemDef;


    /** subscribe to this to detect changes to this.data e.g. use to trigger search endpoint */
    public readonly updated$ = new Subject();
    /** set to true when this.promise has resolved */
    public resolved = false;
    /** used externally by the settings service */
    public created = true;

    /** set to true when this item has loaded */
    private hasLoaded = false;
    /** set to true when this item is loading - ie load() request is in progress */
    private isLoading = false;

    constructor(
        private readonly http: HttpWrapperService,
        private readonly currentUser: CurrentUserService,
        public readonly label: string,
        public key: USER_CACHE_AREA,
        public category: USER_CACHE_CATEGORY,
        private readonly _defaultData: CacheItemDef,
        public readonly canAccess: () => boolean = () => true,
        /** generally, `init()` should be used instead of this `promise`, where it is used in a component init because it also handles basic intialisation for usage */
        public promise: Promise<void> = new Promise((resolve) => {
            this.promiseResolver = resolve;
        }),
        private promiseResolver: (value?: any) => void,
        public readonly master: { promise: Promise<any>; resolved: boolean;[key: number]: boolean }
    ) {
    }

    /** clears data if this item is disabled, load the item if not loaded yet and returns the main `promise` of this item */
    public init(): Promise<void> {
        // return the main `promise` of this item
        return this.currentUser.$promise.then(() => {
            let promise = this.promise;
            if (!this.hasLoaded && !this.isLoading) {
                promise = this.load().then(() => null); // load and return Promise<void>
            }
            return promise.then(() => {
                return this.clearIfDisabled();
            });
        });
    }

    /** get a plain copy of this items data
     * - no getters or setters attached
     * - modifying this object with not have any affect on the original
     */
    public copyData(): CacheItemDef {
        return JSON.parse(JSON.stringify(cloneDeep(this.silentData)));
    }

    /** clears data if this item is disabled  */
    public async clearIfDisabled(): Promise<void> {
        if (!(await this.masterDisabled())) {
            return;
        }
        this.handleUpdateData(null, true);
    }

    /** load this items data from the api */
    public async load(): Promise<CacheItemDef> {
        this.isLoading = true;
        await this.currentUser.$promise.then(async () => {
            if (await this.masterDisabled()) {
                this.update({
                    data: null,
                    id: this.id,
                    key: this.dbKey,
                    userId: this.currentUserId
                });
            } else {
                const res = await toPromise(
                    this.http
                        .get<IUserCacheData>(`/usersetting/getusersetting/${this.currentUserId}/${this.dbKey}`)
                );
                this.update(res as IUserCacheData);
            }
        });
        return this.data;
    }

    /** trigger next on the updated$ subject */
    @Debounce(300)
    public fireUpdated(): void {
        this.updated$.next(null);
    }

    /** save this item via api */
    @Debounce(5000)
    public async save(): Promise<CacheItemDef> {
        if (await this.masterDisabled()) {
            return this.data;
        }
        const res = await this.currentUser.$promise.then(() => toPromise(
            this.http
                .post<IUserCacheData>(`/usersetting/${this.id}`, { id: this.id, key: this.dbKey, userId: this.currentUserId, data: JSON.stringify(this.data) })
        ));
        this.update(res as IUserCacheData);
        return this.data;
    }

    public loadCacheItem(): Observable<IUserCacheData> {
        return this.http.get(`/usersetting/getusersetting/${this.currentUserId}/${this.dbKey}`);
    }

    public saveCacheItem(): Observable<UserCacheItem<CacheItemDef>> {
        return this.http.post(`/usersetting/${this.id}`, { id: this.id, key: this.dbKey, userId: this.currentUserId, data: JSON.stringify(this.data) });
    }

    /** check if this item is disabled via use master settings */
    private async masterDisabled(): Promise<boolean> {
        return this.master.resolved ? this.master[this.key] !== true : this.master.promise.then(() => this.master[this.key] !== true);
    }

    /** update this item with response data */
    private update(res: IUserCacheData): void {
        this.id = res.id;
        this.hasLoaded = true;
        if (res.data) {
            this.handleUpdateData(JSON.parse(res.data), true);
        } else {
            this.handleUpdateData(null, true);
        }
        this.isLoading = false;
        this.promiseResolver.call(this);
        this.resolved = true;
    }

    /** handle updating this.data - this method configures the getters/setters for each data prop
     *
     * @param silent if true - perform the update without saving
     */
    private handleUpdateData(val: CacheItemDef, silent = false): void {
        if (val == null || val instanceof String) {
            val = this._defaultData;
        }
        // create new data object
        const newData = cloneDeep(this._defaultData);
        Object.assign(newData, cleanObjectDeep(cloneDeep(val)));
        // backup old data
        const originalData = JSON.stringify(this._data);
        // create this._data as a fresh object
        this._silentData = this.newDataProxy();
        this._data = this.newDataProxy();
        Object.keys(newData).forEach((key) => {
            this.defineDataProperty(key, newData);
        });
        // handle updates
        if (!silent) {
            this.callIfDiff(originalData, this._data, this.save.bind(this));
            this.callIfDiff(originalData, this._data, this.fireUpdated.bind(this));
        }
    }

    /** @param original json stringified anything
     * @param newItem item to compare - item will be stringified in the comparison
     */
    private readonly callIfDiff = (original: string, newItem: any, callback: (...args: any[]) => any): void => {
        if (original === JSON.stringify(newItem)) {
            return;
        }
        callback();
    };

    /** intialises/defines a property for `this.data` and `this.silentData`  */
    private defineDataProperty(key: string, defaultData: any): void {
        const pKey = `_${key}`;
        this._previousDataValues[key] = defaultData[key];
        // define private prop
        Object.defineProperty(this._data, pKey, {
            enumerable: false,
            configurable: true,
            writable: true,
            value: defaultData[key]
        });
        // define getter and setter for this prop
        Object.defineProperty(this._data, key, {
            enumerable: true,
            get: (): any => {
                return this._data[pKey];
            },
            set: (v: any): void => {
                // setter automatically handles save and update events
                const originalVal = JSON.stringify(this._previousDataValues[key]);
                this._data[pKey] = v;
                this.callIfDiff(originalVal, this._data[pKey], this.save.bind(this));
                this.callIfDiff(originalVal, this._data[pKey], this.fireUpdated.bind(this));
                this._previousDataValues[key] = this._data[pKey];
            }
        });
        // define silentData getter and setter for this prop
        Object.defineProperty(this.silentData, key, {
            enumerable: true,
            get: (): any => {
                return this._data[pKey];
            },
            set: (v: any): void => {
                this._previousDataValues[key] = this._data[pKey];
                this._data[pKey] = v;
            }
        });
    }

    /** creates a new proxy for `this._data` or `this._silentData` */
    private newDataProxy(): CacheItemDef {
        return new Proxy(
            {} as any,
            {
                set: (obj: { [key: number]: UserCacheItem<any> }, prop: string, value: any | UserCacheItem<any>): boolean => {
                    const existing = Object.getOwnPropertyDescriptor(obj, prop);
                    if (!existing) {
                        // does not exist - define this prop
                        this.defineDataProperty(prop, { [prop]: value });
                        this.save();
                        this.fireUpdated();
                    } else {
                        // already exists - update it
                        obj[prop] = value;
                    }
                    return true;
                }
            }
        ) as CacheItemDef;
    }
}
