import { Component, Input, OnInit } from '@angular/core';
import { IProjectDetailsDto, PROJECT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

import { ActivatedRoute } from '@angular/router';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { IClientAccountMappedItem } from '@app/logic/client-account/interfaces/i.client-account.mapped';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { PermissionsPermissions } from '@app/core/permissions';
import { switchMap } from 'rxjs';
import { toPromise } from 'cb-hub-lib';

@Component({
    selector: 'cb-client-account-projects',
    templateUrl: './client-account-projects.component.html',
    styleUrls: ['./client-account-projects.component.scss']
})
export class ClientAccountProjectsComponent implements OnInit {

    @Input() public account: IClientAccountMappedItem;

    public PROJECT_STATUS_ENUM = PROJECT_STATUS_ENUM;

    public readonly displayedColumns: string[] = [
        'code',
        'name',
        'status',
        'stage',
        'lot',
        'dpNumber',
        'actions'
    ];

    public accountProjects: IProjectDetailsDto[];
    public accountProjectsLoaded = false;

    constructor(
        public readonly dialogRef: MatDialog,
        protected readonly clientAccountLogicService: ClientAccountLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly route: ActivatedRoute,
        public readonly navigation: NavigationService,
    ) { }

    public ngOnInit(): void {

        toPromise(this.route.params.pipe(
            switchMap((params: { id: number }) => {
                return this.clientAccountLogicService.getProjects(params.id);
            })
        )).then((mappedItem: IProjectDetailsDto[]) => {
            this.accountProjects = mappedItem;
            this.accountProjectsLoaded = true;
        });

    }

    public viewProject(id: string, event: MouseEvent): void {
        this.navigation.navigate([`/projects/${id}/details`], event);
    }
}
