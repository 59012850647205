import {
    CostTypeEnumId,
    COST_TYPE_ENUM,
    ICategoryDto,
    IIdAndLabelDto,
    ILotSpecScheduleItemDto,
    ILotSpecScheduleListItemDto,
    ILotSpecScheduleRestrictionDto,
    IOfferingCategoryAttributeOptionDto,
    IOfferingLookupDto,
    IQuoteLineRefDto
} from '@classictechsolutions/hubapi-transpiled-enums';

import { ILotScheduleItemAttributeNoteDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/LotScheduleItemAttributeNoteDto';
import { concatMap, map, Observable, of, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { ILotSpecScheduleItemLogicService } from './interfaces/i.lot-spec-schedule-item-logic.service';
import { ILotSpecScheduleItemMappedItem } from './interfaces/i.lot-spec-schedule-item.mapped';

export class LotSpecScheduleItemMappedItem
    extends BaseMappedItem<ILotSpecScheduleItemDto, ILotSpecScheduleItemMappedItem, ILotSpecScheduleItemLogicService>
    implements ILotSpecScheduleItemMappedItem {

    @DtoProp public id: number;
    @DtoProp public manualColourEntryRequired: boolean;
    @DtoProp public manualColourName: string;
    @DtoProp public manualColourNotes: string;
    @DtoProp public restrictions: ILotSpecScheduleRestrictionDto[];
    @DtoProp public offeringOptions: IOfferingCategoryAttributeOptionDto[];
    @DtoProp public lotSpecScheduleItemVersionId: number;
    @DtoProp public slotId: number;
    @DtoProp public sortOrder: number;
    @DtoProp public specGroupId: number;
    @DtoProp public specGroupLabel: string;
    @DtoProp public specGroupSortOrder: number;
    @DtoProp public costType: CostTypeEnumId;
    @DtoProp public quoteLineId: number;
    @DtoProp public slotName: string;
    @DtoProp public product: IOfferingLookupDto;
    @DtoProp public category: ICategoryDto;
    @DtoProp public slotCategory: ICategoryDto;
    @DtoProp public slotDescription: string;
    @DtoProp public tags: string[];
    @DtoProp public childItems: ILotSpecScheduleItemMappedItem[];
    @DtoProp public parentLotSpecScheduleItemId: number;
    @DtoProp public rateSnapshot: number;
    @DtoProp public quoteRequired: boolean;
    @DtoProp public supplierId: number;
    @DtoProp public productLabel: string;
    @DtoProp public clientFriendlyDescription: string;
    @DtoProp public quantity: number;
    @DtoProp public showInClientSpecification: boolean;
    @DtoProp public showInColourYourDreams: boolean;
    @DtoProp public hasProductImage: boolean;
    @DtoProp public productImageUrl: string;
    @DtoProp public productImageKeyPlusExtension: string;
    @DtoProp public scheduleItemImageUrl: string;
    @DtoProp public scheduleItemImageKeyPlusExtension: string;
    @DtoProp public attributeNotes: ILotScheduleItemAttributeNoteDto[];
    @DtoProp public lotId: number;
    @DtoProp public isDeleteAllowed: boolean;
    @DtoProp public suppliers: IIdAndLabelDto[];
    @DtoProp public supplierDisplay: string;
    @DtoProp public generatedByVariationNumber: number;

    public removeImageOnSave = false;
    public specScheduleItemImageFile: File;
    @Computed() public get qRef(): string {
        if (this.quoteLineId > 0) {
            return 'Q' + this.quoteLineId;
        } else {
            return '';
        }
    }

    @Computed() public get isActualCost(): boolean {
        return this.costType === COST_TYPE_ENUM.Actual;
    }

    public get isIncomplete(): boolean {
        let isIncomplete = true;
        switch (this.costType) {
            case COST_TYPE_ENUM.None:
                isIncomplete = true;
                break;
            case COST_TYPE_ENUM.Quote:
                isIncomplete = !this.quoteLineId;
                break;
            case COST_TYPE_ENUM.DescriptiveOnly:
                isIncomplete = !this.productLabel;
                break;
            case COST_TYPE_ENUM.Actual:
                isIncomplete = !this.product?.id &&
                    !this.product.name &&
                    !this.quoteLineId;
                break;
            default:
                isIncomplete = !this.product?.id &&
                    !this.product.name &&
                    !this.quoteLineId &&
                    !this.productLabel;
                break;
        }
        return isIncomplete;
    }

    public get hasIncompleteChildren(): boolean {
        return this.childItems?.some(
            (child: ILotSpecScheduleItemMappedItem) => child.isIncomplete
        );
    }

    constructor(
        sourceData: ILotSpecScheduleItemDto,
        logicService: ILotSpecScheduleItemLogicService
    ) {
        super(sourceData, logicService, LotSpecScheduleItemMappedItem,
            // default data
            {
                category: {} as ICategoryDto,
                product: {} as IOfferingLookupDto,
                childItems: [] as ILotSpecScheduleListItemDto[]
            }
        );
    }

    public getIsLotSpecLocked(): Observable<boolean> {
        return this.$logicService.getIsLotSpecLocked(this.id);
    }

    public $save(): Observable<ILotSpecScheduleItemDto> {
        const data = this.$getMappedDtoItem();

        if (data.id > 0) {
            return this.$logicService
                .$saveItem(data, this.lotId)
                .pipe(
                    concatMap((result: ILotSpecScheduleItemDto) => {
                        this.$updateThisAndOriginal(result);
                        if (this.specScheduleItemImageFile && !this.isActualCost) {
                            return this.uploadImage(result.lotSpecScheduleItemVersionId);
                        } else if (this.removeImageOnSave || (this.isActualCost && this.scheduleItemImageUrl)) {
                            return this.removeImage(result.lotSpecScheduleItemVersionId);
                        }
                        return of(result);
                    })
                );
        } else {
            return this.$logicService
                .$addItem(data, this.lotId)
                .pipe(
                    concatMap((result: ILotSpecScheduleItemDto) => {
                        if (this.specScheduleItemImageFile && !this.isActualCost) {
                            return this.uploadImage(result.lotSpecScheduleItemVersionId);
                        }
                        return of(result);
                    })
                );
        }

    }

    private uploadImage(lotSpecScheduleItemVersionId: number): Observable<ILotSpecScheduleItemDto> {
        if (!this.specScheduleItemImageFile) {
            return;
        }
        const fd = new FormData();
        fd.append('file', this.specScheduleItemImageFile);

        return this.$logicService
            .uploadScheduleItemImage(lotSpecScheduleItemVersionId, this.lotId, fd)
            .pipe(
                tap((result) => {
                    this.specScheduleItemImageFile = null;
                    this.$updateThisAndOriginal(result);
                })
            );
    }

    private removeImage(lotSpecScheduleItemVersionId: number): Observable<ILotSpecScheduleItemDto> {
        this.removeImageOnSave = false;
        return this.$logicService
            .removeScheduleItemImage(lotSpecScheduleItemVersionId, this.lotId)
            .pipe(
                map((result) => {
                    const data = this.$getMappedDtoItem();
                    if (result) {
                        this.specScheduleItemImageFile = null;
                        data.scheduleItemImageUrl = null;
                        this.$updateThisAndOriginal(data);
                    }
                    return data;
                })
            );
    }

    public getQuoteLine(): Observable<IQuoteLineRefDto> {
        return this.$logicService
            .getQuoteLine(this.id);
    }
}
