import { IClientSaleReportLineExtendedDto, IClientSaleReportTaskItemExtendedDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class ClientSaleReportLineExtendedDtoVm {

    public dto: IClientSaleReportLineExtendedDto;
    public isExpanded = false;

    public nextSteps: IClientSaleReportTaskItemExtendedDto[] = [];

    public toggleExpanded(): void {
        this.isExpanded = !this.isExpanded;
    }

    public hasActivitiesRequiringAction(): boolean {
        return this.dto.actions.some(x => x.assignedToCurrentUser);
    }

    public hasActivities(): boolean {
        return this.dto.actions != null && this.dto.actions.length > 0;
    }
}
