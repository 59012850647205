import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BuildTypeLogicService } from '@app/logic/build-type';
import { CouncilLogicService } from '@app/logic/councils';
import { ICouncilDto } from '@app/logic/councils/interfaces/i.council.dto';
import { LeadLogicService } from '@app/logic/leads';
import { ILeadLotInterestDto } from '@app/logic/leads/interfaces/i.lead.lot.interest.dto';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { LocationLogicService } from '@app/logic/location';
import { LotsLogicService } from '@app/logic/lots';
import { UsersLogicService } from '@app/logic/users';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import { IIdAndLabelDto } from '@app/shared/interfaces/i.idandlabel.dto';
import {
    COMPASS_DIRECTION_TYPE_ENUM, ILotDto, LotContractTypeEnumId, LOT_CONTRACT_TYPE_ENUM, LOT_TYPE_ENUM, MarketStatusEnumId,
    MARKET_STATUS_ENUM, ProjectReleaseStageTypeEnumId, PROJECT_RELEASE_STAGE_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { assign, find, forEach, merge, orderBy } from 'lodash';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

interface IData {
    lotTypeId: number;
    existingIds: string[];
    lot?: ILotDto;
    editInterest?: ILeadLotInterestDto | any;
    leadMappedItem: ILeadMappedItem;
}

@Component({
    selector: 'cb-add-lot-dialog',
    templateUrl: './add-lot-dialog.component.html',
    styleUrls: ['./add-lot-dialog.component.scss']
})
export class AddLotDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit, OnDestroy {
    public static readonly MIN_WIDTH = '600px';

    public lot: ILotDto;
    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;
    public COMPASS_DIRECTION_TYPE_ENUM = COMPASS_DIRECTION_TYPE_ENUM;
    public regionId$ = new BehaviorSubject<number>(undefined);
    public regionId: number;
    private readonly onDestroy = new Subject();

    public get alreadyChosenLot(): boolean {
        return this.existingIds.some(id => this.selectedLot.id === id);
    }


    public existingIds: any[];

    // the method that is called when the item is closed. This can be injected in
    protected close: (result?: { result: any }) => void;

    public createLotForm: NgForm;

    private interestId: number;
    public lotTypes = LOT_CONTRACT_TYPE_ENUM
        .toLookup()
        .filter(x =>
            // ProjectReleaseStageType values come from LotContractType - see backend/API defintion of ProjectReleaseStageType
            x.id === PROJECT_RELEASE_STAGE_TYPE_ENUM.DesignAndBuild as unknown as LotContractTypeEnumId
            || x.id === PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand as unknown as LotContractTypeEnumId
        );
    public marketStatus: IEnumLookup<MarketStatusEnumId>[];
    public buildTypes: any[] = [];

    public availableLots: ILotDto[];
    public selectedLot: ILotDto;
    public selectedBuildTypeId: number;
    public selectedAreaId: number;
    public selectedBuildConsentCouncilId: number;
    public lotTypeId: ProjectReleaseStageTypeEnumId;

    public developerProject: string;
    public areas: IIdAndLabelDto<number>[];
    public locationId: number;

    public editInterest: any;
    public isOwned: boolean;
    public roadDirection: number;
    public isPrimary = false;
    public councils: ICouncilDto[] = [];
    public leadMappedItem: ILeadMappedItem;

    constructor(
        public readonly dialogRef: MatDialogRef<AddLotDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly lotsLogicService: LotsLogicService,
        public toastService: ToastService,
        public readonly userLogic: UsersLogicService,
        public readonly councilsLogic: CouncilLogicService,
        public readonly lotLogic: LotsLogicService,
        public readonly buildTypesLogic: BuildTypeLogicService,
        private readonly locationLogic: LocationLogicService,
        private readonly leadLogicService: LeadLogicService
    ) {
        super(dialogRef, toastService);
        this.lot = data.lot;
        this.lotTypeId = data.lotTypeId;
        this.leadMappedItem = data.leadMappedItem;
        this.existingIds = data.existingIds;
        this.editInterest = data.editInterest;
        this.marketStatus = MARKET_STATUS_ENUM.toLookup();
        this.regionId$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: regionId => {
                this.locationId = undefined;
                this.regionId = regionId;
                this.loadLocation();
            }
        });
    }

    public ngOnInit(): void {
        this.formMode = !this.editInterest ? FormMode.Add : FormMode.Edit;

        if (!this.lot) {
            this.lot = { lotAddress: {} } as any;
        }

        this.buildTypesLogic
            .$getList()
            .subOnce(x => {
                const allBuildTypes = x;
                forEach(allBuildTypes, (buildType) => {
                    if (find((buildType as any).lotType, (l) => l === LOT_TYPE_ENUM.Standalone)) {
                        this.buildTypes.push(buildType);
                    }
                });
            });
        this.areas = [];

        if (this.editInterest) {
            this.editLot(this.editInterest);
        }
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(null);
        this.onDestroy.complete();
    }

    public onAreaChanged(): void {
        if (this.selectedAreaId > 0) {
            this.councilsLogic.getByLocation(this.selectedAreaId).subOnce(result => {
                this.councils = result;
                if (this.councils.length === 1) {
                    this.selectedBuildConsentCouncilId = this.councils[0].id;
                }
            });
        }
    }

    public loadLocation(): void {
        if (this.lot?.lotAddress?.region) {
            this.locationLogic.getAreasByRegionId({ addressRegionId: this.lot.lotAddress.region }).subOnce((result) => {
                this.areas = this.orderOptionsByLabel(result);
                this.selectedBuildConsentCouncilId = null;
            });
        }
    }

    public orderOptionsByLabel(options): Array<IIdAndLabelDto<number>> {
        options = orderBy(options, ['label'], ['asc']);
        return options;
    }

    public editLot(interest: any): void {
        this.selectedBuildTypeId = interest.buildTypeId;
        this.lotTypeId = interest.contractType;
        this.developerProject = interest.developerProject;
        this.locationId = interest.locationId;
        this.interestId = interest.id;
        this.isPrimary = interest.isPrimary;
        this.selectedBuildConsentCouncilId = interest.buildingConsentCouncilId;
        this.selectedAreaId = interest.locationId;
        this.onAreaChanged();
        if (interest.lotId > 0 && (!this.lot || !this.lot.id)) {
            this.lotLogic
                .$getItem(interest.lotId)
                .subOnce((result: ILotDto) => {
                    this.lot = result;
                });
        }
    }

    public addLot(): void {
        const interest = {
            buildTypeId: this.selectedBuildTypeId,
            buildingConsentCouncilId: this.selectedBuildConsentCouncilId,
            contractType: this.lotTypeId,
            developerProject: this.developerProject,
            id: this.interestId,
            isPrimary: this.isPrimary,
            locationId: this.selectedAreaId,
            lot: {
                id: 0,
                lotAddress: {} as any,
                lotType: this.lotTypeId,
                roadDirection: this.lot.roadDirection
            } as any,
        };

        if (this.isHouseAndLand()) {
            const lotDetails = {
                lot: {
                    id: this.selectedLot.id,
                    lotAddress: this.selectedLot.lotAddress,
                    lotType: this.lotTypeId,
                    roadDirection: this.selectedLot.roadDirection
                } as any,
                lotId: this.selectedLot.id
            };
            merge(interest, lotDetails);
        } else if (this.editInterest) {
            interest.lot = this.lot;
            interest.lot.lotType = this.lotTypeId;
        } else if (this.isDesignAndBuild()) {
            interest.lot = assign(interest.lot, this.lot);
        }

        this.leadMappedItem.addUpdateInterestObservable(interest as any).subOnce(result => this.handleNext(result));
    }

    public isDesignAndBuild(): boolean {
        return this.lotTypeId === PROJECT_RELEASE_STAGE_TYPE_ENUM.DesignAndBuild;
    }

    public isHouseAndLand(): boolean {
        return this.lotTypeId === PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand;
    }

    public enableHouseAndLandSearch(): boolean {
        return this.isHouseAndLand()
            && this.leadMappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand;
    }

    public enableDesignAndLandSearch(): boolean {
        return this.isHouseAndLand()
            && this.leadMappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    public canEditBuildType(): boolean {
        return this.editInterest === undefined;
    }
}
