import { TASK_TYPE_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { ITaskType } from '../tasks/interfaces/i.Task.dto';

export const taskTypeClassMap = {
    [TASK_TYPE_CONSTANTS_CONST.CallbackTaskType]: 'phone',
    [TASK_TYPE_CONSTANTS_CONST.FollowUpTaskType]: 'email',
    [TASK_TYPE_CONSTANTS_CONST.MeetingTaskType]: 'people',
    [TASK_TYPE_CONSTANTS_CONST.GeneralTaskType]: 'date_range'
};

export const taskTypesSelect: Array<ITaskType> = [
    {
        canCreate: true,
        id: TASK_TYPE_CONSTANTS_CONST.MeetingTaskType,
        label: 'Meeting',
    },
    {
        canCreate: true,
        id: TASK_TYPE_CONSTANTS_CONST.GeneralTaskType,
        label: 'Task',
    },
    {
        canCreate: false,
        id: TASK_TYPE_CONSTANTS_CONST.CallbackTaskType,
        label: 'Callback'
    },
    {
        canCreate: false,
        id: TASK_TYPE_CONSTANTS_CONST.FollowUpTaskType,
        label: 'Follow Up',
    }
];

export const taskTypeLabels = {
    [TASK_TYPE_CONSTANTS_CONST.CallbackTaskType]: 'Callback',
    [TASK_TYPE_CONSTANTS_CONST.FollowUpTaskType]: 'Follow Up',
    [TASK_TYPE_CONSTANTS_CONST.MeetingTaskType]: 'Task',
    [TASK_TYPE_CONSTANTS_CONST.GeneralTaskType]: 'Meeting'
};
