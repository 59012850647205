import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LotSearchService } from '@app/core/services/search/lot-search.service';
import { IHouseAndLandSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { LocationLogicService } from '@app/logic/location';
import { ILotSearchDto, LotsLogicService } from '@app/logic/lots';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { floorAreaSelect } from '@app/views/standard-plans/floor-area-types-constant';
import { ILocationDto, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { cleanObject, toPromisedArray } from 'cb-hub-lib';
import { find } from 'lodash';


@Component({
    selector: 'app-house-and-land-search',
    templateUrl: './house-and-land-search.component.html',
    styleUrls: ['./house-and-land-search.component.scss'],
    providers: [
        LotSearchService,
    ]
})
export class HouseAndLandSearchComponent implements OnInit, OnDestroy {

    @Input() public lots: ILotSearchDto[];

    @Output() public emitFilterEvent = new EventEmitter();
    @Output() public emitResultSizeEvent = new EventEmitter();

    public floorAreaTypes = floorAreaSelect;
    public locations: ILocationDto[] = [];

    public costs: number[] = [];
    public landAreas: number[] = [];
    public floorAreas: number[] = [];

    public costsAreasLoaded = false;

    public minCostLoaded = false;
    public maxCostLoaded = false;

    public minLandAreaLoaded = false;
    public maxLandAreaLoaded = false;

    public minFloorAreaLoaded = false;
    public maxFloorAreaLoaded = false;

    public searchIsLoading = false;
    public noMoreResults = false;
    private currentPage = 1;
    public paramsSetupComplete = false;
    public searchParams: IHouseAndLandSearch;

    public sortingOptions: any[] = [
        { id: undefined, label: 'None' },
        { id: 'priceLow', label: 'Price Low - High' },
        { id: 'priceHigh', label: 'Price High - Low' },
        { id: 'projectLots', label: 'Project Lots' }
    ];

    public cardSizeOptions: any[] = [
        { value: '100', label: '1' },
        { value: '50', label: '2' },
        { value: '33.33', label: '3' },
        { value: '25', label: '4' },
        { value: '20', label: '5' }
    ];

    public searchQueryUpdate = new Subject<string>();

    public filteredLots = new ComputedProperty(
        () => this.lots
    );

    constructor(
        private lotSearchService: LotSearchService,
        private lotsLogicService: LotsLogicService,
        public readonly dialog: MatDialog,
        public readonly userCacheService: UserCacheService,
        public readonly locationLogicService: LocationLogicService,
    ) {
        this.lotSearchService.availableOnly = true;
        this.lotSearchService.extraSearchParams = this.searchParams;
        this.locations = toPromisedArray(locationLogicService.$getList());

        this.searchQueryUpdate.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(() => {
                this.queryUpdated();
            });
    }

    public ngOnInit(): void {
        this.initialiseData();
        this.initSearchParams();
    }

    public ngOnDestroy(): void {
        this.emitFilterEvent.unsubscribe();
        this.emitResultSizeEvent.unsubscribe();
    }

    private readonly handleSearchResult = (results): void => {
        this.lots.push.apply(this.lots, results);
    };

    public initialiseData(): void {
        this.lotsLogicService
            .getMaxMinCostArea()
            .subOnce((result) => {
                this.costs = [Math.floor(result.costs[0]), Math.ceil(result.costs[1])];
                this.floorAreas = [Math.floor(result.floorAreas[0]), Math.ceil(result.floorAreas[1])];
                this.landAreas = [Math.floor(result.landAreas[0]), Math.ceil(result.landAreas[1])];

                this.costsAreasLoaded = true;
                this.minCostLoaded = true;
                this.maxCostLoaded = true;
                this.minFloorAreaLoaded = true;
                this.maxFloorAreaLoaded = true;
                this.minLandAreaLoaded = true;
                this.maxLandAreaLoaded = true;
            });
    }


    public initSearchParams(): void {
        this.userCacheService.houseLandSearch.init().then(() => {
            this.searchParams = this.userCacheService.houseLandSearch.data;

            if (this.searchParams.resultSize) {
                this.emitResultSizeEvent.emit(this.searchParams.resultSize);
            }

            this.paramsSetupComplete = true;
        });
    }

    public queryUpdated(): void {
        if (this.searchIsLoading) {
            return;
        }

        this.currentPage = 0;
        this.lots = [];
        this.noMoreResults = false;
        this.doSearch();
    }

    public doSearch = (): void => {
        if (this.searchIsLoading || this.noMoreResults && this.currentPage > 0) {
            return;
        }

        if (this.currentPage === 0) {
            this.lots = [];
        }

        this.currentPage++;
        this.searchIsLoading = true;

        const params = {
            currentPage: this.currentPage,
            'floorArea.max': Number(this.searchParams.floorAreaMax) !== Number(this.floorAreas[1]) ? this.searchParams.floorAreaMax : null,
            'floorArea.min': Number(this.searchParams.floorAreaMin) !== Number(this.floorAreas[0]) ? this.searchParams.floorAreaMin : null,
            'landArea.max': Number(this.searchParams.landAreaMax) !== Number(this.landAreas[1]) ? this.searchParams.landAreaMax : null,
            'landArea.min': Number(this.searchParams.landAreaMin) !== Number(this.landAreas[0]) ? this.searchParams.landAreaMin : null,
            locationId: this.searchParams.locationId,
            numberOfBathrooms: this.searchParams.numberOfBathrooms > 0 ? this.searchParams.numberOfBathrooms : null,
            numberOfBedrooms: this.searchParams.numberOfBedrooms > 0 ? this.searchParams.numberOfBedrooms : null,
            numberOfGarages: this.searchParams.numberOfGarages > 0 ? this.searchParams.numberOfGarages : null,
            numberOfLivingRooms: this.searchParams.numberOfLivingRooms > 0 ? this.searchParams.numberOfLivingRooms : null,
            order: this.searchParams.order,
            'price.Max': Number(this.searchParams.priceMax) !== Number(this.costs[1]) ? this.searchParams.priceMax : null,
            'price.Min': Number(this.searchParams.priceMin) !== Number(this.costs[0]) ? this.searchParams.priceMin : null,
            query: this.searchParams.query,
            lotContractTypes: [LOT_CONTRACT_TYPE_ENUM.HouseAndLand, LOT_CONTRACT_TYPE_ENUM.DesignAndLand],
        };

        this.lotSearchService.extraSearchParams = cleanObject(params);

        this.lotSearchService.doSearch(this.searchParams.query, this.currentPage).subOnce(
            results => {
                if ((!results) || results.length === 0) {
                    this.noMoreResults = true;
                }
                this.handleSearchResult(results);
                this.emitFilterEvent.emit({ lots: this.lots, noMoreResults: this.noMoreResults });
                this.searchIsLoading = false;
            }
        );

    };

    public remove(prop: any): void {
        this.searchParams[prop] = null;
        this.queryUpdated();
    }

    public clearAll(): void {
        this.searchParams.floorAreaMin = this.floorAreas[0];
        this.searchParams.floorAreaMax = this.floorAreas[1];

        this.searchParams.landAreaMin = this.landAreas[0];
        this.searchParams.landAreaMax = this.landAreas[1];

        this.searchParams.priceMin = this.costs[0];
        this.searchParams.priceMax = this.costs[1];

        this.searchParams.numberOfBathrooms = null;
        this.searchParams.numberOfBedrooms = null;
        this.searchParams.numberOfGarages = null;
        this.searchParams.numberOfLivingRooms = null;
        this.searchParams.locationId = null;
        this.searchParams.query = null;

        this.queryUpdated();
    }

    public isFiniteNumber(value: number): boolean {
        return Number.isFinite(value);
    }

    public anyFilterApplied(): boolean {
        return (this.searchParams?.query && this.searchParams.query !== '' && this.searchParams.query !== null) ||
            this.searchParams.numberOfBedrooms > 0 ||
            this.searchParams.numberOfBathrooms > 0 ||
            this.searchParams.numberOfLivingRooms > 0 ||
            this.searchParams.numberOfGarages > 0 ||
            this.searchParams.locationId > 0 ||
            this.showMinPriceChip() ||
            this.showMaxPriceChip() ||
            this.showMinFloorAreaChip() ||
            this.showMaxFloorAreaChip() ||
            this.showMinLandAreaChip() ||
            this.showMaxLandAreaChip();
    }


    public showMinPriceChip(): boolean {
        return this.costsAreasLoaded &&
            this.minCostLoaded &&
            this.searchParams.priceMin !== this.costs[0] &&
            this.searchParams.priceMin > 0 &&
            this.isFiniteNumber(this.searchParams.priceMin);
    }

    public showMaxPriceChip(): boolean {
        return this.costsAreasLoaded &&
            this.maxCostLoaded &&
            this.searchParams.priceMax !== this.costs[1] &&
            this.searchParams.priceMax > 0 &&
            this.isFiniteNumber(this.searchParams.priceMax);
    }

    public showMinFloorAreaChip(): boolean {
        return this.costsAreasLoaded &&
            this.minFloorAreaLoaded &&
            this.searchParams.floorAreaMin !== this.floorAreas[0] &&
            this.searchParams.floorAreaMin > 0 &&
            this.isFiniteNumber(this.searchParams.floorAreaMin);
    }

    public showMaxFloorAreaChip(): boolean {
        return this.costsAreasLoaded &&
            this.maxFloorAreaLoaded &&
            this.searchParams.floorAreaMax !== this.floorAreas[1] &&
            this.searchParams.floorAreaMax > 0 &&
            this.isFiniteNumber(this.searchParams.floorAreaMax);
    }

    public showMinLandAreaChip(): boolean {
        return this.costsAreasLoaded &&
            this.minLandAreaLoaded &&
            this.searchParams.landAreaMin !== this.landAreas[0] &&
            this.searchParams.landAreaMin > 0 &&
            this.isFiniteNumber(this.searchParams.landAreaMin);
    }

    public showMaxLandAreaChip(): boolean {
        return this.costsAreasLoaded &&
            this.maxLandAreaLoaded &&
            this.searchParams.landAreaMax !== this.landAreas[1] &&
            this.searchParams.landAreaMax > 0 &&
            this.isFiniteNumber(this.searchParams.landAreaMax);
    }

    public getRegionChipText(selectedId: number): string {
        const initialItem = find(this.locations, (item) => {
            return item.id === selectedId;
        });

        if (initialItem !== undefined) {
            return initialItem.name;
        }

        return '';
    }

    public resultSizeChanged($event): void {
        this.emitResultSizeEvent.emit($event);
    }

}
