<mat-form-field>
    <input matInput
           [matDatepicker]="dp"
           placeholder="Month and Year"
           [formControl]="date"
           [(ngModel)]="value"
           [required]="true"
           >
    <mat-datepicker-toggle matSuffix
                           [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
                    startView="multi-year"
                    (monthSelected)="chosenMonthHandler($event, dp)"
                    panelClass="example-month-picker">
    </mat-datepicker>
</mat-form-field>
