import { IExternalSystemFieldTemplateItemDto, ISlotExternalSystemFieldValueDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ISlotExternalSystemFieldValuesLogicService } from './interfaces/i.slot-external-system-field-value.logic-service';
import { ISlotExternalSystemFieldValueMappedItem } from './interfaces/i.slot-external-system-field-value.mapped';

export class SlotExternalSystemFieldValueMappedItem
    extends BaseMappedItem<ISlotExternalSystemFieldValueDto, ISlotExternalSystemFieldValueMappedItem, ISlotExternalSystemFieldValuesLogicService>
    implements ISlotExternalSystemFieldValueMappedItem {

    @DtoProp public id: number;
    @DtoProp public slotId: number;
    @DtoProp public slotName: string;
    @DtoProp public externalSystemFieldTemplateItem: IExternalSystemFieldTemplateItemDto;
    @DtoProp public fieldValue: string;

    constructor(
        sourceData: ISlotExternalSystemFieldValueDto,
        logicService: ISlotExternalSystemFieldValuesLogicService
    ) {
        super(sourceData, logicService, SlotExternalSystemFieldValueMappedItem, {
            externalSystemFieldTemplateItem: {} as IExternalSystemFieldTemplateItemDto
        });
    }


}
