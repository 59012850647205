import {Component, OnInit} from '@angular/core';
import {PriceOptionDto} from '@app/logic/price-options/price-option.dto';
import {PriceOptionsService} from '@app/logic/price-options/priceoptions.service';
import {CanComponentDeactivate} from '@app/shared/validators/can-component-deactivate';
import {ArrayUtils} from '@app/shared/utils/array.util';

@Component({
    selector: 'cb-price-options',
    templateUrl: './price-options.component.html',
    styleUrls: ['./price-options.component.scss']
})
export class PriceOptionsComponent implements OnInit, CanComponentDeactivate {
    public dataSource: PriceOptionDto[] = [];
    public initialDataSourceState = '';
    public displayedColumns: string[] = ['name', 'description', 'textTemplate', 'isActive', 'isDefault', 'captureAltPrice', 'showSellPrice', 'actions'];

    constructor(private service: PriceOptionsService) { }

    public ngOnInit(): void {
        this.fetchData();
    }

    public fetchData(): void {
        this.service.getAll().subscribe(data => {
            this.dataSource = data;
            this.initialDataSourceState = JSON.stringify(data);
        });
    }

    public save(element: PriceOptionDto): void {
        this.service.save(element).subscribe(() => {
            this.fetchData();
        });
    }

    public addNew(): void {
        const newRecord: PriceOptionDto = {
            id: null,
            name: '',
            description: '',
            textTemplate: '',
            isActive: true,
            isDefault: false,
            captureAltPrice: false,
            showSellPrice: false,
            isNew: true
        };
        this.dataSource = [...this.dataSource, newRecord];
    }

    public delete(id: number): void {
        if (!confirm('Are you sure you want to delete this price option?')) {
            return;
        }

        if (id === null) {
            this.dataSource = this.dataSource.filter(item => item.id !== null);
        } else {
            this.service.delete(id).subscribe(() => {
                this.fetchData();
            }, error => {
                console.error('Error deleting price option:', error);
                alert('There was an error deleting the price option.');
            });
        }
    }

    public getPreviewText(element: PriceOptionDto): string {
        if (element.captureAltPrice || element.showSellPrice) {return element.textTemplate + ' ' + '$1,300,000';}
        return element.textTemplate;
    }

    public canDeactivate(): boolean | Promise<boolean> {
        if (JSON.stringify(this.dataSource) !== this.initialDataSourceState) {
            return window.confirm('You have unsaved changes! Are you sure you want to leave?');
        }
        return true;
    }
}
