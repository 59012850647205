<mat-card class="main-card flex-col flex"
          *ngIf="!mappedItem.isDeleted">
    <mat-card-header class="start-start flex-row">
        <div class="header-icons flex-row">
            <cb-lot-spec-colour-item-icons [mappedItem]="mappedItem"></cb-lot-spec-colour-item-icons>
        </div>
        <span class="mat-body-2">{{mappedItem.colourItem.name}}</span>
    </mat-card-header>
    <mat-card-content class="card-content cb-padding-top flex-row flex">
        <div class="flex-col flex">
            <cb-display-value-new label="Colour"
                                  [value]="mappedItem.name"></cb-display-value-new>
            <cb-text-area class="notes"
                          label="Notes"
                          [ngModel]="mappedItem.notes"
                          [readonly]="true"></cb-text-area>
        </div>
        <div class="images flex-col">
            <cb-image-button class="flex"
                             [baseUrl]="mappedItem.specColourItemImage">
            </cb-image-button>
        </div>
    </mat-card-content>
    <mat-card-actions class="with-divider cb-padding-left end-center flex-row">
        <button mat-stroked-button
                color="accent"
                (click)="uiActions.editColourItem()"
                *ngIf="uiActions.canChangeSpecification()">Edit</button>
        <button mat-stroked-button
                (click)="uiActions.removeColourItem()"
                *ngIf="uiActions.canChangeSpecification()">Remove</button>
    </mat-card-actions>
</mat-card>
