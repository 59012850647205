import { clientSaleUpdateDto } from './../../../../logic/client-sale/dtos/client-sale-update.dto';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IClientSaleMappedItem } from '@app/logic/client-sale';
import { IClientSaleUpdateDto } from '@app/logic/client-sale/interfaces/i.client-sale-update.dto';
import { IUserSearchDto } from '@app/logic/users/interfaces/i.user-search.dto';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LOT_CONTRACT_TYPE_ENUM, PURCHASE_REASON_ENUM, USER_TAG_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    clientSale: IClientSaleMappedItem;
}

@Component({
    selector: 'cb-sale-details-edit-dialog',
    templateUrl: './sale-details-edit-dialog.component.html',
    styleUrls: ['./sale-details-edit-dialog.component.scss']
})
export class SaleDetailsEditDialogComponent extends BaseDialogFormViewDirective<IClientSaleUpdateDto, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '400px';

    public readonly isHouseAndLand = new ComputedProperty(() => this.data && this.data.clientSale.lot.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.HouseAndLand);
    public readonly isThirdPartyHouseAndLand = new ComputedProperty(() =>
        this.data
        && this.data.clientSale.lot.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand
    );
    public readonly isDesignAndBuild = new ComputedProperty(() => this.data && this.data.clientSale.lot.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild);
    public readonly isDesignAndLand = new ComputedProperty(() => this.data && this.data.clientSale.lot.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.DesignAndLand);

    public clientSaleDetails: IClientSaleUpdateDto;

    public readonly purchaseReasonLookup = PURCHASE_REASON_ENUM.toLookup();
    public readonly BUILDING_CONSULTANT = USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT;

    public selectedBuildingConsultant = {} as IUserSearchDto;

    constructor(
        public readonly dialogRef: MatDialogRef<SaleDetailsEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.clientSaleDetails = this.data.clientSale.$mapToDtoItem(clientSaleUpdateDto);

        this.selectedBuildingConsultant = {
            id: this.clientSaleDetails.buildingConsultantId,
            name: this.data.clientSale.buildingConsultantName
        } as IUserSearchDto;

        this.isHouseAndLand.recompute();
        this.isThirdPartyHouseAndLand.recompute();
        this.isDesignAndBuild.recompute();
        this.isDesignAndLand.recompute();
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public save(): void {
        if (this.isDesignAndBuild.value || this.isThirdPartyHouseAndLand.value) {
            this.clientSaleDetails.buildingConsultantId = this.selectedBuildingConsultant.id;
        }

        this.data.clientSale.updateDetails(this.clientSaleDetails)
            .subOnce({
                next: this.handleNext,
                error: this.handleError
            });
    }
}
