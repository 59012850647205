import { BaseMappedItem } from '../base/base-mapped-item';
import { ISpecGroupsLogicService } from './interfaces/i.spec-groups.logic.service';
import { ISpecGroupMappedItem } from './interfaces/i.spec-group.mapped';
import { ISpecGroupDto } from './interfaces/i.spec-group.dto';
import { DtoProp } from '../base/dto-prop.decorator';

export class SpecGroupMappedItem
    extends BaseMappedItem<ISpecGroupDto, ISpecGroupMappedItem, ISpecGroupsLogicService>
    implements ISpecGroupMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public code: string;
    @DtoProp public isActive: boolean;
    @DtoProp public isQsOnly: boolean;
    @DtoProp public label: string;
    @DtoProp public name: string;
    @DtoProp public sortOrder: number;
    @DtoProp public type: string;

    constructor(
        sourceData: ISpecGroupDto,
        logicService: ISpecGroupsLogicService
    ) {
        super(sourceData, logicService, SpecGroupMappedItem);
    }
}
