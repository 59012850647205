<div class="flex-row flex"
     *cbWaitFor="mappedItem">
    <div class="flex-col flex">
        <div class="flex-row">
            <div class="cb-margin-right-2x flex-col flex">
                <cb-select name="designComplexity"
                           label="Design Complexity"
                           [options]="designComplexities"
                           labelProp="complexity"
                           [required]="true"
                           [(ngModel)]="mappedItem.designComplexityId"
                           (ngModelChange)="updatedDesignComplexity()">
                </cb-select>
            </div>
            <div class="flex-col flex">
                <cb-datepicker label="Due Date"
                               name="dueBy"
                               pickerType="calendar"
                               [required]="true"
                               [(ngModel)]="mappedItem.dueDate"></cb-datepicker>
            </div>
        </div>
        <cb-input type="number"
                  name="targetWorkHours"
                  label="Estimated Hours"
                  [(ngModel)]="mappedItem.estimatedHours">
        </cb-input>
        <cb-user-autocomplete-scroller [team]="DESIGN_TEAM"
                                       label="Assigned To"
                                       name="assignedToUserId"
                                       [(ngModel)]="assignedUser"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [required]="mappedItem.id > 0"
                                       [active]="true"
                                       (change)="assignedChanged()"></cb-user-autocomplete-scroller>
        <cb-checkbox name="designReviewRequired"
                     label="Design Review Required"
                     [(ngModel)]="mappedItem.designReviewRequired"></cb-checkbox>
        <cb-user-autocomplete-scroller *ngIf="mappedItem.designReviewRequired"
                                       [tag]="DESIGN_SUPERVISOR"
                                       label="Reviewer"
                                       name="reviewerId"
                                       [(ngModel)]="reviewerUser"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [required]="true"
                                       [active]="true"
                                       (change)="reviewerChanged()"></cb-user-autocomplete-scroller>
    </div>
</div>
