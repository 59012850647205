import { Component } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { ISupplyTypeTreeMappedItem } from '@app/logic/supply-types/interfaces/i.supply-type-tree.mapped';
import { SupplyTypesTreeLogicService } from '@app/logic/supply-types/supply-types-tree.logic.service';
import { SupplyTypesLogicService } from '@app/logic/supply-types/supply-types.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { SupplyTypeDialogComponent } from '@app/views/settings/pages/supply-types/supply-type-dialog/supply-type-dialog.component';
import { isNullOrWhiteSpace } from 'cb-hub-lib';

@Component({
    selector: 'cb-supply-types',
    templateUrl: './supply-types.component.html',
    styleUrls: ['./supply-types.component.scss']
})
export class SupplyTypesComponent {
    public filterText = '';
    public supplyTypeTree: ISupplyTypeTreeMappedItem[];
    public loaded = false;
    public expanded: { [supplyTypeId: number]: boolean } = {};
    public isFeatureToggleEnabled: boolean;

    public readonly filteredResults = new ComputedProperty(() => {
        this.expanded = {};
        if (!this.supplyTypeTree) {
            return;
        }
        return this.supplyTypeTree?.filter(this.filterSupplyTypes) ?? [];
    });

    constructor(
        public readonly permissions: PermissionsPermissions,
        private readonly supplyTypeLogicService: SupplyTypesLogicService,
        private readonly supplyTypeTreeLogicService: SupplyTypesTreeLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly featureToggleService: FeatureToggleStatesService
    ) {
        featureToggleService.init().then(() => {
            this.isFeatureToggleEnabled = featureToggleService.isSupplierTypesEnabled;
            if (this.isFeatureToggleEnabled){
                this.loadSupplyTypes();
            }
        });
    }

    public addSupplyType(): void {
        this.cbDialog.open(SupplyTypeDialogComponent, {
            data: {
                mappedItem: this.supplyTypeLogicService.$createMappedItem(),
            },
            minWidth: '50%',
        })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.loadSupplyTypes();
                }
            });
    }

    public generateReport(): void {
        this.supplyTypeLogicService.downloadSupplyTypesReport().subOnce();
    }

    public loadSupplyTypes(): void {
        this.supplyTypeTreeLogicService
            .getMappedTree()
            .subOnce((tree) => {
                this.supplyTypeTree = tree;
                this.loaded = true;
                this.filteredResults.recompute();
            });
    }

    public canEdit(): boolean {
        return this.permissions.canEditSettings();
    }

    @Debounce(300)
    public filterChanged(): void {
        this.filteredResults.recompute();
    }

    public readonly filterSupplyTypes = (item: ISupplyTypeTreeMappedItem): boolean => {
        if (item?.filterChildren) {
            item?.filterChildren(this.filterText);
        }
        if (isNullOrWhiteSpace(this.filterText)) {
            return true;
        }
        const childFound = item?.filteredChildren?.length > 0;
        if (childFound && !this.expanded[item?.id]) {
            this.expanded[item?.id] = true;
        }
        return this.matchesSearch(item?.label) || childFound;
    };

    public matchesSearch(text: string): boolean {
        return text.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1;
    }
}
