import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'cb-toggle-list-filter-menu',
    templateUrl: './toggle-list-filter-menu.component.html',
    styleUrls: ['./toggle-list-filter-menu.component.scss']
})
export class ToggleListFilterMenuComponent {
    @Input() public isSpecSchedule: boolean;
    @Input() public listViewEnabled: boolean;
    @Input() public isIncompleteOnly: boolean;
    @Output() public readonly clearFilters = new EventEmitter<void>();
    @Output() public listViewEnabledChange = new EventEmitter<boolean>();
    @Output() public isIncompleteOnlyChange = new EventEmitter<boolean>();
}
