<cb-dialog [dialogHeading]="'Edit Variation Line'">
    <form #variationLineForm="ngForm">
        <div class="cb-margin flex-row">
            <div class="flex-col flex">
                <cb-display-value-new label="Variation Order"
                                      [value]="'VO' + mappedItem.variationNumber.toString()">
                </cb-display-value-new>
            </div>
            <div class="flex-col flex">
                <cb-display-value-new label="Change Number"
                                      [value]="mappedItem.changeNumber.toString()">
                </cb-display-value-new>
            </div>
        </div>
        <div class="cb-margin flex-row">
            <div class="flex-col flex">
                <div>
                    <cb-input label="Original Description (as appears on Variation)"
                              name="originalDescription"
                              [disabled]="false"
                              [readonly]="true"
                              [ngModel]="mappedItem.changeDetails">
                    </cb-input>
                </div>
            </div>
        </div>
        <div class="cb-margin flex-row">
            <div class="flex-col flex">
                <div>
                    <cb-input label="New Description for AR Tab"
                              name="arDescription"
                              [(ngModel)]="mappedItem.arChangeDetails">
                    </cb-input>
                </div>
            </div>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="variationLineForm.pristine || variationLineForm.invalid"
                (cbClick)="saveArDetails()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancelDialog()">Cancel</button>
    </footer>
</cb-dialog>
