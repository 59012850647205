<cb-dialog dialogHeading="Costx Rate Library Export">
    <form class="flex-col"
          #form="ngForm">
        <cb-datepicker label="Effective Date"
                       name="effectiveDate"
                       [(ngModel)]="effectiveDate"
                       [required]="true"
                       pickerType="calendar">
        </cb-datepicker>
        <cb-checkbox label="Include Composite Items for Pricing"
                     name="includeCompositeItems"
                     [(ngModel)]="includeCompositeItems"></cb-checkbox>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                (cbClick)="confirm()"
                [clickLimit]="1"
                [disabled]="effectiveDate == null">Download</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
