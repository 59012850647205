import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../../base/base-logic.service';
import { IResourceConsentLogicService } from './interfaces/i.resource-consent-logic.service';
import { IResourceConsentDto } from './interfaces/i.resource-consent.dto';
import { IResourceConsentMappedItem } from './interfaces/i.resource-consent.mapped';
import { ResourceConsentMappedItem } from './resource-consent.mapped';

@Injectable()
export class ResourceConsentLogicService
    extends BaseLogicService<IResourceConsentDto, IResourceConsentMappedItem>
    implements IResourceConsentLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'resourceconsents',
            ResourceConsentMappedItem,
        );
    }

    public mapResourceConsent(data = {} as Partial<IResourceConsentDto>): IResourceConsentMappedItem {
        return this.$createMappedItem(data);
    }

    public getElapsedTime(id: number): Observable<string> {
        return this.$http.get(`${this.$baseUri}/elapsedtime/${id}`);
    }
}
