import { Component, Input } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LotDesignPermissions } from '@app/core/permissions';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { ILotSpecColourItemMappedItem } from '@app/logic/lot-spec-colour-item';
import { ILotSpecItemMappedItem } from '@app/logic/lot-spec-item';
import { ILotSpecAllItem } from '@app/logic/lot-spec/interfaces/i.lot-spec-all-item.dto';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { LotSpecLogicService } from '@app/logic/lot-spec/lot-spec.logic.service';
import { LotsLogicService } from '@app/logic/lots';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { toPromisedArray } from 'cb-hub-lib';
import { EditLotSpecColourItemDialogComponent } from '@app/views/lot/specification/edit-lot-spec-colour-item-dialog/edit-lot-spec-colour-item-dialog.component';
import { EditLotSpecItemDialogComponent } from '@app/views/lot/specification/edit-lot-spec-item-dialog/edit-lot-spec-item-dialog.component';
import {
    COST_TYPE_ENUM,
    ILotDto,
    ILotSpecColourItemDto,
    ILotSpecItemDto,
    ProductTypeEnumId,
    PRODUCT_TYPE_ENUM,
    TEAM_CODES_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { find, orderBy, remove } from 'lodash';

@Component({
    selector: 'cb-design-scheme-lot-specification',
    templateUrl: './lot-specification.component.html',
    styleUrls: ['./lot-specification.component.scss']
})
export class DesignSchemeLotSpecificationComponent {

    private _mappedItem: IDesignSchemeMappedItem;
    @Input() public set mappedItem(item: IDesignSchemeMappedItem) {
        this._mappedItem = item;
        if (this.mappedItem) {
            this.hasItems = false;
            this.lotSpecLogic
                .getLotSpecByVersion(this.mappedItem.lotId, this.mappedItem.lotSpecVersion)
                .subOnce((lotSpec) => {
                    this.lotSpec = lotSpec;
                    this.loadSpecGroups();
                });
        }
    }
    public get mappedItem(): IDesignSchemeMappedItem {
        return this._mappedItem;
    }

    @Input() public readonly lot: ILotDto;
    public allSpecGroups = toPromisedArray(this.specGroupLogic.$getList());
    public specGroups: {
        specGroupId: number;
        label: string;
        sortOrder: number;
        items: ILotSpecAllItem[];
    }[];
    public lotSpec: ILotSpecMappedItem;
    public expandedPanels = {};
    public hasItems = false;
    public allExpanded = false;

    public readonly columns = [
        '',
        'Cost Type',
        'Code',
        'Product',
        'Options',
        'Quantity',
        'Amount',
        'Supplier',
        '',
    ];
    public readonly COST_TYPE = COST_TYPE_ENUM;

    constructor(
        private readonly lotLogic: LotsLogicService,
        private readonly lotSpecLogic: LotSpecLogicService,
        private readonly specGroupLogic: SpecGroupsLogicService,
        private readonly lotDesignPermissions: LotDesignPermissions,
        private readonly cbDialog: CbDialogService,
        private readonly currentUser: CurrentUserService,
    ) {
    }

    public canEditSchemeSpecs(): boolean {
        return this.lotDesignPermissions.canEditSchemeSpecs();
    }

    public expandCollapseAll = (): void => {
        this.setAllExpanded();
        const newStatus = !this.allExpanded;
        this.specGroups.forEach((value) => {
            if (newStatus) {
                // set timeout to expand panels one after another - better for performance in this case
                setTimeout(() => {
                    this.expandedPanels[value.specGroupId] = newStatus;
                });
            } else {
                this.expandedPanels[value.specGroupId] = newStatus;
            }
        });
        this.allExpanded = newStatus;
    };

    public setAllExpanded = (specGroupItems = this.specGroups): void => {
        let expandedPanelsCount = 0;
        let panelCount = 0;
        specGroupItems.forEach((value) => {
            if (this.expandedPanels[value.specGroupId]) {
                expandedPanelsCount++;
            }
            panelCount++;
        });

        if (expandedPanelsCount === panelCount) {
            this.allExpanded = true;
        } else {
            this.allExpanded = false;
        }
    };

    private loadSpecGroups(): void {
        this.allSpecGroups.$promise.then(() => {
            this.specGroups = [];
            for (const specGroupId in this.lotSpec.allItems) {
                if (Object.prototype.hasOwnProperty.call(this.lotSpec.allItems, specGroupId)) {
                    const specGroup = this.allSpecGroups.find(x => x.id === Number(specGroupId));
                    if (!specGroup) {
                        continue;
                    }
                    if (specGroup.isQsOnly && !this.currentUserIsInQS()) {
                        continue;
                    }
                    const specGroupItems = this.lotSpec.allItems[specGroupId];
                    if (specGroupItems?.length > 0) {
                        this.hasItems = true;
                    }
                    this.specGroups.push({
                        specGroupId: specGroup.id,
                        label: `${specGroup.label} (${specGroupItems.length})`,
                        sortOrder: specGroup.sortOrder,
                        items: specGroupItems,
                    });
                }
            }
            this.specGroups = orderBy(this.specGroups, 'sortOrder');
        });
    }

    public editLotSpecItem(specItem: ILotSpecItemMappedItem): void {
        this.cbDialog.open(
            EditLotSpecItemDialogComponent,
            {
                minWidth: '40%',
                data: {
                    locked: this.lotSpec.isLocked,
                    lotSpec: this.lotSpec,
                    mappedItem: specItem.$clone(),
                    removeOwnersCareCostType: false,
                }
            }
        );
    }

    public editColourItem(colourItem: ILotSpecColourItemMappedItem): void {
        this.cbDialog.open(
            EditLotSpecColourItemDialogComponent,
            {
                minWidth: '45%',
                data: {
                    locked: this.lotSpec.isLocked,
                    mappedItem: colourItem.$clone(),
                    lotSpec: this.lotSpec,
                }
            }
        );
    }

    public addLotSpecItem(specGroupId: number): void {
        this.cbDialog
            .open(
                EditLotSpecItemDialogComponent,
                {
                    minWidth: '40%',
                    data: {
                        lotSpec: this.lotSpec,
                        removeOwnersCareCostType: !this.lot.hasClientSale,
                        lockedSpecGroupId: specGroupId,
                    }
                }
            )
            .afterClosed()
            .subOnce((result: ILotSpecItemDto | false) => {
                if (result) {
                    this.lotSpec.items.push(result);
                    this.lotSpec.$recomputeProps();
                    this.loadSpecGroups();
                }
            });
    }

    public addLotSpecColourItem(specGroupId: number): void {
        this.cbDialog
            .open(
                EditLotSpecColourItemDialogComponent,
                {
                    minWidth: '40%',
                    data: {
                        lotSpec: this.lotSpec,
                        lockedSpecGroupId: specGroupId,
                    }
                }
            )
            .afterClosed()
            .subOnce((result: ILotSpecColourItemDto | false) => {
                if (result) {
                    this.lotSpec.colourItems.push(result);
                    this.lotSpec.$recomputeProps();
                    this.loadSpecGroups();
                }
            });
    }

    public removeLotSpecItem(specItem: ILotSpecItemMappedItem): void {
        this.cbDialog.confirm({
            dialogHeading: 'Remove Item from the Lot Specification',
            message: 'Are you sure you want to remove this item from the Lot Specification?',
            confirmed: () => {
                const blocker = this.cbDialog.block('Deleteing Item...');
                specItem.delete(specItem.versionNumber).subOnce(() => {
                    remove(this.lotSpec.items, (x) => x.id === specItem.id);
                    this.lotSpec.$recomputeProps();
                    this.loadSpecGroups();
                    blocker.close();
                });
            }
        });
    }

    public removeLotSpecColourItem(colourItem: ILotSpecColourItemMappedItem): void {
        this.cbDialog.confirm({
            dialogHeading: 'Remove Item from the Lot Specification',
            message: 'Are you sure you want to remove this item from the Lot Specification?',
            confirmed: () => {
                const blocker = this.cbDialog.block('Deleteing Item...');
                colourItem.delete(colourItem.versionNumber).subOnce(() => {
                    remove(this.lotSpec.colourItems, (x) => x.id === colourItem.id);
                    this.lotSpec.$recomputeProps();
                    this.loadSpecGroups();
                    blocker.close();
                });
            }
        });
    }

    public getQuantity(specItem: ILotSpecItemMappedItem): string | number {
        if (specItem.costType === COST_TYPE_ENUM.Provisional ||
            specItem.costType === COST_TYPE_ENUM.DescriptiveOnly) {
            return '-';
        } else {
            return specItem.quantity;
        }
    }

    public showAddButton = (): boolean => {
        return this.currentUser.isDesignTeam();
    };

    public isQs = (team): boolean => {
        return team === TEAM_CODES_CONST.QsTeamKey;
    };

    private currentUserIsInQS = (): boolean => {
        return find(this.currentUser.teams, this.isQs) === TEAM_CODES_CONST.QsTeamKey;
    };

    public isBundle(offeringType: ProductTypeEnumId): boolean {
        return offeringType === PRODUCT_TYPE_ENUM.Bundle;
    }

    public generateReport(): void {
        this.lotLogic.generateLotSpecReport(this.mappedItem.lotId, this.mappedItem.lotSpecVersion).subOnce();
    }
}
