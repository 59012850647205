<cb-lot-consents-action-bar class="top-panel"
                            class="cb-margin-left cb-margin-top flex"
                            [lotDto]="lotDto"
                            [hasConsents]="hasConsents"
                            (itemAdded)="addConsent($event)"></cb-lot-consents-action-bar>
<div class="cb-margin-left cb-margin-top ">
    <div class="cb-lot-consent-scroller">
        <div class="flex-wrap">
            <cb-lot-consent-list-card class="consent-list-item flex-row md-33 lg-33 sm-50 xl-20 xs-100"
                                      *ngFor="let item of lotConsents"
                                      [consent]="item"></cb-lot-consent-list-card>
        </div>
    </div>
</div>
