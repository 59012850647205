<button mat-raised-button
        color="primary"
        class="cb-margin-left cb-margin-right"
        ngClass.lt-xl="cb-margin-top"
        [matMenuTriggerFor]="reportMenu">
    <mat-icon>arrow_drop_down</mat-icon>
    &nbsp;Reports
</button>
<mat-menu #reportMenu="matMenu">
    <button mat-menu-item
            (click)="generateJobBuildReport()">
        Job Build Report
    </button>
</mat-menu>
