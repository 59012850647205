import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QSTeamPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { LotsLogicService } from '@app/logic/lots';
import { ITakeoffTaskMappedItem } from '@app/logic/takeoff-tasks/interfaces/i.takeoff-task-mapped';
import { TakeoffTasksLogicService } from '@app/logic/takeoff-tasks/takeoff-tasks-logic-service';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { TAKE_OFF_TASK_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, combineLatest, map, shareReplay, skipWhile, Subscription, switchMap, take } from 'rxjs';
import { TakeoffTaskDialogComponent } from '../takeoff-task-dialog/takeoff-task-dialog.component';

@Component({
    selector: 'cb-takeoff-tasks-search',
    templateUrl: './takeoff-tasks-search.component.html',
    styleUrls: ['./takeoff-tasks-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeoffTasksSearchComponent implements OnDestroy {

    private _subscriptions = new Subscription();
    public lotId$$ = new BehaviorSubject<number>(1);

    @Input() public set lotId(value: number) {
        this.lotId$$.next(value);
    };

    @Output() public reloadLotMappedItem = new EventEmitter();

    public lot$ = this.lotId$$.pipe(
        switchMap(lotId => this.lotsLogicService.$getMappedItem(lotId)),
        shareReplay(1),
        take(1)
    );

    public results$ = this.lotId$$.pipe(
        skipWhile(lotId => !lotId),
        switchMap(lotId => this.logicService.getMappedTakeOffTasksForLot(lotId)),
        shareReplay(1),
        take(1)
    );

    public canCreateTakeOffTask$ = combineLatest([
        this.lot$,
        this.results$,
    ]).pipe(
        map(([lot, results]) => {
            return lot?.lotSpecLocked
                && !results?.some(a =>
                    a.statusId === TAKE_OFF_TASK_STATUS_ENUM.InProgress
                    || a.statusId === TAKE_OFF_TASK_STATUS_ENUM.InQueue
                    || a.statusId === TAKE_OFF_TASK_STATUS_ENUM.OnHold);
        })
    );

    public selectedMappedItem$$: BehaviorSubject<ITakeoffTaskMappedItem> = new BehaviorSubject(null);
    public get selectedMappedItem(): ITakeoffTaskMappedItem {
        return this.selectedMappedItem$$?.value;
    }

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: TakeoffTasksLogicService,
        private readonly lotsLogicService: LotsLogicService,
        private readonly toastService: ToastService,
        public cdRef: ChangeDetectorRef,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
        private readonly dialogService: CbDialogService,
        private readonly qsTeamPermissions: QSTeamPermissions,
        private readonly navigationService: NavigationService
    ) {

    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public reloadLotMappedItemOnParent(): void {
        this.reloadLotMappedItem.emit();
    }

    public viewTakeOffTask(mappedItem: ITakeoffTaskMappedItem): void {
        this.selectedMappedItem$$.next(mappedItem);
    }

    public handleGoBack(): void {
        this.selectedMappedItem$$.next(null);
    }

    public createTakeoff(): any {
        return this.dialogService
            .open(
                TakeoffTaskDialogComponent,
                {
                    data: {
                        mappedItem: this.logicService.$createMappedItem(),
                        mode: FormMode.Add,
                        lotId: this.lotId$$.value
                    },
                }
            ).afterClosed().subOnce(result => {
                if (result) {
                    this.results$ = this.logicService.getMappedTakeOffTasksForLot(this.lotId$$.value);
                    this.cdRef.detectChanges();
                }
            });
    }

    public viewLot(lotId: number): void {
        if (lotId) {
            this.navigationService.navigate([`/lots/${lotId}/summary`]);
        }
    }
}
