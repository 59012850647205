import {
    DOCUMENT_STATUS_ENUM,
    DocumentStatusEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';

import { DatePipe } from '@angular/common';
import { NZ_LOCALE } from '@app/shared/declarations/app.constants';

export function getDocumentUploadLabel(date: string | Date, documentStatus: DocumentStatusEnumId): string {
    if (!date) {
        return '-';
    }
    date = new DatePipe(NZ_LOCALE).transform(date);
    if (documentStatus === DOCUMENT_STATUS_ENUM.Rejected || documentStatus === DOCUMENT_STATUS_ENUM.UploadedRequiresReview) {
        return `${date} - ${DOCUMENT_STATUS_ENUM[documentStatus]}`;
    }
    return date;
}
