<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="pinnedPanels[silentDataKey]"
                     #classicCareExpansionPanel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">phone</mat-icon>
            <b>Classic Care</b>
        </mat-panel-title>
        <mat-panel-description class="end-center flex-row">
            <button *ngIf="classicCareExpansionPanel.expanded || silentData[silentDataKey]"
                    mat-icon-button
                    (click)="togglePanelPinned($event, silentDataKey)">
                <mat-icon svgIcon="pin"
                          [ngClass]="{'cb-accent' : silentData[silentDataKey]}">
                </mat-icon>
            </button>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex-col">
        <cb-display-value-new class="flex"
                              *ngIf="contactField.isVisible"
                              label="Contact">
            {{lotMappedItem?.clientSale?.classicCareContact?.name | cbDasher}}
        </cb-display-value-new>
        <div class="flex-row">
            <cb-display-value-new class="flex"
                                  *ngIf="contactMethodField.isVisible"
                                  label="Contact Method">
                {{CONTACT_METHOD_ENUM[lotMappedItem?.clientSale?.classicCareContact?.preferredContact]}}
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  *ngIf="contactDetailField.isVisible"
                                  label="Contact Detail">
                {{lotMappedItem?.clientSale?.classicCareContact?.preferredContactDetails | cbDasher}}
            </cb-display-value-new>
        </div>
    </div>
</mat-expansion-panel>
