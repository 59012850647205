<mat-card class="cb-margin flex-col flex">
    <mat-tab-group class="flex">
        <mat-tab label="Catalogue Items">
            <ng-template matTabContent>
                <cb-product-catalogue-items-tab></cb-product-catalogue-items-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Rates">
            <ng-template matTabContent>
                <cb-product-rates-tab></cb-product-rates-tab>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</mat-card>
