<cb-dialog>
    <cb-loading-spinner *ngIf="!(locationsLoaded && supplyTypesLoaded); else elseBlock"
                        message="Please wait, loading.."
                        class="cb-margin">
    </cb-loading-spinner>
    <ng-template #elseBlock>
        <form class="flex-col flex"
              #defaultSupplierForm="ngForm">
            <div *ngIf="!invalidSupplier">
                <cb-display-value-new *ngIf="isEdit()"
                                      label="Supply Type"
                                      [value]="mappedItem?.supplyType?.label">
                </cb-display-value-new>
                <cb-select *ngIf="isAdd()"
                           [required]="true"
                           name="selectedSupplyTypeId"
                           label="Supply Type"
                           [(ngModel)]="selectedSupplyTypeId"
                           (change)="selectedSupplyTypeChanged()"
                           [options]="availableSupplyTypes">
                </cb-select>
                <cb-business-accounts-input-find-cards label="Business Account"
                                                       [(ngModel)]="selectedSupplier"
                                                       name="selectedSupplier"
                                                       [text]="selectedSupplier?.tradingName"
                                                       [disabled]="!selectedSupplyTypeId"
                                                       [required]="true"
                                                       [searchParams]="businessAccountSearchParams"
                                                       (change)="supplierChanged()">
                </cb-business-accounts-input-find-cards>
            </div>
        </form>
        <div class="flex-row"
             *ngIf="invalidSupplier">
            <mat-card class="cb-error-card">
                <mat-card-content>
                    <p>Unable to link a supply type because this Business Entity is not linked to any location.</p>
                    <p>Please visit <b>Settings</b> > <b>Locations</b> to configure a location for this Business Entity.
                    </p>
                </mat-card-content>
            </mat-card>
        </div>
        <footer class="flex-row flex">
            <span class="flex"></span>
            <button mat-raised-button
                    color="primary"
                    (click)="save()"
                    [disabled]="defaultSupplierForm.invalid || defaultSupplierForm.pristine || invalidSupplier">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel()">
                Cancel
            </button>
        </footer>
    </ng-template>
</cb-dialog>
