import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import {
    NonWorkingDayMappedItem, INonWorkingDayDto, INonWorkingDayMappedItem,
    INonWorkingDayLogicService, NonWorkingDayLogicService, INonWorkingDayLocationDto
} from '@app/logic/non-working-day';
import { filter, map } from 'lodash';

interface IData {
    mappedItem: NonWorkingDayMappedItem;
}

@Component({
    selector: 'cb-non-working-day-location-dialog',
    templateUrl: './non-working-day-location-dialog.component.html',
    styleUrls: ['./non-working-day-location-dialog.component.scss']
})
export class NonWorkingDayLocationDialogComponent extends BaseDialogFormViewDirective<INonWorkingDayDto, INonWorkingDayMappedItem, INonWorkingDayLogicService> {
    public static readonly MIN_WIDTH = '50%';
    public readonly displayedColumns: string[] = ['region', 'isActive', 'businessEntity', 'addressRegion', 'select'];
    public mappedItem: NonWorkingDayMappedItem;
    public dataSource: any[];
    public showActiveOnly = true;

    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<NonWorkingDayLocationDialogComponent>,
        protected readonly nonWorkingDayLogicService: NonWorkingDayLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.getDataSource();
    }

    public getDataSource(): void {
        this.nonWorkingDayLogicService.getNonWorkingDayLocations(this.mappedItem.id, this.showActiveOnly).subOnce((result: INonWorkingDayLocationDto[]) => {
            this.dataSource = result;
        });
    }

    public onSaveClicked(): void {
        const regionIds = map(filter(this.dataSource, { isSelected: true }), 'id') as number[];
        this.nonWorkingDayLogicService.updateRegions(this.mappedItem.id, regionIds).subOnce((result) => {
            if (result) {
                this.toastService.showToast('Regions updated successfully');
                this.dialogRef.close();
            } else {
                throw new Error('Regions update failed');
            }
        });
    }
}
