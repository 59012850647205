import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import * as _ from 'lodash';

@Component({
    selector: 'cb-drag-and-drop-document',
    templateUrl: './drag-and-drop-document.component.html',
    styleUrls: ['./drag-and-drop-document.component.scss']
})
export class DragAndDropDocumentComponent implements OnInit {
    public static extensionRegularExpresson = /(?:\.([^.]+))?$/;

    @Input() public documentEntityMappedItem: IDocumentEntityMappedItem;
    @Input() public showExistingDocumentInfo = false;

    @Output() public documentChange = new EventEmitter();

    public isFilesizeValid = true;
    public isFileExtensionValid = true;

    public fileSize: number;
    public fileSizeFormatted: string;
    public fileExtension: string;

    public isFileValidAndUploaded = false;

    public ngOnInit(): void {
        if (this.showExistingDocumentInfo && this.documentEntityMappedItem?.document?.id > 0) {
            this.isFileValidAndUploaded = true;
        }
    }

    public uploadFile(uploadedFile: File): void {
        this.clearOldFileReference();
        this.checkFileSize(uploadedFile[0]);
        this.checkFileExtension(uploadedFile[0]);
        this.setDocument(uploadedFile[0]);
    }

    public checkFileSize(uploadedFile: File): void {
        if (uploadedFile) {
            this.fileSize = (uploadedFile.size / 1024 / 1024);
            this.fileSizeFormatted = `${this.fileSize.toFixed(2)}MB`;
            if (this.documentEntityMappedItem?.documentType?.maximumFileSizeInMegaBytes > this.fileSize) {
                this.isFilesizeValid = true;
            } else if (this.documentEntityMappedItem?.documentType) {
                this.isFilesizeValid = false;
                this.isFileValidAndUploaded = false;
            }

        }
    }

    private checkFileExtension(uploadedFile: File): void {
        this.fileExtension = DragAndDropDocumentComponent.extensionRegularExpresson.exec(uploadedFile.name)[1];
        if (this.fileExtension && this.documentEntityMappedItem.documentType &&
            this.documentEntityMappedItem.documentType.fileTypes) {
            this.fileExtension = this.fileExtension.toLowerCase();
            if (_.includes(this.documentEntityMappedItem.documentType.fileTypes, this.fileExtension)) {
                this.isFileExtensionValid = true;
            } else {
                this.isFileExtensionValid = false;
                this.isFileValidAndUploaded = false;
            }
        }
    }

    private setDocument(uploadedFile: File): void {
        if (this.isFilesizeValid && this.isFileExtensionValid) {
            this.documentEntityMappedItem.document.name = uploadedFile.name;
            this.documentEntityMappedItem.document.uploadedFile = uploadedFile;
            this.isFileValidAndUploaded = true;
            this.documentChange.emit();
        }
    }

    private clearOldFileReference(): void {
        if (this.documentEntityMappedItem?.document?.name) {
            this.documentEntityMappedItem.document.name = undefined;
        }

        if (this.documentEntityMappedItem?.document?.uploadedFile) {
            this.documentEntityMappedItem.document.uploadedFile = undefined;
        }
    }
}
