<div class="flex-col"
     [ngStyle]="{'width': collapsed$.value ? '40px' : '280px'}"
     *ngIf="activeComponent$ | async">
    <mat-card class="collapsible-card flex-col"
              *cbWaitFor="mappedItem$ | async">
        <button mat-mini-fab
                color="accent"
                class="collapse-button"
                (click)="collapsed$.next(!collapsed$.value)"
                [matTooltip]="collapsed$.value ? 'Expand' : 'Collapse'">
            <mat-icon>
                {{ collapsed$.value ? 'chevron_left' : 'chevron_right '}}
            </mat-icon>
        </button>
        <div class="flex-row"
             *ngIf="(collapsed$ | async)">
            <h1 class="mat-title collapsed-title">User Information</h1>
        </div>
        <div class="flex-col flex"
             *ngIf="!(collapsed$ | async)">
            <ng-container *ngIf="!(activeComponent.isAdd$ | async) && mappedItem">
                <h4 class="mat-title">{{mappedItem.firstName}} {{mappedItem.lastName}}</h4>
                <div style="position: relative"
                     class="cb-margin-bottom-2x">
                    <mat-divider></mat-divider>
                </div>
                <h4 class="mat-subheading-1 cb-margin-bottom-nil">{{mappedItem.jobTitle}}</h4>
                <mat-checkbox class="cb-grey read-only cb-margin-bottom"
                              [checked]="isUserActive$.value"
                              [disabled]="true">
                    Active
                </mat-checkbox>
            </ng-container>
            <cb-button class="cb-margin-bottom flex-col"
                       *ngIf="(activeComponent.isView$ | async)"
                       [disabled]="isReportDisabled$ | async"
                       (clicked)="generateFullUserDetailReport()">
                User Security Report
            </cb-button>
            <cb-button class="cb-margin-bottom flex-col"
                       *ngIf="(activeComponent.isView$ | async) && canEditUsers"
                       (clicked)="editItem()">
                Edit
            </cb-button>
            <cb-button class="cb-margin-bottom flex-col"
                       *ngIf="activeComponent.isEditOrAdd$ | async"
                       [disabled]="isSaveDisabled$ | async"
                       (clicked)="save()">
                Save
            </cb-button>
            <cb-button class="cb-margin-bottom flex-col"
                       *ngIf="activeComponent.isEditOrAdd$ | async"
                       (clicked)="cancel()">
                Cancel
            </cb-button>
        </div>
    </mat-card>
</div>
