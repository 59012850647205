import {
    BasePermissions,
    IBaseCouncilRfiPermissions,
    IBaseSystemAreaPermissions,
    IDocumentPermissions,
    IhaveNotePermissions,
    IhaveTaskPermissions,
    INotePermissions,
    ITaskPermissions
} from './base.permissions';
import {CurrentUserService} from '../authentication/current.user';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

export interface ICouncilRfiPermissions
    extends IBaseCouncilRfiPermissions, IBaseSystemAreaPermissions, IhaveNotePermissions, IhaveTaskPermissions {
    getDocumentPermissions(): IDocumentPermissions;
    getTaskPermissions(): ITaskPermissions;
}

@Injectable()
export class CouncilRfiPermissions extends BasePermissions<{}> implements ICouncilRfiPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router
    ) {
        // permissions not implemented for councilRfi -  using 'lot' and empty enum
        super('lot', {}, currentUser, http, router);
    }

    public canView(): boolean {
        return true;
    }

    public canCreate(): boolean {
        return true;
    }

    public canEdit(): boolean {
        return true;
    }

    public getNotePermissions(): INotePermissions {
        throw new Error('Method not implemented.');
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => true,
            canEditDocuments: () => true,
            canReviewDocuments: () => true,
            canUploadDocument: () => true,

            canViewDocuments: () => true
        };
    }

    public getTaskPermissions(): ITaskPermissions {
        return {
            canAddTask: () => true,
            canCompleteTask: () => true,
            canEditTask: () => true,
            canViewTask: () => true
        };
    }

}
