import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IHistoryDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    history: IHistoryDto;
}

@Component({
    selector: 'cb-history-view-dialog',
    templateUrl: './history-view-dialog.component.html',
    styleUrls: ['./history-view-dialog.component.scss'],
})
export class HistoryViewDialogComponent implements OnInit {

    public details: string[];

    constructor(
        public readonly dialogRef: MatDialogRef<HistoryViewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) { }

    public ngOnInit(): void {
        const historyDetails = this.data.history.details;

        if (historyDetails != null) {
            this.details = typeof historyDetails === 'string'
                ? JSON.parse(historyDetails)
                : historyDetails;
        }
    }

}
