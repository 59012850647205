export enum DesignTeamPermissionEnum {
    None = 0x00,
    CanView = 0x01 << 1,
    CanSeeAll = 0x01 << 2,
    CanEditScheme = 0x01 << 3,
    CanEditWorkingDrawing = 0x01 << 4,
    CanEditRfi = 0x01 << 5,
    CanViewDesignTeamReports = 0x01 << 6,
    CanRejectPreConsentPlan = 0x01 << 7,
    CanRejectWorkingDrawingRequest = 0x01 << 8,
    CanReviewPreConsentPlan = 0x01 << 9
}
