import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LotDesignPermissions} from '@app/core/permissions';
import {IDocumentPermissions} from '@app/core/permissions/base.permissions';
import {DOCUMENT_GROUP_CODES_CONST, DocumentGroupCodesConstId, SYSTEM_AREA_ENUM, SystemAreaEnumId} from '@classictechsolutions/hubapi-transpiled-enums';
import {ILotMappedItem} from '@app/logic/lots';
import {IDesignRequirementsMappedItem} from '@app/logic/design-requirements/interfaces/i.design-requirements.mapped';
import {DesignRequirementsLogicService} from '@app/logic/design-requirements';

@Component({
    selector: 'cb-design-requirements',
    templateUrl: './design-requirements.component.html',
    styleUrls: ['./design-requirements.component.scss']
})
export class DesignRequirementsComponent implements OnInit {

    public mappedItem$$: BehaviorSubject<IDesignRequirementsMappedItem> = new BehaviorSubject(null);
    public set mappedItem(mappedItem: IDesignRequirementsMappedItem) {
        this.mappedItem$$.next(mappedItem);
    }

    public get mappedItem(): IDesignRequirementsMappedItem {
        return this.mappedItem$$.value;
    }

    public systemArea: SystemAreaEnumId = SYSTEM_AREA_ENUM.DesignRequirements;
    public documentStubGroupCode: DocumentGroupCodesConstId = DOCUMENT_GROUP_CODES_CONST.DESIGN_INFO;

    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public lotMappedItemChange = new EventEmitter<ILotMappedItem>();
    public get documentPermissions(): IDocumentPermissions {
        return this.lotDesignPermissions.getPreconsentDocumentPermissions();
    }

    constructor(
        private readonly lotDesignPermissions: LotDesignPermissions,
        private readonly designRequirementsLogicService: DesignRequirementsLogicService
    ) {}

    public ngOnInit(): void {
        this.designRequirementsLogicService.$getMappedItem(this.lotMappedItem.id).subOnce(result => {
            this.mappedItem$$.next(result);
        });
    }

}
