import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LotCostPermissions} from '@app/core/permissions';
import {IPricingRevisionMappedItem} from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import {PricingRevisionsLogicService} from '@app/logic/pricing-revisions/pricing-revisions.logic.service';
import {PRICING_REVISION_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-pricing-revision-card',
    templateUrl: './pricing-revision-card.component.html',
    styleUrls: ['./pricing-revision-card.component.scss']
})
export class PricingRevisionCardComponent {

    public PRICING_REVISION_STATUS_ENUM = PRICING_REVISION_STATUS_ENUM;

    @Input() public mappedItem: IPricingRevisionMappedItem;
    @Output() public readonly viewClick = new EventEmitter<IPricingRevisionMappedItem>();

    constructor(
        public readonly logicService: PricingRevisionsLogicService,
        private readonly lotCostPermissions: LotCostPermissions
    ) { }

    public getStatusIconIcon(): string {
        switch (this.mappedItem.statusId) {
            case PRICING_REVISION_STATUS_ENUM.Accepted:
            case PRICING_REVISION_STATUS_ENUM.Completed:
            case PRICING_REVISION_STATUS_ENUM.Estimated:
                return 'check_circle';
            case PRICING_REVISION_STATUS_ENUM.InProgress:
                return 'access_time';
            case PRICING_REVISION_STATUS_ENUM.WaitingForReview:
            case PRICING_REVISION_STATUS_ENUM.OnHold:
                return 'timelapse';
            case PRICING_REVISION_STATUS_ENUM.InQueue:
                return 'assignment';
            case PRICING_REVISION_STATUS_ENUM.Declined:
                return 'cancel';
            case PRICING_REVISION_STATUS_ENUM.Cancelled:
                return 'indeterminate_check_box';
            default:
                return 'assignment';
        }
    }

    public getStatusIconClass(): string {
        switch (this.mappedItem.statusId) {
            case PRICING_REVISION_STATUS_ENUM.Accepted:
            case PRICING_REVISION_STATUS_ENUM.Completed:
                return 'mat-primary';
            case PRICING_REVISION_STATUS_ENUM.InProgress:
            case PRICING_REVISION_STATUS_ENUM.Estimated:
                return 'mat-accent';
            case PRICING_REVISION_STATUS_ENUM.InQueue:
            case PRICING_REVISION_STATUS_ENUM.WaitingForReview:
                return 'mat-accent';
            case PRICING_REVISION_STATUS_ENUM.OnHold:
                return 'mat-primary';
            case PRICING_REVISION_STATUS_ENUM.Declined:
                return 'mat-warn';
            case PRICING_REVISION_STATUS_ENUM.Cancelled:
                return 'mat-warn';
            default:
                return 'assignment';
        }
    }

    public getStatusIconTitle(): string {
        switch (this.mappedItem.statusId) {
            case PRICING_REVISION_STATUS_ENUM.Accepted:
                return 'Accepted';
            case PRICING_REVISION_STATUS_ENUM.Completed:
                return 'Completed';
            case PRICING_REVISION_STATUS_ENUM.Estimated:
                return 'Estimated';
            case PRICING_REVISION_STATUS_ENUM.InQueue:
                return 'In Queue';
            case PRICING_REVISION_STATUS_ENUM.InProgress:
                return 'In Progress';
            case PRICING_REVISION_STATUS_ENUM.WaitingForReview:
                return 'Waiting for Review';
            case PRICING_REVISION_STATUS_ENUM.OnHold:
                return 'On Hold';
            case PRICING_REVISION_STATUS_ENUM.Declined:
                return 'Declined';
            case PRICING_REVISION_STATUS_ENUM.Cancelled:
                return 'Cancelled';
            default:
                return 'assignment';
        }
    }

    public viewPricingRevision(): void {
        this.viewClick.emit(this.mappedItem);
    }

    public get canViewPricingRevision(): boolean {
        return this.lotCostPermissions.canViewPricingRevision();
    }
}
