import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { Observable } from 'rxjs';
import { IARProgressPaymentsMappedItem } from '@app/logic/ar-progress-payments/interfaces/i.ar.progress.payments.mapped';
import { IARProgressPaymentsLogicService } from '@app/logic/ar-progress-payments/interfaces/i.ar.progress.payments.logic-service';
import { ARProgressPaymentsMappedItem } from '@app/logic/ar-progress-payments/ar-progress-payments.mapped';
import { IARPaymentScheduleDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/ARPaymentScheduleDto';
import { ILotProgressPaymentHistoryDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class ARProgressPaymentsLogicService
    extends BaseLogicService<IARPaymentScheduleDto, IARProgressPaymentsMappedItem>
    implements IARProgressPaymentsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'progresspayments',
            ARProgressPaymentsMappedItem
        );
    }

    public saveProgressPayments(lotId: number, dto: IARPaymentScheduleDto): Observable<IARPaymentScheduleDto> {
        return this.$http.post<IARPaymentScheduleDto>(`${this.$baseUri}/${lotId}/update`, dto);
    }

    public getProgressPaymentHistory(lotId: number): Observable<ILotProgressPaymentHistoryDto[]>{
        return this.$http.get<ILotProgressPaymentHistoryDto[]>(`${this.$baseUri}/${lotId}/history`);
    }
}
