<mat-card class="cb-margin flex-col flex">
    <cb-table class="flex"
              *cbWaitFor="isInitialised"
              [loaded]="true">
        <thead cbTableHead>
            <tr>
                <td>ABM Code</td>
                <td>Trading Name</td>
                <td>Legal Name</td>
                <td>Supplier</td>
                <td>Installer</td>
                <td>Status</td>
                <td></td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr *ngFor="let account of accounts">
                <td>{{account.abmCode}}</td>
                <td>{{account.tradingName}}</td>
                <td>{{account.businessName}}</td>
                <td>
                    <mat-icon class="cb-grey">
                        {{account.isSupplier ? 'check_box' : 'check_box_outline_blank'}}
                    </mat-icon>
                </td>
                <td>
                    <mat-icon class="cb-grey">
                        {{account.isInstaller ? 'check_box' : 'check_box_outline_blank'}}
                    </mat-icon>
                </td>
                <cb-td-enum [enum]="BUSINESS_ACCOUNT_STATUS_ENUM"
                            [value]="account.status">
                </cb-td-enum>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="onViewAccountClicked(account)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</mat-card>
