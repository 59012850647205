export enum DesignImageType {
    /// <summary>
    /// An image intended to be used to show the exterior.
    /// </summary>
    Exterior = 1,

    /// <summary>
    /// An image intended to be used to show the interior floor plan.
    /// </summary>
    FloorPlan = 2
}
