import { Component, OnInit, Inject } from '@angular/core';
import { IPurchaseOrderMappedItem } from '@app/logic/purchase-orders';
import { PURCHASE_ORDER_STATUS_ENUM, ManualPurchaseOrderOriginEnumId, COST_NATURE_ENUM, USER_TAG_CONSTANTS_CONST, ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPurchaseOrderDto } from '@app/logic/purchase-orders/interfaces/i.purchase-order.dto';
import { IPurchaseOrdersLogicService } from '@app/logic/purchase-orders/interfaces/i.purchase-orders.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { IPurchaseOrderLineMappedItem } from '@app/logic/purchase-orders/interfaces/i.purchase-order-line.mapped';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { IHaveManualPurchaseOrdersPermissions } from '@app/core/permissions/base.permissions';
import { EditManualOrderDialogComponent } from '../edit-manual-order-dialog/edit-manual-order-dialog.component';
import { EditManualOrderLineDialogComponent } from '../edit-manual-order-line-dialog/edit-manual-order-line-dialog.component';
import {
    ReviewManualOrderDialogComponent
} from '../review-manual-order-dialog/review-manual-order-dialog.component';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { SelectVariationDialogComponent } from '../select-variation/select-variation.component';

interface IData {
    mappedItem: IPurchaseOrderMappedItem;
    origin: ManualPurchaseOrderOriginEnumId;
    lotDto: ILotDto;
    permissions: IHaveManualPurchaseOrdersPermissions;
    downloadOnly: boolean;
    isTransformedToManualOrder: boolean;
}

@Component({
    selector: 'cb-view-manual-order-dialog',
    templateUrl: './view-manual-order-dialog.component.html',
    styleUrls: ['./view-manual-order-dialog.component.scss'],
})
export class ViewManualOrderDialogComponent extends BaseDialogFormViewDirective<IPurchaseOrderDto, IPurchaseOrderMappedItem, IPurchaseOrdersLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '66%';

    public readonly PURCHASE_ORDER_STATUS_ENUM = PURCHASE_ORDER_STATUS_ENUM;

    public readonly COST_NATURE_ENUM = COST_NATURE_ENUM;

    public get mappedItem(): IPurchaseOrderMappedItem {
        return this.data.mappedItem;
    }
    public get origin(): ManualPurchaseOrderOriginEnumId {
        return this.data.origin;
    }
    public get lot(): ILotDto {
        return this.data.lotDto;
    }
    public get permissions(): IHaveManualPurchaseOrdersPermissions {
        return this.data.permissions;
    }

    public get isExtraToClient(): boolean {
        return this.data.mappedItem.costNature === COST_NATURE_ENUM.ExtraToClient;
    }

    public get isOrderExtraToSupplier(): boolean {
        return this.mappedItem.costNature === COST_NATURE_ENUM.ExtraToSupplier;
    }

    public canManage = new ComputedProperty(() => {
        return !this.data?.downloadOnly
            && this.mappedItem.statusId === PURCHASE_ORDER_STATUS_ENUM.Draft
            && this.permissions.getManualPurchaseOrderPermissions().canManageManualOrder();
    });

    public canSubmit = new ComputedProperty(() => {
        return !this.data?.downloadOnly
            && this.mappedItem.statusId === PURCHASE_ORDER_STATUS_ENUM.Draft
            && this.mappedItem.purchaseOrderLines
            && this.mappedItem.purchaseOrderLines.length > 0
            && this.mappedItem.buildProgrammeActivity.duration >= 1
            && this.permissions.getManualPurchaseOrderPermissions().canSubmitManualOrder();
    });

    public canReview = new ComputedProperty(() => {
        return !this.data?.downloadOnly
            && this.mappedItem.statusId === PURCHASE_ORDER_STATUS_ENUM.SubmittedForApproval
            && this.permissions.getManualPurchaseOrderPermissions().canApproveManualOrder()
            && this.currentUser.hasTag(USER_TAG_CONSTANTS_CONST.SENIOR_CONSTRUCTION_MANAGER);
    });

    public canUnsubmit = new ComputedProperty(() => {
        return !this.data?.downloadOnly
            && this.mappedItem.statusId === PURCHASE_ORDER_STATUS_ENUM.SubmittedForApproval
            && this.permissions.getManualPurchaseOrderPermissions().canSubmitManualOrder();
    });

    public showPendingSince = new ComputedProperty(() => {
        return this.mappedItem.statusId === PURCHASE_ORDER_STATUS_ENUM.SubmittedForApproval;
    });

    constructor(
        public readonly dialogRef: MatDialogRef<ViewManualOrderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CurrentUserService) public readonly currentUser: CurrentUserService,
    ) {
        super(dialogRef, toastService);
    }

    private readonly recomputeProps = (): void => {
        this.canManage.recompute();
        this.canSubmit.recompute();
        this.canUnsubmit.recompute();
        this.showPendingSince.recompute();
        this.canReview.recompute();
    };

    public ngOnInit(): void {
        this.mappedItem
            .$reload()
            .subOnce({ next: this.recomputeProps });
    }

    public edit(): void {
        if (!this.canManage.value) {
            return;
        }
        this.cbDialog.open(
            EditManualOrderDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem.$clone(),
                    regionId: this.lot.location?.region?.id,
                    lotDto: this.lot,
                },
                minWidth: '33%',
            }
        )
            .afterClosed()
            .subOnce({
                next: this.recomputeProps
            });
    }

    public submit(): void {
        if (!this.canSubmit.value) {
            return;
        }
        const extraMessage = this.mappedItem.orderTotalCalc() === 0 ? 'The total of this Order is $0.00.' : '';
        // Confirm Submit
        this.cbDialog.confirm({
            message: `${extraMessage} Are you sure you want to submit?`,
            confirmed: () => {
                this.mappedItem.submit().subOnce({ next: this.recomputeProps });
            }
        });
    }

    public unsubmit(): void {
        if (!this.canUnsubmit.value) {
            return;
        }
        // Confirm Unsubmit
        this.cbDialog.confirm({
            message: 'Are you sure you want to withdraw your submission?',
            confirmed: () => {
                this.mappedItem.unsubmit().subOnce({ next: this.recomputeProps });
            }
        });
    }

    public review(): void {
        if (!this.canReview.value) {
            return;
        }
        this.cbDialog.open(
            ReviewManualOrderDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem.$clone(),
                },
                minWidth: '40%',
            }
        )
            .afterClosed()
            .subOnce({
                next: this.recomputeProps
            });
    }

    public addLineItem(): void {
        if (!this.canManage.value) {
            return;
        }
        this.editLineItem(this.mappedItem.newLineItem({ gstApplies: true }));
    }

    public addNoteLine(): void {
        if (!this.canManage.value) {
            return;
        }
        this.editLineItem(this.mappedItem.newLineItem({ isNoteLine: true }));
    }

    public removeLineItem(lineItem: IPurchaseOrderLineMappedItem): void {
        if (!this.canManage.value) {
            return;
        }
        // Confirm Remove Line Item
        this.cbDialog.confirm({
            message: 'Are you sure you want to remove this line item?',
            confirmed: () => {
                lineItem.delete().subOnce({ next: this.recomputeProps });
            }
        });
    }

    public editLineItem(lineItem: IPurchaseOrderLineMappedItem): void {
        if (!this.canManage.value) {
            return;
        }
        this.cbDialog.open(
            EditManualOrderLineDialogComponent,
            {
                data: {
                    lot: this.lot,
                    lineItem: lineItem.$clone()
                },
                minWidth: '66%',
            }
        )
            .afterClosed()
            .subOnce({ next: this.recomputeProps });
    }


    public selectVariation(): void {
        this.mappedItem.getAvailableVariations().subOnce(_variations =>
            this.cbDialog.open(
                SelectVariationDialogComponent,
                {
                    data: {
                        variations: _variations,
                        mappedPurchaseOrder: this.mappedItem.$clone()
                    },
                    minWidth: '33%',
                }
            )
                .afterClosed()
                .subOnce({
                    next: (mappedPurchaseOrder: IPurchaseOrderDto) => {
                        this.mappedItem.variationId = mappedPurchaseOrder?.variationId;
                        this.mappedItem.variationNumber = mappedPurchaseOrder?.variationNumber;
                        this.mappedItem.variationAmount = mappedPurchaseOrder?.variationAmount;
                        this.recomputeProps();
                    }
                })
        );
    }

    public cancel(): void {
        return this.dialogRef.close(this.mappedItem);
    }

}
