<cb-dialog class="flex-col"
           dialogHeading="Edit Build Programme Activity">
    <mat-tab-group mat-stretch-tabs
                   class="cb-margin-bottom-2x">
        <mat-tab label="Activity Details">
            <form #editSupplierForm="ngForm"
                  class="cb-margin-top-2x supplierForm flex-row layoutGap-30"
                  *ngIf="data.canSetSupplier">
                <cb-business-account-autocomplete-scroller class="cb-margin-right flex"
                                                           label="Supplier"
                                                           name="supplierSearch"
                                                           [(ngModel)]="selectedBusinessAccount"
                                                           [required]="true"
                                                           [requireMatch]="true"
                                                           [matchProps]="['id']"
                                                           [disabled]="!buildProgrammeActivity?.rules?.canSetSupplier"
                                                           (optionSelected)="setSupplier()"
                                                           [status]="statusQuery"
                                                           [searchType]="searchTypeQuery">
                </cb-business-account-autocomplete-scroller>
                <cb-business-accounts-input-find-cards label="Supplier"
                                                       #businessAccountInput
                                                       [(ngModel)]="selectedBusinessAccount"
                                                       name="selectedSupplier"
                                                       [buttonOnly]="true"
                                                       [disabled]="!buildProgrammeActivity?.rules?.canSetSupplier"
                                                       (change)="setSupplier()"
                                                       [searchParams]="businessAccountFindParams">
                </cb-business-accounts-input-find-cards>
            </form>
            <form #editSsrForm="ngForm"
                  class="cb-margin-bottom-2x flex-row layoutGap-30">
                <div class="flex-col flex">
                    <cb-display-value-new label="Postal Address">
                        <div>
                            <div>{{mappedItem.businessAccountAddress.street | cbDasher}}</div>
                            <div>{{mappedItem.businessAccountAddress.suburb | cbDasher}}</div>
                            <div>{{mappedItem.businessAccountAddress.city | cbDasher}}</div>
                            <div>{{mappedItem.businessAccountAddress.regionName | cbDasher}}</div>
                            <div>{{mappedItem.businessAccountAddress.postCode | cbDasher}}</div>
                        </div>
                    </cb-display-value-new>
                    <cb-display-value-new label="SSR Email Addresses">
                        <div class="mat-body-1">
                            <p *ngIf="!(mappedItem.businessAccountContactOrderRolesEmails.length > 0)"> - </p>
                            <p *ngFor="let email of mappedItem.businessAccountContactOrderRolesEmails">
                                {{email}}
                            </p>
                        </div>
                    </cb-display-value-new>
                    <cb-text-area label="Comments"
                                  class="cb-margin-bottom"
                                  name="comments"
                                  [disabled]="!canEditComments()"
                                  [(ngModel)]="buildProgrammeActivity.ssrComment"
                                  [ngModelOptions]="{ standalone: true }"></cb-text-area>
                    <span *ngIf="mappedItem.valueRetainedPercentage > 0"
                          class="cb-margin-bottom start-center flex-row">
                        <mat-icon class="mat-accent">info_circle</mat-icon>
                        <span class="mat-body-1">&nbsp;This Activity has retained a value of
                            {{mappedItem.valueRetainedPercentage}}%</span>
                    </span>
                </div>
                <div class="flex-col flex">
                    <cb-display-value-new label="Activity"
                                          [value]="getActivityName()"></cb-display-value-new>
                    <span class="mat-body-1"
                          style="margin-bottom: 8px"
                          *ngIf="mappedItem.hasComplianceStatement">
                        <mat-icon class="mat-accent">info_circle</mat-icon>
                        &nbsp; {{mappedItem.complianceStatement}}
                    </span>
                    <cb-edit-build-programme-activity [buildProgrammeActivity]="buildProgrammeActivity">
                    </cb-edit-build-programme-activity>
                </div>
            </form>
            <div class="standardTable">
                <table>
                    <thead>
                        <tr>
                            <td>Cost Type</td>
                            <td>Code</td>
                            <td>Item</td>
                            <td>Supplier Quote Number</td>
                            <td>UOM</td>
                            <td>Lot No.</td>
                            <td style="min-width: 135px"
                                class="right-align">Unallocated Quantity</td>
                            <td class="right-align">Quantity</td>
                            <td class="right-align">Rate</td>
                            <td class="right-align">Subtotal</td>
                            <td class="actions"></td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let lineItem of mappedItem.lineItems">
                            <tr>
                                <td>{{lineItem.costTypeLabel | cbDasher}}</td>
                                <td>{{lineItem.code | cbDasher}}</td>
                                <td>
                                    {{lineItem.description | cbDasher}}
                                    <ul>
                                        <li *ngFor="let attribute of lineItem.attributes">
                                            {{attribute.attributeName}} : {{attribute.attributeValue}},
                                            {{attribute.note}}
                                        </li>
                                        <li *ngIf="lineItem.manualColour"> Manual Colour : {{lineItem.manualColour}}
                                        </li>
                                        <li *ngIf="lineItem.specItemNote"> Note : {{lineItem.specItemNote}}</li>
                                    </ul>
                                </td>
                                <td>{{lineItem.supplierQuoteNumber | cbDasher}}</td>
                                <td>{{lineItem.uom | cbDasher}}</td>
                                <td>{{lineItem.lotNumber | cbDasher}}</td>
                                <td class="right-align">{{lineItem.unallocatedQuantity}}</td>
                                <td class="right-align">{{lineItem.quantity}}</td>
                                <td class="right-align">{{lineItem.rate | cbCurrency}}</td>
                                <td class="right-align">{{lineItem.subtotal | cbCurrency}}</td>
                                <td class="actions right-align">
                                    <button class="cb-margin-right"
                                            mat-icon-button
                                            [disabled]="getEditDisabled(lineItem)"
                                            (click)="editLineItem(lineItem)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button
                                            [disabled]="getEditDisabled(lineItem)"
                                            (click)="removeLineItem(lineItem.id)">
                                        <mat-icon>remove_circle</mat-icon>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="lineItem.notes">
                                <td style="width: 30px">
                                    <mat-icon class="cb-small-icon">insert_drive_file</mat-icon>
                                </td>
                                <td></td>
                                <td>
                                    <span class="mat-body-1 preserve-line-breaks">{{lineItem.notes}}</span>
                                </td>
                                <td [attr.colspan]="this.data.lotType === LOT_TYPE_ENUM.Unit?8:7"></td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td [attr.colspan]="this.data.lotType === LOT_TYPE_ENUM.Unit?10:9"></td>
                            <td class="actions">
                                <button mat-icon-button
                                        [disabled]="getAddDisabled()"
                                        (click)="addLineItemsDialog()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td [attr.colspan]="this.data.lotType === LOT_TYPE_ENUM.Unit?10:9">
                                <div class="end-center flex-row">
                                    <span class="mat-body-2">
                                        Total: {{mappedItem.lineItemTotal() | cbCurrency}}
                                    </span>
                                </div>
                            </td>
                            <td class="actions">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <footer class="cb-margin-bottom end-center flex-row">
                <button mat-raised-button
                        color="accent"
                        *ngIf="canConvertToManualOrder"
                        [disabled]="saveInProgress || !buildProgrammeActivity?.rules?.canConvertToManualOrder"
                        (click)="convertToManualOrder()">Convert To Manual Order</button>
                <button mat-raised-button
                        color="accent"
                        *ngIf="canCompleteSSR"
                        [disabled]="saveInProgress || !buildProgrammeActivity?.rules?.canCompleteSsr"
                        (click)="setSsrCompleted()">Complete SSR</button>
                <button mat-raised-button
                        color="accent"
                        *ngIf="canConfirmSSR"
                        [disabled]="saveInProgress || !buildProgrammeActivity?.rules?.canConfirmSsr || !saveButton.disabled"
                        (click)="setSsrConfirmed()">Confirm SSR</button>
                <button mat-raised-button
                        color="primary"
                        #saveButton
                        [disabled]="saveInProgress || !editSsrForm.dirty || !editSsrForm.valid"
                        (click)="saveBuildProgrammeActivity(editSsrForm)">Save</button>
                <button mat-raised-button
                        (click)="cancel()">Close</button>
            </footer>
        </mat-tab>
        <mat-tab label="Activity Relationships">
            <cb-build-programme-activity-relationships class="tab-content-margin"
                                                       *ngIf="activityRelationships"
                                                       [buildProgrammeActivity]="buildProgrammeActivity"
                                                       [activityRelationships]="activityRelationships">
            </cb-build-programme-activity-relationships>
            <footer class="cb-margin-bottom end-center flex-row">
                <button mat-raised-button
                        (click)="cancel()">Close</button>
            </footer>
        </mat-tab>
    </mat-tab-group>
</cb-dialog>
