import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { LotsLogicService } from '@app/logic/lots';
import {
    COST_TYPE_ENUM,
    IAiRateResponseDto,
    IBuildActivitySummaryDto,
    IBuildStageDtoSummary,
    IQuantityAccountDto,
    ITakeOffCompareItemDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { forIn } from 'lodash';
import { map, Observable, Subscriber, Subscription } from 'rxjs';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { toCurrency } from '@app/shared/components/forms/currency/currency.util';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { AiRateLogicService } from '@app/logic/ai-rate/ai-rate-logic.service';
import { AiRateResponseDialogComponent } from '@app/shared/components/ai-rate/ai-rate-response-dialog/ai-rate-response-dialog.component';

@Component({
    selector: 'cb-lot-costs-confirmed-quantities',
    templateUrl: './lot-costs-confirmed-quantities.component.html',
    styleUrls: ['./lot-costs-confirmed-quantities.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotCostsConfirmedQuantitiesComponent implements OnDestroy, OnInit {

    private _subscriptions = new Subscription();
    public allExpanded = false;
    public quantityAccount$: Observable<IQuantityAccountDto>;
    public quantityAccount: IQuantityAccountDto;
    @Input() private readonly lotId: number;
    public expandedAreas: { [specGroupId: number]: boolean | null } = {};
    @Output() public readonly expandedAreasChange = new EventEmitter<{ [specGroupId: number]: boolean | null }>();
    public loading = true;

    constructor(
        private readonly logicService: LotsLogicService,
        private readonly aiLogicService: AiRateLogicService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly featureToggleStateService: FeatureToggleStatesService,
        private readonly cbDialog: CbDialogService,
    ) {

    }

    public ngOnInit(): void {
        if (!this.loading) {
            this.loading = true;
            this.cdRef.detectChanges();
        }
        this.quantityAccount$ = this.logicService.getQuantityAccount(this.lotId).pipe(
            map(quantityAccount => {

                quantityAccount?.buildStages?.forEach(stage => {
                    this.expandedAreas[`stage${stage?.id}`] = false;
                    stage?.buildActivities?.forEach(activity => {
                        const distinctSuppliers = this._getAllSuppliers(activity).filter(this._distinctFilter);
                        if (distinctSuppliers.length > 1) {
                            (stage as any).$hasMultipleSuppliers = true;
                            (activity as any).$hasMultipleSuppliers = true;
                        }
                    });
                });

                if (this.loading) {
                    this.loading = false;
                    this.cdRef.detectChanges();
                }

                return quantityAccount;
            })
        );

        this._subscriptions.add(
            this.quantityAccount$.subscribe(qa => {
                this.quantityAccount = qa;
                this.cdRef.detectChanges();
            })
        );
    }

    public isTakeOffBudgetsEnabled(): boolean {
        return this.featureToggleStateService.isTakeOffBudgetImportEnabled;
    }

    public isAiRateSystemEnabled(): boolean {
        return this.featureToggleStateService.isAiRateSystemEnabled;
    }

    public isDescriptiveOnly$(item: ITakeOffCompareItemDto): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            subscriber.next(item.costType === COST_TYPE_ENUM.DescriptiveOnly);
        });
    }

    public getCostXName = (activity: IBuildActivitySummaryDto): string => {
        let costXName = '';
        if ((activity.costXPercentage as any) !== ''
            && activity.costXPercentage !== 0
            && activity.costXGroupName !== ''
            && activity.costXGroupName !== undefined) {
            costXName += ' - ' + '[' + activity.costXPercentage + '% ' + activity.costXGroupName + ']';
        }

        return costXName;
    };

    public expandCollapseAll(): void {
        this.allExpanded = this.getAllExpanded();
        forIn(this.expandedAreas, (value, key) => {
            this.expandedAreas[key] = !this.allExpanded;
        });
        this.allExpanded = !this.allExpanded;
    }

    public getAllExpanded(): boolean {
        let expandedAreasCount = 0;
        let panelCount = 0;
        forIn(this.expandedAreas, (value, key) => {
            if (value === true) {
                expandedAreasCount++;
            }
            panelCount++;
        });

        return expandedAreasCount === panelCount;
    }

    public getBudgetTotalsForTakeoffItems(items: ITakeOffCompareItemDto[]): number {
        return items
            .map(item => item.budget)
            .reduce(this.sumBudgetValues(), 0);
    }

    public getBudgetTotalForBuildStage(activities: IBuildActivitySummaryDto[]): number {
        return activities
            .map(activity => activity.takeOffItems)
            .map(items => this.getBudgetTotalsForTakeoffItems(items))
            .reduce(this.sumBudgetValues(), 0);
    }

    private sumBudgetValues() {
        return function(total: number, itemBudget: number) {
            return total + itemBudget;
        };
    }

    private _getAllSuppliers(activity: IBuildActivitySummaryDto): string[] {
        const suppliers = [];
        activity.takeOffItems.forEach(item => {
            if (item.supplier) {
                suppliers.push(item.supplier);
            }
        });
        return suppliers;
    }

    private _distinctFilter = (value: string, index: number, self: any): any => {
        return self.indexOf(value) === index;
    };

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public expandedChange(): void {
        this.expandedAreasChange.emit(this.expandedAreas);
    }

    public getStageLabel(stage: IBuildStageDtoSummary): string {
        const formattedLabel = `${stage?.label} - Total: ${toCurrency(Number(stage?.total.toFixed(2)))}`;
        return this.isTakeOffBudgetsEnabled() && stage.buildActivities
            ? `${formattedLabel} - QS Budget: ${toCurrency(Number(this.getBudgetTotalForBuildStage(stage.buildActivities).toFixed(2)))}`
            : formattedLabel;
    }

    public calculateSubTotal(takeOffRate: number, quantity: number): number {
        /**
         * Round half away from zero ('commercial' rounding)
         * Uses correction to offset floating-point inaccuracies.
         * Works symmetrically for positive and negative numbers.
         */
        const p = Math.pow(10, 2);
        const n = (takeOffRate * quantity * p) * (1 + Number.EPSILON);
        return Math.round(n) / p;
    }

    public CanQueryThisRate(item: ITakeOffCompareItemDto): boolean {
        return item.costType === COST_TYPE_ENUM.Actual && item.offeringId !== 7122;
    }

    public queryRate(item: ITakeOffCompareItemDto, buildActivity: IBuildActivitySummaryDto): void {
        const blockedDialog = this.cbDialog.block('Querying rate logic GPT, please wait...');

        const buildActivityName = buildActivity.name;
        const productName = item.name;
        const productCode = item.code;
        this.aiLogicService
            .queryRate(this.lotId, item.offeringId, buildActivity.id)
            .subOnce({
                next: (n: IAiRateResponseDto) => {
                    blockedDialog.close();
                    this.cbDialog.open(AiRateResponseDialogComponent, {
                        minWidth: '40%',
                        data: {
                            answer: n,
                            buildActivityName,
                            productCode,
                            productName
                        }
                    });
                },
                error: e => {
                    // No need to log anything as the error is displayed in a toast
                    blockedDialog.close();
                }
            });
    }
}
