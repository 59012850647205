<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="pinnedPanels[silentDataKey]"
                     #lotDetailsExpansionPanel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">description</mat-icon>
            <b> Lot Description</b>
        </mat-panel-title>
        <mat-panel-description class="end-center flex-row">
            <button *ngIf="lotDetailsExpansionPanel.expanded || silentData[silentDataKey]"
                    mat-icon-button
                    (click)="togglePanelPinned($event, silentDataKey)">
                <mat-icon svgIcon="pin"
                          [ngClass]="{'cb-accent' : silentData[silentDataKey]}">
                </mat-icon>
            </button>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex-wrap-column flex-col flex">
        <cb-text-area label="Header"
                      name="lotHeading"
                      [(ngModel)]="lotMappedItem.lotHeading"
                      [readonly]="true">
        </cb-text-area>
        <cb-text-area label="Description"
                      name="lotDescription"
                      [(ngModel)]="lotMappedItem.lotDescription"
                      [readonly]="true">
        </cb-text-area>
    </div>
</mat-expansion-panel>
