import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ITakeOffDtoSummary, TAKE_OFF_TASK_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { ITakeoffMappedItem } from './interfaces/i.takeoff.mapped';
import { ITakeoffsLogicService } from './interfaces/i.takeoffs.logic.service';

export class TakeoffMappedItem
    extends BaseMappedItem<ITakeOffDtoSummary,
    ITakeoffMappedItem, ITakeoffsLogicService>
    implements ITakeoffMappedItem {

    @DtoProp public status: number;
    @DtoProp public lotId: number;
    @DtoProp public takeoffTaskNumber: number;
    @DtoProp public cost: number;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public transferHistoryId: number;
    @DtoProp public readonly id: number;

    public $label: string;

    constructor(
        sourceData: ITakeOffDtoSummary,
        logicService: ITakeoffsLogicService
    ) {
        super(sourceData, logicService, TakeoffMappedItem);
    }

    public canManage(): boolean {
        return this.status !== TAKE_OFF_TASK_STATUS_ENUM.Completed
            && this.status !== TAKE_OFF_TASK_STATUS_ENUM.Cancelled
            && this.status !== TAKE_OFF_TASK_STATUS_ENUM.Current;
    }
}

