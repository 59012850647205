import { LabelType, Options } from '@angular-slider/ngx-slider';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

import { IStandardPlanMappedItem } from '@app/logic/standard-plans';
import { garageTypesSelect } from '../const/garage.constants';
import { IStandardPlansSearch } from './../../../core/services/user-cache/user-cache-areas';


// https://www.npmjs.com/package/@angular-slider/ngx-slider

@Component({
    selector: 'cb-standard-plan-search-filter',
    templateUrl: './standard-plan-search-filter.component.html',
    styleUrls: ['./standard-plan-search-filter.component.scss'],
})
export class StandardPlanSearchFilterComponent implements OnInit, OnDestroy {
    @Input() public standardPlans: IStandardPlanMappedItem[];
    @Input() public costs: number[];
    @Input() public areas: number[];
    @Input() public searchData: IStandardPlansSearch;

    @Output() public emitEvent = new EventEmitter();

    public optionsBuildCost: Options;
    public optionsBuildCostLoaded = false;
    public minCost: number;
    public maxCost: number;

    public optionsFloorArea: Options;
    public optionsFloorAreaLoaded = false;
    public minFloorArea: number;
    public maxFloorArea: number;

    public garageTypes = garageTypesSelect;

    public searchQueryUpdate = new Subject<string>();

    public optionsBedRoom: Options = {
        floor: 0,
        ceil: 10,
        step: 1,
        showTicks: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 0) {
                        return 'Bedroom: Any';
                    }
                    return `Bedroom: ${value}`;
                default:
                    return value.toString();
            }
        }
    };

    public optionsBathRoom: Options = {
        floor: 0,
        ceil: 10,
        step: 1,
        showTicks: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 0) {
                        return 'Bathroom: Any';
                    }
                    return `Bathroom:  ${value}`;
                default:
                    return value.toString();
            }
        }
    };

    public optionsLiving: Options = {
        floor: 0,
        ceil: 10,
        step: 1,
        showTicks: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 0) {
                        return 'Living: Any';
                    }
                    return `Living: ${value}`;
                default:
                    return value.toString();
            }
        }
    };

    constructor() {
        this.searchQueryUpdate.pipe(
            debounceTime(800),
            distinctUntilChanged())
            .subscribe(() => {
                this.doSearch();
            });

    }


    public ngOnInit(): void {
        this.setoptionsBuildCost();
        this.setoptionsFloorArea();
    }

    public ngOnDestroy(): void {
        this.emitEvent.unsubscribe();
    }


    public clearSearch = (): void => {
        this.searchData.query = null;
        this.searchData.numberOfBedrooms = null;
        this.searchData.numberOfBathrooms = null;
        this.searchData.numberOfLivingRooms = null;
        this.searchData.numberOfGarages = null;

        this.searchData.costToBuild = this.costs;
        this.minCost = this.costs[0];
        this.maxCost = this.costs[1];

        this.searchData.floorArea = this.areas;
        this.minFloorArea = this.areas[0];
        this.maxFloorArea = this.areas[1];

        this.emitEvent.emit(true);
    };


    public doSearch = (): void => {
        this.emitEvent.emit(true);
    };

    public setoptionsBuildCost(): void {

        this.minCost = this.costs[0];
        this.maxCost = this.costs[1];

        this.optionsBuildCost = {
            floor: this.costs[0],
            ceil: this.costs[1],
            showTicks: false,
            translate: (value: number, label: LabelType): string => {
                if (value > 0 && Number.isFinite(value) && this.costs[0] !== this.costs[1]) {
                    switch (label) {
                        case LabelType.Low:
                            return `Min : $${value}`;
                        case LabelType.High:
                            return `Max : $${value}`;
                        default:
                            return `$${value}`;
                    }
                } else {
                    return '';
                }
            }
        };

        this.optionsBuildCostLoaded = true;
    }


    public setoptionsFloorArea(): void {

        this.minFloorArea = this.areas[0];
        this.maxFloorArea = this.areas[1];

        this.optionsFloorArea = {
            floor: this.areas[0],
            ceil: this.areas[1],
            showTicks: false,
            translate: (value: number, label: LabelType): string => {
                if (value > 0 && Number.isFinite(value) && this.areas[0] !== this.areas[1]) {
                    switch (label) {
                        case LabelType.Low:
                            return `Min : ${value}m²`;
                        case LabelType.High:
                            return `Max : ${value}m²`;
                        default:
                            return `${value}m²`;
                    }
                } else {
                    return '';
                }

            }
        };

        this.optionsFloorAreaLoaded = true;
    }


    public rangerValueChange($event, property: string): void {

        const rangeData = $event;

        const highValue = rangeData.highValue;
        const value = rangeData.value;

        switch (property) {
            case 'Cost':
                this.searchData.costToBuild = [value, highValue];
                break;
            case 'FloorArea':
                this.searchData.floorArea = [value, highValue];
                break;
            default:
                return;
        }
    }


}
