export enum LotPermissionEnum {
    None								= 0x00,
    CanSearch							= 0x01 << 0,
    CanView								= 0x01 << 1,
    CanEdit								= 0x01 << 2,
    CanManageClassicContacts			= 0x01 << 3,
    DocumentsCanView                    = 0x01 << 4,
    DocumentsCanUpload                  = 0x01 << 5,
    DocumentsCanReview                  = 0x01 << 6,
    NoteCanView                         = 0x01 << 7,
    NoteCanAdd                          = 0x01 << 8,
    NoteCanEdit                         = 0x01 << 9,
    NoteCanDelete                       = 0x01 << 10,
    CanViewSpecification                = 0x01 << 11,
    CanChangeSpecification              = 0x01 << 12,
    CanSetSpecificationAttributeValue   = 0x01 << 13,
    CanViewLeadInterests                = 0x01 << 14,
    CanViewHistory                      = 0x01 << 15,
    CanViewCostSummary                  = 0x01 << 16,
    CanViewDesign                       = 0x01 << 17,
    CanViewBuild						= 0x01 << 18,
    DocumentsCanDelete                  = 0x01 << 19,
    DocumentsCanEdit                    = 0x01 << 20,
    CanViewAll                          = 0x01 << 21,
    CanEditLotContingencyAmount         = 0x01 << 22,
    CanEditLotPriceOption               = 0x01 << 23,
    CanViewHouseAndLandFlyer               = 0x01 << 24
}
