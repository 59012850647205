import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import {
    IBusinessEntitiesLogicService,
    IBusinessEntityDefaultInstallersLogicService,
    IBusinessEntityDefaultSuppliersLogicService
} from './interfaces/i.business-entity.logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IBusinessEntityDto, IBusinessEntityDefaultSupplierDto, IBusinessEntityDefaultProductSupplierDto } from './interfaces/i.business-entity.dto';
import { ITradeTypeDto } from '@app/logic/trade-types';
import { IBusinessEntityMappedItem, IBusinessEntityDefaultInstallerMappedItem, IBusinessEntityDefaultSupplierMappedItem } from './interfaces/i.business-entity.mapped';
import { BusinessEntityMappedItem, BusinessEntityDefaultInstallerMappedItem, BusinessEntityDefaultSupplierMappedItem } from './business-entity.mapped';
import { IIdAndLabelDto, ISupplyTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class BusinessEntitiesLogicService extends BaseLogicService<IBusinessEntityDto, IBusinessEntityMappedItem> implements IBusinessEntitiesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'businessentities',
            BusinessEntityMappedItem,
        );
    }

    public getAvailableTrades(businessEntityId: number): Observable<ITradeTypeDto[]> {
        return this.$http
            .get<Array<ITradeTypeDto>>(`/${this.$baseUri}/trades?id=${businessEntityId}`);
    }

    public getAvailableSupplyTypes(businessEntityId: number): Observable<ISupplyTypeDto[]> {
        return this.$http
            .get<Array<ISupplyTypeDto>>(`/${this.$baseUri}/supplyTypes?id=${businessEntityId}`);
    }

    public generateReport(): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/report`, 'Generating Business Entities Report...');
    }

    public $getSearchList(params: any): Observable<IBusinessEntityDto[]> {
        if (!params.query) {
            delete params.query;
        }
        if (!params.activityCode) {
            delete params.activityCode;
        } else {
            params.c = params.activityCode;
            delete params.activityCode;
        }
        if (!params.activityName) {
            delete params.activityName;
        } else {
            params.n = params.activityName;
            delete params.activityName;
        }

        return this.$http
            .get<Array<IBusinessEntityDto>>(`${this.$baseUri}`, params);
    }

    public getBusinessEntityLookupList(): Observable<IIdAndLabelDto[]>{
        return this.$http.get<Array<IIdAndLabelDto>>(`${this.$baseUri}/skinny`);
    }

    public editBusinessEntity(businessEntity: IBusinessEntityDto): Observable<IBusinessEntityDto> {
        return this.$http
            .put<IBusinessEntityDto>(`/${this.$baseUri}`, businessEntity);
    }

    public getValidInstallerLocations(businessEntityId: number): Observable<number[]> {
        return this.$http
            .get<number[]>(`/${this.$baseUri}/${businessEntityId}/validinstallerlocations`);
    }
}

@Injectable()
export class BusinessEntityDefaultInstallersLogicService
    extends BaseLogicService<IBusinessEntityDefaultSupplierDto, IBusinessEntityDefaultInstallerMappedItem>
    implements IBusinessEntityDefaultInstallersLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('businessentities', BusinessEntityDefaultInstallerMappedItem);
    }

    public saveDefaultInstaller(businessEntityId: number, defaultSupplier: IBusinessEntityDefaultSupplierDto): Observable<IBusinessEntityDefaultSupplierDto> {
        return this.$http
            .post(`${this.$baseUri}/${businessEntityId}/defaultinstallers`, defaultSupplier);
    }

    public deleteDefaultInstaller(regionId: number, tradeTypeId: number): Observable<IBusinessEntityDefaultSupplierDto[]> {
        return this.$http
            .delete<Array<IBusinessEntityDefaultSupplierDto>>(`${this.$baseUri}/${regionId}/defaultinstallers/${tradeTypeId}`);
    }

    public $getSearchList(params: any): Observable<IBusinessEntityDefaultSupplierDto[]> {
        if (!params.query) {
            delete params.query;
        }
        if (!params.activityCode) {
            delete params.activityCode;
        } else {
            params.c = params.activityCode;
            delete params.activityCode;
        }
        if (!params.activityName) {
            delete params.activityName;
        } else {
            params.n = params.activityName;
            delete params.activityName;
        }

        return this.$http
            .get<Array<IBusinessEntityDefaultSupplierDto>>(`${this.$baseUri}`, params);
    }
}

@Injectable()
export class BusinessEntityDefaultSuppliersLogicService
    extends BaseLogicService<IBusinessEntityDefaultProductSupplierDto, IBusinessEntityDefaultSupplierMappedItem>
    implements IBusinessEntityDefaultSuppliersLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('businessentities', BusinessEntityDefaultSupplierMappedItem);
    }

    public saveDefaultSupplier(businessEntityId: number, defaultSupplier: IBusinessEntityDefaultProductSupplierDto): Observable<IBusinessEntityDefaultProductSupplierDto> {
        return this.$http
            .post(`${this.$baseUri}/${businessEntityId}/defaultSuppliers`, defaultSupplier);
    }

    public deleteDefaultSupplier(regionId: number, supplyTypeId: number): Observable<IBusinessEntityDefaultProductSupplierDto[]> {
        return this.$http
            .delete<Array<IBusinessEntityDefaultProductSupplierDto>>(`${this.$baseUri}/${regionId}/defaultSuppliers/${supplyTypeId}`);
    }

    public $getSearchList(params: any): Observable<IBusinessEntityDefaultProductSupplierDto[]> {
        if (!params.query) {
            delete params.query;
        }
        if (!params.activityCode) {
            delete params.activityCode;
        } else {
            params.c = params.activityCode;
            delete params.activityCode;
        }
        if (!params.activityName) {
            delete params.activityName;
        } else {
            params.n = params.activityName;
            delete params.activityName;
        }

        return this.$http
            .get<Array<IBusinessEntityDefaultProductSupplierDto>>(`${this.$baseUri}`, params);
    }
}
