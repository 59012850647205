import { ILotSpecColourItemDto, ILotSpecDto, ILotSpecItemDto, ILotSpecScheduleListItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DepInject } from '../base/dep-inject.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { ILotSpecColourItemMappedItem, LotSpecColourItemLogicService } from '../lot-spec-colour-item';
import { ILotSpecItemMappedItem, LotSpecItemLogicService } from '../lot-spec-item';
import { ILotSpecAllItem } from './interfaces/i.lot-spec-all-item.dto';
import { ILotSpecItemColourYourDreamsDto } from './interfaces/i.lot-spec.dto';
import { ILotSpecItemColourYourDreamsLogicService, ILotSpecLogicService } from './interfaces/i.lot-spec.logic.service';
import { IListItem, ILotSpecItemColourYourDreamsMappedItem, ILotSpecMappedItem } from './interfaces/i.lot-spec.mapped';

export class LotSpecItemColourYourDreamsMappedItem
    extends BaseMappedItem<ILotSpecItemColourYourDreamsDto, ILotSpecItemColourYourDreamsMappedItem, ILotSpecItemColourYourDreamsLogicService>
    implements ILotSpecItemColourYourDreamsMappedItem {

    @DtoProp public id: number;
    @DtoProp public specGroupId: number;
    @DtoProp public specGroupName: string;
    @DtoProp public slotName: string;
    @DtoProp public productDisplay: string;
    @DtoProp public showInColourYourDreams: boolean;
    @DtoProp public showInCreateSchedule: boolean;
    @DtoProp public productCode: string;
    @DtoProp public orderId: number;
    @DtoProp public specGroupOrder: number;

    public lotId: number;

    constructor(
        sourceData: ILotSpecItemColourYourDreamsDto,
        logicService: ILotSpecItemColourYourDreamsLogicService
    ) {
        super(sourceData, logicService, LotSpecItemColourYourDreamsMappedItem);
    }
}

export class LotSpecMappedItem
    extends BaseMappedItem<ILotSpecDto, ILotSpecMappedItem, ILotSpecLogicService>
    implements ILotSpecMappedItem {

    @DtoProp public id: number;
    @DtoProp public specVersion: number;
    @DtoProp public lotId: number;
    @DtoProp public baseTemplateName: string;
    @DtoProp public items: ILotSpecItemDto[];
    @DtoProp public colourItems: ILotSpecColourItemDto[];
    @DtoProp public isLocked: boolean;
    @DtoProp public activeDesignConceptId: number;
    @DtoProp public appliedTemplateId: number;
    @DtoProp public lotSpecScheduleItems: ILotSpecScheduleListItemDto[];


    public $areas: IListItem[];

    @DepInject(LotSpecItemLogicService) public readonly lotSpecItemLogic: LotSpecItemLogicService;
    @DepInject(LotSpecColourItemLogicService) public readonly lotSpecColourItemLogic: LotSpecColourItemLogicService;

    @Computed() public get allItems(): { [specGroupId: number]: ILotSpecAllItem<ILotSpecItemMappedItem | ILotSpecColourItemMappedItem>[] } {
        return [
            ...this.items.map(x => ({ item: this.lotSpecItemLogic.$createMappedItem(x), isColourItem: false })),
            ...this.colourItems.map(x => ({ item: this.lotSpecColourItemLogic.$createMappedItem(x), isColourItem: true }))
        ]
            .reduce((prev, curr) => {
                if (prev[curr.item.specGroupId] == null) {
                    prev[curr.item.specGroupId] = [];
                }
                prev[curr.item.specGroupId].push(curr);
                return prev;
            }, {});
    }

    constructor(
        sourceData: ILotSpecDto,
        logicService: ILotSpecLogicService
    ) {
        super(sourceData, logicService, LotSpecMappedItem);
    }

    public applySpecTemplate(templateId: number): Observable<ILotSpecDto> {
        return this.$logicService
            .applySpecTemplate(this.lotId, templateId)
            .pipe(
                tap((result) => {
                    if (result.id === this.id) {
                        this.$updateThisAndOriginal(result);
                    }
                })
            );
    }

    public clear(isLotScheduleTemplate: boolean): Observable<ILotSpecDto> {
        return this.$logicService
            .clearLotSpec(this.lotId, isLotScheduleTemplate)
            .pipe(
                tap((result) => {
                    this.$updateThisAndOriginal(result);
                })
            );
    }
}

