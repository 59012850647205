import {
    ClientSaleBalanceDueTriggerEnumId,
    IClientSaleContractPricingUpdateDto,
    ILotProgressPaymentDto,
    LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { IClientSaleDto } from '..';

export class ClientSaleContractPricingUpdateDto implements IClientSaleContractPricingUpdateDto {
    public contractPrice: number;
    public paymentTemplateId: number;
    public paymentsIncludeGst: boolean;
    public contractManagerId: string;
    public contractGenerationFee: number;
    public lotHoldingDeposit: number;
    public designAndLandLotDeposit: number;
    public designAndLandLotPurchasePrice: number;
    public thirdPartyLotDeposit: number;
    public thirdPartyLotPurchasePrice: number;
    public thirdPartyLotClientAccountId: number;
    public lotProgressPayments: ILotProgressPaymentDto[];
    public clientSaleBalanceDueTrigger: ClientSaleBalanceDueTriggerEnumId;

    constructor(private clientSale: IClientSaleDto) {
        // progress payments
        this.lotProgressPayments = clientSale.lotProgressPayments;
        this.paymentTemplateId = clientSale.lot.paymentTemplateId;

        this.contractPrice = clientSale.lot.contractPrice;
        this.clientSaleBalanceDueTrigger = clientSale.clientSaleBalanceDueTrigger;
        this.paymentsIncludeGst = clientSale.paymentsIncludeGst;
        this.contractManagerId = clientSale.contractMangerId;

        switch (clientSale.lot.lotContractTypeId) {
            case LOT_CONTRACT_TYPE_ENUM.HouseAndLand:
                this.applyHouseAndLandDetails(clientSale);
                break;
            case LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand:
                this.thirdPartyLotDeposit = clientSale.thirdPartyLotDeposit;
                this.thirdPartyLotPurchasePrice = clientSale.thirdPartyLotPurchasePrice;
                this.thirdPartyLotClientAccountId = clientSale.thirdPartyClientAccountId;
                break;
            case LOT_CONTRACT_TYPE_ENUM.DesignAndLand:
                if (clientSale.lot.hasExternalLandVendor) {
                    this.designAndLandLotDeposit = clientSale.designAndLandLotDeposit;
                    this.designAndLandLotPurchasePrice = clientSale.designAndLandLotPurchasePrice;
                    this.thirdPartyLotClientAccountId = clientSale.thirdPartyClientAccountId;
                } else {
                    this.applyHouseAndLandDetails(clientSale);
                }
                break;
        }

        delete this.clientSale;
    }

    private applyHouseAndLandDetails(clientSale: IClientSaleDto): void {
        this.contractGenerationFee = clientSale.lot.contractGenerationFee;
        this.lotHoldingDeposit = clientSale.lot.lotHoldingDeposit;
    }
}
