import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PermissionsPermissions } from '@app/core/permissions';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BusinessAccountLogicService } from '@app/logic/business-accounts/business-account.logic.service';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { ProjectsLogicService } from '@app/logic/projects';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { IReleaseStagesLogicService } from '@app/logic/release-stages/interfaces/i.release-stages.logic.service';
import { TagsLogicService } from '@app/logic/tags';
import { VendorsLogicService } from '@app/logic/vendors/vendors.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import {
    DESIGN_AND_BUILD_LOT_CONTRACT_TYPE_ENUM,
    ELASTIC_SEARCH_DOCUMENT_TYPE_ENUM,
    HOUSE_AND_LAND_LOT_CONTRACT_TYPE_ENUM,
    IBaseSearchableDto,
    IProjectReleaseStageDto,
    ITagDto,
    LandTitleStatusEnumId,
    LAND_TITLE_STATUS_ENUM,
    MARKETING_TYPE_ENUM,
    PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM,
    PROJECT_RELEASE_STAGE_TYPE_ENUM,
    SHOW_HOME_LEVEL_ENUM,
    USER_TAG_CONSTANTS_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { clone, filter, find, orderBy } from 'lodash';

@Component({
    selector: 'cb-manage-project-release-stage-details-dialog',
    templateUrl: './manage-release-details-dialog.component.html',
    styleUrls: ['./manage-release-details-dialog.component.scss']
})
export class ManageProjectReleaseStageDetailsDialogComponent
    extends BaseDialogFormViewDirective<IProjectReleaseStageDto,
    IReleaseStageMappedItem, IReleaseStagesLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '70%';

    @Input() public mappedItem: IReleaseStageMappedItem;

    public projectMappedItem: IProjectMappedItem;
    public isVendorValid: boolean;
    public contractTypes: SelectListItem[];
    public businessEntities: IBusinessEntityDto[];
    public tags: ITagDto[];
    public insuranceLevel: string;
    public PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM = PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM;
    public houseAndLandlotContractTypeEnum = HOUSE_AND_LAND_LOT_CONTRACT_TYPE_ENUM;
    public LAND_TITLE_STATUS_ENUM = LAND_TITLE_STATUS_ENUM;
    public houseAndLandlotContractTypes = HOUSE_AND_LAND_LOT_CONTRACT_TYPE_ENUM
        .toLookup()
        .filter(x => x.id !== HOUSE_AND_LAND_LOT_CONTRACT_TYPE_ENUM.None);
    public designAndBuildlotContractTypes = DESIGN_AND_BUILD_LOT_CONTRACT_TYPE_ENUM.toLookup();
    public DESIGN_AND_BUILD_LOT_CONTRACT_TYPE_ENUM = DESIGN_AND_BUILD_LOT_CONTRACT_TYPE_ENUM;
    public PROJECT_RELEASE_STAGE_TYPE_ENUM = PROJECT_RELEASE_STAGE_TYPE_ENUM;
    public MARKETING_TYPE_ENUM = MARKETING_TYPE_ENUM;
    public SHOW_HOME_LEVEL_ENUM = SHOW_HOME_LEVEL_ENUM;
    public landTitleStatus: IEnumLookup<LandTitleStatusEnumId>[];
    public houseAndLandConsults;
    public vendor: IBaseSearchableDto;
    public agent: IBaseSearchableDto;
    public initialVendorClientAccountId: number;
    public initialVendorBusinessEntityId: number;
    public initialAgentBusinessAccountId: number;
    public initialAgentBusinessEntityId: number;

    constructor(
        private businessEntitiesLogicService: BusinessEntitiesLogicService,
        private projectsLogicService: ProjectsLogicService,
        private tagsLogicService: TagsLogicService,
        public securityPermissions: PermissionsPermissions,
        private readonly clientAccountsLogicService: ClientAccountLogicService,
        private readonly businessAccountsLogicService: BusinessAccountLogicService,
        public readonly cbDialog: CbDialogService,
        public readonly vendorsLogicService: VendorsLogicService,
        public readonly dialogRef: MatDialogRef<ManageProjectReleaseStageDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IReleaseStageMappedItem>,
        toastService: ToastService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.initialVendorBusinessEntityId = clone(this.mappedItem.vendorBusinessEntityId);
        this.initialVendorClientAccountId = clone(this.mappedItem.vendorClientAccountId);
        this.initialAgentBusinessEntityId = clone(this.mappedItem.agentBusinessEntityId);
        this.initialAgentBusinessAccountId = clone(this.mappedItem.agentBusinessAccountId);

        this.setVendor();

        if (!this.mappedItem?.$id) {
            this.formMode = FormMode.Add;
        } else {
            this.formMode = FormMode.Edit;
        }
    }

    private setVendor(): void {
        if (this.initialVendorBusinessEntityId) {
            this.businessEntitiesLogicService.$getItem(this.initialVendorBusinessEntityId)
                .subOnce(result => {
                    this.vendor = result as unknown as IBaseSearchableDto;
                    this.mappedItem.vendorBusinessEntity = { ...result, label: result.legalEntityName };
                });
        } else if (this.initialVendorClientAccountId) {
            this.clientAccountsLogicService.$getItem(this.initialVendorClientAccountId)
                .subOnce(result => {
                    this.vendor = result as unknown as IBaseSearchableDto;
                    this.mappedItem.vendorClientAccount = { ...result, label: result.legalName };
                });
        }
    }

    private setAgent(): void {
        if (this.initialAgentBusinessEntityId) {
            this.businessEntitiesLogicService.$getItem(this.initialAgentBusinessEntityId)
                .subOnce(result => {
                    this.agent = result as unknown as IBaseSearchableDto;
                    this.mappedItem.agentBusinessEntity = { ...result, label: result.legalEntityName };
                });
        } else if (this.initialVendorClientAccountId) {
            this.businessAccountsLogicService.$getItem(this.initialAgentBusinessAccountId)
                .subOnce(result => {
                    this.agent = result as unknown as IBaseSearchableDto;
                    this.mappedItem.vendorClientAccount = { ...result, label: result.legalName };
                });
        }
    }

    public ngOnInit(): void {
        this.setBusinessEntityList();
        this.projectsLogicService.$getMappedItem(this.mappedItem.projectId).subOnce(result => {
            this.projectMappedItem = result;
            this.tagsLogicService.$getList().subOnce(tags => {
                this.tags = tags;
                const houseAndLandUserTagId = find(this.tags, (o) => {
                    return o.key === USER_TAG_CONSTANTS_CONST.HOUSE_AND_LAND_COORDINATOR;
                }).id;
                this.houseAndLandConsults = filter(this.projectMappedItem.teamMembers, (teamMember: any) => {
                    return teamMember.selectedTags.some(tag => tag.id === houseAndLandUserTagId);
                });
                this.houseAndLandConsults = orderBy(this.houseAndLandConsults, ['firstName', 'lastName'], ['asc', 'asc']);
            });
            this.insuranceLevel = this.mappedItem.isProjectLevelInsurance ? 'project' : 'lot';
            this.projectsLogicService.$getMappedItem(this.mappedItem.projectId).subOnce(projectResult => {
                this.projectMappedItem = projectResult;
                if (!this.mappedItem.businessEntityId) {
                    this.mappedItem.businessEntityId = this.projectMappedItem.businessEntity.id;
                }
                this.isVendorValid = this.mappedItem.vendorValid;
            });
        });
    }

    public setBusinessEntityList(): void {
        this.businessEntitiesLogicService.$getList().subOnce((result) => {
            this.businessEntities = result;
        });
    }

    public getDisabled(form: UntypedFormGroup): boolean {
        return form.invalid || form.pristine || form?.controls?.buildingConsentCouncilId?.invalid;
    }

    public releaseTypeChanged = (): void => {
        this.isVendorValid = this.mappedItem.vendorValid;
        this.mappedItem.allowedLotContractTypes = [];
    };

    public vendorChanged(): void {
        switch (this.vendor.elasticSearchDocumentType) {
            case ELASTIC_SEARCH_DOCUMENT_TYPE_ENUM.BusinessEntity:
                this.mappedItem.vendorBusinessEntity = { ...this.vendor, label: this.vendor.legalName };
                this.mappedItem.vendorBusinessEntityId = this.vendor.id;
                this.mappedItem.vendorClientAccount = null;
                this.mappedItem.vendorClientAccountId = null;
                break;
            case ELASTIC_SEARCH_DOCUMENT_TYPE_ENUM.Account:
                this.mappedItem.vendorClientAccount = { ...this.vendor, label: this.vendor.legalName };
                this.mappedItem.vendorClientAccountId = this.vendor.id;
                this.mappedItem.vendorBusinessEntity = null;
                this.mappedItem.vendorBusinessEntityId = null;
                break;
            default:
                this.mappedItem.vendorBusinessEntity = null;
                this.mappedItem.vendorBusinessEntityId = null;
                this.mappedItem.vendorClientAccount = null;
                this.mappedItem.vendorClientAccountId = null;
                break;
        }
    }

    public agentChanged(): void {
        switch (this.agent.elasticSearchDocumentType) {
            case ELASTIC_SEARCH_DOCUMENT_TYPE_ENUM.BusinessEntity:
                this.mappedItem.agentBusinessEntity = { ...this.agent, label: this.agent.legalName };
                this.mappedItem.agentBusinessEntityId = this.agent.id;
                this.mappedItem.agentBusinessAccount = null;
                this.mappedItem.agentBusinessAccountId = null;
                break;
            case ELASTIC_SEARCH_DOCUMENT_TYPE_ENUM.None:
                this.mappedItem.agentBusinessAccount = { ...this.agent, label: this.agent.legalName };
                this.mappedItem.agentBusinessAccountId = this.agent.id;
                this.mappedItem.agentBusinessEntity = null;
                this.mappedItem.agentBusinessEntityId = null;
                break;
            default:
                this.mappedItem.agentBusinessEntity = null;
                this.mappedItem.agentBusinessEntityId = null;
                this.mappedItem.agentBusinessAccount = null;
                this.mappedItem.agentBusinessAccountId = null;
                break;
        }
    }


    public landTitleStatusChanged(): void {
        if (this.mappedItem.landTitleStatus !== LAND_TITLE_STATUS_ENUM.TitleUploaded) {
            this.mappedItem.lotsHaveTitle = false;
        }
    }

    public insuranceLevelChanged(): void {
        this.mappedItem.isProjectLevelInsurance = this.insuranceLevel === 'project';
    }

}
