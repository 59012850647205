<cb-dialog class="flex-col"
           dialogHeading="Lot Template Selection">
    <form class="flex-col flex"
          #form="ngForm">
        <div *cbWaitFor="mappedItem">
            <p class="cb-margin-bottom-2x">Create your specification from an existing Template. Select the appropriate
                option below: </p>
            <cb-display-value-new label="Lot Site/Job Number">
                <span class="flex">{{mappedItem.jobNumber}}</span>
            </cb-display-value-new>
            <cb-display-value-new label="Lot Address">
                <span class="flex">
                    {{mappedItem.lotAddress?.street}}
                    <br/>
                    {{mappedItem.lotAddress?.suburb}}
                </span>
            </cb-display-value-new>
            <cb-select label="Spec Template to Apply"
                       name="selectedTemplate"
                       [(ngModel)]="selectedTemplate"
                       [options]="templates">
            </cb-select>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="form.invalid || form.pristine"
                (cbClick)="onSave()"
                [clickLimit]="1">Apply</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
