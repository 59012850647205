import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { LeadLogicService, ILeadDto } from '@app/logic/leads';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
    MarketStatusEnumId,
    DOCUMENT_GROUP_CODES_CONST,
    SystemAreaEnumId,
    SYSTEM_AREA_ENUM,
    LOT_CONTRACT_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LeadPermissions, HouseLandPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IDocumentPermissions } from '@app/core/permissions/base.permissions';
import { DocumentsLogicService } from '@app/logic/documents';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { LotSpecLogicService } from '@app/logic/lot-spec/lot-spec.logic.service';
import { ViewLotInterestsDialogComponent } from './view-lot-interests-dialog/view-lot-interests-dialog.component';
import { AddHouseLandLeadDialogComponent } from './add-house-land-lead-dialog/add-house-land-lead-dialog.component';
import { CreateLeadDialogComponent, ICreateLeadDialogData } from '@app/views/leads/create-lead-dialog/create-lead-dialog.component';
import { CreateClientSaleDialogComponent, ICreateClientSaleDialogData } from '@app/views/client-sales/dialogs/create-client-sale-dialog/create-client-sale-dialog.component';
import { viewLeadUrl } from '@app/views/leads/lead-routes';
import { IClientSaleDto } from '@app/logic/client-sale';

interface IData {
    mappedItem: ILotMappedItem;
    canCreateLead: boolean;
    canAddExistingLead: boolean;
}

@Component({
    selector: 'cb-view-house-land-dialog',
    templateUrl: './view-house-land-dialog.component.html',
    styleUrls: ['./view-house-land-dialog.component.scss']
})
export class ViewHouseLandDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '60%';
    public mappedItem: ILotMappedItem;
    public lotSpecVersionMappedItem: ILotSpecMappedItem;
    public close: (param: any) => void;
    public salePrice: string;
    public documents: IDocumentEntityMappedItem[];
    public canCreateLead: boolean;
    public canAddExistingLead: boolean;
    public systemArea: SystemAreaEnumId;

    constructor(
        public readonly dialogRef: MatDialogRef<ViewHouseLandDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public toastService: ToastService,
        private leadPermissions: LeadPermissions,
        private readonly houseLandPermissions: HouseLandPermissions,
        private readonly navigation: NavigationService,
        private readonly cbDialog: CbDialogService,
        private readonly leadLogic: LeadLogicService,
        private readonly lotLogic: LotsLogicService,
        private readonly documentsLogicService: DocumentsLogicService,
        private readonly lotSpecLogic: LotSpecLogicService,
        private readonly navigationService: NavigationService
    ) {

        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.canAddExistingLead = data.canAddExistingLead;
        this.canCreateLead = data.canCreateLead;
        this.systemArea = SYSTEM_AREA_ENUM.Lot;
    }

    public get documentPermissions(): IDocumentPermissions {
        return this.leadPermissions.getDocumentPermissions();
    }

    public ngOnInit(): void {
        // this.salePrice = this.mappedItem?.getSalePrice();

        this.documentsLogicService?.getDocumentsInGroupCode('lots', this.mappedItem.id, DOCUMENT_GROUP_CODES_CONST.LOT_PUBLIC_DOCS).subOnce(docs => {
            this.documents = docs;
        });
        if (this.mappedItem?.currentDesignScheme) {
            this.lotSpecLogic.getLotSpecByVersion(
                this.mappedItem?.id, this.mappedItem?.currentDesignScheme.lotSpecVersion)
                .subOnce(lotSpecVersionMappedItem => {
                    this.lotSpecVersionMappedItem = lotSpecVersionMappedItem;
                });
        }
    }

    public getTitle(): string {
        if (this.mappedItem.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return 'Design & Land Details';
        }
        return 'House & Land Details';
    }

    public lotInterestsEnabled(): boolean {
        return this.mappedItem?.buildingConsultantsWithLotInterest?.length > 0;
    }

    public viewLotInterests(event): void {
        this.cbDialog
            .open(ViewLotInterestsDialogComponent, {
                data: {
                    buildingConsultantsWithLotInterest: this.mappedItem.buildingConsultantsWithLotInterest
                },
                minWidth: '40%',
            })
            .afterClosed()
            .subOnce();
    }

    public canCreate(): boolean {
        return this.canCreateLead !== false && this.leadPermissions?.canCreate();
    }

    public canCreateSale(): boolean {
        return this.canCreateLead !== false &&
            this.houseLandPermissions?.canCreateSaleFromHouseAndLandSearch() &&
            this.mappedItem?.marketStatus === MarketStatusEnumId.Available;
    }

    public lotInterestCanAdd(): boolean {
        return this.canAddExistingLead !== false && this.leadPermissions.getLotInterestPermissions().canAddLotInterest();
    }

    public createSale(): void {
        this.cbDialog
            .open(CreateClientSaleDialogComponent, {
                data: {
                    selectedLot: {
                        displayText: `${this.mappedItem?.lotNumber} - ${this.mappedItem?.project.label}, ${this.mappedItem?.lotAddress?.regionName ?? ''} - Available`,
                        id: this.mappedItem?.id,
                    }
                } as ICreateClientSaleDialogData,
                minWidth: '40%'
            })
            .afterClosed()
            .subOnce((result: false | IClientSaleDto) => {
                if (result) {
                    this.cancel();
                    this.navigationService.navigate([`clientsales/view/${result.id}/details`]);
                }
            });
    }

    public getImage(image: any): string {
        return image;
    }

    public createLead(): void {
        this.cbDialog
            .open(CreateLeadDialogComponent, {
                data: {
                    lotData: {
                        displayText: `${this.mappedItem?.lotNumber} - ${this.mappedItem?.project.label}, ${this.mappedItem?.lotAddress?.regionName ?? ''} - Available`,
                        id: this.mappedItem?.id,
                        contractType: this.mappedItem.contractType,
                    }
                } as ICreateLeadDialogData,
                minWidth: '40%',
            })
            .afterClosed()
            .subOnce((result: false | ILeadDto) => {
                if (result) {
                    this.handleNext(result);
                    this.navigationService.navigate([viewLeadUrl(result.id)]);
                }
            });
    }

    public addToLead(): void {
        this.cbDialog
            .open(AddHouseLandLeadDialogComponent, {
                data: {
                    houseLandLot: this.mappedItem
                },
                minWidth: '40%',
            })
            .afterClosed()
            .subOnce((result: ILeadDto | false) => {
                if (result) {
                    this.cancel();
                    this.navigationService.navigate([viewLeadUrl(result.id)]);
                }
            });
    }

}
