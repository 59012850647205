<cb-dialog dialogHeading="Consent Granted">
    <form class="flex-col flex"
          #grantConsentForm="ngForm">
        <cb-user-date-time-new label="Granted User"
                               [date]="data.mappedItem.grantedDateTime"
                               [userFullName]="data.mappedItem.grantedByUserName"
                               *ngIf="isBuildingConsent && data.mappedItem.grantedDateTime && data.mappedItem.grantedByUserName">
        </cb-user-date-time-new>
        <cb-datepicker label="Date Consent Granted"
                       name="consentGrantedDate"
                       pickerType="calendar"
                       [(ngModel)]="mappedItem.consentGrantedDate"
                       (change)="onConsentReceivedDateChanged()"
                       [required]="true"></cb-datepicker>
        <cb-datepicker label="Consent Expiry Date"
                       name="consentExpiryDate"
                       pickerType="calendar"
                       [(ngModel)]="mappedItem.consentExpiryDate"
                       [required]="true"></cb-datepicker>
        <cb-datepicker label="CCC Expiry Date"
                       *ngIf="isBuildingConsent"
                       name="codeOfComplianceExpiryDate"
                       pickerType="calendar"
                       [(ngModel)]="data.mappedItem.codeOfComplianceExpiryDate"
                       [required]="true"></cb-datepicker>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="grantConsentForm.invalid || grantConsentForm.pristine"
                (cbClick)="save()"
                [clickLimit]="1">Submit
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
