import { exportDto } from '@app/shared/logic-utils/export-dto.util';
import { Prop } from '@app/shared/decorators/prop.decorator';
import { ICouncilRfiSearchDto } from '../interfaces/i.council-rfi-search.dto';

class CouncilRfiSearchDto implements ICouncilRfiSearchDto {
    @Prop public readonly id: number;
    @Prop public lotId: number;
    @Prop public receivedFrom: string;
    @Prop public externalReference: string;
    @Prop public councilName: string;
    @Prop public rfiDate: string;
    @Prop public rfiDueDate: string;
    @Prop public assignedToUserId: string;
    @Prop public assignedToUserName: string;
    @Prop public statusLabel: string;
    @Prop public statusId: number;
    @Prop public lotNumber: number;
    @Prop public clientAccountName: string;
    @Prop public jobNumber: string;
    @Prop public councilId: number;
    @Prop public projectName: string;
    @Prop public addressStreet: string;
    @Prop public addressCity: string;
    @Prop public assignedToTeamId: string;
    @Prop public assignedToTeamName: string;
    @Prop public assignedToTeamCode: string;
    @Prop public workingDrawingNumber: string;
}

export const councilRfiSearchDto = exportDto<ICouncilRfiSearchDto>(CouncilRfiSearchDto);
