import { Component } from '@angular/core';
import { PermissionsPermissions } from '../../../../core/permissions';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { SlotTagsDialogComponent } from './slot-tags-dialog/slot-tags-dialog.component';
import { SlotTagsLogicService } from '@app/logic/slot-tags/slot-tags.logic-service';
import { SlotTagMappedItem } from '@app/logic/slot-tags/slot-tag.mapped';
import { ISlotTagMappedItem } from '@app/logic/slot-tags/interfaces/i.slot-tag.mapped';
import { ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-slot-tags',
    templateUrl: './slot-tags.component.html',
    styleUrls: ['./slot-tags.component.scss'],
})
export class SlotTagsComponent extends BaseSimpleListViewDirective<ITagDto, SlotTagsLogicService> {

    public searchResults: ITagDto[] = [];
    public searchResultsLoaded = false;

    public readonly displayedColumns: string[] = ['name', 'key', 'active', 'actions'];

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        tagsLogicService: SlotTagsLogicService,
    ) {
        super(
            dialog,
            tagsLogicService,
            SlotTagsDialogComponent,
            'Schedule Item Tag',
            SlotTagMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<ITagDto>): Array<ITagDto> {

        let sortedSearchResults: ITagDto[] = [];

        const activeTags = searchResults.filter(x => x.isActive).sort((a, b) => a.name.localeCompare(b.name));

        const inactiveTags = searchResults.filter(x => !x.isActive).sort((a, b) => a.name.localeCompare(b.name));

        sortedSearchResults = activeTags.concat(inactiveTags);

        return sortedSearchResults;
    }


    public openDialog(mappedItem: ISlotTagMappedItem, dialogHeading: string): MatDialogRef<any> {
        return this.cbDialog
            .open(
                this.dialogComponent,
                {
                    minWidth: '95%',
                    data: {
                        dialogHeading,
                        mappedItem,
                        existingTags: this.searchResults.map(x => x.key).filter(x => !isNullOrWhiteSpace(x) && mappedItem.key !== x)
                    },
                }
            );
    }
}
