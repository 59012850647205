import { BaseMappedItem } from '../base/base-mapped-item';
import { IBuildContingencyRecordMappedItem } from './interfaces/i.build-contingency-record.mapped';
import { IBuildContingencyRecordDto } from './interfaces/i.build-contingency-record.dto';
import { IBuildContingencyRecordsLogicService } from './interfaces/i.build-contingency-records.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';

export class BuildContingencyRecordMappedItem
    extends BaseMappedItem<IBuildContingencyRecordDto, IBuildContingencyRecordMappedItem, IBuildContingencyRecordsLogicService>
    implements IBuildContingencyRecordMappedItem {
    @DtoProp public businessEntityId: number;
    @DtoProp public amount: number;
    @DtoProp public valueDirectionId: number;
    @DtoProp public scaleId: number;
    @DtoProp public valueTypeId: number;
    @DtoProp public createdDate: string | Date;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string | Date;
    @DtoProp public updatedByName: string;
    @DtoProp public isActive: boolean;
    @DtoProp public id: number;

    constructor(
        sourceData: IBuildContingencyRecordDto,
        logicService: IBuildContingencyRecordsLogicService
    ) {
        super(
            sourceData,
            logicService,
            BuildContingencyRecordMappedItem);
    }
}
