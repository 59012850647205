import {IHubAPIError} from '../interfaces/http-error.interface';

/** Returns true if a string is null/undefined or empty/whitespace */
export function isNullOrWhiteSpace(str: string | null | undefined): boolean {
    return str == null || str.toString().trim().length === 0;
}

/** Returns @param str prefixed by @param prefix if @param str is not already prefixed */
export function prefixStr(str: string, prefix: string): string {
    if (str.startsWith(prefix)) {
        return str;
    }
    return prefix + str;
}

const DASH_CASE_REGEXP = /-+([a-z0-9])/g;

// replaces crtical dependency: import { dashCaseToCamelCase } from '@angular/compiler/src/util';
/** Transfroms @param str from kebab-case to camelCase
 *
 *  @returns str as camelCase
 */
export function kebabCaseToCamelCase(str: string): string {
    return str.replace(DASH_CASE_REGEXP, (substring: string) => {
        const m = [];
        for (let _i = 0; _i < substring.length; _i++) {
            m[_i] = substring[_i];
        }
        return m[1].toUpperCase();
    });
}

export function stringifyReadableError(errorObj: IHubAPIError): string {
    return (errorObj && errorObj.message && errorObj.modelState)
        ? `${errorObj.message} ${Object.values(errorObj.modelState)
            .reduce<string[]>((prev: string[], curr: string[]) => prev.concat(curr), [])
            .join(', ')}`
        : (errorObj && !errorObj.message && !errorObj.modelState && Object.values(errorObj).length > 0)
            ? `Bad Request ${Object.values(errorObj)
                .reduce<string[]>((prev: string[], curr: string[]) => prev.concat(curr), [])
                .join(', ')}`
            : 'Unknown Error';
}
