import {IARVariationDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {Observable} from 'rxjs';
import {BaseMappedItem} from '../base/base-mapped-item';
import {DtoProp} from '../base/dto-prop.decorator';
import {IARVariationLogicService} from './interfaces/i.ar.variation.logic-service';
import {IARVariationMappedItem} from './interfaces/i.ar.variation.mapped';


export class ARVariationMappedItem
    extends BaseMappedItem<IARVariationDto, IARVariationMappedItem, IARVariationLogicService>
    implements IARVariationMappedItem {

    @DtoProp public variationNumber: number;
    @DtoProp public changeOptionId: number;
    @DtoProp public changeNumber: number;
    @DtoProp public changeDetails: string;
    @DtoProp public arChangeDetails: string;
    @DtoProp public lineCostWithGst: number;
    @DtoProp public variationStatus: number;
    @DtoProp public lineCostWithoutGst: number;

    constructor(
        sourceData: IARVariationDto,
        logicService: IARVariationLogicService
    ) {
        super(sourceData, logicService, ARVariationMappedItem);
    }

    public $save(): Observable<IARVariationDto> {
        return this.$logicService.saveArChangeOptionDetails(this.$getMappedDtoItem());
    }

}
