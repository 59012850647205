import { Component, HostBinding, Input } from '@angular/core';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';

@Component({
    selector: 'cb-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(CheckboxComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class CheckboxComponent extends BaseFormComponentDirective {
    @Input() public slim = false;
    @Input() public indeterminate = false;
    @Input() public labelPosition: 'before' | 'after' = 'after';
    @HostBinding('class') public class = 'flex-row';

    public clicked = ($event) => {
        if (this.readonly) {
            $event.preventDefault();
        }
    };
}
