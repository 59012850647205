export const garageTypesSelect = [
    {
        id: 0,
        label: '0',
    },
    {
        id: 1,
        label: '1',
    },
    {
        id: 1.5,
        label: '1.5',
    },
    {
        id: 2,
        label: '2',
    },
    {
        id: 2.5,
        label: '2.5',
    },
    {
        id: 3,
        label: '3',
    },
    {
        id: 4,
        label: '4',
    },
    {
        id: 5,
        label: '5',
    },
];
