<cb-dialog dialogHeading="Edit Amenities">
    <form class="flex-row"
          #form="ngForm">
        <div class="cb-margin-right-2x flex-col">
            <cb-input name="floorArea"
                      type="number"
                      label="Floor Area"
                      [(ngModel)]="amenities.floorArea"
                      [required]="required.floorArea"></cb-input>
            <cb-input name="bedrooms"
                      type="number"
                      label="Bedrooms"
                      [(ngModel)]="amenities.noBedrooms"
                      [required]="required.noBedrooms"></cb-input>
            <cb-input name="livingRooms"
                      type="number"
                      label="Living Rooms"
                      [(ngModel)]="amenities.noLivingRooms"
                      [required]="required.noLivingRooms"></cb-input>
            <cb-input name="carparks"
                      type="number"
                      label="Carpark"
                      [(ngModel)]="amenities.noCarparks"></cb-input>
        </div>
        <div class="flex-col">
            <cb-input name="landArea"
                      type="number"
                      label="Land Area"
                      [(ngModel)]="amenities.landArea"
                      [required]="required.landArea"></cb-input>
            <cb-input name="bathrooms"
                      type="number"
                      label="Bathrooms"
                      [(ngModel)]="amenities.noBathrooms"
                      [required]="required.noBathrooms"></cb-input>
            <cb-garage-select name="garages"
                              label="Garages"
                              [(ngModel)]="amenities.noGarages"
                              [required]="required.noGarages"></cb-garage-select>
            <cb-input name="noStudy"
                      type="number"
                      label="Study"
                      [(ngModel)]="amenities.noStudy"></cb-input>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="form.invalid || form.pristine"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
