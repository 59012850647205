<div class="flex-col">
    <cb-dialog dialogHeading="Image Upload"></cb-dialog>
    <div class="document-container flex-col">
        <form class="flex-col flex"
              #imageUploadForm="ngForm">
            <div class="uploadfilecontainer start-center flex-row"
                 cbDragDrop
                 (onFileDropped)="this.uploadFile($event)">
                <input hidden
                       type="file"
                       (click)="$event.target.value = null"
                       #fileInput
                       (change)="this.uploadFile($event.target.files)"/>
                <div layout-padding
                     class="cb-margin-3x flex-col flex"
                     *ngIf="!isFileValidAndUploaded">
                    <div class="flex-col flex">
                        <h4 class="cb-padding-bottom">{{this.data.style.name}}</h4>
                        <h4>Allowed File Types</h4>
                        <li>
                            Image
                        </li>
                    </div>
                </div>
                <div layout-padding
                     class="cb-margin-3x flex-col flex"
                     *ngIf="isFileValidAndUploaded">
                    <div class="flex-col flex">
                        <h4 class="cb-padding-bottom">{{this.data.style.name}}</h4>
                        <h4>Selected File : {{this.data.plan.uploadedImage.name}}</h4>
                    </div>
                </div>
                <div class="cb-margin-right"
                     (click)="fileInput.click()">
                    <button style="width: 60px;height: 60px;"
                            mat-icon-button
                            color="accent">
                        <mat-icon>attach_file_outline</mat-icon>
                    </button>
                </div>
            </div>
        </form>
        <footer class="cb-margin-top-3x end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    class="save-button"
                    [disabled]="!imageUploadForm.valid || !this.isFileValidAndUploaded"
                    (click)="this.onSubmit()">Upload</button>
            <button mat-raised-button
                    (click)="cancel()">Close</button>
        </footer>
    </div>
</div>
