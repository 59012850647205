import {Component, Input} from '@angular/core';
import {ILeadMappedItem} from '@app/logic/leads/interfaces/i.lead.mapped';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {LeadFinanceInfoEditDialogComponent} from './lead-finance-info-edit-dialog/lead-finance-info-edit-dialog.component';

@Component({
    selector: 'cb-lead-finance-info-card',
    templateUrl: './lead-finance-info-card.component.html',
    styleUrls: ['./lead-finance-info-card.component.scss']
})
export class LeadFinanceInfoCardComponent {
    @Input() public mappedItem: ILeadMappedItem;
    @Input() public canEdit: boolean;
    constructor(public readonly cbDialog: CbDialogService) { }

    public editFinanceInfo(): void {
        if (!this.canEdit) { return; }
        this.cbDialog
            .open(LeadFinanceInfoEditDialogComponent, {
                data: { mappedItem: this.mappedItem.$clone() },
                minWidth: '33%',
            })
            .afterClosed()
            .subOnce();
    }
}
