import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LotVariationPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { DocumentGroupLogicService, DocumentsLogicService, IDocumentEntityDto } from '@app/logic/documents';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { IVariationMappedItem } from '@app/logic/variation/interfaces/i.variation.mapped';
import { VariationLogicService } from '@app/logic/variation/variation.logic-service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import {
    CHANGE_STATUS_ENUM,
    COST_NATURE_ENUM,
    DOCUMENT_GROUP_CODES_CONST,
    IDocumentUploadDto,
    VARIATION_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { LotVariationChangesDialogComponent } from '../lot-variation-changes-dialog/lot-variation-changes-dialog.component';
import { LotVariationDetailsDialogComponent } from '../lot-variation-details-dialog/lot-variation-details-dialog.component';
import { LotVariationReuploadDialogComponent } from '../lot-variation-reupload-dialog/lot-variation-reupload-dialog.component';
@Component({
    selector: 'cb-lot-variation-view',
    templateUrl: './lot-variation-view.component.html',
    styleUrls: ['./lot-variation-view.component.scss']
})
export class LotVariationViewComponent implements OnInit {

    @Input() public variationMappedItem: IVariationMappedItem;
    @Output() public variationMappedItemUpdated = new EventEmitter<IVariationMappedItem>();

    public documentEntity: IDocumentEntityMappedItem;

    public CHANGE_STATUS_ENUM = CHANGE_STATUS_ENUM;
    public COST_NATURE_ENUM = COST_NATURE_ENUM;
    public VARIATION_STATUS_ENUM = VARIATION_STATUS_ENUM;

    constructor(
        private readonly variationPermissions: LotVariationPermissions,
        private readonly navigationService: NavigationService,
        private readonly cbDialog: CbDialogService,
        private readonly documentGroupLogic: DocumentGroupLogicService,
        private readonly documentsLogic: DocumentsLogicService,
        private readonly currentUser: CurrentUserService,
        private readonly variationLogicService: VariationLogicService
    ) {
    }

    public ngOnInit(): void {
        this.loadDocumentEntity();
    }

    private loadDocumentEntity(): void {
        this.documentGroupLogic
            .getByCode(DOCUMENT_GROUP_CODES_CONST.VARIATION_APPROVAL_DOC_GROUP).subOnce((documentGroup) => {
                this.documentEntity = this.documentsLogic.$createMappedItem(
                    {
                        documentGroup,
                        documentType: documentGroup.types[0].documentType,
                        document: {}
                    } as IDocumentEntityDto);
            });
    }

    public canEditVariation(): boolean {
        return this.variationPermissions.canEdit();
    }

    public canDeleteVariation(): boolean {
        return this.variationPermissions.canDelete();
    }

    public canGenerateVariationReport(): boolean {
        return this.variationPermissions.canGenerateReport();
    }

    public canFinaliseVariation(): boolean {
        return this.variationPermissions.canFinalise();
    }

    public canReuploadApprovalDocument = (): boolean => {
        return !this.isAwaitingSignedApproval() && this.hasBeenApproved();
    };

    public showVariationMenu(): boolean {
        return (this.variationMappedItem.isDeleted !== true &&
            !this.hasBeenApproved() &&
            (
                this.canGenerateVariationReport() ||
                this.canDeleteVariation() ||
                this.canEditVariation()
            )) || (this.hasBeenApproved() && this.variationMappedItem.isDeleted !== true);
    }

    public hasBeenApproved = (): boolean => {
        return this.variationMappedItem.variationStatus === VARIATION_STATUS_ENUM.Accepted;
    };

    public editVariationDetails(): void {
        this.cbDialog.open(LotVariationDetailsDialogComponent, {
            data: {
                mappedItem: this.variationMappedItem,
            }
        });
    }

    public variationChanges(): void {
        const clonedVariationMappedItem = this.variationMappedItem.$clone();
        const dialogRef = this.cbDialog.open(LotVariationChangesDialogComponent, {
            data: {
                mappedItem: clonedVariationMappedItem,
            }
        });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {
                this.variationMappedItem.$reload().subOnce(() => {
                    this.variationMappedItem.lines = this.variationMappedItem?.lines.sort(x => x.sortOrder).reverse() ?? [];
                    this.variationMappedItemUpdated.emit(this.variationMappedItem);
                });
            }
        });
    }

    public deleteVariation(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Variation',
            message: 'Are you sure you want to delete this Variation?',
            confirmed: () => {
                this.variationMappedItem.delete().subOnce((result) => {
                    if (result) {
                        this.variationMappedItem.$reload().subOnce(() => {
                            this.variationMappedItemUpdated.emit(this.variationMappedItem);
                        });
                    }
                });
            }
        });
    }

    public generateApprovalDocument(): void {
        this.variationLogicService.generateApprovalDocument(this.variationMappedItem.id).subOnce((result) => {

            // successfully downloaded so change status.
            this.variationMappedItem.variationStatus = VARIATION_STATUS_ENUM.Awaiting;
            this.variationMappedItem.$save().subOnce(variation => {
                this.variationMappedItem.$updateThisAndOriginal(this.variationLogicService.$createMappedItem(variation));
                this.variationMappedItemUpdated.emit(this.variationMappedItem);
            });

        });
    }

    public downloadSignedApprovalDocument(): void {
        this.variationLogicService.generateSignedApprovalDocument(this.variationMappedItem.id).subOnce();
    }

    public hasDocumentBeenUploaded(): boolean {
        return this.documentEntity?.document?.uploadedFile ? true : false;
    }

    public isAwaitingSignedApproval(): boolean {
        return this.variationMappedItem.variationStatus === VARIATION_STATUS_ENUM.Awaiting;
    }

    public reuploadApprovalDocument(): void {
        this.cbDialog.open(LotVariationReuploadDialogComponent, {
            data: {
                variationMappedItem: this.variationMappedItem,
                documentEntity: this.documentEntity
            }
        }).afterClosed().subOnce(result => {
            if (result) {
                this.variationMappedItem.$reload().subOnce(() => {
                    this.variationMappedItemUpdated.emit(this.variationMappedItem);
                });
            }
        });
    }

    public uploadVariationDocument(): void {

        this.cbDialog.confirm({
            dialogHeading: 'Confirm Variation Approval',
            message: 'Please confirm that you wish to upload this variation approval, thereby changing its status to accepted.',
            confirmed: () => {
                const blockedDialog = this.cbDialog.block('Approving variation...');
                this.documentsLogic.uploadDocumentEntity(this.variationMappedItem.documentEntityUri, this.variationMappedItem.documentEntityId,
                    {
                        documentTypeId: this.documentEntity.documentType?.id,
                        documentGroupId: this.documentEntity.documentGroup?.id,
                        documentEntityId: this.variationMappedItem.documentEntityId,
                        file: this.documentEntity.document.uploadedFile,
                        name: this.documentEntity.document.name,
                    } as IDocumentUploadDto)
                    .subOnce({
                        next: (result) => {
                            if (result) {
                                this.variationMappedItem.approvedByUserId = this.currentUser.guid;
                                this.variationMappedItem.variationStatus = VARIATION_STATUS_ENUM.Accepted;
                                this.variationMappedItem.$save().subOnce(variation => {
                                    this.variationMappedItem.$updateThisAndOriginal(this.variationLogicService.$createMappedItem(variation));
                                    this.variationMappedItemUpdated.emit(this.variationMappedItem);
                                });
                            }
                            blockedDialog.close();
                        },
                        error: () => {
                            blockedDialog.close();
                        }
                    });
            },
        });
    }

    public viewChange($event: any, changeRecordId: number): void {
        this.navigationService.navigate(
            [`lots/${this.variationMappedItem.lotId}/changes/changes`],
            $event,
            {
                queryParams: {
                    changeRecordIds: [changeRecordId]
                }
            });
    }

}
