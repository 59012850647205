import { InputComponent } from './input.component';
import { Subscription } from 'rxjs';
import {
    Input,
    Directive,
    OnDestroy,
} from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, AbstractControl } from '@angular/forms';
import { StringMask } from './string.mask';

@Directive({
    selector: 'cb-input[maskType], input[maskType][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaskTypeDirective, multi: true }]
})
export class MaskTypeDirective implements OnDestroy {
    private readonly subs$ = new Subscription();
    private _mask: StringMask;
    private _maskType: string;
    private _isElementsSetUp = false;

    public get maskType(): string {
        return this._maskType;
    }
    @Input() public set maskType(type: string) {
        if (!type) {
            return;
        }
        this._maskType = type;
        this._mask = StringMask.GetStringMask(type);
        this.setUpInputElements();
    }

    public gControl: AbstractControl;

    constructor(private readonly _host: InputComponent) { }

    public setUpInputElements(): void {
        if (!this.maskType || this._isElementsSetUp) {
            return;
        }
        this._host.valueSetter = this._maskValueSetter;

        this.subs$.add(
            this._host.valueChange.subscribe(() => {
                if (this.gControl) {
                    this.gControl.updateValueAndValidity();
                }
            })
        );
        this._isElementsSetUp = true;
    }

    public ngOnDestroy(): void {
        this.subs$.unsubscribe();
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (!this.maskType) {
            return;
        }

        this.gControl = control;
        const masked = this._mask.process(control.value || this._host.value);
        if (!masked.result && !masked.cleaned) {
            return null;
        }
        return masked ? masked.valid ? null : { error: 'Value is not valid' } : null;
    }

    private readonly _maskValueSetter = (value: any): { value: any; modelValue: any } => {
        const maskedValue = this._mask.process(value);
        return {
            value: maskedValue.result,
            modelValue: maskedValue.cleaned
        };
    };
}
