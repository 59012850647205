import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {LotBuildPermissions} from '@app/core/permissions';
import {ToastService} from '@app/core/services/toast/toast.service';
import {IBuildProgrammeActivityMappedItem, IBuildProgrammeActivityRelationshipsDto} from '@app/logic/build-programme-activity';
import {ISsrMappedItem, SsrsLogicService} from '@app/logic/ssrs';
import {ISsrDetailsDto} from '@app/logic/ssrs/interfaces/i.ssr-details.dto';
import {IBasicSsrLineItemDto, ISsrLineItemDto} from '@app/logic/ssrs/interfaces/i.ssr-line-item.dto';
import {ISsrDto} from '@app/logic/ssrs/interfaces/i.ssr.dto';
import {ISsrsLogicService} from '@app/logic/ssrs/interfaces/i.ssrs.logic.service';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {
    BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM,
    BUSINESS_ACCOUNT_STATUS_ENUM,
    BusinessAccountSearchTypeEnumId,
    BusinessAccountStatusEnumId,
    COST_TYPE_ENUM,
    ILotDto,
    ISkinnyBusinessAccountDto,
    LOT_TYPE_ENUM,
    LotTypeEnumId,
    SSR_STATE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import {AddSsrLinesDialogComponent} from '../add-ssr-lines-dialog/add-ssr-lines-dialog.component';
import {ConvertToManualOrderDialogComponent} from '../convert-to-manual-order-dialog/convert-to-manual-order-dialog.component';
import {EditSsrLineDialogComponent} from '../edit-ssr-line-dialog/edit-ssr-line-dialog.component';
import {SetBuildProgrammeActivitySupplierDialogComponent} from '../set-build-programme-activity-supplier-dialog/set-build-programme-activity-supplier-dialog.component';
import {NgForm} from '@angular/forms';

interface IData {
    mappedItem: ISsrMappedItem;
    buildProgrammeActivity: IBuildProgrammeActivityMappedItem;
    lotType: LotTypeEnumId;
    lotDto: ILotDto;
    canSetSupplier: boolean;
}

@Component({
    templateUrl: './edit-build-programme-ssr-dialog.component.html',
    styleUrls: ['./edit-build-programme-ssr-dialog.component.scss'],
})
export class EditBuildProgrammeSsrDialogComponent
    extends BaseDialogFormViewDirective<ISsrDto, ISsrMappedItem, ISsrsLogicService> {
    public static readonly MIN_WIDTH = '90%';
    public readonly LOT_TYPE_ENUM = LOT_TYPE_ENUM;
    public activityRelationships: IBuildProgrammeActivityRelationshipsDto;
    public initialSupplierId: number;
    public selectedBusinessAccount: ISkinnyBusinessAccountDto;

    // Business Account Search Queries
    public statusQuery: BusinessAccountStatusEnumId[];
    public searchTypeQuery: BusinessAccountSearchTypeEnumId;
    public businessAccountFindParams: { [param: string]: any } = {};

    public get mappedItem(): ISsrMappedItem {
        return this.data.mappedItem;
    }

    public get buildProgrammeActivity(): IBuildProgrammeActivityMappedItem {
        return this.data.buildProgrammeActivity;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<EditBuildProgrammeSsrDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LotBuildPermissions) public readonly lotBuildPermissions: LotBuildPermissions,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(SsrsLogicService) public readonly ssrLogic: SsrsLogicService,
    ) {
        super(dialogRef, toastService);
        this.initialSupplierId = this.data?.buildProgrammeActivity.businessAccount?.id;
        this.selectedBusinessAccount = this.data?.buildProgrammeActivity.businessAccount;
        this.buildProgrammeActivity
            .getRelationships()
            .subOnce(result => this.activityRelationships = result);
        this.setBusinessAccountSearchParams();
    }

    public setBusinessAccountSearchParams(): void {
        const statusIds = BUSINESS_ACCOUNT_STATUS_ENUM.toLookup().map((status) => status.id);
        const validStatusIds = statusIds.filter((status: number) => {
            return status === BUSINESS_ACCOUNT_STATUS_ENUM.Active ||
                status === BUSINESS_ACCOUNT_STATUS_ENUM.Overridden;
        });

        this.statusQuery = validStatusIds;
        this.searchTypeQuery = BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM.NotRatesOnly;

        this.businessAccountFindParams = {
            s: validStatusIds,
            t: BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM.NotRatesOnly,
            tt: this.buildProgrammeActivity.tradeTypes.map(tt => tt.id),
        };
    }

    public addLineItemsDialog(): void {
        this.cbDialog
            .open(AddSsrLinesDialogComponent, {
                data: {
                    mappedItem: this.mappedItem,
                    lotType: this.data.lotType
                },
                minWidth: 640,
            })
            .afterClosed()
            .subOnce(items => {
                // BUILD PROGRAMME TODO - the result from this should be able to update the mappedItem without a reload
                if (items?.length > 0) {
                    this.mappedItem.$reload().subOnce();
                }
            });
    }

    public removeLineItem(lineItemId: number): void {
        this.cbDialog.confirm({
            dialogHeading: 'Confirm Remove Line Item',
            message: 'Are you sure you want to remove this line item?',
            confirmed: () => {
                this.mappedItem
                    .removeLineItem(lineItemId)
                    .subOnce(() => {
                        this.mappedItem.$reload().subOnce();
                    });
            }
        });
    }

    public saveBuildProgrammeActivity(formGroup: NgForm): void {
        this.saveInProgress = true;
        this.buildProgrammeActivity.$save().subOnce({
            next: () => {
                this.mappedItem.$reload().subOnce();
                this.saveInProgress = false;

                // The save button should only be enabled for new changes
                formGroup.form.markAsPristine();
            },
            error: () => {
                this.saveInProgress = false;
            }
        });
    };

    public getEditDisabled(lineItem: IBasicSsrLineItemDto): boolean {
        return this.buildProgrammeActivity.ssrStateId === SSR_STATE_ENUM.Confirmed ||
            lineItem.costType === COST_TYPE_ENUM.DescriptiveOnly;
    }

    public getAddDisabled(): boolean {
        return this.buildProgrammeActivity.ssrStateId === SSR_STATE_ENUM.Confirmed;
    }

    public canEditComments(): boolean {
        return this.buildProgrammeActivity.ssrStateId === SSR_STATE_ENUM.Draft ||
            this.buildProgrammeActivity.ssrStateId === SSR_STATE_ENUM.None;
    }

    public editLineItem(lineItem: ISsrLineItemDto): void {
        this.cbDialog
            .open(EditSsrLineDialogComponent, {
                data: {
                    lineItem,
                    mappedItem: this.mappedItem,
                    buildProgramActivity: this.buildProgrammeActivity
                },
                minWidth: 420,
            })
            .afterClosed()
            .subOnce(this.reloadItem);
    }

    public setSsrConfirmed(): void {
        if (!this.buildProgrammeActivity.rules.canConfirmSsr) {
            return;
        }
        this.saveInProgress = true;
        this.cbDialog.confirm({
            dialogHeading: 'Set SSR to Confirmed',
            message: 'Are you sure you want to Confirm this SSR?',
            confirmed: () => {
                this.buildProgrammeActivity
                    .confirmSsr()
                    .subOnce(this.handleSaveClose);
            },
            declined: () => {
                this.saveInProgress = false;
            }
        });
    }

    public setSsrCompleted(): void {
        if (!this.buildProgrammeActivity.rules.canCompleteSsr) {
            return;
        }
        this.saveInProgress = true;
        this.cbDialog.confirm({
            dialogHeading: 'Set SSR State to Completed',
            message: 'Are you sure you want to Set this SSR State to Completed?',
            confirmed: () => {
                this.buildProgrammeActivity
                    .completeSsr()
                    .subOnce(this.handleSaveClose);
            },
            declined: () => {
                this.saveInProgress = false;
            }
        });
    }

    public convertToManualOrder(): void {
        this.saveInProgress = true;
        this.cbDialog
            .open(
                ConvertToManualOrderDialogComponent,
                {
                    minWidth: '33%',
                }
            )
            .afterClosed()
            .subOnce({
                next: (result) => {
                    if (result) {
                        this.buildProgrammeActivity
                            .convertToManualOrder(result)
                            .subOnce(() => {
                                this.dialogRef.close(this.mappedItem);
                                this.saveInProgress = false;
                            });
                    }
                }
            });
    }

    public canConvertToManualOrder(): boolean {
        return this.lotBuildPermissions.getManualPurchaseOrderPermissions().canManageManualOrder();
    }

    public canConfirmSSR(): boolean {
        return this.lotBuildPermissions.canConfirmSSR();
    }

    public canCompleteSSR(): boolean {
        return this.lotBuildPermissions.canCompleteSSR();
    }

    public getActivityName(): string {
        return this.mappedItem.activityName + this.getCostXName();
    }

    private readonly getCostXName = (): string => {
        if (this.buildProgrammeActivity.costXPercentage.toString() !== ''
            && this.buildProgrammeActivity.costXPercentage !== 0
            && this.buildProgrammeActivity.costXGroupName !== ''
            && this.buildProgrammeActivity.costXGroupName !== undefined) {
            return ` - [${this.buildProgrammeActivity.costXPercentage}% ${this.buildProgrammeActivity.costXGroupName}]`;
        } else {
            return '';
        }
    };

    private readonly reloadItem = (result: ISsrDetailsDto): void => {
        if (result) {
            this.mappedItem.$reload().subOnce();
        }
    };

    private readonly handleSaveClose = (result: ISsrDetailsDto): void => {
        this.saveInProgress = false;
        this.mappedItem.state = result.stateId;
        this.dialogRef.close(this.mappedItem);
    };


    public setSupplier(): void {
        // When Opening the Dialog the mat select Panel does not lose focus so setting the focus to the textfield
        const textAreaElement = document.getElementById('cb-textarea-field');
        textAreaElement.focus();

        if (this.selectedBusinessAccount) {
            const dialogRef = this.cbDialog
                .open(
                    SetBuildProgrammeActivitySupplierDialogComponent,
                    {
                        data: {
                            selectedBusinessAccount: this.selectedBusinessAccount,
                            buildProgrammeActivity: this.buildProgrammeActivity,
                            lotIsPrimaryUnit: this.data.lotDto?.isPrimaryUnit,
                            lotId: this.data.lotDto.id
                        },
                        minWidth: '33%',
                    }
                );

            dialogRef.afterClosed().subOnce(result => {
                if (!result) {
                    this.selectedBusinessAccount = this.buildProgrammeActivity.businessAccount;
                }

                this.ssrLogic.$getMappedItem(this.buildProgrammeActivity.ssrId).subOnce((ssr) => {
                    this.data.mappedItem = ssr;
                });
            });

        }
    }

}
