<mat-card class="cb-margin flex-col">
    <mat-card-content class="start-end flex-col flex"
                      *cbWaitFor="mappedItem">
        <button mat-raised-button
                color="primary"
                class="cb-margin-bottom-2x"
                (cbClick)="openAddReleaseDialog()"
                *ngIf="releaseStagePermissions.canCreate()">
            Add Release Stage
        </button>
        <cb-table class="cb-margin-top cb-margin-bottom-3x flex"
                  [loaded]="loaded"
                  style="width: 100%">
            <thead cbTableHead>
                <tr>
                    <td>Release ID</td>
                    <td>Release Name</td>
                    <td>Release Type</td>
                    <td>Release Status</td>
                    <td>Due Date</td>
                    <td>Title Date</td>
                    <td>Market Release</td>
                    <td>SH Date</td>
                    <td width="75">Total Lots</td>
                    <td width="65">No. Sold</td>
                    <td width="65">No. Avail</td>
                    <td *ngIf="releaseStagePermissions.canView()"></td>
                </tr>
            </thead>
            <tr cbTableRow
                *ngFor="let result of mappedItem.releaseStages">
                <cb-td-text [value]="result.releaseId"></cb-td-text>
                <cb-td-text [value]="result.name"></cb-td-text>
                <cb-td-text [value]="PROJECT_RELEASE_STAGE_TYPE_ENUM[result.type]"></cb-td-text>
                <cb-td-text [value]="PROJECT_RELEASE_STATUS_ENUM[result.releaseStatus]"></cb-td-text>
                <cb-td-date [value]="result.dueDate"></cb-td-date>
                <cb-td-date [value]="result.lotTitleDate"></cb-td-date>
                <cb-td-date [value]="result.marketReleaseDate"></cb-td-date>
                <cb-td-date [value]="result.showHomeCompletionDate"></cb-td-date>
                <cb-td-text [value]="result.totalLots"></cb-td-text>
                <cb-td-text [value]="isHouseAndLand(result.type) ? result.numberSold : '-'"></cb-td-text>
                <cb-td-text [value]="isHouseAndLand(result.type) ? result.numberAvailable : '-'"></cb-td-text>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol
                    *ngIf="releaseStagePermissions.canView()">
                    <button mat-icon-button
                            (cbClick)="viewRelease(result)">
                        <mat-icon class="cb-grey">
                            pageview</mat-icon>
                    </button>
                </td>
            </tr>
        </cb-table>
    </mat-card-content>
</mat-card>
