import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[cbMinQuantity]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinQuantityValidatorDirective, multi: true }]
})
export class MinQuantityValidatorDirective implements Validator {

    private minQuantity: number;
    @Input() public set cbMinQuantity(v: number) {
        this.minQuantity = Number(v);
    }
    private maxQuantity: number;
    @Input() public set cbMaxQuantity(v: number) {
        this.maxQuantity = Number(v);
    }

    public validate(control: AbstractControl): ValidationErrors {
        if (Number(control.value) < this.minQuantity) {
            return { minQuantity: `Quantity must be equal to or greater than ${this.minQuantity}` };
        }
        if (Number(control.value) > this.maxQuantity) {
            return { maxQuantity: `Quantity must be equal to or less than ${this.maxQuantity}` };
        }
        return null;
    }

}
