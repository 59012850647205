<cb-dialog dialogHeading="Change Option Line Item">
    <form class="flex-col flex"
          #itemForm="ngForm"
          id="itemForm"
          *cbWaitFor="mappedItem && changeOptionMappedItem && changeRecordMappedItem">
        <span>Option {{changeOptionMappedItem.optionNumber}} for Change {{changeRecordMappedItem.changeNumber}} -
            {{changeOptionMappedItem.description}}
        </span>
        <div class="cb-margin-bottom-2x cb-margin-top-2x">
            <label>Change Type</label>
            <mat-radio-group name="changeDirection"
                             class="direction-radio-group"
                             [(ngModel)]="mappedItem.changeDirection"
                             [required]="true"
                             (change)="changeDirectionChanged()">
                <mat-radio-button class="direction-radio-button"
                                  [value]="CHANGE_DIRECTION_ENUM.Add">Add</mat-radio-button>
                <mat-radio-button class="direction-radio-button"
                                  [value]="CHANGE_DIRECTION_ENUM.Credit">Credit</mat-radio-button>
            </mat-radio-group>
        </div>
        <cb-select label="House Area"
                   name="houseAreas"
                   [(ngModel)]="mappedItem.specGroupId"
                   [options]="houseAreas"
                   [required]="isAddItem()"
                   [readonly]="isCreditItem()"
                   (change)="specGroupSelected($event)"></cb-select>
        <div class="start-center flex-row"
             *ngIf="isAddItem()">
            <cb-product-autocomplete-scroller #productAutocomplete
                                              class="cb-margin-right flex"
                                              label="Product"
                                              name="product"
                                              [(ngModel)]="this.mappedItem.selectedProduct"
                                              [required]="!this.isQuoted()"
                                              [requireMatch]="isActual() && isAddItem()"
                                              [matchProps]="['id']"
                                              [hideCompositeItems]="true"
                                              [categoryId]="mappedItem?.category?.id > 0 ? mappedItem?.category?.id : null"
                                              (searchTextChanged)="productSearchTextChanged($event)"
                                              [readonly]="this.isQuoted() || this.isCreditItem()"
                                              (optionSelected)="productUpdate($event)">
            </cb-product-autocomplete-scroller>
            <cb-product-input-find *ngIf="!productAutocomplete.readonly"
                                   name="selectedProduct"
                                   [required]="true"
                                   [buttonOnly]="true"
                                   [activeOnly]="true"
                                   [hideCompositeItems]="true"
                                   [hasRestrictedCategory]="true"
                                   [restrictedCategories]="mappedItem?.category?.id > 0 ? mappedItem?.slotCategory?.categoryTree : []"
                                   label="Product"
                                   (productSelected)="productSelected($event)">
            </cb-product-input-find>
        </div>
        <div class="start-center flex-row"
             *ngIf="isCreditItem()">
            <mat-form-field style="width: 100%;">
                <input type="text"
                       matInput
                       [(ngModel)]="selectedLotSpec"
                       name="lotSpecificationAuto"
                       (keyup)="lotSpecificationTextChanged($event.target.value)"
                       placeholder="Lot Specification"
                       [matAutocomplete]="lotSpecificationAuto"
                       required="true"/>
                <mat-autocomplete #lotSpecificationAuto="matAutocomplete"
                                  [displayWith]="displayFn"
                                  (optionSelected)="lotSpecUpdate()">
                    <mat-option *ngFor="let option of filteredLotSpecItems"
                                [value]="option">{{option.specGroupName}} - {{option.hasQuoteLines ? option.quoteLineDescription : option.productDisplay}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <cb-display-value-new *ngIf="isQuoted()"
                              [value]="COST_TYPE_ENUM[mappedItem.costType]"
                              label="Cost Type">
        </cb-display-value-new>
        <cb-select *ngIf="!isQuoted()"
                   name="costType"
                   label="Cost Type"
                   [options]="changeOptionLineCostTypes"
                   [required]="true"
                   [(ngModel)]="mappedItem.costType"
                   (ngModelChange)="clearNonSelectedProduct()">
        </cb-select>
        <cb-input type="number"
                  label="Quantity"
                  [required]="!isDescriptiveOnly()"
                  [(ngModel)]="mappedItem.quantity"
                  [readonly]="quantityIsLocked()"
                  name="quantity"
                  (change)="quantityChanged()"></cb-input>
        <cb-currency *ngIf="!this.isQuoted()"
                     label="Rate"
                     name="rate"
                     [(ngModel)]="mappedItem.rate"
                     [required]="!isDescriptiveOnly()"
                     [inclGst]="isProvisional()"
                     (change)="calculateCostAmount()"
                     [readonly]="isDescriptiveOnly()"></cb-currency>
        <cb-currency label="Amount"
                     name="costAmount"
                     [required]="!isDescriptiveOnly()"
                     [(ngModel)]="mappedItem.costAmount"
                     [readonly]="true">
        </cb-currency>
        <div class="cb-checkbox-list flex-col">
            <cb-checkbox name="quoted"
                         label="Quoted"
                         [(ngModel)]="mappedItem.quoted"
                         [readonly]="!canBeQuoted()">
            </cb-checkbox>
            <cb-checkbox *ngIf="isDescriptiveOnly()"
                         name="showInClientSpecification"
                         label="Included in Flyer / Create Schedule"
                         [(ngModel)]="mappedItem.showInClientSpecification">
            </cb-checkbox>
            <cb-checkbox *ngIf="isDescriptiveOnly()"
                         name="ShowInColourYourDreams"
                         label="Show in Colour Schedule"
                         [(ngModel)]="mappedItem.showInColourYourDreams">
            </cb-checkbox>
        </div>
        <cb-text-area label="QS Comments"
                      name="comments"
                      [(ngModel)]="mappedItem.comments"
                      maxlength="1024">
        </cb-text-area>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="addOrUpdateItem()"
                    [clickLimit]="1"
                    [disabled]="itemForm.pristine || itemForm.invalid">{{this.isEdit() ? 'Update' : 'Add'}}</button>
            <button mat-raised-button
                    (cbClick)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
