import { Component, Input } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-card',
    templateUrl: './lot-card.component.html',
    styleUrls: ['./lot-card.component.scss']
})
export class LotCardComponent {
    @Input() public lotDocumentDto: ILotDocumentDto;

    constructor(private readonly navigationService: NavigationService) { }

    public viewLot(): void {
        this.navigationService.navigate([`lots/${this.lotDocumentDto.id}/summary`]);
    }

}
