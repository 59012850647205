import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {InfoMessageComponent} from './info-message.component';

const COMPONENTS = [
    InfoMessageComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatIconModule,
    ],
    exports: COMPONENTS
})
export class CbInfoMessageModule {
}
