import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { Observable, of, Subject, Subscription, map } from 'rxjs';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { orderBy } from 'lodash';
import { DesignTeamPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { COUNCIL_RFI_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { CouncilRfiLogicService, ICouncilRfiMappedItem, ICouncilRfiSearchDto } from '@app/logic/council-rfis';
import { IDesignTeamCounfilRfiSearch } from '@app/core/services/user-cache/user-cache-areas';
import { EditCouncilRfiDialogComponent } from '@app/views/lot/design/council-rfi/edit-council-rfi-dialog/edit-council-rfi-dialog.component';

@Component({
    selector: 'cb-design-team-council-rfis',
    templateUrl: './design-team-council-rfis.component.html',
    styleUrls: ['./design-team-council-rfis.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignTeamCouncilRfisComponent implements OnDestroy, OnInit {

    public resultOrders$ = of([
        { id: 'jobNumber', label: 'Job Number' },
        { id: 'assignedTo', label: 'Assigned To' },
        { id: 'dueDate', label: 'Due Date' },
        { id: 'rfiDate', label: 'RFI Date' },
        { id: 'status', label: 'Status' }
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    private readonly _subscriptions = new Subscription();
    public searchEnabled = false;
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public teamCode: string;
    public loaded = false;
    public cacheLoaded$ = new Subject();
    public results: ICouncilRfiMappedItem[] = [];
    public readonly allUsers = '999';
    public readonly allStatuses = 999;
    public readonly unassigned = undefined;

    public councilRfiStatuses$ = of(COUNCIL_RFI_STATUS_ENUM.toLookup())
        .pipe(
            map(councilRfiStatuses => {
                const orderedCouncilRfiStatuses = orderBy(councilRfiStatuses, tag => tag.label.toLowerCase());
                return orderedCouncilRfiStatuses;
            })
        );

    public hasPermissionsToEdit$ = of(this.designTeamPermissions.canEditRfi());

    public teamUsersOptions$ = this.teamsLogicService
        .loadAllMembersByKey('DESIGNTEAM').pipe(
            map(users => {
                users = orderBy(users, 'firstName');
                users.unshift({ id: this.allUsers, label: 'All' } as any);
                return users;
            })
        );

    public get searchFiltersCache(): UserCacheItem<IDesignTeamCounfilRfiSearch> {
        return this.userCacheService.designTeamCouncilRfiSearch;
    }

    constructor(
        public readonly designTeamPermissions: DesignTeamPermissions,
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: CouncilRfiLogicService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly userCacheService: UserCacheService,
        private readonly dialogService: CbDialogService,
        private readonly navigationService: NavigationService
    ) {
    }

    public ngOnInit(): void {
        this._initSearchFiltersCache();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public fetchResults(): Observable<ICouncilRfiSearchDto[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.$getSearchList(this._queryParams);
    }

    public hasPermissionsToEditCouncilRfi = (): boolean => {
        if (this.designTeamPermissions) {
            return this.designTeamPermissions.canEditRfi();
        }

        return false;
    };

    public getStreetAddress(rfi: ICouncilRfiSearchDto): string {
        return `${rfi.addressStreet}, ${rfi.addressCity}`;
    }

    public readonly editCouncilRfi = async (councilRfi: ICouncilRfiMappedItem): Promise<Subscription> => {
        const mappedItem = await this.logicService.$getMappedItem(councilRfi.id).toPromise();
        return this.dialogService
            .open(
                EditCouncilRfiDialogComponent,
                {
                    data: {
                        dialogHeading: mappedItem.statusId === COUNCIL_RFI_STATUS_ENUM.Complete ? 'View Council RFI' : undefined,
                        councilRfi: mappedItem,
                    },
                }
            ).afterClosed()
            .subOnce({
                next: (result: ICouncilRfiMappedItem) => {
                    if (result && result.id) {
                        const foundIndex = this.results.findIndex(x => x.id === result.id);
                        Object.assign(this.results[foundIndex], result);
                        this.cdRef.detectChanges();
                    }
                }
            });
    };

    public viewLot(lotId: number): void {
        if (lotId) {
            this.navigationService.navigate([`/lots/${lotId}/design/council-rfis`]);
        }
    }

    private get _queryParams(): IDesignTeamCounfilRfiSearch {
        return this.searchEnabled ?
            {
                ...this.searchFiltersCache.copyData(),
                userId: this.searchFiltersCache.data.userId === this.allUsers ?
                    undefined :
                    this.searchFiltersCache.data.userId ?
                        this.searchFiltersCache.data.userId :
                        undefined,
                statusId: (this
                    .searchFiltersCache.data.statusId as any) === this.allStatuses ?
                    undefined :
                    this.searchFiltersCache.data.statusId,
                currentPage: this.currentPage
            } :
            undefined;
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    public _initSearchFiltersCache(): void {
        this.searchFiltersCache.init().then(() => {
            this.cacheLoaded$.next(true);
            this.searchEnabled = true;
            this._onSearchFiltersChanged();
            this._subscriptions.add(
                this.searchFiltersCache.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );

            this.searchFiltersCache.silentData.canSeeAll = this.designTeamPermissions.canSeeAll();
            if (!this.searchFiltersCache.silentData.canSeeAll) {
                this.searchFiltersCache
                    .silentData
                    .userId = this.searchFiltersCache.currentUserId;
            }
        });
    }
}
