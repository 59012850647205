import {Component, Input} from '@angular/core';
import {LEAD_LOST_REASON_ENUM, LEAD_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {ILeadDto} from '@app/logic/leads';
import {LeadPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {BehaviorSubject} from 'rxjs';
import {viewLeadUrl} from '../../lead-routes';

@Component({
    selector: 'cb-lead-side-bar-card-new',
    templateUrl: './lead-side-bar-card.component.html',
    styleUrls: ['./lead-side-bar-card.component.scss']
})
export class LeadSideBarCardNewComponent {
    @Input() public lead: ILeadDto;
    public LEAD_LOST_REASON_ENUM = LEAD_LOST_REASON_ENUM;
    public collapsed$ = new BehaviorSubject<boolean>(false);
    public LEAD_STATUS_ENUM = LEAD_STATUS_ENUM;

    constructor(
        public leadPermissions: LeadPermissions,
        public readonly navigationService: NavigationService
    ) { }

    public canView(): boolean {
        return this.leadPermissions.canView();
    }

    public viewLead(): void {
        this.navigationService.navigate([viewLeadUrl(this.lead.id)]);
    }

}
