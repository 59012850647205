

import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { debounceTime, distinctUntilChanged, skip, Subscription } from 'rxjs';

@Directive({
    selector: '[ngModelChangeDebounced]',
})
export class NgModelChangeDebouncedDirective implements OnDestroy {
    @Output() public readonly ngModelChangeDebounced = new EventEmitter<any>();
    @Input() public ngModelChangeDebounceTime = 300;

    private readonly subscription: Subscription;

    constructor(private readonly ngModel: NgModel) {
        this.subscription = this.ngModel.control.valueChanges
            .pipe(
                skip(1),
                distinctUntilChanged(),
                debounceTime(this.ngModelChangeDebounceTime)
            )
            .subscribe((value) => this.ngModelChangeDebounced.emit(value));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
