<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
    <div class="start-center flex-row">
        <h3 class="mat-title flex">
            Feature Toggle States
        </h3>
        <button mat-raised-button
                color="primary"
                [cbClickRx]="seedStates()">
            Seed
        </button>
    </div>
    <form #form="ngForm">
        <cb-table [loaded]="featureToggleStates.$resolved"
                  [columns]="['Feature', 'Enabled']">
            <tr cbTableRow
                *ngFor="let feature of featureToggleStates">
                <cb-td-text [value]="feature.label"></cb-td-text>
                <td class="checkbox-col"
                    cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <mat-checkbox class="checkbox-toggle"
                                  name="toggle_{{feature.enumId}}"
                                  [checked]="feature.isEnabled"
                                  [(ngModel)]="feature.isEnabled"
                                  (click)="toggleFeature(feature)"></mat-checkbox>
                </td>
            </tr>
        </cb-table>
    </form>
</div>
