import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { UsersLogicService } from '@app/logic/users';
import { IUserMappedItem } from '@app/logic/users/interfaces/i.user.mapped';
import { NonDialogFormViewDirective } from '@app/shared/base-views/non-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import { map, BehaviorSubject, Observable, merge } from 'rxjs';
import { UserDetailsComponent } from '../user-details/user-details.component';

@Component({
    selector: 'cb-user-side-bar-card',
    templateUrl: './user-side-bar-card.component.html',
    styleUrls: ['./user-side-bar-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSideBarCardComponent implements OnInit, AfterViewInit {
    @Input() public activeComponent$: BehaviorSubject<
    NonDialogFormViewDirective<any, any, any>> = new BehaviorSubject(null);
    @Input() public mappedItem$: BehaviorSubject<IUserMappedItem>;
    public isSaveDisabled$: Observable<boolean>;
    public isUserActive$ = new BehaviorSubject(false);
    public isReportDisabled$: Observable<boolean>;

    @Input() public set mappedItem(mappedItem: IUserMappedItem) {
        this.mappedItem$.next(mappedItem);
        this.isUserActive$.next(mappedItem.isActive);
    }

    public get mappedItem(): IUserMappedItem {
        return this.mappedItem$?.value;
    }

    public get activeComponent(): NonDialogFormViewDirective<any, any, any> {
        return this.activeComponent$?.value;
    }

    @Input() public set activeComponent(
        activeComponent: NonDialogFormViewDirective<any, any, any>
    ) {
        this.activeComponent$.next(activeComponent);
        this.isSaveDisabled$ =
            merge(this.activeComponent$?.value?.formMode$,
                this.activeComponent$.value?.baseForm?.valueChanges).pipe(
                map(_ => {
                    return this.activeComponent$?.value?.baseForm?.invalid ||
                            this.activeComponent$?.value?.baseForm?.pristine ||
                            Object.keys(this.activeComponent$?.value?.baseForm?.form?.controls).length < 1;
                })
            );
    }

    public collapsed$ = new BehaviorSubject<boolean>(false);

    constructor(
        public readonly permissionPermissions: PermissionsPermissions,
        private readonly navigationService: NavigationService,
        public readonly route: ActivatedRoute,
        public cdRef: ChangeDetectorRef,
        private readonly logicService: UsersLogicService,
    ) {
    }

    public ngOnInit(): void {
        this.isSaveDisabled$ =
            merge(this.activeComponent$?.value?.formMode$,
                this.activeComponent$.value?.baseForm?.valueChanges).pipe(
                map(_ => {
                    return this.activeComponent$?.value?.baseForm?.invalid ||
                            this.activeComponent$?.value?.baseForm?.pristine ||
                            Object.keys(this.activeComponent$?.value?.baseForm?.form?.controls).length < 1;
                })
            );
    }

    public ngAfterViewInit(): void {
        this.isReportDisabled$ = this.mappedItem$.asObservable().pipe(
            map(mappedItem => !mappedItem?.id)
        );
        this.cdRef.detectChanges();
    }

    public generateFullUserDetailReport(): void {
        this.mappedItem$?.value.generateFullUserDetailReport().subOnce(res =>
            this.cdRef.detectChanges());
    }

    public canView(): boolean {
        return this.permissionPermissions.canView();
    }

    public editItem(): void {
        this.activeComponent.onEditClicked();
        this.activeComponent.baseForm?.form.markAsPristine();
        this.activeComponent.formMode = FormMode.Edit;
        this.activeComponent.formMode$$ = FormMode.Edit;
    }

    public cancel(): void {
        if (this.activeComponent.formMode$.value === FormMode.Add) {
            this.navigationService.navigate(['users/search']);
        }

        this.activeComponent$?.value?.baseForm?.form.markAsPristine();
        this.activeComponent.formMode$$ = FormMode.View;
        this.activeComponent$.value.onCancelClicked();
        this.activeComponent.formMode = FormMode.View;
    }

    public async save(): Promise<void> {
        await this.activeComponent?.save();
        if (this.activeComponent instanceof UserDetailsComponent) {
            this.logicService.$getMappedItem(this.mappedItem.id).subscribe(mi => this.mappedItem = mi);
        }
    }

    public get canEditUsers(): boolean {
        return this.permissionPermissions.canEdit();
    }
}
