import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IVendorDto } from './interfaces/i.vendor.dto';
import { IVendorMappedItem } from './interfaces/i.vendor.mapped';
import { IVendorsLogicService } from './interfaces/i.vendors.logic.service';

export class VendorMappedItem
    extends BaseMappedItem<IVendorDto, IVendorMappedItem, IVendorsLogicService>
    implements IVendorMappedItem {
    @DtoProp public id: number;
    @DtoProp public legalEntityName: string;
    @DtoProp public legalName: string;
    @DtoProp public name: string;
    @DtoProp public tradingName: string;

    constructor(
        sourceData: IVendorDto,
        logicService: IVendorsLogicService
    ) {
        super(sourceData, logicService, VendorMappedItem);
    }
}
