import {BasePermissions, IBaseSystemAreaPermissions} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {BuildProgrammeTemplatePermissionEnum} from '../../shared/enums/permissions';

export interface IBuildProgrammeTemplatePermissions extends IBaseSystemAreaPermissions {
    canView(): boolean;
    canCreate(): boolean;
    canEdit(): boolean;
    canDuplicate(): boolean;
    canManage(): boolean;
    canViewHistory(): boolean;
    canViewAll(): boolean;
    canEditAll(): boolean;
}

@Injectable()
export class BuildProgrammeTemplatePermissions
    extends BasePermissions<BuildProgrammeTemplatePermissionEnum>
    implements IBuildProgrammeTemplatePermissions {
    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('buildingprogrammetemplate', BuildProgrammeTemplatePermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(BuildProgrammeTemplatePermissionEnum.CanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(BuildProgrammeTemplatePermissionEnum.CanCreate);
    }

    public canEdit(): boolean {
        return this.permissionObject(BuildProgrammeTemplatePermissionEnum.CanEdit);
    }

    public canDuplicate(): boolean {
        return this.permissionObject(BuildProgrammeTemplatePermissionEnum.CanDuplicate);
    }

    public canManage(): boolean {
        return this.permissionObject(BuildProgrammeTemplatePermissionEnum.CanManage);
    }

    public canViewHistory(): boolean {
        return this.permissionObject(BuildProgrammeTemplatePermissionEnum.CanViewHistory);
    }

    public canViewAll(): boolean {
        return this.permissionObject(BuildProgrammeTemplatePermissionEnum.CanViewAll);
    }

    public canEditAll(): boolean {
        return this.permissionObject(BuildProgrammeTemplatePermissionEnum.CanEditAll);
    }
}
