<mat-card class="tab-container flex flex-row">
    <mat-tab-group class="flex"
                   (selectedTabChange)="onTabChanged($event);"
                   [selectedIndex]="selectedIndex">
        <mat-tab label="Design Scheme">
            <cb-pricing-revisions-design-scheme-tab [mappedItem]="mappedItem"
                                                    [displayName]="false"></cb-pricing-revisions-design-scheme-tab>
        </mat-tab>
        <mat-tab *ngIf="!lotMappedItem?.hasScheduleSpecTemplate"
                 label="Specification">
            <ng-template matTabContent>
                <cb-pricing-revision-specification-tab *cbWaitFor="mappedItem && lotMappedItem"
                                                       [lot]="lotMappedItem"
                                                       [lotSpecVersion]="designSchemeLotSpecVersion"
                                                       [mappedItem]="mappedItem">
                </cb-pricing-revision-specification-tab>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="lotMappedItem?.hasScheduleSpecTemplate"
                 label="Specification">
            <ng-template matTabContent>
                <cb-lot-spec-view *cbWaitFor="lotMappedItem && mappedItem"
                                  [isSkinnyView]="true"
                                  [lotSpecVersion]="designSchemeLotSpecVersion"
                                  [lotMappedItem]="lotMappedItem"></cb-lot-spec-view>
            </ng-template>
        </mat-tab>
        <mat-tab label="Files">
            <cb-document *cbWaitFor="mappedItem"
                         [noCard]="true"
                         [entity]="mappedItem"
                         [permissions]="lotCostPermissions?.getDocumentPermissions()"
                         (documentsChange)="pricingRevisionTabsData.documents = $event"
                         (documentCountChange)="documentCount = $event"
                         [hideDeletedToggle]="true"
                         [hideRenewalColumn]="true"
                         [includeLinked]="true"
                         [enableCheckbox]="true"
                         [systemArea]="systemArea"></cb-document>
        </mat-tab>
        <mat-tab label="Questions ({{questionCount}})">
            <cb-generic-question-list *cbWaitFor="mappedItem"
                                      [entityId]="mappedItem.id"
                                      [systemArea]="systemArea"
                                      [lotId]="mappedItem.lotId"
                                      [edit]="mappedItem.canEdit"
                                      (questionCountChange)="questionCount = $event">
            </cb-generic-question-list>
        </mat-tab>
        <mat-tab label="Pricing History">
            <cb-pricing-revision-history-tab [entityId]="mappedItem.id"></cb-pricing-revision-history-tab>
        </mat-tab>
        <ng-container *ngIf="canViewEditPricingRevisionMargins">
            <mat-tab label="Margins">
                <cb-lot-costs-margins [pricingRevisionMappedItem]="mappedItem"></cb-lot-costs-margins>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</mat-card>
