<mat-card class="list-card flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon">
            <mat-icon class="cb-primary"
                      *ngIf="mappedItem?.contacts?.length > 0">
                check_circle
            </mat-icon>
            <mat-icon class="cb-error"
                      *ngIf="mappedItem?.contacts?.length < 1">
                error
            </mat-icon>
        </div>
        <mat-card-title>1. Client Details</mat-card-title>
    </mat-card-header>
    <mat-card-content class="start-start flex-col flex"
                      *ngIf="mappedItem?.contacts?.length > 0">
        <div class="flex-col"
             *ngFor="let contact of sortedMappedItemContacts; let last = last;"
             style="width: 100%;">
            <div position="relative">
                <mat-divider></mat-divider>
            </div>
            <div class="start-center flex-row"
                 style="padding: 16px 0">
                <mat-icon *ngIf="isMainContact(contact) && !contact.incomplete"
                          title="Main Contact"
                          class="cb-accent"
                          title="Main Contact">stars</mat-icon>
                <mat-icon *ngIf="!isMainContact(contact) && !contact.incomplete"
                          title="Other Contact">radio_button_unchecked</mat-icon>
                <mat-icon *ngIf="contact.incomplete"
                          class="cb-warn"
                          title="Incomplete">warning</mat-icon>
                <div class="flex-row flex"
                     style="margin-left: 12px">
                    <div class="flex-col">
                        <span class="mat-body-2">
                            &nbsp;{{contact.firstName}}&nbsp;{{contact.lastName}}
                        </span>
                        <span *ngIf="contact.preferredContactDetails"
                              class="mat-body-1 main-text">{{getPreferredContactDetails(contact.preferredContactDetails)}}
                            <div *ngIf="contact.preferredContactDetails.length > this.maxLength"
                                 class="additional-text">
                                {{contact.preferredContactDetails}}
                            </div>
                        </span>
                    </div>
                </div>
                <span class="flex"></span>
                <button [matMenuTriggerFor]="menu"
                        mat-icon-button>
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item
                            *ngIf="canChangeMainContact() && !isMainContact(contact)"
                            (cbClick)="updateMainContact($event, contact)">
                        <mat-icon>stars</mat-icon>
                        &nbsp;
                        Set as Main Contact
                    </button>
                    <button *ngIf="canRemoveContact() && !isMainContact(contact)"
                            mat-menu-item
                            (cbClick)="removeContact($event, contact)">
                        <mat-icon>remove_circle</mat-icon>
                        &nbsp;
                        Remove from Lead
                    </button>
                    <button mat-menu-item
                            (cbClick)="viewContact(contact)">
                        <mat-icon>pageview</mat-icon>
                        &nbsp;View Contact
                    </button>
                    <button *ngIf="canEditContact()"
                            mat-menu-item
                            (cbClick)="editContact(contact)">
                        <mat-icon>edit</mat-icon>
                        &nbsp;Edit Contact
                    </button>
                </mat-menu>
            </div>
            <div position="relative"
                 *ngIf="last">
                <mat-divider></mat-divider>
            </div>
        </div>
    </mat-card-content>
    <div position="relative"
         *ngIf="canEdit && !this.mappedItem.isQualified()">
        <mat-divider></mat-divider>
    </div>
    <mat-card-actions class="end-center flex-row"
                      *ngIf="canEdit && !this.mappedItem.isQualified()">
        <button mat-stroked-button
                color="accent"
                *ngIf="canAddContact()"
                (cbClick)="openAddContactDialog($event)">
            <mat-icon>add</mat-icon>
            &nbsp;Add Contact
        </button>
    </mat-card-actions>
</mat-card>
