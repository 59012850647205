import {IEnumLookup} from '@classictechsolutions/typescriptenums';
import {HttpWrapperService} from '../http-wrapper/http-wrapper.service';
import {Lookup} from './lookup';

interface ILookupObject {
    [key: string]: IEnumLookup<number>[];
}

export class BaseLookupService {

    private readonly cache: { [key: string]: IEnumLookup<number>[] } = {};

    private loadStarted = false;
    private resolver: (value?: void | PromiseLike<void>) => void;
    private readonly cachePromise = new Promise<void>((resolve) => {
        this.resolver = resolve;
    });

    constructor(protected readonly http: HttpWrapperService) { }

    /** loads/gets an individual cache item from the cache */
    public getLookupFromCache(key: string): Promise<IEnumLookup<number>[]> {
        this.initCache();
        return this.cachePromise.then(() => {
            return this.cache[key];
        });
    }

    protected defineLookup(key: string): Lookup {
        return new Lookup(this.loadFromCache.bind(this, key));
    }

    /** loads/gets an individual cache item from the cache */
    private readonly loadFromCache = (key: string): Promise<IEnumLookup<number>[]> => {
        this.initCache();
        return this.cachePromise.then(() => {
            return this.cache[key];
        });
    };

    /** loads lookups from api into cache if not started/finished already */
    private initCache(): void {
        if (this.loadStarted) {
            return;
        }
        this.loadStarted = true;
        this.http
            .get('/lookups')
            .subOnce((result: {
                lookups: ILookupObject; enums: ILookupObject;
            }) => {
                Object.assign(this.cache, result.lookups);
                Object.assign(this.cache, result.enums);
                this.resolver();
            });
    }
}
