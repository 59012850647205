import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ClientSaleLogicService, IClientSaleDto } from '@app/logic/client-sale';
import { CLIENT_SALE_SEARCH_ORDER_ENUM, CLIENT_SALE_STATUS_ENUM, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, Subject, Subscription } from 'rxjs';

import { CurrentUserService } from '@app/core/authentication/current.user';
import { ClientSalePermissions } from '@app/core/permissions';
import { IClientSaleSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { IClientSaleDocumentDto } from '@app/logic/client-sale/interfaces/I.client-sale-document.dto';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { CreateClientSaleDialogComponent } from '../dialogs/create-client-sale-dialog/create-client-sale-dialog.component';

@Component({
    selector: 'cb-my-client-sales-side-panel',
    templateUrl: './my-client-sales-side-panel.component.html',
    styleUrls: ['./my-client-sales-side-panel.component.scss']
})
export class MyClientSalesSidePanelComponent implements OnInit, OnDestroy {

    private _clientSaleUpdated: Subject<IClientSaleDto>;
    @Input() public set clientSaleUpdated(v: Subject<IClientSaleDto>) {
        if (v) {
            this.clientUpdatedSub$.unsubscribe();
            this._clientSaleUpdated = v;
            this.clientUpdatedSub$ = this.clientSaleUpdated.subscribe(this.updateClientSaleResult);
        }
    }
    public get clientSaleUpdated(): Subject<IClientSaleDto> {
        return this._clientSaleUpdated;
    }

    @Input() public readonly currentClientSaleId: number | null;
    @Output() public readonly currentClientSaleIdChange = new EventEmitter<number>();

    public collapsed = false;
    public readonly CLIENT_SALE_STATUS_ENUM = CLIENT_SALE_STATUS_ENUM;
    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    public readonly clientSaleStatuses = CLIENT_SALE_STATUS_ENUM.toLookup()
        .filter(x => x.id !== CLIENT_SALE_STATUS_ENUM.NotPendingJobLost && x.id !== CLIENT_SALE_STATUS_ENUM.None);
    public readonly orders = [
        { label: 'Client Sale Age (Newest > Oldest)', id: CLIENT_SALE_SEARCH_ORDER_ENUM.ClientSaleAgeDesc },
        { label: 'Client Sale Age (Oldest > Newest)', id: CLIENT_SALE_SEARCH_ORDER_ENUM.ClientSaleAgeAsc },
        { label: 'Last Updated (Recent > Oldest)', id: CLIENT_SALE_SEARCH_ORDER_ENUM.LastUpdatedDesc },
        { label: 'Last Updated (Oldest > Recent)', id: CLIENT_SALE_SEARCH_ORDER_ENUM.LastUpdatedAsc },
        { label: 'Client Account Name (A - Z)', id: CLIENT_SALE_SEARCH_ORDER_ENUM.ClientSaleAccountNameAsc },
        { label: 'Client Account Name (Z - A)', id: CLIENT_SALE_SEARCH_ORDER_ENUM.ClientSaleAccountNameDesc },
    ];

    public get userCacheItem(): UserCacheItem<IClientSaleSearch> {
        return this.userCacheService.myClientSalesSearch;
    }

    private clientUpdatedSub$ = new Subscription();
    private readonly subscription$ = new Subscription();

    public readonly queryUpdate = new Subject();
    public currentPage = 1;
    public results: IClientSaleDocumentDto[] = [];
    public searchEnabled = false;

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly clientSaleLogic: ClientSaleLogicService,
        public readonly permissions: ClientSalePermissions,
        public readonly currentUser: CurrentUserService,
        private readonly userCacheService: UserCacheService,
    ) {
    }

    public ngOnInit(): void {
        Promise.all([
            this.userCacheItem.init(),
            this.currentUser.$promise,
        ]).then(() => {
            this.searchEnabled = true;
            this.queryUpdated();
            this.subscription$.add(
                this.userCacheItem.updated$.subscribe({
                    next: this.queryUpdated
                })
            );
        });
    }

    public fetchResults(): Observable<ISearchResult<IClientSaleDocumentDto>> {
        if (!this.searchEnabled) {
            return;
        }
        const params = this.getQueryParams();
        return this.clientSaleLogic.search(params.query, this.currentPage, params);
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    private readonly queryUpdated = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.queryUpdate.next(null);
    };

    public getQueryParams(): IClientSaleSearch {
        if (!this.searchEnabled) {
            return;
        }
        const data = this.userCacheItem.copyData();
        return {
            query: data.query,
            buildingConsultants: [this.currentUser.guid],
            order: data.order,
            jobStatus: data.jobStatus,
            ignoreEmptyQuery: false,
        } as IClientSaleSearch;
    }

    public loadClientSale(clientSaleId: number): void {
        this.currentClientSaleIdChange.emit(clientSaleId);
    }

    public createClientSale(): void {
        this.cbDialog
            .open(CreateClientSaleDialogComponent, {
                minWidth: '40%',
            })
            .afterClosed()
            .subOnce((result: IClientSaleDto | false) => {
                if (!result) {
                    return;
                }
                this.results.unshift(this.mapDtoToSearchDto(result));
                this.loadClientSale(result.id);
            });
    }

    private readonly updateClientSaleResult = (result: IClientSaleDto) => {
        const index = this.results.findIndex(x => x.id === result.id);
        if (index > -1) {
            this.results[index] = this.mapDtoToSearchDto(result);
        }
    };

    private mapDtoToSearchDto(result: IClientSaleDto): IClientSaleDocumentDto {
        return {
            id: result.id,
            clientAccountName: result.clientAccount?.name,
            lotStreet: result.lot?.address?.street,
            lotSuburb: result.lot?.address?.suburb,
            lotCity: result.lot?.address?.city,
            region: result.lot?.address?.regionName,
            buildingConsultant: result.buildingConsultantName,
            saleNumber: result.saleNumber,
            saleNumberString: `Client Sale ${result.saleNumber}`,
            locationId: result.lot?.address?.id,
            statusDisplay: result.statusLabel,
            status: result.statusId,
            sunsetDate: result.sunsetDate,
            contractGenerationFeeDueDate: null,
            contractGenerationFeePaidDate: null,
            lotNumber: result.lot?.lotNumber,
            lotJobNumber: result.lot?.jobNumber,
            isStaffMember: result.clientAccount?.staffMemberExists,
            businessEntityId: null,
            buildingConsultantId: result.buildingConsultantId,
            buildingCoordinatorId: null,
            contractType: result.lot?.lotContractTypeId,
            updatedDate: result.updatedDate,
            createdDate: result.createdDate,
        } as IClientSaleDocumentDto;
    }
}
