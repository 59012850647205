<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="tooltipMessage.value">
            <mat-icon [ngClass]="cardIconClass.value">{{cardIcon.value}}</mat-icon>
        </div>
        <mat-card-title>{{stepNumber}}. Classic Care</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex flex-col">
        <cb-display-value-new label="Contact"
                              [value]="clientSale?.classicCareContactName"></cb-display-value-new>
        <cb-display-value-new label="Preferred Contact"
                              [value]="clientSale?.preferredContact"></cb-display-value-new>
    </mat-card-content>
    <mat-card-actions class="end-center flex-row">
        <button mat-stroked-button
                color="accent"
                *ngIf="permissions?.canEdit()"
                [disabled]="!clientSale?.canEditClassicCare"
                (click)="openEditDialog()">
            <mat-icon>edit</mat-icon>
            Edit
        </button>
    </mat-card-actions>
</mat-card>
