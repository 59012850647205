<div class="end-center flex-row"
     style="height: 40px;">
    <button mat-icon-button
            (click)="cancel()">
        <mat-icon class="button-icon">close</mat-icon>
    </button>
</div>
<div class="center-center flex-row flex"
     style="height: 100%; padding: 0px 50px;">
    <div class="center-center"
         *ngIf="images?.length > 1">
        <button mat-icon-button
                (click)="previousImage()">
            <mat-icon class="button-icon">chevron_left
            </mat-icon>
        </button>
    </div>
    <ng-container *ngIf="!images || images.length < 2">
        <img [src]="imgUrl"
             class="current-image"
             alt="Current Image"/>
    </ng-container>
    <ng-container *ngIf="images && images.length > 1">
        <ng-container *ngFor="let img of images; let i = index">
            <ng-container *ngIf="imgIndex === i">
                <img [src]="img"
                     class="current-image"
                     alt="Current Image"/>
            </ng-container>
        </ng-container>
    </ng-container>
    <div class="center-center"
         *ngIf="images?.length > 1">
        <button mat-icon-button
                (click)="nextImage()">
            <mat-icon class="button-icon">chevron_right
            </mat-icon>
        </button>
    </div>
</div>
