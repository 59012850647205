import {BasePermissions, IBaseSystemAreaPermissions} from './base.permissions';

import {CurrentUserService} from '../authentication/current.user';
import {DesignTeamPermissionEnum} from '@app/shared/enums/permissions';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

export interface IDesignTeamPermissions extends IBaseSystemAreaPermissions {
    canSeeComponent(): boolean;
    canView(): boolean;
    canSeeAll(): boolean;
    canEditScheme(): boolean;
    canEditWorkingDrawing(): boolean;
    canEditRfi(): boolean;
    canViewDesignTeamReports(): boolean;
    canRejectWorkingDrawingRequest(): boolean;
}

@Injectable()
export class DesignTeamPermissions
    extends BasePermissions<DesignTeamPermissionEnum>
    implements IDesignTeamPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('designteam', DesignTeamPermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanView);
    }

    public canSeeAll(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanSeeAll);
    }

    public canEditScheme(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanEditScheme);
    }

    public canEditWorkingDrawing(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanEditWorkingDrawing);
    }

    public canEditRfi(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanEditRfi);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanView);
    }

    public canRejectPreConsentPlans(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanRejectPreConsentPlan);
    }

    public canReviewPreConsentPlans(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanReviewPreConsentPlan);
    }

    public canEdit(): boolean {
        throw new Error('Method not implemented.');
    }
    public canCreate(): boolean {
        throw new Error('Method not implemented.');
    }

    public canViewDesignTeamReports(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanViewDesignTeamReports);
    }

    public canRejectWorkingDrawingRequest(): boolean {
        return this.permissionObject(DesignTeamPermissionEnum.CanRejectWorkingDrawingRequest);
    }

}
