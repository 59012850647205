import { ChangeDetectionStrategy, Component, NgZone, OnDestroy } from '@angular/core';
import { DesignTeamPermissions } from '@app/core/permissions';
import { TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { of, Subscription } from 'rxjs';

@Component({
    selector: 'cb-design-team-area',
    templateUrl: './design-team-area.component.html',
    styleUrls: ['./design-team-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignTeamAreaComponent implements OnDestroy {

    public readonly DESIGN_TEAM_ROUTE = 'teams/design/:cbTabRouteId';
    public selectedTabIndex: number;
    public TEAM_CODES_CONST = TEAM_CODES_CONST;
    public isViewInit = false;

    private _subscriptions = new Subscription();
    private delayLoadDataTimeout = 500;

    constructor(
        public readonly designTeamPermissions: DesignTeamPermissions,
        private readonly ngZone: NgZone,
    ) {
    }

    public ngAfterViewInit(): void {
        this.ngZone.run(() => {
            setTimeout(() => {
                this.isViewInit = true;
            }, this.delayLoadDataTimeout);
        });
    }

    public resultDirections$ = of([
        { id: 'asc', label: 'Ascending' },
        { id: 'desc', label: 'Descending' },
    ]);

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public onTabChanged(selectedTabIndex: number): void {
        this.selectedTabIndex = selectedTabIndex;
    }
}
