import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LotBuildPermissions } from '@app/core/permissions';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { LotsLogicService } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import {
    BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM,
    CONSTRUCTION_MANAGER_ROLE_ENUM,
    ConstructionManagerRoleEnumId,
    IBuildProgrammeIndexedDto,
    ILotDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import moment from 'moment';

@Component({
    selector: 'cb-lot-build-programme-steps',
    templateUrl: './lot-build-programme-steps.component.html',
    styleUrls: ['./lot-build-programme-steps.component.scss']
})
export class LotBuildProgrammeStepsComponent {

    @Input() public buildProgramme: IBuildProgrammeIndexedDto;
    @Input() public lotDto: ILotDto;
    @Output() public reloadLotMappedItem = new EventEmitter();

    constructor(
        private readonly lotBuildPermissions: LotBuildPermissions,
        private readonly currentUser: CurrentUserService,
        private readonly buildProgrammeLogicService: BuildProgrammeLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly lotsLogicService: LotsLogicService,
    ) { }

    public canSetQSApproval(): boolean {
        return this.lotBuildPermissions.canSetQSApproval();
    }

    public canGenerateBuildProgrammeDocument(): boolean {
        return this.lotBuildPermissions.canGenerateBuildProgrammeDocument();
    }

    public buildProgrammeDocumentDisabled(): boolean {
        return !this.lotDto.hasBuildProgrammeGenerated;
    }

    public generateBuildProgrammeDocument(): void {
        this.buildProgrammeLogicService.generateBuildProgrammeReport(this.lotDto.id, this.lotDto.hasScheduleSpecTemplate).subOnce((result) => {
            this.lotDto.hasYourBuildProgramme = (result) ? true : false;
        });
    }

    public confirmGrantQSConsentTakeoffGranted(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Grant Takeoff Approval',
            message: 'Are you sure you want to grant Takeoff Approval for this Build Programme?',
            confirmed: () => {
                this.lotDto.qsConsentTakeoffGranted = true;
                const blockedDialog = this.cbDialog.block('Granting approval, please wait...');
                this.lotsLogicService.$saveItem(this.lotDto).subOnce((result) => {
                    if (result) {
                        this.lotDto.qsConsentedTakeoffGrantedBy = result.qsConsentedTakeoffGrantedBy;
                        this.lotDto.qsConsentedTakeoffGrantedByDate = result.qsConsentedTakeoffGrantedByDate;
                    }
                    blockedDialog.close();
                    this.reloadLotMappedItem.emit();
                });
            }
        });
    }

    public constructionManagerChanged(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Confirm Switch Construction Manager',
            message: 'Are you sure you want to switch construction manager?',
            confirmed: () => {
                const blockedDialog = this.cbDialog.block('Switching construction manager, please wait...');
                const role: ConstructionManagerRoleEnumId = CONSTRUCTION_MANAGER_ROLE_ENUM.FramingManager;
                this.lotsLogicService.setConstructionManagerRole(this.lotDto.id, role).subOnce((result) => {
                    this.buildProgramme.constructionManager = result.cosntructionManagerName;
                    this.lotDto.floorDownUserName = result.floorDownUserName;
                    this.lotDto.floorDownUserId = result.floorDownUserId;
                    this.lotDto.hasFloorDownDate = moment(result.hasFloorDownDate).format('D MMMM YYYY h:mm a');
                    this.lotDto.hasFloorDown = true;
                    blockedDialog.close();
                    this.reloadLotMappedItem.emit();
                });
            }
        });
    }

    public confirmGenerateCPC(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Set Practical Completion',
            message: 'Are you sure you want to set Practical Completion for this Lot?',
            confirmed: () => {
                this.lotsLogicService.generateCpc(this.lotDto.id).subOnce((result) => {
                    if (result) {
                        this.lotDto.hasPracticalCompletion = true;
                        this.reloadLotMappedItem.emit();
                    }
                });
            }
        });
    }

    public setLotHandedOver(): void {
        const blockedDialog = this.cbDialog.block('Completing Handover, please wait...');
        this.lotsLogicService.setLotHandedOver(this.lotDto.id).subOnce((result) => {
            this.lotDto.isHandedOver = result ? true : false;
            blockedDialog.close();
            this.reloadLotMappedItem.emit();
        });
    }

    public canGrantQSConsentTakeoff(): boolean {
        return !!this.lotDto
            && this.lotDto.hasCurrentTakeOff
            && this.lotDto.qsConsentTakeoffGranted !== true
            && this.currentUser.isQSTeam()
            && this.lotDto.hasBuildProgrammeGenerated;
    }

    public canGenerateCPC(): boolean {
        if (!this.buildProgramme || !this.buildProgramme.stages) {
            return false;
        } else {
            return Object.values(this.buildProgramme.buildProgrammeActivities).every(
                act => act.statusId === BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Complete ||
                    act.statusId === BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned ||
                    act.activity.excludeFromPracticalCompletion
            );
        }
    }

    public getConstructionManagerRole(): string {
        if (this.lotDto.constructionManagerRoleId === CONSTRUCTION_MANAGER_ROLE_ENUM.FoundationManager) {
            return 'Foundation Manager';
        } else if (this.lotDto.constructionManagerRoleId === CONSTRUCTION_MANAGER_ROLE_ENUM.FramingManager) {
            return 'Framing Manager';
        } else if (this.lotDto.constructionManagerRoleId === CONSTRUCTION_MANAGER_ROLE_ENUM.None) {
            return '';
        }
    }

}
