import { MonoTypeOperatorFunction, Observable, Operator, Subscriber, TeardownLogic } from 'rxjs';

export type predicateType<T> = (value: T, index: number) => boolean;

class TakeWhileInclusiveSubscriber<T> extends Subscriber<T> {
    private index = 0;

    constructor(
        protected destination: Subscriber<T>,
        private readonly predicate: predicateType<T>,
    ) {
        super(destination);
    }

    protected _next(value: T): void {
        const destination = this.destination;
        let result: boolean;
        try {
            result = this.predicate(value, this.index++);
        } catch (err) {
            destination.error(err);
            return;
        }

        destination.next(value);

        if (!result) {
            destination.complete();
        }
    }
}

class TakeWhileInclusiveOperator<T> implements Operator<T, T> {
    constructor(private readonly predicate: predicateType<T>) {
    }

    public call(subscriber: Subscriber<T>, source: any): TeardownLogic {
        return source.subscribe(new TakeWhileInclusiveSubscriber(subscriber, this.predicate));
    }
}
export function takeWhileInclusive<T>(predicate: predicateType<T>): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => source.lift(new TakeWhileInclusiveOperator(predicate));
}
