import { takeOne } from 'cb-hub-lib';
import { NavigationService } from './../../../../../../core/services/navigation/navigation.service';
import { BaseInputFindDirective } from '../../base-input-find.directive';
import { ComponentType } from '@angular/cdk/portal';
import { Directive } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { VendorsInputFindDialogDirective } from '../../dialog/extensions/vendors/vendors-input-find-dialog-directive';
import { VendorsLogicService } from '@app/logic/vendors/vendors.logic.service';
import { IVendorDto } from '@app/logic/vendors/interfaces/i.vendor.dto';
import { VendorSearchService } from '@app/core/services/search/vendor.search.service';

@Directive()
export class VendorsInputFindDirective extends BaseInputFindDirective {
    protected _myDialog: ComponentType<VendorsInputFindDialogDirective>;
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly vendorsLogicService: VendorsLogicService,
        public readonly navigationService: NavigationService,
        public readonly searchService: VendorSearchService) {
        super();
    }

    public openSearchDialog = (): void => {
        this.dialogRef.open(
            this._myDialog,
            {
                minWidth: '95%',
                data: {
                    extraSearchParams: this.searchParams,
                    placeholder: 'Type in Legal Name or Business Entity Name',
                    dialogHeading: 'Choose Vendor',
                    contentHeading: 'Search Client Accounts and Business Entities',
                    searchService: this.searchService
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .pipe(takeOne())
            .subscribe({
                next: (vendor: IVendorDto) => {
                    if (vendor.legalName) {
                        this.text = vendor.legalName;
                        this.value = vendor;
                    } else {
                        this.text = vendor.legalEntityName;
                        this.value = vendor;
                    }
                }
            });
    };
}
