<mat-form-field class="flex"
                [ngClass]="{'cb-soft-required': softRequired && !required }"
                *ngIf="!readonly">
    <div class="start-center flex-row">
        <div class="flex-col">
            <mat-icon [svgIcon]="svgIcon"
                      *ngIf="svgIcon"
                      class="cb-margin-right"></mat-icon>
            <mat-icon *ngIf="icon"
                      class="cb-margin-right">{{icon}}</mat-icon>
        </div>
        <input matInput
               class="input cb-margin-right"
               [name]="internalName"
               [(ngModel)]="value"
               [ignoreDirty]="ignoreDirty"
               [type]="type"
               [placeholder]="label"
               [required]="required"
               [email]="type === 'email'"
               [maxlength]="maxlength"
               [autocomplete]="autocomplete"
               [pattern]="pattern"
               [disabled]="disabled"
               [maskType]="maskType"
               (keydown.enter)="enterPressed($event)"/>
        <span #suffixEle
              class="suffix mat-body-1">{{suffix}}</span>
    </div>
</mat-form-field>
<div class="flex"
     *ngIf="readonly">
    <cb-display-value-new class="flex"
                          [label]="label">
        <div class="start-center flex-row">
            <div class="flex-col">
                <mat-icon [svgIcon]="svgIcon"
                          *ngIf="svgIcon"
                          class="cb-margin-right"></mat-icon>
                <mat-icon *ngIf="icon"
                          class="cb-margin-right">{{icon}}</mat-icon>
            </div>
            {{value}}
            <span #suffixEle
                  class="suffix mat-body-1">{{suffix}}</span>
        </div>
    </cb-display-value-new>
</div>
