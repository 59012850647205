import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
    selector: ` input[matAutocomplete][isInvalid][ngModel],
                cb-product-autocomplete-scroller[isInvalid][ngModel][matchProps],
            `,
    providers: [
        { provide: NG_VALIDATORS, useExisting: IsInvalidValidatorDirective, multi: true }
    ]
})
export class IsInvalidValidatorDirective {
    private _isInvalid: boolean;

    @Input() public set isInvalid(newValue: boolean) {
        this._isInvalid = newValue;
        if (this.gControl) {
            this.gControl.updateValueAndValidity();
        }
    }

    public get isInvalid(): boolean {
        return this._isInvalid;
    }

    public gControl: AbstractControl;

    public validate(control: AbstractControl): ValidationErrors | null {
        this.gControl = control;
        if (this.isInvalid === false) {
            return null;
        }

        return { isInvalid: 'Entry is invalid' };
    }
}
