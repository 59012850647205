
import { Component } from '@angular/core';
import { ToastService } from '@app/core/services/toast/toast.service';

@Component({
    selector: 'cb-toasts',
    templateUrl: './toasts.component.html',
    styleUrls: ['./toasts.component.scss'],
})
export class ToastsComponent {
    constructor(
        public toastService: ToastService
    ) {
    }
}
