import { TASK_TYPE_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ITaskOutcomeDto } from '../task-outcomes/interfaces/i.task-outcome.dto';
import { ICloseTaskDto } from './interfaces/i.close-task.dto';
import { ITaskDto } from './interfaces/i.Task.dto';
import { ITaskMappedItem } from './interfaces/i.task.mapped';
import { ITasksLogicService } from './interfaces/i.tasks.logic.service';

export class TaskMappedItem
    extends BaseMappedItem<ITaskDto, ITaskMappedItem, ITasksLogicService>
    implements ITaskMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public subject: string;
    @DtoProp public comments: string;
    @DtoProp public assignedToId: string;
    @DtoProp public assignedToName: string;
    @DtoProp public assignedToTeamId: number;
    @DtoProp public assignedToTeamKey: string;
    @DtoProp public assignedToTeamName: string;
    @DtoProp public outcomeNotes: string;
    @DtoProp public outcomeLabel: string;
    @DtoProp public outcomeId: number;
    @DtoProp public taskType: string;
    @DtoProp public location: string;
    @DtoProp public isActive: boolean;
    @DtoProp public dueDate: DateString;
    @DtoProp public isClosed: boolean;
    @DtoProp public closedDate: DateString;
    @DtoProp public closedByName: string;
    @DtoProp public createdDate: DateString;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: DateString;
    @DtoProp public updatedByName: string;

    @DtoProp public entityUri: string;
    @DtoProp public entityId: number | string;

    constructor(
        sourceData: ITaskDto,
        logicService: ITasksLogicService
    ) {
        super(sourceData, logicService, TaskMappedItem);
    }

    public get isMeeting(): boolean {
        return this.taskType === TASK_TYPE_CONSTANTS_CONST.MeetingTaskType;
    }

    public get isGeneralTask(): boolean {
        return this.taskType === TASK_TYPE_CONSTANTS_CONST.GeneralTaskType;
    }

    public get isCallback(): boolean {
        return this.taskType === TASK_TYPE_CONSTANTS_CONST.CallbackTaskType
            || this.taskType === TASK_TYPE_CONSTANTS_CONST.FollowUpTaskType;
    }

    public saveTask(): Observable<ITaskDto> {
        return this.$logicService.saveTask(this.entityUri, this.entityId, this.$getMappedDtoItem())
            .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
    }

    public closeTask(scheduleFollowUp = false, followUpTask?: ITaskDto): Observable<ICloseTaskDto> {
        const dto: ICloseTaskDto = {
            closed: this.$getMappedDtoItem(),
            created: null,
        };

        if (scheduleFollowUp) {
            dto.created = {
                isActive: true,
                taskType: this.taskType,
                assignedToId: this.assignedToId,
                ...followUpTask
            } as ITaskDto;
        }

        return this.$logicService.closeTask(this.entityUri, this.entityId, dto)
            .pipe(tap(result => result && this.$updateThisAndOriginal(result.closed)));
    }

    public getPossibleTaskOutcomes(): Observable<ITaskOutcomeDto[]> {
        return this.$logicService.getPossibleTaskOutcomes(this.id);
    }
}
