export enum ProductPermissionEnum {
    None = 0x00,
    CanSearch = 0x01 << 0,
    CanView = 0x01 << 1,
    CanCreate = 0x01 << 2,
    CanEdit = 0x01 << 3,
    CanManageAvailability = 0x01 << 4,
    ImagesCanManage = 0x01 << 5,
    RatesCanView = 0x01 << 6,
    RatesCanManage = 0x01 << 7,
    CategoriesCanManage = 0x01 << 8,
    SearchResultsCanExport = 0x01 << 9,
    CatalogueCanExport = 0x01 << 10,
    CanImport = 0x01 << 11,
    CanViewDocuments = 0x01 << 12,
    FilesCanUpload = 0x01 << 13,
    HistoryCanView = 0x01 << 14,
    ProductUsageCanView = 0x01 << 15,
    CanManageProductCatalogueExports = 0x01 << 16,
    CanManageBuildOverheadCosts = 0x01 << 17,
}
