import { LocationLogicService } from './../../../logic/location/location.logic.service';
import { MatButton } from '@angular/material/button';
import { UsersLogicService } from './../../../logic/users/users.logic.service';
import { ClientSalePermissions } from './../../../core/permissions/clientsale.permission';
import { ClientSaleLogicService } from './../../../logic/client-sale/client-sale-logic.service';
import { IClientSaleSearch } from '@app/core/services/user-cache/user-cache-areas';
import { ISearchResult } from '@app/shared/components/search/i.search';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { ClientSaleStatusEnumId, CLIENT_SALE_STATUS_ENUM, LOT_CONTRACT_TYPE_ENUM, USER_TAG_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { IUserDto } from '@app/logic/users';
import { UntypedFormControl } from '@angular/forms';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { remove } from 'lodash';
import { ILocationDto } from '@app/logic/location';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import FileSaver from 'file-saver';
import { IClientSaleDto } from '@app/logic/client-sale';
import { IClientSaleDocumentDto } from '@app/logic/client-sale/interfaces/I.client-sale-document.dto';
import moment from 'moment';

@Component({
    selector: 'cb-client-sale-search',
    templateUrl: './client-sale-search.component.html',
    styleUrls: ['./client-sale-search.component.scss'],
    providers: [MatButton]
})
export class ClientSaleSearchComponent implements OnInit, OnDestroy {
    public buildingConsultants: IUserDto[];
    public searchQueryUpdate = new Subject<string>();
    public jobStatuses: IEnumLookup<ClientSaleStatusEnumId>[];
    private readonly subscription$ = new Subscription();
    public results: any[] = [];
    public readonly queryUpdate = new Subject();
    public CLIENT_SALE_STATUS_ENUM = CLIENT_SALE_STATUS_ENUM;

    public searchText = new UntypedFormControl();
    public mappedItem: any;

    public regions: ILocationDto[];
    public areas: ILocationDto[];
    public districts: ILocationDto[];
    public notSelected: ILocationDto;
    public contractTypes: SelectListItem[];
    public canIncludeLost: boolean;

    public get userCacheItem(): UserCacheItem<IClientSaleSearch> {
        return this.userCacheService.clientSaleSearch;
    }
    public currentPage = 1;
    public searchEnabled = false;
    public readonly subscriptions = new Subscription();

    constructor(
        public readonly navigationService: NavigationService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly clientSalePermissions: ClientSalePermissions,
        public readonly matDialog: MatDialog,
        private readonly clientSaleLogicService: ClientSaleLogicService,
        private readonly locationLogicService: LocationLogicService,
        private readonly usersLogicService: UsersLogicService,
        public readonly toastService: ToastService,
        public readonly userCacheService: UserCacheService,
    ) {
        this.contractTypes = LOT_CONTRACT_TYPE_ENUM.toSelectList();
    }

    public ngOnInit(): void {
        this.jobStatuses = CLIENT_SALE_STATUS_ENUM.toLookup({
            transform: (arr) => {
                arr.unshift({ label: 'All' } as any);
                remove(arr, item => item.id === CLIENT_SALE_STATUS_ENUM.None);
                return arr;
            }
        });
        this.clientSalePermissions.observable.subOnce(_ => {
            this.loadBuildingConsultants().subOnce(consultants => {
                this.buildingConsultants = consultants;
            });
        });
        this.initSearch();
    }

    public ngOnDestroy() {
        this.subscription$.unsubscribe();
    }

    public cleanSearch(): void {
        this.searchEnabled = true;
        this.queryUpdate.next(null);
    }

    public clearFilter(): void {
        this.districts = [this.notSelected];
        this.areas = [this.notSelected];
        this.userCacheItem.silentData.selectedRegionId = undefined;
        this.userCacheItem.silentData.selectedDistrictId = undefined;
        this.userCacheItem.silentData.selectedRegionId = undefined;
        this.selectCurrentLocation(undefined, true);
    }

    public onRegionSelected(shouldSave = false) {
        this.selectCurrentLocation(this.userCacheItem.silentData.selectedRegionId, shouldSave);
        this.userCacheItem.silentData.selectedDistrictId = undefined;
        this.userCacheItem.silentData.selectedAreaId = undefined;
        this.getLocations(this.userCacheItem.silentData.selectedRegionId).subOnce((districts) => {
            remove(districts, { id: this.notSelected.id });
            this.districts = districts;
        });
        this.areas = [this.notSelected];
    }

    public onDistrictSelected(shouldSave = false): void {
        if (this.userCacheItem.silentData.selectedDistrictId) {
            this.selectCurrentLocation(this.userCacheItem.silentData.selectedDistrictId, shouldSave);
            this.getLocations(this.userCacheItem.silentData.selectedDistrictId).subOnce((areas) => {
                remove(areas, { id: this.notSelected.id });
                this.areas = areas;
            });
        } else {
            this.selectCurrentLocation(this.userCacheItem.silentData.selectedRegionId, shouldSave);
            this.areas = [this.notSelected];
        }
        this.userCacheItem.silentData.selectedAreaId = undefined;
    }

    public onAreaSelected(shouldSave = false): void {
        let currentLocation = this.userCacheItem.silentData.selectedDistrictId;

        if (this.userCacheItem.silentData.selectedAreaId) {
            currentLocation = this.userCacheItem.silentData.selectedAreaId;
        }

        this.selectCurrentLocation(currentLocation, shouldSave);
    }

    public initSearch(): void {
        this.userCacheItem.init().then(() => {
            this.searchEnabled = true;
            this.notSelected = this.locationLogicService.$createMappedItem({ id: 0, isActive: true, label: 'None' } as ILocationDto);
            this.districts = [this.notSelected];
            this.areas = [this.notSelected];
            this.reloadRegions();
            this.queryUpdated();
            this.subscription$.add(
                this.userCacheItem.updated$.subscribe({
                    next: this.queryUpdated
                })
            );
        });
    }

    public hasPermissionsToViewClientSale = (): boolean => {
        return this.clientSalePermissions.canView();
    };

    public fetchResults(): Observable<ISearchResult<IClientSaleDocumentDto>> {
        if (!this.searchEnabled) {
            return;
        }
        // const params = this.getQueryParams();
        const params = this.userCacheItem.copyData();
        return this.clientSaleLogicService.search(this.searchText.value, this.currentPage, params);
    }

    public shouldShowWarning(job) {
        if (job.contractGenerationFeePaidDate) {
            return false;
        } else {
            if (job.contractGenerationFeeDueDate) {
                const dueDate = moment(job.contractGenerationFeeDueDate).format();
                return moment(dueDate).isBefore(moment.now());
            } else {
                // no due date
                return false;
            }
        }
    }

    private readonly queryUpdated = (): void => {
        this.updateCanIncludeLost();
        if (!this.searchEnabled) {
            return;
        }
        this.queryUpdate.next(null);
    };

    public contractTypesChanged(selectMultipleElement: UntypedFormControl) {
        this.userCacheItem.data.contractTypes = selectMultipleElement.value;
    }

    public reloadRegions() {
        this.locationLogicService.getLocationList(0).subOnce(result => {
            this.regions = result;
            this.reloadDistricts();
        });
    }

    public exportToCsv(): void {
        this.clientSaleLogicService.export(this.userCacheItem.copyData()).subOnce({
            next: (result: ArrayBuffer) => {
                const date = moment().format('YYYY-MM-DD');
                FileSaver.saveAs(new Blob([result]), `ClientSale_${date}.csv`);
            },
            error: (e) => {
                console.error(e);
            }
        });

    }

    public selectCurrentLocation(locationId: number, shouldSave = false) {
        if (shouldSave) {
            this.userCacheItem.data.locationId = locationId as any;
        } else {
            this.userCacheItem.silentData.locationId = locationId as any;
        }
    }

    public getLocations(id?: number): Observable<ILocationDto[]> {
        return this.locationLogicService.getLocationList(id);
    }

    public reloadDistricts() {
        if (this.userCacheItem.silentData.selectedDistrictId !== 0) {
            this.selectCurrentLocation(this.userCacheItem.silentData.selectedDistrictId);
            this.getLocations(this.userCacheItem.silentData.selectedRegionId).subOnce(districts => {
                remove(districts, { id: this.notSelected.id });
                this.districts = districts;
                this.reloadAreas();
            });
        }
    }

    public reloadAreas() {
        if (this.userCacheItem.silentData.selectedAreaId !== 0) {
            this.selectCurrentLocation(this.userCacheItem.silentData.selectedAreaId);
            this.getLocations(this.userCacheItem.silentData.selectedDistrictId).subOnce(areas => {
                remove(areas, { id: this.notSelected.id });
                this.areas = areas;
            });
        }
    }

    public selectedStatusesChanged(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.data.jobStatus = selectMultipleElement.value;
        this.queryUpdated();
    }

    public viewClientSale = (clientSale: IClientSaleDto, event: MouseEvent): void => {
        this.navigationService.navigate([`clientsales/view/${clientSale.id}/details`], event);
    };

    public loadBuildingConsultants(): Observable<IUserDto[]> {
        if (this.clientSalePermissions.canView()) {
            return this.usersLogicService.getListByTag({ tag: USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT });
        }
    }

    public selectedBuildConsultantIdsChanged(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.data.buildingConsultants = selectMultipleElement.value;
        this.queryUpdated();
    }

    private updateCanIncludeLost() {
        this.canIncludeLost = (!this.userCacheItem.silentData.jobStatus);
        if (!this.canIncludeLost) {
            this.userCacheItem.silentData.includeLostJobs = false;
        }
    }
}
