import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CbInfoMessageModule } from 'projects/cb-hub-lib/src/lib/components/info-message/info-message.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CbDasherModule } from 'projects/cb-hub-lib/src/lib/pipe/dasher/dasher.module';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbPopupTipModule } from '@app/shared/components/popup-tip/popup-tip.module';
import { CbButtonModule } from '@app/shared/components/button/button.module';
import { CbTableModule } from 'projects/cb-hub-lib/src/lib/components/table/table.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { WorkingDrawingSearchComponent } from './working-drawing-search/working-drawing-search.component';
import { WorkingDrawingCardComponent } from './working-drawing-card/working-drawing-card.component';
import { CbInfiniteScrollModule } from 'projects/cb-hub-lib/src/lib/directives/infinite-scroll/infinite-scroll.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { WorkingDrawingDialogComponent } from './working-drawing-dialog/working-drawing-dialog.component';
import { WorkingDrawingSchemeDetailsComponent } from './working-drawing-scheme-details/working-drawing-scheme-details.component';
import { WorkingDrawingsAssignedChangesComponent } from './working-drawings-assigned-changes/working-drawings-assigned-changes.component';
import { DragulaModule } from 'ng2-dragula';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WorkingDrawingsComponent } from './working-drawings/working-drawings.component';
import { WorkingDrawingViewComponent } from './working-drawing-view/working-drawing-view.component';
import { WorkingDrawingActionBarComponent } from './working-drawing-action-bar/working-drawing-action-bar.component';
import { WorkingDrawingDetailsComponent } from './working-drawing-details/working-drawing-details.component';
import { WorkingDrawingSideBarComponent } from './working-drawing-side-bar/working-drawing-side-bar.component';
import { CbElapsedTimeModule } from '@app/shared/components/elapsed-time/elapsed-time.module';
import { WorkingDrawingViewTabsComponent } from './working-drawing-view-tabs/working-drawing-view-tabs.component';
import { CbGenericQuestionsModule } from '@app/shared/components/generic-questions/generic-questions.module';
import { FinaliseWorkingDrawingDialogComponent } from './finalise-working-drawing-dialog/finalise-working-drawing-dialog.component';
import { CompleteWorkingDrawingDialogComponent } from './complete-working-drawing-dialog/complete-working-drawing-dialog.component';
import { WorkingDrawingChangeStatusCheckboxComponent } from './working-drawing-change-status-checkbox/working-drawing-change-status-checkbox.component';
import { WorkingDrawingItemsComponent } from './working-drawing-items/working-drawing-items.component';
import { RequestWorkingDrawingDialogComponentComponent } from './request-working-drawing-dialog-component/request-working-drawing-dialog-component.component';
import { RequestWorkingDrawingStepOneComponent } from './request-working-drawing-dialog-wizard-steps/request-working-drawing-step-one/request-working-drawing-step-one.component';
import { RequestWorkingDrawingStepTwoComponent } from './request-working-drawing-dialog-wizard-steps/request-working-drawing-step-two/request-working-drawing-step-two.component';
import { RequestWorkingDrawingEditItemDialogComponentComponent } from './request-working-drawing-edit-item-dialog-component/request-working-drawing-edit-item-dialog-component.component';
import { RequestWorkingDrawingItemComponentComponent } from './request-working-drawing-dialog-wizard-steps/request-working-drawing-item-component/request-working-drawing-item-component.component';
import { WorkingDrawingItemDialogComponent } from './working-drawing-item-dialog/working-drawing-item-dialog.component';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import {MatDividerModule} from '@angular/material/divider';
@NgModule({

    exports: [
        WorkingDrawingDialogComponent,
        FinaliseWorkingDrawingDialogComponent,
        WorkingDrawingsComponent,
        CompleteWorkingDrawingDialogComponent
    ],

    declarations: [
        WorkingDrawingSearchComponent,
        WorkingDrawingCardComponent,
        WorkingDrawingDialogComponent,
        WorkingDrawingSchemeDetailsComponent,
        WorkingDrawingsAssignedChangesComponent,
        WorkingDrawingsComponent,
        WorkingDrawingViewComponent,
        WorkingDrawingActionBarComponent,
        WorkingDrawingDetailsComponent,
        WorkingDrawingSideBarComponent,
        WorkingDrawingViewTabsComponent,
        FinaliseWorkingDrawingDialogComponent,
        CompleteWorkingDrawingDialogComponent,
        WorkingDrawingChangeStatusCheckboxComponent,
        WorkingDrawingItemsComponent,
        RequestWorkingDrawingDialogComponentComponent,
        RequestWorkingDrawingStepOneComponent,
        RequestWorkingDrawingStepTwoComponent,
        RequestWorkingDrawingEditItemDialogComponentComponent,
        RequestWorkingDrawingItemComponentComponent,
        WorkingDrawingItemDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        CbFormsModule,
        CbDialogModule,
        CbClickModule,
        CbDisplayValueModule,
        MatCardModule,
        MatFormFieldModule,
        MatTabsModule,
        MatTooltipModule,
        MatIconModule,
        CbInfoMessageModule,
        CbEnumToLabelModule,
        CbWaitForModule,
        MatInputModule,
        CbDasherModule,
        CbButtonModule,
        CbPopupTipModule,
        CbDocumentsModule,
        MatDividerModule,
        CbTableModule,
        MatMenuModule,
        MatButtonModule,
        CbInfiniteScrollModule,
        CbUserDateTimeModule,
        DragulaModule,
        MatCheckboxModule,
        CbElapsedTimeModule,
        CbGenericQuestionsModule,
        CbDatePipeModule
    ]
})

export class LotWorkingDrawingsModule { }
