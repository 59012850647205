import {
    ChangeSourceEnumId,
    ChangeStatusEnumId,
    CostNatureEnumId,
    COST_NATURE_ENUM,
    IChangeOptionDto,
    IChangeRecordDto,
    ISkinnyRevisionDto,
    IWorkingDrawingDto,
    VariationStatusEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { IChangeRecordLogicService } from './interfaces/i.change-record.logic-service';
import { IChangeRecordMappedItem } from './interfaces/i.change-record.mapped';

export class ChangeRecordMappedItem
    extends BaseMappedItem<IChangeRecordDto, IChangeRecordMappedItem, IChangeRecordLogicService>
    implements IChangeRecordMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public changeNumber: number;
    @DtoProp public lotId: number;
    @DtoProp public responseDate: string;
    @DtoProp public changeSource: ChangeSourceEnumId;
    @DtoProp public costNature: CostNatureEnumId;
    @DtoProp public dueBy: string;
    @DtoProp public changeStatus: ChangeStatusEnumId;
    @DtoProp public designChangesRequired: boolean;
    @DtoProp public clientApprovalRequired: boolean;
    @DtoProp public changeDetails: string;
    @DtoProp public designChangeCost: number;
    @DtoProp public costChangeAmount: number;
    @DtoProp public ownerId: string;
    @DtoProp public owner: string;
    @DtoProp public assignedToId: string;
    @DtoProp public assignedTo: string;
    @DtoProp public createdById: string;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public reviewerId: string;
    @DtoProp public reviewer: string;
    @DtoProp public assignedTeamId: number;
    @DtoProp public assignedTeam: string;
    @DtoProp public teamCode: string;
    @DtoProp public businessAccountId: number;
    @DtoProp public businessAccountLabel: string;
    @DtoProp public quoteId: number;
    @DtoProp public quoteLineId: number;
    @DtoProp public councilRfiId: number;
    @DtoProp public pricingRevision: ISkinnyRevisionDto;
    @DtoProp public designScheme: ISkinnyRevisionDto;
    @DtoProp public workingDrawing: IWorkingDrawingDto;
    @DtoProp public changeOptions: IChangeOptionDto[];
    @DtoProp public variationNumber: number;
    @DtoProp public variationStatus: VariationStatusEnumId;
    @DtoProp public jobNumber: string;
    @DtoProp public hasUnansweredQuestion: boolean;
    @DtoProp public isScheduleSpecTemplate: boolean;
    public isSelectedSubmitForPricing: boolean;


    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    @Computed() public get isExtraToClient(): boolean {
        return this.costNature === COST_NATURE_ENUM.ExtraToClient;
    }

    public revertToInProgress(): Observable<IChangeRecordMappedItem> {
        return this.$logicService
            .revertToInProgress(this.id)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public approveAndAcceptOption(): Observable<IChangeRecordMappedItem> {
        return this.$logicService
            .approveAndAcceptOption(this.id, this.changeOptions[0].id)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public generateChangeSummary(): Observable<any> {
        return this.$logicService.generateChangeSummary(this.id);
    }

    constructor(
        sourceData: IChangeRecordDto,
        logicService: IChangeRecordLogicService
    ) {
        super(sourceData, logicService, ChangeRecordMappedItem,
            {
                changeOptions: [] as IChangeOptionDto[]
            }
        );
    }


}
