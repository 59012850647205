<cb-dialog>
    <form class="flex-col flex"
          #documentNotificationsForm="ngForm">
        <cb-select label="Document Type"
                   [(ngModel)]="mappedItem.documentType"
                   name="documentType"
                   [required]="true"
                   [valueProp]="WHOLE_OPTION_VALUE_PROP"
                   [options]="documentTypes">
        </cb-select>
        <cb-select label="User Tag"
                   [(ngModel)]="mappedItem.userTag"
                   name="userTag"
                   [valueProp]="WHOLE_OPTION_VALUE_PROP"
                   [options]="userTags"
                   [required]="mappedItem.team == null || mappedItem.team?.id === 0">
        </cb-select>
        <cb-select label="Team"
                   [(ngModel)]="mappedItem.team"
                   name="team"
                   [valueProp]="WHOLE_OPTION_VALUE_PROP"
                   [options]="teams"
                   [required]="mappedItem.userTag == null || mappedItem.userTag?.id === 0">
        </cb-select>
        <cb-select label="Document Action"
                   [(ngModel)]="mappedItem.documentAction"
                   name="documentAction"
                   [required]="true"
                   [options]="documentActions">
        </cb-select>
        <cb-select label="Notification Template"
                   [(ngModel)]="mappedItem.notificationTemplate"
                   name="notificationTemplate"
                   [required]="true"
                   [valueProp]="WHOLE_OPTION_VALUE_PROP"
                   [options]="notificationTemplates">
        </cb-select>
        <cb-checkbox name="isActive"
                     label="Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="!documentNotificationsForm.dirty || !documentNotificationsForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
