import { Component, Input } from '@angular/core';
import { CbDialogService } from '../../dialog/cb-dialog.service';
import { LightboxComponent } from '../lightbox/lightbox.component';

@Component({
    selector: 'cb-image-button',
    templateUrl: './image-button.component.html',
    styleUrls: ['./image-button.component.scss'],
})
export class ImageButtonComponent {
    @Input() public readonly image: any;
    @Input() public readonly images: any[];
    @Input() public readonly baseUrl: string;
    @Input() public readonly height: number = 300;
    @Input() public readonly width: number = 500;
    @Input() public readonly fullImage: boolean = false;

    public isImageInvalid: boolean;
    constructor(private readonly cbDialogService: CbDialogService) { }

    public getImageUrl(): string {
        let url = `${this.baseUrl}`;
        if (this.image) {
            url = `${this.baseUrl}${this.image.id}.${this.image.extension}`;
        }
        if (this.fullImage) {
            return `${url}`;
        }
        return `${url}?w=${this.width}&h=${this.height}&scale=canvas`;
    }

    public launchLightbox(): void {

        if (this.isImageInvalid) {
            return;
        }

        this.cbDialogService.open(
            LightboxComponent,
            {
                data: {
                    imgUrl: this.baseUrl,
                    images: this.images
                },
                hasBackdrop: false,
                panelClass: 'cb-lightbox-dialog-container'
            }
        );
    }
}
