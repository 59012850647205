import { Injectable, Injector } from '@angular/core';
import { IAiRateResponseDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IAiRateMappedItem } from '@app/logic/ai-rate/interfaces/i.ai-rate.mapped';
import { BaseLogicService } from '@app/logic/base/base-logic.service';
import { IAiRateLogicService } from '@app/logic/ai-rate/interfaces/i.ai-rate-logic.service';
import { Observable } from 'rxjs';
import { AiRateMappedItem } from '@app/logic/ai-rate/ai-rate.mapped';

@Injectable()
export class AiRateLogicService extends BaseLogicService<IAiRateResponseDto, IAiRateMappedItem> implements IAiRateLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('ai', AiRateMappedItem);
    }

    public queryRate(lotId: number, offeringId: number, buildActivityId: number): Observable<IAiRateResponseDto> {
        return this.$http.get(`/ai/rate/explain?lotId=${lotId}&buildActivityId=${buildActivityId}&offeringId=${offeringId}`);
    }
}
