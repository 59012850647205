import { Component, Input } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { DocumentTemplateLogicService } from '@app/logic/document-template';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ClientSaleStatusEnumId, DOCUMENT_TEMPLATE_CODES_CONST, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import {LotPermissions} from "@app/core/permissions";

@Component({
    selector: 'cb-lot-spec-reports-menu',
    templateUrl: './lot-spec-reports-menu.component.html',
    styleUrls: ['./lot-spec-reports-menu.component.scss']
})
export class LotSpecReportsMenuComponent {

    @Input() public readonly lot: ILotMappedItem;

    constructor(
        private cbDialog: CbDialogService,
        private readonly documentTemplateLogicService: DocumentTemplateLogicService,
        public readonly currentUser: CurrentUserService,
        public readonly lotPermissions: LotPermissions,
    ) { }

    public get canViewHLFlyer(): boolean {
        return (
            this.lotPermissions.canViewHouseAndLandFlyer() &&
            (this.lot.contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand || this.lot.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand)
            && this.lot.hasScheduleSpecTemplate
        );
    }

    public get isHLFlyerButtonEnabled(): boolean {
        return this.lot.activeDesignConceptId > 0;
    }

    public downloadPricingTemplate(): void {
        this.documentTemplateLogicService.downloadDocumentTemplate(DOCUMENT_TEMPLATE_CODES_CONST.LotPricingTemplate).subOnce();
    }

    //     public downloadDraftSChedule(): void{
    //  this.lot.clientSale.status
    //     }

    public cannotDownloadDraftSchedule(): boolean {
        return (!this.lot.hasScheduleSpecTemplate
            || this.lot.lotSpecLocked
            || this.lot?.clientSale?.status === ClientSaleStatusEnumId.UnConditional
        );
    }

    public generateSiteEvaluation(): void {
        this.cbDialog
            .confirm({
                dialogHeading: 'Generate Site Evaluation',
                message: 'Are you sure you want to generate the Site Evaluation Document',
                confirmed: () => this.lot.generateSiteEvaluation().subOnce()
            });
    }
}
