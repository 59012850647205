<cb-dialog dialogHeading="Quote Line">
    <form class="flex-col flex"
          *cbWaitFor="quoteLineDto && quoteMappedItem && houseAreas"
          #quoteLineForm="ngForm">
        <cb-display-value-new [value]="COST_TYPE_ENUM[quoteLineDto.costTypeId]"
                              label="Cost Type">
        </cb-display-value-new>
        <div *ngIf="!quoteMappedItem.isScheduleSpecTemplate">
            <cb-currency *ngIf="quoteLineDto.costTypeId != undefined &&  quoteLineDto.costTypeId !== COST_TYPE_ENUM.Actual"
                         label="Amount"
                         required="true"
                         [(ngModel)]="quoteLineDto.amount"
                         name="amount"
                         [readonly]="isView$ | async"></cb-currency>
            <cb-currency *ngIf="quoteLineDto.costTypeId != undefined && quoteLineDto.costTypeId !== COST_TYPE_ENUM.Actual"
                         [label]="getAssignedAmountTypeLabel()"
                         required="true"
                         [(ngModel)]="quoteLineDto.assignedAmount"
                         name="assignedAmount"
                         [readonly]="isView$ | async"></cb-currency>
            <div class="start-center flex-row">
                <cb-product-autocomplete-scroller class="cb-margin-right flex"
                                                  label="Product"
                                                  name="product"
                                                  [(ngModel)]="quoteLineDto.offering"
                                                  [required]="true"
                                                  [requireMatch]="true"
                                                  [matchProps]="['id']"
                                                  [hideCompositeItems]="true"
                                                  [readonly]="isView$ | async">
                </cb-product-autocomplete-scroller>
                <cb-product-input-find *ngIf="!(isView$ | async)"
                                       name="selectedProduct"
                                       [(ngModel)]="quoteLineDto.offering"
                                       [required]="true"
                                       [buttonOnly]="true"
                                       [activeOnly]="true"
                                       [hideCompositeItems]="true"
                                       (productSelected)="productSelected($event)"></cb-product-input-find>
            </div>
        </div>
        <cb-select label="House Area"
                   name="name"
                   [(ngModel)]="quoteLineDto.specGroupId"
                   [options]="houseAreas"
                   [required]="true"
                   (change)="specGroupSelected($event)"
                   [readonly]="isView$ | async"></cb-select>
        <div *ngIf="quoteMappedItem.isScheduleSpecTemplate">
            <cb-slot-autocomplete-scroller class="cb-margin-top"
                                           label="Item Name"
                                           name="selectedSlot"
                                           style="min-width: 300px;"
                                           [(ngModel)]="selectedSlot"
                                           [requireMatch]="true"
                                           [matchProps]="['id']"
                                           [shouldShowParentInLabel]="true"
                                           [specGroupId]="quoteLineDto.specGroupId"
                                           [disabled]="!(quoteLineDto?.specGroupId > 0)"
                                           (optionSelected)="slotSelected($event)"
                                           required="true"
                                           [excludeIds]="excludedSlotIds"
                                           [readonly]="isView$ | async">
            </cb-slot-autocomplete-scroller>
            <cb-display-value-new [value]="currentLotSpecItem"
                                  label="Current Lot Spec Item">
            </cb-display-value-new>
        </div>
        <cb-text-area class="cb-margin-top-2x"
                      name="description"
                      [label]="getDescriptionLabel()"
                      [required]="true"
                      [(ngModel)]="quoteLineDto.description"
                      maxlength="256"
                      [readonly]="isView$ | async">
        </cb-text-area>
        <cb-checkbox *ngIf="quoteMappedItem.isScheduleSpecTemplate"
                     name="purchaseOrderOnly"
                     label="Purchase Order only - don't show on Lot Spec Reports"
                     [(ngModel)]="quoteLineDto.purchaseOrderOnly"
                     [readonly]="isView$ | async">
        </cb-checkbox>
        <cb-input type="number"
                  label="Quantity"
                  required="true"
                  [(ngModel)]="quoteLineDto.quantity"
                  name="quantity"
                  [readonly]="isView$ | async"></cb-input>
        <div class="start-center flex-row">
            <cb-currency class="flex-50"
                         label="Allocated Amount from Quote (GST Exclusive)"
                         name="allocatedAmount"
                         [(ngModel)]="quoteLineDto.allocatedAmount"
                         [required]="true"
                         [readonly]="isView$ | async">
            </cb-currency>
            <span *ngIf="quoteLineDto.allocatedAmount <= 0"
                  class="cb-margin-left-2x cb-error flex-50">
                <small>
                    <i> Amount cannot be zero or less than zero</i>
                </small>
            </span>
        </div>
        <div class="start-center flex-row">
            <cb-display-value-new class="flex-50"
                                  label="Quote Total Remaining (GST Exclusive)">
                <span [class.is-credit]="unallocatedAmount() < 0">{{unallocatedAmount() | cbCurrency}}</span>
            </cb-display-value-new>
            <span *ngIf="unallocatedAmount() < 0"
                  class="cb-margin-left-2x cb-error flex-50">
                <small>
                    <i>Unallocated Amount cannot be less than zero</i>
                </small>
            </span>
        </div>
        <cb-checkbox name="gstApplies"
                     label="GST Applies"
                     [(ngModel)]="quoteLineDto.gstApplies"
                     [readonly]="isView$ | async">
        </cb-checkbox>
        <cb-select class="space-below"
                   label="Extra To"
                   name="costNature"
                   [(ngModel)]="quoteLineDto.costNatureId"
                   [options]="costNatures"
                   required="true"
                   [readonly]="(isView$ | async) || !canEditCostNature()">
        </cb-select>
        <cb-checkbox *ngIf="this.quoteMappedItem.isScheduleSpecTemplate"
                     name="includeInCreateSchedule"
                     label="Include in Flyer / Create Schedule"
                     [(ngModel)]="quoteLineDto.includeInCreateSchedule"
                     [readonly]="isView$ | async">
        </cb-checkbox>
        <cb-checkbox *ngIf="this.quoteMappedItem.isScheduleSpecTemplate"
                     name="includeInColourSchedule"
                     label="Include in Colour Schedule"
                     [(ngModel)]="quoteLineDto.includeInColourSchedule"
                     [readonly]="isView$ | async">
        </cb-checkbox>
        <cb-checkbox *ngIf="this.quoteMappedItem.isScheduleSpecTemplate"
                     name="manualColourEntryRequired"
                     label="Manual Colour Entry Required"
                     [(ngModel)]="quoteLineDto.manualColourEntryRequired"
                     [readonly]="isView$ | async">
        </cb-checkbox>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="!quoteLineForm.dirty || !quoteLineForm.valid ||   unallocatedAmount() < 0 || quoteLineDto.allocatedAmount <= 0"
                    (click)="saveItem()">{{ (isEdit$ | async) ? 'Apply' : 'Add' }}</button>
            <button class="raised"
                    mat-raised-button
                    (click)="cancel()">{{(isView$ | async) ? 'Close' : 'Cancel'}}</button>
        </footer>
    </form>
</cb-dialog>
