import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {LotCostPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {ITakeoffMappedItem} from '@app/logic/takeoffs/interfaces/i.takeoff.mapped';
import {TakeoffsLogicService} from '@app/logic/takeoffs/takeoffs.logic.service';
import {ICostXImportResultDto, PRICING_REVISION_STATUS_ENUM, TAKE_OFF_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-takeoff-card',
    templateUrl: './lot-takeoff-card.component.html',
    styleUrls: ['./lot-takeoff-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotTakeoffCardComponent {

    public PRICING_REVISION_STATUS_ENUM = PRICING_REVISION_STATUS_ENUM;

    @Input() public mappedItem: ITakeoffMappedItem;
    @Output() public goToTakeOffEmitter = new EventEmitter<number>();
    public selectedTakeOffTaskIndex: number;
    public takeOffResult: ICostXImportResultDto;

    constructor(public readonly logicService: TakeoffsLogicService,
                private readonly lotCostPermissions: LotCostPermissions,
                private readonly navigationService: NavigationService) {
    }

    public get canManageTakeoff(): boolean {
        return this.lotCostPermissions.canManageTakeoff();
    }

    public goToTakeOff(): void {
        this.goToTakeOffEmitter.emit(this.mappedItem.id);
    }

    public get canViewTakeOff(): boolean {
        return this.mappedItem.status === TAKE_OFF_STATUS_ENUM.Pending;
    }

    public getStatusIconIcon(): string {
        switch (parseInt(this.mappedItem.status as any, 10)) {
            case TAKE_OFF_STATUS_ENUM.Current:
                return 'check_circle';
            case TAKE_OFF_STATUS_ENUM.Pending:
                return 'timelapse';
            case TAKE_OFF_STATUS_ENUM.Abandoned:
            case TAKE_OFF_STATUS_ENUM.Rejected:
                return 'delete_forever';
            case TAKE_OFF_STATUS_ENUM.Revised:
                return 'indeterminate_check_box';
            default:
                return 'timelapse';
        }
    }

    public getTransferHistoryFile(): void {
        if (this.mappedItem.transferHistoryId) {
            this.logicService.downloadTakeoffFile(this.mappedItem.transferHistoryId).subOnce();
        }
    }

    public retryTakeOff(): void {
        this.takeOffResult = undefined;
    }

    public getStatusIconClass(): string {
        switch (parseInt(this.mappedItem.status as any, 10)) {
            case TAKE_OFF_STATUS_ENUM.Current:
                return 'mat-primary';
            case TAKE_OFF_STATUS_ENUM.Pending:
            case TAKE_OFF_STATUS_ENUM.Revised:
                return 'mat-accent';
            case TAKE_OFF_STATUS_ENUM.Abandoned:
            case TAKE_OFF_STATUS_ENUM.Rejected:
                return 'mat-warn';
            default:
                return 'mat-accent';
        }
    }

    public getStatusIconTitle(): string {
        switch (parseInt(this.mappedItem.status as any, 10)) {
            case TAKE_OFF_STATUS_ENUM.Current:
                return 'Current';
            case TAKE_OFF_STATUS_ENUM.Pending:
                return 'Pending';
            case TAKE_OFF_STATUS_ENUM.Abandoned:
                return 'Abandoned';
            case TAKE_OFF_STATUS_ENUM.Rejected:
                return 'Rejected';
            case TAKE_OFF_STATUS_ENUM.Revised:
                return 'Revised';
            default:
                return 'none';
        }
    }
}
