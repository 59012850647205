import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ComplianceRegistrationDialogComponent } from './compliance-registration-dialog/compliance-registration-dialog.component';
import { ComplianceRegistrationLogicService } from '@app/logic/compliance-registration/compliance-registration.logic.service';
import { ComplianceRegistrationMappedItem } from '@app/logic/compliance-registration/compliance-registration.mapped';
import { Component } from '@angular/core';
import { IComplianceRegistrationDto } from '@app/logic/compliance-registration';
import { PermissionsPermissions } from '@app/core/permissions';
import { UsersLogicService } from '@app/logic/users/users.logic.service';

@Component({
    selector: 'cb-compliance-registration',
    templateUrl: './compliance-registration.component.html',
    styleUrls: ['./compliance-registration.component.scss'],
    providers: [ComplianceRegistrationLogicService, UsersLogicService]
})
export class ComplianceRegistrationComponent extends BaseSimpleListViewDirective<IComplianceRegistrationDto, ComplianceRegistrationLogicService> {
    public readonly displayedColumns: string[] = [
        'complianceRegistrationType',
        'isActive',
        'actions'
    ];

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly complianceRegistrationLogicService: ComplianceRegistrationLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
    ) {
        super(
            cbDialog,
            complianceRegistrationLogicService,
            ComplianceRegistrationDialogComponent,
            'Compliance Registration',
            ComplianceRegistrationMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<IComplianceRegistrationDto>): Array<IComplianceRegistrationDto> {
        return searchResults.sort((a, b) => a.label.localeCompare(b.label));
    }

    protected handleError = (err): void => {
        console.error(err);
    };

    public generateReport(): void {
        this.complianceRegistrationLogicService.generateReport()
            .subOnce({ error: this.handleError });
    }
}



