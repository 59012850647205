import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CbInfoMessageModule } from 'projects/cb-hub-lib/src/lib/components/info-message/info-message.module';
import { MatDividerModule } from '@angular/material/divider';
import { CbTruncateModule } from '@app/shared/pipe/truncate/truncate.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbGenericQuestionsModule } from '@app/shared/components/generic-questions/generic-questions.module';
import { DesignConceptSearchComponent } from './design-concept-search/design-concept-search.component';
import { DesignConceptCardComponent } from './design-concept-card/design-concept-card.component';
import { DesignConceptDetailsComponent } from './design-concept-details/design-concept-details.component';
import { DesignConceptInfoComponent } from './design-concept-info/design-concept-info.component';
import { DesignConceptsComponent } from './design-concepts/design-concepts.component';
import { CbHandleInvalidImageModule } from '@app/shared/directives/handle-invalid-image/handle-invalid-image.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { DesignConceptComponent } from './design-concept/design-concept.component';
import { DesignConceptViewComponent } from './design-concept-view/design-concept-view.component';
import { CbDasherModule } from 'projects/cb-hub-lib/src/lib/pipe/dasher/dasher.module';
import { CbAmenitiesModule } from '@app/shared/components/amenities/amenities.module';
import { LotSpecificationModule } from '../../specification/specification.module';
import { LotDesignSchemesModule } from '../schemes/schemes.module';
import { MatMenuModule } from '@angular/material/menu';
import { DesignConceptSellPriceDialogComponent } from './design-concept-sell-price-dialog/design-concept-sell-price-dialog.component';
import { CbImageButtonModule } from '@app/shared/components/image-handlers/image-button/image-button.module';
import { CbImageUploadModule } from '@app/shared/components/image-handlers/image-upload-dialog/image-upload.module';

@NgModule({

    exports: [
        DesignConceptSellPriceDialogComponent,
        DesignConceptsComponent,
    ],

    declarations: [
        DesignConceptsComponent,
        DesignConceptSearchComponent,
        DesignConceptCardComponent,
        DesignConceptComponent,
        DesignConceptDetailsComponent,
        DesignConceptInfoComponent,
        DesignConceptViewComponent,
        DesignConceptSellPriceDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        CbFormsModule,
        CbDialogModule,
        CbClickModule,
        CbDisplayValueModule,
        MatCardModule,
        MatButtonModule,
        MatTabsModule,
        MatTooltipModule,
        MatIconModule,
        CbInfoMessageModule,
        MatDividerModule,
        CbTruncateModule,
        CbEnumToLabelModule,
        CbCurrencyModule,
        CbDocumentsModule,
        CbWaitForModule,
        CbGenericQuestionsModule,
        CbHandleInvalidImageModule,
        CbUserDateTimeModule,
        CbDasherModule,
        CbAmenitiesModule,
        LotSpecificationModule,
        LotDesignSchemesModule,
        MatMenuModule,
        CbDialogModule,
        CbImageButtonModule,
        CbImageUploadModule,
    ]
})

export class LotConceptsModule { }
