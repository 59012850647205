import { ISlotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ISlotLabelDecorator } from './i.slot-label-decorator';

export class SlotParentLabelDecorator implements ISlotLabelDecorator {
    public writeLabel = (item: ISlotDto, label: string): string => {
        if (item.parent) {
            return `${item.parent.name} > ${label}`;
        } else {
            return `${label}`;
        }
    };
}
