import {Injector} from '@angular/core';
import {ClassCtor} from './../../shared/types/classctor.type';
import {IBaseMappedItem} from './interfaces/i.base-mapped-item';

interface IHaveInjector extends Object {
    readonly $injector: Injector;
}

/** ***For use with mapped items only*** \
 * Uses Angular Injector to inject an injectable/service into a mappedItem
 */
export function DepInject(injectable: ClassCtor): PropertyDecorator {
    return <Target extends IBaseMappedItem<Object, Object, IHaveInjector>>(target: Target, propertyKey: string) => {
        const privatePropKey = `_${propertyKey}`;
        Object.defineProperty(target, privatePropKey, {
            value: null,
            enumerable: false,
            writable: true,
            configurable: true,
        });
        Object.defineProperty(target, propertyKey, {
            get(this: Target): ClassCtor {
                this[privatePropKey] = this[privatePropKey] ?? this.$logicService?.$injector?.get(injectable);
                return this[privatePropKey];
            }
        });
    };
}
