import {Component, ElementRef, Input} from '@angular/core';

@Component({
    selector: 'cb-td-text[value]',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss', './../table-cell.scss']
})
export class TdTextComponent {
    @Input() public readonly value: string;

    constructor(
        public readonly elementRef: ElementRef,
    ) {
    }
}
