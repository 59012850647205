import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { DOCUMENT_TYPE_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { DocumentUploadDialogComponent } from '@app/shared/components/documents/components/document-upload-dialog/document-upload-dialog';
import { DocumentsLogicService } from '@app/logic/documents';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

interface IClientSaleDocuments {
    spInfo: ClientSaleDocumentUtil;
    clientSpec: ClientSaleDocumentUtil;
    spAgreement: ClientSaleDocumentUtil;
    buildContract: ClientSaleDocumentUtil;
}

class ClientSaleDocumentUtil {
    constructor(
        private readonly _service: ClientSaleDocumentsService,
        public readonly entity: IDocumentEntityMappedItem,
    ) { }

    public openUploadDialog(): void {
        if (!this.entity.canUpload) {
            return;
        }
        this._service.openUploadDialog(this.entity);
    }
}

@Injectable()
export class ClientSaleDocumentsService implements OnDestroy {

    public readonly documents = {} as IClientSaleDocuments;
    /** Client Sale and all documents in this service are resolved on emit */
    public readonly documentsLoaded$ = new Subject();

    private _clientSale: IClientSaleMappedItem;
    private sub$ = new Subscription();

    constructor(
        private readonly documentsLogic: DocumentsLogicService,
        private readonly cbDialog: CbDialogService,
    ) {

    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    public init(clientSale: IClientSaleMappedItem): void {
        this.sub$.unsubscribe();
        this._clientSale = clientSale;
        this.loadDocuments();
        this.sub$ = this._clientSale.$updated.subscribe(() => this.loadDocuments());
    }

    @Debounce(1000)
    public loadDocuments(): void {
        if (!this._clientSale) {
            throw new Error('call init() before loadDocuments to ensure clientSale is defined');
        }
        this.documentsLogic
            .getMappedDocumentEntities(this._clientSale.documentEntityUri, this._clientSale.documentEntityId, false, false)
            .subOnce(this.handleLoadDocuments);
    }

    private readonly handleLoadDocuments = (documents: IDocumentEntityMappedItem[]): void => {
        this.documents.spInfo = this.getDocument(documents, DOCUMENT_TYPE_CODES_CONST.SIGNED_SALE_AND_PURCHASE_INFO);
        this.documents.clientSpec = this.getDocument(documents, DOCUMENT_TYPE_CODES_CONST.SIGNED_CREATE_SCHEDULE);
        this.documents.spAgreement = this.getDocument(documents, DOCUMENT_TYPE_CODES_CONST.SIGNED_SALE_AND_PURCHASE);
        this.documents.buildContract = this.getDocument(documents, DOCUMENT_TYPE_CODES_CONST.SIGNED_BUILD_CONTRACT_DOCUMENT);

        this.documentsLoaded$.next(null);
    };

    private getDocument(documents: IDocumentEntityMappedItem[], typeCode: string): ClientSaleDocumentUtil {
        return new ClientSaleDocumentUtil(this, documents.find(x => x?.documentType?.codeName === typeCode));
    }

    public openUploadDialog(documentEntity: IDocumentEntityMappedItem): void {
        this.cbDialog
            .open(DocumentUploadDialogComponent, {
                data: {
                    documentEntityMappedItem: documentEntity,
                    documentGroups: [documentEntity.documentGroup],
                    isDocumentReupload: true,
                    $baseUri: this._clientSale.documentEntityUri,
                    $documentEntityId: this._clientSale.documentEntityId,
                    allowReviewAtUpload: false
                }
            });
    }
}
