import {
    CostTypeEnumId,
    COST_TYPE_ENUM,
    ILotSpecItemDto,
    IOfferingCategoryAttributeOptionDto,
    IPurchaseOrderDto,
    IQuoteLineRefDto,
    OfferingStatusEnumId,
    UnitOfMeasureEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { concatMap, map, Observable, of, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { ILotSpecItemLogicService } from './interfaces/i.lot-spec-item-logic.service';
import { ILotSpecItemMappedItem } from './interfaces/i.lot-spec-item.mapped';

export class LotSpecItemMappedItem
    extends BaseMappedItem<ILotSpecItemDto, ILotSpecItemMappedItem, ILotSpecItemLogicService>
    implements ILotSpecItemMappedItem {

    @DtoProp public lotId: number;
    @DtoProp public costXRef: string;
    @DtoProp public costType: CostTypeEnumId;
    @DtoProp public originalCostType: CostTypeEnumId;
    @DtoProp public productId: number;
    @DtoProp public productOther: string;
    @DtoProp public quantity: number;
    @DtoProp public supplierId: number;
    @DtoProp public rate: number;
    @DtoProp public specGroupId: number;
    @DtoProp public specGroupName: string;
    @DtoProp public offeringOptions: IOfferingCategoryAttributeOptionDto[];
    @DtoProp public actualCost: number;
    @DtoProp public estimatedCost: number;
    @DtoProp public provisionalCost: number;
    @DtoProp public noChargeCost: number;
    @DtoProp public assignedTotal: number;
    @DtoProp public amountSpent: number;
    @DtoProp public quoteRequired: boolean;
    @DtoProp public bundleId: number;
    @DtoProp public sortOrder: number;
    @DtoProp public versionNumber: number;
    @DtoProp public productDisplay: string;
    @DtoProp public supplierDisplay: string;
    @DtoProp public isPendingVariation: boolean;
    @DtoProp public isPendingDelete: boolean;
    @DtoProp public isPendingCredit: boolean;
    @DtoProp public image: string;
    @DtoProp public specItemImage: string;
    @DtoProp public isDeleted: boolean;
    @DtoProp public hasQuoteLines: boolean;
    @DtoProp public quotedLinesAllocatedAmount: number;
    @DtoProp public parentLotSpecItemId: number;
    @DtoProp public notes: string;
    @DtoProp public name: string;
    @DtoProp public showInClientSpecification: boolean;
    @DtoProp public showInColourYourDreams: boolean;
    @DtoProp public manualColourEntryRequired: boolean;
    @DtoProp public productCode: string;
    @DtoProp public hasImage: boolean;
    @DtoProp public offeringStatus: OfferingStatusEnumId;
    @DtoProp public colourName: string;
    @DtoProp public colourId: number;
    @DtoProp public canRemoveImage: boolean;
    @DtoProp public textForSpecification: string;
    @DtoProp public productCategory: string;
    @DtoProp public productCategoryId: number;
    @DtoProp public uom: UnitOfMeasureEnumId;
    @DtoProp public uomLabel: string;
    @DtoProp public quoteLineDescription: string;
    @DtoProp public isManualQuote: boolean;
    @DtoProp public isSsrConfirmed: boolean;
    @DtoProp public isIncludedInPO: boolean;
    @DtoProp public restrictions: IOfferingCategoryAttributeOptionDto[];
    @DtoProp public id: number;

    public removeImageOnSave = false;
    public specItemImageFile: File;

    @Computed() public get canEdit(): boolean {
        return this.costType !== COST_TYPE_ENUM.Quote
            && this.isSsrConfirmed !== true;
    }

    @Computed() public get canSplit(): boolean {
        return this.quantity > 1
            && (
                this.costType === COST_TYPE_ENUM.Actual
                || this.costType === COST_TYPE_ENUM.NoCharge
                || this.costType === COST_TYPE_ENUM.OwnersCare
            )
            && !this.isIncludedInPO;
    }

    @Computed() public get isProvisionalOrSpecificationQuoteItem(): boolean {
        return this.costType === COST_TYPE_ENUM.Provisional
            || (this.costType === COST_TYPE_ENUM.Quote && !this.productId);
    }

    @Computed() public get isActualCost(): boolean {
        return this.costType === COST_TYPE_ENUM.Actual;
    }

    @Computed() public get isDescriptiveOnlyCost(): boolean {
        return this.costType === COST_TYPE_ENUM.DescriptiveOnly;
    }

    @Computed() public get isOwnersCareCost(): boolean {
        return this.costType === COST_TYPE_ENUM.OwnersCare;
    }

    @Computed() public get isNoChargeCost(): boolean {
        return this.costType === COST_TYPE_ENUM.NoCharge;
    }

    @Computed() public get isEstimatedCost(): boolean {
        return this.costType === COST_TYPE_ENUM.Estimate;
    }

    @Computed() public get isProvisionalCost(): boolean {
        return this.costType === COST_TYPE_ENUM.Provisional;
    }

    @Computed() public get isQuoteCost(): boolean {
        return this.costType === COST_TYPE_ENUM.Quote;
    }

    constructor(
        sourceData: ILotSpecItemDto,
        logicService: ILotSpecItemLogicService
    ) {
        super(sourceData, logicService, LotSpecItemMappedItem);
    }

    protected $preSave(toSave: ILotSpecItemDto): void {
        if (!toSave.quantity || toSave.quantity < 1) {
            toSave.quantity = 1;
        }
        if (toSave.supplierId < 1) {
            toSave.supplierId = null;
        }
        if (toSave.costType === COST_TYPE_ENUM.OwnersCare || toSave.costType === COST_TYPE_ENUM.DescriptiveOnly) {
            toSave.offeringOptions = null;
            toSave.quoteRequired = null;
            toSave.rate = null;
            toSave.supplierId = null;
        }
        if (toSave.costType === COST_TYPE_ENUM.DescriptiveOnly) {
            toSave.productId = null;
            toSave.quantity = 0;
        }
        if (!(toSave.productId > 0) && toSave.productDisplay && isNullOrWhiteSpace(toSave.productOther)) {
            toSave.productOther = toSave.productDisplay;
        }
    }

    public $reload(): Observable<ILotSpecItemDto> {
        return this.$logicService
            .$getItem(this.id, this.lotId, this.versionNumber)
            .pipe(
                tap((response) => {
                    this.$updateThisData(response);
                })
            );
    }

    public delete(versionNumber?: number): Observable<ILotSpecItemDto | false> {
        return this.$logicService
            .$deleteItem(this.lotId, this.id, versionNumber)
            .pipe(
                map((result) => {
                    if (result) {
                        const data = this.$getMappedDtoItem();
                        data.isDeleted = true;
                        this.$updateThisAndOriginal(data);
                        return data;
                    }
                    return false;
                })
            );
    }

    public split(): Observable<ILotSpecItemDto> {
        return this.$logicService
            .splitLotSpecItemAtVersion(this.id, this.lotId, this.versionNumber)
            .pipe(
                tap((result) => {
                    this.$updateThisAndOriginal(result);
                })
            );
    }

    public $save(versionNumber = this.versionNumber): Observable<ILotSpecItemDto> {
        const data = this.$getMappedDtoItem();
        this.$preSave(data);
        return this.$logicService
            .$saveItem(data, versionNumber)
            .pipe(
                concatMap((result: ILotSpecItemDto) => {
                    this.$updateThisAndOriginal(result);
                    if (this.specItemImageFile && !this.isActualCost) {
                        return this.uploadImage(result.id);
                    } else if (this.removeImageOnSave || (this.isActualCost && this.specItemImage)) {
                        return this.removeSpecItemImage(result.id);
                    }
                    return of(result);
                })
            );
    }

    public getQuoteLines(): Observable<IQuoteLineRefDto[]> {
        return this.$logicService
            .getQuoteLinesByLotSpecItem(this.lotId, this.id);
    }

    public getPurchaseOrders(): Observable<IPurchaseOrderDto[]> {
        return this.$logicService
            .getPurchaseOrdersByLotSpecItem(this.lotId, this.id);
    }

    private uploadImage(lotSpecItemId: number): Observable<ILotSpecItemDto> {
        if (!this.specItemImageFile) {
            return;
        }
        const fd = new FormData();
        fd.append('file', this.specItemImageFile);

        return this.$logicService
            .uploadItemImageAtVersion(lotSpecItemId, this.lotId, this.versionNumber, fd)
            .pipe(
                tap((result) => {
                    this.specItemImageFile = null;
                    this.$updateThisAndOriginal(result);
                })
            );
    }

    private removeSpecItemImage(lotSpecItemId: number): Observable<ILotSpecItemDto> {
        this.removeImageOnSave = false;
        return this.$logicService.removeSpecItemImage(lotSpecItemId, this.lotId)
            .pipe(
                map((result) => {
                    const data = this.$getMappedDtoItem();
                    if (result) {
                        this.specItemImageFile = null;
                        data.specItemImage = null;
                        this.$updateThisAndOriginal(data);
                    }
                    return data;
                })
            );
    }
}
