import { BaseLogicService, DEFAULT_DATA_ERROR, MAPPEDITEM_DATA_ERROR, MAPPEDITEM_CTOR_ERROR } from '../base/base-logic.service';
import {
    IBuildProgrammeActivityRelatedActivityResponseDto,
    IBuildProgrammeActivityRelationshipsDto,
    IBuildProgrammeActivityRelationshipUpdateDto
} from './interfaces/i.build-programme-activity-relationship.dto';
import { Injectable, Injector } from '@angular/core';
import { BuildProgrammeActivityMappedItem } from './build-programme-activity.mapped';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IBuildProgrammeActivityDto } from './interfaces/i.build-programme-activity.dto';
import { IBuildProgrammeActivityLogicService } from './interfaces/i.build-programme-activity-logic.service';
import { IBuildProgrammeActivityMappedItem } from './interfaces/i.build-programme-activity.mapped';
import { ISsrDetailsDto } from '../ssrs/interfaces/i.ssr-details.dto';
import { LotBuildProgrammeEventService } from '@app/views/lot/build/services/lot-build-programme-event.service';
import { Observable } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ClassCtor } from '@app/shared/types/classctor.type';
import {
    IBuildProgrammeActivityAffectedBySupplierChangeDto,
    IBuildProgrammeActivitySupplierChangeDetailsDto,
    IBuildProgrammeActivityUpdateSupplier
} from '../build-programme/interfaces/buildprogramme.model';

@Injectable()
export class BuildProgrammeActivityLogicService
    extends BaseLogicService<IBuildProgrammeActivityDto, IBuildProgrammeActivityMappedItem>
    implements IBuildProgrammeActivityLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildprogrammeactivities',
            BuildProgrammeActivityMappedItem,
        );
    }

    public $createMappedItem<ReturnType = IBuildProgrammeActivityMappedItem>(
        defaultData: any = {},
        mappedItemClass = this.$defaultMappedItemCtor as unknown as ClassCtor<ReturnType>,
        eventService?: LotBuildProgrammeEventService,
    ): ReturnType {
        if (defaultData == null || defaultData instanceof Array || !(defaultData instanceof Object)) {
            throw new Error(DEFAULT_DATA_ERROR);
        }
        if (defaultData instanceof BaseMappedItem) {
            throw new Error(MAPPEDITEM_DATA_ERROR);
        }
        if (!(mappedItemClass.prototype instanceof BaseMappedItem)) {
            throw new Error(MAPPEDITEM_CTOR_ERROR);
        }
        return new mappedItemClass(defaultData, this, eventService) as unknown as ReturnType;
    }

    public $saveItem<ReturnType>(data: IBuildProgrammeActivityDto): Observable<ReturnType & IBuildProgrammeActivityDto[]> {
        return this.$http
            .post<ReturnType & IBuildProgrammeActivityDto[]>(`buildprogrammes/${data.buildProgrammeId}/activities/${data.id}`, data);
    }

    public toggleBuildProgammeActivityControl(buildProgrammeId: number, buildProgrammeActivityId: number, isControlledByParent: boolean): Observable<IBuildProgrammeActivityDto[]> {
        return this.$http.post<IBuildProgrammeActivityDto[]>(
            `buildprogrammes/${buildProgrammeId}/activities/${buildProgrammeActivityId}/togglecontrol`,
            {},
            'json',
            {},
            { isControlled: isControlledByParent.toString() }
        );
    }

    public completeManualOrder(buildProgrammeActivityId: number): Observable<IBuildProgrammeActivityDto> {
        return this.$http
            .post(`${this.$baseUri}/${buildProgrammeActivityId}/completemanualorder`);
    }

    public getAffectedActivitiesForSupplierChange(buildProgrammeActivityId: number, dto: IBuildProgrammeActivitySupplierChangeDetailsDto): Observable<IBuildProgrammeActivityAffectedBySupplierChangeDto[]> {
        return this.$http
            .post(`${this.$baseUri}/${buildProgrammeActivityId}/affectedbysupplierchange`, dto);
    }

    public createSsrForBuildProgrammeActivity(buildProgrammeActivityId: number): Observable<ISsrDetailsDto> {
        return this.$http
            .post(`${this.$baseUri}/${buildProgrammeActivityId}/createssr`);
    }

    public updateBuildProgrammeActivitySupplier(buildProgrammeActivityId: number, supplier: IBuildProgrammeActivityUpdateSupplier): Observable<IBuildProgrammeActivityDto[]> {
        return this.$http
            .post(`${this.$baseUri}/${buildProgrammeActivityId}/supplier`, supplier);
    }

    public getActivityRelationships(buildProgrammeActivityId: number): Observable<IBuildProgrammeActivityRelationshipsDto> {
        return this.$http
            .get(`buildprogrammes/activities/${buildProgrammeActivityId}/relationships`);
    }

    public clearBuildProgrammeActivityRelationship(
        buildProgrammeActivityId: number,
        predecessorActivityId: number): Observable<IBuildProgrammeActivityDto[]> {
        return this.$http
            .delete(`buildprogrammes/activities/${buildProgrammeActivityId}/relationships/update`, 'json', {}, { predecessorActivityId: predecessorActivityId.toString() });
    }

    public updateBuildProgrammeActivityRelationship(
        buildProgrammeActivityId: number,
        buildProgrammeActivityRelationshipDto: IBuildProgrammeActivityRelationshipUpdateDto): Observable<IBuildProgrammeActivityRelatedActivityResponseDto> {
        return this.$http
            .post(`buildprogrammes/activities/${buildProgrammeActivityId}/relationships/update`, buildProgrammeActivityRelationshipDto);
    }

    public splitActivity(buildProgrammeId: number, buildProgrammeActivityId: number): Observable<IBuildProgrammeActivityDto> {
        return this.$http
            .post(`/buildprogrammes/${buildProgrammeId}/activities/${buildProgrammeActivityId}/split`);
    }

    public saveBuildProgrammeActivityOrder(buildProgrammeId: number, buildProgrammeActivityDto: IBuildProgrammeActivityDto): Observable<IBuildProgrammeActivityDto> {
        return this.$http
            .post(`/buildprogrammes/${buildProgrammeId}/activities/${buildProgrammeActivityDto.id}/reorder`, buildProgrammeActivityDto);
    }

}
