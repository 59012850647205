import { Injectable, Injector } from '@angular/core';
import { find } from 'lodash';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { INotificationTemplateDto, INotificationTemplateTreeDto } from './interfaces/i.notification-template.dto';
import { INotificationTemplateLogicService } from './interfaces/i.notification-template.logic.service';
import { INotificationTemplateMappedItem } from './interfaces/i.notification-template.mapped';
import { NotificationTemplateMappedItem } from './notification-template.mapped';

@Injectable()
export class NotificationTemplateLogicService
    extends BaseLogicService<INotificationTemplateDto, INotificationTemplateMappedItem>
    implements INotificationTemplateLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('notificationTemplates', NotificationTemplateMappedItem);
    }

    public export(): Observable<any> {
        return this.$http.download(`${this.$baseUri}/report`);
    }

    public getTree(): Observable<INotificationTemplateTreeDto[]> {
        return this.$getList().pipe(
            map((list: INotificationTemplateDto[]) => {
                const tree: INotificationTemplateTreeDto[] = [];
                list.forEach((item: INotificationTemplateDto) => {
                    const match = find(tree, { id: item.templateCategoryId });
                    if (match) {
                        match.children.push(item);
                    } else {
                        const newNode = {
                            id: item.templateCategoryId,
                            label: item.templateCategoryLabel,
                            children: [item]
                        };
                        tree.push(newNode);
                    }
                });
                tree.sort((a, b) => a.id - b.id);
                return tree;
            })
        );
    }

    public seedNotificationTemplates(): Observable<string> {
        return this.$http.get('dev/tools/seedelastic');
    }
}
