import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BusinessAccountLogicService, BusinessAccountMappedItem } from '@app/logic/business-accounts';

interface IData {
    account: BusinessAccountMappedItem;
}

@Component({
    selector: 'cb-business-account-edit-financial-info-dialog',
    templateUrl: './business-account-edit-financial-info-dialog.component.html',
    styleUrls: ['./business-account-edit-financial-info-dialog.component.scss']
})
export class BusinessAccountEditFinancialInfoDialogComponent {
    public static readonly MIN_WIDTH = '60%';

    public bankName: string;
    public bankAccountNumber: string;
    public abmCode: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<BusinessAccountEditFinancialInfoDialogComponent>,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
    ) {
    }

    public ngOnInit(): void {
        this.bankName = this.data.account.bankName;
        this.bankAccountNumber = this.data.account.bankAccountNumber;
        this.abmCode = this.data.account.abmCode;
    }

    public canSave(): boolean {
        return !!this.data?.account?.id && !!this.bankName && !!this.bankAccountNumber && !!this.abmCode;
    }

    public onSaveClicked(): void {
        if (this.canSave()) {
            this.businessAccountLogicService.editFinancialInfo(this.data.account.id, this.bankName, this.bankAccountNumber, this.abmCode)
                .subOnce(() => this.dialogRef.close());
        }
    }
}
