import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectReleaseStagePermissionEnum } from '@app/shared/enums/permissions/project-release-stage.permission';
import { CurrentUserService } from '../authentication/current.user';
import {
    BasePermissions,
    IBaseLotPermissions,
    IBaseSystemAreaPermissions,
    IDocumentPermissions,
    IhaveBaseLotPermissions,
    IhaveDocumentPermissions,
    IhaveNotePermissions,
    IhaveTaskPermissions,
    INotePermissions,
    ITaskPermissions
} from './base.permissions';

export interface IProjectReleaseStagePermissions
    extends IBaseSystemAreaPermissions, IhaveBaseLotPermissions,
    IhaveDocumentPermissions, IhaveNotePermissions, IhaveTaskPermissions {
    canViewHistory(): boolean;
    getDocumentPermissions(): IDocumentPermissions;
    getLotPermissions(): IBaseLotPermissions | any;
    getNotePermissions(): INotePermissions;
    getTaskPermissions(): ITaskPermissions;
    canCancel(): boolean;
    canViewDesignSummary(): boolean;
    canViewCostSummary(): boolean;
    canRequestImages(): boolean;
    canRequestScheme(): boolean;
    canRequestPricing(): boolean;
    canViewDefaultInstallers(): boolean;
}

@Injectable()
export class ProjectReleaseStagePermissions extends BasePermissions<ProjectReleaseStagePermissionEnum> implements IProjectReleaseStagePermissions {


    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('projectreleasestage', ProjectReleaseStagePermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseStageCanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseStageCanCreate);
    }

    public canViewDefaultInstallers(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseCanManageDefaultInstallers);
    }

    public canViewDefaultSuppliers(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseCanManageDefaultSuppliers);
    }

    public canEdit(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseStageCanEdit);
    }

    public canViewHistory(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseHistoryCanView);
    }

    public canRequestPricing(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.LotCanRequestPricing);
    }

    public canCancel(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseStageCanCancel);
    }

    public canRequestImages(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.LotCanRequestImages);
    }

    public canViewDesignSummary(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseCanViewDesignSummary);
    }

    public canRequestScheme(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.LotCanRequestScheme);
    }

    public canViewCostSummary(): boolean {
        return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseCanViewCostSummary);
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return {
            canDeleteDocuments: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseDocumentCanDelete);
            },

            canEditDocuments: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseDocumentCanEdit);
            },
            canReviewDocuments: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseDocumentCanReview);
            },
            canUploadDocument: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseDocumentCanUpload);
            },
            canViewDocuments: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseDocumentCanView);
            },
        };
    }

    public getLotPermissions(): IBaseLotPermissions | any {
        return {
            canApplyLotSpec: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.LotSpecCanApply);
            },
            canCreate: () => {
                return false;
            },
            canCreateLotSpec: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.LotSpecCanCreate);
            },
            canEdit: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.LotRecordCanEdit);
            },
            canImport: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.LotsCanImport);
            },
            canRelease: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.LotCanRelease);
            },
            canRemoveLotFromRelease: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.CanRemoveLotFromRelease);
            },
            canSetAsExternalSale: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.LotCanSetExternalSale);
            },
            canSwitchReleaseStage: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.LotCanSwitchReleaseStage);
            },
            canView: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.LotRecordCanView);
            }
        };
    }

    public getNotePermissions(): INotePermissions {
        return {
            canCreateNote: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseNoteCanAdd);
            },
            canDeleteNote: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseNoteCanDelete);
            },
            canEditNote: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseNoteCanEdit);
            },
            canViewNotes: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseNoteCanView);
            }
        };
    }

    public getTaskPermissions(): ITaskPermissions {
        return {
            canAddTask: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseTaskCanAdd);
            },
            canCompleteTask: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseTaskCanComplete);
            },
            canEditTask: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseTaskCanEdit);
            },
            canViewTask: () => {
                return this.permissionObject(ProjectReleaseStagePermissionEnum.ReleaseTaskCanView);
            }
        };
    }
}
