import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ILotMappedItem } from '@app/logic/lots';
import { IPricingRevisionMappedItem } from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-pricing-revision-view',
    templateUrl: './pricing-revision-view.component.html',
    styleUrls: ['./pricing-revision-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingRevisionViewComponent {
    @Output() public readonly goBack = new EventEmitter();
    @Output() public reloadPricingRevisionMappedItem = new EventEmitter();
    @Input() public readonly lotMappedItem: ILotMappedItem;
    @Input() public readonly previousMappedItem: IPricingRevisionMappedItem;

    public lastSelectedTabIndex: number;

    public mappedItem$$: BehaviorSubject<IPricingRevisionMappedItem> = new BehaviorSubject(null);

    @Input() public set mappedItem(_pricingRevisionMappedItem: IPricingRevisionMappedItem) {
        if (_pricingRevisionMappedItem) {
            _pricingRevisionMappedItem.$reload().subOnce();
            this.mappedItem$$.next(_pricingRevisionMappedItem);
        }
    }

    public get mappedItem(): IPricingRevisionMappedItem {
        return this.mappedItem$$.value;
    }

    constructor() { }

    public storeLastIndex(event): void {
        this.lastSelectedTabIndex = event.index;
    }

    public reloadPricingRevisionMappedItemOnParent(): void {
        this.reloadPricingRevisionMappedItem.emit();
    }
}
