<cb-dialog>
    <form class="flex-col flex"
          #form="ngForm"
          *cbWaitFor="mappedItem">
        <cb-display-value-new [value]="mappedItem.slotName"
                              label="Name">
        </cb-display-value-new>
        <cb-display-value-new [value]="mappedItem.specGroupDto.label"
                              label="Template Section">
        </cb-display-value-new>
        <cb-select name="costTypeControl"
                   label="Cost Type"
                   [options]="scheduleTemplateCostTypes"
                   [required]="true"
                   (change)="onCostTypeChange($event)"
                   [(ngModel)]="mappedItem.costType">
        </cb-select>
        <div class="start-center flex-row"
             *ngIf="mappedItem.costType === COST_TYPE_ENUM.Actual">
            <cb-product-autocomplete-scroller class="cb-margin-right flex"
                                              label="Product"
                                              name="selectProduct"
                                              *cbWaitFor="categoryLoaded"
                                              [(ngModel)]="selectedProduct"
                                              [required]="true"
                                              [isInvalid]="!selectedProduct?.id"
                                              [requireMatch]="true"
                                              [matchProps]="['id']"
                                              [categoryId]="offeringCategoryId"
                                              [hideCompositeItems]="false"
                                              [orderByStandardProduct]="true"
                                              (searchTextChanged)="productSearchTextChanged($event)"
                                              (optionSelected)="productSelectedFromAutoComplete($event)">
            </cb-product-autocomplete-scroller>
        </div>
        <div class="start-center flex-row"
             *ngIf="mappedItem.costType !== COST_TYPE_ENUM.Actual">
            <cb-input class="flex"
                      type="text"
                      name="description"
                      label="Description"
                      required="true"
                      maxlength="1500"
                      [(ngModel)]="mappedItem.productOther">
            </cb-input>
        </div>
        <div class="product-card flex-row flex">
            <div class="flex-col"
                 id="imageUploadDropzone"
                 style="max-width: 180px;">
                <div class="start-center flex-row">
                    <cb-image-button [baseUrl]="mappedItem.productImageUrl"
                                     class="image-button"
                                     *ngIf="mappedItem.costType === COST_TYPE_ENUM.Actual || !mappedItem.scheduleItemImageUrl">
                    </cb-image-button>
                    <cb-image-button [baseUrl]="mappedItem.scheduleItemImageUrl"
                                     class="image-button"
                                     *ngIf="mappedItem.costType !== COST_TYPE_ENUM.Actual && mappedItem.scheduleItemImageUrl">
                    </cb-image-button>
                </div>
                <input hidden
                       type="file"
                       #fileInput
                       (change)="selectFile($event.target.files[0], form.form)"
                       (click)="$event.target.value = null"
                       [disabled]="uploadingImage || mappedItem.costType === COST_TYPE_ENUM.Actual"
                       accept=".jpg,.jpeg,.png,.gif,image/jpeg,image/gif,image/png"/>
                <div>
                    <button mat-button
                            (click)="fileInput.click()"
                            style="display: block;"
                            [disabled]="uploadingImage || mappedItem.costType === COST_TYPE_ENUM.Actual">
                        <mat-icon>delete</mat-icon>
                        <span *ngIf="!mappedItem.productImageUrl && !mappedItem.scheduleItemImageUrl">
                            &nbsp;Upload Image
                        </span>
                        <span *ngIf="mappedItem.productImageUrl || mappedItem.scheduleItemImageUrl">
                            &nbsp;Replace Image
                        </span>
                    </button>
                    <button mat-button
                            style="display: block;"
                            (click)="removeImage(form.form)"
                            *ngIf="mappedItem.scheduleItemImageUrl && mappedItem.costType !== COST_TYPE_ENUM.Actual">
                        <mat-icon>delete</mat-icon>
                        &nbsp;Remove Image
                    </button>
                </div>
            </div>
            <div class="flex-col flex">
                <ul class="simple-breadcrumbs"
                    *ngIf="!mappedItem.productOther">
                    <li *ngFor="let cat of selectedProduct?.categoryPath">{{cat?.label || cat}}</li>
                </ul>
                <h3 class="mat-subheading-1"
                    *ngIf="!mappedItem.productOther">{{selectedProduct?.code}}
                    {{selectedProduct?.name ? '-' : ''}} {{selectedProduct?.name}}
                </h3>
                <span *ngIf="form.controls?.quantity?.errors"
                      class="error-msg mat-caption cb-error">
                    <span *ngIf="form.controls.quantity.errors.required">Quantity is a required field</span>
                    <span *ngIf="form.controls.quantity.errors.maxlength">
                        Quantity is not allowed to exceed a length of
                        {{form.controls.quantity.errors.maxlength.requiredLength}} digits
                    </span>
                </span>
                <cb-input name="quantity"
                          label="Quantity"
                          type="number"
                          [required]="true"
                          maxlength="16"
                          [suffix]="productUom"
                          *ngIf="!shouldHideQuantity"
                          [(ngModel)]="mappedItem.quantity">
                </cb-input>
                <cb-select *ngIf="isSupplierViewable()"
                           name="supplierSelect"
                           label="Supplier"
                           [options]="suppliers"
                           [(ngModel)]="mappedItem.supplierId">
                </cb-select>
                <cb-currency [label]="getRateLabel()"
                             name="rateSnapshot"
                             *ngIf="mappedItem.costType && mappedItem.costType !== COST_TYPE_ENUM.Actual && mappedItem.costType !== COST_TYPE_ENUM.NoCharge && mappedItem.costType !== COST_TYPE_ENUM.DescriptiveOnly"
                             [(ngModel)]="mappedItem.rateSnapshot"
                             [required]="mappedItem.costType !== COST_TYPE_ENUM.Actual && mappedItem.costType !== COST_TYPE_ENUM.NoCharge"
                             [inclGst]="mappedItem.costType === COST_TYPE_ENUM.Provisional">
                </cb-currency>
            </div>
        </div>
        <cb-nullable-boolean label="Include in Flyer / Create Schedule"
                             name="showInClientSpecification"
                             [(ngModel)]="mappedItem.showInClientSpecification"
                             nullLabel="Not Set (Inherit from Product)">
        </cb-nullable-boolean>
        <cb-nullable-boolean label="Include in Colour Schedule"
                             name="showInColourYourDreams"
                             [(ngModel)]="mappedItem.showInColourYourDreams"
                             nullLabel="Not Set (Inherit from Product)">
        </cb-nullable-boolean>
        <cb-nullable-boolean label="Manual Colour Entry Required"
                             name="manualColourEntryRequired"
                             [(ngModel)]="mappedItem.manualColourEntryRequired"
                             nullLabel="Not Set (Inherit from Product)">
        </cb-nullable-boolean>
        <footer class="cb-margin-top-2x start-center flex-row">
            <span class="flex"></span>
            <button mat-raised-button
                    color="primary"
                    (click)="save()"
                    [disabled]="form.pristine || form.invalid">Save</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
