
import { IUserSystemAccessDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ISystemAccessLogicService } from './interfaces/i.system-access.logic-service';
import { ISystemAccessMappedItem } from './interfaces/i.system-access.mapped';


export class SystemAccessMappedItem
    extends BaseMappedItem<IUserSystemAccessDto, ISystemAccessMappedItem, ISystemAccessLogicService>
    implements ISystemAccessMappedItem {

    @DtoProp public readonly id: string;
    @DtoProp public selectedAreas: number[];
    @DtoProp public selectedRoles: number[];

    constructor(
        sourceData: IUserSystemAccessDto,
        logicService: ISystemAccessLogicService
    ) {
        super(sourceData, logicService, SystemAccessMappedItem);
    }
}

