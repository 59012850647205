import { Component, Inject } from '@angular/core';
import { IUserDto } from '@app/logic/users';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';

interface IData {
    buildingConsultantsWithLotInterest: IUserDto[];
}

@Component({
    selector: 'cb-view-lot-interests',
    templateUrl: './view-lot-interests-dialog.component.html',
    styleUrls: ['./view-lot-interests-dialog.component.scss']
})
export class ViewLotInterestsDialogComponent extends BaseDialogFormViewDirective<any, any, any> {
    public static readonly MIN_WIDTH = '40%';

    public buildingConsultantsWithLotInterest: IUserDto[];

    constructor(
        public readonly dialogRef: MatDialogRef<ViewLotInterestsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public toastService: ToastService) {
        super(dialogRef, toastService);
        this.buildingConsultantsWithLotInterest = data.buildingConsultantsWithLotInterest?.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    public getBuildingConsultantText(buildingConsultant: IUserDto): string {
        return `${buildingConsultant.firstName} ${buildingConsultant.lastName} - ${buildingConsultant.region}`;
    }

}
