
import { IDocumentTypeMappedItem } from './interfaces/i.document-type.mapped';
import { BaseMappedItem } from '../base/base-mapped-item';
import { IDocumentTypesLogicService } from './interfaces/i.document-types.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';
import { FileTypeEnumId, IDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class DocumentTypeMappedItem
    extends BaseMappedItem<IDocumentTypeDto, IDocumentTypeMappedItem, IDocumentTypesLogicService>
    implements IDocumentTypeMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public deletedDate: string;
    @DtoProp public fileTypes: string[];
    @DtoProp public isActive: boolean;
    @DtoProp public isDeleted: boolean;
    @DtoProp public isRequired: boolean;
    @DtoProp public label: string;
    @DtoProp public maximumFileSizeInMegaBytes: number;
    @DtoProp public description: string;
    @DtoProp public codeName: string;
    @DtoProp public sortOrder: number;
    @DtoProp public fileTypesFlags: FileTypeEnumId;

    constructor(
        sourceData: IDocumentTypeDto,
        logicService: IDocumentTypesLogicService
    ) {
        super(sourceData, logicService, DocumentTypeMappedItem);
    }
}
