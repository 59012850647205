import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { IDocumentTemplateDto } from './interfaces/i.document-template.dto';
import { IDocumentTemplateLogicService } from './interfaces/i.document-template.logic.service';
import { IDocumentTemplateMappedItem } from './interfaces/i.document-template.mapped';
import { DocumentTemplateMappedItem } from './document-template.mapped';
import { IDocumentTemplateTreeDto } from './interfaces/i.document-template-tree.dto';
import { IDocumentTemplateHistoryDto } from './interfaces/i.document-template-history.dto';

@Injectable()
export class DocumentTemplateLogicService
    extends BaseLogicService<IDocumentTemplateDto, IDocumentTemplateMappedItem>
    implements IDocumentTemplateLogicService {
    public $availableDocTemplates;

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('documenttemplates', DocumentTemplateMappedItem);
    }

    public downloadDocumentTemplate(documentTemplateCode: string): Observable<any> {
        return this.$http.download(`${this.$baseUri}/bycode/${documentTemplateCode}/download`);
    }

    public getTree(): Observable<IDocumentTemplateTreeDto[]> {
        return this.$http.get(`${this.$baseUri}/tree`);
    }

    public getHistotry(documentTemplateId: number): Observable<IDocumentTemplateHistoryDto[]> {
        return this.$http.get(`${this.$baseUri}/history/${documentTemplateId}`);
    }

    public downloadHistory(historyId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/files/${historyId}`);
    }

    public downloadTemplate(documentTemplateId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/${documentTemplateId}/file`);
    }
}
