import { CostTypeEnumId, ICategoryDto, IExternalReferenceDto, ISlotDto, ISpecGroupDto, ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ISlotMappedItem } from './interfaces/i.slot.mapped';
import { ISlotsLogicService } from './interfaces/i.slots.logic-service';

/** Note - this is also known as a ***schedule item***
 */
export class SlotMappedItem
    extends BaseMappedItem<ISlotDto, ISlotMappedItem, ISlotsLogicService>
    implements ISlotMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public reportOrder: number;
    @DtoProp public houseArea: ISpecGroupDto;
    @DtoProp public costType: CostTypeEnumId;
    @DtoProp public isRequired: boolean;
    @DtoProp public tags: ITagDto[];
    @DtoProp public categories: ICategoryDto[];
    @DtoProp public externalReferences: IExternalReferenceDto[];
    @DtoProp public name: string;
    @DtoProp public description: string;
    @DtoProp public isActive: boolean;
    @DtoProp public childSlots: number[];
    @DtoProp public parentSlotId: number;
    @DtoProp public readonly parent: ISlotDto;

    public $parentSlot: ISlotMappedItem;

    constructor(
        sourceData: ISlotDto,
        logicService: ISlotsLogicService
    ) {
        super(sourceData, logicService, SlotMappedItem, { isActive: true });
    }

    public $save(): Observable<any & ISlotDto[]> {
        const toSave = this.$getMappedDtoItem();
        this.$preSave(toSave);
        return (this.$logicService
            .$saveItem(toSave) as Observable<ISlotDto[]>)
            .pipe(
                map((response) => {
                    const data = response.find(x => x.id === this.id);
                    if (data) {
                        this.$updateThisAndOriginal(data);
                    }
                    return response;
                })
            );
    }

    public getChildSlots(): Observable<Array<ISlotDto>> {
        return this.$logicService.getChildSlots(this.id);
    }
}
