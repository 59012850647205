import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {ILotMappedItem} from '@app/logic/lots';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {IManageDialogData} from '@app/shared/interfaces/i.manage-dialog-data';
import {IDocumentEntityDto, IPreConsentPlanDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {combineLatest, mergeMap, Observable, Observer} from 'rxjs';
import {IPreConsentPlanMappedItem} from '@app/logic/pre-consent-plans';
import {IPreConsentPlansLogicService} from '@app/logic/pre-consent-plans/interfaces/i.pre-consent-plans-logic.service';
import {DocumentsLogicService} from '@app/logic/documents';
import {IRequestPreConsentPlanDocumentsToUpload} from '@app/views/lot/design/pre-consent/request-preconsent-dialog-wizard-steps/i.request-pre-consent-plan-documents-to-upload';
import {DesignRequirementsLogicService, IDesignRequirementsMappedItem} from '@app/logic/design-requirements';

@Component({
    selector: 'cb-request-preconsent-dialog',
    templateUrl: './request-preconsent-dialog.component.html',
    styleUrls: ['./request-preconsent-dialog.component.scss']
})
// eslint-disable-next-line max-len
export class RequestPreconsentDialogComponent extends BaseDialogFormViewDirective<IPreConsentPlanDto, IPreConsentPlanMappedItem, IPreConsentPlansLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '800px';
    public mappedItem: IPreConsentPlanMappedItem;
    public lotMappedItem: ILotMappedItem;
    public mode: FormMode;
    public selectedTabStep = 0;
    public maxSteps = 2;
    public preConsentId = 0;
    private designRequirementsMappedItem: IDesignRequirementsMappedItem;

    public documentsToUpload: IRequestPreConsentPlanDocumentsToUpload = {
        designInfoDocuments: [],
        preConsentPlanDocuments: []
    };
    constructor(
        public readonly documentsLogicService: DocumentsLogicService,
        private readonly designRequirementsLogicService: DesignRequirementsLogicService,
        private readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<RequestPreconsentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IPreConsentPlanMappedItem> & { lotMappedItem: ILotMappedItem; mode: FormMode; preConsentId: number },
        public readonly cbDialog: CbDialogService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = data.mappedItem;
        this.lotMappedItem = data.lotMappedItem;
        this.mode = data.mode;
        this.preConsentId = data.preConsentId ?? 0;
    }

    public ngOnInit(): void {
        this.formMode$.next(FormMode.Add);
        this.loadDesignRequirementsMappedItem();
    }

    public loadDesignRequirementsMappedItem(): void {
        this.designRequirementsLogicService.$getMappedItem(this.lotMappedItem.id).subOnce(item => {
            this.designRequirementsMappedItem = item;
        });
    }

    public next(): void {
        this.selectedTabStep++;
    }

    public back(): void {
        this.selectedTabStep--;
    }

    public canGoNext(): boolean {
        return this.selectedTabStep < this.maxSteps;
    }

    public canGoBack(): boolean {
        return this.selectedTabStep > 0;
    }

    public canSeeSave(): boolean {
        return this.selectedTabStep === this.maxSteps;
    }

    public requestPreconsent(): void {
        const loadingDialog = this.cbDialog.block('Please wait... your request is being processed');
        const observer = {
            next: _ => {
                loadingDialog.close();
                this.toastService.saveSuccess();
                this.dialogRef.close(true);
            },
            error: error => {
                loadingDialog.close();
                this.toastService.saveError(error.message ?? 'Something went wrong...');
                this.dialogRef.close(false);
            }
        } as Observer<IDocumentEntityDto[]>;
        const dto = this.mappedItem.$getMappedDtoItem();
        if(this.preConsentId > 0) {
            dto.preConsentId = this.preConsentId;
            this.mappedItem.create(dto).pipe(mergeMap(this.handleRequestDocuments)).subOnce(observer);
        } else {
            this.mappedItem.request(dto).pipe(mergeMap(this.handleRequestDocuments)).subOnce(observer);
        }
    }

    private readonly handleRequestDocuments = (preConsentPlanDto: IPreConsentPlanDto): Observable<IDocumentEntityDto[]> => {
        const observables = [];

        this.documentsToUpload.preConsentPlanDocuments.forEach((document) => {
            observables.push(this.documentsLogicService.uploadDocumentEntity(this.mappedItem.documentEntityUri, preConsentPlanDto.id, document));
        });

        this.documentsToUpload.designInfoDocuments.forEach((document, index) => {
            if (document.documentEntityId > 0) {
                observables.push(this.documentsLogicService.reUploadDocumentEntity(document));
            } else {
                observables.push(this.documentsLogicService.uploadDocumentEntity(
                    this.designRequirementsMappedItem.documentEntityUri,
                    this.designRequirementsMappedItem.documentEntityId,
                    document));
            }
        });

        return combineLatest(observables);
    };

    public isStepComplete(
        step1Form: UntypedFormGroup,
        step2Form: UntypedFormGroup,
        step3Form?: UntypedFormGroup,
    ): boolean {
        switch (this.selectedTabStep) {
            case 0:
                return step1Form.valid && this.mappedItem.items?.length > 0;
            case 1:
                return step1Form.valid && step2Form.valid;
            case 2:
                return step1Form.valid && step2Form.valid && step3Form.valid;
            default:
                return false;
        }
    }

}
