import {Inject} from '@angular/core';
import {LookupService} from '@app/core/services/lookup/lookup.service';
import {IPermissionDto, IUserPermissionsDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {Observable} from 'rxjs';
import {BaseMappedItem} from '../base/base-mapped-item';
import {DtoProp} from '../base/dto-prop.decorator';
import {IPermissionMappedItem} from './interfaces/i.permission.mapped';
import {IPermissionsLogicService} from './interfaces/i.permissions.logic-service';
import {IPermissionsMappedItem} from './interfaces/i.permissions.mapped';
import {PermissionLogicService} from './permission.logic.service';
import {IExplainRequestDto, IExplainResponseDto} from './permissions.logic.service';

export class PermissionsMappedItem
    extends BaseMappedItem<IUserPermissionsDto, IPermissionsMappedItem, IPermissionsLogicService>
    implements IPermissionsMappedItem {

    @DtoProp public readonly id: string;
    @DtoProp public permissions: IPermissionDto[];
    public $permissions: IPermissionMappedItem[];


    @Inject(LookupService) public readonly lookupService: LookupService;
    @Inject(PermissionLogicService) public readonly permissionLogicService: PermissionLogicService;

    constructor(
        sourceData: IUserPermissionsDto,
        logicService: IPermissionsLogicService
    ) {
        super(sourceData, logicService, PermissionsMappedItem);
    }

    protected $postLoad(): void {
        this.$logicService.hydrate$Permissions(this);
    };

    public async hydrate$Permissions(): Promise<void> {
        return await this.$logicService.hydrate$Permissions(this);
    };

    public explain(permission: IPermissionDto, event: Event): Observable<IExplainResponseDto> {
        return this.$logicService.explain({
            area: permission.area,
            id: this.id,
            permissionName: permission.name} as IExplainRequestDto);
    }
}

