import { CostTypeEnumId, ICategoryDto, IExternalReferenceDto, ISlotDto, ISpecGroupDto, ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { exportDto } from '@app/shared/logic-utils/export-dto.util';
import { Prop } from '@app/shared/decorators/prop.decorator';


class SlotDto implements ISlotDto {
    @Prop public readonly id: number;
    @Prop public reportOrder: number;
    @Prop public houseArea: ISpecGroupDto;
    @Prop public costType: CostTypeEnumId;
    @Prop public isRequired: boolean;
    @Prop public tags: ITagDto[];
    @Prop public categories: ICategoryDto[];
    @Prop public externalReferences: IExternalReferenceDto[];
    @Prop public name: string;
    @Prop public description: string;
    @Prop public isActive: boolean;
    @Prop public childSlots: number[];
    @Prop public parentSlotId: number;
    @Prop public parent: ISlotDto;
}

export const slotDto = exportDto<ISlotDto>(SlotDto);
