import { Component } from '@angular/core';
import { detect } from 'detect-browser';
import { CurrentUserService } from '../../../../core/authentication/current.user';
import { environment } from '../../../../../environments/environment';
import { copyElementContentsToClipboard } from '../../../../shared/utils/copy-select-html.util';
import { Debounce } from '../../../../shared/decorators/debounce.decorator';
import { NewHttpErrorsService } from '@app/core/services/http-errors/http-errors.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { NgForm } from '@angular/forms';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
    providers: [
        UserSettingsService,
    ]
})
export class UserSettingsComponent {

    public readonly browser = detect();
    public readonly window = window;
    public readonly environment = environment;
    public clientIp: string;

    private readonly clipboardTooltipDefault = 'Copy info to clipboard';
    private readonly copiedMessage = 'Copied!';
    public clipboardTooltip = this.clipboardTooltipDefault;

    constructor(
        private readonly userSettingsService: UserSettingsService,
        public readonly currentUserService: CurrentUserService,
        public readonly httpErrors: NewHttpErrorsService,
        private readonly cbDialog: CbDialogService,
        private readonly toastService: ToastService,
        public readonly userCache: UserCacheService
    ) {
        userSettingsService
            .getClientIpAddress()
            .subOnce((ip) => {
                this.clientIp = ip;
            });
    }

    public confirmSaveUserCache(form: NgForm): void {
        this.cbDialog.confirm({
            message: 'Are you sure you want to save changes to your Sticky Search/Cache area preferences?',
            confirmed: () => {
                this.userCache.saveMaster().then(() => {
                    this.toastService.saveSuccess();
                    form.form.markAsPristine();
                });
            },
        });
    }

    public confirmCheckAllUserCache(form: NgForm): void {
        this.cbDialog.confirm({
            message: 'Are you sure you want to check all User Cache Areas? The changes will not be saved automatically.',
            confirmed: () => {
                this.userCache.allList.forEach((value) => {
                    this.userCache.master[value.key] = true;
                });
                form.form.markAsDirty();
            },
        });
    }
    public confirmUncheckAllUserCache(form: NgForm): void {
        this.cbDialog.confirm({
            message: 'Are you sure you want to un-check all User Cache Areas? The changes will not be saved automatically.',
            confirmed: () => {
                this.userCache.allList.forEach((value) => {
                    this.userCache.master[value.key] = false;
                });
                form.form.markAsDirty();
            },
        });
    }

    public confirmClearSessionHttpErrorLogs(): void {
        this.cbDialog.confirm({
            message: 'Are you sure you want to clear all of the Http Error Logs for the current users session?',
            confirmed: () => {
                this.httpErrors.clearLogs();
                this.toastService.showToast('Http Error Logs cleared');
            },
        });
    }

    public copyInfoToClipboard(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();

        const result = copyElementContentsToClipboard('#info-table');
        if (result) {
            this.clipboardTooltip = this.copiedMessage;
        }
    }

    @Debounce(100)
    public resetClipboardTooltip(): void {
        this.clipboardTooltip = this.clipboardTooltipDefault;
    }

    public confirmClearUserPreferencesCache(): void {
        this.cbDialog.confirm({
            message: 'Are you sure you want to clear all of your user preferences? This will also clear your Sticky Search/Cache area preferences below.',
            confirmed: () => {
                this.userSettingsService
                    .deleteAllSettingsForCurrentUser()
                    .subOnce({
                        next: () => {
                            this.toastService.showToast('User preferences cleared');
                        }
                    });
            },
        });
    }
}
