import { Component } from '@angular/core';
import { BankAccountsLogicService } from '@app/logic/bank-accounts/bank-accounts.logic.service';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { IBankAccountDto } from '@app/logic/bank-accounts';
import { BankAccountMappedItem } from '@app/logic/bank-accounts/bank-account.mapped';
import { BankAccountDialogComponent } from './bank-account-dialog/bank-account-dialog.component';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-bank-accounts',
    templateUrl: './bank-accounts.component.html',
    styleUrls: ['./bank-accounts.component.scss'],
    providers: [BankAccountsLogicService, UsersLogicService]
})
export class BankAccountsComponent extends BaseSimpleListViewDirective<IBankAccountDto, BankAccountsLogicService> {
    public readonly displayedColumns: string[] = [
        'bankAccount',
        'bank',
        'isTrustAccount',
        'isActive',
        'actions'
    ];

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly bankAccountsLogicService: BankAccountsLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
    ) {
        super(
            cbDialog,
            bankAccountsLogicService,
            BankAccountDialogComponent,
            'Bank Account',
            BankAccountMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: Array<IBankAccountDto>): Array<IBankAccountDto> {
        return searchResults.sort((a, b) => a.accountName.localeCompare(b.accountName));
    }
}
