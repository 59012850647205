import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {IPurchaseOrderSearchDto} from '@app/logic/purchase-orders';
import {BuildProgrammeActivityLogicService} from '@app/logic/build-programme-activity';
import {SsrsLogicService} from '@app/logic/ssrs';

interface IData {
    purchaseOrders: IPurchaseOrderSearchDto[];
    displayedColumns: string[];
}
@Component({
    selector: 'app-complete-multiple-activities-dialog',
    templateUrl: './complete-multiple-activities-dialog.component.html',
    styleUrls: ['./complete-multiple-activities-dialog.component.scss']
})
export class CompleteMultipleActivitiesDialogComponent {

    public static readonly MIN_WIDTH = '980px';
    public inProgress = {} as { [purchaseOrderId: number]: boolean };
    public successful = {} as { [purchaseOrderId: number]: boolean };
    public failed = {} as { [purchaseOrderId: number]: string };


    constructor(
        public readonly dialogRef: MatDialogRef<CompleteMultipleActivitiesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        private readonly ssrLogic: SsrsLogicService,
        private readonly buildProgrammeActivityLogic: BuildProgrammeActivityLogicService,
    ) { }

    public completeAll(): void{
        for (const purchaseOrder of this.data.purchaseOrders) {
            this.inProgress[purchaseOrder.id] = true;

            if (purchaseOrder.isManualOrder) {
                this.buildProgrammeActivityLogic
                    .completeManualOrder(purchaseOrder.buildProgrammeActivityId)
                    .subOnce({
                        next: () => {
                            this.successful[purchaseOrder.id] = true;
                        },
                        error: (ex) => {
                            this.successful[purchaseOrder.id] = false;
                            this.failed[purchaseOrder.id] = ex.message;
                        },
                    });
            } else {
                this.ssrLogic
                    .completeSsr(purchaseOrder.ssrId)
                    .subOnce({
                        next: () => {
                            this.successful[purchaseOrder.id] = true;
                        },
                        error: (ex) => {
                            this.failed[purchaseOrder.id] = ex.message;
                            this.successful[purchaseOrder.id] = false;
                        },
                    });
            }
        }
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }


}
