import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILotSpecColourItemMappedItem } from '@app/logic/lot-spec-colour-item';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import {
    ILotSpecColourItemDto,
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IHaveLotSpecColourItemUiActions, LotSpecColourItemUIActions } from './lot-spec-colour-item-ui-actions';

@Component({
    selector: 'cb-lot-spec-colour-item-card',
    templateUrl: './lot-spec-colour-item-card.component.html',
    styleUrls: ['./lot-spec-colour-item-card.component.scss'],
    providers: [
        LotSpecColourItemUIActions
    ]
})
export class LotSpecColourItemCardComponent implements IHaveLotSpecColourItemUiActions {

    @Input() public readonly lotSpec: ILotSpecMappedItem;
    @Input() public readonly mappedItem: ILotSpecColourItemMappedItem;
    @Output() public readonly itemDeleted = new EventEmitter<ILotSpecColourItemDto>();
    @Output() public readonly itemEdited = new EventEmitter<ILotSpecColourItemDto>();


    constructor(
        public readonly uiActions: LotSpecColourItemUIActions,
    ) {
        this.uiActions.setController(this);
    }
}
