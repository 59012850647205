import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectReleaseStagePermissions } from '@app/core/permissions/project-release-stage.permissions';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IDefaultSuppliersDialogData, ReleaseDefaultSupplierDialogComponent } from './release-default-supplier-dialog/release-default-supplier-dialog.component';
import { IProjectReleaseStageDefaultProductSupplierDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/ProjectReleaseStageDefaultProductSupplierDto';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';

@Component({
    selector: 'cb-release-default-supplier',
    templateUrl: './release-default-supplier.component.html',
    styleUrls: ['./release-default-supplier.component.scss']
})
export class ReleaseDefaultSupplierComponent implements OnInit {

    @Input() public mappedItem: IReleaseStageMappedItem;
    public loaded = false;

    constructor(
        public readonly projectReleaseStagePermissions: ProjectReleaseStagePermissions,
        public readonly cbDialog: CbDialogService,
        private readonly projectReleaseStagesLogicService: ReleaseStagesLogicService,
        private route: ActivatedRoute,
    ) { }

    public ngOnInit(): void {
        if (!this.mappedItem?.id) {
            this.route.params.subOnce(val => {
                this.projectReleaseStagesLogicService.$getMappedItem(val.releaseId).subOnce(result => {
                    this.mappedItem = result;
                    this.loaded = true;
                });
            });
        } else {
            this.loaded = true;
        }
    }

    public dialog(defaultSupplier?: IProjectReleaseStageDefaultProductSupplierDto, idx?: number): void {
        this.cbDialog
            .open<IDefaultSuppliersDialogData, IProjectReleaseStageDefaultProductSupplierDto>(
            ReleaseDefaultSupplierDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem,
                    item: cloneDeepSafe(defaultSupplier)
                },
            }
        )
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    if (idx !== undefined && idx >= 0) {
                        this.mappedItem.defaultProductSuppliers[idx] = result;
                    } else {
                        this.mappedItem.defaultProductSuppliers.push(result);
                    }
                }
            });
    }

    public deleteClick(defaultSupplier: IProjectReleaseStageDefaultProductSupplierDto, idx: number): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Default Supplier',
            message: 'Are you sure you want to delete this Default Supplier?',
            confirmed: () => {
                this.projectReleaseStagesLogicService
                    .deleteDefaultSupplier(defaultSupplier.projectReleaseStageId, defaultSupplier.supplyType.id)
                    .subOnce(_ => {
                        this.mappedItem.defaultProductSuppliers.splice(idx, 1);
                    });
            }
        });
    }

    public addAll(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Reset Default Suppliers',
            message: 'Are you sure you want to reset the default suppliers for this project release?',
            confirmed: () => {
                this.mappedItem.addAllDefaultSuppliers(this.mappedItem.id).subOnce((defaultSuppliers: IProjectReleaseStageDefaultProductSupplierDto[]) => {
                    this.mappedItem.defaultProductSuppliers = defaultSuppliers;
                });
            }
        });
    }

}
