import { Component, Input } from '@angular/core';
import { FieldValidationItem } from '@app/core/services/field-validation/field.validation.item';
import { LOT_COMPONENT_TYPE } from '@app/core/services/field-validation/lot-field-validator/interfaces/i.lot.field.rules.service';
import { LotFieldValidatorService } from '@app/core/services/field-validation/lot-field-validator/lot-field-validator-service';
import { ILotMappedItem } from '@app/logic/lots';
import { LotSummaryPinnablePanelDirective } from '../../shared/lot-summary-pinnable-panel';

@Component({
    selector: 'cb-lot-summary-insurance-details',
    templateUrl: './lot-summary-insurance-details.component.html',
    styleUrls: ['./lot-summary-insurance-details.component.scss']
})
export class LotSummaryInsuranceDetailsComponent extends LotSummaryPinnablePanelDirective {

    @Input() public lotMappedItem: ILotMappedItem;

    // Field Validation Items
    public insurancePriceField: FieldValidationItem = new FieldValidationItem();
    public insuranceStartDateField: FieldValidationItem = new FieldValidationItem();
    public insuranceEndDateField: FieldValidationItem = new FieldValidationItem();
    public insuranceInterestedPartyField: FieldValidationItem = new FieldValidationItem();
    public insuranceInterestedPartyEmailField: FieldValidationItem = new FieldValidationItem();
    public insuranceCoverNumberField: FieldValidationItem = new FieldValidationItem();

    constructor(private readonly lotFieldValidatorService: LotFieldValidatorService,) {
        super();
    }

    public ngOnInit(): void {
        this.lotFieldValidatorService.dto = this.lotMappedItem.$getMappedDtoItem();
        this.populateFieldValidationItems();
    }

    private populateFieldValidationItems(): void {
        this.insurancePriceField =
            this.lotFieldValidatorService.insurancePrice(LOT_COMPONENT_TYPE.InsuranceDetails);
        this.insuranceStartDateField =
            this.lotFieldValidatorService.insuranceStartDate(LOT_COMPONENT_TYPE.InsuranceDetails);
        this.insuranceEndDateField =
            this.lotFieldValidatorService.insuranceEndDate(LOT_COMPONENT_TYPE.InsuranceDetails);
        this.insuranceInterestedPartyField =
            this.lotFieldValidatorService.insuranceInterestedParty(LOT_COMPONENT_TYPE.InsuranceDetails);
        this.insuranceInterestedPartyEmailField =
            this.lotFieldValidatorService.insuranceInterestedPartyEmail(LOT_COMPONENT_TYPE.InsuranceDetails);
        this.insuranceCoverNumberField =
            this.lotFieldValidatorService.insuranceCoverNumber(LOT_COMPONENT_TYPE.InsuranceDetails);
    }


}
