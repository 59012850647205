import { LotConsentLogicService } from '@app/logic/lot-consents/lot-consent-logic.service';
import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { LotConsentViewService } from '../service/lot-consent-view.service';
import { CouncilRfiViewService } from '../../council-rfi/service/council-rfi-view.service';
import { ConsentType } from '@app/logic/lot-consents';
import { ILotMappedItem } from '@app/logic/lots';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-consents-main',
    templateUrl: './lot-consents-main.component.html',
    styleUrls: ['./lot-consents-main.component.scss'],
    providers: [
        LotConsentViewService,
        CouncilRfiViewService // provided for manage consent - council rfi tab
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotConsentsMainComponent implements OnInit {

    public lotDto: ILotDto;

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    private _subscription: Subscription;

    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            mappedItem.$reload().subOnce((lotDto) => {
                this.lotDto = lotDto;
                this.lotMappedItem$$.next(mappedItem);
            });
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    @Output() public reloadLotMappedItem = new EventEmitter();

    constructor(
        protected readonly councilRfiViewService: CouncilRfiViewService,
        public readonly lotConsentViewService: LotConsentViewService,
        public readonly lotConsentLogic: LotConsentLogicService,
        public readonly navigation: NavigationService,
        private readonly cdRef: ChangeDetectorRef,
    ) {
    }

    public ngOnInit(): void {
        this.loadFromParams();

        this._subscription = this.lotConsentViewService.systemArea$$.subscribe(() => {
            this.cdRef.detectChanges();
        });
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    public loadFromParams(): void {
        const params = this.navigation.getQueryParams();
        const id = Number(params?.lotConsentId);
        const consentType = params?.consentType as ConsentType;
        if (id) {
            this.lotConsentViewService.loadConsentById(id, consentType);
        }
    }
}
