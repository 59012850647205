<form #form="ngForm"
      class="cb-margin flex-col flex">
    <div class="flex-row layoutGap-30">
        <cb-currency [readonly]="!isEdit"
                     name="grossMargin"
                     label="Gross Margin"
                     (ngModelChange)="updateGrossMargin($event)"
                     [(ngModel)]="grossMargin">
        </cb-currency>
        <cb-input name="grossMarginPercent"
                  label="Gross Margin %"
                  [required]="true"
                  [readonly]="true"
                  [(ngModel)]="grossMarginPercent">
        </cb-input>
        <button *ngIf="!isEdit"
                mat-raised-button
                class="cb-margin-bottom-2x"
                (click)="edit()">
            <mat-icon svgIcon="pencil"></mat-icon>
            Edit
        </button>
        <button *ngIf="isEdit"
                mat-raised-button
                class="cb-margin-bottom-2x"
                (click)="cancelEdit()">Cancel
        </button>
        <button *ngIf="isEdit"
                mat-raised-button
                class="cb-margin-bottom-2x"
                color="primary"
                [disabled]="!form.dirty || !form.valid || !grossMargin"
                (click)="saveMargin()">Save
        </button>
    </div>
    <div class="mat-h3 flex-row">Margin History</div>
    <div class="flex-row">
        <cb-table class="flex"
                  [columns]="displayedColumns"
                  [loaded]="historyItems$$ | async">
            <tr cbTableRow
                *ngFor="let history of historyItems$$ | async">
                <td>{{history.updatedDate | date:'dd MMM yyyy hh:mm a'}}</td>
                <td>{{history.updatedByName}}</td>
                <td>{{history.grossMargin | cbCurrency}}</td>
                <td>{{history.grossMarginPercentage}}%</td>
            </tr>
        </cb-table>
    </div>
</form>
