import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cbFilter',
    pure: false
})
export class CbFilterPipe implements PipeTransform {

    public transform(list: any[], key: string, value: string | any): any {
        return list?.filter(i => i[key] === value);
    }

}


