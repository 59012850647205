import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotBuildDocumentsComponent } from './lot-build-documents/lot-build-documents.component';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';

@NgModule({
    declarations: [LotBuildDocumentsComponent],
    imports: [
        CommonModule,
        CbDocumentsModule,
    ],
    exports: [
        LotBuildDocumentsComponent
    ],
})

export class LotBuildDocumentsModule { }
