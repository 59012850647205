import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IContactMappedItem } from '@app/logic/contacts/interfaces/i.contact.mapped';
import { ContactsLogicService, IContactsLogicService } from '@app/logic/contacts';
import { CbEnumToLabelPipe } from '@app/shared/pipe/enum-to-label/enum-to-label.pipe';
import { NgForm } from '@angular/forms';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Observable } from 'rxjs';
import { IContactDto } from '@app/logic/contacts/interfaces/i.contact.dto';

@Component({
    selector: 'cb-contact-create-login-dialog',
    templateUrl: './contact-create-login-dialog.component.html',
    styleUrls: ['./contact-create-login-dialog.component.scss']
})
export class ContactCreateLoginDialogComponent extends BaseDialogFormViewDirective<IContactDto, IContactMappedItem, IContactsLogicService> implements OnInit {
    public password: string;
    public mode: 'createLogin' | 'changeUsername' | 'resetPassword';
    public mappedItem: IContactMappedItem;
    public get loginForm(): NgForm {
        return this.loginFormParent?.first;
    }
    @ViewChildren('loginForm') public loginFormParent;

    constructor(
        private readonly toastService: ToastService,
        protected readonly contactsLogicService: ContactsLogicService,
        public readonly dialogRef: MatDialogRef<ContactCreateLoginDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.mode = data.mode;
    }

    public ngOnInit(): void {
        if (this.mode === 'createLogin') {
            this.contactsLogicService.createPortalLogin({ contactId: this.mappedItem.id }).subOnce({
                next: this.handleCreateLogin.bind(this)
            });
        }
    }

    private handleCreateLogin(userCredentials: { username: string; password: string }): void {
        this.mappedItem.portalUsername = userCredentials.username ? userCredentials.username : undefined;
        this.password = userCredentials.password ? userCredentials.password : undefined;
        if (this.mappedItem.portalUsername && this.password) {
            this.loginForm.form.markAsDirty();
        }
    }

    protected handleNext = (x: any) => {
        this.dialogRef.close(x);
        this.toastSerivce.showToast('User Login successfully updated', 'OK', 1000, this.toastService.deleteToast.bind(this));
    };

    public saveMethod(): Observable<any> {
        if (this.mode === 'changeUsername') {
            return this.contactsLogicService.updatePortalUsername({ contactId: this.mappedItem.id, username: this.mappedItem.portalUsername });
        } else {
            return this.contactsLogicService.updatePortalLogin({
                id: this.mappedItem.id,
                username: this.mappedItem.portalUsername,
                password: this.password
            });
        }
    }

    public createLogin(): void {
        this.contactsLogicService
            .updatePortalLogin({ id: this.mappedItem.id, username: this.mappedItem.portalUsername, password: this.password })
            .subOnce(login => { this.dialogRef.close(login); });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public getHeading(): string {
        return new CbEnumToLabelPipe().toHumanizeWords(this.mode.toString());
    }
}


export interface IData {
    mappedItem: IContactMappedItem;
    mode: 'createLogin' | 'changeUsername' | 'resetPassword';
}
