<div class="cb-margin flex-row flex"
     style="overflow: hidden">
    <mat-tab-group class="flex"
                   (selectedIndexChange)="onTabChanged($event);"
                   [selectedIndex]="selectedTabIndex"
                   [cbTabRoute]="USER_ROUTE"
                   style="overflow: hidden">
        <mat-tab label="Details"
                 cbTabRouteId="details">
            <cb-user-details [mappedItem]="mappedItem$.value"
                             [userId$]="userId$">
            </cb-user-details>
        </mat-tab>
        <mat-tab label="System Access"
                 cbTabRouteId="system-access"
                 [disabled]="!(userId$ | async)">
            <cb-system-access [userId$]="userId$">
            </cb-system-access>
        </mat-tab>
        <mat-tab label="Permissions"
                 cbTabRouteId="permissions"
                 [disabled]="!(userId$ | async)">
            <cb-permissions [userId$]="userId$">
            </cb-permissions>
        </mat-tab>
    </mat-tab-group>
    <cb-user-side-bar-card [mappedItem$]="mappedItem$"
                           *ngIf="mappedItem$ | async"
                           [mappedItem]="mappedItem$.value"
                           [activeComponent]="activeComponent$.value">
    </cb-user-side-bar-card>
</div>
