<div class="container">
    <button mat-icon-button
            (click)="toggle($event)">
        <mat-icon [ngClass]="iconColour">{{icon}}</mat-icon>
    </button>
    <div class="fixed"
         [style.bottom]=" undefinedBottom ? undefined : verticalPosition === 'top' ? contentCardHeight + 'px' : 0 + 'px'"
         [style.position]="cssPosition"
         style="z-index: 9999;position: relative;">
        <div #contentCardRef
             class="card-wrapper hide"
             [ngClass]="horizontalPosition">
            <mat-card class="mat-elevation-z3 cb-padding">
                <mat-card-content class="mat-body-1 card-content">
                    <ng-content></ng-content>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>