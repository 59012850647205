import {Injector} from '@angular/core';
import {HttpWrapperService} from '@app/core/services/http-wrapper/http-wrapper.service';
import {cloneDeepSafe} from '@app/shared/utils/clone-object.util';
import {delay, map, mergeMap, MonoTypeOperatorFunction, Observable, of, retryWhen, throwError} from 'rxjs';
import {ClassCtor} from '@app/shared/types/classctor.type';
import {BaseMappedItem} from './base-mapped-item';
import {IBaseLogicService} from './interfaces/i.base-logic.service';
import {IdAndLabelDto} from '@app/logic/location/interfaces/location.model';

export const DEFAULT_DATA_ERROR = 'defaultData is not an instance of an Object';
export const MAPPEDITEM_DATA_ERROR = 'Invalid defaultData - defaultData cannot be an instance of a BaseMappedItem';
export const MAPPEDITEM_CTOR_ERROR = '$createMappedItem: Invalid mappedItemClass - must be a Mapped Item class that extends BaseMappedItem';

/** IMPORTANT: Use `$` prefix for ALL properties and functions on the BaseLogicService. \
 * This avoids any naming overlaps from the sub class / sub class dto properties. \
 * It also makes it clear that the property/function that is being accessed is on the BaseLogicService.
 */
export abstract class BaseLogicService<DtoType, MappedItemType>
implements IBaseLogicService<DtoType, MappedItemType> {

    protected abstract readonly $http: HttpWrapperService;
    protected abstract readonly $injector: Injector;


    constructor(
        public readonly $baseUri: string,
        protected readonly $defaultMappedItemCtor: ClassCtor<MappedItemType>
    ) { }

    protected $retryRequestWhen<ReturnType>(): MonoTypeOperatorFunction<ReturnType> {
        return retryWhen(err => {
            let retries = 2;
            return err.pipe(
                delay(1000),
                mergeMap(error => {
                    retries--;
                    if (retries > 0) {
                        return of(error);
                    } else {
                        return throwError(error);
                    }
                }));
        });
    }

    public $createMappedItem<ReturnType = MappedItemType>(
        defaultData = {} as Partial<DtoType>,
        mappedItemClass = this.$defaultMappedItemCtor as unknown as ClassCtor<ReturnType>
    ): ReturnType {
        if (defaultData == null || defaultData instanceof Array || !(defaultData instanceof Object)) {
            throw new Error(DEFAULT_DATA_ERROR);
        }
        if (defaultData instanceof BaseMappedItem) {
            throw new Error(MAPPEDITEM_DATA_ERROR);
        }
        if (!(mappedItemClass.prototype instanceof BaseMappedItem)) {
            throw new Error(MAPPEDITEM_CTOR_ERROR);
        }
        return new mappedItemClass(defaultData, this);
    }

    public $getItem(entityId: string | number, ..._args: any[]): Observable<DtoType> {
        return this.$http.get<DtoType>(`${this.$baseUri}/${entityId}`).pipe(
            this.$retryRequestWhen()
        );
    }
    public $getMappedItem(entityId: string | number, ..._args: any[]): Observable<MappedItemType> {
        return this.$getItem(entityId, _args)
            .pipe(
                map(x => this.$createMappedItem(x))
            );
    }

    public $saveItem(entity: any): Observable<DtoType> {
        let uri = this.$baseUri;
        if (entity.id) {
            uri += `/${entity.id}`;
        }
        return this.$http.post<DtoType>(uri, entity);
    }

    public $deleteItem<ReturnType>(id: any, ..._args: any[]): Observable<ReturnType> {
        let uri = this.$baseUri;
        if (id) {
            uri += `/${id}`;
        }
        return this.$http.delete<ReturnType>(uri);
    }

    public $getList(..._args: any[]): Observable<Array<DtoType>> {
        return this.$http
            .get<Array<DtoType>>(this.$baseUri);
    }

    public $getSkinnyList(..._args: any[]): Observable<Array<IdAndLabelDto>> {
        return this.$http
            .get<Array<IdAndLabelDto>>(this.$baseUri +'/skinny');
    }

    public $getMappedList(..._args: any[]): Observable<MappedItemType[]> {
        return this.$getList(_args)
            .pipe(
                map(items => items.map(x => this.$createMappedItem(x)))
            );
    }

    /** **Recursive** - Safely clones objects, arrays or MappedItems - extends clone-object.util.ts cloneDeepSafe to avoid circular import warnings
     *
     * @param cloneSource - the object/array/data to be deep cloned.
     * @returns a deep clone of the cloneSource param.
     */
    public $cloneDeepSafe<ClonedType>(cloneSource: ClonedType): ClonedType {
        return cloneDeepSafe(cloneSource, false);
    }
}
