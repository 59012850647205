import { BuildTypeLogicService } from './../../../../logic/build-type/build-type-logic.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SaleDetailsEditDialogComponent } from '../sale-details-edit-dialog/sale-details-edit-dialog.component';
import { IBuildTypeDto } from '@app/logic/build-type';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { COMPASS_DIRECTION_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { IClientSaleLotUpdateDto, IClientSaleMappedItem } from '@app/logic/client-sale';

interface IData {
    clientSale: IClientSaleMappedItem;
}

@Component({
    selector: 'cb-lot-details-edit-dialog',
    templateUrl: './lot-details-edit-dialog.component.html',
    styleUrls: ['./lot-details-edit-dialog.component.scss'],
    providers: [
        BuildTypeLogicService
    ]
})
export class LotDetailsEditDialogComponent extends BaseDialogFormViewDirective<IClientSaleLotUpdateDto, any, any> implements OnInit {

    public clientSaleLotDetails: IClientSaleLotUpdateDto;

    public readonly COMPASS_DIRECTION_TYPE_ENUM = COMPASS_DIRECTION_TYPE_ENUM.toLookup();
    public buildTypes: IBuildTypeDto[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<SaleDetailsEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly buildTypeLogic: BuildTypeLogicService,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.mapLotDetails();

        this.buildTypeLogic
            .getListByLotType(this.data.clientSale.lot.lotTypeId)
            .subOnce(x => this.buildTypes = x);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public save(): void {
        this.data.clientSale.updateLotDetails(this.clientSaleLotDetails)
            .subOnce({
                next: this.handleNext,
                error: this.handleError
            });
    }

    private mapLotDetails(): void {
        this.clientSaleLotDetails = {
            lotNumber: this.data.clientSale.lot.lotNumber,
            buildTypeId: this.data.clientSale.lot.buildTypeId,
            isShowHome: this.data.clientSale.lot.isShowHome,
            hasTitle: this.data.clientSale.lot.hasTitle,
            expectedTitleDate: this.data.clientSale.lot.expectedTitleDate,
            roadDirectionId: this.data.clientSale.lot.roadDirectionId,
        };
    }
}
