import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseFormViewDirective } from './base-form-view.directive';
import { IBaseMappedItem } from '@app/logic/base/interfaces/i.base-mapped-item';
import { Directive } from '@angular/core';

@Directive()
export abstract class NonDialogFormViewDirective<
    DtoType,
    MappedItemType extends IBaseMappedItem<
    DtoType, MappedItemType, LogicServiceType>, LogicServiceType>
    extends BaseFormViewDirective<
    DtoType, MappedItemType, LogicServiceType> {
    constructor(
        toastSerivce: ToastService,
    ) {
        super(toastSerivce);
    }

    public onCancelClicked(): any {}
    public onEditClicked(): any {}

    public async save(): Promise<void> {
        this.saveInProgress = true;
        return this.saveMethod().toPromise().then((result) => {
            this.handleNext(this.handleSaveSuccess(result));
            this.saveInProgress = false;
        });
    }
}
