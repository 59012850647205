import { BaseTableComponentDirective, TableColumn } from '../base-table.component';
import { Component, OnInit } from '@angular/core';

import { DecimalPipe } from '@angular/common';
import { getBaseFormComponentDirectiveProvider } from '../../forms/base-form-component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';

/** @deprecated Do not use anymore, due to lack of strong typing with the string based properties in the column definitions
 * ***Use cb-table instead***
 */
@Component({
    selector: 'cb-standard-table',
    templateUrl: './standard-table.component.html',
    styleUrls: ['./standard-table.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(StandardTableComponent),
        DecimalPipe
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class StandardTableComponent<TableDataType> extends BaseTableComponentDirective<TableDataType> implements OnInit {

    constructor(private readonly decimalPipe: DecimalPipe) {
        super();
    }

    public ngOnInit(): void {
        this.setColumns();
        this.setFooterColumns();
    }

    public getTextFieldTextPercent(element: any, column: TableColumn): string {
        if (column.valueProp2) {
            return `${this.decimalPipe.transform(element[column.valueProp][column.valueProp2], '1.2-2')}%`;
        } else {
            return `${this.decimalPipe.transform(element[column.valueProp], '1.2-2')}%`;
        }
    }
}
