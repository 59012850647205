import { IDesignSchemeDocumentDto, LotTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { exportDto } from '@app/shared/logic-utils/export-dto.util';
import { Prop } from '@app/shared/decorators/prop.decorator';

class DesignSchemeDocumentDto implements IDesignSchemeDocumentDto {
    @Prop public id: number;
    @Prop public lotSpecVersion: number;
    @Prop public hasLotSpecItems: boolean;
    @Prop public name: string;
    @Prop public projectName: string;
    @Prop public addressStreet: string;
    @Prop public addressCity: string;
    @Prop public revisionType: string;
    @Prop public revisionTypeId: number;
    @Prop public numberOfAssignedChanges: number;
    @Prop public clientAccountName: string;
    @Prop public clientAccountId: number;
    @Prop public createdByUserName: string;
    @Prop public rejectionNotes: string;
    @Prop public lotType: LotTypeEnumId;
    @Prop public isConceptAppliedToLot: boolean;
    @Prop public hasUnansweredQuestion: boolean;
    @Prop public clientBudget: number;
    @Prop public finalisedDate: DateString;
    @Prop public lotNumber: number;
    @Prop public jobNumber: string;
    @Prop public lotId: number;
    @Prop public revisionNumber: string;
    @Prop public designComplexityId: number;
    @Prop public complexityCode: string;
    @Prop public createdDate: DateString;
    @Prop public dueBy: DateString;
    @Prop public status: string;
    @Prop public statusId: number;
    @Prop public assignedUserId: string;
    @Prop public assignedToUserName: string;
    @Prop public reviewerName: string;
    @Prop public createdById: string;
    @Prop public team: string;
    @Prop public isUnassigned: boolean;
}

export const designSchemeDocumentDto = exportDto<IDesignSchemeDocumentDto>(DesignSchemeDocumentDto);
