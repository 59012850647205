import { BaseMappedItem } from '../base/base-mapped-item';
import { IUserDto } from './interfaces/i.user.dto';
import { IUsersLogicService } from './interfaces/i.users.logic.service';
import { IUserMappedItem } from './interfaces/i.user.mapped';
import { DtoProp } from '../base/dto-prop.decorator';
import { Observable } from 'rxjs';
import { ITagDto, IUserSystemAccessDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BusinessEntitiesLogicService } from '../business-entity';
import { DepInject } from '../base/dep-inject.decorator';
import { TagsLogicService } from '../tags';
import { IFileResponse } from '@app/shared/interfaces/i.file-response.dto';

export class UserMappedItem
    extends BaseMappedItem<IUserDto, IUserMappedItem, IUsersLogicService>
    implements IUserMappedItem {

    @DtoProp public readonly id: string;
    @DtoProp public firstName: string;
    @DtoProp public company: string;
    @DtoProp public lastName: string;
    @DtoProp public activeDirectoryUsername: string;
    @DtoProp public isStaffMember: boolean;
    @DtoProp public label: string;
    @DtoProp public jobTitle: string;
    @DtoProp public email: string;
    @DtoProp public phoneMobile: string;
    @DtoProp public region: string;
    @DtoProp public primaryBusinessEntity: string;
    @DtoProp public isActiveDirectoryUser: boolean;
    @DtoProp public isActive: boolean;
    @DtoProp public selectedBusinessEntities: number[];
    @DtoProp public selectedTeams: number[];
    @DtoProp public selectedTags: number[];

    @DepInject(TagsLogicService) public readonly tagsLogicService: TagsLogicService;
    @DepInject(BusinessEntitiesLogicService) public readonly businessEntitiesLogicService: BusinessEntitiesLogicService;

    constructor(
        sourceData: IUserDto,
        logicService: IUsersLogicService
    ) {
        super(sourceData, logicService, UserMappedItem);
    }

    public generateFullUserDetailReport(): Observable<IFileResponse> {
        return this.$logicService.generateFullUserDetailReport(this.id);
    }

    public saveSystemAccess(entity: IUserSystemAccessDto): Observable<IUserSystemAccessDto> {
        return this.$logicService.saveSystemAccess(entity);
    }

    public $preSave(toSave: IUserDto): void {
        toSave.isActiveDirectoryUser = true;
        toSave.selectedTags = this.selectedTags?.map(tag => ({ id: (tag as unknown as ITagDto)?.id || tag })) as unknown as number[] || [];
        if (!toSave.isActive) {
            toSave.isActive = false;
        }
        if (toSave.selectedTags?.length < 1) {
            toSave.selectedTags = [];
        }
        if (toSave.selectedTeams?.length < 1) {
            toSave.selectedTeams = [];
        }
        if (toSave.selectedBusinessEntities?.length < 1) {
            toSave.selectedBusinessEntities = [];
        }
    }

    public $postLoad = (): void => {
        this.isActiveDirectoryUser = true;
    };
}

