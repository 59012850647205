import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IDocumentNotificationTriggerDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseLogicService } from '../base/base-logic.service';
import { DocumentNotificationTriggerMappedItem } from './document-notification-trigger.mapped';
import { IDocumentNotificationTriggerLogicService } from './interfaces/i.document-notification-trigger.logic.service';
import { IDocumentNotificationTriggerMappedItem } from './interfaces/i.document-notification-trigger.mapped';

@Injectable()
export class DocumentNotificationTriggerLogicService
    extends BaseLogicService<IDocumentNotificationTriggerDto, IDocumentNotificationTriggerMappedItem>
    implements IDocumentNotificationTriggerLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('documentnotificationtriggers', DocumentNotificationTriggerMappedItem);
    }
}
