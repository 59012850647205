<div class="cb-margin-2x flex-col"
     *cbWaitFor="mappedItem">
    <div class="flex-row">
        <div class="flex-col md-60 lg-40 sm-80 xl-20 xs-100">
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      *ngIf="displayName"
                                      label="Name"
                                      [value]="mappedItem.name"></cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Build Type"
                                      [value]="buildTypes[mappedItem.buildTypeId]"></cb-display-value-new>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="flex-col md-60 lg-40 sm-80 xl-20 xs-100">
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Land Area">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey">border_style</mat-icon>
                        <span class="mat-body-1">&nbsp;{{mappedItem?.amenities?.landArea}}m&#178;</span>
                    </div>
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Floor Area">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey"
                                  svgIcon="floor-plan"></mat-icon>
                        <span class="mat-body-1">&nbsp;{{mappedItem?.amenities?.floorArea}}m&#178;</span>
                    </div>
                </cb-display-value-new>
            </div>
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Bedroom">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey">hotel</mat-icon>
                        <span class="mat-body-1">&nbsp;{{mappedItem?.amenities?.noBedrooms}}</span>
                    </div>
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Bathroom">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey">wc</mat-icon>
                        <span class="mat-body-1">&nbsp;{{mappedItem?.amenities?.noBathrooms}}</span>
                    </div>
                </cb-display-value-new>
            </div>
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Living">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey">weekend</mat-icon>
                        <span class="mat-body-1">&nbsp;{{mappedItem?.amenities?.noLivingRooms}}</span>
                    </div>
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Garage">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey">directions_car</mat-icon>
                        <span class="mat-body-1">&nbsp;{{mappedItem?.amenities?.noGarages}}</span>
                    </div>
                </cb-display-value-new>
            </div>
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Carpark">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey">directions_car</mat-icon>
                        <span class="mat-body-1">&nbsp;{{mappedItem?.amenities?.noCarparks}}</span>
                    </div>
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Study">
                    <div class="start-center flex-row">
                        <mat-icon class="cb-grey">import_contacts</mat-icon>
                        <span class="mat-body-1">&nbsp;{{mappedItem?.amenities?.noStudy}}</span>
                    </div>
                </cb-display-value-new>
            </div>
        </div>
    </div>
    <div class="checkbox-list flex-col">
        <cb-checkbox label="Resource Consent Required"
                     name="resourceConsentRequird"
                     [(ngModel)]="mappedItem.resourceConsentRequired"
                     [readonly]="true"></cb-checkbox>
    </div>
    <cb-text-area label="Design Requirements"
                  name="designRequirements"
                  [(ngModel)]="mappedItem.designRequirements"
                  [readonly]="true"></cb-text-area>
    <div class="flex-col">
        <cb-display-value-new label="Failed Review History">
            <div class="flex-row flex">
                <div class="flex-row">
                    <mat-divider [vertical]="true"></mat-divider>
                </div>
                <ul class="cb-list flex-col flex">
                    <li *ngFor="let item of mappedItem.failedReviewHistories">
                        <span>{{item.createdDate | date}} - {{item.createdByName}}</span>
                        <p>{{item.notes}}</p>
                    </li>
                </ul>
            </div>
        </cb-display-value-new>
        <cb-display-value-new *ngIf="displayRejectionNotes()"
                              label="Rejection Notes"
                              [value]="mappedItem.rejectionNotes"></cb-display-value-new>
    </div>
    <cb-display-value-new label="Standard Plan"
                          [value]="mappedItem.standardPlanLabel"></cb-display-value-new>
    <cb-display-value-new label="Approver"
                          [value]="mappedItem.approverUserName"></cb-display-value-new>
    <cb-user-date-time-new label="Requested by"
                           [userFullName]="mappedItem.createdByUserName"
                           [date]="mappedItem.createdDate"
                           [floatingLabel]="true"
                           [displayTime]="false"></cb-user-date-time-new>
    <cb-user-date-time-new label="Finalised by"
                           [userFullName]="mappedItem.finalisedByUserName"
                           [date]="mappedItem.finalisedDate"
                           [floatingLabel]="true"
                           [displayTime]="false"></cb-user-date-time-new>
    <cb-user-date-time-new label="Accepted by"
                           [userFullName]="mappedItem.acceptedByUserName"
                           [date]="mappedItem.acceptedDate"
                           [floatingLabel]="true"
                           [displayTime]="false"></cb-user-date-time-new>
    <cb-text-area label="Specification Version"
                  name="lotSpecVersion"
                  [(ngModel)]="mappedItem.lotSpecVersion"
                  [readonly]="true"></cb-text-area>
    <cb-currency class="flex"
                 label="Client Budget"
                 name="clientBudget"
                 [(ngModel)]="mappedItem.clientBudget"
                 [readonly]="true"
                 [inclGst]="true"
                 [formatCredit]="true"></cb-currency>
    <cb-display-value-new *ngIf="rejectionHistories?.value?.length > 0"
                          label="Rejection Histories">
        <ul class="list">
            <li class="flex-col"
                *ngFor="let history of rejectionHistories.value">
                <cb-user-date-time-new label="Rejected by"
                                       [userFullName]="history.createdByName"
                                       [date]="history.createdDate"
                                       [floatingLabel]="false"
                                       [displayTime]="true"></cb-user-date-time-new>
                <div class="cb-margin-left flex-row flex">
                    <mat-divider [vertical]="true"></mat-divider>
                    <span class="cb-margin-left mat-body-1 flex">{{history.notes}}</span>
                </div>
            </li>
        </ul>
    </cb-display-value-new>
</div>
