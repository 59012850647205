import {Component, Input, OnChanges} from '@angular/core';

import {BaseClientSaleCardDirective} from '../base-client-sale-card';
import {CLIENT_SALE_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {ClientSaleDocumentsService} from '../../documents-service/client-sale-documents.service';
import {ClientSalePermissions} from '@app/core/permissions';
import {ComputedProperty} from '@app/shared/utils/computed-property.util';
import {SaleDetailsEditDialogComponent} from '../../dialogs/sale-details-edit-dialog/sale-details-edit-dialog.component';
import {LostOrStalledDialogComponent} from '../../dialogs/lost-or-stalled-dialog/lost-or-stalled-dialog.component';
import {IClientSaleMappedItem} from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Component({
    selector: 'cb-client-sale-details-card',
    templateUrl: './details-card.component.html',
    styleUrls: ['./details-card.component.scss']
})
export class ClientSaleDetailsCardComponent extends BaseClientSaleCardDirective implements OnChanges {

    @Input() public readonly stepNumber: number;

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public readonly hasSaleLostPermission = new ComputedProperty(() => {
        return this.clientSale
            && this.permissions
            && (this.clientSale.statusId === CLIENT_SALE_STATUS_ENUM.StalledSale ? this.permissions.canConfirmSaleLost() : this.permissions.canMarkSaleLost());
    }, false);

    public get isComplete(): boolean {
        return this.clientSale?.stepCompletion?.saleDetails;
    }

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissions: ClientSalePermissions,
        public readonly clientSaleDocuments: ClientSaleDocumentsService,
    ) {
        super(clientSaleDocuments);
    }

    public ngOnChanges(): void {
        super.ngOnChanges();
        this.hasSaleLostPermission.recompute();
    }

    public openEditDialog(): void {
        if (!this.permissions.canEdit()) {
            return;
        }
        this.cbDialog
            .open(SaleDetailsEditDialogComponent, {
                data: { clientSale: this.clientSale.$clone() },
                minWidth: 400
            })
            .afterClosed()
            .subOnce({ next: this.recomputeAll });
    }

    public openSaleLostDialog(): void {
        if (!this.permissions.canMarkSaleLost()) {
            return;
        }
        this.cbDialog
            .open(LostOrStalledDialogComponent, {
                data: {clientSale: this.clientSale.$clone()},
                minWidth: 400
            })
            .afterClosed()
            .subOnce({ next: this.recomputeAll });
    }

    public openRevertPendingLostDialog(): void {
        if (!this.permissions.canMarkSaleLost()) {
            return;
        }
        this.cbDialog.confirm({
            message: 'Are you sure you want to revert this Client Sale to its previous status?',
            confirmed: this.revertPendingLost
        });
    }

    public openRevertUnconditionalDialog(): void {
        if (!this.permissions.canRevertUnconditional()) {
            return;
        }
        this.cbDialog.confirm({
            message: 'Are you sure you want to revert this Client Sale to Conditional?',
            confirmed: this.revertUnconditional
        });
    }

    private readonly revertPendingLost = (): void => {
        this.clientSale.revertPendingLost()
            .subOnce({ next: this.recomputeAll });
    };

    private readonly revertUnconditional = (): void => {
        this.clientSale.revertUnconditional()
            .subOnce({ next: this.recomputeAll });
    };

    private readonly recomputeAll = (): void => {
        this.recomputeIconProps();
        this.hasSaleLostPermission.recompute();
    };
}
