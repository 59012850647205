<div [formGroup]="form" class="flex-row">
    <mat-form-field class="flex"
                    *ngIf="multiple">
        <mat-label>{{ label }}</mat-label>
        <mat-select formControlName="selectElement"
                    multiple
                    [panelClass]="panelClass"
                    [(value)]="value"
                    (selectionChange)="updateSelectElement()"
                    [disabled]="disabled">
            <select-all-option [selectElement]="form.get('selectElement')"
                               (checkAllCheckboxChangedEventEmitter)="checkAllCheckboxElementChanged($event)"
                               [updateSelectElement]="updateSelectElement"
                               [values]="allOptions">
            </select-all-option>
            <mat-option *ngFor="let option of allOptions"
                        [value]="option.id">{{ option.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="flex"
                    *ngIf="!multiple">
        <mat-label>{{ label }}</mat-label>
        <mat-select formControlName="selectElement"
                    [(value)]="value"
                    (selectionChange)="updateSelectElement()"
                    [disabled]="disabled">
            <mat-option *ngIf="hasSelectAll"
                        value=""
                        class="select-single-all-option">All
            </mat-option>
            <mat-option *ngFor="let option of allOptions"
                        [value]="option.id">{{ option.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
