<div class="cb-margin-top cb-margin-bottom start flex-col"
     *ngIf="searchData">
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
        <mat-icon>hotel</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="this.searchData.numberOfBedrooms"
                        [options]="optionsBedRoom"
                        [(ngModel)]="this.searchData.numberOfBedrooms"
                        (valueChange)="this.searchQueryUpdate.next($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
        <mat-icon>wc</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="this.searchData.numberOfBathrooms"
                        [options]="optionsBathRoom"
                        [(ngModel)]="this.searchData.numberOfBathrooms"
                        (valueChange)="this.searchQueryUpdate.next($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
        <mat-icon>weekend</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="this.searchData.numberOfLivingRooms"
                        [options]="optionsLiving"
                        [(ngModel)]="this.searchData.numberOfLivingRooms"
                        (valueChange)="this.searchQueryUpdate.next($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
        <mat-icon>directions_car</mat-icon>
        <mat-form-field>
            <mat-label>Garages</mat-label>
            <mat-select [(ngModel)]="this.searchData.numberOfGarages"
                        (ngModelChange)="this.searchQueryUpdate.next($event)">
                <mat-option *ngFor="let type of garageTypes"
                            [value]="type.id">
                    {{ type.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row"
         *ngIf="this.optionsFloorAreaLoaded && this.floorAreas">
        <mat-icon svgIcon="floor-plan"
                  matTooltip="Floor Area"></mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="this.minFloorArea"
                        [(highValue)]="this.maxFloorArea"
                        [options]="this.optionsFloorArea"
                        (valueChange)="floorAreaChange($event)"
                        (highValueChange)="floorAreaChange($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row"
         *ngIf="this.optionsLandAreaLoaded && this.landAreas">
        <mat-icon matTooltip="Land Area">border_style</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="this.minLandArea"
                        [(highValue)]="maxLandArea"
                        [options]="this.optionsLandArea"
                        (valueChange)="landAreaChange($event)"
                        (highValueChange)="landAreaChange($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row"
         *ngIf="this.optionsCostLoaded && this.costs">
        <mat-icon matTooltip="Price">attach_money</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="minCost"
                        [(highValue)]="maxCost"
                        [options]="this.optionsCost"
                        (valueChange)="priceChange($event)"
                        (highValueChange)="priceChange($event)"></ngx-slider>
        </div>
    </div>
    <footer class="cb-margin-left-2x cb-margin-right-2x flex-row">
        <div class="flex"></div>
        <button mat-raised-button
                (click)="this.clearSearch()">
            <mat-icon>clear_all</mat-icon>
            &nbsp;Clear Filter
        </button>
    </footer>
</div>
