<cb-dialog class="flex-col flex"
           dialogHeading="Add Line to SSR">
    <form #searchForm="ngForm">
        <span class="mat-body-1">Which Line(s) do you want to add to the SSR?</span>
        <div class="start-center flex-row">
            <mat-icon>search</mat-icon>
            <cb-input class="cb-margin-left flex"
                      label="Search"
                      name="search"
                      [(ngModel)]="searchFilter"
                      (keyup)="filteredLineItems.recompute()"></cb-input>
            <button mat-icon-button
                    (click)="searchFilter = ''">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </form>
    <form class="flex-col flex"
          #addLinesForm="ngForm">
        <div class="standardTable"
             style="overflow-y: scroll; max-height: 300px">
            <table>
                <thead class="sticky-header">
                    <tr>
                        <td style="width: 35px"></td>
                        <td>Item</td>
                        <td>UOM</td>
                        <td *ngIf="this.data.lotType === LOT_TYPE_ENUM.Unit">Lot No.</td>
                        <td>Quantity Unallocated</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of filteredLineItems.value;">
                        <td>
                            <mat-checkbox [(ngModel)]="linesToAdd[line.quantityAccountItemId]"
                                          [name]="'checkbox_' + line.quantityAccountItemId"
                                          (change)="updateLinesToAdd(line.quantityAccountItemId)"></mat-checkbox>
                        </td>
                        <td>{{line.description | cbDasher}}</td>
                        <td>
                            {{UNIT_OF_MEASURE_ENUM[line.uom]}}
                        </td>
                        <td *ngIf="this.data.lotType === LOT_TYPE_ENUM.Unit">{{line.lotNumber | cbDasher}}</td>
                        <td>{{line.quantity}}</td>
                    </tr>
                    <tr *ngIf="possibleLineItems?.length < 1">
                        <td [attr.colspan]="this.data.lotType === LOT_TYPE_ENUM.Unit?5:4">
                            <mat-icon class="mat-accent">info_circle</mat-icon>
                            <span class="mat-body-1">There are no available Line Items</span>
                        </td>
                    </tr>
                    <tr *ngIf="possibleLineItems?.length > 0 && filteredLineItems.value?.length < 1">
                        <td [attr.colspan]="this.data.lotType === LOT_TYPE_ENUM.Unit?5:4">
                            <mat-icon class="mat-accent">info_circle</mat-icon>
                            <span class="mat-body-1">There are no line items matching your search</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <span class="mat-body-1"
              style="margin: 16px 0 4px">
            Selected Items
        </span>
        <div *ngIf="selectedLines?.length > 0">
            <mat-chip-list>
                <mat-chip *ngFor="let chip of selectedLines">
                    <span class="mat-body-1">{{chip.description}}</span>
                    <button mat-icon-button
                            (click)="removeChip(chip)">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-list>
        </div>
        <span style="color: grey"
              *ngIf="selectedLines?.length < 1">&nbsp; None</span>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="saveInProgress || addLinesForm.invalid || addLinesForm.pristine || selectedLines?.length < 1"
                (click)="addSelectedLines()">Add Selected Lines</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
