
    <!-- first icon -->
<mat-icon class="cb-grey cb-help-cursor"
          *ngIf="mappedItem.isDescriptiveOnlyCost"
          aria-hidden="false"
          matTooltip="Descriptive Only">
    notes
</mat-icon>
<mat-icon class="mat-accent cb-help-cursor"
          *ngIf="mappedItem.isOwnersCareCost"
          title="Owners care"
          aria-hidden="false"
          matTooltip="Owners Care">
    person
</mat-icon>
<mat-icon class="mat-accent cb-help-cursor"
          *ngIf="mappedItem.isNoChargeCost"
          aria-hidden="false"
          matTooltip="No Charge">
    money_off
</mat-icon>

    <!-- second icon -->
<mat-icon class="mat-primary cb-help-cursor"
          *ngIf="mappedItem.offeringStatus === OFFERING_STATUS_ENUM.Active && mappedItem.productId"
          aria-hidden="false"
          matTooltip="Active">
    check_circle
</mat-icon>
<mat-icon class="mat-warn cb-help-cursor"
          *ngIf="mappedItem.offeringStatus === OFFERING_STATUS_ENUM.TemporarilyUnavailable && mappedItem.productId"
          aria-hidden="false"
          matTooltip="Temporarily Unavailable">
    warning
</mat-icon>
<mat-icon class="cb-error cb-help-cursor"
          *ngIf="mappedItem.offeringStatus === OFFERING_STATUS_ENUM.PermanentlyUnavailable && mappedItem.productId"
          aria-hidden="false"
          matTooltip="Permanently Unavailable">
    error
</mat-icon>

    <!-- third icon -->
<mat-icon class="cb-grey cb-help-cursor"
          *ngIf="mappedItem.bundleId"
          aria-hidden="false"
          matTooltip="Is part of bundle">
    attach_file
</mat-icon>

    <!-- <mat-icon class="cb-help-cursor"
                     *ngIf="mappedItem.product.productType === PRODUCT_TYPE_ENUM.Bundle"
                     aria-hidden="false"
                     matTooltip="Is Bundle">
               folder
           </mat-icon> -->
<mat-icon class="cb-grey cb-help-cursor"
          *ngIf="mappedItem.quoteRequired || mappedItem.costType === COST_TYPE_ENUM.Quote"
          aria-hidden="false"
          matTooltip="Is Quote">
    description
</mat-icon>
<mat-icon class="cb-grey cb-help-cursor"
          *ngIf="mappedItem.showInClientSpecification"
          aria-hidden="false"
          matTooltip="Included in Flyer / Create Schedule">
    style
</mat-icon>
<mat-icon class="cb-grey cb-help-cursor"
          *ngIf="mappedItem.showInColourYourDreams"
          svgIcon="book-open-page-variant"
          matTooltip="Included in Colour Schedule">
</mat-icon>
<mat-icon class="mat-accent cb-help-cursor"
          *ngIf="mappedItem.isPendingCredit || mappedItem.isPendingDelete || mappedItem.isPendingVariation"
          aria-hidden="false"
          matTooltip="Pending">
    timelapse
</mat-icon>
