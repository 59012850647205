import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ExternalSystemFieldsLogicService } from '@app/logic/external-system-fields/external-system-fields.logic-service';
import { ISlotExternalSystemFieldValuesLogicService } from '@app/logic/slot-external-system-field-value/interfaces/i.slot-external-system-field-value.logic-service';
import { ISlotExternalSystemFieldValueMappedItem } from '@app/logic/slot-external-system-field-value/interfaces/i.slot-external-system-field-value.mapped';
import { SlotExternalSystemFieldValuesLogicService } from '@app/logic/slot-external-system-field-value/slot-external-system-field-value.logic-service';
import { SlotsLogicService } from '@app/logic/slots/slots.logic-service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';
import {
    ISlotExternalSystemFieldValueDto,
    EXTERNAL_SYSTEM_FIELD_TYPE_ENUM,
    ISlotDto, EXTERNAL_SYSTEM_ENUM,
    IExternalSystemFieldTemplateItemDto,
    ExternalSystemEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: ISlotExternalSystemFieldValueMappedItem;
    title: string;
    mode: FormMode;
}

@Component({
    selector: 'app-slot-external-system-field-value-dialog',
    templateUrl: './slot-external-system-field-value-dialog.component.html',
    styleUrls: ['./slot-external-system-field-value-dialog.component.scss']
})
export class SlotExternalSystemFieldValueDialogComponent
    extends BaseDialogFormViewDirective<ISlotExternalSystemFieldValueDto, ISlotExternalSystemFieldValueMappedItem, ISlotExternalSystemFieldValuesLogicService> {

    public static readonly MIN_WIDTH = '40%';
    public fieldTypes = EXTERNAL_SYSTEM_FIELD_TYPE_ENUM.toLookup();
    public externalSystems = EXTERNAL_SYSTEM_ENUM.toLookup();

    public externalSystemFieldTemplateItems: IExternalSystemFieldTemplateItemDto[] = [];
    public readonly WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;

    public selectedScheduleItem: ISlotDto;

    public EXTERNAL_SYSTEM_FIELD_TYPE_ENUM = EXTERNAL_SYSTEM_FIELD_TYPE_ENUM;
    public externalFieldValueRegex: RegExp;

    public slotExternaReferenceCombinationAlreadyExists: boolean;

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<SlotExternalSystemFieldValueDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ExternalSystemFieldsLogicService) public readonly externalSystemFieldsLogicService: ExternalSystemFieldsLogicService,
        @Inject(SlotsLogicService) public readonly slotsLogicService: SlotsLogicService,
        @Inject(SlotExternalSystemFieldValuesLogicService) public readonly slotExternalSystemFieldValuesLogicService: SlotExternalSystemFieldValuesLogicService,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.loadInitData();
        this.formMode$.next(data.mode);
    }

    public loadInitData(): void {
        if (this.isEdit$ && this.mappedItem.slotId > 0) {
            this.slotsLogicService.$getItem(this.mappedItem.slotId).subOnce((_slotDto) => {
                this.selectedScheduleItem = _slotDto;
            });

            this.loadExternalSystemFields(this.mappedItem.externalSystemFieldTemplateItem.externalSystemId);
        }
    }

    public loadExternalSystemFields(externalSystemId: ExternalSystemEnumId): void {
        this.externalSystemFieldsLogicService.getActiveListBySystemId(externalSystemId)
            .subOnce(result => {
                this.externalSystemFieldTemplateItems = result;
                if (this.mappedItem?.externalSystemFieldTemplateItem?.id > 0 && this.mappedItem?.externalSystemFieldTemplateItem?.isActive === false) {
                    this.externalSystemFieldTemplateItems.push(this.mappedItem.externalSystemFieldTemplateItem);
                }
            });
    }

    public scheduleItemSelected(slot: ISlotDto): void {
        if (slot) {
            this.mappedItem.slotId = this.selectedScheduleItem.id;
            this.mappedItem.slotName = this.selectedScheduleItem.name;
            this.reEvaluateSlotExternaReferenceCombination();
        }
    }

    public externalFieldNameChanged(): void {
        this.reEvaluateSlotExternaReferenceCombination();
        this.externalFieldValueRegex = new RegExp('^\\d{' + 1 + ',' + this.mappedItem.externalSystemFieldTemplateItem.fieldLength + '}$');
    }

    public reEvaluateSlotExternaReferenceCombination(): void {
        if (this.mappedItem.slotId > 0 && this.mappedItem.externalSystemFieldTemplateItem.id > 0) {
            this.slotExternalSystemFieldValuesLogicService.getSlotExternaReferenceCombinationExists(this.mappedItem.$getMappedDtoItem()).subOnce((result) => {
                if (result) {
                    this.slotExternaReferenceCombinationAlreadyExists = true;
                } else {
                    this.slotExternaReferenceCombinationAlreadyExists = false;
                }
            });
        }
    }
}
