import { Component, Input } from '@angular/core';
import { IDocumentPermissions } from '@app/core/permissions/base.permissions';
import { LotChangePermissions } from '@app/core/permissions/lotchange.permission';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { ILotMappedItem } from '@app/logic/lots';
import { ITeamDto, SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-change-view',
    templateUrl: './lot-change-view.component.html',
    styleUrls: ['./lot-change-view.component.scss']
})
export class LotChangeViewComponent {

    @Input() public teams: ITeamDto[];
    @Input() public lotMappedItem: ILotMappedItem;
    @Input() public changeRecordMappedItem: IChangeRecordMappedItem;

    public questionsCount = 0;
    public documentsCount = 0;

    public systemArea = SYSTEM_AREA_ENUM.ChangeRecord;

    constructor(private changePermissions: LotChangePermissions) { }

    public get documentPermissions(): IDocumentPermissions {
        return this.changePermissions.getDocumentPermissions();
    }

}
