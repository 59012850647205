import {Component, Input} from '@angular/core';
import {CONTACT_METHOD_ENUM, IContactDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-contact-right-side-panel',
    templateUrl: './contact-right-side-panel.component.html',
    styleUrls: ['./contact-right-side-panel.component.scss']
})
export class ContactRightSidePanelComponent {
    @Input() public contact: IContactDto;

    constructor() { }

    public isEmailPreferred(): boolean {
        return this.contact?.preferredContact === CONTACT_METHOD_ENUM.Email;
    }

    public hasPhoneHome(): boolean {
        return this.contact?.phoneHome?.length > 0;
    }

    public isHomePreferred(): boolean {
        return this.contact?.preferredContact === CONTACT_METHOD_ENUM.HomePhone;
    }

    public isWorkPreferred(): boolean {
        return this.contact?.preferredContact === CONTACT_METHOD_ENUM.WorkPhone;
    }

    public hasPhoneWork(): boolean {
        return this.contact?.phoneWork?.length > 0;
    }

    public hasPhoneMobile(): boolean {
        return this.contact?.phoneMobile?.length > 0;
    }

    public isMobilePreferred(): boolean {
        return this.contact?.preferredContact === CONTACT_METHOD_ENUM.MobilePhone;
    }


}
