<mat-card class="cb-margin flex-col">
    <mat-card-content class="flex">
        <div class="flex"
             *ngIf="!isExtendedSummaryEnabled">
            <cb-table class="flex"
                      [columns]="displayedColumns"
                      [loaded]="isLoaded">
                <tr cbTableRow
                    *ngFor="let row of tableRows">
                    <td>
                        {{row.clientAccountName}}
                    </td>
                    <td>
                        {{row.jobNumber}}
                    </td>
                    <td>
                        {{row.lotNumber}}
                    </td>
                    <td>
                        {{row.contractType}}
                    </td>
                    <td>
                        {{row.project}}
                    </td>
                    <td>
                        {{row.clientSaleStatus}}
                    </td>
                    <td>
                        {{row.lotStatus}}
                    </td>
                    <td *ngIf="row.latestTask">
                        {{row.latestTask.name}} - {{row.latestTask.statusName}}
                    </td>
                    <td *ngIf="!row.latestTask"></td>
                    <td *ngIf="row.latestTask">
                        {{row.latestTask.assignedTo}}
                    </td>
                    <td *ngIf="!row.latestTask"></td>
                    <td *ngIf="row.latestTask && !isDateLate(row.latestTask.dueDate)">
                        {{row.latestTask.dueDate | cbDate}}
                    </td>
                    <td *ngIf="row.latestTask && isDateLate(row.latestTask.dueDate)">
                        <cb-info-message message="{{row.latestTask.dueDate | cbDate}}"
                                         icon="warning"
                                         iconClass="cb-warn">
                        </cb-info-message>
                    </td>
                    <td *ngIf="!row.latestTask"></td>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button (clicked)="viewLot(row.lotId)"
                                   color="white"
                                   icon="pageview">
                        </cb-button>
                    </td>
                </tr>
            </cb-table>
        </div>
        <div class="flex"
             *ngIf="isExtendedSummaryEnabled">
            <div>
                <cb-table class="cb-margin-top schedule-items-house-area-table"
                          label="Client Sales"
                          [minWidth]="1000"
                          [hideDefaultTable]="true"
                          [loaded]="isLoaded">
                    <table cbTable
                           class="stripedRow">
                        <thead>
                            <td>Client Account Name</td>
                            <td>Job Number</td>
                            <td>Lot Number</td>
                            <td>Lot Type</td>
                            <td>Project</td>
                            <td>Sale Status</td>
                            <td>Lot Status</td>
                            <td>Lot Last Activity</td>
                            <td>Assigned To </td>
                            <td>Activity Due Date</td>
                            <td></td>
                            <td>Actions</td>
                        </thead>
                        <tbody *ngFor="let row of extendedtableRows">
                            <tr cbTableRow>
                                <td>
                                    {{row.dto.clientAccountName}}
                                </td>
                                <td>
                                    {{row.dto.jobNumber}}
                                </td>
                                <td>
                                    {{row.dto.lotNumber}}
                                </td>
                                <td>
                                    {{row.dto.contractType}}
                                </td>
                                <td>
                                    {{row.dto.project}}
                                </td>
                                <td>
                                    {{row.dto.clientSaleStatus}}
                                </td>
                                <td>
                                    {{row.dto.lotStatus}}
                                </td>
                                <td *ngIf="row.dto.latestTask">
                                    {{row.dto.latestTask.name}} - {{row.dto.latestTask.statusName}}
                                </td>
                                <td *ngIf="!row.dto.latestTask"></td>
                                <td *ngIf="row.dto.latestTask">
                                    {{row.dto.latestTask.assignedTo}}
                                </td>
                                <td *ngIf="!row.dto.latestTask"></td>
                                <td *ngIf="row.dto.latestTask && !isDateLate(row.dto.latestTask.dueDate)">
                                    {{row.dto.latestTask.dueDate | cbDate}}
                                </td>
                                <td *ngIf="row.dto.latestTask && isDateLate(row.dto.latestTask.dueDate)">
                                    <cb-info-message message="{{row.dto.latestTask.dueDate | cbDate}}"
                                                     icon="warning"
                                                     iconClass="cb-warn"
                                                     [matTooltip]="'This activity is overdue, please contact Assigned To Person or Team for more info'">
                                    </cb-info-message>
                                </td>
                                <td *ngIf="!row.dto.latestTask"></td>
                                <td cbTableShrinkCol>
                                    <cb-button (clicked)="viewLot(row.dto.lotId)"
                                               color="white"
                                               icon="pageview">
                                    </cb-button>
                                </td>
                                <td cbTableShrinkCol
                                    cbTableStickyCol="end">
                                    <cb-button (clicked)="toggleExpanded(row)"
                                               color="white"
                                               icon="expand_more">
                                    </cb-button>
                                    <span *ngIf="row.hasActivitiesRequiringAction()">
                                        <cb-info-popup class="alert"
                                                       [tooltip]="'You have outstanding actions'"
                                                       [icon]="'error'">
                                        </cb-info-popup>
                                    </span>
                                </td>
                            </tr>
                            <ng-container *ngIf="row.isExpanded">
                                <tr>
                                    <td colspan="12"
                                        class="childItem">
                                        <mat-card-content class="flex">
                                            <table cbTable
                                                   style="min-width:1000px;
                                                   ">
                                                <thead class="actionHeader">
                                                    <tr>
                                                        <td class="actionheader">Action Name</td>
                                                        <td class="actionheader">Action Type</td>
                                                        <td class="actionheader">Action Assigned To</td>
                                                        <td class="actionheader"></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let action of row.dto.actions | cbSortByPipe: 'assignedToCurrentUser' : 'desc'; let odd = odd"
                                                        [class.oddBackground]="odd">
                                                        <td>
                                                            {{action.name}}
                                                        </td>
                                                        <td>
                                                            {{action.saleActionTypeName}}
                                                        </td>
                                                        <td>
                                                            {{action.assignedTo}}
                                                        </td>
                                                        <td width="50px">
                                                            <span *ngIf="action.assignedToCurrentUser">
                                                                <cb-button class="cb-margin-right"
                                                                           [disabled]="viewItemUrl(action)?.url === '#'"
                                                                           (click)="goToItem(action, $event)"
                                                                           color="white"
                                                                           icon="pageview">

                                                                </cb-button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr></tr>
                                                    <tr *ngFor="let action of row.nextSteps; let odd = odd"
                                                        [class.oddBackground]="odd">
                                                        <td>
                                                            {{action.name}}
                                                        </td>
                                                        <td>
                                                            {{action.saleActionTypeName}}
                                                        </td>
                                                        <td>
                                                            {{action.assignedTo}}
                                                        </td>
                                                        <td width="50px">
                                                            <span *ngIf="action.assignedToCurrentUser">

                                                                <!--put this back in once the urls are attached to these actions-->
                                                                <cb-button class="cb-margin-right"
                                                                           [disabled]="viewItemUrl(action)?.url === '#'"
                                                                           (click)="goToItem(action, $event)"
                                                                           color="white"
                                                                           icon="pageview">

                                                                </cb-button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </mat-card-content>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </cb-table>
            </div>
        </div>
    </mat-card-content>
</mat-card>
