import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DocumentsLogicService } from '@app/logic/documents';
import { IDocumentDto } from '@app/logic/documents/interfaces/i.document.dto';
import { DocumentInfoDialogComponent } from '../document-info-dialog/document-info-dialog';

@Component({
    selector: 'cb-document-history',
    templateUrl: './document-history.component.html',
    styleUrls: ['./document-history.component.scss']
})
export class DocumentHistoryComponent {
    public static readonly MIN_WIDTH = '60%';

    public readonly displayedColumns = [
        'Name',
        'Type',
        'Updated By',
        'Date Updated',
        ''
    ];

    constructor(
        public readonly dialogRef: MatDialogRef<DocumentInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public documentPrecursors: IDocumentDto[],
        public readonly documentsLogicService: DocumentsLogicService,) {
    }

    public downloadDocument(documentEntityMappedItem: IDocumentDto): void {
        this.documentsLogicService.downloadDocument(documentEntityMappedItem.id).subOnce();
    }




}
