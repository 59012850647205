import { Injectable } from '@angular/core';

interface ICols {
    [lotSpecItemId: number]: number;
}

class ColWidthSync {
    public cols = {} as ICols;
    public value = 0;

    public update(lotSpecItemId: number, value = 0): void {
        this.cols[lotSpecItemId] = value;
        this.setValue();
    }

    public delete(lotSpecItemId: number): void {
        delete this.cols[lotSpecItemId];
        this.setValue();
    }

    private setValue(): void {
        this.value = Math.max(...Object.values(this.cols));
    }
}

@Injectable()
/** for LotSpecItemListTableComponent */
export class ColWidthSyncService {
    public readonly code = new ColWidthSync();
    public readonly category = new ColWidthSync();
    public readonly supplier = new ColWidthSync();
    private readonly canvas = document.createElement('canvas');

    public update(lotSpecItemId: number, code: HTMLElement, category: HTMLElement, supplier: HTMLElement): void {
        this.code.update(lotSpecItemId, this.getInnerTextNodeWidth(code));
        this.category.update(lotSpecItemId, this.getInnerTextNodeWidth(category));
        this.supplier.update(lotSpecItemId, this.getInnerTextNodeWidth(supplier));
    }

    public delete(lotSpecItemId: number): void {
        this.code.delete(lotSpecItemId);
        this.category.delete(lotSpecItemId);
        this.supplier.delete(lotSpecItemId);
    }

    private getInnerTextNodeWidth(parent: HTMLElement): number {
        let width = 0;
        parent.childNodes.forEach(x => {
            width += this.getTextWidth(x.textContent.replace(/(\r\n|\n|\r)/gm, ''), window.getComputedStyle(parent).font);
        });
        return width;
    }

    private getTextWidth(text: string, font: string): number {
        const context = this.canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }
}
