import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LotCostPermissions} from '@app/core/permissions';
import {DesignSchemesLogicService, IDesignSchemeMappedItem} from '@app/logic/design-schemes';
import {ILotMappedItem} from '@app/logic/lots';
import {IPricingRevisionMappedItem} from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import {SYSTEM_AREA_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {Subscription} from 'rxjs';
import {PricingRevisionTabsDataService} from './pricing-revision-tabs-data.service';

@Component({
    selector: 'cb-pricing-revision-tabs',
    templateUrl: './pricing-revision-tabs.component.html',
    styleUrls: ['./pricing-revision-tabs.component.scss'],
    providers: [
        PricingRevisionTabsDataService,
    ]
})
export class PricingRevisionTabsComponent {
    @Output() public selectedTabChanged = new EventEmitter<number>();
    @Input() public selectedIndex: number;
    public questionCount = 0;
    public documentCount = 0;
    private sub$ = new Subscription();
    private _mappedItem: IPricingRevisionMappedItem;
    public systemArea = SYSTEM_AREA_ENUM.PricingRevision;

    private _designScheme: IDesignSchemeMappedItem;
    public get designSchemeLotSpecVersion(): number {
        return this._designScheme?.lotSpecVersion;
    }

    @Input() public set mappedItem(item: IPricingRevisionMappedItem) {
        this._mappedItem = item;
        this.sub$.unsubscribe();
        if (this.mappedItem) {
            this.designSchemelogicService.$getMappedItem(this.mappedItem.designSchemeId).subOnce(result => {
                this._designScheme = result;
            });
            this.sub$ = this.mappedItem.$updated.subscribe(() => {
            });
        }
    }
    public get mappedItem(): IPricingRevisionMappedItem {
        return this._mappedItem;
    }

    @Input() public readonly lotMappedItem: ILotMappedItem;

    constructor(
        public lotCostPermissions: LotCostPermissions,
        public readonly pricingRevisionTabsData: PricingRevisionTabsDataService,
        private readonly designSchemelogicService: DesignSchemesLogicService,
    ) {
    }
    public onTabChanged(idx): void {
        this.selectedTabChanged.emit(idx);
    }

    public get canViewEditPricingRevisionMargins(): boolean {
        return this.lotCostPermissions.canViewEditPricingRevisionMargins();
    }

}
