<cb-dialog dialogHeading="Import Errors">
    <div ng-show="$ctrl.takeOffResult.hasErrors">
        <span class="mat-body-2">
            <mat-icon class="mat-warn">warning</mat-icon>
            &nbsp;Please fix the following errors and Retry
        </span>
    </div>
    <div class="standardTable scrollable-container">
        <table>
            <thead>
                <tr>
                    <td>Row</td>
                    <td>Column</td>
                    <td>Message</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let error of takeOffResult.errors">
                    <td>{{error.rowNumber | cbDasher}}</td>
                    <td>{{error.columnNumber | cbDasher}}</td>
                    <td>{{error.message | cbDasher}}</td>
                </tr>
                <tr *ngIf="takeOffResult.errors && takeOffResult.errors.length < 1">
                    <td>
                        <mat-icon class="mat-info">warning</mat-icon>
                        &nbsp;There are no errors.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <footer actions
            class="cb-margin-bottom end-center flex-row">
        <button mat-raised-button
                color="primary"
                (click)="getTransferHistoryErrors()">
            Download error list
        </button>
        <button mat-raised-button
                color="primary"
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
