import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ILotSpecItemDto, IPurchaseOrderDto, IQuoteLineRefDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { ILotSpecItemLogicService } from './interfaces/i.lot-spec-item-logic.service';
import { ILotSpecItemMappedItem } from './interfaces/i.lot-spec-item.mapped';
import { LotSpecItemMappedItem } from './lot-spec-item.mapped';

@Injectable()
export class LotSpecItemLogicService
    extends BaseLogicService<ILotSpecItemDto, ILotSpecItemMappedItem>
    implements ILotSpecItemLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'lotspecs',
            LotSpecItemMappedItem
        );
    }

    public $getItem(entityId: number, lotId: number, versionNumber: number): Observable<ILotSpecItemDto> {
        return this.$http.get<ILotSpecItemDto>(`${this.$baseUri}/${lotId}/items/${entityId}/version/${versionNumber}`)
            .pipe(
                this.$retryRequestWhen()
            );
    }

    public $getMappedItem(entityId: number, lotId: number, versionNumber: number): Observable<ILotSpecItemMappedItem> {
        return super.$getMappedItem(entityId, lotId, versionNumber);
    }

    public $saveItem(entity: ILotSpecItemDto, versionNumber?: number): Observable<ILotSpecItemDto> {
        let uri = `${this.$baseUri}/${entity.lotId}/items`;
        if (entity.id) {
            uri += `/${entity.id}`;
        }
        if (versionNumber) {
            uri += `/version/${versionNumber}`;
        }
        return this.$http.post<ILotSpecItemDto>(uri, entity);
    }

    public $deleteItem<ReturnType>(lotId: number, lotSpecItemId: number, versionNumber?: number): Observable<ReturnType> {
        let url = `${this.$baseUri}/${lotId}/items/${lotSpecItemId}`;
        if (versionNumber) {
            url += `/version/${versionNumber}`;
        }
        return this.$http.delete(url);
    }

    public $getList(lotId: number): Observable<Array<ILotSpecItemDto>> {
        return this.$http.get(`${this.$baseUri}/${lotId}/items`);
    }

    public $getMappedList(lotId: number): Observable<Array<ILotSpecItemMappedItem>> {
        return super.$getMappedList(lotId);
    }

    public uploadItemImageAtVersion(lotSpecItemId: number, lotId: number, versionNumber: number, formFile: FormData): Observable<ILotSpecItemDto> {
        return this.$http.post(`${this.$baseUri}/${lotId}/items/${lotSpecItemId}/image/version/${versionNumber}`, formFile);
    }

    public removeSpecItemImage(lotSpecItemId: number, lotId: number): Observable<boolean> {
        return this.$http.delete(`${this.$baseUri}/${lotId}/items/${lotSpecItemId}/image`);
    }

    public splitLotSpecItemAtVersion(lotSpecItemId: number, lotId: number, version: number): Observable<ILotSpecItemDto> {
        return this.$http.post(`${this.$baseUri}/${lotId}/items/split/${lotSpecItemId}/version/${version}`);
    }

    public getQuoteLinesByLotSpecItem(lotId: number, lotSpecItemId: number): Observable<IQuoteLineRefDto[]> {
        return this.$http.get(`${this.$baseUri}/${lotId}/items/${lotSpecItemId}/quotelines`);
    }

    public getPurchaseOrdersByLotSpecItem(lotId: number, lotSpecItemId: number): Observable<IPurchaseOrderDto[]> {
        return this.$http.get(`${this.$baseUri}/${lotId}/items/${lotSpecItemId}/purchaseorders`);
    }
}
