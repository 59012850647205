<cb-table [loaded]="true"
          [columns]="columns">
    <tbody cbTableBody>
        <ng-container *ngFor="let specItem of items">
            <cb-lot-spec-item-table-row class="tr"
                                        *ngIf="!specItem.isColourItem; else colourItem"
                                        [lotSpec]="lotSpec"
                                        [mappedItem]="specItem.item"
                                        (itemDeleted)="specItemDeleted.emit($event)"
                                        (itemEdited)="specItemEdited.emit($event)"></cb-lot-spec-item-table-row>
            <ng-template #colourItem>
                <cb-lot-spec-colour-item-table-row class="tr"
                                                   [lotSpec]="lotSpec"
                                                   [mappedItem]="specItem.item"
                                                   (itemDeleted)="colourItemDeleted.emit($event)"
                                                   (itemEdited)="colourItemEdited.emit($event)">
                </cb-lot-spec-colour-item-table-row>
            </ng-template>
        </ng-container>
    </tbody>
</cb-table>
