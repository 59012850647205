import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbPrettyBoolPipe } from './pretty-bool.pipe';



@NgModule({
    declarations: [CbPrettyBoolPipe],
    imports: [
        CommonModule
    ],
    exports: [
        CbPrettyBoolPipe
    ]
})
export class CbPrettyBoolModule { }
