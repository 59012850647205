import {BasePermissions, IBaseSystemAreaPermissions, IhaveNotePermissions, IMyClassicPermissions, INotePermissions} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ContactPermissionEnum} from '../../shared/enums/permissions';

export interface IContactPermissions extends IBaseSystemAreaPermissions, IhaveNotePermissions {
    canSearch(): boolean;
    canSeeComponent(): boolean;
    canViewHistory(): boolean;
    canViewAccounts(): boolean;
    canViewLeads(): boolean;
    canEdit(): boolean;
    getNotePermissions(): INotePermissions;
}

@Injectable()
export class ContactPermissions
    extends BasePermissions<ContactPermissionEnum>
    implements IContactPermissions,
    IMyClassicPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('contact', ContactPermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(ContactPermissionEnum.CanView);
    }

    public canCreate(): boolean {
        return this.permissionObject(ContactPermissionEnum.CanEdit);
    }

    public canEdit(): boolean {
        return this.permissionObject(ContactPermissionEnum.CanEdit);
    }

    public canSearch(): boolean {
        return this.permissionObject(ContactPermissionEnum.CanSearch);
    }

    public canSeeComponent(): boolean {
        return this.permissionObject(ContactPermissionEnum.CanSearch);
    }

    public canViewNote(): boolean {
        return this.getNotePermissions().canViewNotes();
    }

    public canViewHistory(): boolean {
        return this.permissionObject(ContactPermissionEnum.HistoryCanView);
    }

    public canViewAccounts(): boolean {
        return this.permissionObject(ContactPermissionEnum.AccountsCanView);
    }

    public canViewLeads(): boolean {
        return this.permissionObject(ContactPermissionEnum.LeadsCanView);
    }

    public myClassicCanView(): boolean {
        return this.permissionObject(ContactPermissionEnum.MyClassicCanView);
    }

    public myClassicCanCreateAccount(): boolean {
        return this.permissionObject(ContactPermissionEnum.MyClassicCanCreateAccount);
    }

    public myClassicCanChangeUsername(): boolean {
        return this.permissionObject(ContactPermissionEnum.MyClassicCanChangeUsername);
    }

    public myClassicResetPassword(): boolean {
        return this.permissionObject(ContactPermissionEnum.MyClassicCanResetPassword);
    }

    public myClassicCanChangeAccess(): boolean {
        return this.permissionObject(ContactPermissionEnum.MyClassicCanChangeAccess);
    }

    public getNotePermissions(): INotePermissions {
        return {
            canCreateNote: () => this.permissionObject(ContactPermissionEnum.NoteCanAdd),
            canDeleteNote: () => this.permissionObject(ContactPermissionEnum.NoteCanDelete),
            canEditNote: () => this.permissionObject(ContactPermissionEnum.NoteCanEdit),
            canViewNotes: () => this.permissionObject(ContactPermissionEnum.NoteCanView),
        };
    }
}
