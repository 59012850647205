import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QSTeamPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotPriceRevisionSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { IPricingRevisionMappedItem } from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import { PricingRevisionsLogicService } from '@app/logic/pricing-revisions/pricing-revisions.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ManagePricingRevisionDialogComponent } from '../manage-pricing-revision-dialog/manage-pricing-revision-dialog.component';

@Component({
    selector: 'cb-pricing-revisions-search',
    templateUrl: './pricing-revisions-search.component.html',
    styleUrls: ['./pricing-revisions-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingRevisionsSearchComponent implements OnDestroy, AfterViewInit {

    private _subscriptions = new Subscription();
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public teamCode: string;
    public loaded = false;
    public cacheLoaded$ = new BehaviorSubject(false);
    public searchEnabled$ = new BehaviorSubject(false);
    public get searchEnabled(): boolean {
        return this.searchEnabled$.value;
    }
    public results: IPricingRevisionMappedItem[] = [];
    @Input() private readonly lotId: number;
    @Output() public readonly pricingRevisionSelected = new EventEmitter<IPricingRevisionMappedItem>();
    @Output() public readonly previousPricingRevisionSet = new EventEmitter<IPricingRevisionMappedItem>();

    public get searchFiltersCache(): UserCacheItem<ILotPriceRevisionSearch> {
        return this.userCacheService.lotPriceRevisionSearch;
    }

    constructor(
        private readonly logicService: PricingRevisionsLogicService,
        public cdRef: ChangeDetectorRef,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
        private readonly dialogService: CbDialogService,
        private readonly qsTeamPermissions: QSTeamPermissions,
        private readonly navigationService: NavigationService
    ) {

    }

    public ngAfterViewInit(): void {
        if (this.lotId) {
            this._initSearchFiltersCache();
        }
        this.loadQueryParams();
    }

    private loadQueryParams(): void {
        const queryParams = this.navigationService.getQueryParams<{ paramEntityId?: string }>();
        if (queryParams?.paramEntityId) {
            this.loadPricingRevision(Number(queryParams.paramEntityId));
        }
    }

    private loadPricingRevision(pricingRevisionId: number): void {
        this.logicService
            .$getMappedItem(pricingRevisionId)
            .subOnce((pricingRevisionMappedItem) => {
                this.viewPricingRevision(pricingRevisionMappedItem);
            });
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public createRevision(): any {
        return this.dialogService
            .open(
                ManagePricingRevisionDialogComponent,
                {
                    data: {
                        mappedItem: this.logicService.$createMappedItem(),
                        mode: FormMode.Add
                    },
                }
            ).afterClosed();
    }

    public fetchResults(): Observable<IPricingRevisionMappedItem[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.getMappedLotPricingRevisionSearchList(this._queryParams);
    }

    public hasPermissionsToEditRevision = (): boolean => {
        if (this.qsTeamPermissions) {
            return this.qsTeamPermissions.canEditRevision();
        }

        return false;
    };

    public hasPermissionsToFinaliseRevision = (): boolean => {
        if (this.qsTeamPermissions) {
            const can = this.qsTeamPermissions.canFinaliseRevision();
            return can;
        }

        return false;
    };

    public viewLot(lotId: number): void {
        if (lotId) {
            this.navigationService.navigate([`/lots/${lotId}/summary`]);
        }
    }

    private get _queryParams(): ILotPriceRevisionSearch {
        return this.searchEnabled ?
            {
                ...this.searchFiltersCache.copyData(),
                lotId: this.lotId,
                currentPage: this.currentPage,
            } : undefined;
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    public _initSearchFiltersCache(): void {
        this.searchFiltersCache.init().then(() => {
            this.cacheLoaded$.next(true);
            this.searchEnabled$.next(true);
            this._onSearchFiltersChanged();
            this._subscriptions.add(
                this.searchFiltersCache.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );
        });
    }

    public viewPricingRevision(event: IPricingRevisionMappedItem): void {
        this.pricingRevisionSelected.emit(event);
        const revisionNumber: number = +event.revisionNumber;
        if (this.results.length > 1 && revisionNumber > 1) {
            const previousRevision = this.results.find(r => +r.revisionNumber === revisionNumber - 1);
            this.previousPricingRevisionSet.emit(previousRevision);
            this.navigationService.clearQueryParams();
        }
    }
}
