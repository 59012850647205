import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ClassCtor1 } from '@app/shared/types/classctor.type';
import { IBaseLogicService } from '@app/logic/base/interfaces/i.base-logic.service';
import { IBaseMappedItem } from '@app/logic/base/interfaces/i.base-mapped-item';
import { Observable } from 'rxjs';
import { PermissionsPermissions } from '@app/core/permissions';
import { takeOne } from 'cb-hub-lib';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import { Directive } from '@angular/core';
import { SettingsPermissionsController } from '@app/views/settings/settings.permissions.class';

@Directive()
export class BaseSimpleListViewDirective<DtoType extends { id: any }, LogicServiceType extends IBaseLogicService<any, any>> extends SettingsPermissionsController {
    public searchResults: Array<DtoType> = [];
    public searchResultsLoaded = false;
    public readonly displayedColumns: Array<string>;
    public otherData: Object;

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly logicService: LogicServiceType,
        protected dialogComponent: any,
        public dialogHeading: string,
        protected mappedItemCtor: ClassCtor1,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected getSearchResultsInConstructor = true,
        public minWidth = '95%',
    ) {
        super(permissionsPermissions);
        if (this.logicService && this.getSearchResultsInConstructor) {
            this.getSearchResults().pipe(takeOne()).subscribe(x => {
                this.handleSearchResults(x);
            });
        }
    }

    public getSearchResults(): Observable<any> {
        return this.logicService.$getList();
    }

    public sortSearchResults(searchResults: Array<DtoType>): Array<DtoType> { return searchResults; }

    protected handleSearchResults(searchResults: Array<DtoType>): void {
        this.searchResults = this.sortSearchResults(searchResults);
        this.searchResultsLoaded = true;
    }

    public openDialog(mappedItem: IBaseMappedItem<DtoType, any, IBaseLogicService<any, any>>, dialogHeading: string): MatDialogRef<any> {
        return this.cbDialog
            .open(
                this.dialogComponent,
                {
                    minWidth: this.minWidth,
                    data: {
                        dialogHeading,
                        mappedItem,
                        ...(this.otherData && { otherData: this.otherData }),
                    },
                }
            );
    }

    public newItemClicked(): void {
        const mappedItem = this.logicService.$createMappedItem(this.mappedItemCtor);
        this.openDialog(mappedItem, `Add ${this.dialogHeading}`)
            .afterClosed()
            .subOnce(this.handleNewItem.bind(this));
    }

    public editItemClicked = (mappedItemData: IBaseMappedItem<any, any, any>, loadMappedItemFromDb = false): void => {
        let mappedItem = mappedItemData instanceof BaseMappedItem
            // use existing mapped item
            ? mappedItemData.$clone()
            // create mapped item from data if not already a mapped item
            : (this.logicService ? this.logicService.$createMappedItem(mappedItemData, this.mappedItemCtor) : mappedItemData);

        if (loadMappedItemFromDb) {
            this.logicService.$getMappedItem(mappedItem.$id).subOnce(
                (result) => {
                    mappedItem = result;
                });
        }

        this.openDialog(mappedItem, `Edit ${this.dialogHeading}`)
            .afterClosed()
            .subOnce(this.handleEditItem.bind(this));
    };

    public deleteItemClicked = (item: any, extraParams: { heading: string; message: string }): void => {
        this.cbDialog.confirm({
            dialogHeading: `Delete ${extraParams.heading}`,
            message: extraParams.message,
            confirmed: () => {
                if (item) {
                    this.logicService.$deleteItem(item.id).subOnce(_ => {
                        this.handleDeleteItem(item.id);
                    });
                }
            }
        });
    };

    public handleEditItem = (item: any): void => {
        const index = item?.id && this.searchResults.findIndex(x => x.id === item.id);
        if (index >= 0) {
            this.searchResults[index] = item;
            this.searchResults = this.sortSearchResults([...this.searchResults]);
        }
    };

    protected handleNewItem(item: any): void {
        if (item) {
            this.searchResults = this.sortSearchResults([...this.searchResults, item]);
        }
    }

    protected handleDeleteItem = (id: number): void => {
        if (id) {
            this.searchResults = this.searchResults.filter(x => x.id !== id);
        }
    };
}


