import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {ContactsSearchService} from '@app/core/services/search/contacts-search.service';
import {BaseInputFindDirective} from '../../base-input-find.directive';
import {ContactsInputFindDialogDirective} from '../../dialog/extensions/contacts-input-find-dialogs/contacts-input-find-dialog.directive';
import {ComponentType} from '@angular/cdk/portal';
import {Directive} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {ContactsLogicService} from '@app/logic/contacts';
import {IContactDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Directive()
export class ContactsInputFindDirective extends BaseInputFindDirective {
    protected _myDialog: ComponentType<ContactsInputFindDialogDirective>;
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly contactLogicService: ContactsLogicService,
        public readonly navigationService: NavigationService,
        public readonly searchService: ContactsSearchService) {
        super();
    }

    public openSearchDialog = (): void => {
        this.dialogRef.open(
            this._myDialog,
            {
                minWidth: '95%',
                data: {
                    extraSearchParams: this.searchParams,
                    placeholder: 'Type First Name, Last Name, or Email',
                    dialogHeading: 'Contacts',
                    contentHeading: 'Contact Search',
                    searchService: this.searchService
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .subOnce({
                next: (contact: IContactDto) => {
                    if (contact) {
                        this.text = `${contact.firstName} ${contact.lastName}`;
                        this.contactLogicService.$getMappedItem(contact.id)
                            .subOnce(contactMapped => this.value = contactMapped);
                    }
                }
            });
    };

    public viewItem = (): void => {
        this.navigationService.navigate([`contacts/${this.id}/summary`]);
    };
}
