<div class="flex-col"
     *ngIf="!readonly">
    <mat-form-field class="flex"
                    [ngClass]="{'cb-soft-required': softRequired && !required }">
        <textarea id="cb-textarea-field"
                  matInput
                  [name]="internalName"
                  type="text"
                  [(ngModel)]="value"
                  [placeholder]="label"
                  [required]="required"
                  [maxlength]="maxlength || 1000"
                  [rows]="rows || 5"
                  [disabled]="disabled">
        </textarea>
    </mat-form-field>
    <div class="end-start flex-row">
        <span>{{getCharCount()}}</span>
    </div>
</div>
<cb-display-value-new *ngIf="readonly"
                      [label]="label">
    <div class="flex-row"
         *ngIf="value">
        <mat-divider class="cb-margin-right"
                     [vertical]="true"></mat-divider>
        <div style="white-space: pre-line"
             class="force-wrap flex-col flex">
            {{value}}
        </div>
    </div>
    <span class="flex"
          *ngIf="!value">-</span>
</cb-display-value-new>
