<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="pinnedPanels[silentDataKey]"
                     #insuranceDetailsExpansionPanel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">account_balance_wallet</mat-icon>
            <b>Insurance Details</b>
        </mat-panel-title>
        <mat-panel-description class="end-center flex-row">
            <button *ngIf="insuranceDetailsExpansionPanel.expanded || silentData[silentDataKey]"
                    mat-icon-button
                    (click)="togglePanelPinned($event, silentDataKey)">
                <mat-icon svgIcon="pin"
                          [ngClass]="{'cb-accent' : silentData[silentDataKey]}">
                </mat-icon>
            </button>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex-col">
        <div class="flex-wrap flex">
            <cb-display-value-new class="flex-50"
                                  *ngIf="insurancePriceField.isVisible"
                                  label="Insured Price">
                {{lotMappedItem?.insurancePrice | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="insuranceStartDateField.isVisible"
                                  label="Insurance Start Date">
                {{lotMappedItem?.insurance.start | date: 'dd MMM yyyy' | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="insuranceEndDateField.isVisible"
                                  label="Insurance End Date">
                {{lotMappedItem?.insurance.end | date: 'dd MMM yyyy' | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="insuranceInterestedPartyField.isVisible"
                                  label="Interested Party">
                {{lotMappedItem?.insuranceInterestedParty | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="insuranceInterestedPartyEmailField.isVisible"
                                  label="Interested Party Email">
                {{lotMappedItem?.insuranceInterestedPartyEmail | cbDasher}}
            </cb-display-value-new>
            <cb-display-value-new class="flex-50"
                                  *ngIf="insuranceCoverNumberField.isVisible"
                                  label="Cover Number">
                {{lotMappedItem?.insuranceCoverNumber | cbDasher}}
            </cb-display-value-new>
        </div>
    </div>
</mat-expansion-panel>
