import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { DocumentGroupLogicService, DocumentsLogicService, IDocumentGroupDto } from '@app/logic/documents';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import {
    DOCUMENT_GROUP_CODES_CONST,
    DOCUMENT_TYPE_CODES_CONST,
    IDocumentTypeDto,
    IDocumentUploadDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

interface IDocumentTypeFileDto extends IDocumentTypeDto {
    accepts: string;
    fileInputId: string;
    fileTypesLabel: string;
    groupId: number;
}

@Component({
    templateUrl: './request-pricing-dialog.component.html',
    styleUrls: ['./request-pricing-dialog.component.scss']
})
export class DesignSchemeRequestPricingDialogComponent {
    public static readonly MIN_WIDTH = '40%';

    public get mappedItem(): IDesignSchemeMappedItem {
        return this.data.mappedItem;
    }

    public get isPricingLockedToLatestSpecVersion(): boolean {
        return this.featureToggleService.isPricingForcedToUseLatestSpecVersion;
    }

    public get dialogBody(): string {
        const standardSentenceStart = `Scheme ${this.mappedItem.revisionNumber}`;
        return !this.isPricingLockedToLatestSpecVersion
            ? `${standardSentenceStart} is based on Lot Spec Version ${this.mappedItem.lotSpecVersion}`
            : `${standardSentenceStart} will be priced using the latest version of the Lot Spec`;
    }

    public get versionLabel(): string {
        return `Use the latest version of the Lot Spec for this Pricing Request (Version ${this.data.lot.lotSpecVersion})`;
    }

    public get confirmationLabel(): string {
        // These two sentence basically mean the same thing, but depending on the feature toggle state the user either
        // has a choice to use latest version or not (feature toggle off) versus being forced to confirm they are fine
        // using the latest spec version (feature toggle on)
        // eslint-disable-next-line max-len
        return !this.isPricingLockedToLatestSpecVersion
            ? `There are no Lot Spec changes between version ${this.mappedItem.lotSpecVersion} and ${this.data.lot.lotSpecVersion} which would require a new Design Scheme`
            : 'I confirm that the Lot Specification has been updated to match the Scheme Plan and contains all necessary spec changes for Pricing';
    }

    public documentTypes = [] as IDocumentTypeFileDto[];
    public files = {} as { [documentTypeId: number]: File } & Object;
    public documentTypeFileErrors = {} as { [documentTypeId: number]: string[] };
    public useLatestLotSpecVersion = false;
    public finalConfirmation = false;

    public readonly columns = [
        '',
        'Document Type',
        'File Types',
        'Filename',
        '',
    ];

    constructor(
        public readonly dialogRef: MatDialogRef<DesignSchemeRequestPricingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IDesignSchemeMappedItem;
            lot: ILotMappedItem;
        },
        private readonly documentGroupLogicService: DocumentGroupLogicService,
        private readonly documentsLogic: DocumentsLogicService,
        private readonly toastService: ToastService,
        private readonly lotsLogic: LotsLogicService,
        private readonly featureToggleService: FeatureToggleStatesService
    ) {
        this.loadDocumentTypes();
        this.mappedItem.$reload().subOnce();
        this.useLatestLotSpecVersion = this.isPricingLockedToLatestSpecVersion;
    }

    private loadDocumentTypes(): void {
        this.documentGroupLogicService
            .getByCode(DOCUMENT_GROUP_CODES_CONST.LOT_PRICING)
            .subOnce(group => {
                if (!group) {
                    return;
                }
                this.loadDocumentTypeFromGroup(group, DOCUMENT_TYPE_CODES_CONST.LOT_PRICING_SPREADSHEET);
            });
    }

    public getFileName(documentType: IDocumentTypeFileDto): string | undefined {
        return this.files[documentType.id]?.name;
    }

    private readonly loadDocumentTypeFromGroup = (group: IDocumentGroupDto, documentTypeCode: string): void => {
        const found = group.types.find(x => x.documentType.codeName === documentTypeCode);
        if (!found) {
            return;
        }
        const documentType = {
            ...found.documentType,
            accepts: found.documentType.fileTypes.map(fileType => `.${fileType}`).join(', '),
            fileInputId: `file-upload-${found.documentType.id}`,
            fileTypesLabel: found.documentType.fileTypes.join(', '),
            groupId: group.id,
        };
        this.documentTypes.push(documentType as any);
    };

    public versionChanged(): void {
        if (!this.useLatestLotSpecVersion) {
            this.finalConfirmation = false;
        }
    }

    public isSaveDisabled(): boolean {
        return this.useLatestLotSpecVersion && !this.finalConfirmation;
    }

    public submit(): void {
        if (this.useLatestLotSpecVersion && !this.finalConfirmation) {
            return;
        }

        this.mappedItem.requestPricing(this.useLatestLotSpecVersion).subOnce((requestResult) => {
            if (requestResult) {
                this.toastService
                    .showToast(`Pricing has been requested for Scheme ${this.mappedItem.revisionNumber}`);
                this.handleUploadRequestDocs();
            } else {
                this.toastService
                    .showToast(
                        `Pricing was not requested for Scheme ${this.mappedItem.revisionNumber}. An error occurred.`);
            }
            this.dialogRef.close(requestResult);
        });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public handleUploadRequestDocs(): void {
        this.documentsLogic
            .getDocumentsInGroupCode(this.data.lot.documentEntityUri, this.data.lot.documentEntityId, DOCUMENT_GROUP_CODES_CONST.LOT_PRICING)
            .subOnce((existingDocs) => {
                for (const documentTypeId in this.files) {
                    if (this.files.hasOwnProperty(documentTypeId)) {
                        const found = this.documentTypes.find(x => x.id === +documentTypeId);
                        if (!found) {
                            continue;
                        }

                        const docUpload = {
                            documentGroupId: found.groupId,
                            documentTypeId: found.id,
                            file: this.files[documentTypeId],
                            name: this.files[documentTypeId].name,
                        } as IDocumentUploadDto;

                        const existingDoc = existingDocs.find(x => x.documentType?.id === docUpload.documentTypeId);

                        if (existingDoc) {
                            docUpload.documentEntityId = existingDoc.id;
                            this.documentsLogic
                                .reUploadDocumentEntity(docUpload)
                                .subOnce();
                        } else {
                            this.documentsLogic
                                .uploadDocumentEntity(this.lotsLogic.$baseUri, this.data.lot.id, docUpload)
                                .subOnce();
                        }
                    }
                }
            });
    }

    private clearInputFile(fileInput: HTMLInputElement): void {
        if (fileInput) {
            // clear input file
            fileInput.value = '';
        }
    }

    public removeFile(fileInput: HTMLInputElement, documentType: IDocumentTypeFileDto): void {
        this.clearInputFile(fileInput);
        delete this.files[documentType.id];
    }

    @Debounce()
    public fileUploaded(fileInput: HTMLInputElement, documentType: IDocumentTypeFileDto, file: File): void {
        if (!documentType || !file) {
            return;
        }
        if (!this.validateFile(documentType, file)) {
            this.clearInputFile(fileInput);
            return;
        }
        this.files[documentType.id] = file;
    }

    /** @returns true for valid */
    private validateFile(documentType: IDocumentTypeFileDto, file: File): boolean {
        this.documentTypeFileErrors[documentType.id] = [];
        let result = true;

        const maxMB = documentType.maximumFileSizeInMegaBytes;
        const actualFileSizeMB = (file.size / 1024 / 1024); // Bytes to MB
        if (actualFileSizeMB > maxMB) {
            const fileSizeError = `Invalid File Size ${actualFileSizeMB.toFixed(2)}MB is greater than the maximum ${maxMB}MB`;
            this.documentTypeFileErrors[documentType.id].push(fileSizeError);
        }

        const fileExt = file.name.split('.').pop().toLowerCase();
        if (!documentType.fileTypes.some(fileType => fileType.toString().toLowerCase() === fileExt)) {
            const extError = `Invalid File type (${fileExt}) must be one of the following types; ${documentType.fileTypesLabel}`;
            this.documentTypeFileErrors[documentType.id].push(extError);
            result = false;
        }

        return result;
    }
}
