<mat-card class="flex-col flex"
          *cbWaitFor="mappedItem?.id > 0">
    <span class="cb-title">{{mappedItem.name}}</span>
    <cb-display-value-new label="Status"
                          [value]="mappedItem.statusLabel"></cb-display-value-new>
    <cb-display-value-new label="Complexity"
                          [value]="mappedItem.designComplexityLabel"></cb-display-value-new>
    <cb-display-value-new label="Assigned To"
                          [value]="mappedItem.assignedToName"></cb-display-value-new>
    <cb-display-value-new *ngIf="mappedItem.reviewerName && mappedItem.designReviewRequired"
                          label="Reviewer"
                          [value]="mappedItem.reviewerName"></cb-display-value-new>
    <cb-display-value-new label="Due Date"
                          [value]="mappedItem?.dueDate| date"></cb-display-value-new>
    <cb-elapsed-time label="Elapsed Time"
                     [elapsedTime]="elapsedTime"
                     [counting]="mappedItem.statusId== WORKING_DRAWING_STATUS_ENUM.InProgress">
    </cb-elapsed-time>
    <form class="flex-col flex"
          #workingDrawingChangesForm="ngForm">
        <div style="overflow-y: auto">
            <div class="flex-row"
                 *ngFor="let change of mappedItem.changes"
                 style="border-color: rgba(0,0,0,0.12);border-style: solid; border-width: 0 0 1px;">
                <div class="flex-col flex"
                     layout-padding>
                    <div layout-margin
                         class="no-margin no-padding flex-row start-center flex">
                        <div class="flex-row start-start">
                            <div class="flex-row center-center"
                                 style="margin-top:11px;margin-right: 10px;">
                                <cb-working-drawing-change-status-checkbox [changeStatus]="change.statusId"
                                                                           [edit]="isChangeBeingEdited(change.id)"
                                                                           (statusUpdated)="statusUpdated(change, $event)">
                                        </cb-working-drawing-change-status-checkbox>
                                <mat-icon>message</mat-icon>
                                <span layout-margin
                                      class="mat-body-2"> {{change.changeNumber}}: </span>
                                <span class="mat-body-1"> {{change.changeRecordDetails}}</span>
                            </div>
                            <cb-text-area class="flex"
                                          name="{{change.changeNumber}}_changeComments"
                                          [(ngModel)]="change.comments"
                                          [maxlength]="1024"
                                          [readonly]="!isChangeBeingEdited(change.id)">
                                </cb-text-area>
                        </div>
                        <div class="flex-row start-start">
                            <button mat-button
                                    class="mat-accent"
                                    (click)="changeSaveClicked(change)"
                                    *ngIf="isChangeBeingEdited(change.id)">
                                    Save
                                </button>
                            <button mat-button
                                    (click)="changeCancelClicked(change)"
                                    *ngIf="isChangeBeingEdited(change.id)">
                                    Cancel
                                </button>
                        </div>
                        <div class="flex-col"
                             layouy-align="center center">
                            <button mat-button
                                    class="mat-icon-button"
                                    *ngIf="!isChangeBeingEdited(change.id)"
                                    [disabled]="isEditWorkingDrawingChangeDisabled()"
                                    (click)="changeEditClicked(change)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-card>
