export enum ProjectPermissionEnum {
    None                        = 0x00,
    CanSearch                   = 0x01 << 0,
    CanView                     = 0x01 << 1,
    CanCreate                   = 0x01 << 2,
    CanEdit                     = 0x01 << 3,
    ProjectDocumentCanView		= 0x01 << 4,
    ProjectDocumentCanUpload	= 0x01 << 5,
    ProjectDocumentCanDelete	= 0x01 << 6,
    ProjectDocumentCanEdit		= 0x01 << 7,
    ProjectDocumentCanReview	= 0x01 << 8,
    ProjectNoteCanView          = 0x01 << 9,
    ProjectNoteCanAdd           = 0x01 << 10,
    ProjectNoteCanEdit          = 0x01 << 11,
    ProjectNoteCanDelete        = 0x01 << 12,
    ProjectHistoryCanView       = 0x01 << 13,
    ProjectUsersCanView         = 0x01 << 14,
    AccountContactsCanView      = 0x01 << 15,
    ProjectCanCancel            = 0x01 << 16,
    ProjectCanComplete          = 0x01 << 17
}
