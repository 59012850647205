import {Component, Input} from '@angular/core';
import {CurrentUserService} from '@app/core/authentication/current.user';
import {LotDesignPermissions} from '@app/core/permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {UserCacheService} from '@app/core/services/user-cache/user-cache.service';
import {IDesignSchemeMappedItem} from '@app/logic/design-schemes';
import {ITeamsMappedItem} from '@app/logic/teams/interfaces/i.teams.mapped';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {IBuildTypeDto, IStandardPlanDto, LotTypeEnumId} from '@classictechsolutions/hubapi-transpiled-enums';
import {RequestDesignSchemesEditItemDialogComponent} from './request-schemes-edit-item-dialog/request-schemes-edit-item-dialog.component';

@Component({
    selector: 'cb-request-design-scheme-step-two',
    templateUrl: './request-scheme-step-two.component.html',
    styleUrls: ['./request-scheme-step-two.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class RequestDesignSchemesStepTwoComponent {

    @Input() public mappedItem: IDesignSchemeMappedItem;
    @Input() public lotType: LotTypeEnumId;

    public buildTypes: IBuildTypeDto[];
    public standardPlans: IStandardPlanDto[];
    public selectedStandardPlan: IStandardPlanDto;

    public approverUsers: ITeamsMappedItem[];
    constructor(
        public readonly userCacheService: UserCacheService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly currentUser: CurrentUserService,
        public readonly navigationService: NavigationService,
        public readonly cbDialog: CbDialogService
    ) {
    }

    public addRevisionItems(): void {
        let currentItemSize = 1;

        if (this.mappedItem.designRequirementItems) {
            currentItemSize = this.mappedItem.designRequirementItems.length + 1;
        }

        const params = {
            item: { itemId: currentItemSize },
            mappedItem: this.mappedItem,
            mode: FormMode.Add,
            designerView: false,
        };
        this.cbDialog
            .open(RequestDesignSchemesEditItemDialogComponent, {
                data: { mappedItem: this.mappedItem, item: { itemId: currentItemSize }, mode: FormMode.Add },
            });
    }
}
