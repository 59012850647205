<cb-dialog [dialogHeading]="data.heading">
    <form class="flex-col"
          #form="ngForm">
        <cb-text-area *ngIf="data.formInputConfig.type === SIMPLE_FORM_INPUT_TYPE.Textarea"
                      name="textarea"
                      [label]="data.formInputConfig.label"
                      [(ngModel)]="data.value"
                      [maxlength]="data.formInputConfig.maxlength"
                      [required]="data.formInputConfig.required"></cb-text-area>
        <cb-datepicker *ngIf="data.formInputConfig.type === SIMPLE_FORM_INPUT_TYPE.DatePicker"
                       name="datePicker"
                       [label]="data.formInputConfig.label"
                       [(ngModel)]="data.value"
                       [required]="data.formInputConfig.required"
                       pickerType="calendar"></cb-datepicker>
    </form>
    <footer class="end-center flex-row">
        <button color="primary"
                mat-raised-button
                [disabled]="form.invalid || (form.pristine && !data.formInputConfig.required)"
                (click)="save()">{{data.yesLabel}}</button>
        <button mat-raised-button
                (click)="close()">{{data.noLabel}}</button>
    </footer>
</cb-dialog>
