import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { DesignComplexityLogicService, IDesignComplexityDto } from '@app/logic/design-complexity';
import { DesignConceptsLogicService } from '@app/logic/design-concepts';
import { ILotMappedItem } from '@app/logic/lots';
import { NonWorkingDayLogicService } from '@app/logic/non-working-day';
import { IWorkingDrawingMappedItem } from '@app/logic/working-drawings';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { IDesignConceptDto, TEAM_CODES_CONST, USER_TAG_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import moment from 'moment';
import { isNullOrWhiteSpace } from 'projects/cb-hub-lib/src/lib/utils/string.util';

@Component({
    selector: 'cb-working-drawing-scheme-details',
    templateUrl: './working-drawing-scheme-details.component.html',
    styleUrls: ['./working-drawing-scheme-details.component.scss'],
    viewProviders: [
        provideParentForm(),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkingDrawingSchemeDetailsComponent implements OnInit {

    private _mappedItem: IWorkingDrawingMappedItem;
    @Input() public set mappedItem(_mappedItem: IWorkingDrawingMappedItem) {
        this._mappedItem = _mappedItem;
    }

    public get mappedItem(): IWorkingDrawingMappedItem {
        return this._mappedItem;
    }

    private _lotMappedItem: ILotMappedItem;
    @Input() public set lotMappedItem(_lotMappedItem: ILotMappedItem) {
        this._lotMappedItem = _lotMappedItem;
        this.loadDesignConcept();
        this.loadAssignedToUser();
    }

    public get lotMappedItem(): ILotMappedItem {
        return this._lotMappedItem;
    }

    public designComplexities: IDesignComplexityDto[];
    public designConcepts: IDesignConceptDto[] = [];

    public readonly DESIGN_TEAM = TEAM_CODES_CONST.DesignTeamKey;
    public readonly DESIGN_SUPERVISOR = USER_TAG_CONSTANTS_CONST.DESIGNSUPERVISOR;

    public assignedUser: { name: string; id: string };
    public approverUser: { name: string; id: string };
    public reviewerUser: { name: string; id: string };

    constructor(
        public readonly designComplexityLogicService: DesignComplexityLogicService,
        private readonly designConceptsLogicService: DesignConceptsLogicService,
        private readonly nonWorkingDayLogicService: NonWorkingDayLogicService,
        private readonly cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.loadDesignComplexities();

        if (this.mappedItem.reviewerId) {
            this.reviewerUser = {
                id: this.mappedItem.reviewerId,
                name: this.mappedItem.reviewerName
            };
        }

        this.cdRef.detectChanges();
    }

    public get isAmenitiesDisabled(): boolean {
        return this.mappedItem.designConceptId > 0;
    }

    private loadDesignConcept(): void {
        this.designConceptsLogicService.$getItem(this.lotMappedItem.activeDesignConceptId).subOnce((result) => {
            this.designConcepts.push(result);
            this.designConcepts.unshift({ id: undefined, name: 'New' } as any);
            this.cdRef.detectChanges();
        });
    }

    private loadDesignComplexities(): void {
        this.designComplexityLogicService.$getList().subOnce(result => {
            this.designComplexities = result;
        });
    }

    private loadAssignedToUser(): void {
        this.mappedItem.assignedToId = this.lotMappedItem.contacts?.designer?.id;
        if (!isNullOrWhiteSpace(this.lotMappedItem.contacts?.designer?.id)) {
            this.assignedUser = {
                id: this.lotMappedItem.contacts?.designer?.id,
                name: this.lotMappedItem.contacts?.designer?.label
            };
        }


    }

    public assignedChanged(): void {
        if (this.assignedUser) {
            this.mappedItem.assignedToId = this.assignedUser.id;
        }
    }

    public reviewerChanged(): void {
        if (this.reviewerUser) {
            this.mappedItem.reviewerId = this.reviewerUser.id;
        }
    }

    public designConceptChanged(): void {
        if (this.mappedItem.designConceptId) {
            const selectedItem = this.designConcepts.find(designConcept => designConcept.id === this.mappedItem.designConceptId);
            const sourceEntity = selectedItem.designScheme ? selectedItem.designScheme : selectedItem.workingDrawing;
            this.mappedItem.amenities = sourceEntity.amenities;
        } else {
            this.mappedItem.amenities = {} as any;
        }
    }

    public updatedDesignComplexity(): void {
        if (this.mappedItem.designComplexityId) {
            const selectedDesignComplexity = this.designComplexities.find(designComplexity => designComplexity.id === this.mappedItem.designComplexityId);
            this.mappedItem.estimatedHours = selectedDesignComplexity.targetWorkHours;
            const startDate = this.mappedItem.createdDate != null ? this.mappedItem.createdDate : moment().format();
            this.nonWorkingDayLogicService.getDueDate(startDate, selectedDesignComplexity.targetCompletionHours).subOnce((result) => {
                this.mappedItem.dueDate = result;
            });
        }

    }
}
