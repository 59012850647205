import {Injectable, NgZone} from '@angular/core';
import {Subject} from 'rxjs';

const HTTP_PREFIX = 'http';

@Injectable()
export class CurrentHttpRequestsService {
    /** Are there any current pending http requests? */
    public anyCurrentRequests = false;
    private _currentRequestsComplete$ = new Subject();
    private readonly _currentRequests = new Set<string>();

    constructor(
        private readonly zone: NgZone,
    ) { }

    /** Increment the current requests by one */
    public addRequest(url: string): void {
        if (!url.startsWith(HTTP_PREFIX)) {
            return;
        }
        this._currentRequests.add(url);
        this.setCurrentRequests();
    }

    /** Decrement the current requests by one */
    public removeRequest(url: string): void {
        this._currentRequests.delete(url);
        this.setCurrentRequests();
    }

    public currentRequestsComplete(): Promise<void> {
        return new Promise((resolver) => {
            if (!this.anyCurrentRequests) {
                return resolver();
            }
            this._currentRequestsComplete$.subOnce(() => resolver());
        });
    }

    private setCurrentRequests(): void {
        // setTimeout and zone run prevents change detector errors
        setTimeout(() => {
            this.zone.run(() => {
                this.anyCurrentRequests = this._currentRequests.size > 0;
                if (!this.anyCurrentRequests) {
                    this._currentRequestsComplete$.next(null);
                }
            });
        });
    }
}
