import { AfterViewInit, Component, Input, NgZone } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { ILotPermissions, ProjectPermissions } from '@app/core/permissions';
import { IDocumentPermissions, INotePermissions, ITaskPermissions } from '@app/core/permissions/base.permissions';
import { ProjectReleaseStagePermissions } from '@app/core/permissions/project-release-stage.permissions';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { switchMap, tap } from 'rxjs';
import { RELEASE_ROUTE } from './manage-release-routes';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

enum TabIndexes {
    Details = 0,
    Lots = 1,
    DefaultInstallers = 2,
    Tasks = 3,
    Notes = 4,
    Documents = 5,
    History = 6,
    DefaultSuppliers = 7,
}

enum LotTabIndexes {
    summary = 0,
    designSummary = 1,
    FinancialsSummary = 2,
}

@Component({
    selector: 'cb-manage-project-release-stage',
    templateUrl: './manage-release.component.html',
    styleUrls: ['./manage-release.component.scss']
})
export class ManageProjectReleaseStageComponent implements AfterViewInit {
    @Input() public mappedItem: IReleaseStageMappedItem;

    public systemArea = SYSTEM_AREA_ENUM.ProjectReleaseStage;
    public readonly userCacheArea = USER_CACHE_AREA.SidebarProjectReleaseView;
    public selectedTabIndex = 0;
    public RELEASE_ROUTE = RELEASE_ROUTE;
    public lotsSelectedTabIndex: number;
    public isSupplierTypesEnabled: boolean;

    constructor(
        public readonly projectPermissions: ProjectPermissions,
        public readonly projectReleaseStagePermissions: ProjectReleaseStagePermissions,
        private readonly projectReleasesLogicService: ReleaseStagesLogicService,
        private route: ActivatedRoute,
        private readonly ngZone: NgZone,
        protected readonly featureToggleService: FeatureToggleStatesService
    ) {
        featureToggleService.init().then(() => {
            this.isSupplierTypesEnabled = featureToggleService.isSupplierTypesEnabled;
        });
    }

    public ngAfterViewInit(): void {
        this.route.params.pipe(
            tap(this.setMappedItem),
            switchMap(_ => this.route.url))
            .subOnce(this.setTabIndex);
    }

    private setTabIndex = (urlSegments: UrlSegment[]): void => {
        this.ngZone.run(() => {
            setTimeout(() => {
                switch (urlSegments[urlSegments.length - 1]?.path) {
                    case 'details':
                        this.selectedTabIndex = TabIndexes.Details;
                        break;
                    case 'lots':
                        this.selectedTabIndex = TabIndexes.Lots;
                        this.lotsSelectedTabIndex = LotTabIndexes.summary;
                        break;
                    case 'summary':
                        this.selectedTabIndex = TabIndexes.Lots;
                        this.lotsSelectedTabIndex = LotTabIndexes.summary;
                        break;
                    case 'design-summary':
                        this.selectedTabIndex = TabIndexes.Lots;
                        this.lotsSelectedTabIndex = LotTabIndexes.designSummary;
                        break;
                    case 'cost-summary':
                        this.selectedTabIndex = TabIndexes.Lots;
                        this.lotsSelectedTabIndex = LotTabIndexes.FinancialsSummary;
                        break;
                    case 'default-installers':
                        this.selectedTabIndex = TabIndexes.DefaultInstallers;
                        break;
                    case 'default-suppliers':
                        this.selectedTabIndex = TabIndexes.DefaultSuppliers;
                        break;
                    case 'tasks':
                        this.selectedTabIndex = TabIndexes.Tasks;
                        break;
                    case 'notes':
                        this.selectedTabIndex = TabIndexes.Notes;
                        break;
                    case 'documents':
                        this.selectedTabIndex = TabIndexes.Documents;
                        break;
                    case 'history':
                        this.selectedTabIndex = TabIndexes.History;
                        break;
                    default:
                        this.selectedTabIndex = TabIndexes.Details;
                }
            });
        });
    };

    private setMappedItem = (urlParam: { releaseId: number }): void => {
        this.projectReleasesLogicService.$getMappedItem(urlParam.releaseId).subOnce(result => this.mappedItem = result);
    };

    public get canViewReleaseDefaultInstallers(): boolean {
        return this.projectReleaseStagePermissions?.canViewDefaultInstallers();
    }

    public get canViewReleaseDefaultSuppliers(): boolean {
        return this.projectReleaseStagePermissions?.canViewDefaultSuppliers();
    }

    public get canViewReleaseTasks(): boolean {
        return this.taskPermissions?.canViewTask();
    }

    public get canViewReleaseDocuments(): boolean {
        return this.documentPermissions?.canViewDocuments();
    }

    public get canViewReleaseHistory(): boolean {
        return this.projectReleaseStagePermissions?.canViewHistory();
    }

    public get canViewReleaseNotes(): boolean {
        return this.notePermissions?.canViewNotes();
    }

    public get notePermissions(): INotePermissions {
        return this.projectReleaseStagePermissions?.getNotePermissions();
    }

    public get documentPermissions(): IDocumentPermissions {
        return this.projectReleaseStagePermissions?.getDocumentPermissions();
    }

    public get lotPermissions(): ILotPermissions {
        return this.projectReleaseStagePermissions?.getLotPermissions();
    }

    public get taskPermissions(): ITaskPermissions {
        return this.projectReleaseStagePermissions?.getTaskPermissions();
    }
}
