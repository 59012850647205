import { IScheduleTemplateListItemDto, ISkinnyScheduleTemplateDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IScheduleTemplateMappedItem } from './interfaces/i.schedule-template.mapped';
import { IScheduleTemplatesLogicService } from './interfaces/i.schedule-templates.logic-service';

export class ScheduleTemplateMappedItem
    extends BaseMappedItem<ISkinnyScheduleTemplateDto, IScheduleTemplateMappedItem, IScheduleTemplatesLogicService>
    implements IScheduleTemplateMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public scheduleTemplateItemDtos: IScheduleTemplateListItemDto[];
    @DtoProp public regionIds: number[];
    @DtoProp public isScheduleSpecTemplate: boolean;
    @DtoProp public isNational: boolean;


    constructor(
        sourceData: ISkinnyScheduleTemplateDto,
        logicService: IScheduleTemplatesLogicService
    ) {
        super(sourceData, logicService, ScheduleTemplateMappedItem);
    }



    public $save(): Observable<any & ISkinnyScheduleTemplateDto[]> {
        const toSave = this.$getMappedDtoItem();
        this.$preSave(toSave);
        return (this.$logicService
            .$saveItem(toSave) as Observable<ISkinnyScheduleTemplateDto[]>)
            .pipe(
                map((response) => {
                    const data = response.find(x => x.id === this.id);
                    if (data) {
                        this.$updateThisAndOriginal(data);
                    }
                    return response;
                })
            );
    }


}
