import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILotConsentDto } from '@app/logic/lot-consents/interfaces/i.lot-consent.dto';
import { BUILDING_CONSENT_STATUS_ENUM, RESOURCE_CONSENT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { LotConsentViewService } from '../service/lot-consent-view.service';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';

@Component({
    selector: 'cb-lot-consent-list-card',
    templateUrl: './lot-consent-list-card.component.html',
    styleUrls: ['./lot-consent-list-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotConsentListCardComponent {

    private _consent: ILotConsentDto;
    @Input() public set consent(v: ILotConsentDto) {
        this._consent = v;
        this.buildingConsentIcon.recompute();
        this.resourceConsentIcon.recompute();
        this.isBuildingConsent.recompute();
    }
    public get consent(): ILotConsentDto {
        return this._consent;
    }

    public readonly isBuildingConsent = new ComputedProperty(() => {
        return this.consent?.consentTypeLabel?.toLowerCase().includes('building consent') ?? false;
    });

    public readonly buildingConsentIcon = new ComputedProperty(() => {
        return {
            icon: this.getStatusIconIcon(),
            class: this.getStatusIconClass(),
            title: this.getStatusIconTitle(),
        };
    });

    public readonly resourceConsentIcon = new ComputedProperty(() => {
        return {
            icon: this.getResourceStatusIconIcon(),
            class: this.getResourceStatusIconClass(),
            title: this.getResourceStatusIconTitle(),
        };
    });

    constructor(
        public lotConsentViewService: LotConsentViewService,
    ) { }

    public manageLotConsent(): void {
        this.lotConsentViewService.loadConsent(this.consent);
    }

    private getStatusIconIcon(): string {
        if (this.consent?.isDeleted) {
            return 'delete_forever';
        }

        switch (parseInt(this.consent?.consentStatus as any, 10)) {
            case BUILDING_CONSENT_STATUS_ENUM.Approved:
                return 'check_circle';
            case BUILDING_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation:
            case BUILDING_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed:
            case BUILDING_CONSENT_STATUS_ENUM.Pending:
            case BUILDING_CONSENT_STATUS_ENUM.InProgress:
                return 'timelapse';
            case BUILDING_CONSENT_STATUS_ENUM.OnHold:
                return 'pause_circle_filled';
            case BUILDING_CONSENT_STATUS_ENUM.Declined:
                return 'error';
            default:
                return 'timelapse';
        }
    }

    private getStatusIconClass(): string {
        if (this.consent?.isDeleted) {
            return 'mat-warn';
        }

        switch (parseInt(this.consent?.consentStatus as any, 10)) {
            case BUILDING_CONSENT_STATUS_ENUM.Approved:
                return 'mat-primary';
            case BUILDING_CONSENT_STATUS_ENUM.OnHold:
            case BUILDING_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation:
            case BUILDING_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed:
            case BUILDING_CONSENT_STATUS_ENUM.Pending:
            case BUILDING_CONSENT_STATUS_ENUM.InProgress:
                return 'mat-accent';
            case BUILDING_CONSENT_STATUS_ENUM.Declined:
                return 'mat-warn';
            default:
                return 'mat-accent';
        }
    }
    private getStatusIconTitle(): string {
        if (this.consent?.isDeleted) {
            return 'Deleted';
        }

        switch (parseInt(this.consent?.consentStatus as any, 10)) {
            case BUILDING_CONSENT_STATUS_ENUM.Approved:
                return 'Approved';
            case BUILDING_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation:
            case BUILDING_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed:
                return 'Submitted';
            case BUILDING_CONSENT_STATUS_ENUM.OnHold:
                return 'On Hold';
            case BUILDING_CONSENT_STATUS_ENUM.Pending:
                return 'Pending';
            case BUILDING_CONSENT_STATUS_ENUM.InProgress:
                return 'In Progress';
            case BUILDING_CONSENT_STATUS_ENUM.Declined:
                return 'Consent Denied';
            default:
                return 'none';
        }
    }

    private getResourceStatusIconIcon(): string {
        if (this.consent?.isDeleted) {
            return 'delete_forever';
        }

        switch (parseInt(this.consent?.consentStatus as any, 10)) {
            case RESOURCE_CONSENT_STATUS_ENUM.Approved:
                return 'check_circle';
            case RESOURCE_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation:
            case RESOURCE_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed:
            case RESOURCE_CONSENT_STATUS_ENUM.Pending:
            case RESOURCE_CONSENT_STATUS_ENUM.InProgress:
                return 'timelapse';
            case RESOURCE_CONSENT_STATUS_ENUM.OnHold:
                return 'pause_circle_filled';
            case RESOURCE_CONSENT_STATUS_ENUM.Declined:
                return 'error';
            default:
                return 'timelapse';
        }
    }
    private getResourceStatusIconClass(): string {
        if (this.consent?.isDeleted) {
            return 'mat-warn';
        }

        switch (parseInt(this.consent?.consentStatus as any, 10)) {
            case RESOURCE_CONSENT_STATUS_ENUM.Approved:
                return 'mat-primary';
            case RESOURCE_CONSENT_STATUS_ENUM.OnHold:
            case RESOURCE_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation:
            case RESOURCE_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed:
            case RESOURCE_CONSENT_STATUS_ENUM.Pending:
            case RESOURCE_CONSENT_STATUS_ENUM.InProgress:
                return 'mat-accent';
            case RESOURCE_CONSENT_STATUS_ENUM.Declined:
                return 'mat-warn';
            default:
                return 'mat-accent';
        }
    }
    private getResourceStatusIconTitle(): string {
        if (this.consent?.isDeleted) {
            return 'Deleted';
        }

        switch (parseInt(this.consent?.consentStatus as any, 10)) {
            case RESOURCE_CONSENT_STATUS_ENUM.Approved:
                return 'Approved';
            case RESOURCE_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation:
            case RESOURCE_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed:
                return 'Submitted';
            case RESOURCE_CONSENT_STATUS_ENUM.OnHold:
                return 'On Hold';
            case RESOURCE_CONSENT_STATUS_ENUM.Pending:
                return 'Pending';
            case RESOURCE_CONSENT_STATUS_ENUM.InProgress:
                return 'In Progress';
            case RESOURCE_CONSENT_STATUS_ENUM.Declined:
                return 'Consent Denied';
            default:
                return 'none';
        }
    }
}
