<mat-card class="flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon">
            <mat-icon class="cb-error help-cursor"
                      *ngIf="lotQuoteSearchItem?.quoteStatus == QUOTE_STATUS_ENUM.Abandoned"
                      title="Abandoned"
                      aria-hidden="false">delete_forever</mat-icon>
            <mat-icon class="cb-error help-cursor"
                      *ngIf="lotQuoteSearchItem?.quoteStatus == QUOTE_STATUS_ENUM.Required && !lotQuoteSearchItem?.requiredOverdue"
                      title="Required"
                      aria-hidden="false">panorama_fish_eye</mat-icon>
            <mat-icon class="cb-error help-cursor"
                      *ngIf="lotQuoteSearchItem?.quoteStatus == QUOTE_STATUS_ENUM.Required && lotQuoteSearchItem?.requiredOverdue"
                      title="Required Overdue"
                      aria-hidden="false">error_outline</mat-icon>
            <mat-icon class="mat-accent help-cursor"
                      *ngIf="lotQuoteSearchItem?.quoteStatus == QUOTE_STATUS_ENUM.Pending"
                      title="Pending"
                      aria-hidden="false">timelapse</mat-icon>
            <mat-icon class="mat-accent help-cursor"
                      *ngIf="lotQuoteSearchItem?.quoteStatus == QUOTE_STATUS_ENUM.Submitted"
                      title="Submitted"
                      aria-hidden="false">assignment</mat-icon>
            <mat-icon class="mat-primary help-cursor"
                      *ngIf="lotQuoteSearchItem?.quoteStatus == QUOTE_STATUS_ENUM.Accepted"
                      title="Accepted"
                      aria-hidden="false">assignment_turned_in</mat-icon>
        </div>
        <mat-card-title>{{lotQuoteSearchItem?.supplierTradingName || lotQuoteSearchItem?.freeTextSupplierName}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-col">
        <cb-display-value-new *ngIf="lotQuoteSearchItem?.quoteStatus !== QUOTE_STATUS_ENUM.Required"
                              label="Date"
                              [value]="lotQuoteSearchItem?.quoteDate | date: 'dd MMM yyyy' | cbDasher">
        </cb-display-value-new>
        <div class="flex-row">
            <cb-display-value-new class="flex"
                                  *ngIf="lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Required"
                                  label="Required By"
                                  [value]="lotQuoteSearchItem?.requiredDate| date: 'dd MMM yyyy' | cbDasher">
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  *ngIf="lotQuoteSearchItem?.quoteStatus !== QUOTE_STATUS_ENUM.Required"
                                  label="Quote No">#{{lotQuoteSearchItem?.quoteNumber}}</cb-display-value-new>
            <cb-display-value-new class="flex"
                                  *ngIf="lotQuoteSearchItem?.quoteStatus !== QUOTE_STATUS_ENUM.Required"
                                  label="Amount (GST Exclusive)">{{lotQuoteSearchItem?.quoteTotal | cbCurrency}}</cb-display-value-new>
        </div>
        <cb-display-value-new label="Description"
                              [value]="lotQuoteSearchItem?.description"></cb-display-value-new>
        <cb-display-value-new class="flex"
                              *ngIf="lotQuoteSearchItem?.expiryDate != undefined"
                              label="Expiry Date">
            <div class="start-center">
                <mat-icon *ngIf="hasExpired() &&
                    lotQuoteSearchItem?.quoteStatus !== QUOTE_STATUS_ENUM.Submitted &&
                    lotQuoteSearchItem?.quoteStatus !== QUOTE_STATUS_ENUM.Accepted &&
                    lotQuoteSearchItem?.quoteStatus !== QUOTE_STATUS_ENUM.Abandoned"
                          class="cb-error help-cursor"
                          title="Expired"
                          aria-hidden="false">date_range</mat-icon>
                <span class="mat-body-1">&nbsp;{{lotQuoteSearchItem?.expiryDate| date: 'dd MMM yyyy' | cbDasher}}
                </span>
            </div>
        </cb-display-value-new>
        <cb-info-message message="Waiting for Changes to process"
                         icon="warning"
                         iconClass="mat-warn"
                         *ngIf="this.lotQuoteSearchItem.isWaitingForChangesToProcess">
        </cb-info-message>
    </mat-card-content>
    <mat-card-actions class="flex-col">
        <mat-divider></mat-divider>
        <div class="cb-margin-top flex-row flex layoutGap-5">
            <button *ngIf="canDownload()"
                    mat-flat-button
                    (click)="downloadQuote()">
                <mat-icon>file_download</mat-icon>
                Download
            </button>
            <span class="flex"></span>
            <button *ngIf="lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Accepted"
                    mat-stroked-button
                    class="no-wrap"
                    (click)="viewQuoteVO()"
                    color="accent"> View VO</button>
            <button *ngIf="canAbandonQuote()"
                    mat-flat-button
                    (click)="abandonQuote()">Abandon</button>
            <button *ngIf="lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Pending && canManageQuote()"
                    mat-stroked-button
                    color="accent"
                    (click)="manageQuote()">Manage</button>
            <button *ngIf="lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Pending && canEditQuote()"
                    mat-stroked-button
                    (click)="editQuote()"
                    color="accent">Edit</button>
            <button *ngIf="lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Required && canCreateQuote()"
                    mat-stroked-button
                    (click)="receiveQuote()"
                    color="accent">Receive</button>
            <button *ngIf="lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Accepted ||
                            lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Submitted ||
                            lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Abandoned"
                    mat-stroked-button
                    color="accent"
                    (click)="manageQuote()">View</button>
            <button *ngIf="lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Accepted"
                    (click)="duplicateQuote()"
                    mat-flat-button>Duplicate</button>
        </div>
    </mat-card-actions>
</mat-card>
