import { DOCUMENT_GROUP_CODES_CONST, DOCUMENT_TYPE_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { IDocumentUploadDto } from '@app/logic/documents/interfaces/i.document.dto';
import { IDocumentEntityMappedItem } from './../../../../../logic/documents/interfaces/i.document.mapped';
import { Component, OnInit, Inject } from '@angular/core';
import { DocumentGroupLogicService, DocumentsLogicService, IDocumentEntityDto } from '@app/logic/documents';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BuildingConsentLogicService } from '@app/logic/lot-consents';

@Component({
    selector: 'cb-request-consent-dialog',
    templateUrl: './request-consent-dialog.component.html',
    styleUrls: ['./request-consent-dialog.component.scss']
})
export class RequestConsentDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '33%';

    public document: IDocumentEntityMappedItem;

    constructor(
        public readonly dialogRef: MatDialogRef<RequestConsentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { lotId: number },
        @Inject(BuildingConsentLogicService) public readonly buildingConsentLogic: BuildingConsentLogicService,
        @Inject(DocumentGroupLogicService) public readonly documentGroupLogic: DocumentGroupLogicService,
        @Inject(DocumentsLogicService) public readonly documentsLogic: DocumentsLogicService,
    ) {
    }

    public ngOnInit(): void {
        this.loadDocumentEntityStub();
    }

    private loadDocumentEntityStub(): void {
        this.documentGroupLogic.getByCode(DOCUMENT_GROUP_CODES_CONST.OPERATIONS).subOnce((group) => {
            const type = group.types.find(groupType => groupType.documentType.codeName === DOCUMENT_TYPE_CODES_CONST.SIGNED_WORKING_DRAWING);
            this.document = this.documentsLogic.$createMappedItem({
                documentGroup: group,
                documentType: type.documentType,
                document: {},
            } as IDocumentEntityDto);
        });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public submit(): void {
        let data: IDocumentUploadDto | null;
        if (this.document?.document?.uploadedFile) {
            data = {
                name: this.document.document.name,
                file: this.document.document.uploadedFile,
                documentGroupId: this.document.documentGroup?.id,
                documentTypeId: this.document.documentType?.id,
            } as IDocumentUploadDto;
        }
        this.buildingConsentLogic
            .requestPrimaryBuildingConsent(this.data.lotId, data)
            .subOnce((result) => this.dialogRef.close(result));
    }

}
