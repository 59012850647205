import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbPhoneNumberPipe } from './phone-number.pipe';



@NgModule({
    declarations: [CbPhoneNumberPipe],
    imports: [
        CommonModule
    ],
    exports: [CbPhoneNumberPipe]
})
export class CbPhoneNumberModule { }
