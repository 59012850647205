/* eslint-disable space-before-function-paren */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ILotSpecSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ILotSpecScheduleItemMappedItem, LotSpecScheduleItemLogicService } from '@app/logic/lot-spec-schedule-item';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { SpecGroupsLogicService } from '@app/logic/spec-groups/spec-groups.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ILotSpecScheduleListItemDto, ISpecGroupDto } from '@classictechsolutions/hubapi-transpiled-enums';
import _ from 'lodash';
import { isNullOrWhiteSpace } from 'projects/cb-hub-lib/src/lib/utils/string.util';
import { Observable, Subscription } from 'rxjs';
import { ILotSpecItemExpandedPanels } from '../lot-spec-items-list/lot-spec-items-list.component';
import { LotSpecScheduleItemsRemoveHouseAreaDialogComponent } from '../lot-spec-schedule-items-remove-house-area-dialog/lot-spec-schedule-items-remove-house-area-dialog.component';
import { LotSpecificationService } from '../services/lot-specification.service';

type IAllHouseAreaIdsWithItems = { [houseAreaId: number]: ILotSpecScheduleItemMappedItem[] };
export const HOUSE_AREA_PIN_PREFIX = 'houseArea';

@Component({
    selector: 'cb-lot-spec-schedule-items-list',
    templateUrl: './lot-spec-schedule-items-list.component.html',
    styleUrls: ['./lot-spec-schedule-items-list.component.scss']
})
export class LotSpecScheduleItemsListComponent implements OnInit, OnDestroy {

    public readonly HOUSE_AREA_PIN_PREFIX = HOUSE_AREA_PIN_PREFIX;

    private _lotSpec: ILotSpecMappedItem;
    @Input() public set lotSpec(lotSpec: ILotSpecMappedItem) {
        this._lotSpec = lotSpec;
        this.initialiseData();
    }

    public get lotSpec(): ILotSpecMappedItem {
        return this._lotSpec;
    }

    @Input() public readonly houseAreaItems: ISpecGroupDto[];
    @Output() public readonly totalHouseAreaItemsChanged = new EventEmitter<number>();
    @Input() public isSkinnyView: boolean;
    @Input() public fullscreen: boolean;

    public isIncompleteItemsOnly: boolean;
    @Input() public isIncompleteItemsOnlySubject: Observable<boolean>;

    public readonly readyPanels = {} as ILotSpecItemExpandedPanels;
    @Input() public readonly expandedPanels: ILotSpecItemExpandedPanels;
    @Output() public readonly expandedPanelsChange = new EventEmitter<ILotSpecItemExpandedPanels>();

    public get userCacheItem(): UserCacheItem<ILotSpecSearch> {
        return this.userCacheService.lotSpecSearch;
    }

    public allHouseAreaIdsWithItems: IAllHouseAreaIdsWithItems;
    private listOfSpecScheduleItemsAll: ILotSpecScheduleItemMappedItem[];
    private listOfSpecScheduleItemsFiltered;

    public get lotId(): number {
        return this._lotSpec.lotId;
    }

    public availableHouseAreas: ISpecGroupDto[];

    private subscriptions$ = new Subscription();

    constructor(
        public readonly specGroupsService: SpecGroupsLogicService,
        private readonly userCacheService: UserCacheService,
        private readonly lotSpecificationService: LotSpecificationService,
        private readonly logicService: LotSpecScheduleItemLogicService,
        private readonly cbDialog: CbDialogService
    ) { }

    public ngOnInit(): void {
        this.userCacheItem.init().then(() => {
            this.subscriptions$.add(
                this.userCacheItem.updated$.subscribe(() => {
                    this.filterAndSortItems();
                }),
            );

            this.subscriptions$.add(
                this.isIncompleteItemsOnlySubject.subscribe(($event) => {
                    this.isIncompleteItemsOnly = $event;
                    this.filterAndSortItems();
                }),
            );

            this.subscriptions$.add(
                this.lotSpecificationService.lotScheduleItemAdded.subscribe((scheduleTemplateItem) => {
                    const scheduleTemplateItemMappedItem = this.logicService.$createMappedItem(scheduleTemplateItem);

                    const itemIndex = this.listOfSpecScheduleItemsAll?.findIndex(item => item.id === scheduleTemplateItemMappedItem.id);

                    if (itemIndex > -1) {
                        // Replace the item if added to an existing parent
                        this.listOfSpecScheduleItemsAll?.splice(itemIndex, 1, scheduleTemplateItemMappedItem);
                    } else {
                        this.listOfSpecScheduleItemsAll?.push(scheduleTemplateItemMappedItem);
                    }
                    this.filterAndSortItems();
                })
            );

            this.subscriptions$.add(
                this.lotSpecificationService.lotScheduleParentItemRemoved.subscribe((scheduleTemplateItemId) => {

                    const itemIndex = this.listOfSpecScheduleItemsAll?.findIndex(item => item.id === scheduleTemplateItemId);

                    if (itemIndex > -1) {
                        // Replace the item if added to an existing parent
                        this.listOfSpecScheduleItemsAll?.splice(itemIndex, 1);
                    }
                    this.filterAndSortItems();
                })
            );
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    public clearFilters(): void {
        this.userCacheItem.data.keyword = null;
        this.userCacheItem.data.selectedCostTypes = [];
        this.userCacheItem.data.selectedHouseAreas = [];
    }

    public initialiseData(): void {

        this.listOfSpecScheduleItemsAll = this._lotSpec.lotSpecScheduleItems
            .map(specItem => this.logicService.$createMappedItem(specItem));

        this.availableHouseAreas = [];

        this.filterAndSortItems();

        this.expandedChange();

    }

    public expandedChange(): void {
        this.expandedPanelsChange.emit(this.listOfSpecScheduleItemsFiltered);
    }

    public getHouseAreaDisplay(houseAreasId: number): string {
        const specGroup = this.houseAreaItems.find(x => x.id === houseAreasId);
        if (specGroup && this.allHouseAreaIdsWithItems && this.allHouseAreaIdsWithItems[specGroup.id]?.length > 0) {
            return `${specGroup?.label} ( ${this.allHouseAreaIdsWithItems[specGroup.id].length} )`;
        } else {
            return `${specGroup?.label} ( 0 )`;
        }
    }

    public openRemoveHouseAreaDialog(houseArea: ISpecGroupDto): void {
        this.cbDialog
            .open(LotSpecScheduleItemsRemoveHouseAreaDialogComponent, {
                data: {
                    lotId: this.lotId,
                    houseAreaItems: [...this.allHouseAreaIdsWithItems[houseArea.id]],
                    houseAreaName: houseArea.label
                }
            })
            .afterClosed()
            .subscribe({
                next: (results: number[]) => {
                    if (!results || results.length < 1) {
                        return;
                    }

                    // Remove each item that was removed
                    results.forEach(r => {
                        this.lotSpecificationService.lotScheduleParentItemRemoved.next(+r);
                    });
                },
            });
    }

    private filterAndSortItems(): void {

        if (this.listOfSpecScheduleItemsAll) {

            // Filter parent items
            this.listOfSpecScheduleItemsFiltered = this.listOfSpecScheduleItemsAll.filter((parentItem) => {

                const foundInParent = this.filterItem(parentItem);

                // Keep parent if a child meets filter criteria
                let foundInChild = false;
                if (!foundInParent) {
                    foundInChild = parentItem.childItems.some(childItem => {
                        return this.filterItem(childItem);
                    });
                }

                return foundInParent || foundInChild;
            });

            // Filter child items for each parent item
            this.listOfSpecScheduleItemsFiltered.forEach(parentItem => {

                parentItem.childItems.filter(childItem => {
                    return this.filterItem(childItem);
                });
            });
        }

        // Sort parent and child items
        const sortList = this.listOfSpecScheduleItemsFiltered;
        const fullSortGroupedHouseAreaSorted: IAllHouseAreaIdsWithItems = {};

        const sortedHouseAreas = _.orderBy(this.houseAreaItems, ['sortOrder'], ['desc']);
        const houseAreasWithItems = [];
        let totalItems = 0;

        _.forEach(sortedHouseAreas,
            housearea => {
                const items = _.filter(sortList, (item: ILotSpecScheduleItemMappedItem) => {
                    if (this.isIncompleteItemsOnly) {
                        const isIncompleteOrHasIncompleteChild = item.specGroupId === housearea.id &&
                            (item.isIncomplete || item.hasIncompleteChildren);
                        if (isIncompleteOrHasIncompleteChild) {
                            this.expandedPanels[item.specGroupId] = true;
                        }
                        return isIncompleteOrHasIncompleteChild;
                    } else {
                        return item.specGroupId === housearea.id;
                    }
                });

                const parentSort = _.sortBy(items, [function (parentItem) { return parentItem.sortOrder; }]);
                const fullSort = _.forEach(parentSort, function (parentItem) {
                    _.sortBy(parentItem.childItems, [function (childItem) { return childItem.sortOrder; }]);
                });

                if (items.length > 0) {
                    fullSortGroupedHouseAreaSorted[housearea.id] = fullSort;
                    houseAreasWithItems.push(housearea);
                    totalItems += items.length;
                }
            });

        this.totalHouseAreaItemsChanged.emit(totalItems);
        this.availableHouseAreas = houseAreasWithItems;

        this.allHouseAreaIdsWithItems = fullSortGroupedHouseAreaSorted;

    }

    private filterItem(item: ILotSpecScheduleListItemDto): boolean {
        const searchData = this.userCacheItem.copyData();
        const keyword = searchData?.keyword?.toLowerCase() ?? '';

        // Short circut if no filters applied
        const noFiltersApplied = this.hasNoFiltersApplied(keyword, searchData);
        if (noFiltersApplied || !this.listOfSpecScheduleItemsAll) {
            return true;
        }

        const itemName = item.slotName?.toLowerCase() ?? '';
        const houseAreaLabel = this.houseAreaItems.find(x => x.id === item.specGroupId)?.label.toLowerCase();

        const keywordSearchNotRequired = isNullOrWhiteSpace(keyword);
        const productName = this.lotSpecificationService.getProductName(item);
        const description = item.clientFriendlyDescription;
        const hasKeyword = this.scheduleItemContainsKeyWord(keyword, houseAreaLabel, itemName, productName, description);
        const meetsFilterCriteria = this.meetsDropdownFiltersCriteria(searchData, item);

        return (keywordSearchNotRequired || hasKeyword) && meetsFilterCriteria;
    }

    private hasNoFiltersApplied(keyword: string, searchData: ILotSpecSearch): boolean {
        return isNullOrWhiteSpace(keyword)
            && (!searchData.selectedCostTypes || searchData.selectedCostTypes?.length === 0)
            && (!searchData.selectedHouseAreas || searchData.selectedHouseAreas?.length === 0)
            && (!searchData.selectedTags || searchData.selectedTags?.length === 0);
    }

    private scheduleItemContainsKeyWord(keyword: string, houseAreaLabel: string, itemName: string, productName: string, description: string): boolean {
        if (!isNullOrWhiteSpace(keyword)) {
            const trimmedKeyword = keyword.trim().toLowerCase();
            return ((!isNullOrWhiteSpace(itemName) && itemName.toLowerCase().includes(trimmedKeyword)) ||
                (!isNullOrWhiteSpace(houseAreaLabel) && houseAreaLabel.toLowerCase().includes(trimmedKeyword)) ||
                (!isNullOrWhiteSpace(productName) && productName.toLowerCase().includes(trimmedKeyword)) ||
                (!isNullOrWhiteSpace(description) && description.toLowerCase().includes(trimmedKeyword)));
        } else {
            return false;
        }
    }

    private meetsDropdownFiltersCriteria(searchData: ILotSpecSearch, item: ILotSpecScheduleListItemDto): boolean {
        let hasAllCriteria = true;
        if (searchData.selectedCostTypes?.length > 0) {
            hasAllCriteria = searchData.selectedCostTypes.includes(item.costType);
        }

        if (searchData.selectedHouseAreas?.length > 0) {
            hasAllCriteria = hasAllCriteria && searchData.selectedHouseAreas.includes(item.specGroupId);
        }

        if (searchData.selectedTags?.length > 0) {
            hasAllCriteria = hasAllCriteria && _.intersection(searchData.selectedTags, item.tags).length > 0;
        }
        return hasAllCriteria;
    }

}

