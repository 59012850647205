import { BehaviorSubject } from 'rxjs';
import { PrivateProp } from '../decorators/private-prop.decorator';

/**
 * **ComputedProperty** - Can be used to replace functions or getters that compute and return a value. \
 * The value is only recomputed when the recompute() function is called. \
 * \
 * *Intended use case - when a computed property is used by Angular via a template binding (for example)
 *  because Angular will call a computed property hundreds of times per second!*
 */
export class ComputedProperty<ValueType> {

    public value: ValueType;

    constructor(
        @PrivateProp private readonly _computationMethod: () => ValueType,
        @PrivateProp private readonly _defaultValue?: ValueType,
    ) {
        this.value = _defaultValue;
        this.recompute();
    }

    public recompute(): void {
        this.value = this._computationMethod();
    }
}

/**
 * **ComputedPropertySubject** - Combines a ComputedProperty and a RxJS BehaviourSubject.
 * Like ComputedProperty, this can be used to replace functions or getters that compute and return a value. \
 * The value is only recomputed when the recompute() function is called. \
 * \
 * *Intended use case - when a computed property is used by Angular via a template binding (for example)
 *  because Angular will call a computed property hundreds of times per second!*\
 * \
 * ***IMPORTANT*** - *ComputedPropertySubject should be treated like any other observable object, subscriptions need to be managed to prevent memory leaks!*
 */
export class ComputedPropertySubject<ValueType> extends BehaviorSubject<ValueType> {

    constructor(
        @PrivateProp private readonly _computationMethod: () => ValueType,
        @PrivateProp private readonly _defaultValue?: ValueType,
    ) {
        super(_defaultValue);
        this.recompute();
    }

    public recompute(): void {
        this.next(this._computationMethod());
    }
}
