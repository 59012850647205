import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IVariationMappedItem } from '@app/logic/variation/interfaces/i.variation.mapped';
import { UtilIcons } from '@app/shared/utils/util.icons';
import { CostNatureEnumId, COST_NATURE_ENUM, VARIATION_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-lot-variations-list',
    templateUrl: './lot-variations-list.component.html',
    styleUrls: ['./lot-variations-list.component.scss']
})
export class LotVariationsListComponent implements OnInit {

    public collapsed = false;
    public searchQuery = '';

    public filteredVariations: IVariationMappedItem[];
    public currentVariationId: number | null;


    @Input() public variationMappedItems: IVariationMappedItem[];
    @Output() public readonly variationSelected = new EventEmitter<IVariationMappedItem>();

    public COST_NATURE_ENUM = COST_NATURE_ENUM;
    public VARIATION_STATUS_ENUM = VARIATION_STATUS_ENUM;


    constructor(private readonly navigationService: NavigationService) { }

    public ngOnInit(): void {
        this.filteredVariations = this.variationMappedItems;
        this.loadSelectedVariation();
    }

    public loadSelectedVariation(): void {
        const navParams = this.navigationService.getQueryParams<{ variationNumber: string }>();
        const variationNumber = navParams.variationNumber;
        if (variationNumber) {
            const selectedVariation = this.variationMappedItems.find(variation => variation.variationNumber === +variationNumber);
            this.selectVariation(selectedVariation);
        }
    }

    public getCostNatureIcon = (costNature: CostNatureEnumId): string => UtilIcons.getCostNatureIcon(costNature);
    public getCostNatureIconTitle = (costNature: CostNatureEnumId): string => UtilIcons.getCostNatureIconTitle(costNature);

    public getStatusIconIcon(variation: IVariationMappedItem): string {
        switch (variation.variationStatus) {
            case VARIATION_STATUS_ENUM.Pending:
                return 'timelapse';
            case VARIATION_STATUS_ENUM.Accepted:
                return 'check_box';
            case VARIATION_STATUS_ENUM.Declined:
                return 'indeterminate_check_box';
            case VARIATION_STATUS_ENUM.Awaiting:
                return 'check_box';
            case VARIATION_STATUS_ENUM.Deleted:
                return 'delete';
        }
    }

    public getStatusIconClass(variation: IVariationMappedItem): string {
        switch (variation.variationStatus) {
            case VARIATION_STATUS_ENUM.Awaiting:
            case VARIATION_STATUS_ENUM.Pending:
                return 'mat-accent';
            case VARIATION_STATUS_ENUM.Declined:
            case VARIATION_STATUS_ENUM.Declined:
                return 'mat-warn';
            case VARIATION_STATUS_ENUM.Accepted:
                return 'mat-primary ';
            default:
                return 'none';
        }
    }

    public getStatusIconTitle(variation: IVariationMappedItem): string {
        switch (variation.variationStatus) {
            case VARIATION_STATUS_ENUM.Accepted:
                return 'Accepted';
            case VARIATION_STATUS_ENUM.Awaiting:
                return 'Awaiting';
            case VARIATION_STATUS_ENUM.Declined:
                return 'Declined';
            case VARIATION_STATUS_ENUM.Deleted:
                return 'Deleted';
            case VARIATION_STATUS_ENUM.Pending:
                return 'Pending';
            default:
                return 'none';
        }
    }

    public setFilteredVariationss(): void {
        this.filteredVariations = this.variationMappedItems && this.variationMappedItems.filter(x => {
            return [x.variationNumber]
                .filter(y => y != null)
                .join(' ')
                .toLowerCase()
                .trim()
                .includes(
                    this.searchQuery
                        .toLowerCase()
                        .trim()
                );
        });
    }

    public selectVariation(variation: IVariationMappedItem): void {
        this.currentVariationId = variation.id;
        this.variationSelected.emit(variation);
    }

    public showCostChangeAmountGST(variation: IVariationMappedItem): boolean {
        return variation.isExtraToClient;
    }

}
