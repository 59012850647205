<div class="flex-col">
    <mat-card class="cb-margin flex-row flex"
              *ngIf="!(isLoading$ | async)">
        <div class="cb-margin cb-padding flex-col flex-33">
            <h4 class="mat-title">Catalogue Items</h4>
            <p class="mat-body-1 cb-margin-bottom">Generates an Excel workbook that can be Imported</p>
            <div class="flex-row">
                <button mat-raised-button
                        color="primary"
                        (cbClick)="exportCatalogue()">
                    Export All Catalogue Items
                </button>
            </div>
        </div>
        <mat-divider vertical></mat-divider>
        <div class="cb-margin cb-padding flex-col flex-33">
            <form class="flex-col flex"
                  #productCatalogueImportForm="ngForm">
                <h4 class="mat-title">Rates</h4>
                <p class="mat-body-1"> Generates an Excel workbook that can be imported. Leave the Supplier Selection
                    empty
                    to export all National rates</p>
                <div class="flex-col">
                    <cb-business-accounts-input-find-cards label="Supplier"
                                                           [(ngModel)]="ratesSupplier"
                                                           name="ratesSupplier"
                                                           [text]="ratesSupplier?.tradingName"
                                                           [removeable]="true"
                                                           [searchParams]="rateExportBusinessAccountSearchParams">
                    </cb-business-accounts-input-find-cards>
                </div>
                <div class="flex-row">
                    <mat-radio-group name="export-current-rates"
                                     [(ngModel)]="exportCurrentRates">
                        <mat-radio-button [value]="true"
                                          class="cb-margin-right">Current Rates</mat-radio-button>
                        <mat-radio-button [value]="false">Latest Rates</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="flex-row">
                    <button mat-raised-button
                            color="primary"
                            (cbClick)="exportSupplierRates()">Export
                        {{ratesSupplier?.tradingName ? 'Supplier' : 'National'}}
                        Rates</button>
                </div>
            </form>
        </div>
        <mat-divider vertical></mat-divider>
        <div class="cb-margin cb-padding flex-col flex-33">
            <form class="flex-col flex"
                  #exportAllRatesForm="ngForm">
                <h4 class="mat-title">All Rates</h4>
                <p class="mat-body-1">Generates an Excel file that can not be imported.</p>
                <div class="flex-row">
                    <button mat-raised-button
                            color="primary"
                            (cbClick)="exportAllRates()">
                        Export All Rates
                    </button>
                </div>
            </form>
        </div>
    </mat-card>
    <mat-card class="cb-margin flex-row flex"
              *ngIf="!(isLoading$ | async)">
        <div class="cb-margin cb-padding flex-col flex-33">
            <form class="flex-col flex"
                  #exportSupplierItemsForm="ngForm">
                <h4 class="mat-title">Supplier Items</h4>
                <p class="mat-body-1">Generates an Excel workbook that cannot be imported back into the system</p>
                <div class="flex-col">
                    <cb-business-accounts-input-find-cards label="Supplier"
                                                           [(ngModel)]="supplierItemsSupplier"
                                                           name="supplierItemsSupplier"
                                                           [text]="supplierItemsSupplier?.tradingName"
                                                           [removeable]="true"
                                                           [searchParams]="supplierItemsBusinessAccountSearchParams">
                    </cb-business-accounts-input-find-cards>
                </div>
                <div class="flex-row">
                    <button [disabled]="!supplierItemsSupplier"
                            mat-raised-button
                            color="primary"
                            (cbClick)="exportSupplierItems()">
                        Export Supplier Items
                    </button>
                </div>
            </form>
        </div>
        <mat-divider vertical></mat-divider>
        <cb-product-usage-export></cb-product-usage-export>
        <mat-divider vertical></mat-divider>
        <div class="cb-margin cb-padding flex-col flex-25">
            <div class="flex-col flex">
                <h4 class="mat-title">
                    Create Book Items
                </h4>
                <p class="mat-body-1 cb-margin-bottom">Generates and Excel workbook that can be imported into Create
                    Book
                </p>
                <div class="flex-row">
                    <button mat-raised-button
                            color="primary"
                            (cbClick)="exportCreateBook()">
                        Export All Create Book Items
                    </button>
                </div>
            </div>
        </div>
    </mat-card>
</div>
<mat-card class="cb-margin"
          style="display: block !important">
    <cb-costx-export></cb-costx-export>
</mat-card>
<div class="flex-row"
     xlass="cb-margin"
     *ngIf="isLoading$ | async"
     class="center-center">
    <h3 class="mat-title cb-margin-right-3x">Exporting...</h3>
    <mat-spinner></mat-spinner>
</div>
