import { Injectable, Injector } from '@angular/core';
import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { IPreConsentsLogicService } from './interfaces/i.pre-consents.logic.service';
import { IPreConsentDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPreConsentMappedItem } from './interfaces/i.pre-consent.mapped';
import { PreConsentMappedItem } from './pre-consent.mapped';
import { BoolAndStringTupleDto } from '@app/shared/dtos/tuple.dto';

@Injectable()
export class PreConsentsLogicService
    extends BaseLogicService<IPreConsentDto, IPreConsentMappedItem>
    implements IPreConsentsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('preconsents', PreConsentMappedItem);
    }

    public canGeneratePreConsent(lotId: number): Observable<BoolAndStringTupleDto> {
        return this.$http
            .get<BoolAndStringTupleDto>(`lot/${lotId}/cangeneratepreconsent`);
    }
}
