import { BaseMappedItem } from '../base/base-mapped-item';
import { IBankAccountsLogicService } from './interfaces/i.bank-accounts.logic.service';
import { IBankAccountMappedItem } from './interfaces/i.bank-account.mapped';
import { IBankAccountDto } from './interfaces/i.bank-account.dto';
import { DtoProp } from '../base/dto-prop.decorator';
import { IIdAndLabelDto } from '@classictechsolutions/hubapi-transpiled-enums';


export class BankAccountMappedItem
    extends BaseMappedItem<IBankAccountDto, IBankAccountMappedItem, IBankAccountsLogicService>
    implements IBankAccountMappedItem {
    @DtoProp public id: number;
    @DtoProp public readonly label: string;
    @DtoProp public bankName: string;
    @DtoProp public accountName: string;
    @DtoProp public accountNumber: string;
    @DtoProp public isTrustAccount: boolean;
    @DtoProp public isActive: boolean;
    @DtoProp public allowedBusinessEntities: IIdAndLabelDto[];

    /** mapped ids from allowedBusinessEntities - front-end only */
    public allowedbusinessEntityIds: number[];

    constructor(
        sourceData: IBankAccountDto,
        logicService: IBankAccountsLogicService
    ) {
        super(sourceData, logicService, BankAccountMappedItem, {
            allowedBusinessEntities: [],
        });
    }

    protected $preSave(toSave: IBankAccountDto | any): void {
        toSave.allowedBusinessEntities = this.allowedbusinessEntityIds.map(id => ({ id }));
    }

    protected $postLoad(): void {
        this.allowedbusinessEntityIds = this.allowedBusinessEntities.map(x => x.id);
    }
}
