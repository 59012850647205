import { Component } from '@angular/core';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { DocumentGroupLogicService, DocumentGroupMappedItem, IDocumentGroupDto } from '@app/logic/documents';
import { DocumentGroupDialogComponent } from './document-group-dialog/document-group-dialog.component';
import { SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-document-groups',
    templateUrl: './document-groups.component.html',
    styleUrls: ['./document-groups.component.scss'],
})
export class DocumentGroupsComponent extends BaseSimpleListViewDirective<IDocumentGroupDto, DocumentGroupLogicService> {
    public readonly displayedColumns: string[] = ['name', 'defaultSystemArea', 'isActive', 'actions'];

    public SYSTEM_AREA_ENUM = SYSTEM_AREA_ENUM;

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly documentGroupLogicService: DocumentGroupLogicService,
    ) {
        super(
            cbDialog,
            documentGroupLogicService,
            DocumentGroupDialogComponent,
            'Document Group',
            DocumentGroupMappedItem,
            permissionsPermissions
        );
    }

    public sortSearchResults(searchResults: IDocumentGroupDto[]): IDocumentGroupDto[] {
        return searchResults.sort((a, b) => a.name.localeCompare(b.name));
    }

    protected handleNewItem(item: IDocumentGroupDto): void {
        this.getSearchResults().subOnce(result => this.handleSearchResults(result));
    }

    public handleEditItem = (item: IDocumentGroupDto): void => {
        this.getSearchResults().subOnce(result => this.handleSearchResults(result));
    };
}
