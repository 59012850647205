import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IDocumentNotificationTriggerMappedItem } from '@app/logic/document-notification-trigger';
import { DocumentNotificationTriggerMappedItem } from '@app/logic/document-notification-trigger/document-notification-trigger.mapped';
import { IDocumentNotificationTriggerLogicService } from '@app/logic/document-notification-trigger/interfaces/i.document-notification-trigger.logic.service';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { NotificationTemplateLogicService } from '@app/logic/notification-template';
import { TagsLogicService } from '@app/logic/tags';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';
import { DOCUMENT_ACTION_ENUM, IDocumentNotificationTriggerDto, IIntLookupDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: DocumentNotificationTriggerMappedItem;
}

@Component({
    selector: 'cb-document-notifications-dialog',
    templateUrl: './document-notifications-dialog.component.html',
    styleUrls: ['./document-notifications-dialog.component.scss']
})
export class DocumentNotificationsDialogComponent extends
    BaseDialogFormViewDirective<IDocumentNotificationTriggerDto, IDocumentNotificationTriggerMappedItem, IDocumentNotificationTriggerLogicService> {
    public static readonly MIN_WIDTH = '25%';

    public userTags: IIntLookupDto[];
    public teams: IIntLookupDto[];
    public documentActions = DOCUMENT_ACTION_ENUM.toLookup();
    public notificationTemplates: IIntLookupDto[];
    public documentTypes: IIntLookupDto[];
    public WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;

    public mappedItem: DocumentNotificationTriggerMappedItem;
    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<DocumentNotificationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly documentTypesLogicService: DocumentTypesLogicService,
        public readonly notificationTemplateLogicService: NotificationTemplateLogicService,
        public readonly tagsLogicService: TagsLogicService,
        public readonly teamsLogicService: TeamsLogicService
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        documentTypesLogicService.$getList().subOnce(documentTypes => {
            this.documentTypes = documentTypes.map(documentType => {
                return {
                    id: documentType.id,
                    code: documentType.codeName,
                    isActive: documentType.isActive,
                    label: documentType.label
                } as IIntLookupDto;
            });
        });
        notificationTemplateLogicService.$getList().subOnce(notificationTemplates => {
            this.notificationTemplates = notificationTemplates.map(notificationTemplate => {
                return {
                    id: notificationTemplate.id,
                    isActive: notificationTemplate.isActive,
                    label: notificationTemplate.label,
                    code: ''
                } as IIntLookupDto;
            });
        });
        tagsLogicService.$getList().subOnce(tags => {
            this.userTags = tags.map(tag => {
                return {
                    id: tag.id,
                    isActive: tag.isActive,
                    label: tag.name,
                    code: ''
                } as IIntLookupDto;
            });
            this.userTags.unshift({
                id: 0,
                isActive: false,
                label: 'None',
                code: ''
            });
        });
        teamsLogicService.$getList().subOnce(teams => {
            this.teams = teams.map(team => {
                return {
                    id: team.id,
                    isActive: team.isActive,
                    label: team.name,
                    code: ''
                } as IIntLookupDto;
            });
            this.teams.unshift({
                id: 0,
                isActive: false,
                label: 'None',
                code: ''
            });
        });
    }
}
