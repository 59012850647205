import {Inject, Injectable} from '@angular/core';
import {CurrentUserService} from '@app/core/authentication/current.user';
import {FeatureToggleLogicService, IFeatureToggleStateDictionary} from '@app/logic/feature-toggle';
import {IPromised, toPromisedObject} from 'cb-hub-lib';
import {FeatureToggleEnumId} from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export abstract class BaseFeatureToggleStatesService {

    private statesRequested = false;
    private states = {} as IPromised<IFeatureToggleStateDictionary>;

    constructor(
        private readonly currentUser: CurrentUserService,
        @Inject(FeatureToggleLogicService) private readonly featureToggleLogic: FeatureToggleLogicService
    ) { }

    /** Initialise/load the states object and return a promise that resolves once it is complete */
    public init(...promises: Promise<any>[]): Promise<any> {
        return Promise.all([
            ...promises,
            this.currentUser.$promise,
            this.loadAllStates()
        ]);
    }

    protected getState(enumId: FeatureToggleEnumId, defaultVal = false): boolean {
        return this.states[enumId] ?? this.loadStates(enumId, defaultVal);
    }

    private loadStates(enumId: FeatureToggleEnumId, defaultVal: boolean): boolean {
        if (this.statesRequested || this.states.$resolved) {
            return this.getStateOrDefault(enumId, defaultVal);
        }
        this.statesRequested = true;
        this.states = toPromisedObject(this.featureToggleLogic.getStatesDictionary());
        return this.getStateOrDefault(enumId, defaultVal);
    }

    private loadAllStates(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.states.$resolved) {
                return resolve();
            }
            if (!this.statesRequested) {
                this.states = toPromisedObject(this.featureToggleLogic.getStatesDictionary());
            }
            this.statesRequested = true;
            this.states.$promise.then(() => resolve());
        });
    }

    private getStateOrDefault(enumId: FeatureToggleEnumId, defaultVal: boolean): boolean {
        return this.states[enumId] ?? defaultVal;
    }
}
