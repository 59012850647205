/** Search for and find a PropertyDescriptor on an Object and its Prototypes (rescursively)
 *
 *  @param obj - The root object to search, might contain the PropertyDescriptor at any Prototype level
 *  @param propName - Name of the property
 *  @param descriptorCondition - Default: Returns true - Usage: Descriptor will always be defined in this callback;
 *  it should contain the conditions that determine if the descriptor has been found.
 *  @param maxDepth - Default: 10 - the maximum iterations to run before returning void
 */
export const getOwnPropertyDescriptorDeep = (
    obj: Object,
    propName: string,
    optionalConfig?: {
        descriptorCondition?: (descriptor: PropertyDescriptor) => boolean;
        maxDepth?: number;
    }
): PropertyDescriptor | void => {
    // descriptorCondition default or passed in configuration
    const descriptorCondition = optionalConfig && optionalConfig.descriptorCondition != null ? optionalConfig.descriptorCondition : (() => true);
    // maxDepth default or passed in configuration
    const maxDepth = optionalConfig && optionalConfig.maxDepth != null ? optionalConfig.maxDepth : (10);
    if (maxDepth < 1) {
        // max iterations reached - return void
        return;
    }
    const descriptor = Object.getOwnPropertyDescriptor(obj, propName);
    if (descriptor != null && descriptorCondition(descriptor)) {
        return descriptor;
    }
    // descriptor not found - search prototype
    const proto = Object.getPrototypeOf(obj);
    if (proto == null) {
        // run out of prototype depth to search - return void
        return;
    }
    return getOwnPropertyDescriptorDeep(proto, propName, { descriptorCondition, maxDepth: maxDepth - 1 });
};

export const allTrue = (obj): boolean => {
    let bool = true;
    for (const key in obj) {
        if (!obj[key]) {
            bool = false;
            break;
        }
    }
    return bool;
};
