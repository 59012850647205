import { CONTACT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { CbDialogService } from './../../../shared/components/dialog/cb-dialog.service';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ContactsLogicService, IContactSearchDto, IContactSearchParams } from '@app/logic/contacts';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { AccountListDialogComponent } from '../account-list-dialog/account-list-dialog.component';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { LeadListDialogComponent } from '../lead-list-dialog/lead-list-dialog.component';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { PermissionsPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { CreateLeadDialogComponent } from '@app/views/leads/create-lead-dialog/create-lead-dialog.component';
import { ILeadDto } from '@app/logic/leads';

@Component({
    selector: 'cb-contact-search',
    templateUrl: './contact-search.component.html',
    styleUrls: ['./contact-search.component.scss'],
})
export class ContactSearchComponent extends BaseSearchViewDirective<Partial<IContactSearchParams>> implements AfterViewInit, OnDestroy {

    public searchResults: IContactSearchDto[] = [];

    public readonly CONTACT_STATUS_ENUM = CONTACT_STATUS_ENUM;

    constructor(
        public readonly navigationService: NavigationService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly cbDialog: CbDialogService,
        private readonly contactsLogicService: ContactsLogicService,
        public readonly toastService: ToastService,
        public readonly userCacheService: UserCacheService,
    ) {
        super(
            contactsLogicService,
            toastService,
            userCacheService,
            USER_CACHE_AREA.ContactSearch
        );
    }

    public ngAfterViewInit(): void {
        this.loadSearchParams();
    }

    protected getSearchParams(): Partial<IContactSearchParams> {
        const data = this.userCacheItem.copyData();
        return {
            query: data.query,
            excludeUnlinked: true,
            strictSearch: data.strictSearch
        };
    }

    public viewContact(contact: IContactSearchDto, event: MouseEvent): void {
        this.navigationService.navigate([`contacts/${contact.id}/summary`], event);
    }


    public createLead(contact: IContactSearchDto): void {
        this.cbDialog
            .open(CreateLeadDialogComponent, {
                data: {
                    contact
                },
                minWidth: '40%',
            })
            .afterClosed()
            .subOnce((result: false | ILeadDto) => {
                if (result) {
                    this.navigationService.navigate([`/leads/${result.id}/details`]);
                }
            });
    }

    public viewLeads(contact: IContactSearchDto, event: MouseEvent): void {
        this.cbDialog.open(LeadListDialogComponent, {
            data: contact.id,
            minWidth: 400
        });
    }

    public viewAccounts(contact: IContactSearchDto, event: MouseEvent): void {
        this.cbDialog.open(AccountListDialogComponent, {
            data: contact.id,
            minWidth: 400
        });
    }

}
