import { AfterViewInit, Directive, EventEmitter, HostListener, Input, NgZone, OnDestroy, Output } from '@angular/core';

@Directive({
    selector: '[cbAfterInit], [cbOnDestroy], [cbOnResize]'
})
export class PostLoadDirective implements AfterViewInit, OnDestroy {
    @Output() public readonly cbAfterInit = new EventEmitter<void>();
    @Output() public readonly cbOnDestroy = new EventEmitter<void>();
    @Output() public readonly cbOnResize = new EventEmitter<Event>();
    @Input() public delayed = true;

    constructor(
        private readonly ngZone: NgZone,
    ) { }

    public ngAfterViewInit(): void {
        if (!this.delayed) {
            this.cbAfterInit.emit();
            return;
        }
        this.ngZone.run(() => {
            setTimeout(() => {
                this.cbAfterInit.emit();
            });
        });
    }

    public ngOnDestroy(): void {
        if (!this.delayed) {
            this.cbOnDestroy.emit();
            return;
        }
        this.ngZone.run(() => {
            setTimeout(() => {
                this.cbOnDestroy.emit();
            });
        });
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: Event): void {
        if (!this.delayed) {
            this.cbOnResize.emit();
            return;
        }
        this.ngZone.run(() => {
            setTimeout(() => {
                this.cbOnResize.emit(event);
            });
        });
    }
}
