import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateDurationCalculatorComponent } from './date-duration-calculator.component';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CbLoadingSpinnerModule } from 'cb-hub-lib';
import { CbFormsModule } from '../forms/forms.module';
import { CbDisplayValueModule } from '../display-value/display-value.module';



@NgModule({
    declarations: [
        DateDurationCalculatorComponent
    ],
    exports: [
        DateDurationCalculatorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        CbLoadingSpinnerModule,
        MatInputModule,
        CbFormsModule,
        CbDisplayValueModule
    ]
})
export class CbDateDurationCalculatorModule { }
