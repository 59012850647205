import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IBuildProgrammeActivityDto, IBuildProgrammeActivityLogicService, IBuildProgrammeActivityMappedItem } from '@app/logic/build-programme-activity';
import { IBusinessAccountEditBaseDto } from '@app/logic/business-accounts/interfaces/i.business-account.dto';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ILookupDto } from '@app/shared/interfaces/i.lookup.dto';
import { LotBuildProgrammeEventService } from '@app/views/lot/build/services/lot-build-programme-event.service';
import {
    BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM,
    BUSINESS_ACCOUNT_STATUS_ENUM,
    BusinessAccountSearchTypeEnumId,
    BusinessAccountStatusEnumId,
    IBuildProgrammeActivityUpdateSupplierDto,
    ISkinnyBusinessAccountDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { IBuildProgrammeActivityAffectedBySupplierChangeDto, IBuildProgrammeActivitySupplierChangeDetailsDto } from '@app/logic/build-programme/interfaces/buildprogramme.model';
import { BlockingDialogComponent } from '@app/shared/components/dialog/blocking-dialog/blocking-dialog.component';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

interface IData {
    buildProgrammeActivity: IBuildProgrammeActivityMappedItem;
    lotIsPrimaryUnit: boolean;
    selectedBusinessAccount: ISkinnyBusinessAccountDto;
    lotId: number;
}

@Component({
    templateUrl: './set-build-programme-activity-supplier-dialog.component.html',
    styleUrls: ['./set-build-programme-activity-supplier-dialog.component.scss']
})
export class SetBuildProgrammeActivitySupplierDialogComponent
    extends BaseDialogFormViewDirective<IBuildProgrammeActivityDto, IBuildProgrammeActivityMappedItem, IBuildProgrammeActivityLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '33%';
    public blockingRef: MatDialogRef<BlockingDialogComponent>;

    public get mappedItem(): IBuildProgrammeActivityMappedItem {
        return this.data.buildProgrammeActivity;
    }

    public selectedBusinessAccount: IBusinessAccountEditBaseDto;
    public businessAccountFindParams: { [param: string]: any } = {};
    public reassignIncompleteActivities = false;
    public selectedTradeType: any;
    public selectedSupplyType: any;
    public tradeType: any;
    public labelCheckbox: string;
    public tradeTypesInSupplierAndActivity: Array<ILookupDto<number>> = [];
    public supplyTypesInSupplierAndActivity: Array<ILookupDto<number>> = [];
    public noTradeTypeOptions: boolean;
    public noSupplyTypeOptions: boolean;
    public assignSameSupplierToSecondaryLot = false;

    public affectedActivitiesForSupplierChange: IBuildProgrammeActivityAffectedBySupplierChangeDto[];
    public displayedColumns = ['Lot#', 'Stage', 'Code', 'Activity', 'Current Supplier'];
    // Business Account Search Queries
    public statusQuery: BusinessAccountStatusEnumId[];
    public searchTypeQuery: BusinessAccountSearchTypeEnumId;

    public get displayAssignToAllCheckbox(): boolean {
        return this.tradeTypesInSupplierAndActivity.length > 0 || this.supplyTypesInSupplierAndActivity.length > 0;
    }

    public get displayTradeTypesSelect(): boolean {
        return this.tradeTypesInSupplierAndActivity.length > 1;
    }

    public get displaySupplyTypesSelect(): boolean {
        return this.supplyTypesInSupplierAndActivity.length > 1;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<SetBuildProgrammeActivitySupplierDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LotBuildProgrammeEventService) public readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
        public readonly cbDialog: CbDialogService,
        public readonly featureToggleService: FeatureToggleStatesService,
    ) {
        super(dialogRef, toastService);
        this.selectedBusinessAccount = this?.data?.selectedBusinessAccount as any;
    }

    public ngOnInit(): void {
        this.setSearchParams();
        if (this.selectedBusinessAccount) {
            this.businessAccountSet();
        }
    }

    public setSearchParams(): void {
        const statusIds = BUSINESS_ACCOUNT_STATUS_ENUM.toLookup().map((status) => status.id);
        const validStatusIds = statusIds.filter((status: number) => {
            return status === BUSINESS_ACCOUNT_STATUS_ENUM.Active ||
                status === BUSINESS_ACCOUNT_STATUS_ENUM.Overridden;
        });

        this.statusQuery = validStatusIds;
        this.searchTypeQuery = BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM.NotRatesOnly;

        this.businessAccountFindParams = {
            s: validStatusIds,
            t: BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM.NotRatesOnly,
            tt: this.mappedItem.tradeTypes.map(tt => tt.id),
            st: this.mappedItem.supplyTypes.map(st => st.id)
        };
    }

    public businessAccountSet(): void {
        this.setUpTradeTypes();
        if(this.featureToggleService.isSupplierTypesEnabled) {
            this.setUpSupplyTypes();
        }
        this.reassignIncompleteActivitiesCheckboxClicked(this.reassignIncompleteActivities);
    }

    public reassignIncompleteActivitiesCheckboxClicked(isChecked: any): void
    {
        if(isChecked) {
            const dto: IBuildProgrammeActivitySupplierChangeDetailsDto =
            {
                buildProgrammeActivityId: this.mappedItem.id,
                supplyTypeId: this.selectedSupplyType && this.selectedSupplyType.id,
                tradeTypeId: this.selectedTradeType && this.selectedTradeType.id,
                supplierId: this.selectedBusinessAccount.id,
                lotId: this.data.lotIsPrimaryUnit ? this.data.lotId : this.mappedItem.lotId
            };

            if (!dto.tradeTypeId) {
                delete dto.tradeTypeId;
            }

            if (!dto.supplyTypeId) {
                delete dto.supplyTypeId;
            }

            this.mappedItem
                .getAffectedActivitiesForSupplierChange(dto)
                .subOnce(results => this.affectedActivitiesForSupplierChange = results);
        }else{
            // Clear the affected list
            this.affectedActivitiesForSupplierChange = [];
        }
    }

    public setUpTradeTypes(): void {
        this.tradeTypesInSupplierAndActivity = [];
        this.selectedTradeType = undefined;

        if (this.mappedItem.tradeTypes
            && this.mappedItem.tradeTypes.length === 1) {
            this.tradeTypesInSupplierAndActivity.push(this.mappedItem.tradeTypes[0]);
            this.selectedTradeType = this.mappedItem.tradeTypes[0];
        } else if (this.mappedItem.tradeTypes && this.mappedItem.tradeTypes.length > 1) {
            this.mappedItem.tradeTypes.forEach(activityTt => {
                if (this.selectedBusinessAccount?.tradeTypes?.some(accountTT => accountTT === activityTt.id)) {
                    this.tradeTypesInSupplierAndActivity.push(activityTt);
                }
            });
        }

        if (this.tradeTypesInSupplierAndActivity.length === 1) {
            this.selectedTradeType = this.tradeTypesInSupplierAndActivity[0];
        }

        if (this.tradeTypesInSupplierAndActivity.length < 1) {
            this.noTradeTypeOptions = true;
        } else {
            this.noTradeTypeOptions = false;
            this.setLabel();
        }
    }

    public setUpSupplyTypes(): void {
        this.supplyTypesInSupplierAndActivity = [];
        this.selectedSupplyType = undefined;

        if (this.mappedItem.supplyTypes
            && this.mappedItem.supplyTypes.length === 1) {
            this.supplyTypesInSupplierAndActivity.push(this.mappedItem.supplyTypes[0]);
            this.selectedSupplyType = this.mappedItem.supplyTypes[0];
        } else if (this.mappedItem.supplyTypes && this.mappedItem.supplyTypes.length > 1) {
            this.mappedItem.supplyTypes.forEach(activitySt => {
                if (this.selectedBusinessAccount?.supplyTypes?.some(accountST => accountST === activitySt.id)) {
                    this.supplyTypesInSupplierAndActivity.push(activitySt);
                }
            });
        }

        if (this.supplyTypesInSupplierAndActivity.length === 1) {
            this.selectedSupplyType = this.supplyTypesInSupplierAndActivity[0];
        }

        if (this.supplyTypesInSupplierAndActivity.length < 1) {
            this.noSupplyTypeOptions = true;
        } else {
            this.noSupplyTypeOptions = false;
            this.setLabel();
        }
    }

    public setSupplier(): void {
        this.saveInProgress = true;
        this.blockingRef = this.cbDialog.block('Setting supplier, please wait...');

        const dto: IBuildProgrammeActivityUpdateSupplierDto = {
            buildProgrammeActivityId: this.mappedItem.id,
            reassignIncompleteActivities: this.reassignIncompleteActivities,
            supplierId: this.selectedBusinessAccount.id,
            tradeTypeId: this.selectedTradeType && this.selectedTradeType.id,
            supplyTypeId: this.selectedSupplyType && this.selectedSupplyType.id,
            assignSameSupplierToSecondaryLot: this.assignSameSupplierToSecondaryLot,
            primaryLotId: this.data.lotIsPrimaryUnit ? this.data.lotId : undefined
        };

        if (!dto.tradeTypeId) {
            delete dto.tradeTypeId;
        }

        if (!dto.supplyTypeId) {
            delete dto.supplyTypeId;
        }

        this.mappedItem.businessAccount = this.selectedBusinessAccount as any;

        this.mappedItem
            .setSupplier(dto)
            .subOnce((results) => {
                this.blockingRef.close();
                this.dialogRef.close(results);
                this.saveInProgress = false;
            });
    }

    public setLabel(): void {
        if (this.tradeTypesInSupplierAndActivity.length > 1) {
            this.labelCheckbox = `Assign ${this.selectedBusinessAccount.tradingName} to all Incomplete non-quoted Activities for the selected Trade Type`;
            return;
        }
        if (this.supplyTypesInSupplierAndActivity.length > 1) {
            this.labelCheckbox = `Assign ${this.selectedBusinessAccount.tradingName} to all Incomplete non-quoted Activities for the selected Supply Type`;
            return;
        }
        if(this.selectedTradeType) {
            this.labelCheckbox = `Assign ${this.selectedBusinessAccount.tradingName} to all Incomplete non-quoted ${this.selectedTradeType.label} activities`;
            return;
        }
        if(this.selectedSupplyType) {
            this.labelCheckbox = `Assign ${this.selectedBusinessAccount.tradingName} to all Incomplete non-quoted ${this.selectedSupplyType.label} activities`;
            return;
        }
    }

    public setSupplierDisabled(supplierForm: NgForm): boolean {
        if (this.noTradeTypeOptions && this.noSupplyTypeOptions && supplierForm && supplierForm.dirty) {
            return false;
        }
        return !supplierForm
            || (!this.selectedTradeType && this.mappedItem.tradeTypes.length > 0)
            || (!this.selectedSupplyType && this.mappedItem.supplyTypes.length > 0);
    }

    public mustShowAffectActivityList(): boolean {
        return this.reassignIncompleteActivities;
    }

    public foundAffectedActivities(): boolean {
        return this.affectedActivitiesForSupplierChange &&
            this.affectedActivitiesForSupplierChange.length > 0;
    }
}
