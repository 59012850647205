import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LeadPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILeadSearchPageSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ILeadSearchDto, LeadLogicService } from '@app/logic/leads';
import { IUserDto, UsersLogicService } from '@app/logic/users';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FilterChip } from '@app/shared/components/filter-chips/filter-chip-list/filter-chip-list.component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { LEAD_STATUS_ENUM, LeadStatusEnumId, USER_TAG_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, debounceTime, distinctUntilChanged, of } from 'rxjs';
import { CreateLeadDialogComponent } from '../../create-lead-dialog/create-lead-dialog.component';
import { viewLeadUrl } from '../../lead-routes';

@Component({
    selector: 'cb-lead-search',
    templateUrl: './lead-search.component.html',
    styleUrls: ['./lead-search.component.scss'],
    providers: [
        MatButton
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class LeadSearchComponent extends BaseSearchViewDirective<ILeadSearchPageSearch> implements OnInit, OnDestroy {
    public paramsSetupComplete = false;
    public searchParams: ILeadSearchPageSearch;
    public buildingConsultants: IUserDto[];
    public leadStatuses: IEnumLookup<LeadStatusEnumId>[];
    public fromDateObject: Date;
    public toDateObject: Date;
    public showCreate = false;
    public form: UntypedFormGroup;
    @Output() public emitFilterEvent = new EventEmitter();

    public searchQueryUpdate = new Subject<string>();
    public filterChips$ = new BehaviorSubject<Array<FilterChip>>(undefined);
    public searchResults: Array<ILeadSearchDto>;

    constructor(
        public readonly userCacheService: UserCacheService,
        private readonly usersLogicService: UsersLogicService,
        public readonly leadPermissions: LeadPermissions,
        public readonly currentUser: CurrentUserService,
        leadLogicService: LeadLogicService,
        public readonly toastService: ToastService,
        public readonly datePipe: DatePipe,
        public readonly navigationService: NavigationService,
        public readonly cbDialog: CbDialogService
    ) {
        super(leadLogicService, toastService, userCacheService, USER_CACHE_AREA.LeadSearch);
        this.leadPermissions.observable.subOnce(_ => {
            this.leadStatuses = LEAD_STATUS_ENUM.toLookup();
            this.loadBuildingConsultants().subOnce(consultants => {
                this.buildingConsultants = consultants;
                this.loadSearchParams();
            });
            this.showCreate = this.leadPermissions.canCreate();
            this.searchQueryUpdate.pipe(
                debounceTime(500),
                distinctUntilChanged())
                .subscribe(() => {
                    this.doSearch();
                });
        });
    }

    public ngOnInit(): void {
        this.form = new UntypedFormGroup({
            leadStatuses: new UntypedFormControl(this.leadStatuses),
            buildingConsultants: new UntypedFormControl(this.buildingConsultants),
        });
    }

    public ngOnDestroy(): void {
        this.emitFilterEvent.unsubscribe();
    }

    public initialiseData(): void {
    }

    public selectedStatusesChanged(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.data.status = selectMultipleElement.value;
        this.filtersUpdated();
    }

    public selectedBuildConsultantIdsChanged(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.data.buildingConsultantId = selectMultipleElement.value;
        this.filtersUpdated();
    }

    public loadBuildingConsultants(): Observable<IUserDto[]> {
        if (this.leadPermissions.canViewAll()) {
            return this.usersLogicService.getListByTag({ tag: USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT });
        } else if (this.leadPermissions.canViewForBusinessEntity()) {
            return this.usersLogicService.getListByTagAndBusinessEntity({
                businessEntities: this.currentUser.regionsids,
                tag: USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT
            });
        } else {
            return of([]);
        }
    }

    protected getSearchParams(): ILeadSearchPageSearch {
        const data = this.userCacheItem.copyData();
        return {
            query: data.query,
            buildingConsultantId: data.buildingConsultantId,
            status: data.status,
            fromDate: data.fromDate,
            toDate: data.toDate
        };
    }

    public showNoResultsMessage(): boolean {
        return (!this.searchResults || this.searchResults.length < 1) && this.searchResultsLoaded;
    }

    public updateDataBehindFilterChips(filterChips: FilterChip[]): void {
        if (filterChips) {
            if (this.userCacheItem.data.buildingConsultantId && !filterChips.some(x => x.id === 'buildingConsultantId')) {
                this.userCacheItem.data.buildingConsultantId = undefined;
            }
            if (this.userCacheItem.data.fromDate && !filterChips.some(x => x.id === 'fromDate')) {
                this.fromDateObject = undefined;
                this.userCacheItem.data.fromDate = undefined;
            }
            if (this.userCacheItem.data.toDate && !filterChips.some(x => x.id === 'toDate')) {
                this.toDateObject = undefined;
                this.userCacheItem.data.toDate = undefined;
            }
            if (this.userCacheItem.data.query && !filterChips.some(x => x.id === 'query')) {
                this.userCacheItem.data.query = undefined;
            }
            if (this.userCacheItem.data.status && !filterChips.some(x => x.id === 'status')) {
                this.userCacheItem.data.status = undefined;
            }
            this.userCacheItem.save();
        }
    }

    public updateFilterChips(): void {
        const queryFilterChip = this.getQueryFilterChip();
        const buildingConsultantFilterChip = this.getBuildingConsultantFilterChip();
        const statusFilterChip = this.getStatusFilterChip();
        const fromDateFilterChip = this.getFromDateFilterChip();
        const toDateFilterChip = this.getToDateFilterChip();

        const allFilterChips = [
            queryFilterChip,
            buildingConsultantFilterChip,
            statusFilterChip,
            fromDateFilterChip,
            toDateFilterChip,
        ];

        const validFilterChips = allFilterChips.filter(element => {
            return (element !== undefined && element !== null);
        });
        this.filterChips$.next(validFilterChips);
    }

    private getBuildingConsultantFilterChip = (): FilterChip => {
        if (this.userCacheItem?.data?.buildingConsultantId?.length > 0) {
            const selectedBuildingConsultants = this.buildingConsultants
                .filter(bc => this.userCacheItem?.data?.buildingConsultantId?.some(uc => uc === bc?.id));
            const firstBc = selectedBuildingConsultants[0];
            return { id: 'buildingConsultantId', text: `Building Consultant - ${firstBc?.firstName} ${firstBc?.lastName}... (${selectedBuildingConsultants?.length} selected)` };
        }
    };

    private getStatusFilterChip = (): FilterChip => {
        if (this.userCacheItem?.data?.status?.length > 0) {
            const allStatuses = LEAD_STATUS_ENUM.toLookup().filter(x => this.userCacheItem.data.status?.includes(x?.id));
            const firstStatus = allStatuses[0];
            return { id: 'status', text: `Status - ${firstStatus?.label}... (${allStatuses.length} selected)` };
        }
    };

    private getQueryFilterChip = (): FilterChip => {
        if (this.userCacheItem.data.query !== undefined
            && this.userCacheItem.data.query !== '') {
            return { id: 'query', text: `Query - ${this.userCacheItem.data.query}` };
        }
    };

    private getFromDateFilterChip = (): FilterChip => {
        if (this.userCacheItem.data.fromDate) {
            return {
                id: 'fromDate',
                text: `From Date - ${this.datePipe.transform(this.userCacheItem.data.fromDate, 'dd MMM yyyy')}`
            };
        }
    };

    private getToDateFilterChip = (): FilterChip => {
        if (this.userCacheItem.data.toDate) {
            return {
                id: 'toDate',
                text: `To Date - ${this.datePipe.transform(this.userCacheItem.data.toDate, 'dd MMM yyyy')}`
            };
        }
    };

    public filtersUpdated = (): void => {
        if (this.searchIsLoading) {
            return;
        }
        this.currentPage = 0;
        this.searchResults = undefined;
        this.noMoreResults = false;
        this.doSearch();
        this.updateFilterChips();
    };

    public dateUpdated(): void {
        this.userCacheItem.data.fromDate = this.fromDateObject ? moment(this.fromDateObject).format() : undefined;
        this.userCacheItem.data.toDate = this.toDateObject ? moment(this.toDateObject).format() : undefined;
        this.filtersUpdated();
    }

    public formatData = (): void => {
        this.fromDateObject = this.userCacheItem.data.fromDate ? new Date(this.userCacheItem.data.fromDate) : undefined;
        this.toDateObject = this.userCacheItem.data.toDate ? new Date(this.userCacheItem.data.toDate) : undefined;
    };

    public createLead(): void {
        this.cbDialog
            .open(CreateLeadDialogComponent, {
                data: {},
                minWidth: '40%',
            })
            .afterClosed()
            .subOnce((result: false | ILeadSearchDto) => {
                if (result) {
                    this.searchResults.unshift(result);
                    this.viewLead(result.id);
                }
            });
    }

    public viewLead(leadId): void {
        this.navigationService.navigate([viewLeadUrl(leadId)]);
    }
}
