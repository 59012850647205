import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeOptionLogicService } from '@app/logic/change-option/change-option.logic-service';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { CHANGE_STATUS_ENUM, COST_TYPE_ENUM, IChangeOptionDto, IChangeOptionLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { LotChangeOptionDialogNewComponent } from '../lot-change-option-dialog/lot-change-option-dialog.component';
import { LotChangesVariationsDataService } from '../lot-changes-variations.data.service';

@Component({
    selector: 'cb-lot-change-option',
    templateUrl: './lot-change-option.component.html',
    styleUrls: ['./lot-change-option.component.scss'],
})
export class LotChangeOptionComponent implements OnInit {

    @Input() public changeRecordMappedItem: IChangeRecordMappedItem;
    @Input() public changeOption: IChangeOptionDto;
    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public readonly optionUpdated = new EventEmitter();

    public provisionalCost = 0;
    public onChargeDisplay: number;

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly changeOptionLogicService: ChangeOptionLogicService,
        private readonly lotChangesVariationsDataService: LotChangesVariationsDataService) {
    }

    public ngOnInit(): void {
        this.refreshInfo();
    }

    private refreshInfo(): void {
        if (this.changeOption.id > 0) {
            this.setProvisionalCost();
            this.onChargeDisplay = this.changeOption.onCharge < 0 ? -this.changeOption.onCharge : this.changeOption.onCharge;
        }
    }


    public setProvisionalCost(): void {
        let provisionalCostTotal = 0;
        this.changeOption.changeOptionLines.forEach((line: IChangeOptionLineDto) => {
            if (line.costType === COST_TYPE_ENUM.Provisional) {
                provisionalCostTotal += line.costAmount;
            }
        });
        this.provisionalCost = provisionalCostTotal;
    }

    public notifyQsIfSelected(): boolean {
        return this.changeOption.notifyQsIfSelected;
    }


    public isOptionSelectedOrChangeCancelled(): boolean {
        return this.changeOption.isSelectedChangeOption ||
            this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Cancelled ||
            this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.ResponseRequired;
    }

    public removeChangeOptionDisabled(): boolean {
        return this.changeRecordMappedItem.changeOptions.filter(a => !a.isDeleted).length < 2
            || this.isOptionSelectedOrChangeCancelled()
            || this.isChangeRecordResponseRequired();
    }

    public isChangeRecordPending(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Pending;
    }

    public isChangeRecordResponseRequired(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.ResponseRequired;
    }

    public isChangeRecordDeclined(): boolean {
        return this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Declined;
    }

    public getStatusIconIcon(): string {
        switch (this.changeRecordMappedItem.changeStatus) {
            case CHANGE_STATUS_ENUM.Cancelled:
            case CHANGE_STATUS_ENUM.Declined:
                return 'delete_forever';
            default:
                return this.changeOption.isSelectedChangeOption ? 'check_box' : 'indeterminate_check_box';
        }
    }

    public getStatusIconClass(): string {
        switch (this.changeRecordMappedItem.changeStatus) {
            case CHANGE_STATUS_ENUM.Cancelled:
            case CHANGE_STATUS_ENUM.Declined:
                return 'mat-warn';
            default:
                return this.changeOption.isSelectedChangeOption ? 'mat-primary' : '';
        }
    }

    public getStatusIconTitle(): string {
        switch (this.changeRecordMappedItem.changeStatus) {
            case CHANGE_STATUS_ENUM.Cancelled:
                return 'Cancelled';
            case CHANGE_STATUS_ENUM.Declined:
                return 'Declined';
            default:
                return this.changeOption.isSelectedChangeOption ? 'Selected' : 'Not Selected';
        }
    }

    public isMenuDisabled(): boolean {
        return this.isNotAcceptedOptionAndChangeRecordIsAccepted(this.changeOption)
            || this.isChangeRecordDeclined()
            || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Pending
            || (this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.InQueue && this.changeRecordMappedItem.assignedTeamId > 0)
            || this.changeOption.isDeleted;
    }

    public isNotAcceptedOptionAndChangeRecordIsAccepted(option: IChangeOptionDto): boolean {
        return !option.isSelectedChangeOption &&
            (this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Accepted
                || this.changeRecordMappedItem.changeStatus === CHANGE_STATUS_ENUM.Completed);

    }

    public setAcceptedOptionClicked(): void {
        this.changeRecordMappedItem.changeOptions.forEach(option => {
            if (option.optionNumber === this.changeOption.optionNumber) {
                option.isSelectedChangeOption = true;
            } else {
                option.isSelectedChangeOption = false;
            }
        });
        this.changeRecordMappedItem.changeStatus = CHANGE_STATUS_ENUM.Accepted;
        this.changeRecordMappedItem.$save().subOnce(() => {
            this.lotChangesVariationsDataService.loadUnassignedChangeRecordsCount(this.lotMappedItem.id);
        });

    }


    public editOption(): void {

        const dialogRef = this.cbDialog.open(LotChangeOptionDialogNewComponent,
            {
                data: {
                    changeRecordMappedItem: this.changeRecordMappedItem,
                    changeOptionMappedItem: this.changeOptionLogicService.$createMappedItem(this.changeOption),
                    lotMappedItem: this.lotMappedItem,
                    mode: this.isOptionSelectedOrChangeCancelled() ? FormMode.View : FormMode.Edit,
                },
                width: '60%'
            });

        dialogRef.afterClosed().subOnce(_changeRecordDto => {
            if (_changeRecordDto && _changeRecordDto.id > 0) {
                this.changeRecordMappedItem.$updateThisAndOriginal(_changeRecordDto);

                // Forces the UI to update
                this.optionUpdated.emit();
            }
        });
    }

    public removeOption(changeOption: IChangeOptionDto): void {

        this.cbDialog.confirm({
            message: 'Are you sure you want to remove this change option ?',
            confirmed: () => this.removeChangeOption(changeOption)
        });
    }


    private readonly removeChangeOption = (changeOption: IChangeOptionDto): void => {

        this.changeOptionLogicService.deleteOption(changeOption.id).subOnce((result) => {
            if (result) {

                const indexOfChangeOptionToDelete = this.changeRecordMappedItem.changeOptions.indexOf(changeOption);

                if (indexOfChangeOptionToDelete > -1) {
                    this.changeRecordMappedItem.changeOptions.splice(indexOfChangeOptionToDelete, 1);
                }
            }
        });

    };

}
