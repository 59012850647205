import {Component, Input} from '@angular/core';

@Component({
    selector: 'cb-info-message',
    templateUrl: './info-message.component.html',
    styleUrls: ['./info-message.component.scss'],
})
export class InfoMessageComponent {

    @Input() public readonly message: string = 'Nothing here';
    @Input() public readonly icon: string = 'info_circle';
    @Input() public readonly iconClass: string = '';

    constructor() {
    }
}
