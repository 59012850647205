<form #form="ngForm">
    <div class="flex-col flex"
         *ngIf="!(displayCompare$ | async) && (mappedItem$$ | async)">
        <mat-card class="cb-margin-bottom flex-row">
            <cb-button class="cb-margin-right"
                       (clicked)="backToSearch()"
                       color="white">
                <mat-icon>arrow_back</mat-icon>
                &nbsp;Go Back to Takeoff List
            </cb-button>
            <cb-button class="cb-margin-right"
                       (clicked)="editTakeOffTask()"
                       [disabled]="!(mappedItem$$ | async).$canEditTakeOffTask">
                <mat-icon>edit</mat-icon>
                &nbsp;Edit Takeoff
            </cb-button>
            <cb-button [menu]="menu"
                       class="cb-margin-right"
                       menuButtonLabel="Status"
                       [disabled]="!(mappedItem$$ | async).$canEditTakeOffTask">
            </cb-button>
            <mat-menu #menu="matMenu">
                <cb-button [isMenuItem]="true"
                           icon="timelapse"
                           iconColor="accent"
                           menuItemLabel="In Queue"
                           (clicked)="setStatus(TAKE_OFF_TASK_STATUS_ENUM.InQueue)"
                           [disabled]="!canSetStatus(TAKE_OFF_TASK_STATUS_ENUM.InQueue)">
                </cb-button>
                <cb-button [isMenuItem]="true"
                           icon="timer"
                           iconColor="accent"
                           menuItemLabel="In Progress"
                           (clicked)="setStatus(TAKE_OFF_TASK_STATUS_ENUM.InProgress)"
                           [disabled]="!canSetStatus(TAKE_OFF_TASK_STATUS_ENUM.InProgress) && (mappedItem$$ | async).assignedToUserId != undefined">
                </cb-button>
                <cb-button [isMenuItem]="true"
                           icon="pause"
                           iconColor="warn"
                           menuItemLabel="On Hold"
                           (clicked)="setStatus(TAKE_OFF_TASK_STATUS_ENUM.OnHold)"
                           [disabled]="!canSetStatus(TAKE_OFF_TASK_STATUS_ENUM.OnHold)">
                </cb-button>
                <cb-button [isMenuItem]="true"
                           icon="cancel"
                           iconColor="danger"
                           menuItemLabel="Cancel"
                           (clicked)="setStatus(TAKE_OFF_TASK_STATUS_ENUM.Cancelled)"
                           [disabled]="!canSetStatus(TAKE_OFF_TASK_STATUS_ENUM.Cancelled)">
                </cb-button>
            </mat-menu>
            <cb-button (clicked)="openUploadDialogue()"
                       [disabled]="!(mappedItem$$ | async).$canImportTakeOff"
                       *ngIf="canCreateTakeoff()">
                Import Takeoff
            </cb-button>
        </mat-card>
        <div class="flex-row flex">
            <mat-card class="cb-margin-right flex-col flex-66"
                      *ngIf="(mappedItem$$ | async)">
                <h3 class="mat-title cb-margin-bottom">
                    {{(mappedItem$$ | async).label}}
                </h3>
                <mat-card-content class="flex-col flex">
                    <div class="flex-row">
                        <cb-display-value-new class="flex"
                                              label="Status"
                                              [value]="(mappedItem$$ | async).statusLabel">
                        </cb-display-value-new>
                        <cb-elapsed-time class="flex"
                                         label="Elapsed Time"
                                         [counting]="true"
                                         [elapsedTime]="clientSideElapsedTime$ | async">
                        </cb-elapsed-time>
                    </div>
                    <div class="flex-row">
                        <cb-display-value-new class="flex"
                                              label="Assigned To"
                                              [value]="(mappedItem$$ | async).assignedToUserName">
                        </cb-display-value-new>
                        <cb-display-value-new class="flex"
                                              label="Pricing Complexity"
                                              [value]="(mappedItem$$ | async).pricingComplexityLabel">
                        </cb-display-value-new>
                    </div>
                    <div class="flex-row">
                        <cb-display-value-new class="flex"
                                              label="Design Concept"
                                              [value]="(mappedItem$$ | async).designConcept?.name">
                        </cb-display-value-new>
                        <cb-display-value-new class="flex"
                                              label="Working Drawing"
                                              [value]="(mappedItem$$ | async).workingDrawing?.name">
                        </cb-display-value-new>
                    </div>
                </mat-card-content>
            </mat-card>
            <div class="flex-col flex">
                <cb-lot-takeoff-card *ngFor="let takeoff of (mappedItem$$ | async).takeOffs | cbSortByImpurePipe: 'createdDate': 'desc'"
                                     [mappedItem]="takeoff"
                                     class="cb-margin-bottom"
                                     (goToTakeOffEmitter)="goToTakeOff($event)">
                </cb-lot-takeoff-card>
                <span class="flex"
                      *ngIf="(mappedItem$$ | async).takeOffs?.length < 1">
                    <mat-icon class="mat-accent">info</mat-icon>
                    &nbsp;There are no take offs imported for this task
                </span>
            </div>
        </div>
    </div>
    <cb-takeoff-compare-new class="cb-max-width-100 flex"
                            [lot]="lot"
                            [id]="selectedTakeOffId"
                            [displayCompare$]="displayCompare$"
                            [takeOffs]="(mappedItem$$ | async).takeOffs"
                            [takeOffResult]="takeOffResult"
                            [lotId]="lot.id"
                            [document]="document"
                            *ngIf="displayCompare$ | async">
    </cb-takeoff-compare-new>
</form>
