import { CONTACT_METHOD_ENUM, IContactDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class ContactUtilities {

    public static hasPhoneHome(contact: IContactDto): boolean {
        return contact.phoneHome && contact.phoneHome.length > 0;
    }

    public static isHomePreferred(contact: IContactDto): boolean {
        return contact.preferredContact === CONTACT_METHOD_ENUM.HomePhone;
    }

    public static hasPhoneWork(contact: IContactDto): boolean {
        return contact.phoneWork && contact.phoneWork.length > 0;
    }

    public static isWorkPreferred(contact: IContactDto): boolean {
        return contact.preferredContact === CONTACT_METHOD_ENUM.WorkPhone;
    }

    public static hasPhoneMobile(contact: IContactDto): boolean {
        return contact.phoneMobile && contact.phoneMobile.length > 0;
    }

    public static isMobilePreferred(contact: IContactDto): boolean {
        return contact.preferredContact === CONTACT_METHOD_ENUM.MobilePhone;
    }

    public static isEmailPreferred(contact: IContactDto): boolean {
        return contact.preferredContact === CONTACT_METHOD_ENUM.Email;
    }
}
