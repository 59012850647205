import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';
import {Observable} from 'rxjs';
import {VendorsLogicService} from '@app/logic/vendors/vendors.logic.service';
import {IVendorDto} from '@app/logic/vendors/interfaces/i.vendor.dto';

@Injectable()
export class VendorSearchService extends SearchService<IVendorDto> {

    constructor(
        public readonly vendorsLogicService: VendorsLogicService,
    ) {
        super();
    }

    public getResults(query: string, currentpage: number): Observable<IVendorDto[]> {
        return new Observable<IVendorDto[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;

                const resultsObservable = this.vendorsLogicService.search({
                    query,
                    currentPage: currentpage,
                    ...this.extraSearchParams
                }, false);

                if (!resultsObservable) {
                    return;
                }

                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<IVendorDto>(x);
                        subscriber.next(this.searchResults$.value as IVendorDto[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as IVendorDto[]);
                subscriber.complete();
            }
        });
    }
}
