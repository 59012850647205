import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbClickModule} from '@app/shared/directives/click/click.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {BrowserModule} from '@angular/platform-browser';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatIconModule} from '@angular/material/icon';
import {CbInfoMessageModule} from 'projects/cb-hub-lib/src/lib/components/info-message/info-message.module';
import {CbEnumToLabelModule} from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import {CbWaitForModule} from '@app/shared/directives/wait-for/wait-for.module';
import {CbInfoPopupModule} from 'projects/cb-hub-lib/src/lib/components/info-popup/info-popup.module';
import {CbDasherModule} from 'projects/cb-hub-lib/src/lib/pipe/dasher/dasher.module';
import {PreConsentComponent} from './pre-consent/pre-consent.component';
import {PreConsentCardComponent} from './pre-consent-card/pre-consent-card.component';
import {CbDocumentsModule} from '@app/shared/components/documents/documents.module';
import {CbPopupTipModule} from '@app/shared/components/popup-tip/popup-tip.module';
import {CbButtonModule} from '@app/shared/components/button/button.module';
import {CbTableModule} from 'projects/cb-hub-lib/src/lib/components/table/table.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {RejectPreconsentDialogComponent} from './reject-preconsent-dialog/reject-preconsent-dialog.component';
import {ManagePreConsentViewComponent} from './manage-pre-consent-view/manage-pre-consent-view.component';
import {PreConsentActionBarComponent} from './pre-consent-action-bar/pre-consent-action-bar.component';
import {PreConsentSideBarCardComponent} from './pre-consent-side-bar-card/pre-consent-side-bar-card.component';
import {ManagePreConsentTabsComponent} from './manage-pre-consent-tabs/manage-pre-consent-tabs.component';
import {PreConsentDetailsComponent} from './pre-consent-details/pre-consent-details.component';
import {CbUserDateTimeModule} from '@app/shared/components/user-date-time/user-date-time.module';
import {PreConsentItemsAndChangesComponent} from './pre-consent-items-and-changes/pre-consent-items-and-changes.component';
import {ManagePreConsentDialogComponent} from './manage-pre-consent-dialog/manage-pre-consent-dialog.component';
import {ManagePreConsentDialogDetailsComponent} from './manage-pre-consent-dialog-details/manage-pre-consent-dialog-details.component';
import {PreConsentItemsComponent} from './pre-consent-items/pre-consent-items.component';
import {PreConsentItemDialogComponent} from './pre-consent-item-dialog/pre-consent-item-dialog.component';
import {CbGenericQuestionsModule} from '@app/shared/components/generic-questions/generic-questions.module';
import {FinalisePreconsentPlanDialogComponent} from './finalise-preconsent-plan-dialog/finalise-preconsent-plan-dialog.component';
import {ReviewPreconsentPlanDialogComponent} from './review-preconsent-plan-dialog/review-preconsent-plan-dialog.component';
import {RequestPreconsentDialogComponent} from './request-preconsent-dialog/request-preconsent-dialog.component';
import {
    RequestPreconsentSignedSchemeUploadComponent
} from '@app/views/lot/design/pre-consent/request-preconsent-dialog-wizard-steps/request-preconsent-signed-scheme-upload/request-preconsent-signed-scheme-upload.component';
import {
    RequestPreconsentPlanDocumentsUploadComponent
} from '@app/views/lot/design/pre-consent/request-preconsent-dialog-wizard-steps/request-preconsent-plan-documents-upload/request-preconsent-plan-documents-upload.component';
import {RequestPreConsentPlanItemsComponent} from './request-preconsent-dialog-wizard-steps/request-pre-consent-plan-items/request-pre-consent-plan-items.component';
import {PreConsentPlanItemComponent} from './request-preconsent-dialog-wizard-steps/pre-consent-plan-item/pre-consent-plan-item.component';
import {
    RequestPreConsentEditItemDialogComponent
} from './request-preconsent-dialog-wizard-steps/request-pre-consent-edit-item-dialog/request-pre-consent-edit-item-dialog.component';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({

    exports: [
        PreConsentComponent,
        ManagePreConsentDialogDetailsComponent
    ],

    declarations: [
        PreConsentComponent,
        PreConsentCardComponent,
        RejectPreconsentDialogComponent,
        ManagePreConsentViewComponent,
        PreConsentActionBarComponent,
        PreConsentSideBarCardComponent,
        ManagePreConsentTabsComponent,
        PreConsentDetailsComponent,
        PreConsentItemsAndChangesComponent,
        ManagePreConsentDialogComponent,
        ManagePreConsentDialogDetailsComponent,
        PreConsentItemsComponent,
        PreConsentItemDialogComponent,
        FinalisePreconsentPlanDialogComponent,
        ReviewPreconsentPlanDialogComponent,
        RequestPreconsentDialogComponent,
        RequestPreconsentSignedSchemeUploadComponent,
        RequestPreconsentPlanDocumentsUploadComponent,
        RequestPreConsentPlanItemsComponent,
        PreConsentPlanItemComponent,
        RequestPreConsentEditItemDialogComponent
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        CbFormsModule,
        CbDialogModule,
        CbClickModule,
        CbDisplayValueModule,
        MatCardModule,
        MatTabsModule,
        MatTooltipModule,
        MatIconModule,
        CbInfoMessageModule,
        CbEnumToLabelModule,
        CbWaitForModule,
        CbInfoPopupModule,
        CbDasherModule,
        CbButtonModule,
        CbPopupTipModule,
        CbDocumentsModule,
        CbTableModule,
        MatMenuModule,
        MatButtonModule,
        CbUserDateTimeModule,
        CbGenericQuestionsModule
    ]
})

export class LotPreConsentModule { }
