<mat-card class="cb-margin flex-row flex"
          style="overflow-x: auto">
    <div class="flex-col flex-100">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">Specification Templates</h3>
            <button mat-raised-button
                    (click)="newItemClicked();"
                    color="primary">
                Create Blank Template
            </button>
        </div>
        <div class="flex-row">
            <cb-spec-template-filters (filtersChanged)="search($event)"></cb-spec-template-filters>
        </div>
        <div class="cb-margin-top flex-col">
            <cb-spec-template-table [templates]="searchResults"
                                    (viewTemplate)="viewTemplateClicked($event)"
                                    (editTemplate)="editItemClicked($event)"
                                    (duplicateTemplate)="duplicateItemClicked($event)"></cb-spec-template-table>
        </div>
    </div>
</mat-card>
