import {BasePermissions, IBaseSystemAreaPermissions} from './base.permissions';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../authentication/current.user';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HelpPermissionEnum} from '../../shared/enums/permissions';


export interface IHelpPermissions extends IBaseSystemAreaPermissions {
    canViewUnpublished(): boolean;
    canReorder(): boolean;
    canDelete(): boolean;
    canManageCategories(): boolean;
}

@Injectable()
export class HelpPermissions extends BasePermissions<HelpPermissionEnum>
    implements IHelpPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('help', HelpPermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(HelpPermissionEnum.CanView);
    }

    public canViewUnpublished(): boolean {
        return this.permissionObject(HelpPermissionEnum.CanViewUnpublished);
    }

    public canEdit(): boolean {
        return this.permissionObject(HelpPermissionEnum.CanEdit);
    }

    public canReorder(): boolean {
        return this.permissionObject(HelpPermissionEnum.CanReorder);
    }

    public canCreate(): boolean {
        return this.permissionObject(HelpPermissionEnum.CanCreate);
    }

    public canDelete(): boolean {
        return this.permissionObject(HelpPermissionEnum.CanDelete);
    }

    public canManageCategories(): boolean {
        return this.permissionObject(HelpPermissionEnum.CanManageCategories);
    }
}
