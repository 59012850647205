import { Component, Inject, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@app/core/services/toast/toast.service';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { ScheduleTemplatesLogicService } from '@app/logic/schedule-templates/schedule-templates.logic.service';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { toPromise, toPromisedArray } from 'cb-hub-lib';
import { ILotSpecItemExpandedPanels } from '@app/views/lot/specification/lot-spec-items-list/lot-spec-items-list.component';
import {
    IScheduleTemplateListItemDto,
    ISkinnyScheduleTemplateDto,
    ISkinnyScheduleTemplateItemDto,
    IUpdateSkinnyScheduleTemplateDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { map, switchMap } from 'rxjs';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { ScheduleTemplatePermissionService } from '@app/logic/schedule-templates/schedule-template.permission.service';
import { SpecificationTemplatePermissions } from '@app/core/permissions';
import { ScheduleTemplateItemsTableComponent } from '../schedule-template-items-table/schedule-template-items-table.component';


@Component({
    selector: 'cb-manage-schedule-template',
    templateUrl: './manage-schedule-template.component.html',
    styleUrls: ['./manage-schedule-template.component.scss'],
})
export class ManageScheduleTemplateComponent {

    public scheduleTemplateId: number;
    public scheduleTemplateName: string;

    public expandedTemplateAreas = {} as ILotSpecItemExpandedPanels;
    public expandedItemAreas = {} as ILotSpecItemExpandedPanels;

    public allItemsExpanded = false;
    public allTemplatesExpanded = false;

    public listOfGroupedSpecTemplateItems: Map<number, IScheduleTemplateListItemDto[]>;
    public skinnyScheduleTemplateItems: ISkinnyScheduleTemplateItemDto[];

    public listOfGroupedSpecTemplateItemsBackup: Map<number, IScheduleTemplateListItemDto[]>;
    public skinnyScheduleTemplateItemsBackup: ISkinnyScheduleTemplateItemDto[];

    @ViewChild(ScheduleTemplateItemsTableComponent, {}) public scheduleTemplateItemsTable: ScheduleTemplateItemsTableComponent;

    public isSaveDisabled = true;
    public isCancelDisabled = true;

    public houseAreas = toPromisedArray(
        this.specGroupsService.$getList().pipe(
            map(results => results.sort((a, b) => a.sortOrder - b.sortOrder))
        )
    );

    public canEdit = false;

    constructor(
        private renderer: Renderer2,
        private readonly scheduleTemplatesLogicService: ScheduleTemplatesLogicService,
        public readonly route: ActivatedRoute,
        private readonly toastService: ToastService,
        @Inject(SpecGroupsLogicService) public readonly specGroupsService: SpecGroupsLogicService,
        public readonly featureToggle: FeatureToggleStatesService,
        public readonly scheduleTemplatePermissionService: ScheduleTemplatePermissionService,
        public readonly specTemplatePermissions: SpecificationTemplatePermissions,
    ) {
    }

    public ngOnInit(): void {
        toPromise(this.route.params.pipe(
            switchMap((params: { id: number }) => {
                return this.scheduleTemplatesLogicService.$getItem(params.id);
            })
        )).then((skinnyScheduleTemplate: ISkinnyScheduleTemplateDto) => {
            this.featureToggle
                .init(
                    toPromise(this.specTemplatePermissions.observable)
                )
                .then(() => {
                    this.canEdit = this.scheduleTemplatePermissionService.canEdit(skinnyScheduleTemplate);
                    this.initialiseData(skinnyScheduleTemplate);
                });
        });
    }


    /** Initialise the Data and Set/ Map properties   */
    private initialiseData(skinnyScheduleTemplate: ISkinnyScheduleTemplateDto): void {

        this.scheduleTemplateId = skinnyScheduleTemplate.id;
        this.scheduleTemplateName = skinnyScheduleTemplate.name;

        // List Of Schedule Template Items
        const listOfScheduleTemplateItems = skinnyScheduleTemplate.scheduleTemplateItemDtos as IScheduleTemplateListItemDto[];

        // Group Specification Template Items by specGroupId

        this.listOfGroupedSpecTemplateItems = listOfScheduleTemplateItems.reduce((map, item) => {
            if (!map.has(item.specGroupId)) {
                map.set(item.specGroupId, []);
            }
            map.get(item.specGroupId)?.push(item);
            return map;
        }, new Map<number, IScheduleTemplateListItemDto[]>());

        // Map Schedule Template Item Parents to the Skinny list
        this.skinnyScheduleTemplateItems = listOfScheduleTemplateItems.map(x => ({ id: x.id, slotId: x.slotId, quantity: x.quantity }));

        // Map Schedule Template Item Childs to the Skinny list
        listOfScheduleTemplateItems.forEach(element => {
            const childs = element.childItems.map(x => ({ id: x.id, slotId: x.slotId, quantity: x.quantity }));
            this.skinnyScheduleTemplateItems = this.skinnyScheduleTemplateItems.concat(childs);
        });

        // Create a Backup of the Original Items for future reference (Cancel Changes)
        this.listOfGroupedSpecTemplateItemsBackup = new Map(this.listOfGroupedSpecTemplateItems);
        this.skinnyScheduleTemplateItemsBackup = cloneDeepSafe(this.skinnyScheduleTemplateItems);
    }

    // #region Expand Collapse Template Items


    public expandCollapseTemplatesAll = (): void => {
        this.setAllTemplatesExpanded();
        const newStatus = !this.allTemplatesExpanded;
        this.houseAreas.forEach((value) => {
            this.expandedTemplateAreas[value.id] = newStatus;
        });
        this.allTemplatesExpanded = newStatus;
    };


    public setAllTemplatesExpanded = (): void => {
        let expandedPanelsCount = 0;
        let panelCount = 0;
        this.houseAreas.forEach((houseArea) => {
            if (this.expandedTemplateAreas[houseArea.id]) {
                expandedPanelsCount++;
            }
            panelCount++;
        });

        if (expandedPanelsCount === panelCount) {
            this.allTemplatesExpanded = true;
        } else {
            this.allTemplatesExpanded = false;
        }
    };


    // #endregion Expand Collapse Template Items


    // #region Expand Collapse Schedule Items

    public expandCollapseItemAll = (): void => {
        this.setAllItemsExpanded();
        const newStatus = !this.allItemsExpanded;
        this.houseAreas.forEach((value) => {
            this.expandedItemAreas[value.id] = newStatus;
        });
        this.allItemsExpanded = newStatus;
    };

    public setAllItemsExpanded = (): void => {
        let expandedPanelsCount = 0;
        let panelCount = 0;
        this.houseAreas.forEach((houseArea) => {
            if (this.expandedItemAreas[houseArea.id]) {
                expandedPanelsCount++;
            }
            panelCount++;
        });

        if (expandedPanelsCount === panelCount) {
            this.allItemsExpanded = true;
        } else {
            this.allItemsExpanded = false;
        }
    };


    // #endregion Expand Collapse Schedule Items


    // #region Event Handlers


    /** Scroll to the House Area on the Specification Template when a  SIM Item is added  */
    public scrollToHouseArea(houseAreaId: number): void {
        try {
            const id = `#scrollTo${houseAreaId}`;
            const errorField = this.renderer.selectRootElement(id);
            errorField.scrollIntoView();
        } catch (err) { }
    }

    public handleItemRemoved($event: IRemovedData): void {

        if ($event.isParent) {

            // CAse : Parent To Remove > Will remove parent and all Required and Optional Childs associated

            // Get list of Child Spec Template Ids to remove
            const childSpecTemplateIds = this.listOfGroupedSpecTemplateItems.get($event.houseAreaId).find(x => x.slotId === $event.item.slotId).childItems.map((x) => x.slotId);

            // Remove Child Spec Template from SkinnyScheduleTemplateItems List
            childSpecTemplateIds.forEach(element => {
                const indexOfSkinnyChildToRemove = this.skinnyScheduleTemplateItems.map((x) => x.slotId).indexOf(element);
                if (indexOfSkinnyChildToRemove !== -1) {
                    this.skinnyScheduleTemplateItems.splice(indexOfSkinnyChildToRemove, 1);
                }
            });

            // Remove Parent Spec Template from SkinnyScheduleTemplateItems List
            const indexOfSkinnyParentToRemove = this.skinnyScheduleTemplateItems.map((x) => x.slotId).indexOf($event.item.slotId);
            if (indexOfSkinnyParentToRemove !== -1) {
                this.skinnyScheduleTemplateItems.splice(indexOfSkinnyParentToRemove, 1);
            }

            // Remove Spec Template Item from Spec Template Items > This will automatically Remove all childs too
            const indexOfTemplateItemToRemove = this.listOfGroupedSpecTemplateItems.get($event.houseAreaId).map((x) => x.slotId).indexOf($event.item.slotId);
            if (indexOfTemplateItemToRemove !== -1) {
                this.listOfGroupedSpecTemplateItems.get($event.houseAreaId).splice(indexOfTemplateItemToRemove, 1);
            }

        }

        else {

            // Case : OPtional Child To Remove

            let parentScheduleTemplateItem;

            // Case Removing a Fresh one that hasn't been saved before Reference With parentSlotId

            if ($event?.item?.parentSlotId > 0) {

                // Parent Of Optional Child To Remove
                parentScheduleTemplateItem = this.listOfGroupedSpecTemplateItems.get($event.houseAreaId)
                    .find(parent => parent.slotId === $event.item.parentSlotId);

            }

            // Case Removing a existing item Reference With parentScheduleTemplateItemId

            else {
                // Parent Of Optional Child To Remove
                parentScheduleTemplateItem = this.listOfGroupedSpecTemplateItems.get($event.houseAreaId)
                    .find(parent => parent.id === $event.item.parentScheduleTemplateItemId);
            }


            // Remove Child Spec Template Item from Spec Template Items
            const childToRemove = parentScheduleTemplateItem.childItems.find(scheduleTemplateChildItem => scheduleTemplateChildItem.slotId === $event.item.slotId);

            const indexOfChildToRemove = parentScheduleTemplateItem.childItems.indexOf(childToRemove);
            if (indexOfChildToRemove !== -1) {
                parentScheduleTemplateItem.childItems.splice(indexOfChildToRemove, 1);
            }

            // Remove Child Spec Template from SkinnyScheduleTemplateItems List
            const indexOfSkinnyItemToRemove = this.skinnyScheduleTemplateItems.map((scheduleTemplateItem) => scheduleTemplateItem.slotId).indexOf($event.item.slotId);
            if (indexOfSkinnyItemToRemove !== -1) {
                this.skinnyScheduleTemplateItems.splice(indexOfSkinnyItemToRemove, 1);
            }

        }

        // Enable Save and Cancel Buttons
        this.isSaveDisabled = false;
        this.isCancelDisabled = false;

    }


    public handleScheduleItemAdded($event: IAddedData): void {

        // Expand the House Area on Specification Template the item was added
        if (!this.expandedTemplateAreas[$event.houseAreaId]) {
            this.expandedTemplateAreas[$event.houseAreaId] = true;
        }

        // Scroll to the  House Area on Specification Template the item was added
        this.scrollToHouseArea($event.houseAreaId);

        // Check if item already exist on Specification Template List
        if (this.skinnyScheduleTemplateItems.some(x => x.slotId === $event.specTemplateItem.slotId)) {
            return;
        }

        // Initialise listOfGroupedSpecTemplateItems array if undefined
        if (this.listOfGroupedSpecTemplateItems.get($event.houseAreaId) === undefined) {
            this.listOfGroupedSpecTemplateItems.set($event.houseAreaId, []);
        }

        if ($event.isParent) {

            // Case: Parent To Add > Will add parent and all Required Childs associated

            // Add Spec Template Item to Spec Template Items > This will automatically all all childs too
            this.listOfGroupedSpecTemplateItems.get($event.houseAreaId).push($event.specTemplateItem);


            // Add Parent Spec Template to SkinnyScheduleTemplateItems List
            this.skinnyScheduleTemplateItems.push({
                id: 0,
                slotId: $event.specTemplateItem.slotId,
                quantity: $event.specTemplateItem.quantity
            });

            // Add Child Spec Templates to SkinnyScheduleTemplateItems List
            if ($event.specTemplateItem.childItems.length > 0) {
                $event.specTemplateItem.childItems.forEach(element => {
                    this.skinnyScheduleTemplateItems.push({
                        id: 0,
                        slotId: element.slotId,
                        quantity: element.quantity
                    });
                });
            }
        }

        else {

            // Case: Optional Child To Add

            // Parent Of Optional Child To Add
            const parentSpecTemplateItem = this.listOfGroupedSpecTemplateItems.get($event.houseAreaId)
                .find(templateItem => templateItem.slotId === $event.specTemplateItem.parentSlotId);


            // Add Child Spec Template Item to Parent Spec Template Item
            parentSpecTemplateItem.childItems.push($event.specTemplateItem);

            // Add Child Spec Template to SkinnyScheduleTemplateItems List
            this.skinnyScheduleTemplateItems.push({
                id: 0,
                slotId: $event.specTemplateItem.slotId,
                quantity: $event.specTemplateItem.quantity
            });

        }

        if (this.scheduleTemplateItemsTable) {
            this.scheduleTemplateItemsTable.handleScheduleItemAdded($event.specTemplateItem);
        }

        // Enable Save and Cancel Buttons
        this.isSaveDisabled = false;
        this.isCancelDisabled = false;
    }


    /** Cancels all your recent changes and shows the last fetched data in the interface  */
    public cancelChanges(): void {
        this.listOfGroupedSpecTemplateItems = new Map(this.listOfGroupedSpecTemplateItemsBackup);
        this.skinnyScheduleTemplateItems = cloneDeepSafe(this.skinnyScheduleTemplateItemsBackup);

        this.isSaveDisabled = true;
        this.isCancelDisabled = true;

    }

    public getPreview(): void {
        this.scheduleTemplatesLogicService.generatePreviewTemplate(this.scheduleTemplateId);
    }

    /** Saves all the changes and will show a toast Message on success   */
    public saveAll(): void {
        const skinnyScheduleTemplateToSave: IUpdateSkinnyScheduleTemplateDto = {
            id: this.scheduleTemplateId,
            scheduleTemplateItemDtos: this.skinnyScheduleTemplateItems
        };

        this.scheduleTemplatesLogicService.$saveItem(skinnyScheduleTemplateToSave).subOnce(skinnyScheduleTemplate => {

            // Show Toast Message on Save Success
            this.toastService.saveSuccess();

            this.initialiseData(skinnyScheduleTemplate);

            // Disable save / Cancel Button
            this.isSaveDisabled = true;
            this.isCancelDisabled = false;
        });
    }

    public generateReport(): void {
        this.scheduleTemplatesLogicService.generateReport(this.scheduleTemplateId).subOnce();
    }
    // #endregion Event Handlers
}

// #region Interfaces

interface IAddedData {
    houseAreaId: number;
    specTemplateItem: any;
    isParent: boolean;
}

interface IRemovedData {
    houseAreaId: number;
    item: any;
    isParent: boolean;
}

// #endregion Interfaces


