import {Component, Input, OnInit} from '@angular/core';
import {IPreConsentPlanMappedItem} from '@app/logic/pre-consent-plans';
import {Subscription} from 'rxjs';

@Component({
    selector: 'cb-pre-consent-side-bar-card',
    templateUrl: './pre-consent-side-bar-card.component.html',
    styleUrls: ['./pre-consent-side-bar-card.component.scss']
})
export class PreConsentSideBarCardComponent implements OnInit {
    private sub$ = new Subscription();
    private _mappedItem: IPreConsentPlanMappedItem;
    @Input() public set mappedItem(item: IPreConsentPlanMappedItem) {
        this._mappedItem = item;
        this.sub$.unsubscribe();
        if (this.mappedItem) {
            this.sub$ = this.mappedItem.$updated.subscribe(() => {
                this.getElapsedTime();
            });
        }
    }
    public get mappedItem(): IPreConsentPlanMappedItem {
        return this._mappedItem;
    }

    public elapsedTime: string;

    constructor() { }

    public ngOnInit(): void {
        this.getElapsedTime();
    }

    private getElapsedTime(): void {
        this.mappedItem.getElapsedTime().subOnce(x => this.elapsedTime = x);
    }

    public getHeading(): string {
        return this.mappedItem.planName ? `Pre-consent Plan ${this.mappedItem.planName}` : 'Pre-consent Plan';
    }
}
