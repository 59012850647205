<cb-dialog>
    <form class="flex-col flex"
          #documentTemplateForm="ngForm">
        <cb-input type="text"
                  name="name"
                  label="Template Name"
                  [readonly]="true"
                  [ngModel]="mappedItem.name">
        </cb-input>
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="history">
                <ng-container matColumnDef="fileName">
                    <mat-header-cell *matHeaderCellDef>
                        File Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.filename}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="uploadedBy">
                    <mat-header-cell *matHeaderCellDef>
                        Uploaded By
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.uploadedBy}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="dateUploaded">
                    <mat-header-cell *matHeaderCellDef>
                        Date Uploaded
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.dateUploaded | cbDate}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <button mat-icon-button
                                (click)="onDownloadClicked(item)">
                            <mat-icon>
                                file_download
                            </mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
