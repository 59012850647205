import { Injectable, Injector } from '@angular/core';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { cleanObject } from 'cb-hub-lib';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ISellingAgentDto } from './interfaces/i.sellingagent.dto';
import { ISellingAgentMappedItem } from './interfaces/i.sellingagent.mapped';
import { ISellingAgentsLogicService } from './interfaces/i.sellingagents.logic.service';
import { SellingAgentMappedItem } from './sellingagent.mapped';

export type SellingAgentSearchParams = {
    query: string;
    currentPage: number;
};


@Injectable()
export class SellingAgentsLogicService
    extends BaseLogicService<ISellingAgentDto, ISellingAgentMappedItem>
    implements ISellingAgentsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('sellingagents', SellingAgentMappedItem);
    }

    public $getSearchList(params: { currentPage: number; query: string }): Observable<any> {
        if (params.query) {
            return this.$http.get<any>(`${this.$baseUri}/search`, params);
        }
    }

    public search(params: Partial<SellingAgentSearchParams>, ignoreEmptyQueries = true): Observable<ISearchResult<ISellingAgentDto>> {
        if (ignoreEmptyQueries && (params.query == null || params.query.trim().length < 1)) {
            return;
        }
        return this.$http
            .get<ISearchResult<ISellingAgentDto>>(`${this.$baseUri}/search`, cleanObject(params));
    }
}
