<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="tooltipMessage.value">
            <mat-icon [ngClass]="cardIconClass.value">{{cardIcon.value}}</mat-icon>
        </div>
        <mat-card-title>{{stepNumber}}. Lot Details</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex">
        <ng-container [ngSwitch]="clientSale?.lot?.lotContractTypeId">
            <div class="flex-col"
                 *ngSwitchCase="LOT_CONTRACT_TYPE_ENUM.HouseAndLand">
                <cb-display-value-new label="Lot Number"
                                      [value]="clientSale?.lot?.lotNumber"></cb-display-value-new>
                <cb-display-value-new label="Project">
                    {{clientSale?.lot?.projectLabel}} > {{clientSale?.lot?.projectReleaseStageLabel}}
                </cb-display-value-new>
                <cb-display-value-new label="Job Number"
                                      [value]="clientSale?.lot?.jobNumber"></cb-display-value-new>
                <cb-display-value-new label="Job Status"
                                      [value]="clientSale?.lot?.jobStatusLabel"></cb-display-value-new>
                <cb-display-value-new label="Lot Type"
                                      [value]="clientSale?.lot?.lotContractTypeLabel"></cb-display-value-new>
                <cb-display-value-new label="Is Showhome"
                                      [value]="clientSale?.lot?.isShowHome | cbPrettyBool"></cb-display-value-new>
            </div>
            <div class="flex-col"
                 *ngSwitchDefault>
                <cb-display-value-new label="Job Number"
                                      [value]="clientSale?.lot?.jobNumber"></cb-display-value-new>
                <cb-display-value-new label="Has Title"
                                      [value]="clientSale?.lot?.hasTitle | cbPrettyBool"></cb-display-value-new>
                <cb-display-value-new *ngIf="!clientSale?.lot?.hasTitle"
                                      [ngClass]="!clientSale?.lot?.expectedTitleDate ? 'cb-error' : ''"
                                      label="Actual / Expected Title Date"
                                      [value]="clientSale?.lot?.expectedTitleDate | date"></cb-display-value-new>
                <cb-display-value-new label="DP Number"
                                      [value]="clientSale?.lot?.dpNumber"></cb-display-value-new>
                <cb-display-value-new label="Build Type"
                                      [value]="clientSale?.lot?.buildTypeLabel"></cb-display-value-new>
                <cb-display-value-new label="Lot Number"
                                      [value]="clientSale?.lot?.lotNumber"></cb-display-value-new>
                <cb-display-value-new label="Road Direction"
                                      [value]="clientSale?.lot?.roadDirectionLabel"></cb-display-value-new>
            </div>
        </ng-container>
    </mat-card-content>
    <mat-card-actions class="end-center flex-row layoutGap-16">
        <button mat-stroked-button
                (click)="viewLot($event)">
            <mat-icon svgIcon="card-search"></mat-icon>
            View Lot
        </button>
        <button mat-stroked-button
                color="accent"
                *ngIf="permissions?.canEdit()"
                [disabled]="!clientSale?.canSetSaleLost"
                (click)="openEditDialog()">
            <mat-icon>edit</mat-icon>
            Edit
        </button>
    </mat-card-actions>
</mat-card>
