import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface FilterChip {
    id: number | string;
    text: string;
}

@Component({
    selector: 'cb-filter-chip-list',
    templateUrl: './filter-chip-list.component.html',
    styleUrls: ['./filter-chip-list.component.scss']
})
export class FilterChipListComponent {
    @Input() public filterChips$: BehaviorSubject<Array<FilterChip>>;
    @Output() public updateDataBehindFilterChips = new EventEmitter<FilterChip[]>();

    constructor() { }


    public removeFilterChip(chip: FilterChip): void {
        const activeChips = this.filterChips$.value.filter(filterChip => filterChip.id !== chip.id);
        this.updateDataBehindFilterChips.emit(activeChips);
        this.filterChips$.next(activeChips);
    }
}
