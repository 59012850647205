<div class="flex-col flex"
     *cbWaitFor="lotDto$$ | async">
    <cb-lot-build-programme-action-bar [lotDto]="lotDto"
                                       class="cb-margin-left cb-margin-bottom"
                                       [fullscreen]="fullscreen"
                                       [hasAlerts]="hasAlerts"
                                       (lotDtoChange)="handleLotDtoChange($event)">
    </cb-lot-build-programme-action-bar>
    <cb-lot-build-programme-stage-list *ngIf="lotBuildProgrammeEvents.resolved$$ && lotDto?.hasBuildProgrammeGenerated && !backgrounded.value"
                                       [lotDto]="lotDto"
                                       class="stage-list-container"
                                       cbFillHeight
                                       [updateHeight]="lotBuildProgrammeEvents.BP_RECEIVED">
    </cb-lot-build-programme-stage-list>
</div>
