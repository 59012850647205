import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { INumberId } from '../../shared/interfaces/i.number-id';
import { BaseLogicService } from '../base/base-logic.service';
import { BuildStageMappedItem } from './build-stage.mapped';
import { IBuildStageDto } from './interfaces/i.build-stage.dto';
import { IBuildStageMappedItem } from './interfaces/i.build-stage.mapped';
import { IBuildStagesLogicService } from './interfaces/i.build-stages-logic.service';

@Injectable()
export class BuildStagesLogicService
    extends BaseLogicService<IBuildStageDto, IBuildStageMappedItem>
    implements IBuildStagesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildstages',
            BuildStageMappedItem,
        );
    }

    public saveOrder(addressRegions: INumberId[]): Observable<IBuildStageMappedItem[]> {
        const payload = addressRegions.map(x => ({ id: x.id }));

        return this.$http
            .post<Array<IBuildStageDto>>(`${this.$baseUri}/savesortorder`, payload)
            .pipe(
                map(items => items.map(x => this.$createMappedItem(x)))
            );
    }

    public getBuildStageList(lotId: number | string): Observable<Array<IBuildStageDto>> {
        return this.$http
            .get<Array<IBuildStageDto>>(`lot/${lotId}/buildstages`);
    }

    public getAllNonPreConsentStageBuildStages(): Observable<Array<IBuildStageDto>> {
        return this.$http
            .get<Array<IBuildStageDto>>(`${this.$baseUri}/nonpreconsentstage`);
    }
}
