<mat-expansion-panel class="task-card"
                     [ngClass]="taskStatusClass.value">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="task-card-icon">{{taskIconName.value}}</mat-icon>
            &nbsp;{{task.subject}}
        </mat-panel-title>
        <mat-panel-description>
            {{task.assignedToTeamId ? task.assignedToTeamName + ' - ' : ''}}{{task.assignedToName}}
            - {{task.isClosed ? 'completed' : 'due'}}
            {{taskRelativeDate.value}}
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex-col">
        <cb-display-value-new label="Due"
                              [value]="task.dueDate | date:'medium'"
                              *ngIf="!task.isClosed">
        </cb-display-value-new>
        <cb-display-value-new label="Location"
                              [value]="task.location"
                              *ngIf="task.isMeeting"></cb-display-value-new>
        <cb-display-value-new label="Comments"
                              [value]="task.comments"
                              *ngIf="task.comments && task.comments.length > 0"></cb-display-value-new>
        <div class="flex-col"
             *ngIf="task.isClosed">
            <cb-display-value-new label="Outcome"
                                  [value]="task.outcomeLabel"></cb-display-value-new>
            <cb-display-value-new label="Outcome Notes"
                                  [value]="task.outcomeNotes"></cb-display-value-new>
        </div>
        <div class="flex-col">
            <cb-display-value-new label="Created"
                                  *ngIf="task.createdDate && task.createdByName">
                {{task.createdByName}} on {{task.createdDate | date}}
            </cb-display-value-new>
            <cb-display-value-new label="Completed"
                                  *ngIf="task.isClosed && task.closedDate && task.updatedByName">
                {{task.updatedByName}} on {{task.closedDate | date}}
            </cb-display-value-new>
        </div>
        <mat-divider class="divider"
                     *ngIf="canComplete.value || canEdit.value"></mat-divider>
        <div class="footer-buttons end-center flex-row"
             *ngIf="canComplete.value || canEdit.value">
            <button mat-stroked-button
                    color="accent"
                    (click)="openCompleteDialog()"
                    *ngIf="canComplete.value">
                <mat-icon>done</mat-icon>
                &nbsp;Complete
            </button>
            <button mat-stroked-button
                    color="accent"
                    (click)="openEditDialog()"
                    *ngIf="canEdit.value">
                <mat-icon>mode_edit</mat-icon>
                &nbsp;Edit
            </button>
        </div>
    </div>
</mat-expansion-panel>
