import {Inject, Injectable, Injector} from '@angular/core';
import {BaseLogicService} from '../base/base-logic.service';
import {ContactMappedItem} from './contact.mapped';
import {HttpWrapperService} from '../../core/services/http-wrapper/http-wrapper.service';
import {IContactMappedItem} from './interfaces/i.contact.mapped';
import {IContactSearchDto} from './interfaces/i.contact-search.dto';
import {IContactSearchParams} from './interfaces/i.contact-search-params';
import {IContactsLogicService} from './interfaces/i.contacts-logic.service';
import {INoteMappedItem} from './../notes/interfaces/i.note.mapped';
import {ISearchResult} from '@app/shared/components/search/i.search';
import {Observable} from 'rxjs';
import {cleanObject} from 'cb-hub-lib';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {ILeadDto} from '../leads';
import {IBusinessAccountDto} from '../business-accounts';
import {IContactDto} from './interfaces/i.contact.dto';

@Injectable()
export class ContactsLogicService
    extends BaseLogicService<IContactDto, IContactMappedItem>
    implements IContactsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        @Inject(NavigationService) public readonly navigationService: NavigationService,
    ) {
        super(
            'contacts',
            ContactMappedItem,
        );
    }

    public getClientAccountsList(contactId: string): Observable<IBusinessAccountDto[]> {
        return this.$http
            .get(`${this.$baseUri}/${contactId}/accounts`);
    }

    public getBusinessAccountsList(contactId: string): Observable<IBusinessAccountDto[]> {
        return this.$http
            .get(`${this.$baseUri}/${contactId}/businessaccounts`);
    }

    public getLeadsList(contactId: string): Observable<ILeadDto[]> {
        return this.$http
            .get(`${this.$baseUri}/${contactId}/leads`);
    }

    public getNotesList(contactId: string): Observable<INoteMappedItem[]> {
        return this.$http
            .get(`${this.$baseUri}/${contactId}/notes`);
    }

    public search(params: IContactSearchParams, ignoreEmptyQueries = true): Observable<ISearchResult<IContactSearchDto>> {
        if (ignoreEmptyQueries && (params.query == null || params.query.trim().length < 1)) {
            return;
        }
        return this.$http
            .get<ISearchResult<IContactSearchDto>>(`${this.$baseUri}/search`, params);
    }


    public $getSearchList(params: IContactSearchParams): Observable<ISearchResult<IContactSearchDto>> {
        return this.$http.get<ISearchResult<IContactSearchDto>>(`${this.$baseUri}/search`, params);
    }

    public updatePortalLogin(params: { id: string; username: string; password: string }): Observable<IContactMappedItem> {
        return this.$http
            .post<IContactMappedItem>(`${this.$baseUri}/portalLogin/update`, params);
    }

    public updatePortalUsername(params: { contactId: string; username: string }): Observable<IContactMappedItem> {
        return this.$http
            .post<IContactMappedItem>(`${this.$baseUri}/portalLogin/update/username`, params);
    }

    public createPortalLogin(params: { contactId: string }): Observable<IContactMappedItem> {
        return this.$http
            .post<IContactMappedItem>(`${this.$baseUri}/portalLogin/create`, params);
    }

    public activatePortalAccess(params: { activate: boolean; contactId: string }): Observable<boolean> {
        return this.$http
            .post<boolean>(`${this.$baseUri}/portalLogin/activate`, params);
    }

    public activeDuplicatePortalUserExists(contactId: string): Observable<boolean> {
        return this.$http
            .get<boolean>(`${this.$baseUri}/${contactId}/ActiveDuplicatePortalUserExists`);
    }

    public findExistingByFullName(firstName: string, lastName: string): Observable<IContactSearchDto[]> {
        return this.$http.get<IContactSearchDto[]>(`${this.$baseUri}/findexisting/fullname`, cleanObject({firstName, lastName}));
    }

    public findExistingByMobilePhone(mobilePhone: string): Observable<IContactSearchDto[]> {
        return this.$http.get<IContactSearchDto[]>(`${this.$baseUri}/findexisting/mobilephone`, cleanObject({mobilePhone}));
    }

    public findExistingByHomePhone(homePhone: string): Observable<IContactSearchDto[]> {
        return this.$http.get<IContactSearchDto[]>(`${this.$baseUri}/findexisting/homephone`, cleanObject({ homePhone }));
    }

    public findExistingByEmailAddress(emailAddress: string): Observable<IContactSearchDto[]> {
        return this.$http.get<IContactSearchDto[]>(`${this.$baseUri}/findexisting/emailaddress`, cleanObject({ emailAddress }));
    }

    public viewContact(id: string): void {
        this.navigationService.navigate([`contacts/${id}/summary`]);
    }
}
