import {TitleCasePipe} from '@angular/common';
import {startCase} from 'lodash';
import {IUserCacheData, UserCacheItem} from './user-cache-item';
import {HttpWrapperService} from '../http-wrapper/http-wrapper.service';
import {CurrentUserService} from '@app/core/authentication/current.user';
import {Injectable, NgZone} from '@angular/core';
import {isNullOrWhiteSpace, toPromise, USER_CACHE_AREA, USER_CACHE_CATEGORY} from 'cb-hub-lib';

interface IDefineConfig<TData> {
    label: string;
    key: USER_CACHE_AREA;
    category: USER_CACHE_CATEGORY;
    defaultData: TData;
    permission?: () => boolean;
}

interface IUserCacheConfig {
    label: string;
    /** set to true once user cache item no longer placeholder data and is being loaded from the cache */
    created: boolean;
    key: USER_CACHE_AREA;
    category: USER_CACHE_CATEGORY;
    data: any;
    canAccess: () => boolean;
    promise: Promise<void>;
    init(): Promise<void>;
}

const titleCase = (str: string): string => new TitleCasePipe().transform(startCase(str));

@Injectable()
export class BaseUserCacheService {

    public resolved = false;
    /** array of all category enum values, ordered by label */
    public categories: USER_CACHE_CATEGORY[] = [];
    /** all user cache items (not categorised) */
    public allList: UserCacheItem<any>[] = [];
    /** category = USER_CACHE_CATEGORY, label = USER_CACHE_CATEGORY as titleCase, items = all user cache items under this category */
    public allCategorised: { [category: number]: { label: string; items: UserCacheItem<any>[] } } = {};
    /** dictionary of all user cache items, key = USER_CACHE_AREA */
    public all: { [userCacheItemKey: number]: IUserCacheConfig | UserCacheItem<any> } = new Proxy(
        {},
        {
            /** this setter is called when property is set on this.all, e.g. this.all[key] = something
             *
             * @param obj is this.all
             * @param prop is a USER_CACHE_AREA value, e.g. USER_CACHE_AREA.DesignTeamSchemesSearch = 1
             * @param value is the value that the obj[prop] is being set to, e.g. this.all[key] = value
             */
            set: (obj: { [key: number]: UserCacheItem<any> }, prop: string, value: any | UserCacheItem<any>): boolean => {
                obj[prop] = value.canAccess() && value;
                this.rePopulateBaseData(obj);
                return true;
            }
        }
    );

    /** this is the master dictionary of whether a UserCacheItem item is enabled or not
     *
     * @param userCacheItemKey key = USER_CACHE_AREA e.g. USER_CACHE_AREA.DesignTeamSchemesSearch \
     * value = boolean (whether a UserCacheItem is enabled or not)
     * @param promise the promise of loading the master object
     * @param resovled true if the master object has been loaded
     */
    public readonly master: {
        [userCacheItemKey: number]: boolean;
        promise: Promise<any>;
        resolved: boolean;
    } = {
            promise: new Promise((resolve) => this._masterResolver = resolve),
            resolved: false
        };
    private _masterId = 0;
    private readonly _masterKey = 'user_cache_area_master';
    private _masterResolver: (v?: any) => void;

    constructor(
        private readonly http: HttpWrapperService,
        private readonly currentUser: CurrentUserService,
        private readonly zone: NgZone
    ) {
        this.init();
    }

    private init(): void {
        this.runInZone(() => {
            this.loadMaster().then(() => {
                this.resolved = true;
            });
        });
    }

    /** re-populates all base data/arrays on the base user cache service  */
    private rePopulateBaseData(obj: { [key: number]: UserCacheItem<any> }): void {
        this.allList = Object.values(obj).sort((a, b) => a.label > b.label ? 1 : -1);
        this.allCategorised = {};
        this.allList.forEach(item => {
            if (this.allCategorised[item.category] == null) {
                this.allCategorised[item.category] = { label: titleCase(USER_CACHE_CATEGORY[item.category]), items: [] };
            }
            this.allCategorised[item.category].items.push(item);
        });
        this.categories = Object.keys(this.allCategorised).map(x => Number(x)).sort((a, b) => this.allCategorised[a].label > this.allCategorised[b].label ? 1 : -1);
    }

    /** loads master settings */
    public async loadMaster(): Promise<{ promise: Promise<any>;[key: number]: boolean }> {
        this.master.resolved = false;
        const result = await this.currentUser.$promise.then(() => toPromise(
            this.http
                .get<IUserCacheData>(`/usersetting/getusersetting/${this.currentUser.guid}/${this._masterKey}`)
        )) as IUserCacheData;
        if (!isNullOrWhiteSpace(result?.data)) {
            Object.assign(this.master, JSON.parse(result.data));
        }
        this._masterId = result.id;
        this._masterResolver();
        this.master.resolved = true;
        return this.master;
    }

    /** saves master settings */
    public async saveMaster(): Promise<{ promise: Promise<any>;[key: number]: boolean }> {
        this.master.resolved = false;
        const masterClone = { ...this.master };
        delete masterClone.resolved;
        delete masterClone.promise;
        const result = await this.currentUser.$promise.then(() => toPromise(
            this.http
                .post<IUserCacheData>(
                `/usersetting/${this._masterId}`,
                {
                    id: this._masterId,
                    key: this._masterKey,
                    userId: this.currentUser.guid,
                    data: JSON.stringify(masterClone)
                })
        )) as IUserCacheData;
        this._masterId = result.id;
        this._masterResolver();
        this.master.resolved = true;
        return this.master;
    }

    /** defines a basic mock UserSettingItem that uses a proxy to trigger the lazy loading of an actual UserSettingItem via this.create() */
    protected define<TSettingDef>(config: IDefineConfig<TSettingDef>): UserCacheItem<TSettingDef> {
        // eslint-disable-next-line prefer-const
        let revocable: {
            proxy: any;
            revoke: () => void;
        };
        let resolver: (v?: any) => void;
        let running = false;

        const defineHandler = {
            get: (obj: IUserCacheConfig | UserCacheItem<TSettingDef>, prop: string) => {
                if (running) {
                    this.all[config.key] = obj;
                    return obj[prop];
                }
                running = true;
                let key = null;
                this.runInZone(() => {
                    key = this.findItemOnService(config.key);
                    if (!this[key].created) {
                        // revoke and replace the proxy object with the actual settings class object
                        revocable.revoke();
                        this[key] = undefined;
                        obj = undefined;
                        delete this[key];
                        Object.defineProperty(this, key, {
                            enumerable: true,
                            value: this.create<TSettingDef>(config, this.all[config.key].promise, resolver)
                        });
                        this.all[config.key] = this[key];
                    }
                });
                return obj[prop];
            }
        };

        const placeholdUserCacheItem: IUserCacheConfig = {
            label: config.label,
            created: false,
            key: config.key,
            category: config.category,
            data: config.defaultData,
            canAccess: config.permission || (() => true),
            promise: new Promise((resolve: any) => {
                resolver = resolve;
            }),
            init: (): Promise<void> => {
                // trigger proxy getter to initialise full UserCacheItem
                defineHandler.get(this.all[config.key], Object.keys(config.defaultData)[0]);
                // call init on full UserCacheItem
                return new Promise<void>((resolve) => {
                    // run in zone because previous code may be running in zone as well
                    this.runInZone(() => {
                        if (this.all[config.key].created) {
                            return this.all[config.key].init().then(() => {
                                resolve();
                            });
                        }
                        // or return promise if UserCacheItem has not been created
                        return this.all[config.key].promise.then(() => {
                            resolve();
                        });
                    });
                });
            }
        };

        this.all[config.key] = placeholdUserCacheItem;
        revocable = Proxy.revocable(placeholdUserCacheItem, defineHandler);
        return revocable.proxy;
    }

    /** creates a UserSettingItem */
    private create<TSettingDef>(config: IDefineConfig<TSettingDef>, promise: Promise<any>, resolver: (v?: any) => void): UserCacheItem<TSettingDef> {
        const item = new UserCacheItem<TSettingDef>(
            this.http,
            this.currentUser,
            config.label,
            config.key,
            config.category,
            config.defaultData,
            config.permission,
            promise,
            resolver,
            this.master
        );
        item.created = true;
        return item;
    }

    /** runs a callback in NgZone - this prevents change detector ref errors */
    private readonly runInZone = (callback: () => any): void => {
        setTimeout(() => {
            if (this.zone) {
                this.zone.run(() => {
                    callback();
                });
            } else {
                callback();
            }
        });
    };

    /** find the property key for a UserCacheItem on this UserCacheService */
    private readonly findItemOnService = (configKey: USER_CACHE_AREA): string => {
        return Object.keys(this)
            .find((thisKey) => this[thisKey] != null && this[thisKey] instanceof Object && this[thisKey].key === configKey);
    };
}
