import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ISellingAgentDto } from './interfaces/i.sellingagent.dto';
import { ISellingAgentMappedItem } from './interfaces/i.sellingagent.mapped';
import { ISellingAgentsLogicService } from './interfaces/i.sellingagents.logic.service';

export class SellingAgentMappedItem
    extends BaseMappedItem<ISellingAgentDto, ISellingAgentMappedItem, ISellingAgentsLogicService>
    implements ISellingAgentMappedItem {
    @DtoProp public id: number;
    @DtoProp public legalEntityName: string;
    @DtoProp public legalName: string;
    @DtoProp public name: string;
    @DtoProp public tradingName: string;

    constructor(
        sourceData: ISellingAgentDto,
        logicService: ISellingAgentsLogicService
    ) {
        super(sourceData, logicService, SellingAgentMappedItem);
    }
}
