export class UtilFiles {
    public static isExcelFile = /(?:\.([^.]+))?\.(xlsx|xlsm|csv)/i;
    public static isImageFile = /^(?:\w?\s?&?\-?\(?\)?)+\.(jpg|jpeg|png|gif|bmp)$/i;

    public static getReadableFileSizeString(fileSizeInBytes: number): string {
        let i = -1;
        const byteUnits = [' kB', ' MB', ' GB', ' TB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }
}
