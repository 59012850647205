import { Pipe, PipeTransform } from '@angular/core';
import { DateFormats } from '@app/shared/declarations/date-formats.constants';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import moment from 'moment';

/** Use this cbDate pipe instead of the default angular "date" pipe */
@Pipe({
    name: 'cbDate'
})
export class DatePipe implements PipeTransform {
    public transform(value: string, format = DateFormats.DEAULT_DATE): string {
        if (isNullOrWhiteSpace(value)) {
            return '-';
        }
        return moment(value).format(format);
    }
}
