import { InputFindType } from '../input-find-type.enum';
import { Directive, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { Subscription } from 'rxjs';

interface IData {
    mappedItem: any;
    searchService: SearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

@Directive()
export abstract class BaseInputFindDialogDirective<LogicServiceType> implements OnDestroy {
    @ViewChild('scrollContainer', { static: true }) public scrollContainer: any;
    private sub: Subscription;

    public buttonClick: () => void;
    public buttonLabel: string;

    public mappedItem: any;
    public searchService: SearchService;
    public extraSearchParams: { [key: string]: any };
    public dialogHeading: string;
    public contentHeading: string;
    public placeholder: string;

    public readonly INPUT_FIND_TYPE = InputFindType;
    constructor(
        public readonly dialogRef: MatDialogRef<BaseInputFindDialogDirective<LogicServiceType>>,
        public readonly data: IData,
        protected readonly logicService: LogicServiceType,
        public cdRef: ChangeDetectorRef,
        public type: InputFindType
    ) {
        this.mappedItem = data.mappedItem;
        this.searchService = data.searchService;
        this.extraSearchParams = data.extraSearchParams;
        this.dialogHeading = data.dialogHeading;
        this.contentHeading = data.contentHeading;
        this.placeholder = data.placeholder;
        this.sub = this.searchService.searchResults$.subscribe();
    }

    public ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    public dataScrolled(): void {
        this.data.searchService.nextPage$.next({ addPage: 1 });
    }

    public selectionChosen(selection: any): void {
        this.dialogRef.close(selection);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
