<mat-card class="start-center flex-row">
    <button *ngIf="canAddChange()"
            class="cb-margin-left"
            mat-raised-button
            color="primary"
            (click)="createChange()"
            [disabled]="currentUserTeams.length < 1">
        Add Change
    </button>
    <span *ngIf="numberofPendingChangeRecords > 0">
        <button *ngIf="canSubmitChangeForPricing()"
                (click)="submitChanges()"
                class="cb-margin-left"
                mat-raised-button
                color="primary">
            Submit For Pricing ({{numberofPendingChangeRecords}})
        </button>
    </span>
    <button *ngIf="canCreateVariation()"
            [disabled]="!canGenerateVariations()"
            class="cb-margin-left"
            mat-raised-button
            color="primary"
            (click)="openGenerateVariationDialog($event)">
        Generate Variation ({{$numberofUnassignedChangeRecords}})
    </button>
    <button *ngIf="canGenerateChangesSignOffDocument()"
            [disabled]="!isGenerateChangesSignOffDocumentEnabled()"
            class="cb-margin-left"
            mat-raised-button
            color="primary"
            (click)="generateChangesSignOffDocument()">
        Generate Changes Signoff Document
    </button>
</mat-card>
