import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { Observable } from 'rxjs';
import { IResourceConsentMappedItem, IBuildingConsentMappedItem } from '@app/logic/lot-consents';

interface IData {
    mappedItem: any;
}

@Component({
    selector: 'cb-edit-consent-number-dialog',
    templateUrl: './edit-consent-number-dialog.component.html',
    styleUrls: ['./edit-consent-number-dialog.component.scss']
})
export class EditConsentNumberDialogComponent extends BaseDialogFormViewDirective<any, any, any> {
    public static readonly MIN_WIDTH = '33%';
    public get mappedItem(): IResourceConsentMappedItem | IBuildingConsentMappedItem {
        return this.data.mappedItem;
    }
    constructor(
        public readonly dialogRef: MatDialogRef<EditConsentNumberDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
    }

    public saveMethod(): Observable<any> {
        return this.mappedItem.$save();
    }
}
