import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { ILotMappedItem } from '@app/logic/lots';
import { BehaviorSubject } from 'rxjs';
import { LOTS_ROUTE } from '../../lots-route.constant';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

@Component({
    selector: 'cb-lot-design-area',
    templateUrl: './lot-design-area.component.html',
    styleUrls: ['./lot-design-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class LotDesignAreaComponent {

    public readonly LOTS_ROUTE = LOTS_ROUTE;
    public selectedTabIndex: number;

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);

    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    @Output() public reloadLotMappedItem = new EventEmitter();

    constructor(
        public readonly cdRef: ChangeDetectorRef,
        public readonly featureToggleStateService: FeatureToggleStatesService,
    ) { }

    public onTabChanged(selectedTabIndex: number): void {
        this.selectedTabIndex = selectedTabIndex;
    }

    public isRFIsTabDisabled(): boolean {
        return (!this.lotMappedItem.canCreateRFI && !this.lotMappedItem.hasCouncilRfi);
    }

    public isPreConsentTabDisabled(): boolean {
        return !(this.lotMappedItem.activeDesignConceptId > 0);
    }

    public isWorkingDrawingsTabDisabled(): boolean {
        return !(this.lotMappedItem.hasCompletedAPreConsentPlan && this.lotMappedItem.allPreconsentDocumentsUploaded);
    }

    public lotMappedItemChanged(): void {
        this.lotMappedItem.$reload().subOnce(() => {
            this.cdRef.detectChanges();
        });
    }

}
