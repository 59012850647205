import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';
import { Component, Input } from '@angular/core';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';

@Component({
    selector: 'cb-text-area',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(TextAreaComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class TextAreaComponent extends BaseFormComponentDirective {
    public static readonly DEFAULT_MAXLENGTH = 1024;

    private _maxlength = TextAreaComponent.DEFAULT_MAXLENGTH;
    @Input() public set maxlength(maxlength: number) {
        this._maxlength = maxlength;
    }
    public get maxlength(): number {
        return this._maxlength || TextAreaComponent.DEFAULT_MAXLENGTH;
    }

    @Input() public rows: number;

    @Debounce(300)
    public emitChange(): void {
        super.emitChange();
    }

    public valueSetter(v: any): { value: any; modelValue: any } {
        return {
            value: v,
            modelValue: v
        };
    }

    public getCharCount(): string {
        if (this.value) {
            return `${this.value.length} / ${this.maxlength}`;
        } else {
            return `0 / ${this.maxlength}`;
        }
    }
}
