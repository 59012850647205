import { ISelectListItem } from '@classictechsolutions/typescriptenums';
export const WHOLE_OPTION_VALUE_PROP = 'WHOLE_OPTION_VALUE_PROP';

/** Return if two select options are equal */

export interface ISelectComparisonStrategy<OptionType = ISelectListItem<number>> {
    compareSelectOptions(optionOne: OptionType, optionTwo: OptionType): boolean;
}

export class DefaultOptionComparisonStrategy implements ISelectComparisonStrategy {
    constructor(public valueProp) {
        this.valueProp = valueProp || 'id';
    }

    public compareSelectOptions(optionOne: ISelectListItem<number>, optionTwo?: ISelectListItem<number>): boolean {
        if (optionTwo !== undefined) {
            return optionOne[this.valueProp] === optionTwo[this.valueProp];
        } else {
            return optionOne[this.valueProp] === undefined;
        }
    }
}

export class PrimitveComparisonStrategy implements ISelectComparisonStrategy<string | number> {
    public compareSelectOptions(optionOne, optionTwo): boolean {
        // Sometime option 2 has been an object
        return (optionOne?.id ?? optionOne) === (optionTwo?.id ?? optionTwo);
    }
}

/** Passed to a <Select> component to compare if two options are equal */
export class SelectComparator {

    constructor(public wholeOptionComparisonProp, public valueProp) {
        this.wholeOptionComparisonProp = wholeOptionComparisonProp || 'id';
        this.valueProp = valueProp || 'id';
    }

    /** This result is passed to a <Select> component to compare if two options are equal */
    public compareSelectOptions = (optionOne: ISelectListItem<number>, optionTwo: ISelectListItem<number>): boolean => {
        const strategyComparison = this.strategyFactory(optionOne);
        return strategyComparison?.compareSelectOptions(optionOne, optionTwo);
    };

    private strategyFactory(optionOne): ISelectComparisonStrategy {
        if (optionOne !== undefined && optionOne !== null) {

            if (typeof optionOne === 'number' || typeof optionOne === 'string') {
                return new PrimitveComparisonStrategy();
            }

            if (this.valueProp === WHOLE_OPTION_VALUE_PROP) {
                return new DefaultOptionComparisonStrategy(this.wholeOptionComparisonProp);
            }

            return new DefaultOptionComparisonStrategy(this.valueProp);
        }
    }
}
