import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILotSpecColourItemMappedItem } from '@app/logic/lot-spec-colour-item';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { ILotSpecColourItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IHaveLotSpecColourItemUiActions, LotSpecColourItemUIActions } from '../lot-spec-colour-item-card/lot-spec-colour-item-ui-actions';
import { ColWidthSyncService } from '../services/col-width-sync.service';

@Component({
    selector: 'cb-lot-spec-colour-item-table-row',
    templateUrl: './lot-spec-colour-item-table-row.component.html',
    styleUrls: ['./lot-spec-colour-item-table-row.component.scss'],
    providers: [
        LotSpecColourItemUIActions,
    ]
})
export class LotSpecColourItemTableRowComponent implements IHaveLotSpecColourItemUiActions {
    @Input() public readonly lotSpec: ILotSpecMappedItem;
    @Input() public readonly mappedItem: ILotSpecColourItemMappedItem;
    @Output() public readonly itemDeleted = new EventEmitter<ILotSpecColourItemDto>();
    @Output() public readonly itemEdited = new EventEmitter<ILotSpecColourItemDto>();

    constructor(
        public readonly uiActions: LotSpecColourItemUIActions,
        /** provided by LotSpecItemsListComponent */
        public readonly colWidthSyncService: ColWidthSyncService,
    ) {
        this.uiActions.setController(this);
    }
}
