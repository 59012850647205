<ng-container *cbWaitFor="lotMappedItem$$ | async">
    <cb-design-scheme-search *ngIf="!selectedDesignScheme"
                             [lotMappedItem]="lotMappedItem"
                             (designSchemeSelected)="designSchemeSelected($event)">
    </cb-design-scheme-search>
    <cb-manage-scheme-view *ngIf="selectedDesignScheme"
                           [mappedItem]="selectedDesignScheme"
                           [lotMappedItem]="lotMappedItem"
                           (goBack)="handleGoBack()">
    </cb-manage-scheme-view>
</ng-container>
