import {Component, Input} from '@angular/core';

@Component({
    selector: 'cb-display-pill',
    templateUrl: './display-pill.component.html',
    styleUrls: ['./display-pill.component.scss']
})
export class DisplayPillComponent {
    @Input() public displayPillText: string;
}
