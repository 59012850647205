import {
    IIdAndLabelDto, IInsuranceDto, ILocationDetailsDto, IProjectReleaseStageDefaultSupplierDto, IProjectReleaseStageDefaultProductSupplierDto,
    IProjectReleaseStageDto, IStringIdAndLabelDto, LandTitleStatusEnumId, LotContractTypeEnumId, MarketingTypeEnumId, ProjectReleaseStageContractTypeEnumId,
    ProjectReleaseStageTypeEnumId, ProjectReleaseStatusEnumId, PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM, PROJECT_RELEASE_STAGE_TYPE_ENUM, PROJECT_RELEASE_STATUS_ENUM,
    ShowHomeLevelEnumId, SHOW_HOME_LEVEL_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { startCase } from 'lodash';
import { Observable } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IReleaseStageMappedItem } from './interfaces/i.release-stage.mapped';
import { IReleaseStagesLogicService } from './interfaces/i.release-stages.logic.service';

export class ReleaseStageMappedItem
    extends BaseMappedItem<IProjectReleaseStageDto, IReleaseStageMappedItem, IReleaseStagesLogicService>
    implements IReleaseStageMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public projectId: number;
    @DtoProp public isActiveStage: boolean;
    @DtoProp public releaseId: string;
    @DtoProp public name: string;
    @DtoProp public type: ProjectReleaseStageTypeEnumId;
    @DtoProp public releaseStatus: ProjectReleaseStatusEnumId;
    @DtoProp public contractType: ProjectReleaseStageContractTypeEnumId;
    @DtoProp public dueDate: string;
    @DtoProp public dueDateObject: Date;
    @DtoProp public createdDate: string;
    @DtoProp public insuranceInterestedParty: string;
    @DtoProp public insuranceInterestedPartyEmail: string;
    @DtoProp public lotsHaveTitle: boolean;
    @DtoProp public lotTitleDate: string;
    @DtoProp public lotTitleDateObject: Date;
    @DtoProp public lotTitleDateConfirmed: boolean;
    @DtoProp public landTitleStatus: LandTitleStatusEnumId;
    @DtoProp public tenderDueDate: string;
    @DtoProp public tenderDueDateObject: Date;
    @DtoProp public marketingType: MarketingTypeEnumId;
    @DtoProp public marketReleaseDate: string;
    @DtoProp public marketReleaseDateObject: Date;
    @DtoProp public showHomeCompletionDate: string;
    @DtoProp public showHomeDateObject: Date;
    @DtoProp public showHomeDate: string;
    @DtoProp public showHomeLevel: ShowHomeLevelEnumId;
    @DtoProp public totalLots: number;
    @DtoProp public numberSold: number;
    @DtoProp public numberAvailable: number;
    @DtoProp public vendorClientAccountId: number;
    @DtoProp public vendorBusinessEntityId: number;
    @DtoProp public vendorClientAccount: IIdAndLabelDto;
    @DtoProp public agentBusinessEntity: IIdAndLabelDto;
    @DtoProp public agentBusinessAccountId: number;
    @DtoProp public agentBusinessEntityId: number;
    @DtoProp public agentBusinessAccount: IIdAndLabelDto;
    @DtoProp public vendorBusinessEntity: IIdAndLabelDto;
    @DtoProp public defaultSuppliers: IProjectReleaseStageDefaultSupplierDto[];
    @DtoProp public defaultProductSuppliers: IProjectReleaseStageDefaultProductSupplierDto[];
    @DtoProp public projectLocation: ILocationDetailsDto;
    @DtoProp public businessEntityId: number;
    @DtoProp public isProjectLevelInsurance: boolean;
    @DtoProp public insurance: IInsuranceDto;
    @DtoProp public allowedLotContractTypes: number[];
    @DtoProp public houseAndLandCoordinatorId: string;
    @DtoProp public lockedContractTypes: LotContractTypeEnumId;
    @DtoProp public availableHouseAndLandCoordinators: IStringIdAndLabelDto[];
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;

    constructor(
        sourceData: IProjectReleaseStageDto,
        logicService: IReleaseStagesLogicService
    ) {
        super(sourceData, logicService, ReleaseStageMappedItem,
            {
                insurance: {} as IInsuranceDto
            });
    }

    public get noteEntityId(): number {
        return this.id;
    }

    public get taskEntityId(): number {
        return this.id;
    }

    public get noteEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get taskEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get historyEntityUri(): string {
        return this.$logicService.$baseUri;
    }


    public get vendorDisplay(): string {
        if (this.vendorClientAccount) {
            return this.vendorClientAccount.label;
        } else if (this.vendorBusinessEntity) {
            return this.vendorBusinessEntity.label;
        }
    }

    public get agentDisplay(): string {
        if (this.agentBusinessAccount) {
            return this.agentBusinessAccount.label;
        } else if (this.agentBusinessEntity) {
            return this.agentBusinessEntity.label;
        }
        return null;
    }

    public get vendorValid(): boolean {
        return this.type !== PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand || !!this.vendorDisplay;
    }

    public get isShowHomeSelected(): boolean {
        return this.showHomeLevel !== SHOW_HOME_LEVEL_ENUM.NoShowHome;
    }

    public isCancelled(): boolean {
        return this.releaseStatus === PROJECT_RELEASE_STATUS_ENUM.Cancelled;
    }

    public addAllDefaultInstallers(releaseId: number): Observable<IProjectReleaseStageDefaultSupplierDto> {
        return this.$logicService.addAllDefaultInstallers(releaseId);
    }

    public addAllDefaultSuppliers(releaseId: number): Observable<IProjectReleaseStageDefaultSupplierDto> {
        return this.$logicService.addAllDefaultSuppliers(releaseId);
    }

    private getContractType(): ProjectReleaseStageContractTypeEnumId {
        return this.type === PROJECT_RELEASE_STAGE_TYPE_ENUM.DesignAndBuild ?
            PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM.External :
            PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM.ClassicStandard;
    }

    public getContractTypeDisplay(): string {
        const type = this.getContractType();
        return this.getContractType() ? startCase(PROJECT_RELEASE_STAGE_CONTRACT_TYPE_ENUM[type]) : 'Please choose Release Type to see required Contract Type';
    }
}
