import { CommonModule } from '@angular/common';
import { LazyLoadImageDirective } from './lazy-load-img.directive';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        LazyLoadImageDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        LazyLoadImageDirective
    ]
})
export class CbLazyLoadImageModule { }
