<div class="flex-col">
    <div class="cb-margin flex-row"
         style="align-items: center;">
        <div class="flex-col flex-10">
            <mat-icon [ngClass]="getStatusIconClass()"
                      class="help-cursor"
                      [title]="getStatusIconTitle()">
                {{getStatusIconIcon()}}
            </mat-icon>
            <mat-icon *ngIf="notifyQsIfSelected()"
                      title="Notify Qs">sms</mat-icon>
        </div>
        <div class="flex-col flex-40">
            <span class="flex">{{changeOption.description}}</span>
            <span *ngIf="provisionalCost > 0 && !changeRecordMappedItem.isExtraToClient">
                Includes {{provisionalCost|cbCurrency}} of Provisional Cost</span>
            <span *ngIf="provisionalCost > 0 && changeRecordMappedItem.isExtraToClient">
                Includes {{provisionalCost|cbCurrency:'addGst'}} of Provisional
                Cost</span>
        </div>
        <div class="flex-col flex-40"
             *ngIf="isChangeRecordPending()">
            <span>Option {{changeOption.optionNumber}}</span>
        </div>
        <div class="flex-col flex-40"
             *ngIf="!isChangeRecordPending() && changeRecordMappedItem.isExtraToClient">
            <span>
                Option {{changeOption.optionNumber}}
                <b [class.is-credit]="changeOption.onCharge < 0">{{changeOption.onCharge | cbCurrency:'addGst'}} (Inc
                    Gst)</b>
            </span>
        </div>
        <div class="flex-col flex-40"
             *ngIf="!isChangeRecordPending() && !changeRecordMappedItem.isExtraToClient">
            <span>
                Option {{changeOption.optionNumber}}
                <b [class.is-credit]="changeOption.onCharge < 0">{{changeOption.onCharge | cbCurrency}}</b>
            </span>
        </div>
        <div class="flex-col flex-10">
            <button [matMenuTriggerFor]="menu"
                    mat-icon-button
                    [disabled]="isMenuDisabled()">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item
                        [disabled]="changeOption.isSelectedChangeOption || !isChangeRecordResponseRequired()"
                        (click)="setAcceptedOptionClicked()">
                    <mat-icon>check</mat-icon>
                    Accept Option
                </button>
                <button mat-menu-item
                        (click)="editOption()">
                    <mat-icon>edit</mat-icon>
                    {{isOptionSelectedOrChangeCancelled() ? 'View' : 'Edit'}} Option
                </button>
                <button mat-menu-item
                        [disabled]="removeChangeOptionDisabled()"
                        (click)="removeOption(changeOption)">
                    <mat-icon>remove_circle_outline</mat-icon>
                    Remove Option
                </button>
            </mat-menu>
        </div>
    </div>
    <mat-divider [inset]="false"
                 class="cb-margin-bottom-2x">
    </mat-divider>
</div>
