import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseFormViewDirective } from './base-form-view.directive';
import { IBaseMappedItem } from '@app/logic/base/interfaces/i.base-mapped-item';
import { OnInit, Directive } from '@angular/core';
import { FormMode } from '@app/shared/enums/form';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Directive()
export abstract class BaseDialogFormViewDirective<DtoType, MappedItemType extends IBaseMappedItem<DtoType, MappedItemType, LogicServiceType>, LogicServiceType>
    extends BaseFormViewDirective<DtoType, MappedItemType, LogicServiceType> implements OnInit {
    constructor(
        public readonly dialogRef: MatDialogRef<any>,
        toastSerivce: ToastService,
        /** required for cancel confirmation */
        private readonly _cbDialog?: CbDialogService,
    ) {
        super(toastSerivce);
    }

    public ngOnInit(): void {
        if (!this.mappedItem?.$id) {
            this.formMode = FormMode.Add;
        } else {
            this.formMode = FormMode.Edit;
        }
    }

    protected handleNext = (x: any): void => {
        this.toastSerivce.saveSuccess();
        this.dialogRef.close(x);
    };

    protected handleError = (x: any): void => {
        this.toastSerivce.saveError(x);
    };

    public cancel(requireConfirmation = false): void {
        if (requireConfirmation && this._cbDialog) {
            this._cbDialog.confirm({
                dialogHeading: 'Confirm Cancel',
                message: 'Are you sure you want to cancel? Your changes will be lost.',
                confirmed: () => this.dialogRef.close(false),
            });
        } else {
            this.dialogRef.close(false);
        }
    }
}
