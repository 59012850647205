import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgModelChangeDebouncedDirective } from './model-debounce.directive';


@NgModule({
    declarations: [NgModelChangeDebouncedDirective],
    exports: [NgModelChangeDebouncedDirective],
    imports: [
        CommonModule
    ]
})
export class CbModelDebounceModule { }
