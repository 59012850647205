import { Component, Input } from '@angular/core';
import { DashboardBaseWidgetDirective, IItemRequiringActionDto } from '../dashboard-base-widget/dashboard-base-widget.directive';

@Component({
    selector: 'cb-dashboard-widget-action-list',
    templateUrl: './dashboard-widget-action-list.component.html',
    styleUrls: ['./dashboard-widget-action-list.component.scss']
})
export class DashboardWidgetAcitonListComponent extends DashboardBaseWidgetDirective<IItemRequiringActionDto[]> {
    @Input() public overrideLabel: boolean;
    @Input() public displayStatus: boolean;
    @Input() public showDueDate: boolean;

    public get formattedLabel(): string {
        return this.overrideLabel ? this.label : `${this.label || 'Items'} Requiring Action`;
    }
}
