import {
    ILotSpecScheduleManageAttributeItemDto,
    ILotSpecScheduleRestrictionDto
} from '@classictechsolutions/hubapi-transpiled-enums';

import { DtoProp } from '../base/dto-prop.decorator';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ILotScheduleItemAttributeNoteDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/LotScheduleItemAttributeNoteDto';
import { ILotSpecScheduleManageAttributeItemMappedItem } from './interfaces/i.lot-spec-schedule-manage-attribute-item.mapped';
import { ILotSpecScheduleManageAttributeItemLogicService } from './interfaces/i.lot-spec-schedule-manage-attribute-item-logic.service';

export class LotSpecScheduleManageAttributeItemMappedItem
    extends BaseMappedItem<
    ILotSpecScheduleManageAttributeItemDto,
    ILotSpecScheduleManageAttributeItemMappedItem,
    ILotSpecScheduleManageAttributeItemLogicService>
    implements ILotSpecScheduleManageAttributeItemMappedItem {

    @DtoProp public id: number;
    @DtoProp public specGroupId: number;
    @DtoProp public specGroup: string;
    @DtoProp public specGroupOrder: number;
    @DtoProp public sortOrder: number;
    @DtoProp public slotName: string;
    @DtoProp public offeringName: string;
    @DtoProp public offeringId: number;
    @DtoProp public lotSpecScheduleItemVersionId: number;
    @DtoProp public manualColourEntryRequired: boolean;
    @DtoProp public manualColourName: string;
    @DtoProp public manualColourNotes: string;
    @DtoProp public attributeNotes: ILotScheduleItemAttributeNoteDto[];
    @DtoProp public options: ILotSpecScheduleRestrictionDto[];
    @DtoProp public restrictions: ILotSpecScheduleRestrictionDto[];

    public $hasChanged = false;
    public $notesRowSpan: number;

    constructor(
        sourceData: ILotSpecScheduleManageAttributeItemDto,
        logicService: ILotSpecScheduleManageAttributeItemLogicService
    ) {
        super(sourceData, logicService, LotSpecScheduleManageAttributeItemMappedItem);

        const hasAttributes = this.restrictions !== undefined;

        if (hasAttributes) {
            this.restrictions.forEach(a => {
                a.options.unshift({ id: undefined, name: 'None' } as any);
            });
        }

        const itemsCount = hasAttributes ? this.restrictions.length : 0;
        this.$notesRowSpan = itemsCount + (this.manualColourEntryRequired ? 2 : 1);
    }

    /** returns truthy if this item has an empty attribute or colour field */
    public anyEmptyRestrictions(): boolean {
        let anyEmptyRestrictions = this.restrictions !== undefined
        && this.restrictions !== null
            && this.restrictions?.some(restriction =>
                // if there are no valid/selectable options this should not be considered an empty attribute
                restriction?.options?.filter(opt => opt.id > 0).length > 0
                // if there are valid/selectable options then check that an option has been selected
                && (restriction?.selectedOptionId === undefined
                    || restriction?.selectedOptionId === 0));

        if (anyEmptyRestrictions === false && this.manualColourEntryRequired) {
            anyEmptyRestrictions = !this.manualColourName || this.manualColourName?.trim().length < 1;
        }

        return anyEmptyRestrictions;
    }
}
