<div class="cb-margin flex-col">
    <div class="flex-row">
        <ng-container *ngIf="productCards">
            <cb-item-usage-summary-card class="flex"
                                        *ngFor="let productCard of productCards"
                                        [label]="productCard.label"
                                        [value]="productCard.value">
            </cb-item-usage-summary-card>
        </ng-container>
    </div>
</div>
