import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IBuildActivityMappedItem } from '@app/logic/build-activities/interfaces/i.build-activity.mapped';
import { ISkinnyOfferingDto } from '@app/logic/offerings';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { BUILD_ACTIVITY_REQUIRED_LEVEL_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { CbEnumService, SelectListItem } from 'src/app/core/services/enum/enum.service';

export interface BuildActivitiesExtraSearchFilters {
    activityCode: string;
    activityName: string;
}

@Component({
    selector: 'build-activity-details',
    templateUrl: './build-activity-details.component.html',
    styleUrls: ['./build-activity-details.component.scss'],
    providers: [
        UsersLogicService,
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class BuildActivityDetailsComponent implements OnInit {
    public formMode: FormMode;
    public buildActivityRequiredLevels: Array<SelectListItem>;
    public isView: () => boolean;
    public isEdit: () => boolean;
    public productAlreadyAdded = false;
    @ViewChild('autocompleteControl', {}) public autocompleteControl: any;
    @ViewChild(MatTable, {}) public table: MatTable<any>;
    @Input() public buildActivity: IBuildActivityMappedItem;
    @Input() public buildActivityForm: NgForm;
    public selectedProduct: ISkinnyOfferingDto;
    public selectedProductModel: ISkinnyOfferingDto;

    public taskAnalysisFormRequired = false;
    public readonly displayedColumns: string[] = [
        'rate',
        'actions'
    ];

    constructor(
        public readonly navigationService: NavigationService,
        private readonly enumService: CbEnumService,
        public readonly currentUser: CurrentUserService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: MatDialog,
        public controlContainer: ControlContainer,
    ) {
        this.formMode = FormMode.View;
        this.buildActivityRequiredLevels = BUILD_ACTIVITY_REQUIRED_LEVEL_ENUM.toSelectList();
    }

    public productSelected(value: ISkinnyOfferingDto | void): void {
        if (value) {
            this.selectedProduct = value;
        }
    }

    public displayFn(item): string {
        return `${item.code} - ${item.name}`;
    }

    public ngOnInit(): void {
        if (this.buildActivity.taskAnalysisFormRequiredLevel > 0) {
            this.taskAnalysisFormRequired = true;
        }

        if (!this.taskAnalysisFormRequired) {
            this.buildActivity.taskAnalysisFormRequiredLevel = undefined;
        }
    }

    public incrementVersion(): void {
        this.buildActivity.incrementVersion();
        this.buildActivityForm.form.markAsDirty();
    }

    public taskAnalysisChanged(): void {
        if (!this.taskAnalysisFormRequired || this.buildActivity.taskAnalysisFormRequiredLevel === 0) {
            this.buildActivity.taskAnalysisFormRequiredLevel = undefined;
        }
    }
}
