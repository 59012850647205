import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';

@Component({
    selector: 'cb-inline-text-area',
    templateUrl: './inline-text-area.component.html',
    styleUrls: ['./inline-text-area.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(InlineTextAreaComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class InlineTextAreaComponent extends BaseFormComponentDirective {
    public static readonly DEFAULT_MAXLENGTH = 1024;

    @Output() public readonly saved = new EventEmitter();

    private _maxlength = InlineTextAreaComponent.DEFAULT_MAXLENGTH;
    @Input() public set maxlength(maxlength: number) {
        this._maxlength = maxlength;
    }
    public get maxlength(): number {
        return this._maxlength || InlineTextAreaComponent.DEFAULT_MAXLENGTH;
    }

    public initialValue: string;
    public editingComment: boolean;

    private _isEditDisabled: boolean;
    @Input() public set isEditDisabled(isDisabled: boolean) {
        this._isEditDisabled = isDisabled;
    }

    public get isEditDisabled(): boolean {
        return this._isEditDisabled;
    }

    @Input() public rows: number;

    @Debounce(300)
    public emitChange(): void {
        super.emitChange();
    }

    public valueSetter(v: any): { value: any; modelValue: any } {
        return {
            value: v,
            modelValue: v
        };
    }

    public getCharCount(): string {
        if (this.value) {
            return `${this.value.length} / ${this.maxlength}`;
        } else {
            return `0 / ${this.maxlength}`;
        }
    }
    public editComment = (): void => {
        this.initialValue = this.value;
        this.editingComment = true;
    };

    public cancelComment = (): void => {
        this.value = this.initialValue;
        this.editingComment = false;
    };

    public saveComment(): void {
        this.saved.emit(this.internalNgModel);
        this.editingComment = false;
    }
}
