export enum BuildProgrammeTemplatePermissionEnum {
    None            = 0x00,
    CanView         = 0x01 << 0,
    CanCreate       = 0x01 << 1,
    CanEdit         = 0x01 << 2,
    CanDuplicate    = 0x01 << 3,
    CanManage       = 0x01 << 4,
    CanViewHistory  = 0x01 << 5,
    CanViewAll      = 0x01 << 6,
    CanEditAll      = 0x01 << 7,
}
