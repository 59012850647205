<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
    <form class="flex-col"
          #form="ngForm">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">
                Schedule Items
            </h3>
            <mat-slide-toggle class="cb-margin-right-2x"
                              name="reorderingEnabled"
                              [checked]="reorderingEnabled"
                              [disabled]="reorderingEnabled"
                              (click)="enableReordering()">
                Enable Reordering
            </mat-slide-toggle>
            <button *ngIf="reorderingEnabled"
                    class="header-button"
                    mat-raised-button
                    (cbClick)="saveOrder()"
                    [throttleTime]="2000"
                    [disabled]="!sortOrderChanged"
                    color="primary">
                Save Order
            </button>
            <button *ngIf="reorderingEnabled"
                    mat-raised-button
                    (cbClick)="cancelReordering()"
                    [throttleTime]="2000">
                Cancel Reordering
            </button>
            <button *ngIf="!reorderingEnabled"
                    mat-raised-button
                    (click)="newItemClicked();"
                    color="primary">
                New Schedule Item
            </button>
            <cb-select class="cb-margin"
                       label="Select Tag"
                       name="selectTag"
                       [(ngModel)]="selectedTag"
                       [options]="activeSlotTags">
            </cb-select>
            <button mat-raised-button
                    (click)="applyTag()"
                    color="primary"
                    [disabled]="!selectedTag">
                Apply Tag to Item/s
            </button>
            <button mat-raised-button
                    (click)="generateSimReport()"
                    color="primary">
                Generate Report
            </button>
        </div>
        <div class="start-center flex-row">
            <cb-input class="cb-margin-right-2x flex"
                      type="text"
                      label="Type in Schedule Item Name / Tag to Search"
                      [(ngModel)]="userCacheItem.data.query"
                      *ngIf="!reorderingEnabled"
                      name="query">
            </cb-input>
            <cb-select-list class="cb-margin-right-2x flex"
                            label="Cost Types"
                            [allOptions]="costTypes"
                            [multiple]="true"
                            *ngIf="!reorderingEnabled"
                            [(value)]="userCacheItem.data.costTypeId">
            </cb-select-list>
            <cb-select-list class="cb-margin-right gt-sm-"
                            label="House Areas"
                            [allOptions]="houseAreas"
                            [multiple]="true"
                            *ngIf="!reorderingEnabled"
                            (selectChanged)="filteredHouseAreas.recompute()"
                            [(value)]="userCacheItem.data.selectedHouseAreas">
            </cb-select-list>
            <cb-checkbox label="Active Only"
                         style="height: 30px"
                         class="cb-margin-right-2x"
                         name="activeOnly"
                         *ngIf="!reorderingEnabled"
                         [(ngModel)]="userCacheItem.data.isActive">
            </cb-checkbox>
            <span class="flex"></span>
            <button class="cb-margin-bottom-2x"
                    mat-raised-button
                    (click)="expandCollapseAll()">
                <span class="start-center flex-row"
                      *ngIf="!allExpanded">
                    <span>
                        Expand All&nbsp;
                    </span>
                    <mat-icon>
                        keyboard_arrow_down
                    </mat-icon>
                </span>
                <span class="start-center flex-row"
                      *ngIf="allExpanded">
                    <span>
                        Collapse All&nbsp;
                    </span>
                    <mat-icon>
                        keyboard_arrow_up
                    </mat-icon>
                </span>
            </button>
        </div>
    </form>
    <mat-accordion [multi]="true"
                   class="accordion scroll-container">
        <mat-expansion-panel [hidden]="allExpanded && !(houseArea?.itemCount > 0)"
                             class="panel"
                             *ngFor="let houseArea of filteredHouseAreas.value"
                             [(expanded)]="expandedAreas[houseArea.id]"
                             (expandedChange)="handleExpandChange(houseArea)"
                             (cbOnDestroy)="handlePanelContentsDestroy(houseArea)">
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title>
                    <span class="mat-body-2 cb-margin-top">
                        {{houseArea.label}}
                    </span>
                </mat-panel-title>
                <mat-panel-description class="end-center flex-row">
                </mat-panel-description>
            </mat-expansion-panel-header>
            <cb-house-area-slots-table [searchEnabled]="searchEnabled"
                                       [queryUpdated]="queryUpdated"
                                       [reorderCancelled]="reorderCancelled"
                                       [resetSelectedTags]="resetSelectedTags"
                                       [reorderingEnabled]="reorderingEnabled"
                                       [houseAreaId]="houseArea.id"
                                       [slotTags]="slotTags"
                                       (sortOrderChanged)="sortOrderChange($event)"
                                       (itemSelectedEmitter)="handleListChanged($event)"
                                       (parentSlotCountEmitter)="houseArea.itemCount = $event"
                                       [slotsUpdated]="slotsUpdated"
                                       *ngIf="expandedAreas[houseArea.id]">
            </cb-house-area-slots-table>
        </mat-expansion-panel>
    </mat-accordion>
</div>
