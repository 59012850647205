import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {BusinessAccountsComponent} from '@app/views/settings/pages/business-accounts/business-accounts.component';
import {BusinessAccountTypeDocsDialogComponent} from './business-account-type-docs-dialog/business-account-type-docs-dialog.component';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';
import {TradeTypeDocsDialogComponent} from './trade-type-docs-dialog/trade-type-docs-dialog.component';

@NgModule({
    declarations: [
        BusinessAccountsComponent,
        BusinessAccountTypeDocsDialogComponent,
        TradeTypeDocsDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbHubTableModule,
        CbTableModule
    ],
    exports: [
        BusinessAccountsComponent,
        BusinessAccountTypeDocsDialogComponent,
        TradeTypeDocsDialogComponent
    ]
})
export class BusinessAccountsSettingsModule { }
