import { Directive, Input, TemplateRef, ViewContainerRef, ComponentFactoryResolver, ComponentFactory } from '@angular/core';
import { LoadingSpinnerComponent } from 'cb-hub-lib';

export class WaitForContext {
    public $implicit: any = null;
    public cbWaitFor: any = null;
}

/** Based on source code for *ngIf - https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts */
@Directive({
    selector: '[cbWaitFor]'
})
export class WaitForDirective {

    /** @internal */
    public static cbWaitForUseIfTypeGuard: void;

    /**
     * Doc from https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts
     *
     * Assert the correct type of the expression bound to the `cbWaitFor` input within the template.
     *
     * The presence of this static field is a signal to the Ivy template type check compiler that
     * when the `cbWaitFor` structural directive renders its template, the type of the expression bound
     * to `cbWaitFor` should be narrowed in some way. For `cbWaitFor`, the binding expression itself is used to
     * narrow its type, which allows the strictNullChecks feature of TypeScript to work with `cbWaitFor`.
     */
    public static ngTemplateGuard_cbWaitFor: 'binding';

    private _context: WaitForContext = new WaitForContext();
    private readonly _thenTemplateRef: TemplateRef<WaitForContext> | null = null;
    private readonly _componentFactory: ComponentFactory<LoadingSpinnerComponent>;

    constructor(
        private readonly _viewContainer: ViewContainerRef,
        private readonly templateRef: TemplateRef<WaitForContext>,
        private readonly componentFactoryResolver: ComponentFactoryResolver) {
        this._thenTemplateRef = this.templateRef;

        this._componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingSpinnerComponent);
    }

    /**
     * The Boolean expression to evaluate as the condition for showing a template.
     */
    @Input()
    public set cbWaitFor(condition: any) {
        this._context.$implicit = this._context.cbWaitFor = condition;
        this._updateView();
    }

    private _updateView(): void {
        if (this._context.$implicit) {
            this._viewContainer.clear();
            this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        } else {
            this._viewContainer.clear();
            this._viewContainer.createComponent(this._componentFactory);
        }
    }
}
