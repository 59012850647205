import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ILocationDto, ILocationMappedItem, LocationLogicService, LocationMappedItem } from '@app/logic/location';
import { LocationDialogComponent } from './location-dialog/location-dialog.component';
import { filter, find, findIndex } from 'lodash';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { AddressRegionsLogicService } from '@app/logic/address-regions';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss']
})
export class LocationsComponent extends BaseSimpleListViewDirective<ILocationDto, LocationLogicService> {
    public readonly displayedColumns = ['region', 'district', 'area', 'isActive', 'businessEntity', 'addressRegion', 'actions'];
    public showActiveOnly = true;

    private cachedResult: ILocationDto[];
    private businessEntities: IBusinessEntityDto[];
    private addressRegions: IAddressRegionDto[];

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly locationLogicService: LocationLogicService,
        protected readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        protected readonly addressRegionsLogicService: AddressRegionsLogicService,
    ) {
        super(
            cbDialog,
            locationLogicService,
            LocationDialogComponent,
            'Location',
            LocationMappedItem,
            permissionsPermissions,
            false,
        );
        combineLatest([
            this.businessEntitiesLogicService.$getList(),
            this.addressRegionsLogicService.$getList(),
        ]).subOnce((result: any) => {
            this.refreshSearchResults();
            this.businessEntities = result[0];
            this.addressRegions = result[1];
        });
    }

    public refreshSearchResults(fromCached?: boolean): void {
        if (fromCached) {
            const result = filter(this.cachedResult.slice(), item => this.showActiveOnly ? item.isActive : true);
            this.handleSearchResults(result);
        } else {
            combineLatest([
                this.locationLogicService.$getList(),
                this.locationLogicService.getFlatTree(this.showActiveOnly)
            ]).subOnce((result: any) => {
                result[1].forEach((item: ILocationDto) => {
                    item.name = item.label;
                    item.locationType = item.type;
                    const match: ILocationDto = find(result[0], { id: item.id, locationType: 1 });
                    if (match) {
                        item.code = match.code;
                    }
                });
                this.cachedResult = result[1].slice();
                this.handleSearchResults(this.cachedResult);
            });
        }
    }

    public sortSearchResults(searchResults: ILocationDto[]): ILocationDto[] {
        searchResults.forEach((item: ILocationDto) =>
            item.sortString = [item.region?.label || undefined, item.district?.label || undefined, item.area?.label || undefined].join('-'));
        let visibleRows = filter(searchResults, (item: ILocationDto) => item.visible || item.locationType === 1);
        visibleRows = visibleRows.sort((a, b) => a.sortString.localeCompare(b.sortString));
        return visibleRows;
    }

    public openDialog(mappedItem: ILocationMappedItem, dialogHeading: string): MatDialogRef<any> {
        return this.cbDialog.open(LocationDialogComponent, {
            data: {
                dialogHeading,
                mappedItem,
                locations: this.cachedResult,
                businessEntities: this.businessEntities,
                addressRegions: this.addressRegions,
            }
        });
    }

    public onNewItemClicked(locationType?: number, parentId?: number): void {
        const mappedItem: any = this.logicService.$createMappedItem(LocationMappedItem as any);
        mappedItem.locationType = locationType;
        mappedItem.parentId = parentId;
        this.openDialog(mappedItem as LocationMappedItem, `Add ${this.dialogHeading}`)
            .afterClosed()
            .subOnce(this.handleNewItem.bind(this));
    }

    protected handleNewItem(item: ILocationDto): void {
        if (item?.id) {
            const match = find(this.cachedResult, { id: item.id });
            if (!match) {
                this.cachedResult = [...this.cachedResult, item];
            }
            this.refreshSearchResults(true);
            this.expand(item);
        }
    }

    public handleEditItem = (item: ILocationDto): void => {
        if (item?.id) {
            const matchIndex = findIndex(this.cachedResult, { id: item.id });
            if (matchIndex >= 0) {
                this.cachedResult[matchIndex] = { ...item };
            }
            this.refreshSearchResults(true);
        }
    };

    public onExportExcelClicked(): void {
        this.locationLogicService.export(this.showActiveOnly).subOnce();
    }

    public expand(location: ILocationDto): void {
        if (location.parentId) {
            const parent = find(this.cachedResult, { id: location.parentId });
            if (!parent.expanded) {
                this.expand(parent);
            }
        }

        location.expanded = true;
        this.cachedResult.forEach((item: ILocationDto) => {
            if (item.parentId === location.id) {
                item.visible = true;
            }
        });
        this.refreshSearchResults(true);
    }

    public collapse(location: ILocationDto): void {
        location.expanded = false;
        this.cachedResult.forEach((item: ILocationDto) => {
            if (item.parentId === location.id) {
                item.visible = false;
                this.collapse(item);
            }
        });
        this.refreshSearchResults(true);
    }
}
