<cb-dialog dialogHeading="{{(this.isEdit$ | async) ? 'Edit Takeoff' : 'Create Takeoff'}}">
    <form class="flex-col flex"
          #form="ngForm">
        <div *cbWaitFor="(qsTeamMembers$ | async) && mappedItem">
            <cb-select label="Assigned To"
                       [required]="true"
                       name="assignedToUserId"
                       [(ngModel)]="mappedItem.assignedToUserId"
                       [options]="qsTeamMembers$ | async">
            </cb-select>
            <cb-select *ngIf="(pricingComplexities$ | async)?.length > 0"
                       label="Pricing Complexity"
                       [required]="true"
                       name="pricingComplexityId"
                       labelProp="complexity"
                       (change)="pricingComplexityChanged()"
                       [(ngModel)]="mappedItem.pricingComplexityId"
                       [options]="pricingComplexities$ | async">
            </cb-select>
            <cb-input type="number"
                      name="targetWorkHours"
                      label="Estimated Hours"
                      [(ngModel)]="mappedItem.targetWorkHours">
            </cb-input>
            <cb-datepicker name="dueByDate"
                           [(ngModel)]="mappedItem.dueBy"
                           label="Due By">
            </cb-datepicker>
        </div>
        <footer class="cb-margin-top-2x end-center flex-row">
            <cb-button [disabled]="form.pristine || form.invalid"
                       [clickLimit]="1"
                       class="cb-margin-right"
                       (clicked)="save()">
                Save
            </cb-button>
            <cb-button (clicked)="cancel()">
                Cancel
            </cb-button>
        </footer>
    </form>
</cb-dialog>
