import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { BusinessAccountsInputFindDirective } from './business-accounts-input-find.directive';
import { BusinessAccountsInputFindDialogCardsComponent } from '../../dialog/extensions/business-accounts-input-find-dialogs/business-accounts-input-find-dialog-cards.component';
import { BusinessAccountsInputFindSearchService } from '../../../../../../core/services/search/business-accounts-input-find-search.service';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';

@Component({
    selector: 'cb-business-accounts-input-find-cards',
    templateUrl: '../../base-input-find.directive.html',
    styleUrls: ['../../base-input-find.directive.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(BusinessAccountsInputFindCardsComponent),
        BusinessAccountsInputFindSearchService,
        provideParentForm()
    ]
})
export class BusinessAccountsInputFindCardsComponent extends BusinessAccountsInputFindDirective {
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public searchService: BusinessAccountsInputFindSearchService
    ) {
        super(
            dialogRef,
            router,
            searchService
        );
        this._myDialog = BusinessAccountsInputFindDialogCardsComponent;
    }
}
