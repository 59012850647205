import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { DocumentGroupLogicService, DocumentsLogicService } from '@app/logic/documents';
import { ProductLogicService } from '@app/logic/products';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ButtonControl } from '@app/shared/directives/button-control/button-control.directive';
import { BUSINESS_ACCOUNT_STATUS_ENUM, FILE_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { forEach } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, combineLatest, exhaustMap, filter, map, Observable, startWith, switchMapTo, take, tap, withLatestFrom } from 'rxjs';

@Component({
    selector: 'cb-product-rates-tab',
    templateUrl: './product-rates-tab.component.html',
    styleUrls: ['./product-rates-tab.component.scss']
})
export class ProductRatesTabComponent implements OnInit, AfterViewInit {
    public FILE_TYPE_ENUM = FILE_TYPE_ENUM;
    public dateForRates: Date;
    public uploadDisabled$ = new BehaviorSubject<boolean>(true).asObservable();
    public readonly uploadButton = new ButtonControl();
    public file$ = new BehaviorSubject<File>(undefined);
    public isFileValidAndUploaded$ = new BehaviorSubject<boolean>(false);
    public isLoading$ = new BehaviorSubject<boolean>(false);
    public ratesUploadSheets: {};
    public ratesUploadErrors: any[];
    public ratesUploadResults: any;
    public catalogUploadProgress: boolean;
    public supplier: any;
    public supplierId: number;
    public logLevel = 1;
    public businessAccountSearchParams = {
        s: [
            BUSINESS_ACCOUNT_STATUS_ENUM.Active,
            BUSINESS_ACCOUNT_STATUS_ENUM.Overridden,
            BUSINESS_ACCOUNT_STATUS_ENUM.Pending,
            BUSINESS_ACCOUNT_STATUS_ENUM.OnHold
        ]
    };
    public ratesUploadProgress: boolean;
    public get productRatesImportForm(): NgForm {
        return this.productRatesImportFormParent?.first;
    }
    @ViewChildren('productRatesImportForm') public productRatesImportFormParent;

    constructor(
        public readonly documentGroupLogic: DocumentGroupLogicService,
        public readonly documentTypeLogic: DocumentTypesLogicService,
        protected readonly productsLogicService: ProductLogicService,
        public readonly cbDialog: CbDialogService,
        public readonly documentsLogic: DocumentsLogicService) { }

    public ngOnInit(): void {
        this.uploadButton.clicked.pipe(
            withLatestFrom(this.isFileValidAndUploaded$),
            map(([_, isFileValidAndUploaded]) => isFileValidAndUploaded),
            filter(isFileValidAndUploaded => !!isFileValidAndUploaded && !!this.dateForRates),
            switchMapTo(this.file$.pipe(take(1))),
            tap(_ => this.isLoading$.next(true)),
            exhaustMap(file => this.uploadRates(file, this.dateForRates))
        ).subscribe({
            next: x => {
                this.handleUploadRatesResult(x);
                this.isLoading$.next(false);
            },
            complete: () => {
                this.isLoading$.next(false);
            },
            error: err => {
                this.isLoading$.next(false);
            }
        });
    }

    public ngAfterViewInit(): void {
        this.uploadDisabled$ = combineLatest([
            this.isFileValidAndUploaded$.pipe(startWith(false)),
            this.productRatesImportForm.valueChanges.pipe(startWith()),
            this.isLoading$
        ]).pipe(
            map(
                ([fileValid, formControlValue, isLoading]) => {
                    return !fileValid ||
                        isLoading ||
                        this.productRatesImportForm.pristine ||
                        this.productRatesImportForm.invalid;
                }
            )
        );
    }

    public resetRates = () => {
        this.dateForRates = undefined;
        this.supplier = undefined;
        this.supplierId = undefined;
        this.productRatesImportForm.form.markAsPristine();
        this.productRatesImportForm.form.markAsUntouched();
        this.ratesUploadResults = undefined;
        this.ratesUploadProgress = false;
        this.file$.next(undefined);
        this.isFileValidAndUploaded$.next(false);
        this.isLoading$.next(false);
    };

    public uploadRates(file: File, dateForRates: Date): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        if (this.supplierId) {
            formData.append('vendorId', this.supplierId.toString());
        }
        formData.append('dateForRates', moment(dateForRates).format());
        return this.productsLogicService.uploadRates(formData);
    }

    public uploadRatesByFileId = (fileId: number) => {
        const formData = new FormData();
        if (this.supplierId) {
            formData.append('vendorId', this.supplierId.toString());
        }
        formData.append('dateForRates', moment(this.dateForRates).format());
        formData.append('transferHistoryId', fileId.toString());
        return this.productsLogicService.uploadRates(formData);
    };

    public handleUploadRatesResult = (results: any) => {
        if (results.importResult === 10) {
            this.showDateWarning(results.excelTransferHistoryId, results);
        } else {
            const logResult = {};
            const errors = [];
            forEach(results.records, (record) => {
                if (record.sheetName) {
                    let sheetList = logResult[record.sheetName];
                    if (!sheetList) {
                        sheetList = [];
                        logResult[record.sheetName] = sheetList;
                    }
                    sheetList.push(record);
                } else {
                    errors.push(record);
                }
            });

            this.ratesUploadSheets = logResult;
            this.ratesUploadErrors = errors;

            this.ratesUploadResults = results;
            this.ratesUploadProgress = false;
        }
    };

    public logFilter = (log) => {
        return log.logLevel > this.logLevel;
    };

    public showDateWarning = (transferHistoryId: number, originalResults: any) => {
        this.cbDialog.confirm({
            dialogHeading: 'Import Warning',
            message: 'The workbook is out of date.  Do you wish to continue?',
            confirmed: () => {
                this.isLoading$.next(true);
                this.uploadRatesByFileId(transferHistoryId).subOnce({
                    next: x => {
                        this.isLoading$.next(false);
                        this.handleUploadRatesResult(x);
                    },
                    complete: () => {
                        this.isLoading$.next(false);
                    },
                    error: err => {
                        this.isLoading$.next(false);
                    }
                });
            },
            declined: () => {
                originalResults.importResult = 0;
                this.handleUploadRatesResult(originalResults);
            }
        });
    };

    public supplierSelected(): void {
        this.supplierId = this.supplier?.id;
    }

}
