import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ITradeTypeTreeDto } from './interfaces/i.trade-type-tree.dto';
import { ITradeTypeTreeMappedItem } from './interfaces/i.trade-type-tree.mapped';
import { ITradeTypesTreeLogicService } from './interfaces/i.trade-types-tree.logic.service';
import { TradeTypeTreeMappedItem } from './trade-type-tree.mapped';

@Injectable()
export class TradeTypesTreeLogicService
    extends BaseLogicService<ITradeTypeTreeDto, ITradeTypeTreeMappedItem>
    implements ITradeTypesTreeLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'tradetypes',
            TradeTypeTreeMappedItem,
        );
    }

    public getTree(): Observable<ITradeTypeTreeDto[]> {
        return this.$http.get<ITradeTypeTreeDto[]>(`${this.$baseUri}/tree`);
    }

    public getMappedTree(): Observable<ITradeTypeTreeMappedItem[]> {
        return this.getTree()
            .pipe(
                map(res => res.map(x => this.$createMappedItem(x)))
            );
    }
}
