import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BusinessAccountLogicService, BusinessAccountMappedItem } from '@app/logic/business-accounts';
import { BUSINESS_ACCOUNT_HOLD_ACTION_ENUM, BUSINESS_ACCOUNT_HOLD_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { find, findIndex, map, some } from 'lodash';

interface IData {
    account: BusinessAccountMappedItem;
}

@Component({
    selector: 'cb-business-account-add-account-hold-dialog',
    templateUrl: './business-account-add-account-hold-dialog.component.html',
    styleUrls: ['./business-account-add-account-hold-dialog.component.scss']
})
export class BusinessAccountAddAccountHoldDialogComponent {
    public static readonly MIN_WIDTH = '60%';

    public availableHolds = [];
    public accountHolds = [];
    public description = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<BusinessAccountAddAccountHoldDialogComponent>,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
    ) {
    }

    public ngOnInit(): void {
        this.availableHolds = map(this.data.account.availableHolds, hold => {
            return {
                holdAction: hold.holdAction,
                holdType: hold.holdType,
                label: `${BUSINESS_ACCOUNT_HOLD_TYPE_ENUM[hold.holdType]} Hold for ${BUSINESS_ACCOUNT_HOLD_ACTION_ENUM[hold.holdAction]}`
            };
        });
    }

    public isAccountHoldChecked(holdAction: number, holdType: number): boolean {
        return some(this.accountHolds, { holdType, holdAction });
    }

    public onAccountHoldChanged(holdAction: number, holdType: number, isChecked: boolean): void {
        if (isChecked) {
            if (!find(this.accountHolds, { holdType, holdAction })) {
                this.accountHolds.push({ holdType, holdAction });
            }
        } else {
            const index = findIndex(this.accountHolds, { holdType, holdAction });
            if (index > -1) {
                this.accountHolds.splice(index, 1);
            }
        }
    }

    public canSave(): boolean {
        return !!this.data?.account?.id && !!this.description && !!this.accountHolds.length;
    }

    public onSaveClicked(): void {
        if (this.canSave()) {
            this.accountHolds.forEach(accountHold => accountHold.comments = this.description);
            this.businessAccountLogicService.addAccountHolds(this.data.account.id, this.accountHolds)
                .subOnce(() => this.dialogRef.close());
        }
    }
}
