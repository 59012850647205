export enum ClientSalePermissionEnum {
    None                            = 0x00,
    NoteCanView                     = 0x01 << 1,
    NoteCanAdd                      = 0x01 << 2,
    NoteCanEdit                     = 0x01 << 3,
    NoteCanDelete                   = 0x01 << 4,
    TaskCanView                     = 0x01 << 5,
    TaskCanAdd                      = 0x01 << 6,
    TaskCanEdit                     = 0x01 << 7,
    TaskCanComplete                 = 0x01 << 8,
    DocumentsCanView                = 0x01 << 9,
    DocumentsCanUpload              = 0x01 << 10,
    DocumentsCanEdit                = 0x01 << 11,
    DocumentsCanReview              = 0x01 << 12,
    DocumentsCanDelete              = 0x01 << 13,
    CanEdit                         = 0x01 << 15,
    CanChangeContractType           = 0x01 << 16,
    CanCreate                       = 0x01 << 16, // not used
    CanView                         = 0x01 << 17,
    CanSearch                       = 0x01 << 18,
    HistoryCanView                  = 0x01 << 19,
    CanViewPayments                 = 0x01 << 20,
    PaymentsCanAdd                  = 0x01 << 21,
    CanEditPayment                 = 0x01 << 22,
    CanGenerateContractFee          = 0x01 << 23,
    canDownloadCreateSchedule  = 0x01 << 24,
    CanGenerateSaleAndPurchaseInfo  = 0x01 << 25,
    CanGenerateDesignFee            = 0x01 << 26,
    CanMarkSaleLost                 = 0x01 << 27,
    CanConfirmSaleLost              = 0x01 << 28
}
