
/** Does what it says on the tin. Use as a method decorator
 *
 * @param delay - debounce delay in milliseconds (default 300)
 */
export function Debounce(delay = 300): MethodDecorator {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const original = descriptor.value;

        descriptor.value = function(...args) {
            // explicit check for undefined
            if (this[timeoutPropName(propertyKey)] === undefined) {
                Object.defineProperty(this, timeoutPropName(propertyKey), {
                    value: null,
                    enumerable: false,
                    configurable: false,
                    writable: true,
                });
            }
            clearTimeout(this[timeoutPropName(propertyKey)]);
            this[timeoutPropName(propertyKey)] = setTimeout(() => original.apply(this, args), delay);
        };

        return descriptor;
    };
}


function timeoutPropName(propertyKey: string): string {
    return `__${propertyKey}_CbDebounceDecoratorTimeout`;
}
