import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { IBuildProgrammeLogicService } from './interfaces/i.build-programme.logic.service';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IBuildProgrammeActivityDto } from '../build-programme-activity/interfaces/i.build-programme-activity.dto';
import { IBuildProgrammeIndexedDto } from './interfaces/i.build-programme-indexed.dto';
import { IBuildProgrammeActivityLookupDto } from '../build-programme-activity';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { IBuildProgrammeDocumentDto, IBuildProgrammeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ITeamBuildProgrammeSearch } from '@app/core/services/user-cache/user-cache-areas';
import { IFileResponse } from '@app/shared/interfaces/i.file-response.dto';
import { IDValidationItem } from '@app/shared/interfaces/i.d.validate.item';

@Injectable()
export class BuildProgrammeLogicService
    extends BaseLogicServiceWithSearch<
    IBuildProgrammeIndexedDto,
    Object,
    ITeamBuildProgrammeSearch,
    IBuildProgrammeDocumentDto[]
    >
    implements IBuildProgrammeLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildprogrammes',
            Object,
        );
    }

    public getBuildProgrammeStartDate(lotId: number): Observable<{ startDate: DateString }> {
        return this.$http
            .get(`/lots/${lotId}/buildprogrammes/startdate`);
    }

    public getBuildProgrammeActivity(buildProgrammeId: number, buildProgrammeActivityId: number): Observable<IBuildProgrammeActivityDto> {
        return this.$http
            .get<IBuildProgrammeActivityDto>(`${this.$baseUri}/${buildProgrammeId}/activities/${buildProgrammeActivityId}`);
    }

    public generateBuildProgramme(lotId: number): Observable<IBuildProgrammeDto> {
        return this.$http
            .post(`/lots/${lotId}/generatebuildprogramme`);
    }

    public getBuildProgrammeByLotId(lotId: number): Observable<any> {
        return this.$http
            .get<any>(`/lots/${lotId}/buildprogramme`);
    }

    public getIndexedBuildProgramme(buildProgrammeId: number): Observable<IBuildProgrammeIndexedDto> {
        return this.$http
            .get(`buildprogrammes/${buildProgrammeId}/indexed`);
    }

    public getIndexedBuildProgrammeByLotId(lotId: number): Observable<IBuildProgrammeIndexedDto> {
        return this.$http
            .get<any>(`/lots/${lotId}/buildprogramme/indexed`);
    }

    public getIndexedBuildProgrammeActivitiesByLotIdAndStage(lotId: number, buildStageId: number): Observable<IBuildProgrammeActivityDto[]> {
        return this.$http
            .get<IBuildProgrammeActivityDto[]>(`/lots/${lotId}/buildprogramme/stage/${buildStageId}/indexed`);
    }

    public setBuildProgrammeActivityToAbandoned(buildProgrammeId: number, buildProgrammeActivityId: number): Observable<IBuildProgrammeActivityDto> {
        return this.$http
            .post(`${this.$baseUri}/${buildProgrammeId}/activities/${buildProgrammeActivityId}/abandon`);
    }

    public getBuildProgrammeActivitiesLookup(buildProgrammeId: number): Observable<IBuildProgrammeActivityLookupDto[]> {
        return this.$http
            .get<IBuildProgrammeActivityLookupDto[]>(`${this.$baseUri}/${buildProgrammeId}/activitylookup`);
    }

    public getBuildProgrammeActivitiesLookupByLotId(lotId: number): Observable<IBuildProgrammeActivityLookupDto[]> {
        return this.$http
            .get<IBuildProgrammeActivityLookupDto[]>(`lots/${lotId}/buildprogramme/activitylookup`);
    }

    public getValidationItems(lotId: number | string): Observable<IDValidationItem[]> {
        return this.$http
            .get<IDValidationItem[]>(`buildprogrammes/validation/${lotId}`);
    }

    public regenerateComplianceDocuments(): Observable<void> {
        return this.$http.post(`${this.$baseUri}/compliance/update`);
    }

    public generateBuildProgrammeReport(lotId: number, hasScheduleSpecTemplate: boolean): Observable<IFileResponse> {
        if (hasScheduleSpecTemplate) {
            return this.$http.download<IFileResponse>(
                `/lots/${lotId}/generatesimbuildprogramme`, 'Generating SIM Build Programme...',
                undefined,
                'post'
            );
        } else {
            return this.$http.download<IFileResponse>(
                `/lots/${lotId}/generateyourbuildprogramme`, 'Generating Build Programme...',
                undefined,
                'post'
            );
        }
    }

    public generateJobBuildReport(lotId: number): Observable<IFileResponse> {
        return this.$http.download<IFileResponse>(
            `/lots/${lotId}/generatejobbuildreport`, 'Generating Job Build Report...',
            undefined,
            'post'
        );
    }
}
