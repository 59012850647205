import {Injectable} from '@angular/core';
import {LOT_CONTRACT_TYPE_ENUM, LOT_TYPE_ENUM, LotContractTypeEnumId, LotTypeEnumId} from '@classictechsolutions/hubapi-transpiled-enums';
import {IProjectReleaseLotFieldRulesService} from './interfaces/i.project-release-lot-field-rules.service';

@Injectable()
export class ProjectReleaseLotFieldRulesService implements IProjectReleaseLotFieldRulesService {

    // START amenitiesFloorArea
    public amenitiesFloorAreaEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if ((lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
                return true;
            }

            if ((lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
                return true;
            }

            if ((lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public amenitiesFloorAreaRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.amenitiesFloorAreaEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public amenitiesFloorAreaVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if ((lotType === LOT_TYPE_ENUM.Standalone
            || lotType === LOT_TYPE_ENUM.Unit
            || lotType === LOT_TYPE_ENUM.TerracedBlock
            || lotType === LOT_TYPE_ENUM.ApartmentBlock)
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            return true;
        }

        if ((lotType === LOT_TYPE_ENUM.Standalone
            || lotType === LOT_TYPE_ENUM.Unit
            || lotType === LOT_TYPE_ENUM.TerracedBlock
            || lotType === LOT_TYPE_ENUM.ApartmentBlock)
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return true;
        }

        if ((lotType === LOT_TYPE_ENUM.Standalone
            || lotType === LOT_TYPE_ENUM.Unit
            || lotType === LOT_TYPE_ENUM.TerracedBlock
            || lotType === LOT_TYPE_ENUM.ApartmentBlock)
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            return true;
        }

        return false;
    }
    // END amenitiesFloorArea

    // START amenitiesLandArea
    public amenitiesLandAreaEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }
        }

        return false;
    }

    public amenitiesLandAreaRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.amenitiesLandAreaEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public amenitiesLandAreaVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }


        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }

        return false;
    }
    // END amenitiesLandArea

    // START amenitiesNoBathrooms
    public amenitiesNoBathroomsEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {

        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }
        }

        return false;
    }

    public amenitiesNoBathroomsRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.amenitiesNoBathroomsEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public amenitiesNoBathroomsVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        return false;
    }
    // END amenitiesNoBathrooms

    // START amenitiesNoBedrooms
    public amenitiesNoBedroomsEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }
        }

        return false;
    }

    public amenitiesNoBedroomsRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.amenitiesNoBedroomsEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public amenitiesNoBedroomsVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }
    }
    // END amenitiesNoBedrooms

    // START amenitiesNoGarages
    public amenitiesNoGaragesEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }
        }

        return false;
    }

    public amenitiesNoGaragesRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.amenitiesNoGaragesEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public amenitiesNoGaragesVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        return false;
    }
    // END amenitiesNoGarages


    // START amenitiesNoCarparks
    public amenitiesNoCarparksEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }
        }

        return false;
    }

    public amenitiesNoCarparksRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.amenitiesNoCarparksEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public amenitiesNoCarparksVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        return false;
    }
    // END amenitiesNoCarparks

    // START amenitiesNoLivingRooms
    public amenitiesNoLivingRoomsEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }
        }

        return false;
    }

    public amenitiesNoLivingRoomsRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.amenitiesNoLivingRoomsEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public amenitiesNoLivingRoomsVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        return false;
    }
    // END amenitiesNoLivingRooms

    // START buildType
    public buildTypeEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }

            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }

            if ((lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock))
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public buildTypeRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.buildTypeEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public buildTypeVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }

        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }

        if ((lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock))
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            return true;
        }
    }
    // END buildType

    // START financialsLandPrice
    public financialsLandPriceEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }
        }

        return false;
    }

    public financialsLandPriceRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.financialsLandPriceEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public financialsLandPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }
    }
    // END financialsLandPrice

    // START financialsSellPrice
    public financialsSellPriceEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }
        }
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit)) {
                return true;
            }
        }


        return false;
    }

    public financialsSellPriceRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.financialsSellPriceEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public financialsSellPriceVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit)) {
            return true;
        }

        return false;
    }
    // END financialsSellPrice

    // START lotAddressPrice
    public lotAddressEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }

            if ((lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock))
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public lotAddressRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.lotAddressEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public lotAddressVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }

        if ((lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock))
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            return true;
        }

        return false;
    }
    // END lotAddress

    // START lotNumbe
    public lotNumberEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (hasPriceRevisions !== true) {
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }
            if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
                return true;
            }

            if ((lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
                (lotType === LOT_TYPE_ENUM.Standalone
                    || lotType === LOT_TYPE_ENUM.Unit
                    || lotType === LOT_TYPE_ENUM.TerracedBlock
                    || lotType === LOT_TYPE_ENUM.ApartmentBlock))
                && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
                return true;
            }
        }

        return false;
    }

    public lotNumberRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return this.lotNumberEditable(lotType, lotContractType, hasPriceRevisions);
    }

    public lotNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }

        if ((lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock))
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            return true;
        }

        return false;
    }
    // END lotNumber

    // START jobNumber
    public jobNumberEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean,
        lotSpecLocked: boolean): boolean {
        return (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild
            || lotContractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand)
            && !lotSpecLocked;
    }

    public jobNumberRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean,
        lotSpecLocked: boolean): boolean {
        return this.jobNumberEditable(lotType, lotContractType, hasPriceRevisions, lotSpecLocked);
    }

    public jobNumberVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }
        if (lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock)) {
            return true;
        }

        if ((lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild &&
            (lotType === LOT_TYPE_ENUM.Standalone
                || lotType === LOT_TYPE_ENUM.Unit
                || lotType === LOT_TYPE_ENUM.TerracedBlock
                || lotType === LOT_TYPE_ENUM.ApartmentBlock))
            && lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild) {
            return true;
        }

        return false;
    }
    // END jobNumber

    // START roadDirection
    public roadDirectionEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return true;
    }

    public roadDirectionRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return false;
    }

    public roadDirectionVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return true;
    }
    // END roadDirection


    // START roadDirection
    public lotHoldingDepositEditable(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return true;
    }

    public lotHoldingDepositRequired(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return false;
    }

    public lotHoldingDepositVisible(
        lotType: LotTypeEnumId,
        lotContractType: LotContractTypeEnumId,
        hasPriceRevisions: boolean): boolean {
        return true;
    }
    // END roadDirection

    // START hasExternalLandVendor
    public hasExternalLandVendorEditable(
        lotContractType: LotContractTypeEnumId): boolean {
        return lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    public hasExternalLandVendorRequired(
        lotContractType: LotContractTypeEnumId): boolean {
        return false;
    }

    public hasExternalLandVendorVisible(
        lotContractType: LotContractTypeEnumId): boolean {
        return lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }
    // END hasExternalLandVendor

    // START lotPurchasePrice
    public lotPurchasePriceEditable(
        lotContractType: LotContractTypeEnumId): boolean {
        return lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    public lotPurchasePriceRequired(
        lotContractType: LotContractTypeEnumId): boolean {
        return lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    public lotPurchasePriceVisible(
        lotContractType: LotContractTypeEnumId): boolean {
        return lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }
    // END lotPurchasePrice

    // START lotDeposit
    public lotDepositEditable(
        lotContractType: LotContractTypeEnumId): boolean {
        return lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    public lotDepositRequired(
        lotContractType: LotContractTypeEnumId): boolean {
        return lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    public lotDepositVisible(
        lotContractType: LotContractTypeEnumId): boolean {
        return lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }
    // END lotDeposit
}
