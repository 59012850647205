import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IUserDto } from '@app/logic/users/interfaces/i.user.dto';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';

export interface IUserDetailsDialogData {
    dto: IUserDto;
}

@Component({
    selector: 'cb-user-details-dialog',
    templateUrl: './user-details-dialog.component.html',
    styleUrls: ['./user-details-dialog.component.scss']
})
export class UserDetailsDialogComponent extends BaseDialogFormViewDirective<IUserDto, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '500px';
    public dto: IUserDto;
    public fullName: string;

    constructor(
        private readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<UserDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IUserDetailsDialogData) {
        super(dialogRef, toastService);
        this.dto = data.dto;
    }

    public ngOnInit(): void {
        this.fullName = this.dto.firstName + ' ' + this.dto.lastName;
    }
}
