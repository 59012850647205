<div class="start-center flex-row"
     id="toolbar">
    <h1 class="mat-title">{{dialogHeading}}</h1>
    <span class="flex"></span>
    <button [attr.tabindex]="disableCloseButtonFocus ? -1 : null"
            mat-icon-button
            (click)="cancel(true)">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="content-container cb-padding-left-2x cb-padding-top-2x cb-padding-right-2x cb-padding-bottom flex-col flex">
    <ng-content></ng-content>
</div>
<ng-content select="[actions]"></ng-content>
