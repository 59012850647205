import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ISpecGroupMappedItem } from '@app/logic/spec-groups/interfaces/i.spec-group.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ISpecGroupDto } from '@app/logic/spec-groups';
import { ISpecGroupsLogicService } from '@app/logic/spec-groups/interfaces/i.spec-groups.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { SpecGroupMappedItem } from '@app/logic/spec-groups/spec-group.mapped';

interface IData {
    mappedItem: SpecGroupMappedItem;
}

@Component({
    selector: 'cb-spec-group-dialog',
    templateUrl: './spec-group-dialog.component.html',
    styleUrls: ['./spec-group-dialog.component.scss']
})
export class SpecGroupDialogComponent extends BaseDialogFormViewDirective<ISpecGroupDto, ISpecGroupMappedItem, ISpecGroupsLogicService> {
    public mappedItem: SpecGroupMappedItem;
    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<SpecGroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
    }
}
