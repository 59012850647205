import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbDasherModule, CbInfoMessageModule, CbLoadingSpinnerModule } from 'cb-hub-lib';
import { CbDialogModule } from './../../shared/components/dialog/dialog.module';
import { CbFormsModule } from './../../shared/components/forms/forms.module';
import { CbPrettyBoolModule } from './../../shared/pipe/pretty-bool/pretty-bool.module';
import { TempLotEditDialogComponent } from './temp-lot-edit-dialog/temp-lot-edit-dialog.component';
import { TempLotListComponent } from './temp-lot-list/temp-lot-list.component';
import { UploadBrochureDialogComponent } from './upload-brochure-dialog/upload-brochure-dialog.component';

const MAT_MODULES = [
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatRadioModule
];


@NgModule({
    declarations: [TempLotListComponent, TempLotEditDialogComponent, UploadBrochureDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        CbFormsModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        CbDialogModule,
        CbPrettyBoolModule,
        CbDocumentsModule,
        CbCurrencyModule,
        CbDasherModule,
        RouterModule.forRoot([
            {
                component: TempLotListComponent,
                path: 'temp-lots',
                data: {
                    breadcrumb: 'Temporary Lots'
                }
            }
        ], {}),
        ...MAT_MODULES
    ]
})
export class TempLotsModule { }
