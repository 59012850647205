import {Component, Input} from '@angular/core';
import {ILotMappedItem} from '@app/logic/lots';
import {IWorkingDrawingMappedItem} from '@app/logic/working-drawings';

@Component({
    selector: 'cb-working-drawing-details',
    templateUrl: './working-drawing-details.component.html',
    styleUrls: ['./working-drawing-details.component.scss']
})
export class WorkingDrawingDetailsComponent {

    @Input() public mappedItem: IWorkingDrawingMappedItem;
    @Input() public lotMappedItem: ILotMappedItem;

    constructor() { }

}
