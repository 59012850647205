<cb-dialog>
    <form class="flex-col flex"
          #designSchemeNotificationsForm="ngForm">
        <cb-input type="text"
                  name="label"
                  label="Revision Number"
                  [required]="true"
                  [(ngModel)]="mappedItem.label">
        </cb-input>
        <cb-checkbox name="isActive"
                     label="Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="!designSchemeNotificationsForm.dirty || !designSchemeNotificationsForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
