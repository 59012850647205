import {Component, Input} from '@angular/core';
import {BuildTypeLogicService} from '@app/logic/build-type';
import {IDesignSchemeMappedItem} from '@app/logic/design-schemes';
import {DocumentTypesLogicService, IDocumentTypeDto} from '@app/logic/document-types';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {toPromisedArray} from 'cb-hub-lib';

@Component({
    selector: 'cb-request-design-scheme-step-seven',
    templateUrl: './request-scheme-step-seven.component.html',
    styleUrls: ['./request-scheme-step-seven.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class RequestDesignSchemeStepSevenComponent {
    // Use as a guide

    @Input() public mappedItem: IDesignSchemeMappedItem;
    @Input() public documentsToUpload: any;
    public documentTypesLookup: { [documentTypeId: number]: IDocumentTypeDto } = {};
    public readonly buildTypes = toPromisedArray(this.buildTypeLogic.$getList());

    constructor(
        private readonly documentTypeLogic: DocumentTypesLogicService,
        private readonly buildTypeLogic: BuildTypeLogicService,
    ) {
        this.documentTypeLogic
            .$getList()
            .subOnce(results => {
                this.documentTypesLookup = results.reduce(
                    (prev, curr) => {
                        prev[curr.id] = curr;
                        return prev;
                    },
                    {}
                );
            });
    }
}
