import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LotVariationPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IVariationDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Subscription } from 'rxjs';
import { LotChangesVariationsDataService } from '../lot-changes-variations.data.service';
import { LotGenerateVariationDialogComponent } from '../lot-generate-variation-dialog/lot-generate-variation-dialog.component';

@Component({
    selector: 'cb-lot-variations-action-bar',
    templateUrl: './lot-variations-action-bar.component.html',
    styleUrls: ['./lot-variations-action-bar.component.scss'],
})
export class LotVariationsActionBarComponent implements OnInit, OnDestroy {

    @Output() public readonly variationRecordCreated = new EventEmitter<IVariationDto>();

    private sub$ = new Subscription();
    private _lotMappedItem: ILotMappedItem;

    @Input() public set lotMappedItem(item: ILotMappedItem) {
        this._lotMappedItem = item;
        this.sub$.unsubscribe();
        if (this._lotMappedItem) {
            this.sub$ = this._lotMappedItem.$updated.subscribe(() => {
                this.loadUnassignedChangeRecordsCount();
            });
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this._lotMappedItem;
    }

    public $numberofUnassignedChangeRecords: number;

    private readonly subscription: Subscription;
    constructor(
        private readonly lotVariationPermissions: LotVariationPermissions,
        public readonly cbDialog: CbDialogService,
        public readonly toast: ToastService,
        public lotChangesVariationsDataService: LotChangesVariationsDataService) {
        this.subscription = this.lotChangesVariationsDataService.getnumberofUnassignedChangeRecordsCount()
            .subscribe(count => { this.$numberofUnassignedChangeRecords = count; });
    }

    public ngOnInit(): void {
        this.loadUnassignedChangeRecordsCount();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.sub$.unsubscribe();
    }

    private readonly loadUnassignedChangeRecordsCount = (): void => {
        this.lotChangesVariationsDataService.loadUnassignedChangeRecordsCount(this.lotMappedItem.id);
    };

    public canCreateVariation(): boolean {
        return this.lotVariationPermissions.canCreate();
    }

    public canGenerateVariations(): boolean {
        return this.lotMappedItem.canProcessVariations && this.$numberofUnassignedChangeRecords > 0;
    }

    public openGenerateVariationDialog(): void {

        const dialogRef = this.cbDialog.open(LotGenerateVariationDialogComponent,
            {
                data: { lotId: this.lotMappedItem.$id }
            });

        dialogRef.afterClosed().subOnce(result => {
            if (result) {
                this.loadUnassignedChangeRecordsCount();
                this.variationRecordCreated.emit(result);
            }
        });
    }

}
