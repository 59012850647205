import { Component, Input } from '@angular/core';
import { IPreConsentPlanMappedItem } from '@app/logic/pre-consent-plans';

@Component({
    selector: 'cb-pre-consent-details',
    templateUrl: './pre-consent-details.component.html',
    styleUrls: ['./pre-consent-details.component.scss']
})
export class PreConsentDetailsComponent {
    private _mappedItem: IPreConsentPlanMappedItem;
    @Input() public set mappedItem(item: IPreConsentPlanMappedItem) {
        this._mappedItem = item;
    }
    public get mappedItem(): IPreConsentPlanMappedItem {
        return this._mappedItem;
    }
    constructor() { }

}
