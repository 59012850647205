import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ImageUploadDialogComponent } from './image-upload-dialog.component';
import { CbDialogModule } from '../../dialog/dialog.module';
import { FormsModule } from '@angular/forms';

const COMPONENTS = [
    ImageUploadDialogComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        CbDialogModule,
        FormsModule
    ],
    exports: COMPONENTS
})

export class CbImageUploadModule { }
