<cb-dialog dialogHeading="Edit SSR Line">
    <form class="flex-col"
          #editSsrLineForm="ngForm">
        <h2 class="mat-body-2">
            {{lineItem.description}}
        </h2>
        <div class="flex-row">
            <div class="cb-margin-right flex-col flex">
                <cb-input type="number"
                          label="Quantity"
                          name="quantity"
                          [cbMinQuantity]="lineItem.minimumQuantityToRetain"
                          [cbMaxQuantity]="totalQuantityAvailable"
                          [(ngModel)]="lineItemQuantity"
                          (ngModelChange)="validateQuantityChanged($event)"
                          required="true"></cb-input>
                <span *ngIf="editSsrLineForm.controls?.quantity?.errors?.minQuantity"
                      class="error-msg mat-caption cb-error">
                    {{editSsrLineForm.controls?.quantity?.errors?.minQuantity}}
                </span>
                <span *ngIf="editSsrLineForm.controls?.quantity?.errors?.maxQuantity"
                      class="error-msg mat-caption cb-error">
                    {{editSsrLineForm.controls?.quantity?.errors?.maxQuantity}}
                </span>
                <cb-display-value-new label="Rate">
                    {{
                        lineItem.rate | cbCurrency}} x {{
                        lineItem.uom}}
                </cb-display-value-new>
                <cb-display-value-new label="Cost Type"
                                      [value]="
                                  lineItem.costTypeLabel"></cb-display-value-new>
            </div>
            <div class="flex-col flex">
                <cb-display-value-new label="Unallocated Quantity">
                    {{calculatedUnallocatedQuantity | number:'1.2-2'}} {{
                        lineItem.uom}}
                </cb-display-value-new>
                <cb-display-value-new label="Unallocated Total">
                    {{(calculatedUnallocatedQuantity * lineItem.rate) | cbCurrency}}
                </cb-display-value-new>
                <cb-display-value-new label="Minimum Quantity to Retain">
                    {{ lineItem.minimumQuantityToRetain}}
                </cb-display-value-new>
            </div>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="saveInProgress || editSsrLineForm.invalid || editSsrLineForm.pristine || !canUpdateQuantity()"
                (click)="saveQuantity()">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
