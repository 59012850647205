import {Component, HostBinding} from '@angular/core';

@Component({
    selector: 'cb-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent {

    public expandIcon = 'chevron_right';

    @HostBinding('class.expanded') public expanded = true;

    constructor() { }

    public toggle(): void {
        this.expanded = !this.expanded;
        this.expandIcon = this.expanded ? 'chevron_right' : 'chevron_left';
    }

}
