<cb-dialog [dialogHeading]="isEdit() ? 'Edit Stage' : 'Add Stage'">
    <form #stageForm="ngForm">
        <h3 class="cb-title">
            Payment Trigger
        </h3>
        <mat-radio-group class="flex-col"
                         name="triggerType"
                         [(ngModel)]="triggerType"
                         (change)="clearDropdowns()">
            <mat-radio-button [value]="TRIGGER_TYPE.BuildStage">
                Build Stage
            </mat-radio-button>
            <mat-radio-button [value]="TRIGGER_TYPE.Event">
                Event
            </mat-radio-button>
        </mat-radio-group>
        <cb-select *ngIf="triggerType === TRIGGER_TYPE.BuildStage"
                   label="Build Stage"
                   name="buildStage"
                   [(ngModel)]="buildStageId"
                   [options]="buildStages"
                   [required]="true"></cb-select>
        <cb-select *ngIf="triggerType === TRIGGER_TYPE.Event"
                   label="Event"
                   name="eventSelect"
                   [(ngModel)]="paymentTemplateStage.trigger"
                   [options]="buildStageEvents"
                   [required]="true"></cb-select>
        <h3 class="cb-title">
            Payment Details
        </h3>
        <cb-input type="number"
                  label="Payment Amount"
                  name="paymentAmount"
                  [(ngModel)]="paymentTemplateStage.paymentAmount"
                  [required]="true"></cb-input>
        <label for="amountType"
               class="mat-caption">
            Amount Type
        </label>
        <mat-radio-group class="cb-margin-top flex-col"
                         name="amountType"
                         [(ngModel)]="paymentTemplateStage.amountType">
            <mat-radio-button [value]="PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM.Percentage">
                Percentage
            </mat-radio-button>
            <mat-radio-button [value]="PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM.DollarValue">
                Dollar Amount
            </mat-radio-button>
        </mat-radio-group>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="stageForm.invalid || stageForm.pristine"
                (cbClick)="save()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
