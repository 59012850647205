import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FieldValidationItem } from '@app/core/services/field-validation/field.validation.item';
import { LOT_COMPONENT_TYPE } from '@app/core/services/field-validation/lot-field-validator/interfaces/i.lot.field.rules.service';
import { LotFieldValidatorService } from '@app/core/services/field-validation/lot-field-validator/lot-field-validator-service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BuildProgrammeTemplateLogicService, IBuildProgrammeTemplateDto } from '@app/logic/build-programme-template';
import { ILotMappedItem } from '@app/logic/lots';
import { ILotsLogicService } from '@app/logic/lots/interfaces/i.lots.logic.service';
import { INonWorkingDayDto, NonWorkingDayLogicService } from '@app/logic/non-working-day';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { COMPASS_DIRECTION_TYPE_ENUM, ILotDto, MARKET_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import {LotPermissions} from '@app/core/permissions';
import {MarketStatusEnumId} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/enums/MarketStatusEnum';
import {LotTypeEnumId} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/enums/LotTypeEnum';

@Component({
    selector: 'cb-lot-details-dialog',
    templateUrl: './lot-details-dialog.component.html',
    styleUrls: ['./lot-details-dialog.component.scss']
})
export class LotDetailsDialogComponent extends BaseDialogFormViewDirective<ILotDto, ILotMappedItem, ILotsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '900px';

    public buildProgrammeTemplates: IBuildProgrammeTemplateDto[];
    public nonWorkingDays: INonWorkingDayDto[];
    public COMPASS_DIRECTION_TYPE_ENUM = COMPASS_DIRECTION_TYPE_ENUM;
    public MARKET_STATUS_ENUM = MARKET_STATUS_ENUM;

    // Field Validation Items
    public jobNumberField: FieldValidationItem;
    public lotNumberField: FieldValidationItem;

    constructor(
        public readonly dialogRef: MatDialogRef<LotDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: ILotMappedItem,
        @Inject(ToastService) public readonly toastService: ToastService,
        public readonly lotPermissions: LotPermissions,
        @Inject(LotFieldValidatorService) public readonly lotFieldValidatorService: LotFieldValidatorService,
        @Inject(BuildProgrammeTemplateLogicService) private readonly buildTemplateLogic: BuildProgrammeTemplateLogicService,
        @Inject(NonWorkingDayLogicService) private readonly nonWorkingDayLogic: NonWorkingDayLogicService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data;
        this.buildTemplateLogic
            .getBuildProgrammeTemplatesByAddressRegionLookup(this.data.lotAddress.region, this.data.buildProgrammeTemplateId, this.data.id)
            .subOnce(result => this.buildProgrammeTemplates = result);
        this.nonWorkingDayLogic.getFutureNonWorkingDays().subOnce(result => this.nonWorkingDays = result);
    }

    public ngOnInit(): void {
        this.populateFieldValidationItems();
    }

    public ShowPriceOptions(): boolean {
        if (this.mappedItem.marketStatus !== MarketStatusEnumId.Available) {
            return false;
        }
        if (!this.mappedItem.project) {
            return false;
        }
        if (this.mappedItem.isWithheldFromWebsite) {
            return false;
        }
        return (
            this.mappedItem.lotType === LotTypeEnumId.Standalone ||
            (this.mappedItem.lotType === LotTypeEnumId.Unit)
        );
    }

    private populateFieldValidationItems(): void {
        this.lotFieldValidatorService.dto = this.mappedItem.$getMappedDtoItem();

        this.jobNumberField = this.lotFieldValidatorService.jobNumber(LOT_COMPONENT_TYPE.EditLotDetails);
        this.lotNumberField = this.lotFieldValidatorService.lotNumber(LOT_COMPONENT_TYPE.EditLotDetails);
    }

    public canEditTemplate(): boolean {
        return (this.mappedItem?.parentId === undefined || this.mappedItem?.isPrimaryUnit)
            && !this.mappedItem?.hasUnits
            && !this.mappedItem.hasBuildProgrammeTemplateApplied;
    }

}
