<div class="cb-margin flex-col">
    <mat-tab-group (selectedIndexChange)="onTabChanged($event);"
                   [selectedIndex]="selectedTabIndex"
                   cbTabUrlParam="cbTabRouteId2"
                   [cbTabRoute]="LOTS_ROUTE">
        <mat-tab label="Design Requirements"
                 cbTabRouteId="design-requirements"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-design-requirements *cbWaitFor="lotMappedItem$$ | async"
                                        [lotMappedItem]="lotMappedItem"
                                        (lotMappedItemChange)="lotMappedItemChanged()"></cb-design-requirements>
            </ng-template>
        </mat-tab>
        <mat-tab label="Schemes"
                 cbTabRouteId="schemes"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-design-schemes-view *cbWaitFor="lotMappedItem$$ | async"
                                        [lotMappedItem]="lotMappedItem"></cb-design-schemes-view>
            </ng-template>
        </mat-tab>
        <mat-tab label="Concepts"
                 cbTabRouteId="concepts"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-design-concepts *cbWaitFor="lotMappedItem$$ | async"
                                    [lotMappedItem]="lotMappedItem"
                                    (lotMappedItemChange)="lotMappedItemChanged()"></cb-design-concepts>
            </ng-template>
        </mat-tab>
        <mat-tab label="Pre-Consent"
                 cbTabRouteId="pre-consent"
                 cbNestedTabLevels="0"
                 [disabled]="isPreConsentTabDisabled()">
            <ng-template matTabContent>
                <cb-pre-consent *cbWaitFor="lotMappedItem$$ | async"
                                [lotMappedItem]="lotMappedItem"
                                (lotMappedItemChange)="lotMappedItemChanged()"></cb-pre-consent>
            </ng-template>
        </mat-tab>
        <mat-tab label="Working Drawings"
                 cbTabRouteId="working-drawings"
                 cbNestedTabLevels="0"
                 *cbWaitFor="lotMappedItem$$ | async"
                 [disabled]="isWorkingDrawingsTabDisabled()">
            <ng-template matTabContent>
                <cb-working-drawings *cbWaitFor="lotMappedItem$$ | async"
                                     [lotMappedItem]="lotMappedItem"
                                     (lotMappedItemChange)="lotMappedItemChanged()"></cb-working-drawings>
            </ng-template>
        </mat-tab>
        <mat-tab label="Consent"
                 cbTabRouteId="consent"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-consents-main *cbWaitFor="lotMappedItem$$ | async"
                                      [lotMappedItem]="lotMappedItem"
                                      (reloadLotMappedItem)="reloadLotMappedItem.emit()"></cb-lot-consents-main>
            </ng-template>
        </mat-tab>
        <mat-tab label="Council RFIs"
                 cbTabRouteId="council-rfis"
                 cbNestedTabLevels="0"
                 [disabled]="isRFIsTabDisabled()">
            <ng-template matTabContent>
                <cb-council-rfi-main *cbWaitFor="lotMappedItem$$ | async"
                                     [lotMappedItem]="lotMappedItem"></cb-council-rfi-main>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
