import { Component, Input } from '@angular/core';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';
import { LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-client-sale-details',
    templateUrl: './client-sale-details.component.html',
    styleUrls: ['./client-sale-details.component.scss']
})
export class ClientSaleDetailsComponent {

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    constructor() { }

}
