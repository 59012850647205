import {
    IIdAndLabelDto,
    IProjectDetailsDto,
    IProjectReleaseStageDto,
    PROJECT_RELEASE_STATUS_ENUM,
    PROJECT_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { filter } from 'lodash';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IDocumentEntity } from '../documents';
import { INoteEntity } from '../standard-plans';
import { IProjectMappedItem } from './interfaces/i.project.mapped';
import { IProjectsLogicService } from './interfaces/i.projects.logic.service';

export class ProjectMappedItem
    extends BaseMappedItem<IProjectDetailsDto, IProjectMappedItem, IProjectsLogicService>
    implements IProjectMappedItem, INoteEntity<number>, IDocumentEntity<number> {

    @DtoProp public id: number;
    @DtoProp public projectName: string;
    @DtoProp public label: string;
    @DtoProp public accountOwner: IIdAndLabelDto;
    @DtoProp public address: any;
    @DtoProp public contacts: any;
    @DtoProp public code: string;
    @DtoProp public dpNumber: string;
    @DtoProp public teamMembers: any;
    @DtoProp public locationId: number;
    @DtoProp public currentStage: IProjectReleaseStageDto;
    @DtoProp public releaseStages: IProjectReleaseStageDto[];
    @DtoProp public businessentity: IIdAndLabelDto;
    @DtoProp public status: number;
    @DtoProp public cancellationReason: string;
    @DtoProp public totalNoStages: number;
    @DtoProp public buildingConsentCouncilId: number;
    @DtoProp public lot: string;
    @DtoProp public stage: string;
    @DtoProp public hasProjectLevelRates: boolean;
    @DtoProp public description: string;
    @DtoProp public businessEntity: IIdAndLabelDto;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public areAllLotsToBeSoldToOneProjectClient: boolean;
    @DtoProp public projectLogoUrl: string;

    constructor(
        sourceData: IProjectDetailsDto,
        logicService: IProjectsLogicService
    ) {
        super(sourceData, logicService, ProjectMappedItem);
    }

    public get noteEntityId(): number {
        return this.id;
    }

    public get noteEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public canEdit(): boolean {
        return this.status === PROJECT_STATUS_ENUM.None ||
            this.status === PROJECT_STATUS_ENUM.Open;
    }

    public canComplete(): boolean {
        if (!this.releaseStages || this.releaseStages.length === 0) {
            return false;
        }
        const numberCompletedProjects =
            filter(this.releaseStages, (o) => o.releaseStatus === PROJECT_RELEASE_STATUS_ENUM.Completed).length;
        if (numberCompletedProjects === this.releaseStages.length) {
            return true;
        } else {
            return false;
        }
    }

    public canCancel(): boolean {
        if (!this.releaseStages || this.releaseStages.length === 0) {
            return true;
        }
        const numberCompletedorCancelledProjects =
        filter(this.releaseStages, (o) =>
            o.releaseStatus === PROJECT_RELEASE_STATUS_ENUM.Completed  ||
            o.releaseStatus === PROJECT_RELEASE_STATUS_ENUM.Cancelled).length;
        if (numberCompletedorCancelledProjects === this.releaseStages.length) {
            return true;
        } else {
            return false;
        }
    }
}
