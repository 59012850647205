import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {LotSpecLogicService} from '@app/logic/lot-spec/lot-spec.logic.service';
import {LotsLogicService} from '@app/logic/lots';
import {SearchIndexesLogicService} from '@app/logic/search-indexes/search-indexes.logic.service';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {ISkinnySpecTemplateDto, SEARCH_INDEX_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {IEnumLookup} from '@classictechsolutions/typescriptenums';
import {orderBy} from 'lodash';

interface IData {
    lotId: number;
}
@Component({
    selector: 'cb-select-lot-template-dialog',
    templateUrl: './select-lot-template-dialog.component.html',
    styleUrls: ['./select-lot-template-dialog.component.scss']
})
export class SelectLotTemplateDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '1000px';

    public lotId: number;
    public templates: ISkinnySpecTemplateDto[];
    public selectedTemplate: number;
    public indexes: IEnumLookup<number>[];
    constructor(
        public readonly dialogRef: MatDialogRef<SelectLotTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        private readonly toastService: ToastService,
        private readonly lotsLogicService: LotsLogicService,
        private readonly lotSpecsLogicService: LotSpecLogicService,
        private readonly searchIndexesLogic: SearchIndexesLogicService
    ) {
        super(dialogRef, toastService);
        this.lotId = data.lotId;
    }

    public ngOnInit(): void {
        this.lotsLogicService.$getMappedItem(this.lotId).subOnce(res => this.mappedItem = res);
        this.lotSpecsLogicService.getAvailableSpecTemplates(this.lotId).subOnce((tpls) => {
            this.templates = orderBy(tpls, 'name', 'asc');
        });
    }

    public onSave() {
        this.lotSpecsLogicService.applySpecTemplate(this.lotId, this.selectedTemplate)
            .subOnce((result) => {
                this.mappedItem.specTemplateId = this.selectedTemplate;

                this.searchIndexesLogic.reindex(SEARCH_INDEX_ENUM.Lot).subOnce(() => {
                    setTimeout(() => {
                        this.toastSerivce.saveSuccess();
                        this.dialogRef.close(result);
                    }, 1000);
                });
            });
    }

}
