<cb-dialog [dialogHeading]="dialogHeading()">
    <form class="flex-col flex"
          #itemForm="ngForm"
          id="itemForm"
          *cbWaitFor="mappedItem">
        <cb-select label="House Area"
                   name="name"
                   [(ngModel)]="mappedItem.specGroupId"
                   [options]="houseAreas"
                   [readonly]="isHouseAreaDisabled()"
                   [required]="true"
                   (change)="specGroupSelected($event)"></cb-select>
        <cb-slot-autocomplete-scroller class="cb-margin-top flex flex-row"
                                       label="Schedule Item Name"
                                       name="selectedScheduleItem"
                                       style="min-width: 300px;"
                                       [(ngModel)]="selectedSlot"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [specGroupId]="isAddItem() ? mappedItem.specGroupId : null"
                                       [lotId]="isCreditItem() ? mappedItem.lotId : null"
                                       [excludeIds]="data.excludedSlotIds"
                                       (optionSelected)="scheduleItemSelected($event)"
                                       required="true"
                                       [shouldShowHouseAreaInLabel]="true"
                                       [readonly]="(isEdit$ | async) || (!(mappedItem?.specGroupId > 0) && isAddItem())">
        </cb-slot-autocomplete-scroller>
        <cb-display-value-new *ngIf="this.isAddItem()"
                              [value]="mappedItem?.currentLotSpecItem"
                              label="Current Lot Spec Item">
        </cb-display-value-new>
        <mat-card class="cb-margin-top-2x flex-col flex">
            <h4 class="mat-h4 cb-margin-bottom-2x">
                <b>Item Details</b>
            </h4>
            <cb-select name="costType"
                       label="Cost Type"
                       [options]="changeOptionLineCostTypes"
                       [required]="true"
                       [(ngModel)]="mappedItem.costType"
                       (change)="clearProductForCostType()"
                       [readonly]="isCreditItem()">
            </cb-select>
            <cb-display-value-new *ngIf="isNotDescriptive()"
                                  [value]="mappedItem?.category?.name"
                                  label="Product Category">
            </cb-display-value-new>
            <div class="start-center flex-row"
                 *ngIf="isNotDescriptive()">
                <cb-product-autocomplete-scroller #productAutocomplete
                                                  class="cb-margin-right flex"
                                                  label="New Product / Description"
                                                  name="product"
                                                  [(ngModel)]="this.mappedItem.selectedProduct"
                                                  [required]="true"
                                                  [requireMatch]="isActual() && isAddItem()"
                                                  [matchProps]="['id']"
                                                  maxlength="250"
                                                  [hideCompositeItems]="false"
                                                  [categoryId]="mappedItem?.category?.id > 0 ? mappedItem?.category?.id : null"
                                                  (searchTextChanged)="productSearchTextChanged($event)"
                                                  [readonly]="this.isCreditItem()"
                                                  (optionSelected)="productUpdate($event)">
                </cb-product-autocomplete-scroller>
                <cb-product-input-find *ngIf="!productAutocomplete.readonly"
                                       name="selectedProduct"
                                       [required]="true"
                                       [buttonOnly]="true"
                                       [activeOnly]="true"
                                       [hideCompositeItems]="true"
                                       [hasRestrictedCategory]="true"
                                       [restrictedCategories]="mappedItem?.category?.id > 0 ? mappedItem?.slotCategory?.categoryTree : []"
                                       label="Product"
                                       (productSelected)="productSelected($event)">
                </cb-product-input-find>
            </div>
            <cb-input *ngIf="this.isDescriptiveOnly()"
                      type="text"
                      name="description"
                      label="Description"
                      required="true"
                      maxlength="250"
                      [(ngModel)]="mappedItem.itemDetails"
                      (change)="descriptionTextChanged()"
                      [readonly]="this.isCreditItem()">
            </cb-input>
            <cb-input type="number"
                      label="Quantity"
                      required="true"
                      [(ngModel)]="mappedItem.quantity"
                      name="quantity"
                      (change)="updateQuantity()"></cb-input>
            <span *ngIf="itemForm.controls?.quantity?.errors?.invalid"
                  class="error-msg mat-caption cb-error">
                Quantity is not allowed to exceed {{this.maximumQuantity}}
            </span>
            <cb-currency label="Rate"
                         name="rate"
                         [(ngModel)]="mappedItem.rate"
                         required="true"
                         [inclGst]="isProvisional()"
                         (change)="getCostAmount()"></cb-currency>
            <cb-currency class="space-below"
                         label="Amount"
                         name="costAmount"
                         required="true"
                         [(ngModel)]="mappedItem.costAmount"
                         [readonly]="true">
            </cb-currency>
            <cb-checkbox *ngIf="isAddItem()"
                         name="includeInCreateSchedule"
                         label="Include in Flyer / Create Schedule"
                         [(ngModel)]="mappedItem.showInClientSpecification"
                         [readonly]="isView$ | async">
            </cb-checkbox>
            <cb-checkbox *ngIf="isAddItem()"
                         name="includeInColourSchedule"
                         label="Include in Colour Schedule"
                         [(ngModel)]="mappedItem.showInColourYourDreams"
                         [readonly]="isView$ | async">
            </cb-checkbox>
            <cb-checkbox *ngIf="isAddItem()"
                         name="manualColourEntryRequired"
                         label="Manual Colour Entry Required"
                         [(ngModel)]="mappedItem.manualColourEntryRequired"
                         [readonly]="isView$ | async">
            </cb-checkbox>
            <cb-text-area label="QS Comments"
                          name="comments"
                          [(ngModel)]="mappedItem.comments"
                          maxlength="1024">
            </cb-text-area>
        </mat-card>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="addOrUpdateItem()"
                    [clickLimit]="1"
                    [disabled]="itemForm.pristine || itemForm.invalid">
                {{ (isEdit$ | async) ? 'Update' : 'Add'}}
            </button>
            <button mat-raised-button
                    (cbClick)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
