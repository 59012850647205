import {Injectable, Injector} from '@angular/core';
import {HttpWrapperService} from '../../core/services/http-wrapper/http-wrapper.service';
import {ISpecGroupsLogicService} from './interfaces/i.spec-groups.logic.service';
import {BaseLogicService} from '../base/base-logic.service';
import {ISpecGroupDto} from './interfaces/i.spec-group.dto';
import {ISpecGroupMappedItem} from './interfaces/i.spec-group.mapped';
import {SpecGroupMappedItem} from './spec-group.mapped';

@Injectable()
export class SpecGroupsLogicService extends BaseLogicService<ISpecGroupDto, ISpecGroupMappedItem> implements ISpecGroupsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('houseareas', SpecGroupMappedItem);
    }
}
