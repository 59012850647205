import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DesignSchemesLogicService } from '@app/logic/design-schemes';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { DESIGN_SCHEME_STATUS_ENUM, DesignSchemeStatusEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { filter } from 'lodash';
import { ICustomProjectReleaseLotDesignSummaryDto } from '../../release-lots-design.component';
import { RequestSchemesDialogComponent } from '../request-schemes-dialog/request-schemes-dialog.component';

export interface IRequestPricingDialogData {
    lots: ICustomProjectReleaseLotDesignSummaryDto[];
}

@Component({
    selector: 'cb-request-pricing-dialog',
    templateUrl: './request-pricing-dialog.component.html',
    styleUrls: ['./request-pricing-dialog.component.scss']
})
export class RequestPricingDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '50%';
    public lots: ICustomProjectReleaseLotDesignSummaryDto[];
    private itemsToRequestPricingOn = 0;
    public loaded = false;
    public filteredLots: ICustomProjectReleaseLotDesignSummaryDto[];
    public performingSave = false;
    public DESIGN_SCHEME_STATUS_ENUM = DESIGN_SCHEME_STATUS_ENUM;
    public designSchemeStatuses = DESIGN_SCHEME_STATUS_ENUM.toLookup().unshift({ id: undefined, label: 'Any' } as IEnumLookup<DesignSchemeStatusEnumId>);
    constructor(
        public readonly dialogRef: MatDialogRef<RequestSchemesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IRequestPricingDialogData,
        private readonly toastService: ToastService,
        private readonly designSchemeLogicService: DesignSchemesLogicService
    ) {
        super(dialogRef, toastService);
        this.lots = data.lots;
    }

    public ngOnInit(): void {
        this.filterLots();
        this.loaded = true;
    }

    public requestPricing(): void {
        this.filteredLots.forEach((item) => {
            if (item.$shouldApplyConcept) {
                this.itemsToRequestPricingOn += 1;
                this.designSchemeLogicService.requestPricingForLot(item.id, false).subOnce(() => {
                    this.handleRequestedPricingOnItem();
                });
            }
        });
    }

    public isSaveDisabled(form: UntypedFormGroup): boolean {
        return form.invalid || form.pristine || !this.hasSelectedLots();
    }

    private handleRequestedPricingOnItem = () => {
        this.itemsToRequestPricingOn -= 1;

        if (this.itemsToRequestPricingOn === 0) {
            this.toastSerivce.saveSuccess();
            this.dialogRef.close(true);
        }
    };

    public hasSelectedLots(): boolean {
        let hasSelectedLots = false;

        this.filteredLots.forEach((item) => {
            if (item.$shouldApplyConcept) {
                hasSelectedLots = true;
                return false;
            }
        });

        return hasSelectedLots;
    }

    public clicked(lot: ICustomProjectReleaseLotDesignSummaryDto, form: UntypedFormGroup) {
        lot.$shouldApplyConcept = !lot.$shouldRequestDesignScheme;
        form.markAsDirty();
    }

    private filterLots() {
        this.filteredLots = filter(this.lots, (lot) => {
            if (this.canRequestPricingToDesignScheme(lot)) {
                lot.$shouldApplyConcept = false;
            }
            return this.canRequestPricingToDesignScheme(lot);
        });
    }

    private canRequestPricingToDesignScheme(lot: ICustomProjectReleaseLotDesignSummaryDto) {
        return lot.canRequestDesignSchemePricing && lot.designSchemeStatus === DESIGN_SCHEME_STATUS_ENUM.Accepted;
    }
}
