import { TemplatePortal } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { ActivatedRoute } from '@angular/router';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { cloneDeep } from 'lodash';
import { map, Observable, Subscription } from 'rxjs';

@Directive({
    selector: 'mat-tab-group[cbTabRoute]'
})
export class TabRouteDirective implements OnDestroy, AfterViewInit {

    public static readonly TAB_ID_ATTR = 'cbTabRouteId';
    public static readonly LEVELS_ATTR = 'cbNestedTabLevels';

    @Input() public cbTabRoute: string;
    @Input() public cbTabUrlParam = TabRouteDirective.TAB_ID_ATTR;
    @Input() public cbDefaultTabId: string;

    private readonly sub$ = new Subscription();

    constructor(
        private readonly host: MatTabGroup,
        private readonly location: Location,
        private readonly route: ActivatedRoute
    ) { }

    public ngAfterViewInit(): void {
        this.init();
    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    private readonly init = (): void => {
        this.sub$.add(
            this.host.selectedTabChange
                .subscribe({ next: this.handleTabChangeEvent })
        );
        this.loadInitialSelectedTab();
    };

    private readonly handleTabChangeEvent = (event: MatTabChangeEvent): void => {
        this.handleTabChange(event.tab.content);
    };

    private handleTabChange(content: TemplatePortal<any>): void {
        this.route.params
            .subOnce((params: any) => {
                const currentTab = this.getTabId(content);
                const newParams: { [key: string]: string } = cloneDeep(params);
                newParams[this.cbTabUrlParam] = currentTab.id;

                const split = this.cbTabRoute.split('/');
                const routeEndIndex = split.indexOf(`:${this.cbTabUrlParam}`) + 1 + currentTab.levels;

                // clear extraneous route params
                split
                    .slice(routeEndIndex, split.length)
                    .filter(x => !isNullOrWhiteSpace(x) && x.startsWith(':'))
                    .forEach((value) => {
                        delete newParams[value.split(':').pop()];
                    });

                // map current params to new path/url
                const path = split
                    .slice(0, routeEndIndex)
                    .filter(x => !isNullOrWhiteSpace(x))
                    .reduce((str: string, currentValue: string) => {
                        const key = currentValue.split(':').pop();
                        if (isNullOrWhiteSpace(key)) {
                            return str;
                        }
                        if (currentValue.includes(':') && !isNullOrWhiteSpace(newParams[key])) {
                            return `${str}/${newParams[key]}`;
                        }
                        if (!currentValue.includes(':') && !isNullOrWhiteSpace(key)) {
                            return `${str}/${key}`;
                        }
                        return str;
                    }, '');

                // replace state and prepare for next tab change
                this.location.replaceState(path);
                this.route.params = new Observable<any>((ob) => ob.next(newParams));
            });
    }

    private loadInitialSelectedTab(): void {
        this.route.params.pipe(
            map((params: any): string | null => {
                return params[this.cbTabUrlParam] ||
                    this._getTabIdFromUrl() ||
                    this.cbDefaultTabId ||
                    this.getTabId(this?.host?._tabs?.first?.content);
            })
        ).subOnce(currentTabId => {
            let tab = this?.host?._tabs?.first;
            if (!isNullOrWhiteSpace(currentTabId)) {
                tab = this?.host?._tabs?.find(x => this.getTabId(x.content).id === currentTabId) ?? tab;
            }
            const start = this?.host?._tabs.first?.position;
            let index;
            if (start < 0) {
                index = tab.position - start;
            } else {
                index = tab.position + start;
            }
            this.host.selectedIndex = index ?? 0;
            this.handleTabChange(tab?.content);
        });
    }

    private _getTabIdFromUrl(): string {
        return this.route?.snapshot?.url[this.route.snapshot.url.length - 1]?.path;
    }

    private getTabId(content: TemplatePortal<any>): {
        /** tab id */
        id: string | undefined;
        /** count of levels of nested tab groups */
        levels: number;
    } {
        const ele = (content?.viewContainerRef?.element?.nativeElement as HTMLElement);
        const attrs = ele?.attributes;
        return {
            id: attrs.getNamedItem(TabRouteDirective.TAB_ID_ATTR)?.nodeValue,
            levels: Number(attrs.getNamedItem(TabRouteDirective.LEVELS_ATTR)?.nodeValue ?? 0),
        };
    }
}
