import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CouncilRfiLogicService } from '@app/logic/council-rfis';
import { IBuildingConsentMappedItem, IResourceConsentMappedItem } from '@app/logic/lot-consents';

import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { EditCouncilRfiDialogComponent } from '../../council-rfi/edit-council-rfi-dialog/edit-council-rfi-dialog.component';
import { LotDesignPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { orderBy } from 'lodash';
import { ICouncilRfiDto, ILotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cb-lot-consent-rfis',
    templateUrl: './lot-consent-rfis.component.html',
    styleUrls: ['./lot-consent-rfis.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotConsentRfisComponent implements OnDestroy {

    public councilRfis: ICouncilRfiDto[];
    @Input() public lotDto: ILotDto;
    @Output() public councilRfiCount = new EventEmitter<number>();

    private _mappedItem: IBuildingConsentMappedItem | IResourceConsentMappedItem;
    @Input() public set mappedItem(v: IBuildingConsentMappedItem | IResourceConsentMappedItem) {
        if (this._mappedItem !== v) {
            this._mappedItem = v;
            this.councilRfiLogic.getByConsentId(this.mappedItem.id).subOnce(this.handleCouncilRfis);

            this._subscription?.unsubscribe();
            this._subscription = this._mappedItem.$updated.subscribe(() => {
                this.cdRef.detectChanges();
            });
        }
    }
    public get mappedItem(): IBuildingConsentMappedItem | IResourceConsentMappedItem {
        return this._mappedItem;
    }

    private _subscription: Subscription;

    constructor(
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly councilRfiLogic: CouncilRfiLogicService,
        public readonly cbDialog: CbDialogService,
        public readonly navigation: NavigationService,
        private readonly cdRef: ChangeDetectorRef
    ) {
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public canCreateRFI(): boolean {
        return this.lotDesignPermissions.canCreateRFI();
    }

    public addCouncilRfi(): void {
        this.cbDialog.open(EditCouncilRfiDialogComponent, {
            data: {
                councilRfi: this.councilRfiLogic.$createMappedItem({ lotId: this.lotDto.id, councilName: this.lotDto.councilName, }),
                dialogHeading: 'Add Council RFI',
            }
        })
            .afterClosed()
            .subOnce((result: ICouncilRfiDto) => {
                if (result?.id > 0) {
                    this.councilRfis.unshift(result);
                    this.handleCouncilRfis(this.councilRfis);
                    this.lotDto.hasCouncilRfi = true;
                }
            });
    }

    private readonly handleCouncilRfis = (results: ICouncilRfiDto[]): void => {
        this.councilRfis = orderBy(results, '$id', 'desc');
        this.councilRfiCount.emit(this.councilRfis?.length ?? 0);
        this.cdRef.detectChanges();
    };

    public view(councilRfiId: number): void {
        const url = this.navigation.currentPath.replace('consent', 'council-rfis');
        this.navigation.navigate([url], undefined, { queryParams: { rfiId: councilRfiId } });
    }
}
