import { Component, Input, OnChanges } from '@angular/core';
import { ClientSalePermissions } from '@app/core/permissions';
import { GstService } from '@app/core/services/gst/gst.service';
import { PaymentTemplatesLogicService } from '@app/logic/payment-templates/payment-templates.logic.service';
import {
    BUILD_STAGE_EVENT_ENUM,
    IClientSaleContractPricingUpdateDto,
    ILotProgressPaymentDto,
    PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { PaymentTemplateAmountTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/enums/PaymentTemplateAmountType';

@Component({
    selector: 'cb-progress-payments-table',
    templateUrl: './progress-payments-table.component.html',
    styleUrls: ['./progress-payments-table.component.scss'],
    providers: [
        PaymentTemplatesLogicService
    ]
})
export class ProgressPaymentsTableComponent implements OnChanges {
    public PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM = PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM;
    public BUILD_STAGE_EVENT_ENUM = BUILD_STAGE_EVENT_ENUM;
    public mappedItem: ILotProgressPaymentDto;
    private readonly percentageMultiplier = 100;
    private readonly roundingMultiplier = 1e2;
    /** Used to remember previously entered values in the
     * progress payments for a specific payment template.
     * Gets cleared upon dialog close.
     */
    public progressPaymentsCache: { [paymentTemplateId: number]: ILotProgressPaymentDto[] } = {};
    @Input() public contractPricingUpdateDto: IClientSaleContractPricingUpdateDto;

    constructor(
        private readonly clientSalePermissions: ClientSalePermissions,
        private readonly gstService: GstService
    ) {
    }

    public percentageReadOnly(progressPayment: ILotProgressPaymentDto): boolean {
        return progressPayment
            .amountType !== PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM.Percentage ||
            !this.canEditPaymentsTable;
    }

    public get dollarValueReadOnly(): boolean {
        return !this.canEditPaymentsTable;
    }

    public get canViewPaymentsTable(): boolean {
        return this.clientSalePermissions.canViewPayments();
    }

    public get canEditPaymentsTable(): boolean {
        return this.clientSalePermissions.canViewPayments() &&
            this.clientSalePermissions.canEditPayment();
    }

    public get balance(): number {
        return this._contractPrice - this.stagePaymentsAmountTotal;
    }

    public get stagePaymentsAmountTotal(): number {
        if (this.contractPricingUpdateDto.lotProgressPayments != null &&
            this.contractPricingUpdateDto.lotProgressPayments.length > 0)
        {
            return this.contractPricingUpdateDto.lotProgressPayments.reduce((acc, progressPayment) => {
                if (progressPayment.dollarValue && !isNaN(progressPayment.dollarValue)) {
                    return acc + progressPayment.dollarValue;
                } else {
                    return acc + 0;
                }
            }, 0);
        } else {
            return 0;
        }
    }

    public get percentTotal(): number {
        if (this.contractPricingUpdateDto.lotProgressPayments != null &&
            this.contractPricingUpdateDto.lotProgressPayments.length > 0)
        {
            return this.contractPricingUpdateDto.lotProgressPayments.reduce((acc, progressPayment) => {
                if (progressPayment.percentage && !isNaN(progressPayment.percentage)) {
                    const newPercentage = acc + +progressPayment.percentage;
                    return Math.round(newPercentage * this.roundingMultiplier) / this.roundingMultiplier;
                } else {
                    const newPercentage = acc + 0;
                    return Math.round(newPercentage * this.roundingMultiplier) / this.roundingMultiplier;
                }
            }, 0);
        } else {
            return 0;
        }
    }

    public setDollarValueFromPercentage(progressPayment: ILotProgressPaymentDto): void {
        if (progressPayment.percentage != null) {
            progressPayment.dollarValue = (progressPayment.percentage / this.percentageMultiplier) * this._contractPrice;
        }
    }

    public setPercentageFromDollarValue(progressPayment: ILotProgressPaymentDto): void {
        if(!isNaN(progressPayment.dollarValue)) {
            progressPayment.percentage = (progressPayment.dollarValue / this._contractPrice) * this.percentageMultiplier;
        }
    }

    private get _contractPrice(): number {
        return this.gstService.toGstInclusive(
            this.contractPricingUpdateDto.contractPrice
        );
    }

    public ngOnChanges(): void {
        const realContractPrice = this._contractPrice;
        this.contractPricingUpdateDto.lotProgressPayments.forEach(x=> {
            if (x.amountType === PaymentTemplateAmountTypeEnumId.Percentage) {
                x.dollarValue = realContractPrice * x.percentage / 100;
            }
        });
    }
}
