<div class="flex-col flex-100">
    <span class="cb-margin-bottom">
        <b>Items to Add</b>
    </span>
    <cb-table label="Change Records"
              [columns]="addDisplayedColumns"
              [loaded]="changeOptionMappedItem"
              class="cb-margin-bottom-2x flex">
        <tr cbTableRow
            *ngFor="let optionLine of changeOptionMappedItem.changeOptionLines | cbFilter : 'changeDirection' : CHANGE_DIRECTION_ENUM.Add">
            <td>{{optionLine.specGroupName}}</td>
            <td>{{optionLine.slotDto?.name}}</td>
            <td>{{optionLine.selectedProduct?.id}}</td>
            <td>
                <cb-info-popup [label]="optionLine.selectedProduct?.name || optionLine.itemDetails"
                               [tooltip]="optionLine.comments">
                </cb-info-popup>
            </td>
            <td>{{optionLine.quantity}}</td>
            <td>{{ getAddOrUpdateText(optionLine) }}</td>
            <td>
                {{optionLine.costAmount | cbCurrency}}
            </td>
            <cb-td-enum [value]="optionLine?.costType"
                        [enum]="COST_TYPE_ENUM">
            </cb-td-enum>
            <td>{{getQRef(optionLine) }}</td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        [matMenuTriggerFor]="addOptionMenu"
                        [disabled]="isActionMenuDisabled">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #addOptionMenu="matMenu">
                    <button mat-menu-item
                            (click)="removeOptionLine(optionLine)">
                        <mat-icon>remove_circle</mat-icon>
                        {{getRemoveButtonText(optionLine)}}
                    </button>
                    <button mat-menu-item
                            (click)="editLineItem(optionLine)">
                        <mat-icon>edit</mat-icon>
                        {{getEditButtonText(optionLine)}}
                    </button>
                </mat-menu>
            </td>
        </tr>
        <tr cbTableRow>
            <td colspan="9">

            </td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        [disabled]="isActionMenuDisabled"
                        (click)="addLineItem(CHANGE_DIRECTION_ENUM.Add)">
                    <mat-icon>add</mat-icon>
                </button>
            </td>
        </tr>
        <tr cbTableRow>
            <td colspan="6">

            </td>
            <td>
                <b>{{sumAddAmounts | cbCurrency}}</b>
            </td>
            <td colspan="3"></td>
        </tr>
    </cb-table>
    <span class="cb-margin-bottom"
          style="color:red;">
        <b>Items to Credit</b>
    </span>
    <cb-table label="Change Records"
              [columns]="creditDisplayedColumns"
              [loaded]="changeOptionMappedItem"
              class="cb-margin-bottom-2x flex">
        <tr cbTableRow
            *ngFor="let optionLine of changeOptionMappedItem.changeOptionLines | cbFilter : 'changeDirection' : CHANGE_DIRECTION_ENUM.Credit">
            <td>{{optionLine.specGroupName}}</td>
            <td>{{optionLine.slotDto?.name}}</td>
            <td>{{optionLine.selectedProduct?.id}}</td>
            <td>
                <cb-info-popup [label]="optionLine.selectedProduct?.name || optionLine.itemDetails"
                               [tooltip]="optionLine.comments">
                </cb-info-popup>
            </td>
            <td>{{optionLine.quantity}}</td>
            <td>{{ getAddOrUpdateText(optionLine) }}</td>
            <td>
                {{optionLine.costAmount | cbCurrency}}
            </td>
            <cb-td-enum [value]="optionLine?.costType"
                        [enum]="COST_TYPE_ENUM">
            </cb-td-enum>
            <td>{{getQRef(optionLine) }}</td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        [matMenuTriggerFor]="addOptionMenu"
                        [disabled]="isActionMenuDisabled">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #addOptionMenu="matMenu">
                    <button mat-menu-item
                            (click)="removeOptionLine(optionLine)"
                            [disabled]="!canRemoveCreditLineItem(optionLine)">
                        <mat-icon>remove_circle</mat-icon>
                        {{getRemoveButtonText(optionLine)}}
                    </button>
                    <button mat-menu-item
                            (click)="editLineItem(optionLine)">
                        <mat-icon>edit</mat-icon>
                        {{getEditButtonText(optionLine)}}
                    </button>
                </mat-menu>
            </td>
        </tr>
        <tr cbTableRow>
            <td colspan="9">

            </td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        [disabled]="isActionMenuDisabled"
                        (click)="addLineItem(CHANGE_DIRECTION_ENUM.Credit)">
                    <mat-icon>add</mat-icon>
                </button>
            </td>
        </tr>
        <tr cbTableRow>
            <td colspan="6">

            </td>
            <td>
                <b>{{sumCreditAmounts | cbCurrency}}</b>
            </td>
            <td colspan="3"></td>
        </tr>
    </cb-table>
    <ng-content select="[netChange]"></ng-content>
</div>
