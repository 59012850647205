import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { INotificationTemplateMappedItem } from './interfaces/i.notification-template.mapped';
import { INotificationTemplateDto, INotificationTemplateMediumDto } from './interfaces/i.notification-template.dto';
import { INotificationTemplateLogicService } from './interfaces/i.notification-template.logic.service';

export class NotificationTemplateMappedItem
    extends BaseMappedItem<INotificationTemplateDto, INotificationTemplateMappedItem, INotificationTemplateLogicService>
    implements INotificationTemplateMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public label: string;
    @DtoProp public isActive: boolean;
    @DtoProp public templateCategoryId: number;
    @DtoProp public templateCategoryLabel: string;
    @DtoProp public notificationTypeId: number;
    @DtoProp public notificationTypeLabel: string;
    @DtoProp public templateFields: string[];
    @DtoProp public templateMediums: INotificationTemplateMediumDto[];

    constructor(
        sourceData: INotificationTemplateDto,
        logicService: INotificationTemplateLogicService
    ) {
        super(sourceData, logicService, NotificationTemplateMappedItem);
    }
}
