import { BasePermissions, IBaseSystemAreaPermissions } from '@app/core/permissions/base.permissions';
import { Injectable } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { WipPermissionEnum } from '@app/shared/enums/permissions/wip.permission';

export interface IWipPermissions extends IBaseSystemAreaPermissions {
    canView(): boolean;
    canEdit(): boolean;
}

@Injectable()
export class WipPermissions extends BasePermissions<WipPermissionEnum>
    implements IWipPermissions {

    constructor(
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router) {
        super('wip', WipPermissionEnum, currentUser, http, router);
    }

    public canView(): boolean {
        return this.permissionObject(WipPermissionEnum.CanView);
    }

    public canEdit(): boolean {
        return this.permissionObject(WipPermissionEnum.CanEdit);
    }

    public canCreate(): boolean {
        throw new Error('Method not implemented.');
    }
}
