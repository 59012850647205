import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '@app/core/services/toast/toast.service';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, from, map, Observable } from 'rxjs';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { IEnumNestedLookup } from '@app/shared/enums/IEnumNestedLookup';
import { orderBy } from 'lodash';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { IUserSystemAccessDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { RolesLogicService } from '@app/logic/roles/roles.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { ISystemAccessLogicService } from '@app/logic/system-access/interfaces/i.system-access.logic-service';
import { ISystemAccessMappedItem } from '@app/logic/system-access/interfaces/i.system-access.mapped';
import { SystemAccessLogicService } from '@app/logic/system-access/system-access.logic.service';
import { NonDialogFormViewDirective } from '@app/shared/base-views/non-dialog-form-view.directive';

@Component({
    selector: 'cb-system-access',
    templateUrl: './system-access.component.html',
    styleUrls: ['./system-access.component.scss']
})
export class SystemAccessComponent extends NonDialogFormViewDirective<
IUserSystemAccessDto,
ISystemAccessMappedItem,
ISystemAccessLogicService> implements OnInit, AfterViewInit {

    @ViewChild('systemAccessForm', {}) public baseForm: NgForm;
    @Input() public userId$: BehaviorSubject<string>;
    public windowHeight: number;
    public mappedItem$ = new BehaviorSubject<ISystemAccessMappedItem>(this.logicService.$createMappedItem());
    public mappedItemClone: ISystemAccessMappedItem;

    public set mappedItem(mappedItem: ISystemAccessMappedItem) {
        this.mappedItem$.next(mappedItem);
    }

    public get mappedItem(): ISystemAccessMappedItem {
        return this.mappedItem$.value;
    }

    public systemAreasOptions$ = from(
        this.lookupService.SYSTEM_AREAS.toNestedLookup()
            .$promise as unknown as Observable<
        IEnumNestedLookup<number>[]
        >
    ).pipe(
        map((systemAreas: IEnumLookup<number>[]) => {
            return orderBy(
                systemAreas,
                systemArea => systemArea?.label?.toLowerCase()
            );
        }));

    public rolesOptions$ = this.rolesLogicService.$getList().pipe(
        map((roles) => {
            return orderBy(
                roles,
                role => role?.name?.toLowerCase()
            );
        }));

    public WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;

    constructor(
        private readonly logicService: SystemAccessLogicService,
        private readonly rolesLogicService: RolesLogicService,
        private readonly lookupService: LookupService,
        private readonly toastService: ToastService,
        public readonly route: ActivatedRoute,
    ) {
        super(toastService);
    }

    public ngOnInit(): void {
        this.userId$.subscribe(id => {
            if (id !== null && id !== undefined) {
                this.logicService.$getItem(id)
                    .subOnce(result => {
                        const mi = this.logicService.$createMappedItem(result);
                        this.mappedItem$
                            .next(mi);
                        this.mappedItemClone = mi?.$clone();
                    });
            }
        });
    }

    public onEditClicked(): void {
        const existingMappedItem = this.logicService.$createMappedItem(this.mappedItem.$getMappedDtoItem());
        this.mappedItem = existingMappedItem;
    }

    public ngAfterViewInit(): void {
        this.windowHeight = window.innerHeight;
    }

    public handleSaveSuccess(): void {
        this.logicService.$getMappedItem(this.mappedItem.id).subOnce(result => this.mappedItem = result);
        this.formMode$$ = FormMode.View;
        this.formMode = FormMode.View;
    }

    public onCancelClicked(): void {
        this.logicService.$getMappedItem(this.mappedItem.id).subOnce(result => this.mappedItem = result);
    }

    public selectedRolesChanged(roles: number[], form: UntypedFormGroup): void {
        this.mappedItem.selectedRoles = roles;
        form.markAsDirty();
    }

    public selectedAreasChanged(areas: number[], form: UntypedFormGroup): void {
        this.mappedItem.selectedAreas = areas;
        form.markAsDirty();
    }

    @HostListener('window:resize', ['$event'])
    private _onResize(): void {
        this.windowHeight = window.innerHeight;
    }
}
