import {Component, Input} from '@angular/core';
import {LookupService} from '@app/core/services/lookup/lookup.service';
import {IDesignConceptMappedItem} from '@app/logic/design-concepts';
import {IAmenitiesDto, IBuildTypeDto, IDesignSchemeDto, IWorkingDrawingDto} from '@classictechsolutions/hubapi-transpiled-enums';
import _ from 'lodash';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'cb-design-concept-details',
    templateUrl: './design-concept-details.component.html',
    styleUrls: ['./design-concept-details.component.scss'],
})
export class DesignConceptDetailsComponent {

    public readonly mappedItem$$: BehaviorSubject<IDesignConceptMappedItem> = new BehaviorSubject(null);
    @Input() public set mappedItem(_designConcept: IDesignConceptMappedItem) {
        this.mappedItem$$.next(_designConcept);
        const _sourceEntity = _designConcept.designSchemeId > 0 ? _designConcept.designScheme : _designConcept.workingDrawing;
        this.sourceEntity$$.next(_sourceEntity);
        this.loadAmmenities();
    }

    public get mappedItem(): IDesignConceptMappedItem {
        return this.mappedItem$$?.value;
    }

    public readonly sourceEntity$$: BehaviorSubject<IDesignSchemeDto | IWorkingDrawingDto | any> = new BehaviorSubject(null);

    public get sourceEntity(): IDesignSchemeDto | IWorkingDrawingDto | any {
        return this.sourceEntity$$?.value;
    }

    public buildTypes: IBuildTypeDto[];
    public isbuildTypesLoaded$$: BehaviorSubject<boolean> = new BehaviorSubject(null);

    public amenities: IAmenitiesDto;


    constructor(public readonly lookupService: LookupService) {
        this.loadBuildTypes();
    }


    private loadBuildTypes(): void {
        this.lookupService.BUILD_TYPES.toLookup().$promise.then((result: IBuildTypeDto[]) => {
            this.buildTypes = result;
            this.isbuildTypesLoaded$$.next(true);
        });
    }

    public getBuildTypeDescription(buildTypeCode: string): string {
        const buildType = _.find(this.buildTypes, (type: IBuildTypeDto) => type.code === buildTypeCode);
        return buildType.label;
    }

    private loadAmmenities(): void {
        this.amenities = {
            bedrooms: this.sourceEntity.amenities.noBedrooms,
            bathrooms: this.sourceEntity.amenities.noBathrooms,
            living: this.sourceEntity.amenities.noLivingRooms,
            garages: this.sourceEntity.amenities.noGarages,
        };
    }

}
