import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationExtras, NavigationStart, Router} from '@angular/router';
import {prefixStr} from 'cb-hub-lib';
import {filter, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';

interface INavigationResult {
    /** true if navigation successful, false if failed */
    success: boolean;
    /** true if opened new tab, false if opened in current tab */
    newTab: boolean;
}

interface IQueryParams<ValueType> {
    [key: string]: ValueType;
    [key: number]: ValueType;
}

type TQueryParams = IQueryParams<string | number | string[] | number[] | Object>;

@Injectable()
export class NavigationService implements OnDestroy {

    private queryParams: TQueryParams = {};

    private readonly $subscription = new Subscription();

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute) {
        this.subscribeToRouterEvents();
    }

    public ngOnDestroy(): void {
        this.$subscription.unsubscribe();
    }

    public clearQueryParams(): void {
        this.queryParams = {};
    }

    public getQueryParams<T extends TQueryParams>(): T {
        return this.queryParams as T;
    }

    public get currentPath(): string {
        return window.location.pathname;
    }

    public get snapshotUrl(): string {
        return this.router.routerState.snapshot.url;
    }

    /** Handles standard and 'ctrl click' navigation from sidenav */
    public navigate(path: string[], event?: MouseEvent, extras?: NavigationExtras): Promise<INavigationResult> {
        if (event?.ctrlKey) {
            return new Promise<INavigationResult>((resolve) => {
                window.open(environment.uri + prefixStr(path.join(''), '/'), '_blank');
                resolve({ success: true, newTab: true });
            });
        } else {
            /* Angular has issue navigating  the same route with different parameter,
             so we are navigating to '/' and then returning to the specified path
             that way angular detects it as a new route and works as expected */
            return this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: false })
                .then(() =>
                    this.router.navigate(path, extras)
                        .then(result => ({ success: result, newTab: false }))
                );
        }
    }

    public navigateTo(uri: string): Promise<boolean> {
        return this.router.navigate([uri]);
    }

    public redirectTo(uri: string): Promise<boolean> {
        return this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([uri]));
    }

    private subscribeToRouterEvents(): void {
        this.$subscription.add(
            this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe({
                next: () => {
                    this.clearQueryParams();
                    const params = this.router.getCurrentNavigation().extras.queryParams;
                    this.setQueryParams(params);
                }
            })
        );
    }

    private setQueryParams(newParams: TQueryParams): void {
        if (newParams && Object.keys(newParams).length > 0) {
            this.queryParams = newParams;
        }
    }
}
