import {
    BuildingConsentLogicService,
    ConsentType,
    IBuildingConsentMappedItem,
    IResourceConsentMappedItem,
    ResourceConsentLogicService
} from '@app/logic/lot-consents';
import { SYSTEM_AREA_ENUM, SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

import { ILotConsentDto } from '@app/logic/lot-consents/interfaces/i.lot-consent.dto';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

export enum ViewConsentType {
    None = 0,
    BuildingConsent = 1,
    ResourceConsent = 2,
}

@Injectable()
export class LotConsentViewService {

    public hasPrimaryBuildingConsent = false;
    public currentConsentType = ViewConsentType.None;
    public consent: IBuildingConsentMappedItem | IResourceConsentMappedItem | null;

    public readonly systemArea$$ = new BehaviorSubject<SystemAreaEnumId>(null);

    public get buildingConsent(): IBuildingConsentMappedItem {
        return this.consent as IBuildingConsentMappedItem;
    }
    public get isBuildingConsent(): boolean {
        return this.currentConsentType === ViewConsentType.BuildingConsent;
    }

    public get resourceConsent(): IResourceConsentMappedItem {
        return this.consent as IResourceConsentMappedItem;
    }
    public get isResourceConsent(): boolean {
        return this.currentConsentType === ViewConsentType.ResourceConsent;
    }

    constructor(
        public readonly buildingConsentLogic: BuildingConsentLogicService,
        public readonly resourceConsentLogic: ResourceConsentLogicService,
    ) { }

    public loadConsent(consent: ILotConsentDto): void {
        if (consent.consentTypeLabel.toLowerCase().includes('building consent')) {
            this.loadConsentById(consent.id, ConsentType.BuildingConsent);
        } else {
            this.loadConsentById(consent.id, ConsentType.ResourceConsent);
        }
    }

    public loadConsentById(consentId: number, consentType: ConsentType): void {
        if (consentType === ConsentType.BuildingConsent) {
            this.buildingConsentLogic.$getMappedItem(consentId).subOnce(this.handleLoadBuildingConsent);
        } else {
            this.resourceConsentLogic.$getMappedItem(consentId).subOnce(this.handleLoadResourceConsent);
        }
    }

    public backToList(): void {
        this.consent = null;
        this.currentConsentType = ViewConsentType.None;
        this.systemArea$$.next(null);
    }

    private readonly handleLoadBuildingConsent = (consent: IBuildingConsentMappedItem): void => {
        this.consent = consent;
        this.currentConsentType = ViewConsentType.BuildingConsent;
        this.systemArea$$.next(SYSTEM_AREA_ENUM.BuildingConsent);
    };

    private readonly handleLoadResourceConsent = (consent: IResourceConsentMappedItem): void => {
        this.consent = consent;
        this.currentConsentType = ViewConsentType.ResourceConsent;
        this.systemArea$$.next(SYSTEM_AREA_ENUM.ResourceConsent);
    };
}
