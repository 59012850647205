// Original version: https://github.com/grassator/insert-text-at-cursor
/* eslint-disable */

let browserSupportsTextareaTextNodes;

function canManipulateViaTextNodes(input: HTMLElement): boolean {
    if (input.nodeName !== 'TEXTAREA') {
        return false;
    }
    if (typeof browserSupportsTextareaTextNodes === 'undefined') {
        const textarea = document.createElement('textarea');
        textarea.value = '1';
        browserSupportsTextareaTextNodes = !!textarea.firstChild;
    }
    return browserSupportsTextareaTextNodes;
}

export function insertTextAtCursor(input: HTMLInputElement | HTMLTextAreaElement, text: string): void {
    // Most of the used APIs only work with the field selected
    input.focus();

    // IE 8-10
    if (document['selection']) {
        const ieRange = document['selection'].createRange();
        ieRange.text = text;

        // Move cursor after the inserted text
        ieRange.collapse(false /* to the end */);
        ieRange.select();

        return;
    }

    // Webkit + Edge
    const isSuccess = document.execCommand('insertText', false, text);
    if (!isSuccess) {
        const start = input.selectionStart;
        const end = input.selectionEnd;
        // Firefox (non-standard method)
        if (typeof input.setRangeText === 'function') {
            input.setRangeText(text);
        } else {
            if (canManipulateViaTextNodes(input)) {
                const textNode = document.createTextNode(text);
                let node = input.firstChild;

                // If textarea is empty, just insert the text
                if (!node) {
                    input.appendChild(textNode);
                } else {
                    // Otherwise we need to find a nodes for start and end
                    let offset = 0;
                    let startNode = undefined;
                    let endNode = undefined;

                    // To make a change we just need a Range, not a Selection
                    const range = document.createRange();

                    while (node && (startNode == undefined || endNode == undefined)) {
                        const nodeLength = node.nodeValue.length;

                        // if start of the selection falls into current node
                        if (start >= offset && start <= offset + nodeLength) {
                            startNode = node;
                            range.setStart(startNode, start - offset);
                        }

                        // if end of the selection falls into current node
                        if (end >= offset && end <= offset + nodeLength) {
                            endNode = node;
                            range.setEnd(endNode, end - offset);
                        }

                        offset += nodeLength;
                        node = node.nextSibling;
                    }

                    // If there is some text selected, remove it as we should replace it
                    if (start !== end) {
                        range.deleteContents();
                    }

                    // Finally insert a new node. The browser will automatically
                    // split start and end nodes into two if necessary
                    range.insertNode(textNode);
                }
            } else {
                // For the text input the only way is to replace the whole value :(
                const value = input.value;
                input.value = value.slice(0, start) + text + value.slice(end);
            }
        }

        // Correct the cursor position to be at the end of the insertion
        input.setSelectionRange(start + text.length, start + text.length);

        // Notify any possible listeners of the change
        const e = document.createEvent('UIEvent');
        e.initEvent('input', true, false);
        input.dispatchEvent(e);
    }
}

/**
 * @summary
 * Sanitize string - Allows the following matched characters
 * - a-z Range. Matches a character in the range "a" to "z" (char code 97 to 122). Case insensitive.
 * - 0-9 Range. Matches a character in the range "0" to "9" (char code 48 to 57). Case insensitive.
 * - \  Escaped character. Matches a SPACE character (char code 32).
 * - \r Escaped character. Matches a CARRIAGE RETURN character (char code 13).
 * - \n Escaped character. Matches a LINE FEED character (char code 10).
 * - \~ Escaped character. Matches a "~" character (char code 126).
 * - \! Escaped character. Matches a "!" character (char code 33).
 * - \&#64; Escaped character. Matches a "&#64;" character (char code 64).
 * - \# Escaped character. Matches a "#" character (char code 35).
 * - \$ Escaped character. Matches a "$" character (char code 36).
 * - \% Escaped character. Matches a "%" character (char code 37).
 * - \^ Escaped character. Matches a "^" character (char code 94).
 * - \& Escaped character. Matches a "&" character (char code 38).
 * - \* Escaped character. Matches a "*" character (char code 42).
 * - \( Escaped character. Matches a "(" character (char code 40).
 * - \) Escaped character. Matches a ")" character (char code 41).
 * - \_ Escaped character. Matches a "_" character (char code 95).
 * - \- Escaped character. Matches a "-" character (char code 45).
 * - \+ Escaped character. Matches a "+" character (char code 43).
 * - \= Escaped character. Matches a "=" character (char code 61).
 * - \[ Escaped character. Matches a "[" character (char code 91).
 * - \] Escaped character. Matches a "]" character (char code 93).
 * - \{ Escaped character. Matches a "{" character (char code 123).
 * - \} Escaped character. Matches a "}" character (char code 125).
 * - \| Escaped character. Matches a "|" character (char code 124).
 * - \: Escaped character. Matches a ":" character (char code 58).
 * - \; Escaped character. Matches a ";" character (char code 59).
 * - \" Escaped character. Matches a """ character (char code 34).
 * - \' Escaped character. Matches a "'" character (char code 39).
 * - \< Escaped character. Matches a "<" character (char code 60).
 * - \, Escaped character. Matches a "," character (char code 44).
 * - \> Escaped character. Matches a ">" character (char code 62).
 * - \. Escaped character. Matches a "." character (char code 46).
 * - \? Escaped character. Matches a "?" character (char code 63).
 */
export function sanitizeString(str: string): string {
    str = str.replace(/[^a-z0-9\ \r\n\~\!\@\#\$\%\^\&\*\(\)\_\-\+\=\[\]\{\}\|\:\;\"\'\<\,\>\.\?]/gim, "");
    return str.trim();
}
