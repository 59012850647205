import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { ISystemAccessMappedItem } from './interfaces/i.system-access.mapped';
import { BaseLogicService } from '../base/base-logic.service';
import { ISystemAccessLogicService } from './interfaces/i.system-access.logic-service';
import { SystemAccessMappedItem } from './system-access.mapped';
import { IUserSystemAccessDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class SystemAccessLogicService
    extends BaseLogicService<IUserSystemAccessDto, ISystemAccessMappedItem>
    implements ISystemAccessLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('auth/users/systemaccess', SystemAccessMappedItem);
    }

    public $saveItem(entity: IUserSystemAccessDto): Observable<IUserSystemAccessDto> {
        const uri = this.$baseUri;
        let params: {id: string};
        if (entity.id) {
            params = {id: entity.id};
        }
        return this.$http.put<IUserSystemAccessDto>(uri, entity, undefined, undefined, params);
    }

    public $getItem(userId: string): Observable<IUserSystemAccessDto> {
        return this.$http.get<IUserSystemAccessDto>(`/auth/users/${userId}/systemaccess`);
    }
}
