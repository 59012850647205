import { ILotDto, LOT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BuildProgrammeTemplateLogicService, IBuildProgrammeTemplateDto } from '@app/logic/build-programme-template';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { NonWorkingDayLogicService, INonWorkingDayDto } from '@app/logic/non-working-day';
import { IPromised, toPromisedArray } from 'cb-hub-lib';
import { LotsLogicService } from '@app/logic/lots';

interface IData {
    lotDto: ILotDto;
}

@Component({
    templateUrl: './generate-build-programme-dialog.component.html',
    styleUrls: ['./generate-build-programme-dialog.component.scss']
})
export class GenerateBuildProgrammeDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '33%';

    public buildProgrammeTemplates: IBuildProgrammeTemplateDto[];
    public nonWorkingDays: IPromised<INonWorkingDayDto[]>;
    public readonly LOT_TYPE_ENUM = LOT_TYPE_ENUM;
    public saving = false;

    public readonly canChangeTemplate = new ComputedProperty(() => {
        return this.data?.lotDto?.parentId == null || this.data?.lotDto?.isPrimaryUnit;
    });

    public get lotDto(): ILotDto {
        return this.data.lotDto;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<GenerateBuildProgrammeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(BuildProgrammeTemplateLogicService) private readonly buildTemplateLogic: BuildProgrammeTemplateLogicService,
        @Inject(NonWorkingDayLogicService) private readonly nonWorkingDayLogic: NonWorkingDayLogicService,
        @Inject(LotsLogicService) private readonly lotsLogicService: LotsLogicService,
    ) {
        this.buildTemplateLogic.getLookup().subOnce(result => this.buildProgrammeTemplates = result);
        this.nonWorkingDays = toPromisedArray(this.nonWorkingDayLogic.getNonWorkingDaysForLot(data.lotDto.id));
    }

    public ngOnInit(): void {
        this.canChangeTemplate.recompute();
    }

    public generateBuildProgramme(): void {
        this.saving = true;
        this.lotsLogicService.$saveItem(this.data.lotDto).subOnce((_lot) => {
            this.dialogRef.close(true);
        });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
