import { Injectable, Injector } from '@angular/core';

import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IScheduleTemplateItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IScheduleTemplateItemMappedItem } from './interfaces/i.schedule-template-item.mapped';
import { ScheduleTemplateItemMappedItem } from './schedule-template-item.mapped';
import { IScheduleTemplateItemsLogicService } from './interfaces/i.schedule-template-items.logic-service';
import { Observable } from 'rxjs';


@Injectable()
export class ScheduleTemplatesItemsLogicService
    extends BaseLogicService<IScheduleTemplateItemDto, IScheduleTemplateItemMappedItem>
    implements IScheduleTemplateItemsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'scheduletemplates/item',
            ScheduleTemplateItemMappedItem
        );
    }

    public uploadImage(scheduleTemplateItemId: number, formData: any): Observable<IScheduleTemplateItemDto> {
        return this.$http
            .post<IScheduleTemplateItemDto>(`${this.$baseUri}/${scheduleTemplateItemId}/image`, formData);
    }

    public removeImage(scheduleTemplateItemId: number): Observable<IScheduleTemplateItemDto> {
        return this.$http.delete<IScheduleTemplateItemDto>(`${this.$baseUri}/${scheduleTemplateItemId}/image`);
    }

    public toggleShowInClientSpecification(scheduleTemplateItemId: number): Observable<IScheduleTemplateItemDto> {
        const url = `${this.$baseUri}/${scheduleTemplateItemId}/toggleShowInClientSpecification`;
        return this.$http.post<IScheduleTemplateItemDto>(url);
    }
}
