import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ICostxRateLibraryLogicService } from './interfaces/i.costx-rate-library-logic.service';
import { ICostxRateLibraryDto } from './interfaces/i.costx-rate-library.dto';
import { ICostxRateLibraryMappedItem } from './interfaces/i.costx-rate-library.mapped';
import { CostxRateLibraryMappedItem } from './costx-rate-library.mapped';
import { Observable } from 'rxjs';
import { ILookupDto } from '@app/shared/interfaces/i.lookup.dto';
import { ICostXRateLibraryRequestDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class CostxRateLibraryLogicService
    extends BaseLogicService<ICostxRateLibraryDto, ICostxRateLibraryMappedItem>
    implements ICostxRateLibraryLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'costxratelibrary',
            CostxRateLibraryMappedItem,
        );
    }

    public getAvailableLocations(): Observable<ILookupDto<number>[]> {
        return this.$http
            .get(`${this.$baseUri}/availablelocations`);
    }

    public downloadCostXRateLibraryExport(rateLibraryId: number, effectiveDate: DateString = null, includeCompositeItems = false): Observable<void> {
        return this.$http.download(`${this.$baseUri}/export/costx`, null, null, 'post', { rateLibraryId, effectiveDate, includeCompositeItems } as ICostXRateLibraryRequestDto);
    }

    public downloadAllCostXRateLibraryExport(effectiveDate: DateString = null, includeCompositeItems = false): Observable<void> {
        return this.$http.download(`${this.$baseUri}/export/costx`, null, null, 'post', { effectiveDate, includeCompositeItems } as ICostXRateLibraryRequestDto);
    }
}
