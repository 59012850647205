import { ClientSalePermissions } from '@app/core/permissions';
import { Component, Input } from '@angular/core';
import { ClassicCareEditDialogComponent } from '../../dialogs/classic-care-edit-dialog/classic-care-edit-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BaseClientSaleCardDirective } from '../base-client-sale-card';
import { ClientSaleDocumentsService } from '../../documents-service/client-sale-documents.service';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Component({
    selector: 'cb-client-sale-classic-care-card',
    templateUrl: './classic-care-card.component.html',
    styleUrls: ['./classic-care-card.component.scss']
})
export class ClientSaleClassicCareCardComponent extends BaseClientSaleCardDirective {

    @Input() public readonly stepNumber: number;

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public get isComplete(): boolean {
        return this.clientSale?.stepCompletion?.classicCare;
    }

    constructor(
        public readonly matDialog: MatDialog,
        public readonly permissions: ClientSalePermissions,
        public readonly clientSaleDocuments: ClientSaleDocumentsService,
    ) {
        super(clientSaleDocuments);
    }

    public openEditDialog(): void {
        if (!this.permissions.canEdit()) {
            return;
        }
        this.matDialog
            .open(ClassicCareEditDialogComponent, {
                data: { clientSale: this.clientSale.$clone() },
                panelClass: 'cb-dialog-container',
                minWidth: 400
            })
            .afterClosed()
            .subOnce({ next: this.recomputeIconProps });
    }

}
