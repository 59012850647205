import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LotDesignPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotDesignSchemeSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { DesignSchemesLogicService, IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { FormMode } from '@app/shared/enums/form';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { SelectLotTemplateDialogComponent } from '@app/views/projects/release/lots/design/dialogs/select-lot-template-dialog/select-lot-template-dialog.component';
import {
    IDesignComplexityDto,
    IDesignRequirementItemDto,
    IDesignSchemeDocumentDto, IDesignSchemeDto, ILotAmenitiesDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, Observable, Subject, Subscription, map } from 'rxjs';
import { DesignSchemeDialogComponent } from '../manage/design-scheme-dialog/design-scheme-dialog.component';
import { RequestDesignSchemesDialogComponent } from './request-design-schemes-dialog/request-design-schemes-dialog.component';

@Component({
    selector: 'cb-design-scheme-search',
    templateUrl: './design-scheme-search.component.html',
    styleUrls: ['./design-scheme-search.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class DesignSchemeSearchComponent implements OnInit {
    // Use as a guide

    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public readonly designSchemeSelected = new EventEmitter<IDesignSchemeMappedItem>();

    public readonly queryUpdate = new Subject();
    private readonly subscription$ = new Subscription();

    public get userCacheItem(): UserCacheItem<ILotDesignSchemeSearch> {
        return this.userCacheService.lotDesignSchemeSearch;
    }

    public currentPage: number;
    public searchEnabled = false;
    public results: IDesignSchemeMappedItem[] = [];
    public resultsLoaded$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // eslint-disable-next-line max-len
    private readonly createSchemeConfirmMessage = 'A Spec Template has not been applied to this Lot. Continuing without a Spec Template will prevent the ability to request Pricing for Scheme A. Do you want to continue?';


    constructor(
        public readonly userCacheService: UserCacheService,
        public readonly lotDesignPermissions: LotDesignPermissions,
        public readonly currentUser: CurrentUserService,
        public logicService: DesignSchemesLogicService,
        public lotsLogicService: LotsLogicService,
        public readonly navigationService: NavigationService,
        public readonly cbDialog: CbDialogService,
        private readonly cdRef: ChangeDetectorRef
    ) {
    }

    public viewDesignScheme(event: IDesignSchemeMappedItem): void {
        this.designSchemeSelected.emit(event);
    }

    public fetchResults(): Observable<ISearchResult<IDesignSchemeMappedItem>> {
        if (!this.searchEnabled) {
            return;
        }
        return this.logicService.getSearchList(this.getQueryParams()).pipe(map(this.receivedSearchResults));
    }

    public receivedSearchResults = (results: ISearchResult<IDesignSchemeDocumentDto>): ISearchResult<IDesignSchemeMappedItem> => {
        const mappedResults = {
            total: results.total,
            items: [] as IDesignSchemeMappedItem[],
        };
        mappedResults.items = results.items.map((result) => {
            const item = this.logicService.$createMappedItem();
            item.designSchemeDocumentDto.$mapUpThisAndOriginal(result);
            return item;
        });
        this.resultsLoaded$$.next(true);
        return mappedResults;
    };


    public ngOnInit(): void {
        Promise.all([
            this.userCacheItem.init(),
            this.currentUser.$promise,
        ]).then(() => {
            this.searchEnabled = true;
            this.queryUpdated();
            this.subscription$.add(
                this.userCacheItem.updated$.subscribe({
                    next: this.queryUpdated
                })
            );
        });
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    public readonly queryUpdated = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.resultsLoaded$$.next(false);
        this.currentPage = 1;
        this.queryUpdate.next(null);
    };

    public createDesignScheme(): void {
        const mappedItem = this.logicService.$createMappedItem();
        mappedItem.designComplexity = {} as IDesignComplexityDto;
        mappedItem.amenities = {} as ILotAmenitiesDto;
        mappedItem.designRequirementItems = [] as IDesignRequirementItemDto[];

        this.applyLotData(mappedItem);

        this.cbDialog
            .open(DesignSchemeDialogComponent, {
                data: {
                    mappedItem,
                    lotTypeEnumId: this.lotMappedItem.lotType
                }
            })
            .afterClosed()
            .subOnce((newDesignScheme) => {
                if (newDesignScheme) {
                    this.lotMappedItem.$reload().subOnce();
                    this.results = [this.logicService.$createMappedItem(newDesignScheme), ...this.results];
                    this.cdRef.detectChanges();
                }
            });
    }

    public applyLotData(mappedItem: IDesignSchemeMappedItem): void {
        mappedItem.lotId = this.lotMappedItem.id;
        mappedItem.dpNumber = this.lotMappedItem.dpNumber;
        mappedItem.buildTypeId = this.lotMappedItem.buildType.id;
        // mappedItem.standardPlanId = this.lot.standardPlanId;
        mappedItem.assignedToUserId = this.lotMappedItem.contacts?.designer?.id;
        mappedItem.assignedToUserName = this.lotMappedItem.contacts?.designer?.label;
        mappedItem.amenities = this.lotMappedItem.amenities;
    }

    private requestDesignScheme(): void {
        const mappedItem = this.logicService.$createMappedItem({
            reviewRequired: true,
        });

        const skinnyLatestCompletedDesignSchemePromise: Promise<any> = new Promise<IDesignSchemeDto>((resolve) => {
            if (this.lotMappedItem.latestCompleteDesignSchemeNumber) {
                this.lotsLogicService.getDesignSchemesForLot(this.lotMappedItem.id).subOnce(result => {
                    const latestCompletedDesignSchemeSkinny = result
                        .find(scheme => scheme.revisionNumber === this.lotMappedItem.latestCompleteDesignSchemeNumber);
                    resolve(latestCompletedDesignSchemeSkinny);
                });
            } else {
                resolve(undefined);
            }
        });

        const fullLatestCompletedDesignSchemePromise = (latestCompletedDesignScheme): Promise<IDesignSchemeDto> => {
            let promise: Promise<IDesignSchemeDto>;
            if (latestCompletedDesignScheme) {
                promise = new Promise<IDesignSchemeDto>((resolve) => {
                    this.logicService.$getItem(latestCompletedDesignScheme.id).subOnce(designSchemeFull => {
                        resolve(designSchemeFull);
                    });
                });
            } else {
                promise = new Promise((resolve) => {
                    resolve(undefined);
                });
            }
            return promise;
        };

        skinnyLatestCompletedDesignSchemePromise
            .then(fullLatestCompletedDesignSchemePromise)
            .then((fullLatestCompletedDesignScheme: IDesignSchemeDto) => {
                mappedItem.applyLotData(this.lotMappedItem.$getMappedDtoItem(), fullLatestCompletedDesignScheme);
                this.openRequestDesignSchemeStepsDialog(
                    mappedItem,
                    this.lotMappedItem,
                    FormMode.Add
                );
            });
    }

    private openRequestDesignSchemeStepsDialog(mappedItem: IDesignSchemeMappedItem, lotMappedItem: ILotMappedItem, mode: FormMode): void {
        this.cbDialog
            .open(RequestDesignSchemesDialogComponent, {
                data: { mappedItem, lotMappedItem, mode },
            })
            .afterClosed()
            .subOnce((result) => {
                if (result) {
                    this.lotMappedItem.$reload().subOnce();
                    this.queryUpdated();
                }
            });
    }

    public applySpecTemplate(): void {
        if (this.lotMappedItem.lotSpecLocked) {
            return;
        }

        this.cbDialog
            .open(SelectLotTemplateDialogComponent, {
                data: { lotId: this.lotMappedItem.id },
            })
            .afterClosed()
            .subOnce((result) => {
                if (result) {
                    this.lotMappedItem.$reload().subOnce();
                    this.cdRef.detectChanges();
                }
            });
    }

    protected getQueryParams(): any {
        return {
            pageSize: 10,
            currentpage: this.currentPage,
            query: this.userCacheItem.data.query,
            lotId: this.lotMappedItem?.id
        };
    }

    public shouldShowNoResultsMessage(): boolean {
        return this.resultsLoaded$$.value && (this.results?.length < 1);
    }

    public requestDesignSchemeSteps(): void {
        if (!this.lotMappedItem.hasSpecTemplate && !this.lotMappedItem.hasFirstScheme) {
            this.cbDialog.confirm({
                confirmed: () => this.requestDesignScheme(),
                dialogHeading: 'Confirm Request Design Scheme',
                message: this.createSchemeConfirmMessage,
            });
        } else {
            this.requestDesignScheme();
        }
    }
}
