import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { ContactsLogicService } from '@app/logic/contacts';
import { ContactUtilities } from '@app/logic/contacts/contact.utilities';
import { CouncilLogicService } from '@app/logic/councils';
import { LocationLogicService } from '@app/logic/location';
import { ProjectsLogicService } from '@app/logic/projects';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';
import { IProjectsLogicService } from '@app/logic/projects/interfaces/i.projects.logic.service';
import { UsersLogicService } from '@app/logic/users';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { UsersInputFindDialogTableComponent } from '@app/shared/components/forms/input-find/dialog/extensions/users-input-find-dialogs/users-input-find-dialog-table.component';
import { FormMode } from '@app/shared/enums/form';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { UserDetailsDialogComponent } from '@app/views/users/user-details-dialog/user-details-dialog.component';
import {
    IClientAccountDto,
    ICouncilDto,
    IIdAndLabelDto,
    IProjectDetailsDto,
    ISkinnyContactDto,
    ISkinnyUserDto,
    IUserDto,
    PROJECT_STATUS_ENUM,
    SALUTATION_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { find, orderBy, remove } from 'lodash';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'cb-manage-project-details-dialog',
    templateUrl: './manage-project-details-dialog.component.html',
    styleUrls: ['./manage-project-details-dialog.component.scss']
})
export class ManageProjectDetailsDialogComponent extends BaseDialogFormViewDirective<IProjectDetailsDto, IProjectMappedItem, IProjectsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '90%';

    @Input() public mappedItem: IProjectMappedItem;
    public ContactUtilities = ContactUtilities;
    public selectedTabIndex = 0;
    public loaded: boolean;
    public projectStatuses = PROJECT_STATUS_ENUM.toLookup();
    public PROJECT_STATUS_ENUM = PROJECT_STATUS_ENUM;
    public SALUTATION_ENUM = SALUTATION_ENUM;
    public councils: ICouncilDto[] | any = [];
    public areas: IIdAndLabelDto[];
    public regionId$ = new BehaviorSubject<number>(undefined);
    private readonly onDestroy = new Subject();
    public firstRun = true;

    constructor(
        private readonly contactsLogicService: ContactsLogicService,
        private readonly toastService: ToastService,
        private readonly usersLogicService: UsersLogicService,
        private readonly locationsLogicService: LocationLogicService,
        private readonly logicService: ProjectsLogicService,
        private readonly councilsogicService: CouncilLogicService,
        private readonly navigationService: NavigationService,
        public securityPermissions: PermissionsPermissions,
        private readonly accountLogicService: ClientAccountLogicService,
        public readonly dialogRef: MatDialogRef<ManageProjectDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IProjectMappedItem>,
        public readonly cbDialog: CbDialogService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        if (!this.mappedItem?.id) {
            this.formMode = FormMode.Add;
        } else {
            this.formMode = FormMode.Edit;
        }
    }

    public ngOnInit(): void {
        this.loaded = true;
        this.regionId$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: regionId => {
                if (!this.firstRun && this.isEdit()) {
                    this.mappedItem.locationId = undefined;
                    this.mappedItem.buildingConsentCouncilId = undefined;
                    this.firstRun = false;
                }
                // this.mappedItem.region = regionId;
                this.loadLocation(this.mappedItem);
            }
        });
    }

    public viewContact(contactId: number): void {
        this.navigationService.navigate([`contacts/${contactId}/summary`]);
    }

    public loadLocation(project: IProjectDetailsDto): void {
        if (project.address.region) {
            this.locationsLogicService
                .getAreasByRegionId({ addressRegionId: project.address.region })
                .subOnce(result => {
                    this.areas = this.orderOptionsByLabel(result);
                    this.onAreaChanged();
                });
        } else {
            this.areas = undefined;
            this.councils = undefined;
            this.mappedItem.locationId = undefined;
            this.mappedItem.buildingConsentCouncilId = undefined;
        }
    }

    public orderOptionsByLabel(options): IIdAndLabelDto[] {
        options = orderBy(options, ['label'], ['asc']);
        return options;
    }

    public getDisabled(form: UntypedFormGroup): boolean {
        return form.invalid || form.pristine || form?.controls?.buildingConsentCouncilId?.invalid;
    }

    public onAreaChanged(): void {
        if (this.mappedItem.locationId !== undefined && this.mappedItem.locationId > 0) {
            this.councilsogicService.getByLocation(this.mappedItem.locationId).subOnce(result => {
                if (result.length === 0) {
                    this.councils = [{
                        id: undefined,
                        name: 'none'
                    }];
                } else {
                    this.councils = result;
                    if (this.councils.length === 1) {
                        this.mappedItem.buildingConsentCouncilId = this.councils[0].id;
                    }
                }
            });
        } else {
            this.mappedItem.buildingConsentCouncilId = undefined;
            this.councils = [];
        }
    }

    public onAccountChanged = (clientAccountDto: IClientAccountDto): void => {
        this.mappedItem.accountOwner.label = clientAccountDto.legalName;
        this.mappedItem.accountOwner.id = clientAccountDto.id;
        this.accountLogicService.getContacts(this.mappedItem.accountOwner.id).subOnce((contacts) => this.mappedItem.contacts = (contacts as unknown as ISkinnyContactDto[]));
    };

    public viewUser(user: IUserDto): void {
        this.cbDialog
            .open(UserDetailsDialogComponent, {
                data: { dto: user },
                minWidth: '500px',
            })
            .afterClosed()
            .subOnce();
    }

    public deleteUser(dto: IUserDto, form: UntypedFormGroup): void {
        remove(this.mappedItem.teamMembers, (user: IUserDto) => user.id === dto.id);
        form.markAsDirty();
    }

    public openAddUserDialog(form: UntypedFormGroup): void {
        this.cbDialog
            .open(UsersInputFindDialogTableComponent, {
                data: {
                    dto: this.mappedItem,
                    placeholder: 'Type First Name, Last Name, or Email',
                    dialogHeading: 'Users',
                    contentHeading: 'User Search',
                },
                minWidth: '900px',
            })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    const existingContact = find(this.mappedItem.teamMembers, { id: result.id });
                    if (existingContact) {
                        this.toastSerivce.showToast(`The user ${existingContact.firstName} ${existingContact.lastName} is already a member of this team.`);
                    } else {
                        const user = this.usersLogicService.$getItem(result.id).subOnce(usr => {
                            this.mappedItem.teamMembers = this.mappedItem.teamMembers || [] as ISkinnyUserDto[];
                            this.mappedItem.teamMembers.push(usr as any);
                            form.markAsDirty();
                        });
                    }
                }
            });
    }

    public handleSaveSuccess(): void {
        if (this.isAdd()) {
            this.navigationService.navigate([`projects/${this.mappedItem.$id}/details`]);
        }
    }

}
