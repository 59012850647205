<ul class="start-center flex-row">
    <li class="start-center flex-row"
        *ngFor="let bc of breadcrumbs; last as isLast">
        <span>/</span>
        <a href="{{bc.url}}"
           *ngIf="!isLast"
           (click)="navigate($event, bc)"> {{bc.label}} </a>
        <a *ngIf="isLast"
           class="crumbActive"> {{bc.label}} </a>
    </li>
    <span class="flex"></span>
    <li class="end-center flex-row">
        <a href="https://classicgroupnz.sharepoint.com/sites/HubNotifications/SitePages/All-release-notes.aspx"
           target="_blank">
            Current version: {{environment.buildVersion}}
        </a>
    </li>
</ul>
