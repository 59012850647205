<div class="flex-row flex">
    <cb-display-value-new class="flex"
                          label="Bedroom">
        <div class="start-center">
            <mat-icon class="cb-grey">hotel</mat-icon>
            <span class="mat-body-1">&nbsp;{{amenities?.bedrooms | cbDasher}}</span>
        </div>
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          label="Bathroom">
        <div class="start-center">
            <mat-icon class="cb-grey">wc</mat-icon>
            <span class="mat-body-1">&nbsp;{{amenities?.bathrooms | cbDasher}}</span>
        </div>
    </cb-display-value-new>
</div>
<div class="flex-row flex">
    <cb-display-value-new class="flex"
                          label="Living">
        <div class="start-center">
            <mat-icon class="cb-grey">weekend</mat-icon>
            <span class="mat-body-1">&nbsp;{{amenities?.living | cbDasher}}</span>
        </div>
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          label="Garage">
        <div class="start-center">
            <mat-icon class="cb-grey">home</mat-icon>
            <span class="mat-body-1">&nbsp;{{amenities?.garages | cbDasher}}</span>
        </div>
    </cb-display-value-new>
</div>
