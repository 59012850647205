import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { IBuildContingencyRecordDto } from './interfaces/i.build-contingency-record.dto';
import { IBuildContingencyRecordsLogicService } from './interfaces/i.build-contingency-records.logic.service';
import { IBuildContingencyRecordMappedItem } from './interfaces/i.build-contingency-record.mapped';
import { BuildContingencyRecordMappedItem } from './build-contingency-record.mapped';

@Injectable()
export class BuildContingencyRecordsLogicService
    extends BaseLogicService<IBuildContingencyRecordDto, IBuildContingencyRecordMappedItem>
    implements IBuildContingencyRecordsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildcontingencynotifications',
            BuildContingencyRecordMappedItem,
        );
    }

    public getByBusinessEntity(beId: number): Observable<IBuildContingencyRecordDto[]> {
        return this.$http
            .get<Array<IBuildContingencyRecordDto>>(`businessentities/${beId}/buildcontingencynotifications`);
    }
}
