import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IStandardPlansSearch } from '@app/core/services/user-cache/user-cache-areas';
import { IStandardPlanMappedItem } from '@app/logic/standard-plans/interfaces/i.standard-plan.mapped';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { cleanObject, cleanObjectDeep, isNullOrWhiteSpace, toPromise } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { IStandardPlanStyleDto } from './../styles/interfaces/i.style.dto';
import { IStandardPlanCategoryDto} from '@classictechsolutions/hubapi-transpiled-enums';
import { IStandardPlanSearchFiltersItem } from './interfaces/i.standard-plan-search-filter';
import { IStandardPlanDto, IStandardPlanMaxMinCostArea } from './interfaces/i.standard-plan.dto';
import { IStandardPlanLogicService } from './interfaces/i.standard-plan.logic.service';
import { StandardPlanMappedItem } from './standard-plan.mapped';

@Injectable()
export class StandardPlanLogicService
    extends BaseLogicService<IStandardPlanDto, IStandardPlanMappedItem>
    implements IStandardPlanLogicService {

    public standardPlanStyles: Promise<IStandardPlanStyleDto[]>;
    public standardPlanCategories: Promise<IStandardPlanCategoryDto[]>;

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'standardplans',
            StandardPlanMappedItem
        );
    }

    /** Initialises standardPlanStyles promise */
    public loadStandardPlanStyles(): void {
        this.standardPlanStyles = toPromise(this.getStandardPlanStyles());
    }

    /** Initialises standardPlanCategories promise */
    public loadStandardPlanCategories(): void {
        this.standardPlanCategories = toPromise(this.getStandardPlanCategories());
    }

    public saveStandardPlan(plan: IStandardPlanDto): Observable<IStandardPlanDto> {
        if (plan.id > 0) {
            return this.$http.post(`${this.$baseUri}/${plan.id}`, plan);
        }
    }

    public deleteStandardPlan(planId: number): Observable<IStandardPlanDto> {
        if (planId > 0) {
            return this.$http.post(`${this.$baseUri}/${planId}/delete`, null);
        }
    }

    public getMappedStandardPlans(): Observable<IStandardPlanMappedItem[]> {
        const params = {
            currentPage: 1,
            showUnpublished: true,
            order: 'asc',
        };

        return this.$http
            .get<any>(`${this.$baseUri}/searchitems`, cleanObject(params))
            .pipe(
                map(result => result.items.map(plan => this.$createMappedItem(plan)))
            );
    }

    public getStandardPlanStyles(): Observable<IStandardPlanStyleDto[]> {
        return this.$http
            .get<Array<IStandardPlanStyleDto>>(`${this.$baseUri}/styles`);
    }

    public getStandardPlanCategories(): Observable<IStandardPlanCategoryDto[]> {
        return this.$http
            .get<Array<IStandardPlanCategoryDto>>(`${this.$baseUri}/categories`);
    }

    public getStandardPlanActiveCategories(): Observable<IStandardPlanCategoryDto[]> {
        return this.$http
            .get<Array<IStandardPlanCategoryDto>>(`${this.$baseUri}/categories/active`);
    }

    public getMaxMinCostArea(): Observable<IStandardPlanMaxMinCostArea> {
        return this.$http
            .get(`${this.$baseUri}/maxmincostsareas`);
    }

    public uploadPlanImage(uploadedImage: File, standardPlanDto: IStandardPlanDto, style: IStandardPlanStyleDto): Observable<IStandardPlanDto> {
        const formData = new FormData();
        formData.append('file', uploadedImage);

        return this.$http.post(`${this.$baseUri}/${standardPlanDto.id}/image/${style.imageType}/style/${style.id}`, formData);
    }

    public deletePlanImage(imageId: string, planId: number): Observable<IStandardPlanDto> {
        return this.$http.post(`${this.$baseUri}/${planId}/image/${imageId}/delete`, null);
    }

    public searchItems(filters: IStandardPlansSearch, currentPage: number, costs: number[], areas: number[]): Observable<IStandardPlanMappedItem[]> {
        const params = {

            currentPage,

            query: filters.query,

            'floorArea.min': filters.floorArea[0] !== areas[0] ? filters.floorArea[0] : null,
            'floorArea.max': filters.floorArea[1] !== areas[1] ? filters.floorArea[1] : null,

            numberOfBedrooms: filters.numberOfBedrooms > 0 ? filters.numberOfBedrooms : null,
            numberOfBathrooms: filters.numberOfBathrooms > 0 ? filters.numberOfBathrooms : null,
            numberOfLivingRooms: filters.numberOfLivingRooms > 0 ? filters.numberOfLivingRooms : null,
            numberOfGarages: filters.numberOfGarages > 0 ? filters.numberOfGarages : null,

            'costToBuild.min': filters.costToBuild[0] !== costs[0] ? filters.costToBuild[0] : null,
            'costToBuild.max': filters.costToBuild[1] !== costs[1] ? filters.costToBuild[1] : null,

            showUnpublished: filters.showUnpublished,
            order: filters.order,
        };

        return this.$http.get<any>(`${this.$baseUri}/searchitems`, cleanObject(params))
            .pipe(
                map(result => result.items.map(plan => this.$createMappedItem(plan)))
            );

    }


    public $getSearchList(params: any): Observable<ISearchResult<IStandardPlanSearchFiltersItem>> {
        params.currentPage = 1;
        params.showUnpublished = true;
        params.order = 'asc';
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http
            .get<ISearchResult<IStandardPlanSearchFiltersItem>>(`${this.$baseUri}/searchitems`, params);
    }

}
