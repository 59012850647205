import { Component, Input, OnInit } from '@angular/core';
import { ProductLogicService } from '@app/logic/products';

@Component({
    selector: 'cb-item-usage-summary-tab',
    templateUrl: './item-usage-summary-tab.component.html'
})
export class ItemUsageSummaryTabComponent implements OnInit {
    @Input() public productId: number;
    public productCards: Array<any>;
    constructor(private readonly productLogicService: ProductLogicService) {}

    public ngOnInit(): void {
        this.productLogicService.getProductCards(this.productId).subOnce({
            next: res => this.productCards = res
        });
    }
}
