import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { ICouncilLogicService } from './interfaces/i.council.logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import { CouncilMappedItem } from './council.mapped';
import { ICouncilMappedItem } from './interfaces/i.council.mapped';
import { ICouncilDto } from './interfaces/i.council.dto';

@Injectable()
export class CouncilLogicService extends BaseLogicService<ICouncilDto, ICouncilMappedItem> implements ICouncilLogicService {
    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('councils', CouncilMappedItem);
    }

    public getByLocation(locationId: number): Observable<ICouncilDto[]> {
        return this.$http.get(`${this.$baseUri}/getByLocation/${locationId}`);
    }
}
