<cb-dialog dialogHeading="Set Unconditional">
    <form class="flex-col flex"
          #setUnconditional="ngForm">
        <cb-datepicker name="datePicker"
                       [(ngModel)]="date"
                       label="Unconditional Date"
                       [maxDate]="now"
                       [required]="true"
                       pickerType="calendar"></cb-datepicker>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="!setUnconditional.valid"
                    (click)="save()">Set Unconditional</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
