import { BaseMappedItem } from '../base/base-mapped-item';
import { IComplianceRegistrationLogicService } from './interfaces/i.compliance-registration.logic.service';
import { IComplianceRegistrationMappedItem } from './interfaces/i.compliance-registration.mapped';
import { IComplianceRegistrationDto } from './interfaces/i.compliance-registration.dto';
import { IIdAndLabelDto } from '@app/shared/interfaces/i.idandlabel.dto';
import { DtoProp } from '../base/dto-prop.decorator';

export class ComplianceRegistrationMappedItem
    extends BaseMappedItem<IComplianceRegistrationDto, IComplianceRegistrationMappedItem, IComplianceRegistrationLogicService>
    implements IComplianceRegistrationMappedItem {
    @DtoProp public id: number;
    @DtoProp public label: string;
    @DtoProp public isActive: boolean;
    @DtoProp public complianceRegistrationId: number;
    @DtoProp public documentTypes: IIdAndLabelDto<number>[];

    constructor(
        sourceData: IComplianceRegistrationDto,
        logicService: IComplianceRegistrationLogicService
    ) {
        super(
            sourceData,
            logicService,
            ComplianceRegistrationMappedItem
        );
    }

    protected $preSave(toSave: IComplianceRegistrationDto): void {
        if (!toSave.documentTypes) {
            toSave.documentTypes = [];
        }

        if (toSave.isActive == null) {
            toSave.isActive = false;
        }
    }
}
