import {Component, Inject, Input} from '@angular/core';
import {PriceOptionDto} from '@app/logic/price-options/price-option.dto';
import {IOption} from '@app/shared/components/forms/select/select.component';
import { ILotMappedItem } from '@app/logic/lots';
import {PriceOptionsService} from '@app/logic/price-options/priceoptions.service';
@Component({
    selector: 'cb-price-options',
    templateUrl: './price-options.component.html',
    styleUrls: ['./price-options.component.scss']
})
export class PriceOptionsComponent {

    @Input() mappedItem: ILotMappedItem;
    public priceOptionSet: Map<number, PriceOptionDto> = new Map<number, PriceOptionDto>();
    public priceOptions: IOption[] = [];

    constructor(
        @Inject(PriceOptionsService) private service: PriceOptionsService
    ) {
        this.service.getAllActive().subOnce(data => {
            for (const item of data) {
                this.priceOptionSet.set(item.id, item);
                if (item.isDefault && !this.mappedItem.priceOptionId) {
                    this.mappedItem.priceOptionId = Number(item.id);
                }
            }
            this.priceOptions = data.map(option => {
                return {
                    id: option.id,
                    name: option.name,
                    label: option.name,
                };
            });
        });
    }

    public getCaptureAltPrice(id: number): boolean {
        const priceOption = this.priceOptionSet.get(id);
        return priceOption ? priceOption.captureAltPrice : null;
    }
}
