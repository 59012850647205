<button class="lt-md-hide"
        mat-icon-button
        [matMenuTriggerFor]="menu">
    <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item
            (click)="clearFilters.emit()"
            [matTooltip]="'Clear All Filters'">
        <mat-icon>clear</mat-icon>
        Clear
    </button>
    <div mat-menu-item
         *ngIf="!isSpecSchedule">
        <mat-slide-toggle name="listViewToggle"
                          [ngModel]="listViewEnabled"
                          (ngModelChange)="listViewEnabledChange.emit($event)">
            List View
        </mat-slide-toggle>
    </div>
    <div *ngIf="isIncompleteOnly !== undefined"
         mat-menu-item>
        <mat-slide-toggle name="isIncompleteOnly"
                          [ngModel]="isIncompleteOnly"
                          (ngModelChange)="isIncompleteOnlyChange.emit($event)">
            Incomplete Items Only
        </mat-slide-toggle>
    </div>
</mat-menu>
